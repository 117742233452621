import React, { useState, useEffect } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { Icon, Row, Col, Typography, Modal, Progress, Button, Radio, Input, Tabs, Empty, Table } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import { getCountryName } from '../../../utils/getCountryName';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import DPTournamentScheduleTables from './DPTournamentScheduleTables';
import DPTournamentScheduleMatches from './DPTournamentScheduleMatches';
import Fade from 'react-reveal/Fade';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import _ from 'lodash';
import * as routes from '../../../config/routes';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const ButtonGroup = Button.Group;

function DPTournamentSchedule(props) {
    const { authState, tournament, theme } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['tables', 'matches'];

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    let pathMatch =
        splitPath[splitPath.length] === slug
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : 'matches';

    const [selected, setSelected] = useState(pathMatch);

    useEffect(() => {
        setSelected(pathMatch);
    }, [pathMatch]);

    const styles = {
        selectedToggle: {
            boxShadow: 'none',
        },
        defaultToggle: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    };

    function toggleAutoScroll() {
        if (autoScrollEnabled === true) {
            setAutoScrollEnabled(false);
        } else {
            setAutoScrollEnabled(true);
        }
    }

    return (
        <SectionWrapper>
            {/* <SectionHeader
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				// subtitle={tournament.name}
				// subtitlePrefix={true}
				subtitleStyle={{ color: '#fff' }}
				height={116}
				theme="tournamentBuilder"
			>
				<TBManageButtons page="schedule" {...props} />
			</SectionHeader>
			<TournamentProgressBar /> */}
            <SectionContent padding="20px 40px">
                {autoScrollEnabled === true && (
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Text>&#8673; Auto scroll enabled &#8673;</Text>
                    </div>
                )}

                <Row gutter={24} justify="center" type="flex" className={`schedule ${autoScrollEnabled === true ? 'marquee' : ''}`}>
                    <Col xs={24}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Radio.Group
                                value={selected}
                                type="ghost"
                                onChange={(e) => {
                                    const active = e.target.value;
                                    setSelected(active);
                                    props.history.push(`/tournaments/${tournament.slug}/schedule/${active}`);
                                }}
                                style={{ marginRight: 10 }}
                            >
                                <Radio.Button value="matches" style={selected === 'matches' ? styles.selectedToggle : styles.defaultToggle}>
                                    Matches
                                </Radio.Button>
                                <Radio.Button value="tables" style={selected === 'tables' ? styles.selectedToggle : styles.defaultToggle}>
                                    Tables
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </Col>
                    <Col xs={24}>
                        <Route
                            exact
                            path={routes.DIGITALPOOL.TOURNAMENT_DETAIL_SCHEDULE_MATCHES}
                            render={(matchProps) => <DPTournamentScheduleMatches {...matchProps} {...props} tournament={tournament} />}
                        />
                        <Route
                            exact
                            path={routes.DIGITALPOOL.TOURNAMENT_DETAIL_SCHEDULE_TABLES}
                            render={(matchProps) => <DPTournamentScheduleTables {...matchProps} {...props} tournament={tournament} />}
                        />

                        <Route
                            exact
                            path={routes.DIGITALPOOL.TOURNAMENT_DETAIL_SCHEDULE}
                            render={(matchProps) => <DPTournamentScheduleMatches {...matchProps} {...props} tournament={tournament} />}
                        />
                    </Col>
                </Row>
                <div style={{ position: 'fixed', zIndex: 10, bottom: 20, left: '50%', transform: 'translateX(-50%)' }}>
                    <Button
                        type="secondary"
                        onClick={() => {
                            toggleAutoScroll();
                        }}
                    >
                        {autoScrollEnabled === true ? 'Turn off auto-scroll' : 'Turn on auto-scroll'}
                    </Button>
                </div>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(DPTournamentSchedule);
