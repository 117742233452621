import React from 'react';
import { Avatar, Typography } from 'antd';
import CircleFlag from '../components/CircleFlag';
import CircleStateFlag from '../components/CircleStateFlag';
import Image from '../components/Image';
import { getCountryName, displayEnumType } from '../utils';
import avatarImg1 from '../assets/img/kolage-128.jpg';
import avatarImg2 from '../assets/img/contemplative-reptile.jpg';
import tableBlue from '../assets/img/table/table-blue.png';
import tableCream from '../assets/img/table/table-cream.png';
import tableBlue9Ball from '../assets/img/table/table-blue-9-ball.png';
import tableGrey9Ball from '../assets/img/table/table-grey-9-ball.png';
import tableGrey from '../assets/img/table/table-grey.png';
import tableRed from '../assets/img/table/table-red.png';
import tableGreen from '../assets/img/table/table-green.png';
import './pool-table.css';

const { Text } = Typography;

export default function PoolTable(props) {
    const { onClick, tournament, table, tableSize, displayText, status, gameType, playerStyle } = props;

    const matchData = {
        ...props.matchData,
        ...(props.matchData &&
            props.matchData.challenger1 &&
            props.matchData.challenger1.skill_level &&
            tournament.show_player_skill_levels === true && {
                challenger1_skill_level: props.matchData.challenger1.skill_level,
            }),
        ...(props.matchData &&
            props.matchData.challenger2 &&
            props.matchData.challenger2.skill_level &&
            tournament.show_player_skill_levels === true && {
                challenger2_skill_level: props.matchData.challenger2.skill_level,
            }),
    };

    const isTableOccupied = status === 'live' || status === 'IN_USE' || status === 'IN_PROGRESS' || status === 'OPEN';
    const isScorePending = matchData && matchData.status === 'PENDING';
    const isTableAssigned = table && !table.is_streaming_table && table.status === 'ASSIGNED';
    const challenger1Country = getCountryName(matchData && matchData.challenger1_country);
    const challenger2Country = getCountryName(matchData && matchData.challenger2_country);
    const challenger1State = matchData && matchData.challenger1_state;
    const challenger2State = matchData && matchData.challenger2_state;
    const matchStatus = matchData && matchData.status;
    const textColor = isTableAssigned === true ? 'rgba(0,0,0,0.8)' : '#fff';
    const styles = {
        avatar: {
            margin: 0,
        },
        smallAvatar: {
            width: 20,
            height: 20,
        },
        bigAvatar: {
            width: 30,
            height: 30,
        },
        displayText: { color: textColor, fontWeight: 'bold', fontSize: 18 },
        name: { color: textColor, fontSize: 14 },
    };

    return (
        <div
            className={`pool-table ${tableSize ? tableSize : ''} ${matchStatus && matchStatus.toLowerCase()} ${
                matchStatus && matchStatus === 'PENDING' ? 'blinking' : ''
            }`}
            style={{ ...props.style, width: '100%', borderRadius: 10 }}
            onClick={onClick}
        >
            <div className="match-info">
                {displayText ? (
                    <Text style={styles.displayText}>{displayText}</Text>
                ) : !matchData ? (
                    <Text style={styles.displayText}>No match data found</Text>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            className={`item ${
                                matchData && matchData.challenger1_is_winner
                                    ? 'winner'
                                    : matchData && matchData.challenger2_is_winner !== true && matchData.challenger1_score > matchData.challenger2_score
                                    ? 'winning'
                                    : ''
                            }`}
                            style={tableSize === 'small' ? { padding: '0px 30px' } : null}
                        >
                            <div className="content">
                                <div style={{ marginTop: 3 }}>
                                    {matchData && matchData.challenger1_avatarImg ? (
                                        <Avatar
                                            size="small"
                                            src={matchData.challenger1_avatarImg}
                                            style={tableSize === 'small' ? styles.smallAvatar : styles.bigAvatar}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            {challenger1Country && (
                                                <React.Fragment>
                                                    {challenger1State ? (
                                                        <CircleStateFlag state={challenger1State} size={tableSize === 'large' ? 'medium' : 32} left={0} />
                                                    ) : (
                                                        <CircleFlag country={challenger1Country} size={tableSize === 'large' ? 'medium' : 32} left={0} />
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>

                                <div>
                                    {matchData.challenger1_name ? (
                                        <div>
                                            <h3
                                                className="player-info"
                                                style={
                                                    playerStyle
                                                        ? { ...playerStyle }
                                                        : tableSize === 'small'
                                                        ? styles.name
                                                        : { color: textColor, fontSize: 25, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }
                                                }
                                            >
                                                {matchData.challenger1_name !== 'BYE' &&
                                                matchData.challenger1_skill_level &&
                                                matchData.challenger1_race_to &&
                                                tournament &&
                                                tournament.show_player_skill_levels === true &&
                                                tournament &&
                                                tournament.show_player_races === true
                                                    ? `${matchData.challenger1_name} ${matchData.challenger1_skill_level} (${matchData.challenger1_race_to})`
                                                    : matchData.challenger1_name !== 'BYE' &&
                                                      matchData.challenger1_skill_level &&
                                                      !matchData.challenger1_race_to &&
                                                      tournament &&
                                                      tournament.show_player_skill_levels === true
                                                    ? `${matchData.challenger1_name} ${matchData.challenger1_skill_level}`
                                                    : matchData.challenger1_name !== 'BYE' &&
                                                      !matchData.challenger1_skill_level &&
                                                      matchData.challenger1_race_to &&
                                                      tournament &&
                                                      tournament.show_player_races === true
                                                    ? `${matchData.challenger1_name} (${matchData.challenger1_race_to})`
                                                    : matchData.challenger1_name
                                                    ? `${matchData.challenger1_name}`
                                                    : ''}
                                            </h3>
                                        </div>
                                    ) : (
                                        <div className="winner-loser-from">
                                            <h3 className="player-info" style={tableSize === 'small' ? styles.name : { color: textColor, fontSize: 20 }}>
                                                {matchData.loser_from_top_num && matchData.loser_from_bottom_num ? (
                                                    <React.Fragment>Loser of match {matchData.loser_from_top_num}</React.Fragment>
                                                ) : matchData.loser_from && (matchData.winner_from_top_num || matchData.winner_from_bottom_num) ? (
                                                    <React.Fragment>
                                                        Winner of match {matchData.winner_from_top_num || matchData.winner_from_bottom_num}
                                                    </React.Fragment>
                                                ) : matchData.winner_from_top_num || matchData.winner_from_bottom_num ? (
                                                    <React.Fragment>
                                                        {matchData.winner_from_top_num && `Winner of match ${matchData.winner_from_top_num}`}
                                                    </React.Fragment>
                                                ) : null}
                                            </h3>
                                        </div>
                                    )}

                                    {/* {matchData.scoring_format === 'BEST_OF' ? (
                                        <React.Fragment>
                                            <Text style={{ color: '#fff' }}>
                                                Best of {matchData.best_of_num}{' '}
                                                {matchData.winner_determined_by && displayEnumType(matchData.winner_determined_by.split('_')[0])}
                                            </Text>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {matchData.challenger1_race_to ? (
                                                <Text style={{ color: '#fff' }}>Race to {matchData.challenger1_race_to} </Text>
                                            ) : matchData.challenger1_goes_to ? (
                                                <Text style={{ color: '#fff' }}>
                                                    Goes to {matchData.challenger1_goes_to}{' '}
                                                    {matchData.winner_determined_by && displayEnumType(matchData.winner_determined_by.split('_')[0])}
                                                </Text>
                                            ) : null}
                                        </React.Fragment>
                                    )} */}
                                </div>
                            </div>
                            {tournament && tournament.game_type === 'ONE_POCKET' && (
                                <div
                                    className="points"
                                    style={tableSize === 'small' ? { fontSize: 14, minWidth: 30 } : playerStyle ? { ...playerStyle } : null}
                                >
                                    {matchData.challenger1_points || 0}
                                </div>
                            )}
                            {matchData.challenger1_name && (
                                <div className="score" style={tableSize === 'small' ? { fontSize: 15, minWidth: 30 } : playerStyle ? { ...playerStyle } : null}>
                                    {(matchData && matchData.challenger1_is_forfeit === true ? 'FF' : matchData.challenger1_score) || 0}
                                </div>
                            )}
                        </div>

                        <div
                            className={`item ${
                                matchData && matchData.challenger2_is_winner
                                    ? 'winner'
                                    : matchData && matchData.challenger1_is_winner !== true && matchData.challenger2_score > matchData.challenger1_score
                                    ? 'winning'
                                    : ''
                            }`}
                            style={tableSize === 'small' ? { padding: '0px 30px' } : null}
                        >
                            <div className="content">
                                <div style={{ marginTop: 3 }}>
                                    {matchData && matchData.challenger2_avatarImg ? (
                                        <Avatar
                                            size="small"
                                            src={matchData.challenger2_avatarImg}
                                            style={tableSize === 'small' ? styles.smallAvatar : styles.bigAvatar}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            {challenger2Country && (
                                                <React.Fragment>
                                                    {challenger2State ? (
                                                        <CircleStateFlag state={challenger2State} size={tableSize === 'large' ? 'medium' : 32} left={0} />
                                                    ) : (
                                                        <CircleFlag country={challenger2Country} size={tableSize === 'large' ? 'medium' : 32} left={0} />
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>

                                <div>
                                    {matchData.challenger2_name ? (
                                        <div>
                                            <h3
                                                className="player-info"
                                                style={
                                                    playerStyle
                                                        ? { ...playerStyle }
                                                        : tableSize === 'small'
                                                        ? styles.name
                                                        : { color: textColor, fontSize: 25, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }
                                                }
                                            >
                                                {matchData.challenger2_name !== 'BYE' &&
                                                matchData.challenger2_skill_level &&
                                                matchData.challenger2_race_to &&
                                                tournament &&
                                                tournament.show_player_skill_levels === true &&
                                                tournament &&
                                                tournament.show_player_races === true
                                                    ? `${matchData.challenger2_name} ${matchData.challenger2_skill_level} (${matchData.challenger2_race_to})`
                                                    : matchData.challenger2_name !== 'BYE' &&
                                                      matchData.challenger2_skill_level &&
                                                      !matchData.challenger2_race_to &&
                                                      tournament &&
                                                      tournament.show_player_skill_levels === true
                                                    ? `${matchData.challenger2_name} ${matchData.challenger2_skill_level}`
                                                    : matchData.challenger2_name !== 'BYE' &&
                                                      !matchData.challenger2_skill_level &&
                                                      matchData.challenger2_race_to &&
                                                      tournament &&
                                                      tournament.show_player_races === true
                                                    ? `${matchData.challenger2_name} (${matchData.challenger2_race_to})`
                                                    : matchData.challenger2_name
                                                    ? `${matchData.challenger2_name}`
                                                    : ''}
                                            </h3>
                                        </div>
                                    ) : (
                                        <div className="winner-loser-from">
                                            <h3 className="player-info" style={tableSize === 'small' ? styles.name : { color: textColor, fontSize: 20 }}>
                                                {matchData.loser_from_top_num && matchData.loser_from_bottom_num ? (
                                                    <React.Fragment>Loser of match {matchData.loser_from_bottom_num}</React.Fragment>
                                                ) : matchData.loser_from && (matchData.winner_from_top_num || matchData.winner_from_bottom_num) ? (
                                                    <React.Fragment>Loser of match {matchData.loser_from_num}</React.Fragment>
                                                ) : matchData.winner_from_top_num || matchData.winner_from_bottom_num ? (
                                                    <React.Fragment>
                                                        {matchData.winner_from_bottom_num && `Winner of match ${matchData.winner_from_bottom_num}`}
                                                    </React.Fragment>
                                                ) : null}
                                            </h3>
                                        </div>
                                    )}

                                    {/* {matchData.scoring_format === 'BEST_OF' ? (
                                        <React.Fragment>
                                            <Text style={{ color: '#fff' }}>
                                                Best of {matchData.best_of_num}{' '}
                                                {matchData.winner_determined_by && displayEnumType(matchData.winner_determined_by.split('_')[0])}
                                            </Text>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {matchData.challenger2_race_to ? (
                                                <Text style={{ color: '#fff' }}>Race to {matchData.challenger2_race_to} </Text>
                                            ) : matchData.challenger2_goes_to ? (
                                                <Text style={{ color: '#fff' }}>
                                                    Goes to {matchData.challenger2_goes_to}{' '}
                                                    {matchData.winner_determined_by && displayEnumType(matchData.winner_determined_by.split('_')[0])}
                                                </Text>
                                            ) : null}
                                        </React.Fragment>
                                    )} */}
                                </div>
                            </div>

                            {tournament && tournament.game_type === 'ONE_POCKET' && (
                                <div
                                    className="points"
                                    style={tableSize === 'small' ? { fontSize: 14, minWidth: 30 } : playerStyle ? { ...playerStyle } : null}
                                >
                                    {matchData.challenger2_points || 0}
                                </div>
                            )}
                            {matchData.challenger2_name && (
                                <div className="score" style={tableSize === 'small' ? { fontSize: 15, minWidth: 30 } : playerStyle ? { ...playerStyle } : null}>
                                    {(matchData && matchData.challenger2_is_forfeit === true ? 'FF' : matchData.challenger2_score) || 0}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {table && table.is_streaming_table ? (
                <Image>
                    <img src={tableRed} width="100%" alt={table && table.label} />
                </Image>
            ) : isScorePending === true ? (
                <Image>
                    <img src={tableGreen} width="100%" alt={table && table.label} />
                </Image>
            ) : isTableAssigned === true ? (
                <Image>
                    <img src={tableCream} width="100%" alt={table && table.label} />
                </Image>
            ) : isTableOccupied === true && gameType === 'NINE_BALL' ? (
                <Image>
                    <img src={tableBlue} width="100%" alt={table && table.label} />
                </Image>
            ) : isTableOccupied !== true && gameType === 'NINE_BALL' ? (
                <Image>
                    <img src={tableGrey} width="100%" alt={table && table.label} />
                </Image>
            ) : isTableOccupied !== true ? (
                <Image>
                    <img src={tableGrey} width="100%" alt={table && table.label} />
                </Image>
            ) : (
                <Image>
                    <img src={tableBlue} width="100%" alt={table && table.label} />
                </Image>
            )}
        </div>
    );
}
