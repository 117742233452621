import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { message, Button, Icon, Typography, Drawer, Tooltip, Divider, Modal, Input, Layout, Menu, Dropdown } from 'antd';
import _ from 'lodash';
import appStore from '../../../../lib/appStore';
import { view } from 'react-easy-state';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { PrinterOutlined, FilePdfFilled, FileImageFilled } from '@ant-design/icons';

const { Text, Title } = Typography;

function BracketControls(props) {
    const {
        tournament,
        isFullScreen,
        isPublic,
        toggleFullScreen,
        step,
        zoom,
        theme,
        onZoom,
        onStart,
        onMove,
        onReset,
        onGenerateDraw,
        onSidePanelOpen,
        onShowStatusPanel,
        onUpdateMatchTimeProjections,
        onAutoScrollToggle,
        onNavigationToggle,
        onTabNavigationToggle,
        autoScrollEnabled,
        navigationEnabled,
        tabNavigationEnabled,
        sidePanelOpen,
        showStatusPanel,
        drawRound,
        bracketEl,
        translation,
        viewType,
        onViewType,
        bracketLayout,
        onBracketLayout,
        tournamentComplete,
        compact,
        toggleCompact,
    } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    let minScale = props.minScale || 0.1;
    let maxScale = props.maxScale || 3;

    function canShowControls() {
        return isPublic !== true && tournamentComplete !== true;
    }

    function downloadBracketImage() {
        // const input = document.querySelector('#bracket-container');
        const input = document.querySelector('.bracket-surface');

        const key = 'pdf';
        message.loading({ content: 'Generating Bracket PNG. This could take a minute...', key, duration: 0 });

        html2canvas(input, {
            dpi: 300, // Set to 300 DPI
            // scale: 3, // Adjusts your resolution
        }).then((canvas) => {
            const filename = `${tournament.slug}.png`;
            var link = document.createElement('a');

            if (typeof link.download === 'string') {
                // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                link.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                message.success({
                    content: `${filename} downloaded`,
                    key,
                    duration: 2,
                });
            } else {
                window.open(filename);
            }
        });
    }

    function downloadBracketPdf() {
        // const input = document.querySelector('#bracket-container');
        const input = document.querySelector('.bracket-surface');
        var w = input.offsetWidth;
        var h = input.offsetHeight;
        const key = 'pdf';
        message.loading({ content: 'Generating Bracket PDF. This could take a minute...', key, duration: 0 });

        html2canvas(input, {
            dpi: 300, // Set to 300 DPI
            // scale: 3, // Adjusts your resolution
        }).then((canvas) => {
            const filename = `${tournament.slug}.pdf`;
            console.log('width', w);
            console.log('height', h);
            // var link = document.createElement('a');
            // link.download = 'my-image-name.jpeg';
            // document.body.appendChild(canvas);
            const imgData = canvas.toDataURL('image/png');
            const orientation = bracketLayout === 'left-right' && tournament.max_players < 128 ? 'l' : 'p';
            const pdf = new jsPDF(orientation, 'px', [w, h]);
            pdf.addImage(imgData, 'PNG', 0, 0, w, h, undefined, 'FAST');
            // pdf.output('dataurlnewwindow');
            pdf.save(filename, { returnPromise: true }).then(() => {
                message.success({
                    content: `${filename} downloaded`,
                    key,
                    duration: 2,
                });
            });

            // var a = document.createElement('a');
            // // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
            // a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            // a.download = 'somefilename.png';
            // a.click();
        });

        // var w = document.querySelector('.bracket-surface').offsetWidth;
        // var h = document.querySelector('.bracket-surface').offsetHeight;
        // html2canvas(input, {
        //     dpi: 300, // Set to 300 DPI
        //     scale: 3, // Adjusts your resolution
        // }).then((canvas) => {
        //     document.body.appendChild(canvas);
        //     const imgData = canvas.toDataURL('image/jpeg', 1);
        //     const pdf = new jsPDF('L', 'px', [w, h]);
        //     pdf.addImage(imgData, 'JPEG', 0, 0, [w, h]);
        //     // pdf.output('dataurlnewwindow');
        //     pdf.save('download.pdf');
        // });
    }

    function allMatchesSet() {
        return false;
        // let empty = 0;
        // drawRound.map((round) => {
        // 	if (
        // 		(round.challenger1_name !== undefined &&
        // 			round.challenger1_name !== null &&
        // 			round.challenger1_name !== undefined) ||
        // 		(round.challenger2_name !== undefined &&
        // 			round.challenger2_name !== null &&
        // 			round.challenger2_name !== undefined)
        // 	) {
        // 		empty++;
        // 	}
        // });
        // console.log(empty);
        // return empty === 0 ? true : false;
    }

    function changeScale(delta) {
        const targetScale = zoom + delta;
        const scale = clamp(minScale, targetScale, maxScale);

        let bracketElement = bracketEl.current;
        // console.log(bracketElement);
        const rect = bracketElement.container.current.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;

        // console.log(rect);

        const focalPoint = clientPosToTranslatedPos({ x, y });
        scaleFromPoint(scale, focalPoint);
    }

    function translatedOrigin(t = translation) {
        let bracketElement = bracketEl.current;
        const clientOffset = bracketElement.container.current.getBoundingClientRect();
        return {
            x: clientOffset.left + t.x,
            y: clientOffset.top + t.y,
        };
    }

    function clientPosToTranslatedPos({ x, y }, t = translation) {
        const origin = translatedOrigin(t);
        return {
            x: x - origin.x,
            y: y - origin.y,
        };
    }

    function discreteScaleStepSize() {
        const delta = Math.abs(maxScale - minScale);
        return delta / 30;
    }

    function scaleFromPoint(newScale, focalPt) {
        const scaleRatio = newScale / (zoom !== 0 ? zoom : 1);

        const focalPtDelta = {
            x: coordChange(focalPt.x, scaleRatio),
            y: coordChange(focalPt.y, scaleRatio),
        };

        const newTranslation = {
            x: translation.x - focalPtDelta.x,
            y: translation.y - focalPtDelta.y,
        };

        onZoom(newScale);
        onMove(clampTranslation(newTranslation));
    }

    function coordChange(coordinate, scaleRatio) {
        return scaleRatio * coordinate - coordinate;
    }

    function clampTranslation(desiredTranslation, props) {
        const { x, y } = desiredTranslation;
        const translationBounds = {};
        let { xMax, xMin, yMax, yMin } = translationBounds;
        xMin = xMin !== undefined ? xMin : -Infinity;
        yMin = yMin !== undefined ? yMin : -Infinity;
        xMax = xMax !== undefined ? xMax : Infinity;
        yMax = yMax !== undefined ? yMax : Infinity;

        return {
            x: clamp(xMin, x, xMax),
            y: clamp(yMin, y, yMax),
        };
    }

    function clamp(min, value, max) {
        return Math.max(min, Math.min(value, max));
    }

    function handleMenuClick(e) {
        // console.log('click', e);
        if (e.key === 'reset') {
            onZoom(1.0);
            onMove(clampTranslation({ x: 0, y: 0 }));
            if (bracketLayout === 'up-down') {
                const scrollingElement = document.querySelector('.scroll-container');
                if (scrollingElement) {
                    scrollingElement.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            } else if (bracketLayout === 'left-right') {
                const identifierElement = document.getElementById('W1-1');
                const scrollingElement = document.querySelector('.scroll-container');
                if (scrollingElement) {
                    console.log('left', identifierElement.offsetLeft);
                    const identifierRect = identifierElement.getBoundingClientRect();
                    scrollingElement.scroll({
                        top: 0,
                        left: document.body.offsetWidth / 2 + identifierElement.offsetLeft / 2 - identifierRect.width / 2,
                        behavior: 'smooth',
                    });
                }
            }
        } else if (e.key === 'start') {
            onStart();
        } else if (e.key === 'side-panel') {
            onSidePanelOpen(!sidePanelOpen);
        } else if (e.key === 'status-panel') {
            onShowStatusPanel(!showStatusPanel);
        } else if (e.key === 'reset-bracket') {
            onReset();
        } else if (e.key === 'auto-scroll') {
            onAutoScrollToggle();
        } else if (e.key === 'navigation') {
            onNavigationToggle();
        } else if (e.key === 'tab-navigation') {
            onTabNavigationToggle();
        } else if (e.key === 'update-match-time-projections') {
            onUpdateMatchTimeProjections();
        } else if (e.key === 'view-type') {
            if (viewType === 'zoom') {
                onViewType('scroll');
            } else {
                onViewType('zoom');
            }
        } else if (e.key === 'bracket-layout') {
            if (bracketLayout === 'left-right') {
                onBracketLayout('up-down');
            } else {
                onBracketLayout('left-right');
            }
        } else if (e.key === 'generate-random-draw') {
            const random = true;
            if (allMatchesSet() === false) {
                onGenerateDraw(random);
            } else {
                message.error('You must first reset the bracket before you can generate a new draw');
            }
        } else if (e.key === 'generate-seeded-draw') {
            const random = false;
            if (allMatchesSet() === false) {
                onGenerateDraw(random);
            } else {
                message.error('You must first reset the bracket before you can generate a new draw');
            }
        } else if (e.key === 'new-window') {
            window.open(`${window.location.href}?navigation=false`, '_blank');
        } else if (e.key === 'compact-layout') {
            if (compact === true) {
                toggleCompact(false);
            } else {
                toggleCompact(true);
            }
        }
        // props.history.push(`/tournament-builder/${props.match.params.slug}/${e.key}`);
    }

    const menu = (
        <Menu onClick={handleMenuClick} theme={theme.name}>
            {canShowControls() === true && (
                <Menu.Item key="start" disabled={tournament.status === 'IN_PROGRESS' ? true : false}>
                    <Icon type="play-circle" />
                    Start tournament
                </Menu.Item>
            )}

            {canShowControls() === true && <Menu.Divider style={{ backgroundColor: theme.bracket.panels.borderColor }} />}

            <Menu.ItemGroup title={<Text style={{ color: theme.bracket.panels.textColor }}>Bracket UI</Text>}>
                <Menu.Item key="reset">
                    <Icon type="reload" />
                    Reset zoom
                </Menu.Item>
                {/* <Menu.Item key="tables">
				Bracket layout
			</Menu.Item> */}
                {/* <SubMenu title="Bracket layout" placement="bottomLeft">
				<Menu.Item>Left-to-right</Menu.Item>
				<Menu.Item>Top-to-bottom</Menu.Item>
				<Menu.Item>March madness style</Menu.Item>
			</SubMenu> */}
                <Menu.Item key="view-type">
                    {viewType === 'zoom' ? <Icon type="drag" /> : <Icon type="interaction" />}
                    {viewType === 'zoom' ? 'Scrolling mode' : 'Zoom & pan mode'}
                </Menu.Item>

                <Menu.Item key="bracket-layout">
                    {bracketLayout === 'left-right' ? <Icon type="apartment" /> : <Icon type="apartment" />}
                    {bracketLayout === 'left-right' ? 'Top-to-bottom layout' : 'Left-to-right layout'}
                </Menu.Item>

                {canShowControls() === true && (
                    <Menu.Item key="side-panel">
                        <Icon type={sidePanelOpen === true ? 'menu-unfold' : 'menu-fold'} />
                        {sidePanelOpen === true ? 'Hide tables panel' : 'Show tables panel'}
                    </Menu.Item>
                )}

                {canShowControls() === true && (
                    <Menu.Item key="status-panel">
                        <Icon type="pic-left" />
                        {showStatusPanel === true ? 'Hide status panel' : 'Show status panel'}
                    </Menu.Item>
                )}

                <Menu.Item key="compact-layout">
                    {compact === true ? <Icon type="arrows-alt" /> : <Icon type="shrink" />}
                    {compact === true ? 'Expanded bracket' : 'Compact bracket'}
                </Menu.Item>

                <Menu.Item key="auto-scroll">
                    <Icon type="arrow-up" />
                    {autoScrollEnabled === true ? 'Turn off auto-scroll' : 'Turn on auto-scroll'}
                </Menu.Item>

                {isPublic === true && (
                    <Menu.Item key="navigation">
                        {/* <Icon type="arrow-up" /> */}
                        {navigationEnabled === true ? 'Hide navigation' : 'Show navigation'}
                    </Menu.Item>
                )}

                {isPublic === true && (
                    <Menu.Item key="tab-navigation">
                        {/* <Icon type="arrow-up" /> */}
                        {tabNavigationEnabled === true ? 'Hide tab navigation' : 'Show tab navigation'}
                    </Menu.Item>
                )}

                {/* <Menu.Item key="new-window">
					<Icon type="export" />
					Open in new window
				</Menu.Item> */}
            </Menu.ItemGroup>
            {canShowControls() === true && <Menu.Divider style={{ backgroundColor: theme.bracket.panels.borderColor }} />}

            {/* {canShowControls() === true && (
                <Menu.ItemGroup title={<Text style={{ color: theme.bracket.panels.textColor }}>Bracket Actions</Text>}>
                    <Menu.Item key="update-match-time-projections" disabled={tournament.status === 'IN_PROGRESS' ? false : true}>
                        <Icon type="clock-circle" />
                        Update match time projections
                    </Menu.Item>
                    <Menu.Item key="generate-random-draw" disabled={tournament.status === 'IN_PROGRESS' ? true : false}>
                        <Icon type="usergroup-add" />
                        Seed bracket (random order)
                    </Menu.Item>
                    <Menu.Item key="generate-seeded-draw" disabled={tournament.status === 'IN_PROGRESS' ? true : false}>
                        <Icon type="usergroup-add" />
                        Seed bracket (player seeds)
                    </Menu.Item>
                    <Menu.Item key="reset-bracket">
                        <Icon type="delete" />
                        Reset bracket
                    </Menu.Item>
                </Menu.ItemGroup>
            )} */}
        </Menu>
    );

    return (
        <div
            style={{
                position: 'absolute',
                right: 10,
                bottom: viewType === 'zoom' ? (isFullScreen === true ? 190 : 220) : 120,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: 10,
                backgroundColor: theme.bracket.controls.backgroundColor,
                borderRadius: 10,
                border: `1px solid ${theme.bracket.controls.borderColor}`,
                padding: 10,
            }}
        >
            <React.Fragment>
                <div>
                    <Dropdown overlay={menu} trigger={['click']} placement="topLeft" getPopupContainer={() => document.querySelector('#bracket-container')}>
                        <Button type="primary" ghost>
                            <Icon type="menu" />
                        </Button>
                    </Dropdown>
                </div>
                {breakpoint !== 'mobile' && (
                    <div style={{ marginTop: 10 }}>
                        <Tooltip
                            placement="left"
                            title={isFullScreen === true ? 'Exit Fullscreen' : 'Go Fullscreen'}
                            getPopupContainer={() => document.querySelector('#bracket-container')}
                        >
                            <Button type="primary" onClick={toggleFullScreen}>
                                <Icon type={isFullScreen === true ? 'fullscreen-exit' : 'fullscreen'} />
                            </Button>
                        </Tooltip>
                    </div>
                )}
            </React.Fragment>

            {/* <div style={{ marginTop: 10 }}>
				<Tooltip
					placement="left"
					title="Open In New Window"
					getPopupContainer={() => document.querySelector('#bracket-container')}
				>
					<Button
						type="primary"
						onClick={() => {
							window.open(`${window.location.href}?navigation=false`, '_blank');
						}}
					>
						<Icon type="export" />
					</Button>
				</Tooltip>
			</div> */}

            {breakpoint !== 'mobile' && (
                <React.Fragment>
                    <div style={{ marginTop: 10 }}>
                        <Tooltip placement="left" title="Download Bracket PDF">
                            <Button type="primary" onClick={downloadBracketPdf}>
                                <Icon type="download" />
                            </Button>
                        </Tooltip>
                    </div>

                    <div style={{ marginTop: 10 }}>
                        <Tooltip placement="left" title="Download Bracket PNG">
                            <Button type="primary" onClick={downloadBracketImage}>
                                <Icon type="file-image" />
                            </Button>
                        </Tooltip>
                    </div>
                </React.Fragment>
            )}

            {/* <div style={{ marginTop: 10 }}>
                <Tooltip placement="left" title="Print Bracket">
                    <ReactToPrint
                        trigger={() => (
                            <Button type="primary">
                                <PrinterOutlined />
                            </Button>
                        )}
                        content={() => bracketEl.current}
                    />
                </Tooltip>
            </div> */}

            {viewType === 'scroll' && (
                <React.Fragment>
                    <div style={{ marginTop: 10 }}>
                        <Tooltip placement="left" title="Zoom In" getPopupContainer={() => document.querySelector('.scroll-container')}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    changeScale(step);
                                }}
                            >
                                <Icon type="zoom-in" />
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Tooltip placement="left" title="Zoom Out" getPopupContainer={() => document.querySelector('.scroll-container')}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    changeScale(-step);
                                }}
                            >
                                <Icon type="zoom-out" />
                            </Button>
                        </Tooltip>
                    </div>
                    <Text style={{ marginTop: 10 }}>
                        <Text style={{ color: theme.bracket.panels.textColor }}>{(zoom * 100).toFixed(0)}%</Text>
                    </Text>
                </React.Fragment>
            )}
        </div>
    );
}

export default view(BracketControls);
