import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography } from 'antd';
import * as Yup from 'yup';
import { Mutation } from '@apollo/client/react/components';
import { CountryRegionData } from 'react-country-region-selector';
import { GET_LEAGUE_BY_SLUG_QUERY } from '../data/queries';
import { UPDATE_LEAGUE_MUTATION } from '../data/mutations';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function LMContactSettingsForm(props) {
    const { league, history } = props;
    const [loading, setLoading] = useState(false);

    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    const UpdateSchema = Yup.object().shape({
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        region: Yup.string().required('Region is required'),
        postal_code: Yup.string().required('Postal Code is required'),
        email_address: Yup.string().email('Email is invalid').required('Email address is required'),
        website: Yup.string().url('Website must be a valid url e.g. http://www.example.com)').nullable(),
        phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
        fax_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
        social_facebook: Yup.string().nullable(),
        social_instagram: Yup.string().nullable(),
        social_twitter: Yup.string().nullable(),
    });

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    const handleSubmit = (values, actions, updateLeague) => {
        console.log(values);
        const {
            address1,
            address2,
            city,
            county,
            country,
            region,
            postal_code,
            phone_number,
            fax_number,
            email_address,
            website,
            social_facebook,
            social_instagram,
            social_twitter,
        } = values;
        setLoading(true);
        updateLeague({
            variables: {
                id: league.id,
                changes: {
                    address1: address1,
                    address2: address2,
                    city: city,
                    county: county,
                    country: country,
                    region: region,
                    postal_code: postal_code,
                    phone_number: phone_number,
                    fax_number: fax_number,
                    email_address: email_address,
                    website: website,
                    social_facebook: social_facebook,
                    social_twitter: social_twitter,
                    social_instagram: social_instagram,
                },
            },
            refetchQueries: () => [
                {
                    query: GET_LEAGUE_BY_SLUG_QUERY,
                    variables: { slug: props.league.slug },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                },
            ],
        });
    };

    return (
        <Mutation
            mutation={UPDATE_LEAGUE_MUTATION}
            onCompleted={(res) => {
                console.log(res);
                const league = res.update_leagues.returning[0];
                setLoading(false);
                message.success('Changes saved');
                // const result = league.insert_leagues.returning[0];
                // props.history.push(`/league-manager/${result.slug}/dashboard`);
                // const { updateLeague } = data;
                // history.push(`/league-manager/${league.slug}/settings/contact`);
            }}
            awaitRefetchQueries
        >
            {(updateLeague, { error }) => {
                if (error) {
                    alert('Something went wrong');
                }
                return (
                    <Formik
                        initialValues={{
                            address1: league.address1 || '',
                            address2: league.address2 || '',
                            city: league.city || '',
                            county: league.county || '',
                            country: league.country || undefined,
                            region: league.region || undefined,
                            postal_code: league.postal_code || '',
                            phone_number: league.phone_number || '',
                            fax_number: league.fax_number || '',
                            email_address: league.email_address || '',
                            website: league.website || '',
                            social_facebook: league.social_facebook || '',
                            social_instagram: league.social_instagram || '',
                            social_twitter: league.social_twitter || '',
                        }}
                        validationSchema={UpdateSchema}
                        onSubmit={(values, actions) => handleSubmit(values, actions, updateLeague)}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal" style={{ maxWidth: 600 }}>
                                <FormItem
                                    name="address1"
                                    label="Address 1"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.address1 || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="address1" placeholder="Address 1" size="large" />
                                    <ErrorMessage name="address1" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="address2"
                                    label="Address 2"
                                    className={'ant-form-item-control' + (errors.address2 || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="address2" placeholder="Address 2" size="large" />
                                    <ErrorMessage name="address2" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="city"
                                    label="City"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.city || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="city" placeholder="City" size="large" />
                                    <ErrorMessage name="city" component="div" className="ant-form-explain" />
                                </FormItem>
                                {/* <FormItem
									label="County"
									className={
										'ant-form-item-control' + (errors.county || errors.general ? ' has-error' : '')
									}
								>
									<Input name="county" placeholder="County" size="large" />
									<ErrorMessage name="county" component="div" className="ant-form-explain" />
								</FormItem> */}
                                <FormItem
                                    name="country"
                                    label="Country"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.country || errors.general ? ' has-error' : '')}
                                >
                                    {/* <Input name="country" placeholder="Country" size="large" /> */}
                                    <Select
                                        showSearch
                                        name="country"
                                        placeholder="Please select a country"
                                        optionFilterProp="children"
                                        size="large"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {CountryRegionData.map((option, index) => {
                                            return (
                                                <Option value={option[1]} key={index}>
                                                    {option[0]}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    <ErrorMessage name="country" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="region"
                                    label="Region"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.region || errors.general ? ' has-error' : '')}
                                >
                                    {/* <Input name="region" placeholder="Select State/Province/Region" size="large" /> */}
                                    <Select
                                        showSearch
                                        name="region"
                                        placeholder="Please select a state/province/region"
                                        size="large"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {getRegions(values.country).map((option, index) => (
                                            <Option value={option} key={index}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                    <ErrorMessage name="region" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="postal_code"
                                    label="Postal Code"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.postal_code || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="postal_code" placeholder="Postal Code" size="large" />
                                    <ErrorMessage name="postal_code" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="phone_number"
                                    label="Phone Number"
                                    className={'ant-form-item-control' + (errors.phone_number || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="phone_number" placeholder="Phone Number" size="large" />
                                    <ErrorMessage name="phone_number" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="fax_number"
                                    label="Fax Number"
                                    className={'ant-form-item-control' + (errors.fax_number || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="fax_number" placeholder="Fax Number" size="large" />
                                    <ErrorMessage name="fax_number" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="email_address"
                                    label="Email Address"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.email_address || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="email_address" placeholder="Email Address" size="large" />
                                    <ErrorMessage name="email_address" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="website"
                                    label="Website"
                                    className={'ant-form-item-control' + (errors.website || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="website" placeholder="Website" size="large" />
                                    <ErrorMessage name="website" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="social_facebook"
                                    label="Facebook Link"
                                    className={'ant-form-item-control' + (errors.social_facebook || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="social_facebook" placeholder="Facebook Link" size="large" />
                                    <ErrorMessage name="social_facebook" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="social_instagram"
                                    label="Instagram Link"
                                    className={'ant-form-item-control' + (errors.social_instagram || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="social_instagram" placeholder="Instagram Link" size="large" />
                                    <ErrorMessage name="social_instagram" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="social_twitter"
                                    label="Twitter Link"
                                    className={'ant-form-item-control' + (errors.social_twitter || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="social_twitter" placeholder="Twitter Link" size="large" />
                                    <ErrorMessage name="social_twitter" component="div" className="ant-form-explain" />
                                </FormItem>
                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={loading}
                                >
                                    Update
                                </SubmitButton>
                                <div>
                                    <Text style={styles.error}>{errors.general}</Text>
                                </div>
                            </Form>
                        )}
                    />
                );
            }}
        </Mutation>
    );
}

export default withRouter(LMContactSettingsForm);
