import React, { useState, useEffect, useRef } from 'react';
import { message, Typography, Alert, Modal, Empty, Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TOURNAMENT_PLAYERS_QUERY, GET_TOURNAMENT_QUERY } from './data/queries';
import { GET_TOURNAMENT_PLAYERS_SUBSCRIPTION2 } from './data/subscriptions';
import { DELETE_TOURNAMENT_PLAYER_MUTATION, UPDATE_TOURNAMENT_PLAYER_MUTATION, CREATE_PLAYER_MUTATION } from './data/mutations';
import PlayerTable from './PlayerTable';
import AddPlayerModal from './AddPlayerModal';
import ImportPlayersModal from './ImportPlayersModal';
import ListFargoModal from './ListFargoModal';
import LinkPlayerNotification from './LinkPlayerNotification';
import _ from 'lodash';
import slugify from 'slugify';
import fargoLogoCircle from '../../assets/img/fargo-logo-circle.png';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
};

function TBCreateAddPlayers(props) {
    const { tournament, authState, onContinue, goBack } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    // const [tournamentPlayers, setTournamentPlayers] = useState();
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    const [playersLinked, setPlayersLinked] = useState(false);
    const [playersAdded, setPlayersAdded] = useState(false);
    const [playerImportModalVisible, setPlayerImportModalVisible] = useState(false);
    const [playerAddModalVisible, setPlayerAddModalVisible] = useState(false);
    const [fargoModalVisible, setFargoModalVisible] = useState(false);
    // const [multipleFargoResults, setMultipleFargoResults] = useState([]);
    const [deletePlayer, { loading, error }] = useMutation(DELETE_TOURNAMENT_PLAYER_MUTATION);
    const hasSelected = selectedKeys && selectedKeys.length > 0;
    const [createPlayers] = useMutation(CREATE_PLAYER_MUTATION);
    // useEffect(() => {
    //     console.log('tournament changed');
    // }, [tournament]);

    function handleRemoveSelected() {
        showConfirmDelete();
    }

    function showConfirmDelete() {
        confirm({
            title: `Are you sure you want to remove ${selectedRows.length} players from this tournament?`,
            content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                let promises = [];
                selectedRows.forEach(async (row) => {
                    promises.push(
                        await props.client
                            .mutate({
                                mutation: DELETE_TOURNAMENT_PLAYER_MUTATION,
                                variables: {
                                    tournament_id: tournament.id,
                                    id: row.id,
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                                        variables: { tournament_id: tournament.id },
                                    },
                                ],
                            })
                            .then((data) => {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.log(error);
                                message.error('There was an error', error);
                            })
                    );
                });

                Promise.all(promises).then((values) => {
                    message.success(`${selectedRows.length} Players removed from tournament`);
                    setSelectedKeys([]);
                    setSelectedRows([]);
                    setSelectedPlayerId();
                });
            },
            onCancel() {},
        });
    }

    function handleImportModalOk(e) {
        console.log(e);
        setSelectedKeys([]);
        setSelectedRows([]);
        setSelectedPlayerId();
        setPlayerImportModalVisible(false);
    }

    function handlePlayerImportModalCancel(e) {
        setPlayerImportModalVisible(false);
    }

    function handlePlayerAddModalOk(data) {
        console.log(data);

        setSelectedKeys([]);
        setSelectedRows([]);
        setSelectedPlayerId();
        setPlayerAddModalVisible(false);
    }

    function handlePlayerAddModalCancel(e) {
        setSelectedPlayerId();
        setPlayerAddModalVisible(false);
    }

    function handlePlayerAdd() {
        setPlayerAddModalVisible(true);
        setSelectedPlayerId(null);
    }

    function handlePlayerImport() {
        setPlayerImportModalVisible(true);
    }

    async function handleCreatePlayers(players) {
        const playersToInsert = players.map((player) => {
            const nameSlug = slugify(player.name, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });

            return {
                name: player.name,
                name_slug: nameSlug,
                country: player.country,
                phone_number: player.phone_number,
                fargo_id: player.fargo_id,
                ...(player.fargo_id && { fargo_rating: player.skill_level }),
            };
        });

        console.log({
            players,
            playersToInsert,
        });

        const key = 'insertable';
        message.loading({ content: `Adding ${playersToInsert.length} player records...`, key, duration: 0 });

        createPlayers({
            variables: {
                objects: playersToInsert,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                    variables: { tournament_id: tournament.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `${playersToInsert.length} players were added`,
                    key,
                    duration: 2,
                });
                setPlayersAdded(true);
            })
            .catch((error) => {
                console.error(error);
                message.error('There was an error', JSON.stringify(error));
                setPlayersAdded(false);
            });
    }

    async function handleUnlinkedPlayers(players, tournamentPlayers) {
        console.log({
            players,
            tournamentPlayers,
        });
        const playersToUpdate = _.compact(
            tournamentPlayers.map((item, index) => {
                const newPlayer = players.filter((player) => {
                    const tournamentPlayerName = item.name && item.name.toLowerCase();
                    const playerName = player.name && player.name.toLowerCase();
                    return tournamentPlayerName === playerName;
                })[0];

                console.log(newPlayer);

                if (newPlayer) {
                    return {
                        id: parseInt(item.id),
                        player_id: parseInt(newPlayer.id),
                        ...(newPlayer.country && { country: newPlayer.country }),
                        ...(newPlayer.fargo_id && { fargo_id: newPlayer.fargo_id }),
                        ...(newPlayer.fargo_rating && { skill_level: newPlayer.fargo_rating }),
                        ...(newPlayer.phone_number && { phone_number: newPlayer.phone_number }),
                        ...(newPlayer.user_id && { user_id: newPlayer.user_id }),
                    };
                }
            })
        );
        console.log(playersToUpdate);

        const key = 'linkable';
        message.loading({ content: `Linking ${playersToUpdate.length} player records...`, key, duration: 0 });

        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result
            .then((e) => {
                message.success({
                    content: `Player records have been linked.`,
                    key,
                    duration: 2,
                });

                setPlayersLinked(true);
                // console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
            })
            .catch((error) => {
                console.error(error);
                message.error('There was an error', JSON.stringify(error));
                setPlayersLinked(false);
            });
    }

    async function handleFargoModalOk(players, tournamentPlayers) {
        const playersToUpdate = _.compact(
            tournamentPlayers.map((item, index) => {
                const newPlayer = players
                    .filter((player) => {
                        return item.id === player.id;
                    })
                    .filter((player) => {
                        return parseInt(player.effectiveRating) !== parseInt(player.skillLevel);
                    })[0];

                // console.log(newPlayer);

                if (newPlayer) {
                    return {
                        id: parseInt(item.id),
                        skill_level: parseInt(newPlayer.effectiveRating),
                        fargo_id: parseInt(newPlayer.fargoId),
                    };
                }
            })
        );
        console.log(playersToUpdate);

        const key = 'updatable';
        message.loading({ content: `Updating ${playersToUpdate.length} player Fargo Ratings...`, key, duration: 0 });

        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success({
                content: `Updated player Fargo Ratings.`,
                key,
                duration: 2,
            });
            // console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
        });

        // const playersToUpdate = _.compact(
        //     tournamentPlayers.map((player, index) => {
        //         const newPlayer = players.filter((item) => item.id === player.id)[0];
        //         if (newPlayer) {
        //             return {
        //                 ...newPlayer,
        //                 key: `update_tournament_player${index + 1}`,
        //             };
        //         }
        //     })
        // );

        // console.log(playersToUpdate);

        // const updates = playersToUpdate.reduce(
        //     (a, key) =>
        //         Object.assign(a, {
        //             [key.key]: `update_tournament_players(where: { tournament_id: { _eq: ${tournament.id} }, {id: ${key.id}}}, _set: { skill_level: ${key.rating} }) { affected_rows }`,
        //         }),
        //     {}
        // );

        // console.log(typeof updates);
        // console.log(updates);

        // const UPDATE_TOURNAMENT_PLAYERS_MUTATION = gql`
        //     mutation update_tournament_players {
        //         ${updates}
        //     }
        // `;

        // await props.client
        //     .mutate({
        //         mutation: UPDATE_TOURNAMENT_PLAYERS_MUTATION,
        //         awaitRefetchQueries: true,
        //         refetchQueries: [
        //             {
        //                 query: GET_TOURNAMENT_PLAYERS_QUERY,
        //                 variables: { tournament_id: tournament.id },
        //             },
        //             {
        //                 query: GET_TOURNAMENT_QUERY,
        //                 variables: { id: tournament.id },
        //             },
        //         ],
        //     })
        //     .then((data) => {
        //         console.log(data);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         message.error('There was an error', error);
        //     });

        // const UPDATE_TOURNAMENT_PLAYERS_MUTATION = gql`
        //     mutation myUpdates{
        //         update1: update_users(where: {id : 1}, _set: { value: 10 }) { affected_rows }
        //         update2: update_users(where: {id : 2}, _set: { value: 15 }) { affected_rows }
        //         update3: update_users(where: {id : 3}, _set: { value: 20 }) { affected_rows }
        //     }
        // `;

        console.log(players);
        setFargoModalVisible(false);
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                        variables: { tournament_id: tournament.id },
                    },
                    // {
                    //     query: GET_TOURNAMENT_QUERY,
                    //     variables: { id: tournament.id },
                    // },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function handleFargoModalCancel(e) {
        setFargoModalVisible(false);
    }

    function handleGetFargoRatings(players) {
        setFargoModalVisible(true);

        // skill_level = fargo && fargo.rating;
    }

    function handleSelected(selectedKeys, selectedRows) {
        setSelectedKeys(selectedKeys);
        setSelectedRows(selectedRows);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedPlayerId(selected);
        setPlayerAddModalVisible(true);
    }

    return (
        <Query
            query={GET_TOURNAMENT_PLAYERS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ tournament_id: tournament && tournament.id }}
            onCompleted={async (data) => {
                console.log(data);
                // if (!loading && !error) {
                //     setTournamentPlayers(players);
                // }
            }}
        >
            {({ loading, error, data, subscribeToMore }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                const tournamentPlayers =
                    data.tournament_players &&
                    data.tournament_players.map((item, index) => {
                        if (item.user) {
                            return {
                                ...item.user,
                                ...item,
                                id: item.id,
                                // name: `${item.user.first_name} ${item.user.last_name}`,
                                index: index + 1,
                                key: index,
                            };
                        } else {
                            return {
                                ...item,
                                id: item.id,
                                index: index + 1,
                                key: index,
                            };
                        }
                    });

                return (
                    <React.Fragment>
                        <div>
                            <Alert
                                message="Helpful tips for adding players"
                                description="You can add players individually or many at once by using the Player Import feature. If you include phone numbers, you will be able to send SMS notifications to those players during the tournament. After adding your players, head over to the bracket page to start managing your tournament."
                                type="info"
                                closable
                                showIcon
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Title level={4} style={{ margin: 0 }}>
                                All Players ({tournamentPlayers && tournamentPlayers.length})
                            </Title>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button type="primary" onClick={handlePlayerAdd} style={{ marginLeft: 20 }}>
                                        <Icon type="user-add" /> Add Player
                                    </Button>
                                    {/* <Button type="primary" onClick={handlePlayerAdd}>
                                        <Icon type="usergroup-add" /> My Player List
                                    </Button> */}
                                    <Button ghost onClick={handlePlayerImport}>
                                        <Icon type="upload" /> Import Player Spreadsheet
                                    </Button>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        type="secondary"
                                        onClick={() => {
                                            handleGetFargoRatings(tournamentPlayers);
                                        }}
                                        disabled={tournamentPlayers && tournamentPlayers.length > 0 ? false : true}
                                    >
                                        <img src={fargoLogoCircle} alt="Fargo Logo" height={20} style={{ marginRight: 5 }} />
                                        Get Fargo Ratings
                                    </Button>
                                    <div style={{ marginLeft: 10 }}>
                                        <Button type="danger" onClick={handleRemoveSelected} disabled={!hasSelected} loading={loading}>
                                            Remove
                                        </Button>
                                        <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {playersLinked === false && (
                            <div>
                                <LinkPlayerNotification
                                    {...props}
                                    players={tournamentPlayers}
                                    onUpdatePlayers={(players) => {
                                        handleUnlinkedPlayers(players, tournamentPlayers);
                                    }}
                                    onCreatePlayers={(players) => {
                                        handleCreatePlayers(players);
                                    }}
                                />
                            </div>
                        )}
                        <PlayerTable
                            {...props}
                            tournament={tournament}
                            selectedKeys={selectedKeys}
                            onSelectedKeys={(key) => {
                                setSelectedKeys(key);
                            }}
                            size="small"
                            onDelete={(record) => {
                                deletePlayer({
                                    variables: {
                                        tournament_id: tournament.id,
                                        id: record.id,
                                    },
                                    notifyOnNetworkStatusChange: true,
                                    awaitRefetchQueries: true,
                                    refetchQueries: [
                                        {
                                            query: GET_TOURNAMENT_PLAYERS_QUERY,
                                            variables: { tournament_id: tournament.id },
                                        },
                                    ],
                                })
                                    .then((res) => {
                                        console.log(res);
                                        setSelectedKeys([]);
                                        setSelectedRows([]);
                                        message.success('Player successfully deleted');
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setSelectedKeys([]);
                                        setSelectedRows([]);
                                        message.error('There was an error', error);
                                    });
                            }}
                            onSelected={handleSelected}
                            onEditSelected={handleEditSelected}
                            players={tournamentPlayers}
                            subscribeToMore={subscribeToMore}
                            subscribeToNewData={() => {
                                subscribeToMore({
                                    document: GET_TOURNAMENT_PLAYERS_SUBSCRIPTION2,
                                    variables: { tournament_id: tournament.id },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        if (!subscriptionData.data) return prev;
                                        const newPlayers = subscriptionData.data.tournament_players;
                                        let updated;
                                        if (prev) {
                                            updated = Object.assign({}, prev, {
                                                tournament_players: [...newPlayers],
                                            });
                                        } else {
                                            updated = Object.assign({}, prev, {
                                                tournament_players: [...newPlayers],
                                            });
                                        }
                                        return updated;
                                    },
                                });
                            }}
                        />
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                onContinue(tournamentPlayers);
                            }}
                            style={{ marginTop: 20 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text style={{ color: '#fff' }}>Save and go to step 3</Text>
                            </div>
                        </Button>
                        <Button
                            type="secondary"
                            size="large"
                            onClick={() => {
                                goBack();
                            }}
                            style={{ marginTop: 20 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text>Go back</Text>
                            </div>
                        </Button>
                        <ImportPlayersModal
                            {...props}
                            userID={authState.user.id}
                            tournament={tournament}
                            tournamentID={tournament.id}
                            onModalOk={handleImportModalOk}
                            onModalCancel={handlePlayerImportModalCancel}
                            modalVisible={playerImportModalVisible}
                        />
                        <AddPlayerModal
                            {...props}
                            selectedPlayerId={selectedPlayerId}
                            userID={authState.user.id}
                            tournament={tournament}
                            tournamentID={tournament.id}
                            onModalOk={handlePlayerAddModalOk}
                            onModalCancel={handlePlayerAddModalCancel}
                            modalVisible={playerAddModalVisible}
                        />
                        <ListFargoModal
                            {...props}
                            tournament={tournament}
                            players={tournamentPlayers}
                            modalVisible={fargoModalVisible}
                            onModalOk={(players) => {
                                handleFargoModalOk(players, tournamentPlayers);
                            }}
                            onModalCancel={handleFargoModalCancel}
                        />
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(TBCreateAddPlayers);
