import React, { useState, useEffect } from 'react';
import TBCreateAddPlayers from './TBCreateAddPlayers';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

export default function TBTournamentStep2Players(props) {
    const { authState, match, tournament } = props;
    let url;
    if (match.params.slug) {
        url = `/tournament-builder/${match.params.slug}/edit`;
    } else {
        url = `/tournament-builder/new/edit`;
    }

    return (
        <div
            style={{
                padding: 20,
            }}
        >
            <TBCreateAddPlayers
                {...props}
                tournament={tournament}
                goBack={() => {
                    props.history.push(`${url}/settings`);
                    // setCurrentStep(0);
                }}
                onContinue={(players) => {
                    props.history.push(`${url}/tables`);
                    const user = authState && authState.user && authState.user;
                    mixpanel.track('Tournament players added', {
                        ...user,
                        displayName: user.displayName,
                        tournamentId: tournament.id,
                        tournamentName: tournament.name,
                        source: 'web',
                    });
                    // setCurrentStep(2);
                    // window.scrollTo(0, 0);
                    // createBracket(tournament, players);
                }}
            />
        </div>
    );
}
