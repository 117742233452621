import React, { useState, useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Typography, Button, Row, Col, Radio  } from 'antd';
import { Input, Select } from 'formik-antd';
import SectionWrapper from '../../components/SectionWrapper';
import SectionContent from '../../components/SectionContent';
import SectionHeader from '../../components/SectionHeader';
import Card from '../../components/Card';
import CreateSteps from './components/GECreate/Steps';
import FormController from './components/GECreate/FormController';
import { createStep, createGhostGameFormValues, completedStepAtom } from './data/atoms';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

function onOk() {

}

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		padding: 20
	},
	cardHeader: {
		paddingTop: 5,
		paddingBottom: 5,
		background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
		borderRadius: '10px 10px 0px 0px'
	}
};

function CreateForm(props) {
	const { client, userId, authState } = props;
	const setActiveStep = useSetRecoilState(createStep);
	const completedStepAtomValue = useRecoilValue(completedStepAtom);

	function onStepChange(step) {
		setActiveStep(step);
	}

    return (
        <SectionWrapper>
			<SectionHeader
				title="Tournament Setup"
				titleStyle={{
					color: '#fff',
					textTransform: 'uppercase',
					margin: 0,
					display: 'flex'
				}}
				height={120}
				backButton={
					<Button
						onClick={() =>
							props.match.params.slug
								? props.history.push(`/ghost-game-manager/${props.match.params.slug}/dashboard`)
								: props.history.push(`/ghost-game-manager`)}
						type="ghost"
						shape="circle"
						icon="arrow-left"
						size="large"
						ghost
						style={{ border: 'none' }}
					/>
				}
				subtitle="Create and manage your own tournaments in a few simple steps."
				subtitleStyle={{ color: '#fff' }}
				theme="tournamentBuilder"
			/>
			<SectionContent padding="20px 40px">
				<Row gutter={24} justify="center" type="flex">
					<Col xs={24}>
						<Card headStyle={styles.cardHeader} bodyStyle={{ padding: 0 }}>
							<Row gutter={0} justify="start" type="flex">
								<Col xs={24} sm={4}>
									<CreateSteps onChange={onStepChange} />
                                </Col>
                                <Col xs={24} sm={20}>
                                    <FormController client={client} userId={userId} authState={authState} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default function GGCreate(props) {
    const { authState, client, history } = props;
    const userId = authState.user.id;

	history.listen((location, action) => {
        // location is an object like window.location
        console.log("change", action, location.pathname, location.state)
    });

    return (
        <React.Fragment>
            <CreateForm userId={userId} client={client} authState={authState} />
        </React.Fragment>
     )
}