import React, { useState, useEffect, useRef } from 'react';
import { Popconfirm, message, Alert, Modal, Input, Button, Upload, Icon, Row, Col, Typography, Divider } from 'antd';
import { GET_TOURNAMENT_PLAYERS_QUERY, GET_TOURNAMENT_BRACKET_QUERY } from './data/queries';
import Card from '../../components/Card';
import { withRouter } from 'react-router-dom';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import moment from 'moment';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import TBManageButtons from './TBManageButtons';

const { TextArea } = Input;
const { Text, Title } = Typography;

function NotifyAllPlayers(props) {
    const { tournament } = props;
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState();
    const [matches, setMatches] = useState();
    const [textMessage, setTextMessage] = useState();
    const [sentMessages, setSentMessages] = useState(0);
    const [disabled, setDisabled] = useState(false);

    async function getPlayers(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: GET_TOURNAMENT_PLAYERS_QUERY,
            variables: {
                tournament_id: tournament.id,
            },
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            // dataSet.push(tp.name);
            const { id, name, email, phone_number } = player;

            dataSet.push({
                id,
                name,
                email,
                phone_number,
            });
        }
        console.log(dataSet);

        // const players = [
        //     {
        //         name: 'Justin Espinosa',
        //         phone_number: '5122970639',
        //     },
        //     {
        //         name: 'Dave Wiebelhaus',
        //         phone_number: '7632348657',
        //     },
        //     {
        //         name: 'Adrian Alaniz',
        //         phone_number: '2104227121',
        //     },
        //     {
        //         name: 'Justin Huber',
        //         phone_number: '2107221387',
        //     },
        //     {
        //         name: 'Kevin Bowers',
        //         phone_number: '8304597373',
        //     },
        //     {
        //         name: 'Rasheed Khan',
        //         phone_number: '2102161419',
        //     },
        //     {
        //         name: 'Brian Murphy',
        //         phone_number: '2103836600',
        //     },
        //     {
        //         name: 'Benjie Piamonte',
        //         phone_number: '7577716415',
        //     },
        //     {
        //         name: 'Terry Erben',
        //         phone_number: '2105774248',
        //     },
        //     {
        //         name: 'Christopher Jefferson ',
        //         phone_number: '7157840655',
        //     },
        //     {
        //         name: 'Frank Ferrer Jr',
        //         phone_number: '2107535462',
        //     },
        //     {
        //         name: 'Jesse Rizo',
        //         phone_number: '2106125819',
        //     },
        //     {
        //         name: 'Manny Chau ',
        //         phone_number: '8323177866',
        //     },
        //     {
        //         name: 'Jose Feliciano',
        //         phone_number: '8303916140',
        //     },
        //     {
        //         name: 'Joey Fuentez',
        //         phone_number: '8303889153',
        //     },
        //     {
        //         name: 'Jacob Jimenez ',
        //         phone_number: '4323079948',
        //     },
        //     {
        //         name: 'Destry Ensley ',
        //         phone_number: '2107107093',
        //     },
        //     {
        //         name: 'Christopher Ayers',
        //         phone_number: '9704155640',
        //     },
        //     {
        //         name: 'Rudy Sanchez',
        //         phone_number: '2107693949',
        //     },
        //     {
        //         name: 'Candy Ramos',
        //         phone_number: '2109139192',
        //     },
        //     {
        //         name: 'Steve Espinosa',
        //         phone_number: '8305159455',
        //     },
        //     {
        //         name: 'Gerson Martinez',
        //         phone_number: '8325449968',
        //     },
        //     {
        //         name: 'Mike Rodriguez',
        //         phone_number: '210-689-0373',
        //     },
        //     {
        //         name: 'Chance Eaton',
        //         phone_number: '8303774708',
        //     },
        //     {
        //         name: 'David Gonzalez ',
        //         phone_number: '2108403383',
        //     },
        //     {
        //         name: 'Eric Hall',
        //         phone_number: '2107888998',
        //     },
        //     {
        //         name: 'Fadale Jefferson ',
        //         phone_number: '2622322750',
        //     },
        //     {
        //         name: 'David Evans',
        //         phone_number: '8302216506',
        //     },
        //     {
        //         name: 'Mike Wilson ',
        //         phone_number: '3619476085',
        //     },
        //     {
        //         name: 'Jojo Garcia',
        //         phone_number: '3612281732',
        //     },
        //     {
        //         name: 'Martin Salinas ',
        //         phone_number: '2108239370',
        //     },
        //     {
        //         name: 'Patrick Croft',
        //         phone_number: '4326311965',
        //     },
        //     {
        //         name: 'Vincent Rochefort',
        //         phone_number: '9172976114',
        //     },
        //     {
        //         name: 'Wally Torres ',
        //         phone_number: '2103233711',
        //     },
        //     {
        //         name: 'Ward Kayutak',
        //         phone_number: '5125416387',
        //     },
        //     {
        //         name: 'Ruben Castro',
        //         phone_number: '2105513012',
        //     },
        //     {
        //         name: 'Gilbert Castro',
        //         phone_number: '2105890399',
        //     },
        //     {
        //         name: 'Cody Simko',
        //         phone_number: '9796653022',
        //     },
        //     {
        //         name: 'Joey Gray ',
        //         phone_number: '4055059979',
        //     },
        //     {
        //         name: 'Daniel Schneider',
        //         phone_number: '2108475604',
        //     },
        //     {
        //         name: 'Jeremy Jones',
        //         phone_number: '6822271787',
        //     },
        //     {
        //         name: 'Joey Heinaman',
        //         phone_number: '3613431442',
        //     },
        //     {
        //         name: 'Dannie Stewart',
        //         phone_number: '9162964352',
        //     },
        //     {
        //         name: 'Robert Perez ',
        //         phone_number: '3617650499',
        //     },
        //     {
        //         name: 'Rick Moreno',
        //         phone_number: '2108366776',
        //     },
        //     {
        //         name: 'Matthew Shoemaker',
        //         phone_number: '9548065867',
        //     },
        //     {
        //         name: 'James Davis Jr',
        //         phone_number: '5129609117',
        //     },
        //     {
        //         name: 'Shane Manaole',
        //         phone_number: '2106439533',
        //     },
        //     {
        //         name: 'Wayne Senn',
        //         phone_number: '2105715648',
        //     },
        //     {
        //         name: 'Ruel Serna',
        //         phone_number: '2104137789',
        //     },
        //     {
        //         name: 'Joshua Perez',
        //         phone_number: '8176921345',
        //     },
        //     {
        //         name: 'Kiengchay Phoutthavong',
        //         phone_number: '2142023845',
        //     },
        //     {
        //         name: 'Leonard Vinthaxay',
        //         phone_number: '2103171548',
        //     },
        //     {
        //         name: 'Arthur Sarkis ',
        //         phone_number: '2104329781',
        //     },
        //     {
        //         name: 'Jason Christopher Hunt',
        //         phone_number: '2258281878',
        //     },
        //     {
        //         name: 'Gabriel Martinez',
        //         phone_number: '8308226486',
        //     },
        //     {
        //         name: 'Cristina Schneider',
        //         phone_number: '2108475751',
        //     },
        //     {
        //         name: 'Sophie Lopez',
        //         phone_number: '2102548746',
        //     },
        //     {
        //         name: 'Jarret Newman',
        //         phone_number: '2102591987',
        //     },
        //     {
        //         name: 'Eric Woinicki',
        //         phone_number: '2104497908',
        //     },
        //     {
        //         name: 'Lazaro Martinez Jr.',
        //         phone_number: '8303129731',
        //     },
        //     {
        //         name: 'Gus Moghaddasi',
        //         phone_number: '5129600079',
        //     },
        //     {
        //         name: 'Jesse Ramos',
        //         phone_number: '2108873428',
        //     },
        //     {
        //         name: 'GW McCartney',
        //         phone_number: '2107998789',
        //     },
        //     {
        //         name: 'Kim Pierce',
        //         phone_number: '5127398701',
        //     },
        //     {
        //         name: 'Rick Castillo',
        //         phone_number: '2105488164',
        //     },
        //     {
        //         name: 'Lazaro Martinez III',
        //         phone_number: '8307773184',
        //     },
        //     {
        //         name: 'Justin Espinosa',
        //         phone_number: '5122970739',
        //     },
        //     {
        //         name: 'Jason Harkrider',
        //         phone_number: '5129096210',
        //     },
        //     {
        //         name: 'Gail Eaton',
        //         phone_number: '4076878017',
        //     },
        //     {
        //         name: 'Rasheed Khan',
        //         phone_number: '2103161419',
        //     },
        //     {
        //         name: 'Albert Luna',
        //         phone_number: '2104253400',
        //     },
        //     {
        //         name: 'Eric Reyes',
        //         phone_number: '2106185707',
        //     },
        //     {
        //         name: 'Eric Gauthier',
        //         phone_number: '8326557128',
        //     },
        //     {
        //         name: 'Will Felder',
        //         phone_number: '2819281948',
        //     },
        //     {
        //         name: 'Megan Hardin',
        //         phone_number: '2102904012',
        //     },
        //     {
        //         name: 'Juan Longoria',
        //         phone_number: '2108498032',
        //     },
        //     {
        //         name: 'Tyler Miller',
        //         phone_number: '8327737694',
        //     },
        //     {
        //         name: 'Eddie Cruz',
        //         phone_number: '2106291354',
        //     },
        //     {
        //         name: 'Darrell Newman',
        //         phone_number: '8303911933',
        //     },
        //     {
        //         name: 'Jarrett Newman',
        //         phone_number: '8302161226',
        //     },
        //     {
        //         name: 'Johnny Perez',
        //         phone_number: '8303915665',
        //     },
        //     {
        //         name: 'Andy Jethwa',
        //         phone_number: '8322731779',
        //     },
        //     {
        //         name: 'Kevin Guimond',
        //         phone_number: '4132227904',
        //     },
        //     {
        //         name: 'Jeremiah Perez',
        //         phone_number: '2107051385',
        //     },
        //     {
        //         name: 'Jason Bagby',
        //         phone_number: '8305579731',
        //     },
        //     {
        //         name: 'Roger Senn',
        //         phone_number: '2103679004',
        //     },
        //     {
        //         name: 'Noah Contreras',
        //         phone_number: '5312186531',
        //     },
        //     {
        //         name: 'Justin Geddas',
        //         phone_number: '9314016528',
        //     },
        //     {
        //         name: 'Tito Fernandez',
        //         phone_number: '2108637570',
        //     },
        //     {
        //         name: 'Will Bagley',
        //         phone_number: '7756714678',
        //     },
        //     {
        //         name: 'Roland Vela',
        //         phone_number: '9567152126',
        //     },
        //     {
        //         name: 'Mitch Ports',
        //         phone_number: '2102894335',
        //     },
        //     {
        //         name: 'Justin Howton',
        //         phone_number: '2105176049',
        //     },
        //     {
        //         name: 'Ron Anderson',
        //         phone_number: '2106512134',
        //     },
        //     {
        //         name: 'Scott Aranda',
        //         phone_number: '2109732335',
        //     },
        //     {
        //         name: 'Anthony Abate',
        //         phone_number: '2103268234',
        //     },
        //     {
        //         name: 'Gilbert Villanueva',
        //         phone_number: '2107898487',
        //     },
        //     {
        //         name: 'Robert Clemence',
        //         phone_number: '2102192483',
        //     },
        //     {
        //         name: 'Tim Perez ',
        //         phone_number: '2107393978',
        //     },
        //     {
        //         name: 'William Cerna',
        //         phone_number: '2102757754',
        //     },
        //     {
        //         name: 'Brian Ripoli',
        //         phone_number: '7044580639',
        //     },
        //     {
        //         name: 'Tim Smith',
        //         phone_number: '2108673420',
        //     },
        //     {
        //         name: 'Ronnie Willis',
        //         phone_number: '2104090362',
        //     },
        //     {
        //         name: 'Devin Higgins',
        //         phone_number: '2102379524',
        //     },
        //     {
        //         name: 'Eli Traylor',
        //         phone_number: '8304288126 ',
        //     },
        //     {
        //         name: 'Emiltone Gaelogo',
        //         phone_number: '5122990679',
        //     },
        //     {
        //         name: 'Hank Shaper',
        //         phone_number: '2107017910',
        //     },
        //     {
        //         name: 'Robert Rodriguez',
        //         phone_number: '2105270938',
        //     },
        //     {
        //         name: 'Thomas Hall',
        //         phone_number: '2107271300',
        //     },
        //     {
        //         name: 'Charles Murphy',
        //         phone_number: '2103641313',
        //     },
        //     {
        //         name: 'Che Valdez',
        //         phone_number: '5126440446',
        //     },
        //     {
        //         name: 'Daniel Sanchez',
        //         phone_number: '2104870189',
        //     },
        //     {
        //         name: 'Jason Clarke',
        //         phone_number: '2104523975',
        //     },
        //     {
        //         name: 'Anival Gonzalez',
        //         phone_number: '2102882309',
        //     },
        //     {
        //         name: 'Gibby Martinez',
        //         phone_number: '2108102619',
        //     },
        //     {
        //         name: 'Larry Brevelle',
        //         phone_number: '2103238961',
        //     },
        //     {
        //         name: 'James Berry',
        //         phone_number: '2102943014',
        //     },
        //     {
        //         name: 'George Wilson',
        //         phone_number: '2103671501',
        //     },
        //     {
        //         name: 'Lynn Mascheck',
        //         phone_number: '9567405763',
        //     },
        //     {
        //         name: 'Cesar Sanchez-Medrano',
        //         phone_number: '2109834447',
        //     },
        //     {
        //         name: 'Jeff Aimone',
        //         phone_number: '2107179070',
        //     },
        //     {
        //         name: 'Wayne Richards',
        //         phone_number: '7572025153',
        //     },
        //     {
        //         name: 'Joseph Cannata',
        //         phone_number: '3253706885',
        //     },
        //     {
        //         name: 'Adrian Munos',
        //         phone_number: '2544218459',
        //     },
        //     {
        //         name: 'Ricardo Espinosa',
        //         phone_number: '2547026304',
        //     },
        //     {
        //         name: 'Ariel Aquino ',
        //         phone_number: '2104736303',
        //     },
        //     {
        //         name: 'Jerry Prado',
        //         phone_number: '2102872954',
        //     },
        //     {
        //         name: 'Eric Aicinena',
        //         phone_number: '7022457021',
        //     },
        //     {
        //         name: 'Marc Canchola',
        //         phone_number: '3612188727',
        //     },
        //     {
        //         name: 'Tamara Parlette',
        //         phone_number: '5033805774',
        //     },
        //     {
        //         name: 'Doug Smith',
        //         phone_number: '6282001889',
        //     },
        //     {
        //         name: 'Arturo Garay',
        //         phone_number: '2109784547',
        //     },
        //     {
        //         name: 'Ray Navarro',
        //         phone_number: '2107300920',
        //     },
        // ];
        setDataSource(dataSet);
        // setDataSource(players);
    }

    async function getMatches(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: GET_TOURNAMENT_BRACKET_QUERY,
            variables: {
                tournamentId: tournament.id,
            },
        });
        // 161-176
        let matches = res.data.tournament_brackets.filter((match) => {
            return match.match_number >= 161 && match.match_number <= 176;
        });
        dataSet.push(matches);
        console.log(matches);
        setMatches(matches);
    }

    async function notifyPlayer(player, text) {
        setLoading(true);
        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        // functions.useFunctionsEmulator('http://localhost:5001');

        try {
            const sendSMS = await httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${text}\n${moment().format('llll')}`;
            const result = sendSMS({
                phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                message: messageToSend,
                user_id: player.id,
                tournament_id: tournament.id,
                // token: ''
            });

            setLoading(false);
            // setSentMessages(sentMessages + 1);
            message.success(`Notification sent to ${player.name}`);
            return true;
        } catch (e) {
            setLoading(false);
            console.log(e);
            message.error(`Notification error: ${JSON.stringify(e)}`);
            // setSentMessages(sentMessages - 1);
            return false;
        }
    }

    function notifyAllPlayers() {
        setDisabled(true);
        dataSource.map(async (player, index) => {
            const firstName = player.name.split(' ')[0];
            const lastName = player.name.split(' ')[1];

            // console.log({
            // 	firstName,
            // 	lastName
            // });
            // const textMessage = `Due to time constraints we are going to schedule your match for 9:30am tomorrow. Doors open at 9am. See you then!`;
            // const textMessage = `${firstName}, thank you for playing the Michael Montgomery Memorial tournament at Snookered Frisco! If you enjoyed the event, we would love it if you would give us a review on Google - https://g.page/snookeredfrisco/review?gm`;
            // const textMessage = `${firstName}, thank you for playing the Michael Montgomery Memorial tournament at Snookered Frisco! If you enjoyed the event, we would love it if you would give us a review on Google - https://g.page/snookeredfrisco/review?gm`;
            if (player.phone_number) {
                notifyPlayer(player, textMessage);
            }
        });

        // message.success(`${sentMessages} messages were successfully sent.`);
    }

    function notifyWinnerSidePlayers() {
        const players = [];
        matches.map((match, index) => {
            const player1 = dataSource.filter((player) => {
                return player.name === match.challenger1_name;
            })[0];
            const player2 = dataSource.filter((player) => {
                return player.name === match.challenger2_name;
            })[0];

            players.push({
                name: player1.name.split(' ')[0],
                phone_number: parseInt(player1.phone_number),
            });

            players.push({
                name: player2.name.split(' ')[0],
                phone_number: parseInt(player2.phone_number),
            });
        });

        // const textMessage = `Due to time constraints we are going to schedule your match for 9:30am tomorrow. Doors open at 9am. See you then!`;
        let sent = 0;
        players.map(async (player) => {
            const success = await notifyPlayer(player, textMessage);
            if (success === true) {
                sent = sent + 1;
            }
        });

        message.success(`${sent} messages were successfully sent.`);
    }

    function confirm(e) {
        console.log(e);
        message.success('Click on Yes');
    }

    function cancel(e) {
        console.log(e);
        message.error('Click on No');
    }

    useEffect(() => {
        getPlayers();
        getMatches();
    }, []);

    function onChange(e) {
        const { value } = e.target;
        setTextMessage(value);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Notify Players"
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="settings" {...props} />
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Alert
                            message="Note: Please use your best judgement when using this feature."
                            description="This feature will notify all players in the tournament via SMS message who have a valid phone number. Please use this feature ONLY if you think it is absolutely necessary to send everyone a message. Thank you!"
                            type="warning"
                            closable
                            showIcon
                        />
                    </Col>
                    <Col xs={24}>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Player List ({dataSource && dataSource.length})
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Popconfirm
                                            title="Are you sure to send this notification to all players?"
                                            onConfirm={notifyAllPlayers}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button disabled={disabled} type="primary">
                                                Send Notification
                                            </Button>
                                        </Popconfirm>
                                        ,
                                    </div>
                                </div>
                            }
                            bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <React.Fragment>
                                <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                                    <TextArea rows={4} allowClear onChange={onChange} placeholder="Enter your text message here" />
                                </div>
                                <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                                    <Text>Message length: {textMessage ? textMessage.length : 0} characters</Text>
                                    {/* <br />
									<Text>
										Message parts:{' '}
										{Math.round(
											textMessage && textMessage.length && textMessage.length > 0
												? textMessage.length / 160
												: 0
										)}{' '}
										messages
									</Text> */}
                                </div>

                                <ul>
                                    {dataSource &&
                                        dataSource.map((player, index) => {
                                            return (
                                                <li key={index}>
                                                    <div>
                                                        {player.name} (${player.country_phone || '+1'}
                                                        {player.phone_number})
                                                    </div>
                                                </li>
                                            );
                                        })}
                                </ul>
                                {/* 								
								<ul>
									{matches &&
										matches.map((match, index) => {
											return (
												<li key={index}>
													<div>Match: {match.identifier}</div>
													<div>Player 1: {match.challenger1_name}</div>
													<div>Player 2: {match.challenger2_name}</div>
												</li>
											);
										})}
								</ul>
								<Button type="primary" onClick={notifyWinnerSidePlayers}>
									Notify winner side players only
								</Button> */}
                            </React.Fragment>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(NotifyAllPlayers);
