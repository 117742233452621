import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Table, Button, Upload, Icon, Badge, Typography } from 'antd';
import { GET_USER_MATCHES_QUERY2 } from '../data/queries';
import { UPDATE_TOURNAMENT_PLAYER_MUTATION } from '../data/mutations';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

export default function UnlinkedMatchesModal(props) {
    const { onModalOk, onModalCancel, onLinked, matches, playerId, modalVisible } = props;
    const [loading, setLoading] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const formRef = useRef();

    function cancelSubmit(e) {
        onModalCancel();
    }

    const rowSelection = {
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    function onSelectChange(rowKeys, rows) {
        // console.log(`selectedRowKeys: ${rowKeys}`, 'selectedRows: ', rows);
        setSelectedKeys(rowKeys);
        setSelectedRows(rows);
        // onSelected(selectedRowKeys, selectedRows);
    }

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function selectRow(record) {
        const selectedRowKeys = [...selectedRows];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        setSelectedKeys(selectedRowKeys);
    }

    function linkMatches(rows, keys) {
        console.log(rows);
        let playersToUpdate =
            rows &&
            rows.map((row) => {
                return {
                    id: row.player.id,
                    user_id: row.player.user_id,
                    player_id: row.player.player_id,
                    tournament_id: row.tournament.id,
                };
            });

        console.log(playersToUpdate);
        setLoading(true);
        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                if (nextPlayer.id) {
                    return updatePlayer(nextPlayer);
                } else {
                    message.error('Player ID not found.');
                    return;
                }
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success(`${playersToUpdate.length} matches linked!`);
            console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
            setLoading(false);
            setSelectedKeys([]);
            setSelectedRows([]);
            onLinked();
        });
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: player.tournament_id,
                    changes: {
                        user_id: player.user_id,
                        player_id: player.player_id,
                    },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_USER_MATCHES_QUERY2,
                        variables: { player_id: player.player_id },
                    },
                ],
            })
            .then((data) => {
                // console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                message.error('There was an error', error);
            });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            {record.player && record.player.name} vs. {record.opponent && record.opponent.name}
                            {/* {record.player.skill_level && record.opponent.skill_level ? (
                                <React.Fragment>
                                    {record.player.name} ({record.player.skill_level}) vs. {record.opponent.name} ({record.opponent.skill_level})
                                </React.Fragment>
                            ) : record.player.skill_level && !record.opponent.skill_level ? (
                                <React.Fragment>
                                    {record.player.name} ({record.player.skill_level}) vs. {record.opponent.name}
                                </React.Fragment>
                            ) : !record.player.skill_level && record.opponent.skill_level ? (
                                <React.Fragment>
                                    {record.player.name} vs. {record.opponent.name} ({record.opponent.skill_level})
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {record.player.name} vs. {record.opponent.name}
                                </React.Fragment>
                            )} */}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            <b>
                                {record.player && record.player.score} - {record.opponent && record.opponent.score}
                            </b>
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            sorter: (a, b) => a.result - b.result,
            render: (text, record) => {
                return (
                    <div>
                        <Text style={{ color: record.result === 'Win' ? 'green' : 'red' }}>
                            {record.result === 'Win' ? (
                                <React.Fragment>
                                    <Badge
                                        count="W"
                                        style={{
                                            backgroundColor: '#87d068',
                                            marginRight: 10,
                                        }}
                                    />
                                    Win
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Badge
                                        count={record.player && record.player.is_forfeit ? 'FF' : 'L'}
                                        style={{
                                            backgroundColor: '#f5222d',
                                            marginRight: 10,
                                        }}
                                    />
                                    Loss
                                </React.Fragment>
                            )}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Event',
            dataIndex: 'tournament_name',
            key: 'tournament_name',
            sorter: (a, b) => a.tournament_name - b.tournament_name,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => a.created_at - b.created_at,
            render: (text, record) => <div style={{ whiteSpace: 'nowrap' }}>{moment(text).format('ll') || '-'}</div>,
        },
    ];

    return (
        <Modal
            title="Review unlinked matches"
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            transitionName="fade"
            maskTransitionName="none"
            footer={[
                <Button key="cancel" onClick={cancelSubmit}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        linkMatches(selectedRows, selectedKeys);
                    }}
                    disabled={selectedKeys.length > 0 ? false : true}
                >
                    {loading === true ? 'Linking...' : `Link ${selectedKeys.length} matches`}
                </Button>,
            ]}
            destroyOnClose={true}
        >
            {matches && (
                <Table
                    className="player-table noselect"
                    rowSelection={rowSelection}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                // selectRow(record);
                                // onEditSelected(record.id);
                                // setSelected()
                                // console.log(record, rowIndex);
                                // props.history.push({
                                // 	pathname: `/tournament-builder/${props.tournament.slug}/players/${record.name}`,
                                // 	// search: '?query=abc',
                                // 	state: {
                                // 		data: record,
                                // 		backPath: `/tournament-builder/${props.tournament.slug}/players`
                                // 	}
                                // });
                            },
                            onDoubleClick: (event) => {}, // double click row
                            onContextMenu: (event) => {}, // right button click row
                            onMouseEnter: (event) => {}, // mouse enter row
                            onMouseLeave: (event) => {}, // mouse leave row
                        };
                    }}
                    columns={columns}
                    dataSource={matches.map((match, index) => {
                        return {
                            key: index,
                            ...match,
                        };
                    })}
                    onChange={onChange}
                    pagination={false}
                    tableLayout="auto"
                    // className="noselect"
                    scroll={{ x: 400 }}
                    // pagination={{ pageSize: 25 }}
                    size="small"
                    // bordered
                    style={{ maxHeight: 600, overflowY: 'auto' }}
                />
            )}
        </Modal>
    );
}
