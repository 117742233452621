import React, { useState, useEffect } from 'react';
import { PageHeader, Icon, Row, Col, Tabs, Button, Typography } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Card from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import DivisionTable from './DivisionTable';
import { Query } from '@apollo/client/react/components';
import blueBg from '../../../assets/img/blue-bg.png';
import DPTournamentStats from '../../digitalpool/tournaments/DPTournamentStats';
import DPDivisionOverview from '../../digitalpool/leagues/DPDivisionOverview';
import DPDivisionStandings from '../../digitalpool/leagues/DPDivisionStandings';
import DPDivisionScores from '../../digitalpool/leagues/DPDivisionScores';
import DPDivisionPlayers from '../../digitalpool/leagues/DPDivisionPlayers';
import DPDivisionTeams from '../../digitalpool/leagues/DPDivisionTeams';
import DPDivisionSchedule from '../../digitalpool/leagues/DPDivisionSchedule';
import _ from 'lodash';
import { gql } from '@apollo/client';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        padding: 0,
    },
    headerImg: {
        height: 150,
        overflow: 'hidden',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

export const GET_LEAGUE_DIVISION_QUERY = gql`
    query league_divisions($leagueId: Int, $divisionSlug: String) {
        league_divisions(where: { league_id: { _eq: $leagueId }, slug: { _eq: $divisionSlug } }) {
            id
            name
            slug
            description
            division_type
            game_types
            # division_game_types {
            # 	game_type
            # }
            session_id
            num_weeks
            season
            week
            start_date
            end_date
            is_public
            is_active
            is_handicapped
            league {
                id
                name
                slug
                short_description
                long_description
                avatar
                logo
                is_public
                owner {
                    id
                    first_name
                    last_name
                    email
                }
                operator {
                    id
                    first_name
                    last_name
                    email
                }
                address1
                address2
                city
                county
                country
                region
                postal_code
                phone_number
                fax_number
                email_address
                website
                social_facebook
                social_instagram
                social_twitter
                created_at
                updated_at
                match_notifications_in_app
                match_notifications_sms
            }
            league_player_divisions {
                id
            }
            league_team_divisions {
                id
                league_team {
                    id
                    name
                    slug
                }
            }
        }
    }
`;

function DPLDivisionDetail(props) {
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const division = params.division;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['overview', 'schedule', 'teams', 'players', 'scores', 'results', 'stats'];
    const [current, setCurrent] = useState();

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : 'overview';

        setCurrent(pathMatch);
        // console.log(pathMatch);
    }, [urlPath, dpIncludes, splitPath, params]);

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/dpl/divisions/${division}/${tab}`);
    };

    return (
        <React.Fragment>
            <Query
                query={GET_LEAGUE_DIVISION_QUERY}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ leagueId: 1, divisionSlug: props.match.params.division }}
                onCompleted={async (data) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                    if (data && !data.league_divisions) {
                        return <div>Division not found.</div>;
                    }
                    if (data && data.league_divisions.length === 0) {
                        return <div>Division not found.</div>;
                    }

                    const divisions = data.league_divisions
                        .map((item, index) => {
                            return {
                                ...item,
                                key: index,
                                is_public: item.is_public === true ? 'Public' : 'Private',
                                is_active: item.is_active === true ? 'Active' : 'Inactive',
                                is_handicapped: item.is_handicapped === true ? 'Yes' : 'No',
                                division_type: _.startCase(_.toLower(item.division_type.replace('_', ' '))),
                                game_types: item.game_types.map((item) => _.startCase(_.toLower(item.game_type.replace('_', ' ')))).join(', '),
                            };
                        })
                        .map((division, index) => {
                            return division;
                        });
                    const division = divisions[0];
                    const league = division.league;

                    return (
                        <React.Fragment>
                            <PageHeader
                                onBack={() => props.history.push(`/dpl/divisions`)}
                                backIcon={<Icon type="arrow-left" style={{ color: props.theme === 'dark' ? '#fff' : '#000' }} />}
                                title={<Text style={{ color: props.theme === 'dark' ? '#fff' : '#000' }}>{division.name.toUpperCase()}</Text>}
                                // subTitle={<Tag color="red">LIVE</Tag>}
                                extra={[
                                    <Button
                                        key="setting"
                                        onClick={() => {
                                            props.history.push(`/league-manager/digital-pool-league/divisions/${division.slug}/settings`);
                                        }}
                                    >
                                        <Icon type="setting" /> Settings
                                    </Button>,
                                    <Button key="join" type="primary" style={{ marginLeft: 10 }}>
                                        Join Division
                                    </Button>,
                                ]}
                                style={{
                                    background: props.theme === 'dark' ? 'linear-gradient(180deg, #07101f, #14243b)' : 'linear-gradient(#fff, #fafafa)',
                                    // background: props.theme === 'dark' ? '#000' : 'linear-gradient(180deg, #fff, #f0f2f5)',
                                    // borderBottom: '1px solid #e0e3ea',
                                    paddingTop: 24,
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingBottom: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />

                            <Tabs
                                animated={{ inkBar: true, tabPane: false }}
                                defaultActiveKey={current}
                                activeKey={current}
                                onTabClick={handleTabClick}
                                // type="card"
                                tabBarStyle={{
                                    textTransform: 'uppercase',
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 0,
                                    backgroundColor: props.theme === 'dark' ? '#132339' : '#fafafa',
                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                    color: props.theme === 'dark' ? '#fff' : '#000',
                                    zIndex: 1,
                                    position: 'relative',
                                    borderBottom: 'none',
                                    borderTop: '1px solid rgba(255,255,255,0.1)',
                                }}
                            >
                                <TabPane tab="Overview" key="overview">
                                    <DPDivisionOverview league={league} division={division} />
                                </TabPane>
                                <TabPane tab="Schedule" key="schedule">
                                    <DPDivisionSchedule league={league} division={division} />
                                </TabPane>
                                <TabPane tab="Teams" key="teams">
                                    <DPDivisionTeams league={league} division={division} />
                                </TabPane>
                                <TabPane tab="Players" key="players">
                                    <DPDivisionPlayers league={league} division={division} />
                                </TabPane>
                                <TabPane tab="Live Scores" key="scores">
                                    <DPDivisionScores league={league} division={division} />
                                </TabPane>
                                <TabPane tab="Standings" key="results">
                                    <DPDivisionStandings league={league} division={division} />
                                </TabPane>
                                <TabPane tab="Stats" key="stats">
                                    <DPTournamentStats />
                                </TabPane>
                            </Tabs>
                        </React.Fragment>
                    );
                }}
            </Query>
        </React.Fragment>
    );
}

export default withRouter(DPLDivisionDetail);
