import React, { useState, useEffect } from 'react';
import { Icon, Badge, Row, Col, List, Typography, Button, Radio, Input, Tabs, Empty, Modal, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_TABLES_QUERY } from './data/queries';
import { GET_TOURNAMENT_TABLES_SUBSCRIPTION, GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION } from './data/subscriptions';
import CircularLoader from '../../components/CircularLoader';
import TournamentProgressBar from './TournamentProgressBar';
import ZeroState from '../../components/ZeroState';
import TBManageButtons from './TBManageButtons';
import AddTableModal from './AddTableModal';
import TablesInUse from './TablesInUse';
import TablesAvailable from './TablesAvailable';

const { Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        // paddingTop: 10
    },
    selectedToggle: {
        color: '#000',
        border: '1px solid #fff',
        boxShadow: 'none',
    },
    defaultToggle: {
        color: '#fff',
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        boxShadow: 'none',
    },
};

export default function TBTables(props) {
    const { tournament, theme } = props;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [modalScoreVisible, setScoreModalVisible] = useState(false);
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [selectedTableId, setSelectedTableId] = useState();
    const [tables, setTables] = useState([]);
    const [defaultTab, setDefaultTab] = useState('single');

    useEffect(() => {
        return function cleanup() {
            if (drawerVisible === true) {
                setDrawerVisible(false);
            }
        };
    });

    function showDrawer(match) {
        setCurrentMatch(match);
        setDrawerVisible(true);
        window.scrollTo(0, 0);
    }

    function onClose() {
        setDrawerVisible(false);
    }

    function showScoreModal(match) {
        console.log(match);
        setCurrentMatch(match);
        setScoreModalVisible(true);
        setDefaultTab('single');
    }

    function showTableModal() {
        setSelectedTableId();
        setCurrentMatch();
        setTableModalVisible(true);
        setDefaultTab('single');
    }

    function handleScoreModalOk(e) {
        console.log(e);
        setScoreModalVisible(false);
    }

    function handleScoreModalCancel(e) {
        setScoreModalVisible(false);
    }

    function handleTableModalOk(e) {
        console.log(e);
        setTableModalVisible(false);
    }

    function handleTableModalCancel(e) {
        setTableModalVisible(false);
    }

    function callback(key) {
        // console.log(key);
    }

    return (
        <SectionWrapper className="tables">
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                {/* <Radio.Group
					value={current}
					size="large"
					type="ghost"
					onChange={(e) => {
						setCurrent(e.target.value);
					}}
					style={{ marginRight: 10 }}
				>
					<Radio.Button
						value="grid"
						style={current === 'grid' ? styles.selectedToggle : styles.defaultToggle}
					>
						<Icon type="appstore" />
					</Radio.Button>
					<Radio.Button
						value="list"
						style={current === 'list' ? styles.selectedToggle : styles.defaultToggle}
					>
						<Icon type="unordered-list" />
					</Radio.Button>
				</Radio.Group> */}
                <TBManageButtons page="tables" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="20px 40px">
                <Tabs
                    // type="card"
                    animated={{ inkBar: true, tabPane: false }}
                    onChange={callback}
                    defaultActiveKey="all"
                    tabBarStyle={{
                        textTransform: 'uppercase',
                        // margin: 0,
                        borderBottom: theme && theme.name === 'light' && '1px solid #ddd',
                    }}
                >
                    <TabPane tab="All Tables" key="all" style={styles.tab}>
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <Subscription
                                    subscription={GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ tournament_id: tournament.id }}
                                    onSubscriptionData={(data, error, loading) => {
                                        console.log(data);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading) return <CircularLoader />;
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                        if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                            return (
                                                <Row gutter={24} justify="center" type="flex">
                                                    <Col xs={24}>
                                                        <Card>
                                                            <ZeroState showImage message="No tables added yet." style={{ margin: 30 }} {...props}>
                                                                <Button type="primary" onClick={showTableModal}>
                                                                    Add tables
                                                                </Button>
                                                            </ZeroState>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            );
                                        }

                                        return (
                                            <TablesAvailable
                                                {...props}
                                                data={data.pool_tables.sort((a, b) => {
                                                    return a.label.localeCompare(b.label, undefined, {
                                                        numeric: true,
                                                        sensitivity: 'base',
                                                    });
                                                })}
                                                tableModalVisible={tableModalVisible}
                                                showScoreModal={showScoreModal}
                                                setTableModalVisible={setTableModalVisible}
                                                onShowTableModal={showTableModal}
                                                onTableEdit={(tableId) => {
                                                    setSelectedTableId(tableId);
                                                    setTableModalVisible(true);
                                                }}
                                                onTableSelected={(tableSlug) => {
                                                    // setSelectedTableId(tableSlug);
                                                    props.history.push(`/tournament-builder/${tournament.slug}/tables/${tableSlug}`);
                                                }}
                                            />
                                        );
                                    }}
                                </Subscription>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="In Use" key="in-use" style={styles.tab}>
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <Subscription
                                    subscription={GET_TOURNAMENT_TABLES_SUBSCRIPTION}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ tournament_id: tournament.id, status: 'IN_USE' }}
                                    onSubscriptionData={(data, error, loading) => {
                                        if (data && data.subscriptionData && data.subscriptionData.data) {
                                            setTables(data.subscriptionData.data);
                                        }
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading) return <CircularLoader />;
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                        if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                            return (
                                                <Row gutter={24} justify="center" type="flex">
                                                    <Col xs={24}>
                                                        <Card>
                                                            <ZeroState showImage message="No tables currently in use." style={{ margin: 30 }} {...props} />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            );
                                        }

                                        return (
                                            <TablesInUse
                                                {...props}
                                                data={data.pool_tables.sort((a, b) => {
                                                    return a.label.localeCompare(b.label, undefined, {
                                                        numeric: true,
                                                        sensitivity: 'base',
                                                    });
                                                })}
                                                tableModalVisible={tableModalVisible}
                                                showScoreModal={showScoreModal}
                                                setTableModalVisible={setTableModalVisible}
                                                onTableEdit={(tableId) => {
                                                    setSelectedTableId(tableId);
                                                    setTableModalVisible(true);
                                                }}
                                                onTableSelected={(tableSlug) => {
                                                    // setSelectedTableId(tableId);
                                                    props.history.push(`/tournament-builder/${tournament.slug}/tables/${tableSlug}`);
                                                }}
                                            />
                                        );
                                    }}
                                </Subscription>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>

                <Drawer
                    title={currentMatch && currentMatch.title}
                    // getContainer={() => document.querySelector('#scores')}
                    // mask={false}
                    // placement="bottom"
                    width={400}
                    closable
                    onClose={onClose}
                    visible={drawerVisible}
                >
                    <p>Status: {currentMatch && currentMatch.status}</p>
                </Drawer>
                <Modal
                    title="Matches played on this table"
                    // getContainer={() => document.querySelector('.tables')}
                    visible={modalScoreVisible}
                    onOk={handleScoreModalOk}
                    onCancel={handleScoreModalCancel}
                    centered
                    transitionName="fade"
                    bodyStyle={{ padding: 0 }}
                    // transitionName="none"
                    maskTransitionName="none"
                    footer={[
                        // <Button key="cancel" onClick={handleScoreModalCancel}>
                        // 	Cancel
                        // </Button>,
                        <Button key="submit" type="primary" onClick={handleScoreModalOk}>
                            OK
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <List
                            size="small"
                            loading={false}
                            itemLayout="horizontal"
                            dataSource={
                                tables &&
                                tables.pool_tables &&
                                tables.pool_tables
                                    .filter((table) => {
                                        return table.id === selectedTableId;
                                    })
                                    .map((table, index) => {
                                        return {
                                            matches: table.tournament_match_table,
                                        };
                                    })
                            }
                            header={
                                <div
                                    style={{
                                        padding: '0px 20px',
                                        margin: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Text style={{ fontWeight: 700, width: 50 }}>Round</Text>
                                        <Text style={{ fontWeight: 700, marginLeft: 10 }}>Players</Text>
                                    </div>
                                    <Text style={{ fontWeight: 700 }}>Score</Text>
                                </div>
                            }
                            renderItem={(table) => {
                                return (
                                    <React.Fragment>
                                        {table &&
                                            table.matches &&
                                            table.matches.map((match, index) => (
                                                <List.Item
                                                    key={index}
                                                    style={{
                                                        padding: '10px 20px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Text style={{ width: 60 }}>{match.identifier}</Text>
                                                    <div style={{ textAlign: 'left', width: '100%' }}>
                                                        <Text
                                                            style={{
                                                                marginLeft: 0,
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {match.challenger1_score > match.challenger2_score ? (
                                                                <Badge
                                                                    count="W"
                                                                    style={{
                                                                        backgroundColor: '#52c41a',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            ) : match.challenger1_score < match.challenger2_score ? (
                                                                <Badge count="L" style={{ marginRight: 5 }} />
                                                            ) : null}
                                                            {match.challenger1_name} {match.challenger1_race_to ? `(${match.challenger1_race_to})` : null}
                                                        </Text>
                                                        {' vs. '}
                                                        <Text
                                                            style={{
                                                                marginLeft: 0,
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {match.challenger2_score > match.challenger1_score ? (
                                                                <Badge
                                                                    count="W"
                                                                    style={{
                                                                        backgroundColor: '#52c41a',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            ) : match.challenger2_score < match.challenger1_score ? (
                                                                <Badge count="L" style={{ marginRight: 5 }} />
                                                            ) : null}
                                                            {match.challenger2_name} {match.challenger2_race_to ? `(${match.challenger2_race_to})` : null}
                                                        </Text>
                                                    </div>

                                                    <Text style={{ whiteSpace: 'nowrap', width: 40 }}>
                                                        {match.challenger1_score}-{match.challenger2_score}
                                                    </Text>
                                                </List.Item>
                                            ))}
                                    </React.Fragment>
                                );
                            }}
                        />
                    </React.Fragment>
                </Modal>

                <AddTableModal
                    {...props}
                    tournament={tournament}
                    selectedTableId={selectedTableId}
                    defaultTab={defaultTab}
                    tableModalVisible={tableModalVisible}
                    onTableModalOk={handleTableModalOk}
                    onTableModalCancel={handleTableModalCancel}
                />
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
