import React from 'react';
import Loadable from 'react-loadable';
import { Typography } from 'antd';

const { Text } = Typography;

const styles = {
    container: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        height: '100%',
        // height: '100vh',
        // position: 'absolute',
        // width: '100%',
        // height: '100%',
        // top: 0,
        // left: 0,
        // right: 0,
        // bottom: 0,
        // zIndex: 10
    },
};

function Loading(props) {
    if (props.error) {
        return (
            <div style={styles.container}>
                <Text>
                    Error loading page! <button onClick={props.retry}>Retry</button>
                </Text>
            </div>
        );
    } else if (props.timedOut) {
        return (
            <div style={styles.container}>
                <Text>
                    Taking a long time to load... <button onClick={props.retry}>Retry</button>
                </Text>
            </div>
        );
    } else if (props.pastDelay) {
        return (
            <div style={styles.container}>
                {/*<CircularLoader />*/}
                <Text>Loading...</Text>
            </div>
        );
    } else {
        return null;
    }
}

const LoadableComponent = (opts) =>
    Loadable({
        loading: Loading,
        delay: 300,
        // render(loaded, props) {
        //     console.log('props', props);
        //     let Component = loaded.default;
        //     return <Component {...props} />;
        // },
        ...opts,
    });

export default LoadableComponent;
