import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon, Typography } from 'antd';
import { Query } from '@apollo/client/react/components';
import { GET_USER_GHOST_EVENTS_QUERY, GET_GHOST_EVENT_QUERY } from './data/queries';
import _ from 'lodash';
import CircularLoader from '../../components/CircularLoader';

const { Text } = Typography;

const styles = {
    menuItemGroup: {
        marginTop: 10,
        marginLeft: 10,
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 600,
        color: 'rgba(255,255,255,0.4)',
    },
    titleText: {
        color: '#fff',
        padding: '15px 20px',
    },
};

function GGNav(props) {
    const { match, authState } = props;
    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const tbIncludes = ['dashboard', 'settings', 'schedule', 'bracket', 'scores', 'results', 'matches', 'tables', 'stats', 'players', 'payouts', 'venues'];

    let pathMatch =
        splitPath[splitPath.length] === match.params.slug
            ? splitPath[splitPath.length - 1]
            : _.includes(tbIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(tbIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(tbIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : 'ghost-event-manager';

    const [theme, setTheme] = useState('dark');
    const [current, setCurrent] = useState(pathMatch);

    useEffect(() => {
        setCurrent(pathMatch);
    }, [pathMatch]);

    const handleClick = (e) => {
        // console.log(e.key);
        if (e.key === 'back') {
            setCurrent('ghost-event-manager');
            props.history.push('/ghost-event-manager');
        } else if (e.key === 'all') {
            props.history.push('/ghost-event-manager');
        } else {
            props.onClicked();
            // setTimeout(() => {
            // 	props.onClicked();
            // }, 500);
            const url =
                e.key === 'ghost-event-manager'
                    ? '/ghost-event-manager'
                    : _.includes(tbIncludes, e.key)
                    ? `/ghost-event-manager/${match.params.slug}/${e.key}`
                    : e.key === 'new'
                    ? `/ghost-event-manager/new`
                    : `/ghost-event-manager/${e.key}/dashboard`;
            // if (_.includes(tbIncludes, e.key)) {
            // 	setTimeout(() => {
            // 		props.onClicked();
            // 	}, 300);
            // }
            props.history.push(url);
        }
    };

    return (
        <React.Fragment>
            {match.params.slug ? (
                <Query
                    query={GET_GHOST_EVENT_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: match.params.slug }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                        const ghost_game = data.ghost_games[0];

                        return (
                            <React.Fragment>
                                {(authState && authState.user && authState.user.role === 'admin') ||
                                (authState &&
                                    authState.user &&
                                    ((ghost_game && ghost_game.manager_id && ghost_game.manager_id === authState.user.id) ||
                                        (ghost_game && ghost_game.manager_id && ghost_game.managet_id === authState.user.id))) ||
                                ghost_game.id === 1 ? (
                                    <Menu
                                        theme={theme}
                                        onClick={handleClick}
                                        // style={{ width: 256 }}
                                        // defaultOpenKeys={[ 'sub1' ]}
                                        defaultSelectedKeys={['dashboard']}
                                        selectedKeys={[current]}
                                        mode="inline"
                                    >
                                        <Menu.Item key="back">
                                            <Icon type="arrow-left" /> Back Home
                                        </Menu.Item>
                                        <Menu.Divider style={{ backgroundColor: '#324454' }} />
                                        <Menu.ItemGroup key="ghost_game" title={<div style={styles.menuItemGroup}>{ghost_game && ghost_game.name}</div>}>
                                            <Menu.Item key="dashboard">
                                                <Icon type="dashboard" /> Dashboard
                                            </Menu.Item>
                                            <Menu.Item key="players">
                                                <Icon type="user" /> Players
                                            </Menu.Item>
                                            <Menu.Item key="payouts">
                                                <Icon type="dollar" /> Payouts
                                            </Menu.Item>
                                            <Menu.Item key="results">
                                                <Icon type="trophy" /> Results
                                            </Menu.Item>
                                            <Menu.Item key="stats">
                                                <Icon type="bar-chart" /> Stats
                                            </Menu.Item>
                                            <Menu.Item key="settings">
                                                <Icon type="setting" /> Settings
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </Menu>
                                ) : (
                                    <Menu theme={theme} onClick={handleClick} mode="inline">
                                        <Menu.Item key="back">
                                            <Icon type="arrow-left" /> Back Home
                                        </Menu.Item>
                                    </Menu>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Query>
            ) : (
                <div>
                    <Menu
                        theme={theme}
                        onClick={handleClick}
                        // style={{ width: 256 }}
                        // defaultOpenKeys={[ 'sub1' ]}
                        selectedKeys={[current]}
                        mode="inline"
                    >
                        <Menu.Item key="ghost-game-manager">
                            <Icon type="home" /> Home
                        </Menu.Item>

                        <Menu.Item key="new">
                            <Icon type="plus" /> Create ghost game
                        </Menu.Item>

                        <Menu.Divider style={{ backgroundColor: '#324454' }} />
                        {/* <Menu.Item key="1">
							<Link to="/tournament-builder/tournament-1">
								<Icon type="trophy" /> Tournament 1
							</Link>
						</Menu.Item> */}
                    </Menu>
                    <Query
                        query={GET_USER_GHOST_EVENTS_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{
                            manager_id: props.authState.user.id,
                            limit: 5,
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <div style={styles.titleText}>Loading...</div>;
                            if (error) return <div style={styles.titleText}>Error: {error.message}</div>;
                            if (data && !data.ghost_games) {
                                return <div style={styles.titleText}>No ghost games found.</div>;
                            }

                            return (
                                <Menu
                                    theme={theme}
                                    onClick={handleClick}
                                    // style={{ width: 256 }}
                                    // defaultOpenKeys={[ 'sub1' ]}
                                    selectedKeys={[current]}
                                    mode="inline"
                                >
                                    <Menu.ItemGroup key="ghost-events" title={<div style={styles.menuItemGroup}>My recent ghost games</div>}>
                                        {data.ghost_games.length > 0 ? (
                                            data.ghost_games.map((ghost_game, index) => (
                                                <Menu.Item key={ghost_game.slug}>
                                                    <Icon type="trophy" /> {ghost_game.name}
                                                </Menu.Item>
                                            ))
                                        ) : (
                                            <div style={{ marginTop: 16, marginLeft: 24 }}>None yet</div>
                                        )}
                                    </Menu.ItemGroup>
                                </Menu>
                            );
                        }}
                    </Query>
                </div>
            )}
        </React.Fragment>
    );
}

export default withRouter(GGNav);
