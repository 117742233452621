import { split, HttpLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, InMemoryCache, ApolloProvider, persistCache, gql } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

// function GlobalLoadingIndicator() {
//     const status = useApolloNetworkStatus();

//     if (status.numPendingQueries > 0) {
//         return (
//             <div
//                 style={{
//                     position: 'absolute',
//                     top: 20,
//                     left: 65,
//                     zIndex: 100,
//                 }}
//             >
//                 <CircularLoader />
//             </div>
//         );
//     } else {
//         return null;
//     }
// }

export default function Client({ authState }) {
    console.log(authState);
    const isIn = authState.status === 'in';

    const headers = isIn
        ? {
              Authorization: `Bearer ${authState.token}`,
              'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
          }
        : {
              'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
          };

    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_HTTPS_URL,
        headers,
    });

    // const wsLink = new WebSocketLink({
    //     uri: process.env.REACT_APP_GRAPHQL_WSS_URL,
    //     options: {
    //         reconnect: true,
    //         lazy: true,
    //         timeout: 30000,
    //         inactivityTimeout: 30000,
    //         connectionParams: {
    //             headers,
    //         },
    //     },
    // });

    const wsLink = new WebSocketLink({
        uri: process.env.REACT_APP_GRAPHQL_WSS_URL,
        options: {
            reconnect: true,
            lazy: true,
            // timeout: 30000,
            inactivityTimeout: 3000,
            connectionParams: {
                headers,
            },
        },
    });

    // wsLink.onConnected(() => {
    //     // message.success('Websocket connected');
    //     console.warn('websocket connected');
    // });
    // wsLink.onDisconnected(() => {
    //     // message.warning('Websocket disconnected');
    //     console.warn('websocket disconnected');
    // });
    // wsLink.onReconnecting(() => {
    //     // message.loading('Websocket reconnecting');
    //     console.warn('websocket reconnecting...');
    // });
    // wsLink.onReconnected(() => {
    //     // message.success('Websocket reconnected');
    //     console.warn('websocket reconnected');
    // });
    // wsLink.onError(() => {
    //     // message.error('Websocket error');
    //     console.warn('websocket error');
    // });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    // const errorLink = onError(({ graphQLErrors, networkError }) => {
    //     if (graphQLErrors) graphQLErrors.map((error) => console.log(`[GraphQL error]: `, error));
    //     if (networkError) console.log(`[Network error]: `, networkError);
    //     // if (graphQLErrors)
    //     // 	graphQLErrors.forEach((error) => {
    //     // 		const { message, locations, path } = error;
    //     // 		console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, error);
    //     // 	});

    //     // if (networkError) console.log(`[Network error]: ${networkError}`, networkError);
    // });

    const combinedLink = split(
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        httpLink
    );
    // const cache = new InMemoryCache({
    // 	dataIdFromObject: (object) => object.id
    // });
    // persistCache({
    // 	cache,
    // 	storage: localStorage,
    // 	debug: true,
    // 	maxSize: false
    // });

    const client = new ApolloClient({
        link: link.concat(errorLink.concat(combinedLink)),
        cache: new InMemoryCache(),
        // onError: (e) => {
        // 	console.log(e.graphQLErrors);
        // }
    });

    return client;
}
