import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import Slider from 'react-slick';
import Card from '../../../components/Card';
import { displayEnumType } from '../../../utils';
import VideoJSPlayer from '../../../components/advertising/VideoJSPlayer';
import TVDisplay from '../tournaments/TVDisplay';
import TVDisplayStraightPool from '../tournaments/TVDisplayStraightPool';
import MatchTVDisplay from '../tournaments/MatchTVDisplay';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import chalkysLogo from '../../../assets/img/chalkys-logo.png';
import twentyOnePoolRoomLogo from '../../../assets/img/21poolroom-logo.png';
import choptankLogo from '../../../assets/img/ads/choptank.png';
import bullCarbonLogo from '../../../assets/img/ads/bull-carbon.png';
import aramithLogo from '../../../assets/img/ads/aramith.png';
import ginaCunninghamLogo from '../../../assets/img/ads/gina-cunningham.png';
import dependableElectricalLogo from '../../../assets/img/ads/dependable-electrical.png';
import iwanSimonisLogo from '../../../assets/img/ads/iwan-simonis.png';
import onTheHillLogo from '../../../assets/img/ads/onthehill-productions.png';
import advertiseHere from '../../../assets/img/ads/advertise-here.png';
import marylandStateChampionships from '../../../assets/img/ads/maryland-state-championship-2023.png';
import dannysMarine from '../../../assets/img/ads/dannys-marine.png';
import suzukiMarine from '../../../assets/img/ads/suzuki-marine.png';
import { GET_EVENT_LIVE_TABLE_QUERY2, GET_EVENT_BY_SLUG_QUERY } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SUBSCRIPTION, GET_EVENT_LIVE_TABLE_SUBSCRIPTION2 } from '../data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import VideoJS from '../../digitalpool/tournaments/VideoJS';
import dpLogoHeader from '../../../assets/img/dp-logo-header.png';
// import dpCommercial from '../../../assets/video/dp-commercial-long.mp4';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function EventTableTVScoreDisplay2(props) {
    const { client } = props;
    const { match, authState } = props;
    const params = match.params;
    const eventSlug = params.slug;
    const tableSlug = params.table;
    const [videoError, setVideoError] = useState();
    const [newData, setNewData] = useState();
    const [startPlaying, setStartPlaying] = useState(false);

    const playerRef = React.useRef(null);

    const sliderSettings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        easing: 'ease-in-out',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        beforeChange: (current, next) => {
            console.log({ currentSlide: next });
        },
        afterChange: (current) => {
            if (current === 6) {
                setStartPlaying(true);
            }
            console.log({ currentSlide2: current });
        },
    };

    const videoJsOptions = {
        // lookup the options in the docs for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        muted: true,
        inactivityTimeout: 5,
        // fill: true,
        // playbackRates: [0.5, 1, 1.25, 1.5, 2],
        sources: [
            {
                src: 'https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8',
                type: 'application/x-mpegURL',
            },
        ],
    };

    function checkUrl(url) {
        var request = false;
        if (window.XMLHttpRequest) {
            request = new XMLHttpRequest();
        } else if (window.ActiveXObject) {
            request = new window.ActiveXObject('Microsoft.XMLHttp');
        }

        if (request) {
            request.open('GET', url);
            if (request.status == 200) {
                return true;
            }
        }

        return false;
    }

    // <source src="https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8" type="application/x-mpegURL" />

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // const mylogger = playerRef.current.log.createLogger('mylogger');

        // mylogger('hello world!');

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        // console.log(player);

        // const url = checkUrl('https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8');
        // const url = checkUrl('//vjs.zencdn.net/v/oceans.mp4');
        // console.log(url);

        // console.log(player);

        player.errorDisplay.any('error', () => {
            console.log('THERE WAS AN ERROR');
        });

        player.on('error', () => {
            console.log('ERROR');
            const error = player.error();

            console.log(error);
            // setVideoError(true);
        });

        // player.autoplay('muted');

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    function renderWaiting() {
        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={300} style={{ marginBottom: 50 }} />
                    <Title
                        level={1}
                        style={{
                            padding: 20,
                            margin: 0,
                            color: '#fff',
                            background: 'rgba(255,255,255,0.05)',
                            borderRadius: 20,
                            textTransform: 'uppercase',
                            letterSpacing: 2,
                        }}
                    >
                        Waiting for match on {tableSlug && displayEnumType(tableSlug)}
                    </Title>
                </div>
            </div>
        );
    }

    async function subscribeToNewChanges(subscribeToMore) {
        console.log('subscribe to new changes');
        const unsubscribe = subscribeToMore({
            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION2,
            variables: {
                event_slug: eventSlug,
                table_slug: tableSlug,
            },
            onSubscriptionData: (data) => {
                console.log('subscription data', data);
                setNewData(true);
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return prev;
            },
        });

        return () => unsubscribe();
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                // height: '100%',
                justifyContent: 'center',
                // alignItems: 'center',
                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
                height: 'auto',
                minHeight: '100%',
            }}
        >
            <Query
                query={GET_EVENT_LIVE_TABLE_QUERY2}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ event_slug: eventSlug, table_slug: tableSlug }}
                onCompleted={(data, error, loading) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                    if (loading)
                        return (
                            <div style={styles.container}>
                                <CircularLoader /> <span style={{ marginLeft: 10 }}>Loading...</span>
                            </div>
                        );
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    console.log(data);
                    let tableData;
                    let matchData;
                    let tournamentData;
                    let tables = [];

                    data &&
                        data.events &&
                        data.events.forEach((event) => {
                            const eventMatches = event.matches;
                            const eventTournaments = event.tournaments;
                            const eventVenueTables = event.venue && event.venue.pool_tables;

                            eventMatches.forEach((match) => {
                                if (match.pool_table && match.pool_table.matches) {
                                    match.pool_table.matches.forEach((item) => {
                                        matchData = item;
                                        tableData = match.pool_table;
                                    });
                                }
                            });

                            eventTournaments.forEach((tournament) => {
                                if (tournament.pool_tables) {
                                    tournament.pool_tables.forEach((table) => {
                                        if (table.tournament_match_table && table.tournament_match_table[0]) {
                                            matchData = table.tournament_match_table[0];
                                            tableData = table;
                                            tournamentData = tournament;
                                        }
                                    });
                                }
                            });

                            eventVenueTables.forEach((table) => {
                                if (table) {
                                    tables.push(table);
                                }
                            });
                        });

                    console.log({
                        tableData,
                        tables,
                        matchData,
                        tournamentData,
                    });

                    subscribeToNewChanges(subscribeToMore);

                    const table = tables && tables[0];
                    const event = data.events && data.events[0];

                    if (data && !tableData && !tables.length) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <div style={styles.container}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={250} style={{ marginBottom: 20 }} />
                                            <Title
                                                level={1}
                                                style={{
                                                    padding: 20,
                                                    margin: 0,
                                                    color: '#fff',
                                                    // background: 'rgba(255,255,255,0.05)',
                                                    textTransform: 'uppercase',
                                                    letterSpacing: 2,
                                                    fontSize: 40,
                                                    marginBottom: 20,
                                                }}
                                            >
                                                {tableSlug} does not exist
                                            </Title>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (data && !tableData) {
                        if (event && event.id === 91) {
                            return (
                                <Slider {...sliderSettings} style={{ background: 'transparent' }}>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24}>{renderWaiting()}</Col>
                                    </Row>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24} align="center">
                                            <img src={chalkysLogo} alt="Chalkys Logo" width="80%" />
                                        </Col>
                                    </Row>
                                </Slider>
                            );
                        } else if (event && event.id === 579) {
                            return (
                                <Slider
                                    ref={(slider) => {
                                        console.log(slider);
                                        if (startPlaying === true) {
                                            if (slider) {
                                                slider.slickPause();
                                            }
                                            console.log('slider paused');
                                        } else {
                                            if (slider) {
                                                slider.slickPlay();
                                            }
                                            console.log('slider playing');
                                        }
                                    }}
                                    {...sliderSettings}
                                    style={{ background: 'transparent' }}
                                >
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} style={{ padding: 0 }}>
                                            {renderWaiting()}
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={choptankLogo} alt="Choptank Bowling & Billiards" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={bullCarbonLogo} alt="Bull Carbon" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={aramithLogo} alt="Aramith Billiard Balls" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={ginaCunninghamLogo} alt="Gina Cunningham Real Estate" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={dependableElectricalLogo} alt="Dependable Electrical" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <VideoJSPlayer
                                                options={{
                                                    autoplay: false,
                                                    controls: true,
                                                    responsive: true,
                                                    fluid: true,
                                                    muted: true,
                                                    sources: [
                                                        {
                                                            src: 'https://digitalpool.nyc3.cdn.digitaloceanspaces.com/dp-commercial-long.mp4',
                                                            type: 'video/mp4',
                                                        },
                                                    ],
                                                }}
                                                startPlaying={startPlaying}
                                                onEnded={() => {
                                                    setStartPlaying(false);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={suzukiMarine} alt="Suzuki Marine" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={iwanSimonisLogo} alt="Iwan Simonis Logo" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={onTheHillLogo} alt="On The Hill Logo" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={advertiseHere} alt="Advertise Here" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={marylandStateChampionships} alt="Maryland State Championship 9-Ball" width="100%" />
                                        </Col>
                                    </Row>
                                    <Row gutter={0} justify="center" type="flex">
                                        <Col xs={24} align="center" style={{ padding: 0 }}>
                                            <img src={dannysMarine} alt="Danny's Marine" width="100%" />
                                        </Col>
                                    </Row>
                                </Slider>
                            );
                        } else if (event && event.id === 143) {
                            return (
                                <Slider {...sliderSettings} style={{ background: 'transparent' }}>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24}>{renderWaiting()}</Col>
                                    </Row>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24} align="center">
                                            <img src={twentyOnePoolRoomLogo} alt="21 Pool Room Logo" width="80%" />
                                        </Col>
                                    </Row>
                                </Slider>
                            );
                        } else {
                            return (
                                <Row gutter={24} justify="center" type="flex">
                                    <Col xs={24}>{renderWaiting()}</Col>
                                </Row>
                            );
                        }
                    }

                    console.log(tableData);
                    console.log(tournamentData);
                    console.log(matchData);

                    if (tournamentData) {
                        if (tournamentData && tournamentData.game_type === 'STRAIGHT_POOL') {
                            return (
                                <TVDisplayStraightPool
                                    tournamentData={tournamentData}
                                    matchData={matchData}
                                    tableData={tableData}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                            variables: { event_slug: eventSlug, table_slug: tableSlug },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newTournament = subscriptionData.data.tournaments;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        } else {
                            return (
                                <TVDisplay
                                    tournamentData={tournamentData}
                                    matchData={matchData}
                                    tableData={tableData}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                            variables: { event_slug: eventSlug, table_slug: tableSlug },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newTournament = subscriptionData.data.tournaments;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        }
                    } else {
                        return (
                            <MatchTVDisplay
                                matchData={{
                                    ...matchData,
                                    eventSlug,
                                    tableSlug,
                                }}
                                tableData={tableData}
                                subscribeToMore={subscribeToMore}
                                subscribeToNewData={() => {
                                    subscribeToMore({
                                        document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION2,
                                        variables: { event_slug: eventSlug, table_slug: tableSlug },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            if (!subscriptionData.data) return prev;
                                            const newMatch = subscriptionData.data.matches;
                                            console.log(newMatch);
                                            let updated;
                                            if (prev) {
                                                updated = Object.assign({}, prev, {
                                                    matches: [newMatch],
                                                });
                                            } else {
                                                updated = Object.assign({}, prev, {
                                                    matches: [newMatch],
                                                });
                                            }
                                            return updated;
                                        },
                                    });
                                }}
                                {...props}
                            />
                        );
                    }
                }}
            </Query>
        </div>
    );
}

export default withRouter(EventTableTVScoreDisplay2);

// {videoError !== false ? (
//     <div
//         style={{
//             position: 'fixed',
//             width: '100%',
//             height: '100%',
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             zIndex: 100,
//             display: 'flex',
//             flexDirection: 'column',
//         }}
//     >
//         <div
//             style={{
//                 position: 'absolute',
//                 bottom: 0,
//                 zIndex: 10,
//                 width: '100%',
//                 backgroundColor: '#000',
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 paddingLeft: 10,
//                 paddingRight: 10,
//             }}
//         >
//             <img src={dpLogoHeader} alt="Logo" height={100} />
//             <Title
//                 level={1}
//                 style={{
//                     padding: 20,
//                     margin: 0,
//                     color: '#fff',
//                     // background: 'rgba(255,255,255,0.05)',
//                     borderRadius: 20,
//                     textTransform: 'uppercase',
//                     letterSpacing: 2,
//                 }}
//             >
//                 Waiting for match on {tableSlug}
//             </Title>
//         </div>

//         <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
//     </div>
// ) : (
//     <React.Fragment>
//         <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={300} style={{ marginBottom: 50 }} />
//         <Title
//             level={1}
//             style={{
//                 padding: 20,
//                 margin: 0,
//                 color: '#fff',
//                 background: 'rgba(255,255,255,0.05)',
//                 borderRadius: 20,
//                 textTransform: 'uppercase',
//                 letterSpacing: 2,
//             }}
//         >
//             Waiting for match on {tableSlug}
//         </Title>
//     </React.Fragment>
// )}
