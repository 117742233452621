const artisticPoolShots = [
    {
        discipline: 1,
        shot: '1a',
        name: 'The 3 & 3 Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S1A.jpg',
    },
    {
        discipline: 1,
        shot: '1b',
        name: 'The 2 & 2 Split Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S1B.jpg',
    },
    {
        discipline: 1,
        shot: '1c',
        name: 'Calling the Corners',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S1C.jpg',
    },
    {
        discipline: 1,
        shot: '2a',
        name: '4 In a Line',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S2A.jpg',
    },
    {
        discipline: 1,
        shot: '2b',
        name: "The Big 'X' Shot",
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S2B.jpg',
    },
    {
        discipline: 1,
        shot: '2c',
        name: `The Be Still Shot`,
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S2C.jpg',
    },
    {
        discipline: 1,
        shot: '3a',
        name: "Tim's Twister",
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S3A.jpg',
    },
    {
        discipline: 1,
        shot: '3b',
        name: 'Just Showing Off Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S3B.jpg',
    },
    {
        discipline: 1,
        shot: '3c',
        name: 'The Half Butterfly',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S3C.jpg',
    },
    {
        discipline: 1,
        shot: '4a',
        name: 'The Tricky Cluster',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S4A.jpg',
    },
    {
        discipline: 1,
        shot: '4b',
        name: 'Afterthought',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S4B.jpg',
    },
    {
        discipline: 1,
        shot: '4c',
        name: 'The Neverending Story',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S4C.jpg',
    },
    {
        discipline: 1,
        shot: '5a',
        name: 'The Purr-fect Setup',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S5A.jpg',
    },
    {
        discipline: 1,
        shot: '5b',
        name: 'Corner Stuffing',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S5B.jpg',
    },
    {
        discipline: 1,
        shot: '5c',
        name: 'The Hood Variation',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D1-S5C.jpg',
    },
    {
        discipline: 2,
        shot: '1a',
        name: 'The Speed Demon',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S1A.jpg',
    },
    {
        discipline: 2,
        shot: '1b',
        name: "Beat The '1'",
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S1B.jpg',
    },
    {
        discipline: 2,
        shot: '1c',
        name: 'The Hand is Quicker than the Eye',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S1C.jpg',
    },
    {
        discipline: 2,
        shot: '2a',
        name: "Massey's Over, Around, & Under",
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S2A.jpg',
    },
    {
        discipline: 2,
        shot: '2b',
        name: 'Nick of Time',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S2B.jpg',
    },
    {
        discipline: 2,
        shot: '2c',
        name: "Hide 'N' Seek",
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S2C.jpg',
    },
    {
        discipline: 2,
        shot: '3a',
        name: 'A Faster Speed Demon',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S3A.jpg',
    },
    {
        discipline: 2,
        shot: '3b',
        name: 'The Over & Under Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S3B.jpg',
    },
    {
        discipline: 2,
        shot: '3c',
        name: 'The Easy Button Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S3C.jpg',
    },
    {
        discipline: 2,
        shot: '4a',
        name: 'Wing Shots',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S4A.jpg',
    },
    {
        discipline: 2,
        shot: '4b',
        name: 'Reverse Wing Shots',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S4B.jpg',
    },
    {
        discipline: 2,
        shot: '4c',
        name: 'Through the Gap Shot',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S4C.jpg',
    },
    {
        discipline: 2,
        shot: '5a',
        name: "Pelinga's Jump Hook",
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S5A.jpg',
    },
    {
        discipline: 2,
        shot: '5b',
        name: 'Fly Kick Split',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S5B.jpg',
    },
    {
        discipline: 2,
        shot: '5c',
        name: '3, 2, 1 - 1, 2, 3',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D2-S5C.jpg',
    },
    {
        discipline: 3,
        shot: '1a',
        name: 'The Easy Drawback Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S1A.jpg',
    },
    {
        discipline: 3,
        shot: '1b',
        name: 'Stun Draw',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S1B.jpg',
    },
    {
        discipline: 3,
        shot: '1c',
        name: 'The Firecracker',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S1C.jpg',
    },
    {
        discipline: 3,
        shot: '2a',
        name: 'Pitching a Curve Ball',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S2A.jpg',
    },
    {
        discipline: 3,
        shot: '2b',
        name: 'The Spin Transfer Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S2B.jpg',
    },
    {
        discipline: 3,
        shot: '2c',
        name: 'Stacker Ball Draw Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S2C.jpg',
    },
    {
        discipline: 3,
        shot: '3a',
        name: 'The Carom Draw Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S3A.jpg',
    },
    {
        discipline: 3,
        shot: '3b',
        name: 'Rail Draw Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S3B.jpg',
    },
    {
        discipline: 3,
        shot: '3c',
        name: "Szywala's Split Draw Shot",
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S3C.jpg',
    },
    {
        discipline: 3,
        shot: '4a',
        name: 'Precision Draw Shot',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S4A.jpg',
    },
    {
        discipline: 3,
        shot: '4b',
        name: 'Draw for the Win',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S4B.jpg',
    },
    {
        discipline: 3,
        shot: '4c',
        name: 'The One-handed Quick Draw',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S4C.jpg',
    },
    {
        discipline: 3,
        shot: '5a',
        name: "Massey's Power Draw",
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S5A.jpg',
    },
    {
        discipline: 3,
        shot: '5b',
        name: 'The Circular Draw Shot',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S5B.jpg',
    },
    {
        discipline: 3,
        shot: '5c',
        name: 'Down the Alley',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D3-S5C.jpg',
    },
    {
        discipline: 4,
        shot: '1a',
        name: 'Dipsy Doodle',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S1A.jpg',
    },
    {
        discipline: 4,
        shot: '1b',
        name: 'Up the Rail Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S1B.jpg',
    },
    {
        discipline: 4,
        shot: '1c',
        name: 'The Mystery 9-Ball Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S1C.jpg',
    },
    {
        discipline: 4,
        shot: '2a',
        name: 'The Machine Gun Follow Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S2A.jpg',
    },
    {
        discipline: 4,
        shot: '2b',
        name: 'The Zip Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S2B.jpg',
    },
    {
        discipline: 4,
        shot: '2c',
        name: 'The Kiss Back Follow Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S2C.jpg',
    },
    {
        discipline: 4,
        shot: '3a',
        name: 'Inside Spinner',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S3A.jpg',
    },
    {
        discipline: 4,
        shot: '3b',
        name: 'Follow for the Win',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S3B.jpg',
    },
    {
        discipline: 4,
        shot: '3c',
        name: 'The Follow Back',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S3C.jpg',
    },
    {
        discipline: 4,
        shot: '4a',
        name: 'The Window Shot',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S4A.jpg',
    },
    {
        discipline: 4,
        shot: '4b',
        name: 'Out of My Way',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S4B.jpg',
    },
    {
        discipline: 4,
        shot: '4c',
        name: 'The Zig Zag Shot',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S4C.jpg',
    },
    {
        discipline: 4,
        shot: '5a',
        name: 'The Circular Follow',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S5A.jpg',
    },
    {
        discipline: 4,
        shot: '5b',
        name: 'The Follow Bender',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S5B.jpg',
    },
    {
        discipline: 4,
        shot: '5c',
        name: 'Follow the Kiss',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D4-S5C.jpg',
    },
    {
        discipline: 5,
        shot: '1a',
        name: 'The Triple Reverse Bank',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S1A.jpg',
    },
    {
        discipline: 5,
        shot: '1b',
        name: '5 Rail Chain Reaction',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S1B.jpg',
    },
    {
        discipline: 5,
        shot: '1c',
        name: "Pelinga's Backward Bank",
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S1C.jpg',
    },
    {
        discipline: 5,
        shot: '2a',
        name: 'The Escape Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S2A.jpg',
    },
    {
        discipline: 5,
        shot: '2b',
        name: `"The Hustler" Bank Shot`,
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S2B.jpg',
    },
    {
        discipline: 5,
        shot: '2c',
        name: 'There & Back Again',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S2C.jpg',
    },
    {
        discipline: 5,
        shot: '3a',
        name: 'Poolhall Junkie Bank Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S3A.jpg',
    },
    {
        discipline: 5,
        shot: '3b',
        name: 'The Kiss Back',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S3B.jpg',
    },
    {
        discipline: 5,
        shot: '3c',
        name: 'The Quick Timer Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S3C.jpg',
    },
    {
        discipline: 5,
        shot: '4a',
        name: "Phelan's Challenge Shot",
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S4A.jpg',
    },
    {
        discipline: 5,
        shot: '4b',
        name: 'The Five Rail Kick',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S4B.jpg',
    },
    {
        discipline: 5,
        shot: '4c',
        name: 'The Speedy Skater',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S4C.jpg',
    },
    {
        discipline: 5,
        shot: '5a',
        name: 'The Kiss and Kick Shot',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S5A.jpg',
    },
    {
        discipline: 5,
        shot: '5b',
        name: 'The Efren Reyes Bank Shot',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S5B.jpg',
    },
    {
        discipline: 5,
        shot: '5c',
        name: 'Rat Race',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D5-S5C.jpg',
    },
    {
        discipline: 6,
        shot: '1a',
        name: 'The Resistance Draw',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S1A.jpg',
    },
    {
        discipline: 6,
        shot: '1b',
        name: 'The Jump Draw Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S1B.jpg',
    },
    {
        discipline: 6,
        shot: '1c',
        name: 'The Double Kiss Draw Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S1C.jpg',
    },
    {
        discipline: 6,
        shot: '2a',
        name: "Giumelli's Rendezvous",
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S2A.jpg',
    },
    {
        discipline: 6,
        shot: '2b',
        name: 'Avoid the Noid',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S2B.jpg',
    },
    {
        discipline: 6,
        shot: '2c',
        name: 'Jump-Fouette',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S2C.jpg',
    },
    {
        discipline: 6,
        shot: '3a',
        name: 'The Ribbon Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S3A.jpg',
    },
    {
        discipline: 6,
        shot: '3b',
        name: 'The Ribbon Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S3B.jpg',
    },
    {
        discipline: 6,
        shot: '3c',
        name: 'The Koufax Curveball',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S3C.jpg',
    },
    {
        discipline: 6,
        shot: '4a',
        name: "Luke's Stroke Jump Shot",
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S4A.jpg',
    },
    {
        discipline: 6,
        shot: '4b',
        name: 'The Showoff Spin Shot',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S4B.jpg',
    },
    {
        discipline: 6,
        shot: '4c',
        name: 'Splitting The Chopsticks',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S4C.jpg',
    },
    {
        discipline: 6,
        shot: '5a',
        name: 'The Whip Draw Shot',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S5A.jpg',
    },
    {
        discipline: 6,
        shot: '5b',
        name: 'Opening The Gate',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S5B.jpg',
    },
    {
        discipline: 6,
        shot: '5c',
        name: 'The Impossible Stroke',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D6-S5C.jpg',
    },
    {
        discipline: 7,
        shot: '1a',
        name: 'The Jailbreak Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S1A.jpg',
    },
    {
        discipline: 7,
        shot: '1b',
        name: 'The Waterfall Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S1B.jpg',
    },
    {
        discipline: 7,
        shot: '1c',
        name: 'The One-hand Jump Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S1C.jpg',
    },
    {
        discipline: 7,
        shot: '2a',
        name: 'The Kiss Back Hop Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S2A.jpg',
    },
    {
        discipline: 7,
        shot: '2b',
        name: 'The Close Jump Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S2B.jpg',
    },
    {
        discipline: 7,
        shot: '2c',
        name: 'The Flying Bank Shot',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S2C.jpg',
    },
    {
        discipline: 7,
        shot: '3a',
        name: 'Race Against Time',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S3A.jpg',
    },
    {
        discipline: 7,
        shot: '3b',
        name: "Yow's Barrier",
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S3B.jpg',
    },
    {
        discipline: 7,
        shot: '3c',
        name: 'The Leap Frog Shot',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S3C.jpg',
    },
    {
        discipline: 7,
        shot: '4a',
        name: 'Over and Around',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S4A.jpg',
    },
    {
        discipline: 7,
        shot: '4b',
        name: 'The Evel Knievel Shot',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S4B.jpg',
    },
    {
        discipline: 7,
        shot: '4c',
        name: "Florian's Double Jump",
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S4C.jpg',
    },
    {
        discipline: 7,
        shot: '5a',
        name: "Jump In 'n' Out",
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S5A.jpg',
    },
    {
        discipline: 7,
        shot: '5b',
        name: 'The Six-Pack',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S5B.jpg',
    },
    {
        discipline: 7,
        shot: '5c',
        name: 'The Derby Horse Race',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D7-S5C.jpg',
    },
    {
        discipline: 8,
        shot: '1a',
        name: 'The Ultimate Trap Shot',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S1A.jpg',
    },
    {
        discipline: 8,
        shot: '1b',
        name: 'Masse around the Rack',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S1B.jpg',
    },
    {
        discipline: 8,
        shot: '1c',
        name: 'The Escape Masse',
        points: 6,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S1C.jpg',
    },
    {
        discipline: 8,
        shot: '2a',
        name: `"The Hustler" Masse Shot`,
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S2A.jpg',
    },
    {
        discipline: 8,
        shot: '2b',
        name: 'The Machine Gun Masse',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S2B.jpg',
    },
    {
        discipline: 8,
        shot: '2c',
        name: 'The Color of Money Masse',
        points: 7,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S2C.jpg',
    },
    {
        discipline: 8,
        shot: '3a',
        name: 'Wall Masse',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S3A.jpg',
    },
    {
        discipline: 8,
        shot: '3b',
        name: 'The Mini Rocket Masse',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S3B.jpg',
    },
    {
        discipline: 8,
        shot: '3c',
        name: 'The Big Guy Masse',
        points: 8,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S3C.jpg',
    },
    {
        discipline: 8,
        shot: '4a',
        name: 'The Jump Masse',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S4A.jpg',
    },
    {
        discipline: 8,
        shot: '4b',
        name: 'The Big Rocket Masse',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S4B.jpg',
    },
    {
        discipline: 8,
        shot: '4c',
        name: 'The Whip Masse',
        points: 9,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S4C.jpg',
    },
    {
        discipline: 8,
        shot: '5a',
        name: 'Dipsy Doodle Masse',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S5A.jpg',
    },
    {
        discipline: 8,
        shot: '5b',
        name: 'The Minguad Masse',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S5B.jpg',
    },
    {
        discipline: 8,
        shot: '5c',
        name: 'The Yow Masse',
        points: 10,
        image_url: 'https://d1k23ln7af6dzb.cloudfront.net/artistic-pool/D8-S5C.jpg',
    },
];

export { artisticPoolShots };
