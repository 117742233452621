import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Typography, Upload, Divider, Icon, Avatar } from 'antd';
import { Form, Table, AddRowButton, RemoveRowButton, FormItem, Input, Select, InputNumber } from 'formik-antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CircularLoader from '../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_TOURNAMENT_QUERY, GET_TOURNAMENT_TIERED_ENTRY_FEES } from './data/queries';
import { DELETE_TOURNAMENT_TIERED_ENTRY_FEES, CREATE_TOURNAMENT_TIERED_ENTRY_FEES } from './data/mutations';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import _ from 'lodash';
import { DollarCircleFilled } from '@ant-design/icons';

const { Option } = Select;
const { Text, Title } = Typography;

function TieredEntryFeeModal(props) {
    const { client, tournament, tournamentID, modalVisible, onModalOk, onModalCancel, onSave } = props;
    const [loading, setLoading] = useState(false);
    const [deleteTieredEntryFees] = useMutation(DELETE_TOURNAMENT_TIERED_ENTRY_FEES);
    const [createTieredEntryFees] = useMutation(CREATE_TOURNAMENT_TIERED_ENTRY_FEES);
    const formRef = useRef();

    function submitAdd(data, actions) {
        const { entry_fees } = data;

        const toUpdate = entry_fees
            .filter((item) => {
                return !_.isEmpty(item.amount) && !_.isEmpty(item.skill_level);
            })
            .map((item) => {
                return {
                    skill_level: item.skill_level,
                    amount: parseFloat(item.amount),
                };
            });

        console.log(toUpdate);
        onSave(toUpdate);
        actions.setSubmitting(false);
        setLoading(false);
        onModalOk();
    }

    function submitUpdate(data, actions) {
        const { entry_fees } = data;

        const toUpdate = entry_fees
            .filter((item) => {
                return !_.isEmpty(item.amount) && !_.isEmpty(item.skill_level);
            })
            .map((item) => {
                return {
                    tournament_id: tournament.id,
                    skill_level: item.skill_level,
                    amount: parseFloat(item.amount),
                };
            });
        console.log('to update', toUpdate);

        setLoading(true);
        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_TIERED_ENTRY_FEES,
                variables: {
                    tournament_id: tournamentID,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_TIERED_ENTRY_FEES,
                        variables: { tournament_id: tournamentID },
                    },
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: {
                            id: tournamentID,
                        },
                    },
                ],
            })
            .then(async (data) => {
                createTieredEntryFees({
                    variables: {
                        objects: toUpdate.map((item) => {
                            return {
                                tournament_id: tournament.id,
                                skill_level: item.skill_level,
                                amount: item.amount && parseFloat(item.amount),
                            };
                        }),
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_TIERED_ENTRY_FEES,
                            variables: { tournament_id: tournamentID },
                        },
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: {
                                id: tournamentID,
                            },
                        },
                    ],
                });
                actions.setSubmitting(false);
                setLoading(false);
                message.success('Changes saved');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    }

    const schema = Yup.object().shape({
        player_score: Yup.number().required('Required'),
        opponent_score: Yup.number().required('Required'),
    });

    const renderForm = (entryFees) => {
        const initialValues =
            tournamentID && entryFees
                ? {
                      entry_fees: entryFees,
                  }
                : {
                      entry_fees: [
                          {
                              skill_level: '400-',
                              amount: '',
                          },
                          {
                              skill_level: '400-500',
                              amount: '',
                          },
                          {
                              skill_level: '501-600',
                              amount: '',
                          },
                          {
                              skill_level: '601-650',
                              amount: '',
                          },
                          {
                              skill_level: '651-699',
                              amount: '',
                          },
                          {
                              skill_level: '700+',
                              amount: '',
                          },
                      ],
                  };

        return (
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    if (tournamentID) {
                        submitUpdate(data, actions);
                    } else {
                        submitAdd(data, actions);
                    }
                }}
                enableReinitialize={false}
                initialValues={initialValues}
                render={({ errors, touched, values }) => (
                    <Modal
                        title="Set tiered entry fees by skill level"
                        // getContainer={() => document.querySelector('.tables')}
                        visible={modalVisible}
                        onOk={onModalOk}
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        // transitionName="none"
                        maskTransitionName="none"
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                onClick={() => {
                                    formRef.current.handleSubmit();
                                }}
                            >
                                {loading ? 'Saving...' : 'Save Amounts'}
                            </Button>,
                        ]}
                        bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                        destroyOnClose={true}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Form>
                                <Table
                                    name="entry_fees"
                                    rowKey={(row, index) => index}
                                    size="small"
                                    pagination={false}
                                    columns={[
                                        {
                                            width: 100,
                                            title: 'Skill level',
                                            key: 'skill_level',
                                            render: (text, record, i) => (
                                                <Input placeholder="100" style={{ border: 'none' }} name={`entry_fees.${i}.skill_level`} />
                                            ),
                                        },
                                        {
                                            title: 'Entry fee',
                                            key: 'amount',
                                            render: (text, record, i) => (
                                                <Input
                                                    addonBefore={<DollarCircleFilled />}
                                                    style={{ border: 'none' }}
                                                    name={`entry_fees.${i}.amount`}
                                                    placeholder="Entry fee"
                                                />
                                            ),
                                        },
                                        {
                                            width: 32,
                                            key: 'actions',
                                            align: 'right',
                                            render: (text, record, index) => (
                                                <RemoveRowButton style={{ border: 'none' }} icon="delete" name="entry_fees" index={index} />
                                            ),
                                        },
                                    ]}
                                />
                                <AddRowButton
                                    name="entry_fees"
                                    style={{ marginTop: 10 }}
                                    createNewRow={() => ({
                                        id: '' + Math.random(),
                                        skill_level: '',
                                        amount: '',
                                    })}
                                >
                                    Add
                                </AddRowButton>
                            </Form>
                        </div>
                    </Modal>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            {tournamentID ? (
                <Query
                    query={GET_TOURNAMENT_TIERED_ENTRY_FEES}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ tournament_id: tournament.id }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const entryFees =
                            data && data.tournament_tiered_entry_fees && data.tournament_tiered_entry_fees.length > 0
                                ? data.tournament_tiered_entry_fees.map((item) => {
                                      return {
                                          skill_level: item.skill_level,
                                          amount: parseFloat(item.amount),
                                      };
                                  })
                                : null;

                        return <React.Fragment>{renderForm(entryFees)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default TieredEntryFeeModal;
