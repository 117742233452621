import React from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import * as routes from '../../config/routes';
import { Query } from '@apollo/client/react/components';
import { GET_GHOST_EVENT_QUERY } from './data/queries';
import withAuthorization from '../auth/withAuthorization';
import CircularLoader from '../../components/CircularLoader';
import GEDashboard from './GEDashboard';
import GEPlayers from './GEPlayers';
import GEResults from './GEResults';
import GEStats from './GEStats';
import GEPayouts from './GEPayouts';
import GESettings from './GESettings';

function GGDetail(props) {
    const { match, authState } = props;
    const slug = match.params.slug;

    console.log('propssssss', slug);

    return (
        <Query fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} query={GET_GHOST_EVENT_QUERY} variables={{ slug }}>
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>Error: {error.message}</div>;
                if (data && !data.ghost_games) {
                    return <div>Ghost Game not found.</div>;
                }
                if (data && data.ghost_games.length === 0) {
                    return <div>Ghost Game not found.</div>;
                }
                const ghost_game = data.ghost_games[0];

                return (
                    <React.Fragment>
                        <Switch>
                            <Redirect
                                exact
                                strict
                                from={`/ghost-event-manager/${props.match.params.slug}`}
                                to={`/ghost-event-manager/${props.match.params.slug}/dashboard`}
                            />

                            <Route
                                exact
                                path={routes.GHOST_EVENTS_MANAGER.DETAIL_DASHBOARD}
                                render={() => <GEDashboard {...props} title="Dashboard" ghostEvent={ghost_game} />}
                            />

                            <Route
                                exact
                                path={routes.GHOST_EVENTS_MANAGER.DETAIL_PLAYERS}
                                render={() => <GEPlayers {...props} title="Dashboard" ghostEvent={ghost_game} />}
                            />

                            <Route
                                exact
                                path={routes.GHOST_EVENTS_MANAGER.DETAIL_RESULTS}
                                render={() => <GEResults {...props} title="Results" ghostEvent={ghost_game} />}
                            />

                            <Route
                                exact
                                path={routes.GHOST_EVENTS_MANAGER.DETAIL_STATS}
                                render={() => <GEStats {...props} title="Stats" ghostEvent={ghost_game} />}
                            />

                            <Route
                                exact
                                path={routes.GHOST_EVENTS_MANAGER.DETAIL_PAYOUTS}
                                render={() => <GEPayouts {...props} title="Payouts" ghostEvent={ghost_game} />}
                            />

                            <Route
                                exact
                                path={routes.GHOST_EVENTS_MANAGER.DETAUL_SETTINGS}
                                render={() => <GESettings {...props} title="Settings" ghostEvent={ghost_game} />}
                            />
                        </Switch>
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

const authCondition = (authUser) => !!authUser;

const WithAuthorization = withAuthorization(authCondition)(GGDetail);

export default withRouter(WithAuthorization);
