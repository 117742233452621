import React, { useState, useEffect } from 'react';
import ReactSpinner from 'react-spinjs-fix';
import dpIcon from '../assets/img/dp-icon.png';
import './loading-spinner.css';

const configObject = {
	lines: 18, // The number of lines to draw
	length: 0, // The length of each line
	width: 4, // The line thickness
	radius: 45, // The radius of the inner circle
	corners: 1, // Corner roundness (0..1)
	rotate: 0, // The rotation offset
	direction: 1, // 1: clockwise, -1: counterclockwise
	color: '#1890fe', // #rgb or #rrggbb
	speed: 1, // Rounds per second
	trail: 60, // Afterglow percentage
	shadow: false, // Whether to render a shadow
	className: 'spinner-loading', // The CSS class to assign to the spinner
	zIndex: 2e9 // The z-index (defaults to 2000000000)
	// top: 'auto', // Top position relative to parent in px
	// left: 'auto' // Left position relative to parent in px
};

export default function LoadingSpinner(props) {
	// const [ loading, setLoading ] = useState(props.loading || false);

	// useEffect(
	// 	() => {
	// 		setLoading(props.loading);
	// 	},
	// 	[ props ]
	// );

	return (
		<React.Fragment>
			{props.loading === true && (
				<div id="loader" className="dark">
					<div className="loading-container">
						<ReactSpinner config={configObject} />
						<div className="animated fadeIn">
							<img src={dpIcon} alt="Logo" width={55} />
						</div>
						<div className="message" style={{ color: '#fff' }}>
							Logging in to DigitalPool...
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}
