import React, { useState, useEffect } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { message, PageHeader, Icon, Tabs, Modal, Button, Typography } from 'antd';
import SectionHeader from '../../../components/SectionHeader';
// import SectionContent from '../../../components/SectionContent';
import Card from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
// import usOpenHeader from '../../../assets/img/us-open-header.png';
import { Query } from '@apollo/client/react/components';
import Sticky from 'react-stickynode';
// import DPTournamentSchedule from './DPTournamentSchedule';
// import DPTournamentScores from './DPTournamentScores';
// import DPTournamentPlayers from './DPTournamentPlayers';
// import DPTournamentResults from './DPTournamentResults';
// import DPTournamentStats from './DPTournamentStats';
import DPLeagueOverview from './DPLeagueOverview';
import DPLeagueDivisions from './DPLeagueDivisions';
import DPLeaguePlayers from './DPLeaguePlayers';
import DPLeagueStandings from './DPLeagueStandings';
import DPLeagueChallenges from './DPLeagueChallenges';
import DPDivisionDetail from './DPDivisionDetail';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
// import TabContent from 'rc-tabs/lib/TabContent';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import * as routes from '../../../config/routes';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { GET_LEAGUE_BY_SLUG_QUERY, GET_LEAGUE_PLAYERS_QUERY, GET_LEAGUE_PLAYER } from '../data/queries';
import { CREATE_LEAGUE_PLAYER, DELETE_LEAGUE_PLAYER } from '../data/mutations';
import * as routes from '../../../config/routes';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { confirm } = Modal;

function DPLeagueDetail(props) {
    const { authState, history, client } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['overview', 'divisions', 'schedule', 'teams', 'matches', 'players', 'scores', 'results', 'stats', 'challenges', 'standings'];
    const [current, setCurrent] = useState();
    const [isLeagueMember, setIsLeagueMember] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [createLeaguePlayer] = useMutation(CREATE_LEAGUE_PLAYER);
    const [deleteLeaguePlayer] = useMutation(DELETE_LEAGUE_PLAYER);

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : _.includes(dpIncludes, splitPath[splitPath.length - 5])
            ? splitPath[splitPath.length - 5]
            : 'overview';

        setCurrent(pathMatch);
    }, [urlPath, dpIncludes, splitPath, params]);

    const styles = {
        row: {
            display: 'flex',
            flexGrow: 1,
        },
        tab: {
            paddingTop: 5,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
        },

        headerImg: {
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
            // background: `linear-gradient(45deg, rgb(160, 0, 44), rgb(0, 78, 187)) 0% 0% / 100% no-repeat fixed`,
            // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            // backgroundPosition: '0px -400px'

            // backgroundPosition: '0px -40px'
        },
        header: {
            // background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: '100%',
            paddingTop: breakpoint === 'mobile' ? 5 : 10,
            paddingLeft: breakpoint === 'mobile' ? 10 : 40,
            paddingRight: breakpoint === 'mobile' ? 0 : 0,
            paddingBottom: breakpoint === 'mobile' ? 0 : 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/leagues/${slug}/${tab}`);
    };

    async function checkLeagueMember(league) {
        const res = await client.query({
            query: GET_LEAGUE_PLAYER,
            variables: {
                leagueId: league.id,
                userId: authState.user.id,
            },
        });
        const isMember = res && res.data && res.data.league_players && res.data.league_players.length > 0;
        setIsLeagueMember(isMember);
        return isMember;
    }

    return (
        <Query
            query={GET_LEAGUE_BY_SLUG_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug }}
            onCompleted={(data) => {
                const league = data && data.leagues && data.leagues[0];
                if (league) {
                    checkLeagueMember(league);
                }
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.leagues) {
                    return <div>League not found.</div>;
                }
                if (data && data.leagues.length === 0) {
                    return <div>League not found.</div>;
                }
                const league = data.leagues[0];
                console.log('league', league);

                function showConfirmJoin(record) {
                    confirm({
                        title: `Are you sure you want to join ${record.name}?`,
                        centered: true,
                        transitionName: 'fade',
                        maskTransitionName: 'none',
                        // onOk() {
                        // 	return new Promise((resolve, reject) => {
                        // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                        // 	}).catch(() => console.log('Oops errors!'));
                        // },
                        okText: 'Join League',
                        cancelText: 'Cancel',
                        onOk() {
                            joinLeague(record);
                        },
                        onCancel() {},
                    });
                }

                function showConfirmLeave(record) {
                    confirm({
                        title: `Are you sure you want to leave ${record.name}?`,
                        centered: true,
                        transitionName: 'fade',
                        maskTransitionName: 'none',
                        // onOk() {
                        // 	return new Promise((resolve, reject) => {
                        // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                        // 	}).catch(() => console.log('Oops errors!'));
                        // },
                        okText: 'Leave League',
                        cancelText: 'Cancel',
                        onOk() {
                            leaveLeague(record);
                        },
                        onCancel() {},
                    });
                }

                function joinLeague() {
                    const user = authState && authState.user && authState.user;

                    createLeaguePlayer({
                        variables: {
                            objects: {
                                user_id: user.id,
                                league_id: league.id,
                                player_id: user.player_id,
                                name: user.displayName,
                                country: user.country,
                                region: user.region,
                                email: user.email,
                                status: 'ACTIVE',
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_LEAGUE_BY_SLUG_QUERY,
                                variables: { slug },
                            },
                            {
                                query: GET_LEAGUE_PLAYERS_QUERY,
                                variables: { league_id: league.id },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                            setIsLeagueMember(true);
                            message.success(`You are now a member of ${league.name}`);
                        })
                        .catch((e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log('error', err);
                            message.error('There was an error', err);
                        });
                }

                function leaveLeague() {
                    const user = authState && authState.user && authState.user;
                    console.log('leave league');

                    deleteLeaguePlayer({
                        variables: {
                            league_id: league.id,
                            user_id: user.id,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_LEAGUE_BY_SLUG_QUERY,
                                variables: { slug },
                            },
                            {
                                query: GET_LEAGUE_PLAYERS_QUERY,
                                variables: { league_id: league.id },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                            setIsLeagueMember(false);
                            message.success(`You have left ${league.name}`);
                        })
                        .catch((e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log('error', err);
                            message.error('There was an error', err);
                        });
                }

                return (
                    <Switch>
                        <Route
                            path={`${props.match.path}/divisions/:division`}
                            render={(matchProps) => <DPDivisionDetail league={league} {...matchProps} {...props} />}
                        />
                        <Route
                            path={`${props.match.path}`}
                            render={() => (
                                <div style={styles.headerImg}>
                                    {/* <div style={{ padding: '20px 40px 0px 40px' }}>
										<BreadcrumbContainer theme="dark" />
									</div> */}

                                    <SectionHeader
                                        title={league.name.toUpperCase()}
                                        titleStyle={{
                                            color: '#fff',
                                            textTransform: 'uppercase',
                                            margin: 0,
                                            display: 'flex',
                                            letterSpacing: breakpoint === 'mobile' ? 0 : 6,
                                            paddingRight: 10,
                                        }}
                                        backButton={
                                            <div
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <Icon
                                                    type="arrow-left"
                                                    onClick={() => history.push(`/leagues`)}
                                                    style={{ color: '#fff', fontSize: 16, margin: 0 }}
                                                />
                                            </div>
                                        }
                                        // style={styles.header}
                                        // theme="tournamentBuilder"
                                        style={styles.header}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {authState && authState.user && (authState.user.role === 'admin' || authState.user.id === league.owner_id) && (
                                                <React.Fragment>
                                                    {breakpoint === 'mobile' ? (
                                                        <Button
                                                            ghost
                                                            onClick={() => {
                                                                if (current) {
                                                                    let currentRoute;
                                                                    if (current === 'overview') {
                                                                        currentRoute = 'dashboard';
                                                                    } else {
                                                                        currentRoute = current;
                                                                    }
                                                                    history.push(`/league-manager/${league.slug}/${currentRoute}`);
                                                                } else {
                                                                    history.push(`/league-manager/${league.slug}`);
                                                                }
                                                            }}
                                                        >
                                                            <Icon type="setting" />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            ghost
                                                            onClick={() => {
                                                                if (current) {
                                                                    let currentRoute;
                                                                    if (current === 'overview') {
                                                                        currentRoute = 'dashboard';
                                                                    } else {
                                                                        currentRoute = current;
                                                                    }
                                                                    history.push(`/league-manager/${league.slug}/${currentRoute}`);
                                                                } else {
                                                                    history.push(`/league-manager/${league.slug}`);
                                                                }
                                                            }}
                                                        >
                                                            <Icon type="setting" /> Manage
                                                        </Button>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {isLeagueMember === true ? (
                                                <Button type="danger" ghost onClick={() => showConfirmLeave(league)} style={{ marginLeft: 5 }}>
                                                    Leave League
                                                </Button>
                                            ) : (
                                                <Button ghost onClick={() => showConfirmJoin(league)} style={{ marginLeft: 5 }}>
                                                    Join League
                                                </Button>
                                            )}
                                        </div>
                                    </SectionHeader>

                                    <Tabs
                                        defaultActiveKey={current}
                                        activeKey={current}
                                        animated={{ inkBar: true, tabPane: false }}
                                        // renderTabBar={() => <ScrollableInkTabBar />}
                                        // renderTabContent={() => <TabContent />}
                                        onTabClick={handleTabClick}
                                        className="tabs-bg"
                                        tabPosition="top"
                                        tabBarStyle={{
                                            textTransform: 'uppercase',
                                            paddingLeft: 40,
                                            paddingRight: 40,
                                            paddingTop: 0,
                                            // backgroundColor: '#132339',
                                            // backgroundColor: '#070f20',
                                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                            color: '#fff',
                                            zIndex: 1,
                                            position: 'relative',
                                            // borderBottom: 'none',
                                            // borderTop: '1px solid rgba(255,255,255,0.1)'
                                            borderBottom: '1px solid transparent',
                                            borderTop: '1px solid rgba(255,255,255,0.1)',
                                            height: 'auto',
                                        }}
                                    >
                                        <TabPane tab="Overview" key="overview">
                                            <DPLeagueOverview {...props} league={league} isLeagueMember={isLeagueMember} />
                                        </TabPane>
                                        {league.has_division_support === true && (
                                            <TabPane tab={`Divisions (${league.league_divisions_aggregate.aggregate.count})`} key="divisions">
                                                <DPLeagueDivisions {...props} league={league} isLeagueMember={isLeagueMember} />
                                            </TabPane>
                                        )}
                                        <TabPane tab={`Players (${league.league_players_aggregate.aggregate.count})`} key="players">
                                            <DPLeaguePlayers {...props} league={league} isLeagueMember={isLeagueMember} />
                                        </TabPane>
                                        <TabPane tab="Standings" key="standings">
                                            <DPLeagueStandings {...props} league={league} isLeagueMember={isLeagueMember} />
                                        </TabPane>
                                        <TabPane tab="Challenges" key="challenges">
                                            <DPLeagueChallenges {...props} league={league} isLeagueMember={isLeagueMember} />
                                        </TabPane>

                                        {/* <TabPane tab="Teams" key="teams">
												Teams
											</TabPane>
											<TabPane tab="Players" key="players">
												Players
											</TabPane> */}

                                        {/* <TabPane tab="Live Scores" key="scores">
													Scores
												</TabPane>
												<TabPane tab="Standings" key="results">
													Standings
												</TabPane>
												<TabPane tab="Stats" key="stats">
													Stats
												</TabPane> */}
                                    </Tabs>
                                </div>
                            )}
                        />
                    </Switch>
                );
            }}
        </Query>
    );
}

export default withRouter(DPLeagueDetail);
