import React, { useState } from 'react';
// import objtocsv from 'objects-to-csv';
import { message, Table, Icon, Row, Col, Typography, Button } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import { isoCountries } from '../../../utils/isoCountryCodes';
import SectionTitle from '../../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
// import TBManageButtons from './TBManageButtons';
import StandingsTable from './StandingsTable';
import CircularLoader from '../../../components/CircularLoader';
import { Subscription } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISION_TEAM_STATS_SUBSCRIPTION } from '../data/subscriptions';
// import { GET_TOURNAMENT_PLAYER_STANDINGS } from './data/queries';
import moment from 'moment';
import _ from 'lodash';
import { Parser } from 'json2csv';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function DPDivisionStandings(props) {
    const { league, division, client } = props;
    const [tableData, setTableData] = useState([]);
    console.log('division', division);

    function calcDuration(challenger1_times, challenger2_times) {
        const challenger1Duration = _.compact(
            challenger1_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        const challenger2Duration = _.compact(
            challenger2_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        return challenger1Duration + challenger2Duration;
    }

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22}>
                        <Card
                            // title="Players"
                            bodyStyle={{ padding: 0 }}
                            // style={{
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // }}
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Standings
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    />
                                </div>
                            }
                        >
                            <Subscription
                                subscription={GET_LEAGUE_DIVISION_TEAM_STATS_SUBSCRIPTION}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ league_id: league.id, division_id: division.id }}
                                onSubscriptionData={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                    const tableData =
                                        data &&
                                        data.league_teams &&
                                        data.league_teams
                                            .map((item, index) => {
                                                const homeTeamMatchesWon =
                                                    item && item.league_home_team_matches_won && item.league_home_team_matches_won.aggregate.count;
                                                const visitorTeamMatchesWon =
                                                    item && item.league_visitor_team_matches_won && item.league_visitor_team_matches_won.aggregate.count;
                                                const matchesWon = homeTeamMatchesWon + visitorTeamMatchesWon;
                                                const homeTeamMatchesLost =
                                                    item && item.league_home_team_matches_lost && item.league_home_team_matches_lost.aggregate.count;
                                                const visitorTeamMatchesLost =
                                                    item && item.league_visitor_team_matches_lost && item.league_visitor_team_matches_lost.aggregate.count;
                                                const matchesLost = homeTeamMatchesLost + visitorTeamMatchesLost;
                                                const homeTeamGamesWon =
                                                    item && item.league_home_team_games && item.league_home_team_games.aggregate.sum.home_team_score;
                                                const visitorTeamGamesWon =
                                                    item && item.league_visitor_team_games && item.league_visitor_team_games.aggregate.sum.visitor_team_score;
                                                const gamesWon = homeTeamGamesWon + visitorTeamGamesWon;
                                                const homeTeamGamesLost =
                                                    item && item.league_home_team_games && item.league_home_team_games.aggregate.sum.visitor_team_score;
                                                const visitorTeamGamesLost =
                                                    item && item.league_visitor_team_games && item.league_visitor_team_games.aggregate.sum.home_team_score;
                                                const gamesLost = homeTeamGamesLost + visitorTeamGamesLost;
                                                const homeTeamPointsWon =
                                                    item && item.league_home_team_points && item.league_home_team_points.aggregate.sum.home_team_points;
                                                const visitorTeamPointsWon =
                                                    item &&
                                                    item.league_visitor_team_points &&
                                                    item.league_visitor_team_points.aggregate.sum.visitor_team_points;
                                                const pointsWon = homeTeamPointsWon + visitorTeamPointsWon;
                                                const homeTeamPointsLost =
                                                    item && item.league_home_team_points && item.league_home_team_points.aggregate.sum.visitor_team_points;
                                                const visitorTeamPointsLost =
                                                    item && item.league_visitor_team_points && item.league_visitor_team_points.aggregate.sum.home_team_points;
                                                const pointsLost = homeTeamPointsLost + visitorTeamPointsLost;
                                                const totalMatches = matchesWon + matchesLost;
                                                const totalGames = gamesWon + gamesLost;
                                                const totalPoints = pointsWon + pointsLost;

                                                const matches = {
                                                    matches_won: matchesWon,
                                                    matches_won_pct: parseInt((matchesWon / totalMatches) * 100 || 0),
                                                    matches_lost: matchesLost,
                                                    matches_lost_pct: parseInt((matchesLost / totalMatches) * 100 || 0),
                                                };

                                                const games = {
                                                    games_won: gamesWon,
                                                    games_won_pct: parseInt((gamesWon / totalGames) * 100 || 0),
                                                    games_lost: gamesLost,
                                                    games_lost_pct: parseInt((gamesLost / totalGames) * 100 || 0),
                                                };

                                                const points = {
                                                    points_won: pointsWon,
                                                    points_won_pct: parseInt((pointsWon / totalPoints) * 100 || 0),
                                                    points_lost: pointsLost,
                                                    points_lost_pct: parseInt((pointsLost / totalPoints) * 100 || 0),
                                                };

                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                    points: item.points,
                                                    key: index,
                                                    matches: {
                                                        stats: {
                                                            ...matches,
                                                            ...games,
                                                            ...points,
                                                        },
                                                    },
                                                };
                                            })
                                            .sort((a, b) => {
                                                return (
                                                    b.matches.stats.matches_won_pct - a.matches.stats.matches_won_pct ||
                                                    b.matches.stats.games_won_pct - a.matches.stats.games_won_pct ||
                                                    b.matches.stats.points_won_pct - a.matches.stats.points_won_pct ||
                                                    a.matches.stats.matches_lost - b.matches.stats.matches_lost ||
                                                    a.matches.stats.games_lost - b.matches.stats.games_lost ||
                                                    a.matches.stats.points_lost - b.matches.stats.points_lost
                                                );
                                            })
                                            .map((item, index) => {
                                                return {
                                                    ...item,
                                                    place: index + 1,
                                                };
                                            });

                                    return <StandingsTable divisionID={division.id} formattedResults={tableData} />;
                                }}
                            </Subscription>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
