import React, { useState, useRef } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { message, Typography, Button, Row, Col } from 'antd';
import { Formik, ErrorMessage } from 'formik';
import { DatePicker, Form, FormItem, Input, Radio, Select, SubmitButton, Checkbox } from 'formik-antd';
import { UPDATE_GHOST_GAMES_MUTATION, UPDATE_GHOST_GAMES_BY_SLUG_MUTATION } from '../../data/mutations';
import { GET_GHOST_EVENTS_TYPE, GET_GHOST_EVENT_BY_SLUG_QUERY } from '../../data/queries';
import slugify from 'slugify';
import { Query } from '@apollo/client/react/components';
import * as Yup from 'yup';
import {
    createStep,
    createGhostGameFormValues,
    createGhostGameFormPublicRadio,
    createGhostGameFormRebuyRadio,
    ghostEventId,
    completedStepAtom,
} from '../../data/atoms';
import RadioPublic from '../GECreate/RadioPublic';
import RadioRebuys from '../GECreate/RadioRebuys';
import { displayEnumType } from '../../../../utils/displayEnumType';
import moment from 'moment-timezone';

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;

// const UpdateSchema = Yup.object().shape({
//     address1: Yup.string().required('Address is required'),
//     city: Yup.string().required('City is required'),
//     country: Yup.string().required('Country is required'),
//     region: Yup.string().required('Region is required'),
//     postal_code: Yup.string().required('Postal Code is required'),
//     email_address: Yup.string().email('Email is invalid').required('Email address is required'),
//     website: Yup.string().url('Website must be a valid url e.g. http://www.example.com)').nullable(),
//     phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
//     fax_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
//     social_facebook: Yup.string().nullable(),
//     social_instagram: Yup.string().nullable(),
//     social_twitter: Yup.string().nullable()
// });

export default function SettingsForm(props) {
    const { authState, client, userId, history, slug } = props;
    const setActiveStep = useSetRecoilState(createStep);
    const setRadioPublic = useSetRecoilState(createGhostGameFormPublicRadio);
    const radioPublicValue = useRecoilValue(createGhostGameFormPublicRadio);
    const setGhostEventId = useSetRecoilState(ghostEventId);
    const ghostEventIdValue = useRecoilValue(ghostEventId);
    const setCompletedStepAtom = useSetRecoilState(completedStepAtom);
    const [rebuyRadio, setRadioRebuy] = useRecoilState(createGhostGameFormRebuyRadio);
    const [isPublicRadio, setIsPublicRadio] = useState();
    // const rebuyRadio = RecoilValue(createGhostGameFormRebuyRadio);

    function createSlug(name) {
        return slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });
    }

    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        type: Yup.string().required('Game type is required'),
        number_of_attempts: Yup.string()
            .matches(/^[0-9][0-9]*$/, 'Only numbers')
            .matches(/[1-9]/, 'Number must be greater than 0')
            .required('Number of attempts is required'),
        number_of_games: Yup.string()
            .matches(/^[0-9][0-9]*$/, 'Only numbers')
            .matches(/[1-9]/, 'Number must be greater than 0')
            .required('Number of game is required'),
        start_date_time: Yup.string().nullable().required('Start date required'),
        end_date_time: Yup.string().nullable().required('End date required'),
        max_players: Yup.string()
            .matches(/^[0-9][0-9]*$/, 'Only numbers')
            .matches(/[1-9]/, 'Number must be greater than 0')
            .required('Number of game is required'),
        ...(rebuyRadio && {
            rebuy_cost: Yup.string().required('Rebuy cost is required'),
            number_of_rebuys: Yup.string().required('Number of rebuys required'),
        }),
    });

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        return tzDate;
    }

    function onChangeRebuysRadio(e) {
        setRadioRebuy(e.target.value);
    }

    function onChangePublicRadio(e) {
        setIsPublicRadio(e.target.value);
    }

    return (
        <React.Fragment>
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Title level={4}>Ghost Event Settings</Title>
                <Query
                    query={GET_GHOST_EVENT_BY_SLUG_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{
                        slug: slug,
                    }}
                    onCompleted={(data) => {
                        const ghostData = data && data.ghost_games[0];

                        setIsPublicRadio(ghostData.is_public);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return null;
                        if (error) return null;

                        console.log('data', data);

                        const ghostData = data.ghost_games[0];

                        const initialFormValues = {
                            name: ghostData.name,
                            type: ghostData.type,
                            max_players: ghostData.max_players,
                            number_of_attempts: ghostData.number_of_attempts,
                            number_of_racks: ghostData.number_of_racks,
                            start_date_time: ghostData.start_date,
                            end_date_time: ghostData.end_date,
                            cost: ghostData.cost,
                            payment_link: ghostData.payment_link,
                            description: ghostData.description,
                            number_of_rebuys: ghostData.number_of_rebuys,
                            rebuy_cost: ghostData.rebuy_cost,
                            // is_public: ghostData.is_public ? true : false,
                            allow_rebuys: ghostData.rebuys_allowed ? true : false,
                            rebuys: ghostData.number_of_rebuys || ghostData.rebuy_cost ? true : false,
                        };

                        // console.log("id", ghostEventIdValue);
                        return (
                            <Formik
                                initialValues={initialFormValues}
                                // validationSchema={schema}
                                onSubmit={(data) => {
                                    const objects = {
                                        manager_id: userId,
                                        name: data.name,
                                        slug: createSlug(data.name),
                                        type: data.type,
                                        start_date: data.start_date_time,
                                        end_date: data.end_date_time,
                                        payment_link: data.payment_link,
                                        cost: data.cost,
                                        number_of_attempts: data.number_of_attempts,
                                        number_of_racks: data.number_of_racks,
                                        description: data.description,
                                        number_of_rebuys: data.number_of_rebuys ? data.number_of_rebuys : 0,
                                        rebuy_cost: data.rebuy_cost ? data.rebuy_cost : 0,
                                        max_players: data.max_players,
                                        is_public: data.is_public === undefined || data.is_public === false ? false : true,
                                        rebuys_allowed: data.allow_rebuys,
                                    };

                                    client
                                        .mutate({
                                            mutation: UPDATE_GHOST_GAMES_BY_SLUG_MUTATION,
                                            variables: {
                                                slug: ghostData.slug,
                                                changes: objects,
                                            },
                                        })
                                        .then((res) => {
                                            const slug = res.data.update_ghost_games.returning[0].slug;
                                            const oldSlug = createSlug(initialFormValues.name);

                                            if (slug !== oldSlug) history.push(`/ghost-event-manager/${slug}/settings`);

                                            message.success('Ghost event updated');
                                        })
                                        .catch((err) => {
                                            message.error(`An error has occured when updating a ghost event`);
                                            console.log('ghost event update error', err);
                                        });
                                }}
                                render={(values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValidating) => {
                                    console.log('fv', initialFormValues);
                                    return (
                                        <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                            <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                                <Input label="Name" name="name" size="large" placeholder="Event Name" />
                                            </FormItem>
                                            <FormItem label="Description" name="description">
                                                <TextArea label="description" name="description" rows={7} />
                                            </FormItem>
                                            <FormItem label="Type" name="type" required hasFeedback showValidateSuccess>
                                                <Query query={GET_GHOST_EVENTS_TYPE} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                                    {({ loading, error, data }) => {
                                                        if (loading) return null;
                                                        if (error) return null;
                                                        if (!data && !data.ghost_games_type) return <div>no data available</div>;

                                                        return (
                                                            <Select name="type" placeholder="Type" size="large">
                                                                {data &&
                                                                    data.ghost_games_type &&
                                                                    data.ghost_games_type.map((val, key) => {
                                                                        return (
                                                                            <Option value={val.value} key={key}>
                                                                                {displayEnumType(val.value)}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                            </Select>
                                                        );
                                                    }}
                                                </Query>
                                            </FormItem>
                                            <FormItem label="Max Players" name="max_players" required hasFeedback showValidateSuccess>
                                                <Input name="max_players" placeholder="Max Players" />
                                            </FormItem>
                                            <FormItem label="Number of Attemps" name="number_of_attempts" required hasFeedback showValidateSuccess>
                                                <Input name="number_of_attempts" placeholder="Number of Attemps" />
                                            </FormItem>
                                            <FormItem label="Number of Racks per Attempt" name="number_of_racks" required hasFeedback showValidateSuccess>
                                                <Input label="Number of racks" name="number_of_racks" placeholder="Number of Racks per Attempt" />
                                            </FormItem>
                                            <FormItem label="Start Date & Time" name="start_date_Time" required hasFeedback showValidateSuccess>
                                                <DatePicker showTime name="start_date_time" size="large" format="YYYY-MM-DD hh:mm A z" onOk={onOk} />
                                            </FormItem>
                                            <FormItem label="End Date & Time" name="end_date_Time" required hasFeedback showValidateSuccess>
                                                <DatePicker showTime name="end_date_time" size="large" format="YYYY-MM-DD hh:mm A z" onOk={onOk} />
                                            </FormItem>
                                            <FormItem label="Cost" name="cost">
                                                <Input label="cost" name="cost" />
                                            </FormItem>
                                            <FormItem label="Payment Link" name="payment_link">
                                                <Input label="payment_link" name="payment_link" />
                                            </FormItem>
                                            <RadioRebuys value={initialFormValues.rebuys} onChange={onChangeRebuysRadio} />
                                            <FormItem label="Public" name="is_public">
                                                <Radio.Group name="is_public" value={isPublicRadio} onChange={onChangePublicRadio}>
                                                    <Radio value={true}>Yes - Your game is viewable and anyone can join.</Radio>
                                                    <br />
                                                    <Radio value={false}>No - Your game is invite only.</Radio>
                                                </Radio.Group>
                                            </FormItem>
                                            <SubmitButton size="large" type="primary" disabled={false} loading={false}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text style={{ color: '#fff' }}>Save</Text>
                                                </div>
                                            </SubmitButton>
                                        </Form>
                                    );
                                }}
                            />
                        );
                    }}
                </Query>
            </div>
        </React.Fragment>
    );
}
