import React, { useState, useEffect } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import { GET_MATCH_BY_SLUG_QUERY } from './data/queries';
import { GET_MATCH_BY_SLUG_SUBSCRIPTION } from './data/subscriptions';
import TableScoreCard from '../../digitalpool/tournaments/TableScoreCard';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';

const styles = {};

function StreamTableOverlay(props) {
    const { authState, history, match } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const params = props.match.params;
    const slug = params.slug;

    return (
        <Subscription
            subscription={GET_MATCH_BY_SLUG_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: slug }}
            onSubscriptionData={(data) => {
                console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.matches) {
                    return <div>Match not found.</div>;
                }
                if (data && data.matches.length === 0) {
                    return <div>Match not found.</div>;
                }
                const matchData = data.matches[0];

                return (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <TableScoreCard
                            // title={`Match ${matchData.match_number}`}
                            // table={matchData.table_id}
                            // label={matchData.table_name}
                            matchData={{
                                ...matchData,
                                challenger1_name: matchData.player_fargo ? `${matchData.player_name} (${matchData.player_fargo})` : matchData.player_name,
                                challenger1_country: matchData.player_country,
                                challenger1_score: matchData.player_score,
                                challenger1_points: matchData.player_points,
                                challenger2_name: matchData.opponent_fargo
                                    ? `${matchData.opponent_name} (${matchData.opponent_fargo})`
                                    : matchData.opponent_name,
                                challenger2_country: matchData.opponent_country,
                                challenger2_score: matchData.opponent_score,
                                challenger2_points: matchData.opponent_points,
                                race_to: matchData.race_to,
                                start_time: matchData.start_date_time,
                                end_time: matchData.start_end_time,
                            }}
                            hideStatus
                            hideTime
                            status={matchData.status}
                            gameType={matchData.game_type}
                            hoverable={false}
                            tableSize={'large'}
                        />
                    </div>
                );
            }}
        </Subscription>
    );
}

export default withRouter(StreamTableOverlay);
