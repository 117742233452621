import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';
import { GET_TOURNAMENT_TABLE_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import TVDisplayFire from './TVDisplayFire';
import TVDisplayStraightPool from './TVDisplayStraightPool';

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function DPTournamentTVScoreDisplayFire(props) {
    const { client } = props;
    const { match, authState } = props;
    const params = match.params;
    const tournamentSlug = params.slug;
    const tableSlug = params.table;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: 'auto',
                // minHeight: '100%',
                justifyContent: 'center',
                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
            }}
        >
            <div
                style={{ flex: 1, alignItems: 'center', paddingLeft: 20, paddingRight: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
            >
                <Query query={GET_TOURNAMENT_BY_SLUG} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} variables={{ slug: tournamentSlug }}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;
                        if (data && !data.tournaments) {
                            return <div style={styles.container}>No tournament found.</div>;
                        }
                        if (data && data.tournaments.length === 0) {
                            return <div style={styles.container}>No tournament found.</div>;
                        }

                        const tournamentData = data.tournaments[0];
                        console.log(tournamentData);

                        return (
                            <React.Fragment>
                                {/* {tournamentData.logo && (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                        <img src={tournamentData.logo} alt="Logo" height={160} />
                                    </div>
                                )} */}
                                <Subscription
                                    subscription={GET_TOURNAMENT_TABLE_SUBSCRIPTION}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ tournament_id: tournamentData.id, table_slug: tableSlug }}
                                    onSubscriptionData={(data, error, loading) => {
                                        // console.log(data);
                                    }}
                                >
                                    {({ loading, error, data, refetch }) => {
                                        if (loading) return <CircularLoader />;
                                        if (error) return <div>Error: {error.message}</div>;
                                        if (data && !data.pool_tables) {
                                            return <div style={styles.container}>Match not found.</div>;
                                        }
                                        if (data && data.pool_tables.length === 0) {
                                            return <div style={styles.container}>Match not found.</div>;
                                        }

                                        const tableData = data.pool_tables && data.pool_tables[0];
                                        const matchData = tableData && tableData.tournament_match_table && tableData.tournament_match_table[0];

                                        if (data && !matchData) {
                                            return <div style={styles.container}>Match not found.</div>;
                                        }
                                        if (data && !matchData) {
                                            return <div style={styles.container}>Match not found.</div>;
                                        }
                                        console.log(matchData);

                                        return (
                                            <React.Fragment>
                                                {tournamentData && tournamentData.game_type === 'STRAIGHT_POOL' ? (
                                                    <TVDisplayStraightPool
                                                        tournamentData={tournamentData}
                                                        matchData={matchData}
                                                        tableData={tableData}
                                                        {...props}
                                                    />
                                                ) : (
                                                    <TVDisplayFire tournamentData={tournamentData} matchData={matchData} tableData={tableData} {...props} />
                                                )}
                                            </React.Fragment>
                                        );
                                    }}
                                </Subscription>
                            </React.Fragment>
                        );
                    }}
                </Query>
            </div>
        </div>
    );
}

export default withRouter(DPTournamentTVScoreDisplayFire);
