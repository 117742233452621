import React, { useState } from 'react';
import { Table, Tabs, Icon, Button, Row, Empty, Col, Typography, Modal } from 'antd';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import Card from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { GET_VENUE_MARKERS_QUERY, SEARCH_VENUES_QUERY } from './data/queries';
import { Query } from '@apollo/client/react/components';
import SearchFilterVenues from './SearchFilterVenues';
import GoogleMapReact from 'google-map-react';
import { EnvironmentFilled } from '@ant-design/icons';
import './map.css';

const { Title, Text } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

function VenueMap(props) {
    const { client, authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [filters, setFilters] = useState({
        venueType: 'all',
        tableSize: 'all',
        tableManufacturer: 'all',
    });
    const [searchValue, setSearchValue] = useState();
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const LocationPin = ({ text }) => (
        <div className="pin">
            <EnvironmentFilled className="pin-icon" />
            {searchResults && searchResults.length > 0 && <p className="pin-text">{text}</p>}
        </div>
    );

    function venueTypeFilter(venue) {
        const filter = filters.venueType;
        if (filter && filter !== 'all') {
            return venue.venue_type === filter;
        } else {
            return venue;
        }
    }

    function tableSizeFilter(venue) {
        const filter = filters.tableSize;
        const tables =
            venue.pool_tables &&
            venue.pool_tables.map((table) => {
                return table.size;
            });
        if (filter && filter !== 'all') {
            return tables.includes(filter);
        } else {
            return venue;
        }
    }

    function tableManufacturerFilter(venue) {
        const filter = filters.tableManufacturer;
        const tables =
            venue.pool_tables &&
            venue.pool_tables.map((table) => {
                return table.make;
            });
        if (filter && filter !== 'all') {
            return tables.includes(filter);
        } else {
            return venue;
        }
    }

    async function handleVenueSearch(value) {
        setSearchValue(value);
        if (value) {
            setSearching(true);
            const res = await client.query({
                query: SEARCH_VENUES_QUERY,
                variables: {
                    name: `%${value}%`,
                },
            });
            setSearching(false);
            const venues = res.data.venues;
            setSearchResults(venues);
        }
    }

    function handleVenueTypeChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            venueType: value,
        });
    }

    function handleTableSizeChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            tableSize: value,
        });
    }

    function handleManufacturerChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            tableManufacturer: value,
        });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Venue Map"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                subtitle={breakpoint !== 'mobile' && 'Locate pool rooms near you and across the world.'}
                subtitleStyle={{ color: '#fff' }}
                height={breakpoint === 'mobile' ? 80 : 100}
                theme="digitalpool"
            />

            <SectionContent>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Query
                            query={GET_VENUE_MARKERS_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange
                            awaitRefetchQueries
                            // variables={{ userId: authState && authState.user && authState.user.id }}
                            onCompleted={(data) => {
                                // console.log(data);
                                // const venues = data.venues;
                                // console.log('venues', venues);
                                // venues.forEach((venue) => {
                                //     if (venue.address1) {
                                //         return geocodeAddress(venue);
                                //     }
                                // });
                                // setVenues(venues);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading)
                                    return (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                            <Text style={{ marginLeft: 10 }}>Loading...</Text>
                                        </div>
                                    );
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                if ((data && !data.venues) || (data && data.venues.length === 0)) {
                                    return (
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>No venues found.</Text>}
                                            style={{ margin: 10 }}
                                        />
                                    );
                                }

                                let filteredVenues;

                                if (searchResults && searchValue) {
                                    filteredVenues = searchResults.filter(venueTypeFilter).filter(tableSizeFilter).filter(tableManufacturerFilter);
                                } else {
                                    filteredVenues = data.venues.filter(venueTypeFilter).filter(tableSizeFilter).filter(tableManufacturerFilter);
                                }

                                return (
                                    <React.Fragment>
                                        <div
                                            style={{
                                                // background: 'linear-gradient( #fff, #fafafa)',
                                                // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
                                                padding: '0px',
                                                // borderRadius: 10,
                                                // overflow: 'hidden',
                                                marginTop: 15,
                                            }}
                                        >
                                            <SearchFilterVenues
                                                placeholder="Search all venues"
                                                venues={searchResults && searchValue ? searchResults : data.venues}
                                                searching={searching}
                                                onSearchVenue={handleVenueSearch}
                                                onVenueTypeChange={handleVenueTypeChange}
                                                onTableSizeChange={handleTableSizeChange}
                                                onTableManufacturerChange={handleManufacturerChange}
                                                {...props}
                                            />
                                        </div>

                                        <Row gutter={16} justify="center" type="flex">
                                            <Col xs={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                <div className="map">
                                                    <div className="google-map">
                                                        <GoogleMapReact
                                                            bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY }}
                                                            defaultCenter={{
                                                                address: '1600 Amphitheatre Parkway, Mountain View, california.',
                                                                lat: 37.42216,
                                                                lng: -122.08427,
                                                            }}
                                                            defaultZoom={10}
                                                            yesIWantToUseGoogleMapApiInternals
                                                            onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
                                                            options={() => {
                                                                return {
                                                                    scrollwheel: true,
                                                                    zoomControl: true,
                                                                    zoomControlOptions: {
                                                                        position: 1, // google.maps.ControlPosition.LEFT_TOP
                                                                    },
                                                                    minZoom: 3,
                                                                    zoom: 10,
                                                                    maxZoom: 18,
                                                                    disableDoubleClickZoom: true,
                                                                };
                                                            }}
                                                        >
                                                            {filteredVenues &&
                                                                filteredVenues.map((venue, index) => {
                                                                    return <LocationPin key={index} lat={venue.lat} lng={venue.lng} text={venue.name} />;
                                                                })}
                                                        </GoogleMapReact>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default VenueMap;
