import React, { useState } from 'react';
import SectionWrapper from '../../components/SectionWrapper';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import Card from '../../components/Card';
import CircularLoader from '../../components/CircularLoader';
import { Table, Tabs, Icon, Button, Row, Empty, Col, Typography, Modal } from 'antd';
import { GET_USER_VENUE_COUNT, GET_ALL_VENUE_COUNT } from './venues/data/queries';
import AddVenueModal from './venues/AddVenueModal';
import AllVenuesTable from './venues/AllVenuesTable';
import MyVenuesTable from './venues/MyVenuesTable';
import AdZoneTemplate from '../../components/advertising/AdZoneTemplate';
import { useMutation, useQuery } from '@apollo/react-hooks';

const { TabPane } = Tabs;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

function Venues(props) {
    const { client, authState } = props;
    const [selectedVenueId, setSelectedVenueId] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const allVenues = useQuery(GET_ALL_VENUE_COUNT);
    const myVenues = useQuery(GET_USER_VENUE_COUNT, {
        variables: { userId: authState && authState.user && authState.user.id },
    });

    const allVenueCount = (allVenues && allVenues.data && allVenues.data.venues_aggregate && allVenues.data.venues_aggregate.aggregate.count) || 0;

    const myVenueCount = (myVenues && myVenues.data && myVenues.data.venues_aggregate && myVenues.data.venues_aggregate.aggregate.count) || 0;

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleAddVenue() {
        setModalVisible(true);
        setSelectedVenueId(null);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Venues"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                subtitle={breakpoint !== 'mobile' && 'Find and create venues to host leagues and tournaments.'}
                subtitleStyle={{ color: '#fff' }}
                height={breakpoint === 'mobile' ? 80 : 100}
                theme="digitalpool"
            >
                {authState && authState.user && authState.user.id && (
                    <React.Fragment>
                        {breakpoint === 'mobile' ? (
                            <Button size="large" type="ghost" ghost onClick={handleAddVenue}>
                                <Icon type="plus" />
                            </Button>
                        ) : (
                            <Button size="large" type="ghost" ghost onClick={handleAddVenue}>
                                <Icon type="plus" /> Add Venue
                            </Button>
                        )}
                    </React.Fragment>
                )}
            </SectionHeader>

            <SectionContent>
                <Row gutter={16}>
                    <Col xs={24}>
                        <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                    </Col>
                    <Col xs={24}>
                        <Card bodyStyle={{ padding: 0 }}>
                            <Tabs
                                defaultActiveKey="all-venues"
                                animated={{ inkBar: true, tabPane: false }}
                                tabBarStyle={{
                                    textTransform: 'uppercase',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                <TabPane tab={`All venues (${allVenueCount})`} key="all-venues">
                                    <AllVenuesTable {...props} totalVenues={allVenueCount} onAddVenue={handleAddVenue} />
                                </TabPane>

                                <TabPane tab={`My venues (${myVenueCount})`} key="my-venues">
                                    <MyVenuesTable {...props} totalVenues={myVenueCount} onAddVenue={handleAddVenue} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>

                <AddVenueModal
                    {...props}
                    selectedVenueId={selectedVenueId}
                    // venue={
                    // 	venues.filter((item, index) => {
                    // 		return item.id === selectedVenueId;
                    // 	})[0]
                    // }
                    modalVisible={modalVisible}
                    onModalOk={handleModalOk}
                    onModalCancel={handleModalCancel}
                />
            </SectionContent>
        </SectionWrapper>
    );
}

export default Venues;
