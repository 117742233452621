import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Icon, Upload, Avatar, Typography } from 'antd';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import axios from 'axios';
import { gql } from '@apollo/client';

const { Dragger } = Upload;
const { Title } = Typography;

function TBLogoSettingsForm(props) {
    const [image, setImage] = useState();
    const fileReader = new FileReader();

    useEffect(() => {
        if (image) {
            props.client
                .mutate({
                    mutation: gql`
                        mutation update_tournaments($id: Int, $logoUrl: String) {
                            update_tournaments(where: { id: { _eq: $id } }, _set: { logo: $logoUrl }) {
                                affected_rows
                                returning {
                                    id
                                    name
                                    slug
                                    logo
                                }
                            }
                        }
                    `,
                    variables: {
                        id: props.tournament.id,
                        logoUrl: image,
                    },
                })
                .then(async (res) => {
                    const results = res.data.update_tournaments.returning[0];
                    // console.log(results);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [image, props.tournament, props.client]);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG files!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const onChange = (info) => {
        if (!fileReader.onloadend) {
            // fileReader.onloadend = (obj) => {
            // 	setImage(obj.srcElement.result);
            // };
            // can be any other read function ( any reading function from
            // previously created instance can be used )
            const { status } = info.file;
            // if (status !== 'uploading') {
            // 	console.log(info.file, info.fileList);
            // }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            // fileReader.readAsArrayBuffer(info.file.originFileObj);
        }
    };

    const customRequest = async (option) => {
        const { onSuccess, onError, file, action, onProgress } = option;
        // const url = action;

        const data = {
            S3BucketName: 'digitalpool',
            key: `tournament-logos/${file.name}`,
            contentType: file.type,
        };

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }

        // Retrieve the Cloud Function
        var getPost = await httpsCallable(functions, 'uploadImageS3');
        // await new Promise((resolve) => waitUntilImageLoaded(resolve));
        // Call the cloud function
        return getPost(data)
            .then((result) => {
                // S3 pre-signed URL
                const options = {
                    onUploadProgress: (e) => {
                        onProgress({ percent: (e.loaded / e.total) * 100 });
                    },
                    headers: {
                        'Content-Type': file.type,
                        'x-amz-acl': 'public-read',
                        'x-amz-server-side-encryption': 'AES256',
                    },
                };

                const signedRequest = result.data.signedRequest;
                const imageUrl = result.data.url;
                // Run a HTTP PUT request to the pre-signed URL
                return axios
                    .put(signedRequest, file, options)
                    .then((response) => {
                        setImage(imageUrl);
                        onSuccess(response.body);
                    })
                    .catch((err) => {
                        onError(err);
                    });
            })
            .catch((e) => {
                console.log(e);
                return e;
            });
    };

    return (
        <div style={{ maxWidth: 400 }}>
            <Dragger
                name="file"
                multiple={false}
                className="logo-uploader"
                // showUploadList={false}
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                onChange={onChange}
            >
                {image || props.tournament.logo ? (
                    <img src={image || props.tournament.logo} alt="Logo" width={300} />
                ) : (
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                )}

                <p className="ant-upload-text" style={{ marginTop: 10, paddingLeft: 24, paddingRight: 24 }}>
                    Click or drag image to this area to upload
                </p>
            </Dragger>
        </div>
    );
}

export default withRouter(TBLogoSettingsForm);
