import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, List, Badge, Tag, Drawer, Empty, Modal, Button } from 'antd';
import PoolTable from '../../../components/PoolTable';
import Card, { Meta } from '../../../components/Card';
import TableScoreCard from './TableScoreCard';
import ZeroState from '../../../components/ZeroState';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import MatchesList from './MatchesList';
import { GET_TOURNAMENT_BRACKET_LIVE_SCORES_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';

const { Title, Text } = Typography;

export default function DPTournamentScores2(props) {
    const { tournament, styles } = props;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const [selectedTableId, setSelectedTableId] = useState();
    const [tables, setTables] = useState([]);
    const [currentMatch, setCurrentMatch] = useState({
        title: '',
        slug: '',
        status: '',
    });

    useEffect(() => {
        return function cleanup() {
            if (drawerVisible === true) {
                setDrawerVisible(false);
            }
        };
    });

    function showDrawer(match) {
        setCurrentMatch(match);
        setDrawerVisible(true);
        window.scrollTo(0, 0);
    }

    function onClose() {
        setDrawerVisible(false);
    }

    function showMatchModal(match) {
        setCurrentMatch(match);
        setMatchModalVisible(true);
    }

    function handleMatchModalOk(e) {
        console.log(e);
        setMatchModalVisible(false);
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    return (
        <SectionWrapper>
            <SectionContent padding="14px 40px">
                <Row gutter={24} justify="center" type="flex" id="table-scores">
                    <Col xs={24}>
                        <Subscription
                            subscription={GET_TOURNAMENT_BRACKET_LIVE_SCORES_SUBSCRIPTION}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ tournament_id: tournament.id }}
                            onSubscriptionData={(data, error, loading) => {
                                if (data && data.subscriptionData && data.subscriptionData.data) {
                                    setTables(data.subscriptionData.data);
                                }
                            }}
                        >
                            {({ loading, error, data }) => {
                                let matchesInProgress =
                                    data &&
                                    data.tournament_brackets.filter((match) => {
                                        return match.status === 'IN_PROGRESS';
                                    });

                                if (loading) return <CircularLoader />;
                                if (error) return <div>Error: {error.message}</div>;
                                if ((data && !matchesInProgress) || (data && matchesInProgress.length === 0)) {
                                    return (
                                        <Row gutter={24} justify="center" type="flex">
                                            <Col xs={24}>
                                                <Card>
                                                    <ZeroState showImage message="No matches currently in progress." style={{ margin: 30 }} {...props} />
                                                </Card>
                                            </Col>
                                        </Row>
                                    );
                                }

                                return (
                                    <MatchesList
                                        {...props}
                                        data={matchesInProgress.map((match) => {
                                            return {
                                                ...match.pool_table,
                                                ...match,
                                            };
                                        })}
                                        showMatchModal={showMatchModal}
                                        onTableSelected={(tableId) => {
                                            setSelectedTableId(tableId);
                                        }}
                                    />
                                );
                            }}
                        </Subscription>
                        <Row gutter={16}>
                            {/* {[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((item, index) => {
								return (
									<Col key={index} xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
										<TableScoreCard
											title={`Match ${index + 1}`}
											status="live"
											onClick={() =>
												showDrawer({
													title: `Match ${index + 1}`,
													slug: `match-${index + 1}`,
													status: 'live'
												})}
										/>
									</Col>
								);
							})} */}
                            {/* <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
								<TableScoreCard
									title="Match 11"
									status="completed"
									onClick={() =>
										showDrawer({
											title: `Match 11`,
											slug: `match-11`,
											status: 'completed'
										})}
								/>
							</Col> */}
                        </Row>
                    </Col>
                </Row>

                <Drawer
                    title={currentMatch.title}
                    // getContainer={() => document.querySelector('#scores')}
                    // mask={false}
                    // placement="bottom"
                    width={400}
                    closable
                    onClose={onClose}
                    visible={drawerVisible}
                >
                    <p>Status: {currentMatch.status}</p>
                </Drawer>
                {/* <Modal
					title={currentMatch && currentMatch.identifier}
					
					visible={matchModalVisible}
					onOk={handleMatchModalOk}
					onCancel={handleMatchModalCancel}
					centered
					transitionName="fade"
					
					maskTransitionName="none"
					footer={[
						<Button key="cancel" onClick={handleMatchModalCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" onClick={handleMatchModalOk}>
							Submit
						</Button>
					]}
				>
					<p>Some contents...</p>
					<p>Some contents...</p>
					<p>Some contents...</p>
				</Modal> */}

                <Modal
                    title="Matches played on this table"
                    // getContainer={() => document.querySelector('.tables')}
                    visible={matchModalVisible}
                    onOk={handleMatchModalOk}
                    onCancel={handleMatchModalCancel}
                    centered
                    transitionName="fade"
                    bodyStyle={{ padding: 0 }}
                    // transitionName="none"
                    maskTransitionName="none"
                    footer={[
                        // <Button key="cancel" onClick={handleScoreModalCancel}>
                        // 	Cancel
                        // </Button>,
                        <Button key="submit" type="primary" onClick={handleMatchModalOk}>
                            OK
                        </Button>,
                    ]}
                >
                    <React.Fragment>
                        <List
                            size="small"
                            loading={false}
                            itemLayout="horizontal"
                            dataSource={
                                tables &&
                                tables.pool_tables &&
                                tables.pool_tables
                                    .filter((table) => {
                                        return table.id === selectedTableId;
                                    })
                                    .map((table, index) => {
                                        return {
                                            matches: table.tournament_match_table,
                                        };
                                    })
                            }
                            header={
                                <div
                                    style={{
                                        padding: '0px 20px',
                                        margin: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Text style={{ fontWeight: 700, width: 50 }}>Round</Text>
                                        <Text style={{ fontWeight: 700, marginLeft: 10 }}>Players</Text>
                                    </div>
                                    <Text style={{ fontWeight: 700 }}>Score</Text>
                                </div>
                            }
                            renderItem={(table) => {
                                return (
                                    <React.Fragment>
                                        {table &&
                                            table.matches &&
                                            table.matches.map((match, index) => (
                                                <List.Item
                                                    key={index}
                                                    style={{
                                                        padding: '10px 20px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Text style={{ width: 60 }}>{match.identifier}</Text>
                                                    <div style={{ textAlign: 'left', width: '100%' }}>
                                                        <Text
                                                            style={{
                                                                marginLeft: 0,
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {match.challenger1_score > match.challenger2_score ? (
                                                                <Badge
                                                                    count="W"
                                                                    style={{
                                                                        backgroundColor: '#52c41a',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            ) : match.challenger1_score < match.challenger2_score ? (
                                                                <Badge count="L" style={{ marginRight: 5 }} />
                                                            ) : null}
                                                            {match.challenger1_name} {match.challenger1_race_to ? `(${match.challenger1_race_to})` : null}
                                                        </Text>
                                                        {' vs. '}
                                                        <Text
                                                            style={{
                                                                marginLeft: 0,
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {match.challenger2_score > match.challenger1_score ? (
                                                                <Badge
                                                                    count="W"
                                                                    style={{
                                                                        backgroundColor: '#52c41a',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            ) : match.challenger2_score < match.challenger1_score ? (
                                                                <Badge count="L" style={{ marginRight: 5 }} />
                                                            ) : null}
                                                            {match.challenger2_name} {match.challenger2_race_to ? `(${match.challenger2_race_to})` : null}
                                                        </Text>
                                                    </div>

                                                    <Text style={{ whiteSpace: 'nowrap', width: 40 }}>
                                                        {match.challenger1_score}-{match.challenger2_score}
                                                    </Text>
                                                </List.Item>
                                            ))}
                                    </React.Fragment>
                                );
                            }}
                        />
                    </React.Fragment>
                </Modal>
            </SectionContent>
        </SectionWrapper>
    );
}
