import React from 'react';
import Flag from 'react-flags';
import twIoc from '../assets/img/flags/tw-ioc-rect.png';
import scotland from '../assets/img/flags/scotland.png';

export default function FlagComponent(props) {
    const { name } = props;
    let size = props.size || 32;
    let flagSize = size;
    let containerSize = 22;

    if (size === 'large') {
        flagSize = 64;
        containerSize = 40;
    } else if (size === 'medium') {
        flagSize = 48;
        containerSize = 32;
    } else if (size === 'small') {
        flagSize = 32;
        containerSize = 22;
    }

    return (
        <span style={{ position: 'relative' }}>
            {name &&
            (name.toLowerCase() === 'tw' || name.toLowerCase() === 'taiwan' || name.toLowerCase() === 'tpe' || name.toLowerCase() === 'chinese taipei') ? (
                <img src={twIoc} width={containerSize} height={containerSize} alt="Flag" />
            ) : name && name.toLowerCase() === 'scotland' ? (
                <img src={scotland} width={containerSize} height={containerSize} alt="Flag" />
            ) : (
                <Flag {...props} />
            )}
        </span>
    );
}
