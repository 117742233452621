import React, { useState, useEffect } from 'react';
import { message, Tabs, List, Avatar, Icon, Statistic, Skeleton, Progress, Button, Descriptions, Row, Col, Typography, Empty } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import worldCup from '../../../assets/img/world-cup.jpg';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import { getGameType, displayEnumType, getCountryName, formatPlayerName, getOrdinalSuffix } from '../../../utils';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import axios from 'axios';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';
import {
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_ALL_TOURNAMENT_TABLES_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_OVERVIEW,
    GET_TOURNAMENT_PLAYER_STATS_QUERY,
    GET_TOURNAMENT_POINTS,
    GET_TOURNAMENT_PAYOUTS,
    GET_TOURNAMENT_PLAYER_STANDINGS_QUERY,
} from './data/queries';
import { GET_TOURNAMENT_PLAYER_STANDINGS_SUBSCRIPTION } from './data/subscriptions';
import { Query, Mutation, Subscription } from '@apollo/client/react/components';
import ResultsTable from './ResultsTable';
import useBreakpoint from 'use-breakpoint';
import ZeroState from '../../../components/ZeroState';
import { Parser } from 'json2csv';
import { TrophyFilled } from '@ant-design/icons';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import _ from 'lodash';
import Podium from './Podium';
import numeral from 'numeral';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    headerImg: {
        height: 250,
        overflow: 'hidden',
        backgroundColor: '#14171d',
        borderRadius: 0,
        backgroundImage: `url(${worldCup})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
};

function DPTournamentOverview(props) {
    const { authState, tournament, client, theme, history } = props;

    const [players, setPlayers] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);
    const [preRegisteredPlayers, setPreRegisteredPlayers] = useState();

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    // https://docs.google.com/spreadsheets/d/1it2tfeUwDvqq_u5zKx2fvB5Y7X3S7gLqUy2pk1FHVVg/edit#gid=0
    // useEffect(() => {
    //     async function getPreRegisteredPlayerData(tournament) {
    //         const registeredPlayers = await axios({
    //             method: 'get',
    //             url: 'https://sheetdb.io/api/v1/9r0yayvz1tp99',
    //         });
    //         const allPlayers = registeredPlayers.data;
    //         const tenBallPlayers = _.compact(
    //             allPlayers.map((row) => {
    //                 if (row.TEN_BALL.toLowerCase().includes('hold') !== true) return row.TEN_BALL;
    //             })
    //         );
    //         const nineBallPlayers = _.compact(
    //             allPlayers.map((row) => {
    //                 if (row.NINE_BALL.toLowerCase().includes('hold') !== true) return row.NINE_BALL;
    //             })
    //         );
    //         const ladiesNineBallPlayers = _.compact(
    //             allPlayers.map((row) => {
    //                 if (row.LADIES_NINE_BALL.toLowerCase().includes('hold') !== true) return row.LADIES_NINE_BALL;
    //             })
    //         );
    //         const onePocketPlayers = _.compact(
    //             allPlayers.map((row) => {
    //                 if (row.ONE_POCKET.toLowerCase().includes('hold') !== true) return row.ONE_POCKET;
    //             })
    //         );

    //         if (tournament.game_type === 'ONE_POCKET') {
    //             setPreRegisteredPlayers(onePocketPlayers);
    //         } else if (tournament.game_type === 'TEN_BALL') {
    //             setPreRegisteredPlayers(tenBallPlayers);
    //         } else if (tournament.game_type === 'NINE_BALL') {
    //             if (tournament.name.includes('Ladies') === true) {
    //                 setPreRegisteredPlayers(ladiesNineBallPlayers);
    //             } else {
    //                 setPreRegisteredPlayers(nineBallPlayers);
    //             }
    //         }
    //     }

    //     if (tournament.name.includes('Montgomery') === true) {
    //         getPreRegisteredPlayerData(tournament);
    //     }
    // }, []);

    function calcDuration(challenger1_times, challenger2_times) {
        const challenger1Duration = _.compact(
            challenger1_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        const challenger2Duration = _.compact(
            challenger2_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        return challenger1Duration + challenger2Duration;
    }

    async function downloadCSV() {
        setCSVDownloadLoading(true);

        let matchData = await client.query({
            query: GET_TOURNAMENT_BRACKET_QUERY,
            variables: {
                tournamentId: tournament.id,
            },
        });

        let tableData = await client.query({
            query: GET_ALL_TOURNAMENT_TABLES_QUERY,
            variables: {
                tournament_id: tournament.id,
            },
        });

        if (!matchData.data.tournament_brackets.length) {
            message.success('There was an error');
            setCSVDownloadLoading(false);
        }

        console.log(matchData.data.tournament_brackets);
        console.log(tableData.data.pool_tables);

        let filename = tournament.slug + '.csv';
        const matches = matchData.data.tournament_brackets
            .filter((match) => {
                return match.is_bye !== true;
            })
            .sort((a, b) => {
                return a.match_number - b.match_number;
            });

        const tables = tableData.data.pool_tables;
        let data = orderAndFormatData(matches, true, tables);
        const fields = [
            'id',
            'round',
            // 'match_id',
            'match_number',
            'identifier',
            // 'table_id',
            'challenger1_name',
            // 'challenger1_country',
            'challenger1_score',
            'challenger1_is_winner',
            'challenger1_is_forfeit',
            'challenger1_is_withdraw',
            'challenger2_name',
            // 'challenger2_country',
            'challenger2_score',
            'challenger2_is_winner',
            'challenger2_is_forfeit',
            'challenger2_is_withdraw',
            'start_time',
            'end_time',
            'duration',
        ];

        const fargoFields = [
            'player1',
            'state1',
            'player2',
            'state2',
            'won1',
            'won2',
            'forfeit1',
            'forfeit2',
            'event_date',
            'game',
            'table_size',
            'event_name',
            'venue_name',
            'location1',
            'location2',
        ];
        // const csv = await new objtocsv(data).toString();

        const json2csvParser = new Parser({ fargoFields });
        const csv = json2csvParser.parse(data);

        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }

        message.success('Exporting completed');
        setCSVDownloadLoading(false);
    }

    function orderAndFormatData(arr, csv = false, tables) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formattedResults = [];

        // let totalTournamentGames = 0;
        // let totalTournamentMatches = 0;
        // let totalTournamentMatchDurationMinutes = 0;
        // let totalTournamentMatchDurationHours = 0;

        for (let i = 0; i < count; i++) {
            if (arr[i].place) {
                if (arr[i].place.includes('-')) {
                    split = arr[i].place.split('-');
                    cNum1 = parseInt(split[0]);
                    cNum2 = parseInt(split[1]);
                }

                for (let j = i + 1; j < count; j++) {
                    if (arr[j].place.includes('-')) {
                        split2 = arr[j].place.split('-');
                        cNum11 = parseInt(split2[0]);
                        cNum21 = parseInt(split2)[1];

                        if (split) {
                            if (cNum2 > cNum21) {
                                tmp = arr[i];
                                arr[i] = arr[j];
                                arr[j] = tmp;
                            }
                        } else {
                            if (parseInt(arr[i].place) > parseInt(cNum21)) {
                                tmp = arr[i];
                                arr[i] = arr[j];
                                arr[j] = tmp;
                            }
                        }
                    } else {
                        if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    }

                    if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                        tmp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = tmp;
                    }
                }
            }

            split = false;

            let player = arr[i];
            let match = arr[i];

            if (player) {
                if (csv) {
                    // const start = moment(match.start_time);
                    // const end = moment(match.end_time);
                    // const duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));

                    // 'player1',
                    // 'state1',
                    // 'player2',
                    // 'state2',
                    // 'won1',
                    // 'won2',
                    // 'event_date',
                    // 'game',
                    // 'table_size',
                    // 'event_name',
                    // 'venue_name',
                    // 'location1',
                    // 'location2'

                    const tableData =
                        tables &&
                        tables.filter((table) => {
                            return table.label === match.table_name;
                        })[0];

                    console.log(tableData);

                    formattedResults.push({
                        player1: match.challenger1_name,
                        won1: match.challenger1_score,
                        forfeit1: match.challenger1_is_forfeit ? 'FF' : null,
                        player2: match.challenger2_name,
                        won2: match.challenger2_score,
                        forfeit2: match.challenger2_is_forfeit ? 'FF' : null,
                        event_date: match.start_time && moment(match.start_time).format('MM/DD/YYYY'),
                        game: displayEnumType(tournament.game_type),
                        table_size: tableData && tableData.size,
                        event_name: tournament.name,
                        venue_name: tournament && tournament.venue && tournament.venue.name,
                        location1: tournament && tournament.venue && tournament.venue.city,
                        location2: tournament && tournament.venue && tournament.venue.region,
                    });

                    // formattedResults.push({
                    // 	id: match.id,
                    // 	round: match.round,
                    // 	match_number: match.match_number,
                    // 	identifier: match.identifier,
                    // 	challenger1_name: match.challenger1_name,
                    // 	challenger1_score: match.challenger1_score,
                    // 	challenger1_is_winner: match.challenger1_is_winner,
                    // 	challenger1_is_forfeit: match.challenger1_is_forfeit,
                    // 	challenger2_name: match.challenger2_name,
                    // 	challenger2_score: match.challenger2_score,
                    // 	challenger2_is_winner: match.challenger2_is_winner,
                    // 	challenger2_is_forfeit: match.challenger2_is_forfeit,
                    // 	start_time: match.start_time,
                    // 	end_time: match.end_time,
                    // 	duration: duration
                    // });
                } else {
                    formattedResults.push({
                        ...(player.id && { id: player.id }),
                        key: i + 1,
                        ...(player.place && { place: player.place }),
                        ...(player.name && { name: player.name }),
                        ...(player.country && { country: player.country }),
                        ...(player.skill_level && { skillLevel: player.skill_level }),
                        ...(player.payouts && player.payouts.money && { earnings: player.payouts.money }),
                        ...(player.payouts && player.payouts.calcutta && { calcutta: player.payouts.calcutta }),
                        ...(player.player_points && player.player_points.points && { points: player.player_points.points }),
                        ...(player.matches && { matches: player.matches }),
                        ...(player.user_id && { user_id: player.user_id }),
                        // totalMatchDurationMinutes,
                        // avgMatchDurationMinutes,
                        // totalMatchDurationHours,
                        // avgMatchDurationHours
                    });
                }
            }
        }

        if (csv) {
            return _.uniqBy(
                formattedResults.sort((a, b) => {
                    return a.match_number - b.match_number;
                }),
                'name'
            );
        } else {
            return _.uniqBy(formattedResults, 'name');
        }
    }

    function formatResultsTableData(data) {
        if (data) {
            // console.log(data);
            let orderedData = orderAndFormatData(data);
            return orderedData;
        }
    }

    function formatPayouts(arr) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formatArr = [];

        for (let i = 0; i < count; i++) {
            if (arr[i].place.includes('-')) {
                split = arr[i].place.split('-');
                cNum1 = parseInt(split[0]);
                cNum2 = parseInt(split[1]);
            }

            const diff = cNum2 - cNum1;

            if (diff) {
                for (let j = 0; j <= diff; j++) {
                    formatArr.push({
                        place: arr[i].place,
                        money: arr[i].money,
                        calcutta: arr[i].calcutta,
                        key: j + i + 1,
                    });
                }
            } else {
                formatArr.push({
                    place: arr[i].place,
                    money: arr[i].money,
                    calcutta: arr[i].calcutta,
                    key: i + 1,
                });
            }
        }

        return formatArr;
    }

    function createMarkup(markup) {
        return { __html: markup };
    }

    return (
        <SectionWrapper>
            {/* <img src={worldCup} alt="World Cup" width={'100%'} /> */}
            {/* <div style={styles.headerImg} /> */}
            {/* <SectionTitle
				title="Overview"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row gutter={16}>
                    {/* <Col xs={24}>
                        <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                    </Col> */}
                    <Col xs={24}>
                        <Row
                            gutter={24}
                            justify="end"
                            type="flex"
                            // style={{ padding: 24, position: 'relative', marginTop: -120 }}
                        >
                            <Col xs={24} lg={11}>
                                <Query
                                    query={GET_TOURNAMENT_OVERVIEW}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ slug: tournament && tournament.slug }}
                                    onCompleted={(data) => {
                                        // setPlayers(data.tournament_players);
                                        // console.log(data);
                                        // console.log(tournament);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading)
                                            return (
                                                <div style={styles.container}>
                                                    <CircularLoader />
                                                </div>
                                            );
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                        let tournamentData = data.tournaments[0];
                                        console.log('tournament:', tournamentData);

                                        let duration;
                                        if (tournamentData.start_date_time && tournamentData.end_date_time && tournament.status === 'COMPLETED') {
                                            var start = moment(tournamentData.start_date_time);
                                            var end = moment(tournamentData.end_date_time);

                                            duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');

                                            // moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
                                        } else {
                                            duration = moment
                                                .duration(moment.utc().diff(tournamentData.start_date_time, 'milliseconds'))
                                                .format('h [hours] m [minutes]');
                                        }

                                        const payouts = tournamentData.tournament_payouts;

                                        console.log('payouts', payouts);

                                        const formattedPayouts = formatPayouts(payouts);
                                        const updatedPayouts = _.uniqBy(formattedPayouts, 'place').sort((a, b) => {
                                            return a.place.localeCompare(b.place, undefined, {
                                                numeric: true,
                                                sensitivity: 'base',
                                            });
                                        });
                                        console.log(updatedPayouts);
                                        const totalPayouts = updatedPayouts
                                            .map((payout) => {
                                                if (payout.place.includes('-')) {
                                                    let split = payout.place.split('-');
                                                    let cNum1 = parseInt(split[0]);
                                                    let cNum2 = parseInt(split[1]);
                                                    let payoutMultiple = cNum2 - cNum1 + 1;

                                                    return payout.money * payoutMultiple;
                                                } else {
                                                    return payout.money;
                                                }
                                            })
                                            .reduce((acc, money) => {
                                                return acc + money;
                                            }, 0);

                                        const totalAuction = updatedPayouts
                                            .map((payout) => {
                                                if (payout.place.includes('-')) {
                                                    let split = payout.place.split('-');
                                                    let cNum1 = parseInt(split[0]);
                                                    let cNum2 = parseInt(split[1]);
                                                    let payoutMultiple = cNum2 - cNum1 + 1;

                                                    return payout.calcutta * payoutMultiple;
                                                } else {
                                                    return payout.calcutta;
                                                }
                                            })
                                            .reduce((acc, calcutta) => {
                                                return acc + calcutta;
                                            }, 0);

                                        console.log('updated payouts', updatedPayouts);
                                        const completedMatches =
                                            tournamentData.completed_matches &&
                                            tournamentData.completed_matches.aggregate &&
                                            tournamentData.completed_matches.aggregate.count;
                                        const byeMatches = tournamentData.bye_matches && tournamentData.bye_matches.aggregate.count;
                                        const totalMatches =
                                            (tournamentData.tournament_brackets_aggregate &&
                                                tournamentData.tournament_brackets_aggregate.aggregate &&
                                                tournamentData.tournament_brackets_aggregate.aggregate.count) - byeMatches;
                                        const tournamentProgress =
                                            tournamentData.progress && tournamentData.progress > 90
                                                ? parseInt(tournamentData.progress)
                                                : parseInt((completedMatches / totalMatches) * 100).toFixed(0) || parseInt(tournamentData.progress).toFixed(0);

                                        return (
                                            <React.Fragment>
                                                <Card
                                                    title="Overview"
                                                    bodyStyle={{ padding: 0 }}
                                                    style={{
                                                        borderRadius: 8,
                                                        overflow: 'hidden',
                                                        border: 'none',
                                                        boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                    }}
                                                    // extra={
                                                    //     <div>
                                                    //         <Link to={`/tournaments/${tournament.slug}/register`}>
                                                    //             <Button type="primary" ghost>
                                                    //                 Register Online
                                                    //             </Button>
                                                    //         </Link>
                                                    //     </div>
                                                    // }
                                                >
                                                    <Row gutter={8} type="flex" justify="start">
                                                        <Col xs={24} style={{ padding: 0 }}>
                                                            <div
                                                                style={{
                                                                    padding: '12px 24px',
                                                                }}
                                                            >
                                                                {tournamentProgress > 0 && (
                                                                    <div style={{ marginTop: 5, marginBottom: 10 }}>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor:
                                                                                    theme && theme.name === 'light'
                                                                                        ? tournament.status === 'COMPLETED'
                                                                                            ? '#fff'
                                                                                            : '#e2efff'
                                                                                        : '#06101b',
                                                                                borderRadius: 8,
                                                                                padding: 20,
                                                                                border:
                                                                                    theme && theme.name === 'light'
                                                                                        ? tournamentData.status === 'COMPLETED'
                                                                                            ? '2px solid #f0f0f0'
                                                                                            : 'none'
                                                                                        : '2px solid #06101b',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            >
                                                                                {/* {tournamentData.progress &&
																			tournamentData.progress >= 100 && (
																				<CheckCircleOutlined
																					style={{
																						fontSize: 26,
																						marginRight: 5,
																						color: '#3ebb00'
																					}}
																				/>
																			)} */}

                                                                                <Statistic
                                                                                    value={
                                                                                        tournamentProgress && !isNaN(tournamentProgress)
                                                                                            ? parseInt(tournamentProgress)
                                                                                            : 0
                                                                                    }
                                                                                    valueStyle={{
                                                                                        fontSize: 30,
                                                                                    }}
                                                                                    suffix={`% Complete (${completedMatches} of ${totalMatches} matches)`}
                                                                                    // style={{ color: '#fff' }}
                                                                                />
                                                                            </div>
                                                                            <Progress
                                                                                percent={
                                                                                    tournamentProgress && !isNaN(tournamentProgress)
                                                                                        ? parseInt(tournamentProgress)
                                                                                        : 0
                                                                                }
                                                                                status={
                                                                                    parseInt(tournamentProgress) < 100
                                                                                        ? 'active'
                                                                                        : parseInt(tournamentProgress) === 100
                                                                                        ? 'success'
                                                                                        : 'normal'
                                                                                }
                                                                                size="small"
                                                                                showInfo={false}
                                                                            />

                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between',
                                                                                    marginTop: 10,
                                                                                }}
                                                                            >
                                                                                <Text>
                                                                                    <span>
                                                                                        <Icon type="clock-circle" style={{ marginRight: 3 }} />{' '}
                                                                                        {duration || 'N/A'}
                                                                                    </span>
                                                                                </Text>
                                                                                <Text
                                                                                    style={{ textTransform: 'uppercase' }}
                                                                                    className={`${
                                                                                        tournamentData.status === 'PENDING'
                                                                                            ? 'pending'
                                                                                            : tournamentData.status === 'IN_PROGRESS'
                                                                                            ? 'live'
                                                                                            : 'completed'
                                                                                    }-text`}
                                                                                >
                                                                                    {tournamentData.status === 'IN_PROGRESS'
                                                                                        ? 'LIVE'
                                                                                        : displayEnumType(tournamentData.status)}
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div>
                                                                    <Row gutter={24}>
                                                                        {tournament.flyer && (
                                                                            <Col xs={24}>
                                                                                <img
                                                                                    src={tournament.flyer}
                                                                                    width={'100%'}
                                                                                    alt="Flyer"
                                                                                    style={{
                                                                                        borderRadius: 10,
                                                                                        overflow: 'hidden',
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                        )}
                                                                        <Col xs={24}>
                                                                            <Tabs
                                                                                defaultActiveKey="details"
                                                                                animated={{ inkBar: true, tabPane: false }}
                                                                                className="no-scroll tabs-bg2"
                                                                                tabBarStyle={{
                                                                                    textTransform: 'uppercase',
                                                                                }}
                                                                            >
                                                                                <TabPane tab="Details" key="details">
                                                                                    <Row gutters={[16, 16]}>
                                                                                        <Col xs={24}>
                                                                                            <table width="100%" className="info-table">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>Name</td>
                                                                                                        <td>{tournamentData.name}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Description</td>
                                                                                                        {tournament &&
                                                                                                        tournament.description &&
                                                                                                        tournament.is_fargo_reported === false ? (
                                                                                                            <td className="display-linebreak">
                                                                                                                <div>(NOT reported to FargoRate)</div>
                                                                                                                <div
                                                                                                                    dangerouslySetInnerHTML={
                                                                                                                        tournamentData.description
                                                                                                                            ? createMarkup(
                                                                                                                                  tournamentData.description
                                                                                                                              )
                                                                                                                            : 'N/A'
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                        ) : tournament &&
                                                                                                          tournament.description &&
                                                                                                          tournament.is_fargo_reported === true ? (
                                                                                                            <td className="display-linebreak">
                                                                                                                <div
                                                                                                                    dangerouslySetInnerHTML={
                                                                                                                        tournamentData.description
                                                                                                                            ? createMarkup(
                                                                                                                                  tournamentData.description
                                                                                                                              )
                                                                                                                            : 'N/A'
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                        ) : (
                                                                                                            <td className="display-linebreak"></td>
                                                                                                        )}
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Start date</td>
                                                                                                        <td>
                                                                                                            {moment(tournamentData.start_date_time).format(
                                                                                                                'llll'
                                                                                                            )}{' '}
                                                                                                            ({timezone})
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>End date</td>
                                                                                                        <td>
                                                                                                            {moment(tournamentData.end_date_time).format(
                                                                                                                'llll'
                                                                                                            )}{' '}
                                                                                                            ({timezone})
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {/* <tr>
                                                                                                        <td>Venue</td>
                                                                                                        <td>
                                                                                                            <Link to={`/venues/${tournamentData.venue.slug}`}>
                                                                                                                {tournamentData.venue.name}
                                                                                                            </Link>
                                                                                                        </td>
                                                                                                    </tr> */}
                                                                                                    <tr>
                                                                                                        <td>Player type</td>
                                                                                                        <td>
                                                                                                            {tournamentData.player_type
                                                                                                                ? displayEnumType(tournamentData.player_type)
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Tournament type</td>
                                                                                                        <td>
                                                                                                            {tournamentData.tournament_type
                                                                                                                ? displayEnumType(
                                                                                                                      tournamentData.tournament_type
                                                                                                                  )
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Tournament Format</td>
                                                                                                        <td>
                                                                                                            {tournamentData.tournament_format
                                                                                                                ? displayEnumType(
                                                                                                                      tournamentData.tournament_format
                                                                                                                  )
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {tournamentData.tournament_format === 'fargo_rated' && (
                                                                                                        <tr>
                                                                                                            <td>Handicap Format</td>
                                                                                                            <td>
                                                                                                                {tournamentData.handicap_format
                                                                                                                    ? displayEnumType(
                                                                                                                          tournamentData.handicap_format
                                                                                                                      )
                                                                                                                    : 'N/A'}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}

                                                                                                    <tr>
                                                                                                        <td>Game type</td>
                                                                                                        <td>
                                                                                                            <Avatar
                                                                                                                size={25}
                                                                                                                src={getGameType(tournamentData.game_type)}
                                                                                                            />
                                                                                                            <Text
                                                                                                                style={{
                                                                                                                    marginLeft: 5,
                                                                                                                }}
                                                                                                            >
                                                                                                                {tournamentData.game_type
                                                                                                                    ? displayEnumType(tournamentData.game_type)
                                                                                                                    : 'N/A'}
                                                                                                            </Text>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Winners race to</td>
                                                                                                        <td>{tournamentData.winners_race_to}</td>
                                                                                                    </tr>
                                                                                                    {tournamentData.tournament_type !==
                                                                                                        'single_elimination' && (
                                                                                                        <tr>
                                                                                                            <td>Losers race to</td>
                                                                                                            <td>{tournamentData.losers_race_to}</td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                                    {tournament.finals_race_to && (
                                                                                                        <tr>
                                                                                                            <td>Finals race to</td>
                                                                                                            <td>{tournament.finals_race_to}</td>
                                                                                                        </tr>
                                                                                                    )}

                                                                                                    <tr>
                                                                                                        <td>Bracket size</td>
                                                                                                        <td>{tournamentData.max_players}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Players</td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                tournamentData.tournament_players_aggregate
                                                                                                                    .aggregate.count
                                                                                                            }{' '}
                                                                                                            Players
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Tables</td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                tournamentData.pool_tables_aggregate.aggregate
                                                                                                                    .count
                                                                                                            }{' '}
                                                                                                            Tables
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Rating system</td>
                                                                                                        <td>
                                                                                                            {tournamentData.rating_system
                                                                                                                ? displayEnumType(tournamentData.rating_system)
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Break format</td>
                                                                                                        <td>
                                                                                                            {tournamentData.break_format
                                                                                                                ? displayEnumType(tournamentData.break_format)
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Rules</td>
                                                                                                        <td>{tournamentData.rule_format.toUpperCase()}</td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Entry fee</td>
                                                                                                        <td>
                                                                                                            {tournamentData.entry_fee
                                                                                                                ? `${tournamentData.entry_fee}`
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Admin fee</td>
                                                                                                        <td>
                                                                                                            {tournamentData.admin_fee
                                                                                                                ? `${tournamentData.admin_fee}`
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>Added money</td>
                                                                                                        <td>
                                                                                                            {tournamentData.added_money
                                                                                                                ? `${tournamentData.added_money}`
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Payout type</td>
                                                                                                        <td>
                                                                                                            {tournamentData.payout_type
                                                                                                                ? tournamentData.payout_type
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Draw type</td>
                                                                                                        <td>
                                                                                                            {tournamentData.draw_type
                                                                                                                ? displayEnumType(tournamentData.draw_type)
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Signup cutoff time</td>
                                                                                                        <td>
                                                                                                            {tournamentData.signup_cutoff_time
                                                                                                                ? displayEnumType(
                                                                                                                      tournamentData.signup_cutoff_time
                                                                                                                  )
                                                                                                                : 'N/A'}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    {/* <tr>
                                                                                                    <td>Handicap</td>
                                                                                                    <td>{tournamentData.handicapFormat || 'N/A'}</td>
                                                                                                </tr> */}

                                                                                                    {tournamentData.director && (
                                                                                                        <tr>
                                                                                                            <td>Tournament director</td>
                                                                                                            <td>
                                                                                                                <Link
                                                                                                                    to={`/users/${tournamentData.director.slug}`}
                                                                                                                >
                                                                                                                    {tournamentData.director.first_name}{' '}
                                                                                                                    {tournamentData.director.last_name}
                                                                                                                </Link>
                                                                                                                <br /> {tournamentData.director.email}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}

                                                                                                    {/* <tr>
                                                                                                    <td>Allow players to RSVP</td>
                                                                                                    <td>
                                                                                                        <Text>
                                                                                                            {tournamentData.rsvp_allowed ? 'Yes' : 'No'}
                                                                                                        </Text>
                                                                                                    </td>
                                                                                                </tr> */}

                                                                                                    <tr>
                                                                                                        <td>Use text messaging</td>
                                                                                                        <td>
                                                                                                            <Text>
                                                                                                                {tournamentData.use_text_messaging
                                                                                                                    ? 'Yes'
                                                                                                                    : 'No'}
                                                                                                            </Text>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Report results to FargoRate</td>
                                                                                                        <td>
                                                                                                            <Text>
                                                                                                                {tournament.is_fargo_reported ? 'Yes' : 'No'}
                                                                                                            </Text>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>True double elimination</td>
                                                                                                        <td>
                                                                                                            <Text>
                                                                                                                {tournamentData.consolidation_finals
                                                                                                                    ? 'Yes'
                                                                                                                    : 'No'}
                                                                                                            </Text>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>Access</td>
                                                                                                        <td>
                                                                                                            <Text>
                                                                                                                {tournamentData.is_public
                                                                                                                    ? 'Public'
                                                                                                                    : 'Private'}
                                                                                                            </Text>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>

                                                                                            <Title level={4} style={{ marginTop: 20 }}>
                                                                                                Payouts
                                                                                            </Title>

                                                                                            <table width="100%" className="info-table">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>Place</td>
                                                                                                        <td>Payout</td>
                                                                                                        <td>Auction</td>
                                                                                                    </tr>

                                                                                                    {updatedPayouts &&
                                                                                                        updatedPayouts.map((payout, index) => {
                                                                                                            return (
                                                                                                                <tr key={index}>
                                                                                                                    <td>
                                                                                                                        {payout.place === '1' ? (
                                                                                                                            <TrophyFilled
                                                                                                                                style={{
                                                                                                                                    color: '#eab700',
                                                                                                                                    marginRight: 5,
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        ) : payout.place === '2' ? (
                                                                                                                            <TrophyFilled
                                                                                                                                style={{
                                                                                                                                    color: '#9baab1',
                                                                                                                                    marginRight: 5,
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        ) : (
                                                                                                                            payout.place === '3' && (
                                                                                                                                <TrophyFilled
                                                                                                                                    style={{
                                                                                                                                        color: '#f19921',
                                                                                                                                        marginRight: 5,
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        )}
                                                                                                                        {getOrdinalSuffix(payout.place)}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {payout.money
                                                                                                                            ? numeral(payout.money).format(
                                                                                                                                  '$0,0'
                                                                                                                              )
                                                                                                                            : '-'}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {payout.calcutta
                                                                                                                            ? numeral(payout.calcutta).format(
                                                                                                                                  '$0,0'
                                                                                                                              )
                                                                                                                            : '-'}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            );
                                                                                                        })}
                                                                                                    <tr>
                                                                                                        <td>Total</td>
                                                                                                        <td>{numeral(totalPayouts).format('$0,0')}</td>
                                                                                                        <td>{numeral(totalAuction).format('$0,0')}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </TabPane>
                                                                                {/* <TabPane tab="Payouts" key="payouts">
                                                                                <table width="100%" className="info-table" style={{ marginTop: 8 }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Place</td>
                                                                                            <td>Payout</td>
                                                                                            <td>Auction</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Total</td>
                                                                                            <td>{numeral(totalPayouts).format('$0,0')}</td>
                                                                                            <td>{numeral(totalAuction).format('$0,0')}</td>
                                                                                        </tr>
                                                                                        {updatedPayouts &&
                                                                                            updatedPayouts.map((payout, index) => {
                                                                                                return (
                                                                                                    <tr key={index}>
                                                                                                        <td>
                                                                                                            {payout.place === '1' ? (
                                                                                                                <TrophyFilled
                                                                                                                    style={{
                                                                                                                        color: '#eab700',
                                                                                                                        marginRight: 5,
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : payout.place === '2' ? (
                                                                                                                <TrophyFilled
                                                                                                                    style={{
                                                                                                                        color: '#9baab1',
                                                                                                                        marginRight: 5,
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                payout.place === '3' && (
                                                                                                                    <TrophyFilled
                                                                                                                        style={{
                                                                                                                            color: '#f19921',
                                                                                                                            marginRight: 5,
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )
                                                                                                            )}
                                                                                                            {getOrdinalSuffix(payout.place)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {payout.money
                                                                                                                ? numeral(payout.money).format('$0,0')
                                                                                                                : '-'}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {payout.calcutta
                                                                                                                ? numeral(payout.calcutta).format('$0,0')
                                                                                                                : '-'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </TabPane> */}
                                                                                <TabPane
                                                                                    tab={`Players (${
                                                                                        tournamentData.tournament_players_aggregate &&
                                                                                        tournamentData.tournament_players_aggregate.aggregate &&
                                                                                        tournamentData.tournament_players_aggregate.aggregate.count
                                                                                    })`}
                                                                                    key="players"
                                                                                >
                                                                                    {tournamentData.show_unconfirmed_players === true ? (
                                                                                        <Query
                                                                                            query={GET_TOURNAMENT_PLAYERS_QUERY}
                                                                                            fetchPolicy="cache-and-network"
                                                                                            notifyOnNetworkStatusChange={true}
                                                                                            variables={{ tournament_id: tournament && tournament.id }}
                                                                                            onCompleted={(data) => {
                                                                                                setPlayers(data.tournament_players);
                                                                                                console.log(data);
                                                                                                // console.log(tournament);
                                                                                            }}
                                                                                        >
                                                                                            {({ loading, error, data }) => {
                                                                                                if (loading)
                                                                                                    return (
                                                                                                        <div style={styles.container}>
                                                                                                            <CircularLoader />
                                                                                                        </div>
                                                                                                    );
                                                                                                if (error)
                                                                                                    return (
                                                                                                        <div style={styles.container}>
                                                                                                            Error: {error.message}
                                                                                                        </div>
                                                                                                    );
                                                                                                if (
                                                                                                    (data && !data.tournament_players) ||
                                                                                                    (data && data.tournament_players.length === 0)
                                                                                                ) {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            {preRegisteredPlayers ? (
                                                                                                                <div>
                                                                                                                    <List
                                                                                                                        size="small"
                                                                                                                        dataSource={preRegisteredPlayers}
                                                                                                                        renderItem={(item) => {
                                                                                                                            return (
                                                                                                                                <List.Item
                                                                                                                                    style={{
                                                                                                                                        padding: 0,
                                                                                                                                        justifyContent:
                                                                                                                                            'flex-start',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {item}
                                                                                                                                </List.Item>
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <ZeroState
                                                                                                                    showImage
                                                                                                                    message="No Players have been added yet."
                                                                                                                    style={{ margin: 30 }}
                                                                                                                    {...props}
                                                                                                                />
                                                                                                            )}
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }

                                                                                                return (
                                                                                                    <div>
                                                                                                        <List
                                                                                                            size="small"
                                                                                                            dataSource={data && data.tournament_players}
                                                                                                            renderItem={(item) => {
                                                                                                                const challengerCountry = getCountryName(
                                                                                                                    item.country
                                                                                                                );

                                                                                                                return (
                                                                                                                    <List.Item
                                                                                                                        style={{
                                                                                                                            justifyContent: 'flex-start',
                                                                                                                            padding: item.country
                                                                                                                                ? '10px 10px'
                                                                                                                                : '10px 10px',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {challengerCountry && (
                                                                                                                            <CircleFlag
                                                                                                                                country={challengerCountry}
                                                                                                                                left={0}
                                                                                                                            />
                                                                                                                        )}

                                                                                                                        <span>
                                                                                                                            {formatPlayerName(item)}{' '}
                                                                                                                            {tournament.show_player_skill_levels ===
                                                                                                                                true &&
                                                                                                                                item.skill_level &&
                                                                                                                                `(${item.skill_level})`}
                                                                                                                        </span>
                                                                                                                    </List.Item>
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            }}
                                                                                        </Query>
                                                                                    ) : (
                                                                                        <div style={{ paddingTop: 12 }}>
                                                                                            <Text>No players have been confirmed yet.</Text>
                                                                                        </div>
                                                                                    )}
                                                                                </TabPane>
                                                                            </Tabs>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>

                                                {tournamentData && tournamentData.venue && (
                                                    <React.Fragment>
                                                        <br />
                                                        <Card
                                                            title="Venue"
                                                            bodyStyle={{ padding: 0 }}
                                                            style={{
                                                                borderRadius: 8,
                                                                overflow: 'hidden',
                                                                border: 'none',
                                                                boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                            }}
                                                        >
                                                            <List
                                                                className="hover-list"
                                                                size="small"
                                                                itemLayout="horizontal"
                                                                dataSource={[tournamentData.venue]}
                                                                renderItem={(item) => (
                                                                    <List.Item
                                                                        // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                        style={{ padding: '10px 16px' }}
                                                                        onClick={() => history.push(`/venues/${item.slug}`)}
                                                                    >
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <div>
                                                                                    <Icon
                                                                                        type="environment"
                                                                                        theme="filled"
                                                                                        style={{
                                                                                            color: '#1890ff',
                                                                                            fontSize: 30,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            title={
                                                                                <Text
                                                                                    style={{
                                                                                        fontSize: 16,
                                                                                    }}
                                                                                >
                                                                                    {item.name}
                                                                                </Text>
                                                                            }
                                                                            description={
                                                                                <div>
                                                                                    {item.address1}, {item.city}, {item.region} {item.postal_code}{' '}
                                                                                    {item.country}
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </Card>
                                                    </React.Fragment>
                                                )}

                                                {tournamentData && tournamentData.event && (
                                                    <React.Fragment>
                                                        <br />
                                                        <Card
                                                            title="Event"
                                                            bodyStyle={{ padding: 0 }}
                                                            style={{
                                                                borderRadius: 8,
                                                                overflow: 'hidden',
                                                                border: 'none',
                                                                boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                            }}
                                                        >
                                                            <List
                                                                className="hover-list"
                                                                size="small"
                                                                itemLayout="horizontal"
                                                                dataSource={[tournamentData.event]}
                                                                renderItem={(item) => {
                                                                    return (
                                                                        <List.Item
                                                                            // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                            style={{ padding: '10px 16px' }}
                                                                            onClick={() => history.push(`/events/${item.slug}`)}
                                                                        >
                                                                            <List.Item.Meta
                                                                                avatar={
                                                                                    <div>
                                                                                        <Icon
                                                                                            type="calendar"
                                                                                            style={{
                                                                                                color: '#1890ff',
                                                                                                fontSize: 30,
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                title={
                                                                                    <Text
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                        }}
                                                                                    >
                                                                                        {item.name}
                                                                                    </Text>
                                                                                }
                                                                                description={<div>{item.description}</div>}
                                                                            />
                                                                        </List.Item>
                                                                    );
                                                                }}
                                                            />
                                                        </Card>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    }}
                                </Query>
                            </Col>
                            <Col xs={24} lg={13}>
                                <Podium tournamentID={tournament.id} style={{ marginTop: 10 }} />
                                <Query
                                    query={GET_TOURNAMENT_PLAYER_STANDINGS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ tournament_id: tournament && tournament.id }}
                                    onCompleted={(data) => {
                                        // if (data && data.tournament_players) {
                                        //     const players =
                                        //         data &&
                                        //         data.tournament_players &&
                                        //         data.tournament_players.map((player) => {
                                        //             return {
                                        //                 ...player,
                                        //                 matches: [...player.tournament_matches_challenger1, ...player.tournament_matches_challenger2],
                                        //             };
                                        //         });
                                        //     formatResultsTableData(players);
                                        // }
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading) {
                                            return (
                                                <div style={styles.container}>
                                                    <CircularLoader />
                                                </div>
                                            );
                                        }

                                        const players =
                                            data &&
                                            data.tournament_players &&
                                            data.tournament_players.map((player) => {
                                                return {
                                                    ...player,
                                                    matches: [...player.tournament_matches_challenger1, ...player.tournament_matches_challenger2],
                                                };
                                            });

                                        const resultsData = formatResultsTableData(players);

                                        return (
                                            <Card
                                                title="Results"
                                                extra={
                                                    <div>
                                                        <Link to={`/tournaments/${tournament.slug}/stats`}>
                                                            <Button type="ghost">
                                                                <Icon type="bar-chart" />
                                                                View Stats
                                                            </Button>
                                                        </Link>
                                                        {/* <Button type="ghost" onClick={downloadCSV} style={{ marginLeft: 10 }}>
												<Icon type={csvDownloadLoading ? 'loading' : 'download'} /> Export CSV
											</Button> */}
                                                    </div>
                                                }
                                                bodyStyle={{ padding: 0 }}
                                                style={{
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    border: 'none',
                                                    boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                }}
                                            >
                                                <ResultsTable tournament={tournament} tournamentID={tournament.id} formattedResults={resultsData} />
                                            </Card>
                                        );
                                    }}
                                </Query>
                            </Col>
                        </Row>
                    </Col>

                    {/* <Col xs={breakpoints.isXs === true ? 24 : 6} style={{ marginTop: 5 }}>
						<Card title="Sponsors">Sponsor logos will go here...</Card>
					</Col> */}
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default DPTournamentOverview;
