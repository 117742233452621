import React, { useState, useEffect } from 'react';
import { message, Modal, Button, Input, Typography, Tabs } from 'antd';

const { Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

function EmbedModal(props) {
    const { tournament, modalVisible, onOk, onCancel } = props;
    const [activeTab, setActiveTab] = useState('iframe');
    const [iframeEmbedCode, setIframeEmbedCode] = useState();
    const [jsEmbedCode, setJsEmbedCode] = useState();

    useEffect(() => {
        const iframe = generateIframeEmbedCode();
        const js = generateJsEmbedCode();

        if (modalVisible === true) {
            setIframeEmbedCode(iframe);
            setJsEmbedCode(js);
        }
    }, [modalVisible]);

    function handleTabClick(tab) {
        setActiveTab(tab);
    }

    function generateIframeEmbedCode() {
        const location = window.location.href;
        const iframe = `<iframe src="${location}?navigation=false" style="border: none;" name="${tournament.name}" scrolling="yes" frameborder="0" marginheight="0px" marginwidth="0px" height="600px" width="600px" allowfullscreen></iframe>`;
        console.log(window.location);
        console.log(iframe);
        return iframe;
    }

    function generateJsEmbedCode() {
        const location = window.location.href;
        const url = `${location}?navigation=false`;
        const code = `<script type='text/javascript' charset='utf-8'>
   // place this code at the end of the body tag
   var iframe = document.createElement('iframe');       
   iframe.src = '${url}';
   iframe.style.border = 'none';
   iframe.width = '600';
   iframe.height = '600';
   document.body.appendChild(iframe);
</script>`;
        return code;
    }

    return (
        <Modal
            title="Embed this page on your site"
            visible={modalVisible}
            onOk={() => {
                let code;
                if (activeTab === 'javascript') {
                    code = jsEmbedCode;
                } else {
                    code = iframeEmbedCode;
                }

                navigator.clipboard.writeText(code);
                message.success(`${activeTab} embed code copied to clipboard.`);
                onOk();
            }}
            onCancel={onCancel}
            centered
            transitionName="fade"
            okText="Copy"
            bodyStyle={{ padding: 0 }}
            maskTransitionName="none"
            destroyOnClose={true}
        >
            <Tabs
                defaultActiveKey="iframe"
                animated={{ inkBar: true, tabPane: false }}
                className="no-scroll tabs-bg2"
                onChange={handleTabClick}
                tabBarStyle={{
                    textTransform: 'uppercase',
                }}
            >
                <TabPane tab="iFrame Embed Code" key="iframe" style={{ padding: 20 }}>
                    <TextArea value={iframeEmbedCode} autoSize />
                </TabPane>
                <TabPane tab="JavaScript Embed Code" key="javascript" style={{ padding: 20 }}>
                    <TextArea value={jsEmbedCode} autoSize />
                </TabPane>
            </Tabs>
        </Modal>
    );
}

const EmbedModalMemo = React.memo(EmbedModal);
export default EmbedModalMemo;
