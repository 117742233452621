import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const styles = {
    not_on_the_hill: {
        width: 50,
        background: 'linear-gradient(0deg, black, transparent)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: 18,
        height: '100%',
    },
    on_the_hill: {
        width: 50,
        background: 'linear-gradient(0deg, red, transparent)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: 18,
        height: '100%',
    },
};

export default function MatchScore(props) {
    const { player_score, opponent_score, race_to, highlight_on_hill, scoring_prefix, style } = props;

    return (
        <React.Fragment>
            <div
                style={
                    style || {
                        background: player_score > opponent_score ? 'linear-gradient(rgb(34, 74, 113), rgb(5 119 255))' : 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                    }
                }
            >
                {scoring_prefix && (
                    <div
                        style={{
                            background: '#000',
                            paddingLeft: 5,
                            paddingRight: 5,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 12,
                        }}
                    >
                        {scoring_prefix}
                    </div>
                )}
                <div style={player_score === race_to - 1 && highlight_on_hill ? styles.on_the_hill : styles.not_on_the_hill}>{player_score || 0}</div>
            </div>
        </React.Fragment>
    );
}
