import { gql } from '@apollo/client';

export const GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
            }
            tournament_match_table(
                where: {
                    _or: [{ status: { _eq: NOT_STARTED } }, { status: { _eq: IN_PROGRESS } }, { status: { _eq: PENDING } }, { status: { _eq: SCHEDULED } }]
                }
            ) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int, $status: pool_table_status_enum) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, _and: { status: { _eq: $status } } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
            }
            tournament_match_table(order_by: { status: desc }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLE_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_slug: String!, $table_slug: String!) {
        pool_tables(where: { tournament: { slug: { _eq: $tournament_slug } }, slug: { _eq: $table_slug } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            livestream_url
            status
            manufacturer {
                id
                name
            }
            venue {
                id
                name
                slug
            }
            user {
                id
                email
            }
            tournament_match_table(where: { status: { _eq: IN_PROGRESS } }) {
                id
                hash_id
                round
                identifier
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { id: asc }) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger2 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_STAGES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!, $stage_id: Int) {
        tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, stage_id: { _eq: $stage_id } }, order_by: { id: asc }) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger2 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_PROGRESS = gql`
    subscription tournaments($tournament_id: Int!) {
        tournaments(where: { id: { _eq: $tournament_id } }) {
            id
            progress
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_BY_STATUS_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!, $status: tournament_status_enum) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, is_bye: { _eq: false }, status: { _eq: $status } }
            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_IN_PROGRESS_MATCHES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, _or: [{ status: { _eq: IN_PROGRESS } }, { status: { _eq: PENDING } }] }
            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_UPCOMING_MATCHES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, is_bye: { _eq: false }, _or: [{ status: { _eq: NOT_STARTED } }, { status: { _eq: SCHEDULED } }] }
            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_W_PLAYERS_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!, $status: tournament_status_enum) {
        tournament_brackets(
            where: {
                tournament_id: { _eq: $tournament_id }
                status: { _eq: $status }
                challenger2_name: { _is_null: false }
                challenger1_name: { _is_null: false }
            }
            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                country_phone
                phone_number
                skill_level
                email
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                country_phone
                phone_number
                skill_level
                email
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { updated_at: desc }, limit: 3) {
            id
            hash_id
            round
            group
            group_name
            identifier
            place
            challenger1 {
                id
                name
                country
                country_phone
                phone_number
                skill_level
                email
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                country_phone
                phone_number
                skill_level
                email
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            match_number
            match_id
            match_color
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_SUBSCRIPTION = gql`
    subscription tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            skill_level
            fargo_id
            status
            points
            seed
            place
            race_to
            country_phone
            phone_number
            chips_left
            lives_left
            is_eliminated
            is_advancing
            last_played_at
            group
            player_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                skill_level
                country_phone
                phone_number
                fargo_id
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_SUBSCRIPTION2 = gql`
    subscription tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            status
            points
            seed
            race_to
            special_group
            chips_left
            lives_left
            is_eliminated
            is_advancing
            last_played_at
            group
            player_id
            fargo_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                email
                country_phone
                phone_number
                skill_level
                fargo_id
            }
            tournament_matches_challenger1 {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                stage_id
            }
            tournament_matches_challenger2 {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                stage_id
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS = gql`
    subscription tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { seed: asc }) {
            id
            name
            nickname
            country
            skill_level
            fargo_id
            status
            points
            seed
            place
            race_to
            country_phone
            phone_number
            chips_left
            lives_left
            is_eliminated
            is_advancing
            last_played_at
            group
            player_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                skill_level
                country_phone
                phone_number
                fargo_id
            }
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            tournament_matches_challenger1(where: { is_bye: { _eq: false } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_color
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                stage_number
                seed
                status
                progress
            }
            tournament_matches_challenger2(where: { is_bye: { _eq: false } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_color
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                stage_number
                seed
                status
                progress
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_STAGE_SUBSCRIPTION_W_STATS = gql`
    subscription tournament_players($tournament_id: Int, $stage_number: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { seed: asc }) {
            id
            name
            nickname
            country
            skill_level
            fargo_id
            status
            points
            seed
            place
            race_to
            country_phone
            phone_number
            chips_left
            lives_left
            is_eliminated
            is_advancing
            last_played_at
            group
            player_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                skill_level
                country_phone
                phone_number
                fargo_id
            }
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            tournament_matches_challenger1(where: { is_bye: { _eq: false }, stage_number: { _eq: $stage_number } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                stage_number
                seed
                status
                progress
            }
            tournament_matches_challenger2(where: { is_bye: { _eq: false }, stage_number: { _eq: $stage_number } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                stage_number
                seed
                status
                progress
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false }, stage_number: { _eq: $stage_number } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false }, stage_number: { _eq: $stage_number } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false }, stage_number: { _eq: $stage_number } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false }, stage_number: { _eq: $stage_number } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2 = gql`
    subscription vw_tournament_statistics($tournament_id: Int!) {
        vw_tournament_statistics(where: { tournament_id: { _eq: $tournament_id } }) {
            game_record
            game_win_percentage
            match_record
            match_win_percentage
            place
            player_name
            player_id
            player_country
            points
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION_OLD = gql`
    subscription tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            place
            country
            player_points {
                points
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: tournament_matches_challenger1_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: tournament_matches_challenger2_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, order_by: { place: asc }) {
            id
            name
            nickname
            place
            country
            city
            region
            skill_level
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STANDINGS_SUBSCRIPTION = gql`
    subscription tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, distinct_on: id) {
            id
            name
            place
            country
            skill_level
            user_id
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: tournament_matches_challenger1_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: tournament_matches_challenger2_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION = gql`
    subscription tournament_players($tournament_id: Int!) {
        tournament_players(where: { place: { _lte: "3" }, tournament_id: { _eq: $tournament_id } }, distinct_on: id) {
            id
            name
            place
            payouts {
                money
            }
        }
    }
`;

export const GET_STREAMING_TOURNAMENT_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, is_streaming_table: { _eq: true } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
        }
    }
`;

export const GET_SCHEDULED_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            scheduled_matches(where: { status: { _neq: COMPLETED } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                    status
                    points
                    seed
                    race_to
                    user {
                        id
                        first_name
                        last_name
                        country
                        email
                        country_phone
                        phone_number
                        skill_level
                    }
                }
                challenger2 {
                    id
                    name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                    status
                    points
                    seed
                    race_to
                    user {
                        id
                        first_name
                        last_name
                        country
                        email
                        country_phone
                        phone_number
                        skill_level
                    }
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                scheduled_table_id
                priority
                scheduled_table {
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                }
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TOURNAMENT_NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription tournament_notifications($tournament_id: Int) {
        tournament_notifications(where: { tournament_id: { _eq: $tournament_id } }, order_by: { message_sent_at: desc }) {
            id
            tournament_id
            tournament_bracket_id
            round_number
            match_number
            player_name
            player_id
            player_phone_number
            type
            message_delivered
            message
            message_error
            message_error_code
            message_sent_at
            message_delivered_at
            message_undeliverable
            message_sent
            message_id
            message_status
            delivery_receipt
            created_at
            updated_at
        }
    }
`;
