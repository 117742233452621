import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Popconfirm, Modal, message, Layout, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import TBCreateTournamentForm from './TBCreateTournamentFormStages';
import TBTournamentStep1Settings from './TBTournamentStep1Settings';
import TBTournamentStep2Players from './TBTournamentStep2Players';
import TBTournamentStep3Tables from './TBTournamentStep3Tables';
import TBTournamentStep4Preview from './TBTournamentStep4Preview';
import TBTournamentStep5Flyer from './TBTournamentStep5Flyer';
import TBTournamentStep6Review from './TBTournamentStep6Review';
import SectionFooter from '../../components/SectionFooter';
import TBPlayers from './TBPlayers';
import { getByeChart } from '../../utils';
import TBCreateAddPlayers from './TBCreateAddPlayers';
import TBCreateAddTables from './TBCreateAddTables';
import TBUploadFlyer from './TBUploadFlyer';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import TBPreviewRoundRobin from './TBPreviewRoundRobin';
import TBPreviewChip from './TBPreviewChip';
import TBPreviewBracket from './TBPreviewBracketStages';
import * as routes from '../../config/routes';
import { GET_TOURNAMENT_BY_SLUG_QUERY } from './data/queries';
import _ from 'lodash';
import TBCreateReviewTournament from './TBCreateReviewTournament';
import qs from 'query-string';

const { Step } = Steps;
const { Text, Title } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
};

function TBTournamentSetup(props) {
    const { match, client } = props;
    const slug = match.params.slug;
    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const tbIncludes = ['settings', 'players', 'tables', 'preview', 'flyer', 'review'];
    const tournamentSlug = slug;
    console.log(tournamentSlug);

    const [tournament, setTournament] = useState();
    const [bracketCreated, setBracketCreated] = useState(false);

    const enumField = {
        settings: 0,
        players: 1,
        tables: 2,
        preview: 3,
        flyer: 4,
        review: 5,
    };

    const enumField2 = {
        0: 'settings',
        1: 'players',
        2: 'tables',
        3: 'preview',
        4: 'flyer',
        5: 'review',
    };

    let pathMatch =
        splitPath[splitPath.length] === match.params.slug
            ? splitPath[splitPath.length - 1]
            : _.includes(tbIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(tbIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(tbIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : 'settings';
    console.log(pathMatch);

    const [currentStep, setCurrentStep] = useState();

    useEffect(() => {
        if (pathMatch) {
            setCurrentStep(enumField[pathMatch]);
        }
    }, [pathMatch]);

    console.log('current step', currentStep);

    function onChange(current) {
        setCurrentStep(current);
        const value = enumField2[current];
        console.log(value);
        let url;
        if (match.params.slug) {
            url = current ? `/tournament-builder/${match.params.slug}/edit/${value}` : `/tournament-builder/${match.params.slug}/edit/settings`;
        } else {
            url = current ? `/tournament-builder/new/${value}` : `/tournament-builder/new/settings`;
        }
        props.history.push(url);
    }

    function setRoundOrdering(totalRounds, maxPlayers, trueDoubleElim) {
        let winnerRoundCounter = 0;
        let loserRoundCounter = 0;
        let roundOrder = [];

        Array.from({ length: totalRounds }, (v, index) => {
            if (index === 0) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }

            if (index === totalRounds - 1 && maxPlayers > 64) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }
            if (index === totalRounds - 1 && trueDoubleElim === 1) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }

            if (index % 3 === 1) {
                loserRoundCounter--;
                roundOrder.push(loserRoundCounter);
                loserRoundCounter--;
                roundOrder.push(loserRoundCounter);
            } else if (index % 2 === 0) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }
        });
        console.log('total rounds:', totalRounds);
        console.log('round ordering:', roundOrder);
        return roundOrder;
    }

    return (
        <React.Fragment>
            <SectionWrapper>
                <SectionHeader
                    title="Tournament Setup"
                    titleStyle={{
                        color: '#fff',
                        textTransform: 'uppercase',
                        margin: 0,
                        display: 'flex',
                    }}
                    height={100}
                    backButton={
                        <Button
                            onClick={() =>
                                props.match.params.slug
                                    ? props.history.push(`/tournament-builder/${props.match.params.slug}/dashboard`)
                                    : props.history.push(`/tournament-builder`)
                            }
                            type="ghost"
                            shape="circle"
                            icon="arrow-left"
                            size="large"
                            ghost
                            style={{ border: 'none' }}
                        />
                    }
                    subtitle="Configure your tournament in a few simple steps."
                    subtitleStyle={{ color: '#fff' }}
                    theme="tournamentBuilder"
                >
                    <Button
                        ghost
                        disabled={
                            tournament &&
                            tournament.tournament_brackets_aggregate &&
                            tournament.tournament_brackets_aggregate.aggregate &&
                            tournament.tournament_brackets_aggregate.aggregate.count > 0
                                ? false
                                : true
                        }
                        onClick={() => {
                            props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
                        }}
                    >
                        <Icon type="apartment" /> View Bracket
                    </Button>
                </SectionHeader>
                <SectionContent padding="20px 40px">
                    <Row gutter={24} justify="center" type="flex">
                        <Col xs={24}>
                            <Card bodyStyle={{ padding: 0 }}>
                                <Row gutter={0} justify="start" type="flex">
                                    <Col xs={24} sm={6} md={4}>
                                        <Steps
                                            direction="vertical"
                                            current={currentStep}
                                            onChange={onChange}
                                            style={{
                                                marginBottom: 20,
                                                paddingTop: 20,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                            }}
                                        >
                                            <Step title="Settings" />
                                            <Step title={props.match.params.slug ? 'Edit Players' : 'Add Players'} />
                                            <Step title={props.match.params.slug ? 'Edit Tables' : 'Add Tables'} />
                                            <Step title="Preview Draw" />
                                            <Step title="Upload Flyer" />
                                            <Step title="Review" />
                                        </Steps>
                                    </Col>
                                    <Col xs={24} sm={18} md={20}>
                                        {tournamentSlug || tournament ? (
                                            <Query
                                                query={GET_TOURNAMENT_BY_SLUG_QUERY}
                                                // fetchPolicy="network-only"
                                                notifyOnNetworkStatusChange={true}
                                                variables={{ slug: tournamentSlug || tournament.slug }}
                                                onCompleted={(data) => {
                                                    console.log(data);
                                                    const tournament = data && data.tournaments && data.tournaments[0];
                                                    setTournament(tournament);
                                                    setBracketCreated(
                                                        tournament &&
                                                            tournament.tournament_brackets_aggregate &&
                                                            tournament.tournament_brackets_aggregate.aggregate &&
                                                            tournament.tournament_brackets_aggregate.aggregate.count > 0
                                                            ? true
                                                            : false
                                                    );
                                                }}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) {
                                                        return (
                                                            <div style={styles.container}>
                                                                <CircularLoader />
                                                            </div>
                                                        );
                                                    }
                                                    if (error) {
                                                        return <div style={styles.container}>Error: {error.message}</div>;
                                                    }
                                                    if (!loading && data && !data.tournaments) {
                                                        return (
                                                            <Empty
                                                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                                imageStyle={{
                                                                    height: 100,
                                                                }}
                                                                description={<Text style={{ fontSize: 18 }}>You have not created any tournaments yet.</Text>}
                                                                style={{ margin: 10 }}
                                                            >
                                                                <Button>Create New Tournament</Button>
                                                            </Empty>
                                                        );
                                                    }

                                                    // const tournament = data && data.tournaments && data.tournaments[0];

                                                    return (
                                                        <React.Fragment>
                                                            <Switch>
                                                                <Redirect
                                                                    exact
                                                                    strict
                                                                    from={`/tournament-builder/${props.match.params.slug}/edit`}
                                                                    to={`/tournament-builder/${props.match.params.slug}/edit/settings`}
                                                                />

                                                                <Route
                                                                    exact
                                                                    path={routes.TOURNAMENT_BUILDER.TOURNAMENT_SETUP_STEP_ONE}
                                                                    render={() => <TBTournamentStep1Settings {...props} tournament={tournament} />}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={routes.TOURNAMENT_BUILDER.TOURNAMENT_SETUP_STEP_TWO}
                                                                    render={() => <TBTournamentStep2Players {...props} tournament={tournament} />}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={routes.TOURNAMENT_BUILDER.TOURNAMENT_SETUP_STEP_THREE}
                                                                    render={() => <TBTournamentStep3Tables {...props} tournament={tournament} />}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={routes.TOURNAMENT_BUILDER.TOURNAMENT_SETUP_STEP_FOUR}
                                                                    render={() => (
                                                                        <TBTournamentStep4Preview
                                                                            {...props}
                                                                            bracketCreated={bracketCreated}
                                                                            tournament={tournament}
                                                                        />
                                                                    )}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={routes.TOURNAMENT_BUILDER.TOURNAMENT_SETUP_STEP_FIVE}
                                                                    render={() => <TBTournamentStep5Flyer {...props} tournament={tournament} />}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={routes.TOURNAMENT_BUILDER.TOURNAMENT_SETUP_STEP_SIX}
                                                                    render={() => <TBTournamentStep6Review {...props} tournament={tournament} />}
                                                                />
                                                            </Switch>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            </Query>
                                        ) : (
                                            <React.Fragment>
                                                <Switch>
                                                    <Redirect exact strict from={`/tournament-builder/new`} to={`/tournament-builder/new/settings`} />
                                                </Switch>

                                                {currentStep === 0 && (
                                                    <div style={{ padding: 20 }}>
                                                        <TBCreateTournamentForm {...props} />
                                                    </div>
                                                )}

                                                {currentStep === 1 && (
                                                    <Result
                                                        status="info"
                                                        title="You must finish configuring the tournament before you can add players."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 2 && (
                                                    <Result
                                                        status="info"
                                                        title="You must finish adding players before you can add tables."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(1)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 3 && (
                                                    <Result
                                                        status="info"
                                                        title="You must finish adding players before you can preview the bracket."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(2)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 4 && (
                                                    <Result
                                                        status="info"
                                                        title="You must create the tournament before you can upload a flyer."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(3)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 5 && (
                                                    <Result
                                                        status="info"
                                                        title="You must create the tournament before you can review it."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                                Go to step 1
                                                            </Button>
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </SectionContent>
                <SectionFooter {...props} />
            </SectionWrapper>
        </React.Fragment>
    );
}

export default withRouter(TBTournamentSetup);
