import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Row, Col, Typography, Button, Icon, Empty, List } from 'antd';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete, DatePicker } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import {
    CREATE_LEAGUE_MATCH_GAME,
    UPDATE_LEAGUE_MATCH_GAME,
    DELETE_LEAGUE_MATCH_GAME,
    INCREMENT_PLAYER_SCORE_MUTATION,
    INCREMENT_OPPONENT_SCORE_MUTATION,
    DECREMENT_PLAYER_SCORE_MUTATION,
    DECREMENT_OPPONENT_SCORE_MUTATION,
    SET_CHALLENGER_SCORE_MUTATION,
    INCREMENT_PLAYER_POINTS_MUTATION,
    INCREMENT_OPPONENT_POINTS_MUTATION,
    DECREMENT_PLAYER_POINTS_MUTATION,
    DECREMENT_OPPONENT_POINTS_MUTATION,
    SET_CHALLENGER_POINTS_MUTATION,
    INCREMENT_PLAYER_GAME_POINTS_MUTATION,
    INCREMENT_OPPONENT_GAME_POINTS_MUTATION,
    DECREMENT_PLAYER_GAME_POINTS_MUTATION,
    DECREMENT_OPPONENT_GAME_POINTS_MUTATION,
    SET_CHALLENGER_GAME_POINTS_MUTATION,
    INCREMENT_PLAYER_GAMES_MUTATION,
    INCREMENT_OPPONENT_GAMES_MUTATION,
    DECREMENT_PLAYER_GAMES_MUTATION,
    DECREMENT_OPPONENT_GAMES_MUTATION,
    SET_CHALLENGER_GAMES_MUTATION,
    UPDATE_LEAGUE_MATCH_STATS,
} from './data/mutations';
import { GET_LEAGUE_MATCH_QUERY, GET_LEAGUE_MATCH_GAME, GET_LEAGUE_MATCH_GAME_POINTS } from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import _ from 'lodash';

const { Title, Text } = Typography;

function ScoringControls(props) {
    const { leagueID, divisionID, onCreateGame, onDeleteGame, matchID, layout, currentGame, matchData } = props;
    const [loading, setLoading] = useState(false);
    const [createMatchGame] = useMutation(CREATE_LEAGUE_MATCH_GAME);
    const [deleteMatchGame] = useMutation(DELETE_LEAGUE_MATCH_GAME);
    const [updateMatchGame] = useMutation(UPDATE_LEAGUE_MATCH_GAME);
    const [endMatchGame] = useMutation(UPDATE_LEAGUE_MATCH_GAME);
    const [updateMatchStats] = useMutation(UPDATE_LEAGUE_MATCH_STATS);

    const [incrementplayerScore] = useMutation(INCREMENT_PLAYER_SCORE_MUTATION);
    const [incrementopponentScore] = useMutation(INCREMENT_OPPONENT_SCORE_MUTATION);
    const [decrementplayerScore] = useMutation(DECREMENT_PLAYER_SCORE_MUTATION);
    const [decrementopponentScore] = useMutation(DECREMENT_OPPONENT_SCORE_MUTATION);
    const [setChallengerScore] = useMutation(SET_CHALLENGER_SCORE_MUTATION);

    const [incrementPlayerPoints] = useMutation(INCREMENT_PLAYER_POINTS_MUTATION);
    const [incrementOpponentPoints] = useMutation(INCREMENT_OPPONENT_POINTS_MUTATION);
    const [decrementPlayerPoints] = useMutation(DECREMENT_PLAYER_POINTS_MUTATION);
    const [decrementOpponentPoints] = useMutation(DECREMENT_OPPONENT_POINTS_MUTATION);
    const [setChallengerPoints] = useMutation(SET_CHALLENGER_POINTS_MUTATION);

    const [incrementPlayerGamePoints] = useMutation(INCREMENT_PLAYER_GAME_POINTS_MUTATION);
    const [incrementOpponentGamePoints] = useMutation(INCREMENT_OPPONENT_GAME_POINTS_MUTATION);
    const [decrementPlayerGamePoints] = useMutation(DECREMENT_PLAYER_GAME_POINTS_MUTATION);
    const [decrementOpponentGamePoints] = useMutation(DECREMENT_OPPONENT_GAME_POINTS_MUTATION);
    const [setChallengerGamePoints] = useMutation(SET_CHALLENGER_GAME_POINTS_MUTATION);

    const [incrementPlayerGames] = useMutation(INCREMENT_PLAYER_GAMES_MUTATION);
    const [incrementOpponentGames] = useMutation(INCREMENT_OPPONENT_GAMES_MUTATION);
    const [decrementPlayerGames] = useMutation(DECREMENT_PLAYER_GAMES_MUTATION);
    const [decrementOpponentGames] = useMutation(DECREMENT_OPPONENT_GAMES_MUTATION);
    const [setChallengerGames] = useMutation(SET_CHALLENGER_GAMES_MUTATION);
    const formRef = useRef();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const styles = {
        cellGrey: {
            marginTop: 20,
            // border: '5px solid #000',
            borderRadius: 10,
            minHeight: 350,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            // background: 'linear-gradient(rgb(255, 73, 93), rgb(179, 0, 0))'
            background: 'linear-gradient(rgb(50, 65, 85), rgb(12, 29, 53))',
        },
        cellBlue: {
            marginTop: 20,
            // border: '5px solid #000',
            borderRadius: 10,
            minHeight: 350,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            background: 'linear-gradient(rgb(39, 135, 215), rgb(2, 45, 182))',
        },
        plusButton: {
            margin: 0,
            fontSize: 18,
            width: 40,
            height: 40,
        },
        plusButtonDisabled: {
            margin: 0,
            fontSize: 18,
            width: 40,
            height: 40,
            color: '#fff',
        },
        minusButton: {
            marginLeft: 15,
            fontSize: 18,
            width: 40,
            height: 40,
        },
        minusButtonDisabled: {
            marginLeft: 15,
            fontSize: 18,
            width: 40,
            height: 40,
            color: '#fff',
        },
    };

    // function deleteCurrentMatchGame() {
    // 	if (currentGame) {
    // 		onDeleteFrame(currentGame.id);
    // 		deleteMatchGame({
    // 			variables: {
    // 				id: currentGame.id,
    // 				match_id: matchID
    // 			},
    // 			awaitRefetchQueries: true,
    // 			refetchQueries: [
    // 				{
    // 					query: GET_LEAGUE_MATCH_QUERY,
    // 					variables: { match_id: matchID }
    // 				}
    // 			]
    // 		})
    // 			.then((res) => {
    // 				console.log(res);
    // 				message.success('Match frame deleted');
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				// setLoading(false);
    // 				message.error('There was an error', error);
    // 			});
    // 	}
    // }

    // function createNewMatchGame() {
    // 	const PlayerPoints = currentGame ? parseInt(currentGame.player_points) : 0;
    // 	const OpponentPoints = currentGame ? parseInt(currentGame.opponent_points) : 0;
    // 	let winner;
    // 	if (PlayerPoints > OpponentPoints) {
    // 		winner = 'player';
    // 	} else if (OpponentPoints > PlayerPoints) {
    // 		winner = 'opponent';
    // 	}

    // 	if (currentGame) {
    // 		endMatchGame({
    // 			variables: {
    // 				id: currentGame.id,
    // 				changes: {
    // 					end_time: moment.utc(),
    // 					status: 'COMPLETED',
    // 					progress: '100',
    // 					player_is_winner: winner === 'player' ? true : false,
    // 					opponent_is_winner: winner === 'opponent' ? true : false
    // 				}
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);

    // 				createMatchGame({
    // 					variables: {
    // 						objects: [
    // 							{
    // 								league_id: leagueID,
    // 								division_id: divisionID,
    // 								match_id: matchID,
    // 								player_id: matchData.player_id,
    // 								opponent_id: matchData.opponent_id,
    // 								start_time: moment.utc(),
    // 								status: 'IN_PROGRESS',
    // 								frame_number:
    // 									currentGame && currentGame.frame_number
    // 										? parseInt(currentGame.frame_number) + 1
    // 										: 1
    // 							}
    // 						]
    // 					},
    // 					awaitRefetchQueries: true,
    // 					refetchQueries: [
    // 						{
    // 							query: GET_LEAGUE_MATCH_QUERY,
    // 							variables: { match_id: matchID }
    // 						}
    // 					]
    // 				})
    // 					.then((res) => {
    // 						console.log(res);
    // 						const currentGame =
    // 							res &&
    // 							res.data &&
    // 							res.data.insert_match_games &&
    // 							res.data.insert_match_games.returning &&
    // 							res.data.insert_match_games.returning[0];
    // 						onCreateFrame(currentGame);
    // 						// setLoading(false);
    // 						message.success('Match frame created');
    // 					})
    // 					.catch((error) => {
    // 						console.log(error);
    // 						// setLoading(false);
    // 						message.error('There was an error', error);
    // 					});
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				// setLoading(false);
    // 				message.error('There was an error', error);
    // 			});
    // 	} else {
    // 		createMatchGame({
    // 			variables: {
    // 				objects: [
    // 					{
    // 						league_id: leagueID,
    // 						division_id: divisionID,
    // 						match_id: matchID,
    // 						player_id: matchData.player_id,
    // 						opponent_id: matchData.opponent_id,
    // 						start_time: moment.utc(),
    // 						status: 'IN_PROGRESS',
    // 						frame_number:
    // 							currentGame && currentGame.frame_number ? parseInt(currentGame.frame_number) + 1 : 1
    // 					}
    // 				]
    // 			},
    // 			awaitRefetchQueries: true,
    // 			refetchQueries: [
    // 				{
    // 					query: GET_LEAGUE_MATCH_QUERY,
    // 					variables: { match_id: matchID }
    // 				}
    // 			]
    // 		})
    // 			.then((res) => {
    // 				console.log(res);
    // 				const currentGame =
    // 					res &&
    // 					res.data &&
    // 					res.data.insert_match_games &&
    // 					res.data.insert_match_games.returning &&
    // 					res.data.insert_match_games.returning[0];
    // 				onCreateFrame(currentGame);
    // 				// setLoading(false);
    // 				message.success('Match frame created');
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				// setLoading(false);
    // 				message.error('There was an error', error);
    // 			});
    // 	}
    // }

    function updateStats(data) {
        let formData = {};
        Object.entries(data).forEach(([key, val], i) => {
            formData[key] = parseInt(val);
        });
        // console.log(formData);
        // console.log(matchData);
        // console.log(currentGame);

        const updatedGame = _.merge(currentGame, formData);
        const updatedMatchGames = matchData.match_games.map((game) => {
            if (game.id === updatedGame.id) {
                return updatedGame;
            } else {
                return game;
            }
        });
        // console.log(updatedMatchGames);
        const matchStats = calculateMatchStats(updatedMatchGames);

        updateMatchStats({
            variables: {
                id: matchData.id,
                changes: matchStats,
            },
        })
            .then((data) => {
                // console.log(data);
                console.log('matchStats', matchStats);
                // message.success('Match stats updated');
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
                message.error('There was an error', error);
            });
    }

    // function onSetplayerScore(event) {
    // 	const points = event.target.value;
    // 	console.log('changed', event.target.value);
    // 	if (matchData.winner_determined_by === 'FRAMES_WON') {
    // 		setFrames('player', points);
    // 	} else {
    // 		setMainScore('player', points);
    // 	}
    // }

    // function onSetopponentScore(event) {
    // 	const points = event.target.value;
    // 	console.log('changed', event.target.value);
    // 	if (matchData.winner_determined_by === 'FRAMES_WON') {
    // 		setFrames('opponent', points);
    // 	} else {
    // 		setMainScore('opponent', points);
    // 	}
    // }

    function onSetPlayerPoints(points) {
        // const points = event.target.value;
        console.log('points', points);
        // const debouncedFunction = _.debounce(() => {
        setGamePoints('player', points);
        // 	setPoints('player', points);
        // }, 100);
        // debouncedFunction();
        // setGamePoints('player', points).then(() => {
        // 	setPoints('player', points);
        // });
    }

    function onSetOpponentPoints(points) {
        console.log('points', points);
        setGamePoints('opponent', points);
        // setGamePoints('opponent', points).then(() => {
        // 	setPoints('opponent', points);
        // });
    }

    // async function setMainScore(challenger, score) {
    // 	if (challenger === 'player') {
    // 		return await setChallengerScore({
    // 			variables: {
    // 				id: matchID,
    // 				changes: {
    // 					player_score: score || 0
    // 				}
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else {
    // 		return await setChallengerScore({
    // 			variables: {
    // 				id: matchID,
    // 				changes: {
    // 					opponent_score: score || 0
    // 				}
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    // async function increaseMainScore(challenger) {
    // 	if (challenger === 'player') {
    // 		return await incrementplayerScore({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else if (challenger === 'opponent') {
    // 		return await incrementopponentScore({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    // async function decreaseMainScore(challenger) {
    // 	if (challenger === 'player') {
    // 		return await decrementplayerScore({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else if (challenger === 'opponent') {
    // 		return await decrementopponentScore({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    // async function setPoints(challenger, points) {
    // 	return await setChallengerPoints({
    // 		variables: {
    // 			id: matchID,
    // 			changes: {
    // 				...(challenger === 'player' && { player_points: points || 0 }),
    // 				...(challenger === 'opponent' && { opponent_points: points || 0 })
    // 			}
    // 		}
    // 	})
    // 		.then((data) => {
    // 			console.log(data);
    // 		})
    // 		.catch((error) => {
    // 			console.log(error);
    // 			message.info('There was an error', error);
    // 		});
    // }

    async function setGamePoints(challenger, points) {
        return await setChallengerGamePoints({
            variables: {
                id: currentGame && currentGame.id,
                match_id: matchID,
                changes: {
                    ...(challenger === 'player' && { player_points: points || 0 }),
                    ...(challenger === 'opponent' && { opponent_points: points || 0 }),
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_MATCH_GAME,
                    variables: { match_id: matchID },
                },
            ],
            optimisticResponse: null,
            update: (cache) => {
                // const match = cache.readQuery({
                // 	query: GET_LEAGUE_MATCH_QUERY,
                // 	variables: {
                // 		league_id: leagueID,
                // 		division_id: divisionID,
                // 		match_slug: props.match.params.match
                // 	}
                // });
                // console.log(match);
                // const leagueMatchGames = matches.data.update_league_matches;
                // console.log(leagueMatchGames);
                // 	const newTodos = existingTodos.todos.map((t) => {
                // 		if (t.id === todo.id) {
                // 			return { ...t, is_completed: !t.is_completed };
                // 		} else {
                // 			return t;
                // 		}
                // 	});
                // 	cache.writeQuery({
                // 	query: GET_LEAGUE_MATCH_QUERY,
                // 		data: { league_matches: newPoints}
                // 	});
            },
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    // async function increasePoints(challenger) {
    // 	if (challenger === 'player') {
    // 		return await incrementPlayerPoints({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else if (challenger === 'opponent') {
    // 		return await incrementOpponentPoints({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    async function increaseGamePoints(challenger) {
        if (challenger === 'player') {
            updateStats({
                player_points: currentGame && currentGame.player_points + 1,
            });
            return await incrementPlayerGamePoints({
                variables: {
                    id: currentGame && currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'opponent') {
            updateStats({
                opponent_points: currentGame && currentGame.opponent_points + 1,
            });
            return await incrementOpponentGamePoints({
                variables: {
                    id: currentGame && currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    // async function decreasePoints(challenger) {
    // 	if (challenger === 'player') {
    // 		return await decrementPlayerPoints({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else if (challenger === 'opponent') {
    // 		return await decrementOpponentPoints({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    async function decreaseGamePoints(challenger) {
        if (challenger === 'player') {
            updateStats({
                player_points: currentGame && currentGame.player_points - 1,
            });
            return await decrementPlayerGamePoints({
                variables: {
                    id: currentGame && currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'opponent') {
            updateStats({
                opponent_points: currentGame && currentGame.opponent_points - 1,
            });
            return await decrementOpponentGamePoints({
                variables: {
                    id: currentGame && currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    // async function setFrames(challenger, frames) {
    // 	if (challenger === 'player') {
    // 		return await setChallengerFrames({
    // 			variables: {
    // 				id: matchID,
    // 				changes: {
    // 					player_games: frames || 0
    // 				}
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else {
    // 		return await setChallengerFrames({
    // 			variables: {
    // 				id: matchID,
    // 				changes: {
    // 					opponent_games: frames || 0
    // 				}
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    // async function increaseFrames(challenger) {
    // 	createNewMatchGame();
    // 	if (challenger === 'player') {
    // 		return await incrementPlayerGames({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else if (challenger === 'opponent') {
    // 		return await incrementOpponentGames({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    // async function decreaseFrames(challenger) {
    // 	deleteCurrentMatchGame();
    // 	if (challenger === 'player') {
    // 		return await decrementPlayerGames({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	} else if (challenger === 'opponent') {
    // 		return await decrementOpponentGames({
    // 			variables: {
    // 				id: matchID
    // 			}
    // 		})
    // 			.then((data) => {
    // 				console.log(data);
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 				message.info('There was an error', error);
    // 			});
    // 	}
    // }

    function handleFocus(event) {
        // event.target.select();
    }

    // function calculateTPA(challenger, matchData) {
    // 	let ballsPocketed;
    // 	let errors;
    // 	if (challenger === 'player') {
    // 		ballsPocketed = matchData.player_balls_pocketed;
    // 		errors = matchData.player_errors;
    // 	} else {
    // 		ballsPocketed = matchData.opponent_balls_pocketed;
    // 		errors = matchData.opponent_errors;
    // 	}
    // 	const tpa = numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
    // 	return tpa;
    // }

    function calculateMatchStats(games) {
        return {
            player_games: _.sumBy(games, 'player_games'),
            opponent_games: _.sumBy(games, 'opponent_games'),
            player_points: _.sumBy(games, 'player_points'),
            opponent_points: _.sumBy(games, 'opponent_points'),
            player_balls_pocketed: _.sumBy(games, 'player_balls_pocketed'),
            opponent_balls_pocketed: _.sumBy(games, 'opponent_balls_pocketed'),
            player_errors: _.sumBy(games, 'player_errors'),
            opponent_errors: _.sumBy(games, 'opponent_errors'),
            player_high_break: _.sumBy(games, 'player_high_break'),
            opponent_high_break: _.sumBy(games, 'opponent_high_break'),
        };
    }

    function isDisabled(currentGame) {
        return currentGame && currentGame.status !== 'IN_PROGRESS' ? true : false;
    }

    return (
        <Formik
            ref={formRef}
            onSubmit={(data, actions) => {
                updateStats(data);

                if (data.player_points !== currentGame.player_points) {
                    onSetPlayerPoints(data.player_points);
                }
                if (data.opponent_points !== currentGame.opponent_points) {
                    onSetOpponentPoints(data.opponent_points);
                }
            }}
            // validationSchema={schema}
            enableReinitialize
            initialValues={{
                player_games: matchData.player_games !== null ? matchData.player_games : 0,
                opponent_games: matchData.opponent_games !== null ? matchData.opponent_games : 0,
                player_points: currentGame && currentGame.player_points !== null ? currentGame.player_points : 0,
                opponent_points: currentGame && currentGame.opponent_points !== null ? currentGame.opponent_points : 0,
            }}
            render={({ errors, touched, values }) => (
                <Form
                    layout="vertical"
                    style={{ maxWidth: 600 }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        console.log(values);
                        // handleSubmit(values);
                    }}
                >
                    <div
                        style={
                            layout === 'left' && currentGame && currentGame.player_is_winner === true
                                ? styles.cellBlue
                                : layout === 'right' && currentGame && currentGame.opponent_is_winner === true
                                ? styles.cellBlue
                                : styles.cellGrey
                        }
                    >
                        <div
                            style={{
                                width: '100%',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 15px 0px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                justifyContent: 'space-between',
                                padding: breakpoint === 'mobile' ? 20 : 30,
                                flex: 1,
                            }}
                        >
                            {layout === 'left' ? (
                                <React.Fragment>
                                    <Text
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            textTransform: 'uppercase',
                                            color: '#fff',
                                            flex: 1,
                                            textAlign: 'left',
                                        }}
                                    >
                                        {matchData.winner_determined_by ? matchData.winner_determined_by.split('_')[0] : 'Score'}
                                    </Text>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        {/* <Text
											style={{
												maxWidth: 200,
												backgroundColor: 'transparent',
												color: '#fff',
												fontSize: 150,
												padding: 0,
												fontWeight: 600,
												margin: 0,
												textAlign: 'center',
												border: 'none'
											}}
										>
											{matchData.player_games}
										</Text> */}
                                        <FormItem name="player_games">
                                            <Input
                                                min={0}
                                                onFocus={handleFocus}
                                                name="player_games"
                                                onPressEnter={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                onBlur={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                disabled={isDisabled(currentGame)}
                                                // defaultValue={
                                                // 	matchData.winner_determined_by === 'FRAMES_WON' ? (
                                                // 		matchData.player_games
                                                // 	) : (
                                                // 		matchData.player_score
                                                // 	)
                                                // }
                                                // value={
                                                // 	matchData.winner_determined_by === 'FRAMES_WON' ? (
                                                // 		matchData.player_games
                                                // 	) : (
                                                // 		matchData.player_score
                                                // 	)
                                                // }
                                                // onChange={onSetplayerScore}
                                                style={{
                                                    maxWidth: 200,
                                                    backgroundColor: 'transparent',
                                                    color: '#fff',
                                                    fontSize: 150,
                                                    padding: 0,
                                                    fontWeight: 600,
                                                    height: 150,
                                                    textAlign: 'center',
                                                    border: 'none',
                                                }}
                                            />
                                        </FormItem>
                                    </div>

                                    <div>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // increasePoints('player');
                                                increaseGamePoints('player');
                                            }}
                                            style={isDisabled(currentGame) ? styles.plusButtonDisabled : styles.plusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="plus" />
                                        </Button>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // decreasePoints('player');
                                                decreaseGamePoints('player');
                                            }}
                                            style={isDisabled(currentGame) ? styles.minusButtonDisabled : styles.minusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="minus" />
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/* <div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-end',
											justifyContent: 'flex-start',
											flex: 1
										}}
									>
										<Button
											type="ghost"
											size="large"
											ghost
											onClick={() => {
												if (matchData.winner_determined_by === 'FRAMES_WON') {
													increaseFrames('opponent');
												} else {
													increaseMainScore('opponent');
												}
											}}
											style={{ fontSize: 20, width: 60, height: 60 }}
										>
											<Icon type="plus" />
										</Button>

										<Button
											type="ghost"
											size="large"
											ghost
											onClick={() => {
												if (matchData.winner_determined_by === 'FRAMES_WON') {
													decreaseFrames('opponent');
												} else {
													decreaseMainScore('opponent');
												}
											}}
											style={{ marginTop: 20, fontSize: 20, width: 60, height: 60 }}
										>
											<Icon type="minus" />
										</Button>
									</div> */}
                                    <div>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // increasePoints('player');
                                                increaseGamePoints('player');
                                            }}
                                            style={isDisabled(currentGame) ? styles.plusButtonDisabled : styles.plusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="plus" />
                                        </Button>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // decreasePoints('player');
                                                decreaseGamePoints('player');
                                            }}
                                            style={isDisabled(currentGame) ? styles.minusButtonDisabled : styles.minusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="minus" />
                                        </Button>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        {/* <Text
											style={{
												maxWidth: 200,
												backgroundColor: 'transparent',
												color: '#fff',
												fontSize: 150,
												padding: 0,
												fontWeight: 600,
												margin: 0,
												textAlign: 'center',
												border: 'none'
											}}
										>
											{matchData.opponent_games}
										</Text> */}
                                        <FormItem name="opponent_games">
                                            <Input
                                                min={0}
                                                onFocus={handleFocus}
                                                name="opponent_games"
                                                onPressEnter={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                onBlur={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                disabled={isDisabled(currentGame)}
                                                // defaultValue={
                                                // 	matchData.winner_determined_by === 'FRAMES_WON' ? (
                                                // 		matchData.opponent_games
                                                // 	) : (
                                                // 		matchData.opponent_score
                                                // 	)
                                                // }
                                                // value={
                                                // 	matchData.winner_determined_by === 'FRAMES_WON' ? (
                                                // 		matchData.opponent_games
                                                // 	) : (
                                                // 		matchData.opponent_score
                                                // 	)
                                                // }
                                                // onChange={onSetopponentScore}
                                                style={{
                                                    maxWidth: 200,
                                                    backgroundColor: 'transparent',
                                                    color: '#fff',
                                                    fontSize: 150,
                                                    padding: 0,
                                                    fontWeight: 600,
                                                    height: 150,
                                                    textAlign: 'center',
                                                    border: 'none',
                                                }}
                                            />
                                        </FormItem>
                                    </div>

                                    <Text
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            textTransform: 'uppercase',
                                            color: '#fff',

                                            textAlign: 'right',
                                        }}
                                    >
                                        {matchData.winner_determined_by ? matchData.winner_determined_by.split('_')[0] : 'Score'}
                                    </Text>
                                </React.Fragment>
                            )}
                        </div>
                        <div
                            style={{
                                flex: 1,
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: breakpoint === 'mobile' ? '20px 20px' : '20px 30px',

                                position: 'relative',
                            }}
                        >
                            {layout === 'left' ? (
                                <React.Fragment>
                                    <Text
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            textTransform: 'uppercase',
                                            color: '#fff',
                                        }}
                                    >
                                        Points
                                    </Text>

                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                        }}
                                    >
                                        <FormItem name="player_points">
                                            <Input
                                                min={0}
                                                name="player_points"
                                                // defaultValue={(currentGame && currentGame.player_points) || 0}
                                                onFocus={handleFocus}
                                                onPressEnter={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                onBlur={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                disabled={isDisabled(currentGame)}
                                                // value={(currentGame && currentGame.player_points) || 0}
                                                // onChange={onSetPlayerPoints}
                                                style={{
                                                    maxWidth: 60,
                                                    backgroundColor: 'transparent',
                                                    color: '#fff',
                                                    fontSize: 30,
                                                    padding: 0,
                                                    fontWeight: 600,
                                                    height: 60,
                                                    textAlign: 'center',
                                                    border: 'none',
                                                }}
                                            />
                                        </FormItem>
                                    </div>

                                    <div>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // increasePoints('player');
                                                increaseGamePoints('player');
                                            }}
                                            style={isDisabled(currentGame) ? styles.plusButtonDisabled : styles.plusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="plus" />
                                        </Button>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // decreasePoints('player');
                                                decreaseGamePoints('player');
                                            }}
                                            style={isDisabled(currentGame) ? styles.minusButtonDisabled : styles.minusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="minus" />
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // increasePoints('opponent');
                                                increaseGamePoints('opponent');
                                            }}
                                            style={isDisabled(currentGame) ? styles.plusButtonDisabled : styles.plusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="plus" />
                                        </Button>
                                        <Button
                                            type="ghost"
                                            size="large"
                                            ghost
                                            onClick={() => {
                                                // decreasePoints('opponent');
                                                decreaseGamePoints('opponent');
                                            }}
                                            style={isDisabled(currentGame) ? styles.minusButtonDisabled : styles.minusButton}
                                            disabled={isDisabled(currentGame)}
                                        >
                                            <Icon type="minus" />
                                        </Button>
                                    </div>

                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                        }}
                                    >
                                        <FormItem name="opponent_points">
                                            <Input
                                                min={0}
                                                name="opponent_points"
                                                // defaultValue={(currentGame && currentGame.opponent_points) || 0}
                                                onFocus={handleFocus}
                                                onPressEnter={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                onBlur={() => {
                                                    formRef.current.handleSubmit();
                                                }}
                                                disabled={isDisabled(currentGame)}
                                                // value={(currentGame && currentGame.opponent_points) || 0}
                                                // onChange={onSetOpponentPoints}
                                                style={{
                                                    maxWidth: 60,
                                                    backgroundColor: 'transparent',
                                                    color: '#fff',
                                                    fontSize: 30,
                                                    padding: 0,
                                                    fontWeight: 600,
                                                    height: 60,
                                                    textAlign: 'center',
                                                    border: 'none',
                                                }}
                                            />
                                        </FormItem>
                                    </div>

                                    <Text
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            textTransform: 'uppercase',
                                            color: '#fff',
                                        }}
                                    >
                                        Points
                                    </Text>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        />
    );
}

export default withRouter(ScoringControls);
