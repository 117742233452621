import { message } from 'antd';
import { GET_TOURNAMENT_BRACKET_MATCH_BY_IDENTIFIER, GET_TOURNAMENT_BRACKET_MATCH_QUERY } from '../screens/digitalpool/tournaments/data/queries';
import {
    ADVANCE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TABLE_STATUS_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
} from '../screens/digitalpool/tournaments/data/mutations';

export function advanceTournamentPlayers(client, tournamentID, matchData, winner, loser) {
    console.log({
        client,
        tournamentID,
        matchData,
        winner,
        loser,
    });
    return advancePlayers(matchData, winner, loser);

    async function advancePlayers(matchData, winner, loser) {
        const winnerTo = matchData.winner_to;
        const loserTo = matchData.loser_to;
        let nextWinnerSideMatch;
        let nextLoserSideMatch;

        if (winnerTo) {
            nextWinnerSideMatch = await client.query({
                query: GET_TOURNAMENT_BRACKET_MATCH_BY_IDENTIFIER,
                variables: {
                    identifier: winnerTo,
                    tournament_id: tournamentID,
                },
            });
            console.log('NEXT WINNER SIDE MATCH', nextWinnerSideMatch);
        }

        if (loserTo) {
            nextLoserSideMatch = await client.query({
                query: GET_TOURNAMENT_BRACKET_MATCH_BY_IDENTIFIER,
                variables: {
                    identifier: loserTo,
                    tournament_id: tournamentID,
                },
            });
            console.log('NEXT LOSER SIDE MATCH', nextLoserSideMatch);
        }

        const winnerSideMatchData = nextWinnerSideMatch && nextWinnerSideMatch.data && nextWinnerSideMatch.data.tournament_brackets;
        const loserSideMatchData = nextLoserSideMatch && nextLoserSideMatch.data && nextLoserSideMatch.data.tournament_brackets;

        const tournament = matchData.tournament;

        console.log('current match data', matchData);
        console.log('winner', winner);
        console.log('loser', loser);

        const nextWinnerMatchData =
            winnerSideMatchData &&
            winnerSideMatchData.map((item) => {
                const position = locateNextBracketPosition(item, winner, 'winner', matchData.match_number, matchData.identifier);
                console.log('next winner position', position);

                let challenger = winner;
                const { tournament, challenger1, challenger2, pool_table, __typename, ...origMatchData } = item;

                if (item && item.is_consolidation_finals === true) {
                    return {
                        ...origMatchData,
                        challenger,
                        identifier: item.identifier,
                        is_bye: item.is_bye,
                        ...(matchData.challenger1_id && { challenger1_id: matchData.challenger1_id }),
                        ...(matchData.challenger1_name && { challenger1_name: matchData.challenger1_name }),
                        ...(matchData.challenger1_seed && { challenger1_seed: matchData.challenger1_seed }),
                        ...(matchData.challenger1_country && { challenger1_country: matchData.challenger1_country }),
                        challenger1_race_to: matchData.challenger1_race_to || tournament.finals_race_to || tournament.winners_race_to,
                        challenger1_score: 0,
                        ...(matchData.challenger2_id && { challenger2_id: matchData.challenger2_id }),
                        ...(matchData.challenger2_name && { challenger2_name: matchData.challenger2_name }),
                        ...(matchData.challenger2_seed && { challenger2_seed: matchData.challenger2_seed }),
                        ...(matchData.challenger2_country && { challenger2_country: matchData.challenger2_country }),
                        challenger2_race_to: matchData.challenger2_race_to || tournament.finals_race_to || tournament.winners_race_to,
                        challenger2_score: 0,
                    };
                } else {
                    if (position === 'top') {
                        return {
                            ...origMatchData,
                            challenger,
                            identifier: item.identifier,
                            is_bye: item.is_bye,
                            challenger1_id: challenger && challenger.id,
                            challenger1_name: challenger && challenger.name,
                            challenger1_seed: challenger && challenger.seed,
                            challenger1_country: challenger && challenger.country,
                            challenger1_race_to:
                                item && item.is_finals === true
                                    ? (challenger && challenger.race_to) || tournament.finals_race_to || tournament.winners_race_to
                                    : (challenger && challenger.race_to) || matchData.identifier.includes('L')
                                    ? tournament.losers_race_to
                                    : tournament.winners_race_to,
                            challenger1_score: 0,
                        };
                    } else if (position === 'bottom') {
                        return {
                            ...origMatchData,
                            challenger,
                            identifier: item.identifier,
                            is_bye: item.is_bye,
                            challenger2_id: challenger && challenger.id,
                            challenger2_name: challenger && challenger.name,
                            challenger2_seed: challenger && challenger.seed,
                            challenger2_country: challenger && challenger.country,
                            challenger2_race_to:
                                item && item.is_finals === true
                                    ? (challenger && challenger.race_to) || tournament.finals_race_to || tournament.winners_race_to
                                    : (challenger && challenger.race_to) || matchData.identifier.includes('L')
                                    ? tournament.losers_race_to
                                    : tournament.winners_race_to,
                            challenger2_score: 0,
                        };
                    } else {
                        console.error('not sure where to put them');
                    }
                }
            });

        const nextLoserMatchData =
            loserSideMatchData &&
            loserSideMatchData.map((item) => {
                const position = locateNextBracketPosition(item, loser, 'loser', matchData.match_number, matchData.identifier);
                console.log('next loser position', position);

                let challenger = loser;
                const { tournament, challenger1, challenger2, pool_table, __typename, ...origMatchData } = item;

                if (item && item.is_consolidation_finals === true) {
                    return {
                        ...origMatchData,
                        challenger,
                        identifier: item.identifier,
                        is_bye: item.is_bye,
                        ...(matchData.challenger1_id && { challenger1_id: matchData.challenger1_id }),
                        ...(matchData.challenger1_name && { challenger1_name: matchData.challenger1_name }),
                        ...(matchData.challenger1_seed && { challenger1_seed: matchData.challenger1_seed }),
                        ...(matchData.challenger1_country && { challenger1_country: matchData.challenger1_country }),
                        challenger1_race_to: matchData.challenger1_race_to || tournament.finals_race_to || tournament.winners_race_to,
                        challenger1_score: 0,
                        ...(matchData.challenger2_id && { challenger2_id: matchData.challenger2_id }),
                        ...(matchData.challenger2_name && { challenger2_name: matchData.challenger2_name }),
                        ...(matchData.challenger2_seed && { challenger2_seed: matchData.challenger2_seed }),
                        ...(matchData.challenger2_country && { challenger2_country: matchData.challenger2_country }),
                        challenger2_race_to: matchData.challenger2_race_to || tournament.finals_race_to || tournament.winners_race_to,
                        challenger2_score: 0,
                    };
                } else {
                    if (position === 'top') {
                        if (matchData.challenger1_is_withdraw === true || matchData.challenger2_is_withdraw === true) {
                            return {
                                ...origMatchData,
                                identifier: item.identifier,
                                is_bye: item.is_bye,
                                challenger1_id: null,
                                challenger1_name: 'Walk Over',
                                challenger1_seed: null,
                                challenger1_country: null,
                                challenger1_race_to: null,
                                challenger1_score: null,
                            };
                        } else {
                            return {
                                ...origMatchData,
                                challenger,
                                identifier: item.identifier,
                                is_bye: item.is_bye,
                                challenger1_id: challenger && challenger.id,
                                challenger1_name: challenger && challenger.name,
                                challenger1_seed: challenger && challenger.seed,
                                challenger1_country: challenger && challenger.country,
                                challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                challenger1_score: 0,
                            };
                        }
                    } else if (position === 'bottom') {
                        if (matchData.challenger1_is_withdraw === true || matchData.challenger2_is_withdraw === true) {
                            return {
                                ...origMatchData,
                                identifier: item.identifier,
                                is_bye: item.is_bye,
                                challenger2_id: null,
                                challenger2_name: 'Walk Over',
                                challenger2_seed: null,
                                challenger2_country: null,
                                challenger2_race_to: null,
                                challenger2_score: null,
                            };
                        } else {
                            return {
                                ...origMatchData,
                                challenger,
                                identifier: item.identifier,
                                is_bye: item.is_bye,
                                challenger2_id: challenger && challenger.id,
                                challenger2_name: challenger && challenger.name,
                                challenger2_seed: challenger && challenger.seed,
                                challenger2_country: challenger && challenger.country,
                                challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                challenger2_score: 0,
                            };
                        }
                    } else {
                        console.error('not sure where to put them');
                    }
                }
            });

        await releaseTable(matchData.table_id);

        if (nextWinnerMatchData) {
            console.log('nextMatchDataWinners', nextWinnerMatchData[0]);
            await advanceToWinnerSide({
                tournament: tournament,
                currentMatch: matchData,
                nextMatchData: nextWinnerMatchData[0],
            });
        }

        if (nextLoserMatchData) {
            console.log('nextMatchDataLosers', nextLoserMatchData[0]);
            await advanceToLoserSide({
                tournament: tournament,
                currentMatch: matchData,
                nextMatchData: nextLoserMatchData[0],
            });
        } else {
            if (!nextLoserMatchData) {
                console.log('player eliminated');
                playerEliminated(loser, matchData);
            }
        }

        return {
            nextWinnerSideMatch: winnerSideMatchData && winnerSideMatchData[0],
            nextLoserSideMatch: loserSideMatchData && loserSideMatchData[0],
        };
    }

    async function playerEliminated(player, currentMatch) {
        if (player && player.id) {
            console.log('player eliminated', player);
            console.log('place', currentMatch.place);

            await client
                .mutate({
                    mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                    variables: {
                        id: player.id,
                        tournament_id: tournamentID,
                        changes: {
                            place: currentMatch.is_finals === true ? '2' : currentMatch.is_consolidation_finals === true ? '2' : currentMatch.place,
                        },
                    },
                })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    async function releaseTable(tableId) {
        console.log('release table', tableId);
        await client.mutate({
            mutation: UPDATE_TABLE_STATUS_MUTATION,
            variables: {
                id: tableId,
                changes: {
                    status: 'OPEN',
                },
            },
        });
    }

    async function advanceToWinnerSide(values) {
        const { tournament, currentMatch, nextMatchData } = values;

        console.log('winner side data', values);

        if (currentMatch.winner_to_num) {
            const { challenger, ...newMatch } = nextMatchData;
            let newMatch2 = newMatch;
            let raceTo =
                challenger && challenger.race_to
                    ? challenger.race_to
                    : nextMatchData.identifier.includes('L')
                    ? tournament.losers_race_to
                    : tournament.winners_race_to;

            const changes = {
                // loser_from: currentMatch.identifier,
                ...newMatch2,
                ...(currentMatch.challenger1_name && { challenger1_race_to: raceTo }),
                ...(currentMatch.challenger2_name && { challenger2_race_to: raceTo }),
                // challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                // challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                ...(nextMatchData &&
                    nextMatchData.is_bye === true && {
                        status: 'COMPLETED',
                        progress: 100,
                        is_bye: true,
                    }),
            };

            console.log('winner side changes', changes);

            const advanceBracket = await client.mutate({
                mutation: ADVANCE_TOURNAMENT_BRACKET_MUTATION,
                variables: {
                    match_number: currentMatch.winner_to_num,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                // notifyOnNetworkStatusChange: true,
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //         variables: {
                //             match_id: currentMatch.id,
                //             tournament_id: tournamentID,
                //         },
                //     },
                // ],
            });

            if (nextMatchData && nextMatchData.is_bye === true) {
                console.log('MATCH WINNER SIDE BYE', nextMatchData);
                console.log('CHALLENGER WINNER SIDE BYE', challenger);
                await client.mutate({
                    mutation: ADVANCE_TOURNAMENT_BRACKET_MUTATION,
                    variables: {
                        match_number: nextMatchData.winner_to_num,
                        tournament_id: tournament.id,
                        changes: {
                            // winner_from: currentMatch.identifier,
                            ...(nextMatchData.winner_from_top_num || nextMatchData.loser_from_top_num
                                ? {
                                      challenger1_id: challenger && challenger.id,
                                      challenger1_name: challenger && challenger.name,
                                      challenger1_seed: challenger && challenger.seed,
                                      challenger1_country: challenger && challenger.country,
                                      challenger1_race_to: raceTo,
                                      challenger1_score: 0,
                                  }
                                : {
                                      challenger2_id: challenger && challenger.id,
                                      challenger2_name: challenger && challenger.name,
                                      challenger2_seed: challenger && challenger.seed,
                                      challenger2_country: challenger && challenger.country,
                                      challenger2_race_to: raceTo,
                                      challenger2_score: 0,
                                  }),
                        },
                    },
                });
            }
            return advanceBracket;
        } else {
            return;
        }
    }

    async function advanceToLoserSide(values) {
        const { tournament, currentMatch, nextMatchData } = values;

        console.log('loser side data', values);

        if (currentMatch.loser_to_num) {
            const { challenger, ...newMatch } = nextMatchData;
            let newMatch2 = newMatch;

            const changes = {
                ...newMatch2,
                ...(nextMatchData &&
                    nextMatchData.is_bye === true && {
                        status: 'COMPLETED',
                        progress: 100,
                        is_bye: true,
                    }),
            };
            console.log('loser side changes', changes);

            const advanceBracket = await client.mutate({
                mutation: ADVANCE_TOURNAMENT_BRACKET_MUTATION,
                variables: {
                    match_number: currentMatch.loser_to_num,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                // notifyOnNetworkStatusChange: true,
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //         variables: {
                //             match_id: currentMatch.id,
                //             tournament_id: tournamentID,
                //         },
                //     },
                // ],
            });

            if (nextMatchData && nextMatchData.is_bye === true) {
                console.log('MATCH LOSER SIDE BYE', nextMatchData);
                console.log('CHALLENGER LOSER SIDE BYE', challenger);
                console.log('advance challenger bye', challenger);
                console.log({
                    current_match: currentMatch.match_number,
                    winner_from_bottom_num: nextMatchData.winner_from_bottom_num === currentMatch.match_number,
                    winner_from_top_num: nextMatchData.winner_from_top_num === currentMatch.match_number,
                    loser_from_bottom_num: nextMatchData.loser_from_bottom_num === currentMatch.match_number,
                    loser_from_top_num: nextMatchData.loser_from_top_num === currentMatch.match_number,
                });
                await client.mutate({
                    mutation: ADVANCE_TOURNAMENT_BRACKET_MUTATION,
                    variables: {
                        match_number: nextMatchData.winner_to_num,
                        tournament_id: tournament.id,
                        changes: {
                            // loser_from: currentMatch.identifier,
                            ...(nextMatchData.winner_from_bottom_num
                                ? {
                                      challenger2_id: challenger && challenger.id,
                                      challenger2_name: challenger && challenger.name,
                                      challenger2_seed: challenger && challenger.seed,
                                      challenger2_country: challenger && challenger.country,
                                      challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                      challenger2_score: (challenger && challenger.score) || 0,
                                  }
                                : nextMatchData.loser_from_top_num
                                ? {
                                      challenger1_id: challenger && challenger.id,
                                      challenger1_name: challenger && challenger.name,
                                      challenger1_seed: challenger && challenger.seed,
                                      challenger1_country: challenger && challenger.country,
                                      challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                      challenger1_score: (challenger && challenger.score) || 0,
                                  }
                                : {
                                      challenger1_id: challenger && challenger.id,
                                      challenger1_name: challenger && challenger.name,
                                      challenger1_seed: challenger && challenger.seed,
                                      challenger1_country: challenger && challenger.country,
                                      challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                      challenger1_score: (challenger && challenger.score) || 0,
                                  }),
                        },
                    },
                });
            }
            return advanceBracket;
        }
    }

    function locateNextBracketPosition(match, player, side, fromMatchNumber, identifier) {
        let advanceTo;
        console.log('FROM MATCH', fromMatchNumber);
        console.log('PLAYER CONTENT', player);
        console.log('SIDE', side);
        console.log('MATCH CONTENT', match);
        console.log('CURRENT IDENTIFIER', identifier);

        if (side === 'winner') {
            if (match.identifier.includes('L')) {
                var hashMarkerForIdentifierIndex = match.identifier.indexOf('-');
                if (match.identifier.substring(1, hashMarkerForIdentifierIndex) % 2 === 0) {
                    // alert('code block 1');
                    console.log('match identifier: ', match.identifier);
                    console.log(side + ' advances to top match', match.match_number);
                    advanceTo = 'top';
                } else {
                    if (fromMatchNumber === match.winner_from_top_num) {
                        // alert('code block 2');
                        console.log(side + ' advances to top match', match.match_number);
                        advanceTo = 'top';
                    } else if (fromMatchNumber === match.winner_from_bottom_num) {
                        // alert('code block 3');
                        console.log(side + ' advances to bottom match', match.match_number);
                        advanceTo = 'bottom';
                    } else {
                        // alert('code block 4');
                        console.log(side + ' advances to top match', match.match_number);
                        advanceTo = 'top';
                    }
                }
            } else if (fromMatchNumber === match.winner_from_top_num) {
                // alert('code block 5');
                console.log(side + ' advances to top match', match.match_number);
                advanceTo = 'top';
            } else if (fromMatchNumber === match.winner_from_bottom_num) {
                // alert('code block 6');
                console.log(side + ' advances to bottom match', match.match_number);
                advanceTo = 'bottom';
            } else {
                // alert('code block 7');
                console.log(side + ' advances to top match', match.match_number);
                advanceTo = 'top';
            }
        } else {
            if (match.loser_from && match.loser_from.includes('W1')) {
                if (match.loser_from_top_num === fromMatchNumber) {
                    // alert('code block 8');
                    console.log(side + ' advances to top match', match.match_number);
                    advanceTo = 'top';
                } else if (match.loser_from_bottom_num === fromMatchNumber) {
                    // alert('code block 9');
                    console.log(side + ' advances to bottom match', match.match_number);
                    advanceTo = 'bottom';
                }
            } else {
                // alert('code block 10');
                console.log(side + ' advances to bottom match', match.match_number);
                advanceTo = 'bottom';
            }
        }

        return advanceTo;
    }
}
