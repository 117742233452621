import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Layout, Typography, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, DatePicker, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Slide from 'react-reveal/Slide';

const { Sider, Content, Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

export default function SidePanelRounds(props) {
    const { authState, theme, selectedRound, segments, rounds, roundSidePanelOpen, onRoundSidePanelClose, onSubmit } = props;
    const formRef = useRef();

    const CreateEventSchema = Yup.object().shape({
        round: Yup.string().required('Required'),
        identifier: Yup.string().required('Required'),
    });

    function handleSubmit(values) {
        onSubmit(values);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    return (
        <Drawer
            title="Round Configuration"
            placement="right"
            onClose={onRoundSidePanelClose}
            visible={roundSidePanelOpen}
            getContainer={false}
            width={300}
            mask={false}
            maskClosable={false}
            // style={{ position: 'absolute' }}
            // headerStyle={{
            //     backgroundColor: theme.bracket.panels.backgroundColor,
            // }}
            // drawerStyle={{
            //     backgroundColor: theme.bracket.panels.backgroundColor,
            // }}
        >
            <Formik
                ref={formRef}
                enableReinitialize
                initialValues={
                    selectedRound
                        ? {
                              ...selectedRound,
                          }
                        : {
                              round: '',
                              identifier: '',
                              segment_id: '',
                          }
                }
                validationSchema={CreateEventSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <React.Fragment>
                        <Form
                            onSubmit={handleSubmit}
                            layout="vertical"
                            // onSubmit={(e) => {
                            //     e.preventDefault();
                            //     // handleSubmit(values);
                            //     formRef.current.handleSubmit();
                            //     formRef.current.setSubmitting(false);
                            //     formRef.current.resetForm();
                            // }}
                            style={{ maxWidth: 600 }}
                        >
                            <FormItem label="Round" name="round" required>
                                <Input
                                    name="round"
                                    placeholder="Round"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>
                            <FormItem label="Identifier" name="identifier" required>
                                <Input
                                    name="identifier"
                                    placeholder="Identifier"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>

                            <FormItem label="Segment" name="segment_id">
                                <Select
                                    showSearch
                                    name="segment_id"
                                    placeholder="Select segment"
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value="">
                                        <Text>None</Text>
                                    </Option>
                                    {segments &&
                                        segments.map((segment) => {
                                            return (
                                                <Option key={segment.id} value={segment.id}>
                                                    {segment.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </FormItem>

                            <SubmitButton
                                size="large"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                            >
                                {selectedRound ? 'Update' : 'Create'}
                            </SubmitButton>
                            <Button type="secondary" size="large" onClick={onRoundSidePanelClose}>
                                Dismiss
                            </Button>

                            <div>
                                <Text style={styles.error}>{errors.general}</Text>
                            </div>
                            <div>
                                <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                            </div>
                        </Form>

                        {/* <Text>{JSON.stringify(selectedRound, null, 4)}</Text> */}
                    </React.Fragment>
                )}
            />
        </Drawer>
    );
}
