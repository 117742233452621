import React, { useState } from 'react';
import { Row, Col, List, Avatar, Skeleton, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

const data = [
	{
		name: 'John Doe'
	},
	{
		name: 'Jane Doe'
	},
	{
		name: 'Jake Doe'
	},
	{
		name: 'Jill Doe'
	},
	{
		name: 'John Doe'
	},
	{
		name: 'Jane Doe'
	},
	{
		name: 'Jake Doe'
	},
	{
		name: 'Jill Doe'
	},
	{
		name: 'John Doe'
	},
	{
		name: 'Jane Doe'
	},
	{
		name: 'Jake Doe'
	},
	{
		name: 'Jill Doe'
	}
];

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	}
};

function People(props) {
	const { authState } = props;
	const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

	return (
		<React.Fragment>
			<SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 120} />
			<SectionContent padding="20px 40px">
				<Row>
					<Col>
						<Card title="All Players" bodyStyle={{ padding: 0 }}>
							<List
								className="hover-list"
								size="small"
								loading={false}
								itemLayout="horizontal"
								dataSource={data}
								renderItem={(item) => (
									<List.Item onClick={() => props.history.push(`/username`)}>
										<Skeleton avatar title={false} loading={false} active>
											<List.Item.Meta
												avatar={
													<Avatar
														style={{ backgroundColor: '#1890ff' }}
														icon="user"
														size={35}
													/>
												}
												title={item.name}
												description={item.short_description}
											/>
										</Skeleton>
									</List.Item>
								)}
							/>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</React.Fragment>
	);
}

export default People;
