import { gql } from '@apollo/client';

export const UPDATE_LEAGUE_MATCH = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_LEAGUE_MATCH_STATS = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_LEAGUE_MATCH_GAME = gql`
    mutation update_match_games($id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_MATCH_GAME = gql`
    mutation insert_match_games($objects: [match_GAMES_insert_input!]!) {
        insert_match_games(objects: $objects) {
            affected_rows
        }
    }
`;

export const DELETE_LEAGUE_MATCH_GAME = gql`
    mutation delete_match_games($id: Int, $match_id: Int) {
        delete_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

// Update score mutations
export const INCREMENT_PLAYER_SCORE_MUTATION = gql`
    mutation increment_match_score($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_score: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_SCORE_MUTATION = gql`
    mutation increment_match_score($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_score: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_SCORE_MUTATION = gql`
    mutation decrement_match_score($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_score: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_SCORE_MUTATION = gql`
    mutation decrement_match_score($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_score: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_SCORE_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_POINTS_MUTATION = gql`
    mutation increment_match_points($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_points: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_POINTS_MUTATION = gql`
    mutation increment_match_game_points($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_points: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_POINTS_MUTATION = gql`
    mutation increment_match_points($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_points: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_POINTS_MUTATION = gql`
    mutation increment_match_game_points($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_points: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_POINTS_MUTATION = gql`
    mutation decrement_match_points($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_points: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_POINTS_MUTATION = gql`
    mutation decrement_match_game_points($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_points: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_POINTS_MUTATION = gql`
    mutation decrement_match_points($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_points: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_POINTS_MUTATION = gql`
    mutation decrement_match_game_points($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_points: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_POINTS_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_POINTS_MUTATION = gql`
    mutation upadte_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAMES_MUTATION = gql`
    mutation increment_matches($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_games: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAMES_MUTATION = gql`
    mutation increment_matches($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_games: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAMES_MUTATION = gql`
    mutation decrement_matches($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_games: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAMES_MUTATION = gql`
    mutation decrement_matches($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_games: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAMES_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

// Update TPA mutations
export const INCREMENT_PLAYER_BALLS_POCKETED_MUTATION = gql`
    mutation increment_match_balls_pocketed($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_balls_pocketed: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_BALLS_POCKETED_MUTATION = gql`
    mutation increment_match_balls_pocketed($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_balls_pocketed: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_BALLS_POCKETED_MUTATION = gql`
    mutation decrement_match_balls_pocketed($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_balls_pocketed: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_BALLS_POCKETED_MUTATION = gql`
    mutation decrement_match_balls_pocketed($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_balls_pocketed: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_BALLS_POCKETED_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_BALLS_POCKETED_MUTATION = gql`
    mutation increment_match_game_balls_pocketed($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_balls_pocketed: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_BALLS_POCKETED_MUTATION = gql`
    mutation increment_match_game_balls_pocketed($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_balls_pocketed: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_BALLS_POCKETED_MUTATION = gql`
    mutation decrement_match_game_balls_pocketed($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_balls_pocketed: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_BALLS_POCKETED_MUTATION = gql`
    mutation decrement_match_game_balls_pocketed($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_balls_pocketed: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_BALLS_POCKETED_MUTATION = gql`
    mutation update_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_ERRORS_MUTATION = gql`
    mutation increment_errors($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_errors: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_ERRORS_MUTATION = gql`
    mutation increment_errors($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_errors: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_ERRORS_MUTATION = gql`
    mutation decrement_errors($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_errors: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_ERRORS_MUTATION = gql`
    mutation decrement_errors($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_errors: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_ERRORS_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_ERRORS_MUTATION = gql`
    mutation incremenT_GAME_errors($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_errors: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_ERRORS_MUTATION = gql`
    mutation incremenT_GAME_errors($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_errors: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_ERRORS_MUTATION = gql`
    mutation decremenT_GAME_errors($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_errors: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_ERRORS_MUTATION = gql`
    mutation decremenT_GAME_errors($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_errors: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_ERRORS_MUTATION = gql`
    mutation update_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_BREAK_RUNS_MUTATION = gql`
    mutation increment_break_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_break_runs: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_BREAK_RUNS_MUTATION = gql`
    mutation increment_break_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_break_runs: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_BREAK_RUNS_MUTATION = gql`
    mutation decrement_break_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_break_runs: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_BREAK_RUNS_MUTATION = gql`
    mutation decrement_break_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_break_runs: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_BREAK_RUNS_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_BREAK_RUNS_MUTATION = gql`
    mutation incremenT_GAME_break_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_break_runs: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_BREAK_RUNS_MUTATION = gql`
    mutation incremenT_GAME_break_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_break_runs: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_BREAK_RUNS_MUTATION = gql`
    mutation decremenT_GAME_break_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_break_runs: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_BREAK_RUNS_MUTATION = gql`
    mutation decremenT_GAME_break_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_break_runs: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_BREAK_RUNS_MUTATION = gql`
    mutation update_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_TABLE_RUNS_MUTATION = gql`
    mutation increment_table_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_table_runs: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_TABLE_RUNS_MUTATION = gql`
    mutation increment_table_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_table_runs: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_TABLE_RUNS_MUTATION = gql`
    mutation decrement_table_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_table_runs: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_TABLE_RUNS_MUTATION = gql`
    mutation decrement_table_runs($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_table_runs: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_TABLE_RUNS_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_TABLE_RUNS_MUTATION = gql`
    mutation incremenT_GAME_table_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_table_runs: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_TABLE_RUNS_MUTATION = gql`
    mutation incremenT_GAME_table_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_table_runs: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_TABLE_RUNS_MUTATION = gql`
    mutation decremenT_GAME_table_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_table_runs: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_TABLE_RUNS_MUTATION = gql`
    mutation decremenT_GAME_table_runs($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_table_runs: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_TABLE_RUNS_MUTATION = gql`
    mutation update_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_HIGH_RUN_MUTATION = gql`
    mutation increment_high_run($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_high_run: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_HIGH_RUN_MUTATION = gql`
    mutation increment_high_run($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_high_run: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_HIGH_RUN_MUTATION = gql`
    mutation decrement_high_run($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_high_run: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_HIGH_RUN_MUTATION = gql`
    mutation decrement_high_run($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_high_run: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_HIGH_RUN_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_HIGH_RUN_MUTATION = gql`
    mutation incremenT_GAME_high_run($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_high_run: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_HIGH_RUN_MUTATION = gql`
    mutation incremenT_GAME_high_run($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_high_run: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_HIGH_RUN_MUTATION = gql`
    mutation decremenT_GAME_high_run($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_high_run: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_HIGH_RUN_MUTATION = gql`
    mutation decremenT_GAME_high_run($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_high_run: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_HIGH_RUN_MUTATION = gql`
    mutation update_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_HIGH_BREAK_MUTATION = gql`
    mutation increment_high_break($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_high_break: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_HIGH_BREAK_MUTATION = gql`
    mutation increment_high_break($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_high_break: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_HIGH_BREAK_MUTATION = gql`
    mutation decrement_high_break($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { player_high_break: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_HIGH_BREAK_MUTATION = gql`
    mutation decrement_high_break($id: Int) {
        update_matches(where: { id: { _eq: $id } }, _inc: { opponent_high_break: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_HIGH_BREAK_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const INCREMENT_PLAYER_GAME_HIGH_BREAK_MUTATION = gql`
    mutation incremenT_GAME_high_break($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_high_break: 1 }) {
            affected_rows
        }
    }
`;

export const INCREMENT_OPPONENT_GAME_HIGH_BREAK_MUTATION = gql`
    mutation incremenT_GAME_high_break($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_high_break: 1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_PLAYER_GAME_HIGH_BREAK_MUTATION = gql`
    mutation decremenT_GAME_high_break($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { player_high_break: -1 }) {
            affected_rows
        }
    }
`;

export const DECREMENT_OPPONENT_GAME_HIGH_BREAK_MUTATION = gql`
    mutation decremenT_GAME_high_break($id: Int, $match_id: Int) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _inc: { opponent_high_break: -1 }) {
            affected_rows
        }
    }
`;

export const SET_CHALLENGER_GAME_HIGH_BREAK_MUTATION = gql`
    mutation update_match_games($id: Int, $match_id: Int, $changes: match_GAMES_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
        }
    }
`;
