import React, { useState, useRef } from 'react';
import { Form, FormItem, Input, InputNumber, Radio, Select, AutoComplete, SubmitButton, ResetButton } from 'formik-antd';
import { Formik } from 'formik';
import { message, Button, Modal, Typography, Avatar, Tooltip, Icon } from 'antd';
import * as Yup from 'yup';
import { SEARCH_PLAYERS } from '../data/queries';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Ripples from 'react-ripples';
import CircleFlag from '../../../components/CircleFlag';
import { displayEnumType, getCountryName, formatPlayerName } from '../../../utils';

const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

export default function EventLiveScoringSetupModal(props) {
    const { client, gameType, modalVisible, onModalOk, onModalCancel, onStartScoringMatch, authState, selectedVenueId } = props;
    const [acDataSource, setacDataSource] = useState();
    const formRef = useRef();

    async function queryDB(name) {
        setacDataSource([]);
        var players = [];

        const res = await client.query({
            query: SEARCH_PLAYERS,
            variables: {
                name: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.players.length; i++) {
            let player = res.data.players[i];
            const { id, name, aliases, country, user_id } = player;
            const user = player.user;

            players.push({
                ...user,
                ...player,
                id,
                name: name,
                country: country,
                aliases,
                user_id: user_id,
                key: uuidv4(),
            });
        }

        const dataSet = _.uniqBy([...players], 'name').sort((a, b) => a.name.localeCompare(b.name));

        setacDataSource(dataSet);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryDB(name);
        } else {
            setacDataSource([]);
        }
    }

    function onSelect1(value, option) {
        const player = acDataSource.filter((item) => item.key === value);

        console.log(player);

        if (player) {
            // setSelected(player[0]);
            const playerInfo = player[0];
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                player_id: playerInfo.id,
                player_user_id: playerInfo.user_id,
                player_name: playerInfo.name,
                player_country: playerInfo.country,
                player_fargo: playerInfo.properties && playerInfo.properties.fargo_data && playerInfo.properties.fargo_data.effectiveRating,
            });
        }
    }

    function onSelect2(value, option) {
        const player = acDataSource.filter((item) => item.key === value);

        if (player) {
            // setSelected(player[0]);
            const playerInfo = player[0];
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                opponent_id: playerInfo.id,
                opponent_user_id: playerInfo.user_id,
                opponent_name: playerInfo.name,
                opponent_country: playerInfo.country,
                opponent_fargo: playerInfo.properties && playerInfo.properties.fargo_data && playerInfo.properties.fargo_data.effectiveRating,
            });
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const MatchSchema = Yup.object().shape({
        player_race_to: Yup.number().required('Race value required'),
        opponent_race_to: Yup.number().required('Race value required'),
    });

    function handleSubmit(values) {
        onStartScoringMatch(values);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function renderOption(item, index) {
        return (
            <Option key={item.key} value={item.key}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {item.country && (
                        <span role="img" aria-label={item.country}>
                            <CircleFlag country={getCountryName(item.country)} left={0} />{' '}
                        </span>
                    )}
                    {formatPlayerName(item.name)}
                    {item.properties &&
                        item.properties.fargo_data &&
                        item.properties.fargo_data.location &&
                        item.properties.fargo_data.location !== ' ' &&
                        ` - ${item.properties.fargo_data.location}`}
                    {item.properties &&
                        item.properties.fargo_data &&
                        item.properties.fargo_data.effectiveRating &&
                        ` - ${item.properties.fargo_data.effectiveRating}`}
                </span>
            </Option>
        );
    }

    return (
        <React.Fragment>
            <Formik
                ref={formRef}
                enableReinitialize
                initialValues={{
                    player_id: undefined,
                    player_user_id: undefined,
                    player_name: undefined,
                    player_country: undefined,
                    player_fargo: undefined,
                    player_race_to: 5,
                    opponent_id: undefined,
                    opponent_user_id: undefined,
                    opponent_name: undefined,
                    opponent_fargo: undefined,
                    opponent_race_to: 5,
                    opponent_country: undefined,
                }}
                validationSchema={MatchSchema}
                validate={(values) => {
                    // console.log(values);
                }}
                onSubmit={(values, actions) => {
                    console.log(values);

                    // startScoringMatch(
                    //     {
                    //         game_type: 'EIGHT_BALL',
                    //         table: table,
                    //     },
                    //     event
                    // );

                    handleSubmit(values);
                }}
                render={({ errors, touched, handleChange, values }) => (
                    <Modal
                        title={`${gameType ? displayEnumType(gameType) : ''} Scoring Setup`}
                        visible={modalVisible}
                        onOk={onModalOk}
                        okText="Continue"
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        // transitionName="none"
                        maskTransitionName="none"
                        destroyOnClose={true}
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                onClick={() => {
                                    if (formRef.current) {
                                        formRef.current.handleSubmit();
                                    }
                                }}
                            >
                                Continue
                            </Button>,
                        ]}
                        bodyStyle={{
                            maxHeight: 300,
                            overflowY: 'auto',
                            padding: '10px 20px',
                        }}
                    >
                        <Form
                            {...formItemLayout}
                            onSubmit={handleSubmit}
                            layout="horizontal"
                            // onSubmit={(e) => {
                            //     e.preventDefault();
                            //     handleSubmit(values);
                            // }}
                        >
                            <FormItem label="Player name" name="player_name" tabIndex={1}>
                                <AutoComplete
                                    dataSource={acDataSource && acDataSource.map(renderOption)}
                                    // value={autocompleteValue}
                                    // validate={validator}
                                    style={{ width: '100%' }}
                                    onSearch={onSearch}
                                    onSelect={onSelect1}
                                    onChange={handleChange}
                                    // onChange={onAutoCompleteChange}
                                    size="large"
                                    name="player_name"
                                    // placeholder="Enter full name"
                                    optionFilterProp="children"
                                    optionLabelProp="value"
                                    autoFocus
                                    allowClear
                                    prefix={
                                        values.country && (
                                            <span role="img" aria-label={values.country}>
                                                <CircleFlag country={getCountryName(values.country)} left={0} />{' '}
                                            </span>
                                        )
                                    }
                                    filterOption={(input, option) => {
                                        return (
                                            option &&
                                            option.props &&
                                            option.props.children &&
                                            option.props.children.props &&
                                            option.props.children.props.children &&
                                            option.props.children.props.children[1] &&
                                            option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                >
                                    <Input
                                        name="player_name"
                                        placeholder="Enter full name"
                                        size="large"
                                        style={{ paddingLeft: values.country ? 12 : 10 }}
                                        onChange={(event) => {
                                            // onChange(event);
                                            handleFormFieldUpdates(event);
                                        }}
                                        prefix={
                                            values.country && (
                                                <span role="img" aria-label={values.country}>
                                                    <CircleFlag size={10} country={values.country} left={0} />{' '}
                                                </span>
                                            )
                                        }
                                    />
                                </AutoComplete>
                            </FormItem>
                            <FormItem label="Player races to" name="player_race_to" required tabIndex={2}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <InputNumber name="player_race_to" min={1} placeholder="0" size="large" />
                                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                        <Ripples color="#ccc">
                                            <Button
                                                type="primary"
                                                size="large"
                                                ghost
                                                onClick={() => {
                                                    formRef.current.setValues({
                                                        ...formRef.current.state.values,
                                                        player_race_to: values.player_race_to !== undefined ? values.player_race_to + 1 : 1,
                                                    });
                                                }}
                                            >
                                                <Icon type="plus" />
                                            </Button>
                                        </Ripples>
                                        <div style={{ width: 10 }} />
                                        <Ripples color="#ccc">
                                            <Button
                                                type="danger"
                                                size="large"
                                                ghost
                                                onClick={() => {
                                                    formRef.current.setValues({
                                                        ...formRef.current.state.values,
                                                        player_race_to:
                                                            values.player_race_to !== undefined && values.player_race_to > 1 ? values.player_race_to - 1 : 1,
                                                    });
                                                }}
                                            >
                                                <Icon type="minus" />
                                            </Button>
                                        </Ripples>
                                    </div>
                                </div>
                            </FormItem>

                            <FormItem label="Opponent name" name="opponent_name" tabIndex={3}>
                                <AutoComplete
                                    dataSource={acDataSource && acDataSource.map(renderOption)}
                                    // value={autocompleteValue}
                                    // validate={validator}
                                    style={{ width: '100%' }}
                                    onSearch={onSearch}
                                    onSelect={onSelect2}
                                    onChange={handleChange}
                                    // onChange={onAutoCompleteChange}
                                    size="large"
                                    name="opponent_name"
                                    // placeholder="Enter full name"
                                    optionFilterProp="children"
                                    optionLabelProp="value"
                                    allowClear
                                    prefix={
                                        values.country && (
                                            <span role="img" aria-label={values.country}>
                                                <CircleFlag country={getCountryName(values.country)} left={0} />{' '}
                                            </span>
                                        )
                                    }
                                    filterOption={(input, option) => {
                                        return (
                                            option &&
                                            option.props &&
                                            option.props.children &&
                                            option.props.children.props &&
                                            option.props.children.props.children &&
                                            option.props.children.props.children[1] &&
                                            option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                >
                                    <Input
                                        name="opponent_name"
                                        placeholder="Enter full name"
                                        size="large"
                                        style={{ paddingLeft: values.country ? 12 : 10 }}
                                        onChange={(event) => {
                                            // onChange(event);
                                            handleFormFieldUpdates(event);
                                        }}
                                        prefix={
                                            values.country && (
                                                <span role="img" aria-label={values.country}>
                                                    <CircleFlag size={10} country={values.country} left={0} />{' '}
                                                </span>
                                            )
                                        }
                                    />
                                </AutoComplete>
                            </FormItem>
                            <FormItem label="Opponent races to" name="opponent_race_to" required tabIndex={4}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <InputNumber name="opponent_race_to" min={1} placeholder="0" size="large" />
                                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                        <Ripples color="#ccc">
                                            <Button
                                                type="primary"
                                                size="large"
                                                ghost
                                                onClick={() => {
                                                    formRef.current.setValues({
                                                        ...formRef.current.state.values,
                                                        opponent_race_to: values.opponent_race_to !== undefined ? values.opponent_race_to + 1 : 1,
                                                    });
                                                }}
                                            >
                                                <Icon type="plus" />
                                            </Button>
                                        </Ripples>
                                        <div style={{ width: 10 }} />
                                        <Ripples color="#ccc">
                                            <Button
                                                type="danger"
                                                size="large"
                                                ghost
                                                onClick={() => {
                                                    formRef.current.setValues({
                                                        ...formRef.current.state.values,
                                                        opponent_race_to:
                                                            values.opponent_race_to !== undefined && values.opponent_race_to > 1
                                                                ? values.opponent_race_to - 1
                                                                : 1,
                                                    });
                                                }}
                                            >
                                                <Icon type="minus" />
                                            </Button>
                                        </Ripples>
                                    </div>
                                </div>
                            </FormItem>

                            <FormItem name="player_id">
                                <Input name="player_id" type="hidden" />
                            </FormItem>
                            <FormItem name="player_user_id">
                                <Input name="player_user_id" type="hidden" />
                            </FormItem>
                            <FormItem name="player_country">
                                <Input name="player_country" type="hidden" />
                            </FormItem>
                            <FormItem name="player_fargo">
                                <Input name="player_fargo" type="hidden" />
                            </FormItem>
                            <FormItem name="opponent_id">
                                <Input name="opponent_id" type="hidden" />
                            </FormItem>
                            <FormItem name="opponent_user_id">
                                <Input name="opponent_user_id" type="hidden" />
                            </FormItem>
                            <FormItem name="opponent_country">
                                <Input name="opponent_country" type="hidden" />
                            </FormItem>
                            <FormItem name="opponent_fargo">
                                <Input name="opponent_fargo" type="hidden" />
                            </FormItem>

                            <button type="submit" hidden />
                        </Form>
                    </Modal>
                )}
            />
        </React.Fragment>
    );
}
