import React, { useState, useEffect } from 'react';
import TBPreviewRoundRobin from './TBPreviewRoundRobin';
import TBPreviewChip from './TBPreviewChip';
import TBPreviewBracketStages from './TBPreviewBracketStages';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

export default function TBTournamentStep4Preview(props) {
    const { authState, match, bracketCreated, tournament } = props;
    let url;
    if (match.params.slug) {
        url = `/tournament-builder/${match.params.slug}/edit`;
    } else {
        url = `/tournament-builder/new/edit`;
    }

    return (
        <div
            style={{
                padding: 20,
            }}
        >
            {tournament && tournament.tournament_stage_format === 'single' && tournament.tournament_type === 'round_robin' && (
                <TBPreviewRoundRobin
                    {...props}
                    tournament={tournament}
                    goBack={() => {
                        props.history.push(`${url}/tables`);

                        // setCurrentStep(2);
                    }}
                    onContinue={() => {
                        props.history.push(`${url}/flyer`);
                        // setCurrentStep(4);
                    }}
                />
            )}

            {tournament && tournament.tournament_stage_format === 'multi' && tournament.tournament_type === 'round_robin' && (
                <TBPreviewBracketStages
                    {...props}
                    tournament={tournament}
                    bracketCreated={bracketCreated}
                    stages={tournament && tournament.stages}
                    goBack={() => {
                        props.history.push(`${url}/tables`);
                        // setCurrentStep(1);
                    }}
                    onContinue={() => {
                        props.history.push(`${url}/flyer`);
                        // setCurrentStep(4);
                    }}
                    onSetCurrentStep={(step) => {
                        // setCurrentStep(step);
                    }}
                    onSetBracketCreated={(value) => {
                        const user = authState && authState.user && authState.user;
                        mixpanel.track('Tournament bracket created', {
                            ...user,
                            displayName: user.displayName,
                            tournamentId: tournament.id,
                            tournamentName: tournament.name,
                            source: 'web',
                        });
                        // setBracketCreated(value);
                    }}
                />
            )}

            {tournament && tournament.tournament_type === 'chip' && (
                <TBPreviewChip
                    {...props}
                    tournament={tournament}
                    goBack={() => {
                        props.history.push(`${url}/tables`);
                        // setCurrentStep(2);
                    }}
                    onContinue={() => {
                        props.history.push(`${url}/flyer`);
                        // setCurrentStep(4);
                    }}
                />
            )}

            {tournament && tournament.tournament_type !== 'chip' && tournament.tournament_type !== 'round_robin' && (
                <TBPreviewBracketStages
                    {...props}
                    tournament={tournament}
                    bracketCreated={bracketCreated}
                    stages={tournament && tournament.stages}
                    goBack={() => {
                        props.history.push(`${url}/tables`);
                        // setCurrentStep(1);
                    }}
                    onContinue={() => {
                        props.history.push(`${url}/flyer`);
                        // setCurrentStep(4);
                    }}
                    onSetCurrentStep={(step) => {
                        const enumField2 = {
                            0: 'settings',
                            1: 'players',
                            2: 'tables',
                            3: 'preview',
                            4: 'flyer',
                            5: 'review',
                        };
                        props.history.push(`${url}/${enumField2[step]}`);
                        // setCurrentStep(step);
                    }}
                    onSetBracketCreated={(value) => {
                        const user = authState && authState.user && authState.user;
                        mixpanel.track('Tournament bracket created', {
                            ...user,
                            displayName: user.displayName,
                            tournamentId: tournament.id,
                            tournamentName: tournament.name,
                            source: 'web',
                        });
                        // setBracketCreated(value);
                    }}
                />
            )}
        </div>
    );
}
