import React, { useState, useEffect, useRef } from 'react';
import { message, Row, Col, Icon, Table, Typography, Slider, Result, Button } from 'antd';
import { functions } from '../../../firebase';
import CircleFlag from '../../../components/CircleFlag';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_SHOTCAST_EVENTS_QUERY } from './data/queries';
import { GET_SHOTCAST_EVENTS_SUBSCRIPTION } from './data/subscriptions';
import moment from 'moment';

const { Title, Text } = Typography;

const styles = {};

export default function MatchStatsPlayByPlay(props) {
    const { authState, match, matchId, matchData, theme, user } = props;
    const [loading, setLoading] = useState(false);

    console.log(match);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',

            width: 100,
            sorter: (a, b) => {
                return a.id - b.id;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Player Name',
            dataIndex: 'player_name',
            key: 'player_name',
            sorter: (a, b) => {
                return (
                    a.player_name &&
                    b.player_name.localeCompare(a.player_name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => {
                return (
                    a.description &&
                    b.description.localeCompare(a.description, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Shot data',
            dataIndex: 'shot_data',
            key: 'shot_data',
            render: (text, record) => {
                return <Text>{record.shot_data ? JSON.stringify(record.shot_data, null, 4) : '-'}</Text>;
            },
        },
        {
            title: 'Timestamp',
            dataIndex: 'event_timestamp',
            defaultSortOrder: 'desc',
            width: 100,
            sorter: (a, b) =>
                b.event_timestamp.localeCompare(a.event_timestamp, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),

            render: (text, record) => {
                return <Text>{text ? moment(text).format('lll') : '-'}</Text>;
            },
        },
    ];

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Subscription
                    subscription={GET_SHOTCAST_EVENTS_SUBSCRIPTION}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{ match_id: matchId }}
                    onSubscriptionData={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if (data && !data.shotcast_events) {
                            return <div>No events found.</div>;
                        }
                        if (data && data.shotcast_events.length === 0) {
                            return <div>No events found.</div>;
                        }
                        console.log(data);
                        const shotcast_events = data.shotcast_events;

                        return (
                            <div className="table_nowrap" style={{ width: '100%' }}>
                                <Table
                                    rowKey="id"
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                console.log(record, rowIndex);
                                            }, // click row
                                            onDoubleClick: (event) => {}, // double click row
                                            onContextMenu: (event) => {}, // right button click row
                                            onMouseEnter: (event) => {}, // mouse enter row
                                            onMouseLeave: (event) => {}, // mouse leave row
                                        };
                                    }}
                                    columns={columns}
                                    dataSource={shotcast_events}
                                    pagination={false}
                                    tableLayout="auto"
                                    scroll={{ x: 400 }}
                                />
                            </div>
                        );
                    }}
                </Subscription>
            </SectionContent>
        </SectionWrapper>
    );
}
