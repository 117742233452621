import { gql } from '@apollo/client';
import { LEAGUE_FIELDS, LEAGUE_MATCH_FIELDS, LEAGUE_DIVISION_FIELDS } from './fragments';

export const GET_LEAGUE_MATCHES_SUBSCRIPTION = gql`
    ${LEAGUE_MATCH_FIELDS}
    subscription league_matches($league_id: Int!, $division_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, _and: [{ status: { _eq: IN_PROGRESS } }] }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION = gql`
    ${LEAGUE_MATCH_FIELDS}
    subscription league_matches($league_id: Int!, $slug: String!) {
        league_matches(where: { league_id: { _eq: $league_id }, slug: { _eq: $slug } }, order_by: { scheduled_time: desc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_MATCH_SUBSCRIPTION = gql`
    ${LEAGUE_MATCH_FIELDS}
    subscription league_matches($league_id: Int!, $match_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, id: { _eq: $match_id } }, order_by: { scheduled_time: desc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_PLAYER_STANDINGS_SUBSCRIPTION = gql`
    subscription league_player_stats($league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id }, place: { _is_null: false } }, distinct_on: id) {
            id
            name
            place
            country
            skill_level
            points
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_TEAM_STATS_SUBSCRIPTION = gql`
    subscription league_team_stats($league_id: Int!, $division_id: Int!) {
        league_teams(where: { league_id: { _eq: $league_id }, league_team_divisions: { division_id: { _eq: $division_id } } }, order_by: { name: asc }) {
            id
            name
            league_home_team_matches_won: league_home_team_matches_aggregate(
                where: { home_team_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_home_team_matches_lost: league_home_team_matches_aggregate(
                where: { home_team_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_visitor_team_matches_won: league_visitor_team_matches_aggregate(
                where: { visitor_team_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_visitor_team_matches_lost: league_visitor_team_matches_aggregate(
                where: { visitor_team_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_home_team_points: league_home_team_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        home_team_points
                        visitor_team_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            league_visitor_team_points: league_visitor_team_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        home_team_points
                        visitor_team_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            league_home_team_games: league_home_team_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        home_team_score
                        visitor_team_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            league_visitor_team_games: league_visitor_team_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        home_team_score
                        visitor_team_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_TEAM_STATS_SUBSCRIPTION = gql`
    subscription league_team_stats($league_id: Int!) {
        league_teams(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            league_home_team_matches_won: league_home_team_matches_aggregate(
                where: { home_team_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_home_team_matches_lost: league_home_team_matches_aggregate(
                where: { home_team_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_visitor_team_matches_won: league_visitor_team_matches_aggregate(
                where: { visitor_team_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_visitor_team_matches_lost: league_visitor_team_matches_aggregate(
                where: { visitor_team_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            league_home_team_games: league_home_team_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        home_team_score
                        visitor_team_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            league_visitor_team_matches_games: league_visitor_team_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        home_team_score
                        visitor_team_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription league_player_stats($league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            country
            skill_level
            place
            points
            player_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            player_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            opponent_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            opponent_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            player_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            opponent_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription league_players($league_id: Int!, $division_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id }, league_player_divisions: { division_id: { _eq: $division_id } } }, order_by: { name: asc }) {
            id
            name
            country
            skill_level
            place
            points
            player_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            player_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            opponent_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            opponent_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            player_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            opponent_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_MATCH_STATS_SUBSCRIPTION = gql`
    subscription league_matches($league_id: Int!, $division_id: Int!, $match_id: Int!) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, id: { _eq: $match_id } }) {
            id
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_is_winner
            challenger1_score
            challenger1_frames
            challenger1_points
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_is_winner
            challenger2_score
            challenger2_frames
            challenger2_points
            league_match_frames_aggregate {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    id
                    challenger1_points
                    challenger2_points
                    challenger1_balls_pocketed
                    challenger2_balls_pocketed
                    challenger1_errors
                    challenger2_errors
                    challenger1_high_break
                    challenger2_high_break
                }
            }
        }
    }
`;
