import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { message, Alert, Descriptions, Tooltip, Row, Avatar, Button, Empty, Modal, Tag, Icon, List, Skeleton, Col, Typography } from 'antd';
import { Form, FormItem, InputNumber } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, displayEnumType } from '../../../utils';
import { UPDATE_MATCH_MUTATION, UPDATE_MATCH_STAT, TRACK_MATCH_STAT, DELETE_MATCH_STATS } from './data/mutations';
import { GET_MATCH_PLAYER_SCORES_QUERY, GET_MATCH_STATS_QUERY } from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useBreakpoint from 'use-breakpoint';
import CircleStateFlag from '../../../components/CircleStateFlag';
import Flag from '../../../components/Flag';
import { matchStats as stats } from '../../../utils';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import numeral from 'numeral';
import axios from 'axios';
import apiEndPoint from '../../../utils/apiEndpoint';
import {
    displayOdds,
    isBlank,
    calculateLine,
    getOverUnderMultiplier,
    calculateOverUnder,
    getFargoOddsAsync,
    clearOdds,
    dec2frac,
    dec2US,
    prob2dec,
    round,
    gameOptions,
    isNormalInteger,
} from '../../../utils/fantasyLinesCalc';
import { QuestionCircleFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { confirm } = Modal;

const styles = {};

export default function ScoreboardControls(props) {
    const { matchId, matchData, client, authState, subscribeToMore, subscribeToNewData, isAdmin, theme } = props;
    const [loading, setLoading] = useState(false);
    const [playerFargoOdds, setPlayerFargoOdds] = useState(0);
    const [playerMoneyLine, setPlayerMoneyLine] = useState(0);
    const [opponentMoneyLine, setOpponentMoneyLine] = useState(0);
    const [opponentFargoOdds, setOpponentFargoOdds] = useState(0);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    // const [updateStat] = useMutation(UPDATE_MATCH_STAT);
    const [trackStat] = useMutation(TRACK_MATCH_STAT);
    const formRef = useRef();
    const baseline = -125;

    console.log(matchData);

    useEffect(() => {
        const unsubscribe = subscribeToNewData(subscribeToMore);

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        getFargoOdds(matchData);
    }, []);

    const ballsMade = matchData.scoreboard_balls || {};

    let duration;
    if (matchData.start_date_time && matchData.end_date_time) {
        var start = moment(matchData.start_date_time);
        var end = moment(matchData.end_date_time);
        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
        // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
    } else {
        duration = moment.duration(moment.utc().diff(matchData.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
        // duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('hh:mm');
    }

    //const stats = [
    //	'Balls pocketed',
    //	'Balls made on the break',
    //	'Balls missed',
    //	'Unforced errors',
    //	'Fouls',
    //	'Illegal breaks',
    //	'Dry breaks',
    //	'Runouts',
    //	'Break and runs',
    //	'Golden breaks',
    //	'Racks won on break',
    //	'Racks won against break'
    //];

    // const stats = [
    // 	'Balls made',
    // 	'Errors',
    // 	'High run',
    // 	'Balls made on the break',
    // 	'Balls missed',
    // 	'Fouls',
    // 	'Illegal breaks',
    // 	'Dry breaks',
    // 	'Runouts',
    // 	'Break and runs',
    // 	'Golden breaks',
    // 	'Racks won on break',
    // 	'Racks won against break',
    // 	'Bank shots made',
    // 	'Bank shots missed',
    // 	'Kick shots made',
    // 	'Kick shots missed',
    // 	'Cut shots made',
    // 	'Cut shots missed',
    // 	'Straight shots made',
    // 	'Straight shots missed',
    // 	'Carom shots made',
    // 	'Carom shots missed',
    // 	'Combo shots made',
    // 	'Combo shots missed',
    // 	'Safety shots made',
    // 	'Safety shots missed',
    // 	'Fluked shots',
    // 	'Kick safes made',
    // 	'Kick safes missed',
    // 	'Successful breaks',
    // 	'Unsuccessful breaks'
    // ];

    const initialValues = {};
    const matchStats = matchData.match_stats;
    const gameNumberStat = matchStats.filter((stat) => {
        return stat.metric.indexOf('game_number') !== -1;
    });
    const gameNumber = (gameNumberStat && gameNumberStat[0] && gameNumberStat[0].game_number) || 1;
    // console.log(gameNumberStat);
    // console.log(gameNumber);
    const playerStats = matchStats.filter((stat) => {
        return stat.metric.indexOf('player_') !== -1;
    });

    const opponentStats = matchStats.filter((stat) => {
        return stat.metric.indexOf('opponent_') !== -1;
    });

    stats.forEach((stat, index) => {
        const statUnderscored = stat.split(' ').join('_').toLowerCase();
        const keyPlayer = `player_${statUnderscored}`;
        const keyOpponent = `opponent_${statUnderscored}`;
        const matchingPlayerStat = playerStats.filter((stat) => {
            return stat.metric === keyPlayer;
        })[0];
        const matchingOpponentStat = opponentStats.filter((stat) => {
            return stat.metric === keyOpponent;
        })[0];
        initialValues[keyPlayer] = (matchingPlayerStat && matchingPlayerStat.value) || 0;
        initialValues[keyOpponent] = (matchingOpponentStat && matchingOpponentStat.value) || 0;
    });

    function getMatchProgress(matchData) {
        const totalRace = parseInt(matchData.race_to * 2);
        const matchProgress =
            matchData.status === 'COMPLETED'
                ? 100
                : parseInt(matchData.player_score) >= totalRace || parseInt(matchData.opponent_score) >= totalRace
                ? 100
                : (((matchData.player_score + matchData.opponent_score) / totalRace) * 100).toFixed(0);
        return parseInt(matchProgress);
    }

    function updateMatch(changes, text) {
        // const key = 'scores';
        // message.loading({ content: `Updating ${text}...`, key, duration: 1 });
        return client
            .mutate({
                mutation: UPDATE_MATCH_MUTATION,
                variables: {
                    id: matchId,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_PLAYER_SCORES_QUERY,
                        variables: { id: matchId },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function updateStats(values, updateStat) {
        // const key = 'scores';
        // message.loading({ content: `Updating ${text}...`, key, duration: 1 });
        // return client
        // 	.mutate({
        // 		mutation: UPDATE_MATCH_MUTATION,
        // 		variables: {
        // 			id: matchId,
        // 			changes: changes
        // 		},
        // 		awaitRefetchQueries: true,
        // 		refetchQueries: [
        // 			{
        // 				query: GET_MATCH_PLAYER_SCORES_QUERY,
        // 				variables: { id: matchId }
        // 			}
        // 		]
        // 	})
        // 	.then((data) => {
        // 		console.log(data);
        // 		message.success({
        // 			content: `${text} updated`,
        // 			key,
        // 			duration: 1
        // 		});
        // 	})
        // 	.catch((error) => {
        // 		console.error(error);
        // 		message.info('There was an error', error);
        // 	});
    }

    function trackStats(player, stat, value) {
        return trackStat({
            variables: {
                objects: [
                    {
                        metric: stat,
                        value: value,
                        match_id: matchData.id,
                        user_id: authState && authState.user && authState.user.id,
                        time: moment.utc(),
                        // match_game_id,
                        ...(stat.includes('player_') && { player_id: player && player.id }),
                        ...(stat.includes('opponent_') && { opponent_id: player && player.id }),
                        game_number: gameNumber,
                        // opponent_id
                    },
                ],
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_MATCH_STATS_QUERY,
            // 		variables: { match_id: matchId }
            // 	}
            // ]
        })
            .then((values) => {
                // console.log(values);
                return values;
            })
            .catch((e) => {
                const err = (e && e.message) || JSON.stringify(e);
                message.error(err);
                console.log(err);
                return err;
            });
    }

    async function incrementPlayerScore(currentScore, matchData) {
        const matchProgress = getMatchProgress(matchData);
        const score = currentScore + 1;
        const stat = 'player_score';
        const changes = {
            player_score: score,
            progress: matchProgress,
        };
        updateMatch(changes, 'player scores');
        getFargoOdds(matchData);
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.player_name;
        })[0];
        const matchingPlayerStat = playerStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newScore = matchingPlayerStat ? parseInt(matchingPlayerStat.value) + 1 : score;
        await incrementGameNumber();
        await trackStats(player, stat, newScore);
        const tpa = getPlayerTPA();
        trackStats(player, 'player_tpa', tpa);
    }

    async function decrementPlayerScore(currentScore, matchData) {
        const matchProgress = getMatchProgress(matchData);
        const score = currentScore - 1;
        const stat = 'player_score';
        const changes = {
            player_score: score,
            progress: matchProgress,
        };
        updateMatch(changes, 'player scores');
        getFargoOdds(matchData);
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.player_name;
        })[0];
        const matchingPlayerStat = playerStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newScore = matchingPlayerStat ? parseInt(matchingPlayerStat.value) - 1 : score;
        await decrementGameNumber();
        await trackStats(player, stat, newScore);
        const tpa = getPlayerTPA();
        trackStats(player, 'player_tpa', tpa);
    }

    function incrementPlayerPoints(currentPoints) {
        const points = currentPoints + 1;
        const stat = 'player_points';
        const changes = {
            player_points: points,
        };
        updateMatch(changes, 'player points');
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.player_name;
        })[0];

        const matchingPlayerStat = playerStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newPoints = matchingPlayerStat ? parseInt(matchingPlayerStat.value) + 1 : points;
        trackStats(player, stat, newPoints);
    }

    function decrementPlayerPoints(currentPoints) {
        const points = currentPoints - 1;
        const stat = 'player_points';
        const changes = {
            player_points: points,
        };
        updateMatch(changes, 'player points');
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.player_name;
        })[0];
        const matchingPlayerStat = playerStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newPoints = matchingPlayerStat ? parseInt(matchingPlayerStat.value) - 1 : points;
        trackStats(player, stat, newPoints);
    }

    function resetPlayerPoints() {
        const changes = {
            player_points: 0,
        };
        updateMatch(changes, 'player points');
    }

    async function incrementOpponentScore(currentScore, matchData) {
        const matchProgress = getMatchProgress(matchData);
        const score = currentScore + 1;
        const stat = 'opponent_score';
        const changes = {
            opponent_score: score,
            progress: matchProgress,
        };
        getFargoOdds(matchData);
        updateMatch(changes, 'opponent scores');
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.opponent_name;
        })[0];
        const matchingPlayerStat = opponentStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newScore = matchingPlayerStat ? parseInt(matchingPlayerStat.value) + 1 : score;
        await incrementGameNumber();
        await trackStats(player, stat, newScore);
        const tpa = getOpponentTPA();
        console.log(tpa);
        trackStats(player, 'opponent_tpa', tpa);
    }

    async function decrementOpponentScore(currentScore, matchData) {
        const matchProgress = getMatchProgress(matchData);
        const score = currentScore - 1;
        const stat = 'opponent_score';
        const changes = {
            opponent_score: score,
            progress: matchProgress,
        };
        updateMatch(changes, 'opponent scores');
        getFargoOdds(matchData);
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.opponent_name;
        })[0];
        const matchingPlayerStat = opponentStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newScore = matchingPlayerStat ? parseInt(matchingPlayerStat.value) - 1 : score;
        await decrementGameNumber();
        await trackStats(player, stat, newScore);
        const tpa = getOpponentTPA();
        trackStats(player, 'opponent_tpa', tpa);
    }

    function incrementOpponentPoints(currentPoints) {
        const points = currentPoints + 1;
        const stat = 'opponent_points';
        const changes = {
            opponent_points: points,
        };
        updateMatch(changes, 'opponent points');
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.opponent_name;
        })[0];
        const matchingPlayerStat = opponentStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newPoints = matchingPlayerStat ? parseInt(matchingPlayerStat.value) + 1 : points;
        trackStats(player, stat, newPoints);
    }

    function decrementOpponentPoints(currentPoints) {
        const points = currentPoints - 1;
        const stat = 'opponent_points';
        const changes = {
            opponent_points: points,
        };
        updateMatch(changes, 'opponent points');
        const player = matchData.match_players.filter((player) => {
            return player.name === matchData.opponent_name;
        })[0];
        const matchingPlayerStat = opponentStats.filter((item) => {
            return item.metric === stat;
        })[0];
        const newPoints = matchingPlayerStat ? parseInt(matchingPlayerStat.value) - 1 : points;
        trackStats(player, stat, newPoints);
    }

    function incrementGameNumber() {
        const newGameNumber = parseInt(gameNumber) + 1 || 1;
        return trackStat({
            variables: {
                objects: [
                    {
                        metric: 'game_number',
                        value: newGameNumber,
                        match_id: matchData.id,
                        user_id: authState && authState.user && authState.user.id,
                        time: moment.utc(),
                        // match_game_id,
                        game_number: newGameNumber,
                        // opponent_id
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_MATCH_STATS_QUERY,
                    variables: { match_id: matchId },
                },
            ],
        })
            .then((values) => {
                console.log(values);
                return values;
                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((e) => {
                message.error((e && e.message) || JSON.stringify(e));
                console.log(e);
                return e;
            });
    }

    function decrementGameNumber() {
        const newGameNumber = parseInt(gameNumber) - 1 || 1;
        return trackStat({
            variables: {
                objects: [
                    {
                        match_id: matchData.id,
                        metric: 'game_number',
                        value: newGameNumber,
                        user_id: authState && authState.user && authState.user.id,
                        time: moment.utc(),
                        // match_game_id,
                        game_number: newGameNumber,
                        // opponent_id
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_MATCH_STATS_QUERY,
                    variables: { match_id: matchId },
                },
            ],
        })
            .then((values) => {
                console.log(values);
                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
                return values;
            })
            .catch((e) => {
                message.error((e && e.message) || JSON.stringify(e));
                console.log(e);
                return e;
            });
    }

    function resetOpponentPoints() {
        const changes = {
            opponent_points: 0,
        };
        updateMatch(changes, 'opponent points');
    }

    function markBallMade(ball, ballsMade, matchData) {
        console.log(ball);
        console.log(ballsMade);
        // const ballsMade = {
        // 	1: true,
        // 	2: true,
        // 	3: true,
        // 	5: false,
        // 	6: true,
        // 	7: false,
        // 	8: true,
        // 	9: true,
        // 	10: false,
        // 	11: false,
        // 	12: false,
        // 	13: false,
        // 	14: false,
        // 	15: false
        // };

        // if (authState && authState.user && authState.user.id === matchData.creator_id) {
        if (isAdmin) {
            const isScored = ballsMade[ball] === true;
            const markingMessage = isScored === true ? `Un-marking ball ${ball} as scored...` : `Marking ball ${ball} as scored...`;

            const key = 'ballsMade';
            message.loading({ content: markingMessage, key, duration: 1 });
            return client
                .mutate({
                    mutation: UPDATE_MATCH_MUTATION,
                    variables: {
                        id: matchId,
                        changes: {
                            scoreboard_balls: {
                                ...ballsMade,
                                [ball]: isScored ? false : true,
                            },
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_MATCH_PLAYER_SCORES_QUERY,
                            variables: { id: matchId },
                        },
                    ],
                })
                .then((data) => {
                    console.log(data);
                    message.success({
                        content: `Scored balls updated`,
                        key,
                        duration: 1,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    message.info('There was an error', error);
                });
        }
    }

    function resetBallsMade() {
        const key = 'ballsMade';
        message.loading({ content: 'Resetting balls scored...', key, duration: 1 });
        return client
            .mutate({
                mutation: UPDATE_MATCH_MUTATION,
                variables: {
                    id: matchId,
                    changes: {
                        scoreboard_balls: {},
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_PLAYER_SCORES_QUERY,
                        variables: { id: matchId },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `Scored balls have been reset.`,
                    key,
                    duration: 1,
                });
            })
            .catch((error) => {
                console.error(error);
                message.error('There was an error', error);
            });
    }

    function resetAllStats() {
        const key = 'resetStats';
        message.loading({ content: 'Resetting player stats...', key, duration: 1 });
        return client
            .mutate({
                mutation: DELETE_MATCH_STATS,
                variables: {
                    match_id: matchData.id,
                },
            })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `Player stats reset.`,
                    key,
                    duration: 1,
                });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function startMatch() {
        const key = 'startMatch';

        message.loading({ content: 'Starting match...', key, duration: 1 });

        // let config = {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     'Access-Control-Allow-Credentials': 'true',
        // };

        if (matchData && matchData.pool_table && matchData.pool_table.kiosk_ip_address) {
            axios
                .post(`${apiEndPoint}fullyKiosk`, {
                    endpoint: `${matchData.pool_table.kiosk_ip_address}&cmd=loadUrl&url=https://dev.digitalpool.com/events/${matchData.event.slug}/tables/${matchData.pool_table.slug}/scoring`,
                })
                .then((res) => {
                    console.log(res.data);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    return error;
                });
        }

        return client
            .mutate({
                mutation: UPDATE_MATCH_MUTATION,
                variables: {
                    id: matchId,
                    changes: {
                        status: 'IN_PROGRESS',
                        progress: 0,
                        start_date_time: moment.utc(),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_PLAYER_SCORES_QUERY,
                        variables: { id: matchId },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `Match started.`,
                    key,
                    duration: 1,
                });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function showConfirmEndMatch() {
        confirm({
            title: `Are you sure you want to end the match? This action cannot be undone.`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                endMatch();
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function showConfirmResetStats() {
        confirm({
            title: `Are you sure you want to reset all player stats? This action cannot be undone.`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                resetAllStats();
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function endMatch() {
        const key = 'endMatch';
        message.loading({ content: 'Ending match...', key, duration: 1 });

        if (matchData && matchData.pool_table && matchData.pool_table.kiosk_ip_address) {
            axios
                .post(`${apiEndPoint}fullyKiosk`, {
                    endpoint: `${matchData.pool_table.kiosk_ip_address}&cmd=loadStartUrl`,
                })
                .then((res) => {
                    console.log(res.data);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    return error;
                });
        }

        return client
            .mutate({
                mutation: UPDATE_MATCH_MUTATION,
                variables: {
                    id: matchId,
                    changes: {
                        status: 'COMPLETED',
                        progress: 100,
                        end_date_time: moment.utc(),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_PLAYER_SCORES_QUERY,
                        variables: { id: matchId },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `Match ended.`,
                    key,
                    duration: 1,
                });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function renderStat(player, stat, key, values) {
        return (
            <div style={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Row
                    justify="space-between"
                    type="flex"
                    style={{
                        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        alignItems: 'center',
                        borderRadius: 6,
                        color: '#fff',
                    }}
                >
                    <Col xs={24} sm={12} align="start">
                        <div style={{ fontSize: 16, paddingLeft: 5 }}>{stat}</div>
                    </Col>
                    <Col xs={24} sm={12} align="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {/* <Tooltip placement="top" title={stat}>
                                <QuestionCircleFilled style={{ color: '#999', marginRight: 10 }} />
                            </Tooltip> */}
                            <div>
                                <FormItem name={`${key}`}>
                                    <InputNumber
                                        name={`${key}`}
                                        defaultValue={0}
                                        placeholder={0}
                                        onPressEnter={() => {
                                            formRef.current.handleSubmit();
                                            // handleSubmit(formRef.current.state.values);
                                        }}
                                        // onBlur={() => {
                                        // 	formRef.current.handleSubmit();
                                        // 	// handleSubmit(formRef.current.state.values);
                                        // }}
                                        onChange={(value) => {
                                            formRef.current.handleSubmit();
                                            if (value) {
                                                const updatedValues = {
                                                    ...formRef.current.state.values,
                                                    [key]: value,
                                                };
                                                handleSubmit(player, key, value, updatedValues);
                                            }
                                        }}
                                        style={{
                                            flex: 1,
                                            fontSize: 18,
                                            height: '100%',
                                            backgroundColor: 'transparent',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            marginRight: 8,
                                            border: '1px solid #5e6977',
                                            borderRadius: 4,
                                            maxWidth: 80,
                                            color: '#fff',
                                        }}
                                    />
                                </FormItem>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        const currentValue = formRef.current.state.values[key];
                                        const newValue = currentValue + 1;
                                        const updatedValues = {
                                            ...formRef.current.state.values,
                                            [key]: newValue,
                                        };
                                        formRef.current.setValues(updatedValues);
                                        formRef.current.handleSubmit();
                                        handleSubmit(player, key, newValue, updatedValues);
                                    }}
                                >
                                    <Icon type="plus" />
                                </Button>
                                <Button
                                    type="danger"
                                    ghost
                                    onClick={() => {
                                        const currentValue = formRef.current.state.values[key];
                                        const newValue = currentValue - 1;
                                        const updatedValues = {
                                            ...formRef.current.state.values,
                                            [key]: newValue,
                                        };
                                        formRef.current.setValues(updatedValues);
                                        formRef.current.handleSubmit();
                                        handleSubmit(player, key, newValue, updatedValues);
                                    }}
                                >
                                    <Icon type="minus" />
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    // TPA formula = balls pocketed / balls pocketed + errors
    function calculateTPA(ballsPocketed, errors) {
        return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.00');
    }

    function getPlayerTPA() {
        const playerBallsPocketed = playerStats.filter((stat) => stat.metric === 'player_balls_pocketed')[0];
        const playerBallsPocketedValue = playerBallsPocketed ? playerBallsPocketed.value : 0;
        const playerErrors = playerStats.filter((stat) => stat.metric === 'player_unforced_errors')[0];
        const playerErrorValue = playerErrors ? playerErrors.value : 0;
        const tpaScore = calculateTPA(playerBallsPocketedValue, playerErrorValue);

        return tpaScore;
    }

    function getOpponentTPA() {
        const opponentBallsPocketed = opponentStats.filter((stat) => stat.metric === 'opponent_balls_pocketed')[0];
        const opponentBallsPocketedValue = opponentBallsPocketed ? opponentBallsPocketed.value : 0;
        const opponentErrors = opponentStats.filter((stat) => stat.metric === 'opponent_unforced_errors')[0];
        const opponentErrorValue = opponentErrors ? opponentErrors.value : 0;
        const tpaScore = calculateTPA(opponentBallsPocketedValue, opponentErrorValue);

        return tpaScore;
    }

    // async function getFargoOdds(matchData) {
    // 	const totalRace = Math.round((matchData.race_to - (matchData.player_score + matchData.opponent_score)) / 2);
    // 	var odds = await getFargoOddsAsync(matchData.player_fargo, matchData.opponent_fargo, totalRace, totalRace);
    // 	const playerOdds = odds.implied1;
    // 	const opponentOdds = odds.implied2;
    // 	const line1 = parseInt(odds.american1);
    // 	const line2 = parseInt(odds.american2);
    // 	setPlayerMoneyLine(line1);
    // 	setOpponentMoneyLine(line2);
    // 	// setPlayerMoneyLine(line - baseline);

    // 	// console.log(odds);
    // 	setPlayerFargoOdds(`${playerOdds}%`);
    // 	setOpponentFargoOdds(`${opponentOdds}%`);
    // 	return odds;
    // }

    async function getFargoOdds(matchData) {
        const totalRace = Math.round(matchData.race_to / 2);
        const player1Score = Math.round(totalRace - matchData.player_score / 2);
        const player2Score = Math.round(totalRace - matchData.opponent_score / 2);
        var odds = await getFargoOddsAsync(matchData.player_fargo, matchData.opponent_fargo, player1Score, player2Score);
        // console.log('p1 score', totalRace - matchData.player_score);
        // console.log('p2 score', totalRace - matchData.opponent_score);
        // console.log(odds);
        // const totalRace = Math.round((matchData.race_to - (matchData.player_score + matchData.opponent_score)) / 2);
        // var odds = await getFargoOddsAsync(matchData.player_fargo, matchData.opponent_fargo, totalRace, totalRace);
        const playerOdds = (odds.oneOdds * 100).toFixed(2);
        const opponentOdds = (odds.twoOdds * 100).toFixed(2);
        // const line1 = parseInt(odds.american1);
        // const line2 = parseInt(odds.american2);
        // setPlayerMoneyLine(line1);
        // setOpponentMoneyLine(line2);
        setPlayerFargoOdds(`${playerOdds}%`);
        setOpponentFargoOdds(`${opponentOdds}%`);
        return odds;
    }

    function handleSubmit(player, stat, value, values) {
        trackStats(player, stat, value);
    }

    const gameTypes = {
        ONE_POCKET: 15,
        NINE_BALL: 9,
        TEN_BALL: 10,
        STRAIGHT_POOL: 15,
        EIGHT_BALL: 15,
    };

    return (
        <Formik
            ref={formRef}
            onSubmit={(data, actions) => {
                console.log(data);
            }}
            // validationSchema={schema}
            enableReinitialize
            initialValues={initialValues}
            render={({ errors, touched, values }) => (
                <Form
                    layout="vertical"
                    onSubmit={(e) => {
                        e.preventDefault();
                        console.log(values);
                        // handleSubmit(values);
                    }}
                >
                    <div
                        style={{
                            padding: breakpoint === 'mobile' ? '20px 0px' : '20px 0px',
                            color: '#fff',
                        }}
                    >
                        <Row
                            gutter={24}
                            justify="center"
                            type="flex"
                            style={{
                                paddingLeft: breakpoint === 'mobile' ? 10 : 40,
                                paddingRight: breakpoint === 'mobile' ? 10 : 40,
                            }}
                        >
                            <Col xs={12} sm={10} align="center">
                                <div style={{ marginBottom: 10 }}>
                                    {matchData.player_country && (
                                        <React.Fragment>
                                            {matchData.player_state ? (
                                                <CircleStateFlag state={matchData.player_state} size="xlarge" />
                                            ) : (
                                                <Flag
                                                    name={matchData.player_country || 'Unknown'}
                                                    format="png"
                                                    pngSize={48}
                                                    shiny={false}
                                                    alt="Flag"
                                                    className="flag"
                                                    basePath="/images/flags"
                                                />
                                            )}
                                        </React.Fragment>
                                    )}

                                    <div style={{ fontSize: breakpoint === 'mobile' ? 16 : 25 }}>
                                        {matchData.player_name}{' '}
                                        {matchData.player_fargo && playerFargoOdds
                                            ? `- ${matchData.player_fargo} (${playerFargoOdds})`
                                            : matchData.player_fargo
                                            ? `- ${matchData.player_fargo}`
                                            : ''}
                                    </div>

                                    <div>
                                        <Text style={{ color: 'rgba(255,255,255,0.5)' }}>TPA:</Text> <Text style={{ color: '#fff' }}>{getPlayerTPA()}</Text>
                                        {playerMoneyLine && (
                                            <React.Fragment>
                                                <Text
                                                    style={{
                                                        marginLeft: 10,
                                                        color: 'rgba(255,255,255,0.5)',
                                                    }}
                                                >
                                                    Line:
                                                </Text>{' '}
                                                <Text style={{ color: '#fff' }}>{playerMoneyLine}</Text>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>

                                <Row gutter={24} justify="center" type="flex">
                                    {matchData.game_type !== 'NINE_BALL' && matchData.game_type !== 'TEN_BALL' && matchData.game_type !== 'EIGHT_BALL' && (
                                        <Col xs={24} sm={12} align="center">
                                            <div
                                                style={{
                                                    background: 'linear-gradient(45deg, #0089cd, #0045b5)',
                                                    color: '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    overflow: 'hidden',
                                                    borderRadius: 10,
                                                    // border: '2px solid #3090d2'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: 60,
                                                        fontWeight: 700,
                                                        paddingTop: 20,
                                                        paddingBottom: 20,
                                                    }}
                                                >
                                                    {matchData.player_points || 0}
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        backgroundColor: 'rgba(0,0,0,0.2)',
                                                        padding: 5,
                                                    }}
                                                >
                                                    Ball count
                                                </div>
                                            </div>
                                            {(isAdmin || (authState && authState.user && authState.user.role === 'admin')) && (
                                                <div style={{ marginTop: 20 }}>
                                                    <Tooltip placement="bottom" title="Increase ball count by 1">
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                incrementPlayerPoints(matchData.player_points);
                                                            }}
                                                        >
                                                            <Icon type="plus" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip placement="bottom" title="Decrease ball count by 1">
                                                        <Button
                                                            type="danger"
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                decrementPlayerPoints(matchData.player_points);
                                                            }}
                                                        >
                                                            <Icon type="minus" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip placement="bottom" title="Reset ball count to 0">
                                                        <Button
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                resetPlayerPoints();
                                                            }}
                                                        >
                                                            <Icon type="close" />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </Col>
                                    )}

                                    <Col xs={24} sm={12} align="center">
                                        <div
                                            style={{
                                                background: 'linear-gradient(-45deg, black, transparent)',
                                                color: '#fff',

                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                overflow: 'hidden',
                                                borderRadius: 10,
                                                // border: '2px solid #3090d2'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 60,
                                                    fontWeight: 700,
                                                    paddingTop: 20,
                                                    paddingBottom: 20,
                                                }}
                                            >
                                                {matchData.player_score || 0}
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                                    padding: 5,
                                                }}
                                            >
                                                Games won
                                            </div>
                                        </div>
                                        {(isAdmin || (authState && authState.user && authState.user.role === 'admin')) && (
                                            <div style={{ marginTop: 20 }}>
                                                <Tooltip placement="bottom" title="Increase score by 1">
                                                    <Button
                                                        type="primary"
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            incrementPlayerScore(matchData.player_score, matchData);
                                                        }}
                                                    >
                                                        <Icon type="plus" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip placement="bottom" title="Decrease score by 1">
                                                    <Button
                                                        type="danger"
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            decrementPlayerScore(matchData.player_score, matchData);
                                                        }}
                                                    >
                                                        <Icon type="minus" />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            {breakpoint !== 'mobile' && (
                                <Col xs={4} align="center">
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignSelf: 'flex-start',
                                            margin: 0,
                                            padding: breakpoint === 'mobile' ? 10 : '10px 0px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <div style={{ marginBottom: 20, marginTop: 20 }}>
                                            <Tag
                                                size="large"
                                                color={
                                                    matchData.status === 'NOT_STARTED' ? '#80848c' : matchData.status === 'IN_PROGRESS' ? '#108ee9' : '#cd201f'
                                                }
                                            >
                                                {matchData.status}
                                            </Tag>
                                        </div>
                                        {matchData && (
                                            <div
                                                style={{
                                                    marginBottom: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <img alt="example" src={getGameType(matchData.game_type, theme)} width={50} height={50} />
                                                <Text style={{ color: '#fff', marginTop: 3 }}>{displayEnumType(matchData.game_type)}</Text>
                                            </div>
                                        )}

                                        <div style={{ marginTop: 10 }}>
                                            <Text style={{ fontSize: 20, color: '#fff' }}>{duration || '00:00'}</Text>
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            <Text style={{ fontSize: 14, color: '#fff' }}>
                                                Race to{' '}
                                                {matchData.player_race_to && matchData.opponent_race_to
                                                    ? `${matchData.player_race_to}/${matchData.opponent_race_to}`
                                                    : matchData.race_to}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: 14, color: '#fff' }}>Current game</Text>
                                        </div>
                                        <div>
                                            <Text style={{ fontSize: 40, color: '#fff' }}>{gameNumber || 0}</Text>
                                        </div>
                                        {/* <div>
                                                <Button type="default" size="large" ghost style={{ marginTop: 10, marginLeft: 10 }} onClick={startNewGame}>
                                                    New Game
                                                </Button>
                                            </div> */}
                                    </div>
                                </Col>
                            )}

                            <Col xs={12} sm={10} align="center">
                                <div style={{ marginBottom: 10 }}>
                                    {matchData.opponent_country && (
                                        <React.Fragment>
                                            {matchData.opponent_state ? (
                                                <CircleStateFlag state={matchData.opponent_state} size="xlarge" />
                                            ) : (
                                                <Flag
                                                    name={matchData.opponent_country || 'Unknown'}
                                                    format="png"
                                                    pngSize={48}
                                                    shiny={false}
                                                    alt="Flag"
                                                    className="flag"
                                                    basePath="/images/flags"
                                                />
                                            )}
                                        </React.Fragment>
                                    )}

                                    <div style={{ fontSize: breakpoint === 'mobile' ? 16 : 25 }}>
                                        {matchData.opponent_name}{' '}
                                        {matchData.opponent_fargo && opponentFargoOdds
                                            ? `- ${matchData.opponent_fargo} (${opponentFargoOdds})`
                                            : matchData.opponent_fargo
                                            ? `- ${matchData.opponent_fargo}`
                                            : ''}
                                    </div>

                                    <div>
                                        <Text style={{ color: 'rgba(255,255,255,0.5)' }}>TPA:</Text> <Text style={{ color: '#fff' }}>{getOpponentTPA()}</Text>
                                        {opponentMoneyLine && (
                                            <React.Fragment>
                                                <Text
                                                    style={{
                                                        marginLeft: 10,
                                                        color: 'rgba(255,255,255,0.5)',
                                                    }}
                                                >
                                                    Line:
                                                </Text>{' '}
                                                <Text style={{ color: '#fff' }}>{opponentMoneyLine}</Text>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                <Row gutter={24} justify="center" type="flex">
                                    {matchData.game_type !== 'NINE_BALL' && matchData.game_type !== 'TEN_BALL' && matchData.game_type !== 'EIGHT_BALL' && (
                                        <Col xs={24} sm={12} align="center">
                                            <div
                                                style={{
                                                    background: 'linear-gradient(45deg, #0089cd, #0045b5)',
                                                    color: '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    overflow: 'hidden',
                                                    borderRadius: 10,
                                                    // border: '2px solid #5e6977'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: 60,
                                                        fontWeight: 700,
                                                        paddingTop: 20,
                                                        paddingBottom: 20,
                                                    }}
                                                >
                                                    {matchData.opponent_points || 0}
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        backgroundColor: 'rgba(0,0,0,0.2)',
                                                        padding: 5,
                                                    }}
                                                >
                                                    Ball count
                                                </div>
                                            </div>
                                            {(isAdmin || (authState && authState.user && authState.user.role === 'admin')) && (
                                                <div style={{ marginTop: 20 }}>
                                                    <Tooltip placement="bottom" title="Increase ball count by 1">
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                incrementOpponentPoints(matchData.opponent_points);
                                                            }}
                                                        >
                                                            <Icon type="plus" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip placement="bottom" title="Decrease ball count by 1">
                                                        <Button
                                                            type="danger"
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                decrementOpponentPoints(matchData.opponent_points);
                                                            }}
                                                        >
                                                            <Icon type="minus" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip placement="bottom" title="Reset ball count to 0">
                                                        <Button
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                resetOpponentPoints();
                                                            }}
                                                        >
                                                            <Icon type="close" />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </Col>
                                    )}

                                    <Col xs={24} sm={12} align="center">
                                        <div
                                            style={{
                                                background: 'linear-gradient(-45deg, black, transparent)',
                                                color: '#fff',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                overflow: 'hidden',
                                                borderRadius: 10,
                                                // border: '2px solid #5e6977'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 60,
                                                    fontWeight: 700,
                                                    paddingTop: 20,
                                                    paddingBottom: 20,
                                                }}
                                            >
                                                {matchData.opponent_score || 0}
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                                    padding: 5,
                                                }}
                                            >
                                                Games won
                                            </div>
                                        </div>
                                        {(isAdmin || (authState && authState.user && authState.user.role === 'admin')) && (
                                            <div style={{ marginTop: 20 }}>
                                                <Tooltip placement="bottom" title="Increase score by 1">
                                                    <Button
                                                        type="primary"
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            incrementOpponentScore(matchData.opponent_score, matchData);
                                                        }}
                                                    >
                                                        <Icon type="plus" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip placement="bottom" title="Decrease score by 1">
                                                    <Button
                                                        type="danger"
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            decrementOpponentScore(matchData.opponent_score, matchData);
                                                        }}
                                                    >
                                                        <Icon type="minus" />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            gutter={24}
                            justify="center"
                            type="flex"
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                marginTop: 20,
                            }}
                        >
                            <Col xs={24} align="center" className="ball-tray">
                                <React.Fragment>
                                    {isAdmin || (authState && authState.user && authState.user.role === 'admin') ? (
                                        <React.Fragment>
                                            {Array.from(
                                                {
                                                    length: gameTypes[matchData.game_type] ? gameTypes[matchData.game_type] : 15,
                                                },
                                                (v, index) => {
                                                    const ballNum = index + 1;
                                                    return (
                                                        <Button
                                                            type="link"
                                                            size="large"
                                                            onClick={() => {
                                                                markBallMade(ballNum, ballsMade, matchData);
                                                            }}
                                                            key={index}
                                                        >
                                                            <Avatar
                                                                size={40}
                                                                src={require(`../../../assets/img/table/balls/${ballNum}-ball.png`)}
                                                                className={ballsMade[ballNum] === true ? 'ball-scored' : ''}
                                                            />
                                                        </Button>
                                                    );
                                                }
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {Array.from(
                                                {
                                                    length: gameTypes[matchData.game_type] ? gameTypes[matchData.game_type] : 15,
                                                },
                                                (v, index) => {
                                                    const ballNum = index + 1;
                                                    return (
                                                        <Avatar
                                                            size={40}
                                                            src={require(`../../../assets/img/table/balls/${ballNum}-ball.png`)}
                                                            className={ballsMade[ballNum] === true ? 'ball-scored' : ''}
                                                            key={index}
                                                        />
                                                    );
                                                }
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </Col>
                        </Row>
                        {(isAdmin || (authState && authState.user && authState.user.role === 'admin')) && (
                            <Row gutter={24} justify="center" type="flex" style={{ backgroundColor: '#000' }}>
                                <Col xs={24} align="center" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Button size="large" ghost onClick={resetBallsMade}>
                                        Reset ball tracker
                                    </Button>
                                    {matchData.status === 'NOT_STARTED' ? (
                                        <Button type="primary" size="large" ghost style={{ marginLeft: 10 }} onClick={startMatch}>
                                            Start the match
                                        </Button>
                                    ) : (
                                        <Button type="primary" size="large" ghost style={{ marginLeft: 10 }} onClick={showConfirmEndMatch}>
                                            End the match
                                        </Button>
                                    )}

                                    <Button type="danger" size="large" ghost onClick={showConfirmResetStats}>
                                        Reset all stats
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {/* <Row
													gutter={24}
													justify="center"
													type="flex"
													style={{
														marginTop: 30,
														paddingLeft: breakpoint === 'mobile' ? 10 : 40,
														paddingRight: breakpoint === 'mobile' ? 10 : 40
													}}
												>
													<Col>
														<Title level={4} style={{ color: '#ffff', marginBottom: 0 }}>
															Advanced stats (optional)
														</Title>
													</Col>
												</Row> */}
                        {(isAdmin || (authState && authState.user && authState.user.role === 'admin')) && (
                            <Row
                                gutter={24}
                                justify="center"
                                type="flex"
                                style={{
                                    paddingLeft: breakpoint === 'mobile' ? 10 : 40,
                                    paddingRight: breakpoint === 'mobile' ? 10 : 40,
                                    marginTop: 10,
                                }}
                            >
                                {/* <Col xs={24} align="center">
                                    Note: Balls pockets and Unforced errors are needed to calculate TPA (Total Performance Average)
                                </Col> */}
                                <Col xs={24} align="center">
                                    <div style={{ color: 'orange' }}>
                                        NOTE - Please input all stats BEFORE incrementing the score to ensure they are correctly counted for each game.
                                    </div>
                                </Col>
                                <Col xs={12} sm={10} align="center">
                                    <Row
                                        gutter={24}
                                        justify="end"
                                        type="flex"
                                        style={{
                                            background: 'linear-gradient(180deg, black, transparent)',
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Col xs={24}>
                                            <React.Fragment>
                                                {stats.map((stat, index) => {
                                                    const prefix = 'player';
                                                    const player = matchData.match_players.filter((player) => {
                                                        return player.name === matchData.player_name;
                                                    })[0];
                                                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                                    const key = `${prefix}_${statUnderscored}`;
                                                    return <React.Fragment key={index}>{renderStat(player, stat, key, values)}</React.Fragment>;
                                                })}
                                            </React.Fragment>
                                        </Col>
                                    </Row>
                                </Col>
                                {breakpoint !== 'mobile' && (
                                    <Col xs={4} align="center">
                                        <React.Fragment>&nbsp;</React.Fragment>
                                    </Col>
                                )}
                                <Col xs={12} sm={10} align="center">
                                    <Row
                                        gutter={24}
                                        justify="end"
                                        type="flex"
                                        style={{
                                            background: 'linear-gradient(180deg, black, transparent)',
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Col xs={24}>
                                            <React.Fragment>
                                                {stats.map((stat, index) => {
                                                    const prefix = 'opponent';
                                                    const opponent = matchData.match_players.filter((player) => {
                                                        return player.name === matchData.opponent_name;
                                                    })[0];
                                                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                                    const key = `${prefix}_${statUnderscored}`;
                                                    return <React.Fragment key={index}>{renderStat(opponent, stat, key, values)}</React.Fragment>;
                                                })}
                                            </React.Fragment>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Form>
            )}
        />
    );
}
