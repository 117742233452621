import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { message, Button, Icon, Typography, Drawer, Tooltip, Divider, Modal, Input, Layout, Menu, Dropdown } from 'antd';
import _ from 'lodash';
import { MoreOutlined, SyncOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TOURNAMENT_BRACKET_QUERY } from '../data/queries';
import { UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION } from '../data/mutations';
const { Text, Title } = Typography;

export default function DotsMenu(props) {
    const { tournament, round, theme } = props;
    const [updateBracketMatch] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION);

    const roundPlayers = [];
    round &&
        round.forEach((match) => {
            roundPlayers.push({
                id: match.challenger1_id,
                name: match.challenger1_name,
                country: match.challenger1_country,
                race_to: match.challenger1_race_to,
                seed: match.challenger1_seed,
            });
            roundPlayers.push({
                id: match.challenger2_id,
                name: match.challenger2_name,
                country: match.challenger2_country,
                race_to: match.challenger2_race_to,
                seed: match.challenger2_seed,
            });
        });

    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick} theme={theme}>
            <Menu.Item key="shuffle">
                <SyncOutlined />
                Shuffle players
            </Menu.Item>
        </Menu>
    );

    function handleMenuClick(e) {
        if (e.key === 'shuffle') {
            console.log('shuffle players');
            console.log(round);
            console.log('round players', roundPlayers);
            let shuffledPlayers = roundPlayers.slice(0);
            shuffleArray(shuffledPlayers);
            console.log('shuffled', shuffledPlayers);
            let counter = 0;

            const newMatches = _.compact(
                round &&
                    round.map((match, index) => {
                        const p1 = shuffledPlayers[counter];
                        const p2 = shuffledPlayers[counter + 1];
                        counter = counter + 2;
                        return {
                            // challenger1: p1,
                            challenger1_id: p1 && p1.id,
                            challenger1_name: p1 && p1.name,
                            challenger1_country: p1 && p1.country,
                            challenger1_race_to: p1 && p1.race_to,
                            challenger1_seed: p1 && p1.seed,
                            // challenger2: p2,
                            challenger2_id: p2 && p2.id,
                            challenger2_name: p2 && p2.name,
                            challenger2_country: p2 && p2.country,
                            challenger2_race_to: p2 && p2.race_to,
                            challenger2_seed: p2 && p2.seed,
                        };
                    })
            );

            console.log('round matches', round);
            console.log('updated matches', newMatches);

            updateMatches(round, newMatches);
        }
    }

    function updateMatches(matchesToUpdate, newMatches) {
        let promises = [];

        return new Promise(async (resolve, reject) => {
            matchesToUpdate.forEach((match, index) => {
                const changes = {
                    ...newMatches[index],
                };

                promises.push(
                    updateBracketMatch({
                        variables: {
                            tournament_id: tournament.id,
                            // round: match.round,
                            id: match.id,
                            changes: changes,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_TOURNAMENT_BRACKET_QUERY,
                                variables: { tournamentId: tournament.id },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                );
            });

            Promise.all(promises)
                .then((result) => {
                    console.log(result);
                    message.success(`Players have been shuffled`);

                    resolve(result);
                })
                .catch((error) => {
                    console.log(error);
                    message.error('There was an error', error);

                    reject(error);
                });
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    return (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" getPopupContainer={() => document.querySelector('#bracket-container')}>
            <Button type="ghost">
                <MoreOutlined />
            </Button>
        </Dropdown>
    );
}
