import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
import SectionWrapper from '../../components/SectionWrapper';
import SectionContent from '../../components/SectionContent';
import SectionHeader from '../../components/SectionHeader';
import Card from '../../components/Card';
import SettingsForm from './components/GESettings/SettingsForm';
import ContentController from './components/GESettings/ContentController';

const { Title } = Typography;

export default function GESettings(props) {
    const { client, authState, history, match, ghostEvent } = props;

    console.log('props', props);

    return (
        <React.Fragment>
            <SectionWrapper>
                <SectionHeader
                    title={props.title}
                    titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                    subtitle="Ghost Game Name"
                    subtitlePrefix={true}
                    subtitleStyle={{
                        color: 'rgba(255,255,255,0.8)',
                        textTransform: 'uppercase',
                        fontWeight: 600,
                        fontSize: 12,
                        letterSpacing: 3,
                    }}
                    theme="tournamentBuilder"
                ></SectionHeader>
                <SectionContent padding="20px 40px">
                    <Row gutter={24} justify="center" type="flex">
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                cover={
                                    <div
                                        style={{
                                            paddingTop: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                            Settings
                                        </Title>
                                    </div>
                                }
                            >
                                <div style={{ marginLeft: 24 }}>
                                    {/* <SettingsForm client={client} userId={authState.user.id} history={history} slug={match.params.slug} /> */}
                                    <ContentController
                                        ghostEventStatus="NOT_STARTED"
                                        client={client}
                                        userId={authState.user.id}
                                        history={history}
                                        slug={match.params.slug}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </SectionContent>
            </SectionWrapper>
        </React.Fragment>
    );
}
