import { gql } from '@apollo/client';
import { LEAGUE_FIELDS } from './fragments';

export const GET_LEAGUE_BY_SLUG_QUERY = gql`
    ${LEAGUE_FIELDS}
    query leagues($slug: String) {
        leagues(where: { slug: { _eq: $slug } }) {
            ...leagueFields
        }
    }
`;

export const GET_LEAGUE_QUERY = gql`
    ${LEAGUE_FIELDS}
    query leagues($slug: String, $id: Int) {
        leagues(where: { id: { _eq: $id } }) {
            ...leagueFields
        }
    }
`;

export const GET_UPCOMING_LEAGUE_MATCHES_QUERY = gql`
    query league_matches($league_id: Int, $division_id: Int, $current_date: timestamptz) {
        league_matches(
            where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, scheduled_time: { _gt: $current_date } }
            order_by: { scheduled_time: desc }
        ) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_is_winner
            challenger2_is_winner
            league_match_players {
                id
                name
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUES_QUERY = gql`
    query leagues {
        leagues(where: { deleted_at: { _is_null: true }, is_public: { _eq: true } }, order_by: { name: asc }) {
            id
            name
            slug
            short_description
            # long_description
            avatar
            logo
            deleted_at
            # is_public
            # owner {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # operator {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # address1
            # address2
            # city
            # county
            # country
            # region
            # postal_code
            # phone_number
            # fax_number
            # email_address
            # website
            # social_facebook
            # social_instagram
            # social_twitter
            # created_at
            # updated_at
            # match_notifications_in_app
            # match_notifications_sms
        }
    }
`;

export const GET_USER_CREATED_LEAGUES_QUERY = gql`
    query leagues($ownerId: Int!, $limit: Int) {
        leagues(where: { owner_id: { _eq: $ownerId } }, order_by: { updated_at: desc }, limit: $limit) {
            id
            name
            slug
            short_description
            avatar
            logo
            deleted_at
        }
    }
`;

export const GET_LEAGUE_PLAYER = gql`
    query league_players($leagueId: Int, $userId: Int) {
        league_players(where: { league_id: { _eq: $leagueId }, user_id: { _eq: $userId } }) {
            id
            name
            email
        }
    }
`;

export const GET_USER_LEAGUES_QUERY = gql`
    query league_players($userId: Int, $limit: Int) {
        league_players(where: { user_id: { _eq: $userId } }, order_by: { league: { created_at: desc } }, limit: $limit) {
            id
            league {
                name
                slug
                short_description
                avatar
                logo
                created_at
                deleted_at
            }
        }
        leagues(where: { _or: [{ owner_id: { _eq: $userId }, operator_id: { _eq: $userId } }] }, order_by: { created_at: desc }, limit: $limit) {
            id
            name
            slug
            short_description
            avatar
            logo
            created_at
            deleted_at
        }
    }
`;

export const GET_USER_DIVISIONS_QUERY = gql`
    query league_player_divisions($userId: Int!, $limit: Int) {
        league_players(where: { user_id: { _eq: $userId } }, order_by: { updated_at: desc }, limit: $limit) {
            id
            league_player_divisions {
                id
                division {
                    name
                    slug
                    description
                    avatar
                    game_types
                    league {
                        id
                        name
                        slug
                    }
                }
            }
        }
        league_divisions(
            where: { _or: [{ league: { owner_id: { _eq: $userId } }, operator_id: { _eq: $userId } }] }
            order_by: { updated_at: desc }
            limit: $limit
        ) {
            id
            name
            slug
            description
            avatar
            game_types
        }
    }
`;

export const GET_USER_CHALLENGES_QUERY = gql`
    query challenges($user_id: Int!) {
        challenges(where: { _or: [{ challenger_id: { _eq: $user_id } }, { opponent_id: { _eq: $user_id } }] }, order_by: { updated_at: desc }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                player_is_playing
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                opponent_is_playing
                status
                progress
                duration
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                table_name
                table_size
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_USER_MATCHES_QUERY = gql`
    query league_player_matches($userId: Int!) {
        league_players(where: { user_id: { _eq: $userId } }, order_by: { name: asc }) {
            id
            league_match_players {
                id
                league_match {
                    id
                    name
                    slug
                    game_type
                    league {
                        id
                        name
                        slug
                    }
                    division {
                        id
                        name
                        slug
                    }
                }
            }
        }
        tournament_players(where: { user_id: { _eq: $userId } }) {
            id
            place
            tournament {
                id
                name
                slug
                challenger1_matches_in_progress: tournament_brackets(
                    where: { _or: { challenger1: { user_id: { _eq: $userId } } }, status: { _eq: IN_PROGRESS }, is_bye: { _eq: false } }
                    order_by: { created_at: desc }
                ) {
                    round
                    status
                    table_id
                    table_name
                    table_number
                    start_time
                    seed
                    scheduled_time
                    progress
                    place
                    match_id
                    match_number
                    game_type
                    challenger1_country
                    challenger1_id
                    challenger1_name
                    challenger1_race_to
                    challenger1_score
                    challenger1_seed
                    challenger1_sms_sent
                    challenger2_country
                    challenger2_id
                    challenger2_name
                    challenger2_race_to
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger1_is_forfeit
                    challenger1_is_winner
                    challenger2_seed
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    hash_id
                    identifier
                    created_at
                    updated_at
                }
                challenger2_matches_in_progress: tournament_brackets(
                    where: { _or: { challenger2: { user_id: { _eq: $userId } } }, status: { _eq: IN_PROGRESS }, is_bye: { _eq: false } }
                    order_by: { created_at: desc }
                ) {
                    round
                    status
                    table_id
                    table_name
                    table_number
                    start_time
                    seed
                    scheduled_time
                    progress
                    place
                    match_id
                    match_number
                    game_type
                    challenger1_country
                    challenger1_id
                    challenger1_name
                    challenger1_race_to
                    challenger1_score
                    challenger1_seed
                    challenger1_sms_sent
                    challenger2_country
                    challenger2_id
                    challenger2_name
                    challenger2_race_to
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger1_is_forfeit
                    challenger1_is_winner
                    challenger2_seed
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    hash_id
                    identifier
                    created_at
                    updated_at
                }
                challenger1_matches_completed: tournament_brackets(
                    where: { _or: { challenger1: { user_id: { _eq: $userId } } }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                    order_by: { created_at: desc }
                ) {
                    round
                    status
                    table_id
                    table_name
                    table_number
                    start_time
                    seed
                    scheduled_time
                    progress
                    place
                    match_id
                    match_number
                    game_type
                    challenger1_country
                    challenger1_id
                    challenger1_name
                    challenger1_race_to
                    challenger1_score
                    challenger1_seed
                    challenger1_sms_sent
                    challenger2_country
                    challenger2_id
                    challenger2_name
                    challenger2_race_to
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger1_is_forfeit
                    challenger1_is_winner
                    challenger2_seed
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    hash_id
                    identifier
                    created_at
                    updated_at
                }
                challenger2_matches_completed: tournament_brackets(
                    where: { _or: { challenger2: { user_id: { _eq: $userId } } }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                    order_by: { created_at: desc }
                ) {
                    round
                    status
                    table_id
                    table_name
                    table_number
                    start_time
                    seed
                    scheduled_time
                    progress
                    place
                    match_id
                    match_number
                    game_type
                    challenger1_country
                    challenger1_id
                    challenger1_name
                    challenger1_race_to
                    challenger1_score
                    challenger1_seed
                    challenger1_sms_sent
                    challenger2_country
                    challenger2_id
                    challenger2_name
                    challenger2_race_to
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger1_is_forfeit
                    challenger1_is_winner
                    challenger2_seed
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    hash_id
                    identifier
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_USER_TOURNAMENT_STATS_QUERY = gql`
    query tournament_players($player_name: String) {
        tournament_players(where: { name: { _ilike: $player_name } }) {
            tournament {
                id
                name
                slug
            }
            place
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
        }
    }
`;

export const GET_USER_LEAGUE_STATS_QUERY = gql`
    query league_players($player_name: String) {
        league_players(where: { name: { _ilike: $player_name } }) {
            league {
                id
                name
                slug
            }
            place
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    id
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_frames
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_frames
                    challenger2_is_winner
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    id
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_frames
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_frames
                    challenger2_is_winner
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    id
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_frames
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_frames
                    challenger2_is_winner
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    id
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_frames
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_frames
                    challenger2_is_winner
                }
            }
        }
    }
`;

export const GET_LEAGUE_PLAYERS_QUERY = gql`
    query league_players($league_id: Int) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            country
            region
            city
            state
            email
            phone_number
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            player {
                player_challenges_played: player_challenges_aggregate(where: { match: { status: { _eq: COMPLETED } } }) {
                    aggregate {
                        count
                    }
                }
                player_challenges_won: player_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, player_is_winner: { _eq: true } } }) {
                    aggregate {
                        count
                    }
                }
                player_challenges_lost: player_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, player_is_winner: { _eq: false } } }) {
                    aggregate {
                        count
                    }
                }
                opponent_challenges_played: opponent_challenges_aggregate(where: { match: { status: { _eq: COMPLETED } } }) {
                    aggregate {
                        count
                    }
                }
                opponent_challenges_won: opponent_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, opponent_is_winner: { _eq: true } } }) {
                    aggregate {
                        count
                    }
                }
                opponent_challenges_lost: opponent_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, opponent_is_winner: { _eq: false } } }) {
                    aggregate {
                        count
                    }
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                country
                email
                phone_number
                skill_level
            }
        }
    }
`;

export const GET_USER_TOURNAMENTS_QUERY = gql`
    query users($id: Int!) {
        users(where: { id: { _eq: $id } }) {
            id
            first_name
            last_name
            tournament_organizer(order_by: { created_at: desc }) {
                id
                name
                avatar
                slug
                tournament_type
                player_type
                game_type
                max_players
                progress
                is_public
                status
                created_at
            }
            tournament_director(order_by: { created_at: desc }) {
                id
                name
                avatar
                slug
                tournament_type
                player_type
                game_type
                max_players
                progress
                is_public
                status
                created_at
            }
            tournament_players(order_by: { tournament: { created_at: desc } }) {
                id
                name
                tournament {
                    id
                    name
                    avatar
                    slug
                    tournament_type
                    player_type
                    game_type
                    max_players
                    progress
                    is_public
                    status
                    created_at
                }
            }
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_DIVISION_TEAMS_QUERY = gql`
    query league_players($league_id: Int, $division_id: Int) {
        league_teams(where: { league_id: { _eq: $league_id }, league_team_divisions: { division_id: { _eq: $division_id } } }, order_by: { name: asc }) {
            id
            name
            slug
            team_number
            avatar
            status
            division_id
            division {
                id
                name
                league_teams_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            captain_id
            team_captain {
                id
                name
            }
            co_captain_id
            team_co_captain {
                id
                name
            }
            venue_id
            venue {
                id
                name
            }
            league_dues_paid
            last_payment_date
            league_team_players {
                id
                league_player {
                    id
                    name
                    country
                    email
                    phone_number
                    skill_level
                    user_id
                }
            }
            league_team_divisions {
                id
                division_id
                league_division {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_PLAYERS_QUERY = gql`
    query league_players($league_id: Int, $division_id: Int) {
        league_players(where: { league_id: { _eq: $league_id }, league_player_divisions: { division_id: { _eq: $division_id } } }, order_by: { name: asc }) {
            id
            name
            country
            email
            phone_number
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                country
                email
                phone_number
                skill_level
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_QUERY = gql`
    query league_players($id: Int, $name: String, $league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id }, id: { _eq: $id }, name: { _eq: $name } }) {
            id
            name
            country
            region
            city
            state
            email
            phone_number
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                nickname
                country
                email
                phone_number
                skill_level
                status
            }
        }
    }
`;

export const GET_LEAGUE_DIVISIONS_QUERY = gql`
    query league_divisions($leagueId: Int) {
        league_divisions(where: { league_id: { _eq: $leagueId } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            description
            division_type
            game_types
            league_team_divisions_aggregate {
                aggregate {
                    count
                }
            }
            # division_game_types {
            # 	game_type
            # }
            session_id
            num_weeks
            start_date
            end_date
            is_public
            is_active
            is_handicapped
            league_player_divisions_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_QUERY = gql`
    query league_divisions($leagueId: Int, $divisionSlug: String) {
        league_divisions(where: { league_id: { _eq: $leagueId }, slug: { _eq: $divisionSlug } }) {
            id
            name
            slug
            description
            division_type
            game_types
            # division_game_types {
            # 	game_type
            # }
            session_id
            num_weeks
            season
            week
            start_date
            end_date
            is_public
            is_active
            is_handicapped
            league_matches_aggregate {
                aggregate {
                    count
                }
            }
            league_player_divisions_aggregate {
                aggregate {
                    count
                }
            }
            league_team_divisions_aggregate {
                aggregate {
                    count
                }
            }
            league_player_divisions {
                id
            }
            league_team_divisions {
                id
                league_team {
                    id
                    name
                    slug
                }
            }
        }
    }
`;

export const GET_LEAGUE_MATCHES_QUERY = gql`
    query league_matches($league_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id } }, order_by: { scheduled_time: desc }) {
            id
            name
            slug
            league_id
            match_id
            league_team_id
            match_type
            match_number
            scoring_format
            winner_determined_by
            best_of_num
            race_to_num
            is_vizion_member
            has_paid
            payment_method
            payment_amount
            home_team_id
            home_team_score
            home_team_points
            home_team_is_winner
            visitor_team_id
            visitor_team_score
            visitor_team_points
            visitor_team_is_winner
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_skill_level
            challenger1_race_to
            challenger1_is_winner
            challenger1_frames
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_skill_level
            challenger2_race_to
            challenger2_is_winner
            challenger2_frames
            ranking
            game_type
            table_id
            table_name
            table_number
            table_size
            status
            progress
            scheduled_time
            start_time
            end_time
            duration
            date_challenged
            date_accepted
            date_played
            days_left_to_acknowledge
            days_left_to_play_from_acknowledge
            days_left_to_play_from_callout
            created_at
            updated_at
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            division_id
            division {
                id
                name
                slug
            }
            league_match_teams {
                id
                league_team_id
                name
            }
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            home_team {
                id
                name
                slug
            }
            visitor_team {
                id
                name
                slug
            }
            is_team_match
            is_singles_match
        }
    }
`;

export const GET_LEAGUE_MATCH_BY_SLUG_QUERY = gql`
    query league_matches($league_id: Int, $division_id: Int, $match_slug: String) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, slug: { _eq: $match_slug } }) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_is_winner
            challenger2_is_winner
            league_match_frames(order_by: { created_at: desc }) {
                id
                league_id
                division_id
                league_match_id
                challenger1_id
                challenger1 {
                    id
                    name
                }
                challenger2_id
                challenger2 {
                    id
                    name
                }
                challenger1_points
                challenger2_points
                challenger1_balls_pocketed
                challenger2_balls_pocketed
                challenger1_errors
                challenger2_errors
                challenger1_high_break
                challenger2_high_break
                challenger1_is_winner
                challenger2_is_winner
                frame_number
                status
                progress
                duration
                start_time
                end_time
                created_at
                updated_at
            }
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_QUERY = gql`
    query league_matches($league_id: Int, $division_id: Int, $match_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, id: { _eq: $match_id } }) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_is_winner
            challenger2_is_winner
            league_match_frames(order_by: { created_at: desc }) {
                id
                league_id
                division_id
                league_match_id
                challenger1_id
                challenger1 {
                    id
                    name
                }
                challenger2_id
                challenger2 {
                    id
                    name
                }
                challenger1_points
                challenger2_points
                challenger1_balls_pocketed
                challenger2_balls_pocketed
                challenger1_errors
                challenger2_errors
                challenger1_high_break
                challenger2_high_break
                challenger1_is_winner
                challenger2_is_winner
                frame_number
                status
                progress
                duration
                start_time
                end_time
                created_at
                updated_at
            }
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCHES_BY_STATUS = gql`
    query league_matches($league_id: Int!, $status: league_match_status_enum) {
        league_matches(where: { league_id: { _eq: $league_id }, status: { _eq: $status } }, order_by: { scheduled_time: desc }) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_is_winner
            challenger2_is_winner
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_INVITE = gql`
    query tournament_players($email: String!, $key: String!) {
        league_invite_keys(where: { email: { _eq: $email }, key: { _eq: $key } }) {
            id
            league_id
            slug
        }
    }
`;

export const GET_ALL_LIVE_STREAMS = gql`
    query live {
        live(where: { live: { _eq: true } }) {
            name
            channel
            type
            live_type {
                value
            }
        }
        live_matches(where: { live: { _eq: true } }) {
            name
            data
            live
            match_type
            start_time
        }
    }
`;

export const GET_ALL_STREAMS = gql`
    query get_all {
        live {
            channel
            type
            live_type {
                value
            }
        }
        live_matches {
            data
            live
            match_type
            start_time
        }
    }
`;

export const GET_LIVE_STREAM = gql`
    query live_stream($channel: String!) {
        live(where: { channel: { _eq: $channel } }) {
            id
            channel
            type
            name
            user_id
        }
    }
`;

export const GET_USER = gql`
    query users($user_id: Int!) {
        users(where: { id: { _eq: $user_id } }) {
            id
            first_name
            last_name
            slug
        }
    }
`;

export const GET_VENUES_QUERY = gql`
    query venues {
        venues(limit: 25) {
            id
            name
            slug
            address1
            city
            region
            country
            venue_type
            postal_code
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            owner {
                id
            }
        }
    }
`;

export const SEARCH_VENUES_QUERY = gql`
    query venues($name: String!) {
        venues(
            distinct_on: name
            where: {
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
        }
    }
`;

export const GET_EVENTS_QUERY = gql`
    query events {
        events(where: { deleted_at: { _is_null: true }, is_public: { _eq: true } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            livestream_split_url
            venue_id
            owner {
                id
                email
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
            # is_public
            # owner {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # operator {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # address1
            # address2
            # city
            # county
            # country
            # region
            # postal_code
            # phone_number
            # fax_number
            # email_address
            # website
            # social_facebook
            # social_instagram
            # social_twitter
            # created_at
            # updated_at
            # match_notifications_in_app
            # match_notifications_sms
        }
    }
`;

export const GET_USER_CREATED_EVENTS_QUERY = gql`
    query events($ownerId: Int!, $limit: Int) {
        events(
            where: { _or: [{ owner_id: { _eq: $ownerId } }, { event_managers: { manager_id: { _eq: $ownerId } } }] }
            order_by: { updated_at: desc }
            limit: $limit
        ) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            livestream_split_url
            venue_id
            owner_id
            owner {
                id
                email
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_EVENT_PLAYERS_QUERY = gql`
    query tournaments($eventId: Int!) {
        tournaments(where: { event_id: { _eq: $eventId } }, order_by: { name: asc }) {
            name
            tournament_players {
                id
                name
                country
            }
        }
    }
`;

export const GET_EVENT_QUERY = gql`
    query events($eventSlug: String) {
        events(where: { slug: { _eq: $eventSlug } }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            livestream_split_url
            venue_id
            owner_id
            owner {
                id
                email
            }
            matches(order_by: { updated_at: desc }) {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                player_is_playing
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                opponent_is_playing
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                venue_id
                venue {
                    id
                    name
                    slug
                    address1
                    address2
                    city
                    region
                    country
                    postal_code
                }
                pool_table_id
                pool_table {
                    id
                    label
                    is_streaming_table
                    is_featured_table
                }
                match_players {
                    id
                    match_id
                    user_id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
                match_stats(order_by: { time: desc }) {
                    id
                    metric
                    value
                    user_id
                    match_id
                    match_game_id
                    player_id
                    opponent_id
                    game_number
                    time
                }
                managers {
                    id
                    user_id
                    match_id
                    admin
                    creator
                }
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            tournaments(order_by: { name: asc }) {
                name
                id
                slug
                status
                progress
                start_date_time
                end_date_time
                flyer
                game_type
                tournament_type
                tournament_format
                tournament_stage_format
                handicap_format
                max_players
                description
                created_at
                updated_at
                winners_race_to
                losers_race_to
                handicapped

                tournament_players(
                    where: { _or: [{ place: { _eq: "1" } }, { place: { _eq: "2" } }, { place: { _eq: "3" } }] }
                    order_by: { place: asc }
                    limit: 4
                ) {
                    name
                    place
                }
                tournament_players_aggregate {
                    aggregate {
                        count
                    }
                }

                director {
                    id
                    slug
                    first_name
                    last_name
                }
                venue {
                    id
                    name
                    slug
                    city
                    country
                    region
                }
            }
            event_managers {
                manager {
                    id
                    first_name
                    last_name
                    display_name
                }
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_EVENT_TABLES_QUERY = gql`
    query events($id: Int!) {
        events(where: { id: { _eq: $id } }) {
            id
            slug
            name
            matches(where: { status: { _neq: COMPLETED } }) {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                player_is_playing
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                opponent_is_playing
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                is_scoring_started
                creator_id
                created_at
                updated_at
                pool_table {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                    is_streaming_table
                    is_featured_table
                    livestream_url
                    kiosk_ip_address
                    kiosk_password
                }
            }
            tournaments {
                id
                name
                slug
                pool_tables(where: { status: { _neq: CLOSED } }) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                    is_streaming_table
                    is_featured_table
                    livestream_url
                    kiosk_ip_address
                    kiosk_password
                    tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                        id
                        hash_id
                        round
                        identifier
                        place
                        challenger1 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger2 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger1_id
                        challenger1_name
                        challenger1_country
                        challenger1_score
                        challenger1_points
                        challenger1_race_to
                        challenger1_skill_level
                        challenger1_seed
                        challenger1_is_winner
                        challenger1_is_forfeit
                        challenger1_is_withdraw
                        challenger2_id
                        challenger2_name
                        challenger2_country
                        challenger2_score
                        challenger2_points
                        challenger2_race_to
                        challenger2_skill_level
                        challenger2_seed
                        challenger2_is_winner
                        challenger2_is_forfeit
                        challenger2_is_withdraw
                        challenger1_sms_sent
                        challenger2_sms_sent
                        challenger1_is_playing
                        challenger2_is_playing
                        tournament_player_id
                        match_number
                        match_id
                        team_id
                        scheduled_time
                        start_time
                        end_time
                        duration
                        table_id
                        table_name
                        seed
                        status
                        progress
                        loser_from
                        loser_from_num
                        loser_to
                        loser_to_num
                        winner_from
                        winner_to
                        winner_to_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_from_top_num
                        loser_from_bottom_num
                        is_bye
                        is_split
                        is_semi_finals
                        is_finals
                        is_consolidation_finals
                        is_scoring_started
                        scoreboard_balls
                    }
                }
            }
        }
    }
`;

export const GET_EVENT_BY_SLUG_QUERY = gql`
    query events($eventSlug: String) {
        events(where: { slug: { _eq: $eventSlug } }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            livestream_split_url
            venue_id
            owner_id
            owner {
                id
                email
            }
            tournaments {
                name
                id
                slug
                status
                progress
                start_date_time
                end_date_time
                flyer
                game_type
                tournament_type
                tournament_format
                handicap_format
                max_players
                description
                created_at
                updated_at
                winners_race_to
                losers_race_to
                handicapped
                livestream_split_url
                tournament_players(
                    where: { _or: [{ place: { _eq: "1" } }, { place: { _eq: "2" } }, { place: { _eq: "3" } }] }
                    order_by: { place: asc }
                    limit: 4
                ) {
                    name
                    place
                }
                tournament_players_aggregate {
                    aggregate {
                        count
                    }
                }

                director {
                    id
                    slug
                    first_name
                    last_name
                }
                venue {
                    id
                    name
                    slug
                    city
                    country
                    region
                }
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_QUERY2 = gql`
    query events($event_slug: String!, $table_slug: String!) {
        events(where: { slug: { _eq: $event_slug } }) {
            id
            slug
            name
            livestream_split_url
            venue_id
            venue {
                id
                name
                slug
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    kiosk_ip_address
                    kiosk_password
                    is_streaming_table
                    is_featured_table
                    livestream_url
                }
            }

            matches(where: { pool_table: { slug: { _eq: $table_slug } } }, limit: 1) {
                id
                name
                slug
                pool_table {
                    id
                    label
                    slug
                    kiosk_ip_address
                    kiosk_password
                    is_streaming_table
                    is_featured_table
                    livestream_url
                    matches(where: { status: { _neq: COMPLETED } }) {
                        id
                        name
                        slug
                        description
                        match_number
                        match_type
                        game_type
                        rule_format
                        handicap_format
                        race_to
                        player_id
                        player_name
                        player_score
                        player_points
                        player_tiebreaker_points
                        player_race_to
                        player_country
                        player_state
                        player_fargo
                        player_is_winner
                        player_is_playing
                        opponent_id
                        opponent_name
                        opponent_score
                        opponent_points
                        opponent_tiebreaker_points
                        opponent_race_to
                        opponent_country
                        opponent_state
                        opponent_fargo
                        opponent_is_winner
                        opponent_is_playing
                        winner_id
                        winner_score
                        loser_id
                        loser_score
                        status
                        progress
                        duration
                        livestream_url
                        score_verified
                        scheduled_time
                        start_date_time
                        end_date_time
                        is_public
                        is_scoring_started
                        event_id
                        event {
                            id
                            name
                            slug
                            description
                        }
                        venue_id
                        venue {
                            id
                            name
                            slug
                            address1
                            address2
                            city
                            region
                            country
                            postal_code
                        }

                        match_players {
                            id
                            match_id
                            user_id
                            name
                            country
                            skill_level
                            race_to
                            score
                            is_winner
                        }
                        match_stats(order_by: { time: desc }) {
                            id
                            metric
                            value
                            user_id
                            match_id
                            match_game_id
                            player_id
                            opponent_id
                            game_number
                            time
                        }
                        match_shot_clock(order_by: { updated_at: desc }) {
                            id
                            match_id
                            duration
                            remaining_time
                            direction
                            text_color
                            command
                            created_at
                            updated_at
                        }
                        managers {
                            id
                            user_id
                            match_id
                            admin
                            creator
                        }
                        table_size
                        scoreboard_balls
                        creator_id
                        created_at
                        updated_at
                    }
                }
            }
            tournaments {
                id
                name
                slug
                description
                avatar
                flyer
                logo
                tournament_type
                tournament_format
                handicap_format
                player_type
                game_type
                winners_race_to
                losers_race_to
                race_to
                consolidation_finals
                max_players
                max_tables
                rule_format
                break_format
                status
                progress
                start_date_time
                end_date_time
                entry_fee
                added_money
                payout_type
                draw_type
                rating_system
                use_text_messaging
                rsvp_allowed
                show_player_skill_levels
                show_player_races
                is_public
                signup_cutoff_time
                livestream_split_url
                enable_sets
                sets_per_match
                autopilot_mode
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                    is_streaming_table
                    is_featured_table
                    livestream_url
                    kiosk_ip_address
                    kiosk_password
                    manufacturer {
                        id
                        name
                    }
                    venue {
                        id
                        name
                    }
                    user {
                        id
                        email
                        country
                    }
                    tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                        id
                        hash_id
                        round
                        identifier
                        place
                        challenger1 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger2 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        tournament_match_sets(order_by: { number: asc }) {
                            id
                            name
                            slug
                            number
                            match_id
                            challenger1_id
                            challenger1_score
                            challenger1_race_to
                            challenger1_points
                            challenger1_is_winner
                            challenger1_is_forfeit
                            challenger1_is_withdraw
                            challenger2_id
                            challenger2_score
                            challenger2_points
                            challenger2_race_to
                            challenger2_is_winner
                            challenger2_is_forfeit
                            challenger2_is_withdraw
                            start_time
                            end_time
                            status
                        }
                        challenger1_sets_won: tournament_match_sets_aggregate(where: { challenger1_is_winner: { _eq: true } }) {
                            aggregate {
                                count
                                sum {
                                    challenger1_score
                                }
                            }
                        }
                        challenger2_sets_won: tournament_match_sets_aggregate(where: { challenger2_is_winner: { _eq: true } }) {
                            aggregate {
                                count
                                sum {
                                    challenger2_score
                                }
                            }
                        }
                        tournament_match_shot_clock(order_by: { updated_at: desc }) {
                            id
                            tournament_bracket_id
                            duration
                            remaining_time
                            direction
                            text_color
                            command
                            created_at
                            updated_at
                        }
                        current_set
                        challenger1_id
                        challenger1_name
                        challenger1_country
                        challenger1_score
                        challenger1_points
                        challenger1_race_to
                        challenger1_skill_level
                        challenger1_seed
                        challenger1_is_winner
                        challenger1_is_forfeit
                        challenger1_is_withdraw
                        challenger2_id
                        challenger2_name
                        challenger2_country
                        challenger2_score
                        challenger2_points
                        challenger2_race_to
                        challenger2_skill_level
                        challenger2_seed
                        challenger2_is_winner
                        challenger2_is_forfeit
                        challenger2_is_withdraw
                        challenger1_sms_sent
                        challenger2_sms_sent
                        challenger1_is_playing
                        challenger2_is_playing
                        tournament_player_id
                        match_number
                        match_id
                        team_id
                        scheduled_time
                        start_time
                        end_time
                        duration
                        table_id
                        table_name
                        seed
                        status
                        progress
                        loser_from
                        loser_from_num
                        loser_to
                        loser_to_num
                        winner_from
                        winner_to
                        winner_to_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_from_top_num
                        loser_from_bottom_num
                        is_bye
                        is_split
                        is_semi_finals
                        is_finals
                        is_consolidation_finals
                        is_scoring_started
                        scoreboard_balls
                    }
                }
            }
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_SHOT_CLOCK_QUERY = gql`
    query events($event_slug: String!, $table_slug: String!) {
        events(where: { slug: { _eq: $event_slug } }) {
            id
            name
            slug
            matches(where: { pool_table: { slug: { _eq: $table_slug } } }, limit: 1) {
                id
                name
                slug
                pool_table {
                    id
                    label
                    slug
                    matches(where: { status: { _neq: COMPLETED } }) {
                        id
                        name
                        slug
                        match_shot_clock(order_by: { updated_at: desc }) {
                            id
                            match_id
                            duration
                            remaining_time
                            direction
                            text_color
                            command
                            created_at
                            updated_at
                        }
                    }
                }
            }
            tournaments {
                id
                name
                slug
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                        id
                        hash_id
                        tournament_match_shot_clock(order_by: { updated_at: desc }) {
                            id
                            tournament_bracket_id
                            duration
                            remaining_time
                            direction
                            text_color
                            command
                            created_at
                            updated_at
                        }
                    }
                }
            }
        }
    }
`;

export const SEARCH_PLAYERS = gql`
    query players($name: String!) {
        players(distinct_on: [name], where: { name: { _ilike: $name } }, limit: 25) {
            id
            name
            name_slug
            aliases
            country
            gender
            properties
            image_large
            image_small
            user_id
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_QUERY = gql`
    query tournaments($event_slug: String!, $table_slug: String!) {
        tournaments(where: { event: { slug: { _eq: $event_slug } } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            handicap_format
            player_type
            game_type
            winners_race_to
            losers_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            is_public
            signup_cutoff_time
            livestream_split_url
            pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
                kiosk_ip_address
                kiosk_password
                manufacturer {
                    id
                    name
                }
                venue {
                    id
                    name
                }
                user {
                    id
                    email
                    country
                }
                tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                    id
                    hash_id
                    round
                    identifier
                    place
                    challenger1 {
                        id
                        name
                        country
                        skill_level
                        place
                        race_to
                        seed
                    }
                    challenger2 {
                        id
                        name
                        country
                        skill_level
                        place
                        race_to
                        seed
                    }

                    challenger1_id
                    challenger1_name
                    challenger1_country
                    challenger1_score
                    challenger1_points
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_seed
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_country
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_seed
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    challenger1_sms_sent
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    tournament_player_id
                    match_number
                    match_id
                    team_id
                    scheduled_time
                    start_time
                    end_time
                    duration
                    table_id
                    table_name
                    seed
                    status
                    progress
                    loser_from
                    loser_from_num
                    loser_to
                    loser_to_num
                    winner_from
                    winner_to
                    winner_to_num
                    winner_from_top_num
                    winner_from_bottom_num
                    loser_from_top_num
                    loser_from_bottom_num
                    is_bye
                    is_split
                    is_semi_finals
                    is_finals
                    is_consolidation_finals
                    is_scoring_started
                    scoreboard_balls
                }
            }
        }
    }
`;

export const GET_UPCOMING_GHOST_GAMES = gql`
    query ghost_games {
        ghost_games(order_by: { start_time: desc }, where: { is_public: { _eq: true } }) {
            id
            name
            slug
            start_time
            end_time
        }
    }
`;

export const GET_GHOST_GAMES_INVITE_KEY = gql`
    query ghost_games_invite_keys($slug: String!, $email: String!, $key: String!) {
        ghost_games_invite_keys(where: { slug: { _eq: $slug }, _and: { email: { _eq: $email }, _and: { key: { _eq: $key } } } }) {
            id
            ghost_games_id
        }
    }
`;

export const GET_MATCH_ADMIN_INVITE_KEY = gql`
    query match_managers_invite_keys($email: String!, $key: String!, $slug: String!) {
        match_managers_invite_keys(where: { email: { _eq: $email }, key: { _eq: $key }, slug: { _eq: $slug } }) {
            id
            match_id
        }
    }
`;

export const GET_EVENT_STANDINGS_BY_SLUG = gql`
    query events($slug: String!) {
        events(where: { slug: { _eq: $slug } }) {
            id
            name
            tournaments {
                id
                name
                tournament_players {
                    id
                    name
                    skill_level
                    country
                    place
                }
                tournament_points {
                    id
                    place
                    points
                }
            }
        }
    }
`;

export const GET_ALL_USERS_QUERY = gql`
    query users($limit: Int!, $offset: Int!) {
        users(order_by: { first_name: asc }, limit: $limit, offset: $offset) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            skill_level
            email_verified
            photo_url
            gender
            nickname
            display_name
            status
            fargo_id
            fargo_robustness
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            player_id
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
                fargo_uuid
            }
        }
    }
`;

export const GET_ALL_PLAYERS_QUERY = gql`
    query players($limit: Int!, $offset: Int!) {
        players(order_by: { name: asc }, limit: $limit, offset: $offset) {
            id
            name
            aliases
            properties
            image_large
            image_small
            country
            country_phone
            phone_number
            email
            gender
            name_slug
            user_id
            fargo_id
            fargo_readable_id
        }
    }
`;

export const GET_PLAYER_QUERY = gql`
    query players($id: Int!) {
        players(where: { id: { _eq: $id } }) {
            id
            name
            aliases
            properties
            image_large
            image_small
            country
            country_phone
            phone_number
            email
            gender
            name_slug
            user_id
            fargo_id
            fargo_readable_id
        }
    }
`;

export const GET_ALL_USER_COUNT = gql`
    query users_aggregate {
        users_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_USERS_COUNT = gql`
    query users_aggregate($from: timestamptz, $to: timestamptz) {
        users_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_PLAYERS_COUNT = gql`
    query players_aggregate($from: timestamptz, $to: timestamptz) {
        players_aggregate(where: { _and: [{ created_date: { _gt: $from } }, { created_date: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_PLAYER_COUNT = gql`
    query players_aggregate {
        players_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_VENUES_COUNT = gql`
    query venues_aggregate($from: timestamptz, $to: timestamptz) {
        venues_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_VENUE_TABLES_COUNT = gql`
    query pool_tables_aggregate($from: timestamptz, $to: timestamptz) {
        pool_tables_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], venue_id: { _is_null: false } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_VENUE_WITH_TABLES_COUNT = gql`
    query venues_aggregate($from: timestamptz, $to: timestamptz) {
        venues_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], pool_tables: { id: { _is_null: false } } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_MATCHES_COUNT = gql`
    query matches_aggregate($from: timestamptz, $to: timestamptz) {
        matches_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_PUBLIC_MATCHES_COUNT = gql`
    query matches_aggregate($from: timestamptz, $to: timestamptz) {
        matches_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: true } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_PRIVATE_MATCHES_COUNT = gql`
    query matches_aggregate($from: timestamptz, $to: timestamptz) {
        matches_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: false } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_TOURNAMENTS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_TOURNAMENT_MATCHES_COUNT = gql`
    query tournament_brackets_aggregate($from: timestamptz, $to: timestamptz) {
        tournament_brackets_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_TOURNAMENT_FARGO_MATCHES_COUNT = gql`
    query tournament_brackets_aggregate($from: timestamptz, $to: timestamptz) {
        tournament_brackets_aggregate(
            where: {
                _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }]
                tournament: { status: { _eq: COMPLETED }, progress: { _eq: "100" } }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_PUBLIC_TOURNAMENTS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: true } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_PRIVATE_TOURNAMENTS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: false } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_IN_PROGRESS_TOURNAMENTS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(
            where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: true }, status: { _eq: IN_PROGRESS } }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_COMPLETED_TOURNAMENTS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(
            where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: true }, status: { _eq: COMPLETED } }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_UPCOMING_TOURNAMENTS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(
            where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }], is_public: { _eq: true }, status: { _eq: NOT_STARTED } }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_TOURNAMENT_DIRECTORS_COUNT = gql`
    query tournaments_aggregate($from: timestamptz, $to: timestamptz) {
        tournaments_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }, distinct_on: director_id) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_LEAGUE_OPERATORS_COUNT = gql`
    query leagues_aggregate($from: timestamptz, $to: timestamptz) {
        leagues_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }, distinct_on: operator_id) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_LEAGUES_COUNT = gql`
    query leagues_aggregate($from: timestamptz, $to: timestamptz) {
        leagues_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_LEAGUE_MATCHES_COUNT = gql`
    query league_matches_aggregate($from: timestamptz, $to: timestamptz) {
        league_matches_aggregate(where: { _and: [{ created_at: { _gt: $from } }, { created_at: { _lt: $to } }] }) {
            aggregate {
                count
            }
        }
    }
`;

export const SEARCH_USERS = gql`
    query users($first_name: String, $last_name: String, $nickname: String) {
        users(
            where: {
                _or: [
                    { first_name: { _ilike: $first_name } }
                    { last_name: { _ilike: $last_name } }
                    { display_name: { _ilike: $first_name } }
                    { nickname: { _ilike: $nickname } }
                ]
            }
        ) {
            id
            first_name
            last_name
            email
            avatar
            city
            region
            country
            display_name
            nickname
            skill_level
            country_phone
            fargo_id
            phone_number
            status
        }
    }
`;

export const GET_LEAGUE_CHALLENGES_QUERY = gql`
    query challenges($league_id: Int!) {
        challenges(where: { league_id: { _eq: $league_id } }, order_by: { scheduled_time: desc }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
            }
            approved_by_id
            approver {
                id
                first_name
                last_name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                player_is_playing
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                opponent_is_playing
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                venue_id
                pool_table_id
                table_name
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_CHALLENGE_QUERY = gql`
    query challenges($id: Int!, $league_id: Int!) {
        challenges(where: { id: { _eq: $id }, league_id: { _eq: $league_id } }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
            }
            approved_by_id
            approver {
                id
                first_name
                last_name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                player_is_playing
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                opponent_is_playing
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                venue_id
                pool_table_id
                table_name
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_CHALLENGE_BY_SLUG_QUERY = gql`
    query challenges($slug: String!, $league_id: Int!) {
        challenges(where: { slug: { _eq: $slug }, league_id: { _eq: $league_id } }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            approved_by_id
            approver {
                id
                first_name
                last_name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                player_is_playing
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                opponent_is_playing
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                venue_id
                pool_table_id
                table_name
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG = gql`
    query league_schedules($division_id: Int!) {
        league_schedules(where: { division_id: { _eq: $division_id } }) {
            id
            name
            slug
            description
            league_id
            division_id
            season_id
            start_date
            start_time
            duration_type
            duration
            reserved_weeks
            weeks_off
            total_weeks
            is_active
            created_at
            updated_at
            league_division {
                league_team_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
                league_player_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            league_scheduled_matches {
                id
                home_team {
                    id
                    name
                    slug
                }
                home_team_id
                home_team_name
                league_id
                league_match_id
                schedule_id
                start_date
                start_time
                status
                updated_at
                venue_id
                venue_name
                visitor_team_id
                visitor_team_name
                visitor_team {
                    id
                    name
                    slug
                }
                week
                created_at
                updated_at
            }
        }
    }
`;

export const GET_EVENT_MANAGERS = gql`
    query event_managers($event_id: Int!) {
        event_managers(where: { event_id: { _eq: $event_id } }) {
            id
            manager_id
            email
            event_slug
            invite_accepted
            manager {
                id
                first_name
                last_name
                display_name
            }
        }
    }
`;

export const GET_EVENT_MANAGERS_INVITE_KEY = gql`
    query event_managers_invite_keys($event_id: Int!, $email: String!, $key: String!) {
        event_managers_invite_keys(where: { event_id: { _eq: $event_id }, _and: { email: { _eq: $email }, _and: { key: { _eq: $key } } } }) {
            id
            event_id
        }
    }
`;

export const GET_EVENT_MANAGERS_INVITE_KEY_BY_SLUG = gql`
    query event_managers_invite_keys($event_slug: String!, $email: String!, $key: String!) {
        event_managers_invite_keys(where: { event_slug: { _eq: $event_slug }, _and: { email: { _eq: $email }, _and: { key: { _eq: $key } } } }) {
            id
            event_id
        }
    }
`;

export const CHECK_EVENT_MANAGERS_INVITE_USER = gql`
    query event_managers_invite_keys($event_id: Int!, $email: String!) {
        event_managers_invite_keys(where: { event_id: { _eq: $event_id }, _and: { email: { _eq: $email } } }) {
            id
        }
    }
`;

export const GET_EVENT_MANAGERS_USER_BY_MANAGER_ID = gql`
    query event_managers($event_id: Int!, $manager_id: Int!) {
        event_managers(where: { event_id: { _eq: $event_id }, _and: { manager_id: { _eq: $manager_id } } }) {
            id
        }
    }
`;

export const GET_EVENT_MANAGERS_INVITED_USER_BY_MANAGER_EMAIL = gql`
    query event_managers($event_id: Int!, $email: String!) {
        event_managers(where: { event_id: { _eq: $event_id }, _and: { email: { _eq: $email }, _and: { invite_accepted: { _eq: false } } } }) {
            id
        }
    }
`;

export const GET_USER_BY_EMAIL_QUERY = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            email
            first_name
            display_name
        }
    }
`;

export const GET_MATCH_SHOT_CLOCK_QUERY = gql`
    query match_shot_clock($match_id: Int!) {
        match_shot_clock(where: { match_id: { _eq: $match_id } }, order_by: { updated_at: desc }) {
            id
            match_id
            duration
            remaining_time
            direction
            text_color
            command
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_MATCH_SHOT_CLOCK_QUERY = gql`
    query tournament_match_shot_clock($match_id: Int!) {
        tournament_match_shot_clock(where: { tournament_bracket_id: { _eq: $match_id } }, order_by: { updated_at: desc }) {
            id
            tournament_bracket_id
            duration
            remaining_time
            direction
            text_color
            command
            created_at
            updated_at
        }
    }
`;
