import React from 'react';
import { Link } from 'react-router-dom';
import poolLiveHomeSS from '../../assets/img/pool-live-home-ss.png';
import SectionContent from '../../components/SectionContent';

export default function PLMarketing(props) {
	return (
		<React.Fragment>
			<Link to="/pool-live/1">
				<img src={poolLiveHomeSS} alt="" width="100%" />
			</Link>
		</React.Fragment>
	);
}
