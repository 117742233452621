import React, { useState } from 'react';
import { message, Row, Col, Button, Popconfirm, Icon, Typography, Tag } from 'antd';
import PoolTable from '../../components/PoolTable';
import Card, { Meta } from '../../components/Card';
import { Query, Mutation } from '@apollo/client/react/components';
import { UPDATE_TABLE_STATUS_MUTATION, DELETE_TABLE_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_QUERY, GET_TABLE_QUERY, GET_ALL_TOURNAMENT_TABLES_QUERY, GET_TOURNAMENT_TABLES_QUERY } from './data/queries';

const { Title, Text } = Typography;

export default function TableAssignment2(props) {
    const {
        tournament,
        matchData,
        table,
        label,
        controls,
        showEditButton,
        showDeleteButton,
        title,
        style,
        theme,
        tableSize,
        displayText,
        status,
        showMakeModel,
        onEdit,
        onDelete,
    } = props;
    const [newStatus, setNewStatus] = useState();
    const isTableOccupied = status === 'live' || status === 'IN_USE' || status === 'IN_PROGRESS';

    function handleDelete(deleteTable, id) {
        deleteTable({
            variables: {
                id: table.id,
            },
            // refetchQueries: [ 'pool_tables' ]
            // notifyOnNetworkStatusChange: true,
            // fetchPolicy: 'no-cache',
            // refetchQueries: [ 'tournament_brackets' ]
            // refetchQueries: () => [ 'tournament_brackets' ]
            refetchQueries: [
                // 'pool_tables',
                {
                    query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                    variables: {
                        tournament_id: tournament.id,
                    },
                },
            ],
        });
    }

    return (
        <Mutation
            mutation={UPDATE_TABLE_STATUS_MUTATION}
            // variables={venue ? { venue_id: venue.id } : null}
            onCompleted={(data) => {
                message.info(`${label} is now ${newStatus}`);
            }}
            awaitRefetchQueries
            refetchQueries={[
                // 'pool_tables',
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: {
                        tournament_id: tournament.id,
                    },
                },
            ]}
        >
            {(updateTable, { loading, error }) => (
                <Mutation
                    mutation={DELETE_TABLE_MUTATION}
                    // variables={venue ? { venue_id: venue.id } : null}
                    onCompleted={(data) => {
                        // console.log(data);
                        message.success(`${table.label} deleted`);
                    }}
                    awaitRefetchQueries
                >
                    {(deleteTable, { loading, error }) => (
                        <Card
                            hoverable
                            onClick={() => {
                                const changed = table.status === 'OPEN' ? 'CLOSED' : 'OPEN';
                                setNewStatus(changed);

                                updateTable({
                                    variables: {
                                        id: table.id,
                                        changes: {
                                            status: changed,
                                        },
                                    },
                                    // refetchQueries: [ 'pool_tables' ]
                                    notifyOnNetworkStatusChange: true,
                                    awaitRefetchQueries: true,
                                    // fetchPolicy: 'no-cache',
                                    // refetchQueries: [ 'tournament_brackets' ]
                                    // refetchQueries: () => [ 'tournament_brackets' ]
                                    refetchQueries: [
                                        // 'pool_tables',
                                        // {
                                        //     query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                                        //     variables: {
                                        //         tournament_id: tournament.id,
                                        //     },
                                        // },
                                        {
                                            query: GET_TABLE_QUERY,
                                            variables: { id: table.id },
                                            // awaitRefetchQueries: true,
                                            // onCompleted: (data) => {
                                            // 	console.log(data);
                                            // }
                                            // notifyOnNetworkStatusChange: true
                                            // fetchPolicy: 'cache-and-network'
                                        },
                                    ],
                                });
                            }}
                            bodyStyle={{
                                paddingLeft: tableSize === 'small' ? 12 : 18,
                                paddingRight: tableSize === 'small' ? 12 : 18,
                                paddingTop: 10,
                                paddingBottom: 18,
                            }}
                            style={style}
                            // style={{
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 10,
                                }}
                            >
                                <h3 style={{ margin: 0, fontWeight: 600, marginRight: 10, color: theme.bracket.panels.textColor }}>{label}</h3>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {table && table.is_streaming_table === true ? (
                                        <Tag color="red">STREAMING TABLE</Tag>
                                    ) : isTableOccupied === true ? (
                                        <Tag color="red">IN USE</Tag>
                                    ) : table && table.is_featured_table === true ? (
                                        <Tag color="blue">FEATURED</Tag>
                                    ) : table && table.status === 'ASSIGNED' ? (
                                        <Tag color="orange">{status}</Tag>
                                    ) : (
                                        <Tag>{status}</Tag>
                                    )}
                                    {controls !== false && (
                                        <React.Fragment>
                                            {showEditButton !== false && (
                                                <Button
                                                    size="small"
                                                    type="ghost"
                                                    ghost={theme && theme.name === 'dark' ? true : false}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        onEdit && onEdit();
                                                    }}
                                                >
                                                    <Icon type="edit" />
                                                </Button>
                                            )}

                                            {showDeleteButton !== false && (
                                                <Popconfirm
                                                    title="Are you sure you want to delete this table?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    cancelText="No"
                                                    onCancel={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // console.log('cancel');
                                                        // onDelete();
                                                    }}
                                                    onConfirm={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // console.log('confirm');
                                                        handleDelete(deleteTable, table.id);
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // onDelete();
                                                    }}
                                                >
                                                    <Button
                                                        size="small"
                                                        type="danger"
                                                        ghost
                                                        // onClick={(e) => {
                                                        // 	e.preventDefault();
                                                        // 	e.stopPropagation();
                                                        // 	onDelete();
                                                        // }}
                                                    >
                                                        <Icon type="delete" />
                                                    </Button>
                                                </Popconfirm>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                            <PoolTable
                                tournament={tournament}
                                matchData={matchData}
                                table={table}
                                status={status}
                                displayText={displayText}
                                tableSize={tableSize}
                                {...props}
                            />
                            {showMakeModel !== false && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 14,
                                        color: theme.bracket.panels.textColor,
                                    }}
                                >
                                    {table.make} {table.size}
                                </div>
                            )}
                        </Card>
                    )}
                </Mutation>
            )}
        </Mutation>
    );
}
