import React, { useState, useEffect, useLayoutEffect } from 'react';
import { message, Badge, Layout, Menu, Dropdown, Alert, Icon, Tabs, Button, Avatar, Spin, Typography, Input, Tooltip } from 'antd';
import Tappable from 'react-tappable';
import { Link, Route, withRouter } from 'react-router-dom';
// import Sidebar from './Sidebar';
import dpLogoHeader from './assets/img/dp-logo-header.png';
import dpLogoHeaderDark from './assets/img/dp-logo-header-dark.png';
import dplLogoHeader from './assets/img/dpl-logo-header.png';
import leagueManagerHeader from './assets/img/league-manager-logo-header.png';
import tournamentBuilderHeader from './assets/img/tournament-builder-logo-header.png';
import tourBuilderHeader from './assets/img/tour-builder-logo-header.png';
import poolLiveHeader from './assets/img/pool-live-logo-header.png';
import dpIcon from './assets/img/dp-icon.png';
import dpIconDark from './assets/img/dp-icon-dark.png';
import dplIcon from './assets/img/dpl-icon.png';
import leagueManagerIcon from './assets/img/league-manager-icon.png';
import tournamentBuilderIcon from './assets/img/tournament-builder-icon.png';
import tourBuilderIcon from './assets/img/tour-builder-icon.png';
import poolLiveIcon from './assets/img/pool-live-icon.png';
import BreadcrumbContainer from './components/BreadcrumbContainer';
import DPNav from './screens/digitalpool/DPNav';
// import { Offline, Online } from 'react-detect-offline';
// import DPProductNav from './screens/digitalpool/DPProductNav';
import DPLNav from './screens/dpl/DPLNav';
import LMNav from './screens/league-manager/LMNav2';
import TBNav from './screens/tournament-builder/TBNav2';
import TRBNav from './screens/tour-builder/TRBNav';
import PLNav from './screens/pool-live/PLNav';
import GENav from './screens/ghost-event-manager/GENav';
import SidebarFooter from './components/SidebarFooter';
import SectionFooter from './components/SectionFooter';
import ScrollToTop from './components/ScrollToTop';
import ScrollingWrapper from './components/ScrollingWrapper';
import Headroom from 'react-headroom';
import qs from 'query-string';
// import clearRender from 'clear-render';
import _ from 'lodash';
import './layout.css';
// import { signOut } from './firebase/helpers';

const { Sider } = Layout;
const { Search } = Input;
const { SubMenu } = Menu;
const { Title, Text } = Typography;

Spin.setDefaultIndicator(<Icon type="loading" />);

message.config({
    top: 40,
    duration: 2,
    maxCount: 3,
    getContainer: () => document.querySelector('#auth'),
});

const styles = {
    active: { transition: 'all 250ms ease' },
    inactive: {
        // marginRight: 8,
        filter: 'grayscale(100%)',
        opacity: 0.5,
        transition: 'all 250ms ease',
    },
    menuItem: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
    menuItemActive: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        backgroundColor: '#0b1d34',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.7)',
    },
};

const defaultProductIconSize = 30;

function AppLayoutDesktop(props, context) {
    const { sidebar, authState, signOut, showFooter, theme } = props;
    console.log('p', props);
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { component: Component, ...rest } = props;
    let showNavigation;
    if (props && props.navigation === false) {
        showNavigation = false;
    } else if (params && params.navigation === 'false') {
        showNavigation = false;
    } else {
        showNavigation = true;
    }

    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const defaultUrl = '/';
    const dpIncludes = [
        'home',
        'feeds',
        'dashboard',
        'chat',
        'stats',
        'notifications',
        'friends',
        'billing',
        'leagues',
        'live',
        'tournaments',
        'teams',
        'players',
        'people',
        'venues',
        'events',
        'organizations',
        'register',
        'login',
        'ghost-events',
    ];

    console.log(urlPath);

    const [hovered, setHovered] = useState();
    const [current, setCurrent] = useState(
        splitPath[splitPath.length - 1]
            ? _.includes(dpIncludes, splitPath[splitPath.length - 1])
                ? 'digitalpool'
                : splitPath[splitPath.length - 1]
            : 'digitalpool'
    );

    const [currentSub, setCurrentSub] = useState(
        splitPath[splitPath.length - 1]
            ? _.includes(dpIncludes, splitPath[splitPath.length - 1])
                ? 'digitalpool'
                : splitPath[splitPath.length - 1]
            : 'digitalpool'
    );

    useEffect(() => {
        function setDefaults() {
            // console.log(splitPath);
            setCurrent(splitPath[1] ? (_.includes(dpIncludes, splitPath[1]) ? 'digitalpool' : splitPath[1]) : 'digitalpool');
            setCurrentSub(splitPath[1] ? (_.includes(dpIncludes, splitPath[1]) ? splitPath[1] : splitPath[1]) : 'digitalpool');
        }
        return setDefaults();
    }, [splitPath, dpIncludes]);

    // useLayoutEffect(() => {
    // 	document.body.style.overflow = 'hidden';

    // 	return () => {
    // 		document.body.style.overflow = null;
    // 	};
    // }, []);

    // useLayoutEffect(() => {
    // 	const messageEl = document.querySelector('.ant-message');
    // 	console.log(messageEl);
    // 	messageEl.style.left = props.sidebar === false ? '125px' : '25px';
    // });

    const handleClick = (e) => {
        // console.log('click ', e);
        setCurrent(e.key);
        const url = e.key === 'digitalpool' ? defaultUrl : `/${e.key}`;
        props.history.push(url);
    };

    const handleClickSub = (e) => {
        // console.log('click ', e);
        setCurrentSub(e.key);
        const url = e.key === 'digitalpool' ? defaultUrl : `/${e.key}`;
        props.history.push(url);
    };

    function handleMenuClick(e) {
        message.info('Click on menu item.');
        console.log('click', e);
    }

    function onClose() {
        // setVisible(false);
    }

    function navigate(url) {
        setCurrentSub(); // disable footer nav items
        props.history.push(url);
    }

    const handleHovered = (section) => {
        setHovered(section);
    };

    const isActive = (link, args) => {
        const urlPath = window.location.pathname;
        const splitPath = urlPath.split('/');
        const isRootPath = args && args.isRootPath;
        const rootPath = args && args.rootPath;
        const includes = args && args.includes;

        if (urlPath === '/' || isRootPath === true) {
            return urlPath === link;
        } else if (urlPath.indexOf(rootPath) !== -1) {
            return true;
        } else if (includes && includes.length > 0) {
            return _.includes(includes, splitPath[1]);
        } else {
            return _.includes(splitPath, link);
        }
    };

    const { Header, Content, Footer } = Layout;

    const renderDigitalPoolTitle = (
        <React.Fragment>
            <img
                src={dpIcon}
                alt="DigitalPool Icon"
                width={24}
                style={isActive('', { includes: dpIncludes }) || current === 'digitalpool' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>DigitalPool</span>
        </React.Fragment>
    );

    const renderDigitalPoolLeagueTitle = (
        <React.Fragment>
            <img src={dplIcon} alt="Digital Pool League" width={24} style={isActive('dpl') || current === 'dpl' ? styles.active : styles.inactive} />
            <span style={{ marginLeft: 10 }}>DPL</span>
        </React.Fragment>
    );

    const renderLeagueManagerTitle = (
        <React.Fragment>
            <img
                src={leagueManagerIcon}
                alt="League Manager"
                width={24}
                style={isActive('league-manager') || current === 'league-manager' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>League Manager</span>
        </React.Fragment>
    );

    const renderTournamentBuilderTitle = (
        <React.Fragment>
            <img
                src={tournamentBuilderIcon}
                alt="Tournament Director"
                width={24}
                style={isActive('tournament-builder') || current === 'tournament-builder' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>Tournament Builder</span>
        </React.Fragment>
    );

    const renderPoolLiveTitle = (
        <React.Fragment>
            <img src={poolLiveIcon} alt="Pool.live" width={24} style={isActive('pool-live') || current === 'pool-live' ? styles.active : styles.inactive} />
            <span style={{ marginLeft: 10 }}>Pool.Live</span>
        </React.Fragment>
    );

    const notificationMenu = (
        <Menu onClick={handleMenuClick} style={{ width: 200 }}>
            <Menu.Item key="1">
                <Icon type="bell" />
                Notification 1
            </Menu.Item>
            <Menu.Item key="2">
                <Icon type="bell" />
                Notification 2
            </Menu.Item>
            <Menu.Item key="3">
                <Icon type="bell" />
                Notification 3
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">View all notifications</Menu.Item>
        </Menu>
    );

    const profileMenu = (
        <Menu style={{ width: 200 }}>
            <Menu.Item
                key="profile"
                onClick={() => {
                    props.history.push('/profile');
                }}
            >
                <Icon type="user" /> Profile
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => {
                    props.history.push('/settings');
                }}
            >
                <Icon type="setting" /> Account Settings
            </Menu.Item>
            <Menu.Item
                key="password"
                onClick={() => {
                    props.history.push('/change-password');
                }}
            >
                <Icon type="lock" /> Change Password
            </Menu.Item>
            <Menu.Divider />
            {authState && authState.status === 'in' ? (
                <Menu.Item
                    key="3"
                    onClick={() => {
                        signOut().then(() => {
                            props.history.push('/');
                        });
                    }}
                >
                    <Icon type="logout" /> Logout
                </Menu.Item>
            ) : (
                <Menu.Item key="3" onClick={() => props.history.push('/login')}>
                    <Icon type="login" /> Login
                </Menu.Item>
            )}
        </Menu>
    );

    const profileMenuSidebar = (
        <Menu style={{ width: 200 }}>
            <div style={{ padding: '5px 14px', fontWeight: 700, backgroundColor: '#fafafa' }}>
                {props.authState && props.authState.user && props.authState.user.displayName}
            </div>
            <Menu.Item
                key="profile"
                onClick={() => {
                    props.history.push('/profile');
                }}
            >
                <Icon type="user" /> Profile
            </Menu.Item>
            <Menu.Item
                key="settings"
                onClick={() => {
                    props.history.push('/settings');
                }}
            >
                <Icon type="setting" /> Account Settings
            </Menu.Item>
            <Menu.Item
                key="password"
                onClick={() => {
                    props.history.push('/change-password');
                }}
            >
                <Icon type="lock" /> Change Password
            </Menu.Item>
            {/* <Menu.Item key="theme">
				<Icon type="bg-colors" /> Theme
			</Menu.Item> */}
            {/* <Menu.Item key="billing">
				<Icon type="dollar" /> Payments
			</Menu.Item> */}
            <Menu.Divider />
            {authState && authState.status === 'in' ? (
                <Menu.Item
                    key="3"
                    onClick={() => {
                        signOut().then(() => {
                            props.history.push('/');
                        });
                    }}
                >
                    <Icon type="logout" /> Logout
                </Menu.Item>
            ) : (
                <Menu.Item key="3" onClick={() => props.history.push('/login')}>
                    <Icon type="login" /> Login
                </Menu.Item>
            )}
        </Menu>
    );

    function renderHeader() {
        return (
            <Header
                className="ant-layout-header-dark"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0px 16px 0px 16px',
                    // position: 'absolute',
                    position: authState.status !== 'in' ? 'relative' : 'fixed',
                    top: 0,
                    width: authState.status !== 'in' ? '100%' : 200,
                    // zIndex: 100
                    // boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)'
                    // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 10px 0px'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <div className={authState && authState.status === 'in' ? 'logo' : 'logo-centered'}> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="logo">
                            {authState.status !== 'in' ? (
                                <Link to={_.includes(dpIncludes, splitPath[1]) ? defaultUrl : `/${splitPath[1]}`}>
                                    <img
                                        src={
                                            isActive('', { includes: dpIncludes }) || current === 'digitalpool'
                                                ? dpLogoHeader
                                                : isActive('dpl') || current === 'dpl'
                                                ? dplLogoHeader
                                                : isActive('league-manager') || current === 'league-manager'
                                                ? leagueManagerHeader
                                                : isActive('tournament-builder') || current === 'tournament-builder'
                                                ? tournamentBuilderHeader
                                                : isActive('tour-builder') || current === 'tour-builder'
                                                ? tourBuilderHeader
                                                : isActive('pool-live') || current === 'pool-live'
                                                ? poolLiveHeader
                                                : dpLogoHeader
                                        }
                                        alt="Logo"
                                        width="100%"
                                    />
                                </Link>
                            ) : authState.status === 'in' ? (
                                <Link to={_.includes(dpIncludes, splitPath[1]) ? defaultUrl : `/${splitPath[1]}`}>
                                    <img
                                        src={
                                            isActive('', { includes: dpIncludes }) || current === 'digitalpool'
                                                ? dpLogoHeader
                                                : isActive('dpl') || current === 'dpl'
                                                ? dplLogoHeader
                                                : isActive('league-manager') || current === 'league-manager'
                                                ? leagueManagerHeader
                                                : isActive('tournament-builder') || current === 'tournament-builder'
                                                ? tournamentBuilderHeader
                                                : isActive('tour-builder') || current === 'tour-builder'
                                                ? tourBuilderHeader
                                                : isActive('pool-live') || current === 'pool-live'
                                                ? poolLiveHeader
                                                : dpLogoHeader
                                        }
                                        alt="Logo"
                                        width="100%"
                                    />
                                </Link>
                            ) : null}
                        </div>

                        {authState.status !== 'in' && (
                            <Menu onClick={handleClick} selectedkeys={[current]} mode="horizontal" theme={theme.name} style={{ lineHeight: '64px' }}>
                                <SubMenu
                                    title={
                                        <span className="submenu-title-wrapper">
                                            {/* <Icon type="appstore" /> */}
                                            APPS
                                            <Icon type="caret-down" style={{ marginLeft: 5, width: 6, height: 6 }} />
                                        </span>
                                    }
                                    style={{ padding: 0, marginLeft: 20 }}
                                >
                                    <Menu.Item key="digitalpool">{renderDigitalPoolTitle}</Menu.Item>
                                    <Menu.Item key="dpl">{renderDigitalPoolLeagueTitle}</Menu.Item>
                                    <Menu.Item key="league-manager">{renderLeagueManagerTitle}</Menu.Item>
                                    <Menu.Item key="tournament-builder">{renderTournamentBuilderTitle}</Menu.Item>
                                    <Menu.Item key="pool-live">{renderPoolLiveTitle}</Menu.Item>
                                </SubMenu>

                                {/* <SubMenu
												title={
													<span className="submenu-title-wrapper">
														<Icon type="appstore" />
														Products Apps{' '}
														<Icon
															type="caret-down"
															style={{ marginLeft: 5, width: 6, height: 6 }}
														/>
													</span>
												}
											>
												<Menu.Item key="products:1">DigitalPool</Menu.Item>
												<Menu.Item key="products:2">DPL</Menu.Item>
												<Menu.Item key="products:3">League Manager</Menu.Item>
												<Menu.Item key="products:4">Tournament Director</Menu.Item>
												<Menu.Item key="products:5">Pool.live</Menu.Item>
											</SubMenu> */}
                            </Menu>
                        )}
                    </div>
                    {/* <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1">Leagues</Menu.Item>
            <Menu.Item key="2">Tournaments</Menu.Item>
            <Menu.Item key="3">Live</Menu.Item>
            
          </Menu> */}
                    {/* <Button type="link" icon="download" color="default" size="default" ghost>
						Download
					</Button> */}
                    {/* <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" href="#">
              Hover me <Icon type="down" />
            </a>
          </Dropdown>, */}
                </div>

                {/* {authState.status !== 'in' &&
				!isActive('dpl') && (
					<Search
						placeholder="Search"
						size="large"
						theme={getTheme()}
						// shape="rounded"
						onSearch={(value) => console.log(value)}
						style={{
							width: 300,
							position: 'absolute',
							left: `calc(50%)`,
							transform: 'translateX(-50%)'
						}}
					/>
				)} */}

                {/* {authState.status !== 'in' && !isActive('dpl') ? (
									<Search
										placeholder="Search"
										size="large"
										theme="dark"
										// shape="rounded"
										onSearch={(value) => console.log(value)}
										style={{
											width: 300,
											position: 'absolute',
											left: `calc(50%)`,
											transform: 'translateX(-50%)'
										}}
									/>
								) : authState.status == 'in' ? (
									<Search
										placeholder="Search"
										size="large"
										theme="dark"
										// shape="rounded"
										onSearch={(value) => console.log(value)}
										style={{
											width: 300,
											position: 'absolute',
											left: `calc(50% + ${props.sidebar === false ? '25px' : '120px'})`,
											transform: 'translateX(-50%)'
										}}
									/>
								) : null} */}

                {/* <Menu
									onClick={handleClick}
									selectedKeys={[ current ]}
									mode="horizontal"
									theme="dark"
									style={{ lineHeight: '64px' }}
								>
									<Menu.Item key="digitalpool">DigitalPool</Menu.Item>
									<Menu.Item key="dpl">DigitalPool League</Menu.Item>
									<Menu.Item key="league-manager">League Manager</Menu.Item>
									<Menu.Item key="tournament-builder">Tournament Builder</Menu.Item>
									<Menu.Item key="pool-live">Pool.live</Menu.Item>
								</Menu> */}

                {authState && authState.status !== 'in' && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button type="link" size="small" ghost onClick={() => props.history.push('/login')}>
                            LOG IN
                        </Button>
                        <Button type="link" size="small" ghost onClick={() => props.history.push('/register')}>
                            SIGN UP
                        </Button>
                    </div>
                )}

                {/* {authState && authState.status === 'in' ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Dropdown overlay={notificationMenu} trigger={[ 'hover' ]}>
											<Button type="link" color="default" ghost style={{ marginRight: 10 }}>
												<Badge dot>
													<Icon type="bell" theme="filled" style={{ fontSize: 18 }} />
												</Badge>
											</Button>
										</Dropdown>

										<Dropdown overlay={profileMenu} trigger={[ 'hover' ]}>
											<Avatar
												style={{ color: '#fff', cursor: 'pointer', backgroundColor: '#334454' }}
											>
												{(props.authState &&
													props.authState.user &&
													props.authState.user.displayName &&
													`${props.authState.user.displayName
														.split(' ')[0]
														.slice(0, 1)}${props.authState.user.displayName
														.split(' ')[1]
														.slice(0, 1)}`) ||
													'?'}
											</Avatar>
										</Dropdown>
									</div>
								) : (
									<div>
										<Button
											type="link"
											size="small"
											ghost
											onClick={() => props.history.push('/login')}
										>
											Log In
										</Button>
										<Button
											type="link"
											size="small"
											ghost
											onClick={() => props.history.push('/register')}
										>
											Sign Up
										</Button>
									</div>
								)} */}
            </Header>
        );
    }

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <ScrollToTop>
                    <Layout id="app-container" className={`desktop ${theme && theme.name}`} style={{ height: '100%' }}>
                        {authState.status === 'in' && showNavigation !== false && (
                            <div
                                width={58}
                                className="products-menu"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#081629',
                                    // background: 'linear-gradient(180deg, #0c1e35, #050c17)',
                                    boxShadow: 'inset -5px 0 10px -2px rgba(0,0,0,.4)',
                                    alignItems: 'center',
                                    paddingBottom: 14,
                                }}
                            >
                                <Menu
                                    // onClick={handleClick}
                                    selectedkeys={[`${current}-mini`]}
                                    mode="vertical"
                                    theme={theme.name}
                                    style={{
                                        marginTop: 8,

                                        lineHeight: '64px',
                                    }}
                                >
                                    <Menu.Item
                                        key="digitalpool-mini"
                                        // onMouseEnter={() => handleHovered('digitalpool')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/')}
                                        style={
                                            isActive('', { includes: dpIncludes }) || current === 'digitalpool' || hovered === 'digitalpool'
                                                ? styles.menuItemActive
                                                : styles.menuItem
                                        }
                                    >
                                        <Tooltip placement="right" title="DigitalPool">
                                            <img
                                                src={dpIcon}
                                                alt="DigitalPool Icon"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={
                                                    isActive('', { includes: dpIncludes }) || current === 'digitalpool' || hovered === 'digitalpool'
                                                        ? styles.active
                                                        : styles.inactive
                                                }
                                            />
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="dpl-mini"
                                        // onMouseEnter={() => handleHovered('dpl')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/dpl')}
                                        style={isActive('dpl') || current === 'dpl' || hovered === 'dpl' ? styles.menuItemActive : styles.menuItem}
                                    >
                                        <Tooltip placement="right" title="Digital Pool League">
                                            <img
                                                src={dplIcon}
                                                alt="Digital Pool League"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={isActive('dpl') || current === 'dpl' || hovered === 'dpl' ? styles.active : styles.inactive}
                                            />
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="league-manager-mini"
                                        // onMouseEnter={() => handleHovered('league-manager')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/league-manager')}
                                        style={
                                            isActive('league-manager') || current === 'league-manager' || hovered === 'league-manager'
                                                ? styles.menuItemActive
                                                : styles.menuItem
                                        }
                                    >
                                        <Tooltip placement="right" title="League Manager">
                                            <img
                                                src={leagueManagerIcon}
                                                alt="League Manager"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={
                                                    isActive('league-manager') || current === 'league-manager' || hovered === 'league-manager'
                                                        ? styles.active
                                                        : styles.inactive
                                                }
                                            />
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="tournament-builder-mini"
                                        // onMouseEnter={() => handleHovered('tournament-builder')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/tournament-builder')}
                                        style={
                                            isActive('tournament-builder') || current === 'tournament-builder' || hovered === 'tournament-builder'
                                                ? styles.menuItemActive
                                                : styles.menuItem
                                        }
                                    >
                                        <Tooltip placement="right" title="Tournament Builder">
                                            <img
                                                src={tournamentBuilderIcon}
                                                alt="Tournament Builder"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={
                                                    isActive('tournament-builder') || current === 'tournament-builder' || hovered === 'tournament-builder'
                                                        ? styles.active
                                                        : styles.inactive
                                                }
                                            />
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="tour-builder-mini"
                                        // onMouseEnter={() => handleHovered('tour-builder')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/tour-builder')}
                                        style={
                                            isActive('tour-builder') || current === 'tour-builder' || hovered === 'tour-builder'
                                                ? styles.menuItemActive
                                                : styles.menuItem
                                        }
                                        // disabled
                                    >
                                        <Tooltip placement="right" title="Tour Builder">
                                            <img
                                                src={tourBuilderIcon}
                                                alt="Tour Builder"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={
                                                    isActive('tour-builder') || current === 'tour-builder' || hovered === 'tour-builder'
                                                        ? styles.active
                                                        : styles.inactive
                                                }
                                            />
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="pool-live-mini"
                                        // onMouseEnter={() => handleHovered('pool-live')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/pool-live')}
                                        style={
                                            isActive('pool-live') || current === 'pool-live' || hovered === 'pool-live'
                                                ? styles.menuItemActive
                                                : styles.menuItem
                                        }
                                        // disabled
                                    >
                                        <Tooltip placement="right" title="Pool.live">
                                            <img
                                                src={poolLiveIcon}
                                                alt="Pool.live"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={
                                                    isActive('pool-live') || current === 'pool-live' || hovered === 'pool-live'
                                                        ? styles.active
                                                        : styles.inactive
                                                }
                                            />
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="ghost-event-manager-mini"
                                        // onMouseEnter={() => handleHovered('tournament-builder')}
                                        // onMouseLeave={() => handleHovered('')}
                                        onClick={() => navigate('/ghost-event-manager')}
                                        style={
                                            isActive('ghost-event-manager') || current === 'ghost-event-manager' || hovered === 'ghost-event-manager'
                                                ? styles.menuItemActive
                                                : styles.menuItem
                                        }
                                    >
                                        <Tooltip placement="right" title="Ghost Game Manager">
                                            <img
                                                src={tournamentBuilderIcon}
                                                alt="Ghost Game Manager"
                                                width={defaultProductIconSize}
                                                height={defaultProductIconSize}
                                                style={
                                                    isActive('ghost-event-manager') || current === 'ghost-event-manager' || hovered === 'ghost-event-manager'
                                                        ? styles.active
                                                        : styles.inactive
                                                }
                                            />
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <Menu
                                        mode="vertical"
                                        theme={theme.name}
                                        onClick={handleClickSub}
                                        // style={{ width: 256 }}
                                        // defaultOpenKeys={[ 'sub1' ]}
                                        selectedKeys={[currentSub]}
                                        style={{
                                            textAlign: 'center',
                                            border: 'none',
                                        }}
                                    >
                                        {/* <Menu.Item
											key="dashboard"
											disabled={authState && authState.status === 'in' ? false : true}
										>
											<Tooltip placement="right" title="My Dashboard">
												<Icon type="dashboard" style={{ margin: 0, fontSize: 20 }} />
											</Tooltip>
										</Menu.Item> */}
                                        <Menu.Item key="chat" disabled={authState && authState.status === 'in' ? false : true}>
                                            <Tooltip placement="right" title="My Messages">
                                                <Badge dot offset={[0, -4]}>
                                                    <Icon type="message" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                                </Badge>
                                            </Tooltip>
                                        </Menu.Item>
                                        <Menu.Item key="stats" disabled={authState && authState.status === 'in' ? false : true}>
                                            <Tooltip placement="right" title="My Stats">
                                                <Icon type="bar-chart" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item key="notifications" disabled={authState && authState.status === 'in' ? false : true}>
                                            <Tooltip placement="right" title="My Notifications">
                                                <Badge count={props.notificationCount}>
                                                    <Icon type="bell" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                                </Badge>
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item key="friends" disabled={authState && authState.status === 'in' ? false : true}>
                                            <Tooltip placement="right" title="My Friends">
                                                <Icon type="team" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                            </Tooltip>
                                        </Menu.Item>
                                        {/* <Menu.Item
											key="billing"
											disabled={authState && authState.status === 'in' ? false : true}
										>
											<Tooltip placement="right" title="My Payments">
												<Icon type="dollar" style={{ margin: 0, fontSize: 18 }} />
											</Tooltip>
										</Menu.Item> */}
                                        {/* <Menu.Item key="settings">
										<Icon type="setting" style={{ margin: 0, fontSize: 18 }} />
									</Menu.Item>
									<Menu.Item key="register" onClick={() => props.history.push('/register')}>
										<Icon type="poweroff" style={{ margin: 0, fontSize: 18 }} />
									</Menu.Item> */}
                                    </Menu>
                                    <Dropdown overlay={profileMenuSidebar} trigger={['click']} placement="bottomLeft">
                                        <Tooltip placement="right" title="My Account">
                                            <Avatar
                                                size={30}
                                                style={{
                                                    color: '#fff',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#1890ff',
                                                }}
                                                src={props.authState && props.authState.user && props.authState.user.photoURL}
                                            >
                                                {(props.authState &&
                                                    props.authState.user &&
                                                    props.authState.user.displayName &&
                                                    `${props.authState.user.displayName.split(' ')[0].slice(0, 1)}${props.authState.user.displayName
                                                        .split(' ')[1]
                                                        .slice(0, 1)}`) ||
                                                    '?'}
                                            </Avatar>
                                        </Tooltip>
                                    </Dropdown>
                                </div>
                            </div>
                        )}

                        <Layout
                            style={{
                                marginLeft: authState.status === 'in' && showNavigation !== false ? 58 : 0,
                                position: authState.status === 'in' ? 'absolute' : 'relative',
                                height: authState.status === 'in' ? '100%' : 'auto',
                                width: authState.status === 'in' && showNavigation !== false ? 'calc(100% - 50px)' : '100%',
                            }}
                            className="content-container"
                        >
                            <Layout className="sidebar">
                                {showNavigation !== false && authState.status === 'in' && sidebar !== false && (
                                    <Sider
                                        theme="dark"
                                        style={{
                                            marginTop: 64,
                                            boxShadow: '0 0 10px 0px rgba(0,21,41,.35)',
                                            zIndex: 11,
                                            height: 'calc(100% - 64px)',
                                            position: 'relative',
                                            borderRight: '1px solid rgba(255,255,255,0.1)',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {showNavigation !== false && sidebar !== false && (
                                            <React.Fragment>
                                                {props.authState.status === 'in' ? (
                                                    renderHeader()
                                                ) : (
                                                    <React.Fragment>
                                                        {urlPath === '/' ? (
                                                            <Headroom
                                                            // onPin={() => console.log('pinned mobile')}
                                                            // onUnpin={() => console.log('unpinned mobile')}
                                                            // downTolerance={10}
                                                            >
                                                                {renderHeader()}
                                                            </Headroom>
                                                        ) : (
                                                            renderHeader()
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                                // background: 'linear-gradient(180deg, #474a5e, #292c39)'
                                            }}
                                        >
                                            {isActive('', { includes: dpIncludes }) || current === 'digitalpool' ? (
                                                <DPNav {...props} onClicked={onClose} />
                                            ) : isActive('dpl') || current === 'dpl' ? (
                                                <DPLNav {...props} onClicked={onClose} />
                                            ) : isActive('league-manager') || current === 'league-manager' ? (
                                                <LMNav {...props} onClicked={onClose} />
                                            ) : isActive('tournament-builder') || current === 'tournament-builder' ? (
                                                <TBNav {...props} onClicked={onClose} />
                                            ) : isActive('tour-builder') || current === 'tour-builder' ? (
                                                <TRBNav {...props} onClicked={onClose} />
                                            ) : isActive('pool-live') || current === 'pool-live' ? (
                                                <PLNav {...props} onClicked={onClose} />
                                            ) : isActive('ghost-event-manager') || current === 'ghost-event-manager' ? (
                                                <GENav {...props} onClicked={onClose} />
                                            ) : (
                                                <DPNav {...props} onClicked={onClose} />
                                            )}
                                            <SidebarFooter {...props} toggleNavType={props.toggleNavType} navType="desktop" />
                                        </div>
                                    </Sider>
                                )}

                                <Layout>
                                    <Content
                                        // style={{ marginTop: props.authState.status === 'in' ? 0 : 64 }}
                                        className={current}
                                        style={{ height: '100%' }}
                                    >
                                        {authState && authState.status === 'in' && props.breadcrumb !== false ? (
                                            <div
                                                className="header-narrow"
                                                style={{
                                                    background: 'linear-gradient(rgb(40, 55, 76), rgb(13, 30, 53))',
                                                    padding: '12px 40px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    zIndex: 1,
                                                }}
                                            >
                                                <BreadcrumbContainer theme={theme.name}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Dropdown overlay={notificationMenu} trigger={['click']}>
                                                            <Button type="link" color="default" ghost style={{ marginRight: 10 }}>
                                                                <Badge dot>
                                                                    <Icon type="bell" theme="filled" style={{ fontSize: 18 }} />
                                                                </Badge>
                                                            </Button>
                                                        </Dropdown>

                                                        <Dropdown overlay={profileMenu} trigger={['click']}>
                                                            <Avatar
                                                                style={{
                                                                    color: '#fff',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: '#1890ff',
                                                                }}
                                                                src={authState && authState.user && authState.user.photoURL}
                                                            >
                                                                {(authState &&
                                                                    authState.user &&
                                                                    authState.user.displayName &&
                                                                    `${authState.user.displayName.split(' ')[0].slice(0, 1)}${authState.user.displayName
                                                                        .split(' ')[1]
                                                                        .slice(0, 1)}`) ||
                                                                    '?'}
                                                            </Avatar>
                                                        </Dropdown>
                                                    </div>
                                                </BreadcrumbContainer>
                                            </div>
                                        ) : null}
                                        Test
                                        {current !== 'tournament-builder' && showFooter !== false && <SectionFooter theme={theme} />}
                                    </Content>
                                </Layout>
                            </Layout>
                        </Layout>
                    </Layout>
                </ScrollToTop>
            )}
        />
    );
}

export default withRouter(AppLayoutDesktop);
