import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Row, Col, Typography } from 'antd';
import TBNotificationSettingsForm from './TBNotificationSettingsForm';
const { Text, Title } = Typography;

function TBNotificationSettings(props) {
	return (
		<React.Fragment>
			<Title level={4}>{props.title}</Title>
			<TBNotificationSettingsForm {...props} />
		</React.Fragment>
	);
}

export default withRouter(TBNotificationSettings);
