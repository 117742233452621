import React, { useState } from 'react';
import { Form, Input, Select, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_VENUE_TYPES_QUERY, GET_TABLE_MANUFACTURERS_QUERY } from './data/queries';
import _ from 'lodash';

const { Search } = Input;
const { Option } = Select;
const { Title, Text } = Typography;

export default function SearchFilterVenues(props) {
    const { venues, searching, onSearchVenue, onVenueTypeChange, onTableSizeChange, onTableManufacturerChange, placeholder } = props;

    function handleSubmit(values, actions) {
        actions.setSubmitting(false);
        actions.resetForm();
        console.log('Received values of form: ', values);
    }

    const tableSizes = ['6.5 Foot', '7 Foot', '8 Foot', '9 Foot', '10 Foot'];
    const poolTables = [];

    venues &&
        venues.forEach((venue) => {
            if (venue.pool_tables) {
                venue.pool_tables.forEach((table) => {
                    poolTables.push(table);
                });
            }
        });

    const sizes = poolTables && _.countBy(poolTables, 'size');
    const types = poolTables && _.countBy(poolTables, 'make');

    return (
        <Form layout="inline" onSubmit={handleSubmit}>
            <Form.Item>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <SearchOutlined size="large" style={{ marginRight: 10 }} /> */}
                    <Search
                        allowClear
                        placeholder={placeholder || 'Search all venues'}
                        size="large"
                        style={{ width: 325 }}
                        enterButton
                        onSearch={onSearchVenue}
                        loading={searching}
                    />
                </span>
            </Form.Item>

            <Form.Item name="type" label="Type">
                <Query query={GET_VENUE_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                    {({ loading, error, data }) => {
                        if (loading) return <div>Loading...</div>;
                        if (error) return <div>Error: {error.message}</div>;

                        const venueTypes = venues && _.countBy(venues, 'venue_type');

                        return (
                            <Select
                                showSearch
                                name="type"
                                placeholder="Venue type"
                                size="large"
                                style={{ width: 120 }}
                                optionFilterProp="children"
                                defaultValue="all"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={onVenueTypeChange}
                            >
                                <Option value="all">All ({(venues && venues.length) || 0})</Option>
                                {data.venue_types &&
                                    data.venue_types.map((item, index) => (
                                        <Option key={index} value={item.value}>
                                            {_.startCase(_.toLower(item.value.replace('_', ' ')))} ({(venueTypes && venueTypes[item.value]) || 0})
                                        </Option>
                                    ))}
                            </Select>
                        );
                    }}
                </Query>
            </Form.Item>
            <Form.Item name="table_size" label="Table size">
                <Select showSearch name="table_size" defaultValue="all" size="large" style={{ width: 120 }} onChange={onTableSizeChange}>
                    <Option value="all">All</Option>
                    {tableSizes.map((table, index) => {
                        return (
                            <Option value={table} key={index}>
                                {table} ({(sizes && sizes[table]) || 0})
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item name="table_manufacturer" label="Table brand">
                <Query query={GET_TABLE_MANUFACTURERS_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} onCompleted={(data) => {}}>
                    {({ loading, error, data }) => {
                        if (loading) return <div>Loading...</div>;
                        if (error) return <div>Error: {error.message}</div>;

                        return (
                            <Select
                                showSearch
                                name="table_manufacturer"
                                placeholder="SManufacturer"
                                size="large"
                                style={{ width: 120 }}
                                optionFilterProp="children"
                                defaultValue="all"
                                filterOption={(input, option) => option.props.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={onTableManufacturerChange}
                            >
                                <Option value="all">All</Option>
                                {data.pool_table_manufacturers.map((item, index) => (
                                    <Option key={index} value={item.name}>
                                        <Text>
                                            {item.name} ({(types && types[item.name]) || 0})
                                        </Text>
                                    </Option>
                                ))}
                            </Select>
                        );
                    }}
                </Query>
            </Form.Item>
        </Form>
    );
}
