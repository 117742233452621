import React, { useState, useEffect } from 'react';
import { message, Descriptions, Row, Tag, Modal, Avatar, Button, Empty, Icon, List, Skeleton, Col, Typography, Table } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionTitle from '../../../components/SectionTitle';
import CircularLoader from '../../../components/CircularLoader';
import Card, { Meta } from '../../../components/Card';
import { getGameType, displayEnumType } from '../../../utils';
import { Query, Mutation, Subscription } from '@apollo/client/react/components';
import { GET_MATCHES_QUERY, GET_MATCH_BY_SLUG_QUERY } from './data/queries';
import { DELETE_MATCH_MUTATION } from './data/mutations';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import TableScoreCard from '../../digitalpool/tournaments/TableScoreCard';
import StatsTable from './StatsTable';
import VideoOverlay from '../../../components/VideoOverlay';
import AdminMatchTable from './AdminMatchTable';
import AddAdminModal from './AddAdminModal';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { auth } from '../../../firebase';

const { Title, Text } = Typography;
const { confirm } = Modal;

export default function MatchOverview(props) {
    const { matchData, history, authState, client, theme } = props;
    const [loading, setLoading] = useState(false);
    const [addAdminModalVisible, setAddAdminModalVisible] = useState(false);
    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState();

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [deleteMatch] = useMutation(DELETE_MATCH_MUTATION);
    const params = props.match.params;
    const slug = params.match;

    console.log(matchData);

    if (matchData.managers.some((e) => e.user_id === (authState.user && authState.user.id))) {
        console.log('managers', matchData.managers.length);
    } else {
        console.log('no managers');
    }

    function onClickAddAdmin(evt) {
        setAddAdminModalVisible(true);
    }

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to delete ${record.name}?`,
            content: 'This will permanently remove the match and cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteMatch({
                    variables: {
                        id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_MATCHES_QUERY,
                            variables: {
                                creator_id: props.authState.user.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Match deleted');
                        props.history.push(`/matches`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    function handleVideoModalOk(e) {
        console.log(e);
        setVideoModalVisible(false);
    }

    function handleVideoModalCancel(e) {
        setVideoModalVisible(false);
    }

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                    </Col>
                    <Col xs={24}>
                        <Row gutter={24}>
                            <Col xs={24} sm={8}>
                                <Card
                                    title={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                Match Summary
                                            </Title>

                                            {((authState && authState.user && authState.user.role === 'admin') ||
                                                (authState && authState.user && authState.user.id === matchData.creator_id)) && (
                                                <Button type="danger" ghost onClick={() => showConfirmDelete(matchData)}>
                                                    <Icon type="delete" /> Delete
                                                </Button>
                                            )}
                                        </div>
                                    }
                                    // extra={
                                    //     <div>
                                    //         {matchData.status === 'IN_PROGRESS' && (
                                    //             <Tag color="red" size="large">
                                    //                 LIVE
                                    //             </Tag>
                                    //         )}
                                    //     </div>
                                    // }
                                    // cover={
                                    // 	<div
                                    // 		style={{
                                    // 			paddingTop: 16,
                                    // 			paddingBottom: 16,
                                    // 			paddingLeft: 24,
                                    // 			paddingRight: 10,
                                    // 			// background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                    // 			borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                    // 			display: 'flex',
                                    // 			alignItems: 'center',
                                    // 			justifyContent: 'space-between'
                                    // 		}}
                                    // 	>
                                    // 		<Title level={4} style={{ margin: 0, padding: 0 }}>
                                    // 			Match summary
                                    // 		</Title>
                                    // 		<div>
                                    // 			{matchData.status === 'IN_PROGRESS' ? (
                                    // 				<Tag color="red" size="large">
                                    // 					LIVE
                                    // 				</Tag>
                                    // 			) : (
                                    // 				<Tag size="large">{matchData.status}</Tag>
                                    // 			)}
                                    // 		</div>
                                    // 	</div>
                                    // }
                                    bodyStyle={{ padding: 0 }}
                                >
                                    <div
                                        style={{
                                            padding: 20,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: theme.name === 'dark' ? '#0b1a2c' : '#fafafa',
                                        }}
                                    >
                                        <Avatar
                                            size={150}
                                            src={getGameType(matchData.game_type, theme)}
                                            style={{ height: '100%', width: '100%', maxWidth: 150 }}
                                            // style={{ backgroundColor: '#1890ff' }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                                        <table width="100%" className="info-table">
                                            <tbody>
                                                <tr>
                                                    <td>Description</td>
                                                    <td>
                                                        <Text>{matchData.description || '-'}</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Match ID</td>
                                                    <td>
                                                        <Text>(ID: {matchData.id})</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Match Type</td>
                                                    <td>
                                                        <Text>{displayEnumType(matchData.match_type)}</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Game Type</td>
                                                    <td>
                                                        <Text>
                                                            <Avatar
                                                                size={24}
                                                                src={getGameType(matchData.game_type, theme)}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: 8,
                                                                }}
                                                            />
                                                            {displayEnumType(matchData.game_type)}
                                                        </Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Race To</td>
                                                    <td>
                                                        <Text>{matchData.race_to || '-'}</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Scheduled Time</td>
                                                    <td>
                                                        <Text>{moment(matchData.scheduled_time).format('llll')}</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        <Text>{displayEnumType(matchData.status) || '-'}</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Progress</td>
                                                    <td>
                                                        <Text>{`${matchData.progress || 0}%` || '-'}</Text>
                                                    </td>
                                                </tr>
                                                {matchData.livestream_url && (
                                                    <React.Fragment>
                                                        {matchData.status === 'COMPLETED' ? (
                                                            <tr>
                                                                <td>View recording</td>
                                                                <td>
                                                                    <a
                                                                        href={matchData.livestream_url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            fontWeight: 700,
                                                                            color: '#ff7529',
                                                                            marginBottom: 5,
                                                                            fontSize: 10,
                                                                            textTransform: 'uppercase',
                                                                            letterSpacing: 2,
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            setSelectedVideo(matchData.livestream_url);
                                                                            setVideoModalVisible(true);
                                                                        }}
                                                                    >
                                                                        <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> View recording
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <tr>
                                                                <td>Live stream link</td>
                                                                <td>
                                                                    <a
                                                                        href={matchData.livestream_url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            fontWeight: 700,
                                                                            color: '#f5212d',
                                                                            marginBottom: 5,
                                                                            fontSize: 10,
                                                                            textTransform: 'uppercase',
                                                                            letterSpacing: 2,
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            setSelectedVideo(matchData.livestream_url);
                                                                            setVideoModalVisible(true);
                                                                        }}
                                                                    >
                                                                        <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> Livestream
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                )}

                                                <tr>
                                                    <td>Table</td>
                                                    <td>
                                                        <Text>{(matchData.pool_table && matchData.pool_table.label) || '-'}</Text>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Table Size</td>
                                                    <td>
                                                        <Text>{matchData.table_size || '-'}</Text>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <Button type="primary" size="large" style={{ marginTop: 20, width: '100%' }}>
                                            Start Scoring
                                        </Button> */}
                                    </div>

                                    {authState && authState.user && authState.user.id === matchData.creator_id && (
                                        <div
                                            style={{
                                                padding: 20,
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                }}
                                            >
                                                {matchData.status === 'NOT_STARTED' ? (
                                                    <Link to={`/matches/${matchData.slug}/scoreboard`}>
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            Start scoring match
                                                        </Button>
                                                    </Link>
                                                ) : (
                                                    <Link to={`/matches/${matchData.slug}/scoreboard`}>
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            Scoreboard & stat tracking
                                                        </Button>
                                                    </Link>
                                                )}

                                                {matchData.event && matchData.pool_table_id && (
                                                    <React.Fragment>
                                                        <Link to={`/events/${matchData.event.slug}/tables/${matchData.pool_table.slug}/scoring`}>
                                                            <Button
                                                                type="ghost"
                                                                size="large"
                                                                style={{
                                                                    marginTop: 10,
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                Tablet Scoring
                                                            </Button>
                                                        </Link>

                                                        <Link to={`/events/${matchData.event.slug}/tables/${matchData.pool_table.slug}/tvdisplay`}>
                                                            <Button
                                                                type="ghost"
                                                                size="large"
                                                                style={{
                                                                    marginTop: 10,
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                TV Display
                                                            </Button>
                                                        </Link>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Card>
                                <br />

                                <Card title="Venue" bodyStyle={{ padding: 0 }}>
                                    <List
                                        className="hover-list"
                                        size="small"
                                        loading={loading}
                                        itemLayout="horizontal"
                                        dataSource={[matchData.venue]}
                                        renderItem={(item) => (
                                            <List.Item
                                                // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                style={{ padding: '10px 16px' }}
                                                onClick={() => history.push(`/venues/${item.slug}`)}
                                            >
                                                <Skeleton avatar title={false} loading={loading} active>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <div>
                                                                <Icon
                                                                    type="environment"
                                                                    theme="filled"
                                                                    style={{
                                                                        color: '#1890ff',
                                                                        fontSize: 30,
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        title={
                                                            <Text
                                                                style={{
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                {item.name}
                                                            </Text>
                                                        }
                                                        description={
                                                            <div>
                                                                {item.address1}, {item.city}, {item.region} {item.postal_code} {item.country}
                                                            </div>
                                                        }
                                                    />
                                                </Skeleton>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                                <br />

                                {matchData.event && (
                                    <React.Fragment>
                                        <Card title="Event" bodyStyle={{ padding: 0 }}>
                                            <List
                                                className="hover-list"
                                                size="small"
                                                loading={loading}
                                                itemLayout="horizontal"
                                                dataSource={[matchData.event]}
                                                renderItem={(item) => (
                                                    <List.Item
                                                        // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                        style={{ padding: '10px 16px' }}
                                                        onClick={() => history.push(`/events/${item.slug}`)}
                                                    >
                                                        <Skeleton avatar title={false} loading={loading} active>
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <div>
                                                                        <Icon
                                                                            type="calendar"
                                                                            style={{
                                                                                color: '#1890ff',
                                                                                fontSize: 30,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                title={
                                                                    <Text
                                                                        style={{
                                                                            fontSize: 16,
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </Text>
                                                                }
                                                                description={<div>{item.description}</div>}
                                                            />
                                                        </Skeleton>
                                                    </List.Item>
                                                )}
                                            />
                                        </Card>
                                        <br />
                                    </React.Fragment>
                                )}

                                {matchData.managers.some(
                                    (e) =>
                                        e.user_id === (props.authState && props.authState.user && props.authState.user.id) ||
                                        matchData.creator_id === (props.authState && props.authState.user && props.authState.user.id)
                                ) && (
                                    <Card
                                        title="Admins"
                                        bodyStyle={{ padding: 0 }}
                                        extra={
                                            <Button type="default" onClick={onClickAddAdmin}>
                                                Add Admin
                                            </Button>
                                        }
                                    >
                                        <AdminMatchTable
                                            matchId={matchData.id}
                                            userId={props.authState && props.authState.user && props.authState.user.id}
                                            adminId={matchData.creator_id}
                                            client={props.client}
                                        />
                                    </Card>
                                )}
                            </Col>
                            <Col xs={24} sm={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Row justify="center">
                                    <Col xs={24}>
                                        <Card title="Live Scores" bodyStyle={{ padding: 0 }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: 30,
                                                    backgroundColor: theme.name === 'dark' ? '#0b1a2c' : '#fafafa',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        maxWidth: 600,
                                                    }}
                                                >
                                                    <TableScoreCard
                                                        title={`Match ${matchData.match_number}`}
                                                        table={matchData.pool_table}
                                                        label={matchData.pool_table && matchData.pool_table.label}
                                                        matchData={{
                                                            ...matchData,
                                                            challenger1_name: matchData.player_fargo
                                                                ? `${matchData.player_name} (${matchData.player_fargo})`
                                                                : matchData.player_name,
                                                            challenger1_country: matchData.player_country,
                                                            challenger1_state: matchData.player_state,
                                                            challenger1_score: matchData.player_score,
                                                            challenger1_points: matchData.player_points,
                                                            challenger2_name: matchData.opponent_fargo
                                                                ? `${matchData.opponent_name} (${matchData.opponent_fargo})`
                                                                : matchData.opponent_name,
                                                            challenger2_country: matchData.opponent_country,
                                                            challenger2_state: matchData.opponent_state,
                                                            challenger2_score: matchData.opponent_score,
                                                            challenger2_points: matchData.opponent_points,
                                                            race_to: matchData.race_to,
                                                            start_time: matchData.start_date_time,
                                                            end_time: matchData.start_end_time,
                                                        }}
                                                        status={matchData.status}
                                                        gameType={matchData.game_type}
                                                        hoverable={false}
                                                        tableSize={breakpoint === 'mobile' ? 'small' : 'large'}
                                                        onVideoClick={() => {
                                                            setSelectedVideo(matchData.livestream_url);
                                                            setVideoModalVisible(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col
                                        xs={24}
                                        style={{
                                            display: 'flex',
                                        }}
                                        align="center"
                                    >
                                        <Card
                                            title="Player stats"
                                            headStyle={{
                                                textAlign: 'left',
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                borderRadius: '10px 10px 0px 0px',
                                                border: 'none',
                                                fontSize: 15,
                                                fontWeight: 600,
                                                // zIndex: 1,
                                                position: 'relative',
                                                textTransform: 'uppercase',
                                                letterSpacing: 2,
                                            }}
                                            extra={
                                                <Link to={`/matches/${matchData.slug}/stats`}>
                                                    <Button type="default">View All</Button>
                                                </Link>
                                            }
                                        >
                                            <div
                                                style={{
                                                    maxWidth: 800,
                                                }}
                                            >
                                                <StatsTable matchData={matchData} {...props} />
                                            </div>
                                        </Card>
                                    </Col>

                                    {/* <Col
										xs={24}
										style={{
											display: 'flex'
										}}
									>
										<Card title="Game history">Games</Card>
									</Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
            {matchData.managers.some(
                (e) =>
                    e.user_id === (props.authState && props.authState.user && props.authState.user.id) ||
                    matchData.creator_id === (props.authState && props.authState.user && props.authState.user.id)
            ) ? (
                <AddAdminModal
                    client={client}
                    visible={addAdminModalVisible}
                    matchSlug={params.slug}
                    matchId={matchData.id}
                    matchName={matchData.name}
                    userId={authState.user && authState.user.id}
                    userDisplayName={authState.user && authState.user.displayName}
                    onOk={() => {
                        setAddAdminModalVisible(false);
                    }}
                    onCancel={() => {
                        setAddAdminModalVisible(false);
                    }}
                />
            ) : (
                ''
            )}
            <VideoOverlay
                {...props}
                video={selectedVideo}
                match={matchData}
                onOk={handleVideoModalOk}
                onCancel={handleVideoModalCancel}
                modalVisible={videoModalVisible}
            />
        </SectionWrapper>
    );
}
