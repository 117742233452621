import React, { useState, useEffect } from 'react';
import { message, Alert, Icon, Row, Col, Modal, Typography, Button, Empty } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { SEARCH_UNLINKED_PLAYERS_QUERY } from './data/queries';
import CircularLoader from '../../components/CircularLoader';
import UnlinkedPlayerModal from './UnlinkedPlayerModal';
import _ from 'lodash';

const { Text, Title } = Typography;

export default function LinkPlayerNotification(props) {
    const { authState, client, players, reloaded, user, theme, onUpdatePlayers, onCreatePlayers } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [unlinkedPlayers, setUnlinkedPlayers] = useState();
    const [missingPlayers, setMissingPlayers] = useState();

    useEffect(() => {
        async function fetchData() {
            searchUnlinkedPlayers(players);
        }
        fetchData();
    }, [reloaded]);

    async function searchUnlinkedPlayers(players) {
        let promises = [];
        setSearchLoading(true);
        let missing = [];
        players
            .filter((player) => {
                return player.player_id === null && player.name;
            })
            .forEach((player, index) => {
                // console.log(player);
                promises.push(
                    searchUnlinkedPlayer(player.name).then((item) => {
                        // console.log(player.name);
                        // console.log(item);
                        if (item.length === 0) {
                            missing.push(player);
                        }
                        return item;
                    })
                );
            });

        Promise.all(promises)
            .then((results) => {
                const filtered = results.filter((item) => {
                    return item.length > 0;
                });

                setUnlinkedPlayers(filtered);
                setMissingPlayers(missing);
                // const flatList = _.compact(
                //     results.map((item) => {
                //         return item && item[0];
                //     })
                // );
                // console.log(flatList);
                // setUnlinkedPlayers(flatList);
                setSearchLoading(false);
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
                setSearchLoading(false);
            });
    }

    async function searchUnlinkedPlayer(playerName) {
        const res = await client.query({
            query: SEARCH_UNLINKED_PLAYERS_QUERY,
            variables: {
                search: playerName,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        });

        const results = res && res.data && res.data.search_unlinked_players;
        const filtered = results.filter((player) => {
            if (player.name.toLowerCase() === playerName.toLowerCase()) {
                return player;
            } else {
                return !player;
            }
        });

        if (filtered && filtered.length > 1) {
            const selected = filtered.filter((player) => {
                let dataValues = 0;
                if (player.phone_number) {
                    dataValues++;
                } else if (player.user_id) {
                    dataValues++;
                } else if (player.fargo_id) {
                    dataValues++;
                } else if (player.properties && player.properties.fargo_data) {
                    dataValues++;
                }

                if (dataValues >= 1) {
                    return player;
                } else {
                    return !player;
                }
            });
            return _.uniq(selected);
        } else {
            return filtered;
        }
    }

    function handleViewMatchingPlayers() {
        setModalVisible(true);
    }

    function handleModalCancel() {
        setModalVisible(false);
    }

    return (
        <React.Fragment>
            {searchLoading === true ? (
                <Alert
                    message={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <CircularLoader style={{ color: '#fff' }} /> <Text style={{ marginLeft: 10 }}>Searching for matching player records...</Text>
                        </div>
                    }
                    type="info"
                    closable
                    showIcon={false}
                />
            ) : (searchLoading === false && unlinkedPlayers && unlinkedPlayers.length > 0) || (missingPlayers && missingPlayers.length > 0) ? (
                <React.Fragment>
                    <Alert
                        message={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <React.Fragment>
                                    <div>
                                        <WarningOutlined style={{ color: '#d89614', marginRight: 8 }} />
                                        {(unlinkedPlayers && unlinkedPlayers.length) || 0} of {players && players.length} players have existing player records
                                        in the database. We recommend linking them together.
                                    </div>
                                    <Button
                                        type="ghost"
                                        ghost
                                        onClick={async () => {
                                            handleViewMatchingPlayers();
                                        }}
                                        style={{ marginLeft: 10 }}
                                        loading={searchLoading}
                                    >
                                        Link Player Records
                                        {/* Link player records */}
                                    </Button>
                                </React.Fragment>
                            </div>
                        }
                        type="warning"
                        closable
                        showIcon={false}
                    />
                    {((unlinkedPlayers && unlinkedPlayers.length > 0) || (missingPlayers && missingPlayers.length > 0)) && (
                        <UnlinkedPlayerModal
                            missingPlayers={missingPlayers}
                            unlinkedPlayers={unlinkedPlayers}
                            modalVisible={modalVisible}
                            onPlayersLinked={(players) => {
                                setModalVisible(false);
                                onUpdatePlayers(players);
                            }}
                            onPlayersCreated={(players) => {
                                setModalVisible(false);
                                onCreatePlayers(players);
                            }}
                            onModalCancel={handleModalCancel}
                        />
                    )}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
}
