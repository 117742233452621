import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, Row, Col, Button, Typography, Modal, Tooltip } from 'antd';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID } from './data/queries';
import CircularLoader from '../../../components/CircularLoader';
import Hashids from 'hashids';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    col: {
        paddingTop: 10,
        paddingBottom: 5,
        borderRight: '1px solid #ccc',
        textAlign: 'center',
    },
    colStart: {
        paddingTop: 10,
        paddingBottom: 5,
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
    },
    headerCol: {
        padding: 5,
        borderRight: '1px solid #ccc',
        textAlign: 'center',
    },
    headerColStart: {
        padding: 5,
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        textAlign: 'center',
    },
    row: {
        borderBottom: '1px solid #ccc',
    },
};

function DPTournamentMatchDetailScoresheet(props) {
    const { authState, client } = props;
    const hashids = new Hashids(`Tournament salt`);
    const params = props.match.params;
    const tournamentSlug = params.slug;
    const hashId = params.hash_id;

    let decoded;
    let tournamentID;
    let roundNumber;
    let matchNumber;

    if (hashId) {
        decoded = hashids.decode(hashId);
        tournamentID = decoded[1];
        roundNumber = decoded[2];
        matchNumber = decoded[3];

        console.log('decoded', decoded);
        console.log({
            tournamentID,
            roundNumber,
            matchNumber,
        });
    }

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    return (
        <div>
            {tournamentID ? (
                <Query
                    query={GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{ hash_id: hashId, tournament_id: tournamentID }}
                    onCompleted={(res) => {}}
                >
                    {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if (data && !data.tournament_brackets) {
                            return <div style={styles.container}>Match not found.</div>;
                        }
                        if (data && data.tournament_brackets.length === 0) {
                            return <div style={styles.container}>Match not found.</div>;
                        }

                        const match = data.tournament_brackets[0];
                        const tournament = match && match.tournament;
                        console.log(match);

                        let challenger1RaceTo;
                        let challenger2RaceTo;
                        if (match.identifier.includes('L')) {
                            challenger1RaceTo = match.challenger1_race_to || match.tournament.losers_race_to || 0;
                            challenger2RaceTo = match.challenger2_race_to || match.tournament.losers_race_to || 0;
                        } else {
                            challenger1RaceTo = match.challenger1_race_to || match.tournament.winners_race_to || 0;
                            challenger2RaceTo = match.challenger2_race_to || match.tournament.winners_race_to || 0;
                        }

                        const totalGames = Math.max(match.challenger1_race_to, match.challenger2_race_to);
                        console.log(totalGames);

                        function renderHeader() {
                            return Array(totalGames)
                                .fill()
                                .map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th style={styles.headerColStart} key={index}>
                                                {index + 1}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th style={styles.headerCol} key={index}>
                                                {index + 1}
                                            </th>
                                        );
                                    }
                                });
                        }

                        function renderGames() {
                            return Array(totalGames)
                                .fill()
                                .map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <td style={styles.colStart} key={index}>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: 20,
                                                        height: 20,
                                                        border: '2px solid #ccc',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td style={styles.col} key={index}>
                                                <div
                                                    style={{ display: 'inline-block', width: 20, height: 20, border: '2px solid #ccc', borderRadius: '50%' }}
                                                />
                                            </td>
                                        );
                                    }
                                });
                        }

                        return (
                            <Row gutter={16} type="flex" justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
                                <Col xs={24} align="center">
                                    <div style={{ maxWidth: 800 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div></div>
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 30 }}>{tournament.name}</div>
                                                <div>Match #{match.match_number}</div>
                                                <div>
                                                    {match.scheduled_time && (
                                                        <React.Fragment>
                                                            Scheduled: {moment.utc(match.scheduled_time).local().format('lll z')} {timezone}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>

                                        <div style={{ marginTop: 40, marginBottom: 40 }}>
                                            <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>Loser to #{match.loser_to_num}</div>
                                                <div>Winner to #{match.winner_to_num}</div>
                                            </div>
                                            <table width="100%" style={{ border: '1px solid #ccc' }}>
                                                <thead style={{ borderBottom: '1px solid #ccc' }}>
                                                    <tr>
                                                        <th style={{ paddingLeft: 10 }}>Player's name</th>

                                                        {renderHeader()}

                                                        <th style={styles.headerCol}>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={styles.row}>
                                                        <td style={{ paddingLeft: 10, fontSize: 20 }}>{match.challenger1_name}</td>
                                                        {renderGames()}
                                                        <td style={styles.col}></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 10, fontSize: 20 }}>{match.challenger2_name}</td>
                                                        {renderGames()}
                                                        <td style={styles.col}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div>
                                                {/* <div>{match.challenger1_name}</div>
                                                <div>QR code</div> */}
                                            </div>

                                            <div>
                                                <div>
                                                    Race to{' '}
                                                    {match.challenger1_race_to && match.challenger2_race_to
                                                        ? `${challenger1RaceTo}/${challenger2RaceTo}`
                                                        : match.tournament && match.tournament.winners_race_to}
                                                </div>
                                                <div>Circle the winner</div>
                                                <div>Printed: 09/15/2022 - 3:00pm CST</div>
                                            </div>

                                            <div>
                                                {/* <div>{match.challenger2_name}</div>
                                                <div>QR code</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            ) : (
                <div style={styles.container}>Match not found.</div>
            )}
        </div>
    );
}

export default withRouter(DPTournamentMatchDetailScoresheet);
