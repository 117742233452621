import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, List, Switch, Avatar, Skeleton } from 'antd';
import * as Yup from 'yup';
import { Mutation } from '@apollo/client/react/components';
import slugify from 'slugify';
import { GET_LEAGUE_BY_SLUG_QUERY } from '../data/queries';
import { UPDATE_LEAGUE_MUTATION } from '../data/mutations';

const { TextArea } = Input;
const { Text } = Typography;

function LMNotificationSettingsForm(props) {
    const { league, history } = props;
    const [loading, setLoading] = useState(false);

    function onChange(checked, name, updateLeague) {
        let input = {};
        if (name === 'match_notifications_in_app') {
            input.match_notifications_in_app = checked;
        } else if (name === 'match_notifications_sms') {
            input.match_notifications_sms = checked;
        }
        setLoading(true);
        updateLeague({
            variables: {
                id: props.league.id,
                changes: input,
            },
            refetchQueries: () => [
                {
                    query: GET_LEAGUE_BY_SLUG_QUERY,
                    variables: { slug: props.league.slug },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                },
            ],
        });
    }

    const notifications = [
        {
            title: 'In-App Match notifications',
            description: 'Notify players when they are scheduled to play a match via the DigitalPool app.',
            name: 'match_notifications_in_app',
            checked: league.match_notifications_in_app || false,
        },
        {
            title: 'SMS Match notifications',
            description: 'Notify players when they are scheduled to play a match via SMS.',
            name: 'match_notifications_sms',
            checked: league.match_notifications_sms || false,
        },
    ];

    return (
        <Mutation
            mutation={UPDATE_LEAGUE_MUTATION}
            onCompleted={(res) => {
                console.log(res);
                const league = res.update_leagues.returning[0];
                setLoading(false);
                message.success('Changes saved');
                // const result = league.insert_leagues.returning[0];
                // props.history.push(`/league-manager/${result.slug}/dashboard`);
                // const { updateLeague } = data;
                // history.push(`/league-manager/${league.slug}/settings/notifications`);
            }}
            awaitRefetchQueries
        >
            {(updateLeague, { error }) => {
                if (error) {
                    alert('Something went wrong');
                }
                return (
                    <List
                        // loading={initLoading}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={notifications}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Switch
                                        checkedChildren="ON"
                                        unCheckedChildren="OFF"
                                        defaultChecked={item.checked}
                                        onChange={(checked) => onChange(checked, item.name, updateLeague)}
                                    />,
                                ]}
                            >
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <List.Item.Meta title={item.title} description={item.description} />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                );
            }}
        </Mutation>
    );
}

export default withRouter(LMNotificationSettingsForm);
