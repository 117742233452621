import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Typography, Tag, Drawer, Empty, Button } from 'antd';
import TableScoreCard from './TableScoreCard';
import Fade from 'react-reveal/Fade';

function MatchesList(props) {
    const { authState, data, showMatchModal, tournament, match, onTableSelected } = props;
    const matches = data;

    const managers = tournament && tournament.tournament_managers;

    var manager;
    var isManager = false;

    if (authState && authState.user && managers && managers.length > 0) {
        for (let i = 0; i < managers.length; i++) {
            manager = managers[i].manager;

            if (!manager) continue;

            if (manager.id === authState.user.id) {
                isManager = true;
                break;
            }
        }
    }

    function canAccess() {
        if (
            (authState && authState.user && authState.user.role === 'admin') ||
            (authState &&
                authState.user &&
                ((tournament.organizer_id && tournament.organizer_id === authState.user.id) ||
                    (tournament.director_id && tournament.director_id === authState.user.id))) ||
            isManager
        ) {
            return true;
        } else {
            return false;
        }
    }

    function renderMatch(match, table, index) {
        return (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6} key={index}>
                <TableScoreCard
                    title={table ? `Match ${index + 1}` : null}
                    table={table}
                    label={table ? table.label : `Match ${index + 1}`}
                    matchData={match}
                    tournament={tournament}
                    status={match.status}
                    gameType={tournament.game_type}
                    hoverable={canAccess() === true ? true : false}
                    onClick={() => {
                        if (canAccess() === true) {
                            props.history.push(`/tournaments/${tournament.slug}/tables/${table.slug}/scoring`);
                        }
                    }}
                    playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                />
            </Col>
        );
    }

    return (
        <Row gutter={24} justify="center" type="flex">
            <Col xs={24}>
                <Row gutter={16} justify="start" type="flex">
                    {matches.map((match, index) => {
                        const table = match.pool_table;
                        return renderMatch(match, table, index);
                    })}
                </Row>
            </Col>
        </Row>
    );
}

export default withRouter(MatchesList);
