import React, { useState } from 'react';
import { Table, Icon, Button, Row, Empty, Col, Typography, Modal } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import SectionWrapper from '../../components/SectionWrapper';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import Card from '../../components/Card';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

function Privacy(props) {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <SectionWrapper>
            <SectionHeader
                title="Privacy Policy"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                height={breakpoint === 'mobile' ? 80 : 120}
                theme="digitalpool"
            />

            <SectionContent>
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={22}>
                        <div
                            style={{
                                display: 'flex',
                                margin: 24,
                                justifyContent: 'center',
                            }}
                        >
                            <Card>
                                <div className="content">
                                    <div className="privacy">
                                        <p className="lead">
                                            Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how
                                            we collect, use, communicate and disclose and make use of personal information. The following outlines our privacy
                                            policy.
                                        </p>
                                        <ul>
                                            <li>
                                                Before or at the time of collecting personal information, we will identify the purposes for which information is
                                                being collected.
                                            </li>
                                            <li>
                                                We will collect and use of personal information solely with the objective of fulfilling those purposes specified
                                                by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required
                                                by law.
                                            </li>
                                            <li>We will only retain personal information as long as necessary for the fulfillment of those purposes.</li>
                                            <li>
                                                We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or
                                                consent of the individual concerned.
                                            </li>
                                            <li>
                                                Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for
                                                those purposes, should be accurate, complete, and up-to-date.
                                            </li>
                                            <li>
                                                We will protect personal information by reasonable security safeguards against loss or theft, as well as
                                                unauthorized access, disclosure, copying, use or modification.
                                            </li>
                                            <li>
                                                We will make readily available to customers information about our policies and practices relating to the
                                                management of personal information.
                                            </li>
                                        </ul>
                                        <p>
                                            We are committed to conducting our business in accordance with these principles in order to ensure that the
                                            confidentiality of personal information is protected and maintained.
                                        </p>
                                        This Privacy Policy governs the manner in which DigitalPool LLC collects, uses, maintains and discloses information
                                        collected from users (each, a "User") of the <a href="https://digitalpool.com">DigitalPool.com</a> website ("Site").
                                        This privacy policy applies to the Site and all products and services offered by DigitalPool LLC.
                                        <br />
                                        <br />
                                        <b>Personal identification information</b>
                                        <br />
                                        <br />
                                        We may collect personal identification information from Users in a variety of ways, including, but not limited to, when
                                        Users visit our site, register on the site, place an order, subscribe to the newsletter, respond to a survey, fill out a
                                        form, and in connection with other activities, services, features or resources we make available on our Site. Users may
                                        be asked for, as appropriate, name, email address, mailing address, phone number, credit card information. Users may,
                                        however, visit our Site anonymously. We will collect personal identification information from Users only if they
                                        voluntarily submit such information to us. Users can always refuse to supply personally identification information,
                                        except that it may prevent them from engaging in certain Site related activities.
                                        <br />
                                        <br />
                                        <b>Non-personal identification information</b>
                                        <br />
                                        <br />
                                        We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal
                                        identification information may include the browser name, the type of computer and technical information about Users
                                        means of connection to our Site, such as the operating system and the Internet service providers utilized and other
                                        similar information.
                                        <br />
                                        <br />
                                        <b>Web browser cookies</b>
                                        <br />
                                        <br />
                                        Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for
                                        record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to
                                        refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not
                                        function properly.
                                        <br />
                                        <br />
                                        <b>How we use collected information</b>
                                        <br />
                                        <br />
                                        DigitalPool LLC may collect and use Users personal information for the following purposes:
                                        <br />
                                        <ul>
                                            <li>
                                                <i>To improve customer service</i>- Information you provide helps us respond to your customer service requests
                                                and support needs more efficiently.
                                            </li>
                                            <li>
                                                <i>To personalize user experience</i>- We may use information in the aggregate to understand how our Users as a
                                                group use the services and resources provided on our Site.
                                            </li>
                                            <li>
                                                <i>To improve our Site</i>- We may use feedback you provide to improve our products and services.
                                            </li>
                                            <li>
                                                <i>To process payments</i>- We may use the information Users provide about themselves when placing an order only
                                                to provide service to that order. We do not share this information with outside parties except to the extent
                                                necessary to provide the service.
                                            </li>
                                            <li>
                                                <i>To run a promotion, contest, survey or other Site feature</i>- To send Users information they agreed to
                                                receive about topics we think will be of interest to them.
                                            </li>
                                            <li>
                                                <i>To send periodic emails</i> - We may use the email address to send User information and updates pertaining to
                                                their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User
                                                decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related
                                                product or service information, etc. If at any time the User would like to unsubscribe from receiving future
                                                emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our
                                                Site.
                                            </li>
                                        </ul>
                                        <b>How we protect your information</b>
                                        <br />
                                        <br />
                                        We adopt appropriate data collection, storage and processing practices and security measures to protect against
                                        unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction
                                        information and data stored on our Site.
                                        <br />
                                        <br />
                                        Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and
                                        is encrypted and protected with digital signatures.
                                        <br />
                                        <br />
                                        <b>Sharing your personal information</b>
                                        <br />
                                        <br />
                                        We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated
                                        demographic information not linked to any personal identification information regarding visitors and users with our
                                        business partners, trusted affiliates and advertisers for the purposes outlined above.We may use third party service
                                        providers to help us operate our business and the Site or administer activities on our behalf, such as sending out
                                        newsletters or surveys. We may share your information with these third parties for those limited purposes provided that
                                        you have given us your permission.
                                        <br />
                                        <br />
                                        <b>Third party websites</b>
                                        <br />
                                        <br />
                                        Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers,
                                        advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these
                                        sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these
                                        sites or services, including their content and links, may be constantly changing. These sites and services may have
                                        their own privacy policies and customer service policies. Browsing and interaction on any other website, including
                                        websites which have a link to our Site, is subject to that website's own terms and policies.
                                        <br />
                                        <br />
                                        <b>Advertising</b>
                                        <br />
                                        <br />
                                        Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow
                                        the ad server to recognize your computer each time they send you an online advertisement to compile non personal
                                        identification information about you or others who use your computer. This information allows ad networks to, among
                                        other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy
                                        does not cover the use of cookies by any advertisers.
                                        <br />
                                        <br />
                                        <b>Google Adsense</b>
                                        <br />
                                        <br />
                                        Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their
                                        visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT
                                        track personal information about you, such as your name, email address, physical address, etc. You may opt out of the
                                        use of the DART cookie by visiting the Google ad and content network privacy policy at{' '}
                                        <a href="http://www.google.com/privacy_ads.html">http://www.google.com/privacy_ads.html</a>
                                        <br />
                                        <br />
                                        <b>Google Privacy Policy</b>
                                        <br />
                                        <br />
                                        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                                        <br />
                                        <br />
                                        <b>YouTube API</b>
                                        <br />
                                        <br />
                                        Our API client uses YouTube API Services to provide functionality to our users. By using our API, you consent to the
                                        collection, use, and sharing of your data as described in this privacy policy and in the{' '}
                                        <a href="https://developers.google.com/youtube/terms/api-services-terms-of-service">
                                            YouTube API Services Privacy Policy
                                        </a>
                                        . This includes, but is not limited to, the collection of your IP address, device information, and usage data for the
                                        purpose of providing and improving the API services. We take your privacy seriously and will only use your information
                                        in accordance with applicable laws and regulations. If you have any questions or concerns about our use of YouTube API
                                        Services, please contact us.
                                        <br />
                                        <br />
                                        <b>Changes to this privacy policy</b>
                                        <br />
                                        <br />
                                        DigitalPool LLC has the discretion to update this privacy policy at any time. When we do, we will revise the updated
                                        date at the bottom of this page and send you an email. We encourage Users to frequently check this page for any changes
                                        to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that
                                        it is your responsibility to review this privacy policy periodically and become aware of modifications.
                                        <br />
                                        <br />
                                        <b>Your acceptance of these terms</b>
                                        <br />
                                        <br />
                                        By using this Site, you signify your acceptance of this policy and <Link to="/terms">Terms of Service</Link>. If you do
                                        not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to
                                        this policy will be deemed your acceptance of those changes.
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(Privacy);
