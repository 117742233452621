import React, { useState, useEffect } from 'react';
import { Row, Button, Tabs } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import TBManageButtons from '../../tournament-builder/TBManageButtons';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { GET_TOURNAMENT_BRACKET_QUERY, GET_ALL_TOURNAMENT_TABLES_QUERY } from './data/queries';
import { GET_TOURNAMENT_BRACKET_SUBSCRIPTION } from './data/subscriptions';
import DPStageMatches from './DPStageMatches';
import _ from 'lodash';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function TBMatches(props) {
    const { tournament, client, theme } = props;
    const [activeTab, setActiveTab] = useState('set-1');
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [tournament]);

    function handleTabClick(tab) {
        setActiveTab(tab);
    }

    function toggleAutoScroll() {
        if (autoScrollEnabled === true) {
            setAutoScrollEnabled(false);
        } else {
            setAutoScrollEnabled(true);
        }
    }

    return (
        <SectionContent padding="20px 40px">
            {autoScrollEnabled === true && (
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <div>&#8673; Auto scroll enabled &#8673;</div>
                </div>
            )}

            <Row gutter={24} justify="center" type="flex" className={`matches ${autoScrollEnabled === true ? 'marquee' : ''}`}>
                <Query
                    query={GET_TOURNAMENT_BRACKET_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ tournamentId: tournament.id }}
                    onCompleted={(data) => {
                        console.log('tournament data:', data);
                        // const { tournament_brackets } = data;
                        // setBracket(tournament_brackets);
                    }}
                >
                    {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                        let multiStage = tournament && tournament.tournament_stage_format === 'multi';
                        let tournamentBrackets;
                        let stages = tournament && tournament.stages;
                        let matches;

                        if (tournament.enable_sets === true && tournament.sets_per_match > 0) {
                            matches = data.tournament_brackets.map((match) => {
                                return {
                                    ...match,
                                    challenger1_score: match.challenger1_sets_won.aggregate.count || 0,
                                    challenger2_score: match.challenger2_sets_won.aggregate.count || 0,
                                };
                            });

                            console.log(matches);
                        } else {
                            matches = data.tournament_brackets;
                        }

                        return (
                            <React.Fragment>
                                {multiStage === true && stages && stages.length > 0 ? (
                                    <Tabs
                                        defaultActiveKey="set-1"
                                        animated={{ inkBar: true, tabPane: false }}
                                        className="no-scroll tabs-bg2"
                                        onChange={handleTabClick}
                                        tabBarGutter={5}
                                        tabBarStyle={{
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {stages.map((stage, index) => {
                                            const advanceRounds =
                                                matches &&
                                                matches
                                                    .filter((match) => {
                                                        return match.stage_number === stage.number && match.advance_to !== null;
                                                    })
                                                    .map((match) => {
                                                        return match.round;
                                                    });

                                            tournamentBrackets =
                                                matches &&
                                                matches
                                                    .filter((match) => {
                                                        return match.stage_number === stage.number;
                                                    })
                                                    .filter((match, index) => {
                                                        if (match.stage_number === 1) {
                                                            return advanceRounds.some((value) => {
                                                                if (match.identifier.includes('L')) {
                                                                    return value <= match.round;
                                                                } else {
                                                                    return value >= match.round;
                                                                }
                                                            });
                                                        } else {
                                                            return match;
                                                        }
                                                    });

                                            //const stageMatches = matches.filter((match) => {
                                            //    return match.stage_number === stage.number;
                                            //});
                                            return (
                                                <TabPane tab={stage.name} key={stage.slug}>
                                                    <DPStageMatches
                                                        key={index}
                                                        tournament={tournament}
                                                        stage={stage}
                                                        matches={tournamentBrackets}
                                                        subscribeToMore={subscribeToMore}
                                                        subscribeToNewData={() => {
                                                            subscribeToMore({
                                                                document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                                                variables: { tournamentId: tournament.id },
                                                                updateQuery: (prev, { subscriptionData }) => {
                                                                    if (!subscriptionData.data) return prev;
                                                                    const newBracket = subscriptionData.data.tournament_brackets;
                                                                    let updated;
                                                                    if (prev) {
                                                                        updated = Object.assign({}, prev, {
                                                                            tournament_brackets: [...newBracket],
                                                                        });
                                                                    } else {
                                                                        updated = Object.assign({}, prev, {
                                                                            tournament_brackets: [...newBracket],
                                                                        });
                                                                    }
                                                                    return updated;
                                                                },
                                                            });
                                                        }}
                                                        {...props}
                                                    />
                                                </TabPane>
                                            );
                                        })}
                                    </Tabs>
                                ) : (
                                    <DPStageMatches
                                        tournament={tournament}
                                        matches={matches}
                                        subscribeToMore={subscribeToMore}
                                        subscribeToNewData={() => {
                                            subscribeToMore({
                                                document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                                variables: { tournamentId: tournament.id },
                                                updateQuery: (prev, { subscriptionData }) => {
                                                    if (!subscriptionData.data) return prev;
                                                    const newBracket = subscriptionData.data.tournament_brackets;
                                                    let updated;
                                                    if (prev) {
                                                        updated = Object.assign({}, prev, {
                                                            tournament_brackets: [...newBracket],
                                                        });
                                                    } else {
                                                        updated = Object.assign({}, prev, {
                                                            tournament_brackets: [...newBracket],
                                                        });
                                                    }
                                                    return updated;
                                                },
                                            });
                                        }}
                                        {...props}
                                    />
                                )}
                            </React.Fragment>
                        );
                    }}
                </Query>
            </Row>
            <div style={{ position: 'fixed', zIndex: 10, bottom: 20, left: '50%', transform: 'translateX(-50%)' }}>
                <Button
                    type="secondary"
                    onClick={() => {
                        toggleAutoScroll();
                    }}
                >
                    {autoScrollEnabled === true ? 'Turn off auto-scroll' : 'Turn on auto-scroll'}
                </Button>
            </div>
        </SectionContent>
    );
}

export default TBMatches;

// import React, { useState, useEffect } from 'react';
// import { Row, Tabs, Radio } from 'antd';
// import { Query, Subscription } from '@apollo/client/react/components';
// import CircularLoader from '../../../components/CircularLoader';
// import SectionHeader from '../../../components/SectionHeader';
// import SectionContent from '../../../components/SectionContent';
// import SectionWrapper from '../../../components/SectionWrapper';
// import SectionFooter from '../../../components/SectionFooter';
// import TBManageButtons from '../../tournament-builder/TBManageButtons';
// import { GET_TOURNAMENT_BRACKET_QUERY, GET_ALL_TOURNAMENT_TABLES_QUERY } from './data/queries';
// import { GET_TOURNAMENT_BRACKET_SUBSCRIPTION } from './data/subscriptions';
// import StageMatches from './DPStageMatches';
// import _ from 'lodash';
// import moment from 'moment';
// import momentDurationFormatSetup from 'moment-duration-format';
// momentDurationFormatSetup(moment);

// const { TabPane } = Tabs;

// const styles = {
//     row: {
//         display: 'flex',
//         flexGrow: 1,
//     },
//     tab: {
//         paddingTop: 5,
//         paddingLeft: 24,
//         paddingRight: 24,
//         paddingBottom: 24,
//     },
// };

// function TBMatches(props) {
//     const { tournament, client, theme } = props;
//     const [activeTab, setActiveTab] = useState('stage-1');
//     const [selected, setSelected] = useState('stage-1');
//     const [selectedStage, setSelectedStage] = useState();

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [tournament]);

//     function handleTabClick(tab) {
//         setActiveTab(tab);
//     }

//     return (
//         <SectionContent padding="20px 40px">
//             <Row gutter={24} justify="center" type="flex">
//                 <Query
//                     query={GET_TOURNAMENT_BRACKET_QUERY}
//                     fetchPolicy="cache-and-network"
//                     notifyOnNetworkStatusChange={true}
//                     variables={{ tournamentId: tournament.id }}
//                     onCompleted={(data) => {
//                         console.log('tournament data:', data);
//                         // const { tournament_brackets } = data;
//                         // setBracket(tournament_brackets);
//                     }}
//                 >
//                     {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
//                         if (loading)
//                             return (
//                                 <div style={styles.container}>
//                                     <CircularLoader />
//                                 </div>
//                             );
//                         if (error) return <div style={styles.container}>Error: {error.message}</div>;

//                         let multiStage = tournament && tournament.tournament_stage_format === 'multi';
//                         let tournamentBrackets;
//                         let stages = tournament && tournament.stages;
//                         const matches = data.tournament_brackets;

//                         return (
//                             <React.Fragment>
//                                 {multiStage === true && stages && stages.length > 0 ? (
//                                     <React.Fragment>
//                                         <Radio.Group
//                                             value={selected}
//                                             type="ghost"
//                                             onChange={(e) => {
//                                                 const active = e.target.value;
//                                                 setSelected(active);
//                                                 const activeStage = stages.filter((stage) => {
//                                                     return stage.slug === active;
//                                                 })[0];
//                                                 setSelectedStage(activeStage);
//                                                 // props.history.push(`/tournaments/${tournament.slug}/schedule/${active}`);
//                                             }}
//                                             style={{ marginRight: 10 }}
//                                         >
//                                             {stages.map((stage, index) => {
//                                                 const advanceRounds =
//                                                     matches &&
//                                                     matches
//                                                         .filter((match) => {
//                                                             return match.stage_number === stage.number && match.advance_to !== null;
//                                                         })
//                                                         .map((match) => {
//                                                             return match.round;
//                                                         });

//                                                 tournamentBrackets =
//                                                     matches &&
//                                                     matches
//                                                         .filter((match) => {
//                                                             return match.stage_number === stage.number;
//                                                         })
//                                                         .filter((match, index) => {
//                                                             if (match.stage_number === 1) {
//                                                                 return advanceRounds.some((value) => {
//                                                                     if (match.identifier.includes('L')) {
//                                                                         return value <= match.round;
//                                                                     } else {
//                                                                         return value >= match.round;
//                                                                     }
//                                                                 });
//                                                             } else {
//                                                                 return match;
//                                                             }
//                                                         });

//                                                 //const stageMatches = matches.filter((match) => {
//                                                 //    return match.stage_number === stage.number;
//                                                 //});
//                                                 return (
//                                                     <Radio.Button
//                                                         key={index}
//                                                         value={stage.slug}
//                                                         style={selected === stage.slug ? styles.selectedToggle : styles.defaultToggle}
//                                                     >
//                                                         {stage.name}
//                                                     </Radio.Button>
//                                                 );
//                                             })}
//                                         </Radio.Group>
//                                         <StageMatches
//                                             tournament={tournament}
//                                             stage={selectedStage || (stages && stages[0])}
//                                             matches={tournamentBrackets}
//                                             subscribeToMore={subscribeToMore}
//                                             subscribeToNewData={() => {
//                                                 subscribeToMore({
//                                                     document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
//                                                     variables: { tournamentId: tournament.id },
//                                                     updateQuery: (prev, { subscriptionData }) => {
//                                                         if (!subscriptionData.data) return prev;
//                                                         const newBracket = subscriptionData.data.tournament_brackets;
//                                                         let updated;
//                                                         if (prev) {
//                                                             updated = Object.assign({}, prev, {
//                                                                 tournament_brackets: [...newBracket],
//                                                             });
//                                                         } else {
//                                                             updated = Object.assign({}, prev, {
//                                                                 tournament_brackets: [...newBracket],
//                                                             });
//                                                         }
//                                                         return updated;
//                                                     },
//                                                 });
//                                             }}
//                                             {...props}
//                                         />
//                                     </React.Fragment>
//                                 ) : (
//                                     <StageMatches tournament={tournament} matches={matches} {...props} />
//                                 )}
//                             </React.Fragment>
//                         );
//                     }}
//                 </Query>
//             </Row>
//         </SectionContent>
//     );
// }

// export default TBMatches;
