import React, { useState, useEffect } from 'react';
import { message, List, Tabs, Row, Progress, Col, Table, Radio, Modal, Layout, Button, Tag, Icon, Tooltip, Typography, Empty, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import Card from '../../components/Card';
import CircleFlag from '../../components/CircleFlag';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_STAGES_QUERY } from './data/queries';
import {
    GET_TOURNAMENT_BRACKET_STAGES_SUBSCRIPTION,
    GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS,
    GET_TOURNAMENT_PLAYERS_STAGE_SUBSCRIPTION_W_STATS,
    GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2,
} from './data/subscriptions';
import RoundRobinGroup from './RoundRobinGroup';
import _ from 'lodash';
import PlayerModal from '../digitalpool/tournaments/bracket/PlayerModal';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

function RoundRobinMatches(props) {
    const {
        authState,
        history,
        tournament,
        groups,
        matches,
        stage,
        tables,
        isPublic,
        theme,
        bracketHeight,
        sidePanelOpen,
        onSidePanelOpen,
        onShowScoreModal,
        onSetMatches,
    } = props;
    const [players, setPlayers] = useState([]);
    const [roundTimeModalVisible, setRoundTimeModalVisible] = useState(false);
    const [currentRound, setCurrentRound] = useState();
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();

    // let payout = rounds && tournament.tournament_payouts && tournament.tournament_payouts.filter((payout) => rounds[0].place === payout.place)[0];
    // console.log(payout);
    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    const styles = {
        container: {
            padding: 20,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        noWrap: {
            whiteSpace: 'nowrap',
            borderBottom: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)',
        },
    };

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            sorter: (a, b) => {
                return a.rank - b.rank;
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Player',
            dataIndex: 'player',
            key: 'player',
            sorter: (a, b) => {
                return (
                    a.player &&
                    a.player.localeCompare(b.player, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {record.country && <CircleFlag country={record.country} />}
                            <Text>{getMatchTitle(record)}</Text>
                        </div>
                        {record.is_advancing === true && (
                            <Tag
                                color="#00796b"
                                style={{
                                    marginLeft: 8,
                                    marginRight: 10,
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                }}
                            >
                                A
                            </Tag>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            sorter: (a, b) => {
                return a.group - b.group;
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Matches (W-L-T)',
            dataIndex: 'match_record',
            key: 'match_record',
            sorter: (a, b) => {
                return a.matches_won - b.matches_won;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.matches_won}-{record.matches_lost}-{record.matches_tied} ({getNumber(record.match_differential)})
                    </Text>
                );
            },
        },
        {
            title: 'Games (W-L)',
            dataIndex: 'game_record',
            key: 'game_record',
            sorter: (a, b) => {
                return a.games_won - b.games_won;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.games_won}-{record.games_lost} ({getNumber(record.game_differential)})
                    </Text>
                );
            },
        },
        {
            title: 'Played',
            dataIndex: 'matches_played',
            key: 'matches_played',
            sorter: (a, b) => {
                return a.matches_played - b.matches_played;
            },
            render: (text, record) => {
                return <Text>{record.matches_played}</Text>;
            },
        },
        {
            title: 'Remaining',
            dataIndex: 'matches_remaining',
            key: 'matches_remaining',
            sorter: (a, b) => {
                return a.matches_remaining - b.matches_remaining;
            },
            render: (text, record) => {
                return <Text>{record.matches_remaining}</Text>;
            },
        },
        // {
        //     title: 'Set wins',
        //     dataIndex: 'matches_won',
        //     key: 'matches_won',
        //     sorter: (a, b) => {
        //         return a.matches_won - b.matches_won;
        //     },
        //     render: (text, record) => {
        //         return <Text>{record.matches_won}</Text>;
        //     },
        // },
        {
            title: 'Set ties',
            dataIndex: 'matches_tied',
            key: 'matches_tied',
            sorter: (a, b) => {
                return a.matches_tied - b.matches_tied;
            },
            render: (text, record) => {
                return <Text>{record.matches_tied}</Text>;
            },
        },
        {
            title: 'Tiebreak',
            dataIndex: 'tiebreak',
            key: 'tiebreak',
            sorter: (a, b) => {
                return a.tiebreak - b.tiebreak;
            },
            render: (text, record) => {
                return <Text>{record.tiebreak}</Text>;
            },
        },

        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => {
                return <Text>{(record.points && record.points) || '-'}</Text>;
            },
        },
        {
            title: 'Match History',
            dataIndex: 'match_history',
            key: 'match_history',
            render: (text, record) => {
                return <div style={{ whiteSpace: 'nowrap' }}>{renderMatchHistory(record.matches, record.id)}</div>;
            },
        },
    ];

    function handleShowPlayerModal(playerId) {
        setSelectedPlayerId(playerId);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    function sortByMatchWins(a, b) {
        return (
            b.matches_won - a.matches_won ||
            b.games_won - a.games_won ||
            b.game_differential - a.game_differential ||
            b.matches_tied - a.matches_tied ||
            b.skill_level - a.skill_level
        );
    }

    function getPlayersTied(players) {
        const playerRecords = players.map((player) => {
            return `${player.matches_won}-${player.matches_lost}-${player.matches_tied}`;
        });

        let playerTies = [];
        // number of match wins against tied opponents
        playerRecords.forEach((record, index) => {
            if (record !== '0-0-0' && playerRecords.includes(record) === true) {
                const player = players[index];
                playerTies.push({
                    ...player,
                    matches_tied: 1,
                });
            }
        });

        const tiedPlayers = playerTies.map((player, index) => {
            const playerMatches = player.matches;
            let winsAgainstOpponent = 0;
            playerMatches.forEach((match) => {
                if (match.challenger1_name === player.name && match.challenger1_is_winner === true) {
                    winsAgainstOpponent = winsAgainstOpponent + 1;
                } else if (match.challenger2_name === player.name && match.challenger2_is_winner === true) {
                    winsAgainstOpponent = winsAgainstOpponent + 1;
                }
            });
            const updatedPlayer = {
                ...player,
                opponent_wins: winsAgainstOpponent,
                matches_tiebreak: winsAgainstOpponent > 0 ? 1 : 0,
            };
            return updatedPlayer;
        });

        return tiedPlayers;
    }

    function getPlayersAdvancing(players, group) {
        // console.log('group', group);
        // console.log('group players', players);
        // const playersAdvancePerGroup = (stage && stage.players_advance_per_group) || 1;
        const playersRankedBy = (stage && stage.players_ranked_by) || tournament.players_ranked_by || 'match_wins';
        let advancingPlayers = [];
        const groupNumber = group && group.group;
        // const matchesPerPlayer = players.length;
        // console.log(matchesPerPlayer);
        let totalMatchesPerPlayer;

        if (playersRankedBy === 'match_wins') {
            let totalMatchesCompleted;

            players
                .filter((player) => {
                    if (groupNumber) {
                        return player.group === groupNumber;
                    } else {
                        return player;
                    }
                })
                .map((player) => {
                    totalMatchesPerPlayer = player.matches.length;
                    const completed = player.matches.filter((match) => {
                        return match.status === 'COMPLETED';
                    });
                    totalMatchesCompleted = completed.length;
                });
            // .reduce((acc, obj) => {
            //     return acc + obj.matches.length;
            // }, 0);

            // console.log('matches per player', totalMatchesPerPlayer);
            // console.log('matches completed', totalMatchesCompleted);

            let sortedPlayers = [];

            if (totalMatchesCompleted === totalMatchesPerPlayer) {
                sortedPlayers = players
                    .filter((player) => {
                        if (groupNumber) {
                            return player.group === groupNumber;
                        } else {
                            return player;
                        }
                    })
                    .filter((player) => {
                        const matchesPlayed = player.matches_won + player.matches_lost;
                        const matchesRemaining = player.matches.length - (player.matches_won + player.matches_lost);
                        // return player.matches_won > 0;
                        return matchesPlayed !== 0;
                    })
                    .map((player, index) => {
                        return {
                            ...player,
                            rank: index + 1,
                        };
                    })
                    .sort(sortByMatchWins);
            }

            // console.log('sorted players', sortedPlayers);

            // let rankedPlayers = sortedPlayers.map((player, index) => {
            //     const currentPlayerRecord = `${player.matches_won}-${player.matches_lost}-${player.matches_tied}`;
            //     const nextPlayer = sortedPlayers[index + 1];
            //     let nextPlayerRecord;
            //     if (nextPlayer) {
            //         nextPlayerRecord = `${nextPlayer.matches_won}-${nextPlayer.matches_lost}-${nextPlayer.matches_tied}`;
            //     }
            //     const prevPlayer = sortedPlayers[index - 1];
            //     let prevPlayerRecord;
            //     if (prevPlayer) {
            //         prevPlayerRecord = `${prevPlayer.matches_won}-${prevPlayer.matches_lost}-${prevPlayer.matches_tied}`;
            //     }

            //     let updatedPlayer;

            //     if (nextPlayerRecord && nextPlayerRecord === currentPlayerRecord) {
            //         console.log('next player record matches current player');
            //         let opponentIsWinner = false;
            //         nextPlayer.matches.forEach((match) => {
            //             if (match.challenger1_name === player.name) {
            //                 if (match.challenger1_score > match.challenger2_score) {
            //                     opponentIsWinner = true;
            //                 }
            //             } else if (match.challenger2_name === player.name) {
            //                 if (match.challenger2_score > match.challenger1_score) {
            //                     opponentIsWinner = true;
            //                 }
            //             }
            //         });
            //         console.log('next opponent is winner', opponentIsWinner);
            //         if (opponentIsWinner === true) {
            //             nextPlayer.rank++;
            //             updatedPlayer = {
            //                 ...player,
            //                 rank: player.rank - 1,
            //                 matches_tied: player.matches_tied + 1,
            //             };
            //         } else {
            //             updatedPlayer = player;
            //         }
            //     } else if (prevPlayerRecord && prevPlayerRecord === currentPlayerRecord) {
            //         console.log('prev player record matches current player');
            //         let opponentIsWinner;
            //         prevPlayer.matches.forEach((match) => {
            //             if (match.challenger1_name === player.name) {
            //                 if (match.challenger1_score > match.challenger2_score) {
            //                     opponentIsWinner = true;
            //                 }
            //             } else if (match.challenger2_name === player.name) {
            //                 if (match.challenger2_score > match.challenger1_score) {
            //                     opponentIsWinner = true;
            //                 }
            //             }
            //         });
            //         console.log('prev opponent is winner', opponentIsWinner);
            //         if (opponentIsWinner === true) {
            //             prevPlayer.rank++;
            //             updatedPlayer = {
            //                 ...player,
            //                 rank: player.rank - 1,
            //                 matches_tied: player.matches_tied + 1,
            //             };
            //         } else {
            //             updatedPlayer = player;
            //         }
            //     } else {
            //         updatedPlayer = player;
            //     }
            //     return updatedPlayer;
            // });

            advancingPlayers = sortedPlayers;
            // advancingPlayers = rankedPlayers;

            // console.log('count match wins', advancingPlayers);
            // .slice(0, playersAdvancePerGroup);
        } else if (playersRankedBy === 'game_set_wins') {
            console.log('count game / set wins');
        } else if (playersRankedBy === 'game_set_win_percentage') {
            console.log('count game / set win %');
        } else if (playersRankedBy === 'game_set_win_loss') {
            console.log('count game / set win loss difference');
        } else if (playersRankedBy === 'points_won') {
            console.log('count points won');
        } else if (playersRankedBy === 'point_difference') {
            console.log('count point difference');
        }

        return advancingPlayers;
    }

    function renderMatchHistory(matchesWithoutByes, playerId) {
        return (
            <Text>
                {matchesWithoutByes
                    .sort((a, b) => {
                        return a.match_number - b.match_number;
                    })
                    .filter((match) => {
                        return match.challenger1_is_winner === true || match.challenger2_is_winner === true;
                    })
                    .map((match, index) => {
                        const result =
                            match.challenger1_id === playerId && match.challenger1_is_winner === true
                                ? 'W'
                                : match.challenger2_id === playerId && match.challenger2_is_winner === true
                                ? 'W'
                                : match.challenger1_is_winner !== true && match.challenger2_is_winner !== true
                                ? '-'
                                : match.challenger1_is_forfeit === true || match.challenger2_is_forfeit === true
                                ? 'FF'
                                : 'L';

                        const opponentName =
                            match.challenger1_id === playerId ? match.challenger2_name : match.challenger2_id === playerId ? match.challenger1_name : null;
                        const playerScore =
                            match.challenger1_id === playerId ? match.challenger1_score : match.challenger2_id === playerId ? match.challenger2_score : 0;
                        const opponentScore =
                            match.challenger1_id !== playerId ? match.challenger1_score : match.challenger2_id !== playerId ? match.challenger2_score : 0;

                        return (
                            <React.Fragment key={index}>
                                <Tooltip placement="top" title={`${result} vs. ${opponentName} (${playerScore}-${opponentScore})`}>
                                    <span
                                        className={
                                            (match.challenger1_id === playerId && match.challenger1_is_winner === true) ||
                                            (match.challenger2_id === playerId && match.challenger2_is_winner === true)
                                                ? 'match-badge win'
                                                : 'match-badge loss'
                                        }
                                    >
                                        {result}
                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}
            </Text>
        );
    }

    function getMatchTitle(player) {
        if (player.name) {
            if (
                player.name !== 'BYE' &&
                player.skill_level &&
                player.race_to &&
                tournament.show_player_skill_levels === true &&
                tournament.show_player_races === true
            ) {
                return `${player.name} ${player.skill_level} (${player.race_to})`;
            } else if (player.name !== 'BYE' && player.skill_level && tournament.show_player_skill_levels === true) {
                return `${player.name} ${player.skill_level}`;
            } else if (player.name !== 'BYE' && player.race_to && tournament.show_player_races === true) {
                return `${player.name} (${player.race_to})`;
            } else {
                return `${player.name}`;
            }
            // if (player.playerName !== 'BYE' && player.raceTo) {
            //     return `${player.playerName} (${player.raceTo})`;
            // } else {
            //     return `${player.playerName}`;
            // }
        } else {
            return '';
        }
    }

    function getNumber(theNumber) {
        if (theNumber > 0) {
            return '+' + theNumber;
        } else {
            return theNumber.toString();
        }
    }

    if (stage && stage.number) {
        return (
            <Subscription
                subscription={GET_TOURNAMENT_PLAYERS_STAGE_SUBSCRIPTION_W_STATS}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournament.id, stage_number: stage.number }}
                onSubscriptionData={(data, loading, error) => {
                    if (!loading && !error) {
                        const { tournament_players } = data.subscriptionData.data;

                        let players =
                            tournament_players &&
                            tournament_players
                                // .filter((player) => {
                                //     console.log(player);
                                //     return player.is_eliminat    ed === false;
                                // })
                                .map((player) => {
                                    const matchesChallenger1 = player.tournament_matches_challenger1;
                                    const matchesChallenger2 = player.tournament_matches_challenger2;
                                    const playerMatchesCombined = [...matchesChallenger1, ...matchesChallenger2];

                                    const playerMatches = playerMatchesCombined.filter((match) => match.stage_number === 1);
                                    const player1MatchesWon =
                                        player.challenger1_matches_won &&
                                        player.challenger1_matches_won.aggregate &&
                                        player.challenger1_matches_won.aggregate.count;
                                    const player2MatchesWon =
                                        player.challenger2_matches_won &&
                                        player.challenger2_matches_won.aggregate &&
                                        player.challenger2_matches_won.aggregate.count;
                                    const matchesWon = player1MatchesWon + player2MatchesWon || 0;
                                    const player1MatchesLost =
                                        player.challenger1_matches_lost &&
                                        player.challenger1_matches_lost.aggregate &&
                                        player.challenger1_matches_lost.aggregate.count;
                                    const player2MatchesLost =
                                        player.challenger2_matches_lost &&
                                        player.challenger2_matches_lost.aggregate &&
                                        player.challenger2_matches_lost.aggregate.count;
                                    const matchesLost = player1MatchesLost + player2MatchesLost || 0;
                                    const player1GamesWon =
                                        (player.challenger1_matches_won &&
                                            player.challenger1_matches_won.aggregate &&
                                            player.challenger1_matches_won.aggregate.sum &&
                                            player.challenger1_matches_won.aggregate.sum.challenger1_score) +
                                        (player.challenger1_matches_lost &&
                                            player.challenger1_matches_lost.aggregate &&
                                            player.challenger1_matches_lost.aggregate.sum &&
                                            player.challenger1_matches_lost.aggregate.sum.challenger1_score);

                                    const player2GamesWon =
                                        (player.challenger2_matches_won &&
                                            player.challenger2_matches_won.aggregate &&
                                            player.challenger2_matches_won.aggregate.sum &&
                                            player.challenger2_matches_won.aggregate.sum.challenger2_score) +
                                        (player.challenger2_matches_lost &&
                                            player.challenger2_matches_lost.aggregate &&
                                            player.challenger2_matches_lost.aggregate.sum &&
                                            player.challenger2_matches_lost.aggregate.sum.challenger2_score);

                                    const player1GamesLost =
                                        (player.challenger1_matches_lost &&
                                            player.challenger1_matches_lost.aggregate &&
                                            player.challenger1_matches_lost.aggregate.sum &&
                                            player.challenger1_matches_lost.aggregate.sum.challenger2_score) +
                                        (player.challenger1_matches_won &&
                                            player.challenger1_matches_won.aggregate &&
                                            player.challenger1_matches_won.aggregate.sum &&
                                            player.challenger1_matches_won.aggregate.sum.challenger2_score);

                                    const player2GamesLost =
                                        (player.challenger2_matches_lost &&
                                            player.challenger2_matches_lost.aggregate &&
                                            player.challenger2_matches_lost.aggregate.sum &&
                                            player.challenger2_matches_lost.aggregate.sum.challenger1_score) +
                                        (player.challenger2_matches_won &&
                                            player.challenger2_matches_won.aggregate &&
                                            player.challenger2_matches_won.aggregate.sum &&
                                            player.challenger2_matches_won.aggregate.sum.challenger1_score);

                                    const gamesWon = player1GamesWon + player2GamesWon || 0;
                                    const gamesLost = player1GamesLost + player2GamesLost || 0;
                                    const playerMatchesSorted = playerMatches.sort((a, b) => {
                                        return b.match_number - a.match_number;
                                    });

                                    const playerTable =
                                        (playerMatches && playerMatchesSorted[0] && playerMatchesSorted[0].table_name && playerMatchesSorted[0].table_name) ||
                                        null;

                                    return {
                                        ...player,
                                        matches: playerMatchesSorted,
                                        matches_won: matchesWon,
                                        matches_lost: matchesLost,
                                        match_differential: matchesWon - matchesLost,
                                        matches_tiebreak: 0,
                                        matches_tied: 0,
                                        games_won: gamesWon,
                                        games_lost: gamesLost,
                                        game_differential: gamesWon - gamesLost,
                                        rank_points: gamesWon,
                                        rank_points_avg: (gamesWon + gamesLost) / 2,
                                        table: playerTable,
                                        is_waiting: playerTable ? false : true,
                                    };
                                });

                        // .sort((player) => {
                        //     return player.is_waiting ? -1 : 1;
                        // });

                        console.log('players', players);
                        setPlayers(players);
                    }
                }}
            >
                {({ loading, error, data }) => {
                    let rankedPlayers = [];
                    groups &&
                        groups.forEach((group, groupIndex) => {
                            //console.log(players);
                            //console.log(group);
                            const playersAdvancePerGroup = stage && stage.players_advance_per_group;
                            const groupPlayers =
                                players &&
                                players.filter((player) => {
                                    return player.group === group.group;
                                });

                            const groupPlayersRanked =
                                groupPlayers &&
                                groupPlayers.map((player, index) => {
                                    return {
                                        ...player,
                                        rank: index + 1,
                                    };
                                });

                            const advancing = getPlayersAdvancing(groupPlayersRanked, group);
                            const ties = getPlayersTied(groupPlayersRanked);
                            console.log('tied players', ties);
                            const advancingPlayers = advancing.slice(0, playersAdvancePerGroup);

                            return groupPlayersRanked.map((player) => {
                                const output = {
                                    ...player,
                                    is_advancing: _.includes(
                                        advancingPlayers.map((item) => item.id),
                                        player.id
                                    ),
                                };
                                rankedPlayers.push(output);
                                return output;
                            });
                        });

                    console.log('ranked players', rankedPlayers);

                    return (
                        <React.Fragment>
                            {loading && (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            )}
                            {!loading && !error && (
                                <div
                                    style={{
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingBottom: 20,
                                    }}
                                >
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <Card title="Standings" bodyStyle={{ overflowX: 'auto' }}>
                                            <Table
                                                columns={columns}
                                                dataSource={
                                                    rankedPlayers &&
                                                    rankedPlayers
                                                        .sort((a, b) => {
                                                            return b.rank_points - a.rank_points || b.skill_level - a.skill_level;
                                                        })
                                                        .map((row, index) => {
                                                            return {
                                                                ...row,
                                                                id: row.id,
                                                                rank: index + 1,
                                                                player: row.name,
                                                                country: row.country,
                                                                group: row.group,
                                                                matches_won: row.matches_won,
                                                                matches_lost: row.matches_lost,
                                                                matches_tied: row.matches_tied,
                                                                match_differential: row.match_differential,
                                                                match_record: row,
                                                                games_won: row.games_won,
                                                                games_lost: row.games_lost,
                                                                game_differential: row.game_differential,
                                                                game_record: row,
                                                                matches_played: row.matches_won + row.matches_lost,
                                                                matches_remaining: row.matches.length - (row.matches_won + row.matches_lost),
                                                                set_wins: row.matches_won,
                                                                tiebreak: row.matches_tiebreak,
                                                                points: row.rank_points,
                                                                match_history: row,
                                                                matches: row.matches,
                                                                key: index,
                                                            };
                                                        })
                                                }
                                                onRow={(record, rowIndex) => {
                                                    let classNameRow;
                                                    if (record.is_advancing === true) {
                                                        classNameRow = `advancing`;
                                                    } else {
                                                        classNameRow = '';
                                                    }
                                                    return {
                                                        onClick: (event) => {
                                                            console.log(record);
                                                            handleShowPlayerModal(record.id);
                                                        },
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: classNameRow,
                                                    };
                                                }}
                                                pagination={false}
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size="small"
                                            />
                                            {/* <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player</th>
                                            <th>Group</th>
                                            <th>Matches (W-L-T)</th>
                                            <th>Games (W-L)</th>
                                            <th>Played</th>
                                            <th>Remaining</th>
                                            <th>Set wins</th>
                                            <th>Tiebreak</th>
                                            <th>Set ties</th>
                                            <th>Points</th>
                                            <th>Match history</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rankedPlayers &&
                                            rankedPlayers
                                                .sort((a, b) => {
                                                    return b.rank_points - a.rank_points || b.skill_level - a.skill_level;
                                                })
                                                .map((row, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                backgroundColor: row.is_advancing === true ? 'rgba(76,255,68,0.1)' : null,
                                                            }}
                                                        >
                                                            <td style={styles.noWrap}>{index + 1}</td>
                                                            <td style={styles.noWrap}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {row.country && <CircleFlag country={row.country} />}
                                                                        <Text>{getMatchTitle(row)}</Text>
                                                                    </div>
                                                                    {row.is_advancing === true && (
                                                                        <Tag
                                                                            color="#00796b"
                                                                            style={{
                                                                                marginLeft: 5,
                                                                                marginRight: 10,
                                                                                borderRadius: 5,
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            A
                                                                        </Tag>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td style={styles.noWrap}>{row.group}</td>
                                                            <td style={styles.noWrap}>
                                                                {row.matches_won}-{row.matches_lost}-{row.matches_tied} (
                                                                {getNumber(row.match_differential)})
                                                            </td>
                                                            <td style={styles.noWrap}>
                                                                {row.games_won}-{row.games_lost} ({getNumber(row.game_differential)})
                                                            </td>
                                                            <td style={styles.noWrap}>{row.matches_won + row.matches_lost}</td>
                                                            <td style={styles.noWrap}>{row.matches.length - (row.matches_won + row.matches_lost)}</td>
                                                            <td style={styles.noWrap}>{row.matches_won}</td>
                                                            <td style={styles.noWrap}>{row.matches_tiebreak}</td>

                                                            <td style={styles.noWrap}>{row.matches_tied}</td>
                                                            <td style={styles.noWrap}>{row.rank_points}</td>
                                                            <td style={styles.noWrap}>{renderMatchHistory(row.matches, row.id)}</td>
                                                        </tr>
                                                    );
                                                })}
                                    </tbody>
                                </table> */}
                                        </Card>
                                    </div>
                                    {groups &&
                                        groups.map((group, groupIndex) => {
                                            //console.log(players);
                                            //console.log(group);
                                            const playersAdvancePerGroup = (stage && stage.players_advance_per_group) || 1;

                                            const groupPlayers =
                                                players &&
                                                players.filter((player) => {
                                                    return player.group === group.group;
                                                });

                                            console.log(`group players ${group.group}`, groupPlayers);

                                            const groupPlayersRanked =
                                                groupPlayers &&
                                                groupPlayers.map((player, index) => {
                                                    return {
                                                        ...player,
                                                        rank: index + 1,
                                                    };
                                                });

                                            const advancing = getPlayersAdvancing(groupPlayersRanked, group);
                                            const advancingPlayers = advancing.slice(0, playersAdvancePerGroup);

                                            return (
                                                <React.Fragment key={groupIndex}>
                                                    <RoundRobinGroup
                                                        stage={stage}
                                                        group={group}
                                                        players={groupPlayersRanked}
                                                        matches={matches}
                                                        advancingPlayers={advancingPlayers}
                                                        index={groupIndex}
                                                        onShowScoreModal={onShowScoreModal}
                                                        {...props}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            )}
                            <PlayerModal
                                tournamentID={tournament.id}
                                playerId={selectedPlayerId}
                                modalVisible={playerModalVisible}
                                onPlayerModalVisible={handlePlayerModalVisible}
                                {...props}
                            />
                        </React.Fragment>
                    );
                }}
            </Subscription>
        );
    } else {
        return (
            <Subscription
                subscription={GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournament.id }}
                onSubscriptionData={(data, loading, error) => {
                    if (!loading && !error) {
                        const { tournament_players } = data.subscriptionData.data;

                        let players =
                            tournament_players &&
                            tournament_players
                                // .filter((player) => {
                                //     console.log(player);
                                //     return player.is_eliminat    ed === false;
                                // })
                                .map((player) => {
                                    const matchesChallenger1 = player.tournament_matches_challenger1;
                                    const matchesChallenger2 = player.tournament_matches_challenger2;
                                    const playerMatchesCombined = [...matchesChallenger1, ...matchesChallenger2];
                                    const playerMatches = playerMatchesCombined;
                                    const player1MatchesWon =
                                        player.challenger1_matches_won &&
                                        player.challenger1_matches_won.aggregate &&
                                        player.challenger1_matches_won.aggregate.count;
                                    const player2MatchesWon =
                                        player.challenger2_matches_won &&
                                        player.challenger2_matches_won.aggregate &&
                                        player.challenger2_matches_won.aggregate.count;
                                    const matchesWon = player1MatchesWon + player2MatchesWon || 0;
                                    const player1MatchesLost =
                                        player.challenger1_matches_lost &&
                                        player.challenger1_matches_lost.aggregate &&
                                        player.challenger1_matches_lost.aggregate.count;
                                    const player2MatchesLost =
                                        player.challenger2_matches_lost &&
                                        player.challenger2_matches_lost.aggregate &&
                                        player.challenger2_matches_lost.aggregate.count;
                                    const matchesLost = player1MatchesLost + player2MatchesLost || 0;
                                    const player1GamesWon =
                                        (player.challenger1_matches_won &&
                                            player.challenger1_matches_won.aggregate &&
                                            player.challenger1_matches_won.aggregate.sum &&
                                            player.challenger1_matches_won.aggregate.sum.challenger1_score) +
                                        (player.challenger1_matches_lost &&
                                            player.challenger1_matches_lost.aggregate &&
                                            player.challenger1_matches_lost.aggregate.sum &&
                                            player.challenger1_matches_lost.aggregate.sum.challenger1_score);

                                    const player2GamesWon =
                                        (player.challenger2_matches_won &&
                                            player.challenger2_matches_won.aggregate &&
                                            player.challenger2_matches_won.aggregate.sum &&
                                            player.challenger2_matches_won.aggregate.sum.challenger2_score) +
                                        (player.challenger2_matches_lost &&
                                            player.challenger2_matches_lost.aggregate &&
                                            player.challenger2_matches_lost.aggregate.sum &&
                                            player.challenger2_matches_lost.aggregate.sum.challenger2_score);

                                    const player1GamesLost =
                                        (player.challenger1_matches_lost &&
                                            player.challenger1_matches_lost.aggregate &&
                                            player.challenger1_matches_lost.aggregate.sum &&
                                            player.challenger1_matches_lost.aggregate.sum.challenger2_score) +
                                        (player.challenger1_matches_won &&
                                            player.challenger1_matches_won.aggregate &&
                                            player.challenger1_matches_won.aggregate.sum &&
                                            player.challenger1_matches_won.aggregate.sum.challenger2_score);

                                    const player2GamesLost =
                                        (player.challenger2_matches_lost &&
                                            player.challenger2_matches_lost.aggregate &&
                                            player.challenger2_matches_lost.aggregate.sum &&
                                            player.challenger2_matches_lost.aggregate.sum.challenger1_score) +
                                        (player.challenger2_matches_won &&
                                            player.challenger2_matches_won.aggregate &&
                                            player.challenger2_matches_won.aggregate.sum &&
                                            player.challenger2_matches_won.aggregate.sum.challenger1_score);

                                    const gamesWon = player1GamesWon + player2GamesWon || 0;
                                    const gamesLost = player1GamesLost + player2GamesLost || 0;
                                    const playerMatchesSorted = playerMatches.sort((a, b) => {
                                        return b.match_number - a.match_number;
                                    });

                                    const playerTable =
                                        (playerMatches && playerMatchesSorted[0] && playerMatchesSorted[0].table_name && playerMatchesSorted[0].table_name) ||
                                        null;

                                    return {
                                        ...player,
                                        matches: playerMatchesSorted,
                                        matches_won: matchesWon,
                                        matches_lost: matchesLost,
                                        match_differential: matchesWon - matchesLost,
                                        matches_tiebreak: 0,
                                        matches_tied: 0,
                                        games_won: gamesWon,
                                        games_lost: gamesLost,
                                        game_differential: gamesWon - gamesLost,
                                        rank_points: gamesWon,
                                        rank_points_avg: (gamesWon + gamesLost) / 2,
                                        table: playerTable,
                                        is_waiting: playerTable ? false : true,
                                    };
                                });

                        // .sort((player) => {
                        //     return player.is_waiting ? -1 : 1;
                        // });

                        console.log('players', players);
                        setPlayers(players);
                    }
                }}
            >
                {({ loading, error, data }) => {
                    let rankedPlayers = [];
                    groups &&
                        groups.forEach((group, groupIndex) => {
                            //console.log(players);
                            //console.log(group);
                            const playersAdvancePerGroup = tournament.players_advance_per_group;
                            const groupPlayers =
                                players &&
                                players.filter((player) => {
                                    return player.group === group.group;
                                });

                            const groupPlayersRanked =
                                groupPlayers &&
                                groupPlayers.map((player, index) => {
                                    return {
                                        ...player,
                                        rank: index + 1,
                                    };
                                });

                            const advancing = getPlayersAdvancing(groupPlayersRanked, group);
                            const ties = getPlayersTied(groupPlayersRanked);
                            console.log('tied players', ties);
                            const advancingPlayers = advancing.slice(0, playersAdvancePerGroup);

                            return groupPlayersRanked.map((player) => {
                                const output = {
                                    ...player,
                                    is_advancing: _.includes(
                                        advancingPlayers.map((item) => item.id),
                                        player.id
                                    ),
                                };
                                rankedPlayers.push(output);
                                return output;
                            });
                        });

                    console.log('ranked players', rankedPlayers);

                    return (
                        <React.Fragment>
                            {loading && (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            )}
                            {!loading && !error && (
                                <div
                                    style={{
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingBottom: 20,
                                    }}
                                >
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <Card title="Standings" bodyStyle={{ overflowX: 'auto' }}>
                                            <Table
                                                columns={columns}
                                                dataSource={
                                                    rankedPlayers &&
                                                    rankedPlayers
                                                        .sort((a, b) => {
                                                            return b.rank_points - a.rank_points || b.skill_level - a.skill_level;
                                                        })
                                                        .map((row, index) => {
                                                            return {
                                                                ...row,
                                                                id: row.id,
                                                                rank: index + 1,
                                                                player: row.name,
                                                                country: row.country,
                                                                group: row.group,
                                                                matches_won: row.matches_won,
                                                                matches_lost: row.matches_lost,
                                                                matches_tied: row.matches_tied,
                                                                match_differential: row.match_differential,
                                                                match_record: row,
                                                                games_won: row.games_won,
                                                                games_lost: row.games_lost,
                                                                game_differential: row.game_differential,
                                                                game_record: row,
                                                                matches_played: row.matches_won + row.matches_lost,
                                                                matches_remaining: row.matches.length - (row.matches_won + row.matches_lost),
                                                                set_wins: row.matches_won,
                                                                tiebreak: row.matches_tiebreak,
                                                                points: row.rank_points,
                                                                match_history: row,
                                                                matches: row.matches,
                                                                key: index,
                                                            };
                                                        })
                                                }
                                                onRow={(record, rowIndex) => {
                                                    let classNameRow;
                                                    if (record.is_advancing === true) {
                                                        classNameRow = `advancing`;
                                                    } else {
                                                        classNameRow = '';
                                                    }
                                                    return {
                                                        onClick: (event) => {
                                                            console.log(record);
                                                            handleShowPlayerModal(record.id);
                                                        },
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: classNameRow,
                                                    };
                                                }}
                                                pagination={false}
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size="small"
                                            />
                                            {/* <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player</th>
                                            <th>Group</th>
                                            <th>Matches (W-L-T)</th>
                                            <th>Games (W-L)</th>
                                            <th>Played</th>
                                            <th>Remaining</th>
                                            <th>Set wins</th>
                                            <th>Tiebreak</th>
                                            <th>Set ties</th>
                                            <th>Points</th>
                                            <th>Match history</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rankedPlayers &&
                                            rankedPlayers
                                                .sort((a, b) => {
                                                    return b.rank_points - a.rank_points || b.skill_level - a.skill_level;
                                                })
                                                .map((row, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                backgroundColor: row.is_advancing === true ? 'rgba(76,255,68,0.1)' : null,
                                                            }}
                                                        >
                                                            <td style={styles.noWrap}>{index + 1}</td>
                                                            <td style={styles.noWrap}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {row.country && <CircleFlag country={row.country} />}
                                                                        <Text>{getMatchTitle(row)}</Text>
                                                                    </div>
                                                                    {row.is_advancing === true && (
                                                                        <Tag
                                                                            color="#00796b"
                                                                            style={{
                                                                                marginLeft: 5,
                                                                                marginRight: 10,
                                                                                borderRadius: 5,
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            A
                                                                        </Tag>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td style={styles.noWrap}>{row.group}</td>
                                                            <td style={styles.noWrap}>
                                                                {row.matches_won}-{row.matches_lost}-{row.matches_tied} (
                                                                {getNumber(row.match_differential)})
                                                            </td>
                                                            <td style={styles.noWrap}>
                                                                {row.games_won}-{row.games_lost} ({getNumber(row.game_differential)})
                                                            </td>
                                                            <td style={styles.noWrap}>{row.matches_won + row.matches_lost}</td>
                                                            <td style={styles.noWrap}>{row.matches.length - (row.matches_won + row.matches_lost)}</td>
                                                            <td style={styles.noWrap}>{row.matches_won}</td>
                                                            <td style={styles.noWrap}>{row.matches_tiebreak}</td>

                                                            <td style={styles.noWrap}>{row.matches_tied}</td>
                                                            <td style={styles.noWrap}>{row.rank_points}</td>
                                                            <td style={styles.noWrap}>{renderMatchHistory(row.matches, row.id)}</td>
                                                        </tr>
                                                    );
                                                })}
                                    </tbody>
                                </table> */}
                                        </Card>
                                    </div>
                                    {groups &&
                                        groups.map((group, groupIndex) => {
                                            //console.log(players);
                                            //console.log(group);
                                            const playersAdvancePerGroup = tournament.players_advance_per_group || 1;

                                            const groupPlayers =
                                                players &&
                                                players.filter((player) => {
                                                    return player.group === group.group;
                                                });

                                            const groupPlayersRanked =
                                                groupPlayers &&
                                                groupPlayers.map((player, index) => {
                                                    return {
                                                        ...player,
                                                        rank: index + 1,
                                                    };
                                                });

                                            const advancing = getPlayersAdvancing(groupPlayersRanked, group);
                                            const advancingPlayers = advancing.slice(0, playersAdvancePerGroup);

                                            return (
                                                <React.Fragment key={groupIndex}>
                                                    <RoundRobinGroup
                                                        group={group}
                                                        players={groupPlayersRanked}
                                                        matches={matches}
                                                        advancingPlayers={advancingPlayers}
                                                        index={groupIndex}
                                                        onShowScoreModal={onShowScoreModal}
                                                        {...props}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            )}
                            <PlayerModal
                                tournamentID={tournament.id}
                                playerId={selectedPlayerId}
                                modalVisible={playerModalVisible}
                                onPlayerModalVisible={handlePlayerModalVisible}
                                {...props}
                            />
                        </React.Fragment>
                    );
                }}
            </Subscription>
        );
    }
}

export default withRouter(RoundRobinMatches);
