import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Popconfirm, message, Menu, Dropdown, Modal, Tabs, Progress, List, Button, Upload, Icon, Typography, Avatar } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import CircleFlag from '../../components/CircleFlag';
import verifiedIcon from '../../assets/img/verified-icon.svg';
import { DownOutlined } from '@ant-design/icons';
import numeral from 'numeral';

const { Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    tab: {
        padding: 0,
    },
    error: { color: '#f5222d', marginTop: 10 },
};

function UnlinkedPlayerModal(props) {
    const { tournament, missingPlayers, unlinkedPlayers, modalVisible, onPlayersLinked, onPlayersCreated, onModalCancel } = props;
    const [playerList, setPlayerList] = useState([]);
    const [updatedList, setUpdatedList] = useState([]);
    const [activeTab, setActiveTab] = useState('unlinked');
    const [loading, setLoading] = useState(false);

    console.log({ unlinkedPlayers, missingPlayers });

    function maskPhoneNumber(number) {
        let maskedStr;
        if (number) {
            maskedStr = number.replace(/^(.{2}).*(.{2})$/, (match, p1, p2) => {
                let mask = '';
                for (let i = 0; i < number.length - 4; i++) {
                    mask += '*';
                }
                return p1 + mask + p2;
            });
        }
        return maskedStr; // "12******90"
    }

    function callback(key) {
        console.log(key);
        setActiveTab(key);
    }

    return (
        <Modal
            title={`Link Player Records`}
            centered
            transitionName="fade"
            wrapClassName="unlinked-players"
            // transitionName="none"
            maskTransitionName="none"
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: 0 }}
            destroyOnClose={false}
            footer={[
                <Button key="cancel" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Button
                    key="link"
                    disabled={loading ? true : false}
                    type="primary"
                    onClick={() => {
                        if (activeTab === 'unlinked') {
                            if (playerList && playerList.length > 0) {
                                onPlayersLinked(playerList);
                            } else {
                                const updatedPlayers = _.flatten(unlinkedPlayers);
                                onPlayersLinked(updatedPlayers);
                            }
                        } else if (activeTab === 'missing') {
                            onPlayersCreated(missingPlayers);
                        }
                    }}
                    style={{ marginLeft: 5 }}
                >
                    {activeTab === 'unlinked' ? <React.Fragment>Link Player Records</React.Fragment> : <React.Fragment>Create Player Records</React.Fragment>}
                </Button>,
            ]}
            visible={modalVisible}
            // onOk={() => {
            //     setConfirmPopupVisible(true);
            // }}
            onCancel={onModalCancel}
            // okText="Apply Ratings"
            // cancelText="Dismiss"
        >
            <Tabs
                animated={{ inkBar: true, tabPane: false }}
                onChange={callback}
                defaultActiveKey={activeTab}
                className="tabs-bg2"
                tabPosition="top"
                tabBarStyle={{
                    textTransform: 'uppercase',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    padding: 0,
                    // backgroundColor: '#132339',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                    color: '#fff',
                    zIndex: 1,
                    position: 'relative',

                    // borderTop: '1px solid rgba(255,255,255,0.1)',
                }}
            >
                <TabPane tab={`Unlinked Players (${(unlinkedPlayers && unlinkedPlayers.length) || 0})`} key="unlinked" style={styles.tab}>
                    {unlinkedPlayers && unlinkedPlayers.length > 0 && (
                        <List className="hover-list" size="small" loading={loading}>
                            <div>
                                {unlinkedPlayers.map((item, index) => {
                                    let selectedItem = item && item[0];

                                    const selectedName = selectedItem.name;
                                    const selectedCountry = selectedItem.country;
                                    const selectedLocation =
                                        selectedItem &&
                                        selectedItem.properties &&
                                        selectedItem.properties.fargo_data &&
                                        selectedItem.properties.fargo_data.location;
                                    const selectedMembershipId =
                                        selectedItem &&
                                        selectedItem.properties &&
                                        selectedItem.properties.fargo_data &&
                                        selectedItem.properties.fargo_data.membershipId;
                                    const selectedRobustness =
                                        selectedItem.properties &&
                                        selectedItem.properties.fargo_data &&
                                        selectedItem.properties.fargo_data.robustness &&
                                        numeral(selectedItem.properties.fargo_data.robustness).format('0,0');
                                    const selectedRating =
                                        selectedItem.properties && selectedItem.properties.fargo_data && selectedItem.properties.fargo_data.effectiveRating;

                                    return (
                                        <React.Fragment key={index}>
                                            {item && item.length > 1 ? (
                                                <Dropdown
                                                    getPopupContainer={() => document.querySelectorAll('.unlinked-players .ant-modal-body')[0]}
                                                    overlay={() => {
                                                        return (
                                                            <Menu selectedKeys={[`${index}-0`]}>
                                                                {item.map((row, index2) => {
                                                                    return (
                                                                        <Menu.Item
                                                                            key={`${index}-${index2}`}
                                                                            onClick={() => {
                                                                                console.log(item);
                                                                                let newFargoResults = unlinkedPlayers;
                                                                                let newItems = item;
                                                                                newItems[index2] = newItems[0];
                                                                                newItems[0] = row;
                                                                                newFargoResults[index] = newItems;
                                                                                let newPlayerList = playerList;
                                                                                newPlayerList[index] = row;

                                                                                // setPlayerList([...newPlayerList]);
                                                                                // setUpdatedList([...newFargoResults]);
                                                                                const playersBeforeSelected = unlinkedPlayers.slice(0, index);
                                                                                const playersAfterSelected = unlinkedPlayers.slice(
                                                                                    index + 1,
                                                                                    unlinkedPlayers.length
                                                                                );
                                                                                const playerSelected = row;

                                                                                const updatedPlayerList = [
                                                                                    ...playersBeforeSelected,
                                                                                    [playerSelected],
                                                                                    ...playersAfterSelected,
                                                                                ];
                                                                                console.log(_.flatten(updatedPlayerList));
                                                                                setPlayerList(_.flatten(updatedPlayerList));
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between',
                                                                                }}
                                                                            >
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {row.country && <CircleFlag country={row.country} size={30} />}
                                                                                    <Text>
                                                                                        {row.name && <React.Fragment>{row.name}</React.Fragment>}
                                                                                        {row.properties &&
                                                                                            row.properties.fargo_data &&
                                                                                            row.properties.fargo_data.location && (
                                                                                                <React.Fragment>
                                                                                                    ,{' '}
                                                                                                    {row.properties &&
                                                                                                        row.properties.fargo_data &&
                                                                                                        row.properties.fargo_data.location}{' '}
                                                                                                    -{' '}
                                                                                                    {row.properties &&
                                                                                                        row.properties.fargo_data &&
                                                                                                        row.properties.fargo_data.effectiveRating}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        {row.user_id && (
                                                                                            <img
                                                                                                src={verifiedIcon}
                                                                                                height={20}
                                                                                                alt="Verified User"
                                                                                                style={{ marginLeft: 5 }}
                                                                                            />
                                                                                        )}
                                                                                    </Text>
                                                                                </div>
                                                                                <div>
                                                                                    <Text type="secondary">
                                                                                        {row.properties &&
                                                                                            row.properties.fargo_data &&
                                                                                            row.properties.fargo_data.membershipId}
                                                                                    </Text>
                                                                                </div>
                                                                            </div>
                                                                        </Menu.Item>
                                                                    );
                                                                })}
                                                            </Menu>
                                                        );
                                                    }}
                                                    overlayStyle={{
                                                        maxHeight: 300,
                                                        maxWidth: 300,
                                                        overflowY: 'auto',
                                                        position: 'relative',
                                                        margin: 0,
                                                        padding: '4px 0',
                                                        textAlign: 'left',
                                                        listStyleType: 'none',
                                                        backgroundColor: '#fff',
                                                        backgroundClip: 'padding-box',
                                                        borderRadius: 4,
                                                        outline: 'none',
                                                        boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
                                                    }}
                                                    trigger={['click']}
                                                >
                                                    <List.Item
                                                        extra={
                                                            <Text>
                                                                {item.length - 1} other matches
                                                                <DownOutlined />
                                                            </Text>
                                                        }
                                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <React.Fragment>
                                                                    {selectedCountry ? (
                                                                        <CircleFlag country={selectedCountry} size={30} />
                                                                    ) : (
                                                                        <Avatar size={30}>
                                                                            <Icon type="user" />
                                                                        </Avatar>
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                            title={
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        {selectedName && selectedRating ? (
                                                                            <React.Fragment>
                                                                                {selectedName} - {selectedRating}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>{selectedName}</React.Fragment>
                                                                        )}
                                                                    </div>
                                                                    {selectedItem.user_id && (
                                                                        <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />
                                                                    )}
                                                                </div>
                                                            }
                                                            description={
                                                                <React.Fragment>
                                                                    <Text type="secondary">
                                                                        {selectedLocation && (
                                                                            <React.Fragment>
                                                                                {selectedLocation}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {selectedItem.phone_number && (
                                                                            <React.Fragment>
                                                                                Phone number: {maskPhoneNumber(selectedItem.phone_number)}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {selectedMembershipId && (
                                                                            <React.Fragment>
                                                                                Fargo ID: {selectedMembershipId}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}

                                                                        {selectedRobustness && (
                                                                            <React.Fragment>Robustness: {selectedRobustness}</React.Fragment>
                                                                        )}
                                                                    </Text>
                                                                    {selectedItem.user_id && (
                                                                        <div>
                                                                            User ID: {selectedItem.user_id || '-'}{' '}
                                                                            {selectedItem.user && selectedItem.user.slug && (
                                                                                <React.Fragment>
                                                                                    {' - '}
                                                                                    <Link to={{ pathname: `/users/${selectedItem.user.slug}` }} target="_blank">
                                                                                        User Profile
                                                                                    </Link>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {selectedItem.id && (
                                                                        <div>
                                                                            Player ID: {selectedItem.id || '-'}{' '}
                                                                            {selectedItem.name_slug && (
                                                                                <React.Fragment>
                                                                                    {' - '}
                                                                                    <Link
                                                                                        to={{ pathname: `/players/${selectedItem.name_slug}` }}
                                                                                        target="_blank"
                                                                                    >
                                                                                        Player Profile
                                                                                    </Link>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </List.Item>
                                                </Dropdown>
                                            ) : (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <React.Fragment>
                                                                {selectedCountry ? (
                                                                    <CircleFlag country={selectedCountry} size={30} />
                                                                ) : (
                                                                    <Avatar size={30}>
                                                                        <Icon type="user" />
                                                                    </Avatar>
                                                                )}
                                                            </React.Fragment>
                                                        }
                                                        title={
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    {selectedName && selectedRating ? (
                                                                        <React.Fragment>
                                                                            {selectedName} - {selectedRating}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>{selectedName}</React.Fragment>
                                                                    )}
                                                                </div>
                                                                {selectedItem.user_id && (
                                                                    <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />
                                                                )}
                                                            </div>
                                                        }
                                                        description={
                                                            <React.Fragment>
                                                                <Text type="secondary">
                                                                    {selectedLocation && (
                                                                        <React.Fragment>
                                                                            {selectedLocation}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    {selectedItem.phone_number && (
                                                                        <React.Fragment>
                                                                            Phone number: {maskPhoneNumber(selectedItem.phone_number)}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    {selectedMembershipId && (
                                                                        <React.Fragment>
                                                                            Fargo ID: {selectedMembershipId}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    )}

                                                                    {selectedRobustness && <React.Fragment>Robustness: {selectedRobustness}</React.Fragment>}
                                                                </Text>
                                                                {selectedItem.user_id && (
                                                                    <div>
                                                                        User ID: {selectedItem.user_id || '-'}{' '}
                                                                        {selectedItem.user && selectedItem.user.slug && (
                                                                            <React.Fragment>
                                                                                {' - '}
                                                                                <Link to={{ pathname: `/users/${selectedItem.user.slug}` }} target="_blank">
                                                                                    User Profile
                                                                                </Link>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {selectedItem.id && (
                                                                    <div>
                                                                        Player ID: {selectedItem.id || '-'}{' '}
                                                                        {selectedItem.name_slug && (
                                                                            <React.Fragment>
                                                                                {' - '}
                                                                                <Link to={{ pathname: `/players/${selectedItem.name_slug}` }} target="_blank">
                                                                                    Player Profile
                                                                                </Link>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </List>
                    )}
                </TabPane>
                <TabPane tab={`Missing Players (${(missingPlayers && missingPlayers.length) || 0})`} key="missing" style={styles.tab}>
                    {missingPlayers && missingPlayers.length > 0 ? (
                        <List className="hover-list" size="small" loading={loading}>
                            <div>
                                {missingPlayers.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={
                                                        <React.Fragment>
                                                            {item.country ? (
                                                                <CircleFlag country={item.country} size={30} />
                                                            ) : (
                                                                <Avatar size={30}>
                                                                    <Icon type="user" />
                                                                </Avatar>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                    title={
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {item.name && item.skill_level ? (
                                                                    <React.Fragment>
                                                                        {item.name} - {item.skill_level}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>{item.name}</React.Fragment>
                                                                )}
                                                            </div>
                                                            {item.user_id && (
                                                                <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />
                                                            )}
                                                        </div>
                                                    }
                                                    description={
                                                        <React.Fragment>
                                                            <Text type="secondary">
                                                                {item.phone_number && (
                                                                    <React.Fragment>
                                                                        Phone number: {maskPhoneNumber(item.phone_number)}
                                                                        <br />
                                                                    </React.Fragment>
                                                                )}
                                                                {item.fargo_id && (
                                                                    <React.Fragment>
                                                                        Fargo ID: {item.fargo_id}
                                                                        <br />
                                                                    </React.Fragment>
                                                                )}
                                                                {/* {item.skill_level && (
                                                                    <React.Fragment>
                                                                        Skill Level: {item.skill_level}
                                                                        <br />
                                                                    </React.Fragment>
                                                                )} */}
                                                            </Text>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </List.Item>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </List>
                    ) : (
                        <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20 }}>No player records are missing</div>
                    )}
                </TabPane>
            </Tabs>
        </Modal>
    );
}

export default withRouter(UnlinkedPlayerModal);
