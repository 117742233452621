import React, { useState } from 'react';
import { message, Card, Avatar, Typography, Modal, Tabs, Tag, Button, Divider } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { GET_VENUE_TOURNAMENTS_QUERY, GET_VENUE_LEAGUES_QUERY } from './data/queries';
import { Query } from '@apollo/client/react/components';
import { getGameType } from '../../../utils';
import CircularLoader from '../../../components/CircularLoader';
import moment from 'moment';
import { GlobalOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function VenueHostModal(props) {
    const { venue, modalVisible, onModalOk, onModalCancel, selectedTab, authState, selectedVenueId } = props;
    console.log(venue);

    return (
        <Modal
            title={`${venue.name} Tournaments and Leagues`}
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            centered
            transitionName="fade"
            // transitionName="none"
            maskTransitionName="none"
            footer={[
                <Button key="cancel" onClick={onModalCancel}>
                    Close
                </Button>,
                // <Button key="submit" type="primary" onClick={onModalCancel}>
                // 	Submit
                // </Button>
            ]}
            bodyStyle={{
                padding: 0,
                maxHeight: 500,
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
            destroyOnClose={true}
        >
            <Tabs
                animated={{ inkBar: true, tabPane: false }}
                defaultActiveKey={selectedTab || 'tournaments'}
                // onChange={callback}
                // type="card"
                tabBarStyle={{ textTransform: 'uppercase', paddingLeft: 10 }}
            >
                <TabPane tab={`Tournaments (${venue.tournaments.aggregate.count})`} key="tournaments">
                    <Query
                        query={GET_VENUE_TOURNAMENTS_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ id: venue.id }}
                        onCompleted={(data) => {
                            const tournaments = data.venues && data.venues[0] && data.venues[0].tournaments;
                            console.log(tournaments);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div>Error: {error.message}</div>;

                            const tournaments = data.venues && data.venues[0] && data.venues[0].tournaments;

                            return (
                                <div style={{ padding: 10 }}>
                                    {tournaments.map((tournament, index) => {
                                        return (
                                            <Card
                                                // title={league.name}
                                                bordered={false}
                                                hoverable
                                                style={{ marginBottom: 10 }}
                                                // extra={<a href="#">More</a>}
                                                key={index}
                                            >
                                                <Link to={`/tournaments/${tournament.slug}`}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <div
                                                                style={{
                                                                    width: 50,
                                                                    height: 50,
                                                                }}
                                                            >
                                                                <Avatar
                                                                    size={50}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        backgroundColor: '#fff',
                                                                        border: '1px solid #ccc',
                                                                    }}
                                                                    src={
                                                                        tournament.avatar
                                                                            ? tournament.avatar
                                                                            : tournament.game_type
                                                                            ? getGameType(tournament.game_type)
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                            <div style={{ marginLeft: 15 }}>
                                                                <Title level={4} style={{ margin: 0 }}>
                                                                    {tournament.name}
                                                                </Title>
                                                                {/* <Text type="primary">{tournament.description}</Text> */}

                                                                <Text type="secondary" style={{ marginTop: 5, display: 'block' }}>
                                                                    {moment(tournament.created_at).format('MMMM Do YYYY')}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {tournament.organizer_id === authState && authState.user.id ? (
                                                                <Tag size="large" color="#f50">
                                                                    ORGANIZER
                                                                </Tag>
                                                            ) : tournament.director_id === authState && authState.user.id ? (
                                                                <Tag color="#87d068">DIRECTOR</Tag>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Card>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    </Query>
                </TabPane>
                <TabPane tab={`Leagues (${venue.leagues.aggregate.count})`} key="leagues">
                    <Query
                        query={GET_VENUE_LEAGUES_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ id: venue.id }}
                        onCompleted={(data) => {
                            const leagues = data.venues && data.venues[0] && data.venues[0].league_venues;
                            console.log(leagues);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div>Error: {error.message}</div>;

                            const leagues =
                                data.venues &&
                                data.venues[0] &&
                                data.venues[0].league_venues.map((league_venue) => {
                                    return league_venue.league;
                                });

                            return (
                                <React.Fragment>
                                    {leagues.map((league, index) => {
                                        return (
                                            <Card
                                                // title={league.name}
                                                bordered={false}
                                                hoverable
                                                style={{ marginBottom: 10 }}
                                                // extra={<a href="#">More</a>}
                                                key={index}
                                            >
                                                <Link to={`/leagues/${league.slug}`}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <div
                                                                style={{
                                                                    width: 50,
                                                                    height: 50,
                                                                }}
                                                            >
                                                                <Avatar
                                                                    size={50}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        backgroundColor: '#fff',
                                                                        border: '1px solid #ccc',
                                                                    }}
                                                                    src={league.avatar}
                                                                >
                                                                    <GlobalOutlined style={{ color: '#000', fontSize: 20 }} />
                                                                </Avatar>
                                                            </div>
                                                            <div style={{ marginLeft: 15 }}>
                                                                <Title level={4} style={{ margin: 0 }}>
                                                                    {league.name}
                                                                </Title>
                                                                <Text>{league.short_description}</Text>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {league.owner_id === authState && authState.user.id ? (
                                                                <Tag size="large" color="#f50">
                                                                    ORGANIZER
                                                                </Tag>
                                                            ) : league.owner_id === authState && authState.user.id ? (
                                                                <Tag color="#87d068">DIRECTOR</Tag>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Card>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        }}
                    </Query>
                </TabPane>
            </Tabs>
        </Modal>
    );
}

export default withRouter(VenueHostModal);
