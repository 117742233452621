import React, { useState, useEffect } from 'react';
import { Icon, Row, Col } from 'antd';
import Card from '../../components/Card';
import TableScoreCard from '../digitalpool/tournaments/TableScoreCard';

export default function TablesInUse(props) {
    const { tournament, data, showScoreModal, onTableSelected, setTableModalVisible } = props;

    return (
        <Row gutter={24} justify="center" type="flex">
            <Col xs={24}>
                <Row gutter={16} justify="start" type="flex">
                    {data
                        .sort((a, b) => {
                            return a.label.localeCompare(b.label, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            });
                        })
                        .map((table, index) => {
                            const match = table.tournament_match_table && table.tournament_match_table[0];
                            return (
                                <Col key={index} xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                                    <TableScoreCard
                                        {...props}
                                        title={match && match.match_number && `Match ${match.match_number} (${match.identifier})`}
                                        table={table}
                                        label={table.label}
                                        matchData={match}
                                        tournament={tournament}
                                        status={table.status}
                                        gameType={tournament.game_type}
                                        tableSize="large"
                                        onClick={() => {
                                            onTableSelected(table.slug);
                                            showScoreModal({
                                                title: `Match ${index + 1}`,
                                                slug: `match-${index + 1}`,
                                                status: 'live',
                                            });
                                        }}
                                        playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                                    />
                                </Col>
                            );
                        })}
                    {/* 
					<Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
						<TableScoreCard
							title="Match 11"
							status="completed"
							table={11}
							// tableSize="small"
							onClick={() =>
								showScoreModal({
									title: `Match 11`,
									slug: `match-11`,
									status: 'completed'
								})}
						/>
					</Col> */}
                </Row>
            </Col>
        </Row>
    );
}
