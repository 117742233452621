import React, { useState, useRef, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { message, Modal, Layout } from 'antd';
import Fullscreen from 'react-full-screen';
import clearRender from 'clear-render';
// import { Typography } from 'antd';
// import Card from '../../components/Card';
// import { Link } from 'react-router-dom';
// import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import { SizeMe } from 'react-sizeme';
import Viewer from '../../tournament-builder/Viewer';

import qs from 'query-string';
import _ from 'lodash';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';

const { confirm } = Modal;

function DPTournamentViewer(props) {
    const { tournament, client, theme, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const [playerSearchCoordinates, setPlayerSearchCoordinates] = useState({ x: 0, y: 0 });

    return (
        <SectionWrapper>
            <SectionContent padding="0px" className="bracket-wrapper">
                <Fullscreen enabled={isFullScreen} onChange={(isFull) => setIsFullScreen(isFull)}>
                    <div
                        id="bracket-container"
                        className="full-screenable-node"
                        style={{
                            position: 'relative',
                            overflow: 'auto',
                            width: '100%',
                            height: '100%',
                            // background: 'linear-gradient(180deg, #fff, #f0f2f5)'
                            // backgroundColor: '#eaedef'
                        }}
                    >
                        <Viewer
                            isPublic={true}
                            tournament={tournament}
                            isFullScreen={isFullScreen}
                            onFullScreen={(value) => setIsFullScreen(value)}
                            // setRoundTimes={false}
                            playerSearchCoordinates={playerSearchCoordinates}
                            onUpdateCoordinates={(coords) => {
                                setPlayerSearchCoordinates(coords);
                            }}
                            compact={false}
                            {...props}
                        />
                    </div>
                </Fullscreen>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(DPTournamentViewer);
