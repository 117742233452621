import eightBall from '../assets/img/ball/eight-ball.png';
import nineBall from '../assets/img/ball/nine-ball.png';
import tenBall from '../assets/img/ball/ten-ball.png';
import snookerBall from '../assets/img/ball/snooker-ball.png';
import defaultImg from '../assets/img/ball/default.png';
import defaultDarkImg from '../assets/img/ball/default-dark.png';
import onePocketImg from '../assets/img/ball/one-pocket.png';

export const getGameType = (type, theme) => {
    let currentTheme = theme && typeof theme === 'string' ? theme : typeof theme === 'object' ? theme.name : null;
    if (type) {
        const lower = type.toLowerCase();
        return lower.match(/(eight_ball|eight ball|8ball|8 ball|8-ball)/)
            ? eightBall
            : lower.match(/(nine_ball|nine ball|9ball|9 ball|9-ball)/)
            ? nineBall
            : lower.match(/(one_pocket|one pocket)/)
            ? onePocketImg
            : lower.match(/(ten_ball|ten ball|10ball|10 ball|10-ball)/)
            ? tenBall
            : lower.match(/(snooker)/)
            ? snookerBall
            : currentTheme && currentTheme === 'dark'
            ? defaultDarkImg
            : defaultImg;
    } else {
        return;
    }
};
