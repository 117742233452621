import React, { useState, useEffect, useRef } from 'react';
import { Table, Typography, Button, Icon, Alert, Badge, Modal, Input, Tooltip } from 'antd';
import Card from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import { getCountryName } from '../../../utils';
import { GET_PLAYER_MATCHES_QUERY, GET_USER_UNLINKED_MATCHES_QUERY, SEARCH_UNLINKED_TOURNAMENT_MATCHES_QUERY } from './data/queries';
import { withRouter, Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import { GlobalOutlined } from '@ant-design/icons';
import UnlinkedMatchesModal from '../../auth/profile/UnlinkedMatchesModal';
import ZeroState from '../../../components/ZeroState';
import slugify from 'slugify';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    tabPane: {
        padding: 20,
    },
};

export default function PlayerMatches(props) {
    const { authState, client, player, theme } = props;
    const [reloaded, setReloaded] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [unlinkedMatches, setUnlinkedMatches] = useState();
    const playerId = player.id;

    useEffect(() => {
        setUnlinkedMatches([]);
    }, [reloaded]);

    // useEffect(() => {
    //     async function fetchNewData() {
    //         setSearchLoading(true);
    //         const unlinkedMatchesSearchResults = await searchUnlinkedMatches(userDisplayName);
    //         const formattedResults = formatMatches(unlinkedMatchesSearchResults);
    //         console.log('unlinked search results', formattedResults);
    //         setUnlinkedMatches(formattedResults);
    //         setSearchLoading(false);
    //         setReloaded(false);
    //     }
    //     if (reloaded === true) {
    //         fetchNewData();
    //     }
    // }, [unlinkedMatches]);

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            {record.player && record.opponent && record.player.skill_level && record.opponent.skill_level ? (
                                <React.Fragment>
                                    {record.player.name} ({record.player.skill_level && record.player.skill_level}) vs. {record.opponent.name} (
                                    {record.opponent.skill_level && record.opponent.skill_level})
                                </React.Fragment>
                            ) : record.player && record.player.skill_level && record.opponent && !record.opponent.skill_level ? (
                                <React.Fragment>
                                    {record.player.name} ({record.player.skill_level && record.player.skill_level}) vs. {record.opponent.name}
                                </React.Fragment>
                            ) : record.player && !record.player.skill_level && record.opponent && record.opponent.skill_level ? (
                                <React.Fragment>
                                    {record.player && record.player.name} vs. {record.opponent && record.opponent.name} (
                                    {record.opponent.skill_level && record.opponent.skill_level})
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {record.player && record.player.name} vs. {record.opponent && record.opponent.name}
                                </React.Fragment>
                            )}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            <b>
                                {record.player && record.player.score} - {record.opponent && record.opponent.score}
                            </b>
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            sorter: (a, b) => a.result - b.result,
            render: (text, record) => {
                return (
                    <div>
                        <Text style={{ color: record.result === 'Win' ? 'green' : 'red' }}>
                            {record.result === 'Win' ? (
                                <React.Fragment>
                                    <Badge
                                        count="W"
                                        style={{
                                            backgroundColor: '#87d068',
                                            marginRight: 10,
                                        }}
                                    />
                                    Win
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Badge
                                        count={record.player && record.player.is_forfeit ? 'FF' : 'L'}
                                        style={{
                                            backgroundColor: '#f5222d',
                                            marginRight: 10,
                                        }}
                                    />
                                    Loss
                                </React.Fragment>
                            )}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Event',
            dataIndex: 'tournament_name',
            key: 'tournament_name',
            sorter: (a, b) => a.tournament_name - b.tournament_name,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => a.created_at - b.created_at,
            render: (text, record) => <div style={{ whiteSpace: 'nowrap' }}>{moment(text).format('ll') || '-'}</div>,
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <span style={{ whiteSpace: 'nowrap' }}>
        //             <Tooltip title="Edit">
        //                 <Button
        //                     type="ghost"
        //                     onClick={(e) => {
        //                         e.preventDefault();
        //                         e.stopPropagation();
        //                         // onEditSelected(record.id);
        //                         // props.history.push(
        //                         // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
        //                         // );
        //                     }}
        //                 >
        //                     <Icon type="edit" />
        //                 </Button>
        //             </Tooltip>
        //         </span>
        //     ),
        // },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name} from this tournament?`,
            content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                // onDelete(record);
            },
            onCancel() {},
        });
    }

    async function getUnlinkedMatches(playerName) {
        const res = await client.query({
            query: GET_USER_UNLINKED_MATCHES_QUERY,
            variables: {
                name: `%${playerName}%`,
            },
        });

        const matches = res && res.data && res.data.tournament_brackets;
        return matches;
    }

    async function searchUnlinkedMatches(playerName) {
        const res = await client.query({
            query: SEARCH_UNLINKED_TOURNAMENT_MATCHES_QUERY,
            variables: {
                search: playerName,
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        });

        const matches = res && res.data && res.data.search_unlinked_tournament_matches;
        const filtered = matches.filter((match) => {
            let player;
            if (match.challenger1 && match.challenger1.player_id === playerId) {
                player = match.challenger1;
            } else if (match.challenger2 && match.challenger2.player_id === playerId) {
                player = match.challenger2;
            }

            return !player;
        });
        return filtered;
    }

    function handleModalVisible(visibility) {
        setModalVisible(visibility);
    }

    function formatMatches(unlinkedMatchData) {
        let matches = [];
        const playerId = player.id;

        console.log('player', player);
        console.log(unlinkedMatchData);

        unlinkedMatchData.map((match) => {
            let playerData;
            let opponentData;
            let playerSlug;
            let opponentSlug;

            if (match.challenger1_name && match.challenger1_name.includes(player.name)) {
                playerSlug = slugify(match.challenger1_name, {
                    replacement: '-',
                    remove: /[*+~.,()'"#!:/@]/g,
                    lower: true,
                });

                opponentSlug = slugify(match.challenger2_name, {
                    replacement: '-',
                    remove: /[*+~.,()'"#!:/@]/g,
                    lower: true,
                });

                playerData = {
                    id: match.challenger1_id,
                    name: match.challenger1_name,
                    slug: playerSlug,
                    country: match.challenger1_country,
                    score: match.challenger1_score,
                    skill_level: match.challenger1_skill_level,
                    race_to: match.challenger1_race_to,
                    is_winner: match.challenger1_is_winner,
                    is_forfeit: match.challenger1_is_forfeit,
                    player_id: playerId,
                };
                opponentData = {
                    id: match.challenger2_id,
                    name: match.challenger2_name,
                    slug: opponentSlug,
                    country: match.challenger2_country,
                    score: match.challenger2_score,
                    skill_level: match.challenger2_skill_level,
                    race_to: match.challenger2_race_to,
                    is_winner: match.challenger2_is_winner,
                    is_forfeit: match.challenger2_is_forfeit,
                };
            } else if (match.challenger2_name && match.challenger2_name.includes(player.name)) {
                playerSlug = slugify(match.challenger2_name, {
                    replacement: '-',
                    remove: /[*+~.,()'"#!:/@]/g,
                    lower: true,
                });

                opponentSlug = slugify(match.challenger1_name, {
                    replacement: '-',
                    remove: /[*+~.,()'"#!:/@]/g,
                    lower: true,
                });

                playerData = {
                    id: match.challenger2_id,
                    name: match.challenger2_name,
                    slug: playerSlug,
                    country: match.challenger2_country,
                    score: match.challenger2_score,
                    skill_level: match.challenger2_skill_level,
                    race_to: match.challenger2_race_to,
                    is_winner: match.challenger2_is_winner,
                    is_forfeit: match.challenger2_is_forfeit,
                    player_id: playerId,
                };
                opponentData = {
                    id: match.challenger1_id,
                    name: match.challenger1_name,
                    slug: opponentSlug,
                    country: match.challenger1_country,
                    score: match.challenger1_score,
                    skill_level: match.challenger1_skill_level,
                    race_to: match.challenger1_race_to,
                    is_winner: match.challenger1_is_winner,
                    is_forfeit: match.challenger1_is_forfeit,
                };
            } else {
                let playerSlug;
                let opponentSlug;
                if (match.challenger1_name) {
                    playerSlug = slugify(match.challenger1_name, {
                        replacement: '-',
                        remove: /[*+~.,()'"#!:/@]/g,
                        lower: true,
                    });
                }

                if (match.challenger2_name) {
                    opponentSlug = slugify(match.challenger2_name, {
                        replacement: '-',
                        remove: /[*+~.,()'"#!:/@]/g,
                        lower: true,
                    });
                }

                playerData = {
                    id: match.challenger1_id,
                    name: match.challenger1_name,
                    slug: playerSlug,
                    country: match.challenger1_country,
                    score: match.challenger1_score,
                    skill_level: match.challenger1_skill_level,
                    race_to: match.challenger1_race_to,
                    is_winner: match.challenger1_is_winner,
                    is_forfeit: match.challenger1_is_forfeit,
                    player_id: playerId,
                };
                opponentData = {
                    id: match.challenger2_id,
                    name: match.challenger2_name,
                    slug: opponentSlug,
                    country: match.challenger2_country,
                    score: match.challenger2_score,
                    skill_level: match.challenger2_skill_level,
                    race_to: match.challenger2_race_to,
                    is_winner: match.challenger2_is_winner,
                    is_forfeit: match.challenger2_is_forfeit,
                };
            }

            if (player) {
                matches.push({
                    ...match,
                    player: playerData,
                    opponent: opponentData,
                    result:
                        playerData && playerData.name === player.name && playerData.is_winner === true
                            ? 'Win'
                            : playerData && playerData.name === player.name && playerData.is_winner === false
                            ? 'Loss'
                            : 'N/A',
                    tournament_name: match.tournament.name,
                    tournament_slug: match.tournament.slug,
                });
            }
        });

        return matches;
    }

    return (
        <React.Fragment>
            <Query
                query={GET_PLAYER_MATCHES_QUERY}
                fetchPolicy="cache-and-network"
                variables={{
                    // id: 51 // jason gladden
                    player_id: player.id,
                    // player_name: userDisplayName,
                }}
                notifyOnNetworkStatusChange
                awaitRefetchQueries
                onCompleted={async (data, loading, error, subscribeToMore) => {
                    if (authState && authState.user && (authState.user.id === player.user_id || authState.user.role === 'admin')) {
                        setSearchLoading(true);
                        const unlinkedMatchesSearchResults = await searchUnlinkedMatches(player.name);
                        const formattedResults = formatMatches(unlinkedMatchesSearchResults);
                        console.log('unlinked search results', formattedResults);
                        setUnlinkedMatches(formattedResults);
                        setSearchLoading(false);
                    }
                }}
            >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div style={styles.container}>
                                <CircularLoader />
                            </div>
                        );
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                    if (
                        ((data && !data.tournament_players) || (data && data.tournament_players.length === 0)) &&
                        unlinkedMatches &&
                        unlinkedMatches.length === 0
                    ) {
                        return (
                            <ZeroState
                                message="You have not played any matches yet."
                                messageStyle={{ fontSize: 16 }}
                                showImage={false}
                                style={{ margin: 10, textAlign: 'left' }}
                            />
                        );
                    }

                    const players = data.tournament_players;
                    let matches = [];

                    players &&
                        players.forEach((p) => {
                            p.tournament.tournament_brackets.map((match) => {
                                let player;
                                let opponent;
                                let playerSlug;
                                let opponentSlug;

                                if (match.challenger1 && match.challenger1.player_id === playerId) {
                                    if (match.challenger1_name) {
                                        playerSlug = slugify(match.challenger1_name, {
                                            replacement: '-',
                                            remove: /[*+~.,()'"#!:/@]/g,
                                            lower: true,
                                        });
                                    }

                                    if (match.challenger2_name) {
                                        opponentSlug = slugify(match.challenger2_name, {
                                            replacement: '-',
                                            remove: /[*+~.,()'"#!:/@]/g,
                                            lower: true,
                                        });
                                    }

                                    player = {
                                        id: match.challenger1_id,
                                        name: match.challenger1_name,
                                        slug: playerSlug,
                                        country: match.challenger1_country,
                                        score: match.challenger1_score,
                                        skill_level: match.challenger1_skill_level,
                                        race_to: match.challenger1_race_to,
                                        is_winner: match.challenger1_is_winner,
                                        is_forfeit: match.challenger1_is_forfeit,
                                        user_id: match.challenger1 && match.challenger1.user_id,
                                        player_id: match.challenger1 && match.challenger1.player_id,
                                    };
                                    opponent = {
                                        id: match.challenger2_id,
                                        name: match.challenger2_name,
                                        slug: opponentSlug,
                                        country: match.challenger2_country,
                                        score: match.challenger2_score,
                                        skill_level: match.challenger2_skill_level,
                                        race_to: match.challenger2_race_to,
                                        is_winner: match.challenger2_is_winner,
                                        is_forfeit: match.challenger2_is_forfeit,
                                        user_id: match.challenger2 && match.challenger2.user_id,
                                        player_id: match.challenger2 && match.challenger2.player_id,
                                    };
                                } else if (match.challenger2.player_id === playerId) {
                                    playerSlug = slugify(match.challenger2_name, {
                                        replacement: '-',
                                        remove: /[*+~.,()'"#!:/@]/g,
                                        lower: true,
                                    });

                                    opponentSlug = slugify(match.challenger1_name, {
                                        replacement: '-',
                                        remove: /[*+~.,()'"#!:/@]/g,
                                        lower: true,
                                    });

                                    player = {
                                        id: match.challenger2_id,
                                        name: match.challenger2_name,
                                        slug: playerSlug,
                                        country: match.challenger2_country,
                                        score: match.challenger2_score,
                                        skill_level: match.challenger2_skill_level,
                                        race_to: match.challenger2_race_to,
                                        is_winner: match.challenger2_is_winner,
                                        is_forfeit: match.challenger2_is_forfeit,
                                        user_id: match.challenger2 && match.challenger2.user_id,
                                        player_id: match.challenger2 && match.challenger2.player_id,
                                    };
                                    opponent = {
                                        id: match.challenger1_id,
                                        name: match.challenger1_name,
                                        slug: opponentSlug,
                                        country: match.challenger1_country,
                                        score: match.challenger1_score,
                                        skill_level: match.challenger1_skill_level,
                                        race_to: match.challenger1_race_to,
                                        is_winner: match.challenger1_is_winner,
                                        is_forfeit: match.challenger1_is_forfeit,
                                        user_id: match.challenger1 && match.challenger1.user_id,
                                        player_id: match.challenger1 && match.challenger1.player_id,
                                    };
                                }

                                matches.push({
                                    ...match,
                                    player: player,
                                    opponent: opponent,
                                    result:
                                        player && player.player_id === playerId && player.is_winner === true
                                            ? 'Win'
                                            : player && player.player_id === playerId && player.is_winner === false
                                            ? 'Loss'
                                            : 'N/A',
                                    tournament_name: p.tournament.name,
                                    tournament_slug: p.tournament.slug,
                                });
                            });
                        });

                    matches = matches.sort((a, b) => {
                        const createdAtA = moment(a.created_at).valueOf();
                        const createdAtB = moment(b.created_at).valueOf();
                        return createdAtB - createdAtA;
                    });

                    if (!matches) {
                        return (
                            <div style={styles.container}>
                                <CircularLoader />
                            </div>
                        );
                    }

                    return (
                        <React.Fragment>
                            {authState && authState.user && (authState.user.id === player.user_id || authState.user.role === 'admin') && (
                                <React.Fragment>
                                    {searchLoading === true ? (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                            <CircularLoader /> <Text style={{ marginLeft: 10 }}>Searching for unlinked matches...</Text>
                                        </div>
                                    ) : (
                                        searchLoading === false &&
                                        unlinkedMatches &&
                                        unlinkedMatches.length > 0 && (
                                            <div style={{ marginBottom: 10 }}>
                                                <Alert
                                                    message={`${unlinkedMatches.length} Unlinked matches found`}
                                                    description={
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Text>
                                                                There are {unlinkedMatches.length} additional matches that haven't been linked to this player's
                                                                account.
                                                            </Text>
                                                            <Button
                                                                type="ghost"
                                                                ghost
                                                                onClick={() => {
                                                                    handleModalVisible(true);
                                                                }}
                                                            >
                                                                Review matches
                                                            </Button>
                                                        </div>
                                                    }
                                                    type="info"
                                                    closable={false}
                                                    showIcon
                                                />
                                            </div>
                                        )
                                    )}
                                </React.Fragment>
                            )}

                            <Table
                                className="player-table no-hover"
                                // rowKey="id"
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: (event) => {
                                            // onEditSelected(record.id);
                                            console.log(`/tournaments/${record.tournament_slug}/players/${record.player.slug}`);
                                            props.history.push({
                                                pathname: `/tournaments/${record.tournament_slug}/players/${record.player.slug}`,
                                                // search: '?query=abc',
                                                state: {
                                                    data: record,
                                                    backPath: `/users/${record.player.slug}`,
                                                },
                                            });
                                        },
                                        onDoubleClick: (event) => {}, // double click row
                                        onContextMenu: (event) => {}, // right button click row
                                        onMouseEnter: (event) => {}, // mouse enter row
                                        onMouseLeave: (event) => {}, // mouse leave row
                                    };
                                }}
                                columns={columns}
                                dataSource={
                                    matches &&
                                    matches.map((match, index) => {
                                        return {
                                            key: index,
                                            index: index + 1,
                                            ...match,
                                        };
                                    })
                                }
                                onChange={onChange}
                                pagination={false}
                                tableLayout="auto"
                                // className="noselect"
                                scroll={{ x: 400 }}
                                // pagination={{ pageSize: 25 }}
                                size="small"
                                // bordered
                            />
                        </React.Fragment>
                    );
                }}
            </Query>
            <UnlinkedMatchesModal
                playerId={playerId}
                matches={unlinkedMatches}
                modalVisible={modalVisible}
                onModalOk={() => {
                    handleModalVisible(false);
                }}
                onLinked={async () => {
                    handleModalVisible(false);
                    setReloaded(true);
                    setSearchLoading(true);
                    const unlinkedMatchesSearchResults = await searchUnlinkedMatches(player.name);
                    const formattedResults = formatMatches(unlinkedMatchesSearchResults);
                    console.log('unlinked search results 2', formattedResults);
                    setUnlinkedMatches(formattedResults);
                    setSearchLoading(false);
                }}
                onModalCancel={() => {
                    handleModalVisible(false);
                }}
                onPlayerModalVisible={handleModalVisible}
                {...props}
            />
        </React.Fragment>
    );
}
