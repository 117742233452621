import React, { useState, useEffect, useRef } from 'react';
import { message, Icon, Tooltip, Button } from 'antd';
import pluralize from 'pluralize';
import PostShareModal from './PostShareModal';
import { FacebookShareCount } from 'react-share';

export default function FeedShareButton(props) {
    const { authState, user, post } = props;
    const [shares, setShares] = useState(
        (post.post_shares_aggregate && post.post_shares_aggregate.aggregate && post.post_shares_aggregate.aggregate.count) || 0
    );
    const shareUrl = `${window.location.href}/feed/${post.id}`;
    const [shareModalVisible, setShareModalVisible] = useState(false);

    const showShareModal = () => {
        setShareModalVisible(true);
    };

    const handleShareModalOk = () => {
        setShareModalVisible(false);
    };

    const handleShareModalCancel = () => {
        setShareModalVisible(false);
    };

    return (
        <React.Fragment>
            <Tooltip title="Share">
                <Button
                    type="ghost"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                        showShareModal();
                    }}
                >
                    <Icon type="share-alt" theme={'outlined'} style={{ fontSize: 18 }} />
                </Button>
            </Tooltip>
            <FacebookShareCount url={shareUrl}>
                {(shareCount) => (
                    <span style={{ cursor: 'auto' }}>
                        {shareCount} {pluralize('Share', shareCount)} 0
                    </span>
                )}
            </FacebookShareCount>
            <PostShareModal
                shareUrl={shareUrl}
                user={user}
                post={post}
                modalVisible={shareModalVisible}
                onOk={handleShareModalOk}
                onCancel={handleShareModalCancel}
            />
        </React.Fragment>
    );
}
