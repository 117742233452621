import React from 'react';
import { Icon, Row, Col, Typography, Button, Tabs } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import rackImg from '../../assets/img/rack-bg.jpg';
import digitalpoolApp from '../../assets/img/dpl/dpl-app-scoring.png';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		paddingTop: 24,
		paddingLeft: 24,
		paddingRight: 24,
		paddingBottom: 24
	}
};

export default function DPLMain(props) {
	return (
		<div style={{ position: 'relative' }}>
			{/* <SectionHeader
				dpl={true}
				compact={true}
				theme="dark"
				background={false}
				title="Digital Pool League"
				subtitle="Play what you want, when you want, where you want!"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '24px 40px',
					backgroundColor: '#000',
					position: 'relative',
					zIndex: 4
				}}
			>
				<Link to="/register">
					<Button type="primary" size="large">
						Join The DPL
						<Icon type="arrow-right" />
					</Button>
				</Link>
			</SectionHeader> */}

			<div
				style={{
					marginTop: -50,
					height: 600,
					overflow: 'hidden'
				}}
			>
				<img src={rackImg} alt="Rack" width={'100%'} />
			</div>

			<SectionContent padding="20px 40px">
				<Row gutter={16} justify="center" type="flex">
					<Col xs={24} sm={24} md={24} lg={8}>
						<Card title="How to play in the DPL" bodyStyle={{ padding: 0 }}>
							<Tabs
								defaultActiveKey="1"
								animated={false}
								tabBarStyle={{
									textTransform: 'uppercase'
								}}
							>
								<TabPane tab="Individuals" key="1" style={styles.tab}>
									<div>
										<ol style={{ margin: 0, paddingLeft: 8 }}>
											<li>
												<b>Download</b> the Digital Pool mobile app.
											</li>
											<li>
												<b>Fill out</b> your player profile
											</li>
											<li>
												<b>Join or start</b> a division in your local area.
											</li>
											<li>
												<b>Challenge anyone</b> in your division to play a match!
											</li>
											<li>
												<b>Schedule</b> a time and place to play your opponent.
											</li>
											<li>
												<b>Pay your fees</b> through the app before you play.
											</li>
											<li>
												<b>Play your match</b> and verify your score using the app.
											</li>
											<li>
												<b>Repeat</b> until the session has ended.
											</li>
											<li>
												<b>Collect</b> your winnings.
											</li>
										</ol>
									</div>
								</TabPane>
								<TabPane tab="Teams" key="2" style={styles.tab}>
									<div>
										<ol style={{ margin: 0, paddingLeft: 8 }}>
											<li>
												<b>Download</b> the Digital Pool mobile app.
											</li>
											<li>
												<b>Fill out</b> your player profile.
											</li>
											<li>
												<b>Join or start</b> a division in your local area.
											</li>
											<li>
												<b>Join or start</b> a team and recruit enough players.
											</li>
											<li>
												<b>Wait</b> for the match schedule to be released.
											</li>
											<li>
												<b>Pay your fees</b> through the app before you play.
											</li>
											<li>
												<b>Play your match</b> and verify your score using the app.
											</li>
											<li>
												<b>Repeat</b> until the session has ended.
											</li>
											<li>
												<b>Collect</b> your winnings.
											</li>
										</ol>
									</div>
								</TabPane>
							</Tabs>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8}>
						<Card title="DigitalPool mobile app">
							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: 20 }}>
									<img src={digitalpoolApp} height={180} alt="Digital Pool App" />
								</div>
								<div>
									<div style={{ marginBottom: 16 }}>
										<Title level={4}>
											Check out our free{' '}
											<a href="#" target="_blank" rel="noopener noreferrer">
												Digital Pool App
											</a>{' '}
											for the best DPL experience while on the go.
										</Title>
										<Text>Live scoring and stats, tournaments, player chat, and much more!</Text>
									</div>
									<Button type="primary">
										<Icon type="apple" /> iOS
									</Button>
									<Button type="primary">
										<Icon type="android" /> Android
									</Button>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8}>
						<Card title="Everything else you need to know">
							<div style={{ marginBottom: 16 }}>
								<Title level={4}>Have more questions? </Title>
								<Text>
									Check out our League Overview, Rules and Frequently Asked Questions for everything
									you need to know about playing in the DigitalPool League.
								</Text>
							</div>
							<Button type="primary">League Overview</Button>
							<Button>Rules</Button>
							<Button>FAQ</Button>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}
