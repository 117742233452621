import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Tabs, Descriptions, Divider, Statistic, Row, Avatar, Button, Empty, Icon, List, Skeleton, Col, Typography } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import useBreakpoint from 'use-breakpoint';
import * as routes from '../../../config/routes';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import verifiedIcon from '../../../assets/img/verified-icon.svg';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import apiEndPoint from '../../../utils/apiEndpoint';
import { GET_PLAYER_BY_SLUG } from './data/queries';
import ProfileTabs from '../../auth/profile/ProfileTabs';
import { Query } from '@apollo/client/react/components';
import _ from 'lodash';
import PlayerTournaments from './PlayerTournaments';
import PlayerLeagues from './PlayerLeagues';
import PlayerStats from './PlayerStats';
import PlayerMatches from './PlayerMatches';
import { UserOutlined, EditOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

function PlayerDetail(props) {
    const { authState, history, match } = props;
    const [current, setCurrent] = useState('feed');
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [fargoData, setFargoData] = useState();
    const [fargoLoading, setFargoLoading] = useState(false);
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const playerSlug = match && match.params && match.params.slug && isNaN(match.params.slug) === true ? match.params.slug : null;
    const tabs = ['profile', 'about', 'feed', 'videos', 'lessons', 'shop', 'sponsors', 'admin'];

    const styles = {
        headerImg: {
            height: 120,
            // overflow: 'hidden',
            borderRadius: 0,
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
        },
        container: {
            padding: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // height: '100%'
        },
        tabPane: {
            padding: 20,
        },
    };

    const renderProfile = (player) => (
        <Row gutter={24} justify="center" type="flex">
            {/* <Row gutter={24} justify="center" type="flex" style={{ position: 'relative', marginTop: props.navType === 'desktop' ? -90 : -115 }}> */}
            <Col xs={22} sm={props.navType === 'desktop' ? 24 : 22} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} sm={18}>
                        <Card>
                            <Row gutter={24} justify="center" type="flex">
                                <Col
                                    xs={24}
                                    sm={8}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div className="gradient-border">
                                        <Avatar
                                            size={150}
                                            src={
                                                (player && player.image_large) ||
                                                (player && player.image_small) ||
                                                (player && player.user && player.user.avatar) ||
                                                (player && player.user && player.user.photo_url)
                                            }
                                        >
                                            <UserOutlined
                                                style={{
                                                    fontSize: 50,
                                                    lineHeight: '160px',
                                                    color: '#000',
                                                }}
                                            />
                                        </Avatar>
                                    </div>
                                </Col>
                                <Col xs={24} sm={16}>
                                    <div style={{ marginBottom: 20, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div>
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {player.country && <CircleFlag country={player.country} left={0} />}
                                                    <Title level={3} style={{ marginBottom: 0 }}>
                                                        {player.name}
                                                    </Title>
                                                    <div style={{ marginLeft: 6 }}>
                                                        <img src={verifiedIcon} height={25} alt="Verified User" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                {player.country && player.city && player.region && (
                                                    <div>
                                                        {player.city}, {player.region}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {player && player.user_id && player.user && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Link to={`/users/${player.user.slug}`}>
                                                    <Button type="secondary">
                                                        <Icon type="eye" /> View User Profile
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                    {/* <Divider style={{ marginTop: 20, marginBottom: 10 }} /> */}
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            {fargoLoading ? (
                                                <Statistic title="Fargo Rating" value={'Loading...'} />
                                            ) : (
                                                <React.Fragment>
                                                    <Statistic
                                                        title="Fargo Rating"
                                                        value={(fargoData && fargoData.effectiveRating) || (fargoData && fargoData.rating) || 'N/A'}
                                                    />
                                                    {fargoData && fargoData.robustness && (
                                                        <Text type="secondary">Robustness: {fargoData && fargoData.robustness}</Text>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="Followers" value="N/A" />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="Following" value="N/A" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} sm={18}>
                        <Card bodyStyle={{ padding: 0 }}>
                            <Tabs
                                // onTabClick={handleTabClick}

                                animated={{ inkBar: true, tabPane: false }}
                                tabBarStyle={{
                                    textTransform: 'uppercase',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                                defaultActiveKey="tournaments"
                                tabBarGutter={10}
                                className="tabs-bg2"
                            >
                                {/* <TabPane tab="Feed" key="1" style={styles.tabPane}>
                                Content of Tab Pane 1
                            </TabPane> */}
                                <TabPane
                                    tab={`Tournaments (${
                                        (player &&
                                            player.tournament_players_aggregate &&
                                            player.tournament_players_aggregate.aggregate &&
                                            player.tournament_players_aggregate.aggregate.count) ||
                                        0
                                    })`}
                                    key="tournaments"
                                    style={styles.tabPane}
                                >
                                    <PlayerTournaments player={player} {...props} />
                                </TabPane>
                                <TabPane
                                    tab={`Leagues (${
                                        (player &&
                                            player.league_players_aggregate &&
                                            player.league_players_aggregate.aggregate &&
                                            player.league_players_aggregate.aggregate.count) ||
                                        0
                                    })`}
                                    key="leagues"
                                    style={styles.tabPane}
                                >
                                    <PlayerLeagues player={player} {...props} />
                                </TabPane>
                                <TabPane tab="Statistics" key="stats" style={styles.tabPane}>
                                    <PlayerStats player={player} {...props} />
                                </TabPane>
                                <TabPane tab="Match history" key="matches" style={styles.tabPane}>
                                    <PlayerMatches player={player} {...props} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

    function getFargoRatingByNameUpdated(player) {
        if (player) {
            console.log(player);
            setFargoLoading(true);
            let url;
            if (player.display_name) {
                url = `https://api.fargorate.com/search?search=${player.display_name.toLowerCase()}`;
            } else {
                const formattedName = player.name.toLowerCase();
                const nameSplit = formattedName.split(' ');
                const firstName = nameSplit[0];
                const lastName = nameSplit[1];

                if (nameSplit && nameSplit.length > 0) {
                    url = `https://api.fargorate.com/search?search=firstName:${firstName} AND lastName:${lastName}`;
                } else {
                    url = `https://api.fargorate.com/search?search=${formattedName}`;
                }
            }

            return axios
                .post(`${apiEndPoint}getFargoRating`, {
                    url: url,
                    player: player,
                })
                .then((res) => {
                    console.log(res);
                    setFargoLoading(false);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    setFargoLoading(false);
                    return error;
                });
        }
    }

    async function getFargoRatingByUUId(user) {
        if (user) {
            console.log(user);
            setFargoLoading(true);
            let url = `https://api.fargorate.com/thirdparty/refresh/${user.uuid}`;

            return axios
                .post(`${apiEndPoint}refreshFargoRating`, {
                    url: url,
                    player: user,
                })
                .then((res) => {
                    console.log(res);
                    setFargoLoading(false);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    setFargoLoading(false);
                    return error;
                });
        }
    }

    return (
        <SectionWrapper>
            {/* <div style={styles.headerImg} /> */}
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_PLAYER_BY_SLUG}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: playerSlug }}
                    onCompleted={async (data) => {
                        // console.log(data);
                        const player = data.players[0];
                        console.log(player);
                        let fargo;
                        if (player && player.user && player.user.uuid) {
                            fargo = await getFargoRatingByUUId({
                                ...player,
                                uuid: player.user.uuid,
                            });
                        } else {
                            fargo = await getFargoRatingByNameUpdated(player);
                        }
                        // fargo = await getFargoRatingByID(user.fargo_id);
                        if (fargo && fargo.length > 0) {
                            setFargoData(fargo[0]);
                        } else {
                            setFargoData(fargo);
                        }
                        // getFargoProfileById(fargo.id);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if (data && !data.players) {
                            return <div style={styles.container}>Player not found.</div>;
                        }
                        if (data && data.players.length === 0) {
                            return <div style={styles.container}>Player not found.</div>;
                        }
                        const player = data.players[0];
                        console.log('player', player);

                        return (
                            <React.Fragment>
                                <Helmet>
                                    <title>{player.name} - Feed | DigitalPool</title>
                                    <meta name="description" content={`Social feeds for ${player.name}`} />
                                    <meta name="twitter:card" content="summary_large_image" />
                                    <meta name="twitter:title" content={`${player.name} - Feed | DigitalPool`} />
                                    <meta name="twitter:description" content={`Social feeds for ${player.name}`} />
                                    <meta name="twitter:image" content={player.avatar || 'https://digitalpool.com/images/fb-image.png'} />
                                    <meta property="og:title" content={`${player.name} - Feed | DigitalPool`} />
                                    <meta property="og:description" content={`Social feeds for ${player.name}`} />
                                    <meta property="og:image" content={player.avatar || 'https://digitalpool.com/images/fb-image.png'} />
                                    <meta property="og:url" content={`https://www.digitalpool.com/${player.name_slug}`} />
                                    <meta property="og:site_name" content="digitalpool.com" />
                                    <meta property="og:locale" content="en_US" />
                                    <meta property="og:type" content="website" />
                                    <meta property="fb:app_id" content="1697957547011275" />
                                </Helmet>
                                {renderProfile(player)}
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(PlayerDetail);
