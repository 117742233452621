import React, { useState, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { message, Menu, Icon, Switch, Row, Col, Typography, Button, Modal, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import * as routes from '../../config/routes';
import { useMutation, useQuery } from '@apollo/react-hooks';
import LMBasicSettings from './settings/LMBasicSettings';
import LMLogoAvatarSettings from './settings/LMLogoAvatarSettings';
import LMContactSettings from './settings/LMContactSettings';
import LMOrganizationSettings from './settings/LMOrganizationSettings';
import LMNotificationSettings from './settings/LMNotificationSettings';
import { DELETE_LEAGUE_MUTATION } from './data/mutations';

const { Text, Title } = Typography;
const { SubMenu } = Menu;
const { confirm } = Modal;

function LMSettings(props) {
    const { league, match, authState, theme } = props;
    const url = props.location.pathname.split('/');
    const currentPath = url[url.length - 1];
    const [current, setCurrent] = useState(currentPath !== 'settings' ? currentPath : 'basic');
    const [deleteLeague, { loading, error }] = useMutation(DELETE_LEAGUE_MUTATION);

    useEffect(() => {
        setCurrent(currentPath);
    }, [currentPath]);

    const handleClick = (e) => {
        // console.log(props);
        // console.log('click ', e);
        setCurrent(e.key);
        props.history.push(`/league-manager/${props.match.params.slug}/settings/${e.key}`);
    };

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name}?`,
            content:
                "Note: This won't permanently delete the record in case you change your mind later, but it will be hidden from all public pages and search results.",
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteLeague({
                    variables: {
                        id: record.id,
                        owner_id: authState.user.id,
                    },
                    // awaitRefetchQueries: true,
                    // refetchQueries: [
                    // 	{
                    // 		query: GET_TOURNAMENT_PLAYERS_QUERY,
                    // 		variables: { tournament_id: tournament.id }
                    // 	},
                    // 	{
                    // 		query: GET_TOURNAMENT_QUERY,
                    // 		variables: { id: tournament.id }
                    // 	}
                    // ]
                })
                    .then((data) => {
                        console.log(data);
                        message.success('League deleted');
                        props.history.push(`/league-manager`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                        {/* <Icon type="arrow-right" /> */}
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        League Settings
                                    </Title>
                                    {league.deleted_at ? (
                                        <Button onClick={() => console.log('restore')}>
                                            <Icon type="reload" /> Restore League
                                        </Button>
                                    ) : (
                                        <Button type="danger" ghost onClick={() => showConfirmDelete(league)}>
                                            <Icon type="delete" /> Delete League
                                        </Button>
                                    )}
                                </div>
                            }
                            bodyStyle={{ padding: 0, display: 'flex' }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Menu
                                    onClick={handleClick}
                                    style={{
                                        flex: 1,
                                        marginTop: 1,
                                        width: 224,
                                        borderRight: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid #e8e8e8',
                                    }}
                                    defaultSelectedKeys={[current]}
                                    // defaultOpenKeys={[ 'sub1' ]}
                                    mode="inline"
                                    theme="light"
                                >
                                    <Menu.Item key="basic">
                                        <Icon type="setting" />
                                        Basic Settings
                                    </Menu.Item>
                                    <Menu.Item key="contact">
                                        <Icon type="mail" />
                                        Contact Information
                                    </Menu.Item>
                                    <Menu.Item key="organization">
                                        <Icon type="bank" />
                                        Organization Information
                                    </Menu.Item>
                                    <Menu.Item key="notifications">
                                        <Icon type="bell" />
                                        Notifications
                                    </Menu.Item>
                                    <Menu.Item key="avatar">
                                        <i className="icon ion-md-image" style={{ fontSize: 18, marginRight: 10 }} />
                                        Logos & Avatars
                                    </Menu.Item>
                                    {/* <Menu.Divider />
									<Menu.Item>
										<Icon type="delete" />
										Delete League
									</Menu.Item> */}
                                    {/* <SubMenu
										key="sub1"
										title={
											<span>
												<Icon type="appstore" />
												<span>Navigation Three</span>
											</span>
										}
									>
										<Menu.Item key="3">Basic</Menu.Item>
										<Menu.Item key="4">Option 4</Menu.Item>
										<SubMenu key="sub1-2" title="Submenu">
											<Menu.Item key="5">Option 5</Menu.Item>
											<Menu.Item key="6">Option 6</Menu.Item>
										</SubMenu>
									</SubMenu>
									<SubMenu
										key="sub2"
										title={
											<span>
												<Icon type="setting" />
												<span>Navigation Four</span>
											</span>
										}
									>
										<Menu.Item key="7">Option 7</Menu.Item>
										<Menu.Item key="8">Option 8</Menu.Item>
										<Menu.Item key="9">Option 9</Menu.Item>
										<Menu.Item key="10">Option 10</Menu.Item>
									</SubMenu> */}
                                </Menu>
                            </div>
                            <div
                                style={{
                                    height: '100%',
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    width: '100%',
                                }}
                            >
                                <Route
                                    exact
                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS}
                                    render={() => <LMBasicSettings {...props} title="Basic Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS_BASIC}
                                    render={() => <LMBasicSettings {...props} title="Basic Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS_ORGANIZATION}
                                    render={() => <LMOrganizationSettings {...props} title="Organization Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS_CONTACT}
                                    render={() => <LMContactSettings {...props} title="Contact Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS_NOTIFICATIONS}
                                    render={() => <LMNotificationSettings {...props} title="Notification Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS_AVATAR}
                                    render={() => <LMLogoAvatarSettings {...props} title="Upload Logo & Avatar" />}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(LMSettings);
