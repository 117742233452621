import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Avatar, Layout, Input, List, Tabs, Empty, Skeleton } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import TournamentTable from './TournamentTable';
import ZeroState from '../../components/ZeroState';
import TournamentTypeModal from './TournamentTypeModal';
import { Subscription, Query } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { GET_USER_TOURNAMENTS_QUERY } from './data/queries';
import { DELETE_TOURNAMENT_MUTATION } from './data/mutations';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Search } = Input;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function TBMain(props) {
    console.log('tb main', props);
    const [totalTournaments, setTotalTournaments] = useState(0);
    const [deleteTournament, { loading, error }] = useMutation(DELETE_TOURNAMENT_MUTATION);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [tournamentTypeModalVisible, setTournamentTypeModalVisible] = useState(false);

    function callback(key) {
        console.log(key);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                }}
                height={120}
                subtitle="Everything you need to run world-class pool tournaments"
                subtitleSuffix={true}
                subtitleStyle={{ color: '#fff' }}
                theme="tournamentBuilder"
            >
                {breakpoint === 'tablet' && (
                    <Link to="/tournament-builder/new">
                        <Button size="large" type="ghost" ghost>
                            Create Tournament
                            <Icon type="arrow-right" />
                        </Button>
                    </Link>
                )}
            </SectionHeader>

            <SectionContent padding="20px 40px 100px 40px">
                <Row gutter={16} style={breakpoint === 'tablet' ? styles.row : null}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card title="Create a new tournament">
                            <div style={{ marginBottom: 16 }}>
                                <Text>
                                    Interested in running a tournament in local your area? Check out our{' '}
                                    <a
                                        href="https://docs.google.com/document/d/1Ms96hmsy_R0_qPTuV6-lG-MhcZfgNcNnLsWX_0hpMvE/edit?usp=sharing"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Getting Started Guide
                                    </a>
                                    !
                                </Text>
                            </div>
                            <Link to="/tournament-builder/new">
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        setTournamentTypeModalVisible(true);
                                    }}
                                >
                                    Create Tournament
                                </Button>
                            </Link>
                            {/* <Button
                                size="large"
                                type="primary"
                                onClick={() => {
                                    setTournamentTypeModalVisible(true);
                                }}
                            >
                                Create Tournament
                            </Button> */}

                            <Button
                                size="large"
                                onClick={() => {
                                    window.open('https://docs.google.com/document/d/1Ms96hmsy_R0_qPTuV6-lG-MhcZfgNcNnLsWX_0hpMvE/edit?usp=sharing');
                                }}
                                style={{ marginLeft: 10 }}
                            >
                                Getting Started Guide
                            </Button>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card title="Try it out">
                            <div style={{ marginBottom: 16 }}>
                                <Text>Want to see how Tournament Builder works? Check out the sample demo tournament.</Text>
                            </div>
                            <Link to="/tournament-builder/test-tournament-1">
                                <Button size="large" type="primary">
                                    View Demo
                                </Button>
                            </Link>
                        </Card>
                        {/* <Card title="Everything you need to know">
							<div style={{ marginBottom: 16 }}>
								<Text>
									Have questions? We have answers! Check out our Tournament How-to Guide and FAQs.
								</Text>
							</div>
							<Button>How-To Guide</Button>
							<Button>FAQ</Button>
						</Card> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card title={`My Tournaments (${totalTournaments})`} style={styles.card} bodyStyle={{ padding: 0 }} headStyle={styles.cardHeader}>
                            <Query
                                query={GET_USER_TOURNAMENTS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{
                                    manager_id: props.authState.user.id,
                                }}
                                onCompleted={async (data) => {
                                    console.log(data);
                                    setTotalTournaments(data.tournaments.length);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    return (
                                        <React.Fragment>
                                            {error && <div>Error: {error.message}</div>}
                                            {!loading && data && data.tournaments && data.tournaments.length > 0 ? (
                                                <div className="table_nowrap">
                                                    <TournamentTable
                                                        {...props}
                                                        onDelete={(record) => {
                                                            deleteTournament({
                                                                variables: {
                                                                    tournament_id: record.id,
                                                                },
                                                                notifyOnNetworkStatusChange: true,
                                                                awaitRefetchQueries: true,
                                                                refetchQueries: [
                                                                    {
                                                                        query: GET_USER_TOURNAMENTS_QUERY,
                                                                        variables: {
                                                                            manager_id: props.authState.user.id,
                                                                        },
                                                                    },
                                                                ],
                                                            })
                                                                .then((data) => {
                                                                    console.log(data);
                                                                    message.success(`Tournament ${record.id} deleted`);
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    message.info('There was an error', error);
                                                                });
                                                        }}
                                                        tournaments={data.tournaments.map((item, index) => {
                                                            return {
                                                                ...item,
                                                                key: index,
                                                                progress: item.progress || 0,
                                                                is_public: item.is_public === true ? 'Public' : 'Private',
                                                                tournament_type: _.startCase(_.toLower(item.tournament_type.replace('_', ' '))),
                                                                game_type: _.startCase(_.toLower(item.game_type.replace('_', ' '))),
                                                            };
                                                        })}
                                                    />
                                                    {/* <List
													className="hover-list"
													size="small"
													loading={loading}
													itemLayout="horizontal"
													dataSource={data.tournaments}
													renderItem={(item) => (
														<List.Item
															// actions={[ <a key="list-loadmore-edit">View</a> ]}
															style={{ padding: '10px 16px' }}
															onClick={() =>
																props.history.push(`/tournament-builder/${item.slug}`)}
														>
															<Skeleton avatar title={false} loading={loading} active>
																<List.Item.Meta
																	avatar={
																		<Avatar
																			style={{
																				backgroundColor: 'transparent',
																				color: '#000'
																			}}
																			icon="global"
																		/>
																	}
																	title={item.name}
																	description={item.short_description}
																/>
															</Skeleton>
														</List.Item>
													)}
												/> */}
                                                </div>
                                            ) : (
                                                <ZeroState
                                                    message="Your tournaments will appear here."
                                                    messageStyle={{ fontSize: 16 }}
                                                    showImage={false}
                                                    style={{ margin: 25, textAlign: 'left' }}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                }}
                            </Query>
                        </Card>
                        {/* <Card bodyStyle={{ padding: 0 }}>
							<Tabs animated={false} defaultActiveKey="1" onChange={callback}>
								<TabPane tab="My Tournaments" key="1" style={styles.tab}>
									<Query
										query={GET_USER_TOURNAMENTS_QUERY}
										fetchPolicy="cache-and-network"
										notifyOnNetworkStatusChange={true}
										variables={{ userId: props.authState.user.id }}
										onCompleted={async (data) => {
											console.log(data);
										}}
									>
										{({ loading, error, data }) => {
											return (
												<React.Fragment>
													{error && <div>Error: {error.message}</div>}

													{!loading &&
													data &&
													data.tournaments &&
													data.tournaments.length > 0 ? (
														<List
															className="hover-list"
															size="small"
															loading={loading}
															itemLayout="horizontal"
															dataSource={data.tournaments}
															renderItem={(item) => (
																<List.Item
																	// actions={[ <a key="list-loadmore-edit">View</a> ]}
																	style={{ padding: '10px 16px' }}
																	onClick={() =>
																		props.history.push(
																			`/tournament-builder/${item.slug}`
																		)}
																>
																	<Skeleton
																		avatar
																		title={false}
																		loading={loading}
																		active
																	>
																		<List.Item.Meta
																			avatar={
																				<Avatar
																					style={{
																						backgroundColor: 'transparent',
																						color: '#000'
																					}}
																					icon="global"
																				/>
																			}
																			title={item.name}
																			description={item.short_description}
																		/>
																	</Skeleton>
																</List.Item>
															)}
														/>
													) : (
														<Empty
															image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
															imageStyle={{
																height: 100
															}}
															description={
																<Text style={{ fontSize: 18 }}>
																	You have not played in any tournaments yet.
																</Text>
															}
															style={{ margin: 10 }}
														>
															<Button>Create Tournament</Button>
															<Button>View All Tournaments</Button>
														</Empty>
													)}
												</React.Fragment>
											);
										}}
									</Query>
								</TabPane>
								<TabPane tab="All Tournaments" key="2" style={{ paddingLeft: 24, paddingRight: 24 }}>
									<Search
										placeholder="Search tournaments"
										onSearch={(value) => console.log(value)}
										size="large"
										style={{ maxWidth: 300, marginTop: 16, marginBottom: 16 }}
									/>
									<TournamentTable />
								</TabPane>
							</Tabs>
						</Card> */}
                    </Col>
                </Row>
                <TournamentTypeModal
                    modalVisible={tournamentTypeModalVisible}
                    onOk={() => {
                        setTournamentTypeModalVisible(false);
                    }}
                    onCancel={() => {
                        setTournamentTypeModalVisible(false);
                    }}
                />
            </SectionContent>
        </SectionWrapper>
    );
}

export default TBMain;
