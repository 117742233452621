import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Result, Carousel, Avatar, Alert, Icon, Skeleton, Progress, List, Button, Statistic, Row, Col, Tabs, Typography } from 'antd';
import { GET_USER_TOURNAMENT_STATS_BY_ID_QUERY, GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS, GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS_BY_ID } from '../data/queries';
import { withRouter, Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { TabPane } = Tabs;

const styles = {
    tabPane: {
        padding: 20,
    },
};

export default function UserStats(props) {
    const { authState, client, user, theme } = props;
    const [tournaments, setTournaments] = useState();
    const chartRef = useRef();

    const userDisplayName = user && `${user.first_name} ${user.last_name}`;

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + 'st';
        }
        if (j === 2 && k !== 12) {
            return i + 'nd';
        }
        if (j === 3 && k !== 13) {
            return i + 'rd';
        }
        return i + 'th';
    }

    async function getTournamentEffectiveRating(playerName) {
        const ratings = await client.query({
            query: GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS,
            variables: {
                name: `%${playerName}%`,
            },
        });

        const rating = ratings && ratings.data && ratings.data.vw_tournament_player_effective_ratings;
        console.log(rating);
        return rating;
    }

    async function getTournamentEffectiveRating2(id) {
        const ratings = await client.query({
            query: GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS_BY_ID,
            variables: {
                id: id,
            },
        });

        const rating = ratings && ratings.data && ratings.data.vw_tournament_player_effective_ratings;
        console.log(rating);
        return rating;
    }

    return (
        <Query
            query={GET_USER_TOURNAMENT_STATS_BY_ID_QUERY}
            fetchPolicy="cache-and-network"
            variables={{
                // id: 51 // jason gladden
                user_id: (user && user.id) || authState.user.id,
                // player_name: userDisplayName,
            }}
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            onCompleted={async (data, loading, error, subscribeToMore) => {
                const userId = (user && user.id) || authState.user.id;
                const tournaments = data && data.tournament_players;
                const effectiveRatings = [];
                // const effectiveRatings = await getTournamentEffectiveRating(userDisplayName);
                // const effectiveRatings = await getTournamentEffectiveRating2(userId);
                const updatedTournaments = tournaments.map((tournament, index) => {
                    return {
                        ...tournament,
                        ...effectiveRatings.filter((item) => {
                            return item.tournament_id === tournament.tournament.id;
                        })[0],
                    };
                });
                setTournaments(updatedTournaments);
                console.log(updatedTournaments);
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.tournament_players) || (data && data.tournament_players.length === 0)) {
                    return (
                        <ZeroState
                            message="You have not played in any tournaments yet."
                            messageStyle={{ fontSize: 16 }}
                            showImage={false}
                            style={{ margin: 10, textAlign: 'left' }}
                        />
                    );
                }

                if (!tournaments) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                const topFinishes = tournaments
                    .map((tournament) => {
                        return {
                            id: tournament.tournament.id,
                            name: tournament.tournament.name,
                            slug: tournament.tournament.slug,
                            skill_level: tournament.skill_level,
                            place: tournament.place,
                        };
                    })
                    .sort((a, b) => {
                        return (
                            a.place &&
                            a.place.localeCompare(b.place, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        );
                    });

                console.log(topFinishes);

                const matchesWon = tournaments
                    .map((tournament) => {
                        const challenger1MatchesWon = tournament.challenger1_matches_won.aggregate.count;
                        const challenger2MatchesWon = tournament.challenger2_matches_won.aggregate.count;

                        return challenger1MatchesWon + challenger2MatchesWon;
                    })
                    .reduce((acc, total) => {
                        return acc + total;
                    }, 0);

                const matchesLost = tournaments
                    .map((tournament) => {
                        const challenger1MatchesLost = tournament.challenger1_matches_lost.aggregate.count;
                        const challenger2MatchesLost = tournament.challenger2_matches_lost.aggregate.count;

                        return challenger1MatchesLost + challenger2MatchesLost;
                    })
                    .reduce((acc, total) => {
                        return acc + total;
                    }, 0);

                const totalMatches = matchesWon + matchesLost;
                const avgMatchesWon = parseFloat((matchesWon / totalMatches).toFixed(2) * 100);

                const gamesWon = tournaments
                    .map((tournament) => {
                        const challenger1GamesWon1 = tournament.challenger1_matches_won.aggregate.sum.challenger1_score;
                        const challenger1GamesWon2 = tournament.challenger1_matches_lost.aggregate.sum.challenger1_score;
                        const challenger2GamesWon1 = tournament.challenger2_matches_won.aggregate.sum.challenger2_score;
                        const challenger2GamesWon2 = tournament.challenger2_matches_lost.aggregate.sum.challenger2_score;

                        return challenger1GamesWon1 + challenger1GamesWon2 + challenger2GamesWon1 + challenger2GamesWon2;
                    })
                    .reduce((acc, total) => {
                        return acc + total;
                    }, 0);

                const gamesLost = tournaments
                    .map((tournament) => {
                        const challenger1GamesLost1 = tournament.challenger1_matches_won.aggregate.sum.challenger2_score;
                        const challenger1GamesLost2 = tournament.challenger1_matches_lost.aggregate.sum.challenger2_score;
                        const challenger2GamesLost1 = tournament.challenger2_matches_won.aggregate.sum.challenger1_score;
                        const challenger2GamesLost2 = tournament.challenger2_matches_lost.aggregate.sum.challenger1_score;

                        return challenger1GamesLost1 + challenger1GamesLost2 + challenger2GamesLost1 + challenger2GamesLost2;
                    })
                    .reduce((acc, total) => {
                        return acc + total;
                    }, 0);

                const totalGames = gamesWon + gamesLost;
                const avgGamesWon = parseFloat((gamesWon / totalGames).toFixed(2) * 100);
                const ratings = tournaments.map((tournament) => {
                    return {
                        start_time: tournament.tournament.start_date_time,
                        rating: tournament.skill_level,
                        performance_rating: tournament.effective_rating,
                        tournament_name: tournament.tournament.name,
                    };
                });

                const fargoRatings = _.reject(ratings, (item) => {
                    return item.rating === null || item.rating < 100;
                }).slice(-25);

                function afterRender(chart) {
                    var series = chart.series[0],
                        points = series.points,
                        pLen = points.length,
                        // i = 0,
                        lastIndex = pLen - 1,
                        minIndex = series.processedYData.indexOf(series.dataMin),
                        maxIndex = series.processedYData.indexOf(series.dataMax);

                    points[minIndex].options.showLabel = true;
                    points[maxIndex].options.showLabel = true;
                    points[lastIndex].options.showLabel = true;
                    series.isDirty = true;
                    chart.redraw();
                }

                const tournamentStats = {
                    colors: ['#2196f3', 'rgba(255, 204, 0, 1)', 'rgba(255, 204, 0, 0.4)', '#44475b'],
                    title: {
                        text: null,
                    },

                    chart: {
                        height: 250,
                        panning: false,
                        zoomType: 'x',
                        animation: false,
                        backgroundColor: 'transparent',
                    },

                    yAxis: {
                        title: {
                            text: null,
                        },

                        lineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#C0D0E0',
                        lineWidth: 1,
                        gridLineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#D8D8D8',
                        gridLineDashedStyle: 'solid',
                        gridLineWidth: 1,
                        labels: {
                            style: {
                                fontSize: 10,
                                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                            },
                        },
                    },

                    xAxis: {
                        type: 'datetime',
                        crosshair: true,
                        tickInterval: 2,
                        tickPositions: fargoRatings.map((rating) => moment(rating.start_time).valueOf()),
                        labels: {
                            formatter: function () {
                                var dateObj = new Date(this.value);
                                return moment(dateObj).utc().format('MMM YYYY');
                            },
                            rotation: -45,
                        },
                    },

                    legend: {
                        // symbolWidth: 12,
                        // symbolRadius: 6
                        itemStyle: {
                            color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                        },
                        itemHoverStyle: {
                            color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                        },
                    },
                    credits: { enabled: false },
                    tooltip: {
                        //shared: true,
                        formatter: function () {
                            var dateObj = new Date(this.x);
                            return (
                                '<b>' +
                                this.point.tournament_name +
                                '</b> <br/>' +
                                moment(dateObj).utc().format('MMM Do, YYYY') +
                                '<br/> <span style="color:' +
                                this.point.series.color +
                                ';">\u25CF ' +
                                '</span> Fargo Rating: <b>' +
                                this.y +
                                '</b>' +
                                '<br/> Performance Rating: <b>' +
                                (this.point.performance_rating || 'N/A') +
                                '</b>'
                            );
                        },
                    },

                    plotOptions: {
                        series: {
                            marker: {
                                fillColor: '#fff',
                                lineWidth: 2,
                                lineColor: null, // inherit from series
                                enabled: true,
                                // lineWidth: 0.5,
                                radius: 3,
                                states: {
                                    select: {
                                        fillColor: 'black',
                                        lineWidth: 1,
                                        radius: 3,
                                        color: 'black',
                                    },
                                    hover: {
                                        radius: 4,
                                        lineWidth: 1,
                                    },
                                },
                            },
                            dataLabels: {
                                enabled: true,
                                borderRadius: 5,
                                backgroundColor: 'rgba(252, 255, 197, 0.1)',
                                borderWidth: 1,
                                borderColor: null,
                                y: -6,
                                style: {
                                    fontWeight: 'bold',
                                    textOutline: 'none',
                                    border: 'none',
                                    color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                                },
                                /*formatter: function () {
                                            if (this.point.options.showLabel) {
                                                return parseFloat(this.y);
                                            }
                                            return null;
                                        },*/
                            },
                            lineWidth: 2,
                            crosshairs: true,
                            cursor: 'pointer',
                        },
                    },

                    series: [
                        {
                            name: 'Fargo Rating History',
                            data: fargoRatings
                                .sort((a, b) => {
                                    const createdAtA = moment(a.start_time).valueOf();
                                    const createdAtB = moment(b.start_time).valueOf();
                                    return createdAtA - createdAtB;
                                })
                                .map((rating) => {
                                    // return [rating.tournament_name, rating.rating];
                                    return {
                                        x: moment(rating.start_time).valueOf(),
                                        y: rating.rating,
                                        tournament_name: rating.tournament_name,
                                        performance_rating: rating.performance_rating,
                                    };
                                    // return [moment(rating.start_time).valueOf(), rating.rating];
                                }),
                        },
                    ],
                };

                return (
                    <Row gutter={16}>
                        <Col span={12}>
                            <div style={{ textAlign: 'center', marginTop: -10 }}>
                                <div style={{ paddingBottom: 10 }}>
                                    <Text>Match win %</Text>
                                </div>
                                <Progress
                                    type="dashboard"
                                    percent={avgMatchesWon || 0}
                                    strokeWidth={4}
                                    strokeColor="#2196f3"
                                    format={(percent) => (percent < 100 ? `${percent.toFixed(0)}%` : '100%')}
                                />

                                <Statistic title="Match record" value={matchesWon || 0} suffix={`- ${matchesLost || 0}`} />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{ textAlign: 'center', marginTop: -10 }}>
                                <div style={{ paddingBottom: 10 }}>
                                    <Text>Game win %</Text>
                                </div>
                                <Progress
                                    type="dashboard"
                                    gapPosition="bottom"
                                    percent={avgGamesWon || 0}
                                    strokeWidth={4}
                                    strokeColor="#ffcd00"
                                    format={(percent) => (percent < 100 ? `${percent.toFixed(0)}%` : '100%')}
                                />

                                <Statistic title="Game record" value={gamesWon || 0} suffix={`- ${gamesLost || 0}`} />
                            </div>
                        </Col>
                        {topFinishes && topFinishes.length > 0 && (
                            <Col span={24}>
                                <div
                                    style={{
                                        padding: 10,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Text style={{ fontSize: 18 }}>
                                        {topFinishes[0] && topFinishes[0].place ? (
                                            <React.Fragment>
                                                Best finish: <b>{ordinal_suffix_of(topFinishes[0].place)} place</b>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>Most recent:</React.Fragment>
                                        )}
                                    </Text>
                                    <br />
                                    <Link to={`/tournaments/${topFinishes[0].slug}`}>{topFinishes[0].name}</Link>
                                </div>
                            </Col>
                        )}
                        <Col span={24}>
                            <div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={tournamentStats}
                                    containerProps={{ className: 'tournamentStatsContainer' }}
                                    ref={chartRef}
                                    // callback={afterRender}
                                />
                            </div>
                        </Col>
                    </Row>
                );
            }}
        </Query>
    );
}
