import React, { useState, useEffect } from 'react';
import { Typography, Button, Alert } from 'antd';
import FlyerUploadForm from './FlyerUploadForm';
import LogoUploadForm from './LogoUploadForm';
const { Title, Text } = Typography;

const styles = {};

export default function EventUploadFlyer(props) {
    const { event, onContinue, goBack } = props;

    return (
        <React.Fragment>
            <div
                style={{
                    marginBottom: 20,
                }}
            >
                <Title level={4} style={{ fontSize: 18 }}>
                    Upload Flyer
                </Title>

                <Text>Flyers must in JPG or PNG format and 2MB or less.</Text>
            </div>
            <FlyerUploadForm {...props} />

            <div
                style={{
                    marginTop: 10,
                    marginBottom: 20,
                }}
            >
                <Title level={4} style={{ fontSize: 18 }}>
                    Upload Logo
                </Title>

                <Text>Logos must in JPG or PNG format and 2MB or less.</Text>
            </div>
            <LogoUploadForm {...props} />
            <Button type="primary" size="large" onClick={onContinue} style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text style={{ color: '#fff' }}>Add Managers</Text>
                </div>
            </Button>
            <Button
                type="secondary"
                size="large"
                onClick={() => {
                    goBack();
                }}
                style={{ marginTop: 20 }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text>Go back</Text>
                </div>
            </Button>
        </React.Fragment>
    );
}
