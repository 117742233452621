import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import Round from './Round';
// import MatchGroup from './MatchGroup';
import Match from './Match';
import Card from '../../../../components/Card';
// import scrollToComponent from 'react-scroll-to-component';
// import { scrollTo } from '../../utils/scrollTo';
// import DragScroll from './DragScroll';
import ScrollContainer from 'react-indiana-drag-scroll';
import CircularLoader from '../../../../components/CircularLoader';
import { isoCountries } from '../../../../utils/isoCountryCodes';
import { getByeChart } from '../../../../utils';
// import BracketLogic from './bracketLogic';
import Sticky from 'react-stickynode';
import { GET_TOURNAMENT_BRACKET_QUERY, GET_TOURNAMENT_PLAYERS_QUERY, GET_TOURNAMENT_TABLES_QUERY, GET_TOURNAMENT_STATUS } from '../data/queries';
import { GET_TOURNAMENT_BRACKET_SUBSCRIPTION, GET_TOURNAMENT_TABLES_SUBSCRIPTION, GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION } from '../data/subscriptions';
import {
    UPDATE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    UPDATE_TABLE_STATUS_MUTATION,
    RESET_TOURNAMENT_PLAYER_SEED_ORDER,
    RESET_TOURNAMENT_BRACKET_PLAYERS,
    UPDATE_TOURNAMENT_STATUS,
    UPDATE_TOURNAMENT_TABLE_STATUS_MUTATION,
} from '../data/mutations';
import Draggable from 'react-draggable';
import { DigitalPoolAPI } from '../../../../lib/api';
// import DoubleElimBracketGenerator from './doubleElimBracketGenerator';
import { message, Button, Icon, Typography, Empty, Row, Col, Tooltip, Modal, Input, Layout, Menu } from 'antd';
// import _ from 'lodash';
import createBracketDBDataset from './../../../../lib/bracket-double.js';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import MatchOverlay from './MatchOverlay';
import { MapInteractionCSS } from 'react-map-interaction';
import SidePanel from './SidePanel';
import _ from 'lodash';
import './bracket.css';
import './bracket-compact.css';
import BracketControls from './BracketControls';
// import scrollToComponent from 'react-scroll-to-component';
import qs from 'query-string';
import Autopilot from './Autopilot2';
// import clearRender from 'clear-render';
import moment from 'moment';
import dpLogoHeader from '../../../../assets/img/dp-logo-header.png';

const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
// const { SubMenu } = Menu;
const { confirm } = Modal;

// http://udaikapila165.blogspot.com/2010/12/double-elimination-how-many-rounds.html
function BracketStaticDoubleElim(props) {
    const {
        tournament_brackets,
        isPublic,
        authState,
        tournament,
        stage,
        isFullScreen,
        onFullScreen,
        tablesPanel,
        statusPanel,
        compact,
        // size,
        height,
        navType,
        playerSearchCoordinates,
        onUpdateCoordinates,
        theme,
    } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    // const bracketLayoutToggle = localStorage.getItem('digitalpool__bracketLayout');
    // const [ bracketContent, setBracketContent ] = useState(null);
    // const [bracketLayout, setBracketLayout] = useState('left-right');
    const [bracketLayout, setBracketLayout] = useState('up-down');
    const [selectedStage, setSelectedStage] = useState();
    // const [ matches, setMatches ] = useState([]);
    // const [ drawComplete, setDrawComplete ] = useState(false);
    const [tables, setTables] = useState([]);

    const [loading, setLoading] = useState(false);
    const [viewType, setViewType] = useState('scroll');
    // const [viewType, setViewType] = useState(breakpoint === 'mobile' ? 'scroll' : viewToggle || 'zoom');
    // const [ players, setPlayers ] = useState([]);
    // const [ drawRound, setDrawRound ] = useState([]);
    // const [ winnerBracket, setWinnerBracket ] = useState([]);
    // const [ loserBracket, setLoserBracket ] = useState([]);
    const [rendered, setRendered] = useState(false);
    // const [ loserBracketReversed, setLoserBracketReversed ] = useState([]);
    const [tournamentComplete, setTournamentComplete] = useState(tournament.status === 'COMPLETED' ? true : false);
    const [zoom, setZoom] = useState(breakpoint === 'mobile' ? 0.7 : 0.7);
    const [translation, setTranslation] = useState({ x: 0, y: 0 });
    // const [ drawerVisible, setDrawerVisible ] = useState(false);
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    // const [sidePanelOpen, setSidePanelOpen] = useState((breakpoint === 'mobile') === true ? false : true);
    // const [showStatusPanel, setShowStatusPanel] = useState((breakpoint === 'mobile') === true ? false : true);
    // const [ updateTableStatus, { updateTableLoading, updateTableError } ] = useMutation(
    // 	UPDATE_TOURNAMENT_TABLE_STATUS_MUTATION
    // );
    // const [ updateBracketMatch, { updateBracketLoading, updateBracketError } ] = useMutation(
    // 	UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION
    // );
    const showNavigation = true;
    // const elementsRef = useRef(Array.from(Array(32)).map(() => createRef()));
    // const [ bracketDimensions, setBracketDimensions ] = useState();
    const bracketEl = useRef();
    let minScale = 0.1;
    let maxScale = 3;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const footerEl = document.querySelector('.footer');
    const sectionHeaderEl = document.querySelector('.section-header');
    const bracketWrapperEl = document.querySelector('.bracket-surface');
    const progressBarEl = document.querySelector('.tournament-progress-bar');

    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    let footerHeight = footerEl && footerEl.offsetHeight;
    let bracketWidth = bracketWrapperEl && bracketWrapperEl.offsetWidth;

    const onMapChange = _.debounce(handleMapZoom, 100); // wait  to set the zoom and translation state
    const ForwardRound = React.forwardRef((props, ref) => (
        <Round {...props} theme={theme} compact={compact} innerRef={ref} onUpdateCoordinates={onUpdateCoordinates} />
    ));

    const API = DigitalPoolAPI(props);

    if (isPublic === true) {
        const spacer = navType === 'mobile' ? 100 : 80;
        bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = windowHeight - headerNarrowHeight;
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }

    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    console.log('render bracket');

    useLayoutEffect(() => {
        if (rendered === true) {
            function getOffset(el) {
                var _x = 0;
                var _y = 0;
                while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                    _x += el.offsetLeft - el.scrollLeft;
                    _y += el.offsetTop - el.scrollTop;
                    el = el.offsetParent;
                }
                return { top: _y, left: _x };
            }

            const identifierElement = document.getElementById('W1-1');
            const bracketSurfaceElement = document.querySelector('.bracket-surface');
            let absoluteCoordinates = getOffset(identifierElement);
            const identifierRect = identifierElement && identifierElement.getBoundingClientRect();
            const bracketRect = bracketSurfaceElement && bracketSurfaceElement.getBoundingClientRect();

            // console.log(bracketRect);
            // console.log('bracketLayout:', bracketLayout);
            // console.log('viewType:', viewType);

            let newX;
            if (viewType === 'zoom') {
                if (bracketLayout === 'up-down') {
                    if (bracketRect) {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - bracketRect.width / 2 - 40;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - 40;
                    }
                } else if (bracketLayout === 'left-right') {
                    newX = -absoluteCoordinates.left + document.body.offsetWidth / 2 - identifierRect.width / 2;
                }
            } else if (viewType === 'scroll') {
                handleSetZoom(0.9);
                // onUpdateZoom(1.0);
                if (bracketLayout === 'up-down') {
                    if (bracketRect) {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - bracketRect.width / 2 - 40;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - 40;
                    }
                    const scrollingElement = document.querySelector('.scroll-container');
                    if (scrollingElement) {
                        scrollingElement.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }
                } else if (bracketLayout === 'left-right') {
                    newX = -absoluteCoordinates.left + document.body.offsetWidth / 2 - identifierRect.width / 2;

                    const scrollingElement = document.querySelector('.scroll-container');
                    if (scrollingElement) {
                        // console.log('left', identifierElement.offsetLeft);
                        scrollingElement.scroll({
                            top: 0,
                            left: document.body.offsetWidth / 2 + identifierElement.offsetLeft / 2 - identifierRect.width / 2,
                            behavior: 'smooth',
                        });
                    }
                }
            }
            const newY = 0;
            const newCoords = { x: newX, y: newY };
            // console.log(newCoords);
            if (onUpdateCoordinates) {
                onUpdateCoordinates(newCoords);
            }
        }
    }, [rendered, viewType, bracketLayout]);

    function showScoreModal(match) {
        // console.log(match);
        setCurrentMatch(match);
        setModalScoreVisible(true);
    }

    function toggleFullScreen() {
        onFullScreen(!isFullScreen);
    }

    async function startTournament(callback) {
        return await API.updateTournament({
            start_date_time: moment.utc(),
            status: 'IN_PROGRESS',
        }).then((res) => {
            // await API.updateTournamentStatus('IN_PROGRESS');
            message.success({
                content: `Tournament clock started.`,
                duration: 2,
            });
            return res;
        });
    }

    async function resetBracket() {
        const key = 'updatable';
        message.loading({ content: 'Resetting tournament bracket, this might take a minute...', key, duration: 0 });
        const status = await API.updateTournamentStatus('NOT_STARTED');

        console.log('status', status);

        return props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_BRACKET_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
            })
            .then(async (data) => {
                console.log(data);
                message.loading({ content: 'Creating new tournament bracket...', key, duration: 0 });
                const bracketDBDataset = createBracketDBDataset(tournament.id, tournament.max_players, tournament.consolidation_finals);
                console.log(bracketDBDataset);

                return new Promise(async (resolve, reject) => {
                    return await props.client
                        .mutate({
                            mutation: CREATE_TOURNAMENT_BRACKET_MUTATION,
                            variables: {
                                objects: bracketDBDataset.matches,
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_TOURNAMENT_BRACKET_QUERY,
                                    variables: { tournamentId: tournament.id },
                                },
                            ],
                        })
                        .then((res) => {
                            console.log(res);
                            message.success({ content: 'Tournament bracket reset complete!', key, duration: 1 });
                            resolve(res.data.insert_tournament_brackets.affected_rows);
                        })
                        .catch((error) => {
                            console.log(error);
                            message.error({
                                content: `Error resetting the tournament ${JSON.stringify(error)}`,
                                key,
                                duration: 1,
                            });
                            reject(error);
                        });
                });
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function createBracket() {
        const bracketDBDataset = createBracketDBDataset(tournament.id, tournament.max_players);
        console.log(bracketDBDataset);

        const key = 'updatable';
        message.loading({ content: 'Loading...', key });

        return new Promise(async (resolve, reject) => {
            return await props.client
                .mutate({
                    mutation: CREATE_TOURNAMENT_BRACKET_MUTATION,
                    variables: {
                        objects: bracketDBDataset.matches,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                        },
                    ],
                })
                .then((res) => {
                    console.log(res);
                    message.success({ content: 'Tournament bracket created!', key, duration: 2 });
                    resolve(res.data && res.data.update_tournament_players && res.data.update_tournament_players.affected_rows);
                })
                .catch((error) => {
                    console.log(error);
                    message.error({
                        content: `Error creating tournament bracket ${JSON.stringify(error)}`,
                        key,
                        duration: 2,
                    });
                    reject(error);
                });
        });
    }

    function showConfirmStart(drawRound, winnerBracket, matches) {
        confirm({
            title: 'Are you ready to start the tournament and seed the bracket?',
            content:
                'This action will start the tournament clock and generate the first round draw using a randomized seeding algorithm to place the players in the bracket.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Tournament',
            cancelText: "I'll do this later",
            onOk() {
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
                // if (tables.length > 0 && drawRound[0] && drawRound[0][0] && !drawRound[0][0].challenger1_seed) {
                // 	showConfirmDraw(drawRound, winnerBracket);
                // } else if (tables.length > 0) {
                // 	startTournament(drawRound, winnerBracket);
                // } else {
                // 	message.error('You must add at least 1 table before you can start the tournament.', 10);
                // }
                if (tables.length > 0) {
                    startTournament().then((data) => {
                        generateDraw(drawRound, winnerBracket, matches, true);
                    });

                    // showConfirmDraw(drawRound, winnerBracket);
                } else {
                    message.error('You must add at least 1 table before you can start the tournament.', 10);
                }
            },
            onCancel() {},
        });
    }

    function showConfirmReset() {
        confirm({
            title: 'Are you sure you want to reset the bracket?',
            content: 'This action will destroy all existing matches.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Reset`,
            onOk() {
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
                resetBracket();
            },
            onCancel() {},
        });
    }

    function showConfirmDraw(draw, winnerBracket, matches) {
        confirm({
            title: `Would you like to seed the bracket for this ${tournament.max_players} player tournament?`,
            content: `This will use a random seeding algorithm to place the players in the bracket.`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Seed bracket`,
            cancelText: "I'll do this later",
            onOk() {
                // return new Promise((resolve, reject) => {
                // 	setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));

                generateDraw(draw, winnerBracket, matches, true); // generate random draw
            },
            onCancel() {},
        });
    }

    function showConfirmPlayers() {
        confirm({
            title: `You haven't added any players yet, would you like to do that now?`,
            content: `You will be taken to the player management section.`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Yes take me there`,
            cancelText: 'Cancel',
            onOk() {
                props.history.push(`/tournament-builder/${tournament.slug}/players`);
            },
            onCancel() {},
        });
    }

    function showCompleteDialog(winner) {
        Modal.success({
            content: `${winner.challenger2_name || winner.challenger1_name} won the tournament!`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        setTournamentComplete(true);
    }

    function shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    //     return new Promise(function(resolve, reject) {
    //         var bigArray = [[argument1, argument2, argument3, argument4], [argument5, argument6, argument7, argument8], ....];
    //         //the summ of all arguments is over 60k...
    //         var results = [];

    //         var index = 0;
    //         function next() {
    //             if (index < bigArray.length) {
    //                 getInfoForEveryInnerArgument(bigArray[index++]).then(function(data) {
    //                     results.push(data);
    //                     next();
    //                 }, reject);
    //             } else {
    //                 resolve(results);
    //             }
    //         }
    //         // start first iteration
    //         next();
    //     });
    // }

    async function generateDraw(draw, winnerBracket, matches, random = true) {
        return new Promise(async (resolve, reject) => {
            props.client
                .query({
                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                    variables: {
                        tournament_id: tournament.id,
                    },
                })
                .then((res) => {
                    props.client
                        .mutate({
                            mutation: RESET_TOURNAMENT_PLAYER_SEED_ORDER,
                            variables: {
                                tournament_id: tournament.id,
                            },
                        })
                        .then((data1) => {
                            props.client
                                .mutate({
                                    mutation: RESET_TOURNAMENT_BRACKET_PLAYERS,
                                    variables: {
                                        tournament_id: tournament.id,
                                    },
                                })
                                .then((data2) => {
                                    //  RESET_TOURNAMENT_PLAYER_SEED_ORDER

                                    let players = res && res.data && res.data.tournament_players;

                                    console.log(draw);
                                    // console.log(winnerBracket);
                                    // console.log(tournament.max_players);

                                    var totalRounds = Math.ceil(Math.log(tournament.max_players) / Math.log(2));

                                    if (players.length > 0) {
                                        var seeds = generateSeeds(tournament.max_players, totalRounds, tournament.max_players);
                                        console.log('seeds', seeds);
                                        let playersToUpdate = [];

                                        const key = 'updatable';
                                        message.loading({ content: 'Seeding bracket...', key, duration: 0 });

                                        if (random === true) {
                                            players = shuffle(_.uniq(players, 'id'));
                                        } else {
                                            players = _.uniq(players, 'id');
                                        }

                                        // players = players.slice(0, 28);

                                        var playerIndex = 1;
                                        console.log(players);

                                        const seededPlayers = seeds.map((match, seedIndex) => {
                                            // console.log(match);
                                            // console.log(players);

                                            const byeChart = getByeChart(tournament.max_players);

                                            var matchNumber = seedIndex * 2 + 1;
                                            const numberOfByes = tournament.max_players - players.length;
                                            //console.log(
                                            //	'number of byes: ',
                                            //	tournament.max_players,
                                            //	players.length,
                                            //	numberOfByes,
                                            //	seedIndex,
                                            //	matchNumber
                                            //);

                                            var seed1 = -1;
                                            var seed2 = -1;

                                            if (byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) seed1 = -1;
                                            else {
                                                //seed1 = match[0] - 1;
                                                seed1 = playerIndex - 1;
                                                playerIndex++;
                                            }

                                            //console.log('matchNumber, seed1', matchNumber, seed1);

                                            matchNumber++;
                                            if (byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) seed2 = -1;
                                            else {
                                                //seed2 = match[1] - 1;
                                                seed2 = playerIndex - 1;
                                                playerIndex++;
                                            }

                                            //console.log('matchNumber, seed2', matchNumber, seed2);

                                            const player1 = players[seed1];
                                            const player2 = players[seed2];

                                            // console.log({
                                            // 	match,
                                            // 	seed1,
                                            // 	seed2,
                                            // 	player1,
                                            // 	player2
                                            // });

                                            if (player1) {
                                                playersToUpdate.push({
                                                    id: player1.id,
                                                    name: player1.name,
                                                    country: player1.country,
                                                    email: player1.email,
                                                    phone_number: player1.phone_number,
                                                    skill_level: player1.skill_level,
                                                    status: player1.status,
                                                    points: player1.points,
                                                    seed: match[0],
                                                    race_to: player1.race_to,
                                                    user_id: player1.user_id,
                                                });
                                            }

                                            if (player2) {
                                                playersToUpdate.push({
                                                    id: player2.id,
                                                    name: player2.name,
                                                    country: player2.country,
                                                    email: player2.email,
                                                    phone_number: player2.phone_number,
                                                    skill_level: player2.skill_level,
                                                    status: player2.status,
                                                    points: player2.points,
                                                    seed: match[1],
                                                    race_to: player2.race_to,
                                                    user_id: player2.user_id,
                                                });
                                            }

                                            return {
                                                challenger1_id: player1 ? player1.id : null,
                                                challenger1_name: player1 ? player1.name : 'BYE',
                                                challenger1_country: player1 ? player1.country : null,
                                                challenger1_race_to: player1 ? player1.race_to : null,
                                                challenger1_skill_level: player1 ? player1.skill_level : null,
                                                challenger1_sms_sent: player1 ? player1.sms_sent : null,
                                                challenger1_score: 0,
                                                challenger1_seed: match[0],
                                                challenger2_id: player2 ? player2.id : null,
                                                challenger2_name: player2 ? player2.name : 'BYE',
                                                challenger2_country: player2 ? player2.country : null,
                                                challenger2_race_to: player2 ? player2.race_to : null,
                                                challenger2_skill_level: player2 ? player2.skill_level : null,
                                                challenger2_sms_sent: player2 ? player2.sms_sent : null,
                                                challenger2_score: 0,
                                                challenger2_seed: match[1],
                                            };
                                        });

                                        function updatePlayer(player) {
                                            return props.client
                                                .mutate({
                                                    mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                                                    variables: {
                                                        id: player.id,
                                                        tournament_id: tournament.id,
                                                        changes: player,
                                                    },
                                                    // awaitRefetchQueries: true,
                                                    // refetchQueries: [
                                                    // 	{
                                                    // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                                                    // 		variables: { tournamentId: tournament.id }
                                                    // 	}
                                                    // ]
                                                })
                                                .then((data) => {
                                                    // console.log(data);
                                                    return data;
                                                })
                                                .catch((error) => {
                                                    console.error(error);
                                                    reject(error);
                                                });
                                        }

                                        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
                                            return accumulatorPromise.then(() => {
                                                return updatePlayer(nextPlayer);
                                            });
                                        }, Promise.resolve());

                                        result.then((e) => {
                                            console.log(seededPlayers);
                                            console.log(playersToUpdate);
                                            console.log(draw[0]);

                                            message.loading({
                                                content: 'Generating first-round matches...',
                                                key,
                                                duration: 0,
                                            });

                                            let promises = [];

                                            const firstRoundDraw =
                                                draw &&
                                                draw[0] &&
                                                draw[0].map((match, i) => {
                                                    const player1Race = match.challenger1_race_to
                                                        ? match.challenger1_race_to
                                                        : stage
                                                        ? stage.winners_race_to
                                                        : tournament.winners_race_to;
                                                    const player2Race = match.challenger2_race_to
                                                        ? match.challenger2_race_to
                                                        : stage
                                                        ? stage.winners_race_to
                                                        : tournament.winners_race_to;

                                                    return {
                                                        id: match.id,
                                                        ...(match.round && { round: match.round }),
                                                        ...(match.identifier && { identifier: match.identifier }),
                                                        challenger1_score: match.challenger1_score,
                                                        challenger1_race_to: player1Race,
                                                        challenger1_skill_level: match.challenger1_skill_level,
                                                        challenger1_seed: match.challenger1_seed,
                                                        challenger1_sms_sent: match.challenger1_sms_sent,
                                                        challenger2_score: match.challenger2_score,
                                                        challenger2_race_to: player2Race,
                                                        challenger2_skill_level: match.challenger2_skill_level,
                                                        challenger2_seed: match.challenger2_seed,
                                                        challenger2_sms_sent: match.challenger2_sms_sent,
                                                        match_number: match.match_number,
                                                        match_id: match.match_id,
                                                        team_id: match.team_id,
                                                        scheduled_time: match.scheduled_time,
                                                        start_time: match.start_time,
                                                        end_time: match.end_time,
                                                        duration: match.duration,
                                                        table_id: match.table_id,
                                                        table_number: match.table_number,
                                                        seed: match.seed,
                                                        status: match.status,
                                                        progress: match.progress,
                                                        winner_to: match.winner_to,
                                                        winner_to_num: match.winner_to_num,
                                                        loser_from: match.loser_from,
                                                        loser_from_num: match.loser_from_num,
                                                        loser_to: match.loser_to,
                                                        loser_to_num: match.loser_to_num,
                                                        ...seededPlayers[i],
                                                    };
                                                });
                                            console.log('After draw', firstRoundDraw);

                                            const byeMatches = firstRoundDraw
                                                .filter((match) => {
                                                    return match.challenger1_name === 'BYE' || match.challenger2_name === 'BYE';
                                                })
                                                .map((match) => {
                                                    const matchesCopy = matches;
                                                    // console.log(matchesCopy);
                                                    const winnerMatches = matchesCopy
                                                        .filter((item) => {
                                                            return item.match_number === match.winner_to_num;
                                                        })
                                                        .map((item) => {
                                                            const player1Race =
                                                                match.challenger1_name !== 'BYE'
                                                                    ? match.challenger1_race_to
                                                                    : stage
                                                                    ? stage.winners_race_to
                                                                    : tournament.winners_race_to;
                                                            const player2Race =
                                                                match.challenger2_name !== 'BYE'
                                                                    ? match.challenger2_race_to
                                                                    : stage
                                                                    ? stage.winners_race_to
                                                                    : tournament.winners_race_to;

                                                            return {
                                                                id: item.id,
                                                                round: item.round,
                                                                identifier: item.identifier,
                                                                ...(match.match_number % 2 === 0 && {
                                                                    challenger2_id: match.challenger1_id,
                                                                    challenger2_name: match.challenger1_name,
                                                                    challenger2_seed: match.challenger1_seed,
                                                                    challenger2_country: match.challenger1_country,
                                                                    challenger2_score: 0,
                                                                    challenger2_race_to: player1Race,
                                                                    challenger2_skill_level: match.challenger1_skill_level,
                                                                }),
                                                                ...(match.match_number % 2 !== 0 && {
                                                                    challenger1_id: match.challenger1_id,
                                                                    challenger1_name: match.challenger1_name,
                                                                    challenger1_seed: match.challenger1_seed,
                                                                    challenger1_country: match.challenger1_country,
                                                                    challenger1_score: 0,
                                                                    challenger1_race_to: player1Race,
                                                                    challenger1_skill_level: match.challenger1_skill_level,
                                                                }),
                                                            };
                                                        });

                                                    const loserMatches = matchesCopy
                                                        .filter((item) => {
                                                            return item.match_number === match.loser_to_num;
                                                        })
                                                        .map((item) => {
                                                            return {
                                                                id: item.id,
                                                                round: item.round,
                                                                identifier: item.identifier,
                                                                ...(match.challenger2_name === 'BYE' &&
                                                                    match.match_number % 2 !== 0 && {
                                                                        challenger1_name: 'BYE',
                                                                        challenger1_seed: match.challenger2_seed,
                                                                    }),
                                                                ...(match.challenger2_name === 'BYE' &&
                                                                    match.match_number % 2 === 0 && {
                                                                        challenger2_name: 'BYE',
                                                                        challenger2_seed: match.challenger2_seed,
                                                                    }),
                                                            };
                                                        });

                                                    const current = {
                                                        ...match,
                                                        challenger1_score: 0,
                                                        challenger2_score: 0,
                                                        status: 'COMPLETED',
                                                        progress: 100,
                                                        is_bye: true,
                                                    };

                                                    return [current, winnerMatches[0], loserMatches[0]];
                                                });

                                            console.log('Bye Matches not flat: ', byeMatches);
                                            console.log('Bye matches:', _.flatten(byeMatches));

                                            const matchesToUpdate = firstRoundDraw.concat(_.flatten(byeMatches));

                                            console.log('To update:', matchesToUpdate);

                                            matchesToUpdate.map(async (match, index) => {
                                                let updatedMatch = match;

                                                // if (
                                                // 	match.challenger1_name === 'BYE' ||
                                                // 	match.challenger2_name === 'BYE'
                                                // ) {
                                                // 	updatedMatch = {
                                                // 		...match,
                                                // 		challenger1_score: 0,
                                                // 		challenger2_score: 0,
                                                // 		status: 'COMPLETED',
                                                // 		progress: 100,
                                                // 		is_bye: true
                                                // 	};
                                                // } else {
                                                // 	updatedMatch = match;
                                                // }

                                                promises.push(
                                                    props.client.mutate({
                                                        mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                                                        variables: {
                                                            id: updatedMatch.id,
                                                            tournament_id: tournament.id,
                                                            changes: updatedMatch,
                                                        },
                                                        // awaitRefetchQueries: true,
                                                        // refetchQueries: [
                                                        // 	{
                                                        // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                                                        // 		variables: { tournamentId: tournament.id }
                                                        // 	}
                                                        // ]
                                                    })
                                                );
                                                // console.log(res2);
                                            });

                                            Promise.all(promises)
                                                .then((values) => {
                                                    props.client
                                                        .query({
                                                            query: GET_TOURNAMENT_BRACKET_QUERY,
                                                            variables: {
                                                                tournamentId: tournament.id,
                                                            },
                                                        })
                                                        .then((res) => {
                                                            message.success({
                                                                content: 'First-round draw complete!',
                                                                key,
                                                                duration: 2,
                                                            });
                                                            // setDrawComplete(true);
                                                            // showConfirmStart(drawRound, winnerBracket);
                                                            resolve(res);
                                                        })
                                                        .catch((error) => {
                                                            // setDrawComplete(false);
                                                            console.error(error);
                                                            reject(error);
                                                        });
                                                })
                                                .catch((e) => {
                                                    console.log(e);
                                                    // setDrawComplete(false);
                                                    message.error({ content: JSON.stringify(e), key, duration: 1 });
                                                    reject(e);
                                                    // showConfirmDraw(drawRound, winnerBracket);
                                                });
                                        });
                                    }
                                });
                        });
                });
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    function changeIntoBye(seed, participantsCount) {
        //return seed <= participantsCount ?  seed : '{0} (= bye)'.format(seed);
        return seed <= participantsCount ? seed : null;
    }

    function generateSeeds(participants, rounds, numberOfPlayersMax) {
        if (participants < 2) {
            return [];
        }

        let matches = [[1, 2]];
        var roundMatches = [];
        var seed1 = 0;
        var seed2 = 0;
        for (var playerSeedIndex = 1; playerSeedIndex <= numberOfPlayersMax; playerSeedIndex++) {
            seed1 = playerSeedIndex;
            playerSeedIndex++;
            seed2 = playerSeedIndex;
            roundMatches.push([seed1, seed2]);
        }
        matches = roundMatches;

        // let newRounds = [];

        // for (let i = rounds; i >= 1; i--) {
        // 	newRounds.push(i);
        // }

        // for (var round = 1; round < rounds; round++) {
        // 	var roundMatches = [];
        // 	var sum = Math.pow(2, round + 1) + 1;

        // 	for (var i = 0; i < matches.length; i++) {
        // 		var home = changeIntoBye(matches[i][0], participants);
        // 		var away = changeIntoBye(sum - matches[i][0], participants);
        // 		roundMatches.push([ home, away ]);
        // 		home = changeIntoBye(sum - matches[i][1], participants);
        // 		away = changeIntoBye(matches[i][1], participants);
        // 		roundMatches.push([ home, away ]);
        // 	}
        // 	matches = roundMatches;
        // }
        return matches;
    }

    function handleSetZoom(scale) {
        setZoom(scale);
    }

    function handleSetTranslation(translation) {
        setTranslation(translation);
    }

    // update bracket zoom level and position only after the movement has finished
    function handleMapZoom({ scale, translation }) {
        if (zoom !== scale) {
            handleSetZoom(scale);
        }

        handleSetTranslation(translation);
    }

    function discreteScaleStepSize() {
        const delta = Math.abs(maxScale - minScale);
        return delta / 30;
    }

    function canAccess(user) {
        if (user && user.role && (user.role === 'user' || user.role === 'admin')) {
            return true;
        } else {
            return false;
        }
    }

    function renderBracket(brackets, matches) {
        // console.log(bracketLayout);
        return (
            <div
                style={{
                    position: viewType === 'zoom' && breakpoint === 'mobile' ? 'relative' : 'absolute',
                    // left: viewType === 'scroll' && bracketLayout === 'left-right' ? '50%' : 0,
                    // left: bracketDimensions
                    // 	? `${-bracketDimensions.x + bracketDimensions.width / 2}px`
                    // 	: 0,
                    // width: '100%',
                    // height: '100%',
                    // zoom: zoom,
                    cursor: 'move',
                    // width: `calc(100% - ${windowWidth / zoom}px)`,
                    // height: `calc(100% - ${bracketHeight / zoom}px)`,
                    // transform: `scale(${zoom})`,
                    // transform: `scale(${zoom}) translate(0,0)`,
                    // transform: `translate3d(${translation.x}px, ${translation.y}px, 0) scale(${zoom})`,
                    // transform: `translate3d(scale(${zoom})`,
                    // transform: `scale(${zoom}) translateX(calc(-50% - 300px))`,
                    // transform:
                    // 	viewType === 'zoom'
                    // 		? `translateX(calc(-50% - ${isWidthDown('xs', width)
                    // 				? 450
                    // 				: sidePanelOpen === true ? 300 : 450}px))`
                    // 		: `scale(${zoom})`,
                    // transform:
                    // 	viewType === 'scroll' && bracketLayout === 'left-right'
                    // 		? `translateX(calc(-50% - 450px)) scale(${zoom})`
                    // 		: `scale(${zoom})`,
                    transform: viewType === 'scroll' && `scale(${zoom})`,
                    transformOrigin: 'top left',
                    // transition: 'all 250ms ease-in-out'
                }}
                className="bracket-surface noselect mobilegpu"
            >
                {bracketLayout === 'left-right' ? (
                    <div className="rounds">
                        <div className="b-side">
                            {brackets.loserBracketReversed.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            bracket={brackets.loserBracketReversed}
                                            rounds={rounds}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="draw">
                            {brackets.drawRound.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            bracket={brackets.drawRound}
                                            rounds={rounds}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            // ref={elementsRef.current[i]}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="a-side">
                            {brackets.winnerBracket.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound {...props} bracket={brackets.winnerBracket} rounds={rounds} i={i} onShowScoreModal={showScoreModal} />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="rounds" style={{ display: 'inline-block' }}>
                        <div style={{ display: 'flex' }}>
                            <div className="draw">
                                {brackets.drawRound.map((rounds, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <ForwardRound
                                                {...props}
                                                bracket={brackets.drawRound}
                                                rounds={rounds}
                                                i={i}
                                                onShowScoreModal={showScoreModal}
                                                // ref={elementsRef.current[i]}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className="a-side">
                                {brackets.winnerBracket.map((rounds, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <ForwardRound {...props} bracket={brackets.winnerBracket} rounds={rounds} i={i} onShowScoreModal={showScoreModal} />
                                        </React.Fragment>
                                    );
                                })}
                                <Round />
                            </div>
                        </div>
                        <div className={tournament.tournament_type === 'custom' ? 'a-side' : 'b-side'}>
                            {brackets.loserBracket.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound {...props} bracket={brackets.loserBracket} rounds={rounds} i={i} onShowScoreModal={showScoreModal} />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function buildBracket(tournament_brackets) {
        let bracket = [];
        let roundNum = 0;

        tournament_brackets.forEach((round, i) => {
            if (i > 0 && round.round === tournament_brackets[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== tournament_brackets[i - 1].round) {
                bracket.push([round]);
                roundNum++;
            }
        });

        const loserBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round < 1;
            });

        const loserBracketReversed =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round < 1;
                })
                .reverse();

        const draw =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round === 1;
            });

        const winnerBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round > 1;
            });

        return {
            tournament_brackets,
            loserBracket,
            loserBracketReversed,
            drawRound: draw,
            winnerBracket,
        };

        // console.log(tournament_brackets);
        // console.log('bracket', bracket);
        // console.log('winnerBracket', winnerBracket);
        // console.log('drawRound', draw);
    }

    function buildBracketCustom(rounds) {
        let bracket = [];
        let roundNum = 0;

        rounds.forEach((round, i) => {
            if (i > 0 && round.round === rounds[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== rounds[i - 1].round) {
                bracket.push([round]);
                roundNum++;
            }
        });

        const loserBracket =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round < 1;
                })
                .reverse();

        const draw =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round === 1;
            });

        const winnerBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round > 1;
            });

        const output = {
            tournament_brackets,
            loserBracket,
            drawRound: draw,
            winnerBracket,
        };

        console.log('bracket', output);
        return output;
    }

    const step = discreteScaleStepSize();

    let multiStage = tournament && tournament.tournament_stage_format === 'multi';
    let tournamentBrackets;
    if (multiStage === true) {
        const advanceRounds =
            tournament_brackets &&
            tournament_brackets
                .filter((match) => {
                    return match.advance_to !== null;
                })
                .map((match) => {
                    return match.round;
                });
        console.log('advance rounds', advanceRounds);
        tournamentBrackets =
            tournament_brackets &&
            tournament_brackets
                .filter((match) => {
                    const stageNum = selectedStage ? selectedStage.number : 1;
                    return match.stage_number === stageNum;
                })
                .filter((match, index) => {
                    if (advanceRounds && advanceRounds.length > 1) {
                        return advanceRounds.some((value) => {
                            if (match.identifier.includes('L')) {
                                return value <= match.round;
                            } else {
                                return value >= match.round;
                            }
                        });
                    } else {
                        return true;
                    }
                });
    } else {
        tournamentBrackets = tournament_brackets;
    }

    let brackets;
    if (tournament.bracket_template_id) {
        brackets = buildBracketCustom(tournamentBrackets);
    } else {
        brackets = buildBracket(tournamentBrackets);
    }
    const matches = tournamentBrackets;
    const stages = tournament.stages;

    return (
        <Layout>
            {(isFullScreen === true || showNavigation === false) && (
                <Header
                    className="header-narrow"
                    style={{
                        width: '100%',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {showNavigation !== false ? (
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={toggleFullScreen}
                                style={{
                                    width: 30,
                                    height: 30,
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon type="close" />
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={() => {
                                    props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
                                }}
                                style={{
                                    width: 30,
                                    height: 30,
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon type="arrow-left" />
                            </Button>
                        )}

                        <Title level={4} style={{ margin: 0, color: '#fff', marginLeft: 10 }}>
                            {_.startCase(tournament.name)}
                        </Title>
                    </div>
                    <div className="logo" style={{ position: 'fixed', left: '50%', transform: 'translateX(-50%)' }}>
                        <img src={dpLogoHeader} alt="Logo" width="100%" />
                    </div>
                </Header>
            )}

            <Layout style={{ height: bracketHeight }}>
                <Content
                    style={{ position: 'relative' }}
                    className={bracketLayout === 'left-right' ? `bracket-default ${theme && theme.name}` : `bracket-compact ${theme && theme.name}`}
                >
                    {breakpoint === 'mobile' && viewType === 'scroll' ? (
                        <ScrollContainer
                            id="bracket"
                            className="scroll-container"
                            vertical={true}
                            horizontal={true}
                            hideScrollbars={false}
                            activationDistance={1}
                            ignoreElements=".prevent-drag-scroll"
                            nativeMobileScroll={true}
                            style={{
                                height: bracketHeight,
                                width: '100%',
                                position: 'relative',
                                // background: 'linear-gradient( #fafafa, #f0f2f5)'
                            }}
                            ref={bracketEl}
                        >
                            {renderBracket(brackets, matches)}
                        </ScrollContainer>
                    ) : breakpoint === 'mobile' && viewType === 'zoom' ? (
                        <MapInteractionCSS
                            scale={zoom}
                            // translation={translation}
                            // onChange={({ scale, translation }) => {
                            // 	setZoom(scale);
                            // 	setTranslation(translation);
                            // }}
                            defaultScale={1}
                            // defaultTranslation={{ x: 0, y: 0 }}
                            // minScale={0.05}
                            // maxScale={5}
                            // showControls
                            minScale={minScale}
                            maxScale={maxScale}
                            // scale={zoom}
                            translation={playerSearchCoordinates}
                            showControls
                            // onChange={onMapChange}
                            controlsClass="zoom-buttons-container"
                            btnClass="zoom-buttons"
                            plusBtnClass="zoom-plus"
                            minusBtnClass="zoom-minus"
                            plusBtnContents={<Icon type="zoom-in" />}
                            minusBtnContents={<Icon type="zoom-out" />}
                        >
                            {renderBracket(brackets, matches)}
                        </MapInteractionCSS>
                    ) : viewType === 'zoom' ? (
                        <MapInteractionCSS
                            scale={zoom}
                            // translation={translation}
                            // onChange={({ scale, translation }) => {
                            // 	setZoom(scale);
                            // 	setTranslation(translation);
                            // }}
                            defaultScale={1}
                            // defaultTranslation={{ x: 0, y: 0 }}
                            // minScale={0.05}
                            // maxScale={5}
                            // showControls
                            minScale={minScale}
                            maxScale={maxScale}
                            // scale={zoom}
                            translation={playerSearchCoordinates}
                            showControls
                            // onChange={onMapChange}
                            controlsClass="zoom-buttons-container"
                            btnClass="zoom-buttons"
                            plusBtnClass="zoom-plus"
                            minusBtnClass="zoom-minus"
                            plusBtnContents={<Icon type="zoom-in" />}
                            minusBtnContents={<Icon type="zoom-out" />}
                        >
                            {renderBracket(brackets, matches)}
                        </MapInteractionCSS>
                    ) : (
                        <ScrollContainer
                            id="bracket"
                            className="scroll-container"
                            vertical={true}
                            horizontal={true}
                            hideScrollbars={false}
                            activationDistance={1}
                            ignoreElements=".prevent-drag-scroll"
                            nativeMobileScroll={true}
                            style={{
                                height: '100%',
                                width: '100%',
                                position: 'relative',
                                // background: 'linear-gradient( #fafafa, #f0f2f5)'
                            }}
                            ref={bracketEl}
                        >
                            {renderBracket(brackets, matches)}
                        </ScrollContainer>
                    )}
                </Content>
            </Layout>
        </Layout>
    );
}

export default withRouter(BracketStaticDoubleElim);
