import React from 'react';
import { Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import dfwTourData from './dfw-tour-2021.json';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Search } = Input;

function callback(key) {
    console.log(key);
}

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function TRBMain(props) {
    const players = [];
    const stops = [];
    const tourTournaments = dfwTourData.data.tours[0].tour_tournaments;
    const tourPlayers = tourTournaments.map((item, index) => {
        stops.push(
            item.tournament.tournament_players.map((player) => {
                return {
                    stop: index + 1,
                    tournament_name: item.tournament.name,
                    name: player.name,
                    country: player.country,
                    phone_number: player.phone_number,
                    place: player.place,
                    skill_level: player.skill_level,
                };
            })
        );
        return item.tournament.tournament_players;
    });
    tourPlayers.forEach((item) => {
        item.forEach((player) => {
            players.push(player);
        });
    });

    console.log(stops);

    // const uniquePlayers = _.uniqBy(players, 'name').sort((a, b) => {
    //     return a.name.localeCompare(b.name, undefined, {
    //         numeric: true,
    //         sensitivity: 'base',
    //     });
    // });
    // console.log(uniquePlayers);

    return (
        <React.Fragment>
            <SectionHeader
                compact={true}
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={120}
                subtitle="Create and manage your own pool tours and series"
                subtitleSuffix={true}
                subtitleStyle={{ color: '#fff' }}
            >
                <Link to="/tour-builder/new">
                    <Button size="large" type="ghost" ghost>
                        Create Tour
                        <Icon type="arrow-right" />
                    </Button>
                </Link>
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Row gutter={16} style={styles.row}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card title="Create a new tour">
                            <div style={{ marginBottom: 16 }}>
                                <Text>Want to create your own multi-event tour or tournament series? Get started below!</Text>
                            </div>
                            <Button type="primary">Get Started</Button>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card title="Everything you need to know">
                            <div style={{ marginBottom: 16 }}>
                                <Text>Have questions? We have answers! Check out our Streaming How-to Guide and FAQs.</Text>
                            </div>
                            <Button>How-To Guide</Button>
                            <Button>FAQ</Button>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card style={styles.card}>
                            <Empty
                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                imageStyle={{
                                    height: 100,
                                }}
                                description={<Text style={{ fontSize: 18 }}>You have not created any tours yet.</Text>}
                                style={{ margin: 10 }}
                            >
                                <Button>Create Tour</Button>
                                <Button>View All Tours</Button>
                            </Empty>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </React.Fragment>
    );
}
