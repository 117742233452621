import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography } from 'antd';
import dpIcon from '../../assets/img/dp-icon.png';

const { Title, Text } = Typography;

export default function Success(props, context) {
    const styles = {
        card: {
            maxWidth: 'auto',
            borderRadius: '6px',
            boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
            margin: 24,
            flex: 1,
            // background: 'transparent',
            // boxShadow: 'none',
            // border: 'none',
        },
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Card style={styles.card}>
                <div style={{ maxWidth: 400, margin: 'auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={dpIcon} alt="Logo" height={100} />
                    </div>
                    <Title level={2} style={{ textAlign: 'center', marginTop: 24 }}>
                        You have logged in successfully.
                    </Title>
                </div>
            </Card>
        </div>
    );
}
