import React, { useState, useEffect, useRef } from 'react';
import { message, Avatar, Collapse, Radio, Typography, Layout, Modal, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, InputNumber } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    UPDATE_TOURNAMENT_MATCH_GAME,
    CREATE_TOURNAMENT_MATCH_GAME,
    DELETE_TOURNAMENT_MATCH_GAME,
    RESET_TOURNAMENT_MATCH_GAMES,
} from './data/mutations';
import { GET_TOURNAMENT_BRACKET_MATCH_QUERY, GET_TOURNAMENT_MATCH_GAMES_QUERY, GET_EVENT_LIVE_TABLE_QUERY } from './data/queries';
import { Query, Subscription } from '@apollo/client/react/components';
import { advanceTournamentPlayers } from '../../../utils';
import Card, { Meta } from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import CircleStateFlag from '../../../components/CircleStateFlag';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import axios from 'axios';
import apiEndPoint from '../../../utils/apiEndpoint';
import ArtisticShotModal from '../../digitalpool/matches/ArtisticShotModal';
import tableBlue from '../../../assets/img/table/table-blue.png';
import slugify from 'slugify';
// import RackTracker from './RackTracker';
import { QuestionCircleFilled } from '@ant-design/icons';
import numeral from 'numeral';
import Ripples from 'react-ripples';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;
const { Sider, Content, Header } = Layout;
const { Panel } = Collapse;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
    btnPoints: {
        fontSize: 30,
        fontWeight: 700,
        width: 60,
        height: 55,
        lineHeight: '50px',
    },
};

function EventScoreboardArtistic(props) {
    const { client, backUrl, matchData, tournamentData, tableData, subscribeToMore, subscribeToNewData, theme } = props;
    const [endMatchDisabled, setEndMatchDisabled] = useState(true);
    const [updateBracketMatch, updateBracketMatchRes] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION);
    // const [scoringStarted, setScoringStarted] = useState(false);
    const [scoringStarted, setScoringStarted] = useState(matchData.is_scoring_started === true ? true : false);
    const [userCheckMatchEnding, setUserCheckMatchEnding] = useState(false);
    const [shotModalVisible, setShotModalVisible] = useState(false);
    const [selectedShot, setSelectedShot] = useState();
    const [thumb, setThumb] = useState();
    const [matchEnded, setMatchEnded] = useState(false);
    const formRef = useRef();

    const stats = ['Balls pocketed', 'Unforced errors'];
    const table = tableData;
    const challenger1 = matchData && matchData.challenger1;
    const challenger2 = matchData && matchData.challenger2;

    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData.identifier && matchData.identifier.includes('L')) {
        challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
    } else {
        challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
    }

    let duration;
    if (matchData.start_time && matchData.end_time) {
        var start = moment(matchData.start_time);
        var end = moment(matchData.end_time);
        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
    } else {
        duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('hh:mm');
    }

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        if (matchData) {
            if (matchData.is_scoring_started && matchData.is_scoring_started !== scoringStarted) {
                setScoringStarted(matchData.is_scoring_started);
            }
            if (matchData.status === 'COMPLETED') {
                setEndMatchDisabled(true);
            }
            // else if (matchData.status !== 'COMPLETED' && checkForWinner(matchData) === true && matchData.status !== 'COMPLETED') {
            //     setEndMatchDisabled(false);
            //     showConfirmEndMatch(matchData);
            // }
            else {
                setEndMatchDisabled(false);
            }
        }
    }, [matchData]);

    async function handleShotModalOk(player) {
        console.log(player);
        setShotModalVisible(false);
    }

    function handleShotModalCancel(e) {
        setShotModalVisible(false);
    }

    async function startMatch(matchData, tournamentId) {
        console.log(matchData);

        await resetGames(matchData);

        const startTime = moment.utc();
        const changes = {
            start_time: startTime,
            status: 'IN_PROGRESS',
            progress: 0,
            is_scoring_started: true,
        };

        const tableSlug = slugify(matchData.table_name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: matchData.id,
                    tournament_id: tournamentId,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
            })
            .then((data) => {
                console.log(data);
                message.success(`Match started at ${moment.utc(startTime).local().format('LT')}`);

                createFirstGame(matchData);

                // if (tournamentData && tournamentData.livestream_split_url) {
                //     const payload = {
                //         tournament_id: tournamentId,
                //         tournament_bracket_id: matchData.id,
                //         match_id: matchData.id,
                //         match_number: matchData.match_number,
                //         table_id: matchData.table_id,
                //         table_name: matchData.table_name,
                //         table_slug: matchData.tableSlug,
                //         game_type: matchData.game_type,
                //         player: matchData.player_name,
                //         opponent: matchData.opponent_name,
                //         event: 'start',
                //         webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
                //         callback_url: tournamentData.livestream_split_url,
                //     };

                //     return axios
                //         .post(`${apiEndPoint}matchStatusUpdate`, payload)
                //         .then((res) => {
                //             console.log(res.data);

                //             // if (res.data && res.data.success === true) {
                //             //     message.success(JSON.stringify(res.data));
                //             // } else {
                //             //     message.warning(JSON.stringify(res.data));
                //             // }

                //             return res.data;
                //         })
                //         .catch((error) => {
                //             console.log(error);
                //             // message.error(`There was an error: ${JSON.stringify(error)}`);
                //             return error;
                //         });
                // }
            })
            .catch((error) => {
                console.error(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                throw error;
            });
    }

    function endMatch(match, tournamentID, winner, loser) {
        const endTime = moment.utc();
        let changes;
        const challenger1_winner = winner.id === match.challenger1_id ? true : false;
        const challenger2_winner = winner.id === match.challenger2_id ? true : false;

        if (match && match.tournament && match.tournament.autopilot_mode === true) {
            changes = {
                end_time: endTime,
                status: 'COMPLETED',
                progress: 100,
                table_name: match.table_name && match.table_name,
                table_id: match.table_id && match.table_id,
                ...(challenger1_winner && { challenger1_is_winner: true, challenger2_is_winner: false }),
                ...(challenger2_winner && { challenger2_is_winner: true, challenger1_is_winner: false }),
            };

            if (match.tournament && match.tournament.livestream_split_url) {
                console.log('LIVE STREAM SPLIT URL', match.tournament && match.tournament.livestream_split_url);
                const tableSlug = slugify(table.label, {
                    replacement: '-',
                    remove: /[*+~.,()'"#!:/@]/g,
                    lower: true,
                });

                const payload = {
                    tournament_id: tournamentID,
                    match_id: match.id,
                    match_number: match.match_number,
                    table_id: match.table_id,
                    table_name: table.label,
                    table_slug: tableSlug,
                    game_type: match.tournament.game_type,
                    player: match.challenger1_name,
                    opponent: match.challenger2_name,
                    event: 'split',
                    webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
                    callback_url: match.tournament.livestream_split_url,
                };

                axios
                    .post(`${apiEndPoint}matchStatusUpdate`, payload)
                    .then((res) => {
                        console.log(res.data);
                        // message.success(JSON.stringify(res.data));
                        return res.data;
                    })
                    .catch((error) => {
                        console.log(error);
                        // message.error(`There was an error: ${JSON.stringify(error)}`);
                        return error;
                    });
            }
        } else {
            changes = {
                end_time: endTime,
                status: 'PENDING',
                progress: 100,
                table_name: match.table_name && match.table_name,
                table_id: match.table_id && match.table_id,
                ...(challenger1_winner && { challenger1_is_winner: true, challenger2_is_winner: false }),
                ...(challenger2_winner && { challenger2_is_winner: true, challenger1_is_winner: false }),
            };
        }

        const tableSlug = slugify(matchData.table_name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (tableData && tableData.kiosk_ip_address) {
            axios
                .post(`${apiEndPoint}fullyKiosk`, {
                    endpoint: `${tableData.kiosk_ip_address}&cmd=loadStartUrl`,
                })
                .then((res) => {
                    console.log(res.data);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    return error;
                });
        }

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                        variables: { match_id: matchData.id, tournament_id: tournamentID },
                    },
                ],
                // refetchQueries: [
                //     {
                //         query: GET_EVENT_LIVE_TABLE_QUERY2,
                //         variables: {
                //             event_slug: matchData.eventSlug,
                //             table_slug: matchData.tableSlug,
                //         },
                //     },
                // ],
            })
            .then((data) => {
                console.log(data);

                if (match && match.tournament && match.tournament.autopilot_mode === true) {
                    advanceTournamentPlayers(client, tournamentID, match, winner, loser);
                    message.success(`Match scores submitted at ${moment.utc(endTime).local().format('LT')}`);
                } else {
                    message.success(`Match scores submitted for TD approval at ${moment.utc(endTime).local().format('LT')}`);
                }

                // axios
                //     .post(
                //         'https://elicit.waypointsoftware.io/capture_post.php?xAuthentication=09e648487cf9156231d88645459d5ef0',
                //         {
                //             match_id: matchData.id,
                //         },
                //         {
                //             headers: {
                //                 'Content-Type': 'multipart/form-data',
                //             },
                //         }
                //     )
                //     .then((response) => console.log(response))
                //     .catch((error) => {
                //         console.error('There was an error!', error);
                //     });

                // if (tournamentData && tournamentData.livestream_split_url) {
                //     const payload = {
                //         tournament_id: tournamentId,
                //         match_id: matchData.id,
                //         match_number: matchData.match_number,
                //         table_id: matchData.table_id,
                //         table_name: matchData.table_name,
                //         table_slug: tableSlug,
                //         game_type: matchData.game_type,
                //         player: matchData.player_name,
                //         opponent: matchData.opponent_name,
                //         event: 'split',
                //         webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
                //         callback_url: tournamentData.livestream_split_url,
                //     };

                //     return axios
                //         .post(`${apiEndPoint}matchStatusUpdate`, payload)
                //         .then((res) => {
                //             console.log(res.data);

                //             // if (res.data && res.data.success === true) {
                //             //     message.success(JSON.stringify(res.data));
                //             // } else {
                //             //     message.warning(JSON.stringify(res.data));
                //             // }

                //             return res.data;
                //         })
                //         .catch((error) => {
                //             console.log(error);
                //             // message.error(`There was an error: ${JSON.stringify(error)}`);
                //             return error;
                //         });
                // }
            })
            .catch((error) => {
                console.error(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                throw error;
            });
    }

    function showConfirmEndMatch(matchData) {
        const { winner, loser } = determineMatchWinner(matchData);
        setUserCheckMatchEnding(true);
    }

    function determineMatchWinner(matchData) {
        let challenger1RaceTo;
        let challenger2RaceTo;
        if (matchData.identifier && matchData.identifier.includes('L')) {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        } else {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        }
        let winner;
        let loser;

        if (matchData.challenger1_score >= challenger1RaceTo) {
            winner = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: matchData.challenger1_score,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: matchData.challenger2_score,
                is_winner: false,
            };
        } else if (matchData.challenger2_score >= challenger2RaceTo) {
            winner = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: matchData.challenger2_score,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: matchData.challenger1_score,
                is_winner: false,
            };
        }

        console.log({
            winner,
            loser,
        });

        return {
            winner,
            loser,
        };
    }

    function createFirstGame(matchData) {
        console.log(matchData);
        return client
            .mutate({
                mutation: CREATE_TOURNAMENT_MATCH_GAME,
                variables: {
                    objects: [
                        {
                            tournament_id: tournamentData.id,
                            tournament_bracket_id: matchData.id,
                            match_number: matchData.match_number,
                            game_number: 0,
                            player_id: matchData.challenger1_id,
                            player_score: 0,
                            player_points: 0,
                            player_is_winner: false,
                            player_break_run: false,
                            player_runout: false,
                            opponent_id: matchData.challenger2_id,
                            opponent_score: 0,
                            opponent_points: 0,
                            opponent_is_winner: false,
                            opponent_break_run: false,
                            opponent_runout: false,
                            status: 'IN_PROGRESS',
                            progress: 0,
                            duration: null,
                            start_time: moment.utc(),
                            end_time: moment.utc(),
                            table_id: matchData.table_id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function resetGames(matchData) {
        return client
            .mutate({
                mutation: RESET_TOURNAMENT_MATCH_GAMES,
                variables: {
                    tournament_id: tournamentData.id,
                    tournament_bracket_id: matchData.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function getMatchProgress(matchData) {
        const totalRace = parseInt(matchData.challenger1_race_to + matchData.challenger2_race_to);
        const matchProgress =
            matchData.status === 'COMPLETED'
                ? 100
                : parseInt(matchData.challenger1_score) >= totalRace || parseInt(matchData.challenger2_score) >= totalRace
                ? 100
                : (((matchData.challenger1_score + matchData.challenger2_score) / totalRace) * 100).toFixed(0);
        return parseInt(matchProgress);
    }

    function incrementPlayerScore(challenger, score, matchData, gameData, stats) {
        const matchProgress = getMatchProgress(matchData);
        const totalScore = matchData.challenger1_score + matchData.challenger2_score;
        const changes = {
            ...(challenger === 'challenger1' ? { challenger1_score: score } : { challenger2_score: score }),
            progress: matchProgress,
        };
        console.log('total score', totalScore);
        updateMatch(changes, matchData)
            .then((result) => {
                console.log(result);
                // addGame(challenger, newScore, matchData, tournamentData, gameData, stats);
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
            });
    }

    function decrementPlayerScore(challenger, score, matchData, gameData) {
        const matchProgress = getMatchProgress(matchData);
        const totalScore = matchData.challenger1_score + matchData.challenger2_score - 1;
        const changes = {
            ...(challenger === 'challenger1' ? { challenger1_score: score } : { challenger2_score: score }),
            ...(totalScore === 0 && { is_scoring_started: false }),
            progress: matchProgress,
        };

        console.log('total score', totalScore);
        updateMatch(changes, matchData)
            .then((result) => {
                console.log(result);
                // if (totalScore > 0) {
                //     removeGame(challenger, matchData, tournamentData, gameData);
                // } else {
                //     console.log('reset games');
                //     resetGames(matchData);
                // }
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
            });
    }

    function updateMatch(changes, matchData) {
        console.log(matchData);
        console.log('changes', changes);

        if (matchData.eventSlug) {
            return updateBracketMatch({
                variables: {
                    id: matchData.id,
                    tournament_id: tournamentData.id,
                    changes: changes,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //         variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                //     },
                // ],
                optimisticResponse: {
                    updateMatch: {
                        __typename: 'tournament_brackets_mutation_response',
                        returning: {
                            ...changes,
                        },
                    },
                },
                update: (cache, { data }) => {
                    console.log({
                        cache,
                        data,
                    });

                    if (matchData.eventSlug) {
                        // Get the current cached data.
                        const existingMatchData = client.readQuery({
                            // The cached query key is the same as the name of the GQL schema
                            query: GET_EVENT_LIVE_TABLE_QUERY,
                            variables: { event_slug: matchData.eventSlug, table_slug: matchData.tableSlug },
                        });

                        // Now we combine the optimisticResponse we passed in earlier and the existing data
                        let newMatches;
                        if (existingMatchData && existingMatchData.tournaments) {
                            newMatches = [data.update_tournament_brackets, existingMatchData.tournaments];
                        } else {
                            newMatches = [data.update_tournament_brackets];
                        }

                        console.log('new matches', newMatches);
                        // Finally we overwrite the cache
                        if (newMatches && newMatches.length > 0) {
                            cache.writeQuery({
                                query: GET_EVENT_LIVE_TABLE_QUERY,
                                variables: { event_slug: matchData.eventSlug, table_slug: matchData.tableSlug },
                                data: newMatches,
                            });
                        }
                    }
                },
            })
                .then((data) => {
                    console.log(data);
                    // handleMatchUpdate(matchData);
                    return data;
                    // message.success({
                    // 	content: `${text} updated`,
                    // 	key,
                    // 	duration: 1
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    message.info('There was an error', error);
                    return error;
                });
        } else {
            return updateBracketMatch({
                variables: {
                    id: matchData.id,
                    tournament_id: tournamentData.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                        variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                    },
                ],
            })
                .then((data) => {
                    console.log(data);
                    // handleMatchUpdate(matchData);
                    return data;
                    // message.success({
                    // 	content: `${text} updated`,
                    // 	key,
                    // 	duration: 1
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    message.info('There was an error', error);
                    return error;
                });
        }
    }

    function startScoring() {
        setScoringStarted(true);
    }

    function calculateTPA(ballsPocketed, errors) {
        return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
    }

    function handleSubmit(player, playerData, stat, value, values, gameData) {
        console.log({
            player,
            playerData,
            stat,
            value,
            values,
            gameData,
        });

        const gameId = gameData && gameData[gameData.length - 1] && gameData[gameData.length - 1].id;
        if (gameId) {
            const playerBallsPocketed = values.player_balls_pocketed;
            const playerUnforcedErrors = values.player_unforced_errors;
            const opponentBallsPocketed = values.opponent_balls_pocketed;
            const opponentUnforcedErrors = values.opponent_unforced_errors;
            const changes = {
                player_tpa: calculateTPA(playerBallsPocketed, playerUnforcedErrors),
                player_balls_pocketed: playerBallsPocketed,
                player_unforced_errors: playerUnforcedErrors,
                opponent_tpa: calculateTPA(opponentBallsPocketed, opponentUnforcedErrors),
                opponent_balls_pocketed: opponentBallsPocketed,
                opponent_unforced_errors: opponentUnforcedErrors,
            };

            updateGame(gameId, changes);
        }
    }

    function updateGame(gameId, changes) {
        console.log({
            gameId,
            changes,
        });
        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_MATCH_GAME,
                variables: {
                    id: gameId,
                    tournament_id: tournamentData.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                // message.success('Game updated');
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function isDisabled(matchData) {
        return matchData.status === 'COMPLETED' || matchData.status === 'COMPLETED';
    }

    function setPlayerTurn(player) {
        let currentPlayer;
        if (player === 'challenger1') {
            currentPlayer = matchData.challenger1_name;
        } else {
            currentPlayer = matchData.challenger2_name;
        }

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: tournamentData.id,
                changes: {
                    ...(player === 'challenger1'
                        ? { challenger1_is_playing: true, challenger2_is_playing: false }
                        : { challenger2_is_playing: true, challenger1_is_playing: false }),
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                },
            ],
        })
            .then((data) => {
                const key = 'playerTurn';

                console.log(data);
                message.success({
                    content: `${currentPlayer} is at the table`,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function formatPlayerName(matchData, challenger) {
        if (challenger === 'challenger1') {
            return ((matchData && matchData.challenger1_name && matchData.challenger1_skill_level) || (challenger1 && challenger1.skill_level)) &&
                matchData.challenger1_race_to &&
                tournamentData.show_player_skill_levels === true &&
                tournamentData.show_player_races === true
                ? matchData &&
                      `${matchData.challenger1_name} ${matchData.challenger1_skill_level || (challenger1 && challenger1.skill_level)} (${
                          matchData.challenger1_race_to
                      })`
                : matchData && matchData.challenger1_name && matchData.challenger1_race_to && tournamentData.show_player_races === true
                ? matchData && `${matchData.challenger1_name} (${matchData.challenger1_race_to})`
                : matchData && `${matchData.challenger1_name}`;
        } else if (challenger === 'challenger2') {
            return ((matchData && matchData.challenger2_name && matchData.challenger2_skill_level) || (challenger1 && challenger2.skill_level)) &&
                matchData.challenger2_race_to &&
                tournamentData.show_player_skill_levels === true &&
                tournamentData.show_player_races === true
                ? matchData &&
                      `${matchData.challenger2_name} ${matchData.challenger2_skill_level || (challenger1 && challenger2.skill_level)} (${
                          matchData.challenger2_race_to
                      })`
                : matchData && matchData.challenger2_name && matchData.challenger2_race_to && tournamentData.show_player_races === true
                ? matchData && `${matchData.challenger2_name} (${matchData.challenger2_race_to})`
                : matchData && `${matchData.challenger2_name}`;
        }
    }

    return (
        <Query
            query={GET_TOURNAMENT_MATCH_GAMES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournamentData.id, tournament_bracket_id: matchData.id }}
            onCompleted={(data, error, loading) => {
                console.log('match games', data);
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>Error: {error.message}</div>;

                const gameData = data && data.tournament_match_games;
                const currentGame = gameData && gameData[gameData.length - 1];
                console.log(currentGame);

                const initialValues = {};
                stats.forEach((stat, index) => {
                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                    const keyPlayer = `player_${statUnderscored}`;
                    const keyOpponent = `opponent_${statUnderscored}`;
                    initialValues[keyPlayer] = (currentGame && currentGame[keyPlayer]) || 0;
                    initialValues[keyOpponent] = (currentGame && currentGame[keyOpponent]) || 0;
                });

                return (
                    <Layout style={{ height: '100%', backgroundColor: 'transparent' }}>
                        <Content style={{ position: 'relative', display: 'flex', alignItems: 'center', backgroundColor: 'transparent', padding: 10 }}>
                            {thumb && (
                                <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, width: 800 }}>
                                    <img src={`data:image/png;base64, ${thumb}`} alt="Table Thumbnail" width="100%" />
                                </div>
                            )}

                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 10,
                                    paddingRight: 20,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    zIndex: 11,
                                    // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                                }}
                            >
                                <div />
                                <div>
                                    {matchEnded === true && (
                                        <div>
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Title
                                                    level={1}
                                                    style={{
                                                        padding: 20,
                                                        margin: 0,
                                                        color: '#fff',
                                                        background: 'rgba(255,255,255,0.05)',
                                                        borderRadius: 20,
                                                        textTransform: 'uppercase',
                                                        letterSpacing: 2,
                                                        fontSize: 60,
                                                    }}
                                                >
                                                    Match finished
                                                </Title>
                                            </div>
                                        </div>
                                    )}

                                    {userCheckMatchEnding === true && matchEnded === false && (
                                        <div>
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Ripples color="#000">
                                                    <Button
                                                        size="large"
                                                        type="primary"
                                                        onClick={() => {
                                                            setMatchEnded(true);
                                                            const { winner, loser } = determineMatchWinner(matchData);
                                                            endMatch(matchData, tournamentData.id, winner, loser);
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            paddingLeft: 40,
                                                            paddingRight: 40,
                                                            height: 160,
                                                            fontSize: 100,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        SUBMIT SCORES
                                                    </Button>
                                                </Ripples>
                                                <Ripples color="#000">
                                                    <Button
                                                        size="large"
                                                        type="ghost"
                                                        onClick={() => {
                                                            setUserCheckMatchEnding(false);
                                                        }}
                                                        style={{
                                                            marginTop: 20,
                                                            paddingLeft: 40,
                                                            paddingRight: 40,
                                                            height: 140,
                                                            fontSize: 80,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            color: '#fff',
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        GO BACK
                                                    </Button>
                                                </Ripples>
                                            </div>
                                        </div>
                                    )}
                                    {matchData.status === 'IN_PROGRESS' &&
                                    (matchData.challenger1_score > 0 || matchData.challenger2_score > 0) &&
                                    userCheckMatchEnding === false ? (
                                        <Tooltip
                                            placement="left"
                                            title={
                                                endMatchDisabled === true
                                                    ? matchData.status === 'COMPLETED'
                                                        ? 'Match scores have already been submitted and are awaiting approval.'
                                                        : 'Cannot submit scores until a winner has been determined'
                                                    : 'Submit scores for approval'
                                            }
                                        >
                                            <Button
                                                size="large"
                                                type="ghost"
                                                ghost
                                                onClick={() => {
                                                    showConfirmEndMatch(matchData, tournamentData.id);
                                                }}
                                                disabled={endMatchDisabled || false}
                                                className={endMatchDisabled === true ? 'disabled' : ''}
                                            >
                                                Submit scores
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        matchData.status === 'COMPLETED' && <Text style={{ color: '#fff' }}>Scores pending TD approval</Text>
                                    )}
                                </div>
                            </div>

                            {userCheckMatchEnding === true && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 10,
                                        background: 'rgba(0,0,0,0.6)',
                                    }}
                                />
                            )}

                            <div
                                style={{
                                    flex: 1,
                                    alignItems: 'center',
                                    // padding: 20,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 0, minHeight: 20 }}>
                                    {tournamentData.logo && <img src={tournamentData.logo} alt="Logo" height={120} />}
                                </div>

                                <Formik
                                    ref={formRef}
                                    onSubmit={(data, actions) => {
                                        console.log(data);
                                    }}
                                    // validationSchema={schema}
                                    enableReinitialize
                                    initialValues={initialValues}
                                    render={({ errors, touched, values }) => (
                                        <Form
                                            layout="vertical"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                console.log(values);
                                                // handleSubmit(values);
                                            }}
                                            style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}
                                        >
                                            <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%', position: 'relative' }}>
                                                <Col xs={12} sm={12}>
                                                    <Title level={2} style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, color: '#fff' }}>
                                                        {table.label} - Match {matchData.match_number} ({matchData.identifier})
                                                    </Title>
                                                </Col>
                                                <div style={{ top: 10, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                                    <Title level={3} style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, color: '#fff', fontWeight: 400 }}>
                                                        {tournamentData.name}
                                                    </Title>
                                                </div>
                                                <Col xs={12} sm={12} align="right">
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        {/* <Avatar
                                                            size={28}
                                                            src={getGameType(matchData.game_type, 'dark')}
                                                            style={{ width: 30, marginRight: 10 }}
                                                        /> */}
                                                        <Title level={2} style={{ marginRight: 20, marginTop: 0, marginBottom: 0, color: '#fff' }}>
                                                            {selectedShot && (
                                                                <React.Fragment>
                                                                    {selectedShot.name}
                                                                    {/* D{selectedShot.discipline}-{selectedShot.shot && `${selectedShot.shot.toUpperCase()}`} (
                                                                    {selectedShot.points} points) - {selectedShot.name} */}
                                                                </React.Fragment>
                                                            )}
                                                        </Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={24} type="flex" justify="space-between" style={{ width: '100%' }}>
                                                <Col xs={12} sm={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            background:
                                                                matchData.challenger1_score > matchData.challenger2_score
                                                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                                                    : 'linear-gradient(-45deg, black, transparent)',
                                                            // background: 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)',
                                                            borderRadius: 30,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            padding: 20,
                                                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                                            cursor: 'pointer',
                                                            height: '100%',
                                                        }}
                                                        onClick={() => {
                                                            setPlayerTurn('challenger1');
                                                        }}
                                                        className="ripple"
                                                    >
                                                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                                                            {matchData.challenger1_country && (
                                                                <React.Fragment>
                                                                    {matchData.challenger1_state ? (
                                                                        <CircleStateFlag
                                                                            state={matchData.challenger1_state}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    ) : (
                                                                        <CircleFlag
                                                                            country={getCountryName(matchData.challenger1_country) || 'Unknown'}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                            <Text style={{ fontSize: 40, fontWeight: 700, color: '#fff', textTransform: 'uppercase' }}>
                                                                {formatPlayerName(matchData, 'challenger1')}
                                                            </Text>
                                                        </div>
                                                        <div>
                                                            <Text style={{ margin: 0, fontSize: 200, lineHeight: '210px', fontWeight: 700, color: '#fff' }}>
                                                                {matchData.challenger1_score || 0}
                                                            </Text>
                                                        </div>
                                                        {matchData.challenger1_is_playing === true && <div className="arrow-right"></div>}

                                                        {/* <div
                                                        style={{
                                                            backgroundColor: 'rgba(255,255,255, 0.05)',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            borderRadius: 10,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        <Text style={{ color: '#fff', fontWeight: 700, fontSize: 25 }}>
                                                            TPA: {(currentGame && currentGame.player_tpa) || '0.000'}
                                                        </Text>
                                                    </div> */}
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            background:
                                                                matchData.challenger2_score > matchData.challenger1_score
                                                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                                                    : 'linear-gradient(45deg, black, transparent)',
                                                            // background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                                                            borderRadius: 30,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            padding: 20,
                                                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                                            cursor: 'pointer',
                                                            height: '100%',
                                                        }}
                                                        onClick={() => {
                                                            setPlayerTurn('challenger2');
                                                        }}
                                                        className="ripple"
                                                    >
                                                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                                                            {matchData.challenger2_country && (
                                                                <React.Fragment>
                                                                    {matchData.challenger2_state ? (
                                                                        <CircleStateFlag
                                                                            state={matchData.challenger2_state}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    ) : (
                                                                        <CircleFlag
                                                                            country={getCountryName(matchData.challenger2_country) || 'Unknown'}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                            <Text style={{ fontSize: 40, fontWeight: 700, color: '#fff', textTransform: 'uppercase' }}>
                                                                {formatPlayerName(matchData, 'challenger2')}
                                                            </Text>
                                                        </div>
                                                        <div>
                                                            <Text style={{ margin: 0, fontSize: 200, lineHeight: '210px', fontWeight: 700, color: '#fff' }}>
                                                                {matchData.challenger2_score || 0}
                                                            </Text>
                                                        </div>
                                                        {matchData.challenger2_is_playing === true && <div className="arrow-left"></div>}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="space-between" style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                                                <Col xs={12} sm={12}>
                                                    <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="primary"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={
                                                                    isDisabled(matchData) === true ||
                                                                    matchData.challenger1_score === matchData.challenger1_race_to
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    if (matchData.challenger1_score >= 0) {
                                                                        const newScore = matchData.challenger1_score + 1;
                                                                        incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={
                                                                    isDisabled(matchData) === true ||
                                                                    matchData.challenger1_score === matchData.challenger1_race_to
                                                                        ? 'disabled'
                                                                        : ''
                                                                }
                                                            >
                                                                <Icon type="plus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                        <div style={{ width: 20 }} />
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="danger"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={isDisabled(matchData) === true || matchData.challenger1_score === 0 ? true : false}
                                                                onClick={() => {
                                                                    if (matchData.challenger1_score > 0) {
                                                                        const newScore = matchData.challenger1_score - 1;
                                                                        decrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={
                                                                    isDisabled(matchData) === true || matchData.challenger1_score === 0 ? 'disabled' : ''
                                                                }
                                                            >
                                                                <Icon type="minus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="primary"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={
                                                                    isDisabled(matchData) === true ||
                                                                    matchData.challenger2_score === matchData.challenger2_race_to
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    if (matchData.challenger2_score >= 0) {
                                                                        // showConfirmStats('.opponent', matchData.opponent_score, matchData, gameData);
                                                                        const newScore = matchData.challenger2_score + 1;
                                                                        incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={
                                                                    isDisabled(matchData) === true ||
                                                                    matchData.challenger2_score === matchData.challenger2_race_to
                                                                        ? 'disabled'
                                                                        : ''
                                                                }
                                                            >
                                                                <Icon type="plus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                        <div style={{ width: 20 }} />
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="danger"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={isDisabled(matchData) === true || matchData.challenger2_score === 0 ? true : false}
                                                                onClick={() => {
                                                                    if (matchData.challenger2_score > 0) {
                                                                        const newScore = matchData.challenger2_score - 1;
                                                                        decrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={
                                                                    isDisabled(matchData) === true || matchData.challenger2_score === 0 ? 'disabled' : ''
                                                                }
                                                            >
                                                                <Icon type="minus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: -120 }}>
                                                <Col xs={24} align="center">
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Tag
                                                            size="large"
                                                            color={
                                                                matchData.status === 'NOT_STARTED'
                                                                    ? '#80848c'
                                                                    : matchData.status === 'IN_PROGRESS'
                                                                    ? '#108ee9'
                                                                    : matchData.status === 'COMPLETED'
                                                                    ? '#009ba2'
                                                                    : '#cd201f'
                                                            }
                                                        >
                                                            {matchData.status}
                                                        </Tag>
                                                    </div>
                                                    {selectedShot && (
                                                        <div>
                                                            <Text style={{ fontSize: 40, color: '#fff', fontWeight: 700 }}>
                                                                D{selectedShot.discipline}-{selectedShot.shot && `${selectedShot.shot.toUpperCase()}`} (
                                                                {selectedShot.points} points)
                                                            </Text>
                                                        </div>
                                                    )}

                                                    <div>
                                                        <Text style={{ fontSize: 30, color: '#fff' }}>{duration || '0 minutes'}</Text>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="center" style={{ width: '100%', marginTop: 20 }}>
                                                <Col align="center">
                                                    <div style={{ marginTop: 5, marginBottom: 15 }}>
                                                        <Title level={4} style={{ fontSize: 18 }}>
                                                            Add points for p1
                                                        </Title>
                                                    </div>
                                                    <Radio.Group value="large" size="large" disabled={selectedShot ? false : true} style={{ marginRight: 20 }}>
                                                        <Radio.Button
                                                            value={4}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 4;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 4 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            4
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={5}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 5;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 5 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            5
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={6}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 6;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 6 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            6
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={7}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 7;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 7 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            7
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={8}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 8;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 8 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            8
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={9}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 9;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 9 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            9
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={10}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger1_score + 10;
                                                                incrementPlayerScore('challenger1', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 10 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            10
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                                <Col align="center">
                                                    {selectedShot ? (
                                                        <div
                                                            style={{
                                                                backgroundColor: '#fff',
                                                                padding: 6,
                                                                borderRadius: 5,
                                                                overflow: 'hidden',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setShotModalVisible(true);
                                                            }}
                                                        >
                                                            <img src={selectedShot.image_url} alt={selectedShot.name} style={{ width: 250 }} />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <img src={tableBlue} width="100%" alt="Pool Table" style={{ width: 250 }} />
                                                            <Title level={4} style={{ position: 'relative', marginTop: -80 }}>
                                                                No shot selected
                                                            </Title>
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col align="center">
                                                    <div style={{ marginTop: 5, marginBottom: 15 }}>
                                                        <Title level={4} style={{ fontSize: 18 }}>
                                                            Add points for p2
                                                        </Title>
                                                    </div>
                                                    <Radio.Group value="large" size="large" disabled={selectedShot ? false : true} style={{ marginLeft: 20 }}>
                                                        <Radio.Button
                                                            value={4}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 4;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 4 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            4
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={5}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 5;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 5 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            5
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={6}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 6;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 6 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            6
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={7}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 7;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 7 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            7
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={8}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 8;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 8 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            8
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={9}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 9;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 9 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            9
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={10}
                                                            onClick={() => {
                                                                const newScore = matchData.challenger2_score + 10;
                                                                incrementPlayerScore('challenger2', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 10 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            10
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="center" style={{ width: '100%' }}>
                                                <Col align="center">
                                                    {selectedShot ? (
                                                        <div>
                                                            {/* <div>
                                                                <Title level={4} style={{ fontSize: 16, margin: 0 }}>
                                                                    {selectedShot.name}
                                                                </Title>
                                                            </div> */}
                                                            <div style={{ marginTop: 10 }}>
                                                                <Button
                                                                    type="primary"
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setShotModalVisible(true);
                                                                    }}
                                                                >
                                                                    View Shot Catalog
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div style={{ marginTop: 50 }}>
                                                                <Button
                                                                    type="primary"
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setShotModalVisible(true);
                                                                    }}
                                                                >
                                                                    View Shot Catalog
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                />
                            </div>
                        </Content>
                        <ArtisticShotModal
                            currentShot={selectedShot}
                            modalVisible={shotModalVisible}
                            onModalOk={handleShotModalOk}
                            onModalCancel={handleShotModalCancel}
                            onShotSelected={(value) => {
                                console.log('shot data', value);
                                setSelectedShot(value);
                            }}
                        />
                    </Layout>
                );
            }}
        </Query>
    );
}

export default withRouter(EventScoreboardArtistic);
