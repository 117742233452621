import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, updateProfile } from 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/auth'
import { getDatabase, ref, set } from 'firebase/database';
import { getFunctions } from 'firebase/functions';

// import 'firebase/storage';
// import 'firebase/firestore'
// import 'firebase/messaging';
import * as helpers from './helpers';

const facebookProvider = new FacebookAuthProvider();
// facebookProvider.addScope('user_birthday');
facebookProvider.setCustomParameters({
    display: 'popup',
});
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const providers = {
    google: googleProvider,
    facebook: facebookProvider,
    twitter: twitterProvider,
};

let firebase;
let auth = {};
let storage = {};
let firestore = {};
let db = {};
let functions = {};
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

try {
    firebase = initializeApp(firebaseConfig);
    db = getDatabase(firebase);
    // const ref = firebase.database().ref();
    auth = getAuth(firebase);
    functions = getFunctions(firebase);
    // functions.useFunctionsEmulator('http://localhost:5001');
    // storage = firebase.storage();
    // firestore = firebase.firestore();
    // firestore.settings({ timestampsInSnapshots: true });
    // firestore.enablePersistence({ experimentalTabSynchronization: true }).catch(function(err) {
} catch (e) {
    console.error(e.message);
}

export { auth, db, ref, set, firestore, functions, storage, providers, updateProfile, helpers, firebase };
