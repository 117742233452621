import React, { useState, useEffect } from 'react';
import { message, PageHeader, Avatar, Tag, Alert, Row, Col, Card, Icon, Tabs, Button, Empty, Typography } from 'antd';
import Flag from '../../../components/Flag';
import CircleFlag from '../../../components/CircleFlag';
import { withRouter, Link } from 'react-router-dom';
import { getGameType, getCountryName, getCountryShortCode, displayEnumType } from '../../../utils';
import {
    GET_TOURNAMENT_BRACKET_MATCH_QUERY,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_BRACKET_MATCH_BY_SLUG_QUERY,
    GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
} from './data/queries';
import { UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION, UPDATE_TOURNAMENT_BRACKET_MATCH_BY_SLUG_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useBreakpoint from 'use-breakpoint';
import Ripples from 'react-ripples';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import ShotClockOverlay from './ShotClockOverlay';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;

const gameTypes = {
    ONE_POCKET: 15,
    NINE_BALL: 9,
    TEN_BALL: 10,
    STRAIGHT_POOL: 15,
    EIGHT_BALL: 15,
};

function MatchScoreboard(props) {
    const { matchData, onUpdateMatch, showBallTray, client, subscribeToMore, subscribeToNewData } = props;
    const [updateBracketMatch, updateBracketMatchRes] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [modalVisible, setModalVisible] = useState(false);
    console.log('props', props);

    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData.identifier && matchData.identifier.includes('L')) {
        challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
    } else {
        challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
    }

    let duration;
    if (matchData.start_time && matchData.end_time) {
        var start = moment(matchData.start_time);
        var end = moment(matchData.end_time);
        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
    } else {
        duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('hh:mm');
    }
    const ballsMade = matchData.scoreboard_balls || {};

    useEffect(() => {
        const unsubscribe = subscribeToNewData(subscribeToMore);

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    function incrementPlayerScore(challenger, score) {
        const newScore = score + 1;
        const changes = {
            ...(challenger === 'challenger1' ? { challenger1_score: newScore } : { challenger2_score: newScore }),
        };
        updateMatch(changes, matchData);

        // createGame({
        // 	variables: {
        // 		objects: [
        // 			{
        // 				tournament_id: matchData.tournament.id,
        // 				tournament_bracket_id: matchData.id,
        // 				player_id: match.challenger1_id,
        // 				opponent_id: match.challenger2_id,
        // 				game_number: currentFrame && currentFrame.frame_number + 1,
        // 				status: 'NOT_STARTED'
        // 			}
        // 		]
        // 	},
        // 	awaitRefetchQueries: true,
        // 	refetchQueries: [
        // 		{
        // 			query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
        // 			variables: { hash_id: matchData.hash_id, tournament_slug: matchData.tournament.slug }
        // 		}
        // 	]
        // })
        // 	.then((res) => {
        // 		console.log(res);
        // 		message.success(`Match game created`);
        // 	})
        // 	.catch((error) => {
        // 		console.log(error);
        // 		message.error('There was an error', error);
        // 	});
    }

    function decrementPlayerScore(challenger, score) {
        const newScore = score - 1;
        const changes = {
            ...(challenger === 'challenger1' ? { challenger1_score: newScore } : { challenger2_score: newScore }),
        };
        updateMatch(changes, matchData);

        // deleteGame({
        //     variables: {
        //         id: gameId,
        //         tournament_id: matchData.tournament.id,
        //         tournament_bracket_id: matchData.id,
        //     },
        //     awaitRefetchQueries: true,
        //     refetchQueries: [
        //         {
        //             query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
        //             variables: { hash_id: matchData.hash_id, tournament_slug: matchData.tournament.slug }
        //         }
        //     ]
        // })
        //     .then((data) => {
        //         console.log(data);
        //         message.success('Match game deleted');
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         message.info('There was an error', error);
        //     });
    }

    function updateMatch(changes, matchData) {
        console.log(matchData);

        // const key = 'scores';
        // message.loading({ content: `Updating ${text}...`, key, duration: 1 });
        client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_BY_SLUG_MUTATION,
                variables: {
                    id: matchData.id,
                    tournament_slug: matchData.tournament && matchData.tournament.slug,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_BY_SLUG_QUERY,
                        variables: { match_id: matchData.id, tournament_slug: matchData.tournament && matchData.tournament.slug },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                onUpdateMatch({
                    ...matchData,
                    ...changes,
                });
                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function renderMatchInfo() {
        let duration;
        if (matchData.start_time && matchData.end_time) {
            var start = moment(matchData.start_time);
            var end = moment(matchData.end_time);
            duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [mins]');
            // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
        } else {
            duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('h [hrs] m [mins]');
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('hh:mm');
        }

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignSelf: 'flex-start',
                    marginTop: -30,
                    padding: breakpoint === 'mobile' ? 0 : '10px 0px',
                    textAlign: 'center',
                }}
            >
                {matchData && matchData.tournament && matchData.tournament.game_type && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <img alt="example" src={getGameType(matchData.tournament.game_type)} width={50} height={50} />
                    </div>
                )}

                <div style={{ marginTop: 10 }}>
                    <Text style={{ fontSize: 14, color: '#fff' }}>
                        Race to{' '}
                        {matchData.challenger1_race_to && matchData.challenger2_race_to
                            ? `${challenger1RaceTo}/${challenger2RaceTo}`
                            : matchData.tournament && matchData.tournament.winners_race_to}
                    </Text>
                </div>

                <div>
                    <Text style={{ color: '#fff' }}>{matchData.identifier.includes('W') ? 'Winner side' : 'Loser side'}</Text>
                    <Text style={{ color: '#fff', whiteSpace: 'nowrap' }}> match #{matchData.match_number}</Text>
                </div>

                <div>
                    <Text style={{ color: '#fff', whiteSpace: 'nowrap' }}>{matchData.table_name}</Text>
                </div>

                <div style={{ marginTop: 15 }}>
                    <Tag
                        size="large"
                        color={
                            matchData.status === 'NOT_STARTED'
                                ? '#80848c'
                                : matchData.status === 'IN_PROGRESS'
                                ? '#108ee9'
                                : matchData.status === 'PENDING'
                                ? '#009ba2'
                                : '#cd201f'
                        }
                    >
                        {matchData.status}
                    </Tag>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Text style={{ color: '#fff' }}>{duration || '00:00'}</Text>
                </div>
            </div>
        );
    }

    function isDisabled() {
        return matchData.status === 'PENDING' || matchData.status === 'COMPLETED';
    }

    function neededToWin(player) {
        if (player === 'challenger1') {
            return challenger1RaceTo - matchData.challenger1_score;
        } else if (player === 'challenger2') {
            return challenger2RaceTo - matchData.challenger2_score;
        }
    }

    function setPlayerTurn(player) {
        let currentPlayer;
        if (player === 'challenger1') {
            currentPlayer = matchData.challenger1_name;
        } else {
            currentPlayer = matchData.challenger2_name;
        }

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: matchData.tournament && matchData.tournament.id,
                changes: {
                    ...(player === 'challenger1'
                        ? { challenger1_is_playing: true, challenger2_is_playing: false }
                        : { challenger2_is_playing: true, challenger1_is_playing: false }),
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: matchData.tournament && matchData.tournament.id },
                },
            ],
        })
            .then((data) => {
                const key = 'playerTurn';

                console.log(data);
                message.success({
                    content: `${currentPlayer} is at the table`,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function markBallMade(ball, ballsMade, matchData) {
        console.log(ball);
        console.log(ballsMade);
        const isScored = ballsMade[ball] === true;
        const markingMessage = isScored === true ? `Removed ${ball} ball as scored` : `Marked ${ball} ball as scored`;

        const key = 'ballsMade';
        // message.loading({ content: markingMessage, key, duration: 1 });

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: matchData.tournament && matchData.tournament.id,
                changes: {
                    scoreboard_balls: {
                        ...ballsMade,
                        [ball]: isScored ? false : true,
                    },
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: matchData.tournament && matchData.tournament.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: markingMessage,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function resetBallsMade() {
        const key = 'ballsMade';
        message.loading({ content: 'Clearing scored balls...', key, duration: 1 });
        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: matchData.tournament && matchData.tournament.id,
                changes: {
                    scoreboard_balls: {},
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: matchData.tournament && matchData.tournament.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `All scored balls have been cleared`,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    return (
        <React.Fragment>
            <Row gutter={8} justify="center" type="flex" style={{ marginTop: 20, height: 'calc(100% - 200px)', alignItems: 'flex-end' }}>
                <Col xs={breakpoint === 'mobile' ? 12 : 10} sm={8} align="center" className={`live-scoring ${matchData.status === 'PENDING' && 'pending'}`}>
                    <div style={{ marginBottom: 10, width: '100%' }}>
                        {matchData.challenger1_country && (
                            <CircleFlag country={matchData.challenger1_country || 'Unknown'} size="large" style={{ transform: 'scale(1.2)' }} />
                        )}
                        <div style={{ fontSize: breakpoint === 'mobile' ? 16 : 25, marginTop: 5 }}>
                            {matchData.challenger1_name}{' '}
                            {matchData.challenger1_name &&
                                matchData.challenger1_name !== 'BYE' &&
                                matchData.challenger1_skill_level &&
                                `(${matchData.challenger1_skill_level})`}
                        </div>
                    </div>

                    <div
                        style={{
                            background:
                                matchData.challenger1_score > matchData.challenger2_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(-45deg, black, transparent)',
                            // background: 'linear-gradient(90deg, transparent, black, transparent)',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            borderRadius: 10,
                            marginTop: 5,
                            marginBottom: 5,
                            width: '100%',
                            // border:
                            // 	matchData.challenger1_score > matchData.challenger2_score
                            // 		? '1px solid #3090d2'
                            // 		: '1px solid #5e6977'
                        }}
                        onClick={() => {
                            setPlayerTurn('challenger1');
                        }}
                        className="ripple"
                    >
                        <div
                            style={{
                                fontSize: 80,
                                fontWeight: 700,
                                paddingTop: 20,
                                paddingBottom: 20,
                            }}
                        >
                            {matchData.challenger1_score || 0}
                        </div>
                        {matchData.challenger1_is_playing === true && <div className="arrow-right-tiny"></div>}
                        <div
                            style={{
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                padding: 5,
                                color: 'rgba(255, 255, 255, 0.75)',
                                textTransform: 'uppercase',
                            }}
                        >
                            {neededToWin('challenger1') === 0 ? 'Winner' : `Needs ${neededToWin('challenger1')} to win`}
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 20,
                            width: '100%',
                            position: 'relative',
                            zIndex: 10,
                        }}
                    >
                        <Ripples color="#ccc">
                            <Button
                                shape="circle"
                                type="primary"
                                size="large"
                                ghost
                                disabled={isDisabled(matchData) === true || matchData.challenger1_score === challenger1RaceTo ? true : false}
                                onClick={() => {
                                    if (matchData.challenger1_score >= 0 && matchData.challenger1_score <= challenger1RaceTo) {
                                        incrementPlayerScore('challenger1', matchData.challenger1_score);
                                    }
                                }}
                                className={isDisabled(matchData) === true || matchData.challenger1_score === challenger1RaceTo ? 'disabled' : ''}
                                style={{ width: 40, height: 40, borderWidth: 2 }}
                            >
                                <Icon type="plus" style={{ fontWeight: 700 }} />
                            </Button>
                        </Ripples>
                        <Ripples color="#ccc">
                            <Button
                                shape="circle"
                                type="danger"
                                size="large"
                                ghost
                                disabled={isDisabled(matchData) === true || matchData.challenger1_score === 0 ? true : false}
                                onClick={() => {
                                    if (matchData.challenger1_score > 0) {
                                        decrementPlayerScore('challenger1', matchData.challenger1_score);
                                    }
                                }}
                                className={isDisabled(matchData) === true || matchData.challenger1_score === 0 ? 'disabled' : ''}
                                style={{ marginLeft: 10, width: 40, height: 40, borderWidth: 2 }}
                            >
                                <Icon type="minus" style={{ fontWeight: 700 }} />
                            </Button>
                        </Ripples>
                    </div>

                    {/* <div
                            style={{
                                marginTop: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Button type="danger">
                                <Icon type="minus" />
                            </Button>
                            <div style={{ fontSize: 30, marginLeft: 10, marginRight: 10 }}>0</div>
                            <Button type="primary">
                                <Icon type="plus" />
                            </Button>
                        </div>
                        <div>Runouts</div> */}
                </Col>
                {breakpoint !== 'mobile' && (
                    <Col xs={4} align="center" type="flex">
                        {renderMatchInfo()}
                    </Col>
                )}
                <Col xs={breakpoint === 'mobile' ? 12 : 10} sm={8} align="center" className={`live-scoring ${matchData.status === 'PENDING' && 'pending'}`}>
                    <div style={{ marginBottom: 10, width: '100%' }}>
                        {matchData.challenger2_country && (
                            <CircleFlag country={matchData.challenger2_country || 'Unknown'} size="large" style={{ transform: 'scale(1.2)' }} />
                        )}
                        <div style={{ fontSize: breakpoint === 'mobile' ? 16 : 25, marginTop: 5 }}>
                            {matchData.challenger2_name}{' '}
                            {matchData.challenger2_name &&
                                matchData.challenger2_name !== 'BYE' &&
                                matchData.challenger2_skill_level &&
                                `(${matchData.challenger2_skill_level})`}
                        </div>
                    </div>
                    <div
                        style={{
                            background:
                                matchData.challenger2_score > matchData.challenger1_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(45deg, black, transparent)',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            borderRadius: 10,
                            marginTop: 5,
                            marginBottom: 5,
                            width: '100%',
                            // border:
                            // 	matchData.challenger2_score > matchData.challenger1_score
                            // 		? '1px solid #3090d2'
                            // 		: '1px solid #5e6977'
                        }}
                        onClick={() => {
                            setPlayerTurn('challenger2');
                        }}
                        className="ripple"
                    >
                        <div
                            style={{
                                fontSize: 80,
                                fontWeight: 700,
                                paddingTop: 20,
                                paddingBottom: 20,
                            }}
                        >
                            {matchData.challenger2_score || 0}
                        </div>
                        {matchData.challenger2_is_playing === true && <div className="arrow-left-tiny"></div>}
                        <div
                            style={{
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                padding: 5,
                                color: 'rgba(255, 255, 255, 0.75)',
                                textTransform: 'uppercase',
                            }}
                        >
                            {neededToWin('challenger2') === 0 ? 'Winner' : `Needs ${neededToWin('challenger2')} to win`}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 20,
                            width: '100%',
                            position: 'relative',
                            zIndex: 10,
                        }}
                    >
                        <Ripples color="#ccc">
                            <Button
                                shape="circle"
                                type="primary"
                                size="large"
                                ghost
                                disabled={isDisabled(matchData) === true || matchData.challenger2_score === challenger2RaceTo ? true : false}
                                onClick={() => {
                                    if (matchData.challenger2_score >= 0 && matchData.challenger2_score <= challenger2RaceTo) {
                                        incrementPlayerScore('challenger2', matchData.challenger2_score);
                                    }
                                }}
                                className={isDisabled(matchData) === true || matchData.challenger2_score === challenger2RaceTo ? 'disabled' : ''}
                                style={{ width: 40, height: 40, borderWidth: 2 }}
                            >
                                <Icon type="plus" style={{ fontWeight: 700 }} />
                            </Button>
                        </Ripples>
                        <Ripples color="#ccc">
                            <Button
                                shape="circle"
                                type="danger"
                                size="large"
                                ghost
                                disabled={isDisabled(matchData) === true || matchData.challenger2_score === 0 ? true : false}
                                onClick={() => {
                                    if (matchData.challenger2_score > 0) {
                                        decrementPlayerScore('challenger2', matchData.challenger2_score);
                                    }
                                }}
                                className={isDisabled(matchData) === true || matchData.challenger2_score === 0 ? 'disabled' : ''}
                                style={{ marginLeft: 10, width: 40, height: 40, borderWidth: 2 }}
                            >
                                <Icon type="minus" style={{ fontWeight: 700 }} />
                            </Button>
                        </Ripples>
                    </div>

                    {/* <div
                            style={{
                                marginTop: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Button type="danger">
                                <Icon type="minus" />
                            </Button>
                            <div style={{ fontSize: 30, marginLeft: 10, marginRight: 10 }}>0</div>
                            <Button type="primary">
                                <Icon type="plus" />
                            </Button>
                        </div>
                        <div>Runouts</div> */}
                </Col>
                {breakpoint === 'mobile' && (
                    <Col xs={24} align="center" type="flex">
                        {renderMatchInfo()}
                    </Col>
                )}

                <Col xs={24} align="center" type="flex">
                    <div style={{ marginBottom: 20 }}>
                        <Button
                            size="large"
                            ghost
                            style={{ borderRadius: 10 }}
                            onClick={() => {
                                setModalVisible(true);
                            }}
                        >
                            <ClockCircleOutlined /> Shot Clock
                        </Button>
                    </div>
                    {/* <ShotClock
                        showDays={false}
                        showHours={false}
                        showMinutes={false}
                        showSeconds={true}
                        showMilliseconds={false}
                        direction="backward"
                        type="circular"
                        textColor="#fff"
                        initialTime={30000}
                        matchData={matchData}
                    /> */}
                </Col>

                {showBallTray && showBallTray === true && (
                    <Col xs={24} align="center" style={{ padding: 0 }}>
                        <div style={{ backgroundColor: 'rgba(0,0,0,0.4)', padding: 10, borderRadius: 20 }}>
                            {Array.from(
                                {
                                    length: gameTypes[matchData.tournament.game_type] ? gameTypes[matchData.tournament.game_type] : 15,
                                },
                                (v, index) => {
                                    const ballNum = index + 1;
                                    return (
                                        <Button
                                            type="link"
                                            size="large"
                                            onClick={() => {
                                                markBallMade(ballNum, ballsMade, matchData);
                                            }}
                                            key={index}
                                        >
                                            <Avatar
                                                size={40}
                                                src={require(`../../../assets/img/table/balls/${ballNum}-ball.png`)}
                                                className={ballsMade[ballNum] === true ? 'ball-scored' : ''}
                                            />
                                        </Button>
                                    );
                                }
                            )}
                            <Button size="large" ghost onClick={resetBallsMade} style={{ borderRadius: 10 }}>
                                Clear
                            </Button>
                        </div>
                    </Col>
                )}
            </Row>
            <ShotClockOverlay {...props} matchData={matchData} onOk={handleModalOk} onCancel={handleModalCancel} modalVisible={modalVisible} />
        </React.Fragment>
    );
}

export default withRouter(MatchScoreboard);
