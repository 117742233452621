import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import Card from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { GET_LIVE_STREAM } from '../data/queries';
import { Query } from '@apollo/client/react/components';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import LiveChat from './LiveChat';
import TwitchEmbedVideo from 'react-twitch-embed-video';
import YouTube from 'react-youtube';

function LiveDetail(props) {
    const { history } = props;
    console.log('props22', props);

    function getLiveStream(stream) {
        const { type, channel } = stream;

        let streamType;

        switch (type) {
            case 'YOUTUBE':
                streamType = (
                    <React.Fragment>
                        <YouTube videoId={channel} />
                    </React.Fragment>
                );
                break;

            case 'FACEBOOK':
                break;

            case 'TWITCH':
                streamType = <TwitchEmbedVideo autoplay channel={channel} height="720" width="100%" muted={false} targetId="twitch-embed" />;
                break;
        }

        return streamType;
    }

    return (
        <Query query={GET_LIVE_STREAM} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} variables={{ channel: props.match.params.channel }}>
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>Error: {error.message}</div>;

                const stream = data && data.live && data.live[0];

                return (
                    <SectionWrapper>
                        <SectionHeader
                            title={stream && stream.name}
                            titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                            height={100}
                            backButton={
                                <div
                                    style={{
                                        paddingLeft: 10,
                                        paddingBottom: 10,
                                        paddingTop: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <Icon type="arrow-left" onClick={() => history.push(`/live`)} style={{ color: '#fff', fontSize: 16, margin: 0 }} />
                                </div>
                            }
                        />
                        <SectionContent padding="20px 40px">
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Card>
                                        {stream && getLiveStream(stream)}
                                        <LiveChat liveId={stream.id} client={props.client} channelId={stream.id} userId={props.authState.user.id} />
                                    </Card>
                                </Col>
                            </Row>
                        </SectionContent>
                    </SectionWrapper>
                );
            }}
        </Query>
    );
}

export default withRouter(React.memo(LiveDetail));
