import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { message, PageHeader, Icon, Row, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../components/Card';
import SectionContent from '../../components/SectionContent';
import SectionHeader from '../../components/SectionHeader';
import CircularLoader from '../../components/CircularLoader';
import ZeroState from '../../components/ZeroState';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_VENUE_QUERY, GET_VENUE_TABLES_QUERY } from './data/queries';
import { isoCountries } from '../../utils/isoCountryCodes';
import moment from 'moment';
import _ from 'lodash';
import { DELETE_TABLE_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddTableModal from './AddTableModal';
import AddVenueModal from './AddVenueModal';
import TablesList from './TablesList';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMVenueDetail(props) {
    console.log(props);
    const { league } = props;
    const [selectedTableId, setSelectedTableId] = useState();
    const [venue, setVenue] = useState();
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [deleteVenueTable, { loading, error }] = useMutation(DELETE_TABLE_MUTATION);

    function handleTableModalOk(e) {
        // console.log(e);
        setTableModalVisible(false);
        setSelectedTableId();
    }

    function handleTableModalCancel(e) {
        setTableModalVisible(false);
    }

    function handleVenueModalOk(e) {
        // console.log(e);
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
    }

    function handleEditTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
        setTableModalVisible(true);
    }

    return (
        <React.Fragment>
            <SectionHeader
                title={venue && venue.name}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/league-manager/${props.league.slug}/venues`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                subtitle="Create and manage your own leagues in a few simple steps."
                subtitleStyle={{ color: '#fff' }}
                theme="leagueManager"
            />

            <SectionContent padding="20px 40px">
                <Query
                    query={GET_LEAGUE_VENUE_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: props.match.params.venue }}
                    onCompleted={(data) => {
                        const venueData = data && data.league_venues && data.league_venues[0];
                        if (venueData) {
                            setVenue({
                                ...venueData.venue,
                                league_venue_id: venueData.id,
                            });
                        }
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((!loading && data && !data.league_venues) || (!loading && data && data.league_venues && data.league_venues.length === 0)) {
                            return (
                                <Card>
                                    <ZeroState showImage message="Venue could not be found" style={{ margin: 30 }} {...props} />
                                </Card>
                            );
                        }

                        const venueData = data && data.league_venues && data.league_venues[0];
                        const venue = venueData && {
                            ...venueData.venue,
                            league_venue_id: venueData.id,
                        };
                        console.log(venue);

                        const countryName =
                            venue.country &&
                            Object.entries(isoCountries)
                                .map(([key, value]) => {
                                    return {
                                        name: key,
                                        value: value.toLowerCase(),
                                    };
                                })
                                .filter((item, index) => {
                                    const country = venue.country.toLowerCase();
                                    return item.value === country || item.name === country;
                                });

                        const country = countryName && countryName[0] ? countryName[0].name : null;

                        return (
                            <React.Fragment>
                                <Row gutter={24}>
                                    <Col>
                                        <Card
                                            title="Venue Information"
                                            extra={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Button type="ghost" onClick={() => setVenueModalVisible(true)}>
                                                        <Icon type="edit" /> Edit
                                                    </Button>
                                                    <Button type="danger" ghost>
                                                        <Icon type="delete" /> Delete
                                                    </Button>
                                                </div>
                                            }
                                        >
                                            <Descriptions
                                                size="small"
                                                column={2}
                                                // bordered
                                                layout="horizontal"
                                            >
                                                <Descriptions.Item label="Name">{venue.name || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Address">
                                                    {venue.address1} {venue.address2},{venue.city}, {venue.region} {venue.postal_code} {country}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Description">
                                                    <Text style={{ width: 200 }}>{venue.description || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Operating Hours">{venue.operating_hours || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Venue Type">
                                                    {(venue.venue_type && _.startCase(_.toLower(venue.venue_type.replace('_', ' ')))) || '-'}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Access">{venue.is_public === true ? 'Public' : 'Private'}</Descriptions.Item>
                                                <Descriptions.Item label="Claimed">{venue.is_claimed === true ? 'Yes' : 'No'}</Descriptions.Item>
                                                <Descriptions.Item label="Phone Number">{venue.phone_number || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Email Address">{venue.email_address || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Website">{venue.website || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Facebook Link">{venue.social_facebook || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Instagram Link">{venue.social_instagram || 'N/A'}</Descriptions.Item>
                                                <Descriptions.Item label="Twitter Link">{venue.social_twitter || 'N/A'}</Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    </Col>

                                    <Col>
                                        <Card
                                            title={venue.pool_tables && venue.pool_tables.length > 0 ? `Tables (${venue.pool_tables.length})` : 'Tables'}
                                            extra={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Button
                                                        type="default"
                                                        onClick={() => {
                                                            setTableModalVisible(true);
                                                        }}
                                                    >
                                                        <Icon type="plus-circle" /> Add Tables
                                                    </Button>
                                                </div>
                                            }
                                            bodyStyle={{ padding: 0 }}
                                        >
                                            {venue.pool_tables && venue.pool_tables.length > 0 ? (
                                                <TablesList
                                                    {...props}
                                                    onSelected={handleTableSelected}
                                                    onEditSelected={handleEditTableSelected}
                                                    onDelete={(record) => {
                                                        deleteVenueTable({
                                                            variables: {
                                                                id: record.id,
                                                            },
                                                            notifyOnNetworkStatusChange: true,
                                                            awaitRefetchQueries: true,
                                                            refetchQueries: [
                                                                {
                                                                    query: GET_LEAGUE_VENUE_QUERY,
                                                                    variables: { slug: venue.slug },
                                                                },
                                                            ],
                                                        })
                                                            .then((data) => {
                                                                console.log(data);
                                                                message.success('Table deleted from venue');
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                message.info('There was an error', error);
                                                            });
                                                    }}
                                                    tables={venue.pool_tables.map((item, index) => {
                                                        return {
                                                            ...item,
                                                            key: index,
                                                        };
                                                    })}
                                                />
                                            ) : (
                                                <ZeroState showImage message="No Tables found for this venue." style={{ margin: 30 }} {...props} />
                                            )}
                                        </Card>
                                    </Col>
                                </Row>
                                <AddTableModal
                                    {...props}
                                    league={props.league}
                                    venue={venue}
                                    selectedTableId={selectedTableId}
                                    tableModalVisible={tableModalVisible}
                                    onTableModalOk={handleTableModalOk}
                                    onTableModalCancel={handleTableModalCancel}
                                />
                                <AddVenueModal
                                    {...props}
                                    selectedVenueId={venue && venue.league_venue_id}
                                    // venue={
                                    // 	venues.filter((item, index) => {
                                    // 		return item.id === selectedVenueId;
                                    // 	})[0]
                                    // }
                                    modalVisible={venueModalVisible}
                                    onModalOk={handleVenueModalOk}
                                    onModalCancel={handleVenueModalCancel}
                                />
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(LMVenueDetail);
