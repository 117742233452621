import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import Card from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { Table, Tabs, Icon, Pagination, Button, Row, Empty, Col, Typography, Modal } from 'antd';
import { GET_USER_VENUES_QUERY, SEARCH_USER_VENUES_QUERY } from './data/queries';
import { displayEnumType } from '../../../utils';
import { stringSorter } from '../../../utils/uiUtils';
import { getCountryName } from '../../../utils/getCountryName';
import CircleFlag from '../../../components/CircleFlag';
import ZeroState from '../../../components/ZeroState';
import SearchFilterVenues from './SearchFilterVenues';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

const { Text } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

export default function MyVenuesTable(props) {
    const { client, authState, onAddVenue, totalVenues } = props;
    const [filters, setFilters] = useState({
        venueType: 'all',
        tableSize: 'all',
        tableManufacturer: 'all',
    });
    const [searchValue, setSearchValue] = useState();
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [venues, setVenues] = useState([]);

    const MyPagination = ({ total, onChange, current }) => {
        return <Pagination onChange={onChange} total={total} current={currentPage} pageSize={pageSize} />;
    };

    const columns = [
        // {
        // 	title: '#',
        // 	dataIndex: 'id',
        // 	width: 80,
        // 	sorter: (a, b) => {
        // 		return a.id - b.id;
        // 	}
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'asc',
            sorter: (a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {record.country && <CircleFlag country={record.country} left={0} />}

                        <div>
                            <Text
                                style={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}
                            >
                                {text ? (
                                    <Highlighter highlightClassName="highlighted-text" searchWords={[searchValue]} autoEscape={true} textToHighlight={text} />
                                ) : (
                                    '-'
                                )}
                            </Text>
                            <br />
                            <Text>
                                <Highlighter
                                    highlightClassName="highlighted-text"
                                    searchWords={[searchValue]}
                                    autoEscape={true}
                                    textToHighlight={record.address1}
                                />
                                <br />
                                <Highlighter
                                    highlightClassName="highlighted-text"
                                    searchWords={[searchValue]}
                                    autoEscape={true}
                                    textToHighlight={`${record.city}, ${record.region} ${record.postal_code}`}
                                />
                            </Text>
                            {/* <br />
							<Text type="secondary">{record.pool_tables_aggregate.aggregate.count} Tables</Text> */}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'City',
            dataIndex: 'city',
            sorter: (a, b) => {
                return a.city.localeCompare(b.city, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'State/Region',
            dataIndex: 'region',
            sorter: (a, b) => {
                return a.region.localeCompare(b.region, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            sorter: (a, b) => {
                return a.country.localeCompare(b.country, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },
        {
            title: 'Tables',
            dataIndex: 'pool_tables_aggregate.aggregate.count',
            key: 'tableCounts',
            sorter: (a, b) => a.pool_tables_aggregate.aggregate.count - b.pool_tables_aggregate.aggregate.count,
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Table size',
            dataIndex: 'pool_tables',
            key: 'tableSizes',
            // sorter: (a, b) => {
            // 	const aTables = a.pool_tables.map((table) => {
            // 		return {
            // 			size: table.size
            // 		};
            // 	});
            // 	const bTables = b.pool_tables.map((table) => {
            // 		return {
            // 			size: table.size
            // 		};
            // 	});

            // 	return aTables.length > 0 && bTables.length > 0 && aTables.size.localeCompare(bTables.size);
            // },
            render: (text, record) => {
                const tableSizeCounts = record.pool_tables && _.countBy(record.pool_tables, 'size');
                const sizes = Object.keys(tableSizeCounts)
                    .sort((a, b) => {
                        return a.localeCompare(b, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        });
                    })
                    .map((key, index) => {
                        const totalSizes = Object.keys(tableSizeCounts).length;
                        if (totalSizes > 0 && index !== totalSizes - 1) {
                            return (
                                <React.Fragment key={index}>
                                    <Text>
                                        {key} ({tableSizeCounts[key]})
                                    </Text>
                                    <br />
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <Text key={index}>
                                    {key} ({tableSizeCounts[key]})
                                </Text>
                            );
                        }
                    });
                return <React.Fragment>{sizes && sizes.length > 0 ? sizes : '-'}</React.Fragment>;
            },
        },
        {
            title: 'Table brand',
            dataIndex: 'pool_tables',
            key: 'tableBrands',
            render: (text, record) => {
                // const tableSizes = record.pool_tables && record.pool_tables.map(table => {
                // 	return {
                // 		size: table.size
                // 	}
                // })
                const tableBrandCounts = record.pool_tables && _.countBy(record.pool_tables, 'make');
                const sizes = Object.keys(tableBrandCounts)
                    .sort((a, b) => {
                        return a.localeCompare(b, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        });
                    })
                    .map((key, index) => {
                        const totalSizes = Object.keys(tableBrandCounts).length;
                        if (totalSizes > 0 && index !== totalSizes - 1) {
                            return (
                                <React.Fragment key={index}>
                                    <Text>
                                        {key} ({tableBrandCounts[key]})
                                    </Text>
                                    <br />
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <Text key={index}>
                                    {key} ({tableBrandCounts[key]})
                                </Text>
                            );
                        }
                    });
                return <React.Fragment>{sizes && sizes.length > 0 ? sizes : '-'}</React.Fragment>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'venue_type',
            sorter: (a, b) => {
                if (a.venue_type && b.venue_type) {
                    return a.venue_type.localeCompare(b.venue_type, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
            },
            render: (text, record) => <React.Fragment>{text ? displayEnumType(text) : '-'}</React.Fragment>,
        },
    ];

    async function handleVenueSearch(value) {
        setSearchValue(value);
        if (value) {
            setSearching(true);
            const res = await client.query({
                query: SEARCH_USER_VENUES_QUERY,
                variables: {
                    name: `%${value}%`,
                    userId: authState && authState.user && authState.user.id,
                },
            });
            setSearching(false);
            const venues = res.data.venues;
            setSearchResults(venues);
        }
    }

    function handleVenueTypeChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            venueType: value,
        });
    }

    function handleTableSizeChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            tableSize: value,
        });
    }

    function handleManufacturerChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            tableManufacturer: value,
        });
    }

    function renderVenueData(venues) {
        if (!venues || venues.length === 0) {
            return (
                <ZeroState message="No venues found." {...props}>
                    <Button type="primary" onClick={onAddVenue}>
                        Create Venue
                    </Button>
                </ZeroState>
            );
        } else {
            const getData = (current, pageSize) => {
                // Normally you should get the data from the server
                // return venues.slice((current - 1) * pageSize, current * pageSize);
                return venues;
            };

            return (
                <React.Fragment>
                    <Table
                        rowKey="name"
                        // className="table-striped-rows"
                        columns={columns}
                        dataSource={getData(currentPage, pageSize)}
                        pagination={false}
                        // pagination={<Pagination defaultCurrent={currentPage} total={totalVenues} />}
                        tableLayout="auto"
                        scroll={{ x: 400 }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    console.log(record, rowIndex);
                                    props.history.push({
                                        pathname: `/venues/${record.slug}`,
                                        // search: '?query=abc',
                                        state: { data: record },
                                    });
                                },
                                onDoubleClick: (event) => {}, // double click row
                                onContextMenu: (event) => {}, // right button click row
                                onMouseEnter: (event) => {}, // mouse enter row
                                onMouseLeave: (event) => {}, // mouse leave row
                            };
                        }}
                    />

                    <div style={{ paddingTop: 15, display: 'flex', justifyContent: 'center' }}>
                        <MyPagination total={totalVenues} current={currentPage} onChange={setCurrentPage} />
                    </div>
                </React.Fragment>
            );
        }
    }

    function venueTypeFilter(venue) {
        const filter = filters.venueType;
        if (filter && filter !== 'all') {
            return venue.venue_type === filter;
        } else {
            return venue;
        }
    }

    function tableSizeFilter(venue) {
        const filter = filters.tableSize;
        const tables =
            venue.pool_tables &&
            venue.pool_tables.map((table) => {
                return table.size;
            });
        if (filter && filter !== 'all') {
            return tables.includes(filter);
        } else {
            return venue;
        }
    }

    function tableManufacturerFilter(venue) {
        const filter = filters.tableManufacturer;
        const tables =
            venue.pool_tables &&
            venue.pool_tables.map((table) => {
                return table.make;
            });
        if (filter && filter !== 'all') {
            return tables.includes(filter);
        } else {
            return venue;
        }
    }

    return (
        <React.Fragment>
            {authState && authState.user && authState.user.id ? (
                <Query
                    query={GET_USER_VENUES_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{ userId: authState && authState.user && authState.user.id, limit: pageSize, offset: (currentPage - 1) * pageSize }}
                    onCompleted={(data) => {
                        console.log(data);
                        const venues = data.venues;
                        console.log('venues', venues);
                        // venues.forEach((venue) => {
                        //     if (venue.address1) {
                        //         return geocodeAddress(venue);
                        //     }
                        // });
                        let filteredVenues;
                        if (searchResults && searchValue) {
                            filteredVenues = searchResults.filter(venueTypeFilter).filter(tableSizeFilter).filter(tableManufacturerFilter);
                        } else {
                            filteredVenues = venues.filter(venueTypeFilter).filter(tableSizeFilter).filter(tableManufacturerFilter);
                        }

                        setVenues(filteredVenues);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                    <Text style={{ marginLeft: 10 }}>Loading...</Text>
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.venues) || (data && data.venues.length === 0)) {
                            return (
                                <ZeroState message="No venues found." messageStyle={{ fontSize: 16 }} showImage={false} style={{ textAlign: 'left' }}>
                                    <Button type="primary" onClick={onAddVenue}>
                                        Create Venue
                                    </Button>
                                </ZeroState>
                            );
                        }

                        return (
                            <React.Fragment>
                                <div
                                    style={{
                                        // background: 'linear-gradient( #fff, #fafafa)',
                                        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
                                        padding: '0px 20px',
                                        // borderRadius: 10,
                                        // overflow: 'hidden',
                                        marginTop: 15,
                                    }}
                                >
                                    <SearchFilterVenues
                                        placeholder="Search my venues"
                                        venues={searchResults && searchValue ? searchResults : data.venues}
                                        searching={searching}
                                        onSearchVenue={handleVenueSearch}
                                        onVenueTypeChange={handleVenueTypeChange}
                                        onTableSizeChange={handleTableSizeChange}
                                        onTableManufacturerChange={handleManufacturerChange}
                                        {...props}
                                    />
                                </div>

                                <Row gutter={16} justify="center" type="flex">
                                    <Col xs={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                        {renderVenueData(
                                            searchResults && searchValue
                                                ? searchResults.filter(venueTypeFilter).filter(tableSizeFilter).filter(tableManufacturerFilter)
                                                : venues.filter(venueTypeFilter).filter(tableSizeFilter).filter(tableManufacturerFilter)
                                        )}
                                    </Col>
                                </Row>
                            </React.Fragment>
                        );
                    }}
                </Query>
            ) : (
                <Card>
                    <ZeroState message="You must be logged in to create a venue." {...props}>
                        <Link to="/login">
                            <Button type="primary" size="large">
                                Log In
                            </Button>
                        </Link>
                        <Link to="/register" style={{ marginLeft: 10 }}>
                            <Button size="large">Create Free Account</Button>
                        </Link>
                    </ZeroState>
                </Card>
            )}
        </React.Fragment>
    );
}
