import React, { useState, useRef } from 'react';
import { message, Modal, Button, Icon, Typography, Tooltip } from 'antd';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import { Form, FormItem, FormikDebug, DatePicker, Input, InputNumber } from 'formik-antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TOURNAMENT_BRACKET_QUERY } from '../data/queries';
import {
    UPDATE_TOURNAMENT_ROUND_TIME,
    UPDATE_TOURNAMENT_GROUP_ROUND_TIME,
    UPDATE_TOURNAMENT_STAGE_ROUND_TIME,
    UPDATE_TOURNAMENT_STAGE_GROUP_ROUND_TIME,
    UPDATE_TOURNAMENT_MATCH_TIME,
} from '../data/mutations';
import _ from 'lodash';
import { QuestionCircleFilled } from '@ant-design/icons';
import RoundTimeEntry from './RoundTimeEntry';
import RoundTableSelect from './RoundTableSelect';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;

export default function SetRoundTimeModal(props) {
    const { authState, tournament, currentRound, stage, group, tables, modalVisible, onModalOk, onModalCancel, theme } = props;
    const [loading, setLoading] = useState(false);
    const [updateRoundTime] = useMutation(UPDATE_TOURNAMENT_ROUND_TIME);
    const [updateGroupRoundTime] = useMutation(UPDATE_TOURNAMENT_GROUP_ROUND_TIME);
    const [updateStageRoundTime] = useMutation(UPDATE_TOURNAMENT_STAGE_ROUND_TIME);
    const [updateStageGroupRoundTime] = useMutation(UPDATE_TOURNAMENT_STAGE_GROUP_ROUND_TIME);
    const [updateMatchTime] = useMutation(UPDATE_TOURNAMENT_MATCH_TIME);
    // const [ setRoundTime ] = useMutation(SET_TOURNAMENT_ROUND_TIME);
    const formRef = useRef();

    // console.log(group);

    const matches =
        currentRound &&
        currentRound.matches &&
        currentRound.matches
            .map((match) => {
                return {
                    ...match,
                    scheduled_time: (match.scheduled_time && match.scheduled_time.toString()) || null,
                };
            })
            .filter((match) => {
                return match.challenger1_name !== 'BYE' && match.challenger2_name !== 'BYE';
            });

    function onChangeAllEntries(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        let tzDate;
        if (defaultTimezone) {
            tzDate = moment.tz(new Date(value), defaultTimezone);
        } else {
            var zone_name = moment.tz.guess();
            tzDate = moment.tz(new Date(value), zone_name);
        }

        console.log('onOK', tzDate);
        console.log(matches);
        formRef.current.setValues({
            scheduled_time: tzDate,
            matches: matches.map((match) => {
                return {
                    ...match,
                    scheduled_time: tzDate || null,
                };
            }),
        });
        return tzDate;
    }

    function onChangeEntry(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        let tzDate;
        if (defaultTimezone) {
            tzDate = moment.tz(new Date(value), moment.tz(defaultTimezone));
        } else {
            tzDate = moment.tz(new Date(value), moment.tz(defaultTimezone));
        }
        console.log('onOK', tzDate);
        // formRef.current.setValues({
        // 	matches: matches.map((match) => {
        // 		return tzDate;
        // 	})
        // });
        return tzDate;
    }

    function onChangeTable(value) {
        return value;
    }

    function renderItem({ match, index, values }) {
        return (
            <React.Fragment>
                <RoundTimeEntry
                    {...props}
                    name={`matches.${index}.scheduled_time`}
                    round={currentRound}
                    match={match}
                    index={index}
                    onOk={onChangeEntry}
                    values={values}
                />
                <RoundTableSelect
                    {...props}
                    name={`matches.${index}.scheduled_table_id`}
                    tournament={tournament}
                    tables={tables}
                    round={currentRound}
                    match={match}
                    index={index}
                    onOk={onChangeTable}
                    values={values}
                />

                <Text style={{ fontWeight: 700 }}>
                    Set priority order
                    <Tooltip
                        placement="right"
                        title={
                            <div>
                                Optionally set a priority order when scheduling multiple matches to a single table. This is useful so players can know what
                                table they will play on and match order they will follow even if there is no scheduled time. "Follows match #" will be displayed
                                if no match time is set.
                            </div>
                        }
                    >
                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                    </Tooltip>
                </Text>
                <FormItem name={`matches.${index}.priority`} hasFeedback showValidateSuccess>
                    <InputNumber name={`matches.${index}.priority`} placeholder="Priority order" size="large" style={{ width: 200 }} />
                </FormItem>
                {/* <FormItem name="status">
                    <Input name="status" type="hidden" />
                </FormItem> */}
            </React.Fragment>
        );
    }

    const RoundEntryMemo = React.memo(renderItem);

    async function updateRound(data, actions) {
        console.log({
            data,
            stage,
        });
        if (stage) {
            if (group) {
                return updateStageGroupRoundTime({
                    variables: {
                        tournament_id: tournament.id,
                        stage_id: stage.id,
                        round: currentRound.round,
                        group: group.number,
                        changes: {
                            scheduled_time: data.scheduled_time || null,
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        // setLoading(false);
                        // message.success('Changes saved');
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoading(false);
                        message.info('There was an error', error);
                    });
            } else {
                return updateStageRoundTime({
                    variables: {
                        tournament_id: tournament.id,
                        stage_id: stage.id,
                        round: currentRound.round,
                        changes: {
                            scheduled_time: data.scheduled_time || null,
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        // setLoading(false);
                        // message.success('Changes saved');
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoading(false);
                        message.info('There was an error', error);
                    });
            }
        } else {
            if (group) {
                return updateGroupRoundTime({
                    variables: {
                        tournament_id: tournament.id,
                        round: currentRound.round,
                        group: group.number,
                        changes: {
                            scheduled_time: data.scheduled_time || null,
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        // setLoading(false);
                        // message.success('Changes saved');
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoading(false);
                        message.info('There was an error', error);
                    });
            } else {
                return updateRoundTime({
                    variables: {
                        tournament_id: tournament.id,
                        round: currentRound.round,
                        changes: {
                            scheduled_time: data.scheduled_time || null,
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        // setLoading(false);
                        // message.success('Changes saved');
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoading(false);
                        message.info('There was an error', error);
                    });
            }
        }
    }

    async function handleSubmit(values, actions) {
        let matchesToUpdate;
        const roundMatches = values.matches;
        let promises = [];

        if (values.scheduled_time) {
            console.log('has scheduled time');
            const roundTime = [
                {
                    scheduled_time: values.scheduled_time || null,
                },
            ];
            // let matchesDiff = _.filter(roundMatches, (item) => {
            //     return item && (item.scheduled_time !== null || item.scheduled_table_id !== null);
            // });
            matchesToUpdate = _.differenceBy(roundMatches, roundTime, ['scheduled_time', 'scheduled_table_id']);
        } else {
            console.log('NO scheduled time');
            matchesToUpdate = _.filter(roundMatches, (item) => {
                return item && (item.scheduled_time !== null || item.scheduled_table_id !== null);
            });
        }

        console.log(values);
        console.log(matchesToUpdate);

        setLoading(true);
        await updateRound(values, actions);

        if (matchesToUpdate) {
            console.log('matches to update', matchesToUpdate);
            return new Promise(async (resolve, reject) => {
                matchesToUpdate.forEach((match, index) => {
                    const changes = {
                        scheduled_time: match.scheduled_time || null,
                        scheduled_table_id: match.scheduled_table_id || null,
                        priority: match.priority,
                        status:
                            match.status === 'COMPLETED'
                                ? 'COMPLETED'
                                : match.status === 'IN_PROGRESS'
                                ? 'IN_PROGRESS'
                                : match.scheduled_time
                                ? 'SCHEDULED'
                                : 'NOT_STARTED',
                    };

                    promises.push(
                        updateMatchTime({
                            variables: {
                                tournament_id: tournament.id,
                                round: currentRound.round,
                                id: match.id,
                                changes: changes,
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_TOURNAMENT_BRACKET_QUERY,
                                    variables: { tournamentId: tournament.id },
                                },
                            ],
                        })
                            .then((data) => {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    );
                });

                Promise.all(promises)
                    .then((result) => {
                        console.log(result);
                        message.success(`All match times have been updated`);
                        setLoading(false);
                        actions.setSubmitting(false);
                        // actions.resetForm();
                        onModalOk();
                        resolve(result);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                        setLoading(false);
                        actions.setSubmitting(false);
                        // actions.resetForm();
                        // onModalOk();
                        reject(error);
                    });
            }).catch((error) => {
                console.log(error);
                return error;
            });
        } else {
            setLoading(false);
            actions.setSubmitting(false);
            // actions.resetForm();
            onModalOk();
        }
    }

    return (
        <Modal
            title={`Set scheduled start time for ${
                currentRound && currentRound.identifier && currentRound.identifier.indexOf('W') !== -1 ? 'winner' : 'loser'
            } round ${currentRound && currentRound.round && currentRound.round.toString().replace(/-/g, '')} (${currentRound && currentRound.identifier})`}
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            centered
            transitionName="fade"
            // transitionName="none"
            maskTransitionName="none"
            footer={[
                <Button key="cancel" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    disabled={loading}
                    // disabled={Object.keys(errors).length > 0 ? true : false}
                    // type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                    onClick={() => {
                        formRef.current.handleSubmit();
                    }}
                >
                    {loading === true ? 'Updating...' : 'Save'}
                </Button>,
            ]}
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
            destroyOnClose={true}
        >
            <Formik
                ref={formRef}
                enableReinitialize
                initialValues={
                    currentRound && currentRound.scheduled_time
                        ? {
                              scheduled_time: currentRound.scheduled_time,
                              matches,
                          }
                        : {
                              scheduled_time: null,
                              priority: null,
                              matches: matches || [],
                          }
                }
                // validationSchema={MatchSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
                render={({ errors, touched, values }) => (
                    <Form layout="vertical" style={{ maxWidth: 600 }}>
                        <div style={{ marginRight: 10 }}>
                            <Text style={{ fontSize: 14, fontWeight: 600 }}>Set all match start times for this round</Text>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderBottom: theme && theme.name === 'dark' ? '1px solid #253142' : '1px solid #e8e8e8',
                                paddingBottom: 10,
                                marginBottom: 20,
                            }}
                        >
                            <RoundTimeEntry {...props} name="scheduled_time" round={currentRound} onOk={onChangeAllEntries} allowClear={false} />

                            {values && values.scheduled_time && (
                                <Button
                                    type="link"
                                    className="remove-table-assignment-btn"
                                    onClick={() => {
                                        formRef.current.setValues({
                                            scheduled_time: null,
                                            matches: matches.map((match) => {
                                                return {
                                                    ...match,
                                                    scheduled_time: null,
                                                };
                                            }),
                                        });
                                    }}
                                >
                                    <Icon type="close-circle" />
                                    Clear all times
                                </Button>
                            )}
                        </div>
                        {/* <div>scheduled time: {`${JSON.stringify(currentRound.scheduled_time || (values && values.scheduled_time))}`}</div> */}

                        <Text style={{ fontSize: 14, fontWeight: 600 }}>Set individual match start times</Text>

                        <FieldArray
                            name="matches"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.matches &&
                                        values.matches.map((match, index) => {
                                            return (
                                                <div key={index}>
                                                    <div style={{ minWidth: 60, marginTop: 20 }}>
                                                        <Text>Match {match.match_number}</Text> -{' '}
                                                        {(match.challenger1_name || match.challenger2_name) && (
                                                            <Text type="secondary">
                                                                {match.challenger1_name} vs. {match.challenger2_name}
                                                            </Text>
                                                        )}
                                                    </div>
                                                    {/* <div>
                                                        <React.Fragment>
                                                            <RoundTimeEntry
                                                                name={`matches.${index}.scheduled_time`}
                                                                round={currentRound}
                                                                match={match}
                                                                index={index}
                                                                onOk={onChangeEntry}
                                                                values={values}
                                                            />
                                                            
                                                            <RoundTableSelect
                                                                name={`matches.${index}.scheduled_table_id`}
                                                                tournament={tournament}
                                                                tables={tables}
                                                                round={currentRound}
                                                                match={match}
                                                                index={index}
                                                                onOk={onChangeTable}
                                                                values={values}
                                                            />

                                                            <Text style={{ fontWeight: 700 }}>
                                                                Set priority order
                                                                <Tooltip
                                                                    placement="right"
                                                                    title={
                                                                        <div>
                                                                            Optionally set a priority order when scheduling multiple matches to a single table.
                                                                            This is useful so players can know what table they will play on and match order they
                                                                            will follow even if there is no scheduled time. "Follows match #" will be displayed
                                                                            if no match time is set.
                                                                        </div>
                                                                    }
                                                                >
                                                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                </Tooltip>
                                                            </Text>
                                                            <FormItem name={`matches.${index}.priority`} hasFeedback showValidateSuccess>
                                                                <InputNumber
                                                                    name={`matches.${index}.priority`}
                                                                    placeholder="Priority order"
                                                                    size="large"
                                                                    style={{ width: 200 }}
                                                                />
                                                            </FormItem>
                                                        </React.Fragment>
                                                    </div> */}
                                                    <div>{<RoundEntryMemo values={values} match={match} index={index} />}</div>
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        />
                        {/* <FormikDebug style={{ maxWidth: 400 }} /> */}
                    </Form>
                )}
            />
        </Modal>
    );
}
