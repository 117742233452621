import React, { useState, useRef, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { message, Modal, Layout } from 'antd';
import Fullscreen from 'react-full-screen';
import clearRender from 'clear-render';
// import { Typography } from 'antd';
// import Card from '../../components/Card';
// import { Link } from 'react-router-dom';
// import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { SizeMe } from 'react-sizeme';
import Viewer from './Viewer';
import Bracket from '../digitalpool/tournaments/bracket/Bracket3';
import RoundRobinViewer from './RoundRobinViewer';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PlayerSearch from '../digitalpool/tournaments/bracket/PlayerSearch';
import qs from 'query-string';
import CircularLoader from '../../components/CircularLoader';
import _ from 'lodash';
import { GET_TOURNAMENT_BY_SLUG_QUERY } from './data/queries';
import { UNLOCK_TOURNAMENT_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_BRACKET_STAGES_QUERY, GET_ALL_TOURNAMENT_TABLES_QUERY } from './data/queries';
import { Query, Subscription } from '@apollo/client/react/components';
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function TBViewer(props) {
    const { tournament, client, theme, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const [playerSearchCoordinates, setPlayerSearchCoordinates] = useState({ x: 0, y: 0 });
    const [unlockTournament] = useMutation(UNLOCK_TOURNAMENT_MUTATION);
    const [selectedStage, setSelectedStage] = useState();
    const [stages, setStages] = useState();
    const [tables, setTables] = useState([]);
    const stageSlug = props.match.params.stage;

    useEffect(() => {
        if (tournament.status === 'COMPLETED') {
            confirm({
                title: 'This tournament is complete and is now locked.',
                content: `Would you like to unlock it to make any changes?`,
                centered: true,
                transitionName: 'fade',
                maskTransitionName: 'none',
                okText: 'Dismiss',
                cancelText: 'Make changes',
                icon: 'lock',
                onOk() {
                    console.log('Cancel');
                },
                onCancel() {
                    unlockTournament({
                        variables: {
                            id: tournament.id,
                        },
                        awaitRefetchQueries: true,
                        notifyOnNetworkStatusChange: true,
                        refetchQueries: [
                            {
                                query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                variables: { slug: tournament.slug },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);

                            message.success('Tournament bracket unlocked!');
                        })
                        .catch((error) => {
                            console.log(error);

                            message.error('There was an error', error);
                        });
                },
            });

            // Modal.warning({
            // 	title: 'This league is in a deleted state',
            // 	content: `Would you like to undo this action?`,
            // 	centered: true,
            // 	okText: 'Undo',
            // 	cancelText: 'Cancel',
            // 	onOk: () => {
            // 		console.log('ok');
            // 	},
            // 	onCancel: () => {
            // 		console.log('cancel');
            // 	}
            // });
        }
    }, [tournament, unlockTournament]);

    return (
        <SectionWrapper>
            {showNavigation !== false && (
                <React.Fragment>
                    <SectionHeader
                        title={props.title}
                        titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                        subtitle={tournament.name}
                        subtitlePrefix={true}
                        subtitleStyle={{
                            color: 'rgba(255,255,255,0.8)',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: 12,
                            letterSpacing: 3,
                        }}
                        height={90}
                        theme="tournamentBuilder"
                    >
                        <TBManageButtons page="viewer" {...props} />
                    </SectionHeader>
                    {/* <TournamentProgressBar {...props} /> */}
                </React.Fragment>
            )}

            {/* <SectionContent padding="0px" style={{ height: 'calc(100% - 120px)' }}> */}
            <SectionContent padding="0px" className="bracket-wrapper">
                <Fullscreen enabled={isFullScreen} onChange={(isFull) => setIsFullScreen(isFull)}>
                    <div
                        id="bracket-container"
                        className="full-screenable-node"
                        style={{
                            position: 'relative',
                            overflow: 'auto',
                            width: '100%',
                            height: '100%',
                            // background: 'linear-gradient(180deg, #fff, #f0f2f5)'
                            // backgroundColor: '#eaedef'
                        }}
                    >
                        <Query
                            query={GET_ALL_TOURNAMENT_TABLES_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ tournament_id: tournament.id }}
                            onCompleted={(data, loading, error) => {
                                if (!loading && !error) {
                                    const { pool_tables } = data;
                                    setTables(pool_tables);
                                    // if (
                                    // 	pool_tables.length > 0
                                    // ) {
                                    // 	showConfirmDraw(drawRound, winnerBracket, matches);
                                    // }
                                    const stages = tournament.stages;
                                    console.log('stages', stages);
                                    if (stages && stages.length > 0) {
                                        let defaultStage;
                                        if (stageSlug) {
                                            defaultStage = stages.filter((stage) => {
                                                return stage.slug === stageSlug;
                                            })[0];
                                        } else {
                                            defaultStage = stages[0];
                                        }
                                        console.log(defaultStage);
                                        setStages(stages);
                                        setSelectedStage(defaultStage);
                                    }
                                }
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading)
                                    return (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                        </div>
                                    );
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const tournamentType = (selectedStage && selectedStage.tournament_type) || (tournament && tournament.tournament_type);
                                console.log('tournament type', tournamentType);

                                return (
                                    <React.Fragment>
                                        {tournamentType === 'round_robin' ? (
                                            <RoundRobinViewer
                                                tournament={tournament}
                                                stages={stages}
                                                selectedStage={selectedStage}
                                                tables={tables}
                                                urlPath="/viewer"
                                                isFullScreen={isFullScreen}
                                                onFullScreen={(value) => setIsFullScreen(value)}
                                                // setRoundTimes={false}
                                                playerSearchCoordinates={playerSearchCoordinates}
                                                onUpdateCoordinates={(coords) => {
                                                    setPlayerSearchCoordinates(coords);
                                                }}
                                                onSelectedStage={(stage) => {
                                                    setSelectedStage(stage);
                                                }}
                                                compact={false}
                                                {...props}
                                            />
                                        ) : tournamentType === 'chip' ? (
                                            <Viewer
                                                tournament={tournament}
                                                stages={stages}
                                                selectedStage={selectedStage}
                                                tables={tables}
                                                urlPath="/viewer"
                                                isFullScreen={isFullScreen}
                                                onFullScreen={(value) => setIsFullScreen(value)}
                                                // setRoundTimes={false}
                                                playerSearchCoordinates={playerSearchCoordinates}
                                                onUpdateCoordinates={(coords) => {
                                                    setPlayerSearchCoordinates(coords);
                                                }}
                                                compact={false}
                                                {...props}
                                            />
                                        ) : (
                                            <Fullscreen enabled={isFullScreen} onChange={(isFull) => setIsFullScreen(isFull)}>
                                                <div
                                                    id="bracket-container"
                                                    className="full-screenable-node"
                                                    style={{
                                                        position: 'relative',
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Bracket
                                                        // ref={bracketRef}
                                                        tournament={tournament}
                                                        stages={stages}
                                                        selectedStage={selectedStage}
                                                        tables={tables}
                                                        urlPath="/viewer"
                                                        isFullScreen={isFullScreen}
                                                        onFullScreen={(value) => setIsFullScreen(value)}
                                                        // setRoundTimes={false}
                                                        playerSearchCoordinates={playerSearchCoordinates}
                                                        onUpdateCoordinates={(coords) => {
                                                            setPlayerSearchCoordinates(coords);
                                                        }}
                                                        onStageSelected={(stage) => {
                                                            setSelectedStage(stage);
                                                        }}
                                                        compact={false}
                                                        {...props}
                                                    />
                                                </div>
                                            </Fullscreen>
                                        )}
                                    </React.Fragment>
                                );
                            }}
                        </Query>
                    </div>
                </Fullscreen>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBViewer);
