import { gql } from '@apollo/client';

export const GET_USER = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            slug
            email
            country
            region
            city
            avatar
        }
    }
`;

export const GET_PLAYER_BY_ID = gql`
    query players($id: Int!) {
        players(where: { id: { _eq: $id } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;

export const GET_PLAYER_BY_SLUG = gql`
    query players($slug: String!) {
        players(where: { slug: { _eq: $slug } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;

export const GET_USER_PROFILE_BY_EMAIL = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            default_timezone
            skill_level
            email_verified
            photo_url
            gender
            nickname
            display_name
            status
            fargo_id
            player_id
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_robustness
            fargo_uuid
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            account_disabled
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                city
                region
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
                fargo_uuid
            }
            followers_aggregate {
                aggregate {
                    count
                }
            }
            following_aggregate {
                aggregate {
                    count
                }
            }
            friends1_aggregate {
                aggregate {
                    count
                }
            }
            friends2_aggregate {
                aggregate {
                    count
                }
            }
            friend_requests_aggregate {
                aggregate {
                    count
                }
            }
            tournament_director_aggregate {
                aggregate {
                    count
                }
            }
            tournament_organizer_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            league_owner_aggregate {
                aggregate {
                    count
                }
            }
            league_operator_aggregate {
                aggregate {
                    count
                }
            }
            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_USER_PROFILE_BY_SLUG = gql`
    query users($slug: String!) {
        users(where: { slug: { _eq: $slug } }) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            default_timezone
            skill_level
            email_verified
            photo_url
            gender
            nickname
            display_name
            status
            fargo_id
            player_id
            fargo_robustness
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            account_disabled
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                city
                region
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
                fargo_uuid
                tournament_players_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            followers_aggregate {
                aggregate {
                    count
                }
            }
            following_aggregate {
                aggregate {
                    count
                }
            }
            friends1_aggregate {
                aggregate {
                    count
                }
            }
            friends2_aggregate {
                aggregate {
                    count
                }
            }
            friend_requests_aggregate {
                aggregate {
                    count
                }
            }
            tournament_director_aggregate {
                aggregate {
                    count
                }
            }
            tournament_organizer_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            league_owner_aggregate {
                aggregate {
                    count
                }
            }
            league_operator_aggregate {
                aggregate {
                    count
                }
            }
            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_USER_PROFILE_BY_ID = gql`
    query users($id: Int!) {
        users(where: { id: { _eq: $id } }) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            default_timezone
            skill_level
            email_verified
            photo_url
            nickname
            display_name
            status
            fargo_id
            player_id
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_robustness
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            account_disabled
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                city
                region
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
            }
            followers_aggregate {
                aggregate {
                    count
                }
            }
            following_aggregate {
                aggregate {
                    count
                }
            }
            friends1_aggregate {
                aggregate {
                    count
                }
            }
            friends2_aggregate {
                aggregate {
                    count
                }
            }
            friend_requests_aggregate {
                aggregate {
                    count
                }
            }
            tournament_director_aggregate {
                aggregate {
                    count
                }
            }
            tournament_organizer_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            league_owner_aggregate {
                aggregate {
                    count
                }
            }
            league_operator_aggregate {
                aggregate {
                    count
                }
            }
            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_USER_LEAGUES_QUERY = gql`
    query league_players($userId: Int, $limit: Int) {
        league_players(where: { user_id: { _eq: $userId } }, order_by: { updated_at: desc }, limit: $limit) {
            id
            league {
                name
                slug
                short_description
                avatar
                logo
                deleted_at
            }
        }
        leagues(where: { _or: [{ owner_id: { _eq: $userId }, operator_id: { _eq: $userId } }] }, order_by: { updated_at: desc }, limit: $limit) {
            id
            name
            slug
            short_description
            avatar
            logo
            deleted_at
        }
    }
`;

export const GET_USER_TOURNAMENTS_QUERY = gql`
    query tournament_players($playerId: Int, $userId: Int, $limit: Int) {
        tournament_players(where: { player_id: { _eq: $playerId }, tournament: { is_public: { _eq: true } } }, order_by: { updated_at: asc }, limit: $limit) {
            id
            place
            tournament {
                name
                slug
                description
                game_type
                avatar
                logo
                progress
                organizer_id
                director_id
                created_at
                updated_at
            }
        }
        tournaments(where: { _or: [{ director_id: { _eq: $userId }, organizer_id: { _eq: $userId } }] }, order_by: { updated_at: asc }, limit: $limit) {
            id
            name
            slug
            description
            game_type
            avatar
            logo
            progress
            organizer_id
            director_id
            created_at
            updated_at
        }
    }
`;

export const SEARCH_UNLINKED_TOURNAMENT_MATCHES_QUERY = gql`
    query search_unlinked_tournament_matches($search: String, $limit: Int) {
        search_unlinked_tournament_matches(args: { search: $search }) {
            round
            status
            table_id
            table_name
            table_number
            start_time
            seed
            scheduled_time
            progress
            place
            match_id
            match_number
            game_type
            is_bye
            challenger1 {
                id
                name
                player_id
                user_id
            }
            challenger1_country
            challenger1_id
            challenger1_name
            challenger1_race_to
            challenger1_score
            challenger1_seed
            challenger1_sms_sent
            challenger2 {
                id
                name
                player_id
                user_id
            }
            challenger2_country
            challenger2_id
            challenger2_name
            challenger2_race_to
            challenger2_score
            challenger2_is_winner
            challenger2_is_forfeit
            challenger1_is_forfeit
            challenger1_is_winner
            challenger2_seed
            hash_id
            identifier
            created_at
            updated_at
            tournament {
                id
                name
                slug
            }
        }
    }
`;

export const GET_USER_UNLINKED_MATCHES_QUERY = gql`
    query tournament_brackets($name: String, $limit: Int) {
        tournament_brackets(
            where: { _or: [{ challenger1_name: { _ilike: $name } }, { challenger2_name: { _ilike: $name } }], is_bye: { _eq: false } }
            order_by: { created_at: desc }
        ) {
            round
            status
            table_id
            table_name
            table_number
            start_time
            seed
            scheduled_time
            progress
            place
            match_id
            match_number
            game_type
            is_bye
            challenger1 {
                id
                name
                player_id
                user_id
            }
            challenger1_country
            challenger1_id
            challenger1_name
            challenger1_race_to
            challenger1_score
            challenger1_seed
            challenger1_sms_sent
            challenger2 {
                id
                name
                player_id
                user_id
            }
            challenger2_country
            challenger2_id
            challenger2_name
            challenger2_race_to
            challenger2_score
            challenger2_is_winner
            challenger2_is_forfeit
            challenger1_is_forfeit
            challenger1_is_winner
            challenger2_seed
            hash_id
            identifier
            created_at
            updated_at
            tournament {
                id
                name
                slug
            }
        }
    }
`;

export const GET_USER_UNLINKED_MATCHES_QUERY2 = gql`
    query tournament_players($name: String, $limit: Int) {
        tournament_players(where: { name: { _ilike: $name } }) {
            id
            place
            tournament {
                id
                name
                slug
                tournament_brackets(
                    where: {
                        _or: [{ challenger1_name: { _ilike: $name } }, { challenger2_name: { _ilike: $name } }]
                        is_bye: { _eq: false }
                        tournament: { is_public: { _eq: true } }
                        status: { _eq: COMPLETED }
                    }
                    order_by: { created_at: desc }
                ) {
                    round
                    status
                    table_id
                    table_name
                    table_number
                    start_time
                    seed
                    scheduled_time
                    progress
                    place
                    match_id
                    match_number
                    game_type
                    challenger1 {
                        id
                        name
                        player_id
                        user_id
                    }
                    challenger1_country
                    challenger1_id
                    challenger1_name
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_score
                    challenger1_seed
                    challenger1_sms_sent
                    challenger2_country
                    challenger2 {
                        id
                        name
                        player_id
                        user_id
                    }
                    challenger2_id
                    challenger2_name
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger1_is_forfeit
                    challenger1_is_winner
                    challenger2_seed
                    hash_id
                    identifier
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_USER_MATCHES_QUERY = gql`
    query tournament_brackets($user_id: Int, $limit: Int) {
        tournament_brackets(
            where: {
                tournament: { is_public: { _eq: true } }
                _or: [{ challenger1: { user_id: { _eq: $user_id } } }, { challenger2: { user_id: { _eq: $user_id } } }]
            }
            order_by: { created_at: desc }
        ) {
            round
            status
            table_id
            table_name
            table_number
            start_time
            seed
            scheduled_time
            progress
            place
            match_id
            match_number
            game_type
            challenger1_country
            challenger1_id
            challenger1_name
            challenger1_race_to
            challenger1_score
            challenger1_seed
            challenger1_sms_sent
            challenger2_country
            challenger2_id
            challenger2_name
            challenger2_race_to
            challenger2_score
            challenger2_is_winner
            challenger2_is_forfeit
            challenger1_is_forfeit
            challenger1_is_winner
            challenger2_seed
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            hash_id
            identifier
            created_at
            updated_at
        }
    }
`;

export const GET_USER_MATCHES_QUERY2 = gql`
    query tournament_players($player_id: Int, $limit: Int) {
        tournament_players(where: { player_id: { _eq: $player_id }, tournament: { is_public: { _eq: true } } }) {
            id
            place
            tournament {
                id
                name
                slug
                tournament_brackets(
                    where: {
                        _or: [{ challenger1: { player_id: { _eq: $player_id } } }, { challenger2: { player_id: { _eq: $player_id } } }]
                        is_bye: { _eq: false }
                        tournament: { is_public: { _eq: true } }
                        status: { _eq: COMPLETED }
                    }
                    order_by: { created_at: desc }
                ) {
                    round
                    status
                    table_id
                    table_name
                    table_number
                    start_time
                    seed
                    scheduled_time
                    progress
                    place
                    match_id
                    match_number
                    game_type
                    challenger1 {
                        id
                        name
                        player_id
                        user_id
                    }
                    challenger1_country
                    challenger1_id
                    challenger1_name
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_score
                    challenger1_seed
                    challenger1_sms_sent
                    challenger2_country
                    challenger2 {
                        id
                        name
                        player_id
                        user_id
                    }
                    challenger2_id
                    challenger2_name
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger1_is_forfeit
                    challenger1_is_winner
                    challenger2_seed
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    hash_id
                    identifier
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_EFFECTIVE_RATINGS = gql`
    query vw_tournament_effective_ratings($player_name: String!) {
        vw_tournament_effective_ratings(where: { player_name: { _eq: $player_name } }) {
            to_par
            player_name
            effective_rating
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS = gql`
    query vw_tournament_player_effective_ratings($name: String!) {
        vw_tournament_player_effective_ratings(where: { name: { _ilike: $name } }) {
            to_par
            name
            id
            country
            skill_level
            fargo_rating
            effective_rating
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS_BY_ID = gql`
    query vw_tournament_player_effective_ratings($id: Int!) {
        vw_tournament_player_effective_ratings(where: { user_id: { _eq: $id } }) {
            to_par
            name
            id
            user_id
            player_id
            country
            skill_level
            fargo_rating
            effective_rating
            tournament_id
        }
    }
`;

export const GET_USER_TOURNAMENT_STATS_QUERY = gql`
    query tournament_players($player_name: String) {
        tournament_players(where: { name: { _ilike: $player_name }, tournament: { is_public: { _eq: true } } }, order_by: { tournament: { created_at: asc } }) {
            tournament {
                id
                name
                slug
                start_date_time
            }
            place
            skill_level

            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
        }
    }
`;

export const GET_USER_TOURNAMENT_STATS_BY_ID_QUERY = gql`
    query tournament_players($user_id: Int!) {
        tournament_players(where: { user_id: { _eq: $user_id }, tournament: { is_public: { _eq: true } } }, order_by: { tournament: { created_at: asc } }) {
            tournament {
                id
                name
                slug
                start_date_time
            }
            place
            skill_level

            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, is_bye: { _eq: false }, tournament: { is_public: { _eq: true } }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                }
            }
        }
    }
`;

export const GET_USER_POSTS = gql`
    query posts($user_id: Int!) {
        posts(where: { user_id: { _eq: $user_id } }, order_by: { created_at: desc }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate(where: { comment_id: { _is_null: true } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_QUERY = gql`
    query posts($id: Int!) {
        posts(where: { id: { _eq: $id } }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            user {
                id
                first_name
                last_name
                slug
                email
                photo_url
                avatar
                display_name
                country
            }
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_BY_SLUG = gql`
    query posts($slug: String!) {
        posts(where: { slug: { _eq: $slug } }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            user {
                id
                first_name
                last_name
                slug
                email
                photo_url
                avatar
                display_name
                country
            }
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_COMMENTS = gql`
    query post_comments($post_id: Int!) {
        post_comments(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            id
            message
            user_id
            user {
                id
                first_name
                last_name
                slug
                avatar
            }
            post_id
            parent_id
            created_at
            likes_aggregate(where: { comment_id: { _is_null: false } }) {
                aggregate {
                    count
                }
            }
            children_aggregate {
                aggregate {
                    count
                }
            }
            children(order_by: { created_at: desc }) {
                id
                message
                user_id
                user {
                    id
                    first_name
                    last_name
                    slug
                    avatar
                }
                post_id
                parent_id
                created_at
                likes_aggregate(where: { comment_id: { _is_null: false } }) {
                    aggregate {
                        count
                    }
                }
                children_aggregate {
                    aggregate {
                        count
                    }
                }
                children(order_by: { created_at: desc }) {
                    id
                    message
                    user_id
                    user {
                        id
                        first_name
                        last_name
                        slug
                        avatar
                    }
                    post_id
                    parent_id
                    created_at
                    likes_aggregate(where: { comment_id: { _is_null: false } }) {
                        aggregate {
                            count
                        }
                    }
                    children_aggregate {
                        aggregate {
                            count
                        }
                    }
                    children(order_by: { created_at: desc }) {
                        id
                        message
                        user_id
                        user {
                            id
                            first_name
                            last_name
                            slug
                            avatar
                        }
                        post_id
                        parent_id
                        created_at
                        likes_aggregate(where: { comment_id: { _is_null: false } }) {
                            aggregate {
                                count
                            }
                        }
                        children_aggregate {
                            aggregate {
                                count
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_POST_LIKES = gql`
    query post_likes($post_id: Int!) {
        post_likes(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_POST_LIKE_COUNT = gql`
    query post_likes_aggregate($post_id: Int!) {
        post_likes_aggregate(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_POST_COMMENT_LIKE_COUNT = gql`
    query post_likes_aggregate($post_id: Int!, $comment_id: Int!) {
        post_likes_aggregate(where: { post_id: { _eq: $post_id }, comment_id: { _eq: $comment_id } }, order_by: { created_at: desc }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_USER_POST_LIKES = gql`
    query post_likes($post_id: Int!, $user_id: Int!) {
        post_likes(where: { user_id: { _eq: $user_id }, post_id: { _eq: $post_id }, comment_id: { _is_null: true } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_POST_COMMENT_LIKES = gql`
    query post_likes($post_id: Int!, $user_id: Int!, $comment_id: Int!) {
        post_likes(where: { user_id: { _eq: $user_id }, post_id: { _eq: $post_id }, comment_id: { _eq: $comment_id } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FOLLOWERS = gql`
    query followers($followed_user_id: Int!) {
        followers(where: { followed_user_id: { _eq: $followed_user_id } }, order_by: { created_at: desc }) {
            id
            follower_user_id
            followed_user_id
            followed_date
            unfollowed_date
            created_at
            updated_at
        }
    }
`;

export const FOLLOWER_USER_QUERY = gql`
    query followers($followed_user_id: Int!, $follower_user_id: Int!) {
        followers(where: { followed_user_id: { _eq: $followed_user_id }, follower_user_id: { _eq: $follower_user_id } }, limit: 1) {
            id
            follower_user_id
            followed_user_id
            followed_date
            unfollowed_date
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FRIEND_REQUESTS_QUERY = gql`
    query friend_requests($to_user_id: Int!) {
        friend_requests(where: { to_user_id: { _eq: $to_user_id }, is_accepted: { _is_null: false } }) {
            id
            from_user_id
            from_user {
                id
                slug
                first_name
                last_name
                avatar
            }
            to_user_id
            is_accepted
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FRIEND_REQUESTS_SENT_QUERY = gql`
    query friend_requests($to_user_id: Int!, $from_user_id: Int!) {
        friend_requests(where: { to_user_id: { _eq: $to_user_id }, from_user_id: { _eq: $from_user_id } }, limit: 1) {
            id
            from_user_id
            to_user_id
            is_accepted
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FRIENDS_QUERY = gql`
    query friends($user_id: Int!) {
        friends(where: { _or: [{ user1_id: { _eq: $user_id } }, { user2_id: { _eq: $user_id } }] }) {
            id
            user1_id
            user1 {
                id
                slug
                first_name
                last_name
                avatar
            }
            user2_id
            user2 {
                id
                slug
                first_name
                last_name
                avatar
            }
            status
            created_at
            updated_at
        }
    }
`;
export const GET_USER_NEARBY_VENUES_QUERY = gql`
    query vw_user_venue_distance($user_id: Int!) {
        vw_user_venue_distance(where: { user_id: { _eq: $user_id }, distance: { _lt: 40233 } }, order_by: { distance: asc }) {
            id
            name
            slug
            distance
            venue {
                id
                name
                slug
                address1
                city
                region
                country
                postal_code
            }
        }
    }
`;

export const GET_VENUE_NEARBY_USERS_QUERY = gql`
    query vw_user_venue_distance($venue_id: Int!) {
        vw_user_venue_distance(where: { id: { _eq: $venue_id }, distance: { _lt: 991000 } }) {
            user_id
            id
            name
            slug
            distance
        }
    }
`;

export const SEARCH_PLAYERS = gql`
    query players($name: String!) {
        players(where: { name: { _ilike: $name } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;

export const SEARCH_UNLINKED_PLAYERS_QUERY = gql`
    query search_unlinked_players($search: String, $limit: Int) {
        search_unlinked_players(args: { search: $search }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;
