import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import Card from '../../../components/Card';
import StreamOverlayLarge from '../tournaments/StreamOverlayLarge';
import { GET_EVENT_BY_SLUG_QUERY, GET_EVENT_LIVE_TABLE_QUERY2 } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SUBSCRIPTION, GET_EVENT_LIVE_TABLE_SUBSCRIPTION2 } from '../data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import liveStreamSS from '../../../assets/img/live-stream-bg.png';
import qs from 'query-string';
import DefaultStreamOverlayTemplate from '../tournaments/templates/DefaultStreamOverlayTemplate';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function EventStreamOverlay(props) {
    const { client } = props;
    const { match, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const eventSlug = match.params.slug;
    const tableSlug = match.params.table;
    const showPhoto = params && params.photo === 'true' ? true : false;
    const [newData, setNewData] = useState();

    async function subscribeToNewChanges(subscribeToMore) {
        console.log('subscribe to new changes');
        const unsubscribe = subscribeToMore({
            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION2,
            variables: {
                event_slug: eventSlug,
                table_slug: tableSlug,
            },
            onSubscriptionData: (data) => {
                console.log('subscription data', data);
                setNewData(true);
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return prev;
            },
        });

        return () => unsubscribe();
    }

    return (
        <Query
            query={GET_EVENT_LIVE_TABLE_QUERY2}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ event_slug: eventSlug, table_slug: tableSlug }}
            onCompleted={(data, error, loading) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader /> <span style={{ marginLeft: 10 }}>Loading...</span>
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                console.log(data);
                let tableData;
                let matchData;
                let tournamentData;
                let tables = [];

                data &&
                    data.events &&
                    data.events.forEach((event) => {
                        const eventMatches = event.matches;
                        const eventTournaments = event.tournaments;
                        const eventVenueTables = event.venue && event.venue.pool_tables;

                        eventMatches &&
                            eventMatches.forEach((match) => {
                                if (match.pool_table && match.pool_table.matches) {
                                    match.pool_table.matches.forEach((item) => {
                                        matchData = item;
                                        tableData = match.pool_table;
                                    });
                                }
                            });

                        eventTournaments &&
                            eventTournaments.forEach((tournament) => {
                                if (tournament.pool_tables) {
                                    tournament.pool_tables.forEach((table) => {
                                        if (table.tournament_match_table && table.tournament_match_table[0]) {
                                            matchData = table.tournament_match_table[0];
                                            tableData = table;
                                            tournamentData = tournament;
                                        }
                                    });
                                }
                            });

                        eventVenueTables &&
                            eventVenueTables.forEach((table) => {
                                if (table) {
                                    tables.push(table);
                                }
                            });
                    });

                console.log({
                    tableData,
                    tables,
                    matchData,
                    tournamentData,
                });

                subscribeToNewChanges(subscribeToMore);

                if (data && !tableData && !tables.length) {
                    return (
                        <div>
                            <Title
                                level={4}
                                style={{
                                    marginLeft: 20,
                                    marginTop: 10,
                                    color: '#fff',
                                    letterSpacing: 0,
                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                }}
                            >
                                Waiting for match on {tableSlug}
                            </Title>
                        </div>
                    );
                }

                console.log(tableData);
                console.log(tournamentData);
                console.log(matchData);

                if (tournamentData) {
                    return (
                        <React.Fragment>
                            {showPhoto === true && (
                                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
                                    <img src={liveStreamSS} alt="Live stream screenshot" width="100%" />
                                </div>
                            )}
                            <DefaultStreamOverlayTemplate
                                tournamentData={tournamentData}
                                matchData={matchData}
                                tableData={tableData}
                                showScores={true}
                                subscribeToMore={subscribeToMore}
                                subscribeToNewData={() => {
                                    subscribeToMore({
                                        document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                        variables: { event_slug: eventSlug, table_slug: tableSlug },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            if (!subscriptionData.data) return prev;
                                            const newTournament = subscriptionData.data.tournaments || [];
                                            let updated;
                                            if (prev) {
                                                updated = Object.assign({}, prev, {
                                                    tournaments: [...newTournament],
                                                });
                                            } else {
                                                updated = Object.assign({}, prev, {
                                                    tournaments: [...newTournament],
                                                });
                                            }
                                            return updated;
                                        },
                                    });
                                }}
                                {...props}
                            />
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment>
                            {showPhoto === true && (
                                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
                                    <img src={liveStreamSS} alt="Live stream screenshot" width="100%" />
                                </div>
                            )}
                            <DefaultStreamOverlayTemplate
                                tournamentData={tournamentData}
                                matchData={matchData}
                                tableData={tableData}
                                showScores={true}
                                subscribeToMore={subscribeToMore}
                                subscribeToNewData={() => {
                                    subscribeToMore({
                                        document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION2,
                                        variables: { event_slug: eventSlug, table_slug: tableSlug },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            if (!subscriptionData.data) return prev;
                                            const newMatch = subscriptionData.data.matches;
                                            let updated;
                                            if (prev) {
                                                updated = Object.assign({}, prev, {
                                                    matches: newMatch ? [...newMatch] : [],
                                                });
                                            } else {
                                                updated = Object.assign({}, prev, {
                                                    matches: newMatch ? [...newMatch] : [],
                                                });
                                            }
                                            return updated;
                                        },
                                    });
                                }}
                                {...props}
                            />
                        </React.Fragment>
                    );
                }
            }}
        </Query>
    );
}

export default withRouter(EventStreamOverlay);
