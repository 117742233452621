import React, { useState, useEffect } from 'react';
import { Link, Switch, withRouter, Route } from 'react-router-dom';
import { Tabs } from 'antd';
import SectionTitle from '../../components/SectionTitle';
import SectionWrapper from '../../components/SectionWrapper';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import * as routes from '../../config/routes';
import _ from 'lodash';
import AllPlayers from './players/AllPlayers';
import ProRankings from './players/ProRankings2';
import PlayerDetail from './players/PlayerDetail';

const { TabPane } = Tabs;

const styles = {
    headerImg: {
        background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
        // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        // backgroundPosition: '0px -400px'

        // backgroundPosition: '0px -40px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100%'
    },
};

function Players(props) {
    const { history } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['all', 'pro-rankings'];
    const [current, setCurrent] = useState('all');
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/players/${tab}`);
    };

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : 'all';

        setCurrent(pathMatch);
    }, [urlPath, dpIncludes, splitPath, params]);

    return (
        <SectionWrapper>
            <div style={styles.headerImg}>
                <Switch>
                    <Route
                        path={`${props.match.path}`}
                        render={(matchProps) => (
                            <React.Fragment>
                                <SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 80} />

                                <Tabs
                                    defaultActiveKey={current}
                                    activeKey={current}
                                    animated={{ inkBar: true, tabPane: false }}
                                    // renderTabBar={() => <ScrollableInkTabBar />}
                                    // renderTabContent={() => <TabContent />}
                                    onTabClick={handleTabClick}
                                    className="tabs-bg"
                                    tabPosition="top"
                                    tabBarStyle={{
                                        textTransform: 'uppercase',
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        paddingTop: 0,
                                        // backgroundColor: '#132339',
                                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                        color: '#fff',
                                        zIndex: 1,
                                        position: 'relative',
                                        borderBottom: 'none',
                                        borderTop: '1px solid rgba(255,255,255,0.1)',
                                    }}
                                >
                                    <TabPane tab="All Players" key="all" style={styles.tab}>
                                        <Switch>
                                            <Route
                                                exact
                                                path={routes.DIGITALPOOL.ALL_PLAYERS}
                                                render={(matchProps) => <AllPlayers {...matchProps} {...props} />}
                                            />
                                            <Route exact path={routes.DIGITALPOOL.PLAYERS} render={(matchProps) => <AllPlayers {...matchProps} {...props} />} />
                                            <Route
                                                exact
                                                path={routes.DIGITALPOOL.PLAYER_DETAIL}
                                                render={(matchProps) => <PlayerDetail {...matchProps} {...props} />}
                                            />
                                        </Switch>
                                    </TabPane>
                                    <TabPane tab="Pro Rankings" key="pro-rankings" style={styles.tab}>
                                        <Switch>
                                            {/* <Route exact path={routes.DIGITALPOOL.PLAYERS} render={(matchProps) => <ProRankings {...matchProps} {...props} />} /> */}
                                            <Route
                                                exact
                                                path={routes.DIGITALPOOL.PRO_RANKINGS}
                                                render={(matchProps) => <ProRankings {...matchProps} {...props} />}
                                            />
                                        </Switch>
                                    </TabPane>
                                </Tabs>
                            </React.Fragment>
                        )}
                    />

                    <Route
                        exact
                        path={`${props.match.path}/:slug`}
                        render={(matchProps) => (
                            <React.Fragment>
                                <PlayerDetail {...props} />
                            </React.Fragment>
                        )}
                    />
                </Switch>
            </div>
        </SectionWrapper>
    );
}
export default withRouter(Players);
