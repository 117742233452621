import React, { useState, useEffect, useRef } from 'react';
import { message, Tooltip, Button, Icon, Typography, Popconfirm, Avatar, Drawer, Badge, Empty, Modal, Menu, Dropdown } from 'antd';
import { Form, FormItem, AutoComplete, Input, Radio, InputNumber, Checkbox, DatePicker, Select, FormikDebug, SubmitButton, ResetButton } from 'formik-antd';
import { getGameType, displayEnumType, getContrastColor } from '../../../../utils';
import { Formik, ErrorMessage } from 'formik';
import {
    FILTER_TOURNAMENT_PLAYERS,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_BRACKET_MATCH_QUERY,
    GET_ALL_TOURNAMENT_TABLES_QUERY,
    GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_QUERY,
    GET_EVENT_LIVE_TABLES_QUERY,
} from '../data/queries';
import {
    ADVANCE_TOURNAMENT_BRACKET_MUTATION,
    ADVANCE_TOURNAMENT_BRACKET_STAGE_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    UPDATE_TABLE_STATUS_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    GENERATE_TOURNAMENT_MATCH_TIME_PROJECTIONS,
    LOG_PLAYER_SMS_NOTIFICATION,
    UPDATE_TOURNAMENT_MATCH_TIME,
} from '../data/mutations';
import { functions } from '../../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { DigitalPoolAPI } from '../../../../lib/api';
import * as Yup from 'yup';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query, Mutation } from '@apollo/client/react/components';
import { calculateUSAPLRace } from '../../../../utils';
import _ from 'lodash';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import PrintComponent from './PrintComponent';
import ColorPicker from './ColorPicker';
import RoundTimeEntry from './RoundTimeEntry';
import { PrinterOutlined, LinkOutlined } from '@ant-design/icons';
import { QuestionCircleFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

const styles = {
    input: {
        minWidth: 300,
        padding: 5,
    },
    inputSmall: {
        // maxWidth: 50,
        padding: 5,
        textAlign: 'center',
    },
};

function MatchOverlay(props) {
    const {
        currentRound,
        matches,
        originalMatches,
        currentMatch,
        stages,
        stage,
        modalScoreVisible,
        onModalScoreVisible,
        tournament,
        bracket,
        isFullScreen,
        onTournamentComplete,
        onSave,
        theme,
    } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    // const [ value, setValue ] = useState();
    const [loading, setLoading] = useState(false);
    const [fargoLoading, setFargoLoading] = useState(false);
    const [selectedTable, setSelectedTable] = useState();
    const [tables, setTables] = useState();
    // const [ dataSource, setDataSource ] = useState([]);
    const [players, setPlayers] = useState([]);
    const [players1, setPlayers1] = useState([]);
    const [players2, setPlayers2] = useState([]);
    const [winner, setWinner] = useState();
    const [scheduledTime, setScheduledTime] = useState((currentMatch && currentMatch.scheduled_time) || null);
    const [matchColor, setMatchColor] = useState();
    const [showMatchTimeOverlay, setShowMatchTimeOverlay] = useState(false);
    const [showFargoColumnCharts, setShowFargoColumnCharts] = useState(false);
    const [selectedFargoRace, setSelectedFargoRace] = useState(tournament && tournament.winners_race_to);
    const [challenger1Result, setChallenger1Result] = useState();
    const [challenger2Result, setChallenger2Result] = useState();
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
    const [confirmEndTournamentPopupVisible, setConfirmEndTournamentPopupVisible] = useState(false);
    const [confirmTablePopupVisible, setConfirmTablePopupVisible] = useState(false);
    const [confirmScheduledTablePopupVisible, setConfirmScheduledTablePopupVisible] = useState(false);
    const [tournamentComplete, setTournamentComplete] = useState(false);
    const [generateTournamentMatchTimeProjections] = useMutation(GENERATE_TOURNAMENT_MATCH_TIME_PROJECTIONS);
    const [updateTableStatus, tableProps] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const [updateTournament, tournamentProps] = useMutation(UPDATE_TOURNAMENT_MUTATION);
    const [advanceTournamentStage] = useMutation(ADVANCE_TOURNAMENT_BRACKET_STAGE_MUTATION);
    const [updateMatchTime] = useMutation(UPDATE_TOURNAMENT_MATCH_TIME);
    const [logSMSNotification, { smsNotificationLoading, smsNotificationError }] = useMutation(LOG_PLAYER_SMS_NOTIFICATION);
    const [updateTournamentPlayer, tournamentPlayerProps] = useMutation(UPDATE_TOURNAMENT_PLAYER_MUTATION);
    const formRef = useRef(null);
    const formRef2 = useRef(null);
    const API = DigitalPoolAPI(props);

    // console.log('tournament', tournament);

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            const res = await props.client.query({
                query: GET_TOURNAMENT_PLAYERS_QUERY,
                variables: {
                    tournament_id: tournament.id,
                },
            });
            const players = res.data.tournament_players;
            // console.log('players', players);
            setPlayers(players);
            setScheduledTime(currentMatch.scheduled_time || null);
            setMatchColor(currentMatch.match_color || 'transparent');
            console.log('players', players);
        }
        if (currentMatch) {
            console.log('current match', currentMatch);
            fetchData();
        }

        return function cleanup() {
            mounted = false;
        };
    }, [currentMatch, tournament.id]);

    // useEffect(
    // 	() => {
    // 		if (currentMatch) {
    // 			if (currentMatch.challenger1_is_winner === true) {
    // 				setChallenger1Result('winner');
    // 				setChallenger2Result();
    // 			} else if (currentMatch.challenger2_is_winner === true) {
    // 				setChallenger2Result('winner');
    // 				setChallenger1Result();
    // 			} else if (currentMatch.challenger1_is_forfeit === true) {
    // 				setChallenger1Result('forfeit');
    // 			} else if (currentMatch.challenger2_is_forfeit === true) {
    // 				setChallenger2Result('forfeit');
    // 			}
    // 		}
    // 	},
    // 	[ currentMatch ]
    // );

    function handleScoreModalCancel() {
        onModalScoreVisible(false);
        setChallenger1Result();
        setChallenger2Result();
        setScheduledTime(null);
        setMatchColor(null);
    }

    async function queryDB(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: FILTER_TOURNAMENT_PLAYERS,
            variables: {
                name: `%${name}%`,
                tournament_id: tournament.id,
            },
            fetchPolicy: 'no-cache',
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            dataSet.push(player);
        }
        return dataSet;
    }

    async function onSearch1(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        setPlayers1(players);
    }

    async function onSearch2(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        setPlayers2(players);
    }

    const onSelectPlayer1 = async (value) => {
        console.log(value);
    };

    const onSelectPlayer2 = async (value) => {
        console.log(value);
    };

    // function handleBlur(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // 	const { value } = e.target;
    // 	console.log(value);
    // }

    // function onChange(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // }

    // function onEnter(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // 	const { value } = e.target;
    // 	console.log(value);
    // }

    function handleFocus(event) {
        event.target.select();
    }

    function handleSelectTableChange(id) {
        const table = tables.filter((item) => {
            return item.id === id;
        })[0];
        setSelectedTable(table);
    }

    function getWinnerLoser(values) {
        const {
            challenger1,
            challenger1_id,
            challenger1_name,
            challenger1_score,
            challenger1_race_to,
            challenger1_seed,
            challenger1_country,
            challenger2,
            challenger2_id,
            challenger2_name,
            challenger2_score,
            challenger2_race_to,
            challenger2_seed,
            challenger2_country,
        } = values;

        let winner;
        let loser;

        if (challenger1Result === 'forfeit' || challenger2Result === 'forfeit') {
            if (challenger1Result === 'forfeit') {
                winner = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: true,
                    challenger2_is_forfeit: false,
                    challenger2_is_withdraw: false,
                };
                loser = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: false,
                    challenger1_is_forfeit: true,
                    challenger1_is_withdraw: false,
                };
            } else if (challenger2Result === 'forfeit') {
                winner = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: true,
                    challenger1_is_forfeit: false,
                    challenger1_is_withdraw: false,
                };
                loser = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: false,
                    challenger2_is_forfeit: true,
                    challenger2_is_withdraw: false,
                };
            }
        }
        if (challenger1Result === 'withdraw' || challenger2Result === 'withdraw') {
            if (challenger1Result === 'withdraw') {
                winner = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: true,
                    challenger2_is_withdraw: false,
                    challenger2_is_forfeit: false,
                };
                loser = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: false,
                    challenger1_is_withdraw: true,
                    challenger1_is_forfeit: false,
                };
            } else if (challenger2Result === 'withdraw') {
                winner = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: true,
                    challenger1_is_withdraw: false,
                    challenger1_is_forfeit: false,
                };
                loser = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: false,
                    challenger2_is_withdraw: true,
                    challenger2_is_forfeit: false,
                };
            }
        } else if (challenger1Result === 'split' || challenger2Result === 'split') {
            winner = {
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: true,
                challenger1_is_forfeit: false,
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: true,
                challenger2_is_forfeit: false,
                is_split: true,
            };

            loser = {};
        } else if (challenger1Result === 'winner' || (challenger1_score && challenger1_score === challenger1_race_to)) {
            winner = {
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: true,
            };
            loser = {
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: false,
            };
        } else if (challenger2Result === 'winner' || (challenger2_score && challenger2_score === challenger2_race_to)) {
            winner = {
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: true,
            };
            loser = {
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: false,
            };
        }

        const results = {
            winner,
            loser,
        };
        return results;
    }

    async function getPlayerRace(challenger) {
        let races;
        if (tournament.rating_system === 'danny_ks') {
            console.log('Danny Ks Handicap chart');
            races = getDKHandicapRace(currentMatch.challenger1, currentMatch.challenger2, currentMatch);
        } else if (tournament.tournament_format === 'usapl_fargo_rated') {
            console.log('USAPL Handicap Chart');
            races = getUSAPLRaces();
        } else if (tournament.tournament_format === 'fargo_rated') {
            console.log('Fargo Handicap chart');
            races = await getFargoRaces(5);
        } else {
            console.log('Default races');
            races =
                challenger && challenger.race_to
                    ? challenger.race_to
                    : currentMatch.identifier.includes('L')
                    ? tournament.losers_race_to
                    : tournament.winners_race_to;
        }
        console.log('races', races);
        return races;
    }

    function advanceToWinnerSide(values) {
        const { currentMatch, match, advanceTournamentBracket } = values;
        let newMatch2;
        if (match) {
            const { challenger, ...newMatch } = match;
            newMatch2 = newMatch;

            // const nextWinnerSideMatch = matches.filter((match) => {
            // 	return match.match_number === nextRecord && nextRecord.winner_to_num;
            // });

            const nextWinnerSideMatch = matches[currentMatch.winner_to_num - 1];
            // console.log(matches);
            // console.log('next winner side match', nextWinnerSideMatch);

            if (currentMatch.winner_to_num) {
                let challenger = match.challenger;
                console.log('MATCH WINNER SIDE', match);
                console.log('CHALLENGER WINNER SIDE', challenger);
                // getPlayerRace(challenger);
                const raceTo =
                    challenger && challenger.race_to
                        ? challenger.race_to
                        : match.identifier.includes('L')
                        ? tournament.losers_race_to
                        : tournament.winners_race_to;
                console.log('RACE TO', raceTo);

                console.log({
                    currentMatch,
                    match,
                    nextWinnerSideMatch,
                });

                const changes = {
                    // loser_from: currentMatch.identifier,
                    ...newMatch2,
                    ...(match.challenger1_name && { challenger1_race_to: raceTo }),
                    ...(match.challenger2_name && { challenger2_race_to: raceTo }),
                    // challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    // challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    ...(nextWinnerSideMatch &&
                        nextWinnerSideMatch.is_bye === true && {
                            status: 'COMPLETED',
                            progress: 100,
                            is_bye: true,
                        }),
                };
                console.log('WINNER CHANGES', changes);

                advanceTournamentBracket({
                    variables: {
                        match_number: currentMatch.winner_to_num,
                        tournament_id: tournament.id,
                        changes: changes,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                            variables: {
                                match_id: nextWinnerSideMatch.id,
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                });

                if (nextWinnerSideMatch && nextWinnerSideMatch.is_bye === true) {
                    console.log('MATCH WINNER SIDE BYE', match);
                    console.log('CHALLENGER WINNER SIDE BYE', challenger);
                    advanceTournamentBracket({
                        variables: {
                            match_number: nextWinnerSideMatch.winner_to_num,
                            tournament_id: tournament.id,
                            changes: {
                                // winner_from: currentMatch.identifier,
                                ...(nextWinnerSideMatch.winner_from_top_num || nextWinnerSideMatch.loser_from_top_num
                                    ? {
                                          challenger1_id: challenger && challenger.id,
                                          challenger1_name: challenger && challenger.name,
                                          challenger1_seed: challenger && challenger.seed,
                                          challenger1_country: challenger && challenger.country,
                                          challenger1_race_to: raceTo,
                                          challenger1_score: challenger.score || 0,
                                      }
                                    : {
                                          challenger2_id: challenger && challenger.id,
                                          challenger2_name: challenger && challenger.name,
                                          challenger2_seed: challenger && challenger.seed,
                                          challenger2_country: challenger && challenger.country,
                                          challenger2_race_to: raceTo,
                                          challenger2_score: challenger.score || 0,
                                      }),
                            },
                        },
                    });
                }
            }
        }
    }

    function advanceToLoserSide(values) {
        const { currentMatch, match, advanceTournamentBracket } = values;
        let newMatch2;
        if (match) {
            const { challenger, ...newMatch } = match;
            newMatch2 = newMatch;

            // const nextLoserSideMatch = matches.filter((match) => {
            // 	return match.match_number === nextRecord && nextRecord.winner_to_num;
            // });

            const nextLoserSideMatch = matches[currentMatch.loser_to_num - 1];

            if (currentMatch.loser_to_num) {
                let challenger = match.challenger;
                console.log('MATCH LOSER SIDE', match);
                console.log('CHALLENGER LOSER SIDE', challenger);

                console.log({
                    currentMatch,
                    match,
                    nextLoserSideMatch,
                });

                const changes = {
                    // loser_from: currentMatch.identifier,
                    ...newMatch2,

                    // ...(tournament.tournament_type === 'custom' && nextLoserSideMatch.loser_from_top_num === currentMatch.match_number
                    //     ? {
                    //           challenger1_id: challenger.id,
                    //           challenger1_name: challenger.name,
                    //           challenger1_seed: challenger.seed,
                    //           challenger1_country: challenger.country,
                    //           challenger1_race_to: challenger.race_to || tournament.losers_race_to,
                    //           challenger1_score: challenger.score || 0,
                    //       }
                    //     : tournament.tournament_type !== 'custom' && nextLoserSideMatch.winner_from_bottom_num === currentMatch.match_number
                    //     ? {
                    //           challenger2_id: challenger.id,
                    //           challenger2_name: challenger.name,
                    //           challenger2_seed: challenger.seed,
                    //           challenger2_country: challenger.country,
                    //           challenger2_race_to: challenger.race_to || tournament.losers_race_to,
                    //           challenger2_score: 0,
                    //       }
                    //     : nextLoserSideMatch.loser_from_bottom_num === currentMatch.match_number
                    //     ? {
                    //           challenger2_id: challenger.id,
                    //           challenger2_name: challenger.name,
                    //           challenger2_seed: challenger.seed,
                    //           challenger2_country: challenger.country,
                    //           challenger2_race_to: challenger.race_to || tournament.losers_race_to,
                    //           challenger2_score: challenger.score || 0,
                    //       }
                    //     : {
                    //           ...newMatch2,
                    //       }),
                    // challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    // challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    ...(nextLoserSideMatch &&
                        nextLoserSideMatch.is_bye === true && {
                            status: 'COMPLETED',
                            progress: 100,
                            is_bye: true,
                        }),
                };
                console.log('LOSER CHANGES', changes);
                advanceTournamentBracket({
                    variables: {
                        match_number: currentMatch.loser_to_num,
                        tournament_id: tournament.id,
                        changes: changes,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                            variables: {
                                match_id: nextLoserSideMatch.id,
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                });

                if (nextLoserSideMatch && nextLoserSideMatch.is_bye === true) {
                    console.log('MATCH LOSER SIDE BYE', match);
                    console.log('CHALLENGER LOSER SIDE BYE', challenger);
                    console.log('advance challenger bye', challenger);
                    console.log({
                        current_match: currentMatch.match_number,
                        winner_from_bottom_num: nextLoserSideMatch.winner_from_bottom_num === currentMatch.match_number,
                        winner_from_top_num: nextLoserSideMatch.winner_from_top_num === currentMatch.match_number,
                        loser_from_bottom_num: nextLoserSideMatch.loser_from_bottom_num === currentMatch.match_number,
                        loser_from_top_num: nextLoserSideMatch.loser_from_top_num === currentMatch.match_number,
                    });
                    advanceTournamentBracket({
                        variables: {
                            match_number: nextLoserSideMatch.winner_to_num,
                            tournament_id: tournament.id,
                            changes: {
                                // loser_from: currentMatch.identifier,
                                ...(nextLoserSideMatch.winner_from_bottom_num
                                    ? {
                                          challenger2_id: challenger.id,
                                          challenger2_name: challenger.name,
                                          challenger2_seed: challenger.seed,
                                          challenger2_country: challenger.country,
                                          challenger2_race_to: challenger.race_to || tournament.losers_race_to,
                                          challenger2_score: challenger.score || 0,
                                      }
                                    : nextLoserSideMatch.loser_from_top_num
                                    ? {
                                          challenger1_id: challenger.id,
                                          challenger1_name: challenger.name,
                                          challenger1_seed: challenger.seed,
                                          challenger1_country: challenger.country,
                                          challenger1_race_to: challenger.race_to || tournament.losers_race_to,
                                          challenger1_score: challenger.score || 0,
                                      }
                                    : {
                                          challenger1_id: challenger.id,
                                          challenger1_name: challenger.name,
                                          challenger1_seed: challenger.seed,
                                          challenger1_country: challenger.country,
                                          challenger1_race_to: challenger.race_to || tournament.losers_race_to,
                                          challenger1_score: challenger.score || 0,
                                      }),
                            },
                        },
                    });
                }
            }
        }
    }

    function advanceToWinnerSide2(values) {
        const { currentMatch, match, advanceTournamentBracket } = values;
        let newMatch2;
        if (match) {
            const { challenger, ...newMatch } = match;
            newMatch2 = newMatch;

            // const nextWinnerSideMatch = matches.filter((match) => {
            // 	return match.match_number === nextRecord && nextRecord.winner_to_num;
            // });

            const nextWinnerSideMatch = matches[currentMatch.winner_to_num - 1];
            // console.log(matches);
            // console.log('next winner side match', nextWinnerSideMatch);

            if (currentMatch.winner_to_num) {
                let challenger = match.challenger;
                console.log('MATCH WINNER SIDE', match);
                console.log('CHALLENGER WINNER SIDE', challenger);
                const raceTo =
                    challenger && challenger.race_to
                        ? challenger.race_to
                        : match.identifier.includes('L')
                        ? tournament.losers_race_to
                        : tournament.winners_race_to;
                console.log('RACE TO', raceTo);

                console.log({
                    currentMatch,
                    match,
                    nextWinnerSideMatch,
                });

                const changes = {
                    // loser_from: currentMatch.identifier,
                    // ...newMatch2,
                    ...(nextWinnerSideMatch.winner_from_top_num === currentMatch.match_number
                        ? {
                              challenger1_id: challenger.id,
                              challenger1_name: challenger.name,
                              challenger1_seed: challenger.seed,
                              challenger1_country: challenger.country,
                              challenger1_race_to: raceTo,
                              challenger1_score: challenger.score || 0,
                          }
                        : nextWinnerSideMatch.winner_from_bottom_num === currentMatch.match_number
                        ? {
                              challenger2_id: challenger.id,
                              challenger2_name: challenger.name,
                              challenger2_seed: challenger.seed,
                              challenger2_country: challenger.country,
                              challenger2_race_to: raceTo,
                              challenger2_score: challenger.score || 0,
                          }
                        : {
                              ...newMatch2,
                          }),
                    // challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    // challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    ...(nextWinnerSideMatch &&
                        nextWinnerSideMatch.is_bye === true && {
                            status: 'COMPLETED',
                            progress: 100,
                            is_bye: true,
                        }),
                };
                console.log('WINNER CHANGES', changes);

                advanceTournamentBracket({
                    variables: {
                        match_number: currentMatch.winner_to_num,
                        tournament_id: tournament.id,
                        changes: changes,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                            variables: {
                                match_id: nextWinnerSideMatch.id,
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                });

                if (nextWinnerSideMatch && nextWinnerSideMatch.is_bye === true) {
                    console.log('MATCH WINNER SIDE BYE', match);
                    console.log('CHALLENGER WINNER SIDE BYE', challenger);
                    advanceTournamentBracket({
                        variables: {
                            match_number: nextWinnerSideMatch.winner_to_num,
                            tournament_id: tournament.id,
                            changes: {
                                // winner_from: currentMatch.identifier,
                                ...(nextWinnerSideMatch.winner_from_top_num === currentMatch.match_number ||
                                nextWinnerSideMatch.loser_from_top_num === currentMatch.match_number
                                    ? {
                                          challenger1_id: challenger && challenger.id,
                                          challenger1_name: challenger && challenger.name,
                                          challenger1_seed: challenger && challenger.seed,
                                          challenger1_country: challenger && challenger.country,
                                          challenger1_race_to: raceTo,
                                          challenger1_score: challenger.score || 0,
                                      }
                                    : {
                                          challenger2_id: challenger && challenger.id,
                                          challenger2_name: challenger && challenger.name,
                                          challenger2_seed: challenger && challenger.seed,
                                          challenger2_country: challenger && challenger.country,
                                          challenger2_race_to: raceTo,
                                          challenger2_score: challenger.score || 0,
                                      }),
                            },
                        },
                    });
                }
            }
        }
    }

    function advanceToLoserSide2(values) {
        const { currentMatch, match, advanceTournamentBracket } = values;
        let newMatch2;
        if (match) {
            const { challenger, ...newMatch } = match;
            newMatch2 = newMatch;

            // const nextLoserSideMatch = matches.filter((match) => {
            // 	return match.match_number === nextRecord && nextRecord.winner_to_num;
            // });

            const nextLoserSideMatch = matches[currentMatch.loser_to_num - 1];

            if (currentMatch.loser_to_num) {
                let challenger = match.challenger;
                console.log('MATCH LOSER SIDE', match);
                console.log('CHALLENGER LOSER SIDE', challenger);

                console.log({
                    currentMatch,
                    match,
                    nextLoserSideMatch,
                });

                const changes = {
                    // loser_from: currentMatch.identifier,
                    // ...newMatch2,
                    ...(nextLoserSideMatch.loser_from_top_num === currentMatch.match_number
                        ? {
                              challenger1_id: challenger.id,
                              challenger1_name: challenger.name,
                              challenger1_seed: challenger.seed,
                              challenger1_country: challenger.country,
                              challenger1_race_to: challenger.race_to || tournament.losers_race_to,
                              challenger1_score: challenger.score || 0,
                          }
                        : nextLoserSideMatch.loser_from_bottom_num === currentMatch.match_number
                        ? {
                              challenger2_id: challenger.id,
                              challenger2_name: challenger.name,
                              challenger2_seed: challenger.seed,
                              challenger2_country: challenger.country,
                              challenger2_race_to: challenger.race_to || tournament.losers_race_to,
                              challenger2_score: challenger.score || 0,
                          }
                        : {
                              ...newMatch2,
                          }),
                    // challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    // challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                    ...(nextLoserSideMatch &&
                        nextLoserSideMatch.is_bye === true && {
                            status: 'COMPLETED',
                            progress: 100,
                            is_bye: true,
                        }),
                };
                console.log('LOSER CHANGES', changes);
                advanceTournamentBracket({
                    variables: {
                        match_number: currentMatch.loser_to_num,
                        tournament_id: tournament.id,
                        changes: changes,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                            variables: {
                                match_id: nextLoserSideMatch.id,
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                });

                if (nextLoserSideMatch && nextLoserSideMatch.is_bye === true) {
                    console.log('MATCH LOSER SIDE BYE', match);
                    console.log('CHALLENGER LOSER SIDE BYE', challenger);
                    console.log('advance challenger bye', challenger);
                    console.log({
                        current_match: currentMatch.match_number,
                        winner_from_bottom_num: nextLoserSideMatch.winner_from_bottom_num === currentMatch.match_number,
                        winner_from_top_num: nextLoserSideMatch.winner_from_top_num === currentMatch.match_number,
                        loser_from_bottom_num: nextLoserSideMatch.loser_from_bottom_num === currentMatch.match_number,
                        loser_from_top_num: nextLoserSideMatch.loser_from_top_num === currentMatch.match_number,
                    });
                    advanceTournamentBracket({
                        variables: {
                            match_number: nextLoserSideMatch.winner_to_num,
                            tournament_id: tournament.id,
                            changes: {
                                // loser_from: currentMatch.identifier,
                                ...(nextLoserSideMatch.loser_from_top_num % 2 === 1
                                    ? {
                                          challenger1_id: challenger.id,
                                          challenger1_name: challenger.name,
                                          challenger1_seed: challenger.seed,
                                          challenger1_country: challenger.country,
                                          challenger1_race_to: challenger.race_to || tournament.losers_race_to,
                                          challenger1_score: challenger.score || 0,
                                      }
                                    : {
                                          challenger2_id: challenger.id,
                                          challenger2_name: challenger.name,
                                          challenger2_seed: challenger.seed,
                                          challenger2_country: challenger.country,
                                          challenger2_race_to: challenger.race_to || tournament.losers_race_to,
                                          challenger2_score: challenger.score || 0,
                                      }),
                            },
                        },
                    });
                }
            }
        }
    }

    function logSMSMessage(options) {
        const { match, player, tournament } = options;
        console.log('LOG SMS MESSAGE', options);

        return logSMSNotification({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        tournament_bracket_id: match.id,
                        type: 'SMS',
                        round_number: match.round,
                        match_number: match.match_number,
                        player_name: player.name,
                        player_id: player.id,
                        player_phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                        message: options.message.text,
                        message_id: options.message.id,
                        message_sent: options.message.sent,
                        message_delivered: options.message.delivered,
                        message_error: options.message.error,
                        message_undeliverable: options.message.undeliverable,
                        message_sent_at: options.message.sent_at,
                        message_delivered_at: options.message.delivered_at,
                    },
                ],
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_MATCH_STATS_QUERY,
            // 		variables: { match_id: matchId }
            // 	}
            // ]
        })
            .then((values) => {
                console.log(values);
                return values;
            })
            .catch((e) => {
                const err = (e && e.message) || JSON.stringify(e);
                message.error(err);
                console.log(err);
                return err;
            });
    }

    async function notifyPlayer(player1, player2, table, currentMatch) {
        if (tournament.use_text_messaging !== false) {
            let player1NameSplit;
            let player2NameSplit;
            let player1FirstName;
            let player2FirstName;
            let text;
            let scoreText;
            let player1Race;
            let player2Race;

            console.log({ player1, player2, table, currentMatch });
            const tableName =
                table && table.is_streaming_table === true
                    ? `${table.label} (Live Stream Table)`
                    : table && table.is_featured_table === true
                    ? `${table.label} (Featured Table)`
                    : table.label;

            if (player1) {
                player1NameSplit = player1.name && player1.name.split(' ');
                player1FirstName = player1NameSplit && player1NameSplit.length > 0 ? player1NameSplit[0] : player1.name;
            }

            if (player2) {
                player2NameSplit = player2.name && player2.name.split(' ');
                player2FirstName = player2NameSplit && player2NameSplit.length > 0 ? player2NameSplit[0] : player2.name;
            }

            const liveScoringLink = `Live score your match #${currentMatch.match_number} : https://digitalpool.com/m/${currentMatch.hash_id}`;

            // If match has both players
            if (player1 && player2) {
                if (currentMatch.identifier.includes('L')) {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.losers_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.losers_race_to;
                } else {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.winners_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.winners_race_to;
                }

                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} against ${player2 && player2.name} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} against ${
                        player2 && player2.name
                    } has been assigned to ${tableName}. ${player1FirstName} goes to ${player1Race} and ${player2FirstName} goes to ${player2Race}.`;
                    scoreText = liveScoringLink;
                }
            }
            // If match only has player 1
            else if (player1 && !player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player1 && player1.race_to ? `${player1FirstName} goes to ${player1.race_to}.` : ''
                    }`;
                    scoreText = player1 && player1.race_to && liveScoringLink;
                }
            }
            // if match only has player 2
            else if (!player1 && player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player2 && player2.race_to ? `${player2FirstName} goes to ${player2.race_to}.` : ''
                    }`;
                    scoreText = player2 && player2.race_to && liveScoringLink;
                }
            } else {
                throw new Error('Text message could not be sent. No players found!');
            }

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            const sendSMS = await httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${text}`;
            // const messageToSend = `DigitalPool.com\n${text}\n${moment().format('MM/DD/YYYY h:mm a')}`;
            if (player1) {
                try {
                    sendSMS({
                        phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                        message: messageToSend,
                        user_id: player1.id,
                        tournament_id: tournament.id,
                        // token: ''
                    })
                        .then(async (values) => {
                            console.log(values);

                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: values && values.data && values.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: null,
                                    sent_at: moment.utc(),
                                    delivered: null,
                                    delivered_at: null,
                                    undeliverable: null,
                                },
                            });
                            message.success(`SMS Notification sent to ${player1.name}`);
                            setLoading(false);
                            return values;
                        })
                        .catch(async (e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log(err);
                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: err && err.data && err.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: err,
                                    sent_at: moment.utc(),
                                    delivered: false,
                                    delivered_at: null,
                                    undeliverable: true,
                                },
                            });
                            setLoading(false);
                            message.error(`SMS Notification error: ${err}`);
                            return err;
                        });

                    if (scoreText) {
                        const scoringLinkToSend = `DigitalPool.com\n${scoreText}`;
                        // const scoringLinkToSend = `DigitalPool.com\n${scoreText}\n${moment().format('MM/DD/YYYY h:mm a')}`;
                        sendSMS({
                            phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                            message: scoringLinkToSend,
                            user_id: player1.id,
                            tournament_id: tournament.id,
                            // token: ''
                        })
                            .then(async (values) => {
                                console.log(values);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: values && values.data && values.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: null,
                                        sent_at: moment.utc(),
                                        delivered: null,
                                        delivered_at: null,
                                        undeliverable: null,
                                    },
                                });

                                return values;
                            })
                            .catch(async (e) => {
                                const err = (e && e.message) || JSON.stringify(e);
                                console.log(err);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: err && err.data && err.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: err,
                                        sent_at: moment.utc(),
                                        delivered: false,
                                        delivered_at: null,
                                        undeliverable: true,
                                    },
                                });

                                return err;
                            });
                    }
                } catch (e) {
                    const err = (e && e.message) || JSON.stringify(e);
                    console.log(err);
                    await logSMSMessage({
                        tournament,
                        match: currentMatch,
                        player: player1,
                        message: {
                            id: err && err.data && err.data['message-id'],
                            text: messageToSend,
                            sent: true,
                            error: err,
                            sent_at: moment.utc(),
                            delivered: null,
                            delivered_at: null,
                            undeliverable: true,
                        },
                    });
                    setLoading(false);
                    message.error(`SMS Notification error: ${err}`);
                }
            }
        } else {
            console.log('SMS notifications are currently disabled');
        }
    }

    function playerEliminated(player) {
        const playerId = player.challenger1_id || player.challenger2_id;

        if (playerId) {
            console.log('player eliminated', player);
            console.log('place', currentMatch.place);
            updateTournamentPlayer({
                variables: {
                    id: playerId,
                    tournament_id: tournament.id,
                    changes: {
                        place: currentMatch.is_finals === true ? '2' : currentMatch.is_consolidation_finals === true ? '2' : currentMatch.place,
                    },
                    // changes: {
                    // 	place:
                    // 		currentMatch.is_finals === true
                    // 			? '2'
                    // 			: currentMatch.is_consolidation_finals ? '2' : currentMatch.place
                    // }
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_QUERY,
                // 		variables: { id: tournament.id }
                // 	}
                // ]
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function completeTournament(winner) {
        const playerId = winner.challenger1_id || winner.challenger2_id;

        setTournamentComplete(true);
        if (winner.is_split === true) {
            updateTournamentPlayer({
                variables: {
                    id: winner.challenger1_id,
                    tournament_id: tournament.id,
                    changes: {
                        place: '1',
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            updateTournamentPlayer({
                variables: {
                    id: winner.challenger2_id,
                    tournament_id: tournament.id,
                    changes: {
                        place: '1',
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            updateTournamentPlayer({
                variables: {
                    id: playerId,
                    tournament_id: tournament.id,
                    changes: {
                        place: '1',
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }

        updateTournament({
            variables: {
                id: tournament.id,
                changes: {
                    end_date_time: moment.utc(),
                    progress: '100',
                    status: 'COMPLETED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then((data) => {
                onTournamentComplete(winner);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    function isPlayerInNextMatch(player, match) {
        if (
            (player && match && match.challenger1_name && match.challenger1_name === player.challenger1_name) ||
            (player && match && match.challenger2_name && match.challenger2_name === player.challenger2_name)
        ) {
            return true;
        } else {
            return false;
        }
    }

    function locateNextBracketPosition(match, player, side, fromMatchNumber, identifier) {
        let advanceTo;
        console.log('FROM MATCH', fromMatchNumber);
        console.log('PLAYER CONTENT', player);
        console.log('SIDE', side);
        console.log('MATCH CONTENT', match);
        console.log('CURRENT IDENTIFIER', identifier);
        // if (match.challenger1_name || match.challenger2_name) {
        // 	if (isPlayerInNextMatch(player, match) === true) {
        // 		console.log('player is already in match', match.match_number);
        // 		if (match.challenger1_name === player.challenger1_name) {
        // 			console.log(side + ' advances to top match', match.match_number);
        // 			advanceTo = 'top';
        // 		} else {
        // 			console.log(side + ' advances to bottom match', match.match_number);
        // 			advanceTo = 'bottom';
        // 		}
        // 	}
        // 	else {
        // 		if (match.challenger1_name) {
        // 			console.log(side + ' advances to bottom match', match.match_number);
        // 			advanceTo = 'bottom';
        // 		} else {
        // 			console.log(side + ' advances to topmatch', match.match_number);
        // 			advanceTo = 'top';
        // 		}
        // 	}
        // } else {
        if (side === 'winner') {
            if (match.identifier.includes('L')) {
                var hashMarkerForIdentifierIndex = match.identifier.indexOf('-');
                if (match.identifier.substring(1, hashMarkerForIdentifierIndex) % 2 === 0) {
                    // alert('code block 1');
                    console.log('match identifier: ', match.identifier);
                    console.log(side + ' advances to top match', match.match_number);
                    advanceTo = 'top';
                } else {
                    if (fromMatchNumber === match.winner_from_top_num) {
                        // alert('code block 2');
                        console.log(side + ' advances to top match', match.match_number);
                        advanceTo = 'top';
                    } else if (fromMatchNumber === match.winner_from_bottom_num) {
                        // alert('code block 3');
                        console.log(side + ' advances to bottom match', match.match_number);
                        advanceTo = 'bottom';
                    } else {
                        // alert('code block 4');
                        console.log(side + ' advances to top match', match.match_number);
                        advanceTo = 'top';
                    }
                }
            } else if (fromMatchNumber === match.winner_from_top_num) {
                // alert('code block 5');
                console.log(side + ' advances to top match', match.match_number);
                advanceTo = 'top';
            } else if (fromMatchNumber === match.winner_from_bottom_num) {
                // alert('code block 6');
                console.log(side + ' advances to bottom match', match.match_number);
                advanceTo = 'bottom';
            } else {
                // alert('code block 7');
                console.log(side + ' advances to top match', match.match_number);
                advanceTo = 'top';
            }
        } else {
            if (match.loser_from && match.loser_from.includes('W1')) {
                if (match.loser_from_top_num === fromMatchNumber) {
                    // alert('code block 8');
                    console.log(side + ' advances to top match', match.match_number);
                    advanceTo = 'top';
                } else if (match.loser_from_bottom_num === fromMatchNumber) {
                    // alert('code block 9');
                    console.log(side + ' advances to bottom match', match.match_number);
                    advanceTo = 'bottom';
                }
            } else {
                // alert('code block 10');
                console.log(side + ' advances to bottom match', match.match_number);
                advanceTo = 'bottom';
            }
        }

        return advanceTo;
    }

    function advanceMatches(data) {
        const { winner, loser, advanceTournamentBracket } = data;
        console.log(data);
        console.log(matches);
        console.log({ winner, loser });
        // const currentMatchNum = currentMatch.id % 2 === 0 ? 'even' : 'odd';
        // const currentPosition = winner.challenger1.id ? 'top' : 'bottom';
        // console.log('current match Num:', currentMatchNum);
        // console.log('current position:', currentPosition);

        // const nextWinnerSideMatch = matches.filter((match) => {
        // 	return match.match_number === currentMatch.winner_to_num;
        // });

        const nextWinnerSideMatch = [matches[currentMatch.winner_to_num - 1]];
        const nextWinnerMatchData = nextWinnerSideMatch.map((item) => {
            if (item) {
                // const nextMatchNum = item.id % 2 === 0 ? 'even' : 'odd';
                // const nextPosition = item.challenger1_id ? 'bottom' : 'top';

                const position = locateNextBracketPosition(item, winner, 'winner', currentMatch.match_number, currentMatch.identifier);
                console.log('new position', position);

                if (item && item.is_consolidation_finals === true) {
                    let challenger = winner.challenger1 || winner.challenger2;
                    return {
                        challenger,
                        identifier: item.identifier,
                        challenger1_id: currentMatch.challenger1 && currentMatch.challenger1.id,
                        challenger1_name: currentMatch.challenger1 && currentMatch.challenger1.name,
                        challenger1_seed: currentMatch.challenger1 && currentMatch.challenger1.seed,
                        challenger1_country: currentMatch.challenger1 && currentMatch.challenger1.country,
                        challenger1_race_to:
                            (currentMatch.challenger1 && currentMatch.challenger1.race_to) || tournament.finals_race_to || tournament.winners_race_to,
                        challenger1_score: 0,
                        challenger2_id: currentMatch.challenger2 && currentMatch.challenger2.id,
                        challenger2_name: currentMatch.challenger2 && currentMatch.challenger2.name,
                        challenger2_seed: currentMatch.challenger2 && currentMatch.challenger2.seed,
                        challenger2_country: currentMatch.challenger2 && currentMatch.challenger2.country,
                        challenger2_race_to:
                            (currentMatch.challenger2 && currentMatch.challenger2.race_to) || tournament.finals_race_to || tournament.winners_race_to,
                        challenger2_score: 0,
                    };
                } else {
                    let challenger = winner.challenger1 || winner.challenger2;
                    if (position === 'top') {
                        return {
                            challenger,
                            identifier: item.identifier,
                            challenger1_id: challenger && challenger.id,
                            challenger1_name: challenger && challenger.name,
                            challenger1_seed: challenger && challenger.seed,
                            challenger1_country: challenger && challenger.country,
                            challenger1_race_to:
                                item && item.is_finals === true
                                    ? (challenger && challenger.race_to) || tournament.finals_race_to || tournament.winners_race_to
                                    : (challenger && challenger.race_to) || currentMatch.identifier.includes('L')
                                    ? tournament.losers_race_to
                                    : tournament.winners_race_to,
                            challenger1_score: 0,
                        };
                    } else if (position === 'bottom') {
                        return {
                            challenger,
                            identifier: item.identifier,
                            challenger2_id: challenger && challenger.id,
                            challenger2_name: challenger && challenger.name,
                            challenger2_seed: challenger && challenger.seed,
                            challenger2_country: challenger && challenger.country,
                            challenger2_race_to:
                                item && item.is_finals === true
                                    ? (challenger && challenger.race_to) || tournament.finals_race_to || tournament.winners_race_to
                                    : (challenger && challenger.race_to) || currentMatch.identifier.includes('L')
                                    ? tournament.losers_race_to
                                    : tournament.winners_race_to,
                            challenger2_score: 0,
                        };
                    } else {
                        console.error('not sure where to put them');
                    }
                }
            }
        })[0];

        // const nextLoserSideMatch = matches.filter((match) => {
        // 	return match.match_number === currentMatch.loser_to_num;
        // });

        const nextLoserSideMatch = [matches[currentMatch.loser_to_num - 1]];
        const nextLoserMatchData = nextLoserSideMatch.map((item) => {
            if (item) {
                const position = locateNextBracketPosition(item, loser, 'loser', currentMatch.match_number, currentMatch.identifier);
                console.log('new position', position);

                if (item && item.is_consolidation_finals === true) {
                    let challenger = loser.challenger1 || loser.challenger2;
                    return {
                        challenger,
                        identifier: item.identifier,
                        challenger1_id: currentMatch.challenger1 && currentMatch.challenger1.id,
                        challenger1_name: currentMatch.challenger1 && currentMatch.challenger1.name,
                        challenger1_seed: currentMatch.challenger1 && currentMatch.challenger1.seed,
                        challenger1_country: currentMatch.challenger1 && currentMatch.challenger1.country,
                        challenger1_race_to:
                            (currentMatch.challenger1 && currentMatch.challenger1.race_to) || tournament.finals_race_to || tournament.winners_race_to,
                        challenger1_score: 0,
                        challenger2_id: currentMatch.challenger2 && currentMatch.challenger2.id,
                        challenger2_name: currentMatch.challenger2 && currentMatch.challenger2.name,
                        challenger2_seed: currentMatch.challenger2 && currentMatch.challenger2.seed,
                        challenger2_country: currentMatch.challenger2 && currentMatch.challenger2.country,
                        challenger2_race_to:
                            (currentMatch.challenger2 && currentMatch.challenger2.race_to) || tournament.finals_race_to || tournament.winners_race_to,
                        challenger2_score: 0,
                    };
                } else {
                    let challenger = loser.challenger1 || loser.challenger2;
                    if (position === 'top') {
                        if (currentMatch.challenger1_is_withdraw === true || currentMatch.challenger2_is_withdraw === true) {
                            return {
                                identifier: item.identifier,
                                challenger1_id: null,
                                challenger1_name: 'Walk Over',
                                challenger1_seed: null,
                                challenger1_country: null,
                                challenger1_race_to: null,
                                challenger1_score: null,
                            };
                        } else {
                            return {
                                challenger,
                                identifier: item.identifier,
                                challenger1_id: challenger && challenger.id,
                                challenger1_name: challenger && challenger.name,
                                challenger1_seed: challenger && challenger.seed,
                                challenger1_country: challenger && challenger.country,
                                challenger1_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                challenger1_score: 0,
                            };
                        }
                    } else if (position === 'bottom') {
                        if (currentMatch.challenger1_is_withdraw === true || currentMatch.challenger2_is_withdraw === true) {
                            return {
                                identifier: item.identifier,
                                challenger2_id: null,
                                challenger2_name: 'Walk Over',
                                challenger2_seed: null,
                                challenger2_country: null,
                                challenger2_race_to: null,
                                challenger2_score: null,
                            };
                        } else {
                            return {
                                challenger,
                                identifier: item.identifier,
                                challenger2_id: challenger && challenger.id,
                                challenger2_name: challenger && challenger.name,
                                challenger2_seed: challenger && challenger.seed,
                                challenger2_country: challenger && challenger.country,
                                challenger2_race_to: (challenger && challenger.race_to) || tournament.losers_race_to,
                                challenger2_score: 0,
                            };
                        }
                    } else {
                        console.error('not sure where to put them');
                    }
                }
            }
        })[0];

        console.log({
            winner,
            loser,
        });
        console.log('next winner side match', nextWinnerSideMatch);
        console.log('next loser side match', nextLoserSideMatch);
        console.log('current match', currentMatch);

        if (nextWinnerSideMatch && nextWinnerSideMatch[0] && nextWinnerSideMatch[0].advance_to) {
            advanceToNextStage(nextWinnerSideMatch[0], winner, advanceTournamentBracket);
        }

        // const hotSeatMatch = matches.filter((match) => {
        // 	return match.is_semi_finals === true;
        // })[0];

        if (
            currentMatch &&
            currentMatch.is_finals === true &&
            (currentMatch.challenger1_id === winner.challenger1_id || currentMatch.challenger2_id === winner.challenger2_id)
        ) {
            // alert('is finals');

            if (tournament.tournament_type !== 'modified_double_elimination') {
                setWinner(winner);
                playerEliminated(loser);
            }

            if (winner.is_split === true) {
                completeTournament({
                    ...winner,
                    is_split: true,
                });
            } else {
                if (tournament.tournament_type === 'modified_double_elimination') {
                    updateTournamentPlayer({
                        variables: {
                            id: winner.challenger1_id || winner.challenger2_id,
                            tournament_id: tournament.id,
                            changes: {
                                place: '1',
                            },
                        },
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                            message.info('There was an error', error);
                        });

                    if (tournament.tournament_type === 'custom') {
                        advanceToLoserSide2({
                            advanceTournamentBracket,
                            currentMatch,
                            match: nextLoserMatchData,
                            // nextRecord: nextLoserSideMatch[0]
                        });
                    } else {
                        advanceToLoserSide({
                            advanceTournamentBracket,
                            currentMatch,
                            match: nextLoserMatchData,
                            // nextRecord: nextLoserSideMatch[0]
                        });
                    }
                } else {
                    setConfirmEndTournamentPopupVisible(true);
                    if (tournamentComplete === false) {
                        if (tournament.tournament_type === 'custom') {
                            advanceToWinnerSide2({
                                advanceTournamentBracket,
                                currentMatch,
                                match: nextWinnerMatchData,
                                // nextRecord: nextWinnerSideMatch[0]
                            });

                            advanceToLoserSide2({
                                advanceTournamentBracket,
                                currentMatch,
                                match: nextLoserMatchData,
                                // nextRecord: nextLoserSideMatch[0]
                            });
                        } else {
                            advanceToWinnerSide({
                                advanceTournamentBracket,
                                currentMatch,
                                match: nextWinnerMatchData,
                                // nextRecord: nextWinnerSideMatch[0]
                            });

                            advanceToLoserSide({
                                advanceTournamentBracket,
                                currentMatch,
                                match: nextLoserMatchData,
                                // nextRecord: nextLoserSideMatch[0]
                            });
                        }
                    }
                }
            }
        } else if (currentMatch && currentMatch.is_consolidation_finals === true) {
            // 	alert('is consolidation finals');
            setWinner(winner);
            playerEliminated(loser);
            if (winner.is_split === true) {
                completeTournament({
                    ...winner,
                    is_split: true,
                });
            } else {
                setConfirmEndTournamentPopupVisible(true);
            }
        } else {
            // alert('not finals or consolidation finals');
            if (!nextWinnerMatchData && !nextLoserMatchData) {
                if (tournament.tournament_type === 'modified_double_elimination') {
                    updateTournamentPlayer({
                        variables: {
                            id: winner.challenger1_id || winner.challenger2_id,
                            tournament_id: tournament.id,
                            changes: {
                                place: '2',
                            },
                        },
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                            message.info('There was an error', error);
                        });

                    const finalsMatch = matches.filter((match) => {
                        return match.is_finals === true;
                    })[0];

                    const finalsWinner = getWinnerLoser(finalsMatch);
                    const winner2 = finalsWinner.winner;
                    setWinner(winner2);
                } else {
                    setWinner(winner);
                }

                if (winner.is_split === true) {
                    completeTournament({
                        ...winner,
                        is_split: true,
                    });
                } else {
                    if (currentMatch && currentMatch.is_finals === true) {
                        setConfirmEndTournamentPopupVisible(true);
                    }
                }
            }

            console.log(nextLoserMatchData);
            console.log(loser);

            if (!nextLoserMatchData) {
                console.log('player eliminated');
                playerEliminated(loser);
            }

            if (tournament.tournament_type === 'custom') {
                advanceToWinnerSide2({
                    advanceTournamentBracket,
                    currentMatch,
                    match: nextWinnerMatchData,
                    // nextRecord: nextWinnerSideMatch[0]
                });

                advanceToLoserSide2({
                    advanceTournamentBracket,
                    currentMatch,
                    match: nextLoserMatchData,
                    // nextRecord: nextLoserSideMatch[0]
                });
            } else {
                advanceToWinnerSide({
                    advanceTournamentBracket,
                    currentMatch,
                    match: nextWinnerMatchData,
                    // nextRecord: nextWinnerSideMatch[0]
                });

                advanceToLoserSide({
                    advanceTournamentBracket,
                    currentMatch,
                    match: nextLoserMatchData,
                    // nextRecord: nextLoserSideMatch[0]
                });
            }
        }

        return {
            nextWinnerSideMatch: nextWinnerSideMatch[0],
            nextWinnerMatchData,
            nextLoserSideMatch: nextLoserSideMatch[0],
            nextLoserMatchData,
        };
    }

    async function updateTournamentProgress(value) {
        if (value) {
            await API.updateTournamentProgress(value);
            return value;
        } else {
            const inProgressMatches = matches.filter((match) => {
                return match.progress !== null;
            });

            const byeMatches = matches.filter((match) => {
                return match.is_bye === true;
            });

            const completedMatches = matches
                .filter((match) => {
                    return match.status === 'COMPLETED';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                });

            const progress = inProgressMatches
                .map((match) => {
                    return match.progress;
                })
                .reduce((acc, progress) => {
                    return acc + progress;
                }, 0);

            // console.log(matches.length);
            // console.log(inProgressMatches.length);
            const currentMatchesProgress = progress / inProgressMatches.length;
            const newProgress = (completedMatches.length / (matches.length - byeMatches.length)) * 100;
            console.log(currentMatchesProgress);
            console.log(newProgress);
            await API.updateTournamentProgress(newProgress);
            return newProgress;
        }
    }

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1'
                ? currentMatch.challenger1_race_to
                    ? currentMatch.challenger1_race_to
                    : currentMatch.challenger1 && currentMatch.challenger1.race_to
                : challenger === 'challenger2'
                ? currentMatch.challenger2_race_to
                    ? currentMatch.challenger2_race_to
                    : currentMatch.challenger2 && currentMatch.challenger2.race_to
                : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true
                    ? tournament.finals_race_to || tournament.winners_race_to
                    : tournament.winners_race_to;
            } else {
                return tournament.losers_race_to;
            }
        }
    }

    function convertToMatchFromBye(current, player1, player2, updateTournamentBracket) {
        const nextWinnerSideMatch = [matches[currentMatch.winner_to_num - 1]];
        const nextLoserSideMatch = [matches[currentMatch.loser_to_num - 1]];

        // check to see if the match before this one in the same round is a bye
        const prevMatchInRound = [matches[currentMatch.match_number - 1 - 1]];
        let prevMatchToSameLoserSideMatch;
        if (prevMatchInRound && prevMatchInRound[0]) {
            prevMatchToSameLoserSideMatch = prevMatchInRound[0].loser_to_num === current.loser_to_num && prevMatchInRound[0].is_bye === true;
        }
        // check to see if the match after this one in the same round is a bye
        const nextMatchInRound = [matches[currentMatch.match_number + 1 - 1]];
        let nextMatchToSameLoserSideMatch;
        if (nextMatchInRound && nextMatchInRound[0]) {
            nextMatchToSameLoserSideMatch = nextMatchInRound[0].loser_to_num === current.loser_to_num && nextMatchInRound[0].is_bye === true;
        }

        console.log({
            current,
            player1,
            player2,
            nextWinnerSideMatch,
            nextLoserSideMatch,
            id: currentMatch.id,
            tournament_id: tournament.id,
        });

        if (nextLoserSideMatch && nextLoserSideMatch[0]) {
            updateTournamentBracket({
                variables: {
                    id: nextLoserSideMatch[0].id,
                    tournament_id: tournament.id,
                    changes: {
                        challenger1_name: null,
                        challenger1_score: null,
                        challenger2_name: null,
                        challenger2_score: null,
                        is_bye: false,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            const nextLoserSideByeMatch = matches[nextLoserSideMatch[0].winner_to_num - 1];

            if (nextLoserSideByeMatch && nextLoserSideByeMatch.is_bye === true) {
                updateTournamentBracket({
                    variables: {
                        id: nextLoserSideByeMatch.id,
                        tournament_id: tournament.id,
                        changes: {
                            challenger1_id: null,
                            challenger1_name: null,
                            challenger1_seed: null,
                            challenger1_country: null,
                            challenger1_race_to: null,
                            challenger1_score: null,
                            is_bye: false,
                        },
                    },
                });
            }
        }
    }

    function convertToByeFromMatch(current, player1, player2, updateTournamentBracket) {
        const nextWinnerSideMatch = [matches[currentMatch.winner_to_num - 1]];
        const nextLoserSideMatch = [matches[currentMatch.loser_to_num - 1]];

        // check to see if the match before this one in the same round is a bye
        const prevMatchInRound = [matches[currentMatch.match_number - 1 - 1]];
        let prevMatchToSameLoserSideMatch;
        if (prevMatchInRound && prevMatchInRound[0]) {
            prevMatchToSameLoserSideMatch = prevMatchInRound[0].loser_to_num === current.loser_to_num && prevMatchInRound[0].is_bye === true;
        }
        // check to see if the match after this one in the same round is a bye
        const nextMatchInRound = [matches[currentMatch.match_number + 1 - 1]];
        let nextMatchToSameLoserSideMatch;
        if (nextMatchInRound && nextMatchInRound[0]) {
            nextMatchToSameLoserSideMatch = nextMatchInRound[0].loser_to_num === current.loser_to_num && nextMatchInRound[0].is_bye === true;
        }

        console.log({
            current,
            player1,
            player2,
            nextWinnerSideMatch,
            nextLoserSideMatch,
            id: currentMatch.id,
            tournament_id: tournament.id,
        });

        if (nextLoserSideMatch && nextLoserSideMatch[0]) {
            updateTournamentBracket({
                variables: {
                    id: nextLoserSideMatch[0].id,
                    tournament_id: tournament.id,
                    changes: {
                        challenger1_name: 'BYE',
                        challenger1_score: null,
                        challenger2_name: null,
                        // challenger2_name: prevMatchToSameLoserSideMatch === true || nextMatchToSameLoserSideMatch === true ? 'BYE' : null,
                        challenger2_score: null,
                        is_bye: true,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            const nextLoserSideByeMatch = matches[nextLoserSideMatch[0].winner_to_num - 1];

            if (nextLoserSideByeMatch && nextLoserSideByeMatch.is_bye === true) {
                updateTournamentBracket({
                    variables: {
                        id: nextLoserSideByeMatch.id,
                        tournament_id: tournament.id,
                        changes: {
                            challenger1_id: null,
                            challenger1_name: 'BYE',
                            challenger1_seed: null,
                            challenger1_country: null,
                            challenger1_race_to: null,
                            challenger1_score: null,
                            is_bye: true,
                        },
                    },
                });
            }
        }
    }

    function advanceToNextStage(nextWinnerSideMatch, winner, advanceTournamentBracket) {
        const stages = tournament.stages;
        const advanceTo = nextWinnerSideMatch.advance_to.substr(3, nextWinnerSideMatch.advance_to.length - 1);
        const key = 'nextStage';

        console.log({
            stages,
            advanceTo,
            originalMatches,
        });
        const advanceToMatch = originalMatches.filter((match) => {
            return match.identifier === advanceTo && match.stage_number === 2;
        })[0];
        const nextStage = stages && stages[stages.length - 1];
        console.log('advance to match', advanceToMatch);

        if (advanceToMatch && stage.players_advance_per_group > 2) {
            console.log(winner);
            advanceTournamentStage({
                variables: {
                    tournament_id: tournament.id,
                    stage_id: nextStage.id,
                    match_id: advanceToMatch.id,
                    changes:
                        currentMatch.match_number % 2
                            ? {
                                  challenger1_id: winner.challenger1_id || winner.challenger2_id,
                                  challenger1_name: winner.challenger1_name || winner.challenger2_name,
                                  challenger1_country: winner.challenger1_country || winner.challenger2_country,
                                  challenger1_seed: winner.challenger1_seed || winner.challenger2_seed,
                              }
                            : {
                                  challenger2_id: winner.challenger1_id || winner.challenger2_id,
                                  challenger2_name: winner.challenger1_name || winner.challenger2_name,
                                  challenger2_country: winner.challenger1_country || winner.challenger2_country,
                                  challenger2_seed: winner.challenger1_seed || winner.challenger2_seed,
                              },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                        variables: {
                            match_id: advanceToMatch.id,
                            tournament_id: tournament.id,
                        },
                    },
                ],
            })
                .then((data) => {
                    message.success({
                        content: `${winner.challenger1_name || winner.challenger2_name} advances to ${stages && stages[stages.length - 1].name}.`,
                        key,
                        duration: 3,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
                });
        }
        // else {
        //     message.error({ content: `Unable to advance match to ${nextStage.name}`, key, duration: 2 });
        // }
    }

    function handleSubmit(values, updateTournamentBracket, advanceTournamentBracket) {
        console.log(values);
        let challenger1_seed;
        let challenger1_country;
        let challenger1_sms_sent;
        let challenger2_seed;
        let challenger2_country;
        let challenger2_sms_sent;
        let challenger1 = currentMatch.challenger1;
        let challenger2 = currentMatch.challenger2;
        let player1;
        let player2;
        // let start_time;
        let end_time;
        let matchStatus;
        let tableStatus;
        let winner;
        let loser;
        let results;
        let is_bye;
        let is_split;
        let {
            challenger1_id,
            challenger1_name,
            challenger1_score,
            challenger1_race_to,
            challenger2_id,
            challenger2_name,
            challenger2_score,
            challenger2_race_to,
            table_id,
            scheduled_table_id,
            priority,
            start_time,
            // end_time
            // scheduled_time
        } = values;
        console.log('MATCH DATA', values);

        setLoading(true);

        // returns true if the variable does NOT contain a valid number
        if (isNaN(challenger1_name) !== true) {
            const id = parseInt(challenger1_name);
            player1 = players.filter((item) => {
                return item.id === id;
            })[0];
        } else {
            player1 = players.filter((item) => {
                return item.name === challenger1_name;
            })[0];
        }

        if (isNaN(challenger2_name) !== true) {
            const id = parseInt(challenger2_name);
            player2 = players.filter((item) => {
                return item.id === id;
            })[0];
        } else {
            player2 = players.filter((item) => {
                return item.name === challenger2_name;
            })[0];
        }

        console.log('players', {
            player1,
            player2,
        });

        challenger1_name = player1 && player1.name ? player1.name : challenger1_name;
        challenger1_id = player1 && player1.id ? player1.id : challenger1_id;
        challenger1_seed = player1 && player1.seed ? player1.seed : challenger1_seed;
        challenger1_country = player1 && player1.country ? player1.country : challenger1_country;
        challenger1_sms_sent = player1 && player1.sms_sent ? player1.sms_sent : challenger1_sms_sent;
        challenger2_name = player2 && player2.name ? player2.name : challenger2_name;
        challenger2_id = player2 && player2.id ? player2.id : challenger2_id;
        challenger2_seed = player2 && player2.seed ? player2.seed : challenger2_seed;
        challenger2_country = player2 && player2.country ? player2.country : challenger2_country;
        challenger2_sms_sent = player2 && player2.sms_sent ? player2.sms_sent : challenger2_sms_sent;

        if (challenger1_name && challenger1_name !== 'BYE' && challenger2_name && challenger2_name !== 'BYE') {
            is_bye = false;
        } else if (challenger1_name === 'BYE' || challenger2_name === 'BYE') {
            is_bye = true;
        }

        if (challenger1Result === 'split' || challenger2Result === 'split') {
            is_split = true;
        } else {
            is_split = false;
        }

        if (challenger1Result === 'forfeit' || challenger2Result === 'forfeit' || challenger1Result === 'withdraw' || challenger2Result === 'withdraw') {
            matchStatus = 'COMPLETED';
            tableStatus = 'OPEN';
            end_time = moment.utc();
            results = getWinnerLoser({
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_country,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_country,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
            });
            winner = results.winner;
            loser = results.loser;
        } else if (challenger1Result === 'split' || challenger2Result === 'split') {
            matchStatus = 'COMPLETED';
            tableStatus = 'OPEN';
            end_time = moment.utc();
            results = getWinnerLoser({
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_country,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_country,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
            });
            winner = results.winner;
            loser = results.loser;
        } else if (table_id) {
            if (
                challenger1Result === 'winner' ||
                challenger2Result === 'winner' ||
                challenger1_score === challenger1_race_to ||
                challenger2_score === challenger2_race_to
            ) {
                matchStatus = 'COMPLETED';
                tableStatus = 'OPEN';
                end_time = moment.utc();
                results = getWinnerLoser({
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_country,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_country,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                });
                winner = results.winner;
                loser = results.loser;
            } else if (
                (challenger1_score && challenger1_score > 0 && challenger1_score !== challenger1_race_to) ||
                (challenger2_score && challenger2_score > 0 && challenger2_score !== challenger2_race_to)
            ) {
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else if (
                challenger1_name &&
                challenger2_name &&
                (challenger1_score === null || challenger1_score === 0) &&
                (challenger2_score === null || challenger1_score === 0)
            ) {
                // tableStatus = 'ASSIGNED';
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else {
                tableStatus = 'IN_USE';
                if (!start_time) {
                    start_time = moment.utc();
                }
            }
        } else {
            if (challenger1Result === 'winner' || challenger2Result === 'winner' || challenger1_score || challenger2_score) {
                if (
                    challenger1Result === 'winner' ||
                    challenger2Result === 'winner' ||
                    challenger1_score === challenger1_race_to ||
                    challenger2_score === challenger2_race_to
                ) {
                    matchStatus = 'COMPLETED';
                    tableStatus = 'OPEN';
                    end_time = moment.utc();
                    results = getWinnerLoser({
                        challenger1,
                        challenger1_id,
                        challenger1_name,
                        challenger1_country,
                        challenger1_score,
                        challenger1_race_to,
                        challenger1_seed,
                        challenger2,
                        challenger2_id,
                        challenger2_name,
                        challenger2_country,
                        challenger2_score,
                        challenger2_race_to,
                        challenger2_seed,
                    });
                    winner = results.winner;
                    loser = results.loser;
                } else {
                    tableStatus = 'IN_USE';
                    matchStatus = 'IN_PROGRESS';
                    if (!start_time) {
                        start_time = moment.utc();
                    }
                }
            } else {
                matchStatus = 'NOT_STARTED';
                tableStatus = 'OPEN';
            }
        }

        // console.log('table status:', tableStatus);
        // console.log('match status:', matchStatus);
        console.log('winner:', winner);
        console.log('loser:', loser);

        if (!challenger1_name && !challenger2_name && is_split === true) {
            message.error('Unable to split without 2 players!');
            setLoading(false);
            handleScoreModalCancel();
            return false;
        }

        // console.log(table_id);
        // console.log(tables);
        // console.log(finals);

        const table =
            tables &&
            tables.filter((item) => {
                return item.id === table_id;
            })[0];

        const totalMatches = parseInt(challenger1_race_to + challenger2_race_to) - 1;
        const challengerScores = parseInt(challenger1_score + challenger2_score);
        const matchProgress =
            matchStatus === 'COMPLETED'
                ? 100
                : challenger1_score === challenger1_race_to || challenger2_score === challenger2_race_to
                ? 100
                : ((challengerScores / totalMatches) * 100).toFixed(0);

        if (currentMatch.status !== 'COMPLETED' && currentMatch.table_id && currentMatch.table_id !== table_id) {
            console.log('SWITCH TABLES');
            // Switch the current table back to OPEN before assigning a new table
            updateTableStatus({
                variables: {
                    id: currentMatch.table_id,
                    changes: {
                        status: 'OPEN',
                    },
                },
            });
        }

        if (table_id) {
            console.log(`Table ${table.label}`, tableStatus);
            if (!challenger1_name && !challenger2_name) {
                message.error('Unable to assign table without 2 players!');
                setLoading(false);
                handleScoreModalCancel();
                return false;
            }
            if (!challenger1_score && !challenger2_score && tableStatus === 'IN_USE') {
                matchStatus = matchStatus !== 'COMPLETED' && scheduledTime ? 'IN_PROGRESS' : matchStatus;
                if (player1 && player2 && player1.phone_number && player2.phone_number) {
                    player1.race_to = challenger1_race_to;
                    player2.race_to = challenger2_race_to;
                    notifyPlayer(player1, player2, table, currentMatch);
                    notifyPlayer(player2, player1, table, currentMatch);
                } else {
                    if (player1 && player1.phone_number) {
                        player1.race_to = challenger1_race_to;
                        notifyPlayer(player1, player2, table, currentMatch);
                    }
                    if (player2 && player2.phone_number) {
                        player2.race_to = challenger2_race_to;
                        notifyPlayer(player2, player1, table, currentMatch);
                    }
                }
            }
            updateTableStatus({
                variables: {
                    id: table_id,
                    changes: {
                        status: tableStatus,
                    },
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                //         variables: {
                //             tournament_id: tournament && tournament.id,
                //         },
                //     },
                // ],
            })
                .then((data) => {
                    // if (tableStatus === 'ASSIGNED') {
                    console.log(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            matchStatus = matchStatus !== 'COMPLETED' && scheduledTime ? 'SCHEDULED' : matchStatus;
        }

        if (matchStatus === 'COMPLETED') {
            console.log('match completed', currentMatch);

            const advance = advanceMatches({
                winner,
                loser,
                advanceTournamentBracket,
            });
            // console.log(currentRound);

            const totalMatches = currentRound && currentRound.length;
            let completedMatches = 1;
            currentRound &&
                currentRound.forEach((match) => {
                    if (match.status === 'COMPLETED') {
                        completedMatches++;
                    }
                });
            const matchesLeft = totalMatches - completedMatches;

            console.log('completed matches', completedMatches);
            console.log('matches left', matchesLeft);
            console.log('current round', currentRound);
            console.log('current match', currentMatch);
            console.log('tables', tables && tables.length);
            console.log('winner_side_race', tournament.winner_side_race_to);
            console.log('loser_side_race', tournament.loser_side_race_to);
            console.log('average time per game', 5);

            // if (matchesLeft <= 0) {
            //     console.log('END OF ROUND', currentMatch);
            //     const currentRound = currentMatch.round ? (Math.sign(currentMatch.round) === 1 ? currentMatch.round + 1 : currentMatch.round - 1) : 1;
            //     const projectionConfig = {
            //         from_round: currentRound,
            //         number_of_tables: tables.length || 1,
            //         use_tournament_actuals: true,
            //         winner_side_race_to: tournament.winner_side_race_to || 7,
            //         loser_side_race_to: tournament.loser_side_race_to || 7,
            //         average_time_per_game: 5,
            //     };

            //     console.log('time projection config', projectionConfig);
            //     const key = 'projections';
            //     message.loading({ content: 'Updating match time projections...', key, duration: 0 });

            //     generateTournamentMatchTimeProjections({
            //         variables: {
            //             tournament_id: tournament.id,
            //             ...projectionConfig,
            //         },
            //         awaitRefetchQueries: true,
            //         notifyOnNetworkStatusChange: true,
            //         // refetchQueries: [
            //         // 	{
            //         // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            //         // 		variables: { tournamentId: tournament.id }
            //         // 		// awaitRefetchQueries: true,
            //         // 		// onCompleted: (data) => {
            //         // 		// 	console.log(data);
            //         // 		// },
            //         // 		// notifyOnNetworkStatusChange: true,
            //         // 		// fetchPolicy: 'cache-and-network'
            //         // 	}
            //         // ]
            //     })
            //         .then((data) => {
            //             console.log('time projections', data);

            //             message.success({
            //                 content: `Match time projections updated`,
            //                 key,
            //                 duration: 2,
            //             });
            //         })
            //         .catch((error) => {
            //             console.error(error);
            //             message.info('There was an error', error);
            //         });
            // }

            console.log('advance to', advance);

            if (is_split === true) {
                updateTournamentProgress(100);
                message.success(`Match ${currentMatch.match_number} completed. Both players split 1st place.`);
            } else {
                updateTournamentProgress();
                message.success(`Match ${currentMatch.match_number} completed. ${winner && (winner.challenger1_name || winner.challenger2_name)} won!`);
            }

            setLoading(false);
        }

        if (currentMatch.is_bye === true && is_bye === false) {
            console.log('convert match from BYE into real match');
            convertToMatchFromBye(currentMatch, player1, player2, updateTournamentBracket);
        } else if (currentMatch.is_bye === false && is_bye === true) {
            console.log('convert match into BYE');
            convertToByeFromMatch(currentMatch, player1, player2, updateTournamentBracket);
        }

        let changes;
        if (winner && loser) {
            // remove challenger1 and challenger2 keys from winner and loser objects
            let key1 = 'challenger1';
            let key2 = 'challenger2';
            let { [key1]: c1, [key2]: c2, ...winnerNoChallenger } = winner;

            let key3 = 'challenger1';
            let key4 = 'challenger2';
            let { [key3]: c3, [key4]: c4, ...loserNoChallenger } = loser;

            changes = {
                ...winnerNoChallenger,
                ...loserNoChallenger,
            };
            console.log('winner and loser', changes);
        } else {
            changes = {
                challenger1_id,
                challenger1_name,
                challenger1_country: challenger1_country || null,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed: challenger1_seed || null,
                challenger1_is_winner: false,
                challenger1_is_forfeit: false,
                challenger1_is_withdraw: false,
                challenger2_id,
                challenger2_name,
                challenger2_country: challenger2_country || null,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed: challenger2_seed || null,
                challenger2_is_winner: false,
                challenger2_is_forfeit: false,
                challenger2_is_withdraw: false,
            };
            console.log('no winner or loser yet', changes);

            if (currentMatch.advance_to && stage.players_advance_per_group > 2) {
                const advanceTo = currentMatch.advance_to.substr(3, currentMatch.advance_to.length - 1);
                const advanceToMatch = originalMatches.filter((match) => {
                    return match.identifier === advanceTo && match.stage_number === 2;
                })[0];
                const nextStage = stages && stages[stages.length - 1];
                const key = 'advanceStage';
                console.log('advance to match', advanceToMatch);

                if (advanceToMatch) {
                    advanceTournamentStage({
                        variables: {
                            tournament_id: tournament.id,
                            stage_id: nextStage.id,
                            match_id: advanceToMatch.id,
                            changes: changes,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                                variables: {
                                    match_id: advanceToMatch.id,
                                    tournament_id: tournament.id,
                                },
                            },
                        ],
                    })
                        .then((data) => {
                            message.success({
                                content: `${challenger1_name} and ${challenger2_name} advance to ${stages && stages[stages.length - 1].name}.`,
                                key,
                                duration: 3,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
                        });
                } else {
                    message.error({ content: `Unable to advance match to ${nextStage.name}`, key, duration: 2 });
                }
            }
        }

        changes = {
            ...changes,
            start_time,
            end_time,
            ...(matchColor && { match_color: matchColor === 'transparent' ? null : matchColor }),
            scheduled_time: scheduledTime,
            table_name: table && table.label,
            scheduled_table_id: scheduled_table_id,
            priority: priority,
            table_id: table && table_id,
            status: matchStatus,
            progress: parseInt(matchProgress),
            is_bye,
            is_split,
        };

        console.log({
            id: currentMatch.id,
            tournament_id: tournament.id,
            changes: changes,
        });

        updateTournamentBracket({
            variables: {
                id: currentMatch.id,
                tournament_id: tournament.id,
                changes: changes,
            },
            // optimisticResponse: {
            // 	__typename: 'Mutation',
            // 	update_tournament_brackets: {
            // 		id: currentMatch.id,
            // 		tournament_id: tournament.id,
            // 		__typename: 'tournament_brackets',
            // 		changes: changes,
            // 		affected_rows: []
            // 	}
            // },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                // 	variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                // 	awaitRefetchQueries: true,
                // 	notifyOnNetworkStatusChange: true
                // }
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id }
                // }
            ],
        })
            .then((data) => {
                onModalScoreVisible(false);
                if (onSave) {
                    onSave(currentMatch);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function handleMenuClick(e, position) {
        console.log('click', e);
        console.log(formRef.current);

        if (e.key === 'forfeit') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
                is_split: false,
            });
        } else if (e.key === 'withdraw') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
                is_split: false,
            });
        } else if (e.key === 'split') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
                is_split: true,
            });
        }
        if (position === 'top') {
            if (e.key !== 'none') {
                setChallenger1Result(e.key);
            } else {
                setChallenger1Result();
            }
            setChallenger2Result();
        } else if (position === 'bottom') {
            if (e.key !== 'none') {
                setChallenger2Result(e.key);
            } else {
                setChallenger2Result();
            }
            setChallenger1Result();
        }
    }

    function confirmEditCompletedMatch(form) {
        formRef.current.handleSubmit();
    }

    function cancelEditCompletedMatch(e) {
        handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function cancelEndTournament(e) {
        setConfirmEndTournamentPopupVisible(false);
    }

    function handleVisibleChange(visible) {
        setConfirmPopupVisible(visible);
    }

    function confirmRemoveTableAssignment(updateTournamentBracket) {
        handleRemoveTableAssignment(updateTournamentBracket);
    }

    function confirmRemoveScheduledTableAssignment(updateTournamentBracket) {
        handleRemoveScheduledTableAssignment(updateTournamentBracket);
    }

    function cancelRemoveTableAssignment(e) {
        // handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function cancelRemoveScheduledTableAssignment(e) {
        // handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function handleRemoveTableVisibleChange(visible) {
        setConfirmTablePopupVisible(visible);
    }

    function handleRemoveScheduledTableVisibleChange(visible) {
        setConfirmScheduledTablePopupVisible(visible);
    }

    const MatchSchema = Yup.object().shape({
        // challenger1_name: Yup.string().nullable().required('Required'),
        // challenger2_name: Yup.string().nullable().required('Required'),
        // challenger1_score: Yup.number().nullable(),
        // challenger2_score: Yup.number().nullable()
        // division_type: Yup.string().required('Required'),
        // allowed_games: Yup.string().required('Required')
    });

    const actionsMenuTop = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'top');
            }}
            selectedKeys={[challenger1Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            {currentMatch && (currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true) && <Menu.Item key="split">Split</Menu.Item>}
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Item key="withdraw">Withdraw</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    const actionsMenuBottom = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'bottom');
            }}
            selectedKeys={[challenger2Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            {currentMatch && (currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true) && <Menu.Item key="split">Split</Menu.Item>}
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Item key="withdraw">Withdraw</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    async function handleRemoveTableAssignment(updateTournamentBracket) {
        const key = 'updatable';
        message.loading({ content: `Removing table assignment...`, key, duration: 0 });
        updateTableStatus({
            variables: {
                id: currentMatch.table_id,
                changes: {
                    status: 'OPEN',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
                    variables: { tournament_id: tournament.id },
                    // awaitRefetchQueries: true,
                    // onCompleted: (data) => {
                    // 	console.log(data);
                    // },
                    // notifyOnNetworkStatusChange: true,
                    // fetchPolicy: 'cache-and-network'
                },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id },
                // 	awaitRefetchQueries: true
                // }
            ],
        });

        updateTournamentBracket({
            variables: {
                id: currentMatch.id,
                tournament_id: tournament.id,
                changes: {
                    table_id: null,
                    status: 'NOT_STARTED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // }
                // {
                //     query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //     variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                //     awaitRefetchQueries: true,
                //     notifyOnNetworkStatusChange: true,
                // },
            ],
        })
            .then((data) => {
                onModalScoreVisible(false);
                message.success({
                    content: `Removed table assignment for Match ${currentMatch.match_number}.`,
                    key,
                    duration: 2,
                });
            })
            .catch((error) => {
                console.log(error);
                message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
            });
    }

    async function handleRemoveScheduledTableAssignment(updateTournamentBracket) {
        const key = 'updatable';
        message.loading({ content: `Removing scheduled table assignment...`, key, duration: 0 });

        updateTournamentBracket({
            variables: {
                id: currentMatch.id,
                tournament_id: tournament.id,
                changes: {
                    scheduled_table_id: null,
                    priority: null,
                    status: 'NOT_STARTED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // }
                // {
                //     query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //     variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                //     awaitRefetchQueries: true,
                //     notifyOnNetworkStatusChange: true,
                // },
            ],
        })
            .then((data) => {
                onModalScoreVisible(false);
                message.success({
                    content: `Removed scheduled table assignment for Match ${currentMatch.match_number}.`,
                    key,
                    duration: 2,
                });
            })
            .catch((error) => {
                console.log(error);
                message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
            });
    }

    async function getFargoCharts(type, size, player1Fargo, player2Fargo, match) {
        const typesEnum = {
            hot: 2,
            medium: 1,
            mild: 0,
        };
        const url = `https://lms.fargorate.com/api/ratingcalc/racesbylengthandtype?length=${size}&type=${typesEnum[type]}&ratingOne=${player1Fargo}&ratingTwo=${player2Fargo}`;

        return await axios
            .get(url)
            .then((response) => {
                const results = response.data;
                console.log(results);
                const closestChart = results.filter((item) => item.closest === true)[0];
                const player1RaceTo =
                    player1Fargo > player2Fargo
                        ? closestChart.highPlayerRaceTo
                        : player1Fargo < player2Fargo
                        ? closestChart.lowPlayerRaceTo
                        : closestChart.highPlayerRaceTo;
                const player2RaceTo =
                    player2Fargo > player1Fargo
                        ? closestChart.highPlayerRaceTo
                        : player2Fargo < player1Fargo
                        ? closestChart.lowPlayerRaceTo
                        : closestChart.highPlayerRaceTo;

                const updatedMatch = {
                    ...match,
                    challenger1_race_to: player1RaceTo,
                    challenger2_race_to: player2RaceTo,
                };

                return updatedMatch;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function getUSAPLRaces() {
        const player1Fargo = currentMatch.challenger1 && currentMatch.challenger1.skill_level;
        const player2Fargo = currentMatch.challenger2 && currentMatch.challenger2.skill_level;
        if (!player1Fargo || !player2Fargo) {
            message.error('Both players must have a Fargo rating to calculate a USAPL race.');
            return;
        }
        let races;

        if (tournament.usapl_race_format === 'longer_races') {
            races = calculateUSAPLRace(1.0, player1Fargo, player2Fargo);
        } else if (tournament.usapl_race_format === 'shorter_races') {
            races = calculateUSAPLRace(0.75, player1Fargo, player2Fargo);
        }

        formRef.current.setValues({
            ...formRef.current.state.values,
            challenger1_race_to: races.p1RaceTo,
            challenger2_race_to: races.p2RaceTo,
        });
    }

    function getFargoRaces(race) {
        let promises = [];
        const key = 'fargo';
        let raceTo;
        if (currentMatch && currentMatch.identifier && currentMatch.identifier.includes('L')) {
            raceTo = race || tournament.losers_race_to;
        } else {
            raceTo = race || tournament.winners_race_to;
        }

        // const raceTo = race || tournament.winners_race_to;
        // const raceTo = race || tournament.winners_race_to;

        const player1Fargo = currentMatch.challenger1 && currentMatch.challenger1.skill_level;
        const player2Fargo = currentMatch.challenger2 && currentMatch.challenger2.skill_level;

        if (player1Fargo && player2Fargo) {
            if (tournament.handicap_format === 'fargo_hot_column') {
                promises.push(getFargoCharts('hot', raceTo, player1Fargo, player2Fargo, currentMatch));
            } else if (tournament.handicap_format === 'fargo_medium_column') {
                promises.push(getFargoCharts('medium', raceTo, player1Fargo, player2Fargo, currentMatch));
            } else if (tournament.handicap_format === 'fargo_mild_column') {
                promises.push(getFargoCharts('mild', raceTo, player1Fargo, player2Fargo, currentMatch));
            } else {
                message.warning('This tournament has not been enabled for Fargo Race Charts.');
                return;
            }

            message.loading({ content: 'Getting recommended Fargo Race for players...', key, duration: 1 });
            setFargoLoading(true);
            return new Promise(async (resolve, reject) => {
                Promise.all(promises)
                    .then((match) => {
                        console.log('tournament', tournament);
                        console.log('match', match);

                        if (match && match[0]) {
                            let challenger1Race = parseInt(match[0].challenger1_race_to);
                            let challenger2Race = parseInt(match[0].challenger2_race_to);
                            let finalChallenger1Race;
                            let finalChallenger2Race;

                            console.log('challenger1 race', challenger1Race);
                            console.log('challenger2 race', challenger2Race);

                            if (currentMatch && currentMatch.identifier && currentMatch.identifier.includes('L')) {
                                if (tournament.losers_max_race) {
                                    if (challenger1Race < tournament.losers_max_race) {
                                        finalChallenger1Race = challenger1Race;
                                    } else {
                                        finalChallenger1Race = tournament.losers_max_race;
                                    }

                                    if (challenger2Race < tournament.losers_max_race) {
                                        finalChallenger2Race = challenger2Race;
                                    } else {
                                        finalChallenger2Race = tournament.losers_max_race;
                                    }
                                } else {
                                    finalChallenger1Race = challenger1Race;
                                    finalChallenger2Race = challenger2Race;
                                }
                            } else {
                                if (tournament.winners_max_race) {
                                    if (challenger1Race < tournament.winners_max_race) {
                                        finalChallenger1Race = challenger1Race;
                                    } else {
                                        finalChallenger1Race = tournament.winners_max_race;
                                    }

                                    if (challenger2Race < tournament.winners_max_race) {
                                        finalChallenger2Race = challenger2Race;
                                    } else {
                                        finalChallenger2Race = tournament.winners_max_race;
                                    }
                                } else {
                                    finalChallenger1Race = challenger1Race;
                                    finalChallenger2Race = challenger2Race;
                                }
                            }

                            console.log('final challenger1 race', finalChallenger1Race);
                            console.log('final challenger2 race', finalChallenger2Race);

                            formRef.current.setValues({
                                ...formRef.current.state.values,
                                challenger1_race_to: finalChallenger1Race,
                                challenger2_race_to: finalChallenger2Race,
                            });
                            setFargoLoading(false);

                            message.success({
                                content: `${match[0].challenger1_name} goes to ${finalChallenger1Race} and ${match[0].challenger2_name} goes to ${finalChallenger2Race}`,
                                key,
                                duration: 2,
                            });

                            resolve({
                                challenger1_race_to: finalChallenger1Race,
                                challenger2_race_to: finalChallenger2Race,
                            });
                        } else {
                            resolve(match);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        message.error({ content: JSON.stringify(e), key, duration: 2 });
                        setFargoLoading(false);
                        reject(e);
                    });
            }).catch((e) => {
                console.log(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
                setFargoLoading(false);
                return e;
            });
        } else {
            if (!player1Fargo) {
                message.warning(`${currentMatch.challenger1_name} does not yet have a Fargo Rating assigned.`);
            } else if (!player2Fargo) {
                message.warning(`${currentMatch.challenger2_name} does not yet have a Fargo Rating assigned.`);
            } else {
                message.warning('Both players must have Fargo Rating assigned using the Skill Level field in the Players list.');
            }

            return;
        }
    }

    function showConfirmResendNotification() {
        confirm({
            title: `Are you sure you want to re-send the match assignment SMS notification to both players?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                let player1;
                let player2;

                // returns true if the variable does NOT contain a valid number
                if (isNaN(currentMatch.challenger1_name) !== true) {
                    const id = parseInt(currentMatch.challenger1_name);
                    player1 = players.filter((item) => {
                        return item.id === id;
                    })[0];
                } else {
                    player1 = players.filter((item) => {
                        return item.name === currentMatch.challenger1_name;
                    })[0];
                }

                if (isNaN(currentMatch.challenger2_name) !== true) {
                    const id = parseInt(currentMatch.challenger2_name);
                    player2 = players.filter((item) => {
                        return item.id === id;
                    })[0];
                } else {
                    player2 = players.filter((item) => {
                        return item.name === currentMatch.challenger2_name;
                    })[0];
                }

                const table = tables.filter((item) => {
                    return item.id === currentMatch.table_id;
                })[0];

                console.log('players', {
                    player1,
                    player2,
                    currentMatch,
                    table,
                });

                if (!currentMatch.challenger1_score && !currentMatch.challenger2_score && table.status === 'IN_USE') {
                    if (player1 && player2 && player1.phone_number && player2.phone_number) {
                        player1.race_to = currentMatch.challenger1_race_to;
                        player2.race_to = currentMatch.challenger2_race_to;
                        notifyPlayer(player1, player2, table, currentMatch);
                        notifyPlayer(player2, player1, table, currentMatch);
                    } else {
                        if (player1 && player1.phone_number) {
                            player1.race_to = currentMatch.challenger1_race_to;
                            notifyPlayer(player1, player2, table, currentMatch);
                        }
                        if (player2 && player2.phone_number) {
                            player2.race_to = currentMatch.challenger2_race_to;
                            notifyPlayer(player2, player1, table, currentMatch);
                        }
                    }
                }
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function getDKHandicapRace(player1, player2, currentMatch) {
        const p1SkillLevel = player1.skill_level;
        const p1SkillLevelStr = p1SkillLevel.toString();
        const p2SkillLevel = player2.skill_level;
        const p2SkillLevelStr = p2SkillLevel.toString();
        let p1DkRating;
        let p2DkRating;
        let p1Race;
        let p2Race;

        const dkConversionChart = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
        const fargoChart = [
            [300, 320],
            [321, 340],
            [341, 360],
            [361, 380],
            [381, 400],
            [401, 420],
            [421, 440],
            [441, 460],
            [461, 490],
            [491, 520],
            [521, 550],
            [551, 580],
            [581, 620],
            [621, 650],
            [651, 680],
            [681, 710],
            [711, 750],
            [751, 790],
            [791, 999],
        ];

        if (p1SkillLevelStr && p1SkillLevelStr.length > 2) {
            console.log('p1 fargo rating');
            fargoChart.forEach((ratings, index) => {
                const lowRating = ratings[0];
                const highRating = ratings[1];
                if (p1SkillLevel >= lowRating && p1SkillLevel <= highRating) {
                    p1DkRating = dkConversionChart[index];
                }
            });
        } else {
            p1DkRating = p1SkillLevel;
        }

        if (p2SkillLevelStr && p2SkillLevelStr.length > 2) {
            console.log('p2 fargo rating');
            fargoChart.forEach((ratings, index) => {
                const lowRating = ratings[0];
                const highRating = ratings[1];
                if (p2SkillLevel >= lowRating && p2SkillLevel <= highRating) {
                    p2DkRating = dkConversionChart[index];
                }
            });
        } else {
            p2DkRating = p2SkillLevel;
        }

        console.log({
            p1SkillLevel,
            p2SkillLevel,
            p1DkRating,
            p2DkRating,
        });

        const raceMatrix = [
            ['4-4', '4-4'],
            ['4-5', '4-4'],
            ['4-6', '4-4'],
            ['4-7', '4-4'],
            ['4-8', '5-4'],
            ['4-9', '5-4'],
            ['4-10', '5-4'],
            ['4-11', '5-4'],
            ['4-12', '6-4'],
            ['4-13', '6-4'],
            ['4-14', '7-4'],
            ['4-15', '8-4'],
            ['4-16', '8-4'],
            ['4-17', '8-3'],
            ['4-18', '8-3'],
            ['4-19', '8-3'],
            ['4-20', '8-3'],
            ['4-21', '9-2'],
            ['4-22', '9-2'],
            ['5-4', '4-4'],
            ['5-5', '4-4'],
            ['5-6', '4-4'],
            ['5-7', '4-4'],
            ['5-8', '4-4'],
            ['5-9', '5-4'],
            ['5-10', '5-4'],
            ['5-11', '5-4'],
            ['5-12', '6-4'],
            ['5-13', '6-4'],
            ['5-14', '7-4'],
            ['5-15', '8-4'],
            ['5-16', '8-4'],
            ['5-17', '8-3'],
            ['5-18', '8-3'],
            ['5-19', '8-3'],
            ['5-20', '8-3'],
            ['5-21', '9-2'],
            ['5-22', '9-2'],
            ['6-4', '4-4'],
            ['6-5', '4-4'],
            ['6-6', '4-4'],
            ['6-7', '4-4'],
            ['6-8', '4-4'],
            ['6-9', '4-4'],
            ['6-10', '5-4'],
            ['6-11', '5-4'],
            ['6-12', '6-4'],
            ['6-13', '6-4'],
            ['6-14', '7-4'],
            ['6-15', '8-4'],
            ['6-16', '8-4'],
            ['6-17', '8-3'],
            ['6-18', '8-3'],
            ['6-19', '8-3'],
            ['6-20', '8-3'],
            ['6-21', '9-2'],
            ['6-22', '9-2'],
            ['7-4', '4-4'],
            ['7-5', '4-4'],
            ['7-6', '4-4'],
            ['7-7', '4-4'],
            ['7-8', '4-4'],
            ['7-9', '4-4'],
            ['7-10', '4-4'],
            ['7-11', '5-4'],
            ['7-12', '6-4'],
            ['7-13', '6-4'],
            ['7-14', '7-4'],
            ['7-15', '8-4'],
            ['7-16', '8-4'],
            ['7-17', '8-3'],
            ['7-18', '8-3'],
            ['7-19', '8-3'],
            ['7-20', '8-3'],
            ['7-21', '9-2'],
            ['7-22', '9-2'],
            ['8-4', '5-4'],
            ['8-5', '4-4'],
            ['8-6', '4-4'],
            ['8-7', '4-4'],
            ['8-8', '4-4'],
            ['8-9', '4-4'],
            ['8-10', '4-4'],
            ['8-11', '5-4'],
            ['8-12', '6-5'],
            ['8-13', '6-4'],
            ['8-14', '7-5'],
            ['8-15', '8-4'],
            ['8-16', '8-4'],
            ['8-17', '8-3'],
            ['8-18', '8-3'],
            ['8-19', '8-3'],
            ['8-20', '8-3'],
            ['8-21', '9-2'],
            ['8-22', '9-2'],
            ['9-4', '5-4'],
            ['9-5', '5-4'],
            ['9-6', '4-4'],
            ['9-7', '4-4'],
            ['9-8', '4-4'],
            ['9-9', '4-4'],
            ['9-10', '4-4'],
            ['9-11', '5-4'],
            ['9-12', '6-5'],
            ['9-13', '6-5'],
            ['9-14', '7-5'],
            ['9-15', '8-5'],
            ['9-16', '8-4'],
            ['9-17', '8-3'],
            ['9-18', '8-3'],
            ['9-19', '8-3'],
            ['9-20', '8-3'],
            ['9-21', '9-2'],
            ['9-22', '9-2'],
            ['10-4', '5-4'],
            ['10-5', '5-4'],
            ['10-6', '5-4'],
            ['10-7', '4-4'],
            ['10-8', '4-4'],
            ['10-9', '4-4'],
            ['10-10', '5-5'],
            ['10-11', '5-5'],
            ['10-12', '6-5'],
            ['10-13', '6-5'],
            ['10-14', '7-5'],
            ['10-15', '8-5'],
            ['10-16', '8-4'],
            ['10-17', '8-3'],
            ['10-18', '8-3'],
            ['10-19', '9-4'],
            ['10-20', '8-3'],
            ['10-21', '9-2'],
            ['10-22', '9-2'],
            ['11-4', '5-4'],
            ['11-5', '5-4'],
            ['11-6', '5-4'],
            ['11-7', '5-4'],
            ['11-8', '5-4'],
            ['11-9', '5-4'],
            ['11-10', '5-5'],
            ['11-11', '5-5'],
            ['11-12', '5-5'],
            ['11-13', '6-5'],
            ['11-14', '6-5'],
            ['11-15', '7-5'],
            ['11-16', '8-5'],
            ['11-17', '8-4'],
            ['11-18', '8-3'],
            ['11-19', '9-4'],
            ['11-20', '9-4'],
            ['11-21', '9-3'],
            ['11-22', '9-2'],
            ['12-4', '6-4'],
            ['12-5', '6-4'],
            ['12-6', '6-4'],
            ['12-7', '6-4'],
            ['12-8', '6-5'],
            ['12-9', '6-5'],
            ['12-10', '6-5'],
            ['12-11', '5-5'],
            ['12-12', '5-5'],
            ['12-13', '5-5'],
            ['12-14', '6-5'],
            ['12-15', '7-5'],
            ['12-16', '8-5'],
            ['12-17', '8-4'],
            ['12-18', '8-3'],
            ['12-19', '9-4'],
            ['12-20', '9-4'],
            ['12-21', '9-3'],
            ['12-22', '9-3'],
            ['13-4', '6-4'],
            ['13-5', '6-4'],
            ['13-6', '6-4'],
            ['13-7', '6-4'],
            ['13-8', '6-4'],
            ['13-9', '6-5'],
            ['13-10', '6-5'],
            ['13-11', '6-5'],
            ['13-12', '5-5'],
            ['13-13', '6-6'],
            ['13-14', '6-6'],
            ['13-15', '7-5'],
            ['13-16', '7-5'],
            ['13-17', '8-5'],
            ['13-18', '8-4'],
            ['13-19', '9-5'],
            ['13-20', '9-4'],
            ['13-21', '9-4'],
            ['13-22', '9-3'],
            ['14-4', '7-4'],
            ['14-5', '7-4'],
            ['14-6', '7-4'],
            ['14-7', '7-4'],
            ['14-8', '7-5'],
            ['14-9', '7-5'],
            ['14-10', '7-5'],
            ['14-11', '6-5'],
            ['14-12', '6-5'],
            ['14-13', '6-6'],
            ['14-14', '6-6'],
            ['14-15', '7-6'],
            ['14-16', '7-5'],
            ['14-17', '8-5'],
            ['14-18', '8-5'],
            ['14-19', '9-5'],
            ['14-20', '9-5'],
            ['14-21', '9-4'],
            ['14-22', '9-4'],
            ['15-4', '8-4'],
            ['15-5', '8-4'],
            ['15-6', '8-4'],
            ['15-7', '8-4'],
            ['15-8', '8-4'],
            ['15-9', '8-5'],
            ['15-10', '8-5'],
            ['15-11', '7-5'],
            ['15-12', '7-5'],
            ['15-13', '7-5'],
            ['15-14', '7-6'],
            ['15-15', '6-6'],
            ['15-16', '7-6'],
            ['15-17', '8-6'],
            ['15-18', '8-5'],
            ['15-19', '9-6'],
            ['15-20', '9-5'],
            ['15-21', '9-5'],
            ['15-22', '9-4'],
            ['16-4', '8-4'],
            ['16-5', '8-4'],
            ['16-6', '8-4'],
            ['16-7', '8-4'],
            ['16-8', '8-4'],
            ['16-9', '8-4'],
            ['16-10', '8-4'],
            ['16-11', '8-5'],
            ['16-12', '8-5'],
            ['16-13', '7-5'],
            ['16-14', '7-5'],
            ['16-15', '7-6'],
            ['16-16', '7-7'],
            ['16-17', '8-7'],
            ['16-18', '8-6'],
            ['16-19', '9-6'],
            ['16-20', '9-6'],
            ['16-21', '9-5'],
            ['16-22', '9-5'],
            ['17-4', '8-3'],
            ['17-5', '8-3'],
            ['17-6', '8-3'],
            ['17-7', '8-3'],
            ['17-8', '8-3'],
            ['17-9', '8-3'],
            ['17-10', '8-3'],
            ['17-11', '8-4'],
            ['17-12', '8-4'],
            ['17-13', '8-5'],
            ['17-14', '8-5'],
            ['17-15', '8-6'],
            ['17-16', '8-7'],
            ['17-17', '7-7'],
            ['17-18', '8-7'],
            ['17-19', '8-6'],
            ['17-20', '9-6'],
            ['17-21', '9-6'],
            ['17-22', '9-5'],
            ['18-4', '8-3'],
            ['18-5', '8-3'],
            ['18-6', '8-3'],
            ['18-7', '8-3'],
            ['18-8', '8-3'],
            ['18-9', '8-3'],
            ['18-10', '8-3'],
            ['18-11', '8-3'],
            ['18-12', '8-4'],
            ['18-13', '8-4'],
            ['18-14', '8-5'],
            ['18-15', '8-5'],
            ['18-16', '8-6'],
            ['18-17', '8-7'],
            ['18-18', '7-7'],
            ['18-19', '8-7'],
            ['18-20', '8-6'],
            ['18-21', '9-6'],
            ['18-22', '9-6'],
            ['19-4', '8-3'],
            ['19-5', '8-3'],
            ['19-6', '8-3'],
            ['19-7', '8-3'],
            ['19-8', '8-3'],
            ['19-9', '8-3'],
            ['19-10', '9-4'],
            ['19-11', '9-4'],
            ['19-12', '9-4'],
            ['19-13', '9-5'],
            ['19-14', '9-5'],
            ['19-15', '9-6'],
            ['19-16', '9-6'],
            ['19-17', '8-6'],
            ['19-18', '8-7'],
            ['19-19', '8-8'],
            ['19-20', '8-7'],
            ['19-21', '8-6'],
            ['19-22', '9-6'],
            ['20-4', '8-3'],
            ['20-5', '8-3'],
            ['20-6', '8-3'],
            ['20-7', '8-3'],
            ['20-8', '8-3'],
            ['20-9', '8-3'],
            ['20-10', '8-3'],
            ['20-11', '9-4'],
            ['20-12', '9-4'],
            ['20-13', '9-4'],
            ['20-14', '9-5'],
            ['20-15', '9-5'],
            ['20-16', '9-6'],
            ['20-17', '9-6'],
            ['20-18', '8-6'],
            ['20-19', '8-7'],
            ['20-20', '8-8'],
            ['20-21', '9-8'],
            ['20-22', '9-7'],
            ['21-4', '9-2'],
            ['21-5', '9-2'],
            ['21-6', '9-2'],
            ['21-7', '9-2'],
            ['21-8', '9-2'],
            ['21-9', '9-2'],
            ['21-10', '9-2'],
            ['21-11', '9-3'],
            ['21-12', '9-3'],
            ['21-13', '9-4'],
            ['21-14', '9-4'],
            ['21-15', '9-5'],
            ['21-16', '9-5'],
            ['21-17', '9-6'],
            ['21-18', '9-6'],
            ['21-19', '8-6'],
            ['21-20', '9-8'],
            ['21-21', '9-9'],
            ['21-22', '9-8'],
            ['22-4', '9-2'],
            ['22-5', '9-2'],
            ['22-6', '9-2'],
            ['22-7', '9-2'],
            ['22-8', '9-2'],
            ['22-9', '9-2'],
            ['22-10', '9-2'],
            ['22-11', '9-3'],
            ['22-12', '9-3'],
            ['22-13', '9-3'],
            ['22-14', '9-4'],
            ['22-15', '9-4'],
            ['22-16', '9-5'],
            ['22-17', '9-5'],
            ['22-18', '9-6'],
            ['22-19', '9-6'],
            ['22-20', '9-7'],
            ['22-21', '9-8'],
            ['22-22', '9-9'],
        ];

        raceMatrix.forEach((races, index) => {
            const dkPlayerRatings = races[0];
            const dkPlayerRaces = races[1];
            const dkPlayerRatingSplit = dkPlayerRatings.split('-');
            const dkRating1 = parseInt(dkPlayerRatingSplit[0]);
            const dkRating2 = parseInt(dkPlayerRatingSplit[1]);
            const dkRaceSplit = dkPlayerRaces.split('-');
            const dkHighRace = parseInt(dkRaceSplit[0]);
            const dkLowRace = parseInt(dkRaceSplit[1]);

            if (p1DkRating === dkRating1 && p2DkRating === dkRating2) {
                if (dkRating1 >= dkRating2) {
                    p1Race = dkHighRace;
                    p2Race = dkLowRace;
                } else {
                    p1Race = dkLowRace;
                    p2Race = dkHighRace;
                }
            } else if (p1DkRating === dkRating2 && p2DkRating === dkRating1) {
                if (dkRating2 >= dkRating1) {
                    p1Race = dkHighRace;
                    p2Race = dkLowRace;
                } else {
                    p1Race = dkLowRace;
                    p2Race = dkHighRace;
                }
            }
        });

        // console.log({
        //     p1Race,
        //     p2Race,
        // });

        if (p1Race && p2Race) {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_race_to: p1Race,
                challenger2_race_to: p2Race,
            });

            message.success(`${currentMatch.challenger1_name} goes to ${p1Race} and ${currentMatch.challenger2_name} goes to ${p2Race}`);
        }
    }

    function getHandicapRace(player1, player2, currentMatch) {
        const player1SkillLevel = player1.skill_level;
        const player2SkillLevel = player2.skill_level;
        let player1Handicap;
        let player2Handicap;

        if (player1SkillLevel) {
            if (player1SkillLevel <= 648) {
                player1Handicap = 'C';
            } else if (player1SkillLevel >= 649 && player1SkillLevel <= 699) {
                player1Handicap = 'B';
            } else if (player1SkillLevel >= 700 && player1SkillLevel <= 799) {
                player1Handicap = 'A';
            } else if (player1SkillLevel >= 800) {
                player1Handicap = 'A';
            } else {
                player2Handicap = '';
            }
        } else {
            player1Handicap = '';
        }

        if (player2SkillLevel) {
            if (player2SkillLevel <= 648) {
                player2Handicap = 'C';
            } else if (player2SkillLevel >= 649 && player2SkillLevel <= 699) {
                player2Handicap = 'B';
            } else if (player2SkillLevel >= 700 && player2SkillLevel <= 799) {
                player2Handicap = 'A';
            } else if (player2SkillLevel >= 800) {
                player2Handicap = 'A';
            } else {
                player2Handicap = '';
            }
        } else {
            player2Handicap = '';
        }

        console.log({
            player1SkillLevel,
            player2SkillLevel,
            player1Handicap,
            player2Handicap,
        });

        const races = {
            winners: {
                AA: {
                    player1Race: 7,
                    player2Race: 7,
                },
                AB: {
                    player1Race: 7,
                    player2Race: 6,
                },
                AC: {
                    player1Race: 8,
                    player2Race: 6,
                },
                BA: {
                    player1Race: 6,
                    player2Race: 7,
                },
                BB: {
                    player1Race: 7,
                    player2Race: 7,
                },
                BC: {
                    player1Race: 7,
                    player2Race: 6,
                },
                CA: {
                    player1Race: 8,
                    player2Race: 6,
                },
                CB: {
                    player1Race: 6,
                    player2Race: 7,
                },
                CC: {
                    player1Race: 7,
                    player2Race: 7,
                },
            },
            losers: {
                AA: {
                    player1Race: 5,
                    player2Race: 5,
                },
                AB: {
                    player1Race: 5,
                    player2Race: 4,
                },
                AC: {
                    player1Race: 6,
                    player2Race: 4,
                },
                BA: {
                    player1Race: 4,
                    player2Race: 5,
                },
                BB: {
                    player1Race: 5,
                    player2Race: 5,
                },
                BC: {
                    player1Race: 5,
                    player2Race: 4,
                },
                CA: {
                    player1Race: 4,
                    player2Race: 6,
                },
                CB: {
                    player1Race: 4,
                    player2Race: 5,
                },
                CC: {
                    player1Race: 5,
                    player2Race: 5,
                },
            },
        };

        let finalRaces;

        if (currentMatch && currentMatch.identifier && currentMatch.identifier.includes('L')) {
            finalRaces = races['losers'][player1Handicap + player2Handicap];
        } else {
            finalRaces = races['winners'][player1Handicap + player2Handicap];
        }

        if (finalRaces) {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_race_to: finalRaces.player1Race,
                challenger2_race_to: finalRaces.player2Race,
            });

            message.success(
                `${currentMatch.challenger1_name} goes to ${finalRaces.player1Race} and ${currentMatch.challenger2_name} goes to ${finalRaces.player2Race}`
            );
        }

        console.log(finalRaces);

        return finalRaces;
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function onChangeEntry(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        // formRef.current.setValues({
        // 	matches: matches.map((match) => {
        // 		return tzDate;
        // 	})
        // });
        return tzDate;
    }

    return (
        <Mutation
            mutation={UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION}
            onCompleted={(data) => {
                // console.log(data);
                setLoading(false);
                // message.success('Changes saved');
                // const result = data.update_tournament_brackets.returning[0];
                // console.log(result);
                // props.history.push(`/league-manager/${props.league.slug}/venues/${result.slug}`);
                // onModalScoreVisible(false);
                setChallenger1Result();
                setChallenger2Result();
                // props.refetch();
            }}
            // update={(cache, { data: { update_tournament_brackets } }) => {
            // 	const data = cache.readQuery({
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id }
            // 	});
            // 	const updatedMatch = data.tournament_brackets.find(
            // 		(match) => match.id === update_tournament_brackets.returning[0].id
            // 	);

            // 	const updatedBracket = data.tournament_brackets.map((match) => {
            // 		if (match.id === updatedMatch.id) {
            // 			return updatedMatch;
            // 		} else {
            // 			return match;
            // 		}
            // 	});

            // 	cache.writeQuery({
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id },
            // 		data: { tournament_brackets: [ ...updatedBracket ] }
            // 	});
            // }}
            // refetchQueries={() => [
            // 	{
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id }
            // 		// awaitRefetchQueries: true,
            // 		// onCompleted: (data) => {
            // 		// 	console.log(data);
            // 		// }
            // 		// notifyOnNetworkStatusChange: true
            // 		// fetchPolicy: 'cache-and-network'
            // 	}
            // ]}
            awaitRefetchQueries
        >
            {(updateTournamentBracket, { error }) => (
                <Mutation
                    mutation={ADVANCE_TOURNAMENT_BRACKET_MUTATION}
                    onCompleted={(data) => {
                        // console.log(data);
                        setLoading(false);
                        // message.success('Changes saved');
                        // const result = data.update_tournament_brackets.returning[0];
                        // console.log(result);
                        // props.history.push(`/league-manager/${props.league.slug}/venues/${result.slug}`);
                        onModalScoreVisible(false);
                        setChallenger1Result();
                        setChallenger2Result();
                        // props.refetch();
                    }}
                >
                    {(advanceTournamentBracket, { error }) => {
                        if (error) {
                            alert(error);
                        }

                        let scheduledT;
                        let startT;

                        if (currentMatch) {
                            scheduledT = moment.utc(currentMatch.scheduled_time, 'HH:mm a');
                            startT = moment.utc(currentMatch.start_time, 'HH:mm a');
                        }

                        var zone_name = moment.tz.guess();
                        var timezone = moment.tz(zone_name).zoneAbbr();

                        return (
                            <React.Fragment>
                                <Modal
                                    title={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginRight: 6 }}>
                                                    <ColorPicker
                                                        defaultColor={matchColor}
                                                        colors={[
                                                            '#FF6900',
                                                            '#FCB900',
                                                            '#7BDCB5',
                                                            '#00D084',
                                                            '#8ED1FC',
                                                            '#0693E3',
                                                            // '#ABB8C3',
                                                            '#EB144C',
                                                            '#F78DA7',
                                                            '#9900EF',
                                                            // '#172b40'
                                                            'transparent',
                                                        ]}
                                                        onSetColor={(hex) => {
                                                            setMatchColor(hex);
                                                        }}
                                                        {...props}
                                                    />
                                                </span>
                                                Match {currentMatch && currentMatch.match_number} ({currentMatch && currentMatch.identifier})
                                                {/* {currentMatch && currentMatch.scheduled_time && moment(startT).isAfter(scheduledT)
                                                    ? `- ${moment(currentMatch.scheduled_time).calendar()}`
                                                    : currentMatch && currentMatch.start_time
                                                    ? `- ${moment(currentMatch.start_time).calendar()}`
                                                    : ''} */}
                                            </span>
                                            <span>
                                                {scheduledTime ? (
                                                    <Button
                                                        type="ghost"
                                                        size="small"
                                                        onClick={() => {
                                                            setShowMatchTimeOverlay(true);
                                                        }}
                                                    >
                                                        <Icon type="clock-circle" style={{ marginRight: 3 }} />{' '}
                                                        {moment
                                                            .utc(scheduledTime || (currentMatch && currentMatch.scheduled_time))
                                                            .local()
                                                            .format('lll z')}{' '}
                                                        {timezone}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="ghost"
                                                        size="small"
                                                        onClick={() => {
                                                            setShowMatchTimeOverlay(true);
                                                        }}
                                                    >
                                                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> Set match time
                                                    </Button>
                                                )}
                                            </span>
                                            <span
                                                style={{ marginRight: 30, textTransform: 'uppercase', lineHeight: '23px' }}
                                                className={`${
                                                    currentMatch && currentMatch.status === 'PENDING'
                                                        ? 'pending'
                                                        : currentMatch && currentMatch.status === 'IN_PROGRESS'
                                                        ? 'live'
                                                        : currentMatch && currentMatch.status === 'SCHEDULED'
                                                        ? 'scheduled'
                                                        : 'completed'
                                                }-text`}
                                            >
                                                {currentMatch && currentMatch.status === 'IN_PROGRESS'
                                                    ? 'LIVE'
                                                    : currentMatch
                                                    ? displayEnumType(currentMatch.status)
                                                    : ''}
                                            </span>
                                        </div>
                                    }
                                    getContainer={() =>
                                        breakpoint === 'mobile'
                                            ? document.body
                                            : isFullScreen === true
                                            ? document.querySelector('#bracket-container')
                                            : document.body
                                    }
                                    visible={modalScoreVisible}
                                    // okText="Save Match"
                                    // okButtonProps={{ htmlType: 'submit', loading: loading }}
                                    // onOk={handleScoreModalOk}
                                    onCancel={handleScoreModalCancel}
                                    // centered
                                    style={{
                                        top: '50%',
                                        // left: isWidthDown('xs', width) ? 0 : -120,
                                        transform: 'translateY(-50%)',
                                    }}
                                    // transitionName="none"
                                    transitionName="fade"
                                    maskTransitionName="none"
                                    destroyOnClose={true}
                                    footer={null}
                                >
                                    {showMatchTimeOverlay === true && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: 'rgba(0,0,0,0.9)',
                                                zIndex: 10,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, color: '#fff' }}>
                                                    Set match date and time
                                                </Title>
                                                <Formik
                                                    ref={formRef2}
                                                    initialValues={{ scheduled_time: scheduledTime }}
                                                    // validationSchema={MatchSchema}
                                                    onSubmit={(values, actions) => {
                                                        console.log(JSON.stringify(values, null, 2));
                                                        console.log(values.scheduled_time);
                                                        // setLoading(true);

                                                        // const changes = {
                                                        //     scheduled_time: values.scheduled_time || null,
                                                        //     status: values.scheduled_time ? 'SCHEDULED' : 'NOT_STARTED',
                                                        // };

                                                        // console.log(changes);

                                                        setScheduledTime(values.scheduled_time ? values.scheduled_time.toString() : null);
                                                        setShowMatchTimeOverlay(false);

                                                        // updateMatchTime({
                                                        //     variables: {
                                                        //         tournament_id: tournament.id,
                                                        //         round: currentMatch.round,
                                                        //         id: currentMatch.id,
                                                        //         changes: changes,
                                                        //     },
                                                        //     notifyOnNetworkStatusChange: true,
                                                        //     awaitRefetchQueries: true,
                                                        //     refetchQueries: [
                                                        //         {
                                                        //             query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                                                        //             variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                                                        //         },
                                                        //     ],
                                                        // })
                                                        //     .then((data) => {
                                                        //         console.log(data);
                                                        //         setLoading(false);
                                                        //         setShowMatchTimeOverlay(false);
                                                        //         onModalScoreVisible(false);
                                                        //     })
                                                        //     .catch((error) => {
                                                        //         console.log(error);
                                                        //         setLoading(false);
                                                        //         message.error(JSON.stringify(error));
                                                        //     });

                                                        actions.setSubmitting(false);
                                                        actions.resetForm();
                                                    }}
                                                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                                                        console.log(values);
                                                        return (
                                                            <Form onSubmit={handleSubmit} layout="vertical">
                                                                <RoundTimeEntry
                                                                    name="scheduled_time"
                                                                    onOk={onChangeEntry}
                                                                    round={currentRound}
                                                                    match={currentMatch}
                                                                    theme="dark"
                                                                    values={values}
                                                                />

                                                                <SubmitButton type="primary" size="large" disabled={loading === true ? true : false}>
                                                                    {loading === true ? 'Saving...' : 'OK'}
                                                                </SubmitButton>
                                                                <Button
                                                                    type="default"
                                                                    ghost
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setShowMatchTimeOverlay(false);
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Form>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {showFargoColumnCharts === true && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: 'rgba(0,0,0,0.8)',
                                                zIndex: 10,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Title level={4} style={{ marginTop: 0, marginBottom: 10, color: '#fff' }}>
                                                    Select Fargo{' '}
                                                    {tournament.handicap_format === 'fargo_hot_column'
                                                        ? 'hot column'
                                                        : tournament.handicap_format === 'fargo_medium_column'
                                                        ? 'medium column'
                                                        : tournament.handicap_format === 'fargo_mild_column'
                                                        ? 'mild column'
                                                        : ''}{' '}
                                                    race
                                                </Title>
                                                <div>
                                                    <Button
                                                        type={selectedFargoRace === 2 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(2);
                                                            setSelectedFargoRace(2);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R2
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 3 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(3);
                                                            setSelectedFargoRace(3);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R3
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 4 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(4);
                                                            setSelectedFargoRace(4);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R4
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 5 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(5);
                                                            setSelectedFargoRace(5);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R5
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 6 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(6);
                                                            setSelectedFargoRace(6);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R6
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 7 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(7);
                                                            setSelectedFargoRace(7);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R7
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 9 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(9);
                                                            setSelectedFargoRace(9);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R9
                                                    </Button>
                                                    <Button
                                                        type={selectedFargoRace === 11 ? 'primary' : `ghost`}
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            getFargoRaces(11);
                                                            setSelectedFargoRace(11);
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        R11
                                                    </Button>
                                                </div>
                                                <div style={{ marginTop: 15 }}>
                                                    <Button
                                                        type="secondary"
                                                        size="large"
                                                        onClick={() => {
                                                            setShowFargoColumnCharts(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <Formik
                                        initialValues={
                                            currentMatch
                                                ? {
                                                      challenger1_id: currentMatch.challenger1_id,
                                                      challenger1_name: currentMatch.challenger1_name,
                                                      challenger1_race_to: getChallengerRaceTo(currentMatch, 'challenger1'),
                                                      challenger1_score: currentMatch.challenger1_score || 0,
                                                      challenger2_id: currentMatch.challenger2_id,
                                                      challenger2_name: currentMatch.challenger2_name,
                                                      challenger2_race_to: getChallengerRaceTo(currentMatch, 'challenger2'),
                                                      challenger2_score: currentMatch.challenger2_score || 0,
                                                      table_id:
                                                          currentMatch.table_id && currentMatch.status !== 'COMPLETED' ? currentMatch.table_id : undefined,
                                                      scheduled_table_id: currentMatch.scheduled_table_id || undefined,
                                                      start_time: currentMatch.start_time || null,
                                                      priority: currentMatch.priority || null,
                                                  }
                                                : {
                                                      challenger1_id: null,
                                                      challenger1_name: '',
                                                      challenger1_race_to: tournament.winners_race_to,
                                                      challenger1_score: 0,
                                                      challenger2_id: null,
                                                      challenger2_name: '',
                                                      challenger2_race_to: tournament.winners_race_to,
                                                      challenger2_score: 0,
                                                      table_id: undefined,
                                                      scheduled_table_id: undefined,
                                                      start_time: null,
                                                      priority: null,
                                                  }
                                        }
                                        ref={formRef}
                                        enableReinitialize
                                        validationSchema={MatchSchema}
                                        onSubmit={(values, actions) => {
                                            console.log(values);
                                            // actions.setSubmitting(false);
                                            // actions.resetForm();
                                            handleSubmit(values, updateTournamentBracket, advanceTournamentBracket);
                                        }}
                                        render={({ values, errors, touched }) => (
                                            <Form layout="vertical">
                                                <table style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ paddingBottom: 5 }}>Player</th>
                                                            <th style={{ textAlign: 'left' }}>Race To</th>
                                                            <th style={{ textAlign: 'left' }}>Score</th>
                                                            {/* <th /> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '100%' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {currentMatch.challenger1_is_winner === true ||
                                                                    currentMatch.is_split === true ||
                                                                    challenger1Result === 'winner' ||
                                                                    challenger2Result === 'forfeit' ||
                                                                    challenger2Result === 'withdraw' ||
                                                                    challenger1Result === 'split' ||
                                                                    values.challenger1_score === values.challenger1_race_to ? (
                                                                        <span style={{ marginRight: 5 }}>
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        currentMatch.is_split === true || challenger1Result === 'split'
                                                                                            ? '#2196f3'
                                                                                            : '#87d068',
                                                                                }}
                                                                            >
                                                                                {currentMatch.is_split === true || challenger1Result === 'split' ? 'S' : 'W'}
                                                                            </Avatar>
                                                                        </span>
                                                                    ) : (
                                                                        ((currentMatch.status === 'COMPLETED' &&
                                                                            currentMatch.challenger1_is_winner === false) ||
                                                                            challenger2Result === 'winner' ||
                                                                            challenger1Result === 'forfeit' ||
                                                                            challenger1Result === 'withdraw' ||
                                                                            challenger2Result === 'split' ||
                                                                            values.challenger2_score === values.challenger2_race_to) && (
                                                                            <span style={{ marginRight: 5 }}>
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: challenger2Result === 'split' ? '#2196f3' : '#f5222d',
                                                                                    }}
                                                                                >
                                                                                    {currentMatch.challenger1_is_forfeit || challenger1Result === 'forfeit'
                                                                                        ? 'FF'
                                                                                        : currentMatch.challenger1_is_withdraw === true ||
                                                                                          challenger1Result === 'withdraw'
                                                                                        ? 'WD'
                                                                                        : challenger2Result === 'split'
                                                                                        ? 'S'
                                                                                        : 'L'}
                                                                                </Avatar>
                                                                            </span>
                                                                        )
                                                                    )}
                                                                    <FormItem name="start_time">
                                                                        <Input name="start_time" type="hidden" />
                                                                    </FormItem>
                                                                    <FormItem name="challenger1_id">
                                                                        <Input name="challenger1_id" type="hidden" />
                                                                    </FormItem>
                                                                    <FormItem
                                                                        name="challenger1_name"
                                                                        // required
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <AutoComplete
                                                                            dataSource={players1}
                                                                            style={{ width: '100%' }}
                                                                            onSelect={onSelectPlayer1}
                                                                            onSearch={onSearch1}
                                                                            name="challenger1_name"
                                                                            placeholder="Player 1 Name"
                                                                            size="large"
                                                                            // allowClear
                                                                            // required
                                                                            optionFilterProp="children"
                                                                            filterOption={(inputValue, option) => {
                                                                                return (
                                                                                    option.props.children &&
                                                                                    option.props.children
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                        .indexOf(inputValue.toLowerCase()) >= 0
                                                                                );
                                                                            }}
                                                                        >
                                                                            {players1 &&
                                                                                players1.length > 0 &&
                                                                                players1.map((item, index) => {
                                                                                    return (
                                                                                        <Option key={index} value={item.id.toString()}>
                                                                                            {item.name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                        </AutoComplete>
                                                                    </FormItem>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: 'center',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}
                                                            >
                                                                <FormItem name="challenger1_race_to">
                                                                    <InputNumber
                                                                        name="challenger1_race_to"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // max={getChallengerRaceTo(
                                                                        // 	currentMatch,
                                                                        // 	'challenger1'
                                                                        // )}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}
                                                                        size="large"
                                                                        // disabled
                                                                        style={{ width: 80 }}
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <FormItem name="challenger1_score">
                                                                    <InputNumber
                                                                        name="challenger1_score"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        max={values.challenger1_race_to}
                                                                        defaultValue={0}
                                                                        // value={score}
                                                                        // onChange={onsetChange}
                                                                        onFocus={handleFocus}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}
                                                                        style={{
                                                                            width: 80,
                                                                            backgroundColor: theme && theme.name === 'dark' ? '#093761' : '#bfe0ff',
                                                                        }}
                                                                        size="large"
                                                                        disabled={
                                                                            challenger1Result === 'forfeit' ||
                                                                            challenger2Result === 'forfeit' ||
                                                                            challenger1Result === 'withdraw' ||
                                                                            challenger2Result === 'withdraw'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            {/* <td>
															<div style={{ display: 'flex', marginLeft: 5 }}>
																<Button type="ghost" size="large">
																	W
																</Button>
																<Button type="danger" ghost size="large">
																	F
																</Button>
															</div>
														</td> */}
                                                            <td>
                                                                <Dropdown
                                                                    overlay={actionsMenuTop}
                                                                    trigger={['click']}
                                                                    disabled={challenger2Result ? true : false}
                                                                >
                                                                    <Button size="large" style={{ marginLeft: 5 }}>
                                                                        {challenger1Result ? _.capitalize(challenger1Result) : 'Actions'} <Icon type="down" />
                                                                    </Button>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
														<td colSpan={4}>
															<FormItem name="challenger1_result">
																<Radio.Group name="challenger1_result">
																	<Radio value="winner">Winner</Radio>
																	<Radio value="forfeit">Forfeit</Radio>
																</Radio.Group>
															</FormItem>
														</td>
													</tr> */}
                                                        <tr>
                                                            <td style={{ width: '100%' }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {currentMatch.challenger2_is_winner === true ||
                                                                    currentMatch.is_split === true ||
                                                                    challenger2Result === 'winner' ||
                                                                    challenger1Result === 'forfeit' ||
                                                                    challenger1Result === 'withdraw' ||
                                                                    challenger2Result === 'split' ||
                                                                    values.challenger2_score === values.challenger2_race_to ? (
                                                                        <span style={{ marginRight: 5 }}>
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        currentMatch.is_split === true || challenger2Result === 'split'
                                                                                            ? '#2196f3'
                                                                                            : '#87d068',
                                                                                }}
                                                                            >
                                                                                {currentMatch.is_split === true || challenger2Result === 'split' ? 'S' : 'W'}
                                                                            </Avatar>
                                                                        </span>
                                                                    ) : (
                                                                        ((currentMatch.status === 'COMPLETED' &&
                                                                            currentMatch.challenger2_is_winner === false) ||
                                                                            challenger1Result === 'winner' ||
                                                                            challenger2Result === 'forfeit' ||
                                                                            challenger2Result === 'withdraw' ||
                                                                            challenger1Result === 'split' ||
                                                                            values.challenger1_score === values.challenger1_race_to) && (
                                                                            <span style={{ marginRight: 5 }}>
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: challenger1Result === 'split' ? '#2196f3' : '#f5222d',
                                                                                    }}
                                                                                >
                                                                                    {currentMatch.challenger2_is_forfeit || challenger2Result === 'forfeit'
                                                                                        ? 'FF'
                                                                                        : currentMatch.challenger2_is_withdraw === true ||
                                                                                          challenger2Result === 'withdraw'
                                                                                        ? 'WD'
                                                                                        : challenger1Result === 'split'
                                                                                        ? 'S'
                                                                                        : 'L'}
                                                                                </Avatar>
                                                                            </span>
                                                                        )
                                                                    )}
                                                                    <FormItem name="challenger2_id">
                                                                        <Input name="challenger2_id" type="hidden" />
                                                                    </FormItem>
                                                                    <FormItem
                                                                        name="challenger2_name"
                                                                        // required
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <AutoComplete
                                                                            dataSource={players2}
                                                                            style={{ width: '100%' }}
                                                                            onSelect={onSelectPlayer2}
                                                                            onSearch={onSearch2}
                                                                            name="challenger2_name"
                                                                            placeholder="Player 2 Name"
                                                                            size="large"
                                                                            // allowClear
                                                                            // required
                                                                            optionFilterProp="children"
                                                                            filterOption={(inputValue, option) => {
                                                                                return (
                                                                                    option.props.children &&
                                                                                    option.props.children
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                        .indexOf(inputValue.toLowerCase()) >= 0
                                                                                );
                                                                            }}
                                                                        >
                                                                            {players2 &&
                                                                                players2.length > 0 &&
                                                                                players2.map((item, index) => {
                                                                                    return (
                                                                                        <Option key={index} value={item.id.toString()}>
                                                                                            {item.name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                        </AutoComplete>
                                                                    </FormItem>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <FormItem name="challenger2_race_to">
                                                                    <InputNumber
                                                                        name="challenger2_race_to"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        // max={getChallengerRaceTo(
                                                                        // 	currentMatch,
                                                                        // 	'challenger2'
                                                                        // )}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}

                                                                        size="large"
                                                                        // disabled
                                                                        style={{ width: 80 }}
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <FormItem name="challenger2_score">
                                                                    <InputNumber
                                                                        name="challenger2_score"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        defaultValue={0}
                                                                        max={values.challenger2_race_to}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}
                                                                        style={{
                                                                            width: 80,
                                                                            backgroundColor: theme && theme.name === 'dark' ? '#093761' : '#bfe0ff',
                                                                        }}
                                                                        size="large"
                                                                        disabled={
                                                                            challenger1Result === 'forfeit' ||
                                                                            challenger2Result === 'forfeit' ||
                                                                            challenger1Result === 'withdraw' ||
                                                                            challenger2Result === 'withdraw'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            {/* <td>
															<div style={{ display: 'flex', marginLeft: 5 }}>
																<Button type="ghost" size="large">
																	W
																</Button>
																<Button type="danger" ghost size="large">
																	F
																</Button>
															</div>
														</td> */}
                                                            <td>
                                                                <Dropdown
                                                                    overlay={actionsMenuBottom}
                                                                    trigger={['click']}
                                                                    disabled={challenger1Result ? true : false}
                                                                >
                                                                    <Button size="large" style={{ marginLeft: 5 }}>
                                                                        {challenger2Result ? _.capitalize(challenger2Result) : 'Actions'} <Icon type="down" />
                                                                    </Button>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
														<td colSpan={4}>
															<FormItem name="challenger2_result">
																<Radio.Group name="challenger2_result">
																	<Radio value="winner">Winner</Radio>
																	<Radio value="forfeit">Forfeit</Radio>
																</Radio.Group>
															</FormItem>
														</td>
													</tr> */}
                                                    </tbody>
                                                </table>

                                                {currentMatch.advance_to === null && (
                                                    <React.Fragment>
                                                        <br />
                                                        <Text style={{ fontWeight: 700 }}>
                                                            Assign open table{' '}
                                                            <Tooltip
                                                                placement="right"
                                                                title={
                                                                    <div>
                                                                        Assign any open table for this match immediately. This will override any scheduled table
                                                                        assignments.
                                                                    </div>
                                                                }
                                                            >
                                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                            </Tooltip>
                                                        </Text>
                                                        <FormItem name="table_id">
                                                            <Query
                                                                query={GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY}
                                                                fetchPolicy="no-cache"
                                                                // notifyOnNetworkStatusChange={true}
                                                                variables={{ tournament_id: tournament.id }}
                                                                onCompleted={(data) => {
                                                                    // console.log(data);
                                                                    setTables(data.pool_tables);
                                                                }}
                                                            >
                                                                {({ loading, error, data }) => {
                                                                    if (loading) return <div>Loading...</div>;
                                                                    if (error) return <div>Error: {error.message}</div>;
                                                                    if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                                                        return <div>No tables found</div>;
                                                                    }

                                                                    return (
                                                                        <Select
                                                                            name="table_id"
                                                                            defaultValue={currentMatch && currentMatch.table_id}
                                                                            // style={{ minWidth: 300 }}
                                                                            onChange={handleSelectTableChange}
                                                                            placeholder="Select table assignment"
                                                                            size="large"
                                                                            allowClear={currentMatch && currentMatch.table_id ? false : true}
                                                                        >
                                                                            <OptGroup label="TABLES">
                                                                                {data.pool_tables
                                                                                    .filter((item, index) => {
                                                                                        return item.status !== 'CLOSED';
                                                                                    })
                                                                                    .sort((a, b) => {
                                                                                        return a.label.localeCompare(b.label, undefined, {
                                                                                            numeric: true,
                                                                                            sensitivity: 'base',
                                                                                        });
                                                                                    })
                                                                                    .sort((a, b) => {
                                                                                        return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                                                    })
                                                                                    .map((item, index) => (
                                                                                        <Option
                                                                                            key={index + 1}
                                                                                            value={item.id}
                                                                                            disabled={item.status === 'IN_USE' || item.status === 'ASSIGNED'}
                                                                                        >
                                                                                            {item.label} - {item.status}{' '}
                                                                                            {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                                            {item.is_featured_table ? '(Featured Table)' : ''}
                                                                                        </Option>
                                                                                    ))}
                                                                            </OptGroup>
                                                                        </Select>
                                                                    );
                                                                }}
                                                            </Query>
                                                            {/* {tournament.event_id ? (
                                                        <Query
                                                            query={GET_EVENT_LIVE_TABLES_QUERY}
                                                            fetchPolicy="cache-and-network"
                                                            notifyOnNetworkStatusChange={true}
                                                            variables={{ event_id: tournament.event_id }}
                                                            onCompleted={(data, error, loading) => {
                                                                console.log(data);

                                                                let tables = [];

                                                                data &&
                                                                    data.tournaments &&
                                                                    data.tournaments.forEach((tournament) => {
                                                                        tournament.pool_tables &&
                                                                            tournament.pool_tables.forEach((table) => {
                                                                                if (table.tournament_match_table && table.tournament_match_table.length > 0) {
                                                                                    tables.push({
                                                                                        ...table,
                                                                                        tournament,
                                                                                    });
                                                                                }
                                                                            });
                                                                    });

                                                                setTables(tables);
                                                            }}
                                                        >
                                                            {({ loading, error, data }) => {
                                                                let tables = [];
                                                                const matches = [];
                                                                let tournamentData;

                                                                data &&
                                                                    data.tournaments &&
                                                                    data.tournaments.forEach((tournament) => {
                                                                        tournament.pool_tables &&
                                                                            tournament.pool_tables.forEach((table) => {
                                                                                if (table.tournament_match_table && table.tournament_match_table.length > 0) {
                                                                                    tables.push({
                                                                                        ...table,
                                                                                        tournament,
                                                                                    });
                                                                                    tournamentData = tournament;
                                                                                }

                                                                                if (table.tournament_match_table && table.tournament_match_table[0]) {
                                                                                    matches.push(table.tournament_match_table[0]);
                                                                                }
                                                                            });
                                                                    });

                                                                if (loading) return <div>Loading...</div>;
                                                                if (error) return <div>Error: {error.message}</div>;
                                                                if ((data && !tables) || (data && tables.length === 0)) {
                                                                    return <div>No tables found</div>;
                                                                }

                                                                return (
                                                                    <Select
                                                                        name="table_id"
                                                                        defaultValue={currentMatch && currentMatch.table_id}
                                                                        // style={{ minWidth: 300 }}
                                                                        onChange={handleSelectTableChange}
                                                                        placeholder="Select table assignment"
                                                                        size="large"
                                                                    >
                                                                        <OptGroup label="AVAILABLE">
                                                                            {tables
                                                                                .filter((item, index) => {
                                                                                    console.log(
                                                                                        tables.includes((table) => {
                                                                                            return table.slug === item.slug;
                                                                                        })
                                                                                    );
                                                                                    return (
                                                                                        item.status !== 'CLOSED' &&
                                                                                        tables.includes((table) => {
                                                                                            return table.slug === item.slug && item.status === 'IN_USE';
                                                                                        })
                                                                                    );
                                                                                })

                                                                                .sort((a, b) => {
                                                                                    return a.label.localeCompare(b.label, undefined, {
                                                                                        numeric: true,
                                                                                        sensitivity: 'base',
                                                                                    });
                                                                                })
                                                                                .sort((a, b) => {
                                                                                    return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                                                })
                                                                                .map((item, index) => (
                                                                                    <Option
                                                                                        key={index + 1}
                                                                                        value={item.id}
                                                                                        disabled={
                                                                                            item.status === 'IN_USE' ||
                                                                                            item.status === 'ASSIGNED' ||
                                                                                            (item.status === 'OPEN' && item.tournament.id !== tournament.id)
                                                                                        }
                                                                                    >
                                                                                        {item.label} - {item.status} - {item.tournament && item.tournament.name}{' '}
                                                                                        {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                                        {item.is_featured_table ? '(Featured Table)' : ''}
                                                                                    </Option>
                                                                                ))}
                                                                        </OptGroup>

                                                                        <OptGroup label="NOT AVAILABLE">
                                                                            {tables
                                                                                .filter((item, index) => {
                                                                                    return item.status !== 'CLOSED' && item.status === 'IN_USE';
                                                                                })

                                                                                .sort((a, b) => {
                                                                                    return a.label.localeCompare(b.label, undefined, {
                                                                                        numeric: true,
                                                                                        sensitivity: 'base',
                                                                                    });
                                                                                })
                                                                                .sort((a, b) => {
                                                                                    return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                                                })
                                                                                .map((item, index) => (
                                                                                    <Option
                                                                                        key={index + 1}
                                                                                        value={item.id}
                                                                                        disabled={
                                                                                            item.status === 'IN_USE' ||
                                                                                            item.status === 'ASSIGNED' ||
                                                                                            (item.status === 'OPEN' && item.tournament.id !== tournament.id)
                                                                                        }
                                                                                    >
                                                                                        {item.label} - {item.status} - {item.tournament && item.tournament.name}{' '}
                                                                                        {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                                        {item.is_featured_table ? '(Featured Table)' : ''}
                                                                                    </Option>
                                                                                ))}
                                                                        </OptGroup>
                                                                    </Select>
                                                                );
                                                            }}
                                                        </Query>
                                                    ) : (
                                                        <Query
                                                            query={GET_ALL_TOURNAMENT_TABLES_QUERY}
                                                            fetchPolicy="no-cache"
                                                            // notifyOnNetworkStatusChange={true}
                                                            variables={{ tournament_id: tournament.id }}
                                                            onCompleted={(data) => {
                                                                // console.log(data);
                                                                setTables(data.pool_tables);
                                                            }}
                                                        >
                                                            {({ loading, error, data }) => {
                                                                if (loading) return <div>Loading...</div>;
                                                                if (error) return <div>Error: {error.message}</div>;
                                                                if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                                                    return <div>No tables found</div>;
                                                                }

                                                                return (
                                                                    <Select
                                                                        name="table_id"
                                                                        defaultValue={currentMatch && currentMatch.table_id}
                                                                        // style={{ minWidth: 300 }}
                                                                        onChange={handleSelectTableChange}
                                                                        placeholder="Select table assignment"
                                                                        size="large"
                                                                    >
                                                                        <OptGroup label="TABLES">
                                                                            {data.pool_tables
                                                                                .filter((item, index) => {
                                                                                    return item.status !== 'CLOSED';
                                                                                })
                                                                                .sort((a, b) => {
                                                                                    return a.label.localeCompare(b.label, undefined, {
                                                                                        numeric: true,
                                                                                        sensitivity: 'base',
                                                                                    });
                                                                                })
                                                                                .sort((a, b) => {
                                                                                    return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                                                })
                                                                                .map((item, index) => (
                                                                                    <Option
                                                                                        key={index + 1}
                                                                                        value={item.id}
                                                                                        disabled={item.status === 'IN_USE' || item.status === 'ASSIGNED'}
                                                                                    >
                                                                                        {item.label} - {item.status}{' '}
                                                                                        {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                                        {item.is_featured_table ? '(Featured Table)' : ''}
                                                                                    </Option>
                                                                                ))}
                                                                        </OptGroup>
                                                                    </Select>
                                                                );
                                                            }}
                                                        </Query>
                                                    )} */}

                                                            {currentMatch.table_id !== null && currentMatch.status !== 'COMPLETED' && (
                                                                <Popconfirm
                                                                    title="Are you sure you want to remove this table assignment?"
                                                                    onConfirm={() => {
                                                                        confirmRemoveTableAssignment(updateTournamentBracket);
                                                                    }}
                                                                    onCancel={cancelRemoveTableAssignment}
                                                                    visible={confirmTablePopupVisible}
                                                                    onVisibleChange={handleRemoveTableVisibleChange}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    placement="topLeft"
                                                                >
                                                                    <Button type="link" className="remove-table-assignment-btn">
                                                                        <Icon type="close-circle" /> Remove table assignment
                                                                    </Button>
                                                                </Popconfirm>
                                                            )}
                                                        </FormItem>

                                                        {values && !values.table_id && (
                                                            <React.Fragment>
                                                                <Text style={{ fontWeight: 700 }}>
                                                                    Schedule future table{' '}
                                                                    <Tooltip
                                                                        placement="right"
                                                                        title={
                                                                            <div>
                                                                                Schedule any table for this match at a future date and time. Assigning an open
                                                                                table will override this.
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                    </Tooltip>
                                                                </Text>
                                                                <FormItem name="scheduled_table_id">
                                                                    <Query
                                                                        query={GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY}
                                                                        fetchPolicy="no-cache"
                                                                        // notifyOnNetworkStatusChange={true}
                                                                        variables={{ tournament_id: tournament.id }}
                                                                        onCompleted={(data) => {
                                                                            // console.log(data);
                                                                            setTables(data.pool_tables);
                                                                        }}
                                                                    >
                                                                        {({ loading, error, data }) => {
                                                                            if (loading) return <div>Loading...</div>;
                                                                            if (error) return <div>Error: {error.message}</div>;
                                                                            if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                                                                return <div>No tables found</div>;
                                                                            }

                                                                            return (
                                                                                <Select
                                                                                    name="scheduled_table_id"
                                                                                    defaultValue={currentMatch && currentMatch.scheduled_table_id}
                                                                                    // style={{ minWidth: 300 }}
                                                                                    onChange={handleSelectTableChange}
                                                                                    placeholder="Select future table assignment"
                                                                                    size="large"
                                                                                    allowClear={currentMatch && currentMatch.scheduled_table_id ? false : true}
                                                                                >
                                                                                    <OptGroup label="TABLES">
                                                                                        {data.pool_tables
                                                                                            .filter((item, index) => {
                                                                                                return item.status !== 'CLOSED';
                                                                                            })
                                                                                            .sort((a, b) => {
                                                                                                return a.label.localeCompare(b.label, undefined, {
                                                                                                    numeric: true,
                                                                                                    sensitivity: 'base',
                                                                                                });
                                                                                            })

                                                                                            .map((item, index) => (
                                                                                                <Option key={index + 1} value={item.id}>
                                                                                                    {item.label}{' '}
                                                                                                    {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                                                    {item.is_featured_table ? '(Featured Table)' : ''}
                                                                                                </Option>
                                                                                            ))}
                                                                                    </OptGroup>
                                                                                </Select>
                                                                            );
                                                                        }}
                                                                    </Query>
                                                                </FormItem>

                                                                {values && values.scheduled_table_id && (
                                                                    <React.Fragment>
                                                                        <Text style={{ fontWeight: 700 }}>
                                                                            Set priority order
                                                                            <Tooltip
                                                                                placement="right"
                                                                                title={
                                                                                    <div>
                                                                                        Optionally set a priority order when scheduling multiple matches to a
                                                                                        single table. This is useful so players can know what table they will
                                                                                        play on and match order they will follow even if there is no scheduled
                                                                                        time. "Follows match #" will be displayed if no match time is set.
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                            </Tooltip>
                                                                        </Text>
                                                                        <FormItem name="priority" hasFeedback showValidateSuccess>
                                                                            <InputNumber
                                                                                name="priority"
                                                                                placeholder="Priority order"
                                                                                size="large"
                                                                                style={{ width: 200 }}
                                                                            />
                                                                        </FormItem>
                                                                    </React.Fragment>
                                                                )}

                                                                {currentMatch.scheduled_table_id !== null && currentMatch.status !== 'COMPLETED' && (
                                                                    <Popconfirm
                                                                        title="Are you sure you want to remove this scheduled table assignment?"
                                                                        onConfirm={() => {
                                                                            confirmRemoveScheduledTableAssignment(updateTournamentBracket);
                                                                        }}
                                                                        onCancel={cancelRemoveScheduledTableAssignment}
                                                                        visible={confirmScheduledTablePopupVisible}
                                                                        onVisibleChange={handleRemoveScheduledTableVisibleChange}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                        placement="topLeft"
                                                                    >
                                                                        <Button type="link" className="remove-table-assignment-btn">
                                                                            <Icon type="close-circle" /> Remove scheduled table assignment
                                                                        </Button>
                                                                    </Popconfirm>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                <div style={{ marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    {/* <SubmitButton type="primary" size="large">
														{selectedTable ? 'Update Match' : 'Save Match'}
													</SubmitButton> */}
                                                    <div>
                                                        <Button
                                                            type="primary"
                                                            disabled={Object.keys(errors).length > 0 ? true : false}
                                                            loading={loading}
                                                            className={
                                                                currentMatch.status && currentMatch.status === 'PENDING'
                                                                    ? 'approve-match-btn'
                                                                    : 'update-match-btn'
                                                            }
                                                            size="large"
                                                            onClick={() => {
                                                                if (currentMatch.status && currentMatch.status === 'COMPLETED') {
                                                                    setConfirmPopupVisible(true);
                                                                } else {
                                                                    formRef.current.handleSubmit();
                                                                }
                                                            }}
                                                        >
                                                            {currentMatch.status && currentMatch.status === 'PENDING'
                                                                ? 'Approve Scores'
                                                                : selectedTable
                                                                ? 'Update Match'
                                                                : loading
                                                                ? 'Saving...'
                                                                : 'Save Match'}
                                                        </Button>
                                                        <Button type="default" size="large" onClick={handleScoreModalCancel}>
                                                            Cancel
                                                        </Button>

                                                        {tournament.rating_system === 'florida_pool_tour' && (
                                                            <Button
                                                                type="danger"
                                                                size="large"
                                                                ghost
                                                                onClick={() => {
                                                                    if (currentMatch.challenger1 && currentMatch.challenger2) {
                                                                        getHandicapRace(currentMatch.challenger1, currentMatch.challenger2, currentMatch);
                                                                    } else {
                                                                        message.warning('This match needs two players to get the handicapped race.');
                                                                    }
                                                                }}
                                                            >
                                                                Get Handicapped Race
                                                            </Button>
                                                        )}

                                                        {tournament.rating_system === 'danny_ks' && (
                                                            <Button
                                                                type="danger"
                                                                size="large"
                                                                ghost
                                                                onClick={() => {
                                                                    if (currentMatch.challenger1 && currentMatch.challenger2) {
                                                                        getDKHandicapRace(currentMatch.challenger1, currentMatch.challenger2, currentMatch);
                                                                    } else {
                                                                        message.warning('This match needs two players to get the handicapped race.');
                                                                    }
                                                                }}
                                                            >
                                                                Get Handicapped Race
                                                            </Button>
                                                        )}

                                                        {(tournament.tournament_format === 'fargo_rated' ||
                                                            tournament.tournament_format === 'usapl_fargo_rated') && (
                                                            <React.Fragment>
                                                                {currentMatch.challenger1 && currentMatch.challenger2 ? (
                                                                    <Button
                                                                        type="danger"
                                                                        size="large"
                                                                        ghost
                                                                        onClick={() => {
                                                                            if (currentMatch.challenger1 && currentMatch.challenger2) {
                                                                                if (tournament.tournament_format === 'usapl_fargo_rated') {
                                                                                    getUSAPLRaces();
                                                                                } else {
                                                                                    setShowFargoColumnCharts(true);
                                                                                    // getFargoRaces();
                                                                                }
                                                                            } else {
                                                                                message.warning(
                                                                                    'This match needs two players to get the recommended Fargo Race.'
                                                                                );
                                                                            }
                                                                        }}
                                                                        loading={fargoLoading}
                                                                    >
                                                                        {fargoLoading === true
                                                                            ? 'Loading...'
                                                                            : tournament.tournament_format === 'usapl_fargo_rated'
                                                                            ? 'Get USAPL Race'
                                                                            : 'Get Fargo Race'}
                                                                    </Button>
                                                                ) : (
                                                                    <Tooltip
                                                                        placement="right"
                                                                        title="This match needs two players to get the recommended Fargo Race."
                                                                    >
                                                                        <Button
                                                                            type="danger"
                                                                            size="large"
                                                                            ghost
                                                                            onClick={() => {
                                                                                if (tournament.tournament_format === 'usapl_fargo_rated') {
                                                                                    getUSAPLRaces();
                                                                                } else {
                                                                                    setShowFargoColumnCharts(true);
                                                                                    // getFargoRaces();
                                                                                }
                                                                            }}
                                                                            loading={fargoLoading}
                                                                            disabled={currentMatch.challenger1 && currentMatch.challenger2 ? false : true}
                                                                            style={{ marginLeft: 5 }}
                                                                        >
                                                                            {fargoLoading === true
                                                                                ? 'Loading...'
                                                                                : tournament.tournament_format === 'usapl_fargo_rated'
                                                                                ? 'Get USAPL Race'
                                                                                : 'Get Fargo Race'}
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>

                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {currentMatch && currentMatch.hash_id && (
                                                            <Tooltip placement="left" title="Live Scoring Link">
                                                                <Button
                                                                    type="text"
                                                                    size="large"
                                                                    onClick={() => {
                                                                        const liveScoringLink = `https://digitalpool.com/m/${currentMatch.hash_id}`;
                                                                        window.open(liveScoringLink, '_blank');
                                                                    }}
                                                                >
                                                                    <LinkOutlined />
                                                                </Button>
                                                            </Tooltip>
                                                        )}

                                                        {tournament.use_text_messaging !== false &&
                                                            currentMatch.table_id &&
                                                            currentMatch.status !== 'COMPLETED' && (
                                                                <Tooltip placement="left" title="Re-send SMS Notification">
                                                                    <Button type="text" size="large" onClick={showConfirmResendNotification}>
                                                                        <Icon type="bell" />
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        {/* <a
                                                            href={`/tournaments/${tournament.slug}/matches/${currentMatch.hash_id}/scoresheet`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Button>Print</Button>
                                                        </a> */}
                                                        <PrintComponent
                                                            {...props}
                                                            currentMatch={{
                                                                ...currentMatch,
                                                                challenger1_race_to: values.challenger1_race_to,
                                                                challenger2_race_to: values.challenger2_race_to,
                                                            }}
                                                            tournament={tournament}
                                                        />
                                                    </div>

                                                    {/* <Tooltip placement="left" title="Copy live scoring code">
                                                        <Button
                                                            type="link"
                                                            size="large"
                                                            onClick={() => {
                                                                const link = `https://digitalpool.com/${tournament.slug}/${currentMatch.hash_id}`;
                                                                navigator.clipboard.writeText(link);
                                                                message.success(`Live scoring link copied to clipboard.`);
                                                            }}
                                                        >
                                                            <LinkOutlined /> {currentMatch.hash_id}
                                                        </Button>
                                                    </Tooltip> */}

                                                    {/* {Object.keys(errors).length > 0 && (
													<div>
														<Text style={styles.error}>
															Validation errors: ({Object.keys(errors).length})
														</Text>
														<ul style={{ margin: 0 }}>
															{Object.entries(errors).map(([ key, value ]) => {
																return (
																	<li key={key} style={styles.error}>
																		<Text style={styles.error}>{value}</Text>
																	</li>
																);
															})}
														</ul>
													</div>
												)} */}
                                                    {/* <FormikDebug style={{ maxWidth: 400 }} /> */}
                                                </div>
                                                <Popconfirm
                                                    title="Are you sure you want to edit this completed match?"
                                                    onConfirm={() => {
                                                        confirmEditCompletedMatch(formRef);
                                                    }}
                                                    onCancel={cancelEditCompletedMatch}
                                                    visible={confirmPopupVisible}
                                                    onVisibleChange={handleVisibleChange}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="topLeft"
                                                />
                                            </Form>
                                        )}
                                    />
                                </Modal>

                                <Modal
                                    title="Confirm Tournament Winner"
                                    visible={confirmEndTournamentPopupVisible}
                                    onOk={() => {
                                        completeTournament(winner);
                                    }}
                                    onCancel={cancelEndTournament}
                                    okText="Yes"
                                    cancelText="No"
                                    centered
                                    transitionName="fade"
                                    maskTransitionName="none"
                                    destroyOnClose={true}
                                >
                                    <p>Did {winner && (winner.challenger1_name || winner.challenger2_name)} win the tournament?</p>
                                </Modal>
                            </React.Fragment>
                        );
                    }}
                </Mutation>
            )}
        </Mutation>
    );
}

const MemoizedMatchOverlay = React.memo(MatchOverlay);
export default MemoizedMatchOverlay;
