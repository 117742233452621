import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import EventCountdownTimer from './EventCountdownTimer';
import CircularLoader from '../../../components/CircularLoader';
import { GET_EVENT_LIVE_TABLE_SHOT_CLOCK_QUERY } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SHOT_CLOCK_SUBSCRIPTION } from '../data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import qs from 'query-string';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function EventTableTVShotClockDisplay(props) {
    const { authState, match, client } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const eventSlug = match.params.slug;
    const tableSlug = match.params.table;
    const [newData, setNewData] = useState();
    const [key, setKey] = useState(0);

    async function subscribeToNewChanges(subscribeToMore) {
        console.log('subscribe to new changes');
        const unsubscribe = subscribeToMore({
            document: GET_EVENT_LIVE_TABLE_SHOT_CLOCK_SUBSCRIPTION,
            variables: {
                event_slug: eventSlug,
                table_slug: tableSlug,
            },
            onSubscriptionData: (data) => {
                console.log('subscription data', data);
                // const result = data && data.subscriptionData && data.subscriptionData;
                // const command = result.command;
                // if (command && command === 'RESTART') {
                //     setKey((prevKey) => prevKey + 1);
                // }

                // setNewData(true);
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newEvent = subscriptionData.data.events;
                let updated;
                if (prev) {
                    // setKey((prevKey) => prevKey + 1);
                    updated = Object.assign({}, prev, {
                        events: [...newEvent],
                    });
                } else {
                    // setKey((prevKey) => prevKey + 1);
                    updated = Object.assign({}, prev, {
                        events: [...newEvent],
                    });
                }
                return updated;
            },
        });

        return () => unsubscribe();
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                // height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
                height: 'auto',
                minHeight: '100%',
            }}
        >
            <Query
                query={GET_EVENT_LIVE_TABLE_SHOT_CLOCK_QUERY}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ event_slug: eventSlug, table_slug: tableSlug }}
                onCompleted={(data, error, loading) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                    if (loading)
                        return (
                            <div style={styles.container}>
                                <CircularLoader /> <span style={{ marginLeft: 10 }}>Loading...</span>
                            </div>
                        );
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    console.log(data);
                    let tableData;
                    let matchData;
                    let tournamentData;
                    let shotClockData;
                    let tables = [];

                    data &&
                        data.events &&
                        data.events.forEach((event) => {
                            const eventMatches = event.matches;
                            const eventTournaments = event.tournaments;
                            const eventVenueTables = event.venue && event.venue.pool_tables;

                            eventMatches &&
                                eventMatches.forEach((match) => {
                                    if (match.pool_table && match.pool_table.matches) {
                                        match.pool_table.matches.forEach((item) => {
                                            matchData = item;
                                            tableData = match.pool_table;
                                            shotClockData = item.match_shot_clock[0];
                                        });
                                    }
                                });

                            eventTournaments &&
                                eventTournaments.forEach((tournament) => {
                                    if (tournament.pool_tables) {
                                        tournament.pool_tables.forEach((table) => {
                                            if (table.tournament_match_table && table.tournament_match_table[0]) {
                                                matchData = table.tournament_match_table[0];
                                                tableData = table;
                                                tournamentData = tournament;
                                                shotClockData = matchData.tournament_match_shot_clock[0];
                                            }
                                        });
                                    }
                                });

                            eventVenueTables &&
                                eventVenueTables.forEach((table) => {
                                    if (table) {
                                        tables.push(table);
                                    }
                                });
                        });

                    console.log('shot clock data', shotClockData);

                    const duration = shotClockData ? shotClockData.duration / 1000 : 0;
                    const textColor = shotClockData && shotClockData.text_color;
                    const remainingTime = shotClockData ? shotClockData.remaining_time / 1000 : 0;
                    const command = shotClockData && shotClockData.command;

                    // subscribeToNewChanges(subscribeToMore);

                    if (data && !tableData && !tables.length) {
                        return (
                            <div>
                                <Title
                                    level={4}
                                    style={{
                                        marginLeft: 20,
                                        marginTop: 10,
                                        color: '#fff',
                                        letterSpacing: 0,
                                        textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                    }}
                                >
                                    Waiting for match on {tableSlug}
                                </Title>
                            </div>
                        );
                    }

                    if (tournamentData) {
                        return (
                            <EventCountdownTimer
                                currentKey={key}
                                command={command}
                                duration={duration}
                                initialRemainingTime={remainingTime}
                                colorsTime={[60, 30, 15, 10, 0]}
                                colors={['#00aa00', '#00aa00', '#F7B801', '#A30000', '#A30000']}
                                textColor="FFF"
                                shouldRepeat={false}
                                size={700}
                                fontSize={300}
                                strokeWidth={12}
                                trailStrokeWidth={12}
                                subscribeToMore={subscribeToMore}
                                subscribeToNewData={() => {
                                    subscribeToMore({
                                        document: GET_EVENT_LIVE_TABLE_SHOT_CLOCK_SUBSCRIPTION,
                                        variables: { event_slug: eventSlug, table_slug: tableSlug },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            if (!subscriptionData.data) return prev;
                                            const newEvent = subscriptionData.data.events;
                                            let updated;

                                            console.log({
                                                prev,
                                                newEvent,
                                            });

                                            if (prev) {
                                                // setKey((prevKey) => prevKey + 1);
                                                updated = Object.assign({}, prev, {
                                                    events: [...newEvent],
                                                });
                                                setTimeout(() => {
                                                    setKey((prevKey) => prevKey + 1);
                                                }, 100);
                                            } else {
                                                // setKey((prevKey) => prevKey + 1);
                                                updated = Object.assign({}, prev, {
                                                    events: [...newEvent],
                                                });
                                                setTimeout(() => {
                                                    setKey((prevKey) => prevKey + 1);
                                                }, 100);
                                            }

                                            return updated;
                                        },
                                    });
                                }}
                            />
                        );
                    } else {
                        return (
                            <EventCountdownTimer
                                currentKey={key}
                                command={command}
                                duration={duration}
                                initialRemainingTime={remainingTime}
                                colorsTime={[60, 30, 15, 10, 0]}
                                colors={['#00aa00', '#00aa00', '#F7B801', '#A30000', '#A30000']}
                                textColor="FFF"
                                shouldRepeat={false}
                                size={700}
                                fontSize={300}
                                strokeWidth={8}
                                trailStrokeWidth={8}
                                subscribeToMore={subscribeToMore}
                                subscribeToNewData={() => {
                                    subscribeToMore({
                                        document: GET_EVENT_LIVE_TABLE_SHOT_CLOCK_SUBSCRIPTION,
                                        variables: { event_slug: eventSlug, table_slug: tableSlug },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            if (!subscriptionData.data) return prev;
                                            const newEvent = subscriptionData.data.events;
                                            let updated;

                                            if (prev) {
                                                setKey((prevKey) => prevKey + 1);
                                                updated = Object.assign({}, prev, {
                                                    events: [...newEvent],
                                                });
                                            } else {
                                                setKey((prevKey) => prevKey + 1);
                                                updated = Object.assign({}, prev, {
                                                    events: [...newEvent],
                                                });
                                            }

                                            return updated;
                                        },
                                    });
                                }}
                            />
                        );
                    }
                }}
            </Query>
        </div>
    );
}

export default withRouter(EventTableTVShotClockDisplay);
