import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Row, Col, Typography, Button } from 'antd';
import LMBasicSettingsForm from './LMBasicSettingsForm';
const { Text, Title } = Typography;

function LMBasicSettings(props) {
	return (
		<React.Fragment>
			<Title level={4}>{props.title}</Title>
			<LMBasicSettingsForm {...props} />
		</React.Fragment>
	);
}

export default withRouter(LMBasicSettings);
