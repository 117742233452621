import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Table, Typography, Tabs, List, Badge, Tag, Drawer, Empty, Modal, Button } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionContent from '../../../components/SectionContent';
import CircularLoader from '../../../components/CircularLoader';
import { GET_EVENT_STANDINGS_BY_SLUG } from '../data/queries';
import { getCountryName } from '../../../utils/getCountryName';
import { Query } from '@apollo/client/react/components';
import Flag from '../../../components/Flag';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const styles = {};

function EventStandings(props) {
    const { event, tournaments, authState } = props;

    function buildColumns(tournaments) {
        const columns = [
            {
                title: 'Place',
                dataIndex: 'place',
                key: 'place',
                defaultSortOrder: 'ascend',
                width: 100,
                sorter: (a, b) => {
                    return a.place - b.place;
                },
            },
            {
                title: 'Player Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    return a.name && a.name.localeCompare(b.name);
                },
                render: (text, record) => {
                    const countryName = record && record.country;

                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {countryName && <CircleFlag country={countryName} left={0} />}
                            <span style={{ marginLeft: countryName ? 10 : 0 }}>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: 'Total Points',
                dataIndex: 'points',
                key: 'points',
                sorter: (a, b) => {
                    return a.points - b.points;
                },
            },
            // {
            //     title: 'Skill Level',
            //     dataIndex: 'skillLevel',
            //     key: 'skillLevel',
            //     sorter: (a, b) => {
            //         return a.skillLevel - b.skillLevel;
            //     },
            // },

            {
                title: 'Events Played',
                dataIndex: 'stops',
                key: 'stops',
                width: 200,
                sorter: (a, b) => {
                    if (a.stops && b.stops) {
                        return a.stops.length - b.stops.length;
                    }
                },
                render: (text, record) => {
                    return <Text>{record.stops.length}</Text>;
                },
            },
        ];

        tournaments.forEach((tournament, index) => {
            columns.push({
                title: `${tournament.name} Points`,
                dataIndex: `points${index + 1}`,
                key: `points${index + 1}`,
                sorter: (a, b) => {
                    if (a.points && b.points) {
                        return a.points - b.points;
                    }
                },
                render: (text, record) => {
                    const points =
                        record.stops &&
                        record.stops.filter((item) => {
                            return item.stop === index + 1;
                        });

                    return <Text>{(points && points[0] && points[0].points) || '-'}</Text>;
                },
            });
        });

        return columns;
    }

    return (
        <SectionWrapper>
            <SectionContent padding="14px 40px">
                <Row gutter={24} justify="center" type="flex" id="table-scores">
                    <Col xs={24}>
                        <Query
                            query={GET_EVENT_STANDINGS_BY_SLUG}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ slug: event.slug }}
                            onCompleted={(data) => {
                                // var set = [];
                                // for (let i = 0; i < data.tournament_payouts.length; i++) {
                                // 	set.push(data.tournament_payouts[i]);
                                // }
                                // let formattedData = orderAndFormatData(set);
                                // setTableData(formattedData);
                            }}
                        >
                            {({ loading, error, data, refetch }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const eventData = data.events && data.events[0];

                                if (!eventData) {
                                    return <div style={styles.container}>Event not found.</div>;
                                }
                                const tournaments =
                                    eventData &&
                                    eventData.tournaments &&
                                    eventData.tournaments.map((tournament, index) => {
                                        return {
                                            ...tournament,
                                            num: index + 1,
                                        };
                                    });

                                console.log(tournaments);

                                let tournamentPlayers = [];

                                tournaments.forEach((tournament) => {
                                    tournament.tournament_players &&
                                        tournament.tournament_players.forEach((player) => {
                                            tournamentPlayers.push(player);
                                        });
                                });

                                const players = _.uniqBy(tournamentPlayers, 'phone_number');

                                const formattedPlayers = players
                                    .map((player) => {
                                        return {
                                            name: player.name,
                                            phone_number: player.phone_number,
                                        };
                                    })
                                    .filter((player) => {
                                        return player.phone_number !== null;
                                    });

                                console.log(formattedPlayers);
                                const columns = buildColumns(tournaments);

                                let points = {};
                                const points1 =
                                    tournaments &&
                                    tournaments[0] &&
                                    tournaments[0].tournament_points &&
                                    tournaments[0].tournament_points.forEach((item) => {
                                        points = { ...points, [item.place]: item.points };
                                    });
                                console.log(points1);
                                const tournamentPlayerPointsFlattened = [];

                                tournaments.forEach((tournament, index) => {
                                    tournament.tournament_players.map((player) => {
                                        tournamentPlayerPointsFlattened.push({
                                            stop: index + 1,
                                            tournament_name: tournament.name,
                                            id: player.id,
                                            name: player.name.replace(/\s+/g, ' ').trim(),
                                            skillLevel: player.skill_level,
                                            // name: player.name.replace(/\s+/g, '').toLowerCase(),
                                            country: player.country,
                                            place: player.place,
                                            points: points[player.place] || 0,
                                        });
                                    });
                                });

                                const result = _(tournamentPlayerPointsFlattened)
                                    .groupBy('name')
                                    .map((tournaments, id) => {
                                        return {
                                            stops: tournaments,
                                            id: tournaments[0].id,
                                            name: id,
                                            skillLevel: tournaments[0].skillLevel,
                                            country: tournaments[0].country,
                                            phone_number: tournaments[0].phone_number,
                                            points: _.sumBy(tournaments, 'points'),
                                        };
                                    })
                                    .value();

                                const sortedTotals = result
                                    .sort((a, b) => {
                                        return b.points - a.points;
                                    })
                                    .map((player, index) => {
                                        return {
                                            ...player,
                                            place: index + 1,
                                        };
                                    });

                                return (
                                    <Card title="Event Standings" bodyStyle={{ padding: 0 }}>
                                        <Table
                                            rowKey="place"
                                            pagination={false}
                                            className="match-table table-striped-rows"
                                            columns={columns}
                                            dataSource={sortedTotals}
                                            size="default"
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                            // pagination={{ pageSize: 25 }}
                                        />
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(EventStandings);
