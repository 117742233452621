import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, PageHeader, Icon, Row, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../components/Card';
import SectionContent from '../../components/SectionContent';
import SectionHeader from '../../components/SectionHeader';
import SectionWrapper from '../../components/SectionWrapper';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_VENUE_QUERY, GET_VENUE_TABLES_QUERY } from './data/queries';
import moment from 'moment';
import _ from 'lodash';
import { DELETE_TABLE_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddTableModal from './AddTableModal';
import AddVenueModal from './AddVenueModal';
import TablesList from './TablesList';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function TBVenueDetail(props) {
    // console.log(props);
    const [selectedTableId, setSelectedTableId] = useState();
    const [venue, setVenue] = useState();
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [deleteVenueTable, { loading, error }] = useMutation(DELETE_TABLE_MUTATION);

    function handleTableModalOk(e) {
        // console.log(e);
        setTableModalVisible(false);
        setSelectedTableId();
    }

    function handleTableModalCancel(e) {
        setTableModalVisible(false);
    }

    function handleVenueModalOk(e) {
        // console.log(e);
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
    }

    function handleEditTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
        setTableModalVisible(true);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={venue && venue.name}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={props.title}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/tournament-builder/${props.tournament.slug}/venues`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="tournamentBuilder"
            >
                <Button size="large" type="ghost" ghost onClick={() => setVenueModalVisible(true)}>
                    <Icon type="edit" /> Edit
                </Button>
                {/* <Button size="large" type="danger" ghost>
					<Icon type="delete" /> Delete
				</Button> */}
            </SectionHeader>
            {/* <PageHeader
				onBack={() => props.history.push(`/tournament-builder/${props.tournament.slug}/venues`)}
				backIcon={<Icon type="arrow-left" style={{ color: props.theme === 'dark' ? '#fff' : '#000' }} />}
				title={
					<Text style={{ color: props.theme === 'dark' ? '#fff' : '#000' }}>
						{_.startCase(_.toLower(props.match.params.venue.replace('-', ' '))).toUpperCase()}
					</Text>
				}
				style={{
					background:
						props.theme === 'dark'
							? 'linear-gradient(180deg, #07101f, #14243b)'
							: 'linear-gradient(#fff, #fafafa)',
					paddingTop: 24,
					paddingLeft: 40,
					paddingRight: 40,
					paddingBottom: 24,
					display: 'flex',
					alignItems: 'center'
				}}
			/> */}
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_VENUE_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: props.match.params.venue }}
                    onCompleted={async (data) => {
                        const venue = data.venues && data.venues[0];
                        setVenue(venue);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                        const venue = data.venues && data.venues[0];

                        return (
                            <React.Fragment>
                                {loading && (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                )}
                                {error && <div style={styles.container}>Error: {error.message}</div>}
                                {(!loading && data && !data.venues) ||
                                    (data && data.venues && data.venues.length === 0 && (
                                        <Card>
                                            <Empty
                                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                imageStyle={{
                                                    height: 100,
                                                }}
                                                description={<Text style={{ fontSize: 18 }}>Venue could not be found</Text>}
                                                style={{ margin: 10 }}
                                            >
                                                <Button>Create New Venue</Button>
                                            </Empty>
                                        </Card>
                                    ))}
                                {data.venues && (
                                    <React.Fragment>
                                        {/* <pre>{JSON.stringify(data.venues, null, 4)}</pre> */}
                                        {data.venues.map((venue, index) => {
                                            const {
                                                name,
                                                description,
                                                venue_type,
                                                avatar,
                                                address1,
                                                address2,
                                                city,
                                                country,
                                                region,
                                                postal_code,
                                                phone_number,
                                                email_address,
                                                website,
                                                operating_hours,
                                                social_facebook,
                                                social_instagram,
                                                social_twitter,
                                                is_public,
                                                is_claimed,
                                                claimed_by,
                                                owner,
                                                pool_tables,
                                            } = venue;

                                            return (
                                                <Row gutter={24} key={index}>
                                                    <Col>
                                                        <Card
                                                            // title="Venue Information"
                                                            cover={
                                                                <div
                                                                    style={{
                                                                        paddingTop: 16,
                                                                        paddingBottom: 16,
                                                                        paddingLeft: 24,
                                                                        paddingRight: 24,
                                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                                        Venue Information
                                                                    </Title>
                                                                </div>
                                                            }
                                                            // extra={
                                                            // 	<div>
                                                            // 		<Button
                                                            // 			type="primary"
                                                            // 			onClick={() => setVenueModalVisible(true)}
                                                            // 		>
                                                            // 			<Icon type="edit" /> Edit
                                                            // 		</Button>
                                                            // 		<Button type="danger">
                                                            // 			<Icon type="delete" /> Delete
                                                            // 		</Button>
                                                            // 	</div>
                                                            // }
                                                        >
                                                            <Descriptions
                                                                size="small"
                                                                column={2}
                                                                // bordered
                                                                layout="horizontal"
                                                            >
                                                                <Descriptions.Item label="Name">{name || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Address">
                                                                    {address1} {address2},{city}, {region} {postal_code} {country}
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label="Description">
                                                                    <Text style={{ width: 200 }}>{description || 'N/A'}</Text>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label="Operating Hours">{operating_hours || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Venue Type">
                                                                    {_.startCase(_.toLower(venue_type.replace('_', ' ')))}
                                                                </Descriptions.Item>

                                                                <Descriptions.Item label="Access">
                                                                    {is_public === true ? 'Public' : 'Private'}
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label="Claimed">{is_claimed === true ? 'Yes' : 'No'}</Descriptions.Item>

                                                                <Descriptions.Item label="Phone Number">{phone_number || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Email Address">{email_address || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Website">{website || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Facebook Link">{social_facebook || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Instagram Link">{social_instagram || 'N/A'}</Descriptions.Item>
                                                                <Descriptions.Item label="Twitter Link">{social_twitter || 'N/A'}</Descriptions.Item>
                                                            </Descriptions>
                                                        </Card>
                                                    </Col>

                                                    <Col>
                                                        <Card
                                                            // title={
                                                            // 	<div
                                                            // 		style={{
                                                            // 			display: 'flex',
                                                            // 			alignItems: 'center',
                                                            // 			justifyContent: 'space-between'
                                                            // 		}}
                                                            // 	>
                                                            // 		<Text>
                                                            // 			{venue_pool_tables &&
                                                            // 			venue_pool_tables.length > 0 ? (
                                                            // 				`Tables (${venue_pool_tables.length})`
                                                            // 			) : (
                                                            // 				'Tables'
                                                            // 			)}
                                                            // 		</Text>
                                                            // 		<div>
                                                            // 			<Button
                                                            // 				type="default"
                                                            // 				onClick={() => {
                                                            // 					setTableModalVisible(true);
                                                            // 				}}
                                                            // 			>
                                                            // 				<Icon type="plus-circle" /> Add Tables
                                                            // 			</Button>
                                                            // 		</div>
                                                            // 	</div>
                                                            // }
                                                            cover={
                                                                <div
                                                                    style={{
                                                                        paddingTop: 16,
                                                                        paddingBottom: 16,
                                                                        paddingLeft: 24,
                                                                        paddingRight: 24,
                                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                                        {pool_tables && pool_tables.length > 0 ? `Tables (${pool_tables.length})` : 'Tables'}
                                                                    </Title>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                setTableModalVisible(true);
                                                                            }}
                                                                        >
                                                                            <Icon type="plus-circle" /> Add Tables
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            bodyStyle={{ padding: 0 }}
                                                        >
                                                            {pool_tables && pool_tables.length > 0 ? (
                                                                <TablesList
                                                                    {...props}
                                                                    onSelected={handleTableSelected}
                                                                    onEditSelected={handleEditTableSelected}
                                                                    onDelete={(record) => {
                                                                        deleteVenueTable({
                                                                            variables: {
                                                                                id: record.id,
                                                                            },
                                                                            notifyOnNetworkStatusChange: true,
                                                                            awaitRefetchQueries: true,
                                                                            refetchQueries: [
                                                                                {
                                                                                    query: GET_VENUE_QUERY,
                                                                                    variables: { slug: venue.slug },
                                                                                },
                                                                            ],
                                                                        })
                                                                            .then((data) => {
                                                                                console.log(data);
                                                                                message.success('Table deleted from venue');
                                                                            })
                                                                            .catch((error) => {
                                                                                console.log(error);
                                                                                message.error(JSON.stringify(error, null, 4), 2);
                                                                            });
                                                                    }}
                                                                    tables={pool_tables.map((item, index) => {
                                                                        return {
                                                                            ...item,
                                                                            key: index,
                                                                            index: index + 1,
                                                                        };
                                                                    })}
                                                                />
                                                            ) : (
                                                                <Empty
                                                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                                    imageStyle={{
                                                                        height: 100,
                                                                    }}
                                                                    description={<Text style={{ fontSize: 18 }}>No Tables found for this venue.</Text>}
                                                                    style={{ margin: 30 }}
                                                                />
                                                            )}
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                                <AddTableModal
                                    {...props}
                                    tournament={props.tournament}
                                    venue={venue}
                                    selectedTableId={selectedTableId}
                                    tableModalVisible={tableModalVisible}
                                    onTableModalOk={handleTableModalOk}
                                    onTableModalCancel={handleTableModalCancel}
                                />
                                <AddVenueModal
                                    {...props}
                                    selectedVenueId={venue && venue.id}
                                    // venue={
                                    // 	venues.filter((item, index) => {
                                    // 		return item.id === selectedVenueId;
                                    // 	})[0]
                                    // }
                                    modalVisible={venueModalVisible}
                                    onModalOk={handleVenueModalOk}
                                    onModalCancel={handleVenueModalCancel}
                                />
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(TBVenueDetail);
