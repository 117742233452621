import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { Line, Circle } from 'rc-progress';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PROGRESS } from './data/subscriptions';

export default function TournamentProgressBar(props) {
    const { tournament } = props;

    return (
        <Subscription
            subscription={GET_TOURNAMENT_PROGRESS}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onSubscriptionData={(data, loading, error) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                const tournament = data && data.tournaments && data.tournaments[0];
                const progress = (tournament && tournament.progress && parseInt(tournament.progress)) || 0;
                return (
                    <React.Fragment>
                        <Tooltip placement="bottom" title={`${progress}% complete`} arrowPointAtCenter>
                            <div className="tournament-progress-bar">
                                {tournament && (
                                    <Line
                                        percent={progress}
                                        strokeWidth={5}
                                        trailWidth={5}
                                        trailColor="rgba(26, 135, 255, 0.2)"
                                        strokeColor="#1890ff"
                                        strokeLinecap="butt"
                                    />
                                )}
                            </div>
                        </Tooltip>
                    </React.Fragment>
                );
            }}
        </Subscription>
    );
}
