import React, { useState, useEffect } from 'react';
import CountdownTimerProgress from '../../../components/CountdownTimerProgress';
import countdown from '../../../assets/sound/5sec-countdown.mp3';

export default function EventCountdownTimer(props) {
    const {
        currentKey,
        command,
        duration,
        size,
        fontSize,
        initialRemainingTime,
        textColor,
        colors,
        colorsTime,
        strokeWidth,
        trailStrokeWidth,
        shouldRepeat,
        subscribeToNewData,
        subscribeToMore,
    } = props;

    console.log(props);
    const [audio, setAudio] = useState(new Audio(countdown));
    const [audioPlaying, setAudioPlaying] = useState(false);

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        if ((command === 'STOP' || command === 'CLEAR') && audioPlaying === true) {
            audio.currentTime = 0;
            audio.pause();
            setAudioPlaying(false);
        }
    }, [command, audioPlaying, audio]);

    function stopAudio() {
        audio.currentTime = 0;
        audio.pause();
        setAudioPlaying(false);
    }

    function startAudio() {
        audio.play();
        setAudioPlaying(true);
    }

    // useEffect(() => {
    //     if (command === 'RESTART') {
    //         console.log('restart');
    //         setKey((prevKey) => prevKey + 1);
    //     }
    // }, [command]);

    return (
        <CountdownTimerProgress
            currentKey={currentKey}
            isPlaying={command === 'START' || command === 'RESTART' || command === 'RESUME' || command === 'EXTENSION' ? true : false}
            duration={duration}
            initialRemainingTime={initialRemainingTime}
            colorsTime={colorsTime}
            colors={colors}
            fontSize={fontSize}
            size={size}
            strokeWidth={strokeWidth}
            trailStrokeWidth={trailStrokeWidth}
            textColor={textColor}
            shouldRepeat={shouldRepeat}
            onUpdatedTime={(remainingTime) => {
                console.log(remainingTime);
                if (remainingTime === 5000) {
                    startAudio();
                }
            }}
            onComplete={(totalElapsedTime) => {
                stopAudio();
            }}
        />
    );
}
