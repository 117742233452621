import React, { useState, useEffect } from 'react';
import { Table, Typography, Row, Col, Empty, Drawer } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import TableScoreCard from '../tournaments/TableScoreCard';
import ZeroState from '../../../components/ZeroState';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_LEAGUE_MATCHES_SUBSCRIPTION } from '../data/subscriptions';

const { Title, Text } = Typography;

const styles = {};

export default function DPDivisionScores(props) {
    const { league, division } = props;

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState({
        title: '',
        slug: '',
        status: '',
    });

    useEffect(() => {
        return function cleanup() {
            if (drawerVisible === true) {
                setDrawerVisible(false);
            }
        };
    });

    function showDrawer(match) {
        setCurrentMatch(match);
        setDrawerVisible(true);
        window.scrollTo(0, 0);
    }

    function onClose() {
        setDrawerVisible(false);
    }

    function renderMatch(match, table, index) {
        console.log(match);
        return (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6} key={index}>
                <TableScoreCard
                    title={`Match ${index + 1}`}
                    label={match.table_name}
                    matchData={{
                        ...match,
                        challenger1_name: match.challenger1_name || match.home_team.name,
                        challenger2_name: match.challenger2_name || match.visitor_team.name,
                        challenger1_score: match.challenger1_score || match.home_team_score,
                        challenger2_score: match.challenger2_score || match.visitor_team_score,
                    }}
                    status={match.status}
                    gameType={match.game_type}
                    onClick={() => {
                        props.history.push(`/leagues/${league.slug}/divisions/${division.slug}/matches/${match.slug}`);
                    }}
                    playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                />
            </Col>
        );
    }

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Live Scores"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row
                    gutter={24}
                    justify="center"
                    type="flex"
                    // style={{ padding: 24, position: 'relative', marginTop: -120 }}
                >
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Row gutter={24}>
                            <Subscription
                                subscription={GET_LEAGUE_MATCHES_SUBSCRIPTION}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{
                                    league_id: league.id,
                                    division_id: division.id,
                                }}
                                onCompleted={(data) => {
                                    // console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_matches) || (data && data.league_matches.length === 0)) {
                                        return (
                                            <Card>
                                                <ZeroState showImage message="No Matches found." style={{ margin: 30 }} {...props} />
                                            </Card>
                                        );
                                    }

                                    return (
                                        <Row gutter={24} justify="center" type="flex">
                                            <Col xs={24}>
                                                <Row gutter={16} justify="start" type="flex">
                                                    {data.league_matches &&
                                                        data.league_matches.map((match, index) => {
                                                            const table = match.pool_table;
                                                            return renderMatch(match, table, index);
                                                        })}
                                                </Row>
                                            </Col>
                                        </Row>
                                    );
                                }}
                            </Subscription>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>

            <Drawer
                title={currentMatch.title}
                // getContainer={() => document.querySelector('#scores')}
                // mask={false}
                // placement="bottom"
                width={400}
                closable
                onClose={onClose}
                visible={drawerVisible}
            >
                <p>Status: {currentMatch.status}</p>
            </Drawer>
        </SectionWrapper>
    );
}
