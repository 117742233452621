import React, { useState } from 'react';
import { Table, Icon, Row, Col, Typography, Progress, Button } from 'antd';
import Card from '../../components/Card';
import CircleFlag from '../../components/CircleFlag';
import numeral from 'numeral';
import { TrophyFilled } from '@ant-design/icons';
import { formatPlayerName, getOrdinalSuffix } from '../../utils/';

const { Text, Title } = Typography;

const styles = {};

export default function ViewerResults(props) {
    const { tournament, tournamentID, formattedResults } = props;

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
            <Title level={4}>Tournament results</Title>
            {formattedResults &&
                formattedResults
                    .sort((a, b) => {
                        return a.place - b.place;
                    })
                    .map((player, index) => {
                        const isSplit = formattedResults.filter((player) => {
                            return player.place === '1';
                        });

                        return (
                            <div key={index} style={{ paddingTop: 5, display: 'flex', alignItems: 'center' }}>
                                <div>
                                    {player.place === '1' ? (
                                        <TrophyFilled
                                            style={{
                                                color: '#eab700',
                                                marginRight: 5,
                                            }}
                                        />
                                    ) : player.place === '2' ? (
                                        <TrophyFilled
                                            style={{
                                                color: '#9baab1',
                                                marginRight: 5,
                                            }}
                                        />
                                    ) : (
                                        player.place === '3' && (
                                            <TrophyFilled
                                                style={{
                                                    color: '#f19921',
                                                    marginRight: 5,
                                                }}
                                            />
                                        )
                                    )}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text
                                        style={{
                                            marginLeft: 0,
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {formatPlayerName(player)} {player.skill_level ? `(${player.skill_level})` : null} -{' '}
                                        {player.place
                                            ? `${getOrdinalSuffix(player.place)} ${player.place === '1' && isSplit.length > 1 ? '(split)' : ''}`
                                            : '-'}
                                    </Text>
                                </div>
                            </div>
                        );
                    })}
        </div>
    );
}
