import { gql } from '@apollo/client';

export const CREATE_MATCH_MUTATION = gql`
    mutation insert_matches($objects: [matches_insert_input!]!) {
        insert_matches(objects: $objects) {
            affected_rows
            returning {
                id
                slug
            }
        }
    }
`;

export const UPDATE_MATCH_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            __typename
            returning {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
                match_games {
                    id
                    match_id
                    match_number
                    game_number
                    player_id
                    player_score
                    player_points
                    player_is_winner
                    player_break_run
                    player_runout
                    player_tpa
                    player_unforced_errors
                    player_balls_pocketed
                    opponent_id
                    opponent_score
                    opponent_points
                    opponent_is_winner
                    opponent_break_run
                    opponent_runout
                    opponent_tpa
                    opponent_unforced_errors
                    opponent_balls_pocketed
                    status
                    progress
                    duration
                    table_id
                    start_date_time
                    end_date_time
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const DELETE_MATCH_MUTATION = gql`
    mutation delete_matches($id: Int!) {
        delete_matches(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_VENUE_MUTATION = gql`
    mutation insert_venues($objects: [venues_insert_input!]!) {
        insert_venues(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const UPDATE_VENUE_MUTATION = gql`
    mutation update_venues($id: Int, $changes: venues_set_input) {
        update_venues(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const UPDATE_MATCH_PLAYER = gql`
    mutation upsert_match_players($match_id: Int!, $objects: [match_players_insert_input!]!) {
        insert_match_players(
            objects: $objects
            on_conflict: { constraint: match_players_pkey, update_columns: [id], where: { match_id: { _eq: $match_id } } }
        ) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_MATCH_PLAYER = gql`
    mutation delete_match_players($match_id: Int!) {
        delete_match_players(where: { match_id: { _eq: $match_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_MATCH_STAT = gql`
    mutation upsert_match_stats_combined($match_id: Int!, $objects: [match_stats_combined_insert_input!]!) {
        insert_match_stats_combined(
            objects: $objects
            on_conflict: { constraint: match_stats_combined_pkey, update_columns: [id], where: { match_id: { _eq: $match_id } } }
        ) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const TRACK_MATCH_STAT = gql`
    mutation upsert_match_stats($objects: [match_stats_insert_input!]!) {
        insert_match_stats(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_MATCH_STATS = gql`
    mutation delete_match_stats($match_id: Int!) {
        delete_match_stats(where: { match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

export const STORE_MATCH_SHOT_RESULT = gql`
    mutation fn_store_match_shot_result($match_id: Int!, $match_player_id: Int!, $game_number: Int!, $shot_number: Int!, $shot_results: String!) {
        fn_generate_tournament_projection_results(
            where: { match: { _eq: $match_id } }
            args: {
                _match_id: $match_id
                _match_player_id: $match_player_id
                _game_number: $game_number
                _shot_number: $shot_number
                _shot_result: $shot_results
            }
        ) {
            id
            match_id
            match_player_id
            game_number
            shot_number
            shot_result_id
            ts
        }
    }
`;

export const INSERT_MATCH_MANAGERS_INVITE_KEYS = gql`
    mutation insert_match_managers_invite_keys($objects: [match_managers_invite_keys_insert_input!]!) {
        insert_match_managers_invite_keys(objects: $objects) {
            returning {
                id
            }
        }
    }
`;

export const INSERT_MATCH_MANAGERS = gql`
    mutation insert_match_managers($objects: [match_managers_insert_input!]!) {
        insert_match_managers(objects: $objects) {
            returning {
                id
            }
        }
    }
`;

export const DELETE_MATCH_MANAGERS = gql`
    mutation delete_match_managers($user_id: Int, $email: String) {
        delete_match_managers(where: { _or: { email: { _eq: $email }, user_id: { _eq: $user_id } } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_MATCH_MANAGERS_INVITE_KEYS = gql`
    mutation delete_match_managers($match_id: Int!, $email: String!) {
        delete_match_managers_invite_keys(where: { match_id: { _eq: $match_id }, _and: { email: { _eq: $email } } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_MATCH_MANAGERS_BY_EMAIL = gql`
    mutation delete_match_managers($email: String!, $match_id: Int!) {
        delete_match_managers(where: { email: { _eq: $email }, _and: { match_id: { _eq: $match_id } } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_MATCH_MANAGERS_ADD = gql`
    mutation update_match_managers($match_id: Int!, $email: String!, $invite_accepted: Boolean!) {
        update_match_managers(where: { match_id: { _eq: $match_id }, _and: { email: { _eq: $email } } }, _set: { invite_accepted: $invite_accepted }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_MATCH_MANAGERS_INVITE = gql`
    mutation update_match_managers($user_id: Int!, $match_id: Int!, $email: String!, $invite_accepted: Boolean!) {
        update_match_managers(
            where: { match_id: { _eq: $match_id }, _and: { email: { _eq: $email } } }
            _set: { invite_accepted: $invite_accepted, user_id: $user_id }
        ) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_MATCH_ADMINS_INVITE_KEY = gql`
    mutation delete_match_managers_invite_keys($email: String!, $match_id: Int!) {
        delete_match_managers_invite_keys(where: { email: { _eq: $email }, match_id: { _eq: $match_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_EVENT_MUTATION = gql`
    mutation insert_events($objects: [events_insert_input!]!) {
        insert_events(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
            }
        }
    }
`;

export const UPDATE_EVENT_MUTATION = gql`
    mutation update_events($id: Int, $changes: events_set_input) {
        update_events(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
            }
        }
    }
`;

export const UPDATE_MATCH_GAME = gql`
    mutation update_match_games($id: Int!, $match_id: Int!, $changes: match_games_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_MATCH_GAME = gql`
    mutation insert_match_games($objects: [match_games_insert_input!]!) {
        insert_match_games(objects: $objects) {
            affected_rows
            returning {
                id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_MATCH_GAME = gql`
    mutation delete_match_games($id: Int!, $match_id: Int!) {
        delete_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

export const RESET_MATCH_GAMES = gql`
    mutation delete_match_games($match_id: Int!) {
        delete_match_games(where: { match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_TABLE_MUTATION = gql`
    mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
        insert_pool_tables(objects: $objects) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_TABLE_MUTATION = gql`
    mutation update_pool_tables($id: Int!, $changes: pool_tables_set_input!) {
        update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const CREATE_ARTISTIC_SHOT_MUTATION = gql`
    mutation insert_artistic_pool_shots($objects: [artistic_pool_shots_insert_input!]!) {
        insert_artistic_pool_shots(objects: $objects) {
            affected_rows
            returning {
                id
                slug
            }
        }
    }
`;
