import React, { useEffect, useState } from 'react';
import { message, Progress, Button, Badge, Input, Tag, Popover, Tooltip, Icon, Typography } from 'antd';
import MatchScore from './MatchScore';
import { getCountryName, getOrdinalSuffix } from '../../../../utils';
import CircleFlag from '../../../../components/CircleFlag';
import { Line, Circle } from 'rc-progress';
import Timer from 'react-compound-timer';
import { view } from 'react-easy-state';
import { getContrastColor } from '../../../../utils';
import appStore from '../../../../lib/appStore';
import { TrophyFilled } from '@ant-design/icons';
import axios from 'axios';
import VideoOverlay from '../../../../components/VideoOverlay';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;

function Match(props) {
    const {
        authState,
        tournament,
        match,
        matches,
        className,
        roundNumber,
        index,
        totalRounds,
        number,
        tables,
        table,
        stage,
        tableName,
        progress,
        activePlayerHover,
        isWinner,
        isWinning,
        isForfeit,
        isCompleted,
        isPending,
        isLive,
        isScheduled,
        isTvTable,
        status,
        footerText,
        onClick,
        onTouchEnd,
        compact,
        theme,
    } = props;

    let players = props.players;
    let duration;
    let winner = players.filter((player) => {
        return player.isWinner === true;
    })[0];

    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(false);

    // const [players, setPlayers] = useState(props.players);

    // useEffect(() => {
    //     console.log('MATCH USE EFFECT');
    //     const source = axios.CancelToken.source();
    //     const instance = axios.create();

    //     const getFargoCharts = async (type, size, player1Fargo, player2Fargo) => {
    //         const typesEnum = {
    //             hot: 2,
    //             medium: 1,
    //             mild: 0,
    //         };
    //         const url = `https://lms.fargorate.com/api/ratingcalc/racesbylengthandtype?length=${size}&type=${typesEnum[type]}&ratingOne=${player1Fargo}&ratingTwo=${player2Fargo}`;

    //         await instance
    //             .get(url, { cancelToken: source.token })
    //             .then((response) => {
    //                 const results = response.data;
    //                 // message.success('Fargo races loaded.');

    //                 if (results) {
    //                     const closestChart = results.filter((item) => item.closest === true)[0];
    //                     const player1RaceTo =
    //                         player1Fargo > player2Fargo
    //                             ? closestChart.highPlayerRaceTo
    //                             : player1Fargo < player2Fargo
    //                             ? closestChart.lowPlayerRaceTo
    //                             : closestChart.highPlayerRaceTo;
    //                     const player2RaceTo =
    //                         player2Fargo > player1Fargo
    //                             ? closestChart.highPlayerRaceTo
    //                             : player2Fargo < player1Fargo
    //                             ? closestChart.lowPlayerRaceTo
    //                             : closestChart.highPlayerRaceTo;

    //                     const newPlayers = players.map((player, index) => {
    //                         return {
    //                             ...player,
    //                             raceTo: index === 0 ? player1RaceTo : player2RaceTo,
    //                         };
    //                     });

    //                     console.log(newPlayers);

    //                     setPlayers(newPlayers);
    //                 }

    //                 return results;
    //             })
    //             .catch((error) => {
    //                 if (axios.isCancel(error)) {
    //                 } else {
    //                     console.log(error);
    //                     throw error;
    //                 }
    //             });
    //     };

    //     async function getMatchChart(size, player1Fargo, player2Fargo) {
    //         if (tournament.handicap_format === 'fargo_hot_column') {
    //             getFargoCharts('hot', size, player1Fargo, player2Fargo);
    //         } else if (tournament.handicap_format === 'fargo_medium_column') {
    //             getFargoCharts('medium', size, player1Fargo, player2Fargo);
    //         } else if (tournament.handicap_format === 'fargo_mild_column') {
    //             getFargoCharts('mild', size, player1Fargo, player2Fargo);
    //         }
    //     }

    //     const player1Fargo = players && players[0] && players[0].skillLevel;
    //     const player2Fargo = players && players[1] && players[1].skillLevel;

    //     console.log(player1Fargo);
    //     console.log(player2Fargo);
    //     if (player1Fargo && player2Fargo) {
    //         message.loading('Loading Fargo Player Races...');
    //         getMatchChart(tournament.winners_race_to, player1Fargo, player2Fargo);
    //     }

    //     return () => {
    //         source.cancel();
    //     };
    // }, []);

    if (match.start_time && match.end_time) {
        let start = moment(match.start_time);
        let end = moment(match.end_time);
        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
    } else {
        let start = moment(match.start_time);
        let end = moment(new Date());
        // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
        // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hours] m [minutes]');
        duration = match.start_time ? `${moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]')} ago` : '0 mins';
        // duration = match.start_time ? moment(match.start_time).fromNow() : '0 mins';
    }

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    // const totalTables =
    //     tables &&
    //     tables.filter((table) => {
    //         return table.status !== 'CLOSED';
    //     }).length;

    // const totalMatches = matches && matches.length;
    // const activeTables =
    //     tables &&
    //     tables.filter((table) => {
    //         return table.status === 'IN_USE';
    //     }).length;

    // console.log({
    // 	totalTables,
    // 	activeTables
    // });

    // useEffect(
    // 	() => {
    // 		console.log(appStore.activePlayer);
    // 	},
    // 	[ appStore.activePlayer ]
    // );

    function activePlayer(value) {
        appStore.activePlayer = value;
    }

    function getMatchTitle(player) {
        if (player.playerName) {
            if (
                player.playerName !== 'BYE' &&
                player.skillLevel &&
                player.raceTo &&
                tournament.show_player_skill_levels === true &&
                tournament.show_player_races === true
            ) {
                return `${player.playerName} ${player.skillLevel} (${player.raceTo}) `;
            } else if (player.playerName !== 'BYE' && player.skillLevel && tournament.show_player_skill_levels === true) {
                return `${player.playerName} ${player.skillLevel}`;
            } else if (player.playerName !== 'BYE' && player.raceTo && tournament.show_player_races === true) {
                return `${player.playerName} (${player.raceTo})`;
            } else {
                return `${player.playerName}`;
            }
            // if (player.playerName !== 'BYE' && player.raceTo) {
            //     return `${player.playerName} (${player.raceTo})`;
            // } else {
            //     return `${player.playerName}`;
            // }
        } else {
            return '';
        }
    }

    function handleVideoModalOk(e) {
        console.log(e);
        setVideoModalVisible(false);
    }

    function handleVideoModalCancel(e) {
        setVideoModalVisible(false);
    }

    const scheduledT = moment.utc(match.scheduled_time, 'HH:mm a');
    const startT = moment.utc(match.start_time, 'HH:mm a');
    const hexToLuma = match.advance_to ? getContrastColor('#ff6163') : match.match_color && getContrastColor(match.match_color);
    const scheduledTable =
        match.scheduled_table_id &&
        tables &&
        tables.filter((table) => {
            return table.id === match.scheduled_table_id;
        })[0];

    // if (match.scheduled_time && match.start_time) {
    //     console.log('scheduled', scheduledT);
    //     console.log('start', startT);
    //     console.log('is after', moment(scheduledT).isAfter(startT));
    // }

    let followsMatch;

    if (matches && matches.length > 0) {
        const follows = matches.filter((item) => {
            if (item.priority && item.priority === match.priority - 1 && item.scheduled_table_id === match.scheduled_table_id && item.status !== 'COMPLETED') {
                // console.log(match.status);
                return match;
            }
        })[0];

        if (follows) {
            followsMatch = follows.match_number;
        }
    }

    // if (matches && matches.length > 0) {
    //     const follows = matches.filter((item) => {
    //         if (item.priority && item.priority === match.priority - 1 && item.scheduled_table_id === match.scheduled_table_id) {
    //             return match;
    //         }
    //     })[0];

    //     if (follows) {
    //         followsMatch = follows.match_number;
    //     }
    // }

    const livestreamUrl = table && table.livestream_url ? table.livestream_url : match.livestream_url;
    const livestreamSplit = livestreamUrl && livestreamUrl.split('/');
    const livestreamDomain = livestreamSplit && livestreamSplit[2];
    const useVideoOverlay = false;
    // const useVideoOverlay =
    // livestreamDomain && (livestreamDomain.indexOf('digitalpool.net') > -1 || livestreamDomain.indexOf('onsitepool.com') > -1) ? true : false;
    const hasVideoPackParam = livestreamUrl && livestreamUrl.indexOf('videopack[enable]=true') > -1 ? true : false;

    function formatLivestreamUrl(url) {
        let finalLivestreamUrl;
        if (url) {
            finalLivestreamUrl = hasVideoPackParam === true ? `${url}` : `${url}?videopack[enable]=true`;
        }
        return finalLivestreamUrl;
    }

    function totalSetsWon(player, sets) {
        if (player === 'challenger1') {
            return sets
                .map((set) => {
                    return set.challenger1_score === set.challenger1_race_to;
                })
                .reduce((acc, total) => {
                    return acc + total;
                }, 0);
        } else if (player === 'challenger2') {
            return sets
                .map((set) => {
                    return set.challenger2_score === set.challenger2_race_to;
                })
                .reduce((acc, total) => {
                    return acc + total;
                }, 0);
        }
    }

    // console.log(livestreamDomain);
    // console.log(useVideoOverlay);

    return (
        <div id={match.identifier} className={className}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {match.identifier.includes('L') && match.advance_to && stage && stage.players_advance_per_group === 2 && (
                    <div className="match-winner">
                        {winner ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {winner.country && <CircleFlag country={winner.country} />}

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                                    <Text>{getMatchTitle(winner)} </Text>

                                    {winner.isAdvancing === true && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                flex: 1,
                                            }}
                                        >
                                            <Tag color="#00796b" style={{ marginTop: 2, marginLeft: 5, borderRadius: 5, overflow: 'hidden' }}>
                                                A
                                            </Tag>
                                        </div>
                                    )}

                                    <div>
                                        {winner.place === '1' ? (
                                            <TrophyFilled style={{ color: '#eab700', marginRight: 5 }} />
                                        ) : winner.place === '2' ? (
                                            <TrophyFilled style={{ color: '#9baab1', marginRight: 5 }} />
                                        ) : (
                                            winner.place === '3' && <TrophyFilled style={{ color: '#f19921', marginRight: 5 }} />
                                        )}
                                    </div>
                                    <div style={{ position: 'absolute', bottom: -25, color: '#ff6163', left: 0 }}>{footerText}</div>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <Text>Winner of match {match.match_number}</Text>
                                <div style={{ position: 'absolute', bottom: -25, color: '#ff6163', left: 0 }}>{footerText}</div>
                            </React.Fragment>
                        )}
                    </div>
                )}
                {number && (
                    <div
                        className="match-number"
                        style={{
                            ...(match.advance_to && { backgroundColor: '#ff6163' }),
                            ...(match.match_color && match.match_color !== 'transparent' && { backgroundColor: match.match_color }),
                            ...(match.match_color && { color: match.match_color && hexToLuma > 0.5 ? '#000' : '#fff' }),
                        }}
                    >
                        {number}
                    </div>
                )}

                <div style={{ width: '100%' }}>
                    {compact !== true && (
                        <div className="match-info">
                            <div className="time" style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ height: 18 }}>
                                    {table && table.id && (
                                        <React.Fragment>
                                            <Text
                                                style={{
                                                    backgroundColor:
                                                        match.status === 'COMPLETED'
                                                            ? theme.bracket.matchScores.completedMatchTableBackgroundColor
                                                            : theme.bracket.matchScores.liveMatchTableBackgroundColor,
                                                    borderRadius: 4,
                                                    padding: '2px 5px',
                                                }}
                                            >
                                                <span style={{ color: match.status === 'COMPLETED' ? '#888' : '#333' }}>
                                                    {table.label} {table.status === 'ASSIGNED' ? `- ${table.status}` : ''}
                                                </span>
                                                {/* - {match.place} */}
                                            </Text>
                                            {/* <Popover
                                                content={
                                                    <div>
                                                        <img src={`data:image/png;base64, ${match.livestream_thumbnail}`} alt="thumb" />
                                                    </div>
                                                }
                                                trigger="hover"
                                            >
                                                <img src={`data:image/png;base64,${match.livestream_thumbnail}`} alt="thumb" />
                                            </Popover> */}
                                        </React.Fragment>
                                    )}
                                    {!table && scheduledTable && scheduledTable.id && (
                                        <React.Fragment>
                                            <Text
                                                style={{
                                                    backgroundColor: '#a7e063',
                                                    borderRadius: 4,
                                                    padding: '2px 5px',
                                                }}
                                            >
                                                <span style={{ color: match.status === 'COMPLETED' ? '#333' : '#333' }}>{scheduledTable.label}</span>
                                            </Text>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            {table &&
                            table.is_streaming_table &&
                            table.is_streaming_table === true &&
                            table.livestream_url !== null &&
                            match.status === 'IN_PROGRESS' ? (
                                <React.Fragment>
                                    {useVideoOverlay ? (
                                        <Button
                                            type="text"
                                            onClick={() => {
                                                setVideoModalVisible(true);
                                                const formatted = formatLivestreamUrl(table.livestream_url);
                                                setSelectedVideo(formatted);
                                            }}
                                            style={{
                                                marginLeft: 10,
                                                fontWeight: 700,
                                                color: '#f5212d',
                                                marginBottom: 5,
                                                fontSize: 11,
                                                textTransform: 'uppercase',
                                                letterSpacing: 2,
                                            }}
                                        >
                                            <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> Livestream
                                        </Button>
                                    ) : (
                                        <a
                                            href={
                                                table.livestream_url && !/^https?:\/\//i.test(table.livestream_url)
                                                    ? 'http://' + table.livestream_url
                                                    : table.livestream_url
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                marginLeft: 10,
                                                fontWeight: 700,
                                                color: '#f5212d',
                                                marginBottom: 5,
                                                fontSize: 11,
                                                textTransform: 'uppercase',
                                                letterSpacing: 2,
                                            }}
                                        >
                                            <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> Livestream
                                        </a>
                                    )}
                                </React.Fragment>
                            ) : table &&
                              table.is_streaming_table &&
                              table.is_streaming_table === true &&
                              match.livestream_url !== null &&
                              match.status === 'COMPLETED' ? (
                                <React.Fragment>
                                    {useVideoOverlay ? (
                                        <Button
                                            type="text"
                                            onClick={() => {
                                                setVideoModalVisible(true);
                                                const formatted = formatLivestreamUrl(match.livestream_url);
                                                setSelectedVideo(formatted);
                                            }}
                                            style={{
                                                marginLeft: 10,
                                                fontWeight: 700,
                                                color: '#ff7529',
                                                marginBottom: 5,
                                                fontSize: 11,
                                                textTransform: 'uppercase',
                                                letterSpacing: 2,
                                            }}
                                        >
                                            <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> View Recording
                                        </Button>
                                    ) : (
                                        <a
                                            href={
                                                match.livestream_url && !/^https?:\/\//i.test(match.livestream_url)
                                                    ? 'http://' + match.livestream_url
                                                    : match.livestream_url
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                marginLeft: 10,
                                                fontWeight: 700,
                                                color: '#ff7529',
                                                marginBottom: 5,
                                                fontSize: 11,
                                                textTransform: 'uppercase',
                                                letterSpacing: 2,
                                            }}
                                        >
                                            <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> View Recording
                                        </a>
                                    )}
                                </React.Fragment>
                            ) : !isCompleted && isScheduled && moment(startT).isBefore(scheduledT) ? (
                                <div className="scheduled-text">SCHEDULED</div>
                            ) : isLive ? (
                                <div className="live-text">LIVE</div>
                            ) : isCompleted ? (
                                <div className="completed-text">COMPLETED</div>
                            ) : isPending ? (
                                <div className="pending-text">PENDING</div>
                            ) : match.status === 'NOT_STARTED' ? (
                                <div className="completed-text" />
                            ) : !isCompleted && isScheduled ? (
                                <div className="completed-text">SCHEDULED</div>
                            ) : (
                                <div className="completed-text">{match.status}</div>
                            )}
                        </div>
                    )}

                    <div
                        className="match-data"
                        onClick={onClick}
                        onTouchEnd={onTouchEnd}
                        style={{
                            border: match.advance_to
                                ? `1px solid #ff6163`
                                : match.match_color
                                ? `1px solid ${match.match_color}`
                                : theme.name === 'dark'
                                ? `1px solid #142335`
                                : `1px solid #d3dce4`,
                        }}
                    >
                        {/* <div className="match-number">{number}</div> */}

                        <div
                            className={`match-scores  ${isPending === true && 'blinking'}`}
                            style={{ backgroundColor: theme.bracket.matchScores.backgroundColor, color: theme.bracket.matchScores.textColor }}
                        >
                            {players.map((player, index) => {
                                if (player) {
                                    return (
                                        <React.Fragment key={index}>
                                            <div
                                                onMouseEnter={() => {
                                                    if (player.id && activePlayerHover !== false) {
                                                        activePlayer(player.id);
                                                        // appStore.activePlayer = player.id;
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if (appStore.activePlayer !== null && activePlayerHover !== false) {
                                                        activePlayer(null);
                                                    }
                                                }}
                                                className={`player p${index} ${index === 0 ? 'top' : 'bottom'} ${
                                                    player.isWinner ? 'winner' : player.isLoser ? 'loser' : ''
                                                } ${
                                                    !player.isWinner && !player.isLoser && player.isWinning
                                                        ? 'winning'
                                                        : player.isLosing
                                                        ? 'losing'
                                                        : player.isTied
                                                        ? 'tied'
                                                        : ''
                                                } ${appStore.activePlayer && appStore.activePlayer === player.id ? 'active-path' : ''} ${
                                                    appStore.searchedPlayer &&
                                                    appStore.activePlayerMatchIdentifier &&
                                                    appStore.searchedPlayer === player.id &&
                                                    appStore.activePlayerMatchIdentifier === match.identifier
                                                        ? 'blinking'
                                                        : ''
                                                }`}
                                                style={
                                                    index === 0
                                                        ? {
                                                              borderBottom: `1px solid ${theme.bracket.matchData.borderColor}`,
                                                          }
                                                        : {}
                                                }
                                            >
                                                <div className="player-seed">{player.seed}</div>
                                                {/* {player.seed ? <div className="player-seed">{player.seed || '-'}</div> : <div style={{ paddingLeft: 12 }} />} */}
                                                {/* <div className="player-number">
											{player.playerNumber ? player.playerNumber : '-'}
										</div> */}
                                                <div className="player-name">
                                                    {player.playerName ? (
                                                        <React.Fragment>
                                                            {player.country && <CircleFlag country={player.country} />}

                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                                                                <div className="ellipsis">{getMatchTitle(player)}</div>
                                                                {player.isAdvancing === true && (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-end',
                                                                            alignItems: 'center',
                                                                            flex: 1,
                                                                        }}
                                                                    >
                                                                        <Tag
                                                                            color="#00796b"
                                                                            style={{ marginTop: 2, marginLeft: 5, borderRadius: 5, overflow: 'hidden' }}
                                                                        >
                                                                            A
                                                                        </Tag>
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <Tooltip placement="left" title={player.place && `${getOrdinalSuffix(player.place)} place`}>
                                                                        {player.place === '1' ? (
                                                                            <TrophyFilled style={{ color: '#eab700', marginRight: 5 }} />
                                                                        ) : player.place === '2' ? (
                                                                            <TrophyFilled style={{ color: '#9baab1', marginRight: 5 }} />
                                                                        ) : (
                                                                            player.place === '3' && (
                                                                                <TrophyFilled style={{ color: '#f19921', marginRight: 5 }} />
                                                                            )
                                                                        )}
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="winner-loser-from">
                                                            {match.match_custom_text ? (
                                                                <React.Fragment>{match.match_custom_text}</React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {match.loser_from_top_num && match.loser_from_bottom_num ? (
                                                                        <React.Fragment>
                                                                            {index === 0
                                                                                ? `Loser of match ${match.loser_from_top_num}`
                                                                                : `Loser of match ${match.loser_from_bottom_num}`}
                                                                        </React.Fragment>
                                                                    ) : match.loser_from && (match.winner_from_top_num || match.winner_from_bottom_num) ? (
                                                                        <React.Fragment>
                                                                            {index === 0
                                                                                ? `Winner of match ${match.winner_from_top_num || match.winner_from_bottom_num}`
                                                                                : `Loser of match ${match.loser_from_num}`}
                                                                        </React.Fragment>
                                                                    ) : match.winner_from_top_num || match.winner_from_bottom_num ? (
                                                                        <React.Fragment>
                                                                            {index === 0 ? (
                                                                                <React.Fragment>
                                                                                    {(match.winner_from_top_num || match.winner_from_bottom_num) &&
                                                                                        `Winner of match ${
                                                                                            match.winner_from_top_num || match.winner_from_bottom_num
                                                                                        }`}
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    {match.winner_from_bottom_num &&
                                                                                        `Winner of match ${match.winner_from_bottom_num}`}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : null}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {match && match.tournament_match_sets && match.tournament_match_sets.length > 0 ? (
                                                    <React.Fragment>
                                                        {match.tournament_match_sets
                                                            //.filter((set) => {
                                                            //return set.challenger1_score !== 0 || set.challenger2_score !== 0;
                                                            //})
                                                            //.sort((a, b) => {
                                                            //if (a.number && b.number) {
                                                            //return a.number - b.number;
                                                            //}
                                                            //})
                                                            .map((set, index) => {
                                                                // console.log(set);
                                                                let setPlayer = set.challenger1_id === player.id ? 'challenger1' : 'challenger2';
                                                                let isForfeit =
                                                                    setPlayer === 'challenger1' ? set.challenger1_is_forfeit : set.challenger2_is_forfeit;
                                                                let isWithdraw =
                                                                    setPlayer === 'challenger1' ? set.challenger1_is_withdraw : set.challenger2_is_withdraw;
                                                                let playerScore = setPlayer === 'challenger1' ? set.challenger1_score : set.challenger2_score;

                                                                return (
                                                                    <div className="player-score" key={index}>
                                                                        {isForfeit === true ? (
                                                                            'FF'
                                                                        ) : isWithdraw === true ? (
                                                                            'WD'
                                                                        ) : match.is_split === true ? (
                                                                            <Text
                                                                                style={{
                                                                                    fontSize: 13,
                                                                                    textTransform: 'uppercase',
                                                                                }}
                                                                            >
                                                                                Split
                                                                            </Text>
                                                                        ) : playerScore && playerScore >= 0 ? (
                                                                            playerScore
                                                                        ) : (
                                                                            0
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="player-score set-total">
                                                            {player.isForfeit === true
                                                                ? 'FF'
                                                                : player.isWithdraw === true
                                                                ? 'WD'
                                                                : match.challenger1_id === player.id
                                                                ? totalSetsWon('challenger1', match.tournament_match_sets)
                                                                : match.challenger2_id === player.id
                                                                ? totalSetsWon('challenger2', match.tournament_match_sets)
                                                                : 0}
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {tournament.enable_sets === true && tournament.sets_per_match && tournament.sets_per_match > 0 ? (
                                                            <React.Fragment>
                                                                {Array.from({ length: tournament.sets_per_match }, (v, index) => {
                                                                    return (
                                                                        <div className="player-score" key={index}>
                                                                            {player.isForfeit === true ? (
                                                                                'FF'
                                                                            ) : player.isWithdraw === true ? (
                                                                                'WD'
                                                                            ) : match.is_split === true ? (
                                                                                <Text
                                                                                    style={{
                                                                                        fontSize: 13,
                                                                                        textTransform: 'uppercase',
                                                                                    }}
                                                                                >
                                                                                    Split
                                                                                </Text>
                                                                            ) : player.score ? (
                                                                                player.score
                                                                            ) : (
                                                                                0
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                                <div className="player-score set-total">
                                                                    {player.isForfeit === true
                                                                        ? 'FF'
                                                                        : player.isWithdraw === true
                                                                        ? 'WD'
                                                                        : player.score
                                                                        ? player.score
                                                                        : 0}
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="player-score">
                                                                {player.isForfeit === true ? (
                                                                    'FF'
                                                                ) : player.isWithdraw === true ? (
                                                                    'WD'
                                                                ) : match.is_split === true ? (
                                                                    <Text
                                                                        style={{
                                                                            fontSize: 13,
                                                                            textTransform: 'uppercase',
                                                                        }}
                                                                    >
                                                                        Split
                                                                    </Text>
                                                                ) : player.score ? (
                                                                    player.score
                                                                ) : (
                                                                    0
                                                                )}
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}

                                                {/* <MatchScore player={player} {...props} /> */}
                                            </div>

                                            {index === 1 && progress > 0 && progress < 100 && (
                                                <div
                                                    style={{
                                                        // marginTop: -5,
                                                        width: '100%',
                                                        height: 4,
                                                        // overflow: 'hidden'
                                                    }}
                                                >
                                                    <Progress
                                                        percent={progress || 0}
                                                        status={progress < 100 ? 'active' : progress === 100 ? 'success' : 'normal'}
                                                        size="small"
                                                        showInfo={false}
                                                        strokeWidth={4}
                                                        strokeLinecap="square"
                                                        style={{
                                                            top: -10,
                                                            padding: 0,
                                                            height: 5,
                                                            position: 'relative',
                                                        }}
                                                    />
                                                    {/* <Line
														percent={50}
														// percent={Math.floor(Math.random() * 100) + 1}
														strokeWidth={5}
														trailWidth={5}
														trailColor="#fff"
														strokeColor="#1890ff"
														strokeLinecap="butt"
													/> */}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <div key={index} className={`player p${index} ${index === 0 ? 'top' : 'bottom'}`}>
                                            <div className="player-number">-</div>
                                            <div className="player-name">BYE</div>
                                            <MatchScore player={player} {...props} />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>

                    <div className="match-footer">
                        <div
                            style={{
                                paddingLeft: 5,
                                paddingRight: 5,
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>{index === totalRounds - 1 && stage && stage.players_advance_per_group === 2 ? null : <Text>{footerText}</Text>}</div>
                            {compact !== true && match.is_bye !== true ? (
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column' }}>
                                    {!match.start_time && !match.scheduled_time && followsMatch && (
                                        <React.Fragment>
                                            <Text style={{ color: '#888' }}>Follows Match {followsMatch}</Text>
                                        </React.Fragment>
                                    )}
                                    {match.scheduled_time && (
                                        <React.Fragment>
                                            <Text style={{ color: '#888', fontWeight: 700 }}>
                                                Scheduled: {moment(match.scheduled_time).format('M/D hh:mm A')} {timezone}
                                            </Text>
                                        </React.Fragment>
                                    )}
                                    {match.start_time && !match.end_time ? (
                                        <div style={{ display: 'flex' }}>
                                            <Text style={{ color: '#888' }}>
                                                {match.start_time && `${moment.utc(match.start_time).local().format('h:mm A z')} ${timezone}`}{' '}
                                            </Text>
                                            {/* <Icon type="clock-circle" style={{ marginRight: 5 }} /> */}
                                            <Text style={{ marginLeft: 2, color: match.end_time ? '#888888' : '#198fff' }}>
                                                {/* {moment
												.duration(moment.utc().diff(match.start_time, 'milliseconds'))
												.format('h [hrs] m [min]')} */}
                                                ({duration}){/* {match.start_time} {match.end_time} */}
                                                {/* {moment
												.utc(
													moment(match.end_time, 'DD/MM/YYYY HH:mm:ss').diff(
														moment(match.start_time, 'DD/MM/YYYY HH:mm:ss')
													)
												)
												.format('HH:mm:ss')} */}
                                                {/* {moment
											.duration(moment.utc().diff(match.start_time, 'milliseconds'))
											.humanize()} */}
                                            </Text>
                                        </div>
                                    ) : match.end_time ? (
                                        <div style={{ display: 'flex' }}>
                                            <Text style={{ color: '#888' }}>
                                                {match.end_time && `${moment.utc(match.end_time).local().format('h:mm A z')} ${timezone}`}{' '}
                                            </Text>
                                            <Text style={{ marginLeft: 2, color: match.end_time ? '#888888' : '#198fff' }}>({duration})</Text>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {/* {match && match.tournament_bracket_projections && match.tournament_bracket_projections[0] && (
                                                <Text style={{ color: '#888' }}>
                                                    Projected:{' '}
                                                    {`${moment.utc(match.tournament_bracket_projections[0].start_time).local().format('LT')} ${timezone}`}
                                                    <br />
                                                </Text>
                                            )} */}
                                        </React.Fragment>
                                    )}
                                    {/* {!match.start_time && !match.end_time && match.scheduled_time && (
                                        <React.Fragment>
                                            <Text style={{ color: '#888' }}>
                                                {moment(match.scheduled_time).format('lll')} {timezone}
                                            </Text>
                                        </React.Fragment>
                                    )} */}
                                </div>
                            ) : (
                                <div>
                                    {match.scheduled_time && (
                                        <React.Fragment>
                                            <Text style={{ color: '#888' }}>
                                                {moment(match.scheduled_time).format('lll')} {timezone}
                                            </Text>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {match.identifier.includes('W') && match.advance_to && stage && stage.players_advance_per_group === 2 && (
                    <div className="match-winner">
                        {winner ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {winner.country && <CircleFlag country={winner.country} />}

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                                    <Text>{getMatchTitle(winner)}</Text>
                                    <div>
                                        {winner.place === '1' ? (
                                            <TrophyFilled style={{ color: '#eab700', marginRight: 5 }} />
                                        ) : winner.place === '2' ? (
                                            <TrophyFilled style={{ color: '#9baab1', marginRight: 5 }} />
                                        ) : (
                                            winner.place === '3' && <TrophyFilled style={{ color: '#f19921', marginRight: 5 }} />
                                        )}
                                    </div>
                                    <div style={{ position: 'absolute', bottom: -25, color: '#ff6163', left: 0 }}>{footerText}</div>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <Text>Winner of match {match.match_number}</Text>
                                <div style={{ position: 'absolute', bottom: -25, color: '#ff6163', left: 0 }}>{footerText}</div>
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
            <VideoOverlay
                {...props}
                video={selectedVideo}
                match={match}
                onOk={handleVideoModalOk}
                onCancel={handleVideoModalCancel}
                modalVisible={videoModalVisible}
            />
        </div>
    );
}

export default view(Match);
