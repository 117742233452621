import React, { useState, useEffect } from 'react';
import { message, Alert, Button, Typography, List, Modal, Tooltip } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_PENDING_MATCHES_SUBSCRIPTION } from '../data/subscriptions';
import CircularLoader from '../../../../components/CircularLoader';
import pluralize from 'pluralize';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function EmptyIcon() {
    return <div></div>;
}

export default function PendingMatches(props) {
    const { theme, tournament, tables } = props;

    function showConfirmPendingScores(pending) {
        const key = 'pending';
        console.log(pending);
        let needsApproval = [];
        pending &&
            pending.forEach((match) => {
                needsApproval.push(`Match ${match.match_number} on ${match.table_name} - ${match.challenger1_name} vs. ${match.challenger2_name}`);
            });

        // message.warning({
        //     content: (
        //         <Alert
        //             message={`${pending.length} pending ${pluralize('match', pending.length)} ${pending.length > 1 ? 'need' : 'needs'} your approval`}
        //             description={
        //                 <div>
        //                     {needsApproval.map((match, index) => (
        //                         <div key={index}>{match}</div>
        //                     ))}
        //                 </div>
        //             }
        //             type="warning"
        //         />
        //     ),
        //     icon: <EmptyIcon />,
        //     key,
        //     top: 200,
        //     duration: 10,
        //     // getContainer: () => document.querySelector('.section-header'),
        // });

        message.success({
            content: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <b>
                            {pending.length} pending {pluralize('match', pending.length)} {pending.length > 1 ? 'need' : 'needs'} your approval
                        </b>
                    </div>
                    <div>
                        {needsApproval.map((match, index) => (
                            <div key={index}>{match}</div>
                        ))}
                    </div>
                </div>
            ),
            key,
            top: 100,
            duration: 10,
        });

        // Modal.info({
        //     title: `${pending.length} pending ${pluralize('match', pending.length)} ${pending.length > 1 ? 'need' : 'needs'} your approval`,
        //     centered: true,
        //     transitionName: 'fade',
        //     maskTransitionName: 'none',
        //     content: (
        //         <div>
        //             {needsApproval.map((match, index) => (
        //                 <div key={index}>{match}</div>
        //             ))}
        //         </div>
        //     ),
        //     onOk() {
        //         console.log('ok');
        //     },
        //     okText: 'Review',
        // });
    }

    return (
        <React.Fragment>
            <Subscription
                subscription={GET_PENDING_MATCHES_SUBSCRIPTION}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange
                variables={{ tournamentId: tournament.id }}
                onSubscriptionData={(res) => {
                    const matches = res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.tournament_brackets;
                    console.log('pending matches', matches);

                    if (matches && matches.length > 0) {
                        let needsApproval = [];
                        matches.forEach((match) => {
                            needsApproval.push(`Match ${match.match_number} - ${match.challenger1_name} vs. ${match.challenger2_name}`);
                        });

                        showConfirmPendingScores(matches);
                    }
                }}
            >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div style={styles.container}>
                                <CircularLoader />
                            </div>
                        );
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    return null;
                }}
            </Subscription>
        </React.Fragment>
    );
}
