import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import poweredByLogo from '../../../assets/img/powered-by-logo.png';
import CircleFlag from '../../../components/CircleFlag';
import EventCountdownTimer from '../events/EventCountdownTimer';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import RackTracker from './RackTracker';
import VideoJS from './VideoJS';
import CircularLoader from '../../../components/CircularLoader';
import { GET_EVENT_LIVE_TABLE_SHOT_CLOCK_QUERY } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SUBSCRIPTION, GET_EVENT_LIVE_TABLE_SHOT_CLOCK_SUBSCRIPTION } from '../data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import qs from 'query-string';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

function TVDisplayFire(props) {
    const { tournamentData, eventSlug, tableSlug, matchData, shotClockData, tableData, subscribeToMore, subscribeToNewData } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const scale = (params && params.scale) || 1.0;
    const [key, setKey] = useState(0);
    const challenger1 = matchData && matchData.challenger1;
    const challenger2 = matchData && matchData.challenger2;

    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData) {
        if (matchData.identifier && matchData.identifier.includes('L')) {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        } else {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        }
    }

    const playerRef = React.useRef(null);

    const currentGame = matchData && matchData.tournament_match_games && matchData.tournament_match_games[matchData.tournament_match_games.length - 1];
    const duration = shotClockData ? shotClockData.duration / 1000 : 0;
    const textColor = shotClockData && shotClockData.text_color;
    const remainingTime = shotClockData ? shotClockData.remaining_time / 1000 : 0;
    const command = shotClockData && shotClockData.command;

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const videoJsOptions = {
        // lookup the options in the docs for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
            {
                src: 'https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8',
                type: 'application/x-mpegURL',
            },
        ],
    };

    // <source src="https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8" type="application/x-mpegURL" />

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    function totalSetsWon(player, sets) {
        if (player === 'challenger1') {
            return sets
                .map((set) => {
                    return set.challenger1_score === set.challenger1_race_to;
                })
                .reduce((acc, total) => {
                    return acc + total;
                }, 0);
        } else if (player === 'challenger2') {
            return sets
                .map((set) => {
                    return set.challenger2_score === set.challenger2_race_to;
                })
                .reduce((acc, total) => {
                    return acc + total;
                }, 0);
        }
    }

    function formatPlayerName(matchData, challenger) {
        if (challenger === 'challenger1') {
            return ((matchData && matchData.challenger1_name && matchData.challenger1_skill_level) || (challenger1 && challenger1.skill_level)) &&
                matchData.challenger1_race_to &&
                tournamentData.show_player_skill_levels === true &&
                tournamentData.show_player_races === true
                ? matchData &&
                      `${matchData.challenger1_name} ${matchData.challenger1_skill_level || (challenger1 && challenger1.skill_level)} (${
                          matchData.challenger1_race_to
                      })`
                : matchData && matchData.challenger1_name && matchData.challenger1_race_to && tournamentData.show_player_races === true
                ? matchData && `${matchData.challenger1_name} (${matchData.challenger1_race_to})`
                : matchData && `${matchData.challenger1_name}`;
        } else if (challenger === 'challenger2') {
            return ((matchData && matchData.challenger2_name && matchData.challenger2_skill_level) || (challenger1 && challenger2.skill_level)) &&
                matchData.challenger2_race_to &&
                tournamentData.show_player_skill_levels === true &&
                tournamentData.show_player_races === true
                ? matchData &&
                      `${matchData.challenger2_name} ${matchData.challenger2_skill_level || (challenger1 && challenger2.skill_level)} (${
                          matchData.challenger2_race_to
                      })`
                : matchData && matchData.challenger2_name && matchData.challenger2_race_to && tournamentData.show_player_races === true
                ? matchData && `${matchData.challenger2_name} (${matchData.challenger2_race_to})`
                : matchData && `${matchData.challenger2_name}`;
        }
    }

    return (
        <div style={{ width: '100%', transform: `scale(${scale}) translateZ(0px)` }}>
            <div
                style={{
                    top: 250,
                    position: 'absolute',
                    marginLeft: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 100,
                    padding: 20,
                    backgroundColor: '#07090d',
                    borderRadius: '50%',
                }}
            >
                {tournamentData && (
                    <EventCountdownTimer
                        currentKey={key}
                        command={command}
                        duration={duration}
                        initialRemainingTime={remainingTime}
                        colorsTime={[30, 15, 5, 0]}
                        colors={['#00aa00', '#F7B801', '#A30000', '#A30000']}
                        textColor="FFF"
                        shouldRepeat={false}
                        size={120}
                        fontSize={70}
                        strokeWidth={6}
                        trailStrokeWidth={6}
                        subscribeToMore={subscribeToMore}
                        subscribeToNewData={() => {
                            subscribeToMore({
                                document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                variables: { event_slug: eventSlug, table_slug: tableSlug },
                                updateQuery: (prev, { subscriptionData }) => {
                                    if (!subscriptionData.data) return prev;
                                    const newTournament = subscriptionData.data.tournaments;
                                    let updated;
                                    if (prev) {
                                        updated = Object.assign({}, prev, {
                                            tournaments: [...newTournament],
                                        });
                                        setTimeout(() => {
                                            setKey((prevKey) => prevKey + 1);
                                        }, 100);
                                    } else {
                                        updated = Object.assign({}, prev, {
                                            tournaments: [...newTournament],
                                        });
                                        setTimeout(() => {
                                            setKey((prevKey) => prevKey + 1);
                                        }, 100);
                                    }
                                    return updated;
                                },
                            });
                        }}
                    />
                )}
            </div>
            <Row gutter={16} type="flex" justify="space-between">
                <Col xs={12} sm={12} align="center">
                    <Title level={2} style={{ marginTop: 0, marginBottom: 0, color: '#fff' }}>
                        {tableData.label}
                    </Title>
                </Col>

                <Col xs={12} sm={12} align="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Avatar size={28} src={getGameType(tournamentData.game_type)} style={{ width: 30, marginRight: 10 }} />
                        <Title level={2} style={{ marginTop: 0, marginBottom: 0, color: '#fff' }}>
                            Race to{' '}
                            {matchData.challenger1_race_to && matchData.challenger2_race_to
                                ? `${challenger1RaceTo}/${challenger2RaceTo}`
                                : matchData.tournament && matchData.tournament.winners_race_to}
                        </Title>
                    </div>
                </Col>
            </Row>
            <Row gutter={24} type="flex" justify="space-between">
                <Col xs={12} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            background:
                                matchData.challenger1_score > matchData.challenger2_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(-45deg, black, transparent)',
                            // background: 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)',
                            borderRadius: 30,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: 20,
                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                        }}
                    >
                        {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                            <div
                                style={{
                                    padding: 10,
                                    position: 'absolute',
                                    bottom: 30,
                                    left: 30,
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 50, textTransform: 'uppercase' }}>
                                    {matchData.challenger1_points || 0}
                                </Title>
                                <Title level={1} style={{ margin: 0, color: '#fff', textTransform: 'uppercase' }}>
                                    {matchData.challenger1_points === 1 ? 'Ball' : 'Balls'}
                                </Title>
                            </div>
                        )}

                        <div style={{ marginTop: 20, marginBottom: 5, paddingLeft: 20 }}>
                            {matchData.challenger1_country && (
                                <CircleFlag
                                    country={getCountryName(matchData.challenger1_country) || 'Unknown'}
                                    size="large"
                                    style={{ transform: 'scale(1.5)' }}
                                />
                            )}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 40, textAlign: 'center', textTransform: 'uppercase' }}>
                                {formatPlayerName(matchData, 'challenger1')}
                            </Title>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ margin: 0, fontSize: 150, lineHeight: '130px', fontWeight: 700, color: '#fff' }}>
                                {tournamentData.enable_sets === true ? (
                                    <React.Fragment>
                                        {(matchData.tournament_match_sets &&
                                            matchData.tournament_match_sets[matchData.current_set - 1 || 0] &&
                                            matchData.tournament_match_sets[matchData.current_set - 1 || 0].challenger1_score) ||
                                            0}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>{matchData.challenger1_score || 0}</React.Fragment>
                                )}
                            </Text>
                        </div>
                        {matchData.challenger1_is_playing === true && <div className="arrow-right"></div>}
                        {currentGame && currentGame.player_tpa && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255,255,255, 0.05)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRadius: 10,
                                    fontSize: 20,
                                }}
                            >
                                <Text style={{ fontSize: 30, color: '#fff' }}>
                                    TPA: <b>{currentGame.player_tpa}</b>
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            background:
                                matchData.challenger2_score > matchData.challenger1_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(45deg, black, transparent)',
                            // background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                            borderRadius: 30,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: 20,
                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                        }}
                    >
                        {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                            <div
                                style={{
                                    padding: 10,
                                    position: 'absolute',
                                    bottom: 30,
                                    right: 30,
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 50, textTransform: 'uppercase' }}>
                                    {matchData.challenger2_points || 0}
                                </Title>
                                <Title level={1} style={{ margin: 0, color: '#fff', textTransform: 'uppercase' }}>
                                    {matchData.challenger2_points === 1 ? 'Ball' : 'Balls'}
                                </Title>
                            </div>
                        )}

                        <div style={{ marginTop: 20, marginBottom: 5, paddingLeft: 20 }}>
                            {matchData.challenger2_country && (
                                <CircleFlag
                                    country={getCountryName(matchData.challenger2_country) || 'Unknown'}
                                    size="large"
                                    style={{ transform: 'scale(1.5)' }}
                                />
                            )}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 40, textAlign: 'center', textTransform: 'uppercase' }}>
                                {formatPlayerName(matchData, 'challenger2')}
                            </Title>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ margin: 0, fontSize: 150, lineHeight: '130px', fontWeight: 700, color: '#fff' }}>
                                {tournamentData.enable_sets === true ? (
                                    <React.Fragment>
                                        {(matchData.tournament_match_sets &&
                                            matchData.tournament_match_sets[matchData.current_set - 1 || 0] &&
                                            matchData.tournament_match_sets[matchData.current_set - 1 || 0].challenger2_score) ||
                                            0}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>{matchData.challenger2_score || 0}</React.Fragment>
                                )}
                            </Text>
                        </div>
                        {matchData.challenger2_is_playing === true && <div className="arrow-left"></div>}
                        {currentGame && currentGame.opponent_tpa && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255,255,255, 0.05)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRsxadius: 10,
                                    fontSize: 20,
                                }}
                            >
                                <Text style={{ fontSize: 30, color: '#fff' }}>
                                    TPA: <b>{currentGame.opponent_tpa}</b>
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>

            <Row gutter={16} type="flex" justify="space-between">
                <Col xs={24} align="center">
                    <div style={{ marginTop: 10, display: 'inline-block', transform: `scale(0.9) translateZ(0px)`, position: 'relative', left: -30 }}>
                        <div>
                            {tournamentData.enable_sets === true ? (
                                <div
                                    style={{
                                        border: '1px solid rgba(255,255,255,0.3)',
                                        borderRadius: 10,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <div style={{ textAlign: 'right', display: 'flex', flex: 1, alignItems: 'center', paddingLeft: 5 }}>
                                            {matchData && matchData.challenger1_country && (
                                                <CircleFlag country={getCountryName(matchData.challenger1_country) || 'Unknown'} size="medium" />
                                            )}
                                            <Text
                                                style={{
                                                    fontSize: 30,
                                                    textAlign: 'left',
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 'bold',
                                                    marginRight: 10,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {matchData.challenger1_name}
                                            </Text>
                                        </div>
                                        {matchData.tournament_match_sets &&
                                            matchData.tournament_match_sets.map((set, index) => {
                                                return (
                                                    <div key={index} style={{ padding: 5, width: 30, textAlign: 'center' }}>
                                                        <Text
                                                            style={{
                                                                fontSize: 30,
                                                                color: set.challenger1_score === set.challenger1_race_to && '#86d068',
                                                                fontWeight:
                                                                    set.challenger1_score === set.challenger1_race_to ||
                                                                    set.challenger1_score > set.challenger2_score
                                                                        ? 'bold'
                                                                        : 'normal',
                                                            }}
                                                        >
                                                            {set.challenger1_score}
                                                        </Text>
                                                    </div>
                                                );
                                            })}
                                        <div
                                            style={{
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                textAlign: 'center',
                                                backgroundColor: 'rgba(0,0,0,0.8)',
                                                borderRadius: 10,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: 30,
                                                    color:
                                                        totalSetsWon('challenger1', matchData.tournament_match_sets) >
                                                            totalSetsWon('challenger2', matchData.tournament_match_sets) && '#86d068',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {totalSetsWon('challenger1', matchData.tournament_match_sets) || 0}
                                            </Text>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <div style={{ textAlign: 'right', display: 'flex', flex: 1, alignItems: 'center', paddingLeft: 5 }}>
                                            {matchData && matchData.challenger2_country && (
                                                <CircleFlag country={getCountryName(matchData.challenger2_country) || 'Unknown'} size="medium" />
                                            )}
                                            <Text
                                                style={{
                                                    fontSize: 30,
                                                    textAlign: 'left',
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 'bold',
                                                    marginRight: 10,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {matchData.challenger2_name}
                                            </Text>
                                        </div>
                                        {matchData.tournament_match_sets &&
                                            matchData.tournament_match_sets.map((set, index) => {
                                                return (
                                                    <div key={index} style={{ padding: 5, width: 30, textAlign: 'center' }}>
                                                        <Text
                                                            style={{
                                                                fontSize: 30,

                                                                color: set.challenger2_score === set.challenger2_race_to && '#86d068',
                                                                fontWeight:
                                                                    set.challenger2_score === set.challenger2_race_to ||
                                                                    set.challenger2_score > set.challenger1_score
                                                                        ? 'bold'
                                                                        : 'normal',
                                                            }}
                                                        >
                                                            {set.challenger2_score}
                                                        </Text>
                                                    </div>
                                                );
                                            })}
                                        <div
                                            style={{
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                textAlign: 'center',
                                                backgroundColor: 'rgba(0,0,0,0.8)',
                                                borderRadius: 10,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: 30,
                                                    color:
                                                        totalSetsWon('challenger2', matchData.tournament_match_sets) >
                                                            totalSetsWon('challenger1', matchData.tournament_match_sets) && '#86d068',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {totalSetsWon('challenger2', matchData.tournament_match_sets) || 0}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <RackTracker matchData={matchData} tournamentData={tournamentData} />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(TVDisplayFire);
