import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Button, Icon, Modal, Typography } from 'antd';
import { Query } from '@apollo/client/react/components';
import AddEventManagerModal from './AddEventManagerModal';
import { GET_EVENT_MANAGERS, CHECK_EVENT_MANAGERS_INVITE_USER } from '../data/queries';
import { DELETE_EVENT_MANAGER, DELETE_EVENT_MANAGER_INVITE_KEY } from '../data/mutations';
import CircularLoader from '../../../components/CircularLoader';

const { Title, Text } = Typography;
const { confirm } = Modal;

function EventManagerTable(props) {
    const { client, authState, event, onContinue, goBack, ownerId, userId } = props;
    const [addManagerModalVisible, setAddManagerModalVisible] = useState(false);
    const [tableDataSource, setTableDataSource] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            key: 'name',
            width: 200,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            sorter: (a, b) => {
                return a.role.localeCompare(b.role);
            },
        },
        {
            title: 'Accepted',
            dataIndex: 'accepted_invite',
            key: 'accepted_invite',
            sorter: (a, b) => {
                return a.accepted_invite.localeCompare(b.accepted_invite);
            },
            render: (text, record) => (
                <React.Fragment>
                    <span style={record.accepted_invite === 'Yes' ? { color: 'green' } : record.accepted_invite === 'No' ? { color: 'red' } : {}}>
                        {text || '-'}
                    </span>
                </React.Fragment>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return record.user_id !== userId && record.user_id !== ownerId ? (
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Delete">
                            <Button
                                type="ghost"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    confirm({
                                        title: `Are you sure you want to delete this manager?`,
                                        centered: true,
                                        transitionName: 'fade',
                                        maskTransitionName: 'none',
                                        async onOk() {
                                            await client.mutate({
                                                mutation: DELETE_EVENT_MANAGER,
                                                variables: {
                                                    id: record.id,
                                                },
                                                notifyOnNetworkStatusChange: true,
                                                awaitRefetchQueries: true,
                                                refetchQueries: [
                                                    {
                                                        query: GET_EVENT_MANAGERS,
                                                        variables: { event_id: event.id },
                                                    },
                                                ],
                                            });

                                            if (!record.email) return; // the user was not invited

                                            let managerInviteKeyCheck = await client.query({
                                                query: CHECK_EVENT_MANAGERS_INVITE_USER,
                                                variables: {
                                                    event_id: event.id,
                                                    email: record.email,
                                                },
                                            });

                                            if (
                                                managerInviteKeyCheck &&
                                                managerInviteKeyCheck.data &&
                                                managerInviteKeyCheck.data.event_managers_invite_keys &&
                                                managerInviteKeyCheck.data.event_managers_invite_keys[0]
                                            ) {
                                                console.log('mi', managerInviteKeyCheck);
                                                await client.mutate({
                                                    mutation: DELETE_EVENT_MANAGER_INVITE_KEY,
                                                    variables: {
                                                        event_id: event.id,
                                                        email: record.email,
                                                    },
                                                });
                                            }
                                        },
                                        onCancel() {},
                                        cancelText: 'No',
                                        okText: 'Yes',
                                    });
                                }}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip>
                    </span>
                ) : (
                    <div></div>
                );
            },
        },
    ];

    function handleAddManager() {
        setAddManagerModalVisible(true);
    }

    return (
        <React.Fragment>
            <div
                style={{
                    marginBottom: 20,
                }}
            >
                <div>
                    <Title level={4} style={{ fontSize: 18 }}>
                        Add Event Managers
                    </Title>
                    <Text>Share access to this event with other people and they will be able to create new tournaments and matches within it.</Text>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Button type="primary" onClick={handleAddManager}>
                        <Icon type="user-add" /> Add Manager
                    </Button>
                </div>
            </div>
            {event && event.id && (
                <Query
                    query={GET_EVENT_MANAGERS}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{
                        event_id: event.id,
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return null;

                        let eventManagers = data && data.event_managers;
                        let dataSource = [];
                        let eventManagerObj, manager;

                        console.log('managers', eventManagers);

                        for (let i = 0; i < eventManagers.length; i++) {
                            manager = eventManagers[i];

                            eventManagerObj = {
                                id: manager.id,
                                email: manager.email,
                                user_id: manager && manager.manager !== null ? manager.manager.id : '',
                                name: manager.manager
                                    ? manager.manager.first_name
                                        ? `${manager.manager.first_name} ${manager.manager.last_name}`
                                        : manager.manager.display_name
                                    : manager.email,
                                accepted_invite: manager.invite_accepted ? 'Yes' : 'No',
                                role: manager && manager.manager && ownerId === manager.manager.id ? 'Owner' : 'Manager',
                                key: i,
                            };

                            dataSource.push(eventManagerObj);
                        }

                        return (
                            <Table
                                columns={columns}
                                // dataSource={dataSource.filter(item => item.user_id !== userId && item.user_id !== eventDirectorId)}
                                dataSource={dataSource}
                                pagination={false}
                                tableLayout="auto"
                                scroll={{ x: 400 }}
                                size="default"
                            />
                        );
                    }}
                </Query>
            )}
            <AddEventManagerModal
                userId={authState.user.id}
                eventId={event.id}
                eventSlug={event.slug}
                eventName={event.name}
                authState={props.authState}
                client={props.client}
                visible={addManagerModalVisible}
                onOk={() => {
                    setAddManagerModalVisible(false);
                }}
                onCancel={() => {
                    setAddManagerModalVisible(false);
                }}
            />

            <Button type="primary" size="large" onClick={onContinue} style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text style={{ color: '#fff' }}>Review Event</Text>
                </div>
            </Button>
            <Button
                type="secondary"
                size="large"
                onClick={() => {
                    goBack();
                }}
                style={{ marginTop: 20 }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text>Go back</Text>
                </div>
            </Button>
        </React.Fragment>
    );
}

export default EventManagerTable;
