import React, { useState, useEffect, useRef } from 'react';
import { message, Tooltip, Button, Select, Icon, Typography, Popconfirm, Avatar, Drawer, Badge, Empty, Modal, Menu, Dropdown } from 'antd';
import { Form, FormItem, AutoComplete, Input, Radio, InputNumber } from 'formik-antd';
import { FILTER_TOURNAMENT_PLAYERS } from '../data/queries';
import _ from 'lodash';

const { Option, OptGroup } = Select;

export default function MatchOverlayScores(props) {
    const { formRef, currentMatch, tournament, set, index, values, onSetWinner, players, onMenuClick, theme, onSetChallenger1Result, onSetChallenger2Result } =
        props;
    const [players1, setPlayers1] = useState([]);
    const [players2, setPlayers2] = useState([]);

    const currentValues = (formRef && formRef.current && formRef.current.state && formRef.current.state.values) || values;

    const setFormField =
        currentMatch.tournament_match_sets && currentMatch.tournament_match_sets.length > 0
            ? `sets.${index}`
            : currentValues && currentValues.sets && currentValues.sets.length > 0 && `sets.${index}`;
    console.log(setFormField);
    console.log('current match', currentMatch);
    console.log('values', values);

    const challenger1Score = currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger1_score : currentValues.challenger1_score;
    const challenger1Winner =
        currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger1_is_winner : currentValues.challenger1_is_winner;
    const challenger1Forfeit =
        currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger1_is_forfeit : currentValues.challenger1_is_forfeit;
    const challenger1Withdraw =
        currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger1_is_withdraw : currentValues.challenger1_is_withdraw;
    const challenger1Race = currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger1_race_to : currentValues.challenger1_race_to;
    const challenger1Split = currentValues.is_split;

    const challenger2Score = currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger2_score : currentValues.challenger2_score;
    const challenger2Winner =
        currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger2_is_winner : currentValues.challenger2_is_winner;
    const challenger2Forfeit =
        currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger2_is_forfeit : currentValues.challenger2_is_forfeit;
    const challenger2Withdraw =
        currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger2_is_withdraw : currentValues.challenger2_is_withdraw;
    const challenger2Race = currentValues.sets && currentValues.sets[index] ? currentValues.sets[index].challenger2_race_to : currentValues.challenger2_race_to;
    const challenger2Split = currentValues.is_split;

    const challenger1Result =
        challenger1Winner === true
            ? 'winner'
            : challenger1Forfeit === true
            ? 'forfeit'
            : challenger1Withdraw === true
            ? 'withdraw'
            : challenger1Split === true
            ? 'split'
            : null;
    const challenger2Result =
        challenger2Winner === true
            ? 'winner'
            : challenger2Forfeit === true
            ? 'forfeit'
            : challenger2Withdraw === true
            ? 'withdraw'
            : challenger2Split === true
            ? 'split'
            : null;

    async function queryDB(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: FILTER_TOURNAMENT_PLAYERS,
            variables: {
                name: `%${name}%`,
                tournament_id: tournament.id,
            },
            fetchPolicy: 'no-cache',
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            dataSet.push(player);
        }
        return dataSet;
    }

    async function onSearch1(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        console.log(players);
        setPlayers1(players);
    }

    async function onSearch2(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        console.log(players);
        setPlayers2(players);
    }

    const onSelectPlayer1 = async (value) => {
        console.log(value);
    };

    const onSelectPlayer2 = async (value) => {
        console.log(value);
    };

    function handleFocus(event) {
        event.target.select();
    }

    function handleMenuClick(e, position) {
        console.log('click', e);

        // make a copy of the sets values
        const tempState = currentValues && currentValues.sets ? [...currentValues.sets] : [];
        // find current set index
        const tempItemIndex = _.findIndex(
            tempState,
            (item) => {
                return item.number === set.number;
            },
            0
        );

        const matchData = {
            challenger1_is_forfeit: e.key === 'forfeit' && position === 'top' ? true : false,
            challenger2_is_forfeit: e.key === 'forfeit' && position === 'bottom' ? true : false,
            challenger1_is_withdraw: e.key === 'withdraw' && position === 'top' ? true : false,
            challenger2_is_withdraw: e.key === 'withdraw' && position === 'bottom' ? true : false,
            is_split: e.key === 'split' ? true : false,
        };

        if (position === 'top') {
            if (e.key === 'forfeit' || e.key === 'withdraw') {
                matchData.challenger1_score = 0;
                matchData.challenger2_score = 0;
                matchData.challenger1_is_winner = false;
                matchData.challenger2_is_winner = true;
            } else if (e.key === 'split') {
                matchData.challenger1_score = 0;
                matchData.challenger2_score = 0;
                matchData.challenger1_is_winner = false;
                matchData.challenger2_is_winner = false;
            } else if (e.key === 'winner') {
                matchData.challenger1_score = challenger1Score;
                matchData.challenger2_score = challenger2Score;
                matchData.challenger1_is_winner = true;
                matchData.challenger2_is_winner = false;
            } else {
                matchData.challenger1_is_winner = false;
                matchData.challenger2_is_winner = false;
            }
        } else if (position === 'bottom') {
            if (e.key === 'forfeit' || e.key === 'withdraw') {
                matchData.challenger1_score = 0;
                matchData.challenger2_score = 0;
                matchData.challenger1_is_winner = true;
                matchData.challenger2_is_winner = false;
            } else if (e.key === 'split') {
                matchData.challenger1_score = 0;
                matchData.challenger2_score = 0;
                matchData.challenger1_is_winner = false;
                matchData.challenger2_is_winner = false;
            } else if (e.key === 'winner') {
                matchData.challenger1_score = challenger1Score;
                matchData.challenger2_score = challenger2Score;
                matchData.challenger1_is_winner = false;
                matchData.challenger2_is_winner = true;
            } else {
                matchData.challenger1_is_winner = false;
                matchData.challenger2_is_winner = false;
            }
        }

        // modify the set values based on the menu selections
        const tempItem = {
            ...tempState
                .filter((item) => {
                    return item.number === set.number;
                })
                .map((data) => {
                    return {
                        ...data,
                        ...matchData,
                    };
                })[0],
        };
        // update the copied sets array with new changes
        tempState[tempItemIndex] = tempItem;
        console.log(tempItem);
        // save over existing set values
        formRef.current.setValues({
            ...currentValues,
            ...matchData,
            sets: tempState,
        });

        if (position === 'top') {
            if (e.key === 'none') {
                onSetChallenger1Result();
            } else {
                onSetChallenger1Result(e.key);
            }
            onSetChallenger2Result();
        } else if (position === 'bottom') {
            if (e.key === 'none') {
                onSetChallenger2Result();
            } else {
                onSetChallenger2Result(e.key);
            }
            onSetChallenger1Result();
        }
    }

    function onSetChange(score, player, set) {
        console.log({
            score,
            player,
            set,
        });
        determineWinner(score, player, set);
    }

    function determineWinner(score, challenger, set) {
        let challenger1Wins = 0;
        let challenger2Wins = 0;

        console.log(score, challenger);
        const currentValues = formRef.current.state.values;
        let challenger1Score = challenger === 'challenger1' ? score : currentValues.challenger1_score;
        let challenger2Score = challenger === 'challenger2' ? score : currentValues.challenger2_score;

        if (tournament.enable_sets === true) {
            let setResults =
                currentValues &&
                currentValues.sets &&
                currentValues.sets.map((item, index) => {
                    console.log('item', item.number);
                    console.log('current set', set.number);
                    if (item.number === set.number) {
                        if (tournament.set_tiebreaker_format === 'most_points_won' && index === currentValues.sets.length - 1) {
                            console.log('last set shootout');
                            return {
                                challenger1_is_winner: challenger1Score > challenger2Score ? true : false,
                                challenger2_is_winner: challenger2Score > challenger1Score ? true : false,
                            };
                        } else {
                            console.log('other sets');
                            return {
                                challenger1_is_winner: challenger1Score === challenger1Race ? true : false,
                                challenger2_is_winner: challenger2Score === challenger1Race ? true : false,
                            };
                        }
                    } else {
                        return {
                            challenger1_is_winner: challenger1Winner,
                            challenger2_is_winner: challenger2Winner,
                        };
                    }
                });

            challenger1Wins = setResults
                .map((result) => (result.challenger1_is_winner === true ? 1 : 0))
                .reduce((acc, total) => {
                    console.log({
                        player: 1,
                        acc,
                        total,
                    });
                    return acc + total;
                }, 0);
            challenger2Wins = setResults
                .map((result) => (result.challenger2_is_winner === true ? 1 : 0))
                .reduce((acc, total) => {
                    console.log({
                        player: 2,
                        acc,
                        total,
                    });
                    return acc + total;
                }, 0);

            console.log('set results', setResults);
            console.log('challenger1Wins', challenger1Wins);
            console.log('challenger2Wins', challenger2Wins);
        }

        let matchWinner;
        if (tournament.enable_sets === true) {
            matchWinner =
                challenger1Wins > challenger2Wins ? currentMatch.challenger1_id : challenger2Wins > challenger1Wins ? currentMatch.challenger2_id : null;
        } else {
            matchWinner =
                challenger1Score === currentValues.challenger1_race_to
                    ? currentMatch.challenger1_id
                    : challenger2Score === currentValues.challenger2_race_to
                    ? currentMatch.challenger2_id
                    : null;
        }

        formRef.current.setValues({
            ...currentValues,
            ...(tournament.enable_sets === true
                ? {
                      sets: [
                          ...currentValues.sets.map((set, index2) => {
                              if (index === index2) {
                                  return {
                                      ...set,
                                      ...(challenger === 'challenger1' ? { challenger1_score: score } : { challenger2_score: score }),
                                      challenger1_is_winner: challenger1Wins > challenger2Wins,
                                      challenger2_is_winner: challenger2Wins > challenger1Wins,
                                  };
                              } else {
                                  return set;
                              }
                          }),
                      ],
                  }
                : {
                      challenger1_score: challenger1Score,
                      challenger2_score: challenger2Score,
                      challenger1_is_winner: currentValues.challenger1_is_winner
                          ? currentValues.challenger1_is_winner
                          : challenger1Result === 'winner'
                          ? true
                          : false,
                      challenger2_is_winner: currentValues.challenger2_is_winner
                          ? currentValues.challenger2_is_winner
                          : challenger2Result === 'winner'
                          ? true
                          : false,
                  }),
        });

        if (matchWinner) {
            // message.success(`${matchWinner} won the match ${challenger1Wins}-${challenger2Wins}`);

            console.log('updated values', values);

            if (onSetWinner) {
                if (challenger1Wins > challenger2Wins) {
                    console.log('set winner to player 1');
                    onSetWinner(matchWinner);
                } else if (challenger2Wins > challenger1Wins) {
                    console.log('set winner to player 2');
                    onSetWinner(matchWinner);
                } else if (challenger2Wins === challenger1Wins) {
                    console.log('sets are tied');
                    onSetWinner();
                } else {
                    console.log('set winner to none');
                    onSetWinner();
                }
            }
        }
    }

    const actionsMenuTop = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'top');
            }}
            selectedKeys={[challenger1Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            {currentMatch && (currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true) && <Menu.Item key="split">Split</Menu.Item>}
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Item key="withdraw">Withdraw</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    const actionsMenuBottom = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'bottom');
            }}
            selectedKeys={[challenger2Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            {currentMatch && (currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true) && <Menu.Item key="split">Split</Menu.Item>}
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Item key="withdraw">Withdraw</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th style={{ paddingBottom: 5 }}>Player</th>
                    {(!currentValues.sets || (currentValues.sets && currentValues.sets.length - 1 !== index)) && <th style={{ textAlign: 'left' }}>Race To</th>}
                    <th style={{ textAlign: 'left' }}>Score</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {currentMatch.challenger1_is_winner === true ||
                            currentMatch.is_split === true ||
                            challenger1Result === 'winner' ||
                            challenger2Result === 'forfeit' ||
                            challenger2Result === 'withdraw' ||
                            challenger1Result === 'split' ||
                            (tournament.set_tiebreaker_format === 'most_points_won'
                                ? challenger1Score > challenger2Score
                                : challenger1Score === challenger1Race) ? (
                                <span style={{ marginRight: 5 }}>
                                    <Avatar
                                        style={{
                                            backgroundColor: currentMatch.is_split === true || challenger1Result === 'split' ? '#2196f3' : '#87d068',
                                        }}
                                    >
                                        {currentMatch.is_split === true || challenger1Result === 'split' ? 'S' : 'W'}
                                    </Avatar>
                                </span>
                            ) : (
                                ((currentMatch.status === 'COMPLETED' && challenger1Winner === false) ||
                                    challenger2Result === 'winner' ||
                                    challenger1Result === 'forfeit' ||
                                    challenger1Result === 'withdraw' ||
                                    challenger2Result === 'split' ||
                                    (tournament.set_tiebreaker_format === 'most_points_won'
                                        ? challenger2Score > challenger1Score
                                        : challenger2Score === challenger2Race)) && (
                                    <span style={{ marginRight: 5 }}>
                                        <Avatar
                                            style={{
                                                backgroundColor: challenger2Result === 'split' ? '#2196f3' : '#f5222d',
                                            }}
                                        >
                                            {currentMatch.challenger1_is_forfeit || challenger1Result === 'split'
                                                ? 'FF'
                                                : currentMatch.challenger1_is_withdraw === true || challenger1Result === 'withdraw'
                                                ? 'WD'
                                                : challenger2Result === 'split'
                                                ? 'S'
                                                : 'L'}
                                        </Avatar>
                                    </span>
                                )
                            )}
                            <FormItem name={setFormField ? `${setFormField}.start_time` : 'start_time'}>
                                <Input name={setFormField ? `${setFormField}.start_time` : 'start_time'} type="hidden" />
                            </FormItem>
                            <FormItem name={setFormField ? `${setFormField}.challenger1_id` : 'challenger1_id'}>
                                <Input name={setFormField ? `${setFormField}.challenger1_id` : 'challenger1_id'} type="hidden" />
                            </FormItem>
                            <FormItem
                                name={setFormField ? `${setFormField}.challenger1_name` : 'challenger1_name'}
                                // required
                                style={{ width: '100%' }}
                            >
                                <AutoComplete
                                    dataSource={players1}
                                    style={{ width: '100%' }}
                                    onSelect={onSelectPlayer1}
                                    onSearch={onSearch1}
                                    name={setFormField ? `${setFormField}.challenger1_name` : 'challenger1_name'}
                                    placeholder="Player 1 Name"
                                    size="large"
                                    // allowClear
                                    // required
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) => {
                                        return option.props.children && option.props.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                    }}
                                    disabled={currentValues.sets && currentValues.sets.length > 0 ? true : false}
                                >
                                    {players1 &&
                                        players1.length > 0 &&
                                        players1.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.id.toString()}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                </AutoComplete>
                            </FormItem>
                        </div>
                    </td>
                    {(!currentValues.sets || (currentValues.sets && currentValues.sets.length - 1 !== index)) && (
                        <td
                            style={{
                                textAlign: 'center',
                                paddingLeft: 5,
                            }}
                        >
                            <FormItem name={setFormField ? `${setFormField}.challenger1_race_to` : 'challenger1_race_to'}>
                                <InputNumber
                                    name={setFormField ? `${setFormField}.challenger1_race_to` : 'challenger1_race_to'}
                                    placeholder="0"
                                    min={0}
                                    // value={score}
                                    // onChange={onChange}
                                    onFocus={handleFocus}
                                    // max={getChallengerRaceTo(
                                    // 	currentMatch,
                                    // 	'challenger1'
                                    // )}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}
                                    size="large"
                                    // disabled
                                    style={{ width: 80 }}
                                />
                            </FormItem>
                        </td>
                    )}

                    <td style={{ textAlign: 'center', paddingLeft: 5 }}>
                        <FormItem name={setFormField ? `${setFormField}.challenger1_score` : 'challenger1_score'}>
                            <InputNumber
                                name={setFormField ? `${setFormField}.challenger1_score` : 'challenger1_score'}
                                placeholder="0"
                                min={0}
                                max={
                                    tournament.enable_sets === true &&
                                    tournament.set_tiebreaker_format === 'most_points_won' &&
                                    currentValues.sets &&
                                    currentValues.sets.length - 1 === index
                                        ? undefined
                                        : currentValues.sets && currentValues.sets[index]
                                        ? currentValues.sets[index].challenger1_race_to
                                        : currentValues.challenger1_race_to
                                }
                                defaultValue={0}
                                // value={score}
                                onChange={(score) => {
                                    onSetChange(score, 'challenger1', set);
                                }}
                                onFocus={handleFocus}
                                // onBlur={handleBlur}
                                // style={styles.inputSmall}
                                // onPressEnter={onEnter}
                                style={{
                                    width: 80,
                                    backgroundColor: theme && theme.name === 'dark' ? '#093761' : '#bfe0ff',
                                }}
                                size="large"
                                disabled={
                                    challenger1Forfeit === true || challenger2Forfeit === true || challenger1Withdraw === true || challenger2Withdraw === true
                                        ? true
                                        : false
                                }
                            />
                        </FormItem>
                    </td>
                    {!set && (
                        <td>
                            <Dropdown
                                overlay={actionsMenuTop}
                                trigger={['click']}
                                disabled={challenger2Result !== 'winner' || (currentMatch && currentMatch.status === 'COMPLETED') ? false : true}
                            >
                                <Button size="large" style={{ marginLeft: 5 }}>
                                    {challenger1Result ? _.capitalize(challenger1Result) : 'Actions'} <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </td>
                    )}
                </tr>

                <tr>
                    <td style={{ width: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {currentMatch.challenger2_is_winner === true ||
                            currentMatch.is_split === true ||
                            challenger2Result === 'winner' ||
                            challenger1Result === 'forfeit' ||
                            challenger1Result === 'withdraw' ||
                            challenger2Result === 'split' ||
                            (tournament.set_tiebreaker_format === 'most_points_won'
                                ? challenger2Score > challenger1Score
                                : challenger2Score === challenger2Race) ? (
                                <span style={{ marginRight: 5 }}>
                                    <Avatar
                                        style={{
                                            backgroundColor: currentMatch.is_split === true || challenger2Result === 'split' ? '#2196f3' : '#87d068',
                                        }}
                                    >
                                        {currentMatch.is_split === true || challenger2Result === 'split' ? 'S' : 'W'}
                                    </Avatar>
                                </span>
                            ) : (
                                ((currentMatch.status === 'COMPLETED' && challenger2Winner === false) ||
                                    challenger1Result === 'winner' ||
                                    challenger2Result === 'forfeit' ||
                                    challenger2Result === 'withdraw' ||
                                    challenger1Result === 'split' ||
                                    (tournament.set_tiebreaker_format === 'most_points_won'
                                        ? challenger1Score > challenger2Score
                                        : challenger1Score === challenger1Race)) && (
                                    <span style={{ marginRight: 5 }}>
                                        <Avatar
                                            style={{
                                                backgroundColor: challenger1Result === 'split' ? '#2196f3' : '#f5222d',
                                            }}
                                        >
                                            {currentMatch.challenger2_is_forfeit || challenger2Result === 'forfeit'
                                                ? 'FF'
                                                : currentMatch.challenger2_is_withdraw === true || challenger2Result === 'withdraw'
                                                ? 'WD'
                                                : challenger1Result === 'split'
                                                ? 'S'
                                                : 'L'}
                                        </Avatar>
                                    </span>
                                )
                            )}
                            <FormItem name={setFormField ? `${setFormField}.challenger2_id` : 'challenger2_id'}>
                                <Input name={setFormField ? `${setFormField}.challenger2_id` : 'challenger2_id'} type="hidden" />
                            </FormItem>
                            <FormItem
                                name={setFormField ? `${setFormField}.challenger2_name` : 'challenger2_name'}
                                // required
                                style={{ width: '100%' }}
                            >
                                <AutoComplete
                                    dataSource={players2}
                                    style={{ width: '100%' }}
                                    onSelect={onSelectPlayer2}
                                    onSearch={onSearch2}
                                    name={setFormField ? `${setFormField}.challenger2_name` : 'challenger2_name'}
                                    placeholder="Player 2 Name"
                                    size="large"
                                    // allowClear
                                    // required
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) => {
                                        return option.props.children && option.props.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                    }}
                                    disabled={currentValues.sets && currentValues.sets.length > 0 ? true : false}
                                >
                                    {players2 &&
                                        players2.length > 0 &&
                                        players2.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.id.toString()}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                </AutoComplete>
                            </FormItem>
                        </div>
                    </td>
                    {(!currentValues.sets || (currentValues.sets && currentValues.sets.length - 1 !== index)) && (
                        <td
                            style={{
                                textAlign: 'center',
                                paddingLeft: 5,
                            }}
                        >
                            <FormItem name={setFormField ? `${setFormField}.challenger2_race_to` : 'challenger2_race_to'}>
                                <InputNumber
                                    name={setFormField ? `${setFormField}.challenger2_race_to` : 'challenger2_race_to'}
                                    placeholder="0"
                                    min={0}
                                    // max={getChallengerRaceTo(
                                    // 	currentMatch,
                                    // 	'challenger2'
                                    // )}
                                    // value={score}
                                    // onChange={onChange}
                                    onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}

                                    size="large"
                                    // disabled
                                    style={{ width: 80 }}
                                />
                            </FormItem>
                        </td>
                    )}
                    <td style={{ textAlign: 'center', paddingLeft: 5 }}>
                        <FormItem name={setFormField ? `${setFormField}.challenger2_score` : 'challenger2_score'}>
                            <InputNumber
                                name={setFormField ? `${setFormField}.challenger2_score` : 'challenger2_score'}
                                placeholder="0"
                                min={0}
                                defaultValue={0}
                                max={
                                    tournament.enable_sets === true &&
                                    tournament.set_tiebreaker_format === 'most_points_won' &&
                                    currentValues.sets &&
                                    currentValues.sets.length - 1 === index
                                        ? undefined
                                        : currentValues.sets && currentValues.sets[index]
                                        ? currentValues.sets[index].challenger2_race_to
                                        : currentValues.challenger2_race_to
                                }
                                // value={score}
                                onChange={(score) => {
                                    onSetChange(score, 'challenger2', set);
                                }}
                                onFocus={handleFocus}
                                // onBlur={handleBlur}
                                // style={styles.inputSmall}
                                // onPressEnter={onEnter}
                                style={{
                                    width: 80,
                                    backgroundColor: theme && theme.name === 'dark' ? '#093761' : '#bfe0ff',
                                }}
                                size="large"
                                disabled={
                                    challenger1Result === 'forfeit' ||
                                    challenger2Result === 'forfeit' ||
                                    challenger1Result === 'withdraw' ||
                                    challenger2Result === 'withdraw'
                                        ? true
                                        : false
                                }
                            />
                        </FormItem>
                    </td>

                    {!set && (
                        <td>
                            <Dropdown
                                overlay={actionsMenuBottom}
                                trigger={['click']}
                                disabled={challenger1Result !== 'winner' || (currentMatch && currentMatch.status === 'COMPLETED') ? false : true}
                            >
                                <Button size="large" style={{ marginLeft: 5 }}>
                                    {challenger2Result ? _.capitalize(challenger2Result) : 'Actions'} <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
}
