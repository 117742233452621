import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Checkbox, Modal, Button, Upload, Divider, Menu, Icon, Typography, Avatar, Dropdown } from 'antd';
import { Form, FormItem, Input, InputNumber, Radio, Select, SubmitButton, ResetButton, AutoComplete, DatePicker } from 'formik-antd';
import * as Yup from 'yup';
import { isoCountries } from '../../utils/isoCountryCodes';
import { Formik, ErrorMessage } from 'formik';
import { CountryRegionData } from 'react-country-region-selector';
import CircleFlag from '../../components/CircleFlag';
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';
import { Query, Mutation } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import { getGameType } from '../../utils';
import {
    GET_LEAGUE_QUERY,
    GET_LEAGUE_MATCH_QUERY,
    GET_LEAGUE_DIVISION_MATCHES_QUERY,
    GET_GAME_TYPES_QUERY,
    GET_DIVISION_TYPES_QUERY,
    GET_VENUES_QUERY,
    GET_LEAGUE_DIVISION_PLAYERS_QUERY,
    GET_LEAGUE_PLAYERS_QUERY,
    GET_LEAGUE_DIVISION_TEAMS_QUERY,
    GET_LEAGUE_VENUES_QUERY,
    SEARCH_LEAGUE_VENUES_QUERY,
    GET_LEAGUE_DIVISION_SCHEDULE,
    FILTER_LEAGUE_PLAYERS,
    FILTER_USERS,
    GET_LEAGUE_DIVISIONS_SELECT_QUERY,
    GET_VENUE_TABLES_QUERY,
} from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddVenueModal from './AddVenueModal';
import { CREATE_LEAGUE_MATCH, UPDATE_LEAGUE_MATCH, DELETE_LEAGUE_MATCH_TEAM, UPDATE_LEAGUE_MATCH_TEAM, CREATE_LEAGUE_MATCH_GAME } from './data/mutations';
import _ from 'lodash';
import moment from 'moment-timezone';

const { Title, Text } = Typography;
const { Option } = Select;

function AddTeamMatchModal(props) {
    const { authState, client, league, selectedMatchId, userID, leagueID, divisionID, scheduleID, modalVisible, onModalOk, onModalCancel, theme } = props;
    const [loading, setLoading] = useState(false);
    const [teams, setTeams] = useState();
    const [tables, setTables] = useState();
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [venueDataSource, setVenueDataSource] = useState('');
    const [createLeagueMatch, { createLoading, createError }] = useMutation(CREATE_LEAGUE_MATCH);
    const [updateLeagueMatch, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_MATCH);
    const [updateLeagueMatchTeam] = useMutation(UPDATE_LEAGUE_MATCH_TEAM);
    const [deleteLeagueMatchTeam] = useMutation(DELETE_LEAGUE_MATCH_TEAM);
    const [createLeagueMatchGame] = useMutation(CREATE_LEAGUE_MATCH_GAME);
    const [team1Winner, setTeam1Winner] = useState(false);
    const [team2Winner, setTeam2Winner] = useState(false);
    const formRef = useRef();

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleFocus(event) {
        event.target.select();
    }

    const submitAdd = (data, actions) => {
        const {
            name,
            division_id,
            home_team_id,
            home_team_score,
            home_team_points,
            visitor_team_id,
            visitor_team_score,
            visitor_team_points,
            scoring_format,
            match_type,
            game_type,
            table_id,
            venue_id,
            scheduled_time,
            status,
        } = data;
        console.log(data);
        console.log(teams);
        const table = tables.filter((table) => table.id === table_id);
        const homeTeam = teams.filter((team) => team.id === home_team_id)[0];
        const visitorTeam = teams.filter((team) => team.id === visitor_team_id)[0];
        // const matchName = name ? name : homeTeam && visitorTeam ? `${homeTeam.name} vs. ${visitorTeam.name}` : '';
        const match_teams = [homeTeam, visitorTeam];

        let slug;
        if (name) {
            slug = slugify(`${name} ${moment(scheduled_time).format('MM-DD-YYYY')}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        setLoading(true);

        const newData = {
            name: name,
            ...(slug && { slug: slug }),
            match_type,
            game_type,
            scoring_format,
            venue_id,
            league_id: leagueID,
            schedule_id: scheduleID,
            division_id,
            home_team_id,
            home_team_score,
            home_team_points,
            home_team_is_winner: team1Winner,
            visitor_team_id,
            visitor_team_score,
            visitor_team_points,
            visitor_team_is_winner: team2Winner,
            ...(table_id && { table_id: table_id }),
            ...(table && table.length && { table_name: table[0].label }),
            ...(table && table.length && { table_size: table[0].size }),
            scheduled_time,
            status,
            is_team_match: true,
            league_match_teams: {
                data:
                    match_teams &&
                    match_teams.map((item) => {
                        console.log(item);
                        const teamData = teams.filter((team) => team.id === item && item.id)[0];
                        console.log(teamData);
                        return {
                            league_id: leagueID,
                            league_team_id: item && item.id,
                            division_id: division_id,
                            name: teamData && teamData.name,
                        };
                    }),
                // on_conflict: {
                //   constraint: leagues_pkey,
                //   update_columns: [name, league_player_id, league_id, division_id, country, skill_level, race_to]
                // }
            },
            // managers: {
            //     data: [
            //         {
            //             user_id: authState && authState.user && authState.user.id,
            //             email: authState && authState.user && authState.user.email,
            //             match_slug: slug,
            //             invite_accepted: true,
            //             admin: true,
            //             creator: true,
            //         },
            //     ],
            // },
        };

        console.log('new data', newData);
        createLeagueMatch({
            variables: {
                objects: [newData],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_DIVISION_MATCHES_QUERY,
                    variables: { league_id: leagueID, division_id: divisionID },
                },
                {
                    query: GET_LEAGUE_QUERY,
                    variables: { id: leagueID },
                },
                {
                    query: GET_LEAGUE_DIVISION_SCHEDULE,
                    variables: { division_id: divisionID },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.success('Match successfully added');
                const added = res.data.insert_league_matches.returning[0];
                // createLeagueMatchGame({
                //     variables: {
                //         objects: [
                //             {
                //                 league_id: leagueID,
                //                 division_id: division_id,
                //                 league_match_id: added.id,
                //                 home_team_id: home_team_id,
                //                 visitor_team_id: visitor_team_id,
                //                 start_date_time: moment.utc(),
                //                 status: 'IN_PROGRESS',
                //                 game_number: 1,
                //             },
                //         ],
                //     },
                // })
                //     .then((res) => {
                //         console.log(res);
                //         // message.success('Match frame created');
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         // setLoading(false);
                //         message.error('There was an error', error);
                //     });

                onModalOk(added);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.info('There was an error', error);
                onModalOk();
            });
    };

    const submitUpdate = (data, match) => {
        console.log({
            data,
            match,
        });
        const {
            name,
            division_id,
            home_team_id,
            home_team_score,
            home_team_points,
            visitor_team_id,
            visitor_team_score,
            visitor_team_points,
            scoring_format,
            match_type,
            game_type,
            table_id,
            venue_id,
            scheduled_time,
            status,
        } = data;
        console.log(teams);
        const table = tables.filter((table) => table.id === table_id);
        const homeTeam = teams.filter((team) => team.id === home_team_id)[0];
        const visitorTeam = teams.filter((team) => team.id === visitor_team_id)[0];
        // const matchName = `${homeTeam.name} vs. ${visitorTeam.name}` || '';
        const match_teams = _.compact([home_team_id, visitor_team_id]);
        // const playerNames =
        // 	league_match_players &&
        // 	league_match_players.map((id) => {
        // 		const playerData = players.filter((player) => player.id === id);
        // 		return {
        // 			name: playerData && playerData[0].name,
        // 			skill_level: playerData && playerData[0].skill_level
        // 		};
        // 	});
        // const matchName =
        // 	playerNames && playerNames.length > 0
        // 		? `${playerNames[0].name} ${playerNames[0].skill_level
        // 				? `(${playerNames[0].skill_level})`
        // 				: ''} vs. ${playerNames[1].name} ${playerNames[1].skill_level
        // 				? `(${playerNames[1].skill_level})`
        // 				: ''}`
        // 		: '';
        let slug;
        if (name) {
            slug = slugify(`${name} ${moment(scheduled_time).format('MM-DD-YYYY')}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        // console.log(challenger1);
        // console.log(challenger2);
        // console.log(matchName);

        console.log({
            selectedMatchId,
            leagueID,
        });

        setLoading(true);
        updateLeagueMatch({
            variables: {
                id: selectedMatchId,
                league_id: leagueID,
                changes: {
                    name: name,
                    ...(slug && { slug: slug }),
                    match_type,
                    game_type,
                    scoring_format,
                    venue_id,
                    league_id: leagueID,
                    schedule_id: scheduleID,
                    division_id,
                    home_team_id,
                    home_team_score,
                    home_team_points,
                    home_team_is_winner: team1Winner,
                    visitor_team_id,
                    visitor_team_score,
                    visitor_team_points,
                    visitor_team_is_winner: team2Winner,
                    ...(table_id && { table_id: table_id }),
                    ...(table && table.length && { table_name: table[0].label }),
                    ...(table && table.length && { table_size: table[0].size }),
                    scheduled_time,
                    is_team_match: true,
                    status,
                },
            },
        })
            .then((res) => {
                console.log(match_teams);
                console.log(match.league_match_players);
                const original = match.league_match_players.map((id) => id);
                const toUpdate = _.uniq(match_teams, original);
                const toDelete = _.difference(original, toUpdate);
                console.log('original', original);
                console.log('new', match_teams);
                console.log('update', toUpdate);
                console.log('delete', toDelete);

                if (toDelete.length > 0) {
                    deleteLeagueMatchTeam({
                        variables: {
                            league_id: leagueID,
                            league_match_id: match.id,
                        },
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

                if (toUpdate.length > 0) {
                    const updateData = toUpdate.map((teamId) => {
                        const team = teams.filter((team) => team.id === teamId)[0];
                        console.log(team);

                        return {
                            league_id: leagueID,
                            league_match_id: match.id,
                            league_team_id: teamId,
                            ...(division_id && { division_id: division_id }),
                            name: team && team.name,
                        };
                    });

                    console.log(updateData);
                    updateLeagueMatchTeam({
                        variables: {
                            league_id: leagueID,
                            league_match_id: match.id,
                            objects: updateData,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_LEAGUE_MATCH_QUERY,
                                variables: { match_id: match.id, league_id: leagueID },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            onModalOk();
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            message.info('There was an error', error);
                            onModalOk();
                        });
                } else {
                    setLoading(false);
                    onModalOk();
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        // division_id: Yup.number().required('Required'),
        match_type: Yup.string().required('Required'),
        game_type: Yup.string().required('Required'),
        // match_players: Yup.string().required('Required'),
        venue_id: Yup.number().required('Required'),
        scheduled_time: Yup.string().required('Required'),
    });

    const validator = (values, props) => {
        console.log(values);
        console.log(props);
        const errors = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        return errors;
    };

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_LEAGUE_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function onSearchVenue(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelectVenue(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function onTeam1Change(event) {
        console.log('checked = ', event.target.checked);
        setTeam1Winner(event.target.checked);
    }

    function onTeam2Change(event) {
        console.log('checked = ', event.target.checked);
        setTeam2Winner(event.target.checked);
    }

    const renderForm = (match) => {
        const initialValues = selectedMatchId
            ? {
                  ...match,
                  venue_name: match && match.venue && match.venue.name,
                  //   status: team1Winner === true || team2Winner === true ? 'COMPLETED' : match && match.status,
                  league_match_teams:
                      match &&
                      match.league_match_teams &&
                      match.league_match_teams.map((team) => {
                          return team.id;
                      }),
              }
            : {
                  division_id: divisionID ? divisionID : undefined,
                  match_type: undefined,
                  game_type: undefined,
                  venue_id: undefined,
                  venue_name: undefined,
                  scoring_format: 'TEN_POINT_SYSTEM',
                  home_team_id: undefined,
                  home_team_score: null,
                  home_team_points: null,
                  home_team_is_winner: false,
                  visitor_team_id: undefined,
                  visitor_team_score: null,
                  visitor_team_points: null,
                  visitor_team_is_winner: false,
                  table_size: '8 Foot',
                  table_id: undefined,
                  scheduled_time: '',
                  status: 'NOT_STARTED',
              };

        return (
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    console.log(data);
                    // const merged = {
                    // 	...data,
                    // 	...selected
                    // };
                    if (selectedMatchId) {
                        submitUpdate(data, match);
                    } else {
                        submitAdd(data, actions);
                    }
                }}
                validationSchema={schema}
                enableReinitialize
                initialValues={initialValues}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Modal
                        title={selectedMatchId ? 'Update Team Match' : 'Create Team Match'}
                        // getContainer={() => document.querySelector('.tables')}
                        visible={modalVisible}
                        onOk={onModalOk}
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        // transitionName="none"
                        maskTransitionName="none"
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                onClick={() => {
                                    formRef.current.handleSubmit();
                                }}
                            >
                                {selectedMatchId ? (loading ? 'Updating...' : 'Update Match') : 'Add Match'}
                            </Button>,
                        ]}
                        bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                        destroyOnClose={true}
                    >
                        <Form
                            onSubmit={handleSubmit}
                            layout="vertical"
                            style={{ maxWidth: 600 }}
                            // onSubmit={(e) => {
                            // 	e.preventDefault();
                            // 	console.log(values);
                            // 	// handleSubmit(values);
                            // }}
                        >
                            <FormItem label={divisionID ? 'Division' : 'Divisions'} name="division_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_DIVISIONS_SELECT_QUERY}
                                    fetchPolicy="cache-and-network"
                                    variables={{
                                        leagueId: leagueID,
                                    }}
                                    notifyOnNetworkStatusChange={true}
                                    onCompleted={(data) => {
                                        console.log(data);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading) return <div>Loading...</div>;
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                mode={divisionID ? 'single' : 'multiple'}
                                                name="division_id"
                                                placeholder="Select division"
                                                size="large"
                                                loading={loading}
                                                defaultValue={divisionID}
                                                // disabled={divisionID ? true : false}
                                                // onChange={handleDivisionChange}
                                                disabled={divisionID ? true : false}
                                                allowClear={divisionID ? false : true}
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.league_divisions &&
                                                    data.league_divisions.map((item, index) => (
                                                        <Option key={index} name={item.name} value={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Avatar
                                                                size={24}
                                                                src={getGameType(item.game_types && item.game_types[0].game_type)}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: 8,
                                                                }}
                                                            />
                                                            <Text>{item.name}</Text>
                                                        </Option>
                                                    ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Name" name="name" autoFocus>
                                <Input
                                    name="name"
                                    placeholder="Name"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>

                            <FormItem label="Match Type" name="match_type" required hasFeedback showValidateSuccess>
                                <Query query={GET_DIVISION_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                name="match_type"
                                                placeholder="Select a match type"
                                                size="large"
                                                loading={loading}
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.league_division_types &&
                                                    data.league_division_types
                                                        .filter((division) => division.value !== 'SINGLES')
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                            </Option>
                                                        ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Game Type" name="game_type" required hasFeedback showValidateSuccess>
                                <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                    {({ loading, error, data }) => {
                                        if (loading) return <div>Loading...</div>;
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                name="game_type"
                                                placeholder="Select game to be played"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.game_types &&
                                                    data.game_types.map((item, index) => (
                                                        <Option key={index} value={item.value}>
                                                            <Avatar
                                                                size={24}
                                                                src={getGameType(item.value, theme)}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: 8,
                                                                }}
                                                            />
                                                            <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                        </Option>
                                                    ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>

                            <FormItem label="Scoring format" name="scoring_format" hasFeedback showValidateSuccess style={{ flex: 1 }}>
                                <Select name="scoring_format" defaultValue="TEN_POINT_SYSTEM" size="large">
                                    <Option value="TEN_POINT_SYSTEM">10 point system</Option>
                                    <Option value="RACE_TO">Race to number</Option>
                                    <Option value="BEST_OF">Best of number</Option>
                                    <Option value="CUSTOM">Custom</Option>
                                </Select>
                            </FormItem>

                            {values.scoring_format === 'BEST_OF' ? (
                                <FormItem label="Best of number" name="best_of_num" hasFeedback showValidateSuccess>
                                    <InputNumber name="best_of_num" min={0} placeholder="0" size="large" />
                                </FormItem>
                            ) : values.scoring_format === 'RACE_TO' ? (
                                <FormItem label="Default race to number" name="race_to_num" hasFeedback showValidateSuccess>
                                    <InputNumber name="race_to_num" min={0} placeholder="0" size="large" />
                                </FormItem>
                            ) : null}

                            <FormItem label="Home team" name="home_team_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_DIVISION_TEAMS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange
                                    awaitRefetchQueries
                                    variables={{ league_id: leagueID, division_id: divisionID }}
                                    onCompleted={(data) => {
                                        // console.log(data);
                                        setTeams(data.league_teams);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                // mode="multiple"
                                                name="home_team_id"
                                                loading={loading}
                                                placeholder="Select home team"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {data &&
                                                    data.league_teams &&
                                                    data.league_teams.map((team, index) => {
                                                        return (
                                                            <Option key={index} name={team.name} value={team.id}>
                                                                {team.avatarImg ? (
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Avatar
                                                                            size={24}
                                                                            src={team.avatarImg}
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        {team.name}
                                                                    </span>
                                                                ) : (
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span />
                                                                        {team.name}
                                                                    </span>
                                                                )}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FormItem label="Home team score" name="home_team_score" hasFeedback showValidateSuccess>
                                    <InputNumber
                                        name="home_team_score"
                                        placeholder="0"
                                        min={0}
                                        // value={score}
                                        // onChange={onChange}
                                        // onFocus={handleFocus}
                                        // onBlur={handleBlur}
                                        // style={styles.inputSmall}
                                        // onPressEnter={onEnter}
                                        size="large"
                                        // disabled
                                    />

                                    {/* <Dropdown overlay={actionsMenuBottom} trigger={['click']} disabled={team2Winner ? true : false}>
                                        <Button style={{ marginLeft: 10 }}>
                                            {team1Winner ? _.capitalize(team1Winner) : 'Actions'} <Icon type="down" />
                                        </Button>
                                    </Dropdown> */}
                                </FormItem>
                                <div style={{ marginLeft: 20 }} />
                                <FormItem label="Home team points" name="home_team_points" hasFeedback showValidateSuccess>
                                    <span>
                                        <InputNumber
                                            name="home_team_points"
                                            placeholder="0"
                                            min={0}
                                            // value={score}
                                            // onChange={onChange}
                                            // onFocus={handleFocus}
                                            // onBlur={handleBlur}
                                            // style={styles.inputSmall}
                                            // onPressEnter={onEnter}
                                            size="large"
                                            // disabled
                                        />

                                        <Checkbox
                                            checked={team1Winner === true}
                                            // disabled={team2Winner === true}
                                            onChange={onTeam1Change}
                                            style={{ marginLeft: 10 }}
                                        >
                                            Winner
                                        </Checkbox>
                                    </span>
                                </FormItem>
                            </div>

                            <FormItem label="Visiting team" name="visitor_team_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_DIVISION_TEAMS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange
                                    awaitRefetchQueries
                                    variables={{ league_id: leagueID, division_id: divisionID }}
                                    onCompleted={(data) => {
                                        // console.log(data);
                                        setTeams(data.league_teams);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                // mode="multiple"
                                                name="visitor_team_id"
                                                loading={loading}
                                                placeholder="Select visiting team"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {data &&
                                                    data.league_teams &&
                                                    data.league_teams.map((team, index) => {
                                                        return (
                                                            <Option key={index} name={team.name} value={team.id}>
                                                                {team.avatarImg ? (
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Avatar
                                                                            size={24}
                                                                            src={team.avatarImg}
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        {team.name}
                                                                    </span>
                                                                ) : (
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span />
                                                                        {team.name}
                                                                    </span>
                                                                )}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FormItem label="Visiting team score" name="visitor_team_score" hasFeedback showValidateSuccess>
                                    <InputNumber
                                        name="visitor_team_score"
                                        placeholder="0"
                                        min={0}
                                        // value={score}
                                        // onChange={onChange}
                                        // onFocus={handleFocus}
                                        // onBlur={handleBlur}
                                        // style={styles.inputSmall}
                                        // onPressEnter={onEnter}
                                        size="large"
                                        // disabled
                                    />

                                    {/* <Dropdown overlay={actionsMenuBottom} trigger={['click']} disabled={team1Winner ? true : false}>
                                            <Button style={{ marginLeft: 10 }}>
                                                {team2Winner ? _.capitalize(team2Winner) : 'Actions'} <Icon type="down" />
                                            </Button>
                                        </Dropdown> */}
                                </FormItem>
                                <div style={{ marginLeft: 20 }} />
                                <FormItem label="Visiting team points" name="visitor_team_points" hasFeedback showValidateSuccess>
                                    <span>
                                        <InputNumber
                                            name="visitor_team_points"
                                            placeholder="0"
                                            min={0}
                                            // value={score}
                                            // onChange={onChange}
                                            // onFocus={handleFocus}
                                            // onBlur={handleBlur}
                                            // style={styles.inputSmall}
                                            // onPressEnter={onEnter}
                                            size="large"
                                            // disabled
                                        />
                                        <Checkbox
                                            checked={team2Winner === true}
                                            // disabled={team1Winner === true}
                                            onChange={onTeam2Change}
                                            style={{ marginLeft: 10 }}
                                        >
                                            Winner
                                        </Checkbox>
                                    </span>
                                </FormItem>
                            </div>

                            <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <AutoComplete
                                        name="venue_name"
                                        dataSource={venueDataSource}
                                        placeholder="Search for a venue"
                                        onSearch={onSearchVenue}
                                        onSelect={onSelectVenue}
                                        size="large"
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{
                                                        padding: '4px 8px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={handleAddVenue}
                                                >
                                                    <Icon type="plus" /> Add Venue
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {venueDataSource &&
                                            venueDataSource.map((item, index) => (
                                                <Option key={index} value={`${item.id}`}>
                                                    {item.name} - {item.city}, {item.region}
                                                </Option>
                                            ))}
                                    </AutoComplete> */}

                                    <Query
                                        query={GET_LEAGUE_VENUES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            leagueId: leagueID,
                                        }}
                                        onCompleted={(data) => {
                                            console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="venue_id"
                                                    placeholder="Select a venue"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        console.log(option);
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data.league_venues.map((item, index) => {
                                                        const venue = item.venue;
                                                        return (
                                                            <Option key={index} value={venue.id}>
                                                                {venue.name} - {venue.city}, {venue.region}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            );
                                        }}
                                    </Query>

                                    <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                        <Icon type="plus" /> Add Venue
                                    </Button>
                                </div>
                            </FormItem>

                            {values.venue_id && (
                                <FormItem label="Table" name="table_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_VENUE_TABLES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            venueId: values.venue_id,
                                        }}
                                        onCompleted={(data) => {
                                            console.log(data);
                                            setTables(data.pool_tables);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="table_id"
                                                    placeholder="Assign a table from the selected venue"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data.pool_tables.map((item, index) => (
                                                        <Option key={index} value={item.id}>
                                                            <Text>{item.label}</Text>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                            )}

                            <FormItem label="Scheduled date and time" name="scheduled_time" required hasFeedback showValidateSuccess>
                                <DatePicker
                                    showTime
                                    name="scheduled_time"
                                    size="large"
                                    format="YYYY-MM-DD hh:mm A z"
                                    disabledSeconds
                                    // onChange={onDateChange}
                                    onOk={onOk}
                                />
                            </FormItem>

                            <FormItem label="Status" name="status" hasFeedback showValidateSuccess>
                                <Select
                                    showSearch
                                    // mode="multiple"
                                    name="status"
                                    placeholder="Select match status"
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    <Option value="NOT_STARTED">
                                        <Text>NOT_STARTED</Text>
                                    </Option>
                                    <Option value="IN_PROGRESS">
                                        <Text>IN_PROGRESS</Text>
                                    </Option>
                                    <Option value="COMPLETED">
                                        <Text>COMPLETED</Text>
                                    </Option>
                                    <Option value="SCHEDULED">
                                        <Text>SCHEDULED</Text>
                                    </Option>
                                </Select>
                            </FormItem>
                            <AddVenueModal {...props} modalVisible={venueModalVisible} onModalOk={handleVenueModalOk} onModalCancel={handleVenueModalCancel} />
                        </Form>
                    </Modal>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            {selectedMatchId ? (
                <Query
                    query={GET_LEAGUE_MATCH_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ match_id: selectedMatchId, league_id: leagueID }}
                    onCompleted={(data) => {
                        console.log(data);
                        const matchData = data && data.league_matches && data.league_matches[0];
                        setTeam1Winner(matchData.home_team_is_winner);
                        setTeam2Winner(matchData.visitor_team_is_winner);
                    }}
                >
                    {({ loading, error, data }) => {
                        // if (loading) return <CircularLoader />;
                        // if (error) return <div>Error: {error.message}</div>;
                        const match = data && data.league_matches && data.league_matches.length > 0 ? data.league_matches[0] : null;
                        return <React.Fragment>{renderForm(match)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddTeamMatchModal);
