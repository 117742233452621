import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography } from 'antd';
import * as Yup from 'yup';
import { Mutation } from '@apollo/client/react/components';
import slugify from 'slugify';
import { GET_TOURNAMENT_QUERY } from '../data/queries';
import { UPDATE_TOURNAMENT_MUTATION } from '../data/mutations';

const { TextArea } = Input;
const { Text } = Typography;

function TBBasicSettingsForm(props) {
    const { tournament, history } = props;
    const [loading, setLoading] = useState(false);

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    });

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Mutation
            mutation={UPDATE_TOURNAMENT_MUTATION}
            onCompleted={(res) => {
                console.log(res);
                const tournament = res.update_tournaments.returning[0];
                setLoading(false);
                message.success('Changes saved');
                // const result = data.insert_leagues.returning[0];
                // props.history.push(`/league-manager/${result.slug}/dashboard`);
                // const { updateLeague } = data;
                history.push(`/tournament-builder/${tournament.slug}/settings/basic`);
            }}
            awaitRefetchQueries
        >
            {(updateTournament, { error }) => {
                if (error) {
                    alert('Something went wrong');
                }
                return (
                    <Formik
                        initialValues={{
                            ...tournament,
                            is_public: tournament && tournament.is_public === true ? 'public' : 'private',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values) => {
                            console.log(values);
                            const { name, description, is_public } = values;
                            setLoading(true);
                            const slug = slugify(name, {
                                replacement: '-',
                                remove: /[*+~.,()'"#!:/@]/g,
                                lower: true,
                            });

                            updateTournament({
                                variables: {
                                    id: tournament.id,
                                    changes: {
                                        name: name,
                                        slug: slug,
                                        // owner_id: props.authState.user.id,
                                        // operator_id: props.authState.user.id,
                                        description: description,
                                        is_public: is_public === 'public' ? true : false,
                                    },
                                },
                                refetchQueries: () => [
                                    {
                                        query: GET_TOURNAMENT_QUERY,
                                        variables: { id: tournament.id },
                                        notifyOnNetworkStatusChange: true,
                                        awaitRefetchQueries: true,
                                    },
                                ],
                            });
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal" style={{ maxWidth: 600 }}>
                                <FormItem
                                    name="name"
                                    label="Tournament Name"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="name" placeholder="Name" size="large" />
                                    <ErrorMessage name="name" component="div" className="ant-form-explain" />
                                </FormItem>

                                <FormItem label="Description" name="description">
                                    <TextArea rows={6} name="description" placeholder="Description" size="large" />
                                </FormItem>

                                <FormItem name="is_public" label="Access">
                                    <Radio.Group name="is_public">
                                        <Radio value="public">Public - Anyone can view and request to join.</Radio>
                                        <br />
                                        <Radio value="private">Private - Only users who have been sent an invite can view or join.</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={loading}
                                >
                                    Update
                                </SubmitButton>
                                <div>
                                    <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                </div>
                            </Form>
                        )}
                    />
                );
            }}
        </Mutation>
    );
}

export default withRouter(TBBasicSettingsForm);
