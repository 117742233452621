import React from 'react';
import { withRouter } from 'react-router-dom';
import { Descriptions, Row, Avatar, Button, Empty, Icon, List, Skeleton, Col, Typography } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionTitle from '../../../components/SectionTitle';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import HeaderImage from '../../../components/HeaderImage';
import { GET_LEAGUE_MATCHES_QUERY, GET_UPCOMING_LEAGUE_MATCHES_QUERY } from '../data/queries';
import { Query } from '@apollo/client/react/components';
// import DPDivisionCard from './DPDivisionCard';
// import DPTournamentSchedule from '../tournaments/DPTournamentSchedule';
import { getGameType } from '../../../utils';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import blueBg from '../../../assets/img/blue-bg.png';
import defaultImg from '../../../assets/img/ball/default.png';
import _ from 'lodash';
import moment from 'moment';

const { Title, Text } = Typography;

function DPDivisionOverview(props) {
    const { league, division, theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const styles = {
        headerImg: {
            height: 120,
            overflow: 'hidden',
            borderRadius: 0,
            background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 40,
            paddingRight: 40,
            // backgroundPosition: '0px -40px'
        },
    };

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Overview"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            {/* <HeaderImage /> */}
            <SectionContent padding="20px 40px">
                {/* <Row gutter={24} justify="center" type="flex" style={{ position: 'relative', marginTop: -88 }}> */}
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Row gutter={24}>
                            <Col xs={24}>
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                Division information
                                            </Title>
                                        </div>
                                    }
                                >
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col
                                            xs={24}
                                            sm={8}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Avatar
                                                size={200}
                                                src={getGameType(division.game_types, theme)}
                                                // style={{ backgroundColor: '#1890ff' }}
                                            />
                                        </Col>
                                        <Col xs={24} sm={16}>
                                            <Descriptions
                                                // title="League Details"
                                                size="small"
                                                column={2}
                                                bordered={false}
                                                layout="horizontal"
                                            >
                                                <Descriptions.Item label="Description">
                                                    <Text>{division.description || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Games Played">
                                                    <Text>{division.game_types || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Season">
                                                    <Text>{division.season || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Week">
                                                    <Text>{division.week || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Weeks">
                                                    <Text>{division.num_weeks || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Start Date">
                                                    {division.start_date ? moment(division.start_date).format('llll') : 'N/A'}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="End Date">
                                                    {division.end_date ? moment(division.end_date).format('llll') : 'N/A'}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Session ID">
                                                    <Text>{division.session_id || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Handicapped">
                                                    <Text>{division.is_handicapped || 'N/A'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Access">
                                                    <Text>{division.is_public ? 'Public' : 'Private'}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Active">
                                                    <Text>{division.is_active ? 'Yes' : 'No'}</Text>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24}>
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                News & Updates
                                            </Title>
                                        </div>
                                    }
                                >
                                    News will go here...
                                </Card>
                            </Col>
                            <Col
                                xs={24}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                Standings
                                            </Title>
                                        </div>
                                    }
                                >
                                    Standings will go here
                                </Card>
                            </Col>
                            <Col
                                xs={24}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                Upcoming matches
                                            </Title>
                                        </div>
                                    }
                                    bodyStyle={{ padding: 0 }}
                                >
                                    <Query
                                        query={GET_UPCOMING_LEAGUE_MATCHES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            league_id: league.id,
                                            division_id: division.id,
                                            current_date: moment.utc(),
                                        }}
                                        onCompleted={(data) => {
                                            console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <CircularLoader />;
                                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                            const matches =
                                                data.league_matches &&
                                                data.league_matches.map((item, index) => {
                                                    return {
                                                        ...item,
                                                        key: index,
                                                        player1: item.league_match_players && item.league_match_players[0],
                                                        player2: item.league_match_players && item.league_match_players[1],
                                                        venue: item.venue && item.venue.name,
                                                    };
                                                });
                                            return (
                                                <List
                                                    className="hover-list"
                                                    size="small"
                                                    loading={loading}
                                                    itemLayout="horizontal"
                                                    dataSource={matches}
                                                    renderItem={(item) => (
                                                        <List.Item
                                                            // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                            style={{ padding: '10px 16px' }}
                                                            onClick={() =>
                                                                props.history.push(`/leagues/${league.slug}/divisions/${division.slug}/matches/${item.slug}`)
                                                            }
                                                        >
                                                            <Skeleton avatar title={false} loading={loading} active>
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <React.Fragment>
                                                                            {item.game_type ? (
                                                                                <Avatar size={30} src={getGameType(item.game_type)} />
                                                                            ) : (
                                                                                <Avatar size={30} src={defaultImg} />
                                                                            )}
                                                                        </React.Fragment>
                                                                    }
                                                                    title={
                                                                        <div>
                                                                            <Text>{moment(item.scheduled_time).format('llll')}</Text>
                                                                            <br />
                                                                            <Text
                                                                                style={{
                                                                                    fontSize: 16,
                                                                                }}
                                                                            >
                                                                                {item.name}
                                                                            </Text>
                                                                            <Text>{item.table}</Text>
                                                                        </div>
                                                                    }
                                                                    description={item.short_description}
                                                                />
                                                            </Skeleton>
                                                        </List.Item>
                                                    )}
                                                />
                                            );
                                        }}
                                    </Query>
                                </Card>
                            </Col>
                            <Col
                                xs={24}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                Venues
                                            </Title>
                                        </div>
                                    }
                                >
                                    Venues will go here
                                </Card>
                            </Col>
                            <Col
                                xs={12}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                Players
                                            </Title>
                                        </div>
                                    }
                                >
                                    Players will go here
                                </Card>
                            </Col>
                            <Col
                                xs={12}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Card
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                Teams
                                            </Title>
                                        </div>
                                    }
                                >
                                    Teams will go here
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(DPDivisionOverview);
