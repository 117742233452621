import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon, Switch } from 'antd';
import _ from 'lodash';

const { SubMenu } = Menu;

function DPLNav(props) {
	const { match } = props;
	const urlPath = window.location.pathname;
	const splitPath = urlPath.split('/');
	const dplIncludes = [
		'dpl',
		'dashboard',
		'settings',
		'divisions',
		'teams',
		'players',
		'payouts',
		'schedule',
		'matches',
		'results',
		'stats',
		'venues'
	];

	const [ theme, setTheme ] = useState('dark');
	const [ current, setCurrent ] = useState();

	useEffect(
		() => {
			let pathMatch =
				splitPath[splitPath.length] === match.params.division
					? splitPath[splitPath.length - 1]
					: splitPath[splitPath.length - 3] === 'divisions'
						? 'divisions'
						: _.includes(dplIncludes, splitPath[splitPath.length - 1])
							? splitPath[splitPath.length - 1]
							: _.includes(dplIncludes, splitPath[splitPath.length - 2])
								? splitPath[splitPath.length - 2]
								: _.includes(dplIncludes, splitPath[splitPath.length - 3])
									? splitPath[splitPath.length - 3]
									: _.includes(dplIncludes, splitPath[splitPath.length - 4])
										? splitPath[splitPath.length - 4]
										: _.includes(dplIncludes, splitPath[splitPath.length - 5])
											? splitPath[splitPath.length - 5]
											: 'dpl';
			setCurrent(pathMatch);
		},
		[ urlPath, splitPath, dplIncludes ]
	);

	const handleClick = (e) => {
		// console.log('click ', e);
		setCurrent(e.key);
		props.onClicked();
		const url = e.key === 'dpl' ? '/dpl' : `/dpl/${e.key}`;
		props.history.push(url);
	};

	return (
		<Menu
			theme={theme}
			onClick={handleClick}
			// style={{ width: 256 }}
			// defaultOpenKeys={[ 'sub1' ]}
			selectedKeys={[ current ]}
			mode="inline"
		>
			<Menu.Item key="dpl">
				<Icon type="home" /> Home
			</Menu.Item>
			<Menu.Item key="dashboard">
				<Icon type="dashboard" /> Dashboard
			</Menu.Item>
			<Menu.Item key="settings">
				<Icon type="setting" /> Settings
			</Menu.Item>
			<Menu.Item key="divisions">
				<Icon type="block" /> Divisions
			</Menu.Item>
			<Menu.Item key="teams">
				<Icon type="team" /> Teams
			</Menu.Item>
			<Menu.Item key="players">
				<Icon type="user" /> Players
			</Menu.Item>
			<Menu.Item key="schedule">
				<Icon type="schedule" /> Schedule
			</Menu.Item>
			<Menu.Item key="matches">
				<Icon type="thunderbolt" /> Matches
			</Menu.Item>
			<Menu.Item key="results">
				<Icon type="trophy" /> Results
			</Menu.Item>
			<Menu.Item key="stats">
				<Icon type="bar-chart" /> Stats
			</Menu.Item>
			<Menu.Item key="venues">
				<Icon type="bank" /> Venues
			</Menu.Item>
			<SubMenu
				key="sub1"
				title={
					<span>
						<Icon type="info-circle" />
						<span>League Info</span>
					</span>
				}
			>
				<Menu.Item key="about">
					<Icon type="bulb" /> About DPL
				</Menu.Item>
				<Menu.Item key="app">
					<Icon type="mobile" /> App
				</Menu.Item>
				<Menu.Item key="rules">
					<Icon type="info-circle" /> Rules
				</Menu.Item>
				<Menu.Item key="faq">
					<Icon type="question-circle" /> FAQ
				</Menu.Item>
				<Menu.Item key="support">
					<Icon type="customer-service" /> Support
				</Menu.Item>
			</SubMenu>
			{/* <Menu.Divider style={{ backgroundColor: '#324454' }} /> */}
		</Menu>
	);
}

export default withRouter(DPLNav);
