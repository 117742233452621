import React from 'react';
import NewsFeed from './feed/NewsFeed';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import { Tabs } from 'antd';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

const { TabPane } = Tabs;

const styles = {
	tab: {
		paddingLeft: 4,
		paddingRight: 4
	}
};

function Organizations(props) {
	const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

	return (
		<React.Fragment>
			<SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 120} />
			<SectionContent>Coming soon</SectionContent>
		</React.Fragment>
	);
}

export default Organizations;
