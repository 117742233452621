import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Layout, Button, Typography, Tooltip, Icon, Modal } from 'antd';
import { Query } from '@apollo/client/react/components';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import ScrollContainer from 'react-indiana-drag-scroll';
import Card from '../../components/Card';
import '../digitalpool/tournaments/bracket/bracket.css';
import '../digitalpool/tournaments/bracket/bracket-compact.css';
import SidePanelMatches from './SidePanelMatches';
import SidePanelRounds from './SidePanelRounds';
import AddBracketSegmentModal from './AddBracketSegmentModal';
import numeral from 'numeral';
import CircularLoader from '../../components/CircularLoader';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    CREATE_TOURNAMENT_BRACKET_ROUND,
    UPDATE_TOURNAMENT_BRACKET_ROUND,
    DELETE_TOURNAMENT_BRACKET_ROUND,
    CREATE_TOURNAMENT_BRACKET_MATCH,
    UPDATE_TOURNAMENT_BRACKET_MATCH,
    DELETE_TOURNAMENT_BRACKET_MATCH,
} from './data/mutations';
import { GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG, GET_TOURNAMENT_BRACKET_TEMPLATE_MATCH } from './data/queries';
import ZeroState from '../../components/ZeroState';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Sider, Content, Header } = Layout;
const { Title, Text } = Typography;
const { confirm } = Modal;

let segments = [];
let rounds = [];
let roundMatches = [];
let matches = [];

const styles = {};

function TBBracketCreatorTemplateDetail(props) {
    const { authState, theme } = props;
    const slug = props.match.params.slug;
    // const [rounds, setRounds] = useState([]);
    // const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [bracket, setBracket] = useState();
    const [segmentModalVisible, setSegmentModalVisible] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState();
    const [selectedRound, setSelectedRound] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [sidePanelMatchesOpen, setSidePanelMatchesOpen] = useState(false);
    const [sidePanelRoundsOpen, setSidePanelRoundsOpen] = useState(false);
    const [activeMatch, setActiveMatch] = useState();
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', ' Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    const [createBracketRound] = useMutation(CREATE_TOURNAMENT_BRACKET_ROUND);
    const [updateBracketRound] = useMutation(UPDATE_TOURNAMENT_BRACKET_ROUND);
    const [deleteBracketRound] = useMutation(DELETE_TOURNAMENT_BRACKET_ROUND);

    const [createBracketMatch] = useMutation(CREATE_TOURNAMENT_BRACKET_MATCH);
    const [updateBracketMatch] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH);
    const [deleteBracketMatch] = useMutation(DELETE_TOURNAMENT_BRACKET_MATCH);

    function createRound(data) {
        const { rounds, round, segment } = data;

        console.log(data);

        const output = {
            identifier: rounds && rounds.length > 0 ? alphabet[rounds.length] : alphabet[0],
            round: round ? parseInt(round) + 1 : rounds && rounds.length > 0 ? rounds.length + 1 : 1,
            segment: segment,
            // matches: [],
        };
        // setRounds([...rounds, round]);
        // rounds = [...rounds, round];
        // roundMatches[rounds.length] = [];
        console.log('r', output);
        // console.log('round matches', roundMatches);
        createRoundRecord(output);
        return output;
    }

    function createMatch(data) {
        const {
            rounds,
            round,
            roundNum,
            segment,
            place,
            identifier,
            is_finals,
            is_semi_finals,
            is_consolidation_finals,
            winner_to,
            winner_to_num,
            winner_from,
            winner_from_num,
            loser_to,
            loser_to_num,
            loser_from,
            loser_from_num,
            top_seed,
            bottom_seed,
        } = data;

        console.log('match data', data);

        const currentRoundMatch = round.matches.length;
        // const currentIdentifier = ((roundNum && rounds[roundNum - 1].identifier) || rounds[rounds.length - 1].identifier) + (currentRoundMatch + 1);
        const currentIdentifier = `${round.identifier}${round.matches.length + 1}`;
        console.log(currentRoundMatch);
        const match = {
            round: roundNum || rounds.length,
            identifier: identifier || currentIdentifier,
            match_number: round.matches.length + 1,
            place: place,
            top_seed: parseInt(top_seed),
            bottom_seed: parseInt(bottom_seed),
            is_finals: is_finals || false,
            is_semi_finals: is_semi_finals || false,
            is_consolidation_finals: is_consolidation_finals || false,
            winner_to: winner_to,
            winner_to_num: winner_to_num,
            winner_from: winner_from,
            winner_from_num: winner_from_num,
            loser_to: loser_to,
            loser_to_num: loser_to_num,
            loser_from: loser_from,
            loser_from_num: loser_from_num,
            round_id: round.id,
        };

        // matches = [...matches, match];

        // setMatches([...matches, match]);

        // if (roundNum) {
        //     rounds[roundNum - 1].matches.push(match);
        //     if (roundMatches[roundNum - 1]) {
        //         roundMatches[roundNum - 1].push(match);
        //     } else {
        //         roundMatches[roundNum - 1] = [];
        //     }
        // } else {
        //     if (rounds[rounds.length - 1]) {
        //         rounds[rounds.length - 1].matches.push(match);
        //     }
        //     if (roundMatches[rounds.length - 1]) {
        //         roundMatches[rounds.length - 1].push(match);
        //     } else {
        //         roundMatches[rounds.length - 1] = [];
        //     }
        // }

        console.log('m', match);
        // console.log('round matches', roundMatches);

        createMatchRecord(match);
        return match;
    }

    function createRoundRecord(data) {
        const { round, identifier, segment } = data;
        setLoading(true);
        createBracketRound({
            variables: {
                objects: [
                    {
                        round,
                        tournament_bracket_template_id: selectedTemplate.id,
                        identifier,
                        segment_id: segment,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG,
                    variables: { slug: slug },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                message.success('Round successfully added');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function createMatchRecord(data) {
        const {
            round,
            round_id,
            identifier,
            match_number,
            place,
            winner_to,
            winner_to_num,
            winner_from,
            winner_from_num,
            loser_to,
            loser_to_num,
            loser_from,
            loser_from_num,
            is_finals,
            is_semi_finals,
            is_consolidation_finals,
            top_seed,
            bottom_seed,
        } = data;
        setLoading(true);
        createBracketMatch({
            variables: {
                objects: [
                    {
                        round,
                        round_id,
                        tournament_bracket_template_id: selectedTemplate.id,
                        identifier,
                        match_number,
                        place: place,
                        top_seed,
                        bottom_seed,
                        winner_to,
                        winner_to_num,
                        winner_from,
                        winner_from_num,
                        loser_to,
                        loser_to_num,
                        loser_from,
                        loser_from_num,
                        is_finals,
                        is_semi_finals,
                        is_consolidation_finals,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG,
                    variables: { slug: slug },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                message.success(`Match ${identifier} successfully added`);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function getMatchFromIdentifier(identifier) {
        const match = matches.filter((match) => {
            return match.identifier === identifier;
        })[0];
        return match;
    }

    function updateMatchRecord(data) {
        const {
            id,
            round,
            round_id,
            identifier,
            match_number,
            place,
            top_seed,
            bottom_seed,
            winner_to,
            winner_to_num,
            winner_to_slot,
            winner_from,
            winner_from_num,
            loser_to,
            loser_to_num,
            loser_to_slot,
            loser_from,
            loser_from_num,
            is_finals,
            is_semi_finals,
            is_consolidation_finals,
        } = data;
        setLoading(true);

        console.log(data);

        const winnerToMatch = getMatchFromIdentifier(winner_to);
        const loserToMatch = getMatchFromIdentifier(loser_to);

        console.log({
            winnerToMatch,
            loserToMatch,
        });

        updateBracketMatch({
            variables: {
                id: id,
                changes: {
                    round: parseInt(round),
                    round_id,
                    tournament_bracket_template_id: selectedTemplate.id,
                    identifier,
                    match_number: parseInt(match_number),
                    place: place,
                    top_seed: parseInt(top_seed),
                    bottom_seed: parseInt(bottom_seed),
                    winner_to,
                    winner_to_num: winnerToMatch && winnerToMatch.match_number,
                    winner_from,
                    winner_from_num,
                    loser_to,
                    loser_to_num: loserToMatch && loserToMatch.match_number,
                    loser_from,
                    loser_from_num,
                    is_finals,
                    is_semi_finals,
                    is_consolidation_finals,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_TEMPLATE_MATCH,
                    variables: { id: id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                handleToggleMatchesSidePanel(false);
                message.success('Changes saved');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });

        if (winnerToMatch) {
            updateBracketMatch({
                variables: {
                    id: winnerToMatch.id,
                    changes: {
                        winner_from: identifier,
                        winner_from_num: match_number,
                        ...(winner_to_slot === 'bottom' ? { winner_from_bottom_num: match_number } : { winner_from_top_num: match_number }),
                        // ...(match_number % 2 === 0
                        //     ? { winner_from_top_num: match_number, winner_from_bottom_num: null }
                        //     : { winner_from_bottom_num: match_number, winner_from_top_num: null }),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_TEMPLATE_MATCH,
                        variables: { id: winnerToMatch.id },
                    },
                ],
            })
                .then((data) => {
                    // console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (loserToMatch) {
            updateBracketMatch({
                variables: {
                    id: loserToMatch.id,
                    changes: {
                        loser_from: identifier,
                        loser_from_num: match_number,
                        ...(loser_to_slot === 'bottom' ? { loser_from_bottom_num: match_number } : { loser_from_top_num: match_number }),
                        // ...(match_number % 2 === 0
                        //     ? { loser_from_top_num: match_number, loser_from_bottom_num: null }
                        //     : { loser_from_bottom_num: match_number, loser_from_top_num: null }),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_TEMPLATE_MATCH,
                        variables: { id: loserToMatch.id },
                    },
                ],
            })
                .then((data) => {
                    // console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    function updateRoundRecord(data) {
        console.log(data);
        const { id, round, identifier, segment_id } = data;
        setLoading(true);

        updateBracketRound({
            variables: {
                id: id,
                changes: {
                    round: parseInt(round),
                    tournament_bracket_template_id: selectedTemplate.id,
                    identifier,
                    segment_id,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG,
                    variables: { slug: slug },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                handleToggleRoundSidePanel(false);
                message.success('Changes saved');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    // useEffect(() => {
    //     createRound();
    // }, []);

    // console.log('rounds', rounds);
    // console.log('matches', matches);

    function handleMatchClick(match, round) {
        console.log({
            match,
            round,
        });
        setSelectedMatch(match);
        handleToggleMatchesSidePanel(true);
    }

    function handleRoundClick(round) {
        console.log(round);
        setSelectedRound(round);
        handleToggleRoundSidePanel(true);
    }

    function handleToggleMatchesSidePanel(status) {
        setSidePanelRoundsOpen(false);
        if (status) {
            setSidePanelMatchesOpen(status);
            if (status === false) {
                setSelectedMatch(null);
            }
        } else {
            if (sidePanelMatchesOpen === true) {
                setSidePanelMatchesOpen(false);
                setSelectedMatch(null);
            } else {
                setSidePanelMatchesOpen(true);
            }
        }
    }

    function handleToggleRoundSidePanel(status) {
        setSidePanelMatchesOpen(false);
        if (status) {
            setSidePanelRoundsOpen(status);
            if (status === false) {
                setSelectedRound(null);
            }
        } else {
            if (sidePanelRoundsOpen === true) {
                setSidePanelRoundsOpen(false);
                setSelectedRound(null);
            } else {
                setSidePanelRoundsOpen(true);
            }
        }
    }

    function handleMatchDelete(match) {
        confirm({
            title: `Are you sure you want to delete match ${match.identifier}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteBracketMatch({
                    variables: {
                        tournament_bracket_template_id: selectedTemplate.id,
                        id: match.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG,
                            variables: { slug: slug },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Match deleted');
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    function renderFooterTextWithIdentifiers(round, rounds, match) {
        return (
            <Button
                type="link"
                onClick={(e) => {
                    console.log(round, rounds, match);
                }}
                className="match-link"
            >
                {round.round === 1
                    ? `${match.identifier} ${match.loser_to ? `(L to ${match.loser_to})` : ''}`
                    : round.round === rounds.length + 1
                    ? match.is_consolidation_finals === true
                        ? `${match.identifier} (Double Elim Final)`
                        : `${match.identifier} (Final)`
                    : round.round === rounds.length
                    ? match.is_consolidation_finals === true
                        ? `${match.identifier} (Final)`
                        : `${match.identifier}`
                    : round.round > 1 && match.loser_to
                    ? `${match.identifier} (L to ${match.loser_to})`
                    : round.round % 2 !== 0 && match.loser_from
                    ? `${match.identifier} (L from ${match.loser_from_num} - W to ${match.winner_to_num})`
                    : round.round % 2 === 1
                    ? `${match.winner_to_num ? `${match.identifier} (W to ${match.winner_to_num})` : ''}`
                    : `${match.identifier}`}
            </Button>
        );
    }

    function buildBracket(rounds) {
        let bracket = [];
        let roundNum = 0;

        // console.log(rounds);

        // createRound();

        rounds.forEach((round, i) => {
            if (i > 0 && round.round === rounds[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== rounds[i - 1].round) {
                bracket.push([round]);
                // createRound();
                roundNum++;
            }
        });

        const loserBracket =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round < 1;
                })
                .reverse()
                .map((rounds) => {
                    return {
                        ...rounds[0],
                        matches: rounds[0].matches,
                    };
                });

        const draw =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round === 1;
                })
                .map((rounds) => {
                    return {
                        ...rounds[0],
                        matches: rounds[0].matches,
                    };
                });

        const winnerBracket =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round > 1;
                })
                .map((rounds) => {
                    return {
                        ...rounds[0],
                        matches: rounds[0].matches,
                    };
                });

        const output = {
            rounds,
            loserBracket,
            drawRound: draw,
            winnerBracket,
        };

        console.log('bracket', output);
        return output;
    }

    const Round = function (props) {
        const { theme, round, rounds, segment, i } = props;
        return (
            <div className={`round r${i} compact`}>
                <div
                    className="round-header"
                    style={{
                        color: theme && theme.bracket && theme.bracket.roundHeader && theme.bracket.roundHeader.color,
                        backgroundColor: theme && theme.bracket && theme.bracket.roundHeader && theme.bracket.roundHeader.backgroundColor,
                    }}
                >
                    {round ? (
                        <Button
                            size="small"
                            ghost
                            onClick={() => {
                                handleRoundClick(round);
                            }}
                        >
                            Round {round.round} ({round.identifier})
                        </Button>
                    ) : (
                        <div style={{ marginTop: 5, marginBottom: 5 }}>
                            <Button
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => {
                                    createRound({
                                        rounds: rounds,
                                        segment: segment.id,
                                        ...(round && { round: round.round }),
                                    });
                                }}
                            >
                                + Add Round
                            </Button>
                        </div>
                    )}
                </div>
                <div className="spacer" />
                {props.children}

                <div className="spacer" />

                {round && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button
                            type="primary"
                            size="small"
                            ghost
                            onClick={() => {
                                createMatch({
                                    rounds: rounds,
                                    round: round,
                                    roundNum: round ? round.round : 1,
                                });
                            }}
                        >
                            + Add Match
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const Match = function (props) {
        const { round, match, index } = props;
        const rounds = round.matches;

        return (
            <React.Fragment>
                <div
                    id={match.identifier}
                    className={`match ${rounds.length > 1 ? (index % 2 === 0 ? 'top' : 'bottom') : ''} not_started w${rounds[0].round}-${index + 1}`}
                >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div className="match-number">{match.match_number}</div>
                        <div style={{ width: '100%' }}>
                            <div
                                className="match-data"
                                onClick={() => {
                                    handleMatchClick(match, round);
                                }}
                                // onMouseEnter={() => {
                                //     if (match.id) {
                                //         setActiveMatch(match.id);
                                //     }
                                // }}
                                // onMouseLeave={() => {
                                //     if (activeMatch) {
                                //         setActiveMatch(null);
                                //     }
                                // }}
                                style={{
                                    border:
                                        selectedMatch && selectedMatch.identifier === match.identifier
                                            ? `1px solid red`
                                            : theme.name === 'dark'
                                            ? `1px solid #142335`
                                            : `1px solid #d3dce4`,
                                }}
                            >
                                <div className="match-scores">
                                    <div className={`player p1 top`}>
                                        <div className="player-seed">
                                            <Text>{match.top_seed || '-'}</Text>
                                        </div>
                                        <div className="player-name">
                                            <div className="winner-loser-from">
                                                <React.Fragment>
                                                    {match.loser_from_top_num && match.loser_from_bottom_num ? (
                                                        <React.Fragment>Loser of match {match.loser_from_top_num}</React.Fragment>
                                                    ) : match.loser_from && (match.winner_from_top_num || match.winner_from_bottom_num) ? (
                                                        <React.Fragment>
                                                            Winner of match {match.winner_from_top_num || match.winner_from_bottom_num}
                                                        </React.Fragment>
                                                    ) : match.winner_from_top_num ? (
                                                        <React.Fragment>
                                                            <React.Fragment>Winner of match {match.winner_from_top_num}</React.Fragment>
                                                        </React.Fragment>
                                                    ) : null}
                                                </React.Fragment>
                                            </div>
                                        </div>
                                        <div className="player-score">
                                            <Text>-</Text>
                                        </div>
                                    </div>
                                    <div className="player p2 bottom">
                                        <div className="player-seed">
                                            <Text>{match.bottom_seed || '-'}</Text>
                                        </div>
                                        <div className="player-name">
                                            <div className="winner-loser-from">
                                                <React.Fragment>
                                                    {match.loser_from_top_num && match.loser_from_bottom_num ? (
                                                        <React.Fragment>Loser of match {match.loser_from_bottom_num}</React.Fragment>
                                                    ) : match.loser_from && (match.winner_from_top_num || match.winner_from_bottom_num) ? (
                                                        <React.Fragment>Loser of match {match.loser_from_num}</React.Fragment>
                                                    ) : match.winner_from_bottom_num ? (
                                                        <React.Fragment>Winner of match {match.winner_from_bottom_num}</React.Fragment>
                                                    ) : null}
                                                </React.Fragment>
                                            </div>
                                        </div>
                                        <div className="player-score">
                                            <Text>-</Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="match-footer">
                                <div
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Text>{round && renderFooterTextWithIdentifiers(round, rounds, match)} </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                height: '98%',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                position: 'relative',
                                paddingTop: 10,
                                paddingBottom: 10,
                                // position: 'absolute',
                                // right: -35,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '0px 10px 10px 0px',
                            }}
                        >
                            <Tooltip title="Edit match" placement="right">
                                <Button
                                    type="link"
                                    ghost
                                    onClick={() => {
                                        handleMatchClick(match, round);
                                    }}
                                    size="small"
                                >
                                    <Icon type="edit" />
                                </Button>
                            </Tooltip>
                            <div style={{ height: 5 }} />
                            <Tooltip title="Delete match" placement="right">
                                <Button
                                    type="link"
                                    ghost
                                    onClick={() => {
                                        handleMatchDelete(match);
                                    }}
                                    size="small"
                                >
                                    <Icon type="delete" />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                {rounds.length > 1 && index === 0 ? (
                    <div className={`match-spacer blue ${rounds[0].round % 2 === 0 ? 'even' : 'odd'}`}>
                        {/* {rounds[index].round > 1 && `${alphabet[rounds[0].round - 2]}${index + 1}-${alphabet[rounds[0].round - 2]}${index + 2}`} */}
                    </div>
                ) : null}

                {index > 0 && index % 2 === 0 ? (
                    <div className={`match-spacer red ${rounds[0].round % 2 === 0 ? 'even' : 'odd'}`}>
                        {/* {rounds[index].round > 1 && `${alphabet[rounds[0].round - 2]}${index + 1}-${alphabet[rounds[0].round - 2]}${index + 2}`} */}
                    </div>
                ) : null}

                {index < rounds.length - 1 && index % 2 === 1 ? <div className="match-spacer green no-connector" /> : null}
            </React.Fragment>
        );
    };

    function handleSegmentModalOk(e) {
        setSegmentModalVisible(false);
    }

    function handleSegmentModalCancel(e) {
        setSegmentModalVisible(false);
    }

    function handleAddSegment() {
        setSegmentModalVisible(true);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Bracket Creator"
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle="Create custom bracket"
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/tournament-builder/bracket-creator`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="tournamentBuilder"
            >
                <Button size="default" type="ghost" ghost onClick={handleAddSegment}>
                    + Add Segment
                </Button>
            </SectionHeader>
            <SectionContent padding="0px" className="bracket-wrapper">
                <Query
                    query={GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    variables={{ slug: slug }}
                    onCompleted={(data) => {
                        const template = data && data.tournament_bracket_templates && data.tournament_bracket_templates[0];
                        console.log(template);
                        setSelectedTemplate(template);
                        // const matches = template && template.matches;
                        // const brackets = buildBracket(matches);
                        // setBracket(brackets);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading)
                            return (
                                <Card>
                                    <CircularLoader />
                                    <Text style={{ marginLeft: 10 }}>Loading...</Text>
                                </Card>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.tournament_bracket_templates) || (data && data.tournament_bracket_templates.length === 0)) {
                            return (
                                <Card>
                                    <ZeroState showImage message="Template not found." {...props} />
                                </Card>
                            );
                        }

                        const template = data.tournament_bracket_templates && data.tournament_bracket_templates[0];
                        segments = template && template.segments;
                        rounds = [];
                        segments &&
                            segments.forEach((segment) => {
                                segment.rounds &&
                                    segment.rounds.map((round) => {
                                        rounds.push(round);
                                    });
                            });

                        const roundsSorted = _.sortBy(rounds, ['round']);
                        matches = [];
                        roundsSorted.forEach((round) => {
                            round.matches &&
                                round.matches.map((match) => {
                                    matches.push(match);
                                });
                        });

                        const bracket = buildBracket(roundsSorted);

                        console.log(bracket);

                        return (
                            <div id="bracket-container" className="full-screenable-node site-drawer-render-in-current-wrapper">
                                <Layout style={{ height: 800 }}>
                                    <Content className="bracket-compact dark" style={{ position: 'relative', backgroundColor: theme.bracket.backgroundColor }}>
                                        <ScrollContainer
                                            id="bracket"
                                            className="scroll-container"
                                            vertical={true}
                                            horizontal={true}
                                            hideScrollbars={false}
                                            activationDistance={1}
                                            ignoreElements=".prevent-drag-scroll"
                                            nativeMobileScroll={true}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                position: 'absolute',
                                                zIndex: 0,
                                                // background: 'linear-gradient( #fafafa, #f0f2f5)'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    cursor: 'move',
                                                    transform: `scale(0.7)`,
                                                    transformOrigin: 'top left',
                                                    transition: 'all 150ms cubic-bezier(0, 0, 0.58, 1)',
                                                }}
                                                className="bracket-surface noselect mobilegpu"
                                            >
                                                <div className="rounds" style={{ display: 'inline-block' }}>
                                                    {segments &&
                                                        segments.map((segment, index) => {
                                                            return (
                                                                <div className="segment" key={index}>
                                                                    <div className="segment-title">
                                                                        <Text>{segment.name}</Text>
                                                                    </div>
                                                                    {segment.slug === 'a-side' ? (
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="draw">
                                                                                {/* <div>Draw Segment</div> */}
                                                                                {bracket &&
                                                                                    bracket.drawRound.map((round, i) => {
                                                                                        return (
                                                                                            <React.Fragment key={i}>
                                                                                                <Round
                                                                                                    bracket={bracket.drawRound}
                                                                                                    rounds={rounds}
                                                                                                    round={round}
                                                                                                    segment={segment}
                                                                                                    i={i}
                                                                                                >
                                                                                                    {round.matches && round.matches.length > 0 ? (
                                                                                                        round.matches.map((match, i2) => {
                                                                                                            return (
                                                                                                                <Match
                                                                                                                    rounds={rounds}
                                                                                                                    round={round}
                                                                                                                    index={i2}
                                                                                                                    match={match}
                                                                                                                    key={i + i2}
                                                                                                                />
                                                                                                            );
                                                                                                        })
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: 'flex',
                                                                                                                justifyContent: 'center',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Text>No matches yet.</Text>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Round>
                                                                                                <div className="spacer" />
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                            </div>
                                                                            <div className="a-side">
                                                                                {/* <div>A Side Segment</div> */}
                                                                                {bracket &&
                                                                                    bracket.winnerBracket.map((round, i) => {
                                                                                        return (
                                                                                            <React.Fragment key={i}>
                                                                                                <Round
                                                                                                    bracket={bracket.winnerBracket}
                                                                                                    rounds={rounds}
                                                                                                    round={round}
                                                                                                    segment={segment}
                                                                                                    i={i}
                                                                                                >
                                                                                                    {round.matches && round.matches.length > 0 ? (
                                                                                                        round.matches.map((match, i2) => {
                                                                                                            return (
                                                                                                                <Match
                                                                                                                    rounds={rounds}
                                                                                                                    round={round}
                                                                                                                    index={i2}
                                                                                                                    match={match}
                                                                                                                    key={i + i2}
                                                                                                                />
                                                                                                            );
                                                                                                        })
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: 'flex',
                                                                                                                justifyContent: 'center',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Text>No matches yet.</Text>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Round>
                                                                                                <div className="spacer" />
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                                <Round rounds={rounds} segment={segment} />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="a-side">
                                                                                {/* <div>B Side Segment</div> */}
                                                                                {bracket &&
                                                                                    bracket.loserBracket.map((round, i) => {
                                                                                        return (
                                                                                            <React.Fragment key={i}>
                                                                                                <Round
                                                                                                    bracket={bracket.loserBracket}
                                                                                                    rounds={rounds}
                                                                                                    round={round}
                                                                                                    segment={segment}
                                                                                                    i={i}
                                                                                                >
                                                                                                    {round.matches && round.matches.length > 0 ? (
                                                                                                        round.matches.map((match, i2) => {
                                                                                                            return (
                                                                                                                <Match
                                                                                                                    rounds={rounds}
                                                                                                                    round={round}
                                                                                                                    index={i2}
                                                                                                                    match={match}
                                                                                                                    key={i + i2}
                                                                                                                />
                                                                                                            );
                                                                                                        })
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: 'flex',
                                                                                                                justifyContent: 'center',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Text>No matches yet.</Text>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Round>
                                                                                                <div className="spacer" />
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                                <Round rounds={rounds} segment={segment} />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </ScrollContainer>
                                    </Content>

                                    <SidePanelRounds
                                        {...props}
                                        roundSidePanelOpen={sidePanelRoundsOpen}
                                        onRoundSidePanelClose={() => {
                                            handleToggleRoundSidePanel(false);
                                        }}
                                        onSubmit={updateRoundRecord}
                                        selectedRound={selectedRound}
                                        rounds={rounds}
                                        segments={segments}
                                    />

                                    <SidePanelMatches
                                        {...props}
                                        sidePanelOpen={sidePanelMatchesOpen}
                                        onSidePanelClose={() => {
                                            handleToggleMatchesSidePanel(false);
                                        }}
                                        onSubmit={updateMatchRecord}
                                        selectedMatch={selectedMatch}
                                        matches={matches}
                                    />

                                    <AddBracketSegmentModal
                                        {...props}
                                        modalVisible={segmentModalVisible}
                                        onModalOk={handleSegmentModalOk}
                                        onModalCancel={handleSegmentModalCancel}
                                        template={selectedTemplate}
                                    />
                                </Layout>
                            </div>
                        );
                    }}
                </Query>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBBracketCreatorTemplateDetail);
