import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

// import './styles.css';

export default function CountdownTimerProgress(props) {
    const {
        currentKey,
        size,
        fontSize,
        strokeWidth,
        trailStrokeWidth,
        duration,
        initialRemainingTime,
        isPlaying,
        colors,
        textColor,
        colorsTime,
        shouldRepeat = false,
        onUpdatedTime,
    } = props;

    // console.log('key', currentKey);
    // console.log('duration', duration);
    // console.log('initial remaining time', initialRemainingTime);

    const renderTime = (props) => {
        const { remainingTime } = props;
        return (
            <div className="timer">
                {/* <div className="text">Remaining</div> */}
                <div className="value" style={{ color: textColor, fontSize: fontSize || 18 }}>
                    {remainingTime}
                </div>
                {/* <div className="text">seconds</div> */}
            </div>
        );
    };

    return (
        <div className="timer-wrapper">
            <CountdownCircleTimer
                key={currentKey}
                size={size || 50}
                isPlaying={isPlaying}
                duration={duration}
                initialRemainingTime={initialRemainingTime}
                strokeWidth={strokeWidth || 4}
                trailStrokeWidth={trailStrokeWidth || 4}
                trailColor={'#333'}
                isSmoothColorTransition={true}
                colors={colors || ['#00aa00', '#F7B801', '#A30000']}
                colorsTime={colorsTime || [7, 3, 0]}
                onComplete={() => ({ shouldRepeat: shouldRepeat, delay: 0 })}
                onUpdate={(remainingTime) => {
                    // console.log(remainingTime);
                    if (onUpdatedTime) {
                        onUpdatedTime(remainingTime * 1000);
                    }
                }}
            >
                {renderTime}
            </CountdownCircleTimer>
        </div>
    );
}
