import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Card from '../../components/Card';
import SectionTitle from '../../components/SectionTitle';
import SectionContent from '../../components/SectionContent';

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	}
};

export default function DPLApp(props) {
	return (
		<div style={{ background: 'linear-gradient(#eaedef, #fafafa)' }}>
			<SectionTitle
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/>
			<SectionContent padding="20px 40px">
				<Row gutter={16} justify="center" type="flex">
					<Col xs={24}>
						<Card title="DPL App">App details</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}
