import React, { useState, useEffect } from 'react';
import { message, Alert, Icon, Row, Col, Modal, Typography, Button, Empty } from 'antd';
import Card from '../../components/Card';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import NotifyPlayersModal from './NotifyPlayersModal';
import AddPlayerModal from './AddPlayerModal';
import ImportPlayersModal from './ImportPlayersModal';
import PlayerTable from './PlayerTable';
import LinkPlayerNotification from './LinkPlayerNotification';
import { Query, Mutation } from '@apollo/client/react/components';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_TOURNAMENT_PLAYERS_QUERY, GET_TOURNAMENT_QUERY } from './data/queries';
import { GET_TOURNAMENT_PLAYERS_SUBSCRIPTION2 } from './data/subscriptions';
import { DELETE_TOURNAMENT_PLAYER_MUTATION, UPDATE_TOURNAMENT_PLAYER_MUTATION, CREATE_PLAYER_MUTATION } from './data/mutations';
import ListFargoModal from './ListFargoModal';
import { Parser } from 'json2csv';
import _ from 'lodash';
import slugify from 'slugify';
import fargoLogoCircle from '../../assets/img/fargo-logo-circle.png';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function TBPlayers(props) {
    const { tournament, authState, client } = props;
    const [loading, setLoading] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    const [playersLinked, setPlayersLinked] = useState(false);
    const [reloaded, setReloaded] = useState(false);
    const [playerImportModalVisible, setPlayerImportModalVisible] = useState(false);
    const [playerAddModalVisible, setPlayerAddModalVisible] = useState(false);
    const [playerNotifyModalVisible, setPlayerNotifyModalVisible] = useState(false);
    // const [ players, setPlayers ] = useState([]);
    const hasSelected = selectedKeys && selectedKeys.length > 0;
    const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);
    const [fargoModalVisible, setFargoModalVisible] = useState(false);
    const [createPlayers] = useMutation(CREATE_PLAYER_MUTATION);

    function handleRemoveSelected() {
        showConfirmDelete();
    }

    function maskPhoneNumber(number) {
        let maskedStr;
        if (number) {
            maskedStr = number.replace(/^(.{2}).*(.{2})$/, (match, p1, p2) => {
                let mask = '';
                for (let i = 0; i < number.length - 4; i++) {
                    mask += '*';
                }
                return p1 + mask + p2;
            });
        }
        return maskedStr; // "12******90"
    }

    function showConfirmDelete() {
        confirm({
            title: `Are you sure you want to remove ${selectedRows.length} players from this tournament?`,
            content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                let promises = [];
                selectedRows.forEach(async (row) => {
                    promises.push(
                        await props.client
                            .mutate({
                                mutation: DELETE_TOURNAMENT_PLAYER_MUTATION,
                                variables: {
                                    tournament_id: tournament.id,
                                    id: row.id,
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                                        variables: { tournament_id: tournament.id },
                                    },
                                    {
                                        query: GET_TOURNAMENT_QUERY,
                                        variables: { id: tournament.id },
                                    },
                                ],
                            })
                            .then((data) => {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.log(error);
                                message.error('There was an error', error);
                            })
                    );
                });

                Promise.all(promises).then((values) => {
                    message.success(`${selectedRows.length} Players removed from tournament`);
                    setSelectedKeys([]);
                    setSelectedRows([]);
                });
            },
            onCancel() {},
        });
    }

    function handleImportModalOk(e) {
        console.log(e);
        setPlayerImportModalVisible(false);
    }

    function handlePlayerImportModalCancel(e) {
        setPlayerImportModalVisible(false);
    }

    function handlePlayerAddModalOk(data) {
        console.log(data);
        if (data) {
            // const updatedPlayers = players.concat(data);
            // setPlayers(updatedPlayers);
        }
        setPlayerAddModalVisible(false);
        setSelectedPlayerId(null);
    }

    function handlePlayerAddModalCancel(e) {
        setPlayerAddModalVisible(false);
        setSelectedPlayerId(null);
    }

    function handleSelected(selectedKeys, selectedRows) {
        setSelectedKeys(selectedKeys);
        setSelectedRows(selectedRows);
    }

    function handleEditSelected(selected) {
        console.log(selected);
        setSelectedPlayerId(selected);
        setPlayerAddModalVisible(true);
    }

    function handlePlayerAdd() {
        setSelectedPlayerId(null);
        setPlayerAddModalVisible(true);
    }

    function handlePlayerNotifyOk(e) {
        setPlayerNotifyModalVisible(false);
    }

    function handlePlayerNotifyModalCancel() {
        setPlayerNotifyModalVisible(false);
    }

    function handlePlayerNotify() {
        setPlayerNotifyModalVisible(true);
    }

    function handlePlayerImport() {
        setPlayerImportModalVisible(true);
    }

    function orderAndFormatData(arr, csv = false) {
        let count = arr.length;
        let formattedResults = [];
        for (let i = 0; i < count; i++) {
            let player = arr[i];

            formattedResults.push({
                name: player.name,
                country: player.country,
                country_phone: player.country_phone,
                email: player.email,
                phone_number: maskPhoneNumber(player.phone_number),
                fargo_id: player.fargo_id,
                seed: player.seed,
                skill_level: player.skill_level,
                race_to: player.race_to,
                status: player.status,
            });
        }

        console.log(formattedResults);

        return formattedResults;
    }

    async function downloadCSV() {
        setCSVDownloadLoading(true);

        let query = await client.query({
            query: GET_TOURNAMENT_PLAYERS_QUERY,
            variables: {
                tournament_id: tournament.id,
            },
        });

        if (!query.data.tournament_players.length) {
            message.success('There was an error');
            setCSVDownloadLoading(false);
        }

        let filename = tournament.slug + '.csv';
        let data = orderAndFormatData(query.data.tournament_players, true);
        const fields = ['name', 'country', 'email', 'country_phone', 'phone_number', 'fargo_id', 'seed', 'skill_level', 'race_to', 'status'];
        // const csv = await new objtocsv(data).toString();

        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(data);

        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }

        message.success('Exporting completed');
        setCSVDownloadLoading(false);
    }

    async function handleCreatePlayers(players) {
        const playersToInsert = players.map((player) => {
            const nameSlug = slugify(player.name, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });

            return {
                name: player.name,
                name_slug: nameSlug,
                country: player.country,
                phone_number: player.phone_number,
                fargo_id: player.fargo_id,
                ...(player.fargo_id && { fargo_rating: player.skill_level }),
            };
        });

        console.log({
            players,
            playersToInsert,
        });

        const key = 'insertable';
        message.loading({ content: `Adding ${playersToInsert.length} player records...`, key, duration: 0 });

        createPlayers({
            variables: {
                objects: playersToInsert,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                    variables: { tournament_id: tournament.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `${playersToInsert.length} players were added`,
                    key,
                    duration: 2,
                });
                setReloaded(true);
            })
            .catch((error) => {
                console.error(error);
                message.error('There was an error', JSON.stringify(error));
                setReloaded(false);
            });
    }

    async function handleUnlinkedPlayers(players, tournamentPlayers) {
        console.log({
            players,
            tournamentPlayers,
        });
        const playersToUpdate = _.compact(
            tournamentPlayers.map((item, index) => {
                const newPlayer = players.filter((player) => {
                    const tournamentPlayerName = item.name && item.name.toLowerCase();
                    const playerName = player.name && player.name.toLowerCase();
                    return tournamentPlayerName === playerName;
                })[0];

                console.log(newPlayer);

                if (newPlayer) {
                    return {
                        id: parseInt(item.id),
                        player_id: parseInt(newPlayer.id),
                        ...(newPlayer.country && { country: newPlayer.country }),
                        ...(newPlayer.fargo_id && { fargo_id: newPlayer.fargo_id }),
                        ...(newPlayer.fargo_rating && { skill_level: newPlayer.fargo_rating }),
                        ...(newPlayer.phone_number && { phone_number: newPlayer.phone_number }),
                        ...(newPlayer.user_id && { user_id: newPlayer.user_id }),
                    };
                }
            })
        );
        console.log(playersToUpdate);

        const key = 'linkable';
        message.loading({ content: `Linking ${playersToUpdate.length} player records...`, key, duration: 0 });

        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result
            .then((e) => {
                message.success({
                    content: `Player records have been linked.`,
                    key,
                    duration: 2,
                });

                setPlayersLinked(true);
                setReloaded(true);
                // console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
            })
            .catch((error) => {
                console.error(error);
                message.error('There was an error', JSON.stringify(error));
                setPlayersLinked(false);
                setReloaded(false);
            });

        // const slug = slugify(newPlayer.name, {
        //     replacement: '-',
        //     remove: /[*+~.,()'"#!:/@]/g,
        //     lower: true,
        // });
        // createPlayer({
        //     variables: {
        //         objects: [
        //             {
        //                 name: name,
        //                 name_slug: slug,
        //                 ...(data.aliases && { aliases: data.aliases }),
        //                 ...(data.properties && { properties: data.properties }),
        //                 ...(data.image_large && { image_large: data.image_large }),
        //                 ...(data.image_small && { image_small: data.image_small }),
        //                 ...(data.country && { country: data.country }),
        //                 ...(data.gender && { gender: data.gender }),
        //                 ...(data.id && { user_id: data.id }),
        //                 ...(data.fargo_id && { fargo_id: parseInt(data.fargo_id) }),
        //                 ...(data.fargo_robustness && { fargo_robustness: parseInt(data.fargo_robustness) }),
        //                 ...(data.fargo_readable_id && { fargo_readable_id: parseInt(data.fargo_readable_id) }),
        //                 ...(data.fargo_effective_rating && { fargo_effective_rating: data.fargo_effective_rating }),
        //             },
        //         ],
        //     },
        // })
        //     .then((res) => {
        //         console.log(res);
        //         const added = res.data.insert_players.returning[0];

        //         if (data.id) {
        //             updateUser({
        //                 variables: {
        //                     id: data.id,
        //                     changes: {
        //                         player_id: added.id,
        //                     },
        //                 },
        //                 notifyOnNetworkStatusChange: true,
        //                 awaitRefetchQueries: true,
        //                 refetchQueries: [
        //                     {
        //                         query: GET_ALL_USERS_QUERY,
        //                         variables: { limit: pageSize, offset: (currentPage - 1) * pageSize || 0 },
        //                     },
        //                 ],
        //             })
        //                 .then((data) => {
        //                     console.log(data);
        //                     setLoading(false);
        //                     message.success(`Player record successfully updated`);
        //                 })
        //                 .catch((error) => {
        //                     console.log(error);
        //                     setLoading(false);
        //                     message.error('There was an error', JSON.stringify(error.message));
        //                 });
        //         } else {
        //             console.log(data);
        //             setLoading(false);
        //             message.success(`Player record for ${name} successfully added`);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         setLoading(false);
        //         if (error.message && error.message.includes('Uniqueness violation')) {
        //             // message.error('Error: There is already a player record with the same name');
        //             showConfirmLink(data);
        //         } else {
        //             message.error((error && error.message) || JSON.stringify(error));
        //         }
        //     });
    }

    async function handleFargoModalOk(players, tournamentPlayers) {
        const playersToUpdate = _.compact(
            tournamentPlayers.map((item, index) => {
                const newPlayer = players
                    .filter((player) => {
                        return item.id === player.id;
                    })
                    .filter((player) => {
                        return parseInt(player.effectiveRating) !== parseInt(player.skillLevel);
                    })[0];

                // console.log(newPlayer);

                if (newPlayer) {
                    return {
                        id: parseInt(item.id),
                        skill_level: parseInt(newPlayer.effectiveRating),
                        fargo_id: parseInt(newPlayer.fargoId),
                    };
                }
            })
        );
        console.log(playersToUpdate);

        const key = 'updatable';
        message.loading({ content: `Updating ${playersToUpdate.length} player Fargo Ratings...`, key, duration: 0 });

        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success({
                content: `Updated player Fargo Ratings.`,
                key,
                duration: 2,
            });
            // console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
        });
        console.log(players);
        setFargoModalVisible(false);
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                        variables: { tournament_id: tournament.id },
                    },
                    // {
                    //     query: GET_TOURNAMENT_QUERY,
                    //     variables: { id: tournament.id },
                    // },
                ],
            })
            .then((data) => {
                // console.log(data);

                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function handleFargoModalCancel(e) {
        setFargoModalVisible(false);
    }

    function handleGetFargoRatings(players) {
        setFargoModalVisible(true);

        // skill_level = fargo && fargo.rating;
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="players" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Alert
                            message="Helpful tips for adding players"
                            description="You can add players individually or many at once by using the Player Import feature. If you include phone numbers, you will be able to send SMS notifications to those players during the tournament. After adding your players, head over to the bracket page to start managing your tournament."
                            type="info"
                            closable
                            showIcon
                        />
                    </Col>
                    <Col xs={24}>
                        <Query
                            query={GET_TOURNAMENT_PLAYERS_QUERY}
                            fetchPolicy="cache-and-network"
                            // notifyOnNetworkStatusChange
                            awaitRefetchQueries
                            variables={{ tournament_id: tournament.id }}
                            onCompleted={(data) => {
                                // setPlayers(data.tournament_players);
                                console.log(data);
                                console.log(tournament);

                                // const players = data.tournament_players;
                                // const firstPlayer = players[0];
                                // let fargoRatings = [];
                                // const fargoRating = await getFargoRatingByName(firstPlayer);
                                // const fargoRating = await getFargoRatingByName({
                                // 	name: 'paul penrose'
                                // });
                                // console.log(fargoRating);
                                // players.forEach(async (player) => {
                                // 	const data = await getFargoRatingByName(player);
                                // 	if (data) {
                                // 		fargoRatings.push({
                                // 			id: data.id,
                                // 			effectiveRating: data.effectiveRating,
                                // 			firstName: data.firstName,
                                // 			lastName: data.lastName,
                                // 			imageUrl: data.imageUrl,
                                // 			lmsId: data.lmsId,
                                // 			location: data.location,
                                // 			membershipId: data.membershipId,
                                // 			membershipNumber: data.membershipNumber,
                                // 			provisionalRating: data.privisionalRating,
                                // 			rating: data.rating,
                                // 			ratingHistory: data.ratingHistory,
                                // 			readableId: data.readableId,
                                // 			robustness: data.robustness,
                                // 			statsByRating: data.statsByRating,
                                // 			statsOverall: data.statsOverall
                                // 		});
                                // 	}

                                // 	return data;
                                // });
                                // console.log(fargoRatings);
                            }}
                        >
                            {({ loading, error, data, subscribeToMore }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const tournamentPlayers =
                                    data.tournament_players &&
                                    data.tournament_players.map((item, index) => {
                                        if (item.user) {
                                            return {
                                                ...item.user,
                                                ...item,
                                                id: item.id,
                                                // name: `${item.user.first_name} ${item.user.last_name}`,
                                                index: index + 1,
                                                key: index,
                                            };
                                        } else {
                                            return {
                                                ...item,
                                                id: item.id,
                                                index: index + 1,
                                                key: index,
                                            };
                                        }
                                    });

                                return (
                                    <Card
                                        // title="Players"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        cover={
                                            <div>
                                                <div
                                                    style={{
                                                        paddingTop: 16,
                                                        paddingBottom: 16,
                                                        paddingLeft: 24,
                                                        paddingRight: 24,
                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                        Players ({tournament.tournament_players_aggregate.aggregate.count})
                                                    </Title>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Button type="primary" onClick={handlePlayerAdd}>
                                                            <Icon type="user-add" /> Add Player
                                                        </Button>
                                                        <Button type="primary" onClick={handlePlayerImport}>
                                                            <Icon type="usergroup-add" /> Import Spreadsheet
                                                        </Button>
                                                        <Button
                                                            type="secondary"
                                                            onClick={() => {
                                                                handleGetFargoRatings(tournamentPlayers);
                                                            }}
                                                            disabled={tournamentPlayers && tournamentPlayers.length > 0 ? false : true}
                                                        >
                                                            <img src={fargoLogoCircle} alt="Fargo Logo" height={20} style={{ marginRight: 5 }} />
                                                            Get Fargo Ratings
                                                        </Button>
                                                        <Button type="default" onClick={handlePlayerNotify}>
                                                            <Icon type="bell" /> Notify
                                                        </Button>
                                                        <Button type="primary" onClick={downloadCSV}>
                                                            <Icon type={csvDownloadLoading ? 'loading' : 'download'} /> Export CSV
                                                        </Button>
                                                        <div style={{ marginLeft: 10 }}>
                                                            <Button type="danger" onClick={handleRemoveSelected} disabled={!hasSelected} loading={loading}>
                                                                Remove
                                                            </Button>
                                                            <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {playersLinked === false && (
                                                    <div>
                                                        <LinkPlayerNotification
                                                            {...props}
                                                            reloaded={reloaded}
                                                            players={tournamentPlayers}
                                                            onUpdatePlayers={(players) => {
                                                                handleUnlinkedPlayers(players, tournamentPlayers);
                                                            }}
                                                            onCreatePlayers={(players) => {
                                                                handleCreatePlayers(players);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    >
                                        <Mutation
                                            mutation={DELETE_TOURNAMENT_PLAYER_MUTATION}
                                            // variables={venue ? { venue_id: venue.id } : null}
                                            onCompleted={(data) => {
                                                console.log(data);
                                                setSelectedKeys([]);
                                                setSelectedRows([]);
                                                setSelectedPlayerId(null);
                                                // const deleted = data.delete_tournament_players.returning[0];
                                                // const updatedPlayers = players.filter((item) => {
                                                // 	return item.id !== deleted.id;
                                                // });
                                                // setPlayers(updatedPlayers);
                                            }}
                                            notifyOnNetworkStatusChange
                                            awaitRefetchQueries
                                        >
                                            {(deletePlayer, { loading, error }) => (
                                                <PlayerTable
                                                    {...props}
                                                    tournament={tournament}
                                                    selectedKeys={selectedKeys}
                                                    onSelectedKeys={(key) => {
                                                        setSelectedKeys(key);
                                                    }}
                                                    size="small"
                                                    onDelete={(record) => {
                                                        deletePlayer({
                                                            variables: {
                                                                tournament_id: tournament.id,
                                                                id: record.id,
                                                            },
                                                            notifyOnNetworkStatusChange: true,
                                                            awaitRefetchQueries: true,
                                                            refetchQueries: [
                                                                {
                                                                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                                                                    variables: { tournament_id: tournament.id },
                                                                },
                                                                {
                                                                    query: GET_TOURNAMENT_QUERY,
                                                                    variables: { id: tournament.id },
                                                                },
                                                            ],
                                                        })
                                                            .then((res) => {
                                                                console.log(res);
                                                                setSelectedKeys([]);
                                                                setSelectedRows([]);
                                                                message.success('Player successfully deleted');
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                setSelectedKeys([]);
                                                                setSelectedRows([]);
                                                                message.error('There was an error', error);
                                                            });
                                                    }}
                                                    onSelected={handleSelected}
                                                    onEditSelected={handleEditSelected}
                                                    players={tournamentPlayers}
                                                    subscribeToMore={subscribeToMore}
                                                    subscribeToNewData={() => {
                                                        subscribeToMore({
                                                            document: GET_TOURNAMENT_PLAYERS_SUBSCRIPTION2,
                                                            variables: { tournament_id: tournament.id },
                                                            updateQuery: (prev, { subscriptionData }) => {
                                                                if (!subscriptionData.data) return prev;
                                                                const newPlayers = subscriptionData.data.tournament_players;
                                                                let updated;
                                                                if (prev) {
                                                                    updated = Object.assign({}, prev, {
                                                                        tournament_players: [...newPlayers],
                                                                    });
                                                                } else {
                                                                    updated = Object.assign({}, prev, {
                                                                        tournament_players: [...newPlayers],
                                                                    });
                                                                }
                                                                return updated;
                                                            },
                                                        });
                                                    }}
                                                />
                                            )}
                                        </Mutation>
                                        <ImportPlayersModal
                                            userID={authState.user.id}
                                            players={tournamentPlayers}
                                            tournamentID={tournament.id}
                                            onModalOk={handleImportModalOk}
                                            onModalCancel={handlePlayerImportModalCancel}
                                            modalVisible={playerImportModalVisible}
                                            {...props}
                                        />
                                        <AddPlayerModal
                                            selectedPlayerId={selectedPlayerId}
                                            players={tournamentPlayers}
                                            userID={authState.user.id}
                                            tournamentID={tournament.id}
                                            onModalOk={handlePlayerAddModalOk}
                                            onModalCancel={handlePlayerAddModalCancel}
                                            modalVisible={playerAddModalVisible}
                                            {...props}
                                        />

                                        <ListFargoModal
                                            tournament={tournament}
                                            players={tournamentPlayers}
                                            modalVisible={fargoModalVisible}
                                            onModalOk={(players) => {
                                                handleFargoModalOk(players, tournamentPlayers);
                                            }}
                                            onModalCancel={handleFargoModalCancel}
                                        />

                                        <NotifyPlayersModal
                                            tournamentID={tournament.id}
                                            onModalOk={handlePlayerNotifyOk}
                                            onModalCancel={handlePlayerNotifyModalCancel}
                                            modalVisible={playerNotifyModalVisible}
                                            {...props}
                                        />
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
