import React, { useState, useEffect } from 'react';
import { Typography, Table } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { getCountryName } from '../../../utils';
import { GET_CURRENT_PRO_RANKINGS_QUERY } from './data/queries';
import { Query } from '@apollo/client/react/components';
import RankingSystemPicker from './RankingSystemPicker';
import CircleFlag from '../../../components/CircleFlag';
import moment from 'moment';
import _ from 'lodash';

const { Title, Text } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default function CurrentProRankings(props) {
    const { theme, rankings } = props;
    const [selectedRankingSystem, setSelectedRankingSystem] = useState('ALL_RANKINGS');
    const [sortedInfo, setSortedInfo] = useState({
        order: 'ascend',
        columnKey: 'fargo_ranking',
    });

    function handleSelectedRankingSystem(id) {
        console.log(id);
        setSelectedRankingSystem(id);
        setSortedInfo({
            order: 'ascend',
            columnKey: id === 'ALL_RANKINGS' ? 'fargo_ranking' : id.toLowerCase(),
        });
    }

    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 240,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {record.country && <CircleFlag country={getCountryName(record.country)} size="small" left={0} />} <Text>{text}</Text>
                        </div>
                        <div>
                            <Text>{record.country}</Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Fargo US Ranking',
            dataIndex: 'fargo_us_ranking',
            key: 'fargo_us_ranking',
            // defaultSortOrder: 'asc',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'fargo_us_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.fargo_us_ranking && b && b.fargo_us_ranking) {
                    return a.fargo_us_ranking - b.fargo_us_ranking;
                } else if (a && a.fargo_us_ranking) {
                    return -1;
                } else if (b && b.fargo_us_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Fargo World Ranking',
            dataIndex: 'fargo_ranking',
            key: 'fargo_ranking',
            defaultSortOrder: 'asc',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'fargo_ranking' && sortedInfo.order,
            ellipsis: true,
            // width: 100,
            sorter: (a, b) => {
                if (a && a.fargo_ranking && b && b.fargo_ranking) {
                    return a.fargo_ranking - b.fargo_ranking;
                } else if (a && a.fargo_ranking) {
                    return -1;
                } else if (b && b.fargo_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: "Fargo World Men's Ranking",
            dataIndex: 'fargo_mens_ranking',
            key: 'fargo_mens_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'fargo_mens_ranking' && sortedInfo.order,
            ellipsis: true,
            // width: 100,
            sorter: (a, b) => {
                if (a && a.fargo_mens_ranking && b && b.fargo_mens_ranking) {
                    return a.fargo_mens_ranking - b.fargo_mens_ranking;
                } else if (a && a.fargo_mens_ranking) {
                    return -1;
                } else if (b && b.fargo_mens_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: "Fargo World Women's Ranking",
            dataIndex: 'fargo_womens_ranking',
            key: 'fargo_womens_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'fargo_womens_ranking' && sortedInfo.order,
            ellipsis: true,
            // width: 100,
            sorter: (a, b) => {
                if (a && a.fargo_womens_ranking && b && b.fargo_womens_ranking) {
                    return a.fargo_womens_ranking - b.fargo_womens_ranking;
                } else if (a && a.fargo_womens_ranking) {
                    return -1;
                } else if (b && b.fargo_womens_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Matchroom ranking',
            dataIndex: 'matchroom_ranking',
            key: 'matchroom_ranking',
            defaultSortOrder: 'asc',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'matchroom_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.matchroom_ranking && b && b.matchroom_ranking) {
                    return a.matchroom_ranking - b.matchroom_ranking;
                } else if (a && a.matchroom_ranking) {
                    return -1;
                } else if (b && b.matchroom_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'US Pro Series Ranking',
            dataIndex: 'us_proseries_ranking',
            key: 'us_proseries_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'us_proseries_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.us_proseries_ranking && b && b.us_proseries_ranking) {
                    return a.us_proseries_ranking - b.us_proseries_ranking;
                } else if (a && a.us_proseries_ranking) {
                    return -1;
                } else if (b && b.us_proseries_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: "WPA World Men's Ranking",
            dataIndex: 'wpa_mens_ranking',
            key: 'wpa_mens_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'wpa_mens_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.wpa_mens_ranking && b && b.wpa_mens_ranking) {
                    return a.wpa_mens_ranking - b.wpa_mens_ranking;
                } else if (a && a.wpa_mens_ranking) {
                    return -1;
                } else if (b && b.wpa_mens_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: "WPA World Women's Ranking",
            dataIndex: 'wpa_womens_ranking',
            key: 'wpa_womens_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'wpa_womens_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.wpa_womens_ranking && b && b.wpa_womens_ranking) {
                    return a.wpa_womens_ranking - b.wpa_womens_ranking;
                } else if (a && a.wpa_womens_ranking) {
                    return -1;
                } else if (b && b.wpa_womens_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Digital Pool Ranking',
            dataIndex: 'digital_pool_ranking',
            key: 'digital_pool_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'digital_pool_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.digital_pool_ranking && b && b.digital_pool_ranking) {
                    return a.digital_pool_ranking - b.digital_pool_ranking;
                } else if (a && a.digital_pool_ranking) {
                    return -1;
                } else if (b && b.digital_pool_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: "Digital Pool Men's Ranking",
            dataIndex: 'digital_pool_mens_ranking',
            key: 'digital_pool_mens_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'digital_pool_mens_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.digital_pool_mens_ranking && b && b.digital_pool_mens_ranking) {
                    return a.digital_pool_mens_ranking - b.digital_pool_mens_ranking;
                } else if (a && a.digital_pool_mens_ranking) {
                    return -1;
                } else if (b && b.digital_pool_mens_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: "Digital Pool Women's Ranking",
            dataIndex: 'digital_pool_womens_ranking',
            key: 'digital_pool_womens_ranking',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sortOrder: sortedInfo.columnKey === 'digital_pool_womens_ranking' && sortedInfo.order,
            ellipsis: true,
            sorter: (a, b) => {
                if (a && a.digital_pool_womens_ranking && b && b.digital_pool_womens_ranking) {
                    return a.digital_pool_womens_ranking - b.digital_pool_mens_ranking;
                } else if (a && a.digital_pool_womens_ranking) {
                    return -1;
                } else if (b && b.digital_pool_womens_ranking) {
                    return 1;
                }
                return 0;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Rank Change',
            dataIndex: 'rank_change',
            key: 'rank_change',
            sortOrder: sortedInfo.columnKey === 'rank_change' && sortedInfo.order,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {record.rank_change && (
                            <Text
                                style={{
                                    color: record.rank_change.includes('+')
                                        ? theme.colors.success500
                                        : record.rank_change.includes('-')
                                        ? theme.colors.error500
                                        : 'inherit',
                                }}
                            >
                                {record.rank_change}
                            </Text>
                        )}
                    </div>
                );
            },
        },
    ];

    function calculateRankingChange(history) {
        if (history[0] && history[1]) {
            if (history[0].ranking > history[1].ranking) {
                const diff = history[0].ranking - history[1].ranking;
                return `-${diff}`;
            } else if (history[0].ranking < history[1].ranking) {
                const diff = history[1].ranking - history[0].ranking;
                return `+${diff}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    function calculateDiff(current, prev) {
        if (current && prev) {
            if (current > prev) {
                const diff = current - prev;
                return `-${diff}`;
            } else if (current < prev) {
                const diff = prev - current;
                return `+${diff}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const rankingTypes = [
        'digital_pool_mens_ranking',
        'digital_pool_ranking',
        'digital_pool_womens_ranking',
        'fargo_mens_ranking',
        'fargo_ranking',
        'fargo_us_ranking',
        'fargo_womens_ranking',
        'matchroom_ranking',
        'us_proseries_ranking',
        'weighted_fargo_mens_ranking',
        'weighted_fargo_ranking',
        'weighted_fargo_womens_ranking',
        'weighted_matchroom_ranking',
        'weighted_us_proseries_ranking',
        'weighted_wpa_mens_ranking',
        'weighted_wpa_womens_ranking',
        'wpa_mens_ranking',
        'wpa_womens_ranking',
    ];

    function getRankChange(player, player2, allowed) {
        let rankDiff = {};
        if (player2) {
            rankingTypes.forEach((type) => {
                if (player[type] && player2[type]) {
                    const currentRank = player[type];
                    const prevRank = player2[type];
                    rankDiff[type] = calculateDiff(currentRank, prevRank);
                }
            });
        }
        const updatedPlayer = {
            ...player,
            rank_change: rankDiff[allowed[1]],
        };

        return updatedPlayer;
    }

    function filterRankings(rankings, allowed) {
        return rankings
            .filter((player) => {
                return player[allowed[1]] !== null;
            })
            .filter((player) => {
                return player.rank_date_rank !== 2;
            })
            .map((player) => {
                const player2 = previousRankings.filter((item) => {
                    return item.ranked_player_id === player.ranked_player_id;
                })[0];
                return getRankChange(player, player2, allowed);
            })
            .sort((a, b) => {
                return a[allowed[1]] - b[allowed[1]];
            });
    }

    function handleChange(pagination, filters, sorter) {
        console.log('sorter', pagination, filters, sorter);
        setSortedInfo({
            order: 'ascend',
            columnKey: sorter.columnKey,
        });
    }

    const previousRankings = rankings.filter((player) => {
        return player.rank_date_rank === 2;
    });

    let allowed = [];
    let filteredRankings = [];
    if (selectedRankingSystem === 'FARGO_US_RANKINGS') {
        allowed = ['name', 'fargo_us_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'FARGO_WORLD_MENS_RANKINGS') {
        allowed = ['name', 'fargo_mens_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'FARGO_WORLD_WOMENS_RANKINGS') {
        allowed = ['name', 'fargo_womens_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'FARGO_WORLD_RANKINGS') {
        allowed = ['name', 'fargo_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'MATCHROOM_WORLD_RANKINGS') {
        allowed = ['name', 'matchroom_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'US_PRO_SERIES_RANKINGS') {
        allowed = ['name', 'us_proseries_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'WPA_WORLD_MENS_RANKINGS') {
        allowed = ['name', 'wpa_mens_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'WPA_WORLD_WOMENS_RANKINGS') {
        allowed = ['name', 'wpa_womens_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'DIGITAL_POOL_RANKINGS') {
        allowed = ['name', 'digital_pool_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'DIGITAL_POOL_MENS_RANKINGS') {
        allowed = ['name', 'digital_pool_mens_ranking', 'rank_change'];
    } else if (selectedRankingSystem === 'DIGITAL_POOL_WOMENS_RANKINGS') {
        allowed = ['name', 'digital_pool_womens_ranking', 'rank_change'];
    } else {
        allowed = [
            'name',
            // 'fargo_us_ranking',
            // 'fargo_mens_ranking',
            // 'fargo_womens_ranking',
            'fargo_ranking',
            'matchroom_ranking',
            'us_proseries_ranking',
            'wpa_mens_ranking',
            'wpa_womens_ranking',
            // 'digital_pool_ranking',
            // 'rank_change',
        ];
    }

    if (selectedRankingSystem === 'ALL_RANKINGS') {
        filteredRankings = rankings.filter((player) => {
            return player.rank_date_rank !== 2;
        });
    } else {
        filteredRankings = filterRankings(rankings, allowed);
    }

    columns = columns.filter((column) => {
        return _.includes(allowed, column.key);
    });

    // console.log(filteredRankings);

    return (
        <React.Fragment>
            <div style={{ marginTop: 10, marginBottom: 10, marginLeft: 20, marginRight: 20 }}>
                <RankingSystemPicker selected={selectedRankingSystem} onSelected={handleSelectedRankingSystem} />
            </div>

            <div className="table_nowrap">
                <Table
                    rowKey="index"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                console.log(record, rowIndex);
                                props.history.push({
                                    pathname: `/players/${record.name_slug}`,
                                    // search: '?query=abc',
                                    state: { data: record },
                                });
                            }, // click row
                            onDoubleClick: (event) => {}, // double click row
                            onContextMenu: (event) => {}, // right button click row
                            onMouseEnter: (event) => {}, // mouse enter row
                            onMouseLeave: (event) => {}, // mouse leave row
                        };
                    }}
                    columns={columns}
                    onChange={handleChange}
                    dataSource={filteredRankings}
                    pagination={false}
                    tableLayout="auto"
                    scroll={{ x: 400 }}
                />
            </div>
        </React.Fragment>
    );
}
