import React, { useState, useRef } from 'react';
import { Table, Typography, Button, Icon, Modal, Input, Tooltip } from 'antd';
import { isoCountries } from '../../utils/isoCountryCodes';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const { Text } = Typography;
const { confirm } = Modal;

export default function TeamTable(props) {
    const { teams, league } = props;
    const { onSelected, onEditSelected, onDelete } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    function onSelectChange(selectedKeys) {
        // console.log('selectedRowKeys changed: ', selectedKeys);
        setSelectedKeys(selectedKeys);
        onSelected(selectedKeys);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const rowSelection = {
        selectedKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 60,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ fontWeight: 600 }}>{text}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Division',
            dataIndex: 'division',
            sorter: (a, b) => {
                const aDivisions = a.league_team_divisions
                    .map((item) => {
                        return item.league_division.name;
                    })
                    .join(', ');
                const bDivisions = b.league_team_divisions
                    .map((item) => {
                        return item.league_division.name;
                    })
                    .join(', ');
                return (
                    aDivisions &&
                    bDivisions &&
                    aDivisions.localeCompare(bDivisions, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.league_team_divisions.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Text>{item.league_division.name || '-'}</Text>
                                    <br />
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Players',
            dataIndex: 'players',
            sorter: (a, b) => {
                const aPlayers = a.league_team_players
                    .map((item) => {
                        return item.league_player.name;
                    })
                    .join(', ');
                const bPlayers = b.league_team_players
                    .map((item) => {
                        return item.league_player.name;
                    })
                    .join(', ');
                return (
                    aPlayers &&
                    bPlayers &&
                    aPlayers.localeCompare(bPlayers, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.league_team_players.map((item, index) => {
                            let name;

                            if (item.league_player.nickname) {
                                const nameSplit = item.league_player.name.split(' ');
                                const firstName = nameSplit[0];
                                const lastName = nameSplit[1];
                                name = `${firstName} "${item.league_player.nickname}" ${lastName}`;
                            } else {
                                name = item.league_player.name;
                            }

                            return (
                                <React.Fragment key={index}>
                                    <Text>{name || '-'}</Text>
                                    <br />
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Captain',
            dataIndex: 'team_captain',
            sorter: (a, b) => {
                return (
                    a.team_captain &&
                    b.team_captain &&
                    a.team_captain.name.localeCompare(b.team_captain.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => <Text>{(record.team_captain && record.team_captain.name) || '-'}</Text>,
        },

        {
            title: 'Co-Captain',
            dataIndex: 'team_co_captain',
            sorter: (a, b) => {
                return (
                    a.team_co_captain &&
                    b.team_co_captain &&
                    a.team_co_captain.name.localeCompare(b.team_co_captain.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => <Text>{(record.team_co_captain && record.team_co_captain.name) || '-'}</Text>,
        },
        {
            title: 'Venue',
            dataIndex: 'venue',
            sorter: (a, b) => {
                return (
                    a.venue &&
                    b.venue &&
                    a.venue.name.localeCompare(b.venue.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => <Text>{(record.venue && record.venue.name) || '-'}</Text>,
        },

        {
            title: 'Dues paid',
            dataIndex: 'league_dues_paid',
            sorter: (a, b) => a.league_dues_paid - b.league_dues_paid,
            render: (text, record) => <Text>{record.league_dues_paid === true ? 'Yes' : 'No' || '-'}</Text>,
        },
        // {
        // 	title: 'Payment date',
        // 	dataIndex: 'last_payment_date',
        // 	sorter: (a, b) => a.last_payment_date - b.last_payment_date,
        // 	render: (text, record) => <Text>{text ? moment(text).format('ll') : '-'}</Text>
        // },
        {
            title: 'Status',
            dataIndex: 'Status',
            sorter: (a, b) => {
                return a.status && a.status.localeCompare(b.status);
            },
            render: (text, record) => {
                return record.status || '-';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name} from this league?`,
            // content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            // rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        console.log(record, rowIndex);
                        props.history.push({
                            pathname: props.division
                                ? `/league-manager/${props.league.slug}/divisions/${props.division.slug}/teams/${record.slug}`
                                : `/league-manager/${props.league.slug}/teams/${record.slug}`,
                            // search: '?query=abc',
                            state: {
                                data: record,
                                backPath: `/league-manager/${props.league.slug}/teams`,
                            },
                        });
                    },
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns.filter((column) => {
                console.log(column);
                const divisionSlug = props.division && props.division.slug;
                if (league.has_division_support === false && column.dataIndex === 'division') {
                    return null;
                } else {
                    return column;
                }
            })}
            dataSource={teams}
            onChange={onChange}
            // pagination={false}
            tableLayout="auto"
            scroll={{ x: 400 }}
            pagination={{ pageSize: 30 }}
            size="middle"
            // bordered
        />
    );
}
