import React, { useState, useRef, useEffect } from 'react';
import { message, Icon, Row, Col, Typography, Layout, Progress, Button, Input, Tabs, Empty, Table, Modal } from 'antd';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import Highlighter from 'react-highlight-words';
import CircleFlag from '../../components/CircleFlag';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export default function ScheduledTables(props) {
    const { authState, tournament, matches, subscribeToMore, subscribeToNewData, theme, onModalVisible, onCurrentRound, onCurrentMatch } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const defaultSize = 'medium';
    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            // defaultSortOrder: 'desc',
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },

        {
            title: 'Table',
            dataIndex: 'table_name',
            align: 'left',
            key: 'table',
            width: 150,
            ...getColumnSearchProps('table_name'),
            sorter: (a, b) => {
                return (
                    a.table_name &&
                    b.table_name &&
                    a.table_name.localeCompare(b.table_name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {/* <img src={tableBlue} width={25} alt="Table 1" /> */}
                        {/* <span style={{ marginLeft: 10 }}>{text || '-'}</span> */}
                        <span>{text || '-'}</span>
                    </div>
                );
            },
        },

        {
            title: 'Match',
            dataIndex: 'match_number',
            key: 'match_number',
            width: 80,
            ...getColumnSearchProps('match_number'),
            // defaultSortOrder: 'desc',
            sorter: (a, b) => b.match_number - a.match_number,
        },

        {
            title: 'Order',
            dataIndex: 'priority',
            key: 'priority',
            width: 80,
            // defaultSortOrder: 'desc',
            sorter: (a, b) => b.priority - a.priority,
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },

        {
            title: 'Player 1',
            dataIndex: 'challenger1_name',
            key: 'player1',
            align: 'right',
            width: 300,
            ...getColumnSearchProps('names'),
            sorter: (a, b) => {
                return a.challenger1_name.localeCompare(b.challenger1_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                const country = record.challenger1_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div
                            style={{
                                marginRight: country ? 10 : 0,
                                fontWeight:
                                    record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {`${record.challenger1_name || ''} ${
                                record.challenger1_name && record.challenger1_name !== 'BYE' && record.challenger1_race_to
                                    ? `(${record.challenger1_race_to})`
                                    : ''
                            }` || '-'}
                        </div>

                        {country && <CircleFlag country={country} left={0} />}

                        <span
                            style={{
                                marginLeft: 10,
                                padding: '5px 10px',
                                backgroundColor: !record.challenger1_name
                                    ? 'rgba(0,0,0,0.4)'
                                    : record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                    ? '#1890fe'
                                    : theme && theme.name === 'dark'
                                    ? '#135da2'
                                    : '#61b3ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger1_is_forfeit === true ? 'FF' : record.challenger1_score || 0}
                        </span>
                    </div>
                );
            },
        },

        {
            title: 'Player 2',
            dataIndex: 'challenger2_name',
            key: 'player2',
            width: 300,
            ...getColumnSearchProps('names'),
            sorter: (a, b) => {
                return a.challenger2_name.localeCompare(b.challenger2_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                const country = record.challenger2_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <span
                            style={{
                                marginRight: 20,
                                padding: '5px 10px',
                                backgroundColor: !record.challenger2_name
                                    ? 'rgba(0,0,0,0.4)'
                                    : record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                    ? '#1890fe'
                                    : theme && theme.name === 'dark'
                                    ? '#135da2'
                                    : '#61b3ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_score || 0}
                        </span>

                        {country && <CircleFlag country={country} left={0} />}

                        <span
                            style={{
                                marginLeft: 0,
                                fontWeight:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {`${record.challenger2_name || ''} ${
                                record.challenger2_name && record.challenger2_name !== 'BYE' && record.challenger2_race_to
                                    ? `(${record.challenger2_race_to})`
                                    : ''
                            }` || '-'}
                        </span>
                    </div>
                );
            },
        },

        {
            title: 'Time',
            dataIndex: 'start_time',
            key: 'start_time',
            width: 200,
            // sorter: (a, b) => {
            //     if (a.priority && b.priority) {
            //         return a.priority - b.priority;
            //     }
            // },
            render: (text, record) => {
                const matches = record.matches;
                let followsMatch;

                if (matches && matches.length > 0) {
                    const follows = matches.filter((match) => {
                        if (
                            match.priority &&
                            match.priority === record.priority - 1 &&
                            record.scheduled_table_id === match.scheduled_table_id &&
                            match.status !== 'COMPLETED'
                        ) {
                            console.log(match.status);
                            return match;
                        }
                    })[0];

                    if (follows) {
                        followsMatch = follows.match_number;
                    }
                }

                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {!record.start_time && !record.scheduled_time && followsMatch
                            ? `Follows Match ${followsMatch}`
                            : record.status === 'COMPLETED' && record.end_time
                            ? `Ended - ${moment.utc(record.end_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.scheduled_time
                            ? `Scheduled - ${moment.utc(record.scheduled_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_time
                            ? `Started - ${moment.utc(record.start_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_date_time
                            ? `Started - ${moment.utc(record.start_date_time).local().format('lll z')} ${timezone}`
                            : '-'}
                    </div>
                );
            },
        },

        {
            title: 'Round',
            dataIndex: 'identifier',
            key: (a) => {
                return `identifier-${a.identifier}`;
            },
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {record.identifier}{' '}
                        {/* {record.loser_to ? (
							`(Loser to ${record.loser_to})`
						) : record.loser_from ? (
							`(Loser from ${record.loser_from})`
						) : record.winner_to ? (
							`(Winner to ${record.winner_to})`
						) : (
							''
						)} */}
                    </div>
                );
            },
            sorter: (a, b) => {
                return a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Progress
                            percent={record.progress || 0}
                            status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                        {/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							width: '100%'
						}}
					>
						<span
							style={{
								width: '100%',
								textAlign: 'left',
								backgroundColor: '#ececec',
								height: 10,
								position: 'relative'
							}}
						>
							<span
								style={{
									display: 'inline-block',
									// backgroundColor: '#bac2cc',
									backgroundColor: '#1890fe',
									width: `${(record.progress || 0) / 100 * 100}%`,
									height: '100%',
									position: 'absolute',
									transition: 'background-color .3s ease',
									cursor: 'pointer'
								}}
							/>
						</span>
						<span
							style={{
								width: 80,
								marginLeft: 10
							}}
						>
							{record.progress || 0}%
						</span>
					</div> */}
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => {
                return a.status.localeCompare(b.status, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },
    ];

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return tournament.winners_race_to;
            } else {
                return tournament.losers_race_to;
            }
        }
    }

    function handleModal() {
        onModalVisible(true);
    }

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    function getCurrentRound(match) {
        return matches.filter((item) => {
            return item.round === match.round;
        });
    }

    return (
        <div className="table_nowrap">
            <Table
                dataSource={matches}
                columns={columns}
                className="match-table table-striped-rows"
                tableLayout="auto"
                scroll={{ x: 400 }}
                size={defaultSize}
                pagination={false}
                // components={{
                // 	body: {
                // 		row: RenderRow
                // 	}
                // }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            const currentRound = getCurrentRound(record);
                            onCurrentRound(currentRound);
                            onCurrentMatch(record);
                            handleModal();
                        }, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                        className: getClassName(record),
                    };
                }}
                onHeaderRow={(column) => {
                    return {
                        onClick: () => {}, // click header row
                    };
                }}
            />
        </div>
    );
}
