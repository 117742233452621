import React from 'react';
import Flag from 'react-flags';
import { CircleFlag } from 'react-circle-flags';
import { getCountryShortCode } from '../utils';
import twIoc from '../assets/img/flags/tw-ioc.svg';
import scotland from '../assets/img/flags/scotland.svg';

export default function CircleFlagComponent(props) {
    const { country } = props;
    let size = props.size || 32;
    let flagSize = size;
    let containerSize = 22;

    if (size === 'large') {
        flagSize = 64;
        containerSize = 40;
    } else if (size === 'medium') {
        flagSize = 48;
        containerSize = 32;
    } else if (size === 'small') {
        flagSize = 32;
        containerSize = 22;
    }
    // else {
    //     containerSize = size;
    // }
    const left = props.left === 0 ? 0 : 5;

    return (
        <span
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                width: containerSize,
                minWidth: containerSize,
                height: containerSize,
                overflow: 'hidden',
                borderRadius: '50%',
                marginRight: 10,
                border: '1px solid rgba(0,0,0,0.2)',
                ...props.style,
            }}
        >
            <span style={{ position: 'relative' }}>
                {country &&
                (country.toLowerCase() === 'tw' ||
                    country.toLowerCase() === 'taiwan' ||
                    country.toLowerCase() === 'tpe' ||
                    country.toLowerCase() === 'chinese taipei') ? (
                    <img src={twIoc} height={containerSize} alt="Flag" />
                ) : country && (country.toLowerCase() === 'scotland' || country.toLowerCase() === 'scb') ? (
                    <img src={scotland} height={48} alt="Flag" />
                ) : country && (country.toLowerCase() === 'great britain' || country.toLowerCase() === 'gb') ? (
                    <CircleFlag countryCode="gb" width={containerSize} height={containerSize} />
                ) : (
                    <CircleFlag countryCode={getCountryShortCode(country) || 'Unknown'} width={containerSize} height={containerSize} />
                )}

                {/* <Flag
                    name={getCountryName(country) || 'Unknown'}
                    format="png"
                    pngSize={flagSize}
                    shiny={false}
                    alt="Flag"
                    className="flag"
                    basePath="/images/flags"
                /> */}
            </span>
        </span>
    );
}
