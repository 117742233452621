export function calculateUSAPLRace(dampFactor, ratingOne, ratingTwo) {
    var p1Rating = parseInt(ratingOne);
    var p2Rating = parseInt(ratingTwo);
    var gp = 0.018 * ((p1Rating + p2Rating) / 2) - 1.4;
    var delta = p1Rating - p2Rating;

    var p1WinPercent = 1 - 1 / (1 + Math.pow(2, delta / 100));
    var p2WinPercent = 1 - p1WinPercent;

    var p1AvgPts = p1WinPercent * 14.0 + p2WinPercent * (7.4 - 0.0062 * p2Rating);
    var p2AvgPts = p2WinPercent * 14.0 + p1WinPercent * (7.4 - 0.0062 * p1Rating);

    var p1RaceTo = p1AvgPts * gp * dampFactor;
    var p2RaceTo = p2AvgPts * gp * dampFactor;

    var normalizedRace = normalizeRace(p1RaceTo, p2RaceTo, dampFactor === 0.75);

    p1RaceTo = rhte(normalizedRace.p1RaceTo, 1) || null;
    p2RaceTo = rhte(normalizedRace.p2RaceTo, 1) || null;

    return {
        p1RaceTo,
        p2RaceTo,
    };
}

export function rhte(race, placeToRound) {
    var fixed = placeToRound.toString().split('.').length < 2 ? 0 : placeToRound.toString().split('.')[1].length,
        numParts = {
            mvDec: (race / placeToRound).toFixed(race.toString().length).toString().split('.'),
            wholeNum: function () {
                return parseInt(this.mvDec[0], 10);
            },
            dec: function () {
                return this.mvDec.length > 1 ? parseFloat('0.' + this.mvDec[1]) : 0;
            },
            oddEven: function () {
                return this.wholeNum() % 2 === 1 ? 1 : 0;
            },
        };

    if (numParts.dec() !== 0.5) {
        return numParts.dec() > 0.5
            ? parseFloat(((numParts.wholeNum() + 1) * placeToRound).toFixed(fixed))
            : parseFloat((numParts.wholeNum() * placeToRound).toFixed(fixed));
    } else {
        if (numParts.oddEven() === 1) {
            return parseFloat(((numParts.wholeNum() + 1) * placeToRound).toFixed(fixed));
        } else {
            return parseFloat((numParts.wholeNum() * placeToRound).toFixed(fixed));
        }
    }
}

export function normalizeRace(p1RaceTo, p2RaceTo, isShort) {
    var highClamp = 99.0,
        lowClamp = isShort ? 24.0 : 28.0;

    var p1RaceIsHigh = p1RaceTo >= p2RaceTo,
        highRace = Math.max(p1RaceTo, p2RaceTo),
        lowRace = Math.min(p1RaceTo, p2RaceTo);

    var highRaceClamped = highRace > highClamp ? highClamp : highRace;
    var lowRaceClamped = highRace > highClamp ? lowRace - lowRace * ((highRace - highClamp) / highRace) : lowRace;

    highRaceClamped = Math.min(lowRace < lowClamp ? highRaceClamped + highRaceClamped * ((lowClamp - lowRace) / lowRaceClamped) : highRaceClamped, highClamp);

    lowRaceClamped = Math.max(lowRaceClamped < lowClamp ? lowClamp : lowRaceClamped, lowClamp);

    return {
        p1RaceTo: p1RaceIsHigh ? highRaceClamped : lowRaceClamped,
        p2RaceTo: p1RaceIsHigh ? lowRaceClamped : highRaceClamped,
    };
}
