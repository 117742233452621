import React, { useState } from 'react';
import { message, Icon, Alert, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import ZeroState from '../../../components/ZeroState';
import AddTeamModal from '../AddTeamModal';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_LEAGUE_TEAMS_QUERY, GET_LEAGUE_QUERY, GET_LEAGUE_DIVISION_TEAMS_QUERY } from '../data/queries';
import { Query, Mutation } from '@apollo/client/react/components';
import TeamTable from '../TeamTable';
import { DELETE_LEAGUE_TEAM_MUTATION } from '../data/mutations';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

export default function LMDivisionTeams(props) {
    const { league, division, authState } = props;
    const [selectedTeamId, setSelectedTeamId] = useState();
    const [teamModalVisible, setTeamModalVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selected, setSelected] = useState();
    const [deleteLeagueTeam, { loading, error }] = useMutation(DELETE_LEAGUE_TEAM_MUTATION);

    function handleAddTeam() {
        setTeamModalVisible(true);
        setSelectedTeamId(null);
    }

    function handleTeamModalOk(e) {
        setTeamModalVisible(false);
    }

    function handleTeamModalCancel(e) {
        setTeamModalVisible(false);
    }

    function handleSelected(selected) {
        // console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedTeamId(selected);
        setTeamModalVisible(true);
    }

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Alert
                            message="Helpful tips for adding teams"
                            description="You can add teams and assign them to one or more divisions. Players can also be assigned to multiple teams and divisions."
                            type="info"
                            closable
                            showIcon
                        />
                    </Col>
                    <Col xs={24}>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Division Teams ({division.league_team_divisions_aggregate.aggregate.count})
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button type="primary" onClick={handleAddTeam}>
                                            <Icon type="user-add" /> Add Team
                                        </Button>
                                    </div>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_DIVISION_TEAMS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ league_id: league.id, division_id: division.id }}
                                onCompleted={(data) => {
                                    // setPlayers(data.league_players);
                                    console.log(data);
                                    console.log(league);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading)
                                        return (
                                            <div style={styles.container}>
                                                <CircularLoader />
                                            </div>
                                        );
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_teams) || (data && data.league_teams.length === 0)) {
                                        return <ZeroState showImage message="No Teams have been added yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    return (
                                        <div className="table_nowrap">
                                            <TeamTable
                                                {...props}
                                                league={league}
                                                onDelete={(record) => {
                                                    deleteLeagueTeam({
                                                        variables: {
                                                            league_id: league.id,
                                                            id: record.id,
                                                        },
                                                        notifyOnNetworkStatusChange: true,
                                                        awaitRefetchQueries: true,
                                                        refetchQueries: [
                                                            {
                                                                query: GET_LEAGUE_QUERY,
                                                                variables: { id: league.id },
                                                            },
                                                            {
                                                                query: GET_LEAGUE_TEAMS_QUERY,
                                                                variables: { league_id: league.id },
                                                            },
                                                            {
                                                                query: GET_LEAGUE_DIVISION_TEAMS_QUERY,
                                                                variables: {
                                                                    league_id: league.id,
                                                                    division_id: division.id,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                        .then((data) => {
                                                            console.log(data);
                                                            message.success('Team deleted from league');
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            message.info('There was an error', error);
                                                        });
                                                }}
                                                onSelected={handleSelected}
                                                onEditSelected={handleEditSelected}
                                                teams={data.league_teams.map((item, index) => {
                                                    return {
                                                        ...item,
                                                        players: item.league_team_players
                                                            .map((item) => {
                                                                return item.league_player;
                                                            })
                                                            .join(', '),
                                                        index: index + 1,
                                                        key: index,
                                                    };
                                                })}
                                            />
                                        </div>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
                <AddTeamModal
                    selectedTeamId={selectedTeamId}
                    userID={authState.user.id}
                    leagueID={league.id}
                    divisionID={division.id}
                    modalVisible={teamModalVisible}
                    onModalOk={handleTeamModalOk}
                    onModalCancel={handleTeamModalCancel}
                    {...props}
                />
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
