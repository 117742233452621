import React, { useState, useEffect } from 'react';
import { Icon, Row, Col, Empty, Modal, Button, Typography } from 'antd';
import Card from '../../components/Card';
import TableAssignment from './TableAssignment';
import TableScoreCard from '../digitalpool/tournaments/TableScoreCard';
import Ripples from 'react-ripples';

const { Text } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
};

export default function TablesAvailable(props) {
    const { tournament, data, onShowTableModal, showScoreModal, setTableModalVisible, onTableSelected, onTableEdit, theme } = props;
    const [editModalVisible, setEditModalVisible] = useState(false);

    function handleEditModalOk(e) {
        console.log(e);
        setEditModalVisible(false);
    }

    function handleEditModalCancel(e) {
        setEditModalVisible(false);
    }

    return (
        <Row gutter={24} justify="center" type="flex">
            <Col xs={24}>
                <Row gutter={16} justify="start" type="flex">
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                        <Ripples color="#ccc" className="ripple-effect">
                            <Card
                                hoverable
                                onClick={() => {
                                    onShowTableModal();
                                    // setTableModalVisible(true);
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    minHeight: 220,
                                    borderRadius: '8px',
                                    // backgroundColor: '#fff',
                                    // border: theme && theme.name === 'light' ? '3px dashed #ccc' : '3px dashed rgba(255,255,255,0.2)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                                className="dashed-card"
                            >
                                <Icon type="plus" style={{ fontSize: 50, color: '#8a8a8a', fontWeight: 'bold' }} />
                                {/* <div>Add Table</div> */}
                            </Card>
                        </Ripples>
                    </Col>
                    {data.map((table, index) => {
                        const match = table.tournament_match_table && table.tournament_match_table[0];

                        return (
                            <Col key={index} xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                                {match ? (
                                    <TableScoreCard
                                        {...props}
                                        title={match && match.match_number && `Match ${match.match_number} (${match.identifier})`}
                                        table={table}
                                        label={table.label}
                                        matchData={match}
                                        tournament={tournament}
                                        status={table.status}
                                        gameType={tournament.game_type}
                                        tableSize="large"
                                        onEdit={() => {
                                            onTableEdit(table.id);
                                        }}
                                        onClick={() => {
                                            // showScoreModal({
                                            // 	title: `Match ${index + 1}`,
                                            // 	slug: `match-${index + 1}`,
                                            // 	status: 'live'
                                            // })}
                                            if (onTableSelected) {
                                                onTableSelected(table.slug);
                                            }

                                            // setEditModalVisible(true);
                                        }}
                                        playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                                    />
                                ) : (
                                    <TableAssignment
                                        {...props}
                                        title={match && match.match_number && `Match ${match.match_number} (${match.identifier})`}
                                        table={table}
                                        label={table.label}
                                        matchData={match}
                                        tournament={tournament}
                                        status={table.status}
                                        gameType={tournament.game_type}
                                        displayText={table.status}
                                        tableSize="large"
                                        onEdit={() => {
                                            onTableEdit(table.id);
                                            // setEditModalVisible(true);
                                        }}
                                        onClick={() => {
                                            // showScoreModal({
                                            // 	title: `Match ${index + 1}`,
                                            // 	slug: `match-${index + 1}`,
                                            // 	status: 'live'
                                            // })}
                                            if (onTableSelected) {
                                                onTableSelected(table.slug);
                                            }

                                            // setEditModalVisible(true);
                                        }}
                                        playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                                        // tableSize="small"
                                    />
                                )}
                            </Col>
                        );
                    })}
                </Row>

                <Modal
                    title="Configure Table"
                    // getContainer={() => document.querySelector('.tables')}
                    visible={editModalVisible}
                    onOk={handleEditModalOk}
                    onCancel={handleEditModalCancel}
                    centered
                    transitionName="fade"
                    // transitionName="none"
                    maskTransitionName="none"
                    footer={[
                        <Button key="cancel" onClick={handleEditModalCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleEditModalOk}>
                            Submit
                        </Button>,
                    ]}
                >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Modal>
            </Col>
        </Row>
    );
}
