import { gql } from '@apollo/client';

export const GET_ALL_TOURNAMENT_TABLES_TV_DISPLAY_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, status: { _neq: CLOSED } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
                city
                region
            }

            tournament_match_table(
                where: { _or: [{ status: { _eq: IN_PROGRESS } }, { status: { _eq: PENDING } }, { status: { _eq: COMPLETED } }, { status: { _eq: SCHEDULED } }] }
                order_by: { updated_at: desc }
                limit: 1
            ) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int!) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }

            tournament_match_table(
                where: {
                    _or: [{ status: { _eq: NOT_STARTED } }, { status: { _eq: IN_PROGRESS } }, { status: { _eq: PENDING } }, { status: { _eq: SCHEDULED } }]
                }
            ) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                tournament_match_sets(order_by: { number: asc }) {
                    id
                    name
                    slug
                    number
                    match_id
                    challenger1_id
                    challenger1_score
                    challenger1_race_to
                    challenger1_points
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    start_time
                    end_time
                    status
                }

                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLE_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int!, $table_slug: String!) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, slug: { _eq: $table_slug } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
                city
                region
            }
            tournament_match_table(where: { tournament_id: { _eq: $tournament_id }, status: { _neq: COMPLETED } }, order_by: { status: desc }, limit: 1) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }

                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int, $status: pool_table_status_enum) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, _and: { status: { _eq: $status } } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
                city
                region
            }
            tournament_match_table(order_by: { status: desc }) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    place
                    race_to
                    seed
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_points
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_points
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_SCHEDULED_TABLES_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            scheduled_matches(where: { status: { _neq: COMPLETED } }) {
                id
                hash_id
                round
                identifier
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                priority
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
                scoreboard_balls
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { match_number: asc }) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                race_to
                place
                seed
                user_id
                fargo_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                race_to
                place
                seed
                user_id
                fargo_id
                user {
                    id
                    region
                }
            }
            tournament_match_sets(order_by: { number: asc }) {
                id
                name
                slug
                number
                match_id
                challenger1_id
                challenger1_score
                challenger1_race_to
                challenger1_points
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                start_time
                end_time
                status
            }
            challenger1_sets_won: tournament_match_sets_aggregate(where: { challenger1_is_winner: { _eq: true } }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            challenger2_sets_won: tournament_match_sets_aggregate(where: { challenger2_is_winner: { _eq: true } }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
            }
            current_set
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            match_number
            match_id
            match_color
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            match_custom_text
            match_custom_link
            scoreboard_balls
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_LIVE_SCORES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, status: { _neq: COMPLETED } }, order_by: { match_number: asc }) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }

            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            is_scoring_started
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_IN_PROGRESS_MATCHES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, _or: [{ status: { _eq: IN_PROGRESS } }, { status: { _eq: PENDING } }] }

            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }

            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_UPCOMING_MATCHES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, is_bye: { _eq: false }, _or: [{ status: { _eq: NOT_STARTED } }, { status: { _eq: SCHEDULED } }] }

            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                country_phone
                phone_number
                email
                skill_level
                place
                race_to
                seed
                points
                status
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_BY_STATUS_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!, $status: tournament_status_enum) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, is_bye: { _eq: false }, status: { _eq: $status } }
            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            is_scoring_started
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_W_PLAYERS_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournament_id: Int!, $status: tournament_status_enum) {
        tournament_brackets(
            where: {
                tournament_id: { _eq: $tournament_id }
                status: { _eq: $status }
                challenger2_name: { _is_null: false }
                challenger1_name: { _is_null: false }
            }
            order_by: { match_number: asc }
        ) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            is_scoring_started
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { updated_at: desc }, limit: 3) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            tournament_match_sets(order_by: { number: asc }) {
                id
                name
                slug
                number
                match_id
                challenger1_id
                challenger1_score
                challenger1_race_to
                challenger1_points
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                start_time
                end_time
                status
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            match_number
            match_id
            match_color
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            is_scoring_started
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            match_custom_text
            match_custom_link
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_PLAYERS_SUBSCRIPTION = gql`
    subscription tournament_players($tournament_id: Int) {
        tournament_brackets(
            distinct_on: [challenger1_name, challenger2_name]
            where: { tournament_id: { _eq: $tournament_id }, _and: { challenger1_name: { _is_null: false }, _or: { challenger2_name: { _is_null: false } } } }
        ) {
            id
            pool_table {
                id
                slug
                label
            }
            challenger1_seed
            challenger1_race_to
            challenger1_country
            challenger1 {
                id
                name
                country
                city
                region
                email
                country_phone
                phone_number
                skill_level
                fargo_id
                status
                points
                seed
                race_to
                place
                user {
                    id
                    first_name
                    last_name
                    country
                    city
                    region
                    email
                    country_phone
                    phone_number
                    skill_level
                    fargo_id
                }
            }
            challenger2_seed
            challenger2_race_to
            challenger2_country
            challenger2 {
                id
                name
                country
                city
                region
                email
                country_phone
                phone_number
                skill_level
                fargo_id
                status
                points
                seed
                race_to
                place
                user {
                    id
                    first_name
                    last_name
                    country
                    city
                    region
                    email
                    country_phone
                    phone_number
                    skill_level
                    fargo_id
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_SUBSCRIPTION = gql`
    subscription tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            city
            region
            skill_level
            fargo_id
            status
            points
            seed
            place
            race_to
            country_phone
            phone_number
            chips_left
            player_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                city
                region
                skill_level
                country_phone
                phone_number
                fargo_id
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STANDINGS_SUBSCRIPTION = gql`
    subscription tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, distinct_on: id) {
            id
            name
            nickname
            place
            country
            city
            region
            skill_level
            fargo_id
            payouts {
                money
            }
            player_points {
                points
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: tournament_matches_challenger1_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: tournament_matches_challenger2_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, order_by: { place: asc }) {
            id
            name
            nickname
            place
            country
            city
            region
            skill_level
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2 = gql`
    subscription vw_tournament_statistics($tournament_id: Int!) {
        vw_tournament_statistics(where: { tournament_id: { _eq: $tournament_id } }) {
            game_record
            game_win_percentage
            match_record
            match_win_percentage
            place
            player_id
            player_name
            player_country
            points
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_STATS_SUBSCRIPTION2 = gql`
    subscription fn_get_tournament_statistics($tournament_id: Int!) {
        fn_get_tournament_statistics(where: { tournament_id: { _eq: $tournament_id } }, args: { _tournament_id: $tournament_id }) {
            tournament_id
            skill_level_range
            number_of_players
            percent_of_field
            number_made_final_32
            number_made_final_16
            number_made_final_8
            percentage_of_final_32
            percentage_of_final_16
            percentage_of_final_8
            win_percentage
            average_match_duration
            win_percentage_vs_lower_skill_level
            win_percentage_vs_higher_skill_level
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION_OLD = gql`
    subscription tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            place
            country
            city
            region
            fargo_id
            player_points {
                points
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: tournament_matches_challenger1_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: tournament_matches_challenger2_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION = gql`
    subscription tournament_players($tournament_id: Int!) {
        tournament_players(where: { place: { _lte: "3" }, tournament_id: { _eq: $tournament_id } }, distinct_on: id) {
            id
            name
            place
            payouts {
                money
            }
        }
    }
`;

export const GET_TOURNAMENT_STREAM_OVERLAY_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
                city
                region
            }
            tournament_match_table(order_by: { updated_at: desc }) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    race_to
                    place
                    seed
                    user_id
                    user {
                        id
                        region
                    }
                }
                challenger2 {
                    id
                    name
                    country
                    city
                    region
                    skill_level
                    fargo_id
                    race_to
                    place
                    seed
                    user_id
                    user {
                        id
                        region
                    }
                }
                tournament_match_sets(order_by: { number: asc }) {
                    id
                    name
                    slug
                    number
                    match_id
                    challenger1_id
                    challenger1_score
                    challenger1_race_to
                    challenger1_points
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    start_time
                    end_time
                    status
                }
                challenger1_sets_won: tournament_match_sets_aggregate(where: { challenger1_is_winner: { _eq: true } }) {
                    aggregate {
                        count
                        sum {
                            challenger1_score
                            challenger2_score
                        }
                    }
                }
                challenger2_sets_won: tournament_match_sets_aggregate(where: { challenger2_is_winner: { _eq: true } }) {
                    aggregate {
                        count
                        sum {
                            challenger1_score
                            challenger2_score
                        }
                    }
                }
                current_set
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID_SUBSCRIPTION = gql`
    subscription tournament_brackets($hash_id: String!, $tournament_id: Int!) {
        tournament_brackets(where: { hash_id: { _eq: $hash_id }, tournament_id: { _eq: $tournament_id } }, order_by: { id: asc }) {
            id
            hash_id
            round
            identifier
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            game_type
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_to
            winner_to_num
            winner_from
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            is_scoring_started
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            tournament {
                name
                slug
                game_type
                winners_race_to
                losers_race_to
                finals_race_to
                autopilot_mode
            }
            pool_table {
                id
                slug
                label
                is_streaming_table
                is_featured_table
                livestream_url
                status
            }
        }
    }
`;

export const GET_TOURNAMENT_MATCH_GAMES_SUBSCRIPTION = gql`
    subscription tournament_match_games($tournament_id: Int!, $tournament_bracket_id: Int!) {
        tournament_match_games(
            where: { tournament_id: { _eq: $tournament_id }, tournament_bracket_id: { _eq: $tournament_bracket_id } }
            order_by: { game_number: asc }
        ) {
            id
            tournament_id
            tournament_bracket_id
            match_number
            game_number
            inning
            player_id
            player_score
            player_points
            player_is_winner
            player_break_run
            player_high_run
            player_end_run
            player_runout
            player_tpa
            player_unforced_errors
            player_balls_pocketed
            opponent_id
            opponent_score
            opponent_points
            opponent_is_winner
            opponent_break_run
            opponent_high_run
            opponent_end_run
            opponent_runout
            opponent_tpa
            opponent_unforced_errors
            opponent_balls_pocketed
            status
            progress
            duration
            table_id
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_PENDING_MATCHES_SUBSCRIPTION = gql`
    subscription tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId }, status: { _eq: PENDING } }, order_by: { id: asc }) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                city
                region
                skill_level
                fargo_id
                race_to
                place
                seed
                user_id
                user {
                    id
                    region
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_skill_level
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_skill_level
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            match_number
            match_id
            match_color
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            scoreboard_balls
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

// export const GET_PENDING_MATCHES_SUBSCRIPTION = gql`
//     subscription tournament_brackets($tournamentId: Int!) {
//         tournament_brackets(where: { tournament_id: { _eq: $tournamentId }, status: { _eq: PENDING } }, order_by: { id: asc }) {
//             id
//             hash_id
//             round
//             identifier
//             place
//             challenger1 {
//                 id
//                 name
//                 country
//                 skill_level
//                 race_to
//                 place
//                 seed
//                 user_id
//             }
//             challenger2 {
//                 id
//                 name
//                 country
//                 skill_level
//                 race_to
//                 place
//                 seed
//                 user_id
//             }
//             challenger1_id
//             challenger1_name
//             challenger1_country
//             challenger1_score
//             challenger1_points
//             challenger1_race_to
//             challenger1_skill_level
//             challenger1_seed
//             challenger1_is_winner
//             challenger1_is_forfeit
//             challenger1_is_withdraw
//             challenger2_id
//             challenger2_name
//             challenger2_country
//             challenger2_score
//             challenger2_points
//             challenger2_race_to
//             challenger2_skill_level
//             challenger2_seed
//             challenger2_is_winner
//             challenger2_is_forfeit
//             challenger2_is_withdraw
//             challenger1_sms_sent
//             challenger2_sms_sent
//             match_number
//             match_id
//             match_color
//             table_id
//             table_name
//             scheduled_time
//             start_time
//             end_time
//             duration
//             status
//             progress
//             loser_from
//             loser_from_num
//             loser_to
//             loser_to_num
//             winner_from
//             winner_to
//             winner_to_num
//             winner_from_top_num
//             winner_from_bottom_num
//             loser_from_top_num
//             loser_from_bottom_num
//             is_bye
//             is_split
//             is_semi_finals
//             is_finals
//             is_consolidation_finals
//             tournament {
//                 start_date_time
//             }
//             pool_table {
//                 id
//                 slug
//                 label
//                 status
//                 is_streaming_table
//                 is_featured_table
//                 livestream_url
//             }
//             tournament_bracket_projections {
//                 id
//                 round
//                 round_combo
//                 start_time
//                 end_time
//                 match_duration
//             }
//         }
//     }
// `;

export const GET_SHOTCAST_EVENTS_SUBSCRIPTION = gql`
    subscription shotcast_events($match_id: Int!) {
        shotcast_events(where: { match_id: { _eq: $match_id } }, limit: 10, order_by: { event_timestamp: desc }) {
            id
            tournament_bracket_id
            match_id
            event_timestamp
            player_name
            description
            shot_data
            created_at
            updated_at
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_SUBSCRIPTION = gql`
    subscription pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { updated_at: desc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
        }
    }
`;
