import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Tooltip, Popconfirm, Typography, Upload, Divider, Icon, Avatar } from 'antd';
import { Form, FormItem, Input, Select, InputNumber, SubmitButton, ResetButton } from 'formik-antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY } from './data/queries';
import { UPDATE_TOURNAMENT_MATCH_MUTATION, UPDATE_TABLE_STATUS_MUTATION } from './data/mutations';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

const styles = {};

export default function AssignTableForm(props) {
    const { tournament, tables, matches, playerId } = props;
    const [loading, setLoading] = useState(false);
    const [selectedTable, setSelectedTable] = useState();
    const [confirmTablePopupVisible, setConfirmTablePopupVisible] = useState(false);
    const [updateTableStatus] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const [updateMatch] = useMutation(UPDATE_TOURNAMENT_MATCH_MUTATION);
    const formRef = useRef();

    function handleSelectTableChange(id) {
        const table = tables.filter((item) => {
            return item.id === id;
        })[0];
        setSelectedTable(table);
    }

    function cancelRemoveTableAssignment(e) {}

    async function handleRemoveTableAssignment(table) {
        const match = table && table.tournament_match_table && table.tournament_match_table[0];

        const key = 'removing';
        message.loading({ content: `Removing table assignment...`, key, duration: 0 });
        updateTableStatus({
            variables: {
                id: table.id,
                changes: {
                    status: 'OPEN',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
                    variables: { tournament_id: tournament.id },
                    // awaitRefetchQueries: true,
                    // onCompleted: (data) => {
                    // 	console.log(data);
                    // },
                    // notifyOnNetworkStatusChange: true,
                    // fetchPolicy: 'cache-and-network'
                },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id },
                // 	awaitRefetchQueries: true
                // }
            ],
        });

        updateMatch({
            variables: {
                id: match.id,
                tournament_id: tournament.id,
                changes: {
                    table_name: null,
                    table_id: null,
                    status: 'NOT_STARTED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // }
                // {
                //     query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //     variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                //     awaitRefetchQueries: true,
                //     notifyOnNetworkStatusChange: true,
                // },
            ],
        })
            .then((data) => {
                message.success({
                    content: `Removed table assignment for Match ${match.match_number}.`,
                    key,
                    duration: 2,
                });
            })
            .catch((error) => {
                console.log(error);
                message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
            });
    }

    function handleRemoveTableVisibleChange(visible) {
        setConfirmTablePopupVisible(visible);
    }

    const currentTableAssignment =
        tables &&
        tables.filter((table) => {
            const match = table && table.tournament_match_table && table.tournament_match_table[0];
            if (match) {
                if (match.challenger1_id === playerId || match.challenger2_id === playerId) {
                    return table;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        })[0];

    console.log(currentTableAssignment);

    return (
        <Formik
            ref={formRef}
            onSubmit={(data, actions) => {
                // submitUpdate(data, match);
            }}
            enableReinitialize
            initialValues={{
                table_id: currentTableAssignment && currentTableAssignment.id,
            }}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form
                    onSubmit={handleSubmit}
                    layout="vertical"
                    style={{ maxWidth: 600 }}
                    // onSubmit={(e) => {
                    // 	e.preventDefault();
                    // 	console.log(values);
                    // 	// handleSubmit(values);
                    // }}
                >
                    {/* <Text style={{ fontWeight: 700 }}>
                        Assign open table{' '}
                        <Tooltip
                            placement="right"
                            title={<div>Assign any open table for this match immediately. This will override any scheduled table assignments.</div>}
                        >
                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </Text> */}
                    <FormItem name="table_id">
                        <Query
                            query={GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY}
                            fetchPolicy="no-cache"
                            // notifyOnNetworkStatusChange={true}
                            variables={{ tournament_id: tournament.id }}
                            onCompleted={(data) => {
                                // console.log(data);
                                // setTables(data.pool_tables);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <div>Loading...</div>;
                                if (error) return <div>Error: {error.message}</div>;
                                if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                    return <div>No tables found</div>;
                                }

                                return (
                                    <Select
                                        name="table_id"
                                        defaultValue={currentTableAssignment && currentTableAssignment.id}
                                        // style={{ minWidth: 300 }}
                                        onChange={handleSelectTableChange}
                                        placeholder="Select table assignment"
                                        size="large"
                                        allowClear={currentTableAssignment && currentTableAssignment.id ? false : true}
                                    >
                                        <OptGroup label="TABLES">
                                            {data.pool_tables
                                                .filter((item, index) => {
                                                    return item.status !== 'CLOSED';
                                                })
                                                .sort((a, b) => {
                                                    return a.label.localeCompare(b.label, undefined, {
                                                        numeric: true,
                                                        sensitivity: 'base',
                                                    });
                                                })
                                                .sort((a, b) => {
                                                    return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                })
                                                .map((item, index) => (
                                                    <Option key={index + 1} value={item.id} disabled={item.status === 'IN_USE' || item.status === 'ASSIGNED'}>
                                                        {item.label} - {item.status} {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                        {item.is_featured_table ? '(Featured Table)' : ''}
                                                    </Option>
                                                ))}
                                        </OptGroup>
                                    </Select>
                                );
                            }}
                        </Query>
                        {currentTableAssignment && currentTableAssignment.id !== null && (
                            <Popconfirm
                                title={`Are you sure you want to remove this player from ${currentTableAssignment.label}?`}
                                onConfirm={() => {
                                    handleRemoveTableAssignment(currentTableAssignment);
                                }}
                                onCancel={cancelRemoveTableAssignment}
                                visible={confirmTablePopupVisible}
                                onVisibleChange={handleRemoveTableVisibleChange}
                                okText="Yes"
                                cancelText="No"
                                placement="topLeft"
                            >
                                <Button type="link" className="remove-table-assignment-btn">
                                    <Icon type="close-circle" /> Remove table assignment
                                </Button>
                            </Popconfirm>
                        )}
                    </FormItem>
                    {values && values.table_id && !currentTableAssignment && (
                        <React.Fragment>
                            <SubmitButton
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                loading={loading}
                            >
                                Save
                            </SubmitButton>
                            <ResetButton>Clear</ResetButton>
                            <div>
                                <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                            </div>
                        </React.Fragment>
                    )}
                </Form>
            )}
        />
    );
}
