import React from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Icon, Row, Col, Alert, Typography, Tag } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import ResultsTable from './components/GEResults/Table';
import { ghostEventId } from './data/atoms';
import ResultsPodium from './components/GEResults/Podium';

const { Title } = Typography;

export default function GEResults(props) {
    const { client, ghostEvent } = props;

    console.log("props", props)

    return (
            <React.Fragment>
                <SectionWrapper>
                    <SectionHeader
                        title={props.title}
                        titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                        subtitle="Ghost Game Name"
                        subtitlePrefix={true}
                        subtitleStyle={{
                            color: 'rgba(255,255,255,0.8)',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: 12,
                            letterSpacing: 3
                        }}
                        theme="tournamentBuilder"
                    >
                    </SectionHeader>
                    <SectionContent padding="20px 40px">
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <ResultsPodium style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }} client={client} ghostEventId={ghostEvent.id} />
                                <Card
                                    bodyStyle={{ padding: 0 }}
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                Results
                                            </Title>
                                            <Tag color="red">LIVE</Tag>
                                        </div>
                                    }
                                >
                                <div>
                                    <ResultsTable client={client} ghostEvent={ghostEvent} />
                                </div>
                                </Card>
                            </Col>
                        </Row>
                    </SectionContent>
                </SectionWrapper>
            </React.Fragment>
    );
}