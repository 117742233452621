import React from 'react';
import { useRecoilValue } from 'recoil';
import { completedStepAtom, createStep } from '../../data/atoms';
import AddPlayerForm from './AddPlayerForm';
import SettingsForm from './SettingsForm';
import ReviewPage from './ReviewPage';
import GoBackStep from './GoBackStep';
import { settingsFormSelector } from '../../data/selectors';

export default function FormController(props) {
    const { step, publicRadio } = useRecoilValue(settingsFormSelector);
    const completedStepAtomValue = useRecoilValue(completedStepAtom);
    const createStepValue = useRecoilValue(createStep);

    let jsx = null;

    if(createStepValue >= 1 && completedStepAtomValue === -1) {
        return <GoBackStep />
    }

    switch(step) {
        case 0: 
            jsx = <SettingsForm {...props} />
            break;

        case 1:
            jsx = <AddPlayerForm {...props} />
            break;

        case 2:
            jsx = <ReviewPage {...props } />
            break;
    }

    return jsx
}