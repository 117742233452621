import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { PageHeader, Tag, Row, Col, Avatar, Result, Icon, Tabs, Button, Empty, Typography } from 'antd';
import { Form, FormItem, Input, SubmitButton, Radio, Checkbox } from 'formik-antd';
import { Formik, insert, setIn } from 'formik';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Card from '../../../components/Card';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import { getGameType, displayEnumType } from '../../../utils';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';
import { Query } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { TextArea } = Input;

const styles = {};

function DPTournamentRegistration(props) {
    const { authState, client, match, theme } = props;
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    const SignupSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
    });

    const handleSubmit = async (values, actions) => {};

    return (
        <Query
            query={GET_TOURNAMENT_BY_SLUG}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: props.match.params.slug }}
            onCompleted={(data) => {
                // var set = [];
                // for (let i = 0; i < data.tournament_payouts.length; i++) {
                // 	set.push(data.tournament_payouts[i]);
                // }
                // let formattedData = orderAndFormatData(set);
                // setTableData(formattedData);
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                const tournamentData = data.tournaments && data.tournaments[0];

                console.log(tournamentData);

                let duration;
                if (tournamentData.start_date_time && tournamentData.end_date_time && tournamentData.status === 'COMPLETED') {
                    var start = moment(tournamentData.start_date_time);
                    var end = moment(tournamentData.end_date_time);

                    duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');

                    // moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
                } else {
                    duration = moment.duration(moment.utc().diff(tournamentData.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
                }

                if (!tournamentData) {
                    return (
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <Card style={{ margin: 10 }}>
                                    <ZeroState showImage message="Tournament not found" style={{ margin: 30 }} {...props} />
                                </Card>
                            </Col>
                        </Row>
                    );
                } else if (tournamentData.is_public === false && !authState.user) {
                    return (
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <Card style={{ margin: 10 }}>
                                    <ZeroState showImage message="Tournament not found" style={{ margin: 30 }} {...props} />
                                </Card>
                            </Col>
                        </Row>
                    );
                } else if (
                    tournamentData.is_public === false &&
                    authState &&
                    authState.user &&
                    (tournamentData.organizer_id !== authState.user.id || tournamentData.owner_id !== authState.user.id) &&
                    authState.user.role !== 'admin'
                ) {
                    return (
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <Card style={{ margin: 10 }}>
                                    <ZeroState showImage message="Tournament not found" style={{ margin: 30 }} {...props} />
                                </Card>
                            </Col>
                        </Row>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <SectionHeader
                                title={
                                    <Text style={breakpoint === 'mobile' ? { fontSize: 16, color: '#fff', maxWidth: 300 } : { fontSize: 20, color: '#fff' }}>
                                        {tournamentData && tournamentData.name.toUpperCase()}
                                    </Text>
                                }
                                height={100}
                                titleStyle={{
                                    color: '#fff',
                                    textTransform: 'uppercase',
                                    margin: 0,
                                    display: 'flex',
                                }}
                                backButton={
                                    <div
                                        style={{
                                            paddingLeft: 0,
                                            paddingBottom: 10,
                                            paddingTop: 10,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <Icon
                                            type="arrow-left"
                                            onClick={() => props.history.push(`/tournaments/${tournamentData.slug}`)}
                                            style={{ color: '#fff' }}
                                        />
                                    </div>
                                }
                                subTitle={tournamentData && tournamentData.status === 'IN_PROGRESS' ? <Tag color="#f5222d">LIVE</Tag> : ''}
                                subtitleStyle={{ color: '#fff' }}
                                style={styles.header}
                            />
                            <SectionWrapper>
                                <SectionContent padding="20px 40px">
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24} align="center">
                                            {submitted === true ? (
                                                <Result status="success" title="Your lesson request has been sent to Karl!" />
                                            ) : (
                                                <Formik
                                                    initialValues={{
                                                        email: '',
                                                        'tournament-slug': true,
                                                    }}
                                                    validationSchema={SignupSchema}
                                                    onSubmit={handleSubmit}
                                                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                        <Form
                                                            onSubmit={handleSubmit}
                                                            layout="vertical"
                                                            style={{
                                                                display: 'flex',
                                                                flex: 1,
                                                                // flexGrow: 1,
                                                                // height: 'calc(100% - 48px)',
                                                                // height: '100%',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                maxWidth: 800,
                                                                width: '100%',
                                                                textAlign: 'left',
                                                            }}
                                                        >
                                                            <Card title="Online Registration">
                                                                <div style={{ textAlign: 'left' }}>
                                                                    <Title level={4}>Sign up online to play in this tournament</Title>
                                                                    <Title level={4} style={{ fontSize: 14 }}>
                                                                        There are only 10 spots remaining!
                                                                    </Title>

                                                                    <FormItem label="Name" name="name" hasFeedback showValidateSuccess required>
                                                                        <Input
                                                                            addonBefore={<Icon type="user" />}
                                                                            name="name"
                                                                            size="large"
                                                                            autoFocus
                                                                            // prefix={<Icon type="mail"  />}
                                                                            placeholder="First and last name"
                                                                        />
                                                                    </FormItem>

                                                                    <FormItem label="Email" name="email" hasFeedback showValidateSuccess required>
                                                                        <Input
                                                                            addonBefore={<Icon type="mail" />}
                                                                            name="email"
                                                                            size="large"
                                                                            // prefix={<Icon type="mail"  />}
                                                                            placeholder="Email address"
                                                                        />
                                                                    </FormItem>

                                                                    <FormItem
                                                                        label="Cell Phone Number"
                                                                        name="phone_number"
                                                                        hasFeedback
                                                                        showValidateSuccess
                                                                        required
                                                                    >
                                                                        <Input
                                                                            addonBefore={<Icon type="phone" />}
                                                                            name="phone_number"
                                                                            size="large"
                                                                            // prefix={<Icon type="mail"  />}
                                                                            placeholder="Phone number"
                                                                        />
                                                                    </FormItem>

                                                                    <FormItem
                                                                        label="Open 9-Ball Tournament $5,000 ADDED $100 ENTRY"
                                                                        name="tournament-slug"
                                                                        required
                                                                    >
                                                                        <Checkbox name="tournament-slug">Sign me up</Checkbox>
                                                                    </FormItem>

                                                                    <FormItem label="Payment Options" name="needs">
                                                                        <Checkbox.Group
                                                                            name="needs"
                                                                            options={[
                                                                                { label: 'CashApp - $UHOHDaryl', value: 'cashapp' },
                                                                                { label: 'Venmo - @Daryl-Keplinger', value: 'venmo' },
                                                                                { label: 'PayPal - UHOH.Daryl@gmail.com', value: 'paypal' },
                                                                                { label: 'CASH AT EVENT (FIRST PAID FIRST ENTERED)', value: 'cash' },
                                                                            ]}
                                                                            style={{ display: 'flex', flexDirection: 'column' }}
                                                                        />
                                                                    </FormItem>

                                                                    <FormItem label="Acknowledgement" name="acknowledgement" required>
                                                                        <Checkbox name="acknowledgement">
                                                                            I understand that I MUST be paid to be entered in this event
                                                                        </Checkbox>
                                                                    </FormItem>

                                                                    <SubmitButton
                                                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                                                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                                                        size="large"
                                                                        style={{ marginTop: 10, width: '100%' }}
                                                                        loading={loading}
                                                                    >
                                                                        {loading === true ? 'Signing Up...' : 'Sign Up'}
                                                                    </SubmitButton>
                                                                    <div style={{ marginTop: 10 }}>
                                                                        <Text style={{ color: '#f5222d' }}>{errors.general}</Text>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Form>
                                                    )}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </SectionContent>
                            </SectionWrapper>
                        </React.Fragment>
                    );
                }
            }}
        </Query>
    );
}

export default withRouter(DPTournamentRegistration);
