import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import MatchScore from '../../../components/matches/MatchScore';
import TPA from '../../../components/matches/TPA';
import PlayerName from '../../../components/matches/PlayerName';
import RaceTo from '../../../components/matches/RaceTo';
import PlayerPoints from '../../../components/matches/PlayerPoints';
import ScoringHeader from '../../../components/matches/ScoringHeader';
import BallsMade from '../../../components/matches/BallsMade';

import { getFargoOddsAsync } from '../../../utils/fantasyLinesCalc';

const { Title, Text } = Typography;

export default function StreamOverlayLayout3(props) {
    const { ballsMade, matchData, params, stats } = props;
    const [playerFargoOdds, setPlayerFargoOdds] = useState(0);
    const [playerMoneyLine, setPlayerMoneyLine] = useState(0);
    const [opponentMoneyLine, setOpponentMoneyLine] = useState(0);
    const [opponentFargoOdds, setOpponentFargoOdds] = useState(0);
    const ballSize = params.ball_size || 28;

    useEffect(() => {
        getFargoOdds(matchData);
    }, [matchData]);

    async function getFargoOdds(matchData) {
        const totalRace = Math.round(matchData.race_to / 2);
        const player1Score = Math.round(totalRace - matchData.player_score / 2);
        const player2Score = Math.round(totalRace - matchData.opponent_score / 2);
        var odds = await getFargoOddsAsync(matchData.player_fargo, matchData.opponent_fargo, player1Score, player2Score);
        const playerOdds = (odds.oneOdds * 100).toFixed(2);
        const opponentOdds = (odds.twoOdds * 100).toFixed(2);
        setPlayerFargoOdds(`${playerOdds}%`);
        setOpponentFargoOdds(`${opponentOdds}%`);
        return odds;
    }

    function showBallCount() {
        return params.show_ball_count === 'true' || matchData.game_type === 'ONE_POCKET' || matchData.game_type === 'STRAIGHT_POOL';
    }

    return (
        <div>
            <div
                style={{
                    background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    color: '#fff',
                }}
            >
                {/* <ScoringHeader show_ball_count = {params.show_ball_count}
                               game_type = {matchData.game_type}
                /> */}

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            paddingLeft: 10,
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 12,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <TPA player_stats={stats} player_tpa={stats.playerTPA} />

                        <PlayerName
                            country={matchData.player_country}
                            player_name={matchData.player_name}
                            player_fargo={matchData.player_fargo}
                            player_fargo_odds={playerFargoOdds}
                        />
                    </div>

                    <PlayerPoints show_ball_count={showBallCount()} player_points={matchData.player_points} />

                    <MatchScore
                        player_score={matchData.player_score}
                        opponent_score={matchData.opponent_score}
                        race_to={matchData.race_to}
                        highlight_on_hill={true}
                    />

                    <RaceTo race_to={matchData.race_to} />

                    <MatchScore
                        player_score={matchData.opponent_score}
                        opponent_score={matchData.player_score}
                        race_to={matchData.race_to}
                        highlight_on_hill={true}
                    />

                    <PlayerPoints show_ball_count={showBallCount()} game_type={matchData.game_type} player_points={matchData.opponent_points} />

                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            paddingLeft: 12,
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 10,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <PlayerName
                            country={matchData.opponent_country}
                            player_name={matchData.opponent_name}
                            player_fargo={matchData.opponent_fargo}
                            player_fargo_odds={opponentFargoOdds}
                        />

                        <TPA player_stats={stats} player_tpa={stats.opponentTPA} />
                    </div>
                </div>
            </div>

            {params.show_balls === 'true' && <BallsMade ball_size={ballSize} balls_made={ballsMade} game_type={matchData.game_type} />}
        </div>
    );
}
