import { gql } from '@apollo/client';

export const UPDATE_TOURNAMENT_SET_MUTATION = gql`
    mutation update_tournament_match_sets($id: Int!, $tournament_id: Int!, $changes: tournament_match_sets_set_input!) {
        update_tournament_match_sets(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION = gql`
    mutation update_tournament_brackets($id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_MATCH_BY_SLUG_MUTATION = gql`
    mutation update_tournament_brackets($id: Int!, $tournament_slug: String!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { id: { _eq: $id }, tournament: { slug: { _eq: $tournament_slug } } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const ADVANCE_TOURNAMENT_BRACKET_MUTATION = gql`
    mutation update_tournament_brackets($match_number: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, _and: { match_number: { _eq: $match_number } } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const ADVANCE_TOURNAMENT_BRACKET_STAGE_MUTATION = gql`
    mutation update_tournament_brackets($match_id: Int!, $stage_id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $match_id }, stage_id: { _eq: $stage_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const GENERATE_TOURNAMENT_MATCH_TIME_PROJECTIONS = gql`
    mutation fn_generate_tournament_projection_results(
        $tournament_id: Int!
        $from_round: Int!
        $number_of_tables: Int!
        $use_tournament_actuals: Boolean!
        $winner_side_race_to: numeric!
        $loser_side_race_to: numeric!
        $average_time_per_game: numeric!
    ) {
        fn_generate_tournament_projection_results(
            where: { tournament_id: { _eq: $tournament_id } }
            args: {
                _tournament_id: $tournament_id
                _from_round: $from_round
                _number_of_tables: $number_of_tables
                _use_tournament_actuals: $use_tournament_actuals
                _winner_side_race_to: $winner_side_race_to
                _loser_side_race_to: $loser_side_race_to
                _average_time_per_game: $average_time_per_game
            }
        ) {
            end_time
            id
            match_duration
            round
            round_combo
            start_time
            tournament_bracket_id
            tournament_id
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_MUTATION = gql`
    mutation update_tournament_brackets($tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const CREATE_TOURNAMENT_BRACKET_MUTATION = gql`
    mutation insert_tournament_brackets($objects: [tournament_brackets_insert_input!]!) {
        insert_tournament_brackets(objects: $objects) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_BRACKET_MUTATION = gql`
    mutation delete_tournament_brackets($tournament_id: Int!) {
        delete_tournament_brackets(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
        update_pool_tables(where: { tournament_id: { _eq: $tournament_id }, status: { _neq: CLOSED } }, _set: { status: OPEN }) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_MUTATION = gql`
    mutation update_tournaments($id: Int!, $changes: tournaments_set_input) {
        update_tournaments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const COMPLETE_TOURNAMENT_MUTATION = gql`
    mutation update_tournaments($id: Int!) {
        update_tournaments(where: { id: { _eq: $id } }, _set: { status: COMPLETED, progress: "100" }) {
            affected_rows
        }
    }
`;

export const UPDATE_TABLE_STATUS_MUTATION = gql`
    mutation update_pool_tables($id: Int!, $changes: pool_tables_set_input) {
        update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_TABLE_STATUS_MUTATION = gql`
    mutation update_pool_tables($tournament_id: Int!, $status: pool_table_status_enum) {
        update_pool_tables(where: { tournament_id: { _eq: $tournament_id }, status: { _neq: CLOSED } }, _set: { status: $status }) {
            affected_rows
        }
    }
`;

export const RESET_TOURNAMENT_PLAYER_SEED_ORDER = gql`
    mutation reset_seed_order($tournament_id: Int!) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id } }, _set: { seed: null }) {
            affected_rows
        }
    }
`;

export const RESET_TOURNAMENT_PLAYER_PLACEMENTS = gql`
    mutation reset_placements($tournament_id: Int!) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id } }, _set: { place: null }) {
            affected_rows
        }
    }
`;

export const RESET_TOURNAMENT_BRACKET_PLAYERS = gql`
    mutation reset_bracket_players($tournament_id: Int!) {
        update_tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id } }
            _set: { challenger1_id: null, challenger2_id: null, challenger1_name: null, challenger2_name: null }
        ) {
            affected_rows
        }
    }
`;

// export const SET_TOURNAMENT_ROUND_TIME = gql`
// 	mutation insert_tournament_bracket_schedules($objects: [tournament_bracket_schedules_insert_input!]!) {
// 		insert_tournament_bracket_schedules(objects: $objects) {
// 			affected_rows
// 		}
// 	}
// `;

export const UPDATE_TOURNAMENT_ROUND_TIME = gql`
    mutation update_tournament_brackets($round: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, round: { _eq: $round } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_STAGE_ROUND_TIME = gql`
    mutation update_tournament_brackets($round: Int!, $tournament_id: Int!, $stage_id: Int!, $changes: tournament_brackets_set_input) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, stage_id: { _eq: $stage_id }, round: { _eq: $round } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_GROUP_ROUND_TIME = gql`
    mutation update_tournament_brackets($round: Int!, $tournament_id: Int!, $group: Int!, $changes: tournament_brackets_set_input) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, round: { _eq: $round }, group: { _eq: $group } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_STAGE_GROUP_ROUND_TIME = gql`
    mutation update_tournament_brackets($round: Int!, $tournament_id: Int!, $stage_id: Int!, $group: Int!, $changes: tournament_brackets_set_input) {
        update_tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, stage_id: { _eq: $stage_id }, round: { _eq: $round }, group: { _eq: $group } }
            _set: $changes
        ) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_MATCH_TIME = gql`
    mutation update_tournament_brackets($round: Int!, $id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, round: { _eq: $round }, id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_PLAYER_MUTATION = gql`
    mutation update_tournament_players($id: Int!, $tournament_id: Int!, $changes: tournament_players_set_input) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_STATUS = gql`
    mutation update_tournaments($id: Int!, $changes: tournaments_set_input!) {
        update_tournaments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT = gql`
    mutation update_tournaments($id: Int!, $changes: tournaments_set_input!) {
        update_tournaments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_PAYOUTS_MUTATION = gql`
    mutation delete_tournament_payouts($tournament_id: Int!) {
        delete_tournament_payouts(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_POINTS_MUTATION = gql`
    mutation delete_tournament_points($tournament_id: Int!) {
        delete_tournament_points(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_TABLE_MUTATION = gql`
    mutation update_pool_tables($id: Int, $changes: pool_tables_set_input) {
        update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_TABLE_MUTATION = gql`
    mutation delete_pool_tables($id: Int) {
        delete_pool_tables(where: { id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const LOG_PLAYER_SMS_NOTIFICATION = gql`
    mutation upsert_tournament_notifications($objects: [tournament_notifications_insert_input!]!) {
        insert_tournament_notifications(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_MATCH_GAME = gql`
    mutation update_tournament_match_games($id: Int!, $tournament_id: Int!, $changes: tournament_match_games_set_input) {
        update_tournament_match_games(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_TOURNAMENT_MATCH_GAME = gql`
    mutation insert_tournament_match_games($objects: [tournament_match_games_insert_input!]!) {
        insert_tournament_match_games(objects: $objects) {
            affected_rows
            returning {
                id
                tournament_id
                tournament_bracket_id
                match_number
                game_number
                inning
                player_id
                player_score
                player_points
                player_is_winner
                player_break_run
                player_high_run
                player_end_run
                player_runout
                player_tpa
                player_unforced_errors
                player_balls_pocketed
                opponent_id
                opponent_score
                opponent_points
                opponent_is_winner
                opponent_break_run
                opponent_high_run
                opponent_end_run
                opponent_runout
                opponent_tpa
                opponent_unforced_errors
                opponent_balls_pocketed
                status
                progress
                duration
                table_id
                start_time
                end_time
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_TOURNAMENT_MATCH_GAME = gql`
    mutation delete_tournament_match_games($id: Int!, $tournament_id: Int, $tournament_bracket_id: Int) {
        delete_tournament_match_games(
            where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id }, tournament_bracket_id: { _eq: $tournament_bracket_id } }
        ) {
            affected_rows
        }
    }
`;

export const RESET_TOURNAMENT_MATCH_GAMES = gql`
    mutation delete_tournament_match_games($tournament_id: Int!, $tournament_bracket_id: Int!) {
        delete_tournament_match_games(where: { tournament_id: { _eq: $tournament_id }, tournament_bracket_id: { _eq: $tournament_bracket_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_VENUE_MUTATION = gql`
    mutation upsert_venues($objects: [venues_insert_input!]!) {
        insert_venues(objects: $objects, on_conflict: { constraint: venues_slug_key, update_columns: [] }) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

// export const UPDATE_SET_MUTATION = gql`
//     mutation update_tournament_match_sets($id: Int!, $match_id: Int!, $tournament_id: Int!, $changes: tournament_match_sets_set_input) {
//         update_tournament_match_sets(where: { id: { _eq: $id }, match_id: { _eq: $match_id }, tournament_id: { _eq: $tournament_id } }, _set: $changes) {
//             affected_rows
//         }
//     }
// `;

export const DELETE_SET_MUTATION = gql`
    mutation delete_tournament_match_sets($id: Int!, $tournament_id: Int) {
        delete_tournament_match_sets(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_SET_MUTATION = gql`
    mutation upsert_tournament_match_sets($objects: [tournament_match_sets_insert_input!]!) {
        insert_tournament_match_sets(
            objects: $objects
            on_conflict: {
                constraint: tournament_match_sets_pkey
                update_columns: [
                    challenger1_id
                    challenger1_score
                    challenger1_race_to
                    challenger1_points
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_score
                    challenger2_race_to
                    challenger2_points
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                ]
            }
        ) {
            affected_rows
            returning {
                id
                name
                slug
                number
                match_id
                challenger1_id
                challenger1_score
                challenger1_race_to
                challenger1_points
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_score
                challenger2_race_to
                challenger2_points
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                start_time
                end_time
            }
        }
    }
`;

export const DELETE_TOURNAMENT_MATCH = gql`
    mutation delete_tournament_brackets($id: Int!, $tournament_id: Int!) {
        delete_tournament_brackets(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_MATCH_GAME = gql`
    mutation insert_match_games($objects: [match_games_insert_input!]!) {
        insert_match_games(objects: $objects) {
            affected_rows
            returning {
                id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_MATCH_GAME = gql`
    mutation delete_match_games($id: Int!, $match_id: Int!) {
        delete_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

export const RESET_MATCH_GAMES = gql`
    mutation delete_match_games($match_id: Int!) {
        delete_match_games(where: { match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_MATCH_GAME = gql`
    mutation update_match_games($id: Int!, $match_id: Int!, $changes: match_games_set_input) {
        update_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_MATCH_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            __typename
            returning {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
                match_games {
                    id
                    match_id
                    match_number
                    game_number
                    inning
                    player_id
                    player_score
                    player_points
                    player_is_winner
                    player_break_run
                    player_high_run
                    player_end_run
                    player_runout
                    player_tpa
                    player_unforced_errors
                    player_balls_pocketed
                    opponent_id
                    opponent_score
                    opponent_points
                    opponent_is_winner
                    opponent_break_run
                    opponent_high_run
                    opponent_end_run
                    opponent_runout
                    opponent_tpa
                    opponent_unforced_errors
                    opponent_balls_pocketed
                    status
                    progress
                    duration
                    table_id
                    start_date_time
                    end_date_time
                    created_at
                    updated_at
                }
            }
        }
    }
`;
