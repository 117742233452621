import React, { useState } from 'react';
import { message, Col, Row, Statistic, Progress, Skeleton, List, Avatar, Icon, Button, Empty, Typography, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import SectionContent from '../../../components/SectionContent';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { GET_EVENT_QUERY, GET_USER_CREATED_EVENTS_QUERY, GET_EVENT_PLAYERS_QUERY } from '../data/queries';
import { DELETE_EVENT_MUTATION } from '../data/mutations';
import { Query } from '@apollo/client/react/components';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    DollarOutlined,
    UserOutlined,
    TeamOutlined,
    CalendarOutlined,
    EnvironmentOutlined,
    TrophyFilled,
} from '@ant-design/icons';
import ZeroState from '../../../components/ZeroState';
import CircleFlag from '../../../components/CircleFlag';
import Highlighter from 'react-highlight-words';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getCountryName, formatPlayerName, displayEnumType, getGameType, getOrdinalSuffix } from '../../../utils/';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import blueBg from '../../../assets/img/blue-bg.png';
import tournamentThumb from '../../../assets/img/tournament-thumb.png';
import tournamentThumb8Ball from '../../../assets/img/tournament-thumb-8ball.png';
import tournamentThumb9Ball from '../../../assets/img/tournament-thumb-9ball.png';
import tournamentThumb10Ball from '../../../assets/img/tournament-thumb-10ball.png';
import tournamentThumbOnePocket from '../../../assets/img/tournament-thumb-one-pocket.png';
import numeral from 'numeral';
import MatchTable from './MatchTable';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { confirm } = Modal;

const styles = {};

export default function EventOverview(props) {
    const { authState, history, match, event, tournaments, matches, isManager, theme } = props;
    const [searchValue, setSearchValue] = useState();
    const [deleteEvent, { loading, error }] = useMutation(DELETE_EVENT_MUTATION);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    function createMarkup(markup) {
        return { __html: markup };
    }

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to delete ${record.name}?`,
            content: 'This will permanently remove the event and cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteEvent({
                    variables: {
                        id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_USER_CREATED_EVENTS_QUERY,
                            variables: {
                                ownerId: props.authState.user.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Event deleted');
                        props.history.push(`/events/my-events`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    return (
        <SectionContent padding="20px 40px">
            <Row gutter={24} justify="center" type="flex">
                <Col xs={24}>
                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                </Col>
                <Col xs={24}>
                    <Row gutter={24}>
                        <Col xs={24} lg={10}>
                            <Card
                                title={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                            Event Summary
                                        </Title>

                                        {((authState && authState.user && authState.user.role === 'admin') ||
                                            (authState && authState.user && authState.user.id === event.owner_id)) && (
                                            <Button type="danger" ghost onClick={() => showConfirmDelete(event)}>
                                                <Icon type="delete" /> Delete Event
                                            </Button>
                                        )}
                                    </div>
                                }
                                // extra={
                                //     <Button
                                //         type="default"
                                //         onClick={() => {
                                //             console.log('register');
                                //         }}
                                //     >
                                //         Register
                                //     </Button>
                                // }
                                bodyStyle={{ padding: 0 }}
                            >
                                <Card
                                    // hoverable
                                    bordered={false}
                                    loading={loading}
                                    cover={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#000',
                                            }}
                                        >
                                            {!loading && (
                                                <React.Fragment>
                                                    {event && event.flyer ? (
                                                        <img
                                                            alt="example"
                                                            src={event && event.flyer}
                                                            style={{ maxHeight: 800, width: 'auto', maxWidth: '100%' }}
                                                            // style={{ width: '100%' }}
                                                        />
                                                    ) : (
                                                        <img
                                                            alt="example"
                                                            src={blueBg}
                                                            style={{ maxHeight: 800, width: 'auto', maxWidth: '100%' }}
                                                            // style={{ width: '100%' }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    }
                                    style={{ border: 'none', borderRadius: 0 }}
                                >
                                    {event && (
                                        <React.Fragment>
                                            <Meta
                                                title={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div>
                                                            <Title level={4}>
                                                                <CalendarOutlined style={{ marginRight: 5 }} /> {event && event.name}
                                                            </Title>
                                                        </div>
                                                        {/* <div>
                                                                        <Button type="primary" shape="round">
                                                                            Interested
                                                                        </Button>
                                                                    </div> */}
                                                    </div>
                                                }
                                                description={
                                                    <div style={{ maxWidth: 800, maxHeight: 400, overflowY: 'auto' }}>
                                                        <Text className="display-linebreak">{event.description}</Text>
                                                    </div>
                                                }
                                            />
                                            <br />
                                            <table width="100%" className="info-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Event ID:</td>
                                                        <td>(ID: {event.id})</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Start date</td>
                                                        <td>
                                                            {moment(event.start_date_time).format('llll')} ({timezone})
                                                        </td>
                                                    </tr>
                                                    {event.end_date_time && (
                                                        <tr>
                                                            <td>End date</td>
                                                            <td>
                                                                {moment(event.end_date_time).format('llll')} ({timezone})
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {event.director && (
                                                        <tr>
                                                            <td>Tournament director</td>
                                                            <td>
                                                                {event.director.first_name} {event.director.last_name} <br /> {event.director.email}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    <tr>
                                                        <td>Access</td>
                                                        <td>
                                                            <Text>{event.is_public ? 'Public' : 'Private'}</Text>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    )}
                                </Card>
                            </Card>
                            <br />

                            <Card title="Venue" bodyStyle={{ padding: 0 }}>
                                <List
                                    className="hover-list"
                                    size="small"
                                    loading={loading}
                                    itemLayout="horizontal"
                                    dataSource={event.venue ? [event.venue] : []}
                                    renderItem={(item) => (
                                        <List.Item
                                            // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                            style={{ padding: '10px 16px' }}
                                            onClick={() => history.push(`/venues/${item.slug}`)}
                                        >
                                            <Skeleton avatar title={false} loading={loading} active>
                                                <List.Item.Meta
                                                    avatar={
                                                        <div>
                                                            <Icon
                                                                type="environment"
                                                                theme="filled"
                                                                style={{
                                                                    color: '#1890ff',
                                                                    fontSize: 30,
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    title={
                                                        <Text
                                                            style={{
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Text>
                                                    }
                                                    description={
                                                        <div>
                                                            {event.venue && event.venue.address1} {event.venue && event.venue.address2}
                                                            {event.venue && event.venue.city}, {event.venue && event.venue.region}{' '}
                                                            {event.venue && event.venue.postal_code}
                                                            {event.venue && event.venue.country}
                                                        </div>
                                                    }
                                                />
                                            </Skeleton>
                                        </List.Item>
                                    )}
                                />
                            </Card>

                            {breakpoint === 'desktop' && (
                                <React.Fragment>
                                    <br />

                                    <Query
                                        query={GET_EVENT_PLAYERS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ eventId: event.id }}
                                        onCompleted={async (data) => {
                                            console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <CircularLoader />;
                                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                            if (data && !data.tournaments) {
                                                return null;
                                            }
                                            if (data && data.tournaments.length === 0) {
                                                return null;
                                            }

                                            const tournaments = data.tournaments;
                                            console.log(tournaments);
                                            let players = [];
                                            tournaments &&
                                                tournaments.forEach((tournament) => {
                                                    tournament.tournament_players &&
                                                        tournament.tournament_players.forEach((player) => {
                                                            players.push({
                                                                ...player,
                                                                tournament_name: tournament.name,
                                                            });
                                                        });
                                                });

                                            let uniquePlayers = _.uniqBy(players, (o) => {
                                                return o.name;
                                            });

                                            const groups = _.groupBy(uniquePlayers, 'tournament_name');
                                            const playersGrouped = Object.values(groups);

                                            return (
                                                <Card
                                                    title={`Players (${uniquePlayers && uniquePlayers.length})`}
                                                    bodyStyle={{ padding: 0, maxHeight: 600, overflowY: 'auto' }}
                                                >
                                                    {playersGrouped ? (
                                                        <React.Fragment>
                                                            {playersGrouped.map((group, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <Title level={4} style={{ marginLeft: 20, marginTop: 20 }}>
                                                                            {tournaments[index].name}
                                                                        </Title>

                                                                        <List
                                                                            size="small"
                                                                            dataSource={group}
                                                                            renderItem={(player) => {
                                                                                return (
                                                                                    <List.Item
                                                                                        style={{
                                                                                            padding: '10px 20px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'flex-start',
                                                                                        }}
                                                                                    >
                                                                                        {player.country && <CircleFlag country={player.country} left={0} />}
                                                                                        <Text>
                                                                                            <div className="ellipsis">
                                                                                                {formatPlayerName(player)}{' '}
                                                                                                {player.race_to ? `(${player.race_to})` : null}
                                                                                            </div>
                                                                                        </Text>
                                                                                    </List.Item>
                                                                                );
                                                                            }}
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    ) : (
                                                        <ZeroState showImage message="No Players have been added yet." style={{ margin: 30 }} {...props} />
                                                    )}
                                                </Card>
                                            );
                                        }}
                                    </Query>
                                </React.Fragment>
                            )}
                        </Col>

                        <Col xs={24} lg={14}>
                            <Card
                                title="Brackets"
                                extra={
                                    ((authState && authState.user && (authState.user.id === event.owner_id || authState.user.role === 'admin')) ||
                                        isManager) && (
                                        <Link to={`/tournament-builder/new/settings?event_id=${event.id}`}>
                                            <Button type="default" size="large">
                                                Create Bracket
                                            </Button>
                                        </Link>
                                    )
                                }
                                bodyStyle={{ padding: breakpoint === 'mobile' ? 0 : 24 }}
                            >
                                <Row gutter={16} type="flex">
                                    {tournaments && tournaments.length === 0 ? (
                                        <div>No tournament brackets created yet.</div>
                                    ) : (
                                        tournaments &&
                                        tournaments.map((tournament, index) => {
                                            let duration;
                                            if (tournament.start_date_time && tournament.end_date_time && tournament.status === 'COMPLETED') {
                                                var start = moment(tournament.start_date_time);
                                                var end = moment(tournament.end_date_time);

                                                duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');

                                                // moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
                                            } else {
                                                duration = moment
                                                    .duration(moment.utc().diff(tournament.start_date_time, 'milliseconds'))
                                                    .format('h [hours] m [minutes]');
                                            }

                                            const totalPayouts =
                                                tournament.tournament_payouts_aggregate &&
                                                tournament.tournament_payouts_aggregate.aggregate.sum &&
                                                tournament.tournament_payouts_aggregate.aggregate.sum.money +
                                                    tournament.tournament_payouts_aggregate.aggregate.sum.calcutta;

                                            return (
                                                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8} key={index}>
                                                    <Card
                                                        hoverable
                                                        cover={
                                                            <div
                                                                style={{
                                                                    height: 200,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                <img
                                                                    alt="example"
                                                                    src={
                                                                        tournament.flyer
                                                                            ? tournament.flyer
                                                                            : tournament.game_type === 'NINE_BALL'
                                                                            ? tournamentThumb9Ball
                                                                            : tournament.game_type === 'EIGHT_BALL'
                                                                            ? tournamentThumb8Ball
                                                                            : tournament.game_type === 'TEN_BALL'
                                                                            ? tournamentThumb10Ball
                                                                            : tournament.game_type === 'ONE_POCKET'
                                                                            ? tournamentThumbOnePocket
                                                                            : tournamentThumb
                                                                    }
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div>
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                tournament.tournament_stage_format === 'multi' ||
                                                                tournament.tournament_type === 'round_robin'
                                                            ) {
                                                                props.history.push(`/tournaments/${tournament.slug}/viewer`);
                                                            } else {
                                                                props.history.push(`/tournaments/${tournament.slug}/bracket`);
                                                            }
                                                        }}
                                                        actions={
                                                            authState && authState.user && authState.user.id === event.owner_id
                                                                ? [
                                                                      <Button
                                                                          type="link"
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              e.stopPropagation();
                                                                              props.history.push(`/tournaments/${tournament.slug}`);
                                                                          }}
                                                                      >
                                                                          <Icon type="eye" key="view" /> View
                                                                      </Button>,
                                                                      <Button
                                                                          type="link"
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              e.stopPropagation();
                                                                              props.history.push(`/tournament-builder/${tournament.slug}`);
                                                                          }}
                                                                      >
                                                                          <Icon type="setting" key="manage" /> Manage
                                                                      </Button>,
                                                                  ]
                                                                : []
                                                        }
                                                    >
                                                        <Meta
                                                            avatar={
                                                                <div>
                                                                    {tournament.status === 'IN_PROGRESS' ? (
                                                                        <ClockCircleOutlined
                                                                            style={{
                                                                                fontSize: 20,
                                                                                marginTop: 5,
                                                                                color: '#1890ff',
                                                                            }}
                                                                        />
                                                                    ) : tournament.status === 'NOT_STARTED' ? (
                                                                        <ClockCircleOutlined
                                                                            style={{
                                                                                fontSize: 20,
                                                                                marginTop: 5,
                                                                                color:
                                                                                    theme && theme.name === 'dark'
                                                                                        ? 'rgba(255,255,255, 0.45)'
                                                                                        : 'rgba(0, 0, 0, 0.45)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CheckCircleOutlined
                                                                            style={{
                                                                                fontSize: 20,
                                                                                marginTop: 5,
                                                                                color: '#3ebb00',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            }
                                                            title={
                                                                <Title level={3} style={{ margin: 0 }}>
                                                                    <Highlighter
                                                                        highlightClassName="highlighted-text"
                                                                        searchWords={[searchValue]}
                                                                        autoEscape={true}
                                                                        textToHighlight={tournament.name}
                                                                    />
                                                                </Title>
                                                            }
                                                            description={
                                                                <div
                                                                    style={{
                                                                        maxHeight: 300,
                                                                        overflowY: 'auto',
                                                                    }}
                                                                >
                                                                    {tournament.progress > 0 && tournament.progress < 100 && (
                                                                        <div
                                                                            style={{
                                                                                marginTop: 5,
                                                                                marginBottom: 5,
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <Statistic
                                                                                    value={tournament.progress ? parseInt(tournament.progress).toFixed(0) : 0}
                                                                                    valueStyle={{
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                    suffix={`% Complete`}
                                                                                />
                                                                                <Progress
                                                                                    percent={parseInt(tournament.progress)}
                                                                                    status={
                                                                                        parseInt(tournament.progress) < 100
                                                                                            ? 'active'
                                                                                            : parseInt(tournament.progress) === 100
                                                                                            ? 'success'
                                                                                            : 'normal'
                                                                                    }
                                                                                    size="small"
                                                                                    showInfo={false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    {tournament.status === 'COMPLETED' && (
                                                                        <div>
                                                                            {tournament.tournament_players &&
                                                                                tournament.tournament_players.map((player, index) => {
                                                                                    const isSplit = tournament.tournament_players.filter((player) => {
                                                                                        return player.place === '1';
                                                                                    });
                                                                                    return (
                                                                                        <div
                                                                                            key={index}
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                {player.place === '1' ? (
                                                                                                    <TrophyFilled
                                                                                                        style={{
                                                                                                            color: '#eab700',
                                                                                                            marginRight: 5,
                                                                                                        }}
                                                                                                    />
                                                                                                ) : player.place === '2' ? (
                                                                                                    <TrophyFilled
                                                                                                        style={{
                                                                                                            color: '#9baab1',
                                                                                                            marginRight: 5,
                                                                                                        }}
                                                                                                    />
                                                                                                ) : (
                                                                                                    player.place === '3' && (
                                                                                                        <TrophyFilled
                                                                                                            style={{
                                                                                                                color: '#f19921',
                                                                                                                marginRight: 5,
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    style={{
                                                                                                        marginLeft: 0,
                                                                                                        width: '100%',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                    }}
                                                                                                >
                                                                                                    {formatPlayerName(player)}{' '}
                                                                                                    {player.race_to ? `(${player.race_to})` : null} -{' '}
                                                                                                    {player.place
                                                                                                        ? `${getOrdinalSuffix(player.place)} ${
                                                                                                              player.place === '1' && isSplit.length > 1
                                                                                                                  ? '(split)'
                                                                                                                  : ''
                                                                                                          }`
                                                                                                        : '-'}
                                                                                                </Text>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </div>
                                                                    )}

                                                                    <div style={{ marginTop: 14 }}>
                                                                        <CalendarOutlined />{' '}
                                                                        <Text>{moment(tournament.start_date_time).format('MMMM Do YYYY')}</Text>
                                                                        <br />
                                                                        <TeamOutlined />{' '}
                                                                        <Text>{tournament.tournament_players_aggregate.aggregate.count} Players</Text> -{' '}
                                                                        {displayEnumType(tournament.tournament_type)}
                                                                        {tournament.venue && (
                                                                            <React.Fragment>
                                                                                <br />
                                                                                <EnvironmentOutlined />{' '}
                                                                                <Text>
                                                                                    <Highlighter
                                                                                        highlightClassName="highlighted-text"
                                                                                        searchWords={[searchValue]}
                                                                                        autoEscape={true}
                                                                                        textToHighlight={`${tournament.venue.name} -
                                                                            ${tournament.venue.city}, 
                                                                            ${tournament.venue.region}`}
                                                                                    />
                                                                                </Text>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <br />
                                                                        <UserOutlined />{' '}
                                                                        <Text>
                                                                            <Highlighter
                                                                                highlightClassName="highlighted-text"
                                                                                searchWords={[searchValue]}
                                                                                autoEscape={true}
                                                                                textToHighlight={`${tournament.director && tournament.director.first_name} ${
                                                                                    tournament.director && tournament.director.last_name
                                                                                }`}
                                                                            />
                                                                        </Text>{' '}
                                                                        - Director
                                                                        {totalPayouts > 0 && (
                                                                            <React.Fragment>
                                                                                <br />
                                                                                <DollarOutlined /> <Text>{numeral(totalPayouts).format('$0,0')}</Text> - Payouts
                                                                            </React.Fragment>
                                                                        )}
                                                                        <br />
                                                                        <Avatar
                                                                            size={18}
                                                                            src={getGameType(tournament.game_type)}
                                                                            style={{
                                                                                marginLeft: -1,
                                                                                position: 'relative',
                                                                            }}
                                                                        />{' '}
                                                                        <Text>
                                                                            <Highlighter
                                                                                highlightClassName="highlighted-text"
                                                                                searchWords={[searchValue]}
                                                                                autoEscape={true}
                                                                                textToHighlight={displayEnumType(tournament.game_type)}
                                                                            />
                                                                        </Text>
                                                                        - Race to {tournament.winners_race_to}
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            marginTop: 15,
                                                                            color: theme.name === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.85)',
                                                                        }}
                                                                        className="display-linebreak"
                                                                        dangerouslySetInnerHTML={createMarkup(tournament.description)}
                                                                    />
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Col>
                                            );
                                        })
                                    )}
                                </Row>
                            </Card>

                            <br />
                            <Card
                                title="Matches"
                                extra={
                                    ((authState && authState.user && (authState.user.id === event.owner_id || authState.user.role === 'admin')) ||
                                        isManager) && (
                                        <Link to={`/matches/new?event_id=${event.id}`}>
                                            <Button type="default" size="large">
                                                Create Match
                                            </Button>
                                        </Link>
                                    )
                                }
                                bodyStyle={{ padding: 0 }}
                            >
                                <Row gutter={16} type="flex">
                                    {matches && matches.length === 0 ? (
                                        <div>No matches created yet.</div>
                                    ) : (
                                        <React.Fragment>
                                            <MatchTable matches={matches} {...props} />
                                        </React.Fragment>
                                    )}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </SectionContent>
    );
}
