import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import axios from 'axios';

export const getTwitchUserStream = (userLogin) => {
    return axios({
        method: 'get',
        url: 'https://api.twitch.tv/helix/streams',
        params: {
            user_login: userLogin
        },
        headers: {
            'Client-ID': process.env.REACT_APP_TWITCH_CLIENT_ID
        }
    });
}

export const getYouTubeThumbnailURL = (channel) => {
    return  `https://img.youtube.com/vi/${channel}/hqdefault.jpg`
}