import React, { useState, useEffect } from 'react';
import { GET_MATCH_PLAYER_CONSECUTIVE_BREAK_AND_RUNS, GET_MATCH_OPPONENT_CONSECUTIVE_BREAK_AND_RUNS } from './data/subscriptions';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Subscription } from '@apollo/client/react/components';

const styles = {};

export default function StatConsecutiveBreakAndRunStreak(props) {
    const { matchId } = props;

    return (
        <tr>
            <Subscription
                subscription={GET_MATCH_PLAYER_CONSECUTIVE_BREAK_AND_RUNS}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ match_id: matchId }}
                onSubscriptionData={async (data) => {
                    // console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <React.Fragment></React.Fragment>;
                    if (error) return <React.Fragment></React.Fragment>;

                    const breakAndRunStreak = data && data.vw_break_and_run_streaks;
                    const playerStreak = breakAndRunStreak && breakAndRunStreak[0];

                    return (
                        <React.Fragment>
                            <td align="right" width="60px"></td>
                            <td align="right">
                                <div
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <b>{(playerStreak && playerStreak.player_streak_length) || 0}</b>
                                </div>
                            </td>
                        </React.Fragment>
                    );
                }}
            </Subscription>

            <td align="center" width="350px">
                Consecutive Break and Runs
            </td>

            <Subscription
                subscription={GET_MATCH_OPPONENT_CONSECUTIVE_BREAK_AND_RUNS}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ match_id: matchId }}
                onSubscriptionData={async (data) => {
                    // console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <React.Fragment></React.Fragment>;
                    if (error) return <React.Fragment></React.Fragment>;

                    const breakAndRunStreak = data && data.vw_break_and_run_streaks;
                    const opponentStreak = breakAndRunStreak && breakAndRunStreak[0];
                    return (
                        <React.Fragment>
                            <td align="left">
                                <div
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <b>{(opponentStreak && opponentStreak.player_streak_length) || 0}</b>
                                </div>
                            </td>
                            <td align="left" width="60px"></td>
                        </React.Fragment>
                    );
                }}
            </Subscription>
        </tr>
    );
}
