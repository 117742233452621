import React, { useEffect } from 'react';
import CircleFlag from '../../../../components/CircleFlag';
import { Avatar, Typography } from 'antd';
import { Row, Col } from 'antd';
import { getGameType, getCountryName, getCountryShortCode } from '../../../../utils';

const { Text, Title } = Typography;

const styles = {
    smallAvatar: {
        width: 20,
        height: 20,
    },
    logo: { position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 10, width: 150 },
    overlay: {
        // borderRadius: '10px 10px 0px 0px',
        position: 'relative',
        top: 0,
        background: 'linear-gradient(180deg,#354358,#0d1e35)',
        width: '100%',
        color: '#fff',
        height: 50,
        display: 'flex',
        alignItems: 'center',
    },
};

export default function DefaultStreamOverlaySetsTemplate(props) {
    const { authState, matchData, tournamentData, showScores, theme, subscribeToMore, subscribeToNewData } = props;

    const challenger1 = matchData && matchData.challenger1;
    const challenger2 = matchData && matchData.challenger2;
    const challenger1Country = getCountryShortCode(matchData && matchData.challenger1_country);
    const challenger2Country = getCountryShortCode(matchData && matchData.challenger2_country);

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    function totalSetsWon(player, sets) {
        if (player === 'challenger1') {
            return sets
                .map((set) => {
                    return set.challenger1_score === set.challenger1_race_to;
                })
                .reduce((acc, total) => {
                    return acc + total;
                }, 0);
        } else if (player === 'challenger2') {
            return sets
                .map((set) => {
                    return set.challenger2_score === set.challenger2_race_to;
                })
                .reduce((acc, total) => {
                    return acc + total;
                }, 0);
        }
    }

    return (
        <div>
            <div
                style={{
                    border: '1px solid rgba(255,255,255,0.3)',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <div style={{ textAlign: 'right', display: 'flex', flex: 1, alignItems: 'center', paddingLeft: 5 }}>
                        {matchData && matchData.challenger1_country && (
                            <CircleFlag country={getCountryName(matchData.challenger1_country) || 'Unknown'} size="medium" />
                        )}
                        <Text
                            style={{
                                fontSize: 30,
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                                marginRight: 10,
                                textTransform: 'uppercase',
                            }}
                        >
                            {matchData.challenger1_name}
                        </Text>
                    </div>
                    {matchData.tournament_match_sets &&
                        matchData.tournament_match_sets.map((set, index) => {
                            return (
                                <div key={index} style={{ padding: 5, width: 30, textAlign: 'center' }}>
                                    <Text
                                        style={{
                                            fontSize: 30,
                                            color: set.challenger1_score === set.challenger1_race_to && '#86d068',
                                            fontWeight:
                                                set.challenger1_score === set.challenger1_race_to || set.challenger1_score > set.challenger2_score
                                                    ? 'bold'
                                                    : 'normal',
                                        }}
                                    >
                                        {set.challenger1_score}
                                    </Text>
                                </div>
                            );
                        })}
                    <div
                        style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            textAlign: 'center',
                            backgroundColor: 'rgba(0,0,0,0.8)',
                            borderRadius: 10,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 30,
                                color:
                                    totalSetsWon('challenger1', matchData.tournament_match_sets) >
                                        totalSetsWon('challenger2', matchData.tournament_match_sets) && '#86d068',
                                fontWeight: 'bold',
                            }}
                        >
                            {totalSetsWon('challenger1', matchData.tournament_match_sets) || 0}
                        </Text>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <div style={{ textAlign: 'right', display: 'flex', flex: 1, alignItems: 'center', paddingLeft: 5 }}>
                        {matchData && matchData.challenger2_country && (
                            <CircleFlag country={getCountryName(matchData.challenger2_country) || 'Unknown'} size="medium" />
                        )}
                        <Text
                            style={{
                                fontSize: 30,
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                                marginRight: 10,
                                textTransform: 'uppercase',
                            }}
                        >
                            {matchData.challenger2_name}
                        </Text>
                    </div>
                    {matchData.tournament_match_sets &&
                        matchData.tournament_match_sets.map((set, index) => {
                            return (
                                <div key={index} style={{ padding: 5, width: 30, textAlign: 'center' }}>
                                    <Text
                                        style={{
                                            fontSize: 30,

                                            color: set.challenger2_score === set.challenger2_race_to && '#86d068',
                                            fontWeight:
                                                set.challenger2_score === set.challenger2_race_to || set.challenger2_score > set.challenger1_score
                                                    ? 'bold'
                                                    : 'normal',
                                        }}
                                    >
                                        {set.challenger2_score}
                                    </Text>
                                </div>
                            );
                        })}
                    <div
                        style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            textAlign: 'center',
                            backgroundColor: 'rgba(0,0,0,0.8)',
                            borderRadius: 10,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 30,
                                color:
                                    totalSetsWon('challenger2', matchData.tournament_match_sets) >
                                        totalSetsWon('challenger1', matchData.tournament_match_sets) && '#86d068',
                                fontWeight: 'bold',
                            }}
                        >
                            {totalSetsWon('challenger2', matchData.tournament_match_sets) || 0}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
}
