import React, { useState, useEffect } from 'react';
import { getTwitchUserStream, getYouTubeThumbnailURL } from '../../../lib/live';

export default function LiveThumbnail(props) {
	const { channel, type } = props;
	const [ thumbnail, setThumbnail ] = useState();

	useEffect(() => {
		async function getStream(channel) {
			let stream;
			let thumbStr;
			let streamData;

			switch(type) {
				case "YOUTUBE":
					thumbStr = getYouTubeThumbnailURL(channel);
				break;

				case "TWITCH":
					stream = await getTwitchUserStream(channel);
					streamData = stream.data.data[0];

					if (streamData) {
						// stream is live
						thumbStr = streamData.thumbnail_url
							.replace(/\{width\}/g, 300)
							.replace(/\{height\}/g, 170);
					}
				break;

				case "FACEBOOK":

				break;
			}

			setThumbnail(thumbStr);
		}

		getStream(channel);
	}, []);

	return (
		<div>
			{thumbnail && (
				<div
					style={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						borderBottom: '1px solid #e6e7ea',
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
						overflow: 'hidden'
					}}
				>
					<img alt="Logo" src={thumbnail} style={{ width: '100%'}} />
				</div>
			)}
		</div>
	);
}
