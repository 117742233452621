export function formatPlayerName(player) {
    if (player && player.name) {
        let displayName;
        const nameSplit = player.name.split(' ');
        const firstName = nameSplit && nameSplit[0];
        const lastName = nameSplit && nameSplit[1];
        const nickname = player.nickname;

        if (firstName && lastName && nickname) {
            displayName = `${firstName} "${player.nickname}" ${lastName}`;
        } else if (firstName && !lastName && nickname) {
            displayName = `${firstName} "${player.nickname}"`;
        } else {
            displayName = player.name;
        }
        return displayName.toString();
    } else {
        return player.toString();
    }
}
