import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, PageHeader, Icon, Row, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import SectionHeader from '../../../components/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper';
import { displayEnumType } from '../../../utils';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_LEAGUE_DIVISION_SCHEDULE } from '../data/queries';
import { DELETE_LEAGUE_MATCH_MUTATION } from '../data/mutations';
import _ from 'lodash';
import AddTeamMatchModal from '../AddTeamMatchModal';
import AddSingleMatchModal from '../AddSingleMatchModal';
import ScheduledMatchesList from './ScheduledMatchesList';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMDivisionScheduledMatchesEdit(props) {
    const { authState, league, division } = props;
    console.log(props);

    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedMatchId, setSelectedMatchId] = useState();
    const [teamMatchModalVisible, setTeamMatchModalVisible] = useState(false);
    const [singleMatchModalVisible, setSingleMatchModalVisible] = useState(false);
    const [deleteLeagueMatch] = useMutation(DELETE_LEAGUE_MATCH_MUTATION);

    function handleSelected(selected) {
        console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedMatchId(selected);
        setSingleMatchModalVisible(true);
    }

    function handleEditSelectedTeam(selected) {
        // console.log(selected);
        setSelectedMatchId(selected);
        setTeamMatchModalVisible(true);
    }

    const hasSelected = selectedKeys && selectedKeys.length > 0;

    function handleTeamMatchModalOk(e) {
        console.log(e);
        setTeamMatchModalVisible(false);
    }

    function handleTeamMatchModalCancel(e) {
        setTeamMatchModalVisible(false);
    }

    function handleAddTeamMatch() {
        setTeamMatchModalVisible(true);
        setSelectedMatchId(null);
    }

    function handleSingleMatchModalOk(e) {
        console.log(e);
        setSingleMatchModalVisible(false);
    }

    function handleSingleMatchModalCancel(e) {
        setSingleMatchModalVisible(false);
    }

    function handleAddSinglesMatch() {
        setSingleMatchModalVisible(true);
        setSelectedMatchId(null);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={displayEnumType(props.match.params.schedule)}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={props.title}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/schedule/${props.match.params.schedule}`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="leagueManager"
            >
                <Button type="secondary" size="large" onClick={handleAddTeamMatch}>
                    <Icon type="plus" /> Create Match
                </Button>
                {/* <Button size="large" type="ghost" ghost onClick={() => setMatchModalVisible(true)}>
                    <Icon type="plus" /> Add Week
                </Button> */}
                {/* <Button size="large" type="danger" ghost>
					<Icon type="delete" /> Delete
				</Button> */}
            </SectionHeader>
            {/* <PageHeader
				onBack={() => props.history.push(`/tournament-builder/${props.tournament.slug}/venues`)}
				backIcon={<Icon type="arrow-left" style={{ color: props.theme === 'dark' ? '#fff' : '#000' }} />}
				title={
					<Text style={{ color: props.theme === 'dark' ? '#fff' : '#000' }}>
						{_.startCase(_.toLower(props.match.params.venue.replace('-', ' '))).toUpperCase()}
					</Text>
				}
				style={{
					background:
						props.theme === 'dark'
							? 'linear-gradient(180deg, #07101f, #14243b)'
							: 'linear-gradient(#fff, #fafafa)',
					paddingTop: 24,
					paddingLeft: 40,
					paddingRight: 40,
					paddingBottom: 24,
					display: 'flex',
					alignItems: 'center'
				}}
			/> */}
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_LEAGUE_DIVISION_SCHEDULE}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ division_id: division.id }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                        const schedule = data.league_schedules && data.league_schedules[data.league_schedules.length - 1];
                        const matches = schedule && schedule.league_matches;
                        console.log(matches);
                        // const scheduledMatches = matches.filter((match) => match.scheduled_id !== null);
                        // const unscheduledMatches = matches.filter((match) => match.scheduled_id === null);

                        const weeks = _(matches)
                            .groupBy((item) => {
                                return moment(item.scheduled_time).startOf('week').format('llll');
                            })
                            .map((value, key) => ({ scheduled_time: key, matches: value }))
                            .value();

                        console.log(weeks);

                        return (
                            <React.Fragment>
                                <React.Fragment>
                                    {/* <pre>{JSON.stringify(data.venues, null, 4)}</pre> */}
                                    {weeks.map((week, index) => {
                                        return (
                                            <Row gutter={24} key={index}>
                                                <Col>
                                                    <Card title={`Week ${index + 1} - ${moment(week.scheduled_time).format('ll')}`} bodyStyle={{ padding: 0 }}>
                                                        <ScheduledMatchesList
                                                            {...props}
                                                            onSelected={handleSelected}
                                                            onEditSelected={handleEditSelectedTeam}
                                                            onDelete={(record) => {
                                                                deleteLeagueMatch({
                                                                    variables: {
                                                                        league_id: league.id,
                                                                        id: record.id,
                                                                    },
                                                                    notifyOnNetworkStatusChange: true,
                                                                    awaitRefetchQueries: true,
                                                                    refetchQueries: [
                                                                        {
                                                                            query: GET_LEAGUE_DIVISION_SCHEDULE,
                                                                            variables: {
                                                                                division_id: division.id,
                                                                            },
                                                                        },
                                                                    ],
                                                                })
                                                                    .then((data) => {
                                                                        console.log(data);
                                                                        message.success('Match deleted');
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                        message.info('There was an error', error);
                                                                    });
                                                            }}
                                                            matches={week.matches.map((item, index) => {
                                                                return {
                                                                    ...item,
                                                                    key: index,
                                                                    index: index + 1,
                                                                };
                                                            })}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </React.Fragment>
                                <AddTeamMatchModal
                                    {...props}
                                    selectedMatchId={selectedMatchId}
                                    userID={authState.user.id}
                                    leagueID={league.id}
                                    divisionID={division.id}
                                    scheduleID={schedule.id}
                                    modalVisible={teamMatchModalVisible}
                                    onModalOk={handleTeamMatchModalOk}
                                    onModalCancel={handleTeamMatchModalCancel}
                                />

                                {/* <AddSingleMatchModal
                                    {...props}
                                    selectedMatchId={selectedMatchId}
                                    userID={authState.user.id}
                                    leagueID={league.id}
                                    divisionID={division.id}
                                    modalVisible={singleMatchModalVisible}
                                    onModalOk={handleSingleMatchModalOk}
                                    onModalCancel={handleSingleMatchModalCancel}
                                /> */}
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );

    // return (
    // 	<Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
    // 		<Form.List name="users">
    // 			{(fields, { add, remove }) => (
    // 				<React.Fragment>
    // 					{fields.map((field, index) => (
    // 						<React.Fragment key={index}>
    // 							<Form.Item
    // 								{...field}
    // 								name={[ field.name, 'first' ]}
    // 								fieldKey={[ field.fieldKey, 'first' ]}
    // 								rules={[ { required: true, message: 'Missing first name' } ]}
    // 							>
    // 								<Input placeholder="First Name" />
    // 							</Form.Item>
    // 							<Form.Item
    // 								{...field}
    // 								name={[ field.name, 'last' ]}
    // 								fieldKey={[ field.fieldKey, 'last' ]}
    // 								rules={[ { required: true, message: 'Missing last name' } ]}
    // 							>
    // 								<Input placeholder="Last Name" />
    // 							</Form.Item>
    // 							<MinusCircleOutlined onClick={() => remove(field.name)} />
    // 						</React.Fragment>
    // 					))}
    // 					<Form.Item>
    // 						<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
    // 							Add field
    // 						</Button>
    // 					</Form.Item>
    // 				</React.Fragment>
    // 			)}
    // 		</Form.List>
    // 		<Form.Item>
    // 			<Button type="primary" htmlType="submit">
    // 				Submit
    // 			</Button>
    // 		</Form.Item>
    // 	</Form>
    // );
}

export default withRouter(LMDivisionScheduledMatchesEdit);
