import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import { Modal, Result, Avatar, Empty, Icon, List, Button, Row, Col, Typography } from 'antd';
import axios from 'axios';

import iconManagedOnline from '../../assets/img/dpl/icon-managed-online.png';
import iconIndividualsTeams from '../../assets/img/dpl/icon-individuals-teams.png';
import iconSocialNetworking from '../../assets/img/dpl/icon-social-networking.png';
// import headerLogo from '../../assets/img/dpl/header-logo.png';
import headerApp from '../../assets/img/dpl/header-apps2.png';
import featureApp from '../../assets/img/dpl/feature-app2.png';
import featureRealtimeScoring from '../../assets/img/dpl/feature-realtime-scoring.png';
import featureLiveBrackets from '../../assets/img/dpl/feature-live-brackets.png';
import featureMultipleGames from '../../assets/img/dpl/feature-multiple-games2.png';
import featureSponsoredLocations from '../../assets/img/dpl/feature-sponsored-locations2.png';
import Fade from 'react-reveal/Fade';
import dplLogoText from '../../assets/img/dpl/dpl-logo-text-white.png';
import * as routes from '../../config/routes';
import './dpl-marketing.css';
// import '../../assets/css/materialize.min.css';
// import * as materialize from '../../assets/css/materialize.min.css';

const { Title, Text } = Typography;

const styles = {
    text: {
        fontSize: 15,
        lineHeight: '30px',
        margin: '20px auto',
        maxWidth: 900,
    },
};

function ValidateEmail(address) {
    const pattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if (address.match(pattern)) {
        return true;
    }
    return false;
}

export default class DPLMarketing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            error: '',
            submitting: false,
            saved: false,
        };
    }

    handleChange = (event) => {
        if (this.state.emailAddress.length === 0) {
            this.setState({ emailAddress: event.target.value, error: '' });
            return;
        }
        if (ValidateEmail(this.state.emailAddress) === true) {
            this.setState({ emailAddress: event.target.value, error: '' });
        } else {
            this.setState({ emailAddress: event.target.value, error: this.state.error });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (ValidateEmail(this.state.emailAddress) === true) {
            // if (mixpanel) {
            //   mixpanel.track('Launch subscriber added');
            // }

            // gtag('event', 'sign_up', {
            //   event_category: 'engagement',
            //   event_label: 'launch_subscriber_added',
            // });

            this.setState({
                error: '',
                submitting: true,
            });
            axios
                .post('https://dpl-marketing-site.firebaseapp.com/subscribe-user', {
                    email: this.state.emailAddress,
                    // name: ''
                })
                .then((response) => {
                    this.setState({ saved: true, message: response.data.message });
                })
                .catch((error) => {
                    let message;
                    if (error.toString().match(/400/g) != null) {
                        // message = "Bad Request - Often missing a required parameter";
                        message = 'Error - Email address already exists';
                    } else if (error.toString().match(/401/g) != null) {
                        message = 'Unauthorized - No valid API key provided';
                    } else if (error.toString().match(/402/g) != null) {
                        message = 'Request Failed - Parameters were valid but request failed';
                    } else if (error.toString().match(/404/g) != null) {
                        message = 'Not Found - The requested item doesn’t exist';
                    } else if (error.toString().match(/500/g) != null) {
                        message = 'Server Errors - something is wrong on Mailgun’s end';
                    } else if (error.toString().match(/502/g) != null) {
                        message = 'Server Errors - something is wrong on Mailgun’s end';
                    } else if (error.toString().match(/503/g) != null) {
                        message = 'Server Errors - something is wrong on Mailgun’s end';
                    } else if (error.toString().match(/504/g) != null) {
                        message = 'Server Errors - something is wrong on Mailgun’s end';
                    }
                    this.setState({ error: message, submitting: false });
                });
        } else if (this.state.emailAddress.length === 0) {
            this.setState({ error: 'Please enter a valid email address', submitting: false });
        } else {
            this.setState({
                error: `"${this.state.emailAddress}" is not a valid email address`,
                submitting: false,
            });
        }

        // console.log('A name was submitted: ' + this.state.emailAddress);

        // axios.get('https://api.mailgun.net/v3/mg.digitalpoolleague.com/address/validate', {
        //   params: {
        //     address: this.state.emailAddress,
        //     api_key: 'pubkey-5121a4bbe3b1461507eae4f68a1156ff'
        //   }
        // })
        // .then(function (response) {
        //   console.log(response);
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
    };

    render() {
        return (
            <div className="dpl">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css" />
                {/* <link rel="stylesheet" href={materialize} /> */}
                <div className="header-container animated fadeIn">
                    <div className="container">
                        <Fade up distance="50px" delay={100}>
                            <img src={dplLogoText} width={300} alt="" style={{ marginLeft: -50 }} />
                        </Fade>
                    </div>
                    <div className="row container">
                        <div className="col s12 m12 l12 text-container">
                            <h3 style={{ marginTop: 30, marginBottom: 20, color: '#fff', lineHeight: '1.3em' }}>
                                Play what you want.
                                <br />
                                When you want.
                                <br />
                                Where you want.
                            </h3>
                            <div className="notify-container">
                                <p style={{ fontSize: 18, marginBottom: 25 }}>
                                    Digital Pool League was created to be the most versatile pool league in the world. Join a division or start one today and
                                    see what it's all about!
                                </p>

                                <Link to={routes.DIGITALPOOL.REGISTER}>
                                    <Button type="primary" size="large">
                                        Create Free Account
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="header-app">
                            <img alt="" src={headerApp} width={1100} />
                        </div>
                    </div>
                </div>

                <div className="content-container">
                    <div className="row content-block about centered dark-grey-bg reverse-shadow intro">
                        <div className="col s12 m12 l12 centered">
                            <Title level={2} style={{ color: '#fff', margin: '20px 0px', padding: 0, fontWeight: 400 }}>
                                A new kind of pool league experience
                            </Title>
                        </div>
                    </div>
                    <div className="row content-block centered medium-grey-bg shadow intro">
                        <div className="container col-3">
                            <div className="col s12 m12 l4 intro-block">
                                <img alt="" src={iconManagedOnline} height={67} width={67} className="intro-icon" />
                                <h3>100% Managed Online</h3>
                                <p>
                                    Individual matches to playoff tournaments will all be scored through the DigitalPool App. This allows us to update standings
                                    in real time and give you access to accurate statistics.
                                </p>
                            </div>
                            <div className="col s12 m12 l4 intro-block">
                                <img alt="" src={iconIndividualsTeams} height={67} width={67} className="intro-icon" />
                                <h3>Individuals & Teams</h3>
                                <p>
                                    The first league to offer both individual and team formats for all games and skill levels. Compete on a team or on your own
                                    to qualify for Regional, and National events.
                                </p>
                            </div>
                            <div className="col s12 m12 l4 intro-block">
                                <img alt="" src={iconSocialNetworking} height={67} width={67} className="intro-icon" />
                                <h3>Social Networking</h3>
                                <p>
                                    Stay connected with your friends, teammates and the larger pool community using the official DPL app. Instant messaging,
                                    news feed, player profiles, and more.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row content-block why centered dark-grey-bg" style={{ paddingBottom: 30 }}>
                        <div className="col s12 m12 l12 centered">
                            <h5 className="orange-text">How is it different?</h5>
                            <h3 style={{ color: '#fff' }}>DPL gives you the freedom to design your own division and play any game you want!</h3>
                            <p style={{ color: '#9aa9bd' }}>
                                Form a division exclusively for your friends, neighbors, family, or co-workers. Play matches at times most convenient for you.
                                Play at your local bar, favorite pool hall, or even in the comforts of your own home. Digital Pool League gives you the
                                opportunity to play what you want, when you want, and where you want.
                            </p>
                        </div>
                    </div>
                    <div className="row content-block ribbon centered blue-gradient-bg">
                        <div className="col s12 m12 l12 centered">
                            <h2>DPL is currently in the testing phase and will be launching in 2019.</h2>
                        </div>
                    </div>
                    <div className="row content-block feature how white-bg">
                        <div className="container">
                            <h6 className="feature-label">League</h6>
                            <h2 className="feature-title">General Information</h2>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                }}
                            >
                                <Row gutter={24}>
                                    <Col xs={24} sm={24}>
                                        <Title level={4}>How does it work?</Title>
                                        <div className="text-container">
                                            <div className="col">
                                                Players join divisions according to their location and game they want to play. Once in a division, players
                                                challenge other players to play matches. You can challenge a specific player or send out a challenge to anyone
                                                who can play at a certain time or place. You can even challenge players outside your division. These matches can
                                                be played wherever and whenever you like. DPL also gives you the freedom to negotiate your own weight.
                                            </div>
                                            <div className="col">
                                                At the end of your division’s session, the top 50% of players will qualify for the Divisional Playoff
                                                Tournament. This tournament is also open to the League Liaison and the player, or players depending on division
                                                size, who attended the most matches. The top 25% of players from this tournament will qualify for the National
                                                Championships.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-container">
                                    <div className="col">
                                        <Title level={4}>Who is it for?</Title>
                                        <Text>Digital Pool League has something for everyone, regardless of skill level, age, or gender.</Text>
                                    </div>
                                    <div className="col">
                                        <Title level={4}>How much does it cost?</Title>
                                        <Text>
                                            $35 Annual membership fee
                                            <br />
                                            $10 each match you play
                                        </Text>
                                    </div>
                                    <div className="col">
                                        <Title level={4}>What games can I play?</Title>
                                        <Text>8-Ball, 9-Ball, 10-Ball, One Pocket, Straight Pool and more!</Text>
                                    </div>
                                    <div className="col">
                                        <Title level={4}>How can I sign up?</Title>

                                        <Link to={routes.DIGITALPOOL.REGISTER}>Create a free DigitalPool account</Link>
                                        <Text style={{ marginLeft: 5 }}>to participate in the Digital Pool League.</Text>
                                    </div>
                                </div>

                                {/* <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                  gutterBottom
                  style={{ paddingTop: 50 }}
                >
                  Interested in becoming a sponsor? <a href="#">Contact us</a>
                </Typography> */}
                            </div>
                        </div>
                    </div>
                    <div className="row content-block feature light-blue-bg">
                        <div className="container flex">
                            <div className="col">
                                <img alt="" className="app" src={featureApp} width="100%" />
                            </div>
                            <div className="col">
                                <h6 className="feature-label">App</h6>
                                <h2 className="feature-title">Digital Pool League is the first league to be managed 100% digitally.</h2>
                                <div className="text-container">
                                    <p>
                                        The DPL app is everything you need. Keep live scoring, find matches being played, challenge other players anywhere DPL
                                        is played, find sponsoring locations, manage your team and RSVP for matches, easily access stats and standings, and even
                                        run your own tournaments.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content-block feature white-bg">
                        <div className="container flex">
                            <div className="col">
                                <h6 className="feature-label">Feature</h6>
                                <h2 className="feature-title">Real-time scoring</h2>
                                <div className="text-container">
                                    <p>
                                        Follow the action as it happens. All matches from session matches to Divisional Playoff Tournament matches to National
                                        Championship matches will be scored in real-time through the DPL app.
                                    </p>
                                </div>
                            </div>
                            <div className="col">
                                <img alt="" className="realtime-scoring" src={featureRealtimeScoring} width="100%" />
                            </div>
                        </div>
                    </div>
                    <div className="row content-block feature light-blue-bg">
                        <div className="container flex">
                            <div className="col">
                                <img alt="" className="live-brackets" src={featureLiveBrackets} width="100%" />
                            </div>
                            <div className="col">
                                <h6 className="feature-label">App</h6>
                                <h2 className="feature-title">Live brackets</h2>
                                <div className="text-container">
                                    <p>
                                        Tournament brackets that keep you up to date on scores, table assignments, and match times. Our tournament brackets are
                                        a great option for anyone who currently runs a tournament or even someone who wants to try one for them self.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content-block feature white-bg">
                        <div className="container flex">
                            <div className="col">
                                <h6 className="feature-label">Feature</h6>
                                <h2 className="feature-title">Multiple game types</h2>
                                <div className="text-container">
                                    <p>
                                        DPL was designed to give you the freedom to choose the games and formats you want to play. Whether it’s playing
                                        individual 8 Ball, scotch doubles 10-Ball, or even a Mosconi Cup style Saratoga, we’ll help you grow your favorite games
                                        to a National Event.
                                    </p>
                                </div>
                            </div>
                            <div className="col">
                                <img alt="" className="multiple-game-types" src={featureMultipleGames} width="100%" />
                            </div>
                        </div>
                    </div>
                    <div className="row content-block feature light-blue-bg">
                        <div className="container flex">
                            <div className="col">
                                <img alt="" className="sponsored-locations" src={featureSponsoredLocations} width="100%" />
                            </div>
                            <div className="col">
                                <h6 className="feature-label">Location</h6>
                                <h2 className="feature-title">Sponsored locations</h2>
                                <div className="text-container">
                                    <p>
                                        Sponsor the DPL by giving table time or discounts to members and boost your business. We offer incentives to players who
                                        check-in and simply attend matches. Receive free ad space and access to stats on which players have a following. Chat
                                        with players to invite them to your location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content-block ribbon centered blue-gradient-bg">
                        <div className="col s12 m12 l12 centered">
                            <h2>
                                Interested in becoming a sponsor or affiliate?{' '}
                                <a href="mailto:info@digitalpoolleague.com?subject=Sponsorship Opportunities" target="_blank" className="contact-link">
                                    Contact Us
                                </a>
                            </h2>
                        </div>
                    </div>
                    <div className="row content-block signup dark-grey-bg reverse-shadow">
                        <div className="container shadow">
                            <div className="col s12 m12 l6">
                                <h3 className="orange-text">Launching in 2019</h3>
                                <h2>Be the first to get notified</h2>
                            </div>
                            <div className="col s12 m12 l6 notify-form">
                                <form onSubmit={this.handleSubmit} className={this.state.saved === false ? '' : 'hidden'}>
                                    <div className={this.state.error ? 'fields animated shake' : 'fields'}>
                                        <input
                                            type="text"
                                            name="email-address"
                                            placeholder="Email address"
                                            className={this.state.error ? 'input-field error' : 'input-field'}
                                            value={this.state.emailAddress}
                                            onChange={this.handleChange}
                                        />
                                        <input
                                            type="submit"
                                            value={this.state.submitting === false ? 'Notify me' : 'Saving...'}
                                            className={this.state.submitting === false ? 'input-button orange' : 'input-button orange disabled'}
                                        />
                                    </div>
                                    {this.state.error ? <div className="error">{this.state.error}</div> : <div />}
                                </form>
                                <div className={this.state.saved === true ? 'success-message animated flipInX' : 'hidden'}>
                                    You've been added to the list. Thank you!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content-block footer dark-bg  intro">
                        <div className="container">
                            <div className="col s8 m8 l8">
                                <p>&copy; {new Date().getFullYear()} Digital Pool League, Inc. - All Rights Reserved.</p>
                            </div>
                            <div className="col s4 m4 l4">
                                <p className="align-right">
                                    <Link to="/terms" className="footer-link">
                                        Terms of service
                                    </Link>
                                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                    <Link to="/privacy" className="footer-link">
                                        Privacy policy
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
