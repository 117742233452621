import React from 'react';
import { Typography, Alert } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';

const { Text, Title } = Typography;

export default function EventNameForm(props) {
    const initialFormValues = {};

    return (
        <div
            style={{
                marginTop: 20,
                marginBottom: 20,
            }}
        >
            <div style={{ marginBottom: 20, marginRight: 20 }}>
                <Alert
                    message="In Progress"
                    description="Once the ghost game is in progress, you can only edit the event name and payment link."
                    type="warning"
                    closable
                    showIcon
                />
            </div>
            <Title level={4}>Ghost Event Settings</Title>
            <Formik
                initialValues={initialFormValues}
                // validationSchema={schema}
                onSubmit={(data) => {}}
                render={(values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValidating) => {
                    return (
                        <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                            <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                <Input label="Name" name="name" size="large" placeholder="Event Name" />
                            </FormItem>
                            <FormItem label="Payment Link" name="payment_link" required hasFeedback showValidateSuccess>
                                <Input label="Name" name="name" size="large" placeholder="Payment Link" />
                            </FormItem>
                            <SubmitButton size="large" type="primary" disabled={false} loading={false}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text style={{ color: '#fff' }}>Save</Text>
                                </div>
                            </SubmitButton>
                        </Form>
                    );
                }}
            />
        </div>
    );
}
