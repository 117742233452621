import React, { useEffect } from 'react';
import Flag from '../../../../components/Flag';
import CircleFlag from '../../../../components/CircleFlag';
import { Avatar, Typography } from 'antd';
import { getGameType, getCountryShortCode, displayEnumType } from '../../../../utils';
import BallTray from './BallTray';
import blankTemplateImg from '../../../../assets/img/tournament-builder/templates/apf/blank-template.png';

const { Text, Title } = Typography;

export default function APFStreamOverlayTemplate(props) {
    const { authState, matchData, chromaColor, tournamentData, showScores, theme, subscribeToMore, subscribeToNewData } = props;
    const challenger1 = (matchData && matchData.challenger1) || 0;
    const challenger2 = (matchData && matchData.challenger2) || 0;
    const challenger1Race = matchData && matchData.challenger1_race_to;
    const challenger2Race = matchData && matchData.challenger2_race_to;
    const challenger1Country = getCountryShortCode(matchData && matchData.challenger1_country);
    const challenger2Country = getCountryShortCode(matchData && matchData.challenger2_country);

    console.log(chromaColor);

    const styles = {
        smallAvatar: {
            width: 20,
            height: 20,
        },
        overlay: {
            // borderRadius: '10px 10px 0px 0px',
            position: 'absolute',
            top: 0,
            background: `url(${blankTemplateImg}) no-repeat center center fixed`,
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
            color: '#fff',
            fontFamily: 'gill_sans_mt_condensedregular',
        },
    };

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    function getMatchRace() {
        if (challenger1Race && challenger1Race === challenger2Race && challenger2Race) {
            return challenger1Race;
        } else if (challenger1Race !== challenger2Race) {
            return `${challenger1Race}/${challenger2Race}`;
        } else {
            return '';
        }
    }

    return (
        <div style={styles.overlay}>
            <div
                className="score-data"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 135,
                    width: '100%',
                    paddingTop: 0,
                    paddingBottom: 0,
                    justifyContent: 'center',
                }}
            >
                <div
                    className="player-data"
                    style={{
                        position: 'absolute',
                        right: 1160,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Title level={4} style={{ margin: 0, paddingRight: 20, color: '#fff', fontSize: 30, textTransform: 'uppercase' }}>
                            {((matchData && matchData.challenger1_name && matchData.challenger1_skill_level) || (challenger1 && challenger1.skill_level)) &&
                            matchData.challenger1_race_to &&
                            tournamentData.show_player_skill_levels === true &&
                            tournamentData.show_player_races === true
                                ? matchData &&
                                  `${matchData.challenger1_name} ${matchData.challenger1_skill_level || (challenger1 && challenger1.skill_level)} (${
                                      matchData.challenger1_race_to
                                  })`
                                : matchData && matchData.challenger1_name && matchData.challenger1_race_to && tournamentData.show_player_races === true
                                ? matchData && `${matchData.challenger1_name} (${matchData.challenger1_race_to})`
                                : matchData && `${matchData.challenger1_name}`}
                        </Title>

                        {matchData && matchData.challenger1_is_playing === true ? (
                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                    borderTop: '13px solid transparent',
                                    borderBottom: '13px solid transparent',
                                    borderRight: '13px solid #fff',
                                    position: 'relative',
                                    right: 10,
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    width: 13,
                                }}
                            />
                        )}

                        {challenger1Country && (
                            <div style={{ paddingRight: 10 }}>
                                <Flag
                                    name={challenger1Country || 'Unknown'}
                                    format="png"
                                    pngSize={48}
                                    shiny={false}
                                    alt="Flag"
                                    className="flag"
                                    basePath="/images/flags"
                                />
                                {/* <CircleFlag country={challenger1Country} size="large" left={0} /> */}
                            </div>
                        )}
                    </div>
                </div>

                <div className="player-score" style={{ position: 'absolute', left: 768, width: 55, textAlign: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {showScores !== false ? (
                            <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 40, textTransform: 'uppercase' }}>
                                {matchData && matchData.challenger1_score ? matchData.challenger1_score : 0}
                            </Title>
                        ) : (
                            <div style={{ height: 30 }} />
                        )}
                    </div>
                </div>

                <div
                    className="match-race"
                    style={{
                        position: 'absolute',
                        left: 1000,
                        width: 80,
                        textAlign: 'left',
                    }}
                >
                    <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 30, textTransform: 'uppercase' }}>
                        {getMatchRace()}
                    </Title>
                </div>

                <div className="opponent-score" style={{ position: 'absolute', left: 1095, width: 55, textAlign: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {showScores !== false ? (
                            <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 40, textTransform: 'uppercase', position: 'relative' }}>
                                {matchData && matchData.challenger2_score ? matchData.challenger2_score : 0}
                            </Title>
                        ) : (
                            <div style={{ height: 30 }} />
                        )}
                    </div>
                </div>

                <div className="opponent-data" style={{ position: 'absolute', left: 1160 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {challenger2Country && (
                            <div style={{ paddingLeft: 10 }}>
                                <Flag
                                    name={challenger2Country || 'Unknown'}
                                    format="png"
                                    pngSize={48}
                                    shiny={false}
                                    alt="Flag"
                                    className="flag"
                                    basePath="/images/flags"
                                />
                                {/* <CircleFlag country={challenger2Country} size="large" left={0} /> */}
                            </div>
                        )}

                        {matchData && matchData.challenger2_is_playing === true ? (
                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                    borderTop: '13px solid transparent',
                                    borderBottom: '13px solid transparent',
                                    borderLeft: '13px solid #fff',
                                    position: 'relative',
                                    left: 10,
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    width: 13,
                                }}
                            />
                        )}

                        <Title level={4} style={{ margin: 0, paddingLeft: 15, color: '#fff', fontSize: 30, textTransform: 'uppercase' }}>
                            {((matchData && matchData.challenger2_name && matchData.challenger2_skill_level) || (challenger1 && challenger2.skill_level)) &&
                            matchData.challenger2_race_to &&
                            tournamentData.show_player_skill_levels === true &&
                            tournamentData.show_player_races === true
                                ? matchData &&
                                  `${matchData.challenger2_name} ${matchData.challenger2_skill_level || (challenger1 && challenger2.skill_level)} (${
                                      matchData.challenger2_race_to
                                  })`
                                : matchData && matchData.challenger2_name && matchData.challenger2_race_to && tournamentData.show_player_races === true
                                ? matchData && `${matchData.challenger2_name} (${matchData.challenger2_race_to})`
                                : matchData && `${matchData.challenger2_name}`}
                        </Title>
                    </div>
                </div>
            </div>
            <div
                className="ball-tray"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 70,
                    width: '100%',
                    paddingTop: 0,
                    paddingBottom: 0,
                    justifyContent: 'center',
                }}
            >
                <BallTray matchData={matchData} tournamentData={tournamentData} {...props} />
            </div>
        </div>
    );
}
