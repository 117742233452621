import React, { useState, useRef, useEffect } from 'react';
import { Table, Typography, Button, Icon, Modal, Input, Tooltip } from 'antd';
import CircleFlag from '../../components/CircleFlag';
import { getCountryName } from '../../utils';
import _ from 'lodash';
import verifiedIcon from '../../assets/img/verified-icon.svg';
import Highlighter from 'react-highlight-words';
import geo from 'countrycitystatejson';
import moment from 'moment';

const { Text } = Typography;
const { confirm } = Modal;

export default function PlayerTable(props) {
    const { players, league } = props;
    console.log(players);
    const { onSelected, onEditSelected, selectedRowKeys, onDelete } = props;
    const [selectedKeys, setSelectedKeys] = useState(selectedRowKeys || []);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const countries = geo.getCountries();
    const countryPhones = countries
        .sort((a, b) => {
            return a.phone.localeCompare(b.phone, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        })
        .filter((country) => {
            return country.shortName !== 'UM';
        })
        .map((country) => {
            return {
                phone: country.phone,
                emoji: country.emoji,
                shortName: country.shortName,
            };
        });

    useEffect(() => {
        setSelectedKeys(selectedRowKeys);
    }, [selectedRowKeys]);

    function onSelectChange(selectedRowKeys, selectedRows) {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedKeys(selectedRowKeys);
        onSelected(selectedRowKeys, selectedRows);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const rowSelection = {
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 60,

            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return (
                    a.name &&
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                const nameSplit = record.name && record.name.split(' ');
                const firstName = nameSplit && nameSplit[0];
                const lastName = nameSplit && nameSplit[1];
                const nickname = record.nickname;
                let displayName;

                if (firstName && lastName && nickname) {
                    displayName = `${firstName} "${record.nickname}" ${lastName}`;
                } else if (firstName && !lastName && nickname) {
                    displayName = `${firstName} "${record.nickname}"`;
                } else {
                    displayName = record.name;
                }

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {record.country ? (
                                <React.Fragment>
                                    {record.country && <CircleFlag country={record.country} left={0} />}
                                    <Text style={{ fontWeight: 600 }}>{displayName}</Text>
                                </React.Fragment>
                            ) : (
                                <Text style={{ fontWeight: 600 }}>{displayName}</Text>
                            )}
                        </div>
                        {record.user_id && <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />}
                    </div>
                );
            },
        },

        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            sorter: (a, b) => {
                return a.country && a.country.localeCompare(b.country);
            },
            render: (text, record) => {
                const country = record.country ? getCountryName(record.country) : text;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {country ? _.startCase(country) : '-'}
                    </div>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => {
                return a.email && a.email.localeCompare(b.email);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Country Phone',
            dataIndex: 'country_phone',
            key: 'country_phone',
            sorter: (a, b) => {
                return a.country_phone && a.country_phone.localeCompare(b.country_phone);
            },
            render: (text, record) => {
                const phonePrefix = countryPhones.filter((country, index) => {
                    if (record.country_phone === `${country.shortName}-${country.phone}`) {
                        return country;
                    }
                })[0];
                return <React.Fragment>{record.country_phone ? `${phonePrefix.emoji} +${phonePrefix.phone} ${phonePrefix.shortName}` : '-'}</React.Fragment>;
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            sorter: (a, b) => {
                return a.phone_number && a.phone_number.localeCompare(b.phone_number);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Divisions',
            dataIndex: 'divisions',
            sorter: (a, b) => {
                return a.divisions.localeCompare(b.divisions, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => (
                <div className="ellipsis" style={{ maxWidth: 150 }}>
                    <Text>{text || '-'}</Text>
                </div>
            ),
        },
        {
            title: 'Teams',
            dataIndex: 'teams',
            sorter: (a, b) => {
                return a.teams.localeCompare(b.teams, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => (
                <div className="ellipsis" style={{ maxWidth: 150 }}>
                    <Text>{text || '-'}</Text>
                </div>
            ),
        },

        // {
        //     title: 'Special Group',
        //     dataIndex: 'special_group',
        //     sorter: (a, b) => {
        //         return a.special_group.localeCompare(b.special_group, undefined, {
        //             numeric: true,
        //             sensitivity: 'base',
        //         });
        //     },
        //     render: (text, record) => <Text>{text || '-'}</Text>,
        // },
        {
            title: 'Skill Level',
            dataIndex: 'skill_level',
            sorter: (a, b) => a.skill_level - b.skill_level,
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Fargo ID',
            dataIndex: 'fargo_id',
            sorter: (a, b) => a.fargo_id - b.fargo_id,
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        // {
        //     title: 'Race to',
        //     dataIndex: 'race_to',
        //     sorter: (a, b) => a.race_to - b.race_to,
        //     render: (text, record) => <Text>{text || '-'}</Text>,
        // },
        {
            title: 'Dues paid',
            dataIndex: 'league_dues_paid',
            sorter: (a, b) => a.league_dues_paid - b.league_dues_paid,
            render: (text, record) => <Text>{record.league_dues_paid === true ? 'Yes' : 'No' || '-'}</Text>,
        },
        // {
        // 	title: 'Payment date',
        // 	dataIndex: 'last_payment_date',
        // 	sorter: (a, b) => a.last_payment_date - b.last_payment_date,
        // 	render: (text, record) => <Text>{text ? moment(text).format('ll') : '-'}</Text>
        // },
        {
            title: 'Status',
            dataIndex: 'Status',
            sorter: (a, b) => {
                return a.status && a.status.localeCompare(b.status);
            },
            render: (text, record) => {
                return record.status || '-';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="danger"
                            ghost
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name} from this league?`,
            content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        console.log(record, rowIndex);
                        props.history.push({
                            pathname: `/league-manager/${props.league.slug}/players/${record.name}`,
                            // search: '?query=abc',
                            state: {
                                data: record,
                                backPath: `/league-manager/${props.league.slug}/players`,
                            },
                        });
                    },
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns.filter((column) => {
                if (league.has_team_support === false && column.dataIndex === 'teams') {
                    return null;
                } else if (league.has_division_support === false && column.dataIndex === 'divisions') {
                    return null;
                } else {
                    return column;
                }
            })}
            dataSource={players}
            onChange={onChange}
            pagination={false}
            // pagination={false}
            tableLayout="auto"
            scroll={{ x: 400 }}
            // pagination={{ pageSize: 30 }}
            size="middle"
            // bordered
        />
    );
}
