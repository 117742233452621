import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { message, Menu, Dropdown, Button, Icon, Typography, Empty, Row, Col, Tooltip, Modal, Input, Layout } from 'antd';
import Card from '../../../components/Card';
import ScrollContainer from 'react-indiana-drag-scroll';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { GET_TOURNAMENT_BRACKET_STAGES_QUERY } from './data/queries';
import { Query, Subscription } from '@apollo/client/react/components';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import TournamentStageDropdown from './bracket/TournamentStageDropdown';
import MatchInfoModal from './bracket/MatchInfoModal';
import RoundRobinMatches from '../../tournament-builder/RoundRobinMatches';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';

import _ from 'lodash';
import qs from 'query-string';

const { Sider, Content, Header } = Layout;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export function DPRoundRobinViewer(props) {
    const { authState, tournament, selectedStage, stages, tables, theme, urlPath, statusPanel, tablesPanel, isPublic, isFullScreen, onSelectedStage } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [groups, setGroups] = useState([]);
    const [originalMatches, setOriginalMatches] = useState();
    const [matches, setMatches] = useState();
    const [currentRound, setCurrentRound] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const stageSlug = props.match.params.stage;
    console.log('stage slug', stageSlug);
    const windowHeight = window.innerHeight;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const sectionHeaderEl = document.querySelector('.section-header');
    const progressBarEl = document.querySelector('.tournament-progress-bar');
    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    let footerHeight = 53;

    if (isPublic === true) {
        if (isFullScreen === true) {
            bracketHeight = '100%';
        } else {
            const spacer = breakpoint === 'mobile' ? 170 : 95;
            bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
        }
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = '100%';
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }
    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalScoreVisible(true);
    }

    function handleMatchModalOk(e) {
        // console.log(e);
        setMatchModalVisible(false);
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    return (
        <SectionWrapper>
            <SectionContent padding="0px">
                <Query
                    query={GET_TOURNAMENT_BRACKET_STAGES_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ tournament_id: tournament.id }}
                    onCompleted={(data, loading, error) => {
                        if (!loading && !error) {
                            const tournament_brackets = data.tournament_brackets;
                            // const matches = tournament_brackets.filter((match) => {
                            //     return match.stage_number === 1;
                            // });
                            const originalMatches = tournament_brackets;
                            let matches;
                            if (stages) {
                                matches =
                                    tournament_brackets &&
                                    tournament_brackets.filter((match) => {
                                        return match.stage_number === 1;
                                    });
                            } else {
                                matches = tournament_brackets;
                            }

                            const groupedMatches = _.groupBy(matches, 'group');
                            const groups = Object.values(groupedMatches);
                            // console.log(groups);
                            const groupsWithRounds =
                                groups &&
                                groups.map((group, index) => {
                                    console.log(group);
                                    const groupNumber = index + 1;
                                    const groupName = group && group[0] && group[0].group_name;
                                    const groupedRounds = _.groupBy(group, 'round');
                                    const rounds = Object.values(groupedRounds);

                                    return {
                                        group: groupNumber,
                                        group_name: groupName,
                                        rounds: rounds,
                                    };
                                });
                            // console.log(groupsWithRounds);
                            setGroups(groupsWithRounds);
                            setOriginalMatches(originalMatches);
                            setMatches(matches);
                        }
                    }}
                >
                    {({ loading, error, data, subscribeToMore, networkStatus }) => {
                        if (networkStatus === 1 || loading) {
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        }

                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                            return (
                                <Row gutter={24} justify="center" type="flex" style={{ padding: '20px 40px' }}>
                                    <Col xs={24}>
                                        <Card>
                                            <ZeroState showImage message="No tournament bracket exists yet." style={{ margin: 30 }} {...props}>
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={() => {
                                                        props.history.push(`/tournament-builder/${tournament.slug}/edit?step=4`);
                                                    }}
                                                >
                                                    Set up bracket
                                                </Button>
                                            </ZeroState>
                                        </Card>
                                    </Col>
                                </Row>
                            );
                        }

                        return (
                            <Layout>
                                <Content style={{ position: 'relative' }}>
                                    <TournamentStageDropdown
                                        isPublic={true}
                                        stages={stages}
                                        selectedStage={selectedStage}
                                        urlPath={urlPath}
                                        tables={tables}
                                        tournament={tournament}
                                        onSelected={(stage) => {
                                            console.log('stage', stage);
                                            onSelectedStage(stage);
                                        }}
                                        style={{ top: 20, right: 20, position: 'absolute', zIndex: 1 }}
                                        {...props}
                                    />

                                    <RoundRobinMatches
                                        isPublic={isPublic}
                                        groups={groups}
                                        matches={matches}
                                        stage={selectedStage}
                                        tables={tables}
                                        bracketHeight={bracketHeight}
                                        onShowScoreModal={showScoreModal}
                                        {...props}
                                    />

                                    {currentMatch && (
                                        <MatchInfoModal
                                            {...props}
                                            tournament={tournament}
                                            tournamentID={tournament.id}
                                            currentMatch={currentMatch}
                                            modalVisible={matchModalVisible}
                                            onMatchModalOk={handleMatchModalOk}
                                            onMatchModalCancel={handleMatchModalCancel}
                                        />
                                    )}
                                </Content>
                            </Layout>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );
}

export default DPRoundRobinViewer;
