import React, { useState, useEffect } from 'react';
import { message, Icon, Row, Col, Typography, Button, Radio, Input, Tabs, Empty, Modal, Drawer } from 'antd';
import Card from '../../components/Card';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISIONS_QUERY, GET_LEAGUE_QUERY } from './data/queries';
import { DELETE_DIVISION_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CircularLoader from '../../components/CircularLoader';
// import AddDivisionModal from './AddDivisionModal';
import DivisionTable from './divisions/DivisionTable';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
};

export default function LMCreateAddDivisions(props) {
    const { league, authState, onContinue } = props;
    const [divisionModalVisible, setDivisionModalVisible] = useState(false);
    const [selectedDivisionId, setSelectedDivisionId] = useState();
    const [deleteDivision, { loading, error }] = useMutation(DELETE_DIVISION_MUTATION);

    function showDivisionModal() {
        setDivisionModalVisible(true);
    }

    function handleDivisionModalOk(e) {
        console.log(e);
        setDivisionModalVisible(false);
    }

    function handleDivisionModalCancel(e) {
        setDivisionModalVisible(false);
    }

    function handleDivisionSelected(selected) {
        console.log(selected);
        setSelectedDivisionId(selected);
    }

    function handleEditDivisionSelected(selected) {
        console.log(selected);
        setSelectedDivisionId(selected);
        setDivisionModalVisible(true);
    }

    return (
        <Query
            query={GET_LEAGUE_DIVISIONS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ leagueId: league.id }}
            onCompleted={(data, error, loading) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                return (
                    <React.Fragment>
                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Title level={4} style={{ margin: 0 }}>
                                All Divisions ({data.league_divisions && data.league_divisions.length})
                            </Title>
                            <div>
                                <Button type="primary" onClick={showDivisionModal}>
                                    <Icon type="plus" /> Add Table
                                </Button>

                                {/* <Button onClick={onContinue}>
									Go to Bracket <Icon type="arrow-right" style={{ marginTop: 3 }} />
								</Button> */}
                            </div>
                        </div>
                        <DivisionTable
                            {...props}
                            onSelected={handleDivisionSelected}
                            onEditSelected={handleEditDivisionSelected}
                            size="small"
                            onDelete={(record) => {
                                deleteDivision({
                                    variables: {
                                        id: record.id,
                                    },
                                    notifyOnNetworkStatusChange: true,
                                    awaitRefetchQueries: true,
                                    refetchQueries: [
                                        {
                                            query: GET_LEAGUE_DIVISIONS_QUERY,
                                            variables: { leagueId: league.id },
                                        },
                                        {
                                            query: GET_LEAGUE_QUERY,
                                            variables: { id: league.id },
                                        },
                                    ],
                                })
                                    .then((data) => {
                                        console.log(data);
                                        message.success('Table successfully deleted');
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        message.error('There was an error', error);
                                    });
                            }}
                            tables={
                                data.league_divisions &&
                                data.league_divisions.map((item, index) => {
                                    return {
                                        ...item,
                                        key: index,
                                        index: index + 1,
                                    };
                                })
                            }
                        />
                        <Button type="primary" size="large" onClick={onContinue} style={{ marginTop: 20 }}>
                            Go to League Dashboard
                        </Button>

                        {/* <AddDivisionModal
							{...props}
							league={league}
							selectedDivisionId={selectedDivisionId}
							divisionModalVisible={divisionModalVisible}
							onDivisionModalOk={handleDivisionModalOk}
							onDivisionModalCancel={handleDivisionModalCancel}
							redirectAfterSave={false}
						/> */}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}
