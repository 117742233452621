import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Row, Col, PageHeader, Steps, Typography, Button, Avatar, Input, List, Tabs, Empty, Skeleton } from 'antd';
import Card from '../../../components/Card';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import MatchCreateEditForm from './MatchCreateEditForm';
import ZeroState from '../../../components/ZeroState';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_MATCH_BY_SLUG_QUERY } from './data/queries';
import _ from 'lodash';

const { Step } = Steps;
const { Text, Title } = Typography;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function MatchCreate(props) {
    return (
        <React.Fragment>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                subtitle="Create a new match between two players and keep track of live scoring and statistics."
                subtitleStyle={{ color: '#fff' }}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/matches`)}
                        type="default"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
            />

            <SectionContent padding="20px 40px">
                <Row>
                    <Col>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Match Setup
                                    </Title>
                                </div>
                            }
                            headStyle={styles.cardHeader}
                        >
                            {props.match.params.slug ? (
                                <Query
                                    query={GET_MATCH_BY_SLUG_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ slug: props.match.params.slug }}
                                    onCompleted={(data) => {
                                        console.log(data);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading)
                                            return (
                                                <div style={styles.container}>
                                                    <CircularLoader />
                                                </div>
                                            );
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                        if (!loading && data && !data.matches) {
                                            return (
                                                <ZeroState showImage message="You have not added any matches yet." style={{ margin: 30 }} {...props}>
                                                    <Button>Create New match</Button>
                                                </ZeroState>
                                            );
                                        }

                                        return (
                                            <React.Fragment>
                                                <MatchCreateEditForm {...props} matchData={data.matches[0]} />
                                            </React.Fragment>
                                        );
                                    }}
                                </Query>
                            ) : (
                                <MatchCreateEditForm {...props} />
                            )}
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(MatchCreate);
