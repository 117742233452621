// export const matchStats = [
//     'Score',
//     'Balls pocketed',
//     'Balls missed',
//     'Unforced errors',
//     'Safety errors',
//     'Balls made on break',
//     'Dry breaks',
//     'Scratches on break',
//     'Scratches',
//     // 'Illegal breaks',
//     'Break and runs',
//     'Runouts',
//     'Racks won on break',
//     'Racks won against break',
// ];

export const matchStats = [
    // 'Score',
    'Balls pocketed',
    'Balls missed',
    'Unforced errors',
    'Safety errors',
    'Balls made on break',
    // 'Dry breaks',
    'Scratches on break',
    // 'Scratches',
    // 'Illegal breaks',
    'Break and runs',
    // 'Runouts',
    // 'Racks won on break',
    // 'Racks won against break',
];
