import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
// importing the VideoJS Functional component
import VideoJS from './VideoJS';

export default function VideoJSPlayer(props) {
    const { startPlaying, options, onEnded } = props;
    const playerReference = useRef(null);

    useEffect(() => {
        if (startPlaying && startPlaying === true) {
            playerReference.current.play();
        }
    }, [startPlaying]);

    // setting the video-js option for the player
    const videoJsOptions = options || {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        muted: true,
        sources: [
            {
                src: 'https://www.tutorialspoint.com/videos/sample480.mp4',
                type: 'video/mp4',
            },
            {
                src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                type: 'video/mp4',
            },
        ],
    };

    const playerReady = (player) => {
        playerReference.current = player;

        // handling video player
        player.on('waiting', () => {
            videojs.log('Video Player is waiting');
        });
        player.on('error', () => {
            console.log('ERROR');
            const error = player.error();

            console.log(error);
            // setVideoError(true);
        });
        player.errorDisplay.any('error', () => {
            console.log('THERE WAS AN ERROR');
        });
        player.on('ended', () => {
            videojs.log('Video ended');
            onEnded();
        });
        player.on('dispose', () => {
            videojs.log('Video player will dispose');
        });
    };

    return (
        <React.Fragment>
            <VideoJS options={videoJsOptions} onReady={playerReady} />
        </React.Fragment>
    );
}
