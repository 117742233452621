import React, { useState, useRef, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { message, Modal, Layout } from 'antd';
import Fullscreen from 'react-full-screen';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { SizeMe } from 'react-sizeme';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PlayerSearch from '../digitalpool/tournaments/bracket/PlayerSearch';
import qs from 'query-string';
import _ from 'lodash';
import { GET_TOURNAMENT_BY_SLUG_QUERY } from './data/queries';
import { UNLOCK_TOURNAMENT_MUTATION } from './data/mutations';
import { Row, Tabs } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import StageMatches from './StageMatches';
import { GET_TOURNAMENT_BRACKET_SUBSCRIPTION } from './data/subscriptions';
import { GET_TOURNAMENT_BRACKET_QUERY } from './data/queries';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function TBList(props) {
    const { tournament, client, theme, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const [playerSearchCoordinates, setPlayerSearchCoordinates] = useState({ x: 0, y: 0 });
    const [unlockTournament] = useMutation(UNLOCK_TOURNAMENT_MUTATION);
    const [activeTab, setActiveTab] = useState('set-1');

    function handleTabClick(tab) {
        setActiveTab(tab);
    }

    return (
        <SectionWrapper>
            {showNavigation !== false && (
                <React.Fragment>
                    <SectionHeader
                        title={props.title}
                        titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                        subtitle={tournament.name}
                        subtitlePrefix={true}
                        subtitleStyle={{
                            color: 'rgba(255,255,255,0.8)',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: 12,
                            letterSpacing: 3,
                        }}
                        height={90}
                        theme="tournamentBuilder"
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <TBManageButtons page="bracket" {...props} />
                        </div>
                    </SectionHeader>
                    {/* <TournamentProgressBar {...props} /> */}
                </React.Fragment>
            )}

            {/* <SectionContent padding="0px" style={{ height: 'calc(100% - 120px)' }}> */}
            <SectionContent padding="0px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Query
                        query={GET_TOURNAMENT_BRACKET_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournamentId: tournament.id }}
                        onCompleted={(data) => {
                            console.log('tournament data:', data);
                            // const { tournament_brackets } = data;
                            // setBracket(tournament_brackets);
                        }}
                    >
                        {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            let multiStage = tournament && tournament.tournament_stage_format === 'multi';
                            let tournamentBrackets;
                            let stages = tournament && tournament.stages;
                            const matches = data.tournament_brackets;

                            return (
                                <React.Fragment>
                                    {multiStage === true && stages && stages.length > 0 ? (
                                        <Tabs
                                            defaultActiveKey="set-1"
                                            animated={{ inkBar: true, tabPane: false }}
                                            className="no-scroll tabs-bg2"
                                            onChange={handleTabClick}
                                            tabBarGutter={5}
                                            tabBarStyle={{
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {stages.map((stage, index) => {
                                                const advanceRounds =
                                                    matches &&
                                                    matches
                                                        .filter((match) => {
                                                            return match.stage_number === stage.number && match.advance_to !== null;
                                                        })
                                                        .map((match) => {
                                                            return match.round;
                                                        });

                                                tournamentBrackets =
                                                    matches &&
                                                    matches
                                                        .filter((match) => {
                                                            return match.stage_number === stage.number;
                                                        })
                                                        .filter((match, index) => {
                                                            if (match.stage_number === 1) {
                                                                return advanceRounds.some((value) => {
                                                                    if (match.identifier.includes('L')) {
                                                                        return value <= match.round;
                                                                    } else {
                                                                        return value >= match.round;
                                                                    }
                                                                });
                                                            } else {
                                                                return match;
                                                            }
                                                        });

                                                //const stageMatches = matches.filter((match) => {
                                                //    return match.stage_number === stage.number;
                                                //});
                                                return (
                                                    <TabPane tab={stage.name} key={stage.slug}>
                                                        <StageMatches
                                                            key={index}
                                                            tournament={tournament}
                                                            stage={stage}
                                                            matches={tournamentBrackets}
                                                            subscribeToMore={subscribeToMore}
                                                            subscribeToNewData={() => {
                                                                subscribeToMore({
                                                                    document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                                                    variables: { tournamentId: tournament.id },
                                                                    updateQuery: (prev, { subscriptionData }) => {
                                                                        if (!subscriptionData.data) return prev;
                                                                        const newBracket = subscriptionData.data.tournament_brackets;
                                                                        let updated;
                                                                        if (prev) {
                                                                            updated = Object.assign({}, prev, {
                                                                                tournament_brackets: [...newBracket],
                                                                            });
                                                                        } else {
                                                                            updated = Object.assign({}, prev, {
                                                                                tournament_brackets: [...newBracket],
                                                                            });
                                                                        }
                                                                        return updated;
                                                                    },
                                                                });
                                                            }}
                                                            {...props}
                                                        />
                                                    </TabPane>
                                                );
                                            })}
                                        </Tabs>
                                    ) : (
                                        <StageMatches
                                            tournament={tournament}
                                            matches={matches}
                                            subscribeToMore={subscribeToMore}
                                            subscribeToNewData={() => {
                                                subscribeToMore({
                                                    document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                                    variables: { tournamentId: tournament.id },
                                                    updateQuery: (prev, { subscriptionData }) => {
                                                        if (!subscriptionData.data) return prev;
                                                        const newBracket = subscriptionData.data.tournament_brackets;
                                                        let updated;
                                                        if (prev) {
                                                            updated = Object.assign({}, prev, {
                                                                tournament_brackets: [...newBracket],
                                                            });
                                                        } else {
                                                            updated = Object.assign({}, prev, {
                                                                tournament_brackets: [...newBracket],
                                                            });
                                                        }
                                                        return updated;
                                                    },
                                                });
                                            }}
                                            {...props}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Query>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBList);
