import React, { useState } from 'react';
import { Link, withRouter, Switch, Route } from 'react-router-dom';
import { message, Avatar, PageHeader, Icon, Row, Tag, Modal, Col, List, Skeleton, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../../components/Card';
import { getGameType, displayEnumType } from '../../../utils';
import SectionContent from '../../../components/SectionContent';
import SectionHeader from '../../../components/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper';
import CircularLoader from '../../../components/CircularLoader';
import BasicMatchScoring from '../../../components/scoring/BasicMatchScoring';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
import TableScoreCard from '../../../screens/digitalpool/tournaments/TableScoreCard';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import MatchScoringModal from '../MatchScoringModal';
import AddSingleMatchModal from '../AddSingleMatchModal';
import FramesList from './FramesList';
import MatchStatsTable from './MatchStatsTable';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_LEAGUE_MATCH_BY_SLUG_QUERY, GET_LEAGUE_MATCH_QUERY } from '../data/queries';
import { GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION, GET_LEAGUE_DIVISION_MATCH_STATS_SUBSCRIPTION } from '../data/subscriptions';
import { UPDATE_LEAGUE_MATCH } from '../data/mutations';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMDivisionMatchDetail(props) {
    const { league, division, history, authState, theme } = props;
    const [loading, setLoading] = useState(false);
    const [match, setMatch] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const [updateLeagueMatch, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_MATCH);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleMatchModalOk(e) {
        console.log(e);
        setMatchModalVisible(false);
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    function handleEditMatch() {
        setMatchModalVisible(true);
    }

    function showConfirmStart(match) {
        confirm({
            title: 'Are you ready to start the match?',
            content: 'This action will start the match clock and enable live scoring.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Match',
            cancelText: "I'll do this later",
            onOk() {
                updateLeagueMatch({
                    variables: {
                        id: match.id,
                        changes: {
                            start_time: moment.utc(),
                            status: 'IN_PROGRESS',
                            progress: 0,
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_LEAGUE_MATCH_QUERY,
                            variables: { league_id: league.id, match_id: match.id },
                        },
                    ],
                })
                    .then((res) => {
                        console.log(res);
                        setLoading(false);
                        message.success('Match started');
                        props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/matches/${match.slug}/scoring`);

                        // updateLeagueMatchPlayer({
                        // 	variables: {
                        // 		league_id: leagueID,
                        // 		league_match_id: match.id,
                        // 		objects: match.league_match_players.map((player, index) => {
                        // 			return {
                        // 				league_id: leagueID,
                        // 				league_match_id: match.id,
                        // 				league_player_id: player.id,
                        // 				division_id: divisionID,
                        // 				score: index === 0 ? parseInt(challenger1_score) : parseInt(challenger2_score)
                        // 			};
                        // 		})
                        // 	},
                        // 	awaitRefetchQueries: true,
                        // 	refetchQueries: [
                        // 		{
                        // 			query: GET_LEAGUE_MATCHES_QUERY,
                        // 			variables: { league_id: leagueID }
                        // 		}
                        // 	]
                        // })
                        // 	.then((data) => {
                        // 		console.log(data);
                        // 		setLoading(false);
                        // 		message.success('Changes saved');
                        // 		onModalOk();
                        // 	})
                        // 	.catch((error) => {
                        // 		console.log(error);
                        // 		setLoading(false);
                        // 		message.info('There was an error', error);
                        // 		onModalOk();
                        // 	});
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    // TPA formula = balls pocketed / balls pocketed + errors
    function calculateTPA(challenger, matchData) {
        let ballsPocketed;
        let errors;
        if (challenger === 'challenger1') {
            ballsPocketed = matchData.challenger1_balls_pocketed;
            errors = matchData.challenger1_errors;
        } else {
            ballsPocketed = matchData.challenger2_balls_pocketed;
            errors = matchData.challenger2_errors;
        }
        const tpa = numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
        return tpa;
    }

    function orderAndFormatData(match) {
        let formattedResults = [];

        console.log('match', match);

        const player1 = {
            id: match.challenger1_id,
            name: match.challenger1_name,
            is_winner: match.challenger1_is_winner,
            country: match.challenger1_country,
            totalScore: match.challenger1_score,
            gamesWon: match.challenger1_games,
            gamesLost: match.challenger2_games,
            totalGames: match.league_match_games_aggregate.aggregate.sum.challenger1_games,
            pointsWon: match.challenger1_points,
            pointsLost: match.challenger2_points,
            totalPoints: match.league_match_games_aggregate.aggregate.sum.challenger1_points,
            ballsPocketed: match.league_match_games_aggregate.aggregate.sum.challenger1_balls_pocketed,
            errors: match.league_match_games_aggregate.aggregate.sum.challenger1_errors,
            highBreak: match.league_match_games_aggregate.aggregate.sum.challenger1_high_break,
            gameData: match.league_match_games_aggregate.nodes,
            tpa: calculateTPA('challenger1', match.league_match_games_aggregate.aggregate.sum),
        };
        formattedResults.push(player1);

        const player2 = {
            id: match.challenger2_id,
            name: match.challenger2_name,
            is_winner: match.challenger2_is_winner,
            country: match.challenger2_country,
            totalScore: match.challenger2_score,
            gamesWon: match.challenger2_games,
            gamesLost: match.challenger1_games,
            totalGames: match.league_match_games_aggregate.aggregate.sum.challenger2_games,
            pointsWon: match.challenger2_points,
            pointsLost: match.challenger1_points,
            totalPoints: match.league_match_games_aggregate.aggregate.sum.challenger2_points,
            ballsPocketed: match.league_match_games_aggregate.aggregate.sum.challenger2_balls_pocketed,
            errors: match.league_match_games_aggregate.aggregate.sum.challenger2_errors,
            highBreak: match.league_match_games_aggregate.aggregate.sum.challenger2_high_break,
            gameData: match.league_match_games_aggregate.nodes,
            tpa: calculateTPA('challenger2', match.league_match_games_aggregate.aggregate.sum),
        };
        formattedResults.push(player2);

        return formattedResults;
    }

    return (
        <React.Fragment>
            <SectionHeader
                title={match && match.name}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    fontWeight: 400,
                    display: 'flex',
                }}
                // breadCrumb={
                //     breakpoint === 'tablet' && (
                //         <div>
                //             <BreadcrumbContainer theme="dark" />
                //         </div>
                //     )
                // }
                backButton={
                    <Button
                        onClick={() => props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/matches`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="leagueManager"
            >
                <Button type="ghost" ghost onClick={handleEditMatch}>
                    <Icon type="edit" /> Edit Match
                </Button>
            </SectionHeader>

            <SectionContent padding="0px">
                <Query
                    query={GET_LEAGUE_MATCH_BY_SLUG_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    variables={{ league_id: league.id, slug: props.match.params.match }}
                    onCompleted={(data) => {
                        console.log(data);
                        const match = data && data.league_matches && data.league_matches[0];
                        setMatch(match);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.league_matches) || (data && data.league_matches.length === 0)) {
                            return (
                                <Col xs={24}>
                                    <Card
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	width: '100%',
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                    >
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>That match does not exist</Text>}
                                            style={{ margin: 30 }}
                                        />
                                    </Card>
                                </Col>
                            );
                        }

                        const match = data && data.league_matches && data.league_matches[0];

                        return (
                            <Switch>
                                <Route
                                    exact
                                    path={`/league-manager/${league.slug}/divisions/${division.slug}/matches/${match.slug}/scoring`}
                                    render={(matchProps) => (
                                        <React.Fragment>
                                            <BasicMatchScoring {...props} leagueID={league.id} divisionID={division.id} matchID={match.id} match={match} />
                                        </React.Fragment>
                                    )}
                                />

                                <Route
                                    path={`/league-manager/${league.slug}/divisions/${division.slug}/matches/${match.slug}`}
                                    render={(matchProps) => (
                                        <React.Fragment>
                                            <SectionWrapper>
                                                <SectionContent padding="20px 40px">
                                                    <Row gutter={24} justify="center" type="flex">
                                                        <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                            <Row gutter={24}>
                                                                <Col xs={24} sm={8}>
                                                                    <Card
                                                                        title="Match summary"
                                                                        extra={
                                                                            <div>
                                                                                {match.status === 'IN_PROGRESS' ? (
                                                                                    <Tag color="red" size="large">
                                                                                        LIVE
                                                                                    </Tag>
                                                                                ) : (
                                                                                    <Tag size="large">{match.status}</Tag>
                                                                                )}
                                                                            </div>
                                                                        }
                                                                        bodyStyle={{ padding: 0 }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                padding: 20,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                borderBottom:
                                                                                    theme.name === 'dark'
                                                                                        ? '1px solid rgba(255,255,255, 0.1)'
                                                                                        : '1px solid #f2f2f2',
                                                                                backgroundColor: theme.name === 'dark' ? '#0b1a2c' : '#fafafa',
                                                                            }}
                                                                        >
                                                                            <Avatar
                                                                                size={150}
                                                                                src={getGameType(match.game_type)}
                                                                                // style={{ backgroundColor: '#1890ff' }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ padding: 24 }}>
                                                                            <table width="100%" className="info-table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Name:</td>
                                                                                        <td>
                                                                                            {match.challenges && match.challenges.length > 0 ? (
                                                                                                <Link
                                                                                                    to={`/league-manager/${league.slug}/challenges/${match.challenges[0].slug}`}
                                                                                                >
                                                                                                    {match.name}
                                                                                                </Link>
                                                                                            ) : (
                                                                                                <React.Fragment>{match.name || 'N/A'}</React.Fragment>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Match type</td>
                                                                                        <td>{displayEnumType(match.match_type) || 'N/A'}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Game type</td>
                                                                                        <td>
                                                                                            <Avatar
                                                                                                size={24}
                                                                                                src={getGameType(match.game_type)}
                                                                                                style={{
                                                                                                    display: 'inline-block',
                                                                                                    marginRight: 8,
                                                                                                }}
                                                                                            />
                                                                                            {displayEnumType(match.game_type)}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Scoring format</td>
                                                                                        <td>{displayEnumType(match.scoring_format) || 'N/A'}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Races</td>
                                                                                        <td>
                                                                                            {match &&
                                                                                                `${match.challenger1_name || match.home_team.name} goes to ${
                                                                                                    match.challenger1_race_to
                                                                                                }`}
                                                                                            <br />
                                                                                            {match &&
                                                                                                `${match.challenger2_name || match.visitor_team.name} goes to ${
                                                                                                    match.challenger2_race_to
                                                                                                }`}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Scheduled time</td>
                                                                                        <td>
                                                                                            {moment(match.scheduled_time).format('llll') || 'N/A'} ({timezone})
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Start time</td>
                                                                                        <td>
                                                                                            {moment(match.start_date_time).format('llll') || 'N/A'} ({timezone})
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>End time</td>
                                                                                        <td>
                                                                                            {moment(match.end_date_time).format('llll') || 'N/A'} ({timezone})
                                                                                        </td>
                                                                                    </tr>
                                                                                    {/* <tr>
                                                                                        <td>Status</td>
                                                                                        <td>{match.status || 'N/A'}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Progress</td>
                                                                                        <td>
                                                                                            <Progress percent={match.progress} strokeWidth={4} />{' '}
                                                                                        </td>
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>Table name</td>
                                                                                        <td>{match.table_name || 'N/A'}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </Card>
                                                                    <br />

                                                                    <Card title="Venue" bodyStyle={{ padding: 0 }}>
                                                                        {match && match.venue && (
                                                                            <List
                                                                                className="hover-list"
                                                                                size="small"
                                                                                loading={loading}
                                                                                itemLayout="horizontal"
                                                                                dataSource={[match.venue]}
                                                                                renderItem={(item) => (
                                                                                    <List.Item
                                                                                        // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                                        style={{ padding: '10px 16px' }}
                                                                                        onClick={() => history.push(`/venues/${item.slug}`)}
                                                                                    >
                                                                                        <Skeleton avatar title={false} loading={loading} active>
                                                                                            <List.Item.Meta
                                                                                                avatar={
                                                                                                    <div>
                                                                                                        <Icon
                                                                                                            type="environment"
                                                                                                            theme="filled"
                                                                                                            style={{
                                                                                                                color: '#1890ff',
                                                                                                                fontSize: 30,
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                                title={
                                                                                                    <Text
                                                                                                        style={{
                                                                                                            fontSize: 16,
                                                                                                        }}
                                                                                                    >
                                                                                                        {item.name}
                                                                                                    </Text>
                                                                                                }
                                                                                                description={
                                                                                                    <div>
                                                                                                        {item.address1}, {item.city}, {item.region}{' '}
                                                                                                        {item.postal_code} {item.country}
                                                                                                    </div>
                                                                                                }
                                                                                            />
                                                                                        </Skeleton>
                                                                                    </List.Item>
                                                                                )}
                                                                            />
                                                                        )}
                                                                    </Card>
                                                                </Col>

                                                                <Col xs={24} sm={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                    <Row justify="center">
                                                                        <Col xs={24}>
                                                                            <Card title="Live scores" bodyStyle={{ padding: 0 }}>
                                                                                <Subscription
                                                                                    subscription={GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION}
                                                                                    fetchPolicy="cache-and-network"
                                                                                    notifyOnNetworkStatusChange={true}
                                                                                    variables={{
                                                                                        league_id: league.id,
                                                                                        slug: props.match.params.match,
                                                                                    }}
                                                                                    onSubscriptionData={(res) => {
                                                                                        console.log(res);
                                                                                        // const match =
                                                                                        // 	res &&
                                                                                        // 	res.subscriptionData &&
                                                                                        // 	res.subscriptionData.data &&
                                                                                        // 	res.subscriptionData.data
                                                                                        // 		.league_matches &&
                                                                                        // 	res.subscriptionData.data
                                                                                        // 		.league_matches[0];
                                                                                        // setMatch(match);
                                                                                    }}
                                                                                >
                                                                                    {({ loading, error, data }) => {
                                                                                        if (loading) return <CircularLoader />;
                                                                                        if (error)
                                                                                            return <div style={styles.container}>Error: {error.message}</div>;
                                                                                        if (
                                                                                            (data && !data.league_matches) ||
                                                                                            (data && data.league_matches.length === 0)
                                                                                        ) {
                                                                                            return (
                                                                                                <Col xs={24}>
                                                                                                    <Card
                                                                                                        bodyStyle={{
                                                                                                            padding: 0,
                                                                                                        }}
                                                                                                        // style={{
                                                                                                        // 	width: '100%',
                                                                                                        // 	borderRadius: 8,
                                                                                                        // 	overflow: 'hidden',
                                                                                                        // 	border: 'none',
                                                                                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                                                                                        // }}
                                                                                                    >
                                                                                                        <Empty
                                                                                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                                                                            imageStyle={{
                                                                                                                height: 100,
                                                                                                            }}
                                                                                                            description={
                                                                                                                <Text
                                                                                                                    style={{
                                                                                                                        fontSize: 18,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    That match does not exist
                                                                                                                </Text>
                                                                                                            }
                                                                                                            style={{
                                                                                                                margin: 30,
                                                                                                            }}
                                                                                                        />
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            );
                                                                                        }

                                                                                        const match = data && data.league_matches && data.league_matches[0];

                                                                                        console.log(match);

                                                                                        return (
                                                                                            <div
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    flexDirection: 'column',
                                                                                                    justifyContent: 'center',
                                                                                                    padding: 30,
                                                                                                    backgroundColor:
                                                                                                        theme.name === 'dark' ? '#0b1a2c' : '#fafafa',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        maxWidth: 600,
                                                                                                    }}
                                                                                                >
                                                                                                    <TableScoreCard
                                                                                                        title={`Match ${match.match_number}`}
                                                                                                        table={match.table_id}
                                                                                                        label={match.table_name}
                                                                                                        matchData={{
                                                                                                            ...match,
                                                                                                            challenger1_name:
                                                                                                                match.challenger1_name || match.home_team.name,
                                                                                                            challenger2_name:
                                                                                                                match.challenger2_name ||
                                                                                                                match.visitor_team.name,
                                                                                                            challenger1_score:
                                                                                                                match.challenger1_score ||
                                                                                                                match.home_team_score,
                                                                                                            challenger2_score:
                                                                                                                match.challenger2_score ||
                                                                                                                match.visitor_team_score,
                                                                                                        }}
                                                                                                        status={match.status}
                                                                                                        gameType={match.game_type}
                                                                                                        hoverable={false}
                                                                                                        tableSize={breakpoint === 'tablet' ? 'small' : 'large'}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }}
                                                                                </Subscription>

                                                                                <div
                                                                                    style={{
                                                                                        padding: '20px',
                                                                                        borderTop:
                                                                                            theme.name === 'dark'
                                                                                                ? '1px solid rgba(255,255,255, 0.1)'
                                                                                                : '1px solid #f2f2f2',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        width: '100%',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            flex: 1,
                                                                                        }}
                                                                                    >
                                                                                        {match.status === 'NOT_STARTED' ? (
                                                                                            <Button
                                                                                                type="primary"
                                                                                                size="large"
                                                                                                onClick={() => showConfirmStart(match)}
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                    textTransform: 'uppercase',
                                                                                                }}
                                                                                            >
                                                                                                Start scoring Match
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Link
                                                                                                to={`/league-manager/${league.slug}/divisions/${division.slug}/matches/${match.slug}/scoring`}
                                                                                            >
                                                                                                <Button
                                                                                                    type="primary"
                                                                                                    size="large"
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        textTransform: 'uppercase',
                                                                                                    }}
                                                                                                >
                                                                                                    Continue scoring Match
                                                                                                </Button>
                                                                                            </Link>
                                                                                        )}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            flex: 1,
                                                                                            marginLeft: 10,
                                                                                        }}
                                                                                    >
                                                                                        <Button
                                                                                            size="large"
                                                                                            ghost
                                                                                            color="default"
                                                                                            onClick={() => setModalVisible(true)}
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                textTransform: 'uppercase',
                                                                                            }}
                                                                                        >
                                                                                            Enter Final Scores
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </Card>
                                                                        </Col>

                                                                        <Col
                                                                            xs={24}
                                                                            style={{
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <Card title="Player stats" bodyStyle={{ padding: 0 }}>
                                                                                <Subscription
                                                                                    subscription={GET_LEAGUE_DIVISION_MATCH_STATS_SUBSCRIPTION}
                                                                                    fetchPolicy="cache-and-network"
                                                                                    notifyOnNetworkStatusChange={true}
                                                                                    variables={{
                                                                                        league_id: league.id,
                                                                                        division_id: division.id,
                                                                                        match_id: match.id,
                                                                                    }}
                                                                                    onSubscriptionData={(data) => {
                                                                                        console.log('stats', data);
                                                                                    }}
                                                                                >
                                                                                    {({ loading, error, data }) => {
                                                                                        if (loading) {
                                                                                            return <CircularLoader />;
                                                                                        }

                                                                                        let match = data && data.league_matches && data.league_matches[0];

                                                                                        // let orderedData = match && orderAndFormatData(match);
                                                                                        let orderedData = [];

                                                                                        console.log(match);

                                                                                        return (
                                                                                            <MatchStatsTable
                                                                                                divisionID={division.id}
                                                                                                formattedResults={orderedData.map((item, index) => {
                                                                                                    return {
                                                                                                        ...item,
                                                                                                        key: index,
                                                                                                    };
                                                                                                })}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                </Subscription>
                                                                            </Card>
                                                                        </Col>

                                                                        <Col
                                                                            xs={24}
                                                                            style={{
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <Card title="Frame breakdown" bodyStyle={{ padding: 0 }}>
                                                                                <FramesList
                                                                                    {...props}
                                                                                    frames={
                                                                                        match &&
                                                                                        match.league_match_games &&
                                                                                        match.league_match_games.map((item, index) => {
                                                                                            return {
                                                                                                ...item,
                                                                                                key: index,
                                                                                                index: index + 1,
                                                                                            };
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </SectionContent>
                                            </SectionWrapper>
                                        </React.Fragment>
                                    )}
                                />
                            </Switch>
                        );
                    }}
                </Query>
                <AddSingleMatchModal
                    {...props}
                    selectedMatchId={match && match.id}
                    userID={authState.user.id}
                    leagueID={league.id}
                    divisionID={match && match.division_id}
                    modalVisible={matchModalVisible}
                    onModalOk={handleMatchModalOk}
                    onModalCancel={handleMatchModalCancel}
                />
                <MatchScoringModal
                    {...props}
                    match={match && match}
                    selectedMatchId={match && match.id}
                    userID={authState.user.id}
                    leagueID={league.id}
                    divisionID={match && match.division_id}
                    modalVisible={modalVisible}
                    onModalOk={handleModalOk}
                    onModalCancel={handleModalCancel}
                />
            </SectionContent>
        </React.Fragment>
    );
}
export default withRouter(LMDivisionMatchDetail);
