import React, { useState, useRef, useEffect } from 'react';
import { Table, Typography, Row, Col, Button, Icon, Modal, Input, Tooltip } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import CircularLoader from '../../components/CircularLoader';
import CircleFlag from '../../components/CircleFlag';
import ZeroState from '../../components/ZeroState';
import Highlighter from 'react-highlight-words';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_LEAGUE_PLAYERS_QUERY } from './data/queries';

const { Text, Title } = Typography;

const styles = {};

export default function LMStandings(props) {
    const { league, client } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 80,
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',

            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country ? (
                            <React.Fragment>
                                <CircleFlag country={record.country} left={0} />
                                <Text>{text}</Text>
                            </React.Fragment>
                        ) : (
                            <Text>{text}</Text>
                        )}
                    </div>
                );
            },
        },
        // {
        // 	title: 'Divisions',
        // 	dataIndex: 'divisions',
        // 	sorter: (a, b) => {
        // 		return a.divisions.localeCompare(b.divisions, undefined, {
        // 			numeric: true,
        // 			sensitivity: 'base'
        // 		});
        // 	},
        // 	render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>
        // },

        {
            title: 'Skill Level',
            dataIndex: 'skill_level',
            sorter: (a, b) => a.skill_level - b.skill_level,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Points',
            dataIndex: 'points',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.points - b.points,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Challenges played',
            dataIndex: 'challenges_played',
            sorter: (a, b) => a.challenges_played - b.challenges_played,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Challenges won',
            dataIndex: 'challenges_won',
            sorter: (a, b) => a.challenges_won - b.challenges_won,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Challenges lost',
            dataIndex: 'challenges_lost',
            sorter: (a, b) => a.challenges_lost - b.challenges_lost,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
    ];

    function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}/standings`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={24}>
                    <Col xs={24}>
                        <Card title="League Standings" bodyStyle={{ padding: 0 }}>
                            <Query
                                query={GET_LEAGUE_PLAYERS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ league_id: league.id }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_players) || (data && data.league_players.length === 0)) {
                                        return <ZeroState showImage message="No Players have been added yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    const standings =
                                        data.league_players &&
                                        data.league_players
                                            .map((item, index) => {
                                                const playerChallengesPlayed =
                                                    item.player &&
                                                    item.player.player_challenges_played &&
                                                    item.player.player_challenges_played.aggregate &&
                                                    item.player.player_challenges_played.aggregate.count;
                                                const opponentChallengesPlayed =
                                                    item.player &&
                                                    item.player.opponent_challenges_played &&
                                                    item.player.opponent_challenges_played.aggregate &&
                                                    item.player.opponent_challenges_played.aggregate.count;
                                                const challengesPlayed = playerChallengesPlayed + opponentChallengesPlayed;

                                                const playerChallengesWon =
                                                    item.player &&
                                                    item.player.player_challenges_won &&
                                                    item.player.player_challenges_won.aggregate &&
                                                    item.player.player_challenges_won.aggregate.count;
                                                const opponentChallengesWon =
                                                    item.player &&
                                                    item.player.opponent_challenges_won &&
                                                    item.player.opponent_challenges_won.aggregate &&
                                                    item.player.opponent_challenges_won.aggregate.count;
                                                const challengesWon = playerChallengesWon + opponentChallengesWon;

                                                const playerChallengesLost =
                                                    item.player &&
                                                    item.player.player_challenges_lost &&
                                                    item.player.player_challenges_lost.aggregate &&
                                                    item.player.player_challenges_lost.aggregate.count;
                                                const opponentChallengesLost =
                                                    item.player &&
                                                    item.player.opponent_challenges_lost &&
                                                    item.player.opponent_challenges_lost.aggregate &&
                                                    item.player.opponent_challenges_lost.aggregate.count;
                                                const challengesLost = playerChallengesLost + opponentChallengesLost;

                                                return {
                                                    ...item,
                                                    points: randomIntFromInterval(1, 100),
                                                    challenges_played: challengesPlayed,
                                                    challenges_won: challengesWon,
                                                    challenges_lost: challengesLost,
                                                    index: index + 1,
                                                    key: index,
                                                };
                                            })
                                            .sort((a, b) => {
                                                return a.points - b.points;
                                            });

                                    return (
                                        <Table
                                            columns={columns}
                                            dataSource={standings}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                            // pagination={{ pageSize: 25 }}
                                            // bordered
                                        />
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
