import React, { useState, useEffect } from 'react';
import { message, Icon, Row, Col, Typography, Button, Input, Tabs, Empty, Table, Alert } from 'antd';
import Card from '../../components/Card';
import PointsTable from './PointsTable';
import PointsModal from './PointsModal';
import ZeroState from '../../components/ZeroState';
import { Query } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TOURNAMENT_POINTS } from './data/queries';
import { DELETE_POINTS_ENTRY } from './data/mutations';
import _ from 'lodash';

const { Text, Title } = Typography;

export default function TBPayouts(props) {
    const { tournament, client } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPointsId, setSelectedPointsId] = useState(false);
    const [deletePoints] = useMutation(DELETE_POINTS_ENTRY);

    function handleModalOk() {
        setModalVisible(false);
        setSelectedPointsId(false);
    }

    function handleModalCancel() {
        setModalVisible(false);
        setSelectedPointsId(false);
    }

    function handleModalEdit(data) {
        setModalVisible(true);
        setSelectedPointsId(data.id);
    }

    // async function handleDeleteSelected(id) {
    // 	await client.mutate({
    // 		mutation: DELETE_POINTS_ENTRY,
    // 		variables: {
    // 			id: id
    // 		},
    // 		awaitRefetchQueries: true,
    // 		refetchQueries: [
    // 			{
    // 				query: GET_TOURNAMENT_POINTS,
    // 				variables: {
    // 					tournament_id: tournament.id
    // 				}
    // 			}
    // 		]
    // 	});
    // }

    function orderAndFormatData(arr) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formatArr = [];

        for (let i = 0; i < count; i++) {
            if (arr[i].place.includes('-')) {
                split = arr[i].place.split('-');
                cNum1 = parseInt(split[0]);
                cNum2 = parseInt(split[1]);
            }

            for (let j = i + 1; j < count; j++) {
                if (arr[j].place.includes('-')) {
                    split2 = arr[j].place.split('-');
                    cNum11 = parseInt(split2[0]);
                    cNum21 = parseInt(split2)[1];

                    if (split) {
                        if (cNum2 > cNum21) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    } else {
                        if (parseInt(arr[i].place) > parseInt(cNum21)) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    }
                } else {
                    if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                        tmp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = tmp;
                    }
                }

                if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                    tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
                }
            }

            split = false;

            formatArr.push({
                id: arr[i].id,
                place: arr[i].place,
                points: arr[i].points,
                key: i + 1,
            });
        }

        return _.uniqBy(formatArr, 'place');
    }

    return (
        <Row gutter={24} justify="center" type="flex">
            <Col xs={24}>
                <Alert
                    message="Helpful tips for adding points"
                    description="When adding a points range, put a dash inbetween two places. eg 10-20"
                    type="info"
                    closable
                    showIcon
                />
            </Col>
            <Col xs={24}>
                <Card
                    title="Points"
                    extra={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'right',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={(e) => {
                                    setModalVisible(true);
                                }}
                            >
                                <Icon type="plus-circle" /> Add Points
                            </Button>
                        </div>
                    }
                    bodyStyle={{ padding: 0 }}
                >
                    <Query
                        query={GET_TOURNAMENT_POINTS}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onCompleted={(data) => {
                            // var set = [];
                            // for (let i = 0; i < data.tournament_payouts.length; i++) {
                            // 	set.push(data.tournament_payouts[i]);
                            // }
                            // let formattedData = orderAndFormatData(set);
                            // setTableData(formattedData);
                        }}
                    >
                        {({ loading, error, data, refetch }) => {
                            // console.log(data);
                            if (loading) return <div>Loading...</div>;
                            if (error) return <div>{error}</div>;
                            if ((data && !data.tournament_points) || (data && data.tournament_points.length === 0)) {
                                return <ZeroState showImage message="No points found." style={{ margin: 30 }} {...props} />;
                            }

                            var set = [];
                            for (let i = 0; i < data.tournament_points.length; i++) {
                                set.push(data.tournament_points[i]);
                            }

                            let formattedData = orderAndFormatData(set);
                            return (
                                <PointsTable
                                    tableData={formattedData}
                                    onEditSelected={handleModalEdit}
                                    onDelete={(record) => {
                                        deletePoints({
                                            variables: {
                                                id: record.id,
                                            },
                                            notifyOnNetworkStatusChange: true,
                                            awaitRefetchQueries: true,
                                            refetchQueries: [
                                                {
                                                    query: GET_TOURNAMENT_POINTS,
                                                    variables: {
                                                        tournament_id: tournament.id,
                                                    },
                                                },
                                            ],
                                        })
                                            .then((data) => {
                                                console.log(data);
                                                message.success('Points deleted');
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                message.info('There was an error', error);
                                            });
                                    }}
                                />
                            );
                        }}
                    </Query>
                </Card>
            </Col>
            <PointsModal
                {...props}
                selectedPointsId={selectedPointsId}
                modalVisible={modalVisible}
                onModalOk={handleModalOk}
                onModalCancel={handleModalCancel}
                client={props.client}
                tournamentID={tournament.id}
            />
        </Row>
    );
}
