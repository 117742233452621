import React, { useState, useEffect, useRef } from 'react';
import { message, Tooltip, Button, Icon, Typography, Popconfirm, Avatar, Drawer, Badge, Empty, Modal, Menu, Dropdown } from 'antd';
import { Form, FormItem, AutoComplete, Input, Radio, InputNumber, Checkbox, DatePicker, Select, FormikDebug, SubmitButton, ResetButton } from 'formik-antd';
import { getGameType, displayEnumType, getContrastColor } from '../../utils';
import { Formik, ErrorMessage } from 'formik';
import {
    FILTER_TOURNAMENT_PLAYERS,
    GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
    GET_TOURNAMENT_PLAYERS_W_STATS,
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_MATCH_GAMES_QUERY,
} from './data/queries';
import {
    UPDATE_TOURNAMENT_MATCH_MUTATION,
    UPDATE_TABLE_STATUS_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    LOG_PLAYER_SMS_NOTIFICATION,
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_MATCH,
} from './data/mutations';
import { v4 as uuidv4 } from 'uuid';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { DigitalPoolAPI } from '../../lib/api';
import * as Yup from 'yup';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query, Mutation } from '@apollo/client/react/components';
import { calculateUSAPLRace, getOrdinalSuffix } from '../../utils';
import _ from 'lodash';
import axios from 'axios';
import { getDKHandicapRace } from '../../utils/handicap_charts/dkHandicapChart';
import RoundTimeEntry from './RoundTimeEntry';
import { QuestionCircleFilled } from '@ant-design/icons';
import Hashids from 'hashids';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

const styles = {
    input: {
        minWidth: 300,
        padding: 5,
    },
    inputSmall: {
        padding: 5,
        textAlign: 'center',
    },
};

var seeder = function () {
    var seed = [];
    return {
        set: function (length) {
            for (var i = 0; i < length; i++) {
                seed.push(Math.random());
            }
            return seed;
        },
        get: function () {
            return seed;
        },
        clear: function () {
            seed = [];
        },
    };
};

function ViewerMatchOverlay(props) {
    const {
        authState,
        currentRound,
        matches,
        currentMatch,
        modalScoreVisible,
        onModalScoreVisible,
        tournament,
        bracket,
        isFullScreen,
        onTournamentComplete,
        nextPlayerWaiting,
        onSave,
        theme,
    } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    // const [ value, setValue ] = useState();
    const [loading, setLoading] = useState(false);
    const [fargoLoading, setFargoLoading] = useState(false);
    const [selectedTable, setSelectedTable] = useState();
    const [tables, setTables] = useState();
    // const [ dataSource, setDataSource ] = useState([]);
    const [players, setPlayers] = useState([]);
    const [players1, setPlayers1] = useState([]);
    const [players2, setPlayers2] = useState([]);
    const [winner, setWinner] = useState();
    const [scheduledTime, setScheduledTime] = useState((currentMatch && currentMatch.scheduled_time) || null);
    const [matchColor, setMatchColor] = useState();
    const [showMatchTimeOverlay, setShowMatchTimeOverlay] = useState(false);
    const [showFargoColumnCharts, setShowFargoColumnCharts] = useState(false);
    const [selectedFargoRace, setSelectedFargoRace] = useState(tournament && tournament.winners_race_to);
    const [challenger1Result, setChallenger1Result] = useState();
    const [challenger2Result, setChallenger2Result] = useState();
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
    const [confirmEndTournamentPopupVisible, setConfirmEndTournamentPopupVisible] = useState(false);
    const [confirmTablePopupVisible, setConfirmTablePopupVisible] = useState(false);
    const [confirmScheduledTablePopupVisible, setConfirmScheduledTablePopupVisible] = useState(false);
    const [tournamentComplete, setTournamentComplete] = useState(false);
    const [createMatchMutation] = useMutation(CREATE_TOURNAMENT_BRACKET_MUTATION);
    const [updateTableStatus] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const [updateTournament] = useMutation(UPDATE_TOURNAMENT_MUTATION);
    const [updateTournamentBracket] = useMutation(UPDATE_TOURNAMENT_MATCH_MUTATION);
    const [logSMSNotification, { smsNotificationLoading, smsNotificationError }] = useMutation(LOG_PLAYER_SMS_NOTIFICATION);
    const [updateTournamentPlayer] = useMutation(UPDATE_TOURNAMENT_PLAYER_MUTATION);
    const formRef = useRef(null);
    const formRef2 = useRef(null);
    const API = DigitalPoolAPI(props);
    const hashids = new Hashids(`Tournament salt`);
    const seed = seeder();

    // console.log('tournament', tournament);

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            const res = await props.client.query({
                query: GET_TOURNAMENT_PLAYERS_W_STATS,
                variables: {
                    tournament_id: tournament.id,
                },
            });
            const players = res.data.tournament_players;
            // console.log('players', players);
            setPlayers(players);
            setScheduledTime(currentMatch.scheduled_time || null);
            setMatchColor(currentMatch.match_color || 'transparent');
            console.log('players', players);
        }
        if (currentMatch) {
            console.log('current match', currentMatch);
            fetchData();
        }

        return function cleanup() {
            mounted = false;
        };
    }, [currentMatch, tournament.id]);

    // useEffect(
    // 	() => {
    // 		if (currentMatch) {
    // 			if (currentMatch.challenger1_is_winner === true) {
    // 				setChallenger1Result('winner');
    // 				setChallenger2Result();
    // 			} else if (currentMatch.challenger2_is_winner === true) {
    // 				setChallenger2Result('winner');
    // 				setChallenger1Result();
    // 			} else if (currentMatch.challenger1_is_forfeit === true) {
    // 				setChallenger1Result('forfeit');
    // 			} else if (currentMatch.challenger2_is_forfeit === true) {
    // 				setChallenger2Result('forfeit');
    // 			}
    // 		}
    // 	},
    // 	[ currentMatch ]
    // );

    function handleScoreModalCancel() {
        onModalScoreVisible(false);
        setChallenger1Result();
        setChallenger2Result();
        setScheduledTime(null);
        setMatchColor(null);
    }

    async function queryDB(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: FILTER_TOURNAMENT_PLAYERS,
            variables: {
                name: `%${name}%`,
                tournament_id: tournament.id,
            },
            fetchPolicy: 'no-cache',
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            dataSet.push({
                ...player,
                key: uuidv4(),
            });
        }
        return dataSet;
    }

    async function onSearch1(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        setPlayers1(players);
    }

    async function onSearch2(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        setPlayers2(players);
    }

    const onSelectPlayer1 = async (value) => {
        const player = players1.filter((item) => item.key === value);

        if (player && player.length > 0) {
            // setPlayers1(player[0]);
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_name: player[0].name,
                challenger1_id: player[0].id,
            });
        } else {
            // setPlayers1();
            console.log('test');
            // formRef.current.setValues([]);
        }
    };

    const onSelectPlayer2 = async (value) => {
        const player = players2.filter((item) => item.key === value);

        if (player && player.length > 0) {
            // setPlayers2(player[0]);
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger2_name: player[0].name,
                challenger2_id: player[0].id,
            });
        } else {
            // setPlayers2();
            console.log('test');
            // formRef.current.setValues([]);
        }
    };

    function handleChange(name) {
        if (name && name.length === 0) {
            formRef.current.setValues([]);
        }
    }

    // function handleBlur(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // 	const { value } = e.target;
    // 	console.log(value);
    // }

    // function onChange(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // }

    // function onEnter(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // 	const { value } = e.target;
    // 	console.log(value);
    // }

    function handleFocus(event) {
        event.target.select();
    }

    function handleSelectTableChange(id) {
        const table = tables.filter((item) => {
            return item.id === id;
        })[0];
        setSelectedTable(table);
    }

    function getWinnerLoser(values) {
        const {
            challenger1,
            challenger1_id,
            challenger1_name,
            challenger1_score,
            challenger1_race_to,
            challenger1_seed,
            challenger1_country,
            challenger2,
            challenger2_id,
            challenger2_name,
            challenger2_score,
            challenger2_race_to,
            challenger2_seed,
            challenger2_country,
        } = values;

        let winner;
        let loser;

        if (challenger1Result === 'forfeit' || challenger2Result === 'forfeit') {
            if (challenger1Result === 'forfeit') {
                winner = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: true,
                    challenger2_is_forfeit: false,
                    challenger2_is_withdraw: false,
                };
                loser = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: false,
                    challenger1_is_forfeit: true,
                    challenger1_is_withdraw: false,
                };
            } else if (challenger2Result === 'forfeit') {
                winner = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: true,
                    challenger1_is_forfeit: false,
                    challenger1_is_withdraw: false,
                };
                loser = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: false,
                    challenger2_is_forfeit: true,
                    challenger2_is_withdraw: false,
                };
            }
        }
        if (challenger1Result === 'withdraw' || challenger2Result === 'withdraw') {
            if (challenger1Result === 'withdraw') {
                winner = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: true,
                    challenger2_is_withdraw: false,
                    challenger2_is_forfeit: false,
                };
                loser = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: false,
                    challenger1_is_withdraw: true,
                    challenger1_is_forfeit: false,
                };
            } else if (challenger2Result === 'withdraw') {
                winner = {
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: true,
                    challenger1_is_withdraw: false,
                    challenger1_is_forfeit: false,
                };
                loser = {
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: false,
                    challenger2_is_withdraw: true,
                    challenger2_is_forfeit: false,
                };
            }
        } else if (challenger1Result === 'split' || challenger2Result === 'split') {
            winner = {
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: true,
                challenger1_is_forfeit: false,
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: true,
                challenger2_is_forfeit: false,
                is_split: true,
            };

            loser = {};
        } else if (challenger1Result === 'winner' || (challenger1_score && challenger1_score === challenger1_race_to)) {
            winner = {
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: true,
            };
            loser = {
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: false,
            };
        } else if (challenger2Result === 'winner' || (challenger2_score && challenger2_score === challenger2_race_to)) {
            winner = {
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: true,
            };
            loser = {
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: false,
            };
        }

        const results = {
            winner,
            loser,
        };
        return results;
    }

    async function getPlayerRace(challenger) {
        let races;
        if (tournament.rating_system === 'danny_ks') {
            console.log('Danny Ks Handicap chart');
            races = getDKHandicapRace(currentMatch.challenger1, currentMatch.challenger2, currentMatch);
        } else if (tournament.tournament_format === 'usapl_fargo_rated') {
            console.log('USAPL Handicap Chart');
            races = getUSAPLRaces();
        } else if (tournament.tournament_format === 'fargo_rated') {
            console.log('Fargo Handicap chart');
            races = await getFargoRaces(5);
        } else {
            console.log('Default races');
            races =
                challenger && challenger.race_to
                    ? challenger.race_to
                    : currentMatch.identifier.includes('L')
                    ? tournament.losers_race_to
                    : tournament.winners_race_to;
        }
        console.log('races', races);
        return races;
    }

    function logSMSMessage(options) {
        const { match, player, tournament } = options;
        console.log('LOG SMS MESSAGE', options);

        return logSMSNotification({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        tournament_bracket_id: match.id,
                        type: 'SMS',
                        round_number: match.round,
                        match_number: match.match_number,
                        player_name: player.name,
                        player_id: player.id,
                        player_phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                        message: options.message.text,
                        message_id: options.message.id,
                        message_sent: options.message.sent,
                        message_delivered: options.message.delivered,
                        message_error: options.message.error,
                        message_undeliverable: options.message.undeliverable,
                        message_sent_at: options.message.sent_at,
                        message_delivered_at: options.message.delivered_at,
                    },
                ],
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_MATCH_STATS_QUERY,
            // 		variables: { match_id: matchId }
            // 	}
            // ]
        })
            .then((values) => {
                console.log(values);
                return values;
            })
            .catch((e) => {
                const err = (e && e.message) || JSON.stringify(e);
                message.error(err);
                console.log(err);
                return err;
            });
    }

    async function notifyPlayer(player1, player2, table, currentMatch) {
        if (tournament.use_text_messaging !== false) {
            let player1NameSplit;
            let player2NameSplit;
            let player1FirstName;
            let player2FirstName;
            let text;
            let scoreText;
            let player1Race;
            let player2Race;

            console.log({ player1, player2, table, currentMatch });
            const tableName =
                table && table.is_streaming_table === true
                    ? `${table.label} (Live Stream Table)`
                    : table && table.is_featured_table === true
                    ? `${table.label} (Featured Table)`
                    : table.label;

            if (player1) {
                player1NameSplit = player1.name && player1.name.split(' ');
                player1FirstName = player1NameSplit && player1NameSplit.length > 0 ? player1NameSplit[0] : player1.name;
            }

            if (player2) {
                player2NameSplit = player2.name && player2.name.split(' ');
                player2FirstName = player2NameSplit && player2NameSplit.length > 0 ? player2NameSplit[0] : player2.name;
            }

            const liveScoringLink = `Live score your match #${currentMatch.match_number} : https://digitalpool.com/m/${currentMatch.hash_id}`;

            // If match has both players
            if (player1 && player2) {
                if (currentMatch.identifier.includes('L')) {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.losers_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.losers_race_to;
                } else {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.winners_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.winners_race_to;
                }

                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} against ${player2 && player2.name} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} against ${
                        player2 && player2.name
                    } has been assigned to ${tableName}. ${player1FirstName} goes to ${player1Race} and ${player2FirstName} goes to ${player2Race}.`;
                    scoreText = liveScoringLink;
                }
            }
            // If match only has player 1
            else if (player1 && !player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player1 && player1.race_to ? `${player1FirstName} goes to ${player1.race_to}.` : ''
                    }`;
                    scoreText = player1 && player1.race_to && liveScoringLink;
                }
            }
            // if match only has player 2
            else if (!player1 && player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player2 && player2.race_to ? `${player2FirstName} goes to ${player2.race_to}.` : ''
                    }`;
                    scoreText = player2 && player2.race_to && liveScoringLink;
                }
            } else {
                throw new Error('Text message could not be sent. No players found!');
            }

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            const sendSMS = await httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${text}`;
            // const messageToSend = `DigitalPool.com\n${text}\n${moment().format('MM/DD/YYYY h:mm a')}`;
            if (player1) {
                try {
                    sendSMS({
                        phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                        message: messageToSend,
                        user_id: player1.id,
                        tournament_id: tournament.id,
                        // token: ''
                    })
                        .then(async (values) => {
                            console.log(values);

                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: values && values.data && values.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: null,
                                    sent_at: moment.utc(),
                                    delivered: null,
                                    delivered_at: null,
                                    undeliverable: null,
                                },
                            });
                            message.success(`SMS Notification sent to ${player1.name}`);
                            setLoading(false);
                            return values;
                        })
                        .catch(async (e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log(err);
                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: err && err.data && err.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: err,
                                    sent_at: moment.utc(),
                                    delivered: false,
                                    delivered_at: null,
                                    undeliverable: true,
                                },
                            });
                            setLoading(false);
                            message.error(`SMS Notification error: ${err}`);
                            return err;
                        });

                    if (scoreText) {
                        const scoringLinkToSend = `DigitalPool.com\n${scoreText}`;
                        // const scoringLinkToSend = `DigitalPool.com\n${scoreText}\n${moment().format('MM/DD/YYYY h:mm a')}`;
                        sendSMS({
                            phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                            message: scoringLinkToSend,
                            user_id: player1.id,
                            tournament_id: tournament.id,
                            // token: ''
                        })
                            .then(async (values) => {
                                console.log(values);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: values && values.data && values.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: null,
                                        sent_at: moment.utc(),
                                        delivered: null,
                                        delivered_at: null,
                                        undeliverable: null,
                                    },
                                });

                                return values;
                            })
                            .catch(async (e) => {
                                const err = (e && e.message) || JSON.stringify(e);
                                console.log(err);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: err && err.data && err.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: err,
                                        sent_at: moment.utc(),
                                        delivered: false,
                                        delivered_at: null,
                                        undeliverable: true,
                                    },
                                });

                                return err;
                            });
                    }
                } catch (e) {
                    const err = (e && e.message) || JSON.stringify(e);
                    console.log(err);
                    await logSMSMessage({
                        tournament,
                        match: currentMatch,
                        player: player1,
                        message: {
                            id: err && err.data && err.data['message-id'],
                            text: messageToSend,
                            sent: true,
                            error: err,
                            sent_at: moment.utc(),
                            delivered: null,
                            delivered_at: null,
                            undeliverable: true,
                        },
                    });
                    setLoading(false);
                    message.error(`SMS Notification error: ${err}`);
                }
            }
        } else {
            console.log('SMS notifications are currently disabled');
        }
    }

    function playerEliminated(player) {
        const playerId = player.challenger1_id || player.challenger2_id;

        if (playerId) {
            console.log('player eliminated', player);
            console.log('place', currentMatch.place);
            updateTournamentPlayer({
                variables: {
                    id: playerId,
                    tournament_id: tournament.id,
                    changes: {
                        place: currentMatch.is_finals === true ? '2' : currentMatch.is_consolidation_finals === true ? '2' : currentMatch.place,
                    },
                    // changes: {
                    // 	place:
                    // 		currentMatch.is_finals === true
                    // 			? '2'
                    // 			: currentMatch.is_consolidation_finals ? '2' : currentMatch.place
                    // }
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_QUERY,
                // 		variables: { id: tournament.id }
                // 	}
                // ]
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function completeTournament(winner) {
        const playerId = winner.challenger1_id || winner.challenger2_id;

        setTournamentComplete(true);
        if (winner.is_split === true) {
            updateTournamentPlayer({
                variables: {
                    id: winner.challenger1_id,
                    tournament_id: tournament.id,
                    changes: {
                        place: '1',
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            updateTournamentPlayer({
                variables: {
                    id: winner.challenger2_id,
                    tournament_id: tournament.id,
                    changes: {
                        place: '1',
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            updateTournamentPlayer({
                variables: {
                    id: playerId,
                    tournament_id: tournament.id,
                    changes: {
                        place: '1',
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }

        updateTableStatus({
            variables: {
                id: currentMatch.table_id,
                changes: {
                    status: 'OPEN',
                },
            },
        });

        updateTournament({
            variables: {
                id: tournament.id,
                changes: {
                    end_date_time: moment.utc(),
                    progress: '100',
                    status: 'COMPLETED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                onTournamentComplete(winner);
                // props.client
                //     .mutate({
                //         mutation: DELETE_TOURNAMENT_MATCH,
                //         variables: {
                //             id: currentMatch.id,
                //             tournament_id: tournament.id,
                //         },
                //     })
                //     .then(async (data) => {
                //         console.log(data);
                //         onTournamentComplete(winner);
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         message.info('There was an error', error);
                //     });
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function unassignAllTables() {
        const currentMatches = matches.filter((match) => {
            return match.status === 'IN_PROGRESS';
        });
        let result = currentMatches.reduce((accumulatorPromise, nextMatch) => {
            return accumulatorPromise.then(() => {
                return removeTableAssignment(nextMatch);
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success(`Removed all table assignemnts`);
            console.log('Removed all table assignments', currentMatches);
        });
    }

    async function removeTableAssignment(match) {
        const key = 'removing';
        message.loading({ content: `Removing table assignment...`, key, duration: 0 });
        updateTableStatus({
            variables: {
                id: match.table_id,
                changes: {
                    status: 'OPEN',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
                    variables: { tournament_id: tournament.id },
                    // awaitRefetchQueries: true,
                    // onCompleted: (data) => {
                    // 	console.log(data);
                    // },
                    // notifyOnNetworkStatusChange: true,
                    // fetchPolicy: 'cache-and-network'
                },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id },
                // 	awaitRefetchQueries: true
                // }
            ],
        });

        updateTournamentBracket({
            variables: {
                id: match.id,
                tournament_id: tournament.id,
                changes: {
                    table_name: null,
                    table_id: null,
                    status: 'NOT_STARTED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // }
                // {
                //     query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //     variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                //     awaitRefetchQueries: true,
                //     notifyOnNetworkStatusChange: true,
                // },
            ],
        })
            .then((data) => {
                onModalScoreVisible(false);
                message.success({
                    content: `Removed table assignment for Match ${match.match_number}.`,
                    key,
                    duration: 2,
                });
            })
            .catch((error) => {
                console.log(error);
                message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
            });
    }

    function checkTournamentWinner(isEliminated) {
        const playersEliminated = players.filter((player) => {
            return player.is_eliminated === false;
        });
        const playersLeft = isEliminated === true ? playersEliminated.length - 1 : playersEliminated.length;

        if (playersLeft === 1) {
            setConfirmEndTournamentPopupVisible(true);
        }
    }

    function checkIfFinalRound(isEliminated) {
        const payouts = tournament.tournament_payouts;
        console.log('payouts', payouts);

        const playersEliminated = players.filter((player) => {
            return player.is_eliminated === false;
        });
        const playersLeft = isEliminated === true ? playersEliminated.length - 1 : playersEliminated.length;
        console.log('players left', playersLeft);

        if (playersLeft && payouts && playersLeft === payouts.length) {
            console.log('enter final round');
            confirm({
                title: `There are only ${playersLeft} players left in the tournament. Would you like to re-shuffle the player order?`,
                centered: true,
                transitionName: 'fade',
                maskTransitionName: 'none',
                // onOk() {
                // 	return new Promise((resolve, reject) => {
                // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // 	}).catch(() => console.log('Oops errors!'));
                // },
                onOk() {
                    unassignAllTables();
                    reshufflePlayers();
                },
                onCancel() {},
                cancelText: 'Cancel',
            });
        }
    }

    function randomShuffle(ar, seed) {
        var numbers = [];
        for (var a = 0, max = ar.length; a < max; a++) {
            numbers.push(a);
        }
        var shuffled = [];

        for (var i = 0, len = ar.length; i < len; i++) {
            var r = parseInt(seed[i] * (len - i));
            shuffled.push(ar[numbers[r]]);
            numbers.splice(r, 1);
        }
        return shuffled;
    }

    function generateSeeds(playerData) {
        seed.set(playerData.length);
        const randShuffle = randomShuffle(playerData, seed.get());
        // const roundRobinPairings = makeRoundRobinPairings(randShuffle);
        // const roundRobinPairings2 = roundRobin(randShuffle.length, randShuffle);
        console.log('shuffle', randShuffle);
        const updatedPlayers = randShuffle.map((player) => {
            return {
                ...player,
            };
        });

        console.log('updated players', updatedPlayers);
        return _.compact(updatedPlayers);
    }

    function reshufflePlayers() {
        const seededPlayers = generateSeeds(players);
        // setTournamentPlayers(seededPlayers);

        const playersToUpdate = seededPlayers.map((player, index) => {
            return {
                id: player.id,
                seed: index + 1,
            };
        });
        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success(`Re-shuffle complete`);
            console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
        });
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                // notifyOnNetworkStatusChange: true,
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PLAYERS_W_STATS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then((data) => {
                // console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function advanceMatches(data) {
        const { winner, loser } = data;
        // console.log(data);
        // console.log(matches);
        console.log({
            winner,
            loser,
        });
        // console.log('current match', currentMatch);

        const playersLeft = players.filter((player) => {
            return player.is_eliminated === false;
        });

        if (playersLeft && playersLeft.length === 1) {
            setWinner(winner);

            if (winner.is_split === true) {
                completeTournament({
                    ...winner,
                    is_split: true,
                });
            } else {
                setConfirmEndTournamentPopupVisible(true);
                // if (tournamentComplete === false) {
                //     // advanceToWinnerSide({
                //     //     advanceTournamentBracket,
                //     //     currentMatch,
                //     //     match: nextWinnerMatchData,
                //     //     // nextRecord: nextWinnerSideMatch[0]
                //     // });

                //     // advanceToLoserSide({
                //     //     advanceTournamentBracket,
                //     //     currentMatch,
                //     //     match: nextLoserMatchData,
                //     //     // nextRecord: nextLoserSideMatch[0]
                //     // });

                //     console.log('MARK WINNER & LOSER FINAL MATCH');
                // }
            }
        } else {
            // alert('not finals or consolidation finals');
            setWinner(winner);

            if (winner.is_split === true) {
                completeTournament({
                    ...winner,
                    is_split: true,
                });
            } else {
                if (currentMatch && currentMatch.is_finals === true) {
                    setConfirmEndTournamentPopupVisible(true);
                }
            }

            // if (!nextLoserMatchData) {
            //     console.log('player eliminated');
            //     playerEliminated(loser);
            // }

            const winningPlayer = players.filter((player) => {
                if (player.id === winner.challenger1_id || player.id === winner.challenger2_id) {
                    return player;
                }
            })[0];

            const losingPlayer = players.filter((player) => {
                if (player.id === loser.challenger1_id || player.id === loser.challenger2_id) {
                    return player;
                }
            })[0];

            console.log({
                winningPlayer,
                losingPlayer,
            });

            let nextPlayerToAssign;

            if (playersLeft && playersLeft.length === 2) {
                if (!nextPlayerWaiting) {
                    nextPlayerToAssign = losingPlayer;
                } else {
                    nextPlayerToAssign = nextPlayerWaiting;
                }
            } else {
                nextPlayerToAssign = nextPlayerWaiting;
            }

            console.log('MARK WINNER & LOSER');
            console.log('matches', matches);
            console.log('current match', currentMatch);
            console.log('winner', winner);
            console.log('next player to assign', nextPlayerToAssign);

            const counter = matches.length + 1;

            const identifier = `R${currentMatch.round}-M${counter}`;
            const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
            const hashId = hashids.encode(randomNum, tournament.id, counter + 1);

            const newMatch = {
                // ...match,
                round: currentMatch.round,
                tournament_id: tournament.id,
                match_number: counter,
                identifier: identifier,
                hash_id: hashId,
                table_id: currentMatch.table_id,
                table_name: currentMatch.table_name,
                // ...(tournament.autopilot_mode === true && { table_id: currentMatch.table_id }),
                // ...(tournament.autopilot_mode === true && { table_name: currentMatch.table_name }),
                challenger1_id: winningPlayer.id,
                challenger1_name: winningPlayer.name,
                challenger1_seed: winningPlayer.seed,
                challenger1_country: winningPlayer.country,
                challenger1_score: 0,
                challenger1_race_to: winningPlayer.race_to || tournament.winners_race_to,
                challenger1_skill_level: winningPlayer.skill_level,
                ...(nextPlayerToAssign && { challenger2_id: nextPlayerToAssign.id }),
                ...(nextPlayerToAssign && { challenger2_name: nextPlayerToAssign.name }),
                ...(nextPlayerToAssign && { challenger2_seed: nextPlayerToAssign.seed }),
                ...(nextPlayerToAssign && { challenger2_country: nextPlayerToAssign.country }),
                ...(nextPlayerToAssign && { challenger2_score: 0 }),
                ...(nextPlayerToAssign && { challenger2_race_to: nextPlayerToAssign.race_to || tournament.winners_race_to }),
                ...(nextPlayerToAssign && { challenger2_skill_level: nextPlayerToAssign.skill_level }),
                status: tournament.autopilot_mode === true ? 'IN_PROGRESS' : 'NOT_STARTED',
            };

            console.log('new match', newMatch);

            createMatchMutation({
                variables: {
                    objects: newMatch,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_QUERY,
                        variables: {
                            tournamentId: tournament.id,
                        },
                    },
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournament.id, tournament_bracket_id: currentMatch.id },
                    },
                ],
            })
                .then((res) => {
                    console.log('res', res);
                    const key = 'assigned';
                    const createdMatch =
                        res &&
                        res.data &&
                        res.data.insert_tournament_brackets &&
                        res.data.insert_tournament_brackets.returning &&
                        res.data.insert_tournament_brackets.returning[0];
                    console.log('create match', createdMatch);

                    const losingPlayerChips = parseInt(losingPlayer.chips_left) > 0 ? parseInt(losingPlayer.chips_left) - 1 : 0;
                    const isEliminated = losingPlayerChips === 0 ? true : false;

                    const eliminatedPlayers = players.filter((player) => {
                        return player.is_eliminated === true;
                    });

                    let placeValue = eliminatedPlayers && players.length - eliminatedPlayers.length;

                    console.log('places', {
                        totalPlayers: players.length,
                        eliminated: eliminatedPlayers.length,
                        place: placeValue,
                    });

                    const place = isEliminated ? placeValue : null;
                    if (nextPlayerToAssign) {
                        message.success({
                            content: `${winningPlayer.name} assigned to ${createdMatch && createdMatch.table_name} ${
                                nextPlayerToAssign && `vs. ${nextPlayerToAssign.name}`
                            }`,
                            key,
                            duration: 2,
                        });
                    }

                    updateTournamentPlayer({
                        variables: {
                            id: losingPlayer.id,
                            tournament_id: tournament.id,
                            changes: {
                                chips_left: losingPlayerChips,
                                is_eliminated: isEliminated,
                                ...(place && { place: place.toString() }),
                                last_played_at: moment.utc(),
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_TOURNAMENT_PLAYERS_W_STATS,
                                variables: {
                                    tournament_id: tournament.id,
                                },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                            if (isEliminated === true) {
                                message.info({
                                    content: `${losingPlayer.name} was eliminated ${place && `in ${getOrdinalSuffix(place)} place`}`,
                                    key,
                                    duration: 5,
                                });
                            }

                            const matchesNeedingPlayers =
                                matches &&
                                matches.filter((match) => {
                                    return !match.challenger1_name || !match.challenger2_name;
                                });

                            console.log('matches needing players', matchesNeedingPlayers);

                            checkTournamentWinner(isEliminated);
                            checkIfFinalRound(isEliminated);

                            // assign loser to match needing players
                            if (matchesNeedingPlayers.length > 0 && isEliminated === false) {
                                const nextMatch = matchesNeedingPlayers[0];
                                if (nextMatch) {
                                    const changes = {
                                        challenger1_id: nextMatch.challenger1_id ? nextMatch.challenger1_id : losingPlayer.id,
                                        challenger1_name: nextMatch.challenger1_name ? nextMatch.challenger1_name : losingPlayer.name,
                                        challenger1_seed: nextMatch.challenger1_seed ? nextMatch.challenger1_seed : losingPlayer.seed,
                                        challenger1_country: nextMatch.challenger1_country ? nextMatch.challenger1_country : losingPlayer.country,
                                        challenger1_race_to: nextMatch.challenger1_race_to ? nextMatch.challenger1_race_to : losingPlayer.race_to,
                                        challenger1_skill_level: nextMatch.challenger1_race_to ? nextMatch.challenger1_race_to : losingPlayer.race_to,
                                        challenger1_score: 0,
                                        challenger2_id: nextMatch.challenger2_id ? nextMatch.challenger2_id : losingPlayer.id,
                                        challenger2_name: nextMatch.challenger2_name ? nextMatch.challenger2_name : losingPlayer.name,
                                        challenger2_seed: nextMatch.challenger2_seed ? nextMatch.challenger2_seed : losingPlayer.seed,
                                        challenger2_country: nextMatch.challenger2_country ? nextMatch.challenger2_country : losingPlayer.country,
                                        challenger2_race_to: nextMatch.challenger2_race_to ? nextMatch.challenger2_race_to : losingPlayer.race_to,
                                        challenger2_skill_level: nextMatch.challenger2_race_to ? nextMatch.challenger2_race_to : losingPlayer.race_to,
                                        challenger2_score: 0,
                                        start_time: moment.utc(),
                                        progress: 0,
                                        status: 'IN_PROGRESS',
                                    };
                                    console.log('filling empty match', changes);
                                    updateTournamentBracket({
                                        variables: {
                                            id: nextMatch.id,
                                            tournament_id: tournament.id,
                                            changes: changes,
                                        },
                                        // optimisticResponse: {
                                        // 	__typename: 'Mutation',
                                        // 	update_tournament_brackets: {
                                        // 		id: currentMatch.id,
                                        // 		tournament_id: tournament.id,
                                        // 		__typename: 'tournament_brackets',
                                        // 		changes: changes,
                                        // 		affected_rows: []
                                        // 	}
                                        // },
                                        notifyOnNetworkStatusChange: true,
                                        awaitRefetchQueries: true,
                                        refetchQueries: [
                                            // {
                                            // 	query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                                            // 	variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                                            // 	awaitRefetchQueries: true,
                                            // 	notifyOnNetworkStatusChange: true
                                            // }
                                            // {
                                            // 	query: GET_TOURNAMENT_TABLES_QUERY,
                                            // 	variables: { tournament_id: tournament.id }
                                            // },
                                            // {
                                            // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                                            // 	variables: { tournamentId: tournament.id }
                                            // }
                                        ],
                                    })
                                        .then((res) => {
                                            const createdMatch =
                                                res &&
                                                res.data &&
                                                res.data.update_tournament_brackets &&
                                                res.data.update_tournament_brackets.returning &&
                                                res.data.update_tournament_brackets.returning[0];
                                            console.log(createdMatch);
                                            const key = 'assigned';

                                            setLoading(false);

                                            message.success({
                                                content: `${losingPlayer.name} assigned to ${nextMatch && nextMatch.table_name} ${
                                                    nextMatch && `vs. ${nextMatch.challenger1_name || nextMatch.challenger2_name}`
                                                }`,
                                                key,
                                                duration: 5,
                                            });
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            setLoading(false);
                                            message.info('There was an error', error);
                                        });
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            message.info('There was an error', error);
                        });

                    if (tournament.autopilot_mode === true) {
                        updateTableStatus({
                            variables: {
                                id: currentMatch.table_id,
                                changes: {
                                    status: 'IN_USE',
                                },
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            /**
             * TODO: Create new match and put the winner in the top spot
             * Assign the match to the same table
             * Take a chip away from the loser and check to see if they are eliminated
             * Assign the "Up Next" player to the new match
             * Determine when to re-shuffle based on the number of players left
             */

            // advanceToWinnerSide({
            //     advanceTournamentBracket,
            //     currentMatch,
            //     match: nextWinnerMatchData,
            //     // nextRecord: nextWinnerSideMatch[0]
            // });

            // advanceToLoserSide({
            //     advanceTournamentBracket,
            //     currentMatch,
            //     match: nextLoserMatchData,
            //     // nextRecord: nextLoserSideMatch[0]
            // });
        }
    }

    async function updateTournamentProgress(value) {
        if (value) {
            await API.updateTournamentProgress(value);
            return value;
        } else {
            const inProgressMatches = matches.filter((match) => {
                return match.progress !== null;
            });

            const byeMatches = matches.filter((match) => {
                return match.is_bye === true;
            });

            const completedMatches = matches
                .filter((match) => {
                    return match.status === 'COMPLETED';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                });

            const progress = inProgressMatches
                .map((match) => {
                    return match.progress;
                })
                .reduce((acc, progress) => {
                    return acc + progress;
                }, 0);

            // console.log(matches.length);
            // console.log(inProgressMatches.length);
            const currentMatchesProgress = progress / inProgressMatches.length;
            const newProgress = (completedMatches.length / (matches.length - byeMatches.length)) * 100;
            console.log(currentMatchesProgress);
            console.log(newProgress);
            await API.updateTournamentProgress(newProgress);
            return newProgress;
        }
    }

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1'
                ? currentMatch.challenger1_race_to
                    ? currentMatch.challenger1_race_to
                    : currentMatch.challenger1 && currentMatch.challenger1.race_to
                : challenger === 'challenger2'
                ? currentMatch.challenger2_race_to
                    ? currentMatch.challenger2_race_to
                    : currentMatch.challenger2 && currentMatch.challenger2.race_to
                : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'L') {
                return tournament.losers_race_to;
            } else {
                return currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true
                    ? tournament.finals_race_to || tournament.winners_race_to
                    : tournament.winners_race_to;
            }
        }
    }

    function submitAdd(values) {
        console.log(values);

        let {
            challenger1_id,
            challenger1_name,
            challenger1_country,
            challenger1_seed,
            challenger1_score,
            challenger1_race_to,
            challenger1_skill_level,
            challenger2_id,
            challenger2_name,
            challenger2_country,
            challenger2_seed,
            challenger2_score,
            challenger2_race_to,
            challenger2_skill_level,
            table_id,
            table_name,
            scheduled_table_id,
            priority,
            scheduled_time,
        } = values;

        const counter = matches.length + 1;
        const round = matches[matches.length - 1] ? matches[matches.length - 1].round : 1;
        const identifier = `R${round}-M${counter}`;
        const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
        const hashId = hashids.encode(randomNum, tournament.id, counter + 1);
        const key = 'create';

        createMatchMutation({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        round: round,
                        match_number: counter,
                        identifier: identifier,
                        hash_id: hashId,
                        table_id: table_id,
                        table_name: table_name,
                        challenger1_id,
                        challenger1_name,
                        challenger1_seed,
                        challenger1_country,
                        challenger1_score,
                        challenger1_race_to: challenger1_race_to || tournament.winners_race_to,
                        challenger1_skill_level,
                        challenger1_is_winner: false,
                        challenger1_is_forfeit: false,
                        challenger1_is_withdraw: false,
                        challenger2_id,
                        challenger2_name,
                        challenger2_seed,
                        challenger2_country,
                        challenger2_score,
                        challenger2_race_to: challenger2_race_to || tournament.winners_race_to,
                        challenger2_skill_level,
                        challenger2_is_winner: false,
                        challenger2_is_forfeit: false,
                        challenger2_is_withdraw: false,
                        scheduled_time: scheduled_time,
                        scheduled_table_id: scheduled_table_id,
                        priority: priority,
                        status: 'NOT_STARTED',
                        progress: 0,
                        is_bye: false,
                        is_split: false,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_QUERY,
                    variables: {
                        tournamentId: tournament.id,
                    },
                },
            ],
        })
            .then((res) => {
                console.log('res', res);
                setLoading(false);
                message.success(`New match ${counter} created`);

                updateTableStatus({
                    variables: {
                        id: table_id,
                        changes: {
                            status: 'IN_USE',
                        },
                    },
                    // awaitRefetchQueries: true,
                    // refetchQueries: [
                    //     {
                    //         query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                    //         variables: {
                    //             tournament_id: tournament && tournament.id,
                    //         },
                    //     },
                    // ],
                })
                    .then((data) => {
                        // if (tableStatus === 'ASSIGNED') {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                handleScoreModalCancel();
            })
            .catch((error) => {
                console.log(error);
                message.error({
                    content: `There was an error ${JSON.stringify(error)}`,
                    key,
                    duration: 2,
                });
            });
    }

    function isPlayerInMatch(player) {
        const matchPlayer = matches
            .filter((match) => {
                return match.status === 'IN_PROGRESS';
            })
            .filter((match) => {
                return match.challenger1_id === (player && player.id) || match.challenger2_id === (player && player.id);
            })[0];
        if (matchPlayer) {
            return true;
        } else {
            return false;
        }
    }

    function getPlayerTable(player) {
        console.log(player);
        let table;
        const matchPlayer = matches
            .filter((match) => {
                return match.status === 'IN_PROGRESS';
            })
            .filter((match) => {
                return match.challenger1_id === (player && player.id) || match.challenger2_id === (player && player.id);
            })[0];
        if (matchPlayer) {
            table = matchPlayer.pool_table;
        }
        return table;
    }

    function submitUpdate(values) {
        console.log(values);
        let challenger1_seed;
        let challenger1_country;
        let challenger1_id = values.challenger1_id && values.challenger1_name ? values.challenger1_id : null;
        let challenger2_id = values.challenger2_id && values.challenger2_name ? values.challenger2_id : null;
        let challenger1_race_to = challenger1_id && values.challenger1_race_to ? values.challenger1_race_to : tournament.winners_race_to;
        let challenger2_race_to = challenger2_id && values.challenger2_race_to ? values.challenger2_race_to : tournament.winners_race_to;
        let challenger1_sms_sent;
        let challenger2_seed;
        let challenger2_country;
        let challenger2_sms_sent;
        let challenger1 = currentMatch.challenger1;
        let challenger2 = currentMatch.challenger2;
        let player1;
        let player2;
        // let start_time;
        let end_time;
        let matchStatus;
        let tableStatus;
        let winner;
        let loser;
        let results;
        let is_bye;
        let is_split;
        let {
            challenger1_name,
            challenger1_score,
            challenger2_name,
            challenger2_score,
            table_id,
            scheduled_table_id,
            priority,
            start_time,
            // end_time
            // scheduled_time
        } = values;
        console.log('MATCH DATA', values);

        setLoading(true);

        // returns true if the variable does NOT contain a valid number
        if (isNaN(challenger1_name) !== true) {
            const id = parseInt(challenger1_name);
            player1 = players.filter((item) => {
                return item.id === id;
            })[0];
        } else {
            player1 = players.filter((item) => {
                return item.name === challenger1_name;
            })[0];
        }

        if (isNaN(challenger2_name) !== true) {
            const id = parseInt(challenger2_name);
            player2 = players.filter((item) => {
                return item.id === id;
            })[0];
        } else {
            player2 = players.filter((item) => {
                return item.name === challenger2_name;
            })[0];
        }

        console.log('players', {
            player1,
            player2,
        });

        challenger1_name = player1 && player1.name ? player1.name : challenger1_name;
        challenger1_id = player1 && player1.id ? player1.id : challenger1_id;
        challenger1_seed = player1 && player1.seed ? player1.seed : challenger1_seed;
        challenger1_country = player1 && player1.country ? player1.country : challenger1_country;
        challenger1_sms_sent = player1 && player1.sms_sent ? player1.sms_sent : challenger1_sms_sent;
        challenger2_name = player2 && player2.name ? player2.name : challenger2_name;
        challenger2_id = player2 && player2.id ? player2.id : challenger2_id;
        challenger2_seed = player2 && player2.seed ? player2.seed : challenger2_seed;
        challenger2_country = player2 && player2.country ? player2.country : challenger2_country;
        challenger2_sms_sent = player2 && player2.sms_sent ? player2.sms_sent : challenger2_sms_sent;

        if (challenger1_name && challenger1_name !== 'BYE' && challenger2_name && challenger2_name !== 'BYE') {
            is_bye = false;
        } else if (challenger1_name === 'BYE' || challenger2_name === 'BYE') {
            is_bye = true;
        }

        if (challenger1Result === 'split' || challenger2Result === 'split') {
            is_split = true;
        } else {
            is_split = false;
        }

        if (challenger1Result === 'forfeit' || challenger2Result === 'forfeit' || challenger1Result === 'withdraw' || challenger2Result === 'withdraw') {
            matchStatus = 'COMPLETED';
            tableStatus = 'OPEN';
            end_time = moment.utc();
            results = getWinnerLoser({
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_country,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_country,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
            });
            winner = results.winner;
            loser = results.loser;
        } else if (challenger1Result === 'split' || challenger2Result === 'split') {
            matchStatus = 'COMPLETED';
            tableStatus = 'OPEN';
            end_time = moment.utc();
            results = getWinnerLoser({
                challenger1,
                challenger1_id,
                challenger1_name,
                challenger1_country,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger2,
                challenger2_id,
                challenger2_name,
                challenger2_country,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
            });
            winner = results.winner;
            loser = results.loser;
        } else if (table_id) {
            if (
                challenger1Result === 'winner' ||
                challenger2Result === 'winner' ||
                challenger1_score === challenger1_race_to ||
                challenger2_score === challenger2_race_to
            ) {
                matchStatus = 'COMPLETED';
                tableStatus = 'OPEN';
                end_time = moment.utc();
                results = getWinnerLoser({
                    challenger1,
                    challenger1_id,
                    challenger1_name,
                    challenger1_country,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger2,
                    challenger2_id,
                    challenger2_name,
                    challenger2_country,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                });
                winner = results.winner;
                loser = results.loser;
            } else if (
                (challenger1_score && challenger1_score > 0 && challenger1_score !== challenger1_race_to) ||
                (challenger2_score && challenger2_score > 0 && challenger2_score !== challenger2_race_to)
            ) {
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else if (
                challenger1_name &&
                challenger2_name &&
                (challenger1_score === null || challenger1_score === 0) &&
                (challenger2_score === null || challenger1_score === 0)
            ) {
                // tableStatus = 'ASSIGNED';
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else if (
                ((challenger1_name || challenger2_name) && (challenger1_score === null || challenger1_score === 0)) ||
                challenger2_score === null ||
                challenger1_score === 0
            ) {
                // tableStatus = 'ASSIGNED';
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else {
                tableStatus = 'IN_USE';
                matchStatus = 'NOT_STARTED';
                if (!start_time) {
                    start_time = moment.utc();
                }
            }
        } else {
            if (challenger1Result === 'winner' || challenger2Result === 'winner' || challenger1_score || challenger2_score) {
                if (
                    challenger1Result === 'winner' ||
                    challenger2Result === 'winner' ||
                    challenger1_score === challenger1_race_to ||
                    challenger2_score === challenger2_race_to
                ) {
                    matchStatus = 'COMPLETED';
                    tableStatus = 'OPEN';
                    end_time = moment.utc();
                    results = getWinnerLoser({
                        challenger1,
                        challenger1_id,
                        challenger1_name,
                        challenger1_country,
                        challenger1_score,
                        challenger1_race_to,
                        challenger1_seed,
                        challenger2,
                        challenger2_id,
                        challenger2_name,
                        challenger2_country,
                        challenger2_score,
                        challenger2_race_to,
                        challenger2_seed,
                    });
                    winner = results.winner;
                    loser = results.loser;
                } else {
                    tableStatus = 'IN_USE';
                    matchStatus = 'IN_PROGRESS';
                    if (!start_time) {
                        start_time = moment.utc();
                    }
                }
            } else {
                matchStatus = 'NOT_STARTED';
                tableStatus = 'OPEN';
            }
        }

        // console.log('table status:', tableStatus);
        // console.log('match status:', matchStatus);
        console.log('winner:', winner);
        console.log('loser:', loser);

        if (matchStatus === 'NOT_STARTED') {
            const player1InMatch = isPlayerInMatch(player1);
            const player2InMatch = isPlayerInMatch(player2);
            if (player1InMatch === true || player2InMatch === true) {
                if (player1InMatch && player2InMatch) {
                    message.error('Both players are already in a match');
                } else if (!player1InMatch && player2InMatch) {
                    const table = getPlayerTable(player2);
                    message.error(`${player2.name} is already playing on ${table && table.label}`);
                } else if (player1InMatch && !player2InMatch) {
                    const table = getPlayerTable(player1);
                    message.error(`${player1.name} is already playing on ${table && table.label}`);
                }
                setLoading(false);
                handleScoreModalCancel();
                return false;
            }
        }

        if (!challenger1_name && !challenger2_name && is_split === true) {
            message.error('Unable to split without 2 players!');
            setLoading(false);
            handleScoreModalCancel();
            return false;
        }

        // console.log(table_id);
        // console.log(tables);
        // console.log(finals);

        const table = tables.filter((item) => {
            return item.id === table_id;
        })[0];

        const totalMatches = parseInt(challenger1_race_to + challenger2_race_to) - 1;
        const challengerScores = parseInt(challenger1_score + challenger2_score);
        const matchProgress =
            matchStatus === 'COMPLETED'
                ? 100
                : challenger1_score === challenger1_race_to || challenger2_score === challenger2_race_to
                ? 100
                : ((challengerScores / totalMatches) * 100).toFixed(0);

        if (currentMatch.status !== 'COMPLETED' && currentMatch.table_id && currentMatch.table_id !== table_id) {
            console.log('SWITCH TABLES');
            // Switch the current table back to OPEN before assigning a new table
            updateTableStatus({
                variables: {
                    id: currentMatch.table_id,
                    changes: {
                        status: 'OPEN',
                    },
                },
            });
        }

        if (table_id) {
            console.log(`Table ${table.label}`, tableStatus);
            if (!challenger1_name && !challenger2_name) {
                // message.error('Unable to assign table without 2 players!');
                message.info('Removing table assignment and deleting match...');
                // setLoading(false);
                // handleScoreModalCancel();
                // return false;
                const key = 'delete';
                updateTableStatus({
                    variables: {
                        id: currentMatch.table_id,
                        changes: {
                            status: 'OPEN',
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
                            variables: { tournament_id: tournament.id },
                            // awaitRefetchQueries: true,
                            // onCompleted: (data) => {
                            // 	console.log(data);
                            // },
                            // notifyOnNetworkStatusChange: true,
                            // fetchPolicy: 'cache-and-network'
                        },
                        // {
                        // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                        // 	variables: { tournamentId: tournament.id },
                        // 	awaitRefetchQueries: true
                        // }
                    ],
                })
                    .then(async (data) => {
                        console.log(data);

                        props.client
                            .mutate({
                                mutation: DELETE_TOURNAMENT_MATCH,
                                variables: {
                                    id: currentMatch.id,
                                    tournament_id: tournament.id,
                                },
                            })
                            .then(async (data) => {
                                console.log(data);

                                setLoading(false);
                                handleScoreModalCancel();
                            })
                            .catch((error) => {
                                console.log(error);
                                message.error({
                                    content: `There was an error ${JSON.stringify(error)}`,
                                    key,
                                    duration: 2,
                                });
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });
            }
            if (!challenger1_score && !challenger2_score && tableStatus === 'IN_USE') {
                matchStatus = matchStatus !== 'COMPLETED' && scheduledTime ? 'IN_PROGRESS' : matchStatus;
                if (player1 && player2 && player1.phone_number && player2.phone_number) {
                    player1.race_to = challenger1_race_to;
                    player2.race_to = challenger2_race_to;
                    notifyPlayer(player1, player2, table, currentMatch);
                    notifyPlayer(player2, player1, table, currentMatch);
                } else {
                    if (player1 && player1.phone_number) {
                        player1.race_to = challenger1_race_to;
                        notifyPlayer(player1, player2, table, currentMatch);
                    }
                    if (player2 && player2.phone_number) {
                        player2.race_to = challenger2_race_to;
                        notifyPlayer(player2, player1, table, currentMatch);
                    }
                }
            }
            updateTableStatus({
                variables: {
                    id: table_id,
                    changes: {
                        status: tableStatus,
                    },
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                //         variables: {
                //             tournament_id: tournament && tournament.id,
                //         },
                //     },
                // ],
            })
                .then((data) => {
                    // if (tableStatus === 'ASSIGNED') {
                    console.log(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            matchStatus = matchStatus !== 'COMPLETED' && scheduledTime ? 'SCHEDULED' : matchStatus;
        }

        if (matchStatus === 'COMPLETED') {
            console.log('match completed', currentMatch);

            const advance = advanceMatches({
                winner,
                loser,
            });
            // console.log(currentRound);

            const totalMatches = (currentRound && currentRound.length) || 0;
            let completedMatches = 1;
            currentRound &&
                currentRound.forEach((match) => {
                    if (match.status === 'COMPLETED') {
                        completedMatches++;
                    }
                });
            const matchesLeft = totalMatches - completedMatches;

            console.log('completed matches', completedMatches);
            console.log('matches left', matchesLeft);
            console.log('current round', currentRound);
            console.log('current match', currentMatch);
            console.log('tables', tables.length);
            console.log('winner_side_race', tournament.winner_side_race_to);
            console.log('loser_side_race', tournament.loser_side_race_to);
            console.log('average time per game', 5);

            console.log('advance to', advance);

            if (is_split === true) {
                updateTournamentProgress(100);
                message.success(`Match ${currentMatch.match_number} completed. Both players split 1st place.`);
            } else {
                const playersLeft = players.filter((player) => {
                    return player.is_eliminated === false;
                });

                console.log(playersLeft);

                let progress;
                if (playersLeft) {
                    progress = playersLeft.length > 0 ? 100 / playersLeft.length : 100;
                    updateTournamentProgress(progress);
                } else {
                    updateTournamentProgress();
                }

                message.success(`Match ${currentMatch.match_number} completed. ${winner && (winner.challenger1_name || winner.challenger2_name)} won!`);
            }

            setLoading(false);
        }

        let changes;
        if (winner && loser) {
            // remove challenger1 and challenger2 keys from winner and loser objects
            let key1 = 'challenger1';
            let key2 = 'challenger2';
            let { [key1]: c1, [key2]: c2, ...winnerNoChallenger } = winner;

            let key3 = 'challenger1';
            let key4 = 'challenger2';
            let { [key3]: c3, [key4]: c4, ...loserNoChallenger } = loser;

            changes = {
                ...winnerNoChallenger,
                ...loserNoChallenger,
            };
            console.log('winner and loser', changes);
        } else {
            changes = {
                challenger1_id,
                challenger1_name,
                challenger1_country: challenger1_country || null,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed: challenger1_seed || null,
                challenger1_is_winner: false,
                challenger1_is_forfeit: false,
                challenger1_is_withdraw: false,
                challenger2_id,
                challenger2_name,
                challenger2_country: challenger2_country || null,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed: challenger2_seed || null,
                challenger2_is_winner: false,
                challenger2_is_forfeit: false,
                challenger2_is_withdraw: false,
            };
            console.log('no winner or loser yet', changes);
        }

        changes = {
            ...changes,
            start_time,
            end_time,
            ...(matchColor && { match_color: matchColor === 'transparent' ? null : matchColor }),
            scheduled_time: scheduledTime,
            table_name: table && table.label,
            scheduled_table_id: scheduled_table_id,
            priority: priority,
            table_id: table && table_id,
            status: matchStatus,
            progress: parseInt(matchProgress),
            is_bye,
            is_split,
        };

        console.log({
            id: currentMatch.id,
            tournament_id: tournament.id,
            changes: changes,
        });

        updateTournamentBracket({
            variables: {
                id: currentMatch.id,
                tournament_id: tournament.id,
                changes: changes,
            },
            // optimisticResponse: {
            // 	__typename: 'Mutation',
            // 	update_tournament_brackets: {
            // 		id: currentMatch.id,
            // 		tournament_id: tournament.id,
            // 		__typename: 'tournament_brackets',
            // 		changes: changes,
            // 		affected_rows: []
            // 	}
            // },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                // 	variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                // 	awaitRefetchQueries: true,
                // 	notifyOnNetworkStatusChange: true
                // }
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id }
                // }
            ],
        })
            .then((data) => {
                onModalScoreVisible(false);
                setLoading(false);
                setChallenger1Result();
                setChallenger2Result();
                if (onSave) {
                    onSave(currentMatch);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function handleMenuClick(e, position) {
        console.log('click', e);
        console.log(formRef.current);

        if (e.key === 'forfeit') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
                is_split: false,
            });
        } else if (e.key === 'withdraw') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
                is_split: false,
            });
        } else if (e.key === 'split') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
                is_split: true,
            });
        }
        if (position === 'top') {
            if (e.key !== 'none') {
                setChallenger1Result(e.key);
            } else {
                setChallenger1Result();
            }
            setChallenger2Result();
        } else if (position === 'bottom') {
            if (e.key !== 'none') {
                setChallenger2Result(e.key);
            } else {
                setChallenger2Result();
            }
            setChallenger1Result();
        }
    }

    function confirmEditCompletedMatch(form) {
        formRef.current.handleSubmit();
    }

    function cancelEditCompletedMatch(e) {
        handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function cancelEndTournament(e) {
        setConfirmEndTournamentPopupVisible(false);
    }

    function handleVisibleChange(visible) {
        setConfirmPopupVisible(visible);
    }

    function confirmRemoveTableAssignment() {
        handleRemoveTableAssignment();
    }

    function cancelRemoveTableAssignment(e) {
        // handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function handleRemoveTableVisibleChange(visible) {
        setConfirmTablePopupVisible(visible);
    }

    const MatchSchema = Yup.object().shape({
        // challenger1_name: Yup.string().nullable().required('Required'),
        // challenger2_name: Yup.string().nullable().required('Required'),
        // challenger1_score: Yup.number().nullable(),
        // challenger2_score: Yup.number().nullable()
        // division_type: Yup.string().required('Required'),
        // allowed_games: Yup.string().required('Required')
    });

    const actionsMenuTop = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'top');
            }}
            selectedKeys={[challenger1Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            {currentMatch && (currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true) && <Menu.Item key="split">Split</Menu.Item>}
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Item key="withdraw">Withdraw</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    const actionsMenuBottom = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'bottom');
            }}
            selectedKeys={[challenger2Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            {currentMatch && (currentMatch.is_finals === true || currentMatch.is_consolidation_finals === true) && <Menu.Item key="split">Split</Menu.Item>}
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Item key="withdraw">Withdraw</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    async function handleRemoveTableAssignment() {
        const key = 'removing';
        message.loading({ content: `Removing table assignment...`, key, duration: 0 });
        updateTableStatus({
            variables: {
                id: currentMatch.table_id,
                changes: {
                    status: 'OPEN',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY,
                    variables: { tournament_id: tournament.id },
                    // awaitRefetchQueries: true,
                    // onCompleted: (data) => {
                    // 	console.log(data);
                    // },
                    // notifyOnNetworkStatusChange: true,
                    // fetchPolicy: 'cache-and-network'
                },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id },
                // 	awaitRefetchQueries: true
                // }
            ],
        });

        if (currentMatch.challenger1_id && currentMatch.challenger2_id) {
            updateTournamentBracket({
                variables: {
                    id: currentMatch.id,
                    tournament_id: tournament.id,
                    changes: {
                        table_name: null,
                        table_id: null,
                        status: 'NOT_STARTED',
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    // {
                    // 	query: GET_TOURNAMENT_TABLES_QUERY,
                    // 	variables: { tournament_id: tournament.id }
                    // }
                    // {
                    //     query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    //     variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                    //     awaitRefetchQueries: true,
                    //     notifyOnNetworkStatusChange: true,
                    // },
                ],
            })
                .then((data) => {
                    onModalScoreVisible(false);
                    message.success({
                        content: `Removed table assignment for Match ${currentMatch.match_number}.`,
                        key,
                        duration: 2,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
                });
        } else {
            props.client
                .mutate({
                    mutation: DELETE_TOURNAMENT_MATCH,
                    variables: {
                        id: currentMatch.id,
                        tournament_id: tournament.id,
                    },
                    notifyOnNetworkStatusChange: true,
                })
                .then((data) => {
                    onModalScoreVisible(false);
                    message.success({
                        content: `Removed table assignment for Match ${currentMatch.match_number}.`,
                        key,
                        duration: 2,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
                });
        }
    }

    async function getFargoCharts(type, size, player1Fargo, player2Fargo, match) {
        const typesEnum = {
            hot: 2,
            medium: 1,
            mild: 0,
        };
        const url = `https://lms.fargorate.com/api/ratingcalc/racesbylengthandtype?length=${size}&type=${typesEnum[type]}&ratingOne=${player1Fargo}&ratingTwo=${player2Fargo}`;

        return await axios
            .get(url)
            .then((response) => {
                const results = response.data;
                console.log(results);
                const closestChart = results.filter((item) => item.closest === true)[0];
                const player1RaceTo =
                    player1Fargo > player2Fargo
                        ? closestChart.highPlayerRaceTo
                        : player1Fargo < player2Fargo
                        ? closestChart.lowPlayerRaceTo
                        : closestChart.highPlayerRaceTo;
                const player2RaceTo =
                    player2Fargo > player1Fargo
                        ? closestChart.highPlayerRaceTo
                        : player2Fargo < player1Fargo
                        ? closestChart.lowPlayerRaceTo
                        : closestChart.highPlayerRaceTo;

                const updatedMatch = {
                    ...match,
                    challenger1_race_to: player1RaceTo,
                    challenger2_race_to: player2RaceTo,
                };

                return updatedMatch;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function getUSAPLRaces() {
        const player1Fargo = currentMatch.challenger1 && currentMatch.challenger1.skill_level;
        const player2Fargo = currentMatch.challenger2 && currentMatch.challenger2.skill_level;
        if (!player1Fargo || !player2Fargo) {
            message.error('Both players must have a Fargo rating to calculate a USAPL race.');
            return;
        }
        let races;

        if (tournament.usapl_race_format === 'longer_races') {
            races = calculateUSAPLRace(1.0, player1Fargo, player2Fargo);
        } else if (tournament.usapl_race_format === 'shorter_races') {
            races = calculateUSAPLRace(0.75, player1Fargo, player2Fargo);
        }

        formRef.current.setValues({
            ...formRef.current.state.values,
            challenger1_race_to: races.p1RaceTo,
            challenger2_race_to: races.p2RaceTo,
        });
    }

    function getFargoRaces(race) {
        let promises = [];
        const key = 'fargo';
        let raceTo;
        if (currentMatch && currentMatch.identifier && currentMatch.identifier.includes('L')) {
            raceTo = race || tournament.losers_race_to;
        } else {
            raceTo = race || tournament.winners_race_to;
        }

        // const raceTo = race || tournament.winners_race_to;
        // const raceTo = race || tournament.winners_race_to;

        const player1Fargo = currentMatch.challenger1 && currentMatch.challenger1.skill_level;
        const player2Fargo = currentMatch.challenger2 && currentMatch.challenger2.skill_level;

        if (player1Fargo && player2Fargo) {
            if (tournament.handicap_format === 'fargo_hot_column') {
                promises.push(getFargoCharts('hot', raceTo, player1Fargo, player2Fargo, currentMatch));
            } else if (tournament.handicap_format === 'fargo_medium_column') {
                promises.push(getFargoCharts('medium', raceTo, player1Fargo, player2Fargo, currentMatch));
            } else if (tournament.handicap_format === 'fargo_mild_column') {
                promises.push(getFargoCharts('mild', raceTo, player1Fargo, player2Fargo, currentMatch));
            } else {
                message.warning('This tournament has not been enabled for Fargo Race Charts.');
                return;
            }

            message.loading({ content: 'Getting recommended Fargo Race for players...', key, duration: 1 });
            setFargoLoading(true);
            return new Promise(async (resolve, reject) => {
                Promise.all(promises)
                    .then((match) => {
                        console.log('tournament', tournament);
                        console.log('match', match);

                        if (match && match[0]) {
                            let challenger1Race = parseInt(match[0].challenger1_race_to);
                            let challenger2Race = parseInt(match[0].challenger2_race_to);
                            let finalChallenger1Race;
                            let finalChallenger2Race;

                            console.log('challenger1 race', challenger1Race);
                            console.log('challenger2 race', challenger2Race);

                            if (currentMatch && currentMatch.identifier && currentMatch.identifier.includes('L')) {
                                if (tournament.losers_max_race) {
                                    if (challenger1Race < tournament.losers_max_race) {
                                        finalChallenger1Race = challenger1Race;
                                    } else {
                                        finalChallenger1Race = tournament.losers_max_race;
                                    }

                                    if (challenger2Race < tournament.losers_max_race) {
                                        finalChallenger2Race = challenger2Race;
                                    } else {
                                        finalChallenger2Race = tournament.losers_max_race;
                                    }
                                } else {
                                    finalChallenger1Race = challenger1Race;
                                    finalChallenger2Race = challenger2Race;
                                }
                            } else {
                                if (tournament.winners_max_race) {
                                    if (challenger1Race < tournament.winners_max_race) {
                                        finalChallenger1Race = challenger1Race;
                                    } else {
                                        finalChallenger1Race = tournament.winners_max_race;
                                    }

                                    if (challenger2Race < tournament.winners_max_race) {
                                        finalChallenger2Race = challenger2Race;
                                    } else {
                                        finalChallenger2Race = tournament.winners_max_race;
                                    }
                                } else {
                                    finalChallenger1Race = challenger1Race;
                                    finalChallenger2Race = challenger2Race;
                                }
                            }

                            console.log('final challenger1 race', finalChallenger1Race);
                            console.log('final challenger2 race', finalChallenger2Race);

                            formRef.current.setValues({
                                ...formRef.current.state.values,
                                challenger1_race_to: finalChallenger1Race,
                                challenger2_race_to: finalChallenger2Race,
                            });
                            setFargoLoading(false);

                            message.success({
                                content: `${match[0].challenger1_name} goes to ${finalChallenger1Race} and ${match[0].challenger2_name} goes to ${finalChallenger2Race}`,
                                key,
                                duration: 2,
                            });

                            resolve({
                                challenger1_race_to: finalChallenger1Race,
                                challenger2_race_to: finalChallenger2Race,
                            });
                        } else {
                            resolve(match);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        message.error({ content: JSON.stringify(e), key, duration: 2 });
                        setFargoLoading(false);
                        reject(e);
                    });
            }).catch((e) => {
                console.log(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
                setFargoLoading(false);
                return e;
            });
        } else {
            if (!player1Fargo) {
                message.warning(`${currentMatch.challenger1_name} does not yet have a Fargo Rating assigned.`);
            } else if (!player2Fargo) {
                message.warning(`${currentMatch.challenger2_name} does not yet have a Fargo Rating assigned.`);
            } else {
                message.warning('Both players must have Fargo Rating assigned using the Skill Level field in the Players list.');
            }

            return;
        }
    }

    function showConfirmDeleteMatch() {
        confirm({
            title: `Are you sure you want to delete this match?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            onOk() {
                const key = 'delete';
                props.client
                    .mutate({
                        mutation: DELETE_TOURNAMENT_MATCH,
                        variables: {
                            id: currentMatch.id,
                            tournament_id: tournament.id,
                        },
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        //     {
                        //         query: GET_TOURNAMENT_PAYOUTS,
                        //         variables: { tournament_id: tournament.id },
                        //     },
                        // ],
                    })
                    .then(async (data) => {
                        console.log(data);
                        handleScoreModalCancel();
                        // props.client
                        //     .mutate({
                        //         mutation: RESET_TABLE_STATUS_MUTATION,
                        //         variables: {
                        //             tournament_id: tournament.id,
                        //         },
                        //         awaitRefetchQueries: true,
                        //         refetchQueries: [
                        //             {
                        //                 query: GET_TOURNAMENT_TABLES_QUERY,
                        //                 variables: { tournament_id: tournament.id, status: 'OPEN' },
                        //             },
                        //         ],
                        //     })
                        //     .then((data) => {
                        //         console.log(data);
                        //     })
                        //     .catch((e) => {
                        //         console.error(e);
                        //         message.error({ content: JSON.stringify(e), key, duration: 2 });
                        //     });
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function showConfirmResendNotification() {
        confirm({
            title: `Are you sure you want to re-send the match assignment SMS notification to both players?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                let player1;
                let player2;

                // returns true if the variable does NOT contain a valid number
                if (isNaN(currentMatch.challenger1_name) !== true) {
                    const id = parseInt(currentMatch.challenger1_name);
                    player1 = players.filter((item) => {
                        return item.id === id;
                    })[0];
                } else {
                    player1 = players.filter((item) => {
                        return item.name === currentMatch.challenger1_name;
                    })[0];
                }

                if (isNaN(currentMatch.challenger2_name) !== true) {
                    const id = parseInt(currentMatch.challenger2_name);
                    player2 = players.filter((item) => {
                        return item.id === id;
                    })[0];
                } else {
                    player2 = players.filter((item) => {
                        return item.name === currentMatch.challenger2_name;
                    })[0];
                }

                const table = tables.filter((item) => {
                    return item.id === currentMatch.table_id;
                })[0];

                console.log('players', {
                    player1,
                    player2,
                    currentMatch,
                    table,
                });

                if (!currentMatch.challenger1_score && !currentMatch.challenger2_score && table.status === 'IN_USE') {
                    if (player1 && player2 && player1.phone_number && player2.phone_number) {
                        player1.race_to = currentMatch.challenger1_race_to;
                        player2.race_to = currentMatch.challenger2_race_to;
                        notifyPlayer(player1, player2, table, currentMatch);
                        notifyPlayer(player2, player1, table, currentMatch);
                    } else {
                        if (player1 && player1.phone_number) {
                            player1.race_to = currentMatch.challenger1_race_to;
                            notifyPlayer(player1, player2, table, currentMatch);
                        }
                        if (player2 && player2.phone_number) {
                            player2.race_to = currentMatch.challenger2_race_to;
                            notifyPlayer(player2, player1, table, currentMatch);
                        }
                    }
                }
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function getHandicapRace(player1, player2, currentMatch) {
        const player1SkillLevel = player1.skill_level;
        const player2SkillLevel = player2.skill_level;
        let player1Handicap;
        let player2Handicap;

        if (player1SkillLevel) {
            if (player1SkillLevel <= 648) {
                player1Handicap = 'C';
            } else if (player1SkillLevel >= 649 && player1SkillLevel <= 699) {
                player1Handicap = 'B';
            } else if (player1SkillLevel >= 700 && player1SkillLevel <= 799) {
                player1Handicap = 'A';
            } else if (player1SkillLevel >= 800) {
                player1Handicap = 'A';
            } else {
                player2Handicap = '';
            }
        } else {
            player1Handicap = '';
        }

        if (player2SkillLevel) {
            if (player2SkillLevel <= 648) {
                player2Handicap = 'C';
            } else if (player2SkillLevel >= 649 && player2SkillLevel <= 699) {
                player2Handicap = 'B';
            } else if (player2SkillLevel >= 700 && player2SkillLevel <= 799) {
                player2Handicap = 'A';
            } else if (player2SkillLevel >= 800) {
                player2Handicap = 'A';
            } else {
                player2Handicap = '';
            }
        } else {
            player2Handicap = '';
        }

        console.log({
            player1SkillLevel,
            player2SkillLevel,
            player1Handicap,
            player2Handicap,
        });

        const races = {
            winners: {
                AA: {
                    player1Race: 7,
                    player2Race: 7,
                },
                AB: {
                    player1Race: 7,
                    player2Race: 6,
                },
                AC: {
                    player1Race: 8,
                    player2Race: 6,
                },
                BA: {
                    player1Race: 6,
                    player2Race: 7,
                },
                BB: {
                    player1Race: 7,
                    player2Race: 7,
                },
                BC: {
                    player1Race: 7,
                    player2Race: 6,
                },
                CA: {
                    player1Race: 8,
                    player2Race: 6,
                },
                CB: {
                    player1Race: 6,
                    player2Race: 7,
                },
                CC: {
                    player1Race: 7,
                    player2Race: 7,
                },
            },
            losers: {
                AA: {
                    player1Race: 5,
                    player2Race: 5,
                },
                AB: {
                    player1Race: 5,
                    player2Race: 4,
                },
                AC: {
                    player1Race: 6,
                    player2Race: 4,
                },
                BA: {
                    player1Race: 4,
                    player2Race: 5,
                },
                BB: {
                    player1Race: 5,
                    player2Race: 5,
                },
                BC: {
                    player1Race: 5,
                    player2Race: 4,
                },
                CA: {
                    player1Race: 4,
                    player2Race: 6,
                },
                CB: {
                    player1Race: 4,
                    player2Race: 5,
                },
                CC: {
                    player1Race: 5,
                    player2Race: 5,
                },
            },
        };

        let finalRaces;

        if (currentMatch && currentMatch.identifier && currentMatch.identifier.includes('L')) {
            finalRaces = races['losers'][player1Handicap + player2Handicap];
        } else {
            finalRaces = races['winners'][player1Handicap + player2Handicap];
        }

        if (finalRaces) {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_race_to: finalRaces.player1Race,
                challenger2_race_to: finalRaces.player2Race,
            });

            message.success(
                `${currentMatch.challenger1_name} goes to ${finalRaces.player1Race} and ${currentMatch.challenger2_name} goes to ${finalRaces.player2Race}`
            );
        }

        console.log(finalRaces);

        return finalRaces;
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function onChangeEntry(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        // formRef.current.setValues({
        // 	matches: matches.map((match) => {
        // 		return tzDate;
        // 	})
        // });
        return tzDate;
    }

    let scheduledT;
    let startT;

    if (currentMatch) {
        scheduledT = moment.utc(currentMatch.scheduled_time, 'HH:mm a');
        startT = moment.utc(currentMatch.start_time, 'HH:mm a');
    }

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    const renderForm = (currentMatch) => {
        return (
            <React.Fragment>
                <Modal
                    title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {currentMatch ? `Match ${currentMatch && currentMatch.match_number} (${currentMatch && currentMatch.identifier})` : 'New Match'}
                                {/* {currentMatch && currentMatch.scheduled_time && moment(startT).isAfter(scheduledT)
                                                    ? `- ${moment(currentMatch.scheduled_time).calendar()}`
                                                    : currentMatch && currentMatch.start_time
                                                    ? `- ${moment(currentMatch.start_time).calendar()}`
                                                    : ''} */}
                            </span>
                            <span>
                                {scheduledTime ? (
                                    <Button
                                        type="text"
                                        size="small"
                                        ghost={false}
                                        onClick={() => {
                                            setShowMatchTimeOverlay(true);
                                        }}
                                    >
                                        <Icon type="clock-circle" style={{ marginRight: 3 }} />{' '}
                                        {moment
                                            .utc(scheduledTime || (currentMatch && currentMatch.scheduled_time))
                                            .local()
                                            .format('lll z')}{' '}
                                        {timezone}
                                    </Button>
                                ) : (
                                    <Button
                                        type="text"
                                        size="small"
                                        ghost={false}
                                        onClick={() => {
                                            setShowMatchTimeOverlay(true);
                                        }}
                                    >
                                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> Set scheduled time
                                    </Button>
                                )}
                            </span>
                            <span
                                style={{ marginRight: 30, textTransform: 'uppercase', lineHeight: '23px' }}
                                className={`${
                                    currentMatch && currentMatch.status === 'PENDING'
                                        ? 'pending'
                                        : currentMatch && currentMatch.status === 'IN_PROGRESS'
                                        ? 'live'
                                        : currentMatch && currentMatch.status === 'SCHEDULED'
                                        ? 'scheduled'
                                        : 'completed'
                                }-text`}
                            >
                                {currentMatch && currentMatch.status === 'IN_PROGRESS' ? 'LIVE' : currentMatch ? displayEnumType(currentMatch.status) : ''}
                            </span>
                        </div>
                    }
                    getContainer={() =>
                        breakpoint === 'mobile' ? document.body : isFullScreen === true ? document.querySelector('#bracket-container') : document.body
                    }
                    visible={modalScoreVisible}
                    // okText="Save Match"
                    // okButtonProps={{ htmlType: 'submit', loading: loading }}
                    // onOk={handleScoreModalOk}
                    onCancel={handleScoreModalCancel}
                    // centered
                    style={{
                        top: '50%',
                        // left: isWidthDown('xs', width) ? 0 : -120,
                        transform: 'translateY(-50%)',
                    }}
                    // transitionName="none"
                    transitionName="fade"
                    maskTransitionName="none"
                    destroyOnClose={true}
                    footer={null}
                >
                    {showMatchTimeOverlay === true && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0,0,0,0.8)',
                                zIndex: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <Title level={4} style={{ margin: 0, color: '#fff' }}>
                                    Set match date and time
                                </Title>
                                <Formik
                                    ref={formRef2}
                                    initialValues={{ scheduled_time: scheduledTime }}
                                    // validationSchema={MatchSchema}
                                    onSubmit={(values, actions) => {
                                        console.log(JSON.stringify(values, null, 2));
                                        console.log(values.scheduled_time);
                                        // setLoading(true);

                                        // const changes = {
                                        //     scheduled_time: values.scheduled_time || null,
                                        //     status: values.scheduled_time ? 'SCHEDULED' : 'NOT_STARTED',
                                        // };

                                        // console.log(changes);

                                        setScheduledTime(values.scheduled_time ? values.scheduled_time.toString() : null);
                                        setShowMatchTimeOverlay(false);

                                        // updateMatchTime({
                                        //     variables: {
                                        //         tournament_id: tournament.id,
                                        //         round: currentMatch.round,
                                        //         id: currentMatch.id,
                                        //         changes: changes,
                                        //     },
                                        //     notifyOnNetworkStatusChange: true,
                                        //     awaitRefetchQueries: true,
                                        //     refetchQueries: [
                                        //         {
                                        //             query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                                        //             variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                                        //         },
                                        //     ],
                                        // })
                                        //     .then((data) => {
                                        //         console.log(data);
                                        //         setLoading(false);
                                        //         setShowMatchTimeOverlay(false);
                                        //         onModalScoreVisible(false);
                                        //     })
                                        //     .catch((error) => {
                                        //         console.log(error);
                                        //         setLoading(false);
                                        //         message.error(JSON.stringify(error));
                                        //     });

                                        actions.setSubmitting(false);
                                        actions.resetForm();
                                    }}
                                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                                        console.log(values);
                                        return (
                                            <Form onSubmit={handleSubmit} layout="vertical">
                                                <RoundTimeEntry
                                                    name="scheduled_time"
                                                    onOk={onChangeEntry}
                                                    round={currentRound}
                                                    match={currentMatch}
                                                    theme="dark"
                                                    values={values}
                                                />
                                                <SubmitButton type="primary" size="large" disabled={loading === true ? true : false}>
                                                    {loading === true ? 'Saving...' : 'Set'}
                                                </SubmitButton>
                                                <Button
                                                    type="default"
                                                    ghost
                                                    size="large"
                                                    onClick={() => {
                                                        setShowMatchTimeOverlay(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Form>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {showFargoColumnCharts === true && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0,0,0,0.8)',
                                zIndex: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <Title level={4} style={{ marginTop: 0, marginBottom: 10, color: '#fff' }}>
                                    Select Fargo{' '}
                                    {tournament.handicap_format === 'fargo_hot_column'
                                        ? 'hot column'
                                        : tournament.handicap_format === 'fargo_medium_column'
                                        ? 'medium column'
                                        : tournament.handicap_format === 'fargo_mild_column'
                                        ? 'mild column'
                                        : ''}{' '}
                                    race
                                </Title>
                                <div>
                                    <Button
                                        type={selectedFargoRace === 2 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(2);
                                            setSelectedFargoRace(2);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R2
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 3 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(3);
                                            setSelectedFargoRace(3);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R3
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 4 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(4);
                                            setSelectedFargoRace(4);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R4
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 5 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(5);
                                            setSelectedFargoRace(5);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R5
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 6 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(6);
                                            setSelectedFargoRace(6);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R6
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 7 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(7);
                                            setSelectedFargoRace(7);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R7
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 9 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(9);
                                            setSelectedFargoRace(9);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R9
                                    </Button>
                                    <Button
                                        type={selectedFargoRace === 11 ? 'primary' : `ghost`}
                                        ghost
                                        size="large"
                                        onClick={() => {
                                            getFargoRaces(11);
                                            setSelectedFargoRace(11);
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        R11
                                    </Button>
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    <Button
                                        type="secondary"
                                        size="large"
                                        onClick={() => {
                                            setShowFargoColumnCharts(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    <Formik
                        initialValues={
                            currentMatch
                                ? {
                                      challenger1_id: currentMatch.challenger1_id,
                                      challenger1_name: currentMatch.challenger1_name,
                                      challenger1_race_to: getChallengerRaceTo(currentMatch, 'challenger1'),
                                      challenger1_score: currentMatch.challenger1_score || 0,
                                      challenger2_id: currentMatch.challenger2_id,
                                      challenger2_name: currentMatch.challenger2_name,
                                      challenger2_race_to: getChallengerRaceTo(currentMatch, 'challenger2'),
                                      challenger2_score: currentMatch.challenger2_score || 0,
                                      table_id: currentMatch.table_id && currentMatch.status !== 'COMPLETED' ? currentMatch.table_id : undefined,
                                      scheduled_table_id: currentMatch.scheduled_table_id || undefined,
                                      start_time: currentMatch.start_time || null,
                                      priority: currentMatch.priority || null,
                                  }
                                : {
                                      challenger1_id: null,
                                      challenger1_name: '',
                                      challenger1_race_to: tournament.winners_race_to,
                                      challenger1_score: 0,
                                      challenger2_id: null,
                                      challenger2_name: '',
                                      challenger2_race_to: tournament.winners_race_to,
                                      challenger2_score: 0,
                                      table_id: undefined,
                                      scheduled_table_id: undefined,
                                      start_time: null,
                                      priority: null,
                                  }
                        }
                        ref={formRef}
                        enableReinitialize
                        validationSchema={MatchSchema}
                        onSubmit={(values, actions) => {
                            // actions.setSubmitting(false);
                            // actions.resetForm();
                            if (currentMatch) {
                                submitUpdate(values);
                            } else {
                                submitAdd(values);
                            }
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} layout="vertical">
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ paddingBottom: 5 }}>Player</th>
                                            <th style={{ textAlign: 'left' }}>Race To</th>
                                            <th style={{ textAlign: 'left' }}>Score</th>
                                            {/* <th /> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '100%' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {(currentMatch && currentMatch.challenger1_is_winner === true) ||
                                                    (currentMatch && currentMatch.is_split === true) ||
                                                    challenger1Result === 'winner' ||
                                                    challenger2Result === 'forfeit' ||
                                                    challenger2Result === 'withdraw' ||
                                                    challenger1Result === 'split' ||
                                                    values.challenger1_score === values.challenger1_race_to ? (
                                                        <span style={{ marginRight: 5 }}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor:
                                                                        (currentMatch && currentMatch.is_split === true) || challenger1Result === 'split'
                                                                            ? '#2196f3'
                                                                            : '#87d068',
                                                                }}
                                                            >
                                                                {(currentMatch && currentMatch.is_split === true) || challenger1Result === 'split' ? 'S' : 'W'}
                                                            </Avatar>
                                                        </span>
                                                    ) : (
                                                        ((currentMatch &&
                                                            currentMatch.status === 'COMPLETED' &&
                                                            currentMatch.challenger1_is_winner === false) ||
                                                            challenger2Result === 'winner' ||
                                                            challenger1Result === 'forfeit' ||
                                                            challenger1Result === 'withdraw' ||
                                                            challenger2Result === 'split' ||
                                                            values.challenger2_score === values.challenger2_race_to) && (
                                                            <span style={{ marginRight: 5 }}>
                                                                <Avatar
                                                                    style={{
                                                                        backgroundColor: challenger2Result === 'split' ? '#2196f3' : '#f5222d',
                                                                    }}
                                                                >
                                                                    {(currentMatch && currentMatch.challenger1_is_forfeit) || challenger1Result === 'forfeit'
                                                                        ? 'FF'
                                                                        : (currentMatch && currentMatch.challenger1_is_withdraw === true) ||
                                                                          challenger1Result === 'withdraw'
                                                                        ? 'WD'
                                                                        : challenger2Result === 'split'
                                                                        ? 'S'
                                                                        : 'L'}
                                                                </Avatar>
                                                            </span>
                                                        )
                                                    )}
                                                    <FormItem name="start_time">
                                                        <Input name="start_time" type="hidden" />
                                                    </FormItem>
                                                    <FormItem name="challenger1_id">
                                                        <Input name="challenger1_id" type="hidden" />
                                                    </FormItem>
                                                    <FormItem
                                                        name="challenger1_name"
                                                        // required
                                                        style={{ width: '100%' }}
                                                    >
                                                        <AutoComplete
                                                            dataSource={players1}
                                                            style={{ width: '100%' }}
                                                            onSelect={onSelectPlayer1}
                                                            onSearch={onSearch1}
                                                            onChange={handleChange}
                                                            name="challenger1_name"
                                                            placeholder="Player 1 Name"
                                                            size="large"
                                                            // allowClear
                                                            // required
                                                            optionFilterProp="children"
                                                            filterOption={(inputValue, option) => {
                                                                return (
                                                                    option.props.children &&
                                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                );
                                                            }}
                                                        >
                                                            {players1 &&
                                                                players1.length > 0 &&
                                                                players1.map((item, index) => {
                                                                    return (
                                                                        <Option key={index} value={item.key}>
                                                                            {item.name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </AutoComplete>
                                                    </FormItem>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <FormItem name="challenger1_race_to">
                                                    <InputNumber
                                                        name="challenger1_race_to"
                                                        placeholder="0"
                                                        min={0}
                                                        // value={score}
                                                        // onChange={onChange}
                                                        onFocus={handleFocus}
                                                        // max={getChallengerRaceTo(
                                                        // 	currentMatch,
                                                        // 	'challenger1'
                                                        // )}
                                                        // onBlur={handleBlur}
                                                        // style={styles.inputSmall}
                                                        // onPressEnter={onEnter}
                                                        size="large"
                                                        // disabled
                                                    />
                                                </FormItem>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <FormItem name="challenger1_score">
                                                    <InputNumber
                                                        name="challenger1_score"
                                                        placeholder="0"
                                                        min={0}
                                                        max={values.challenger1_race_to}
                                                        defaultValue={0}
                                                        // value={score}
                                                        // onChange={onChange}
                                                        onFocus={handleFocus}
                                                        // onBlur={handleBlur}
                                                        // style={styles.inputSmall}
                                                        // onPressEnter={onEnter}
                                                        style={{ backgroundColor: theme && theme.name === 'dark' ? '#093761' : '#bfe0ff' }}
                                                        size="large"
                                                        disabled={
                                                            challenger1Result === 'forfeit' ||
                                                            challenger2Result === 'forfeit' ||
                                                            challenger1Result === 'withdraw' ||
                                                            challenger2Result === 'withdraw'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </FormItem>
                                            </td>

                                            <td>
                                                <Dropdown overlay={actionsMenuTop} trigger={['click']} disabled={challenger2Result ? true : false}>
                                                    <Button size="large" style={{ marginLeft: 5 }}>
                                                        {challenger1Result ? _.capitalize(challenger1Result) : 'Actions'} <Icon type="down" />
                                                    </Button>
                                                </Dropdown>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: '100%' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {(currentMatch && currentMatch.challenger2_is_winner === true) ||
                                                    (currentMatch && currentMatch.is_split === true) ||
                                                    challenger2Result === 'winner' ||
                                                    challenger1Result === 'forfeit' ||
                                                    challenger1Result === 'withdraw' ||
                                                    challenger2Result === 'split' ||
                                                    values.challenger2_score === values.challenger2_race_to ? (
                                                        <span style={{ marginRight: 5 }}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor:
                                                                        (currentMatch && currentMatch.is_split === true) || challenger2Result === 'split'
                                                                            ? '#2196f3'
                                                                            : '#87d068',
                                                                }}
                                                            >
                                                                {(currentMatch && currentMatch.is_split === true) || challenger2Result === 'split' ? 'S' : 'W'}
                                                            </Avatar>
                                                        </span>
                                                    ) : (
                                                        ((currentMatch &&
                                                            currentMatch.status === 'COMPLETED' &&
                                                            currentMatch.challenger2_is_winner === false) ||
                                                            challenger1Result === 'winner' ||
                                                            challenger2Result === 'forfeit' ||
                                                            challenger2Result === 'withdraw' ||
                                                            challenger1Result === 'split' ||
                                                            values.challenger1_score === values.challenger1_race_to) && (
                                                            <span style={{ marginRight: 5 }}>
                                                                <Avatar
                                                                    style={{
                                                                        backgroundColor: challenger1Result === 'split' ? '#2196f3' : '#f5222d',
                                                                    }}
                                                                >
                                                                    {(currentMatch && currentMatch.challenger2_is_forfeit) || challenger2Result === 'forfeit'
                                                                        ? 'FF'
                                                                        : (currentMatch && currentMatch.challenger2_is_withdraw === true) ||
                                                                          challenger2Result === 'withdraw'
                                                                        ? 'WD'
                                                                        : challenger1Result === 'split'
                                                                        ? 'S'
                                                                        : 'L'}
                                                                </Avatar>
                                                            </span>
                                                        )
                                                    )}
                                                    <FormItem name="challenger2_id">
                                                        <Input name="challenger2_id" type="hidden" />
                                                    </FormItem>
                                                    <FormItem
                                                        name="challenger2_name"
                                                        // required
                                                        style={{ width: '100%' }}
                                                    >
                                                        <AutoComplete
                                                            dataSource={players2}
                                                            style={{ width: '100%' }}
                                                            onSelect={onSelectPlayer2}
                                                            onSearch={onSearch2}
                                                            onChange={handleChange}
                                                            name="challenger2_name"
                                                            placeholder="Player 2 Name"
                                                            size="large"
                                                            // allowClear
                                                            // required
                                                            optionFilterProp="children"
                                                            filterOption={(inputValue, option) => {
                                                                return (
                                                                    option.props.children &&
                                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                );
                                                            }}
                                                        >
                                                            {players2 &&
                                                                players2.length > 0 &&
                                                                players2.map((item, index) => {
                                                                    return (
                                                                        <Option key={index} value={item.key}>
                                                                            {item.name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </AutoComplete>
                                                    </FormItem>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <FormItem name="challenger2_race_to">
                                                    <InputNumber
                                                        name="challenger2_race_to"
                                                        placeholder="0"
                                                        min={0}
                                                        // max={getChallengerRaceTo(
                                                        // 	currentMatch,
                                                        // 	'challenger2'
                                                        // )}
                                                        // value={score}
                                                        // onChange={onChange}
                                                        onFocus={handleFocus}
                                                        // onBlur={handleBlur}
                                                        // style={styles.inputSmall}
                                                        // onPressEnter={onEnter}

                                                        size="large"
                                                        // disabled
                                                    />
                                                </FormItem>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <FormItem name="challenger2_score">
                                                    <InputNumber
                                                        name="challenger2_score"
                                                        placeholder="0"
                                                        min={0}
                                                        defaultValue={0}
                                                        max={values.challenger2_race_to}
                                                        // value={score}
                                                        // onChange={onChange}
                                                        onFocus={handleFocus}
                                                        // onBlur={handleBlur}
                                                        // style={styles.inputSmall}
                                                        // onPressEnter={onEnter}
                                                        style={{ backgroundColor: theme && theme.name === 'dark' ? '#093761' : '#bfe0ff' }}
                                                        size="large"
                                                        disabled={
                                                            challenger1Result === 'forfeit' ||
                                                            challenger2Result === 'forfeit' ||
                                                            challenger1Result === 'withdraw' ||
                                                            challenger2Result === 'withdraw'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </FormItem>
                                            </td>

                                            <td>
                                                <Dropdown overlay={actionsMenuBottom} trigger={['click']} disabled={challenger1Result ? true : false}>
                                                    <Button size="large" style={{ marginLeft: 5 }}>
                                                        {challenger2Result ? _.capitalize(challenger2Result) : 'Actions'} <Icon type="down" />
                                                    </Button>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <Text style={{ fontWeight: 700 }}>
                                    Assign open table{' '}
                                    <Tooltip
                                        placement="right"
                                        title={<div>Assign any open table for this match immediately. This will override any scheduled table assignments.</div>}
                                    >
                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                    </Tooltip>
                                </Text>
                                <FormItem name="table_id">
                                    <Query
                                        query={GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY}
                                        fetchPolicy="no-cache"
                                        // notifyOnNetworkStatusChange={true}
                                        variables={{ tournament_id: tournament.id }}
                                        onCompleted={(data) => {
                                            // console.log(data);
                                            setTables(data.pool_tables);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;
                                            if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                                return <div>No tables found</div>;
                                            }

                                            return (
                                                <Select
                                                    name="table_id"
                                                    defaultValue={currentMatch && currentMatch.table_id}
                                                    // style={{ minWidth: 300 }}
                                                    onChange={handleSelectTableChange}
                                                    placeholder="Select table assignment"
                                                    size="large"
                                                    allowClear={currentMatch && currentMatch.table_id ? false : true}
                                                >
                                                    <OptGroup label="TABLES">
                                                        {data.pool_tables
                                                            .filter((item, index) => {
                                                                return item.status !== 'CLOSED';
                                                            })
                                                            .sort((a, b) => {
                                                                return a.label.localeCompare(b.label, undefined, {
                                                                    numeric: true,
                                                                    sensitivity: 'base',
                                                                });
                                                            })
                                                            .sort((a, b) => {
                                                                return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                            })
                                                            .map((item, index) => (
                                                                <Option
                                                                    key={index + 1}
                                                                    value={item.id}
                                                                    disabled={item.status === 'IN_USE' || item.status === 'ASSIGNED'}
                                                                >
                                                                    {item.label} - {item.status} {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                    {item.is_featured_table ? '(Featured Table)' : ''}
                                                                </Option>
                                                            ))}
                                                    </OptGroup>
                                                </Select>
                                            );
                                        }}
                                    </Query>

                                    {currentMatch && currentMatch.table_id !== null && currentMatch.status !== 'COMPLETED' && (
                                        <Popconfirm
                                            title="Are you sure you want to remove this table assignment?"
                                            onConfirm={() => {
                                                confirmRemoveTableAssignment();
                                            }}
                                            onCancel={cancelRemoveTableAssignment}
                                            visible={confirmTablePopupVisible}
                                            onVisibleChange={handleRemoveTableVisibleChange}
                                            okText="Yes"
                                            cancelText="No"
                                            placement="topLeft"
                                        >
                                            <Button type="link" className="remove-table-assignment-btn">
                                                <Icon type="close-circle" /> Remove table assignment
                                            </Button>
                                        </Popconfirm>
                                    )}
                                </FormItem>

                                <div style={{ marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div>
                                        <Button
                                            type="primary"
                                            disabled={Object.keys(errors).length > 0 ? true : false}
                                            loading={loading}
                                            className={
                                                currentMatch && currentMatch.status && currentMatch.status === 'PENDING'
                                                    ? 'approve-match-btn'
                                                    : 'update-match-btn'
                                            }
                                            size="large"
                                            onClick={() => {
                                                if (currentMatch && currentMatch.status && currentMatch.status === 'COMPLETED') {
                                                    setConfirmPopupVisible(true);
                                                } else {
                                                    formRef.current.handleSubmit();
                                                }
                                            }}
                                        >
                                            {currentMatch && currentMatch.status && currentMatch.status === 'PENDING'
                                                ? 'Approve Scores'
                                                : selectedTable
                                                ? 'Update Match'
                                                : loading
                                                ? 'Saving...'
                                                : 'Save Match'}
                                        </Button>

                                        <Button type="default" size="large" onClick={handleScoreModalCancel}>
                                            Cancel
                                        </Button>

                                        {tournament.rating_system === 'florida_pool_tour' && (
                                            <Button
                                                type="danger"
                                                size="large"
                                                ghost
                                                onClick={() => {
                                                    if (currentMatch && currentMatch.challenger1 && currentMatch.challenger2) {
                                                        getHandicapRace(currentMatch.challenger1, currentMatch.challenger2, currentMatch);
                                                    } else {
                                                        message.warning('This match needs two players to get the handicapped race.');
                                                    }
                                                }}
                                            >
                                                Get Handicapped Race
                                            </Button>
                                        )}

                                        {tournament.rating_system === 'danny_ks' && (
                                            <Button
                                                type="danger"
                                                size="large"
                                                ghost
                                                onClick={() => {
                                                    if (currentMatch && currentMatch.challenger1 && currentMatch.challenger2) {
                                                        const dkHandicapChart = getDKHandicapRace(
                                                            currentMatch.challenger1,
                                                            currentMatch.challenger2,
                                                            currentMatch
                                                        );
                                                        // console.log({
                                                        //     p1Race,
                                                        //     p2Race,
                                                        // });

                                                        if (dkHandicapChart.p1Race && dkHandicapChart.p2Race) {
                                                            formRef.current.setValues({
                                                                ...formRef.current.state.values,
                                                                challenger1_race_to: dkHandicapChart.p1Race,
                                                                challenger2_race_to: dkHandicapChart.p2Race,
                                                            });

                                                            message.success(
                                                                `${currentMatch.challenger1_name} goes to ${dkHandicapChart.p1Race} and ${currentMatch.challenger2_name} goes to ${dkHandicapChart.p2Race}`
                                                            );
                                                        }
                                                    } else {
                                                        message.warning('This match needs two players to get the handicapped race.');
                                                    }
                                                }}
                                            >
                                                Get Handicapped Race
                                            </Button>
                                        )}

                                        {(tournament.tournament_format === 'fargo_rated' || tournament.tournament_format === 'usapl_fargo_rated') && (
                                            <React.Fragment>
                                                {currentMatch && currentMatch.challenger1 && currentMatch.challenger2 ? (
                                                    <Button
                                                        type="danger"
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            if (currentMatch.challenger1 && currentMatch.challenger2) {
                                                                if (tournament.tournament_format === 'usapl_fargo_rated') {
                                                                    getUSAPLRaces();
                                                                } else {
                                                                    setShowFargoColumnCharts(true);
                                                                    // getFargoRaces();
                                                                }
                                                            } else {
                                                                message.warning('This match needs two players to get the recommended Fargo Race.');
                                                            }
                                                        }}
                                                        loading={fargoLoading}
                                                    >
                                                        {fargoLoading === true
                                                            ? 'Loading...'
                                                            : tournament.tournament_format === 'usapl_fargo_rated'
                                                            ? 'Get USAPL Race'
                                                            : 'Get Fargo Race'}
                                                    </Button>
                                                ) : (
                                                    <Tooltip placement="right" title="This match needs two players to get the recommended Fargo Race.">
                                                        <Button
                                                            type="danger"
                                                            size="large"
                                                            ghost
                                                            onClick={() => {
                                                                if (tournament.tournament_format === 'usapl_fargo_rated') {
                                                                    getUSAPLRaces();
                                                                } else {
                                                                    setShowFargoColumnCharts(true);
                                                                    // getFargoRaces();
                                                                }
                                                            }}
                                                            loading={fargoLoading}
                                                            disabled={currentMatch.challenger1 && currentMatch.challenger2 ? false : true}
                                                            style={{ marginLeft: 5 }}
                                                        >
                                                            {fargoLoading === true
                                                                ? 'Loading...'
                                                                : tournament.tournament_format === 'usapl_fargo_rated'
                                                                ? 'Get USAPL Race'
                                                                : 'Get Fargo Race'}
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {currentMatch && (
                                            <Button
                                                type="danger"
                                                ghost
                                                size="large"
                                                onClick={(e) => {
                                                    showConfirmDeleteMatch();
                                                }}
                                            >
                                                <Icon type="delete" />
                                            </Button>
                                        )}

                                        {tournament.use_text_messaging !== false &&
                                            currentMatch &&
                                            currentMatch.table_id &&
                                            currentMatch.status !== 'COMPLETED' && (
                                                <Tooltip placement="left" title="Re-send SMS Notification">
                                                    <Button type="text" size="large" onClick={showConfirmResendNotification}>
                                                        <Icon type="bell" />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                    </div>
                                </div>
                                <Popconfirm
                                    title="Are you sure you want to edit this completed match?"
                                    onConfirm={() => {
                                        confirmEditCompletedMatch(formRef);
                                    }}
                                    onCancel={cancelEditCompletedMatch}
                                    visible={confirmPopupVisible}
                                    onVisibleChange={handleVisibleChange}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topLeft"
                                />
                            </Form>
                        )}
                    />
                </Modal>

                <Modal
                    title="Confirm Tournament Winner"
                    visible={confirmEndTournamentPopupVisible}
                    onOk={() => {
                        completeTournament(winner);
                    }}
                    onCancel={cancelEndTournament}
                    okText="Yes"
                    cancelText="No"
                    centered
                    transitionName="fade"
                    maskTransitionName="none"
                    destroyOnClose={true}
                >
                    <p>Did {winner && (winner.challenger1_name || winner.challenger2_name)} win the tournament?</p>
                </Modal>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {currentMatch ? <React.Fragment>{renderForm(currentMatch)}</React.Fragment> : <React.Fragment>{renderForm()}</React.Fragment>}
        </React.Fragment>
    );
}

const MemoizedMatchOverlay = React.memo(ViewerMatchOverlay);
export default MemoizedMatchOverlay;
