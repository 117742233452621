import React from 'react';
import { GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION } from './data/subscriptions';
import { Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import PodiumPlayers from './PodiumPlayers';
import './podium.css';

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    avatar: {
        margin: 0,
    },
    bigAvatar: {
        width: 40,
        height: 40,
    },
};

function Podium(props) {
    const { tournamentID } = props;

    return (
        <div className="podium" style={props.style}>
            <Subscription
                subscription={GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournamentID }}
                onData={(data) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div style={styles.container}>
                                <CircularLoader />
                            </div>
                        );

                    return (
                        <React.Fragment>
                            <PodiumPlayers players={data && data.tournament_players} />
                        </React.Fragment>
                    );
                }}
            </Subscription>
        </div>
    );
}

export default React.memo(Podium);
