import React, { useState } from 'react';
import { FormItem, DatePicker, TimePicker } from 'formik-antd';
import { Icon, Typography } from 'antd';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;

export default function RoundTimeEntry(props) {
    const { authState, name, match, round, theme, onOk, allowClear, index, values } = props;

    // const dateFormat = 'YYYY-MM-DD h:mm A z';
    const dateFormat = 'YYYY-MM-DD';
    const timeFormat = 'h:mm A z';

    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    console.log(values);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem name={name}>
                <DatePicker
                    showNow
                    showToday
                    use12Hours
                    name={name}
                    defaultValue={values.scheduled_time ? moment(values.scheduled_time) : moment()}
                    placeholder="Start date"
                    size="large"
                    format={dateFormat}
                    allowClear={allowClear === false ? false : true}
                    // autoFocus
                    suffixIcon={<Icon type="calendar" />}
                    // onChange={onDateChange}
                    onChange={onOk}
                />
            </FormItem>
            <FormItem name={name} style={{ marginLeft: 10 }}>
                <TimePicker
                    showNow
                    use12Hours
                    minuteStep={5}
                    name={name}
                    defaultValue={values.scheduled_time ? moment(values.scheduled_time) : moment()}
                    placeholder="Start time"
                    size="large"
                    format={timeFormat}
                    allowClear={allowClear === false ? false : true}
                    // autoFocus
                    suffixIcon={<Icon type="clock-circle" />}
                    // onChange={onDateChange}
                    onChange={onOk}
                />
            </FormItem>
            <Text type="secondary" style={{ marginLeft: 8, color: theme === 'dark' ? '#fff' : '#888' }}>
                {timezone}
            </Text>
        </div>
    );
}
