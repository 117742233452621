import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, Button, Typography, Modal, Tooltip } from 'antd';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID } from './data/queries';
import { UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID_SUBSCRIPTION } from './data/subscriptions';
import { advanceTournamentPlayers } from '../../../utils';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import CircularLoader from '../../../components/CircularLoader';
import MatchScoreboard from './MatchScoreboard2';
import axios from 'axios';
import apiEndPoint from '../../../utils/apiEndpoint';
import slugify from 'slugify';
import Hashids from 'hashids';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function DPTournamentMatchScoring(props) {
    const { client } = props;
    const hashids = new Hashids(`Tournament salt`);
    const params = props.match.params;
    const tournamentSlug = params.slug;
    const hashId = params.hash_id;
    const [endMatchDisabled, setEndMatchDisabled] = useState(true);

    let decoded;
    let tournamentID;
    let roundNumber;
    let matchNumber;

    if (hashId) {
        decoded = hashids.decode(hashId);
        tournamentID = decoded[1];
        roundNumber = decoded[2];
        matchNumber = decoded[3];

        console.log('decoded', decoded);
        console.log({
            tournamentID,
            roundNumber,
            matchNumber,
        });
    }

    function startMatch(match) {
        const startTime = moment.utc();
        let changes = {
            start_time: startTime,
            status: 'IN_PROGRESS',
            progress: 0,
        };

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
                        variables: { hash_id: match.hash_id, tournament_id: tournamentID },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success(`Match started at ${moment.utc(startTime).local().format('LT')}`);
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function endMatch(match, winner, loser) {
        const endTime = moment.utc();
        let changes;
        const challenger1_winner = winner.id === match.challenger1_id ? true : false;
        const challenger2_winner = winner.id === match.challenger2_id ? true : false;

        if (match && match.tournament && match.tournament.autopilot_mode === true) {
            changes = {
                end_time: endTime,
                status: 'COMPLETED',
                progress: 100,
                table_name: match.table_name && match.table_name,
                table_id: match.table_id && match.table_id,
                ...(challenger1_winner && { challenger1_is_winner: true, challenger2_is_winner: false }),
                ...(challenger2_winner && { challenger2_is_winner: true, challenger1_is_winner: false }),
            };

            if (match.tournament && match.tournament.livestream_split_url) {
                console.log('LIVE STREAM SPLIT URL', match.tournament && match.tournament.livestream_split_url);
                const tableSlug = slugify(match.table_name, {
                    replacement: '-',
                    remove: /[*+~.,()'"#!:/@]/g,
                    lower: true,
                });

                const payload = {
                    tournament_id: tournamentID,
                    match_id: match.id,
                    match_number: match.match_number,
                    table_id: match.table_id,
                    table_name: match.table_name,
                    table_slug: tableSlug,
                    game_type: match.tournament.game_type,
                    player: match.challenger1_name,
                    opponent: match.challenger2_name,
                    event: 'split',
                    webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
                    callback_url: match.tournament.livestream_split_url,
                };

                axios
                    .post(`${apiEndPoint}matchStatusUpdate`, payload)
                    .then((res) => {
                        console.log(res.data);
                        // message.success(JSON.stringify(res.data));
                        return res.data;
                    })
                    .catch((error) => {
                        console.log(error);
                        // message.error(`There was an error: ${JSON.stringify(error)}`);
                        return error;
                    });
            }
        } else {
            changes = {
                end_time: endTime,
                status: 'PENDING',
                progress: 100,
                table_name: match.table_name && match.table_name,
                table_id: match.table_id && match.table_id,
                ...(challenger1_winner && { challenger1_is_winner: true, challenger2_is_winner: false }),
                ...(challenger2_winner && { challenger2_is_winner: true, challenger1_is_winner: false }),
            };
        }

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
                        variables: { hash_id: match.hash_id, tournament_id: tournamentID },
                    },
                ],
            })
            .then((data) => {
                console.log(data);

                if (match && match.tournament && match.tournament.autopilot_mode === true) {
                    advanceTournamentPlayers(client, tournamentID, match, winner, loser);
                    message.success(`Match scores submitted at ${moment.utc(endTime).local().format('LT')}`);
                } else {
                    message.success(`Match scores submitted for TD approval at ${moment.utc(endTime).local().format('LT')}`);
                }
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function showConfirmEndMatch(matchData) {
        const { winner, loser } = determineMatchWinner(matchData);

        confirm({
            title: (
                <React.Fragment>
                    Did {winner.name} win the match {winner.score}-{loser.score}? Please verify the scores are correct and submit for Tournament Director
                    approval.
                </React.Fragment>
            ),
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                endMatch(matchData, winner, loser);
            },
            onCancel() {},
            okText: 'Submit Scores',
            cancelText: 'Cancel',
        });
    }

    function checkForWinner(matchData) {
        const challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.winners_race_to || 0;
        const challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.winners_race_to || 0;

        if (matchData.challenger1_score >= challenger1RaceTo || matchData.challenger2_score >= challenger2RaceTo) {
            return true;
        } else {
            return false;
        }
    }

    function determineMatchWinner(matchData) {
        const challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.winners_race_to || 0;
        const challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.winners_race_to || 0;
        let winner;
        let loser;

        if (matchData.challenger1_score >= challenger1RaceTo) {
            winner = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: matchData.challenger1_score,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: matchData.challenger2_score,
                is_winner: false,
            };
        } else if (matchData.challenger2_score >= challenger2RaceTo) {
            winner = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: matchData.challenger2_score,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: matchData.challenger1_score,
                is_winner: false,
            };
        }

        console.log({
            winner,
            loser,
        });

        return {
            winner,
            loser,
        };
    }

    function handleMatchUpdate(matchData) {
        console.log('match', matchData);

        if (checkForWinner(matchData) === true) {
            setEndMatchDisabled(false);
            showConfirmEndMatch(matchData);
        } else {
            setEndMatchDisabled(true);
        }
    }

    return (
        <SectionWrapper>
            <SectionContent
                padding="0px"
                style={{
                    width: '100%',
                    height: '100%',
                    flex: 1,
                    background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                    // background: 'linear-gradient(180deg, rgb(0, 46, 175), rgb(0, 141, 208)) 100% 100% / 100% no-repeat',
                    color: '#fff',
                    position: 'relative',
                }}
            >
                {tournamentID ? (
                    <Query
                        query={GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange
                        awaitRefetchQueries
                        variables={{ hash_id: hashId, tournament_id: tournamentID }}
                        onCompleted={async (res) => {
                            const matchData = res && res.tournament_brackets && res.tournament_brackets[0];

                            if (matchData) {
                                if (matchData.status === 'PENDING') {
                                    setEndMatchDisabled(true);
                                } else if (matchData.status !== 'PENDING' && checkForWinner(matchData) === true && matchData.status !== 'COMPLETED') {
                                    setEndMatchDisabled(false);
                                    showConfirmEndMatch(matchData);
                                } else {
                                    setEndMatchDisabled(true);
                                }
                            }
                        }}
                    >
                        {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if (data && !data.tournament_brackets) {
                                return <div style={styles.container}>Match not found.</div>;
                            }
                            if (data && data.tournament_brackets.length === 0) {
                                return <div style={styles.container}>Match not found.</div>;
                            }

                            const match = data.tournament_brackets[0];
                            console.log(match);

                            return (
                                <React.Fragment>
                                    <div
                                        style={{
                                            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
                                            // position: 'absolute',
                                            // top: 0,
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            paddingLeft: 10,
                                            paddingRight: 20,
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                                        }}
                                    >
                                        <div>
                                            <Button
                                                type="default"
                                                shape="circle"
                                                icon="arrow-left"
                                                size="large"
                                                ghost
                                                style={{ border: 'none' }}
                                                onClick={() => {
                                                    props.history.push(`/tournaments/${match.tournament.slug}/bracket`);
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: '#fff',
                                                        textTransform: 'uppercase',
                                                        fontSize: 16,
                                                        fontWeight: 500,
                                                        letterSpacing: 1,
                                                    }}
                                                >
                                                    View Bracket
                                                </Text>
                                            </Button>
                                        </div>
                                        <div>
                                            {match.status === 'IN_PROGRESS' ? (
                                                <Tooltip
                                                    placement="left"
                                                    title={
                                                        endMatchDisabled === true
                                                            ? match.status === 'PENDING'
                                                                ? 'Match scores have already been submitted and are awaiting approval.'
                                                                : 'Cannot submit scores until a winner has been determined'
                                                            : 'Submit scores for approval'
                                                    }
                                                >
                                                    <Button
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            showConfirmEndMatch(match);
                                                        }}
                                                        disabled={endMatchDisabled || false}
                                                        className={endMatchDisabled === true ? 'disabled' : ''}
                                                    >
                                                        Submit scores
                                                    </Button>
                                                </Tooltip>
                                            ) : match.status === 'PENDING' ? (
                                                <Text style={{ color: '#fff' }}>Scores pending TD approval</Text>
                                            ) : (
                                                match.status !== 'COMPLETED' && (
                                                    <Button
                                                        size="large"
                                                        ghost
                                                        onClick={() => {
                                                            startMatch(match);
                                                        }}
                                                    >
                                                        Start match
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                        {/* <div>
                                        <Button type="ghost" ghost>
                                            Submit Scores
                                        </Button>
                                    </div> */}
                                    </div>
                                    <MatchScoreboard
                                        matchData={match}
                                        onUpdateMatch={handleMatchUpdate}
                                        subscribeToMore={subscribeToMore}
                                        subscribeToNewData={() => {
                                            subscribeToMore({
                                                document: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID_SUBSCRIPTION,
                                                variables: { hash_id: hashId, tournament_id: tournamentID },
                                                updateQuery: (prev, { subscriptionData }) => {
                                                    // console.log('match updated');
                                                    // console.log({
                                                    //     prev,
                                                    //     subscriptionData,
                                                    // });
                                                    if (!subscriptionData.data) return prev;
                                                    const updatedMatch = subscriptionData.data.tournament_brackets[0];

                                                    if (updatedMatch.id === prev.tournament_brackets[0].id) {
                                                        const output = Object.assign({}, prev, {
                                                            ...prev.tournament_brackets[0],
                                                            updatedMatch,
                                                        });

                                                        return output;
                                                    } else {
                                                        return prev;
                                                    }
                                                },
                                            });
                                        }}
                                        {...props}
                                    />
                                </React.Fragment>
                            );
                        }}
                    </Query>
                ) : (
                    <div style={styles.container}>Match not found.</div>
                )}
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(React.memo(DPTournamentMatchScoring));
