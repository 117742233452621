import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, Card, Tooltip, Icon, Modal, Button, Typography } from 'antd';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signInWithEmail } from '../../firebase/helpers';
import { gql } from '@apollo/client';
import moment from 'moment';
import qs from 'query-string';
import stringify from '../../lib/query-stringifty';
import { getAuthStatus } from '../../getAuthStatus';
import dpIcon from '../../assets/img/dp-icon.png';
import _ from 'lodash';
import LoadingSpinner from '../../components/LoadingSpinner';
import dpLogoHeader from '../../assets/img/dp-logo-header.png';
import { CREATE_LEAGUE_PLAYER } from '../league-manager/data/mutations';
import { UPDATE_GHOST_GAMES_INVITE_PLAYER, DELETE_GHOST_GAMES_USER_INVITE_KEY } from '../ghost-event-manager/data/mutations';
import { UPDATE_MATCH_MANAGERS_ADD, DELETE_MATCH_ADMINS_INVITE_KEY } from '../digitalpool/matches/data/mutations';
import { GET_LEAGUE_INVITE, GET_GHOST_GAMES_INVITE_KEY, GET_MATCH_ADMIN_INVITE_KEY } from '../digitalpool/data/queries';
import { GET_USER } from './data/queries';
import { payoutsModalVisible } from '../ghost-event-manager/data/atoms';
import { useApolloClient } from '@apollo/client';
import apiEndPoint from '../../utils/apiEndpoint';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

const { Title, Text } = Typography;

const UPDATE_USER_MUTATION = gql`
    mutation update_users($uid: String, $timestamp: timestamptz!, $token: String) {
        update_users(where: { uid: { _eq: $uid }, _and: { uid: { _neq: "" } } }, _set: { last_seen: $timestamp, token: $token }) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                country
                region
                city
                country_phone
                fargo_id
                player_id
                email
                role
                uid
                uuid
                last_seen
                token
                avatar
            }
        }
    }
`;

const styles = {
    card: {
        maxWidth: 'auto',
        borderRadius: '6px',
        boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
        margin: 24,
        width: '100%',
        // background: 'transparent',
        // boxShadow: 'none',
        // border: 'none',
    },
};

function Login(props, context) {
    const { authState, setAuthState, location } = props;
    console.log('login props', props);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const redirectUrl = params && params.redirect_url;
    const client = useApolloClient();

    const showLoading = () => {
        // const hide = message.loading('Logging in...', 0);
        // // Dismiss manually and asynchronously
        // setTimeout(hide, 1000);
    };

    const showError = (text) => {
        text = text ? text : error ? error.message : null;
        message.error(text);
    };

    function getUserInfo(token) {
        return axios
            .post(`${apiEndPoint}sso`, {
                access_token: token,
                password: '!Q89qvo44#m*R8olRBTDf',
            })
            .then((res) => {
                console.log(res.data);
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });
    }

    const handleSubmit = (values, actions) => {
        // console.log(values);
        setLoading(true);
        showLoading();
        const { email, password } = values;
        const emailTrim = email.trim();

        signInWithEmail(emailTrim, password)
            .then((result) => {
                console.log('result', result);
                actions.setSubmitting(false);

                let newAuthState = {};

                getAuthStatus()
                    .then(async (user, context) => {
                        // message.success(`Logged in as ${user.email}`);
                        // var userProfile = auth.currentUser;
                        const { email, displayName, emailVerified, photoURL, isAnonymous, uid, providerData } = user;
                        const userProfile = {
                            displayName,
                            email,
                            emailVerified,
                            photoURL,
                            isAnonymous,
                            uid,
                            providerData,
                        };

                        console.log('user profile from firebase', userProfile);

                        const token = await user.getIdToken(true);
                        const idTokenResult = await user.getIdTokenResult();
                        // console.log('token from firebase', token);
                        console.log('token result from firebase', idTokenResult);
                        const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

                        const timestamp = moment().format();

                        mixpanel.identify(uid);
                        mixpanel.people.set({
                            $email: email, // only special properties need the $
                            'User ID': uid, // use human-readable names
                            $name: displayName,
                            'Last Seen': timestamp,
                            'Account Type': 'user',
                        });

                        let res = await props.client.mutate({
                            mutation: UPDATE_USER_MUTATION,
                            variables: {
                                uid: uid,
                                timestamp: timestamp,
                                token: token,
                            },
                        });

                        if (hasuraClaim) {
                            console.log('hasura claim', hasuraClaim);
                            let userHasura = res.data.update_users.returning[0];
                            console.log('user from hasura', userHasura);
                            if (!displayName && userHasura && userHasura.firstName) {
                                userProfile.displayName = `${userHasura.first_name} ${userHasura.last_name}`;
                            }

                            const mergedUser = _.assign({}, userProfile, userHasura);
                            localStorage.setItem('digitalpool-auth', JSON.stringify({ status: 'in', user: mergedUser, token }));
                            newAuthState = { status: 'in', user: mergedUser, token, uid, timestamp };
                        } else {
                            if (
                                userProfile &&
                                userProfile.providerData &&
                                userProfile.providerData[0] &&
                                userProfile.providerData[0].providerId !== 'password'
                            ) {
                                // Check if refresh is required.
                                // const metadataRef = ref('metadata/' + user.uid + '/refreshTime');
                                // metadataRef.on('value', async () => {
                                //     // Force refresh to pick up the latest custom claims changes.
                                //     const token = await user.getIdToken(true);
                                //     // console.log(token);
                                //     localStorage.setItem('digitalpool-auth', JSON.stringify({ status: 'in', user, userProfile, token }));
                                //     context.setAuthState({ status: 'in', user, userProfile, token });
                                // });

                                const token = await user.getIdToken(true);
                                // console.log(token);
                                localStorage.setItem('digitalpool-auth', JSON.stringify({ status: 'in', user, userProfile, token }));
                                newAuthState = { status: 'in', user, userProfile, token };
                            }
                        }
                        setAuthState(newAuthState);
                    })
                    .catch((err, context) => {
                        console.log(err);
                        console.log('remove user from local storage');
                        localStorage.removeItem('digitalpool-auth');
                        setAuthState({ status: 'out' });
                    });

                // mixpanel.track('Sign Up', {
                // 	'user_id': "Pat's affiliate site",
                // 	'Opted out of email': true,
                // });

                // const mergedUser = _.assign({}, result, userCopy);
                // props.setAuthState({ status: 'in', user: mergedUser, token });
                // league invite stuff.. need to handle this better

                if (result.user) {
                    const { user } = result;
                    mixpanel.track('Login', {
                        Name: user.displayName,
                        Email: user.email,
                        'Email Verified': user.emailVerified,
                        UID: user.uid,
                        Source: 'web',
                    });
                }

                setLoading(false);

                if (redirectUrl && result.user) {
                    const accessToken = result.user.uid;
                    // getUserInfo(token);
                    window.location.replace(`${redirectUrl}/?access_token=${accessToken}`);
                } else {
                    props.history.push('/dashboard');
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setError(error);
                showError(error && error.message);
                actions.setFieldError('general', error.message);
            });
    };

    const SignupSchema = Yup.object().shape({
        email: Yup.string().trim().email('Email is invalid').required('Email is required'),
        password: Yup.string().trim().min(6, 'Password must be at least 6 characters').required('Password is required'),
    });

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    if (authState && authState.user && authState.status === 'in') {
        if (redirectUrl) {
            const accessToken = authState.user.uid;
            // getUserInfo(token);
            window.location.replace(`${redirectUrl}/?access_token=${accessToken}`);
        } else {
            props.history.push('/dashboard');
        }
    } else {
        return (
            <React.Fragment>
                <LoadingSpinner loading={loading} />
                <Formik
                    initialValues={{
                        email: params.email,
                        password: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={handleSubmit}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form
                            onSubmit={handleSubmit}
                            layout="vertical"
                            style={{
                                display: 'flex',
                                flex: 1,
                                // flexGrow: 1,
                                // height: 'calc(100% - 48px)',
                                // height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'left',
                            }}
                        >
                            <Card style={styles.card}>
                                <div style={{ maxWidth: 400, margin: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Link to="/">
                                            <img src={dpLogoHeader} alt="Logo" height={50} />
                                        </Link>
                                    </div>
                                    <Title level={2} style={{ textAlign: 'center', marginTop: 10 }}>
                                        Network Login
                                    </Title>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Tooltip placement="bottom" title="Sign In With Facebook">
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    props
                                                        .signInWithFacebook(client)
                                                        .then((data) => {
                                                            console.log('logged in with facebook');
                                                            console.log(data);

                                                            if (data) {
                                                                mixpanel.track('Login', {
                                                                    Name: `${data.first_name} ${data.last_name}`,
                                                                    Email: data.email,
                                                                    'Email Verified': true,
                                                                    UID: data.uid,
                                                                    Source: 'facebook',
                                                                });
                                                            }

                                                            if (redirectUrl) {
                                                                window.location.replace(`${redirectUrl}/?access_token=${data.uid}`);
                                                            } else {
                                                                props.history.push('/dashboard');
                                                            }
                                                        })
                                                        .catch((e) => {
                                                            const err = (e && e.message) || JSON.stringify(e);
                                                            console.log('error', err);
                                                            return err;
                                                        })
                                                }
                                                shape="circle"
                                                size="large"
                                                style={{ backgroundColor: '#3b5998', borderColor: '#3b5998' }}
                                            >
                                                <Icon type="facebook" theme="filled" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip placement="bottom" title="Sign In With Google">
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    props
                                                        .signInWithGoogle(client)
                                                        .then((data) => {
                                                            console.log('logged in with google');
                                                            console.log(data);

                                                            if (data) {
                                                                mixpanel.track('Login', {
                                                                    Name: `${data.first_name} ${data.last_name}`,
                                                                    Email: data.email,
                                                                    'Email Verified': true,
                                                                    UID: data.uid,
                                                                    Source: 'google',
                                                                });
                                                            }

                                                            if (redirectUrl) {
                                                                window.location.replace(`${redirectUrl}/?access_token=${data.uid}`);
                                                            } else {
                                                                props.history.push('/dashboard');
                                                            }
                                                        })
                                                        .catch((e) => {
                                                            const err = (e && e.message) || JSON.stringify(e);
                                                            console.log('error', err);
                                                            return err;
                                                        })
                                                }
                                                shape="circle"
                                                icon="google"
                                                size="large"
                                                style={{
                                                    marginLeft: 10,
                                                    backgroundColor: '#dd4b39',
                                                    borderColor: '#dd4b39',
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div style={{ marginTop: 10, marginBottom: 8, textAlign: 'center' }}>
                                        <Text>Or with your email address</Text>
                                    </div>

                                    <FormItem name="email" hasFeedback showValidateSuccess required>
                                        <Input
                                            addonBefore={<Icon type="mail" />}
                                            name="email"
                                            size="large"
                                            autoFocus
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                            // prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="Email"
                                        />
                                    </FormItem>
                                    <FormItem name="password" hasFeedback showValidateSuccess required>
                                        <Input.Password
                                            addonBefore={<Icon type="lock" />}
                                            name="password"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                            // autocomplete="current-password"
                                            // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </FormItem>

                                    <SubmitButton
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                        size="large"
                                        style={{ marginTop: 10, width: '100%' }}
                                        loading={loading}
                                    >
                                        Submit
                                    </SubmitButton>
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ marginTop: 10 }}>
                                            <Text style={{ color: '#f5222d' }}>{errors.general}</Text>
                                        </div>
                                        <div style={{ marginBottom: 10, fontSize: 18 }}>
                                            <Link to="/forgot-password">Forgot password?</Link>
                                        </div>
                                        <div>
                                            Don't have an account? <Link to="/register">Sign Up</Link>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Form>
                    )}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(Login);
