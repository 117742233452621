import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Icon, Typography, Avatar } from 'antd';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { CountryRegionData } from 'react-country-region-selector';
import geo from 'countrycitystatejson';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_PLAYER_QUERY, GET_USER_QUERY, SEARCH_PLAYERS, SEARCH_USERS } from '../data/queries';
import { v4 as uuidv4 } from 'uuid';
import { formatPlayerName } from '../../../utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_PLAYER, UPDATE_PLAYER, UPDATE_USER } from '../data/mutations';
import _ from 'lodash';
import CircleFlag from '../../../components/CircleFlag';
import axios from 'axios';
import GetFargoModal from '../../../components/GetFargoModal';
import { displayEnumType, getCountryName } from '../../../utils';
import slugify from 'slugify';

const { Title, Text } = Typography;
const { Option } = Select;

function AddPlayerModal(props) {
    const { authState, client, selectedPlayerId, userID, modalVisible, onModalOk, onModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [acDataSource, setacDataSource] = useState();
    const [acUserDataSource, setacUserDataSource] = useState();
    const [nicknameForm, setNicknameForm] = useState('');
    const [autocompleteValue, setAutoCompleteValue] = useState('');
    const [fargoModalVisible, setFargoModalVisible] = useState(false);
    const [selected, setSelected] = useState();
    const [selectedUser, setSelectedUser] = useState();
    // const { data } = useQuery(GET_TOURNAMENT_PLAYER_QUERY, {
    // 	variables: { id: selectedPlayerId, tournament_id: tournamentID }
    // });
    const [createPlayer] = useMutation(CREATE_PLAYER);
    const [updatePlayer] = useMutation(UPDATE_PLAYER);
    const [updateUser] = useMutation(UPDATE_USER);

    const countries = geo.getCountries();
    const countryPhones = countries
        .sort((a, b) => {
            return a.phone.localeCompare(b.phone, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        })
        .filter((country) => {
            return country.shortName !== 'UM';
        })
        .map((country) => {
            return {
                phone: country.phone,
                emoji: country.emoji,
                shortName: country.shortName,
            };
        });

    const formRef = useRef();

    async function queryDB(name) {
        setacDataSource([]);
        var players = [];

        const res = await client.query({
            query: SEARCH_PLAYERS,
            variables: {
                name: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.players.length; i++) {
            let player = res.data.players[i];
            const { id, name, aliases, country, image_large, image_small, properties, user_id } = player;
            const user = player.user;

            players.push({
                ...user,
                ...player,
                id,
                name: name,
                country: country,
                aliases,
                user_id: user_id,
                image_large,
                image_small,
                city: properties && properties.fargo_data && properties.fargo_data.city,
                state: properties && properties.fargo_data && properties.fargo_data.state,
                robustness: properties && properties.fargo_data && properties.fargo_data.robustness,
                fargo_id: properties && properties.fargo_data && properties.fargo_data.membershipId,
                fargo_readable_id: properties && properties.fargo_data && properties.fargo_data.readableId,
                fargo_effective_rating: properties && properties.fargo_data && properties.fargo_data.effectiveRating,
                key: uuidv4(),
            });
        }

        const dataSet = _.uniqBy([...players], 'name').sort((a, b) => a.name.localeCompare(b.name));
        console.log(dataSet);
        setacDataSource(dataSet);
    }

    async function queryDBUsers(name) {
        setacDataSource([]);
        var tournamentPlayers = [];
        var users = [];

        const res = await client.query({
            query: SEARCH_USERS,
            variables: {
                first_name: `%${name}%`,
                last_name: `%${name}%`,
                nickname: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.users.length; i++) {
            let user = res.data.users[i];
            const { id, first_name, last_name, display_name, avatar, city, region, nickname } = user;

            users.push({
                ...user,
                id,
                name: display_name ? `${display_name}` : `${first_name} ${last_name}`,
                nickname,
                city,
                region,
                avatar,
                user_id: id,
                key: uuidv4(),
            });
        }

        const dataSet = users.sort((a, b) => a.name.localeCompare(b.name));
        console.log(dataSet);

        setacUserDataSource(dataSet);
    }

    function onChange(event) {
        setNicknameForm(event.target.value);
    }

    function handleCancel() {
        setSelected();
        setacDataSource([]);
        formRef.current.setValues({});
        onModalCancel();
    }

    function onAutoCompleteChange(value) {
        console.log(value);
        console.log(formRef);
        if (value) {
            formRef.current.setValues({
                name: value,
            });
        }

        // setAutoCompleteValue(value);
    }

    function onSearchUsers(name) {
        if (name && name.length > 1) {
            queryDBUsers(name);
        } else {
            setSelectedUser();
            setacUserDataSource([]);
        }
    }

    function onSelectUser(value, option) {
        console.log(value);
        // let match = value.match(/\"[a-zA-Z\ \-\']+\"/g);
        const player = acUserDataSource.filter((item) => item.key === value);
        console.log(player);
        if (player) {
            setSelectedUser(player[0]);
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                user_id: player[0].user_id.toString(),
            });
        } else {
            setSelectedUser();
            console.log('test');
            formRef.current.setValues([]);
        }

        // if (match) {
        //     let replaceNickname = match[0].replace(/\"/g, '');
        //     setNicknameForm(replaceNickname);
        // }
    }

    function onSearch(name) {
        if (name && name.length > 1) {
            queryDB(name);
        } else {
            setSelected();
            setacDataSource([]);
        }
    }

    function handleChange(name) {
        if (name && name.length === 0) {
            formRef.current.setValues([]);
        }
    }

    function handleChangeUser(player) {
        console.log(player);
        // // const player = acUserDataSource.filter((item) => item.key === name);
        // formRef.current.setValues({
        //     ...formRef.current.state.values,
        //     user_id: player.user_id.toString(),
        // });
    }

    function onSelect(value, option) {
        console.log(value);
        // let match = value.match(/\"[a-zA-Z\ \-\']+\"/g);
        const player = acDataSource.filter((item) => item.key === value);
        console.log(player);
        if (player) {
            setSelected(player[0]);
            console.log(player);
            formRef.current.setValues(player[0]);
        } else {
            setSelected();
            console.log('test');
            formRef.current.setValues([]);
        }

        // if (match) {
        //     let replaceNickname = match[0].replace(/\"/g, '');
        //     setNicknameForm(replaceNickname);
        // }
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    const submitAdd = (data) => {
        console.log('add player', data);
        setLoading(true);
        const slug = slugify(data.name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });
        createPlayer({
            variables: {
                objects: [
                    {
                        name: data.name,
                        name_slug: slug,
                        ...(data.aliases && { aliases: data.aliases }),
                        ...(data.properties && { properties: data.properties }),
                        ...(data.image_large && { image_large: data.image_large }),
                        ...(data.image_small && { image_small: data.image_small }),
                        ...(data.country && { country: data.country }),
                        ...(data.gender && { gender: data.gender }),
                        ...(data.user_id && { user_id: data.user_id }),
                        ...(data.fargo_id && { fargo_id: parseInt(data.fargo_id) }),
                        ...(data.fargo_readable_id && { fargo_readable_id: parseInt(data.fargo_readable_id) }),
                        ...(data.fargo_effective_rating && { fargo_effective_rating: data.fargo_effective_rating }),
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            // refetchQueries: [
            //     {
            //         query: GET_TOURNAMENT_PLAYERS_QUERY,
            //         variables: { tournament_id: tournamentID },
            //     },
            //     {
            //         query: GET_TOURNAMENT_QUERY,
            //         variables: { id: tournamentID },
            //     },
            // ],
        })
            .then((res) => {
                console.log(res);
                const added = res.data.insert_players.returning[0];

                if (data.user_id) {
                    updateUser({
                        variables: {
                            id: data.user_id,
                            changes: {
                                player_id: added.id,
                            },
                        },
                    })
                        .then((data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Player successfully added');
                            onModalOk(added);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            message.error('There was an error', JSON.stringify(error.message));
                            onModalOk();
                        });
                } else {
                    console.log(data);
                    setLoading(false);
                    message.success('Player successfully added');
                    onModalOk(added);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    };

    const submitUpdate = (data) => {
        console.log(data);
        setLoading(true);
        const slug = slugify(data.name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });
        updatePlayer({
            variables: {
                id: selectedPlayerId,
                changes: {
                    name: data.name,
                    name_slug: slug,
                    properties: data.properties,
                    country: data.country,
                    gender: data.gender,
                    image_large: data.image_large,
                    image_small: data.image_small,
                    ...(data.user_id && { user_id: data.user_id }),
                    fargo_id: parseInt(data.fargo_id),
                    fargo_readable_id: parseInt(data.fargo_readable_id),
                    fargo_effective_rating: data.fargo_effective_rating,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_PLAYER_QUERY,
                    variables: { id: selectedPlayerId },
                },
            ],
        })
            .then((data) => {
                console.log(data);

                if (data.user_id) {
                    updateUser({
                        variables: {
                            id: data.user_id,
                            changes: {
                                player_id: selectedPlayerId,
                            },
                        },
                    })
                        .then((data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            onModalOk();
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            message.error('There was an error', JSON.stringify(error.message));
                            onModalOk();
                        });
                } else {
                    console.log(data);
                    setLoading(false);
                    message.success('Changes saved');
                    onModalOk();
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', JSON.stringify(error.message));
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
    });

    const validator = (values, props) => {
        console.log(values);
        console.log(props);
        const errors = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        return errors;
    };

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    async function handleFargoModalOk(player) {
        console.log(player);
        if (player) {
            formRef.current.setValues({
                ...formRef.current.state.values,
                fargo_id: player.fargoId,
                fargo_readable_id: player.fargoReadableId,
                properties: player.properties,
            });
        }

        setFargoModalVisible(false);
    }

    function handleFargoModalCancel(e) {
        setFargoModalVisible(false);
    }

    function handleGetFargoRating() {
        setFargoModalVisible(true);
    }

    const renderForm = (player) => {
        const initialValues = selectedPlayerId
            ? {
                  ...player,
                  fargo_id: player && player.properties && player.properties.fargo_data && player.properties.fargo_data.membershipId,
                  fargo_readable_id: player && player.properties && player.properties.fargo_data && player.properties.fargo_data.readableId,
                  fargo_effective_rating: player && player.properties && player.properties.fargo_data && player.properties.fargo_data.effectiveRating,
                  user_id: player && player.user_id && player.user_id.toString(),
              }
            : {
                  name: '',
                  gender: undefined,
                  properties: {},
                  country: undefined,
                  image_large: '',
                  image_small: '',
                  user_id: '',
                  fargo_id: '',
                  fargo_readable_id: '',
                  fargo_effective_rating: '',
              };

        return (
            <React.Fragment>
                <Formik
                    ref={formRef}
                    onSubmit={(data, actions) => {
                        let merged;
                        if (selected) {
                            merged = {
                                ...selected,
                                ...data,
                            };
                        } else {
                            merged = data;
                        }

                        console.log('data to submit', merged);
                        if (selectedPlayerId) {
                            submitUpdate(merged, player);
                        } else {
                            actions.setSubmitting(false);
                            actions.resetForm();
                            submitAdd(merged);
                        }
                    }}
                    validationSchema={schema}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    render={({ errors, touched, values }) => (
                        <Modal
                            title={selectedPlayerId ? 'Update Player' : 'Add Player'}
                            // getContainer={() => document.querySelector('.tables')}
                            visible={modalVisible}
                            onOk={onModalOk}
                            onCancel={handleCancel}
                            centered
                            transitionName="fade"
                            // transitionName="none"
                            maskTransitionName="none"
                            footer={[
                                <Button key="cancel" onClick={handleCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    onClick={() => {
                                        formRef.current.handleSubmit();
                                    }}
                                >
                                    {selectedPlayerId ? (loading ? 'Updating...' : 'Update Player') : 'Add Player'}
                                </Button>,
                            ]}
                            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                            destroyOnClose={true}
                        >
                            <Form
                                layout="vertical"
                                style={{ maxWidth: 600 }}
                                // onSubmit={(e) => {
                                // 	e.preventDefault();
                                // 	console.log(values);
                                // 	// handleSubmit(values);
                                // }}
                            >
                                {/* <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
								<Input name="name" placeholder="Name" size="large" />
							</FormItem> */}
                                <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                    <AutoComplete
                                        dataSource={acDataSource}
                                        // value={autocompleteValue}
                                        // validate={validator}
                                        style={{ width: '100%' }}
                                        onSearch={onSearch}
                                        onSelect={onSelect}
                                        onChange={handleChange}
                                        // onChange={onAutoCompleteChange}
                                        size="large"
                                        name="name"
                                        placeholder="Search for a player name"
                                        optionFilterProp="children"
                                        optionLabelProp="value"
                                        autoFocus
                                        allowClear
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.props &&
                                                option.props.children.props.children &&
                                                option.props.children.props.children[1] &&
                                                option.props.children.props.children[1].toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <React.Fragment>
                                                <div>{menu}</div>
                                            </React.Fragment>
                                        )}
                                    >
                                        {acDataSource &&
                                            acDataSource.map((item, index) => (
                                                <Option key={item.key} value={item.key}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.country && (
                                                            <span role="img" aria-label={item.country}>
                                                                <CircleFlag country={getCountryName(item.country)} left={0} />{' '}
                                                            </span>
                                                        )}
                                                        {formatPlayerName(item.name)}
                                                        {item.properties &&
                                                            item.properties.fargo_data &&
                                                            item.properties.fargo_data.location &&
                                                            item.properties.fargo_data.location !== ' ' &&
                                                            ` - ${item.properties.fargo_data.location}`}
                                                        {item.properties &&
                                                            item.properties.fargo_data &&
                                                            item.properties.fargo_data.effectiveRating &&
                                                            ` - ${item.properties.fargo_data.effectiveRating}`}
                                                    </span>
                                                </Option>
                                            ))}
                                    </AutoComplete>
                                </FormItem>

                                <FormItem label="Country" name="country" hasFeedback showValidateSuccess>
                                    <Select
                                        showSearch
                                        name="country"
                                        placeholder="Please select a country"
                                        optionFilterProp="children"
                                        size="large"
                                        filterOption={(input, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.props &&
                                                option.props.children.props.children &&
                                                option.props.children.props.children[1] &&
                                                option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <React.Fragment>
                                                <div>{menu}</div>
                                            </React.Fragment>
                                        )}
                                    >
                                        {CountryRegionData.map((option, index) => {
                                            return (
                                                <Option value={option[1]} key={index}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span role="img" aria-label={option[0]}>
                                                            <CircleFlag country={option[1]} left={0} />{' '}
                                                        </span>

                                                        {option[0]}
                                                    </span>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>

                                <FormItem name="gender" label="Gender">
                                    <Radio.Group name="gender">
                                        <Radio value="M">Male</Radio>
                                        <Radio value="F">Female</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem label="Large image" name="image_large" hasFeedback>
                                    <Input
                                        name="image_large"
                                        placeholder="Large image"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Small image" name="image_small" hasFeedback>
                                    <Input
                                        name="image_small"
                                        placeholder="Small image"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Fargo ID" name="fargo_id" hasFeedback>
                                    <Input
                                        name="fargo_id"
                                        placeholder="Fargo ID"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                        addonAfter={
                                            <Button
                                                type="ghost"
                                                onClick={() => {
                                                    // lookupPlayerFargoRating(values.name);
                                                    handleGetFargoRating();
                                                }}
                                                disabled={values.name ? false : true}
                                            >
                                                Lookup Fargo
                                            </Button>
                                        }
                                    />
                                </FormItem>

                                <FormItem label="Fargo Readable ID" name="fargo_readable_id" hasFeedback>
                                    <Input
                                        name="fargo_readable_id"
                                        placeholder="Fargo Readable ID"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="User" name="user_id" hasFeedback>
                                    <AutoComplete
                                        dataSource={acUserDataSource}
                                        // value={autocompleteValue}
                                        // validate={validator}
                                        style={{ width: '100%' }}
                                        onSearch={onSearchUsers}
                                        onSelect={onSelectUser}
                                        onChange={handleChangeUser}
                                        // onChange={onAutoCompleteChange}
                                        size="large"
                                        name="user_id"
                                        placeholder="Associate with user"
                                        optionFilterProp="children"
                                        optionLabelProp="value"
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.props &&
                                                option.props.children.props.children &&
                                                option.props.children.props.children[1] &&
                                                option.props.children.props.children[1].toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <React.Fragment>
                                                <div>{menu}</div>
                                            </React.Fragment>
                                        )}
                                    >
                                        {acUserDataSource &&
                                            acUserDataSource.map((item, index) => (
                                                <Option key={item.key} value={item.key}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.country && (
                                                            <span role="img" aria-label={item.country}>
                                                                <CircleFlag country={getCountryName(item.country)} left={0} />{' '}
                                                            </span>
                                                        )}
                                                        {formatPlayerName(item.name)}
                                                        {` - ${item.email}`}

                                                        {item.properties &&
                                                            item.properties.fargo_data &&
                                                            item.properties.fargo_data.location &&
                                                            item.properties.fargo_data.location !== ' ' &&
                                                            ` - ${item.properties.fargo_data.location}`}
                                                        {item.properties &&
                                                            item.properties.fargo_data &&
                                                            item.properties.fargo_data.effectiveRating &&
                                                            ` - ${item.properties.fargo_data.effectiveRating}`}
                                                    </span>
                                                </Option>
                                            ))}
                                    </AutoComplete>
                                </FormItem>

                                <GetFargoModal
                                    player={values}
                                    modalVisible={fargoModalVisible}
                                    onModalOk={(players) => {
                                        handleFargoModalOk(players);
                                    }}
                                    onModalCancel={handleFargoModalCancel}
                                />

                                {/* <FormItem>
								<button type="submit" hidden />
							</FormItem> */}
                            </Form>
                        </Modal>
                    )}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {selectedPlayerId ? (
                <Query
                    query={GET_PLAYER_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedPlayerId }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const player = data && data.players && data.players.length > 0 ? data.players[0] : null;

                        return <React.Fragment>{renderForm(player)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddPlayerModal);
