import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Statistic, Avatar, Progress, Button, Table, Typography } from 'antd';
import { getGameType, displayEnumType } from '../../../utils';
import moment from 'moment';
import { CheckCircleOutlined, ClockCircleOutlined, PlayCircleFilled } from '@ant-design/icons';
import VideoOverlay from '../../../components/VideoOverlay';

const { Text, Title } = Typography;
const styles = {};

function MatchTable(props) {
    const { matches, theme } = props;
    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(false);

    console.log(matches);

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            width: 30,
            sorter: (a, b) =>
                b.status.localeCompare(a.status, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record.status === 'IN_PROGRESS' ? (
                            <ClockCircleOutlined
                                style={{
                                    fontSize: 20,
                                    marginTop: 5,
                                    color: record.pool_table && record.pool_table.is_streaming_table === true ? '#ff4d4f' : '#1890ff',
                                }}
                            />
                        ) : record.status === 'NOT_STARTED' ? (
                            <ClockCircleOutlined
                                style={{
                                    fontSize: 20,
                                    marginTop: 5,
                                    color: theme && theme.name === 'dark' ? 'rgba(255,255,255, 0.45)' : 'rgba(0, 0, 0, 0.45)',
                                }}
                            />
                        ) : (
                            <CheckCircleOutlined
                                style={{
                                    fontSize: 20,
                                    marginTop: 5,
                                    color: '#3ebb00',
                                }}
                            />
                        )}
                        {record.livestream_url && (
                            <Button
                                type="text"
                                onClick={(e) => {
                                    setSelectedVideo(record.livestream_url);
                                    setSelectedMatch(record);
                                    setVideoModalVisible(true);
                                    e.stopPropagation();
                                }}
                                style={{ marginLeft: 10, marginTop: 8 }}
                            >
                                <PlayCircleFilled
                                    style={{
                                        fontSize: 20,

                                        color:
                                            record.pool_table && record.pool_table.is_streaming_table === true
                                                ? '#ff4d4f'
                                                : record.status === 'COMPLETED'
                                                ? '#3ebb00'
                                                : '#1890ff',
                                    }}
                                />
                            </Button>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Table',
            dataIndex: 'table',
            render: (text, record) => {
                return (
                    <div>
                        <Text>{(record.pool_table && record.pool_table.label) || '-'}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) =>
                b.name.localeCompare(a.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Score',
            dataIndex: 'score',
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            {record.player_score}-{record.opponent_score}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Type',
            dataIndex: 'game_type',
            render: (text, record) => {
                return (
                    <div>
                        <Avatar
                            size={18}
                            src={getGameType(record.game_type, theme)}
                            style={{
                                position: 'relative',
                                marginRight: 5,
                            }}
                        />
                        <Text>{displayEnumType(record.game_type)}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            render: (text, record) => {
                return (
                    <div>
                        <Progress
                            percent={parseInt(record.progress)}
                            status={parseInt(record.progress) < 100 ? 'active' : parseInt(record.progress) === 100 ? 'success' : 'normal'}
                            size="small"
                        />
                    </div>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'start_date_time',
            defaultSortOrder: 'desc',
            width: 100,
            sorter: (a, b) => {
                if (b.start_date_time && a.start_date_time) {
                    b.start_date_time.localeCompare(a.start_date_time, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
            },

            render: (text, record) => {
                return <Text>{moment(text).format('llll')}</Text>;
            },
        },
    ];

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    function handleVideoModalOk(e) {
        console.log(e);
        setVideoModalVisible(false);
    }

    function handleVideoModalCancel(e) {
        setVideoModalVisible(false);
    }

    return (
        <div className="table_nowrap" style={{ width: '100%' }}>
            <Table
                rowKey="id"
                columns={columns}
                className="match-table table-striped-rows"
                dataSource={matches}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log(record, rowIndex);
                            props.history.push({
                                pathname: `/matches/${record.slug}`,
                                // search: '?query=abc',
                                state: { data: record },
                            });
                        }, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                        className: getClassName(record),
                    };
                }}
                tableLayout="auto"
                scroll={{ x: 400 }}
            />
            <VideoOverlay
                {...props}
                video={selectedVideo}
                match={selectedMatch}
                onOk={handleVideoModalOk}
                onCancel={handleVideoModalCancel}
                modalVisible={videoModalVisible}
            />
        </div>
    );
}

export default withRouter(MatchTable);
