import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { message, Descriptions, Tooltip, Row, Avatar, Button, Empty, Modal, Tag, Icon, List, Skeleton, Col, Typography } from 'antd';
import { Form, FormItem, InputNumber } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, displayEnumType } from '../../../utils';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_MATCH_SUBSCRIPTION } from './data/subscriptions';
import { UPDATE_MATCH_MUTATION } from './data/mutations';
import { GET_MATCH_QUERY, GET_MATCH_PLAYER_SCORES_QUERY } from './data/queries';
import useBreakpoint from 'use-breakpoint';
import ZeroState from '../../../components/ZeroState';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import ScoreboardControls from './ScoreboardControls';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        padding: 20,
    },
};

export default function MatchScoreboard(props) {
    const { matchId, client, authState } = props;

    return (
        <SectionWrapper>
            <SectionContent
                padding="0px"
                style={{
                    width: '100%',
                    height: '100%',
                    flex: 1,
                    background: 'linear-gradient(#101f2f, #06101b)',
                    color: '#fff',
                    position: 'relative',
                }}
            >
                <Query
                    query={GET_MATCH_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{ id: matchId }}
                    onComplete={(res) => {
                        const match = res && res.data && res.data.matches && res.data.matches[0];

                        console.log(match);

                        // setMatchData(match);
                        // if (!currentFrame) {
                        // 	setCurrentFrame(frames[0]);
                        // } else {
                        // 	setCurrentFrame(frames.filter((frame) => frame.id === currentFrame.id)[0]);
                        // }
                    }}
                >
                    {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.matches) || (data && data.matches.length === 0)) {
                            return (
                                <Col xs={24}>
                                    <Card
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	width: '100%',
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                    >
                                        <ZeroState showImage message="That match does not exist." style={{ margin: 30 }} {...props} />
                                    </Card>
                                </Col>
                            );
                        }

                        const matchData = data && data.matches && data.matches[0];

                        return (
                            <ScoreboardControls
                                matchData={matchData}
                                isAdmin={matchData.managers.some((ele) => {
                                    return ele.user_id === (authState && authState.user && authState.user.id) && ele.match_id === matchData.id && ele.admin;
                                })}
                                subscribeToMore={subscribeToMore}
                                subscribeToNewData={() => {
                                    subscribeToMore({
                                        document: GET_MATCH_SUBSCRIPTION,
                                        variables: { match_id: matchId },
                                        updateQuery: (prev, { subscriptionData }) => {
                                            // console.log('match updated');
                                            // console.log({
                                            // 	prev,
                                            // 	subscriptionData
                                            // });
                                            if (!subscriptionData.data) return prev;
                                            const updatedMatch = subscriptionData.data.matches[0];

                                            if (updatedMatch.id === prev.matches[0].id) {
                                                const output = Object.assign({}, prev, {
                                                    ...prev.matches[0],
                                                    updatedMatch,
                                                });

                                                return output;
                                            } else {
                                                return prev;
                                            }
                                        },
                                    });
                                }}
                                {...props}
                            />
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );
}
