import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

const styles = {
	input: {
		maxWidth: 30,
		maxHeight: 24,
		padding: 5,
		textAlign: 'center',
		position: 'relative',
		zIndex: 1
	}
};

export default function MatchScore(props) {
	const { player } = props;
	const [ scoreClicked, setScoreClicked ] = useState(false);
	const [ score, setScore ] = useState(player.score);

	useEffect(
		() => {
			setScore(player.score);
		},
		[ player ]
	);

	function handleScoreClicked(e) {
		e.preventDefault();
		e.stopPropagation();
		setScoreClicked(!scoreClicked);
	}

	function handleFocus(event) {
		event.target.select();
	}

	function handleBlur(e) {
		e.preventDefault();
		e.stopPropagation();
		handleScoreClicked(e);
		// const { value } = e.target;
		// setScore(value);

		// console.log(value);
		console.log(player);
	}

	function onChange(e) {
		e.preventDefault();
		e.stopPropagation();
		// const { value } = e.target;
		// setScore(value);
		// console.log(value);
		// console.log(player);
	}

	function handleKeyPress(e) {
		if (e.keyCode === 27) {
			// escape key
			handleScoreClicked(e);
		}
	}

	function onEnter(e) {
		e.preventDefault();
		e.stopPropagation();
		const { value } = e.target;
		setScore(value);
		handleScoreClicked(e);
		console.log(value);
		console.log(player);
	}

	return (
		<div className="player-score prevent-drag-scroll">
			{scoreClicked === true ? (
				<Input
					defaultValue={score}
					// value={score}
					onChange={onChange}
					autoFocus
					onFocus={handleFocus}
					onBlur={handleBlur}
					style={styles.input}
					onPressEnter={onEnter}
					onKeyDown={handleKeyPress}
				/>
			) : (
				<div onClick={handleScoreClicked}>{score === 0 ? score : score ? score : '-'}</div>
			)}
		</div>
	);
}
