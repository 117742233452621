import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Button, Collapse, Layout, Row, Col, Select, Modal, Typography, Avatar } from 'antd';
import Card, { Meta } from '../../../components/Card';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import Ripples from 'react-ripples';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_ARTISTIC_SHOT_MUTATION } from './data/mutations';
import { GET_ARTISTIC_SHOTS_QUERY } from './data/queries';
import _ from 'lodash';
import slugify from 'slugify';

const { Text, Title } = Typography;
const { confirm } = Modal;
const { Sider, Content, Header } = Layout;
const { Panel } = Collapse;
const { Option, OptGroup } = Select;

export default function ArtisticShotModal(props) {
    const { modalVisible, currentShot, onModalOk, onModalCancel, onShotSelected } = props;
    const currentShotString = currentShot && `d${currentShot.discipline}-${currentShot.shot}`;
    const [selectedShot, setSelectedShot] = useState(currentShotString || 'd1-1a');
    const [selectedShotData, setSelectedShotData] = useState();
    const [selectedDiscipline, setSelectedDiscipline] = useState([1]);
    const [artisticPoolShots, setArtisticPoolShots] = useState();
    const [createShot] = useMutation(CREATE_ARTISTIC_SHOT_MUTATION);

    console.log(currentShot);

    // const disciplines = _.groupBy(artisticPoolShots, 'discipline');
    // const disciplineArray = Object.values(disciplines);

    // useEffect(() => {
    //     const shotData = artisticPoolShots.filter((shot) => {
    //         const currentShot = `d${shot.discipline}-${shot.shot}`;
    //         return currentShot === 'd1-1a';
    //     })[0];
    //     setSelectedShotData(shotData);
    // }, [artisticPoolShots]);

    function createShots(shots) {
        console.log(shots);

        createShot({
            variables: {
                objects: shots.map((data) => {
                    const stageSlug = slugify(data.name, {
                        replacement: '-',
                        remove: /[*+~.,()'"#!:/@]/g,
                        lower: true,
                    });
                    return {
                        discipline: data.discipline,
                        shot: data.shot,
                        name: data.name,
                        slug: stageSlug,
                        points: data.points,
                        image_url: data.image_url,
                    };
                }),
            },
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function handleChange(value) {
        const discipline = value.split('-')[0];
        const selectedDiscipline = discipline.substring(1, 2);
        setSelectedShot(value);
        setSelectedDiscipline([selectedDiscipline]);
        const shotData = artisticPoolShots.filter((shot) => {
            const current = `d${shot.discipline}-${shot.shot}`;
            return current === value;
        })[0];
        setSelectedShotData(shotData);
    }

    function changeDiscipline(key) {
        setSelectedDiscipline([key]);
    }

    return (
        <Modal
            title="Artistic shot catalog"
            centered
            transitionName="fade"
            wrapClassName="fargo-modal"
            // transitionName="none"
            maskTransitionName="none"
            visible={modalVisible}
            onOk={() => {
                onShotSelected(selectedShotData);
                onModalOk();
            }}
            onCancel={onModalCancel}
            okText="Select Shot"
            cancelText="Close"
            bodyStyle={{ width: '100%', maxHeight: 800, overflowY: 'auto', padding: 10 }}
            destroyOnClose
            width="90%"
        >
            <Query
                query={GET_ARTISTIC_SHOTS_QUERY}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                onCompleted={async (data) => {
                    console.log(data);
                    const poolShots = data.artistic_pool_shots;
                    setArtisticPoolShots(poolShots);

                    const shotData = poolShots.filter((shot) => {
                        const current = `d${shot.discipline}-${shot.shot}`;
                        return current === selectedShot;
                    })[0];
                    setSelectedShotData(shotData);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div>
                                <CircularLoader />
                            </div>
                        );
                    if (error) return <div>Error: {error.message}</div>;

                    const poolShots = data.artistic_pool_shots;
                    const disciplines = _.groupBy(poolShots, 'discipline');
                    const disciplineArray = Object.values(disciplines);
                    const disciplineTypes = ['Trick & Fancy', 'Special Arts', 'Draw', 'Follow', 'Bank/Kick', 'Stroke', 'Jump', 'Masse'];

                    return (
                        <Layout style={{ height: '100%', backgroundColor: 'transparent' }}>
                            {/* <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                        createShots(artisticPoolShots);
                    }}
                >
                    Create shots
                </Button> */}
                            <Sider
                                theme="dark"
                                width={400}
                                style={{
                                    backgroundColor: '#121b28',
                                    // borderLeft: '1px solid #eee',
                                    position: 'relative',
                                    zIndex: 1,
                                    boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                    overflowY: 'auto',
                                }}
                            >
                                <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                                    <Select defaultValue={selectedShot} onChange={handleChange} filterOption showSearch style={{ width: '100%' }}>
                                        {disciplineArray &&
                                            disciplineArray.map((discipline, index) => {
                                                return (
                                                    <OptGroup label={`Discipline ${index + 1}`} key={index}>
                                                        {discipline &&
                                                            discipline.map((shot, index2) => {
                                                                return (
                                                                    <Option value={`d${shot.discipline}-${shot.shot}`} key={`${index}+${index2}`} size="large">
                                                                        D{shot.discipline}-{shot.shot && `${shot.shot.toUpperCase()}`} ({shot.points} points) -{' '}
                                                                        {shot.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                    </OptGroup>
                                                );
                                            })}
                                    </Select>
                                </div>
                                <div style={{ marginTop: 10, padding: 10 }}>
                                    {/* <Title level={4}>Shots by discipline</Title> */}
                                    <Collapse accordion bordered={false} defaultActiveKey={[1]} activeKey={selectedDiscipline} onChange={changeDiscipline}>
                                        {disciplineArray &&
                                            disciplineArray.map((discipline, index) => {
                                                return (
                                                    <Panel
                                                        header={`Discipline ${index + 1} - ${disciplineTypes[index + 1]}`}
                                                        extra={`${discipline.length} shots`}
                                                        key={index + 1}
                                                        style={{ padding: 0, maxHeight: 600, overflowY: 'auto' }}
                                                        forceRender={true}
                                                    >
                                                        <Row gutter={16} justify="start" type="flex">
                                                            {discipline &&
                                                                discipline.map((shot, index2) => {
                                                                    const current = `d${shot.discipline}-${shot.shot}`;
                                                                    return (
                                                                        <Col xs={24} key={index2}>
                                                                            <Ripples color="#ccc">
                                                                                <div style={{ marginBottom: 5 }}>
                                                                                    <Card
                                                                                        hoverable
                                                                                        onClick={() => {
                                                                                            handleChange(current);
                                                                                        }}
                                                                                        bodyStyle={{ padding: 15 }}
                                                                                        className={current === selectedShot && 'selected-card'}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                backgroundColor: '#fff',
                                                                                                padding: 6,
                                                                                                borderRadius: 5,
                                                                                                overflow: 'hidden',
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={shot.image_url}
                                                                                                alt={shot.name}
                                                                                                style={{ width: '100%' }}
                                                                                            />
                                                                                        </div>
                                                                                        <div style={{ marginTop: 10 }}>
                                                                                            <Title level={4} style={{ margin: 0 }}>
                                                                                                {shot.name} - D{shot.discipline}-
                                                                                                {shot.shot && `${shot.shot.toUpperCase()}`} ({shot.points}{' '}
                                                                                                points)
                                                                                            </Title>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                            </Ripples>
                                                                        </Col>
                                                                    );
                                                                })}
                                                        </Row>
                                                    </Panel>
                                                );
                                            })}
                                    </Collapse>
                                    {/* {artisticPoolShots &&
                                    artisticPoolShots.map((shot, index) => {
                                        return (
                                            <Card key={index} bodyStyle={{ padding: 10 }}>
                                                <div style={{ marginBottom: 10 }}>
                                                    <Text>Discipline {shot.discipline}</Text>
                                                    <br />
                                                    <Text>
                                                        Shot {shot.shot} ({shot.points}) - {shot.name}
                                                    </Text>
                                                </div>
                                                <div style={{ backgroundColor: '#fff', padding: 3, borderRadius: 10, overflow: 'hidden' }}>
                                                    <img src={shot.image_url} alt={shot.name} style={{ width: '100%' }} />
                                                </div>
                                            </Card>
                                        );
                                    })} */}
                                </div>
                            </Sider>

                            <Content>
                                {selectedShotData && (
                                    <div style={{ padding: 20 }}>
                                        <Title level={3}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>{selectedShotData.name}</div>
                                                <div>
                                                    D{selectedShotData.discipline}-{selectedShotData.shot && `${selectedShotData.shot.toUpperCase()}`} (
                                                    {selectedShotData.points} points)
                                                </div>
                                            </div>
                                        </Title>
                                        <div
                                            style={{
                                                backgroundColor: '#fff',
                                                padding: 6,
                                                borderRadius: 5,
                                                overflow: 'hidden',
                                                // maxWidth: 800,
                                            }}
                                        >
                                            <img src={selectedShotData.image_url} alt={selectedShotData.name} style={{ width: '100%' }} />
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            <Title level={4} style={{ fontSize: 18 }}>
                                                {selectedShotData.description}
                                            </Title>
                                        </div>
                                    </div>
                                )}
                            </Content>
                        </Layout>
                    );
                }}
            </Query>
        </Modal>
    );
}
