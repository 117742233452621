import React, { useState, useEffect } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { Tabs } from 'antd';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TBManageButtons from './TBManageButtons';
import TBScheduleMatches from './TBScheduleMatches';
import TBScheduleTables from './TBScheduleTables';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import _ from 'lodash';
import * as routes from '../../config/routes';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;

function TBSchedule(props) {
    const { authState, tournament, width, theme } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['matches', 'tables'];
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const defaultSize = breakpoint === 'mobile' ? 'small' : 'medium';
    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

    let pathMatch =
        splitPath[splitPath.length] === slug
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : 'matches';

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/tournament-builder/${tournament.slug}/schedule/${tab}`);
    };

    const [current, setCurrent] = useState(pathMatch);

    useEffect(() => {
        setCurrent(pathMatch);
    }, [pathMatch]);

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="schedule" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="0px">
                <Tabs
                    defaultActiveKey={current}
                    activeKey={current}
                    animated={{ inkBar: true, tabPane: false }}
                    // renderTabBar={() => <ScrollableInkTabBar />}
                    // renderTabContent={() => <TabContent />}
                    onTabClick={handleTabClick}
                    className="tabs-bg-blue"
                    tabPosition="top"
                    tabBarStyle={{
                        textTransform: 'uppercase',
                        paddingLeft: breakpoint === 'mobile' ? 0 : 24,
                        paddingRight: breakpoint === 'mobile' ? 0 : 24,
                        paddingTop: 0,
                        background: '#06101b',
                        // background: 'linear-gradient(#0b1a2d, #13253c)',
                        boxShadow: theme && theme.name === 'dark' ? 'rgba(0, 0, 0, 0.8) 0px 2px 8px 0px' : 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
                        color: '#fff',
                        zIndex: 1,
                        position: 'relative',
                        borderBottom: 'none',
                        borderTop: '1px solid rgba(255,255,255,0.1)',
                    }}
                >
                    <TabPane tab="Matches" key="matches">
                        <Route
                            exact
                            path={routes.TOURNAMENT_BUILDER.DETAIL_SCHEDULE_MATCHES}
                            render={(matchProps) => <TBScheduleMatches {...matchProps} {...props} tournament={tournament} />}
                        />
                        <Route
                            exact
                            path={routes.TOURNAMENT_BUILDER.DETAIL_SCHEDULE}
                            render={(matchProps) => <TBScheduleMatches {...matchProps} {...props} tournament={tournament} />}
                        />
                    </TabPane>
                    <TabPane tab="Tables" key="tables">
                        <Route
                            exact
                            path={routes.TOURNAMENT_BUILDER.DETAIL_SCHEDULE_TABLES}
                            render={(matchProps) => <TBScheduleTables {...matchProps} {...props} tournament={tournament} />}
                        />
                    </TabPane>
                </Tabs>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBSchedule);
