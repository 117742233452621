import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { message, Icon, Tabs, Button, Typography, Menu, Dropdown } from 'antd';
import useBreakpoint from 'use-breakpoint';
import CircularLoader from '../../../components/CircularLoader';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import SectionHeader from '../../../components/SectionHeader';
import MatchOverview from './MatchOverview';
import MatchScoreboard from './MatchScoreboard';
import MatchStats from './MatchStats2';
import MatchStatsAdvanced from './MatchStatsAdvanced';
import MatchStatsPlayByPlay from './MatchStatsPlayByPlay';
import { Query } from '@apollo/client/react/components';
import { GET_MATCH_BY_SLUG_QUERY } from './data/queries';
import { displayEnumType } from '../../../utils';
import * as routes from '../../../config/routes';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function MatchDetail(props) {
    const { authState, history, match } = props;
    const [current, setCurrent] = useState('overview');
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const tabs = ['overview', 'scoreboard', 'stats', 'stats-advanced', 'play-by-play'];

    useEffect(() => {
        let pathMatch = _.includes(tabs, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(tabs, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : 'overview';

        setCurrent(pathMatch);
    }, [urlPath, tabs, splitPath, params]);

    const styles = {
        headerImg: {
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
        },
        header: {
            paddingTop: 16,
            paddingLeft: breakpoint === 'mobile' ? 10 : 20,
            paddingRight: breakpoint === 'mobile' ? 10 : 20,
            paddingBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/matches/${slug}/${tab}`);
    };

    function handleButtonClick(slug) {
        const layout = 1;
        window.open(`/matches/${slug}/overlay?layout=${layout}`, '_blank');
    }

    function handleMenuClick(e) {
        const layout = e.key || 1;
        console.log(layout);
        if (layout === 'large') {
            window.open(`/matches/${slug}/score-overlay`, '_blank');
        } else if (layout === 'table') {
            window.open(`/matches/${slug}/table-overlay`, '_blank');
        } else if (layout === 'omegatv') {
            window.open(`/matches/${slug}/overlay?layout=omegatv`, '_blank');
        } else if (layout === 'omegatv2') {
            window.open(`/matches/${slug}/overlay?layout=omegatv2&show_balls=false`, '_blank');
        } else {
            if (layout === '4') {
                window.open(`/matches/${slug}/overlay?layout=1&show_balls=false`, '_blank');
            } else if (layout === '5') {
                window.open(`/matches/${slug}/overlay?layout=2&show_balls=false`, '_blank');
            } else if (layout === '6') {
                window.open(`/matches/${slug}/overlay?layout=3&show_balls=false`, '_blank');
            } else {
                window.open(`/matches/${slug}/overlay?layout=${layout}`, '_blank');
            }
        }
    }

    return (
        <Query
            query={GET_MATCH_BY_SLUG_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: slug }}
            onCompleted={(data) => {
                console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.matches) {
                    return <div>Match not found.</div>;
                }
                if (data && data.matches.length === 0) {
                    return <div>Match not found.</div>;
                }
                const match = data.matches[0];

                return (
                    <div style={styles.headerImg}>
                        <SectionHeader
                            title={
                                <Text style={breakpoint === 'mobile' ? { fontSize: 18, color: '#fff', maxWidth: 300 } : { fontSize: 20, color: '#fff' }}>
                                    {displayEnumType(slug)}
                                </Text>
                            }
                            titleStyle={{
                                color: '#fff',
                                textTransform: 'uppercase',
                                margin: 0,
                                display: 'flex',
                                letterSpacing: breakpoint === 'mobile' ? 0 : 6,
                            }}
                            backButton={
                                <Button
                                    onClick={() => props.history.push(`/matches`)}
                                    type="default"
                                    shape="circle"
                                    icon="arrow-left"
                                    size="large"
                                    ghost
                                    style={{ border: 'none' }}
                                />
                            }
                            style={styles.header}
                            wrapButtons
                        >
                            <React.Fragment>
                                {((authState && authState.user && authState.user.id === match.creator_id) ||
                                    (authState && authState.user && authState.user.role === 'admin')) && (
                                    <Button ghost onClick={() => props.history.push(`/matches/${match.slug}/edit`)}>
                                        <Icon type="edit" /> Edit
                                    </Button>
                                )}

                                <Dropdown.Button
                                    type="ghost"
                                    onClick={() => {
                                        handleButtonClick(match.slug);
                                    }}
                                    style={{ marginLeft: 10 }}
                                    icon={<Icon type="down" />}
                                    overlay={
                                        <Menu onClick={handleMenuClick}>
                                            <Menu.Item key="1">Overlay layout 1</Menu.Item>
                                            <Menu.Item key="2">Overlay layout 2</Menu.Item>
                                            <Menu.Item key="3">Overlay layout 3</Menu.Item>
                                            <Menu.Item key="omegatv">Omega TV overlay</Menu.Item>
                                            <Menu.Item key="large">Large score overlay</Menu.Item>
                                            <Menu.Item key="table">Table overlay</Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item key="4">Overlay layout 1 - No ball tracker</Menu.Item>
                                            <Menu.Item key="5">Overlay layout 2 - No ball tracker</Menu.Item>
                                            <Menu.Item key="6">Overlay layout 3 - No ball tracker</Menu.Item>
                                            <Menu.Item key="omegatv2">Omega TV overlay - No ball tracker</Menu.Item>
                                        </Menu>
                                    }
                                    className="stream-overlay-button"
                                >
                                    <Icon type="play-circle" /> Stream Overlay
                                </Dropdown.Button>

                                {/* <a
									href={`/matches/${match.slug}/overlay`}
									style={{ marginLeft: 10 }}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Button type="ghost" ghost size="default">
										<Icon type="play-circle" /> Stream Overlay
									</Button>
								</a> */}
                                <a href={`/matches/${match.slug}/stats-overlay`} style={{ marginLeft: 10 }} target="_blank" rel="noopener noreferrer">
                                    <Button type="ghost" ghost size="default">
                                        <Icon type="bar-chart" /> Stats
                                    </Button>
                                </a>
                            </React.Fragment>
                        </SectionHeader>

                        <Tabs
                            defaultActiveKey={current}
                            activeKey={current}
                            animated={{ inkBar: true, tabPane: false }}
                            // renderTabBar={() => <ScrollableInkTabBar />}
                            // renderTabContent={() => <TabContent />}
                            onTabClick={handleTabClick}
                            className="tabs-bg"
                            tabPosition="top"
                            tabBarStyle={{
                                textTransform: 'uppercase',
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 0,
                                // backgroundColor: '#132339',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                color: '#fff',
                                zIndex: 1,
                                position: 'relative',
                                borderBottom: 'none',
                                borderTop: '1px solid rgba(255,255,255,0.1)',
                            }}
                        >
                            <TabPane tab="Overview" key="overview">
                                <Route
                                    path={routes.DIGITALPOOL.MATCH_DETAIL}
                                    render={(matchProps) => <MatchOverview {...matchProps} {...props} matchData={match} />}
                                />
                            </TabPane>
                            <TabPane tab="Scoreboard" key="scoreboard">
                                <Route
                                    path={routes.DIGITALPOOL.MATCH_DETAIL_SCOREBOARD}
                                    render={(matchProps) => <MatchScoreboard {...matchProps} {...props} matchId={match.id} />}
                                />
                            </TabPane>
                            <TabPane tab="Stats" key="stats">
                                <Route
                                    path={routes.DIGITALPOOL.MATCH_DETAIL_STATS}
                                    render={(matchProps) => <MatchStats {...matchProps} {...props} matchId={match.id} matchData={match} />}
                                />
                            </TabPane>
                            {match && match.id === 971 && (
                                <TabPane tab="Advanced Stats" key="stats-advanced">
                                    <Route
                                        path={routes.DIGITALPOOL.MATCH_DETAIL_STATS_ADVANCED}
                                        render={(matchProps) => <MatchStatsAdvanced {...matchProps} {...props} matchId={match.id} matchData={match} />}
                                    />
                                </TabPane>
                            )}
                            {match && match.id === 971 && (
                                <TabPane tab="Play By Play" key="play-by-play">
                                    <Route
                                        path={routes.DIGITALPOOL.MATCH_DETAIL_PLAY_BY_PLAY}
                                        render={(matchProps) => <MatchStatsPlayByPlay {...matchProps} {...props} matchId={match.id} matchData={match} />}
                                    />
                                </TabPane>
                            )}
                        </Tabs>
                    </div>
                );
            }}
        </Query>
    );
}

export default withRouter(MatchDetail);
