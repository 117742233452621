import React, { useState, useEffect } from 'react';
import TBCreateAddTables from './TBCreateAddTables';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

export default function TBTournamentStep3Tables(props) {
    const { authState, match, tournament } = props;
    let url;
    if (match.params.slug) {
        url = `/tournament-builder/${match.params.slug}/edit`;
    } else {
        url = `/tournament-builder/new/edit`;
    }

    return (
        <div
            style={{
                padding: 20,
            }}
        >
            <TBCreateAddTables
                {...props}
                tournament={tournament}
                venue={tournament && tournament.venue}
                goBack={() => {
                    props.history.push(`${url}/players`);
                    const user = authState && authState.user && authState.user;
                    mixpanel.track('Tournament tables added', {
                        ...user,
                        displayName: user.displayName,
                        tournamentId: tournament.id,
                        tournamentName: tournament.name,
                        source: 'web',
                    });
                    // setCurrentStep(1);
                }}
                onContinue={() => {
                    props.history.push(`${url}/preview`);
                    // setCurrentStep(3);
                }}
            />
        </div>
    );
}
