import React, { Component } from 'react';

export default class Masonry extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = { columns: 1 };
		this.masonryRef = React.createRef();
	}
	componentDidMount() {
		this._isMounted = true;
		this.onResize();
		window.addEventListener('resize', this.onResize);
	}

	getColumns = (w) => {
		return (
			this.props.breakPoints.reduceRight((p, c, i) => {
				return c < w ? p : i;
			}, this.props.breakPoints.length) + 1
		);
	};

	onResize = () => {
		const node = this.masonryRef.current;
		const columns = node && this.getColumns(node.offsetWidth);
		if (columns !== this.state.columns) {
			if (this._isMounted) {
				this.setState({ columns: columns });
			}
		}
	};

	mapChildren = () => {
		let col = [];
		const numC = this.state.columns;
		for (let i = 0; i < numC; i++) {
			col.push([]);
		}
		return this.props.children.reduce((p, c, i) => {
			p[i % numC].push(c);
			return p;
		}, col);
	};

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener('resize', this.handleResize);
	}

	render() {
		return (
			<div className="masonry" ref={this.masonryRef}>
				{this.mapChildren().map((col, ci) => {
					return (
						<div className="column" key={ci}>
							{col.map((child, i) => {
								return (
									<div key={i} className="item">
										{child}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	}
}
