import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Steps } from 'antd';
import { createStep, createGhostGameFormPublicRadio } from '../../data/atoms';
import { settingsFormSelector } from '../../data/selectors';


const { Step } = Steps;

function RenderSteps(props) {
    const { invite, step, onChange } = props;

    let jsx;

    jsx = (
        <Steps
            direction="vertical"
            current={step}
            onChange={onChange}
            style={{ marginBottom: 20, paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
        >
            <Step title="Settings" />
            <Step title="Invite Players" /> 
            <Step title="Review" />
        </Steps>
    );

    return jsx;
}

export default function CreateSteps(props) {
    const { onChange } = props;
    const settings = useRecoilValue(settingsFormSelector);

    console.log("settings", settings);
    
    return ( <RenderSteps step={settings.step} onChange={onChange} /> );
}