import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Menu, Dropdown, Button, Icon, Typography, Empty, Row, Col, Tooltip, Modal, Input, Layout } from 'antd';
import Card from '../../components/Card';
import ScrollContainer from 'react-indiana-drag-scroll';
import CircularLoader from '../../components/CircularLoader';
import ZeroState from '../../components/ZeroState';
import { GET_TOURNAMENT_BRACKET_STAGES_QUERY, GET_ALL_TOURNAMENT_TABLES_QUERY } from './data/queries';
import { GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION, GET_TOURNAMENT_BRACKET_SUBSCRIPTION } from './data/subscriptions';
import { UPDATE_TOURNAMENT_PLAYER_MUTATION, ADVANCE_TOURNAMENT_BRACKET_STAGE_MUTATION } from './data/mutations';
import { DownOutlined } from '@ant-design/icons';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PendingMatches from '../digitalpool/tournaments/bracket/PendingMatches';
import useBreakpoint from 'use-breakpoint';
import RoundRobinViewerMatchOverlay from './RoundRobinViewerMatchOverlay';
import SetRoundTimeModal from '../digitalpool/tournaments/bracket/SetRoundTimeModal';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { MapInteractionCSS } from 'react-map-interaction';
import TournamentStageDropdown from '../digitalpool/tournaments/bracket/TournamentStageDropdown';
import SidePanel from '../digitalpool/tournaments/bracket/SidePanel';
import MatchInfoModal from '../digitalpool/tournaments/bracket/MatchInfoModal';
import Autopilot from '../digitalpool/tournaments/bracket/Autopilot3';
import RoundRobinMatches from './RoundRobinMatches';

import { DigitalPoolAPI } from '../../lib/api';
import _ from 'lodash';
import qs from 'query-string';
import moment from 'moment';

const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
// const { SubMenu } = Menu;
const { confirm } = Modal;

const tabHeight = 45;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export function RoundRobinViewer(props) {
    const { authState, tournament, selectedStage, stages, tables, theme, urlPath, statusPanel, tablesPanel, isPublic, isFullScreen, onSelectedStage } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [groups, setGroups] = useState([]);
    const [originalMatches, setOriginalMatches] = useState();
    const [matches, setMatches] = useState();
    const [playersToAdvance, setPlayersToAdvance] = useState();
    const [playerWaiting, setPlayerWaiting] = useState();
    const [currentRound, setCurrentRound] = useState();
    // const [tables, setTables] = useState([]);
    // const [selectedStage, setSelectedStage] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [sidePanelOpen, setSidePanelOpen] = useState(breakpoint === 'mobile' ? false : true);
    const [showStatusPanel, setShowStatusPanel] = useState(breakpoint === 'mobile' ? false : true);
    const [roundTimeModalVisible, setRoundTimeModalVisible] = useState(false);
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const [advanceTournamentStage] = useMutation(ADVANCE_TOURNAMENT_BRACKET_STAGE_MUTATION);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const tournamentPath = props.match.params.slug;
    const stageSlug = props.match.params.stage;
    console.log('stage slug', stageSlug);
    const viewerEl = useRef();

    const windowHeight = window.innerHeight;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const sectionHeaderEl = document.querySelector('.section-header');
    const progressBarEl = document.querySelector('.tournament-progress-bar');

    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    let footerHeight = 53;

    console.log('players to advance', playersToAdvance);

    const API = DigitalPoolAPI(props);

    if (isPublic === true) {
        if (isFullScreen === true) {
            bracketHeight = '100%';
        } else {
            const spacer = breakpoint === 'mobile' ? 170 : 95;
            bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
        }
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = '100%';
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }
    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    function canAccess(user) {
        if (user && user.role && (user.role === 'user' || user.role === 'admin')) {
            return true;
        } else {
            return false;
        }
    }

    function showCompleteDialog(winner) {
        console.log(winner);
        let content;
        if (winner.is_split === true) {
            content = `${winner.challenger1_name} and ${winner.challenger2_name} split 1st place!.`;
        } else {
            content = `${winner.challenger2_name || winner.challenger1_name} won the tournament!`;
        }
        Modal.success({
            content: content,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        // setTournamentComplete(true);
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalScoreVisible(true);
    }

    function handleSetMatches(data) {
        setMatches(data);
    }

    function handlePlayerWaiting(data) {
        setPlayerWaiting(data);
    }

    function handleMatchModalOk(e) {
        // console.log(e);
        setMatchModalVisible(false);
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    function handleMatchSelected(match) {
        setCurrentMatch(match);
        setMatchModalVisible(true);
    }

    function handleRoundTimeModalOk(e) {
        setRoundTimeModalVisible(false);
    }

    function handleRoundTimeModalCancel(e) {
        setRoundTimeModalVisible(false);
    }

    function showConfirmStart(tables) {
        confirm({
            title: 'Are you ready to start the tournament?',
            content: 'This action will start the tournament clock.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Tournament',
            cancelText: "I'll do this later",
            onOk() {
                if (tables.length > 0) {
                    startTournament();
                } else {
                    message.error('You must add at least 1 table before you can start the tournament.', 10);
                }
            },
            onCancel() {},
        });
    }

    // function advancePlayers(advancingPlayers, nextStageMatches) {
    //     let result = advancingPlayers.reduce((accumulatorPromise, nextPlayer) => {
    //         return accumulatorPromise.then(() => {
    //             return updatePlayer({
    //                 id: nextPlayer.id,
    //                 is_advancing: true,
    //             });
    //         });
    //     }, Promise.resolve());

    //     result.then((e) => {
    //         // message.success(`Players advanced to stage 2`);
    //         // console.log('PLAYER UPDATE COMPLETE', players);

    //         const key = 'advanceStage';
    //         const nextStageId = nextStageMatches[0] && nextStageMatches[0].stage_id;
    //         console.log(nextStageMatches);

    //         let matchCounter = 0;

    //         let resultMatches = nextStageMatches.reduce((accumulatorPromise, nextMatch, index) => {
    //             return accumulatorPromise.then(() => {
    //                 const p1 = advancingPlayers[matchCounter];
    //                 const p2 = advancingPlayers[matchCounter + 1];
    //                 return advanceTournamentStage({
    //                     variables: {
    //                         tournament_id: tournament.id,
    //                         stage_id: nextStageId,
    //                         match_id: nextMatch.id,
    //                         changes: {
    //                             challenger1_id: p1 && p1.id,
    //                             challenger1_name: p1 && p1.name,
    //                             challenger1_country: p1 && p1.country,
    //                             challenger1_score: 0,
    //                             challenger1_race_to: p1 && p1.race_to,
    //                             challenger1_seed: p1 && p1.seed,
    //                             challenger1_is_winner: false,
    //                             challenger1_is_forfeit: false,
    //                             challenger1_is_withdraw: false,
    //                             challenger2_id: p2 && p2.id,
    //                             challenger2_name: p2 && p2.name,
    //                             challenger2_country: p2 && p2.country,
    //                             challenger2_score: 0,
    //                             challenger2_race_to: p2 && p2.race_to,
    //                             challenger2_seed: p2 && p2.seed,
    //                             challenger2_is_winner: false,
    //                             challenger2_is_forfeit: false,
    //                             challenger2_is_withdraw: false,
    //                         },
    //                     },
    //                     // notifyOnNetworkStatusChange: true,
    //                     // awaitRefetchQueries: true,
    //                     // refetchQueries: [
    //                     //     {
    //                     //         query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
    //                     //         variables: {
    //                     //             match_id: advanceToMatch.id,
    //                     //             tournament_id: tournament.id,
    //                     //         },
    //                     //     },
    //                     // ],
    //                 })
    //                     .then((data) => {
    //                         matchCounter = matchCounter + 2;
    //                     })
    //                     .catch((error) => {
    //                         console.log(error);
    //                         message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
    //                     });
    //             });
    //         }, Promise.resolve());

    //         resultMatches.then((e) => {
    //             message.success(`Players advanced to stage 2`);
    //             console.log('PLAYER UPDATE COMPLETE', advancingPlayers);
    //         });
    //     });
    // }

    function advancePlayers(advancingPlayers, nextStage, nextStageMatches) {
        // message.success(`Players advanced to stage 2`);
        // console.log('PLAYER UPDATE COMPLETE', players);

        const key = 'advanceStage';
        const nextStageId = nextStage.id;
        console.log(nextStageMatches);

        message.loading({ content: `Advancing players...`, key, duration: 2 });

        let resultMatches = nextStageMatches
            .filter((match) => {
                return match.round === 1;
            })
            .reduce((accumulatorPromise, nextMatch, index) => {
                return accumulatorPromise.then(() => {
                    const p1Seed = nextMatch.challenger1_seed;
                    const p2Seed = nextMatch.challenger2_seed;

                    const p1 = advancingPlayers[p1Seed - 1];
                    const p2 = advancingPlayers[p2Seed - 1];

                    const updatedMatch = {
                        challenger1_id: p1 && p1.id,
                        challenger1_name: p1 && p1.name,
                        challenger1_country: p1 && p1.country,
                        challenger1_score: 0,
                        challenger1_race_to: p1 && p1.race_to,
                        challenger1_is_winner: false,
                        challenger1_is_forfeit: false,
                        challenger1_is_withdraw: false,
                        challenger2_id: p2 && p2.id,
                        challenger2_name: p2 && p2.name,
                        challenger2_country: p2 && p2.country,
                        challenger2_score: 0,
                        challenger2_race_to: p2 && p2.race_to,
                        challenger2_is_winner: false,
                        challenger2_is_forfeit: false,
                        challenger2_is_withdraw: false,
                    };

                    let changes;
                    if (!updatedMatch.challenger1_name || !updatedMatch.challenger2_name) {
                        changes = {
                            ...updatedMatch,
                            challenger1_name: updatedMatch.challenger1_name || 'BYE',
                            challenger2_name: updatedMatch.challenger2_name || 'BYE',
                            is_bye: true,
                        };
                    } else {
                        changes = updatedMatch;
                    }

                    return advanceTournamentStage({
                        variables: {
                            tournament_id: tournament.id,
                            stage_id: nextStageId,
                            match_id: nextMatch.id,
                            changes: changes,
                        },
                        // notifyOnNetworkStatusChange: true,
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        //     {
                        //         query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                        //         variables: {
                        //             match_id: advanceToMatch.id,
                        //             tournament_id: tournament.id,
                        //         },
                        //     },
                        // ],
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                            message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
                        });
                });
            }, Promise.resolve());

        resultMatches.then((e) => {
            message.success({ content: `Players advanced to stage 2`, key, duration: 2 });
            console.log('PLAYER UPDATE COMPLETE', advancingPlayers);
            onSelectedStage(nextStage);
            props.history.push(`/tournament-builder/${tournament.slug}/viewer/stage-2`);
        });
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                // notifyOnNetworkStatusChange: true,
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PLAYERS_W_STATS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then((data) => {
                // console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function showConfirmAdvancePlayers(players, nextStage, nextStageMatches) {
        const advancingPlayers =
            players &&
            players.map((player, index) => {
                return {
                    name: player.name,
                    seed: index + 1,
                    rank_points: player.rank_points,
                };
            });

        confirm({
            title: `Do you want to advance the following ${players && players.length} players to stage 2?`,
            content: (
                <div>
                    {advancingPlayers.map((player, index) => (
                        <div key={index}>
                            {player.name} ({player.seed}) - {player.rank_points} Points
                        </div>
                    ))}
                </div>
            ),
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Advance Players',
            cancelText: 'Cancel',
            onOk() {
                advancePlayers(players, nextStage, nextStageMatches);
            },
            onCancel() {},
        });
    }

    function startTournament(callback) {
        API.updateTournament({
            start_date_time: moment.utc(),
            status: 'IN_PROGRESS',
        }).then((res) => {
            // await API.updateTournamentStatus('IN_PROGRESS');
            message.success({
                content: `Tournament clock started.`,
                duration: 2,
            });

            return res;
        });
    }

    return (
        <Query
            query={GET_TOURNAMENT_BRACKET_STAGES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onCompleted={(data, loading, error) => {
                if (!loading && !error) {
                    const tournament_brackets = data.tournament_brackets;
                    // const matches = tournament_brackets.filter((match) => {
                    //     return match.stage_number === 1;
                    // });
                    const originalMatches = tournament_brackets;
                    const matches =
                        tournament_brackets &&
                        tournament_brackets.filter((match) => {
                            return match.stage_number !== 2;
                        });

                    const groupedMatches = _.groupBy(matches, 'group');
                    const groups = Object.values(groupedMatches);
                    // console.log(groups);
                    const groupsWithRounds =
                        groups &&
                        groups.map((group, index) => {
                            const groupNumber = index + 1;
                            const groupName = group && group[0] && group[0].group_name;
                            const groupedRounds = _.groupBy(group, 'round');
                            const rounds = Object.values(groupedRounds);

                            return {
                                group: groupNumber,
                                group_name: groupName,
                                rounds: rounds,
                            };
                        });
                    // console.log(groupsWithRounds);
                    console.log(matches);
                    setGroups(groupsWithRounds);
                    setOriginalMatches(originalMatches);
                    setMatches(matches);
                }
            }}
        >
            {({ loading, error, data, subscribeToMore, networkStatus }) => {
                if (networkStatus === 1 || loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                    return (
                        <Row gutter={24} justify="center" type="flex" style={{ padding: '20px 40px' }}>
                            <Col xs={24}>
                                <Card>
                                    <ZeroState showImage message="No tournament bracket exists yet." style={{ margin: 30 }} {...props}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={() => {
                                                props.history.push(`/tournament-builder/${tournament.slug}/edit?step=4`);
                                            }}
                                        >
                                            Set up bracket
                                        </Button>
                                    </ZeroState>
                                </Card>
                            </Col>
                        </Row>
                    );
                }

                return (
                    <Layout style={{ height: bracketHeight, position: 'relative' }}>
                        {showStatusPanel === true && statusPanel !== false && tournament.status !== 'COMPLETED' && (
                            <Sider
                                theme={theme.name}
                                width={300}
                                style={{
                                    backgroundColor: theme.bracket.panels.backgroundColor,
                                    // borderLeft: '1px solid #eee',
                                    position: 'relative',
                                    zIndex: 1,
                                    boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                }}
                            >
                                <Autopilot
                                    theme={theme}
                                    tournament={tournament}
                                    tournamentMatches={matches}
                                    tables={tables}
                                    // players={players}
                                    onShowScoreModal={showScoreModal}
                                    showPanel={showStatusPanel}
                                    bracketHeight={isFullScreen === true ? windowHeight : bracketHeight}
                                    onStartTournament={() => {
                                        if (tables.length > 0) {
                                            showConfirmStart(tables);
                                        } else {
                                            message.error('You must first add tables and players before you can start the tournament');
                                        }
                                    }}
                                    setShowStatusPanel={setShowStatusPanel}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                            variables: { tournamentId: tournament.id },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newMatches = subscriptionData.data.tournament_brackets;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        tournament_brackets: [...newMatches],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        tournament_brackets: [...newMatches],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                />
                                <PendingMatches theme={theme} tournament={tournament} tables={tables} />
                            </Sider>
                        )}

                        <TournamentStageDropdown
                            isPublic={isPublic}
                            stages={stages}
                            selectedStage={selectedStage}
                            urlPath={urlPath}
                            tables={tables}
                            tournament={tournament}
                            onSelected={(stage) => {
                                console.log('stage', stage);
                                onSelectedStage(stage);
                            }}
                            style={{ top: 20, right: 320, position: 'absolute', zIndex: 1 }}
                            {...props}
                        />

                        <Content style={{ position: 'relative', backgroundColor: theme.bracket.backgroundColor }}>
                            <RoundRobinMatches
                                isPublic={isPublic}
                                groups={groups}
                                matches={matches}
                                stage={selectedStage}
                                tables={tables}
                                bracketHeight={bracketHeight}
                                onShowScoreModal={showScoreModal}
                                {...props}
                            />
                        </Content>
                        {authState && tournament.status !== 'COMPLETED' && (
                            <React.Fragment>
                                <RoundRobinViewerMatchOverlay
                                    {...props}
                                    isFullScreen={isFullScreen}
                                    // players={players}
                                    groups={groups}
                                    stage={selectedStage}
                                    stages={stages}
                                    originalMatches={originalMatches}
                                    matches={matches}
                                    currentRound={currentRound}
                                    currentMatch={currentMatch}
                                    modalScoreVisible={modalScoreVisible}
                                    onModalScoreVisible={setModalScoreVisible}
                                    onTournamentComplete={(winner) => {
                                        completeTournament(winner);
                                    }}
                                    onAdvancePlayers={(players, nextStage, nextStageMatches) => {
                                        showConfirmAdvancePlayers(players, nextStage, nextStageMatches);
                                        // setPlayersToAdvance([...playersToAdvance, player]);
                                    }}
                                />
                                <SetRoundTimeModal
                                    tournament={tournament}
                                    currentRound={currentRound}
                                    matches={matches}
                                    tables={tables}
                                    onModalOk={handleRoundTimeModalOk}
                                    onModalCancel={handleRoundTimeModalCancel}
                                    modalVisible={roundTimeModalVisible}
                                    {...props}
                                />
                            </React.Fragment>
                        )}

                        {currentMatch && (
                            <MatchInfoModal
                                {...props}
                                tournament={tournament}
                                tournamentID={tournament.id}
                                currentMatch={currentMatch}
                                modalVisible={matchModalVisible}
                                onMatchModalOk={handleMatchModalOk}
                                onMatchModalCancel={handleMatchModalCancel}
                            />
                        )}
                        {sidePanelOpen === true && tablesPanel !== false && tournament.status !== 'COMPLETED' && canAccess(authState.user) && (
                            <Sider
                                theme={theme.name}
                                width={300}
                                style={{
                                    backgroundColor: theme.bracket.panels.backgroundColor,
                                    // borderLeft: '1px solid #eee',
                                    boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                }}
                            >
                                <SidePanel
                                    theme={theme}
                                    tournament={tournament}
                                    matches={matches}
                                    onSidePanelOpen={() => {
                                        setSidePanelOpen(false);
                                    }}
                                    onShowScoreModal={showScoreModal}
                                    scrollToTable={() => {
                                        // const nextHeights = elementsRef.current.map(
                                        // 	(ref) => ref.current.getBoundingClientRect().height
                                        // );
                                        // console.log(nextHeights);
                                        // scrollToComponent(this[`winnerRound1_ref`], {
                                        // 	offset: 1000,
                                        // 	align: 'top',
                                        // 	duration: 1500
                                        // });
                                    }}
                                    bracketHeight={isFullScreen === true ? windowHeight : bracketHeight}
                                />
                            </Sider>
                        )}
                    </Layout>
                );
            }}
        </Query>
    );
}

export default RoundRobinViewer;
