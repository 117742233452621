import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Row, Col, Typography } from 'antd';
import LMContactSettingsForm from './LMContactSettingsForm';
const { Text, Title } = Typography;

function LMContactSettings(props) {
	return (
		<React.Fragment>
			<Title level={4}>{props.title}</Title>
			<LMContactSettingsForm {...props} />
		</React.Fragment>
	);
}

export default withRouter(LMContactSettings);
