import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography } from 'antd';
import * as Yup from 'yup';
import { Mutation } from '@apollo/client/react/components';
import slugify from 'slugify';
import { CountryRegionData } from 'react-country-region-selector';
import { GET_ORGANIZATION_QUERY } from '../data/queries';
import { UPDATE_ORGANIZATION_MUTATION } from '../data/mutations';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function LMOrganizationSettingsForm(props) {
    const { organization, league, history } = props;
    const [loading, setLoading] = useState(false);

    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    const UpdateSchema = Yup.object().shape({
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        region: Yup.string().required('Region is required'),
        postal_code: Yup.string().required('Postal Code is required'),
        email_address: Yup.string().email('Email is invalid').required('Email address is required'),
        website: Yup.string().url('Website must be a valid url e.g. http://www.example.com)').nullable(),
        phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
        fax_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
    });

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    const handleSubmit = (values, actions, updateOrganization) => {
        console.log(values);
        const { name, address1, address2, city, county, country, region, postal_code, phone_number, fax_number, email_address, website } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        updateOrganization({
            variables: {
                id: organization.id,
                changes: {
                    name,
                    slug,
                    address1,
                    address2,
                    city,
                    county,
                    country,
                    region,
                    postal_code,
                    phone_number,
                    fax_number,
                    email_address,
                    website,
                },
            },
            refetchQueries: () => [
                {
                    query: GET_ORGANIZATION_QUERY,
                    variables: { slug: organization.slug },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                },
            ],
        });
    };

    return (
        <Mutation
            mutation={UPDATE_ORGANIZATION_MUTATION}
            onCompleted={(res) => {
                console.log(res);
                const organization = res && res.update_organizations && res.update_organizations.returning && res.update_organizations.returning[0];
                setLoading(false);
                message.success('Changes saved', organization);
                // const result = organization.insert_leagues.returning[0];
                // props.history.push(`/league-manager/${result.slug}/dashboard`);
                // const { updateLeague } = data;
                // history.push(`/league-manager/${organization.slug}/settings/contact`);
            }}
            awaitRefetchQueries
        >
            {(updateOrganization, { error }) => {
                if (error) {
                    alert('Something went wrong');
                }
                return (
                    <Formik
                        initialValues={
                            organization
                                ? {
                                      ...organization,
                                  }
                                : {
                                      name: '',
                                      address1: '',
                                      address2: '',
                                      city: '',
                                      county: '',
                                      country: undefined,
                                      region: undefined,
                                      postal_code: '',
                                      phone_number: '',
                                      fax_number: '',
                                      email_address: '',
                                      website: '',
                                  }
                        }
                        validationSchema={UpdateSchema}
                        onSubmit={(values, actions) => handleSubmit(values, actions, updateOrganization)}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal" style={{ maxWidth: 600 }}>
                                <FormItem
                                    name="name"
                                    label="Name"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="name" placeholder="Name" size="large" />
                                    <ErrorMessage name="name" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="address1"
                                    label="Address 1"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.address1 || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="address1" placeholder="Address 1" size="large" />
                                    <ErrorMessage name="address1" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="address2"
                                    label="Address 2"
                                    className={'ant-form-item-control' + (errors.address2 || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="address2" placeholder="Address 2" size="large" />
                                    <ErrorMessage name="address2" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="city"
                                    label="City"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.city || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="city" placeholder="City" size="large" />
                                    <ErrorMessage name="city" component="div" className="ant-form-explain" />
                                </FormItem>
                                {/* <FormItem
									label="County"
									className={
										'ant-form-item-control' + (errors.county || errors.general ? ' has-error' : '')
									}
								>
									<Input name="county" placeholder="County" size="large" />
									<ErrorMessage name="county" component="div" className="ant-form-explain" />
								</FormItem> */}
                                <FormItem
                                    name="country"
                                    label="Country"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.country || errors.general ? ' has-error' : '')}
                                >
                                    {/* <Input name="country" placeholder="Country" size="large" /> */}
                                    <Select
                                        showSearch
                                        name="country"
                                        placeholder="Please select a country"
                                        optionFilterProp="children"
                                        size="large"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {CountryRegionData.map((option, index) => {
                                            return (
                                                <Option value={option[1]} key={index}>
                                                    {option[0]}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    <ErrorMessage name="country" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="region"
                                    label="Region"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.region || errors.general ? ' has-error' : '')}
                                >
                                    {/* <Input name="region" placeholder="Select State/Province/Region" size="large" /> */}
                                    <Select
                                        showSearch
                                        name="region"
                                        placeholder="Please select a state/province/region"
                                        size="large"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {getRegions(values.country).map((option, index) => (
                                            <Option value={option} key={index}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                    <ErrorMessage name="region" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="postal_code"
                                    label="Postal Code"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.postal_code || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="postal_code" placeholder="Postal Code" size="large" />
                                    <ErrorMessage name="postal_code" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="phone_number"
                                    label="Phone Number"
                                    className={'ant-form-item-control' + (errors.phone_number || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="phone_number" placeholder="Phone Number" size="large" />
                                    <ErrorMessage name="phone_number" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="fax_number"
                                    label="Fax Number"
                                    className={'ant-form-item-control' + (errors.fax_number || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="fax_number" placeholder="Fax Number" size="large" />
                                    <ErrorMessage name="fax_number" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="email_address"
                                    label="Email Address"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.email_address || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="email_address" placeholder="Email Address" size="large" />
                                    <ErrorMessage name="email_address" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem
                                    name="website"
                                    label="Website"
                                    className={'ant-form-item-control' + (errors.website || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="website" placeholder="Website" size="large" />
                                    <ErrorMessage name="website" component="div" className="ant-form-explain" />
                                </FormItem>

                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={loading}
                                >
                                    Update
                                </SubmitButton>
                                <div>
                                    <Text style={styles.error}>{errors.general}</Text>
                                </div>
                            </Form>
                        )}
                    />
                );
            }}
        </Mutation>
    );
}

export default withRouter(LMOrganizationSettingsForm);
