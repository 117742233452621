import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

export default function SearchFilterBar(props) {
	function handleSubmit(values, actions) {
		actions.setSubmitting(false);
		actions.resetForm();
		console.log('Received values of form: ', values);
	}

	return (
		<Form layout="inline" onSubmit={handleSubmit}>
			<Form.Item>
				<span style={{ display: 'flex', alignItems: 'center' }}>
					{/* <SearchOutlined size="large" style={{ marginRight: 10 }} /> */}
					<Search
						placeholder="Search venues"
						size="default"
						enterButton
						onSearch={(value) => console.log(value)}
					/>
				</span>
			</Form.Item>

			<Form.Item label="Type">
				<Select name="type" defaultValue="All" size="default" style={{ width: 120 }}>
					<Option value="All">All</Option>
					<Option value="EIGHT_BALL">8-Ball</Option>
					<Option value="NINE_BALL">9-Ball</Option>
					<Option value="TEN_BALL">10-Ball</Option>
				</Select>
			</Form.Item>
			<Form.Item label="Showing">
				<Select name="status" defaultValue="All" size="default" style={{ width: 120 }}>
					<Option value="All">All</Option>
					<Option value="IN_PROGRESS">In Progress</Option>
					<Option value="UPCOMING">Upcoming</Option>
					<Option value="COMPLETED">Completed</Option>
					<Option value="CANCELED">Canceled</Option>
					<Option value="RESCHEDULED">Rescheduled</Option>
					<Option value="FORFEITED">Forfeited</Option>
				</Select>
			</Form.Item>
		</Form>
	);
}
