import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Button, Avatar } from 'antd';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import leagueManagerIcon from '../../assets/img/league-manager-icon-large.svg';
import Fade from 'react-reveal/Fade';
import { withRouter } from 'react-router-dom';
import * as routes from '../../config/routes';
import screenshot from '../../assets/img/home/league-manager-screenshot.png';
import headerBg from '../../assets/img/home/league-manager-bg.jpg';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#0D1E35',
        // background: 'linear-gradient(#20242d, #242733)',
        backgroundImage: `url(${headerBg})`,
        backgroundPosition: 'bottom left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundAttachment: 'fixed',
        borderRadius: 0,
        paddingTop: 50,
        paddingLeft: 10,
        paddingRight: 10,
    },
    avatar: {
        background: 'linear-gradient(180deg, #ff7600, #fea333)',
        // background: '#27282f',
    },
    col: {
        marginTop: 5,
        marginBottom: 5,
    },
};

function LMMarketing(props) {
    const { width, theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            <div
                style={{
                    // background: 'linear-gradient(180deg, #081629, #040e1b)',
                    backgroundColor: '#0D1E35',
                    // marginLeft: 60,
                    // position: 'relative',
                    // zIndex: 1,
                    // flexGrow: 1
                }}
            >
                <div style={styles.headerImg}>
                    <Fade>
                        <img src={leagueManagerIcon} width={150} alt="" style={{ marginBottom: 20 }} />
                    </Fade>
                    <Fade up distance="50px" delay={200}>
                        <Title level={2} align="center" style={{ color: '#fff', fontWeight: 500, paddingLeft: 20, paddingRight: 20 }}>
                            Create and manage leagues like a seasoned operator
                        </Title>
                    </Fade>
                    <Fade up distance="50px" delay={100}>
                        <Text
                            align="center"
                            style={{
                                color: '#d6d6d6',
                                fontWeight: 400,
                                fontSize: 20,
                                maxWidth: 800,
                                display: 'inline-block',
                                marginBottom: 25,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            Save time and money running your leagues with our easy-to-use league management software right from your mobile device or desktop.
                        </Text>
                    </Fade>
                    <Fade up distance="50px" delay={100}>
                        <Link to={routes.DIGITALPOOL.REGISTER}>
                            <Button
                                type="default"
                                size="large"
                                style={{
                                    textTransform: 'uppercase',
                                    width: breakpoint === 'mobile' ? '80%' : 'auto',
                                }}
                                ghost
                            >
                                Get started free
                            </Button>
                        </Link>
                    </Fade>
                    <img src={screenshot} alt="UI Screenshot" width={breakpoint === 'mobile' ? '100%' : '80%'} style={{ marginTop: 20 }} />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: 30,
                    paddingBottom: 20,
                    // background: 'linear-gradient(#fff, #eff3f5)',
                    // borderBottom: '1px solid rgb(221, 221, 221)',
                    background: 'linear-gradient(45deg, #fed233, #ff7600)',
                }}
            >
                <Title level={3} align="center" style={{ fontWeight: 500, color: '#fff' }}>
                    Everything you need to run a world-class pool league (coming soon)
                </Title>
            </div>
            <Row gutter={16} type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            marginTop: 60,
                            marginLeft: 24,
                            marginRight: 24,
                            marginBottom: 60,
                        }}
                    >
                        <Row gutter={100} type="flex" justify="center">
                            <Col xs={24} sm={8} style={styles.col}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-people" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Player & Team Management
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-play" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Real-time Scoring
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-disc" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Many Supported Formats
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-calendar" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Dynamic Scheduling
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-stats" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Standings & Statistics
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-chatboxes" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Social Networking Features
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-notifications" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Match Notifications
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-analytics" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Analytics Dashboard
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-help-buoy" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Help & Support
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis molestie nisl. Aliquam nunc nunc, eleifend eget
                                            hendrerit ac, congue venenatis nisl.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                            <Col xs={24} sm={12} align="center">
                                <Title level={3} style={{ marginTop: 20, fontWeight: 600, color: theme.bodyTitle.color }}>
                                    Digital Pool <span style={{ color: '#feb138' }}>Network</span>
                                </Title>
                                <Text style={{ marginTop: 30, marginBottom: 40, color: theme.bodyText.color }}>
                                    Get instant access to the Digital Pool Network and promote your league to a worldwide community of pool players, operators,
                                    businesses and venues. You will also benefit from any new product releases and feature improvements that we make. Sign up
                                    for free and start building your first league!
                                </Text>
                                <div style={{ marginTop: 20 }}>
                                    <Link to={routes.DIGITALPOOL.REGISTER}>
                                        <Button
                                            type="default"
                                            size="large"
                                            style={{
                                                backgroundColor: '#000',
                                                color: '#fff',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            Get Started Free
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default withRouter(LMMarketing);
