import React, { useState, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import { displayEnumType } from '../../../utils';
import StatLongestWinningStreak from './StatLongestWinningStreak';
import StatBreakAndRunPackages from './StatBreakAndRunPackages';
import StatConsecutiveBreakAndRunStreak from './StatConsecutiveBreakAndRunStreak';
import CircleStateFlag from '../../../components/CircleStateFlag';
import Flag from '../../../components/Flag';
import { matchStats as stats } from '../../../utils';
import _ from 'lodash';
import numeral from 'numeral';

const { Title, Text } = Typography;

export default function StatsTable(props) {
    const { matchData } = props;

    const matchStats = matchData.match_stats;

    const playerStats = matchStats.filter((stat) => {
        return stat.metric.indexOf('player_') !== -1;
    });

    const opponentStats = matchStats.filter((stat) => {
        return stat.metric.indexOf('opponent_') !== -1;
    });

    const uniquePlayerStats = _.uniqBy(playerStats, 'metric');
    const uniqueOpponentStats = _.uniqBy(opponentStats, 'metric');

    // console.log(uniquePlayerStats);
    // console.log(uniqueOpponentStats);

    function calculateTPA(ballsPocketed, errors) {
        return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
    }

    function getPlayerTPA() {
        const playerBallsPocketed = playerStats.filter((stat) => stat.metric === 'player_balls_pocketed')[0];
        const playerBallsPocketedValue = playerBallsPocketed ? playerBallsPocketed.value : 0;
        const playerErrors = playerStats.filter((stat) => stat.metric === 'player_unforced_errors')[0];
        const playerErrorValue = playerErrors ? playerErrors.value : 0;
        const tpaScore = calculateTPA(playerBallsPocketedValue, playerErrorValue);
        return tpaScore;
    }

    function getOpponentTPA() {
        const opponentBallsPocketed = opponentStats.filter((stat) => stat.metric === 'opponent_balls_pocketed')[0];
        const opponentBallsPocketedValue = opponentBallsPocketed ? opponentBallsPocketed.value : 0;
        const opponentErrors = opponentStats.filter((stat) => stat.metric === 'opponent_unforced_errors')[0];
        const opponentErrorValue = opponentErrors ? opponentErrors.value : 0;
        const tpaScore = calculateTPA(opponentBallsPocketedValue, opponentErrorValue);
        return tpaScore;
    }

    //const stats = [
    //	'Score',
    //	'Points',
    //	'Balls pocketed',
    //	'Balls made on the break',
    //	'Balls missed',
    //	'Unforced errors',
    //	'Fouls',
    //	'Illegal breaks',
    //	'Dry breaks',
    //	'Runouts',
    //	'Break and runs',
    //	'Golden breaks',
    //	'Racks won on break',
    //	'Racks won against break'
    //];

    return (
        <Row gutter={24} justify="center" type="flex">
            <Col xs={12} align="center">
                <div style={{ marginBottom: 10 }}>
                    {matchData.player_country && (
                        <React.Fragment>
                            {matchData.player_state ? (
                                <CircleStateFlag state={matchData.player_state} size="xlarge" />
                            ) : (
                                <Flag
                                    name={matchData.player_country || 'Unknown'}
                                    format="png"
                                    pngSize={48}
                                    shiny={false}
                                    alt="Flag"
                                    className="flag"
                                    basePath="/images/flags"
                                />
                            )}
                        </React.Fragment>
                    )}

                    <div style={{ fontSize: 25 }}>{matchData.player_name}</div>
                    {matchStats && (
                        <div style={{ fontSize: 18 }}>
                            <Text>TPA: {getPlayerTPA()}</Text>
                        </div>
                    )}
                </div>
            </Col>

            <Col xs={12} align="center">
                <div style={{ marginBottom: 10 }}>
                    {matchData.opponent_country && (
                        <React.Fragment>
                            {matchData.opponent_state ? (
                                <CircleStateFlag state={matchData.opponent_state} size="xlarge" />
                            ) : (
                                <Flag
                                    name={matchData.opponent_country || 'Unknown'}
                                    format="png"
                                    pngSize={48}
                                    shiny={false}
                                    alt="Flag"
                                    className="flag"
                                    basePath="/images/flags"
                                />
                            )}
                        </React.Fragment>
                    )}

                    <div style={{ fontSize: 25 }}>{matchData.opponent_name}</div>
                    {matchStats && (
                        <div>
                            <div style={{ fontSize: 18 }}>
                                <Text>TPA: {getOpponentTPA()}</Text>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
            <Col xs={24}>
                <div>
                    <table width="100%" className="info-table-stats">
                        <tbody>
                            {playerStats &&
                                stats.map((stat, index) => {
                                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                    const playerStat = `player_${statUnderscored}`;
                                    const opponentStat = `opponent_${statUnderscored}`;
                                    const playerValue = uniquePlayerStats.filter((item) => {
                                        return item.metric === playerStat;
                                    })[0];
                                    const opponentValue = uniqueOpponentStats.filter((item) => {
                                        return item.metric === opponentStat;
                                    })[0];
                                    const totalValue = playerValue && opponentValue && playerValue.value + opponentValue.value;
                                    const playerValuePct = playerValue && ((playerValue.value / totalValue) * 100).toFixed(0);
                                    const opponentValuePct = opponentValue && ((opponentValue.value / totalValue) * 100).toFixed(0);

                                    return (
                                        <tr key={index}>
                                            <td align="right" width="60px">
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        textAlign: 'right',
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                    }}
                                                >
                                                    <React.Fragment>{isNaN(playerValuePct) === false ? `${playerValuePct}%` : '-'}</React.Fragment>
                                                </div>
                                            </td>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    <b>{playerValue ? playerValue.value : 0}</b>
                                                </div>
                                            </td>
                                            <td align="center" width="350px">
                                                {displayEnumType(statUnderscored)}
                                            </td>
                                            <td align="left">
                                                <div
                                                    style={{
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    <b>{opponentValue ? opponentValue.value : 0}</b>
                                                </div>
                                            </td>
                                            <td align="left" width="60px">
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        textAlign: 'left',
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                    }}
                                                >
                                                    <React.Fragment>{isNaN(opponentValuePct) === false ? `${opponentValuePct}%` : '-'}</React.Fragment>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            <StatLongestWinningStreak matchId={matchData.id} {...props} />
                            <StatConsecutiveBreakAndRunStreak matchId={matchData.id} {...props} />
                            <StatBreakAndRunPackages matchId={matchData.id} {...props} />
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
    );
}
