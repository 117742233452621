import React, { useRef } from 'react';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import * as Yup from 'yup';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import { GET_LIVE_CHAT_STREAM_SUBSCRIPTION } from '../data/subscriptions';
import { SEND_LIVE_CHAT_MESSAGE } from '../data/mutations';
import { GET_USER } from '../data/queries';

function LiveChat(props) {
    const { channelId, client, userId, liveId } = props;

    const formRef = useRef();

    const initialValues = {
        message: '',
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
    });

    async function sendMessage(message) {
        await client.mutate({
            mutation: SEND_LIVE_CHAT_MESSAGE,
            variables: {
                message: message,
                user_id: userId,
                live_id: liveId,
            },
        });
    }

    return (
        <React.Fragment>
            <Subscription
                subscription={GET_LIVE_CHAT_STREAM_SUBSCRIPTION}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ live_id: channelId }}
                onSubscriptionData={async (data, error, loading) => {
                    console.log('l', loading);
                    let messageObj =
                        data.subscriptionData.data &&
                        data.subscriptionData.data.live_chat &&
                        data.subscriptionData.data.live_chat.length &&
                        data.subscriptionData.data.live_chat[0];

                    let e = document.createElement('div');
                    e.innerHTML = `${messageObj.user.first_name}: ${messageObj.message}`;

                    document.getElementById('chat').append(e);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div>Error: {error.message}</div>;

                    let message = data && data.live_chat && data.live_chat.length && data.live_chat[0].message;

                    console.log('ddd', data);

                    return null;
                }}
            </Subscription>
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    // console.log(data);
                    sendMessage(data.message);
                }}
                validationSchema={schema}
                enableReinitialize
                initialValues={initialValues}
                render={({ errors, touched, values }) => (
                    <Form layout="vertical" style={{ maxWidth: 600 }}>
                        <Input name="message" placeholder="Message" size="large" />
                        <Button
                            type="primary"
                            onClick={() => {
                                formRef.current.executeSubmit();
                            }}
                            key="submit"
                        >
                            Send
                        </Button>
                    </Form>
                )}
            />
            <div id="chat" />
        </React.Fragment>
    );
}

export default LiveChat;
