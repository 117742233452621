import { gql } from '@apollo/client';

export const GET_TOURNAMENT_BY_ID = gql`
    query tournaments($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            name
            slug
            status
            flyer
            game_type
            consolidation_finals
            organizer_id
            director_id
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
        }
    }
`;

export const GET_TOUR_LIST = gql`
    query tours {
        tours(where: { is_public: { _eq: true } }, order_by: { updated_at: desc }) {
            name
            id
            slug
            location
            description
            created_at
            updated_at
        }
    }
`;

export const GET_TOUR_STANDINGS_BY_SLUG = gql`
    query tours($slug: String!) {
        tours(where: { slug: { _eq: $slug } }) {
            id
            name
            tour_tournaments(order_by: { tournament: { start_date_time: desc } }) {
                tournament {
                    id
                    name
                    slug
                    start_date_time
                    end_date_time
                    tournament_players(where: { name: { _nilike: "%BYE%" } }, order_by: { place: asc }) {
                        id
                        name
                        skill_level
                        country
                        place
                        phone_number
                        player_id
                        user_id
                        player {
                            id
                            name
                            name_slug
                            country
                            is_active_wpba_member
                        }
                    }
                    tournament_points(order_by: { id: asc }) {
                        id
                        place
                        points
                    }
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_EFFECTIVE_RATINGS = gql`
    query vw_tournament_effective_ratings($player_name: String!) {
        vw_tournament_effective_ratings(where: { player_name: { _eq: $player_name } }) {
            to_par
            name
            player_name
            effective_rating
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS = gql`
    query vw_tournament_player_effective_ratings($tournament_id: Int!) {
        vw_tournament_player_effective_ratings(where: { tournament_id: { _eq: $tournament_id } }) {
            to_par
            name
            id
            country
            skill_level
            fargo_rating
            effective_rating
            tournament_id
        }
    }
`;
