import React, { useState } from 'react';
import { message, Modal, Tooltip, Tag, Button, Icon } from 'antd';
import ShotClock from '../../../components/ShotClock';

export default function ShotClockOverlay(props) {
    const { matchData, modalVisible, onOk, onCancel, theme } = props;

    return (
        <Modal
            title="Shot clock"
            visible={modalVisible}
            onOk={onOk}
            onCancel={onCancel}
            centered
            transitionName="fade"
            okText="Copy"
            // bodyStyle={{ padding: 0 }}
            maskTransitionName="none"
            destroyOnClose={true}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Close
                </Button>,
            ]}
            width="100%"
        >
            <ShotClock
                showDays={false}
                showHours={false}
                showMinutes={false}
                showSeconds={true}
                showMilliseconds={false}
                direction="backward"
                type="circular"
                textColor="#fff"
                initialTime={30000}
                matchData={matchData}
            />
        </Modal>
    );
}
