import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Query } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import { rowSelectAtom, removeButtonDisabledAtom, removeButtonLoadingAtom, totalPlayerCountAtom } from './data/atoms';
import { GET_GHOST_EVENT_PLAYERS_QUERY } from './data/queries';
import { UPDATE_GHOST_GAMES_PLAYER_INVITED } from './data/mutations';
import { Table, Checkbox, Icon, Spin } from 'antd';
import CircleFlag from '../../components/CircleFlag';
import { isoCountries } from '../../utils/isoCountryCodes';

function PlayerTable(props) {
    const { ghostEventId, client } = props;
    const setSelectedRows = useSetRecoilState(rowSelectAtom);
    const setRemoveButtonDisabled = useSetRecoilState(removeButtonDisabledAtom);
    const setTotalPlayerCount = useSetRecoilState(totalPlayerCountAtom);
    // const setRemoveButtonLoading = useSetRecoilState(removeButtonLoadingAtom);
    const [checkBoxes, setCheckBoxes] = useState({});

    function paidCheckboxChange(id, e) {
        switch (e.target.checked) {
            case true:
                client
                    .mutate({
                        mutation: UPDATE_GHOST_GAMES_PLAYER_INVITED,
                        variables: {
                            id: id,
                            paid: true,
                        },
                    })
                    .then((res) => {
                        setCheckBoxes({ ...checkBoxes, [id]: { checked: true } });
                    });
                break;

            case false:
                client
                    .mutate({
                        mutation: UPDATE_GHOST_GAMES_PLAYER_INVITED,
                        variables: {
                            id: id,
                            paid: false,
                        },
                    })
                    .then((res) => {
                        setCheckBoxes({ ...checkBoxes, [id]: { checked: false } });
                    });
                break;
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country && <CircleFlag country={record.country} left={0} />}
                        <span>{text}</span>
                    </div>
                );
            },
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
        },
        {
            title: 'Invited',
            dataIndex: 'invited',
            key: 'invited',
        },
        {
            title: 'Accepted Invitation',
            dataIndex: 'accepted_invitation',
            key: 'accepted_invitation',
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
        },
    ];

    const rowSelection = {
        selectedKeys: 0,
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);

            if (selectedRows.length) setRemoveButtonDisabled(false);
            else setRemoveButtonDisabled(true);
        },
        onSelectAll: (selected, selectedRows) => {
            setSelectedRows(selectedRows);

            if (selectedRows.length) setRemoveButtonDisabled(false);
            else setRemoveButtonDisabled(true);
        },
        getCheckboxProps: (record) => {},
    };

    return (
        <Query
            query={GET_GHOST_EVENT_PLAYERS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ ghost_games_id: ghostEventId }}
            onCompleted={(data) => {}}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return error;

                var dataSource =
                    data &&
                    data.ghost_games_players &&
                    data.ghost_games_players.map((val, key) => {
                        return {
                            key: key,
                            id: val.id,
                            ghost_games_id: val.ghost_games_id,
                            name: val.user
                                ? val.user.first_name || val.user.last_name
                                    ? `${val.user.first_name} ${val.user.last_name}`
                                    : val.user.display_name
                                : '',
                            email: val.user ? val.user.email : val.email_address,
                            invited: val.is_invited ? 'Yes' : 'No',
                            accepted_invitation: val.accepted_invitation ? 'Yes' : 'No',
                            paid: (
                                <Checkbox
                                    checked={checkBoxes.hasOwnProperty(val.id) ? checkBoxes[val.id].checked : val.paid ? true : false}
                                    onChange={(e) => paidCheckboxChange(val.id, e)}
                                />
                            ),
                            country: val.user ? val.user.country : '',
                        };
                    });

                setTotalPlayerCount(dataSource.length);

                return <Table pagination={false} columns={columns} dataSource={dataSource} rowSelection={rowSelection} />;
            }}
        </Query>
    );
}

export default PlayerTable;
