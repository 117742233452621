import { auth, db, providers, helpers } from './firebase';

const getAuthStatus = function () {
    return new Promise((resolve, reject) => {
        return auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log('user from firebase', user);
                resolve(user);
            } else {
                reject('It broke');
            }
        });
    });
};

export { getAuthStatus };
