import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import CircleFlag from '../../../components/CircleFlag';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import _ from 'lodash';

const { Title, Text } = Typography;

export default function RackTable(props) {
    const { matchData } = props;

    let matchGames = _.uniqBy(
        props.matchGames
            .sort((a, b) => {
                return a.game_number - b.game_number;
            })
            .filter((game) => {
                return game.game_number !== 0;
            }),
        'game_number'
    );

    const currentGame = matchGames && matchGames[matchGames.length - 1];

    console.log('current game', currentGame);
    console.log('games', props.matchGames);
    // if (currentGame && currentGame.game_number === 0) {
    //     matchGames = matchGames.slice(1, matchGames.length - 1);
    //     // matchGames.shift();
    //     console.log('shifted', matchGames);
    // }

    // if (matchGames && matchGames[0]) {
    //     if (matchGames[0].player_score === 0 && matchGames[0].opponent_score === 0) {
    //         matchGames.shift();
    //         console.log('shifted', matchGames);
    //     }
    // }

    const player1Data = matchGames;
    const player2Data = matchGames;

    console.log(matchGames);

    const totalGames = matchData.challenger1_race_to + matchData.challenger2_race_to;
    const playerIsWinning = matchData.challenger1_score > matchData.challenger2_score;
    const opponentIsWinning = matchData.challenger2_score > matchData.challenger1_score;

    return (
        <React.Fragment>
            <div
                style={{
                    // marginTop: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    border: '1px solid rgba(255,255,255,0.3)',
                    borderRadius: 10,
                    flexDirection: 'column',
                }}
            >
                <div style={{ backgroundColor: playerIsWinning === true ? 'rgba(255,255,255,0.05)' : null }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: 45 }}>
                        <div style={{ width: 250, textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                            {matchData && matchData.challenger1_country && (
                                <CircleFlag country={getCountryName(matchData.challenger1_country) || 'Unknown'} size="medium" />
                            )}
                            <Text
                                style={{
                                    fontSize: 20,
                                    color: '#fff',
                                    marginRight: 10,
                                    whiteSpace: 'nowrap',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    textAlign: 'left',
                                }}
                            >
                                {matchData && matchData.challenger1_name}{' '}
                                {matchData &&
                                    matchData.challenger1_name &&
                                    matchData.challenger1_name !== 'BYE' &&
                                    matchData.challenger1_skill_level &&
                                    `(${matchData.challenger1_skill_level})`}
                            </Text>
                        </div>
                        {Array.from({ length: totalGames }, (v, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                            width: 45,
                                            height: 45,
                                        }}
                                    >
                                        {matchGames[index] && matchGames[index].player_is_winner ? (
                                            <React.Fragment>
                                                {matchGames[index].player_break_run ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                                            width: 45,
                                                            height: 45,
                                                            background: '#ffe4690f',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: 30,
                                                                height: 30,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                background: 'radial-gradient(49% 100%, #FFE469 50%, #FFC438 100%)',
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#000' }}>BR</Text>
                                                        </div>
                                                    </div>
                                                ) : matchGames[index].player_runout ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                                            width: 45,
                                                            height: 45,
                                                            background: '#ffffff0f',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: 30,
                                                                height: 30,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                background: 'radial-gradient(49% 100%, rgb(235 235 235) 50%, rgb(152 148 145) 100%)',
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#000' }}>RO</Text>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                                            width: 45,
                                                            height: 45,
                                                            background: '#ffe4690f',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                // opacity: 0.5,
                                                                background: 'radial-gradient(49% 100%, #FFE469 50%, #FFC438 100%)',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <div style={{ borderLeft: '1px solid rgba(255,255,255,0.1)', width: 45, height: 45 }} />
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}

                        <div
                            style={{
                                borderLeft: '1px solid rgba(255,255,255,0.1)',
                                width: 45,
                                height: 45,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Text style={{ fontSize: 20, color: '#fff', fontWeight: playerIsWinning ? 700 : 400 }}>{matchData.challenger1_score}</Text>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: opponentIsWinning ? 'rgba(255,255,255,0.05)' : null }}>
                    <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid rgba(255,255,255,0.1)', height: 45 }}>
                        <div style={{ width: 250, textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                            {matchData && matchData.challenger2_country && (
                                <CircleFlag country={getCountryName(matchData.challenger2_country) || 'Unknown'} size="medium" />
                            )}
                            <Text
                                style={{
                                    fontSize: 20,
                                    color: '#fff',
                                    marginRight: 10,
                                    whiteSpace: 'nowrap',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    textAlign: 'left',
                                }}
                            >
                                {matchData && matchData.challenger2_name}{' '}
                                {matchData &&
                                    matchData.challenger2_name &&
                                    matchData.challenger2_name !== 'BYE' &&
                                    matchData.challenger2_skill_level &&
                                    `(${matchData.challenger2_skill_level})`}
                            </Text>
                        </div>
                        {Array.from({ length: totalGames }, (v, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                            width: 45,
                                            height: 45,
                                        }}
                                    >
                                        {matchGames[index] && matchGames[index].opponent_is_winner ? (
                                            <React.Fragment>
                                                {matchGames[index].opponent_break_run ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                                            width: 45,
                                                            height: 45,
                                                            background: '#ffe4690f',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: 30,
                                                                height: 30,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                background: 'radial-gradient(49% 100%, #FFE469 50%, #FFC438 100%)',
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#000' }}>BR</Text>
                                                        </div>
                                                    </div>
                                                ) : matchGames[index].opponent_runout ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                                            width: 45,
                                                            height: 45,
                                                            background: '#ffffff0f',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: 30,
                                                                height: 30,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                background: 'radial-gradient(49% 100%, rgb(235 235 235) 50%, rgb(152 148 145) 100%)',
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#000' }}>RO</Text>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                                                            width: 45,
                                                            height: 45,
                                                            background: '#ffe4690f',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                // opacity: 0.5,
                                                                background: 'radial-gradient(49% 100%, #FFE469 50%, #FFC438 100%)',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <div style={{ borderLeft: '1px solid rgba(255,255,255,0.1)', width: 45, height: 45 }} />
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}

                        <div
                            style={{
                                borderLeft: '1px solid rgba(255,255,255,0.1)',
                                width: 45,
                                height: 45,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Text style={{ fontSize: 20, color: '#fff', fontWeight: opponentIsWinning ? 700 : 400 }}>{matchData.challenger2_score}</Text>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', height: 45 }}>
                <div style={{ width: 250, textAlign: 'right' }} />
                {Array.from({ length: totalGames }, (v, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                width: 45,
                                textAlign: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 18,
                                    color: '#fff',
                                }}
                            >
                                {index + 1}
                            </Text>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
}
