import React, { useState, useEffect } from 'react';
import { Row, Col, List, Avatar, Skeleton, Icon, Result, Button } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { gql } from '@apollo/client';

const GET_USER = gql`
    query users($email: String) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            country
            email
        }
    }
`;

const UPDATE_USER = gql`
    mutation update_users($id: Int!, $changes: users_set_input) {
        update_users(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                first_name
                last_name
                country
                email
            }
        }
    }
`;

function VerifyEmail(props) {
    const { client, match, authState } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    async function updateUser(email) {
        const res = await client.query({
            query: GET_USER,
            variables: {
                email: email,
            },
        });

        const user = res && res.data && res.data.users && res.data.users[0];

        if (user) {
            return await client.mutate({
                mutation: UPDATE_USER,
                variables: {
                    id: user.id,
                    changes: {
                        email_verified: true,
                    },
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                // 		variables: { tournamentId: tournament.id }
                // 	}
                // ]
            });
        }
    }

    if (match.params.email) {
        const email = match.params.email.split('&');
        updateUser(email[0]);
    }

    return (
        <React.Fragment>
            {/* <SectionTitle title={props.title} height={isWidthDown('xs', width) ? 80 : 120} /> */}
            <SectionContent padding="20px 40px">
                <Row>
                    <Col>
                        <Card bodyStyle={{ padding: 0 }}>
                            <Result
                                status="success"
                                title="Success! Your email address has been verified."
                                subTitle={!authState || authState.status === 'out' ? 'Please login to access your account.' : ''}
                                extra={[
                                    <React.Fragment key="button">
                                        {!authState || authState.status === 'out' ? (
                                            <Link to="/login" key="login">
                                                <Button type="primary" size="large">
                                                    Log In
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link to="/dashboard" key="dashboard">
                                                <Button type="primary" size="large">
                                                    Go to Dashboard
                                                </Button>
                                            </Link>
                                        )}
                                    </React.Fragment>,
                                ]}
                            />
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(VerifyEmail);
