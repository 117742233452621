import React, { useState, useEffect } from 'react';
import TBCreateTournamentFormStages from './TBCreateTournamentFormStages';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

export default function TBTournamentStep1Settings(props) {
    const { authState, match, tournament } = props;
    let url;
    if (match.params.slug) {
        url = `/tournament-builder/${match.params.slug}/edit`;
    } else {
        url = `/tournament-builder/new/edit`;
    }

    return (
        <div style={{ padding: 20 }}>
            <TBCreateTournamentFormStages
                {...props}
                onCreated={(tournament) => {
                    console.log('tournament created', tournament);
                    // setTournament(tournament);
                    // setCurrentStep(1);
                    const user = authState && authState.user && authState.user;
                    mixpanel.track('Create tournament', {
                        ...user,
                        displayName: user.displayName,
                        tournamentId: tournament.id,
                        tournamentName: tournament.name,
                        source: 'web',
                    });

                    props.history.push(`${url}/players`);
                }}
                tournament={tournament}
            />
        </div>
    );
}
