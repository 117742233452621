import React, { useState, useEffect } from 'react';
import { Typography, Button, Alert } from 'antd';
import TBFlyerUploadForm from './settings/TBFlyerUploadForm';
const { Title, Text } = Typography;

const styles = {};

export default function TBUploadFlyer(props) {
    const { tournament, onContinue, goBack } = props;

    return (
        <React.Fragment>
            <div>
                <Alert
                    message="Helpful tips for uploading a flyer"
                    description="Adding a flyer is a great way to draw more attention to your tournament and make it look more professional. By default the flyer will appear on the main /tournaments page and in the tournament overview page."
                    type="info"
                    closable
                    showIcon
                />
            </div>
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Title level={4}>Upload Flyer</Title>

                <Text>Flyers must in JPG or PNG format and 2MB or less.</Text>
            </div>
            <TBFlyerUploadForm {...props} />
            <Button type="primary" size="large" onClick={onContinue} style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text style={{ color: '#fff' }}>Save and Review</Text>
                </div>
            </Button>
            <Button
                type="secondary"
                size="large"
                onClick={() => {
                    goBack();
                }}
                style={{ marginTop: 20 }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text>Go back</Text>
                </div>
            </Button>
        </React.Fragment>
    );
}
