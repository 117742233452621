import React, { useState } from 'react';
import { message, Modal, Tooltip, Button, Input, Typography, Tabs } from 'antd';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, EmailIcon, FacebookIcon, TwitterIcon } from 'react-share';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function PostShareModal(props) {
    const { shareUrl, user, post, modalVisible, onOk, onCancel } = props;

    // console.log(window.location);

    // const url = `${window.location.href}/posts/${post.id}`;
    // const url = 'https://www.digitalpool.com';
    const title = post && `${post.message}`;
    const hashtag = '#digitalpool';

    console.log(post);
    console.log(shareUrl);

    return (
        <React.Fragment>
            {/* <Helmet>
                <title>
                    {user.first_name} {user.last_name} | DigitalPool
                </title>
                <meta name="description" content={post.message} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${user.first_name} ${user.last_name} | DigitalPool`} />
                <meta name="twitter:description" content={post.message} />
                <meta name="twitter:image" content={post.image_url || 'https://digitalpool.com/images/fb-image.png'} />
                <meta property="og:title" content={`${user.first_name} ${user.last_name} | DigitalPool`} />
                <meta property="og:description" content={post.message} />
                <meta property="og:image" content={post.image_url || 'https://digitalpool.com/images/fb-image.png'} />
                <meta property="og:url" content={shareUrl} />
                <meta property="og:site_name" content="digitalpool.com" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content="1697957547011275" />
            </Helmet> */}
            <Modal
                title="Share this post"
                visible={modalVisible}
                onOk={onOk}
                onCancel={onCancel}
                centered
                transitionName="fade"
                okText="Copy"
                // bodyStyle={{ padding: 0 }}
                maskTransitionName="none"
                destroyOnClose
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Close
                    </Button>,
                ]}
            >
                <div>
                    <EmailShareButton
                        onShareWindowClose={() => {
                            console.log('close email share');
                        }}
                        url={shareUrl}
                        quote={title}
                        hashtag={hashtag}
                        className="email-share-button"
                    >
                        <EmailIcon size={48} round />
                    </EmailShareButton>
                    <FacebookShareButton
                        onShareWindowClose={() => {
                            console.log('close fb share');
                        }}
                        url={shareUrl}
                        quote={title}
                        hashtag={hashtag}
                        className="facebook-share-button"
                        style={{ marginLeft: 10 }}
                    >
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                    {/* <FacebookMessengerShareButton
                    appId="1697957547011275"
                    url={url}
                    quote={title}
                    hashtag={hashtag}
                    className="facebook-share-button"
                    style={{ marginLeft: 10 }}
                >
                    <FacebookMessengerIcon size={48} round />
                </FacebookMessengerShareButton> */}
                    <TwitterShareButton
                        onShareWindowClose={() => {
                            console.log('close twitter share');
                        }}
                        url={shareUrl}
                        quote={title}
                        title={title}
                        hashtag={hashtag}
                        className="twitter-share-button"
                        style={{ marginLeft: 10 }}
                    >
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>

                    <Input
                        value={shareUrl}
                        size="large"
                        addonAfter={
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    navigator.clipboard.writeText(shareUrl);
                                    message.success(`URL copied to clipboard.`);
                                }}
                            >
                                COPY
                            </Button>
                        }
                        style={{ marginTop: 10 }}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
}
