import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Row, Col, Typography } from 'antd';
import LMOrganizationSettingsForm from './LMOrganizationSettingsForm';
const { Text, Title } = Typography;

function LMOrganizationSettings(props) {
    return (
        <React.Fragment>
            <Title level={4}>{props.title}</Title>
            <LMOrganizationSettingsForm {...props} />
        </React.Fragment>
    );
}

export default withRouter(LMOrganizationSettings);
