import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Query } from '@apollo/client/react/components';
import { Typography, Row, Col, Button } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { GET_GHOST_EVENT_REVIEW_PAGE_QUERY, GET_USER_BY_EMAIL_QUERY, GET_CHECK_INVITE_CODE_QUERY } from '../../data/queries';
import { INSERT_GHOST_EVENT_PLAYER_MUTATION, UPDATE_GHOST_GAMES_MUTATION } from '../../data/mutations';
import { ghostEventId } from '../../data/atoms';
import moment from 'moment';
import { displayEnumType } from '../../../../utils/displayEnumType';
import { functions } from '../../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

const { Title } = Typography;

export default function ReviewPage(props) {
    const { client, ghostEvent, authState } = props;
    const ghostEventIdValue = useRecoilValue(ghostEventId);
    const [invitePlayers, setInvitePlayers] = useState([]);

    console.log('asd', authState);

    function generateInviteKey() {
        var key = [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            0,
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'N',
            'W',
            'X',
            'Y',
            'Z',
        ];
        var length = key.length;

        var appendStr = '';
        for (let i = 0; i < 25; i++) {
            let rand = Math.floor(Math.random() * length);
            appendStr += key[rand];
        }

        return appendStr;
    }

    async function onSubmit(props) {
        const { name, slug, fromName, invitedPlayers } = props;

        var _inviteCodeId = generateInviteKey();
        var _loop = true;
        var _insertData = [];
        var _emailAddresses = [];

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }

        const sendInvite = await httpsCallable(functions, 'ghostEventPlayerInvite');

        while (_loop === true) {
            const res = await client.query({
                query: GET_CHECK_INVITE_CODE_QUERY,
                variables: {
                    invite_link_id: _inviteCodeId,
                },
            });

            if (res && res.data && res.data.ghost_games && res.data.ghost_games && res.data.ghost_games[0]) {
                _inviteCodeId = generateInviteKey();
            } else {
                _loop = false;
            }
        }

        const res = await client.mutate({
            mutation: UPDATE_GHOST_GAMES_MUTATION,
            variables: {
                changes: {
                    invite_link_id: _inviteCodeId,
                },
                id: ghostEventIdValue,
            },
        });

        if (invitedPlayers) {
            for (let i = 0; i < invitedPlayers.length; i++) {
                let player = invitedPlayers[i];

                const res = await client.query({
                    query: GET_USER_BY_EMAIL_QUERY,
                    variables: {
                        email: player.email_address,
                    },
                });

                let isUser = res && res.data && res.data.users && res.data.users[0] ? true : false;

                _insertData.push({
                    ghost_games_id: ghostEventIdValue,
                    user_id: isUser ? res.data.users[0].id : null,
                    email_address: player.email,
                    is_invited: true,
                });

                _emailAddresses.push(player.email_address);
            }

            const result = await sendInvite({
                to_email: _emailAddresses,
                from_name: authState.user.displayName,
                from_ghost_event_name: name,
                from_ghost_event_slug: slug,
                redirect_url: `/ghost-event-manager/${slug}`,
                invite_key: _inviteCodeId,
            });
        }
    }

    return (
        <React.Fragment>
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Title level={4} style={{ marginBottom: 20 }}>
                    Review Ghost Event Configuration
                </Title>
                <Query
                    query={GET_GHOST_EVENT_REVIEW_PAGE_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: ghostEventIdValue }}
                    onCompleted={async (data) => {
                        console.log('dd', data);

                        // setInvitePlayers(data && data.ghost_games && data.ghost_games[0] && data.ghost_games[0].ghost_games_players);
                    }}
                >
                    {({ loading, error, data }) => {
                        // if (loading) return <CircularLoader />;
                        if (loading) return null;

                        console.log('d', data);

                        if (!data && data.ghost_games && data.ghost_games[0]) {
                            return error;
                        }

                        const ghostEvent = data.ghost_games[0];

                        return (
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <Title level={4} style={{ fontSize: 18, marginBottom: 20 }}>
                                        Settings
                                    </Title>
                                    <table width="100%" className="info-table">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{ghostEvent.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td>{ghostEvent.description}</td>
                                            </tr>
                                            <tr>
                                                <td>Start Date</td>
                                                <td>{moment(ghostEvent.start_date).tz('America/Los_Angeles').format('llll z')}</td>
                                            </tr>
                                            <tr>
                                                <td>End Date</td>
                                                <td>{moment(ghostEvent.end_date).tz('America/Los_Angeles').format('llll z')}</td>
                                            </tr>
                                            <tr>
                                                <td>Buy in cost</td>
                                                <td>${ghostEvent.cost}</td>
                                            </tr>
                                            <tr>
                                                <td>Type</td>
                                                <td>{displayEnumType(ghostEvent.type)}</td>
                                            </tr>
                                            <tr>
                                                <td>Max Players</td>
                                                <td>{ghostEvent.max_players}</td>
                                            </tr>
                                            <tr>
                                                <td>Number of rebuys</td>
                                                <td>{ghostEvent.number_of_rebuys}</td>
                                            </tr>
                                            <tr>
                                                <td>Rebuy cost</td>
                                                <td>${ghostEvent.rebuy_cost}</td>
                                            </tr>
                                            <tr>
                                                <td>Number of attempts</td>
                                                <td>{ghostEvent.number_of_attempts}</td>
                                            </tr>
                                            <tr>
                                                <td>Number of games</td>
                                                <td>{ghostEvent.number_of_games}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button
                                        onClick={() => {
                                            console.log(props);
                                            onSubmit({
                                                name: ghostEvent.name,
                                                slug: ghostEvent.slug,
                                                fromName: authState.user.displayName,
                                                invitedPlayers: ghostEvent.ghost_games_players,
                                            });
                                        }}
                                        type="primary"
                                    >
                                        Submit
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Title level={4} style={{ fontSize: 18, marginBottom: 20 }}>
                                        Invited Players
                                    </Title>
                                    {ghostEvent.ghost_games_players ? (
                                        <table width="100%" className="info-table">
                                            <tbody>
                                                {ghostEvent.ghost_games_players.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>
                                                                {val.user_id ? `${val.user.first_name} ${val.user.last_name} - ` : ''}
                                                                {val.email_address}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div>No players invited</div>
                                    )}
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            </div>
        </React.Fragment>
    );
}
