import { gql } from '@apollo/client';

export const GET_VENUE_QUERY = gql`
    query venues($slug: String) {
        venues(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            lat
            lng
            is_public
            is_claimed
            claimed_by_id
            owner_id
            owner {
                id
                first_name
                last_name
                slug
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
            leagues: league_venues_aggregate(where: { league: { is_public: { _eq: true } } }) {
                aggregate {
                    count
                }
            }
            tournaments: tournaments_aggregate(where: { is_public: { _eq: true } }) {
                aggregate {
                    count
                }
            }
            created_at
            updated_at
        }
    }
`;

export const GET_VENUE_BY_ID_QUERY = gql`
    query venues($id: Int!) {
        venues(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            lat
            lng
            is_public
            is_claimed
            claimed_by_id
            owner_id
            owner {
                id
                first_name
                last_name
                slug
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const GET_TABLE_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
        }
    }
`;

export const GET_TABLE_MANUFACTURERS_QUERY = gql`
    query pool_table_manufacturers {
        pool_table_manufacturers {
            id
            name
        }
    }
`;

export const GET_VENUE_TYPES_QUERY = gql`
    query venue_types {
        venue_types {
            value
            comment
        }
    }
`;

export const GET_GAME_TYPES_QUERY = gql`
    query game_types {
        game_types {
            value
            comment
        }
    }
`;

export const GET_VENUE_TOURNAMENTS_QUERY = gql`
    query venues($id: Int) {
        venues(where: { id: { _eq: $id } }) {
            id
            name
            tournaments(where: { is_public: { _eq: true } }, order_by: { created_at: desc }) {
                id
                name
                slug
                description
                avatar
                flyer
                logo
                tournament_type
                player_type
                game_type
                winners_race_to
                losers_race_to
                race_to
                consolidation_finals
                max_players
                max_tables
                rule_format
                break_format
                status
                progress
                start_date_time
                end_date_time
                entry_fee
                added_money
                payout_type
                draw_type
                rating_system
                use_text_messaging
                use_in_app_messaging
                rsvp_allowed
                is_public
                signup_cutoff_time
                director_id
                director {
                    id
                    first_name
                    last_name
                    email
                }
                organizer_id
                organizer {
                    id
                    first_name
                    last_name
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const GET_VENUE_LEAGUES_QUERY = gql`
    query venues($id: Int) {
        venues(where: { id: { _eq: $id } }) {
            id
            name
            league_venues(where: { league: { is_public: { _eq: true } } }, order_by: { created_at: desc }) {
                id
                league {
                    id
                    name
                    slug
                    short_description
                    long_description
                    avatar
                    logo
                    is_public
                    owner_id
                    owner {
                        id
                        first_name
                        last_name
                        email
                    }
                    operator_id
                    operator {
                        id
                        first_name
                        last_name
                        email
                    }
                    address1
                    address2
                    city
                    county
                    country
                    region
                    postal_code
                    phone_number
                    fax_number
                    email_address
                    website
                    social_facebook
                    social_instagram
                    social_twitter
                    match_notifications_in_app
                    match_notifications_sms
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const SEARCH_USER_VENUES_QUERY = gql`
    query venues($name: String!, $userId: Int!) {
        venues(
            distinct_on: name
            where: {
                owner_id: { _eq: $userId }
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

export const SEARCH_VENUES_QUERY = gql`
    query venues($name: String!) {
        venues(
            distinct_on: name
            where: {
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

export const GET_VENUE_MARKERS_QUERY = gql`
    query venues {
        venues(where: { is_public: { _eq: true } }, order_by: { name: asc }) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
        }
    }
`;

export const GET_VENUES_QUERY = gql`
    query venues($limit: Int!, $offset: Int!) {
        venues(where: { is_public: { _eq: true } }, order_by: { name: asc }, limit: $limit, offset: $offset) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

export const GET_NEW_VENUES_QUERY = gql`
    query venues {
        venues(order_by: { created_at: desc }, limit: 100) {
            id
            name
            slug
            address1
            city
            region
            country
            venue_type
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            owner {
                id
            }
        }
    }
`;

export const GET_USER_VENUES_QUERY = gql`
    query venues($userId: Int!, $limit: Int!, $offset: Int!) {
        venues(where: { owner_id: { _eq: $userId } }, order_by: { name: asc }, limit: $limit, offset: $offset) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

export const GET_USER_VENUE_COUNT = gql`
    query venues_aggregate($userId: Int!) {
        venues_aggregate(where: { owner_id: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ALL_VENUE_COUNT = gql`
    query venues_aggregate {
        venues_aggregate(where: { is_public: { _eq: true } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_VENUE_TABLES_QUERY = gql`
    query venue_pool_tables($venueId: Int) {
        venue_pool_tables(where: { venue_id: { _eq: $venueId } }, order_by: { label: asc }) {
            id
            label
            type
            size
            year_built
            make
            model
            livestream_url
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
        }
    }
`;

export const GET_LEAGUE_VENUES_QUERY = gql`
    query venues($leagueId: Int) {
        venues(where: { league_venues: { league_id: { _eq: $leagueId } } }, order_by: { name: asc }) {
            id
            name
            slug
            description
            venue_type
            league_venues {
                league {
                    id
                    name
                    slug
                }
            }
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            organizer_id
        }
    }
`;

export const GET_TABLE_BY_ID_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            livestream_url
            manufacturer_id
            venue_id
            user_id
            match_id
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
        }
    }
`;

export const SEARCH_POOL_VENUES = gql`
    query venues($name: String!) {
        venues(where: { name: { _ilike: $name } }) {
            id
            name
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POOL_TABLES = gql`
    query pool_tables($venue_id: Int!) {
        pool_tables(where: { venue_id: { _eq: $venue_id } }) {
            id
            slug
            is_featured_table
            is_streaming_table
            label
            league_id
            livestream_url
            make
            manufacturer_id
            match_id
            model
            size
            status
            tournament_id
            type
            updated_at
            user_id
            venue_id
            year_built
            created_at
        }
    }
`;
