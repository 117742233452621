import React, { useState, useRef, useEffect } from 'react';
import { Table, Typography, Button, Icon, Modal, Input, Tooltip } from 'antd';
import CircleFlag from '../../../components/CircleFlag';
import _ from 'lodash';
import verifiedIcon from '../../../assets/img/verified-icon.svg';
import Highlighter from 'react-highlight-words';
import PlayerModal from './bracket/PlayerModal';
import { TrophyFilled } from '@ant-design/icons';
import { getCountryName } from '../../../utils';

const { Text } = Typography;
const { confirm } = Modal;

export default function PlayerTable(props) {
    const { tournament } = props;
    const { onSelected, onEditSelected, selectedRowKeys, onDelete, size } = props;
    const [selectedKeys, setSelectedKeys] = useState(selectedRowKeys || []);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    const searchInput = useRef(null);

    useEffect(() => {
        setSelectedKeys(selectedRowKeys);
    }, [selectedRowKeys]);

    function onSelectChange(selectedRowKeys, selectedRows) {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedKeys(selectedRowKeys);
        onSelected(selectedRowKeys, selectedRows);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    function handleShowPlayerModal(playerId) {
        console.log(playerId);
        setSelectedPlayerId(playerId);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 80,
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',

            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                const nameSplit = record.name && record.name.split(' ');
                const firstName = nameSplit && nameSplit[0];
                const lastName = nameSplit && nameSplit[1];
                const nickname = record.nickname;
                let displayName;

                if (firstName && lastName && nickname) {
                    displayName = `${firstName} "${record.nickname}" ${lastName}`;
                } else if (firstName && !lastName && nickname) {
                    displayName = `${firstName} "${record.nickname}"`;
                } else {
                    displayName = record.name;
                }

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {record.country && <CircleFlag country={record.country} left={0} />}
                            <span>{displayName}</span>
                        </div>
                        {record.user_id && <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />}
                    </div>
                );
            },
        },

        {
            title: 'Country',
            dataIndex: 'country',

            sorter: (a, b) => {
                return a.country && a.country.localeCompare(b.country);
            },
            render: (text, record) => {
                const country = record.country ? getCountryName(record.country) : text;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {country ? _.startCase(country) : '-'}
                    </div>
                );
            },
        },

        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',

            sorter: (a, b) => {
                return (
                    a.place &&
                    a.place.localeCompare(b.place, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => (
                <div>
                    {record.place === '1' ? (
                        <TrophyFilled style={{ color: '#eab700', marginRight: 5 }} />
                    ) : record.place === '2' ? (
                        <TrophyFilled style={{ color: '#9baab1', marginRight: 5 }} />
                    ) : (
                        record.place === '3' && <TrophyFilled style={{ color: '#f19921', marginRight: 5 }} />
                    )}
                    <Text>{text || '-'}</Text>
                </div>
            ),
        },

        // {
        //     title: 'Fargo ID',
        //     dataIndex: 'fargo_id',
        //     sorter: (a, b) => {
        //         return a.fargo_id.localeCompare(b.fargo_id, undefined, {
        //             numeric: true,
        //             sensitivity: 'base',
        //         });
        //     },

        //     render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        // },

        {
            title: 'Skill Level',
            dataIndex: 'skill_level',

            sorter: (a, b) => a.skill_level - b.skill_level,
            render: (text, record) => <React.Fragment>{text ? text : '-'}</React.Fragment>,
        },

        {
            title: 'Seed',
            dataIndex: 'seed',

            sorter: (a, b) => a.seed - b.seed,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },

        {
            title: 'Match History',
            dataIndex: 'matches',
            key: 'matches',
            disabled: true,
            render: (text, record) => {
                // console.log(record);
                return <Text>{renderMatchHistory(record.matches, record.id)}</Text>;
            },
        },

        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <span style={{ whiteSpace: 'nowrap' }}>
        //             <Tooltip title="View player details">
        //                 <Button
        //                     type="ghost"
        //                     onClick={(e) => {
        //                         e.preventDefault();
        //                         e.stopPropagation();
        //                         console.log(record);
        //                         // props.history.push(
        //                         // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
        //                         // );
        //                     }}
        //                 >
        //                     <Icon type="eye" />
        //                 </Button>
        //             </Tooltip>
        //         </span>
        //     ),
        // },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function renderMatchHistory(matchesWithoutByes, playerId) {
        return (
            <Text>
                {matchesWithoutByes
                    .sort((a, b) => {
                        return a.match_number - b.match_number;
                    })
                    .filter((match) => {
                        return match.challenger1_is_winner === true || match.challenger2_is_winner === true;
                    })
                    .map((match, index) => {
                        const result =
                            match.challenger1_id === playerId && match.challenger1_is_winner === true
                                ? 'W'
                                : match.challenger2_id === playerId && match.challenger2_is_winner === true
                                ? 'W'
                                : match.challenger1_is_winner !== true && match.challenger2_is_winner !== true
                                ? '-'
                                : match.challenger1_is_forfeit === true || match.challenger2_is_forfeit === true
                                ? 'FF'
                                : 'L';

                        const opponentName =
                            match.challenger1_id === playerId ? match.challenger2_name : match.challenger2_id === playerId ? match.challenger1_name : null;
                        const playerScore =
                            match.challenger1_id === playerId ? match.challenger1_score : match.challenger2_id === playerId ? match.challenger2_score : 0;
                        const opponentScore =
                            match.challenger1_id !== playerId ? match.challenger1_score : match.challenger2_id !== playerId ? match.challenger2_score : 0;

                        return (
                            <React.Fragment key={index}>
                                <Tooltip placement="top" title={`${result} vs. ${opponentName} (${playerScore}-${opponentScore})`}>
                                    <span
                                        className={
                                            (match.challenger1_id === playerId && match.challenger1_is_winner === true) ||
                                            (match.challenger2_id === playerId && match.challenger2_is_winner === true)
                                                ? 'match-badge win'
                                                : 'match-badge loss'
                                        }
                                    >
                                        {result}
                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}
            </Text>
        );
    }

    return (
        <React.Fragment>
            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log(record, rowIndex);
                            handleShowPlayerModal(record.id);
                            // props.history.push({
                            // 	pathname: `/tournament-builder/${props.tournament
                            // 		.slug}/players/${record.name}`,
                            // 	// search: '?query=abc',
                            // 	state: {
                            // 		data: record,
                            // 		backPath: `/tournament-builder/${props.tournament
                            // 			.slug}/stats`
                            // 	}
                            // });
                        },
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                    };
                }}
                columns={columns}
                dataSource={props.players}
                onChange={onChange}
                pagination={false}
                tableLayout="auto"
                // className="noselect"
                scroll={{ x: 400 }}
                // pagination={{ pageSize: 25 }}

                // bordered
            />
            <PlayerModal
                tournamentID={tournament.id}
                playerId={selectedPlayerId}
                modalVisible={playerModalVisible}
                onPlayerModalVisible={handlePlayerModalVisible}
                {...props}
            />
        </React.Fragment>
    );
}
