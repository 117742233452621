import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function HelmetMetaData(props) {
    const location = window.location;
    let currentUrl = 'https://digitalpool.com' + location.pathname;
    let quote = props.quote || '';
    let title = props.title || 'DigitalPool - Bridging the gap between player and technology';
    let image = props.image || 'https://digitalpool.com/images/fb-image.png';
    let description =
        props.description ||
        'Create and manage your own leagues and tournaments while you stay up to date with the latest social activity from around the pool world.';
    let hashtag = props.hashtag || '#digitalpool';

    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href="https://www.digitalpool.com/" />
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:image" content={image} />

            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="digitalpool.com" />
            <meta property="og:description" content={description} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="fb:app_id" content="1697957547011275" />

            <meta property="type" content="website" />
            <meta property="url" content={currentUrl} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
        </Helmet>
    );
}
