import React, { useState, useEffect } from 'react';
import { message, List, Tabs, Row, Col, Alert, Button, Icon, Tooltip, Table, Progress, Badge, Typography, Modal, Empty, Input } from 'antd';
import { GET_TOURNAMENT_PLAYER_QUERY, GET_TOURNAMENT_PLAYER_MATCHES_QUERY, GET_TOURNAMENT_PLAYER_STATS_QUERY2 } from './data/queries';
import { UPDATE_TOURNAMENT_PLAYER_MUTATION } from './data/mutations';
import { Query, Mutation } from '@apollo/client/react/components';
import { withRouter, Link } from 'react-router-dom';
import CircleFlag from '../../components/CircleFlag';
import verifiedIcon from '../../assets/img/verified-icon.svg';
import { getCountryName, formatPlayerName, getOrdinalSuffix } from '../..//utils';
import CircularLoader from '../../components/CircularLoader';
import _ from 'lodash';
import numeral from 'numeral';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { TrophyFilled } from '@ant-design/icons';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import AssignTableForm from './AssignTableForm';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { Text } = Typography;

function PlayerModal(props) {
    const { tournament, tournamentID, tables, isPublic, player, playerId, modalVisible, onPlayerModalVisible, client } = props;
    const [currentPlayer, setCurrentPlayer] = useState();
    const [updateTournamentPlayer] = useMutation(UPDATE_TOURNAMENT_PLAYER_MUTATION);

    function handlePlayerModalOk(e) {
        console.log(e);
        onPlayerModalVisible(false);
        // setPlayerModalVisible(false);
    }

    function handlePlayerModalCancel(e) {
        onPlayerModalVisible(false);
        // setPlayerModalVisible(false);
    }

    function showPlayerModal(player) {
        setCurrentPlayer(player);
        onPlayerModalVisible(true);
        // setPlayerModalVisible(true);
    }

    function renderMatchHistory(matchesWithoutByes, player) {
        return (
            <Text>
                {matchesWithoutByes

                    .filter((match) => {
                        return match.challenger1_is_winner === true || match.challenger2_is_winner === true;
                    })
                    .map((match, index) => {
                        const result =
                            match.challenger1_id === playerId && match.challenger1_is_winner === true
                                ? 'W'
                                : match.challenger2_id === playerId && match.challenger2_is_winner === true
                                ? 'W'
                                : match.challenger1_is_winner !== true && match.challenger2_is_winner !== true
                                ? '-'
                                : match.challenger1_is_forfeit === true || match.challenger2_is_forfeit === true
                                ? 'FF'
                                : 'L';

                        const opponentName =
                            match.challenger1_id === playerId ? match.challenger2_name : match.challenger2_id === playerId ? match.challenger1_name : null;
                        const playerScore =
                            match.challenger1_id === playerId ? match.challenger1_score : match.challenger2_id === playerId ? match.challenger2_score : 0;
                        const opponentScore =
                            match.challenger1_id !== playerId ? match.challenger1_score : match.challenger2_id !== playerId ? match.challenger2_score : 0;

                        return (
                            <React.Fragment key={index}>
                                <Tooltip placement="top" title={`${result} vs. ${opponentName} (${playerScore}-${opponentScore})`}>
                                    <span
                                        className={
                                            (match.challenger1_id === playerId && match.challenger1_is_winner === true) ||
                                            (match.challenger2_id === playerId && match.challenger2_is_winner === true)
                                                ? 'match-badge win'
                                                : 'match-badge loss'
                                        }
                                    >
                                        {result}
                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}
            </Text>
        );
    }

    function addPlayerChip(player) {
        const chipsLeft = parseInt(player.chips_left);

        updateTournamentPlayer({
            variables: {
                id: player.id,
                tournament_id: tournamentID,
                changes: {
                    chips_left: chipsLeft ? chipsLeft + 1 : 1,
                    is_eliminated: false,
                    place: null,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_PLAYER_QUERY,
                    variables: {
                        id: player.id,
                        tournament_id: tournamentID,
                    },
                },
            ],
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    function removePlayerChip(player) {
        const chipsLeft = parseInt(player.chips_left) - 1;
        const isEliminated = chipsLeft === 0 ? true : false;

        updateTournamentPlayer({
            variables: {
                id: player.id,
                tournament_id: tournamentID,
                changes: {
                    chips_left: chipsLeft ? chipsLeft : 0,
                    is_eliminated: isEliminated,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_PLAYER_QUERY,
                    variables: {
                        id: player.id,
                        tournament_id: tournamentID,
                    },
                },
            ],
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    return (
        <React.Fragment>
            {player ||
                (playerId && (
                    <Query
                        query={GET_TOURNAMENT_PLAYER_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ id: player ? player.id : playerId, tournament_id: tournamentID }}
                        onCompleted={(data) => {
                            console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div>Error: {error.message}</div>;

                            const playerData = data && data.tournament_players && data.tournament_players.length > 0 ? data.tournament_players[0] : null;
                            const matches = [...playerData.tournament_matches_challenger1, ...playerData.tournament_matches_challenger2];
                            const matchesWithoutByes = matches
                                .filter((match) => {
                                    return match.is_bye !== true && match.challenger1_name && match.challenger2_name && match.status === 'COMPLETED';
                                })
                                .sort((a, b) => {
                                    return a.match_number - b.match_number;
                                });
                            const upcomingMatch = matches.filter((matchData) => {
                                return (
                                    matchData.is_bye !== true &&
                                    matchData.challenger1_name &&
                                    matchData.challenger2_name &&
                                    matchData.status === 'NOT_STARTED' &&
                                    tournament.status !== 'COMPLETED'
                                );
                            })[0];

                            console.log(matches);
                            console.log(playerData);
                            console.log(upcomingMatch);

                            return (
                                <Modal
                                    title={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {playerData.country && <CircleFlag country={playerData.country} left={0} />}

                                                <Text
                                                    style={{
                                                        marginLeft: 0,
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {formatPlayerName(playerData)} {playerData.race_to ? `(${playerData.race_to})` : null}{' '}
                                                    {playerData.chips_left === 0 && '(Eliminated)'}
                                                </Text>
                                            </div>
                                            {playerData.user_id && <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />}
                                        </div>
                                    }
                                    visible={modalVisible}
                                    onOk={handlePlayerModalOk}
                                    onCancel={handlePlayerModalCancel}
                                    centered
                                    transitionName="fade"
                                    maskTransitionName="none"
                                    bodyStyle={{ padding: 0 }}
                                    footer={[
                                        <Button
                                            key="submit"
                                            size="large"
                                            type="primary"
                                            onClick={() => {
                                                handlePlayerModalOk();
                                            }}
                                        >
                                            Close
                                        </Button>,
                                    ]}
                                >
                                    <Tabs
                                        animated={{ inkBar: true, tabPane: false }}
                                        defaultActiveKey="tables"
                                        // onChange={callback}
                                        // type="card"
                                        tabBarStyle={{ textTransform: 'uppercase', paddingLeft: 10 }}
                                    >
                                        <TabPane tab="Player" key="player">
                                            <div style={{ padding: 20 }}>
                                                {/* {upcomingMatch && (
                                                    <div style={{ marginBottom: 15 }}>
                                                        <Alert
                                                            message={`Upcoming match #${upcomingMatch.match_number} (${upcomingMatch.identifier})`}
                                                            description={`${
                                                                upcomingMatch.challenger1_name === playerData.name
                                                                    ? upcomingMatch.challenger1_name
                                                                    : upcomingMatch.challenger2_name
                                                            }'s next match against ${
                                                                upcomingMatch.challenger1_name === playerData.name
                                                                    ? upcomingMatch.challenger2_name
                                                                    : upcomingMatch.challenger1_name
                                                            } is projected to start at ${
                                                                upcomingMatchProjection &&
                                                                moment.utc(upcomingMatchProjection.start_time).local().format('h:mm a z')
                                                            } ${timezone}.`}
                                                            type="info"
                                                            closable
                                                            showIcon
                                                        />
                                                    </div>
                                                )} */}

                                                <Text style={{ fontWeight: 700, marginBottom: 10 }}>Player info</Text>
                                                <table width="100%" className="info-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Name</td>
                                                            <td>
                                                                {/* {playerData.name} (Player ID: {playerData.id})
                                                                 */}
                                                                {formatPlayerName(playerData)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Country</td>
                                                            <td className="display-linebreak">{playerData.country || '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chips left</td>
                                                            <td className="display-linebreak">{playerData.chips_left || 0}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Place</td>
                                                            <td className="display-linebreak">
                                                                {playerData.place === '1' ? (
                                                                    <TrophyFilled
                                                                        style={{
                                                                            color: '#eab700',
                                                                            marginRight: 5,
                                                                        }}
                                                                    />
                                                                ) : playerData.place === '2' ? (
                                                                    <TrophyFilled
                                                                        style={{
                                                                            color: '#9baab1',
                                                                            marginRight: 5,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    playerData.place === '3' && (
                                                                        <TrophyFilled
                                                                            style={{
                                                                                color: '#f19921',
                                                                                marginRight: 5,
                                                                            }}
                                                                        />
                                                                    )
                                                                )}
                                                                {playerData.place ? `${getOrdinalSuffix(playerData.place)} place` : '-'}
                                                            </td>
                                                        </tr>
                                                        {tournament.show_player_skill_levels === true && (
                                                            <tr>
                                                                <td>Skill Level</td>
                                                                <td className="display-linebreak">{playerData.skill_level || '-'}</td>
                                                            </tr>
                                                        )}
                                                        {playerData.user_id && (
                                                            <tr>
                                                                <td>User ID</td>
                                                                <td className="display-linebreak">
                                                                    {playerData.user_id || '-'}{' '}
                                                                    {playerData.user && playerData.user.slug && (
                                                                        <React.Fragment>
                                                                            {' - '}
                                                                            <Link to={`/users/${playerData.user.slug}`}>View Profile</Link>
                                                                        </React.Fragment>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {playerData.user && playerData.user.fargo_id && (
                                                            <tr>
                                                                <td>Fargo ID</td>
                                                                <td className="display-linebreak">{playerData.user.fargo_id || '-'}</td>
                                                            </tr>
                                                        )}

                                                        <tr>
                                                            <td>Matches played</td>
                                                            <td className="display-linebreak">{matchesWithoutByes.length}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Match history</td>
                                                            <td className="display-linebreak">{renderMatchHistory(matchesWithoutByes, player)}</td>
                                                        </tr>

                                                        {isPublic !== true && (
                                                            <tr>
                                                                <td>Actions</td>
                                                                <td>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Button
                                                                            key="add-chip"
                                                                            onClick={() => {
                                                                                addPlayerChip(playerData);
                                                                            }}
                                                                        >
                                                                            Add Chip
                                                                        </Button>
                                                                        <Button
                                                                            key="remove-chip"
                                                                            type="danger"
                                                                            ghost
                                                                            onClick={() => {
                                                                                removePlayerChip(playerData);
                                                                            }}
                                                                            style={{ marginLeft: 10 }}
                                                                        >
                                                                            Remove Chip
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {/* {isPublic !== true && (
                                                            <tr>
                                                                <td>Table assignment</td>
                                                                <td>
                                                                    <AssignTableForm
                                                                        tournament={tournament}
                                                                        matches={matches}
                                                                        tables={tables}
                                                                        playerId={playerId}
                                                                        {...props}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )} */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tab={`Match history (${matchesWithoutByes && matchesWithoutByes.length})`}
                                            key="matches"
                                            style={{ maxHeight: 500, overflowY: 'auto' }}
                                        >
                                            <Query
                                                query={GET_TOURNAMENT_PLAYER_MATCHES_QUERY}
                                                fetchPolicy="cache-and-network"
                                                notifyOnNetworkStatusChange={true}
                                                variables={{
                                                    tournament_id: tournamentID,
                                                    player_name: playerData.name,
                                                }}
                                                onCompleted={(data) => {
                                                    console.log(data);
                                                }}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) return <CircularLoader />;
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    const matches = data && data.vw_player_tournament_matches;
                                                    console.log(matches);

                                                    return (
                                                        <React.Fragment>
                                                            <div
                                                                style={{
                                                                    paddingLeft: 20,
                                                                    paddingRight: 20,
                                                                    paddingBottom: 20,
                                                                    paddingTop: 5,
                                                                }}
                                                            >
                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        {matches &&
                                                                            matches.map((match, index) => {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <tr>
                                                                                            <td
                                                                                                style={{
                                                                                                    paddingTop: 15,
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    style={{
                                                                                                        fontWeight: 700,
                                                                                                        marginBottom: 10,
                                                                                                    }}
                                                                                                >
                                                                                                    Match: {match.match_number} ({match.identifier})
                                                                                                </Text>
                                                                                            </td>
                                                                                        </tr>

                                                                                        {/* <tr>
																						<td>Round</td>
																						<td>{match.identifier}</td>
																					</tr>
																					<tr>
																						<td>Match number</td>
																						<td className="display-linebreak">
																							{match.match_number}
																						</td>
																					</tr> */}
                                                                                        <tr>
                                                                                            <td>Players</td>
                                                                                            <td className="display-linebreak">
                                                                                                <div
                                                                                                    style={{
                                                                                                        maxWidth: 250,
                                                                                                    }}
                                                                                                >
                                                                                                    <Text
                                                                                                        style={{
                                                                                                            marginLeft: 0,
                                                                                                            width: '100%',
                                                                                                        }}
                                                                                                    >
                                                                                                        {match.player_won ? (
                                                                                                            <Badge
                                                                                                                count="W"
                                                                                                                style={{
                                                                                                                    backgroundColor: '#87d068',
                                                                                                                    marginRight: 5,
                                                                                                                }}
                                                                                                            />
                                                                                                        ) : match.opponent_won ? (
                                                                                                            <Badge
                                                                                                                count={
                                                                                                                    match.challenger2_is_forfeit ? 'FF' : 'L'
                                                                                                                }
                                                                                                                style={{
                                                                                                                    backgroundColor: '#f5222d',
                                                                                                                    marginRight: 5,
                                                                                                                }}
                                                                                                            />
                                                                                                        ) : null}
                                                                                                        {match.player_name || 'N/A'}{' '}
                                                                                                        {match.player_skill_level &&
                                                                                                        tournament.show_player_skill_levels === true
                                                                                                            ? `(${match.player_skill_level})`
                                                                                                            : null}
                                                                                                    </Text>
                                                                                                    <Text
                                                                                                        style={{
                                                                                                            marginLeft: 5,
                                                                                                            marginRight: 5,
                                                                                                        }}
                                                                                                    >
                                                                                                        vs.
                                                                                                    </Text>
                                                                                                    <Text
                                                                                                        style={{
                                                                                                            marginLeft: 0,
                                                                                                            width: '100%',
                                                                                                            whiteSpace: 'nowrap',
                                                                                                        }}
                                                                                                    >
                                                                                                        {match.opponent_won ? (
                                                                                                            <Badge
                                                                                                                count="W"
                                                                                                                style={{
                                                                                                                    backgroundColor: '#87d068',
                                                                                                                    marginRight: 5,
                                                                                                                }}
                                                                                                            />
                                                                                                        ) : match.player_won ? (
                                                                                                            <Badge
                                                                                                                count={
                                                                                                                    match.challenger1_is_forfeit ? 'FF' : 'L'
                                                                                                                }
                                                                                                                style={{
                                                                                                                    backgroundColor: '#f5222d',
                                                                                                                    marginRight: 5,
                                                                                                                }}
                                                                                                            />
                                                                                                        ) : null}
                                                                                                        {match.opponent_name || 'N/A'}{' '}
                                                                                                        {match.opponent_skill_level &&
                                                                                                        tournament.show_player_skill_levels === true
                                                                                                            ? `(${match.opponent_skill_level})`
                                                                                                            : null}
                                                                                                    </Text>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td>Score</td>
                                                                                            <td className="display-linebreak">
                                                                                                <b>
                                                                                                    {match.players_score || 0}-{match.opponents_score || 0}
                                                                                                </b>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {match.match_duration && (
                                                                                            <tr>
                                                                                                <td>Duration</td>
                                                                                                <td className="display-linebreak">
                                                                                                    <Text type="secondary">
                                                                                                        <Icon type="clock-circle" />{' '}
                                                                                                        {match.match_duration.split(':')[0]}:
                                                                                                        {match.match_duration.split(':')[1]}:
                                                                                                        {Math.trunc(match.match_duration.split(':')[2])}
                                                                                                    </Text>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}

                                                                                        {match.match_rating && tournament.show_player_skill_levels === true && (
                                                                                            <tr>
                                                                                                <td>Performance rating</td>
                                                                                                <td className="display-linebreak">
                                                                                                    {match.match_rating === match.player_skill_level ? (
                                                                                                        <div>
                                                                                                            {parseFloat(
                                                                                                                match.match_rating - match.player_skill_level
                                                                                                            ).toFixed(2)}{' '}
                                                                                                            ({match.match_rating})
                                                                                                        </div>
                                                                                                    ) : parseFloat(match.match_rating) >
                                                                                                      match.player_skill_level ? (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                color: 'green',
                                                                                                            }}
                                                                                                        >
                                                                                                            <ArrowUpOutlined style={{ color: 'green' }} />{' '}
                                                                                                            {parseFloat(
                                                                                                                match.match_rating - match.player_skill_level
                                                                                                            ).toFixed(2)}{' '}
                                                                                                            ({match.match_rating})
                                                                                                        </div>
                                                                                                    ) : parseFloat(
                                                                                                          match.match_rating - match.player_skill_level
                                                                                                      ) < match.player_skill_level ? (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                color: 'red',
                                                                                                            }}
                                                                                                        >
                                                                                                            <ArrowDownOutlined style={{ color: 'red' }} />{' '}
                                                                                                            {parseFloat(
                                                                                                                match.match_rating - match.player_skill_level
                                                                                                            ).toFixed(2)}{' '}
                                                                                                            ({match.match_rating})
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div>
                                                                                                            {match.match_rating - match.player_skill_level}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            {/* <List
																size="small"
																loading={false}
																itemLayout="horizontal"
																dataSource={matches.sort((a, b) => {
																	return a.match_number - b.match_number;
																})}
																header={
																	<div
																		style={{
																			padding: '0px 20px',
																			margin: 0,
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'space-between'
																		}}
																	>
																		<div>
																			<Text
																				style={{ fontWeight: 700, width: 60 }}
																			>
																				Round
																			</Text>
																			<Text
																				style={{
																					fontWeight: 700,
																					width: 50,
																					marginLeft: 10
																				}}
																			>
																				Match
																			</Text>
																			<Text
																				style={{
																					fontWeight: 700,
																					marginLeft: 10
																				}}
																			>
																				Players
																			</Text>
																		</div>
																		<Text style={{ fontWeight: 700 }}>Score</Text>
																	</div>
																}
																renderItem={(match, index) => {
																	return (
																		<List.Item
																			key={index}
																			style={{
																				padding: '10px 20px',
																				display: 'flex',
																				justifyContent: 'space-between',
																				opacity:
																					match.is_bye === true ? 0.5 : 1.0
																			}}
																		>
																			<Text style={{ width: 60 }}>
																				{match.identifier}
																			</Text>
																			<Text style={{ width: 60 }}>
																				{match.match_number}
																			</Text>
																			<div
																				style={{
																					textAlign: 'left',
																					width: '100%'
																				}}
																			>
																				<div>
																					<Text
																						style={{
																							marginLeft: 0,
																							width: '100%',
																							whiteSpace: 'nowrap'
																						}}
																					>
																						{match.player_won ? (
																							<Badge
																								count="W"
																								style={{
																									backgroundColor:
																										'#87d068',
																									marginRight: 5
																								}}
																							/>
																						) : match.opponent_won ? (
																							<Badge
																								count="L"
																								style={{
																									backgroundColor:
																										'#f5222d',
																									marginRight: 5
																								}}
																							/>
																						) : null}
																						{match.player_name || 'N/A'}{' '}
																						{match.player_skill_level ? `(${match.player_skill_level})` : null}
																					</Text>
																					<Text
																						style={{
																							marginLeft: 5,
																							marginRight: 5
																						}}
																					>
																						vs.
																					</Text>
																					<Text
																						style={{
																							marginLeft: 0,
																							width: '100%',
																							whiteSpace: 'nowrap'
																						}}
																					>
																						{match.opponent_won ? (
																							<Badge
																								count="W"
																								style={{
																									backgroundColor:
																										'#87d068',
																									marginRight: 5
																								}}
																							/>
																						) : match.player_won ? (
																							<Badge
																								count="L"
																								style={{
																									backgroundColor:
																										'#f5222d',
																									marginRight: 5
																								}}
																							/>
																						) : null}
																						{match.opponent_name || 'N/A'}{' '}
																						{match.opponent_skill_level ? `(${match.opponent_skill_level})` : null}
																					</Text>
																				</div>
																				<div
																					style={{
																						display: 'flex',
																						alignItems: 'center',
																						justifyContent: 'flex-start',
																						marginTop: 5
																					}}
																				>
																					<Text>
																						Duration: <br />
																						<Icon type="clock-circle" />{' '}
																						{match.match_duration}
																					</Text>
																					<Text style={{ marginLeft: 10 }}>
																						Performance rating: <br />
																						{parseFloat(
																							match.match_rating
																						) > match.player_skill_level ? (
																							<div
																								style={{
																									color: 'green'
																								}}
																							>
																								<ArrowUpOutlined style={{ color: 'green' }} />{' '}
																								{match.match_rating}
																							</div>
																						) : parseFloat(
																							match.match_rating
																						) < match.player_skill_level ? (
																							<div
																								style={{ color: 'red' }}
																							>
																								<ArrowDownOutlined style={{ color: 'red' }} />{' '}
																								{match.match_rating}
																							</div>
																						) : (
																							<div>
																								{match.match_rating}
																							</div>
																						)}
																					</Text>
																				</div>
																			</div>

																			<Text
																				style={{
																					whiteSpace: 'nowrap',
																					width: 40
																				}}
																			>
																				{match.players_score || 0}-{match.opponents_score || 0}
																			</Text>
																		</List.Item>
																	);
																}}
															/> */}
                                                        </React.Fragment>
                                                    );
                                                }}
                                            </Query>
                                        </TabPane>
                                        <TabPane tab="Stats" key="stats">
                                            <Query
                                                query={GET_TOURNAMENT_PLAYER_STATS_QUERY2}
                                                fetchPolicy="cache-and-network"
                                                notifyOnNetworkStatusChange={true}
                                                variables={{
                                                    tournament_id: tournamentID,
                                                    player_id: player ? player.id : playerId,
                                                }}
                                                onCompleted={(data) => {
                                                    console.log(data);
                                                }}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) return <CircularLoader />;
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    const stats = data && data.vw_tournament_statistics;
                                                    const statsFormatted = stats.map((player, index) => {
                                                        return {
                                                            id: player.id,
                                                            name: player.player_name,
                                                            place: player.place,
                                                            country: player.player_country,
                                                            points: player.points,
                                                            matches_won: player.match_record,
                                                            matches_won_pct: player.match_win_percentage,
                                                            games_won: player.game_record,
                                                            games_won_pct: player.game_win_percentage,
                                                            key: index,
                                                        };
                                                    })[0];

                                                    return (
                                                        <div style={{ padding: 20 }}>
                                                            <Text style={{ fontWeight: 700, marginBottom: 10 }}>Player stats</Text>
                                                            {statsFormatted ? (
                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        {/* <tr>
																		<td>Matches played</td>
																		<td className="display-linebreak">
																			{matchesWithoutByes.length}
																			{renderMatchHistory(
																				matchesWithoutByes,
																				player
																			)}
																		</td>
																	</tr> */}
                                                                        <tr>
                                                                            <td>Match record</td>
                                                                            <td className="display-linebreak">
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={{
                                                                                            width: 80,
                                                                                            marginRight: 8,
                                                                                            whiteSpace: 'nowrap',
                                                                                        }}
                                                                                    >
                                                                                        {statsFormatted.matches_won}
                                                                                    </Text>
                                                                                    <Progress
                                                                                        percent={parseInt(statsFormatted.matches_won_pct.toFixed(0)) || 0}
                                                                                        status="normal"
                                                                                        size="small"
                                                                                        showInfo={false}
                                                                                        strokeWidth={6}
                                                                                        strokeLinecap="square"
                                                                                        style={{ maxWidth: 100 }}
                                                                                    />

                                                                                    <div
                                                                                        style={{
                                                                                            width: 40,
                                                                                            marginLeft: 10,
                                                                                        }}
                                                                                    >
                                                                                        <Text>{statsFormatted.matches_won_pct}%</Text>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Game record</td>
                                                                            <td className="display-linebreak">
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={{
                                                                                            width: 80,
                                                                                            marginRight: 8,
                                                                                            whiteSpace: 'nowrap',
                                                                                        }}
                                                                                    >
                                                                                        {statsFormatted.games_won}
                                                                                    </Text>
                                                                                    <Progress
                                                                                        percent={parseInt(statsFormatted.games_won_pct.toFixed(0)) || 0}
                                                                                        status="normal"
                                                                                        size="small"
                                                                                        showInfo={false}
                                                                                        strokeWidth={6}
                                                                                        strokeLinecap="square"
                                                                                        style={{ maxWidth: 100 }}
                                                                                    />

                                                                                    <div
                                                                                        style={{
                                                                                            width: 40,
                                                                                            marginLeft: 10,
                                                                                        }}
                                                                                    >
                                                                                        <Text>{statsFormatted.games_won_pct}%</Text>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Place</td>
                                                                            <td>{statsFormatted.place || '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Earnings</td>
                                                                            <td className="display-linebreak">
                                                                                <Text type="secondary">
                                                                                    {(playerData.payouts &&
                                                                                        numeral(playerData.payouts.money).format('$0,00')) ||
                                                                                        '-'}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Auction</td>
                                                                            <td className="display-linebreak">
                                                                                <Text type="secondary">
                                                                                    {(playerData.payouts &&
                                                                                        numeral(playerData.payouts.calcutta).format('$0,00')) ||
                                                                                        '-'}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        {statsFormatted.points && (
                                                                            <tr>
                                                                                <td>Points</td>
                                                                                <td className="display-linebreak">
                                                                                    <Text type="secondary">
                                                                                        {(statsFormatted.points && statsFormatted.points) || '-'}
                                                                                    </Text>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <div>No stats found</div>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                            </Query>
                                        </TabPane>
                                    </Tabs>
                                </Modal>
                            );
                        }}
                    </Query>
                ))}
        </React.Fragment>
    );
}

const MemoizedPlayerModal = React.memo(PlayerModal);
export default withRouter(MemoizedPlayerModal);
