import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Collapse } from 'antd';
import * as Yup from 'yup';
import { Mutation } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import slugify from 'slugify';

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Panel } = Collapse;

const CREATE_STREAM_MUTATION = gql`
    mutation insert_streams($objects: [streams_insert_input!]!) {
        insert_streams(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
            }
        }
    }
`;

function PLCreateEditStreamForm(props) {
    const [loading, setLoading] = useState(false);

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        description: Yup.string().required('Required'),
    });

    return (
        <Mutation
            mutation={CREATE_STREAM_MUTATION}
            onCompleted={(data) => {
                console.log(data);
                setLoading(false);
                const result = data.insert_leagues.returning[0];
                props.history.push(`/league-manager/${result.slug}/dashboard`);
                // const { createLeague } = data;
                // history.push(`/league-manager/${createLeague.slug}`);
            }}
            awaitRefetchQueries
        >
            {(createLeague, { error }) => {
                if (error) {
                    alert('Something went wrong');
                }
                return (
                    <Formik
                        initialValues={{
                            name: '',
                            description: '',
                            isPublic: 'public',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values) => {
                            console.log(values);
                            const { name, description, longDescription, isPublic } = values;
                            setLoading(true);
                            const slug = slugify(name, {
                                replacement: '-',
                                remove: /[*+~.,()'"#!:/@]/g,
                                lower: true,
                            });

                            createLeague({
                                variables: {
                                    objects: [
                                        {
                                            name: name,
                                            slug: slug,
                                            owner_id: props.authState.user.id,
                                            short_description: description,
                                            long_description: longDescription,
                                            is_public: isPublic === 'public' ? true : false,
                                        },
                                    ],
                                },
                            });
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 400 }}>
                                <FormItem
                                    name="name"
                                    label="Name"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="name" placeholder="Name" size="large" />
                                    <ErrorMessage name="name" component="div" className="ant-form-explain" />
                                </FormItem>

                                <FormItem
                                    name="description"
                                    label="Description"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.description || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="description" placeholder="Short Description" size="large" />
                                    <ErrorMessage name="description" component="div" className="ant-form-explain" />
                                </FormItem>

                                <FormItem name="isPublic" label="Who can view your broadcast">
                                    <Radio.Group name="isPublic">
                                        <Radio value="public">Public - Anyone can view this live stream.</Radio>
                                        <br />
                                        <Radio value="private">Private - Only friends will be able to view this live stream league.</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <Collapse>
                                    <Panel header="Advanced usage with streaming software" key="1">
                                        <h3 style={{ fontWeight: 600 }}>Single Field</h3>
                                        <FormItem
                                            name="streamUrl"
                                            label="Server or stream URL"
                                            className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="streamUrl" placeholder="Name" size="large" />
                                            <ErrorMessage name="streamUrl" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <h3 style={{ fontWeight: 600 }}>Separate Fields</h3>
                                        <FormItem
                                            name="streamUrl"
                                            label="Server URL"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="streamUrl" placeholder="Name" size="large" />
                                            <ErrorMessage name="streamUrl" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="streamKey"
                                            label="Stream Key"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="streamKey" placeholder="Name" size="large" />
                                            <ErrorMessage name="streamKey" component="div" className="ant-form-explain" />
                                        </FormItem>
                                    </Panel>
                                </Collapse>
                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={loading}
                                    style={{ marginTop: 10 }}
                                >
                                    Go Live
                                </SubmitButton>
                                <div>
                                    <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                </div>
                            </Form>
                        )}
                    />
                );
            }}
        </Mutation>
    );
}

export default withRouter(PLCreateEditStreamForm);
