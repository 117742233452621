import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Checkbox, DatePicker, Select, FormikDebug, SubmitButton, ResetButton, AutoComplete, InputNumber } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Card, Collapse, Col, Row, Alert, Button, Tooltip, Typography, Avatar, Icon, Divider } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import slugify from 'slugify';
import _ from 'lodash';
import CircularLoader from '../../components/CircularLoader';
import { getGameType, payoutStructures, calculateUSAPLRace, displayEnumType } from '../../utils';
import moment from 'moment-timezone';
import {
    GET_TOURNAMENT_BY_SLUG_QUERY,
    GET_GAME_TYPES_QUERY,
    GET_VENUES_QUERY,
    SEARCH_VENUES_QUERY,
    GET_USER_EVENTS_QUERY,
    SEARCH_USERS_BY_EMAIL,
    GET_TOURNAMENT_MANAGERS,
    SEARCH_USERS,
    GET_TOURNAMENT_BRACKET_TEMPLATES,
    GET_TOURNAMENT_TEMPLATES,
} from './data/queries';
import {
    CREATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    DELETE_TOURNAMENT_MANAGER,
    INSERT_TOURNAMENT_MANAGERS,
    CREATE_TOURNAMENT_TEMPLATE,
    DELETE_TOURNAMENT_TEMPLATE,
} from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddVenueModal from './AddVenueModal';
import AddEventModal from './AddEventModal';
import ChipAmountModal from './ChipAmountModal';
import { QuestionCircleOutlined, QuestionCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import numeral from 'numeral';
import axios from 'axios';
import qs from 'query-string';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function TBCreateTournamentForm(props) {
    const { authState, onCreated, tournament, match, theme, client } = props;
    console.log(tournament);
    const [fargoLoading, setFargoLoading] = useState(false);
    const [chipAmounts, setChipAmounts] = useState();
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [chipModalVisible, setChipModalVisible] = useState(false);
    const [fargoCharts, setFargoCharts] = useState();
    const [fargoRaceSize, setFargoRaceSize] = useState(5);
    const [player1Fargo, setPlayer1Fargo] = useState(500);
    const [player1RaceTo, setPlayer1RaceTo] = useState(0);
    const [player2Fargo, setPlayer2Fargo] = useState(600);
    const [player2RaceTo, setPlayer2RaceTo] = useState(0);
    const [venueDataSource, setVenueDataSource] = useState('');
    const [createTournamentTemplate] = useMutation(CREATE_TOURNAMENT_TEMPLATE);
    const [deleteTournamentTemplate] = useMutation(DELETE_TOURNAMENT_TEMPLATE);
    const [createTournament] = useMutation(CREATE_TOURNAMENT_MUTATION);
    const [updateTournament] = useMutation(UPDATE_TOURNAMENT_MUTATION);

    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const eventId = params.event_id;
    const formRef = useRef();

    useEffect(() => {
        async function fargoRatedCharts(size) {
            size = size || 0;
            setFargoLoading(true);
            const hotCharts = await getFargoCharts('hot', size);
            const mediumCharts = await getFargoCharts('medium', size);
            const mildCharts = await getFargoCharts('mild', size);
            setFargoLoading(false);
            // console.log(charts);
            const output = {
                hot: hotCharts,
                medium: mediumCharts,
                mild: mildCharts,
            };
            return output;
            // setFargoCharts(output);
        }

        const getData = async () => {
            const charts = await fargoRatedCharts(fargoRaceSize);
            console.log(charts);
            setFargoCharts(charts);
        };
        if (player1Fargo || player2Fargo) {
            getData();

            if (tournament && tournament.tournament_format === 'usapl_fargo_rated') {
                if (tournament.usapl_race_format === 'longer_races') {
                    calculateRace(1.0, player1Fargo, player2Fargo);
                } else if (tournament.usapl_race_format === 'shorter_races') {
                    calculateRace(0.75, player1Fargo, player2Fargo);
                }
            }
        }
    }, [fargoRaceSize, player1Fargo, player2Fargo]);

    // const [ publicPrivate, setPublicPrivate ] = useState('public');

    // const bracketDBDataset = createBracketDBDataset(1, 128);
    // console.log(bracketDBDataset);
    // console.table(bracketDBDataset.matches);

    // const winnerPlaces = bracketDBDataset.winnerPlacement;
    // const loserPlaces = bracketDBDataset.loserPlacement;
    // const matches = bracketDBDataset.matches;

    // console.log(matches);

    // const combined = _.uniq([ ...winnerPlaces.reverse(), ...loserPlaces.reverse(), '2' ])
    // 	.concat([ '3' ])
    // 	.sort((a, b) => {
    // 		return a.localeCompare(b, undefined, {
    // 			numeric: true,
    // 			sensitivity: 'base'
    // 		});
    // 	})
    // 	.map((item) => {
    // 		return {
    // 			place: item,
    // 			tournament_id: 1
    // 		};
    // 	});

    // console.log(combined);

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelect(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    const showLoading = () => {
        const hide = message.loading('Action in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleEventModalOk(e) {
        setEventModalVisible(false);
    }

    function handleEventModalCancel(e) {
        setEventModalVisible(false);
    }

    function handleAddEvent() {
        setEventModalVisible(true);
    }

    function handleChipModalOk(e) {
        setChipModalVisible(false);
    }

    function handleChipModalCancel(e) {
        setChipModalVisible(false);
    }

    function handleSetChipAmounts() {
        setChipModalVisible(true);
    }

    function handleSaveChips(data) {
        console.log(data);
        setChipAmounts(data);
    }

    // function onDateChange(value, dateString) {
    //     console.log('Selected Time: ', value);
    //     console.log('Formatted Selected Time: ', dateString);
    //     const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
    //     console.log('TZ Date', tzDate);
    //     return value;
    // }

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function getFargoCharts(type, size) {
        return new Promise((resolve, reject) => {
            const typesEnum = {
                hot: 2,
                medium: 1,
                mild: 0,
            };
            const url = `https://lms.fargorate.com/api/ratingcalc/racesbylengthandtype?length=${size}&type=${typesEnum[type]}&ratingOne=${player1Fargo}&ratingTwo=${player2Fargo}`;

            axios
                .get(url)
                .then((response) => {
                    const results = response.data;

                    resolve(results);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
        venue_id: Yup.string().required('Required'),
        winners_race_to: Yup.number().nullable().required('Required'),
        // losers_race_to: Yup.number().nullable().required('Required'),
        player_type: Yup.string().required('Required'),
        game_type: Yup.string().required('Required'),
        tournament_type: Yup.string().required('Required'),
        // tournament_format: Yup.string().required('Required'),
        start_date_time: Yup.date().nullable().required('Required'),
        end_date_time: Yup.date().nullable().required('Required'),
        // max_players: Yup.number().required('Required'),
    });

    function handleSubmit(values, actions) {
        console.log('values', values);
        const {
            name,
            venue_id,
            description,
            start_date_time,
            end_date_time,
            player_type,
            tournament_type,
            tournament_format,
            bracket_template_id,
            participant_times_played,
            players_per_group,
            players_advance_per_group,
            players_ranked_by,
            handicap_format,
            rule_format,
            break_format,
            game_type,
            max_players,
            max_tables,
            rebuys_allowed,
            max_rebuys,
            entry_fee,
            added_money,
            payout_type,
            draw_type,
            rating_system,
            use_text_messaging,
            is_public,
            is_featured,
            tournament_stage_format,
            rsvp_allowed,
            show_player_skill_levels,
            show_player_races,
            show_unconfirmed_players,
            winners_race_to,
            winners_max_race,
            losers_race_to,
            losers_max_race,
            finals_race_to,
            signup_cutoff_time,
            consolidation_finals,
            usapl_race_format,
            event_id,
            save_as_template,
            template_name,
            players_per_table,
            redraw_every_round,
            default_chip_amount,
            autopilot_mode,
        } = values;

        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (props.tournament) {
            updateTournament({
                variables: {
                    id: props.tournament.id,
                    changes: {
                        name,
                        slug,
                        venue_id,
                        description,
                        start_date_time,
                        end_date_time,
                        player_type,
                        tournament_type,
                        tournament_format,
                        bracket_template_id,
                        participant_times_played,
                        players_per_group,
                        players_advance_per_group,
                        players_ranked_by,
                        handicap_format,
                        rule_format,
                        break_format,
                        game_type,
                        max_players: parseInt(max_players),
                        max_tables: parseInt(max_tables),
                        rebuys_allowed,
                        max_rebuys: parseInt(max_rebuys),
                        entry_fee,
                        added_money,
                        payout_type,
                        draw_type,
                        rating_system,
                        use_text_messaging,
                        is_public,
                        is_featured,
                        tournament_stage_format,
                        rsvp_allowed,
                        show_player_skill_levels,
                        show_player_races,
                        show_unconfirmed_players,
                        winners_race_to: parseInt(winners_race_to),
                        winners_max_race: parseInt(winners_max_race),
                        losers_race_to: parseInt(losers_race_to),
                        losers_max_race: parseInt(losers_max_race),
                        finals_race_to: parseInt(finals_race_to),
                        usapl_race_format,
                        signup_cutoff_time,
                        consolidation_finals,
                        players_per_table: parseInt(players_per_table),
                        default_chip_amount: parseInt(default_chip_amount),
                        redraw_every_round,
                        autopilot_mode,
                        event_id,
                        // is_public: is_public === 'public' ? true : false,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                        variables: {
                            slug: props.tournament.slug,
                        },
                    },
                ],
            })
                .then((res) => {
                    actions.setSubmitting(false);

                    // actions.resetForm();
                    if (save_as_template === true) {
                        if (tournament.tournament_templates && tournament.tournament_templates.length === 0) {
                            createTournamentTemplate({
                                variables: {
                                    objects: [
                                        {
                                            name: template_name,
                                            tournament_id: tournament.id,
                                            user_id: props.authState.user.id,
                                        },
                                    ],
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                        variables: {
                                            slug: tournament.slug,
                                        },
                                    },
                                ],
                            })
                                .then((data) => {
                                    actions.setSubmitting(false);
                                    console.log(data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    } else {
                        const original = tournament.tournament_templates.map((item) => item.id);
                        const toUpdate = _.uniq(save_as_template, original.length > 0);
                        const toDelete = _.difference(original, toUpdate);
                        console.log('original', original);
                        console.log('update', toUpdate);
                        console.log('delete', toDelete);

                        if (toDelete.length > 0) {
                            deleteTournamentTemplate({
                                variables: {
                                    id: toDelete[0],
                                    tournament_id: tournament.id,
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                        variables: {
                                            slug: tournament.slug,
                                        },
                                    },
                                ],
                            })
                                .then((data) => {
                                    console.log(data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }

                    message.success('Changes saved');
                    console.log(res);
                    const result =
                        res && res.data && res.data.update_tournaments && res.data.update_tournaments.returning && res.data.update_tournaments.returning[0];
                    console.log('res', result);
                    if (onCreated) {
                        onCreated(result);
                    } else {
                        props.history.push(`/tournament-builder/${result.slug}/edit/?step=2`);
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createTournament({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            organizer_id: props.authState.user.id,
                            director_id: props.authState.user.id,
                            venue_id,
                            start_date_time,
                            end_date_time,
                            tournament_type,
                            tournament_format,
                            bracket_template_id,
                            participant_times_played,
                            players_per_group,
                            players_advance_per_group,
                            players_ranked_by,
                            handicap_format,
                            rule_format,
                            break_format,
                            player_type,
                            game_type,
                            max_players: parseInt(max_players),
                            max_tables: parseInt(max_tables),
                            rebuys_allowed,
                            max_rebuys: parseInt(max_rebuys),
                            entry_fee,
                            added_money,
                            payout_type,
                            draw_type,
                            rating_system,
                            use_text_messaging,
                            is_public,
                            is_featured,
                            tournament_stage_format,
                            rsvp_allowed,
                            show_player_skill_levels,
                            show_player_races,
                            show_unconfirmed_players,
                            winners_race_to: parseInt(winners_race_to),
                            winners_max_race: parseInt(winners_max_race),
                            losers_race_to: parseInt(losers_race_to),
                            losers_max_race: parseInt(losers_max_race),
                            finals_race_to: parseInt(finals_race_to),
                            usapl_race_format,
                            signup_cutoff_time,
                            consolidation_finals,
                            players_per_table: parseInt(players_per_table),
                            default_chip_amount: parseInt(default_chip_amount),
                            redraw_every_round,
                            autopilot_mode,
                            event_id,
                            ...(chipAmounts && {
                                tournament_chips: {
                                    data: chipAmounts,
                                },
                            }),
                            ...(save_as_template === true && {
                                tournament_templates: {
                                    data: {
                                        name: template_name,
                                        user_id: props.authState.user.id,
                                    },
                                },
                            }),
                        },
                    ],
                },
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();

                    const result =
                        res && res.data && res.data.insert_tournaments && res.data.insert_tournaments.returning && res.data.insert_tournaments.returning[0];
                    console.log(res);
                    console.log('res', result);
                    if (onCreated) {
                        props.history.push(`/tournament-builder/${result.slug}/edit?step=2`);
                        onCreated(result);
                    } else {
                        props.history.push(`/tournament-builder/${result.slug}/edit?step=2`);
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    if (e.message && e.message.includes('Uniqueness violation')) {
                        message.error('Error: There is already a tournament with that name. Please choose a different name.');
                    } else {
                        message.error((e && e.message) || JSON.stringify(e));
                    }

                    console.log(e);
                });
        }
    }

    function calculateRace(dampFactor, ratingOne, ratingTwo) {
        const { p1RaceTo, p2RaceTo } = calculateUSAPLRace(dampFactor, ratingOne, ratingTwo);

        setPlayer1RaceTo(p1RaceTo);
        setPlayer2RaceTo(p2RaceTo);
    }

    const initialValues = props.tournament
        ? {
              ...props.tournament,
              //   tournament_format: props.tournament_format || 'standard',
              venue_id: props.tournament.venue && props.tournament.venue.id,
              venue_name: props.tournament.venue && props.tournament.venue.name,
              event_id: props.tournament.event_id && props.tournament.event_id,
              save_as_template: props.tournament.tournament_templates && props.tournament.tournament_templates.length > 0 ? true : false,
              template_name: props.tournament.tournament_templates && props.tournament.tournament_templates[0] && props.tournament.tournament_templates[0].name,
          }
        : {
              name: '',
              venue_id: undefined,
              venue_name: undefined,
              description: '',
              start_date_time: '',
              end_date_time: '',
              player_type: 'singles',
              tournament_type: 'double_elimination',
              tournament_format: 'standard',
              bracket_template_id: undefined,
              participant_times_played: 1,
              players_per_group: 1,
              players_advance_per_group: 1,
              players_ranked_by: 'match_wins',
              handicap_format: 'fargo_hot_column',
              rule_format: 'bca',
              break_format: 'winner_break',
              game_type: undefined,
              max_players: undefined,
              max_tables: undefined,
              rebuys_allowed: false,
              max_rebuys: undefined,
              entry_fee: '',
              added_money: '',
              payout_type: 'custom',
              draw_type: 'random',
              rating_system: undefined,
              use_text_messaging: false,
              is_public: false,
              is_featured: false,
              tournament_stage_format: 'single',
              rsvp_allowed: false,
              show_player_skill_levels: true,
              show_player_races: true,
              show_unconfirmed_players: true,
              consolidation_finals: false,
              winners_race_to: 5,
              winners_max_race: undefined,
              losers_race_to: 5,
              losers_max_race: undefined,
              finals_race_to: undefined,
              save_as_template: false,
              redraw_every_round: false,
              players_per_table: 2,
              default_chip_amount: 3,
              autopilot_mode: false,
              usapl_race_format: 'longer_races',
              signup_cutoff_time: 'start_time',
              event_id: eventId ? parseInt(eventId) : undefined,
          };

    function handleFocus(event) {
        event.target.select();
    }

    return (
        <div>
            <div>
                <Alert
                    message="Helpful tips for setting up your tournament"
                    description={`We recommend to start by making your tournament private if you are just testing out the software or don't want other people to be able to access it. When you are ready to make it public, simply change the access setting to "Public" and your tournament will appear on the main /tournaments page.`}
                    type="info"
                    closable
                    showIcon
                />
            </div>
            <Title level={4} style={{ marginTop: 20, marginBottom: 15 }}>
                Tournament Settings
            </Title>
            <div>
                <Formik
                    ref={formRef}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={SignupSchema}
                    onSubmit={(values, actions) => {
                        handleSubmit(values, actions);
                    }}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                        const payouts = payoutStructures(values);
                        // console.log(payouts);
                        // console.log(fargoCharts);
                        // console.log('payouts', payouts.payoutOptions);
                        // console.log('recommended', payouts.recommendedPayouts);

                        return (
                            // <Form layout="horizontal" {...formItemLayout} style={{ maxWidth: 600 }}>
                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                <FormItem label="Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                    <Input
                                        name="name"
                                        placeholder="Name"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Saved Tournament Template" name="template_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_TOURNAMENT_TEMPLATES}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            user_id: props.authState.user.id,
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            const templates = data.tournament_templates;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="template_id"
                                                    placeholder="Select tournament template"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    onChange={(e) => {
                                                        const template = templates && templates.filter((item) => item.id === e)[0];
                                                        const templateTournament = template && template.tournament;

                                                        if (e) {
                                                            formRef.current.setValues({
                                                                ...templateTournament,
                                                                venue_id: templateTournament.venue && templateTournament.venue.id,
                                                                venue_name: templateTournament.venue && templateTournament.venue.name,
                                                                template_id: e,
                                                            });
                                                        } else {
                                                            formRef.current.setValues({
                                                                ...initialValues,
                                                            });
                                                        }
                                                    }}
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        // console.log(option);
                                                        return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    <Option value="">
                                                        <Text>None</Text>
                                                    </Option>
                                                    {data.tournament_templates &&
                                                        data.tournament_templates
                                                            .sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
                                                            .map((item, index) => (
                                                                <Option key={index} value={item.id}>
                                                                    <Text>
                                                                        {item.name} - {displayEnumType(item.tournament.game_type)}
                                                                        {item.tournament.max_players} Players {displayEnumType(item.tournament.tournament_type)}
                                                                    </Text>
                                                                </Option>
                                                            ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                                <FormItem name="description" label="Description">
                                    <TextArea
                                        rows={4}
                                        name="description"
                                        placeholder="Description"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Start Date & Time" name="start_date_time" required hasFeedback showValidateSuccess>
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        use12Hours
                                        name="start_date_time"
                                        size="large"
                                        format="YYYY-MM-DD h:mm A z"
                                        // onChange={onDateChange}
                                        onOk={onOk}
                                    />
                                </FormItem>
                                <FormItem
                                    label="End Date & Time"
                                    name="end_date_time"
                                    required
                                    hasFeedback
                                    showValidateSuccess
                                    // className={
                                    // 	'ant-form-item-control' +
                                    // 	(errors.end_date || errors.general ? ' has-error' : '')
                                    // }
                                >
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        use12Hours
                                        name="end_date_time"
                                        size="large"
                                        format="YYYY-MM-DD h:mm A z"
                                        // onChange={onDateChange}
                                        onOk={onOk}
                                    />
                                    {/* <ErrorMessage name="end_date" component="div" className="ant-form-explain" /> */}
                                </FormItem>
                                <FormItem
                                    label={
                                        <React.Fragment>
                                            Associate with an event{' '}
                                            <Tooltip
                                                placement="right"
                                                title={
                                                    <div>
                                                        Events can be used for marketing purposes and linking multiple tournaments together. In the near future,
                                                        players will also be able to pre-register for an event. Creating an event is not required.
                                                    </div>
                                                }
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                    name="event_id"
                                >
                                    <Query
                                        query={GET_USER_EVENTS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ user_id: props.authState.user.id }}
                                        onCompleted={async (data) => {
                                            console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="event_id"
                                                    placeholder="Select an event"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={eventId}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div
                                                                style={{
                                                                    padding: '4px 8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                onClick={handleAddEvent}
                                                            >
                                                                <Icon type="plus" /> Add Event
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {data.events
                                                        .sort((a, b) => {
                                                            return a.name.localeCompare(b.name, undefined, {
                                                                numeric: true,
                                                                sensitivity: 'base',
                                                            });
                                                        })
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.id}>
                                                                <Text>{item.name}</Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                                <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AutoComplete
                                            name="venue_name"
                                            dataSource={venueDataSource}
                                            placeholder="Search for a venue"
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            size="large"
                                            filterOption={(inputValue, option) => {
                                                return (
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                );
                                            }}
                                            dropdownRender={(menu) => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div
                                                        style={{
                                                            padding: '4px 8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={handleAddVenue}
                                                    >
                                                        <Icon type="plus" /> Add Venue
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            {venueDataSource &&
                                                venueDataSource.map((item, index) => (
                                                    <Option key={index} value={`${item.id}`}>
                                                        {item.name} - {item.city}, {item.region}
                                                    </Option>
                                                ))}
                                        </AutoComplete>
                                        <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                            <Icon type="plus" /> Add Venue
                                        </Button>
                                    </div>
                                </FormItem>
                                {/* <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                                    <Query
                                                        query={GET_VENUES_QUERY}
                                                        fetchPolicy="cache-and-network"
                                                        notifyOnNetworkStatusChange={true}
                                                        onCompleted={async (data) => {
                                                            // console.log(data);
                                                        }}
                                                    >
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <div>Loading...</div>;
                                                            if (error) return <div>Error: {error.message}</div>;

                                                            return (
                                                                <Select
                                                                    showSearch
                                                                    // mode="multiple"
                                                                    name="venue_id"
                                                                    placeholder="Select a venue for the tournament"
                                                                    size="large"
                                                                    optionFilterProp="children"
                                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                                    filterOption={(input, option) => {
                                                                        return (
                                                                            option.props.children.props.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(input.toLowerCase()) >= 0
                                                                        );
                                                                    }}
                                                                    dropdownRender={(menu) => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            <div
                                                                                style={{
                                                                                    padding: '4px 8px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onMouseDown={(e) => e.preventDefault()}
                                                                                onClick={handleAddVenue}
                                                                            >
                                                                                <Icon type="plus" /> Add Venue
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {data.venues
                                                                        .sort((a, b) => {
                                                                            return a.name.localeCompare(b.name, undefined, {
                                                                                numeric: true,
                                                                                sensitivity: 'base',
                                                                            });
                                                                        })
                                                                        .map((item, index) => (
                                                                            <Option key={index} value={item.id}>
                                                                                <Text>
                                                                                    {item.name} ({item.city}, {item.region})
                                                                                </Text>
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            );
                                                        }}
                                                    </Query>
                                                </FormItem> */}
                                <FormItem label="Player Type" name="player_type" required hasFeedback showValidateSuccess>
                                    {/* <Radio.Group
										name="player_type"
										defaultValue="singles"
										size="medium"
									>
										<Radio.Button value="singles">Singles (1v1)</Radio.Button>
										<Radio.Button value="doubles">Doubles (2v2)</Radio.Button>
										<Radio.Button value="scotch-doubles">
											Scotch Doubles (2v2 alternating shot)
										</Radio.Button>
										<Radio.Button value="team" disabled>
											Teams
										</Radio.Button>
									</Radio.Group> */}
                                    <Select
                                        showSearch
                                        name="player_type"
                                        placeholder="Select a player type"
                                        size="large"
                                        optionFilterProp="children"
                                        defaultValue="singles"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {[
                                            {
                                                type: 'Singles (1v1)',
                                                value: 'singles',
                                            },
                                            {
                                                type: 'Doubles (2v2)',
                                                value: 'doubles',
                                            },
                                            {
                                                type: 'Scotch Doubles (2v2 alternating shot)',
                                                value: 'scotch-doubles',
                                            },
                                            {
                                                type: 'Teams',
                                                value: 'teams',
                                            },
                                        ].map((item, index) => (
                                            <Option key={index} value={item.value}>
                                                {item.type}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                                <FormItem label="Game Type" name="game_type" required hasFeedback showValidateSuccess>
                                    <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="game_type"
                                                    placeholder="Select game being played in the tournament"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        // console.log(option);
                                                        return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data.game_types
                                                        .sort((a, b) => a.value.localeCompare(b.value))
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                {getGameType(item.value) && (
                                                                    <Avatar
                                                                        size={24}
                                                                        src={getGameType(item.value, theme)}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    />
                                                                )}

                                                                <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))} </Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                {/* <FormItem label="Tournament Stages" name="tournament_stage_format">
                                    <Radio.Group name="tournament_stage_format">
                                        <Radio value="single">Single stage</Radio>
                                        <Radio value="multi">Multi-stage</Radio>
                                    </Radio.Group>
                                </FormItem> */}

                                <FormItem label="Tournament Type" name="tournament_type" required hasFeedback showValidateSuccess>
                                    {/* <Radio.Group
										name="tournament_type"
										defaultValue="single-elimination"
										size="medium"
									>
										<Radio.Button value="single-elimination">Single Elimination</Radio.Button>
										<Radio.Button value="double-elimination">Double Elimination</Radio.Button>
										<Radio.Button value="round-robin" disabled>
											Round Robin
										</Radio.Button>
										<Radio.Button value="knockout" disabled>
											Knockout
										</Radio.Button>
										<Radio.Button value="multi-stage" disabled>
											Multi-Stage
										</Radio.Button>
									</Radio.Group> */}
                                    <Select
                                        showSearch
                                        name="tournament_type"
                                        placeholder="Select a tournament type"
                                        size="large"
                                        optionFilterProp="children"
                                        defaultValue="double_elimination"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {[
                                            {
                                                type: 'Double Elimination',
                                                value: 'double_elimination',
                                            },
                                            {
                                                type: 'Modified Double Elimination',
                                                value: 'modified_double_elimination',
                                            },
                                            {
                                                type: 'Single Elimination',
                                                value: 'single_elimination',
                                            },
                                            {
                                                type: 'Custom Template',
                                                value: 'custom',
                                            },
                                            {
                                                type: 'Chip Tournament',
                                                value: 'chip',
                                            },
                                            {
                                                type: 'Round Robin (Coming soon)',
                                                value: 'round_robin',
                                            },
                                            {
                                                type: 'Single Modified (Not yet supported)',
                                                value: 'single_modified',
                                            },
                                            {
                                                type: 'Knockout FFA (Not yet supported)',
                                                value: 'knockout',
                                            },
                                        ].map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                disabled={
                                                    item.value !== 'double_elimination' &&
                                                    item.value !== 'modified_double_elimination' &&
                                                    item.value !== 'single_elimination' &&
                                                    item.value !== 'custom' &&
                                                    item.value !== 'chip'
                                                }
                                            >
                                                {item.type}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>

                                {values && values.tournament_type === 'custom' && (
                                    <FormItem label="Custom Template" name="bracket_template_id" hasFeedback showValidateSuccess>
                                        <Query
                                            query={GET_TOURNAMENT_BRACKET_TEMPLATES}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{
                                                creator_id: props.authState.user.id,
                                            }}
                                        >
                                            {({ loading, error, data }) => {
                                                if (loading) return <div>Loading...</div>;
                                                if (error) return <div>Error: {error.message}</div>;

                                                return (
                                                    <Select
                                                        showSearch
                                                        // mode="multiple"
                                                        name="bracket_template_id"
                                                        placeholder="Select custom tournament bracket template"
                                                        size="large"
                                                        optionFilterProp="children"
                                                        // defaultValue={[ 'jack', 'lucy' ]}
                                                        filterOption={(input, option) => {
                                                            // console.log(option);
                                                            return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                        }}
                                                    >
                                                        {data.tournament_bracket_templates
                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((item, index) => (
                                                                <Option key={index} value={item.id}>
                                                                    <Text>{item.name}</Text>
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                );
                                            }}
                                        </Query>
                                    </FormItem>
                                )}

                                {values && values.tournament_type === 'round_robin' && (
                                    <React.Fragment>
                                        <FormItem label="Participants play each other" name="participant_times_played">
                                            <Radio.Group name="participant_times_played">
                                                <Radio value={1}>Once</Radio>
                                                <Radio value={2}>Twice</Radio>
                                                <Radio value={3}>Three times</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem label="Estimated number of players" name="max_players" required hasFeedback showValidateSuccess>
                                            <InputNumber
                                                name="max_players"
                                                min={0}
                                                placeholder="1"
                                                // value={score}
                                                // onChange={onChange1}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}
                                                // style={styles.inputSmall}
                                                // onPressEnter={onEnter}
                                                size="large"
                                                // disabled
                                                style={{ maxWidth: 200 }}
                                            />
                                        </FormItem>
                                        <FormItem label="Players in each group" name="players_per_group" hasFeedback showValidateSuccess>
                                            <InputNumber
                                                name="players_per_group"
                                                min={0}
                                                placeholder="1"
                                                // value={score}
                                                // onChange={onChange1}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}
                                                // style={styles.inputSmall}
                                                // onPressEnter={onEnter}
                                                size="large"
                                                // disabled
                                                style={{ maxWidth: 200 }}
                                            />
                                        </FormItem>
                                        <FormItem label="Players advancing from each group" name="players_advance_per_group" hasFeedback showValidateSuccess>
                                            <InputNumber
                                                name="players_advance_per_group"
                                                min={0}
                                                placeholder="1"
                                                // value={score}
                                                // onChange={onChange1}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}
                                                // style={styles.inputSmall}
                                                // onPressEnter={onEnter}
                                                size="large"
                                                // disabled
                                                style={{ maxWidth: 200 }}
                                            />
                                        </FormItem>
                                        <FormItem label="Players ranked by" name="players_ranked_by" hasFeedback showValidateSuccess>
                                            <Select
                                                showSearch
                                                name="players_ranked_by"
                                                placeholder="Select how players are ranked"
                                                size="large"
                                                optionFilterProp="children"
                                                defaultValue="match_wins"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'Match Wins',
                                                        value: 'match_wins',
                                                    },
                                                    {
                                                        type: 'Game / Set Wins',
                                                        value: 'game_set_wins',
                                                    },
                                                    {
                                                        type: 'Game / Set Win %',
                                                        value: 'game_set_win_percentage',
                                                    },
                                                    {
                                                        type: 'Game / Set Win Loss Difference',
                                                        value: 'game_set_win_loss',
                                                    },
                                                    {
                                                        type: 'Points Won',
                                                        value: 'points_won',
                                                    },
                                                    {
                                                        type: 'Point Difference',
                                                        value: 'point_difference',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                    </React.Fragment>
                                )}

                                {values && values.tournament_type === 'double_elimination' && (
                                    <FormItem
                                        name="consolidation_finals"
                                        label="True double elimination - Hot seat winner must be defeated twice to win the tournament."
                                    >
                                        <Radio.Group name="consolidation_finals">
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                )}

                                <FormItem label="Tournament Format" name="tournament_format">
                                    <Radio.Group name="tournament_format" defaultValue="standard" size="medium">
                                        <Radio.Button value="standard">Standard</Radio.Button>
                                        <Radio.Button value="fargo_rated">Fargo Rated</Radio.Button>
                                        <Radio.Button value="usapl_fargo_rated">USAPL Fargo Rated</Radio.Button>
                                        {/* <Radio.Button value="custom">Custom</Radio.Button> */}
                                    </Radio.Group>
                                    <Tooltip
                                        placement="right"
                                        title={
                                            <React.Fragment>
                                                {values && values.tournament_format === 'fargo_rated' ? (
                                                    <div>
                                                        Learn more about Fargo Hot, Medium and Mild tournament formats here -{' '}
                                                        <a
                                                            href="https://fargorate.com/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            FargoRate.com
                                                        </a>
                                                    </div>
                                                ) : values && values.tournament_format === 'usapl_fargo_rated' ? (
                                                    <div>
                                                        The USAPL Fargo Rated format uses points for races instead of games. The built in USAPL Fargo Rate
                                                        calculator will suggest a short race or long race based on two Fargo Ratings.
                                                    </div>
                                                ) : (
                                                    <div>
                                                        The standard tournament format allows you to set default winner and loser side races (loser races only
                                                        apply to double elimination tournaments).
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        }
                                    >
                                        <QuestionCircleFilled style={{ marginLeft: 10, fontSize: 15 }} />
                                    </Tooltip>
                                </FormItem>

                                {/* <FormItem label="Tournament Format" name="tournament_format">
                                                    <Select
                                                        // showSearch
                                                        name="tournament_format"
                                                        placeholder="Select a tournament format"
                                                        size="large"
                                                        optionFilterProp="children"
                                                        defaultValue="standard"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        suffixIcon={
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        {values && values.tournament_format === 'fargo_rated' ? (
                                                                            <div>
                                                                                Learn more about Fargo Hot, Medium and Mild tournament formats here -{' '}
                                                                                <a
                                                                                    href="https://fargorate.com/"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                >
                                                                                    FargoRate.com
                                                                                </a>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                The standard tournament format allows you to set default winner and loser side
                                                                                races (loser races only apply to double elimination tournaments).
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <QuestionCircleFilled style={{ color: 'rgba(0,0,0,.45)', fontSize: 15 }} />
                                                            </Tooltip>
                                                        }
                                                    >
                                                        {[
                                                            {
                                                                type: 'Standard',
                                                                value: 'standard',
                                                            },
                                                            {
                                                                type: 'Fargo Rated',
                                                                value: 'fargo_rated',
                                                            },
                                                        ].map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                {item.type}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </FormItem> */}

                                {values && values.tournament_format === 'standard' && (
                                    <React.Fragment>
                                        <FormItem
                                            label="Winners Race To"
                                            name="winners_race_to"
                                            extra="Override this value using the 'race_to' field when adding players."
                                            required
                                            hasFeedback
                                            showValidateSuccess
                                        >
                                            <Input
                                                name="winners_race_to"
                                                placeholder="Winner side match length"
                                                size="large"
                                                onChange={(e) => {
                                                    const size = e.target.value;
                                                    setFargoRaceSize(parseInt(size));
                                                }}
                                                suffix={
                                                    <Tooltip title="The default number of games on the winner side required to win a match.">
                                                        <QuestionCircleFilled />
                                                    </Tooltip>
                                                }
                                            />
                                        </FormItem>
                                        {values &&
                                            (values.tournament_type === 'double_elimination' || values.tournament_type === 'modified_double_elimination') && (
                                                <FormItem
                                                    label="Losers Race To"
                                                    name="losers_race_to"
                                                    extra="Override this value using the 'race_to' field when adding players."
                                                    hasFeedback
                                                    showValidateSuccess
                                                >
                                                    <Input
                                                        name="losers_race_to"
                                                        placeholder="Losers side match length"
                                                        size="large"
                                                        suffix={
                                                            <Tooltip title="The default number of games on the loser side required to win a match.">
                                                                <QuestionCircleFilled />
                                                            </Tooltip>
                                                        }
                                                    />
                                                </FormItem>
                                            )}
                                        <FormItem label="Finals Race To" name="finals_race_to" hasFeedback showValidateSuccess>
                                            <Input
                                                name="finals_race_to"
                                                placeholder="Final match length"
                                                size="large"
                                                suffix={
                                                    <Tooltip title="The number of games needed to win the final match if different than the winners race to number.">
                                                        <QuestionCircleFilled />
                                                    </Tooltip>
                                                }
                                            />
                                        </FormItem>
                                    </React.Fragment>
                                )}

                                {values && values.tournament_format === 'usapl_fargo_rated' && (
                                    <React.Fragment>
                                        <div style={{ marginTop: 10, paddingBottom: 10 }}>
                                            <div style={{ marginBottom: 10 }}>
                                                <Text style={{ fontSize: 15, fontWeight: 600 }}>USAPL Fargo Rate Handicaps</Text>
                                                <br />
                                                <Text>
                                                    Enter the Fargo Ratings for two players and select "Shorter Races" or "Longer Races" under Race Format to
                                                    preview the handicap match length used by USAPL scoring. The selected race format will be used to calculate
                                                    the race for each match in the bracket.
                                                </Text>
                                            </div>

                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={12} sm={4}>
                                                        <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 1 Fargo</Text>
                                                        <FormItem name="player_fargo">
                                                            <InputNumber
                                                                name="player_fargo"
                                                                min={1}
                                                                max={1000}
                                                                value={player1Fargo}
                                                                onChange={(value) => {
                                                                    if (value > 0) {
                                                                        setPlayer1Fargo(value);
                                                                    }
                                                                }}
                                                            />
                                                        </FormItem>
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 2 Fargo</Text>
                                                        <FormItem name="opponent_fargo">
                                                            <InputNumber
                                                                name="opponent_fargo"
                                                                min={1}
                                                                max={1000}
                                                                value={player2Fargo}
                                                                onChange={(value) => {
                                                                    if (value > 0) {
                                                                        setPlayer2Fargo(value);
                                                                    }
                                                                }}
                                                            />
                                                        </FormItem>
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <Text style={{ fontSize: 13, fontWeight: 600 }}>Rating difference</Text>
                                                        <div style={{ marginTop: 10 }}>
                                                            <Text>
                                                                {player1Fargo > player2Fargo
                                                                    ? player1Fargo - player2Fargo
                                                                    : player2Fargo > player1Fargo
                                                                    ? player2Fargo - player1Fargo
                                                                    : 'N/A'}{' '}
                                                                points
                                                            </Text>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={8}>
                                                    <Col xs={24}>
                                                        <FormItem name="usapl_race_format" label="Race Format">
                                                            <Radio.Group name="usapl_race_format" defaultValue="longer_races" size="medium">
                                                                <Radio.Button
                                                                    value="longer_races"
                                                                    onClick={() => {
                                                                        calculateRace(1.0, player1Fargo, player2Fargo);
                                                                    }}
                                                                >
                                                                    Longer Races
                                                                </Radio.Button>
                                                                <Radio.Button
                                                                    value="shorter_races"
                                                                    onClick={() => {
                                                                        calculateRace(0.75, player1Fargo, player2Fargo);
                                                                    }}
                                                                >
                                                                    Shorter Races
                                                                </Radio.Button>
                                                            </Radio.Group>
                                                            {/* <Button
                                                                            type="primary"
                                                                            ghost
                                                                            onClick={() => {
                                                                                calculateRace(1.0, player1Fargo, player2Fargo);
                                                                            }}
                                                                        >
                                                                            Longer Race
                                                                        </Button>
                                                                        <Text style={{ marginLeft: 10, marginRight: 10 }}>- or -</Text>
                                                                        <Button
                                                                            type="primary"
                                                                            ghost
                                                                            onClick={() => {
                                                                                calculateRace(0.75, player1Fargo, player2Fargo);
                                                                            }}
                                                                        >
                                                                            Shorter Race
                                                                        </Button> */}
                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                <Row gutter={8}>
                                                    <Col xs={24}>
                                                        <Text>
                                                            Player One Race To: <b>{player1RaceTo}</b>
                                                        </Text>
                                                        <br />
                                                        <Text>
                                                            Player Two Race To: <b>{player2RaceTo}</b>
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                {values && values.tournament_format === 'fargo_rated' && (
                                    <React.Fragment>
                                        <div style={{ marginTop: 10, paddingBottom: 10 }}>
                                            <div style={{ marginBottom: 10 }}>
                                                <Text style={{ fontSize: 15, fontWeight: 600 }}>Fargo Rate Match Configuration</Text>
                                                <br />
                                                <Text>
                                                    Determine the preferred race length (R2-R11) and handicap level (Hot, Medium, or Mild) for your Fargo Rated
                                                    tournament. Keep in mind when choosing a race length that the maximum amount of games in the race will be
                                                    roughly double the R number (e.g. R4 = 8 max games, R5 = 10 max games etc).
                                                </Text>
                                                <br />
                                                <br />
                                                <Text>
                                                    Hot means the handicaps will be as close to even as possible and Medium and Mild get progressively less. For
                                                    more information on handicapped Fargo Rated Tournaments, please watch this{' '}
                                                    <a
                                                        href="https://www.facebook.com/931961723522836/videos/1775349585850708"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Facebook video
                                                    </a>
                                                    .
                                                </Text>
                                            </div>

                                            <div>
                                                <Row gutter={8}>
                                                    <Col xs={12} sm={4}>
                                                        <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 1 Fargo</Text>
                                                        <FormItem name="player_fargo">
                                                            <InputNumber
                                                                name="player_fargo"
                                                                min={1}
                                                                max={1000}
                                                                value={player1Fargo}
                                                                onChange={(value) => {
                                                                    if (value > 0) {
                                                                        setPlayer1Fargo(value);
                                                                    }
                                                                }}
                                                            />
                                                        </FormItem>
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 2 Fargo</Text>
                                                        <FormItem name="opponent_fargo">
                                                            <InputNumber
                                                                name="opponent_fargo"
                                                                min={1}
                                                                max={1000}
                                                                value={player2Fargo}
                                                                onChange={(value) => {
                                                                    if (value > 0) {
                                                                        setPlayer2Fargo(value);
                                                                    }
                                                                }}
                                                            />
                                                        </FormItem>
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <Text style={{ fontSize: 13, fontWeight: 600 }}>Rating difference</Text>
                                                        <div style={{ marginTop: 10 }}>
                                                            <Text>
                                                                {player1Fargo > player2Fargo
                                                                    ? player1Fargo - player2Fargo
                                                                    : player2Fargo > player1Fargo
                                                                    ? player2Fargo - player1Fargo
                                                                    : 'N/A'}{' '}
                                                                points
                                                            </Text>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div>
                                                <FormItem
                                                    label={values.tournament_type === 'double_elimination' ? 'Winners Race Length' : 'Race Length'}
                                                    name="winners_race_to"
                                                >
                                                    <Radio.Group
                                                        name="winners_race_to"
                                                        size="large"
                                                        onChange={(e) => {
                                                            const size = e.target.value;
                                                            setFargoRaceSize(size);
                                                        }}
                                                    >
                                                        <Radio.Button value={2}>R2</Radio.Button>
                                                        <Radio.Button value={3}>R3</Radio.Button>
                                                        <Radio.Button value={4}>R4</Radio.Button>
                                                        <Radio.Button value={5}>R5</Radio.Button>
                                                        <Radio.Button value={6}>R6</Radio.Button>
                                                        <Radio.Button value={7}>R7</Radio.Button>
                                                        <Radio.Button value={9}>R9</Radio.Button>
                                                        <Radio.Button value={11}>R11</Radio.Button>
                                                    </Radio.Group>
                                                </FormItem>

                                                <FormItem
                                                    label={
                                                        <React.Fragment>
                                                            Winners Max Race{' '}
                                                            <Tooltip
                                                                placement="right"
                                                                title={<div>The maximum number of games a player can go to (optional).</div>}
                                                            >
                                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    }
                                                    name="winners_max_race"
                                                >
                                                    <Input name="winners_max_race" placeholder="0" size="large" />
                                                </FormItem>
                                            </div>

                                            {values && values.tournament_type === 'double_elimination' && (
                                                <div>
                                                    <FormItem label="Losers Race Length" name="losers_race_to">
                                                        <Radio.Group
                                                            name="losers_race_to"
                                                            size="large"
                                                            onChange={(e) => {
                                                                const size = e.target.value;
                                                                setFargoRaceSize(size);
                                                            }}
                                                        >
                                                            <Radio.Button value={2}>R2</Radio.Button>
                                                            <Radio.Button value={3}>R3</Radio.Button>
                                                            <Radio.Button value={4}>R4</Radio.Button>
                                                            <Radio.Button value={5}>R5</Radio.Button>
                                                            <Radio.Button value={6}>R6</Radio.Button>
                                                            <Radio.Button value={7}>R7</Radio.Button>
                                                            <Radio.Button value={9}>R9</Radio.Button>
                                                            <Radio.Button value={11}>R11</Radio.Button>
                                                        </Radio.Group>
                                                    </FormItem>

                                                    <FormItem
                                                        label={
                                                            <React.Fragment>
                                                                Losers Max Race{' '}
                                                                <Tooltip
                                                                    placement="right"
                                                                    title={<div>The maximum number of games a player can go to (optional).</div>}
                                                                >
                                                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        }
                                                        name="losers_max_race"
                                                    >
                                                        <Input name="losers_max_race" placeholder="0" size="large" />
                                                    </FormItem>
                                                </div>
                                            )}

                                            <div>
                                                <FormItem label="Fargo Handicap Format" name="handicap_format">
                                                    <Radio.Group name="handicap_format" defaultValue="fargo_hot_column" size="medium">
                                                        <Radio.Button value="fargo_hot_column">Hot</Radio.Button>
                                                        <Radio.Button value="fargo_medium_column">Medium</Radio.Button>
                                                        <Radio.Button value="fargo_mild_column">Mild</Radio.Button>
                                                    </Radio.Group>
                                                </FormItem>
                                            </div>

                                            <table
                                                width="100%"
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                }}
                                            >
                                                <tbody>
                                                    <tr style={{ display: 'flex' }}>
                                                        <td style={{ flex: 1, fontSize: 13, fontWeight: 600 }}>Hot</td>
                                                        <td style={{ flex: 1, fontSize: 13, fontWeight: 600 }}>Medium</td>
                                                        <td style={{ flex: 1, fontSize: 13, fontWeight: 600 }}>Mild</td>
                                                    </tr>

                                                    {fargoLoading === true ? (
                                                        <tr style={{ display: 'flex' }}>
                                                            <td style={{ flex: 1 }}>Loading...</td>
                                                            <td style={{ flex: 1 }}>Loading...</td>
                                                            <td style={{ flex: 1 }}>Loading...</td>
                                                        </tr>
                                                    ) : (
                                                        <tr style={{ display: 'flex' }}>
                                                            <td style={{ flex: 1 }}>
                                                                {fargoCharts &&
                                                                    fargoCharts.hot &&
                                                                    fargoCharts.hot.map((item, index) => {
                                                                        return (
                                                                            <div
                                                                                key={`hot-${index}`}
                                                                                style={
                                                                                    item.closest === true && values.handicap_format === 'fargo_hot_column'
                                                                                        ? {
                                                                                              backgroundColor:
                                                                                                  theme && theme.name === 'dark' ? '#083865' : '#e6f7ff',
                                                                                              fontWeight: 'bold',
                                                                                          }
                                                                                        : item.closest === true
                                                                                        ? { fontWeight: 'bold' }
                                                                                        : {}
                                                                                }
                                                                            >
                                                                                {parseInt(player1Fargo) > parseInt(player2Fargo) ? (
                                                                                    <Text>
                                                                                        {item.highPlayerRaceTo} - {item.lowPlayerRaceTo}
                                                                                    </Text>
                                                                                ) : (
                                                                                    <Text>
                                                                                        {item.lowPlayerRaceTo} - {item.highPlayerRaceTo}
                                                                                    </Text>
                                                                                )}
                                                                                {item.closest === true && ' (closest)'}
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </td>

                                                            <td style={{ flex: 1 }}>
                                                                {fargoCharts &&
                                                                    fargoCharts.medium &&
                                                                    fargoCharts.medium.map((item, index) => {
                                                                        return (
                                                                            <div
                                                                                key={`medium-${index}`}
                                                                                style={
                                                                                    item.closest === true && values.handicap_format === 'fargo_medium_column'
                                                                                        ? {
                                                                                              backgroundColor:
                                                                                                  theme && theme.name === 'dark' ? '#083865' : '#e6f7ff',
                                                                                              fontWeight: 'bold',
                                                                                          }
                                                                                        : item.closest === true
                                                                                        ? { fontWeight: 'bold' }
                                                                                        : {}
                                                                                }
                                                                            >
                                                                                {parseInt(player1Fargo) > parseInt(player2Fargo) ? (
                                                                                    <Text>
                                                                                        {item.highPlayerRaceTo} - {item.lowPlayerRaceTo}
                                                                                    </Text>
                                                                                ) : (
                                                                                    <Text>
                                                                                        {item.lowPlayerRaceTo} - {item.highPlayerRaceTo}
                                                                                    </Text>
                                                                                )}
                                                                                {item.closest === true && ' (closest)'}
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </td>
                                                            <td style={{ flex: 1 }}>
                                                                {fargoCharts &&
                                                                    fargoCharts.mild &&
                                                                    fargoCharts.mild.map((item, index) => {
                                                                        return (
                                                                            <div
                                                                                key={`mild-${index}`}
                                                                                style={
                                                                                    item.closest === true && values.handicap_format === 'fargo_mild_column'
                                                                                        ? {
                                                                                              backgroundColor:
                                                                                                  theme && theme.name === 'dark' ? '#083865' : '#e6f7ff',
                                                                                              fontWeight: 'bold',
                                                                                          }
                                                                                        : item.closest === true
                                                                                        ? { fontWeight: 'bold' }
                                                                                        : {}
                                                                                }
                                                                            >
                                                                                {parseInt(player1Fargo) > parseInt(player2Fargo) ? (
                                                                                    <Text>
                                                                                        {item.highPlayerRaceTo} - {item.lowPlayerRaceTo}
                                                                                    </Text>
                                                                                ) : (
                                                                                    <Text>
                                                                                        {item.lowPlayerRaceTo} - {item.highPlayerRaceTo}
                                                                                    </Text>
                                                                                )}
                                                                                {item.closest === true && ' (closest)'}
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>
                                )}

                                {/* <FormItem name="skill_level_races">
												<Checkbox name="skill_level_races">
													Use Player's skill level as the race
												</Checkbox>
											</FormItem> */}
                                {values && values.tournament_type !== 'chip' && values.tournament_type !== 'round_robin' && (
                                    <FormItem
                                        label="Estimated Bracket Size"
                                        name="max_players"
                                        extra="The final bracket size will be determined in the next step based on how many players have been added."
                                        required
                                        hasFeedback
                                        showValidateSuccess
                                    >
                                        <Select
                                            showSearch
                                            name="max_players"
                                            placeholder="Select the bracket size"
                                            size="large"
                                            optionFilterProp="children"
                                            // defaultValue="lucy"
                                            filterOption={(input, option) => {
                                                return option.props.children.toString().indexOf(input) >= 0;
                                            }}
                                        >
                                            {[4, 8, 16, 32, 64, 128, 256, 512, 1024].map((item, index) => (
                                                <Option key={index} value={item}>
                                                    {item}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                )}

                                {values && values.tournament_type === 'chip' && (
                                    <React.Fragment>
                                        <FormItem label="Estimated Number of Players" name="max_players" required hasFeedback showValidateSuccess>
                                            <InputNumber
                                                name="max_players"
                                                min={0}
                                                placeholder="1"
                                                // value={score}
                                                // onChange={onChange1}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}
                                                // style={styles.inputSmall}
                                                // onPressEnter={onEnter}
                                                size="large"
                                                // disabled
                                                style={{ maxWidth: 200 }}
                                            />
                                        </FormItem>
                                        <FormItem label="Players Per Table" name="players_per_table" hasFeedback showValidateSuccess>
                                            <Input
                                                name="players_per_table"
                                                placeholder="2"
                                                // value={score}
                                                // onChange={onChange1}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}
                                                // style={styles.inputSmall}
                                                // onPressEnter={onEnter}
                                                size="large"
                                                // disabled
                                                style={{ maxWidth: 200 }}
                                            />
                                        </FormItem>
                                        <FormItem label="Default Number of Chips Per Player" name="default_chip_amount" hasFeedback showValidateSuccess>
                                            <Input
                                                name="default_chip_amount"
                                                placeholder="1"
                                                // value={score}
                                                // onChange={onChange1}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}
                                                // style={styles.inputSmall}
                                                // onPressEnter={onEnter}
                                                size="large"
                                                // disabled
                                                style={{ maxWidth: 200 }}
                                            />
                                            <Button
                                                type="primary"
                                                ghost
                                                size="large"
                                                onClick={() => {
                                                    handleSetChipAmounts();
                                                }}
                                                style={{ marginLeft: 10 }}
                                            >
                                                Set custom chip amounts
                                            </Button>
                                        </FormItem>
                                        {/* <FormItem
                                                            name="redraw_every_round"
                                                            label={
                                                                <React.Fragment>
                                                                    Re-draw players every round{' '}
                                                                    <Tooltip
                                                                        title={<div>This option will re-draw the player order every round.</div>}
                                                                        placement="right"
                                                                    >
                                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <Radio.Group name="redraw_every_round">
                                                                <Radio value={true}>Yes</Radio>
                                                                <br />
                                                                <Radio value={false}>No</Radio>
                                                            </Radio.Group>
                                                        </FormItem> */}

                                        <FormItem
                                            name="autopilot_mode"
                                            label={
                                                <React.Fragment>
                                                    Autopilot mode{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                In chip tournaments, Autopilot mode will automatically assign the next player waiting to play in
                                                                the queue to a table waiting for an opponent.
                                                            </div>
                                                        }
                                                        placement="right"
                                                    >
                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        >
                                            <Radio.Group name="autopilot_mode">
                                                <Radio value={true}>On</Radio>
                                                <br />
                                                <Radio value={false}>Off</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </React.Fragment>
                                )}

                                {values && values.tournament_type !== 'chip' && (
                                    <FormItem name="draw_type" label="Bracket ordering">
                                        <Radio.Group name="draw_type" defaultValue="random" size="medium">
                                            <Radio.Button value="random">Random draw </Radio.Button>
                                            <Radio.Button value="seeded">Seeded draw</Radio.Button>
                                            <Radio.Button value="ordered">Set order</Radio.Button>
                                            {/* <Radio.Button value="custom">Custom</Radio.Button> */}
                                        </Radio.Group>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Select from a random draw, seeded draw (matching the highest seeds against the lowest seeds), or a set order
                                                    which allows you to specify the exact order the players will appear in the bracket.
                                                </div>
                                            }
                                        >
                                            <QuestionCircleFilled style={{ marginLeft: 10 }} />
                                        </Tooltip>
                                    </FormItem>
                                )}

                                <FormItem
                                    name="use_text_messaging"
                                    label={
                                        <React.Fragment>
                                            Use Text Messaging{' '}
                                            <Tooltip
                                                title={
                                                    <div>
                                                        The text messaging feature will automatically notify players when they are assigned to play a match.
                                                        There is also a live scoring link that is sent with each text so players can live score their own
                                                        matches from their mobile phone.
                                                    </div>
                                                }
                                                placement="right"
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                >
                                    <Radio.Group name="use_text_messaging">
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>
                                {/* <div style={{ marginTop: 5, marginBottom: 5, display: 'flex', alignItems: 'center' }}>
                                                    <Icon type="warning" style={{ color: 'orange' }} />
                                                    <Text type="secondary" style={{ marginLeft: 10 }}>
                                                        This feature is currently disabled until further notice. We are sorry for any inconvenience.
                                                    </Text>
                                                </div> */}

                                <FormItem name="is_public" label="Access">
                                    <Radio.Group name="is_public">
                                        <Radio value={true}>Public - Anyone can view this league and request to join.</Radio>
                                        <br />
                                        <Radio value={false}>Private - Only users who have been sent an invite can view or join this league.</Radio>
                                    </Radio.Group>
                                </FormItem>

                                {authState && authState.user && authState.user.role === 'admin' && (
                                    <FormItem name="is_featured" label="Featured">
                                        <Radio.Group name="is_featured">
                                            <Radio value={true}>Yes</Radio>
                                            <br />
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                )}

                                <FormItem
                                    name="save_as_template"
                                    label={
                                        <React.Fragment>
                                            Save Template{' '}
                                            <Tooltip
                                                title={<div>Save this tournament as a template which can be re-used for future tournaments.</div>}
                                                placement="right"
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                >
                                    <Checkbox name="save_as_template">Save tournament as template</Checkbox>
                                </FormItem>
                                {values && values.save_as_template === true && (
                                    <FormItem name="template_ame">
                                        <Input
                                            name="template_name"
                                            placeholder="Template name"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                )}

                                <Collapse style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Panel
                                        header="Extra Settings (optional)"
                                        key="1"
                                        extra={
                                            <Tooltip title="These settings are not required but can be useful.">
                                                <QuestionCircleFilled />
                                            </Tooltip>
                                        }
                                    >
                                        <FormItem name="rating_system" label="Rating System">
                                            <Select
                                                // showSearch
                                                name="rating_system"
                                                placeholder="Select a rating system"
                                                size="large"
                                                defaultValue="even"
                                            >
                                                {[
                                                    {
                                                        type: 'Fargo Rate',
                                                        value: 'fargo_rate',
                                                    },
                                                    {
                                                        type: 'APA',
                                                        value: 'apa',
                                                    },
                                                    {
                                                        type: 'ABCD',
                                                        value: 'abcd',
                                                    },
                                                    {
                                                        type: 'Local',
                                                        value: 'local',
                                                    },
                                                    {
                                                        type: 'In-House',
                                                        value: 'in_house',
                                                    },
                                                    {
                                                        type: 'No Handicap (Even race)',
                                                        value: 'even',
                                                    },
                                                    {
                                                        type: 'Florida Pool Tour',
                                                        value: 'florida_pool_tour',
                                                    },
                                                    {
                                                        type: `Danny K's`,
                                                        value: 'danny_ks',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                        <FormItem name="break_format" label="Break Format">
                                            <Radio.Group name="break_format" defaultValue="winner_break" size="medium">
                                                <Radio.Button value="winner_break">Winner Breaks</Radio.Button>
                                                <Radio.Button value="loser_break">Loser Breaks</Radio.Button>
                                                <Radio.Button value="alternate_break">Alternate Break</Radio.Button>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem name="rule_format" label="Rules">
                                            <Select
                                                // showSearch
                                                name="rule_format"
                                                placeholder="Select the rules to use"
                                                size="large"
                                                optionFilterProp="children"
                                                // defaultValue="lucy"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'WPA',
                                                        value: 'wpa',
                                                    },
                                                    {
                                                        type: 'BCA',
                                                        value: 'bca',
                                                    },
                                                    {
                                                        type: 'APA',
                                                        value: 'apa',
                                                    },
                                                    {
                                                        type: 'VNEA',
                                                        value: 'vnea',
                                                    },
                                                    {
                                                        type: 'Local',
                                                        value: 'local',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                        <FormItem name="entry_fee" label="Entry Fee">
                                            <Input name="entry_fee" placeholder="Cost of entry" size="large" />
                                        </FormItem>
                                        <FormItem name="added_money" label="Added Money">
                                            <Input name="added_money" placeholder="Money added to the prize pool" size="large" />
                                        </FormItem>
                                        <FormItem name="payout_type" label="Payout Structure">
                                            <Select
                                                showSearch
                                                name="payout_type"
                                                placeholder="Select how winners will be paid out"
                                                size="large"
                                                optionFilterProp="children"
                                                defaultValue="custom"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'Custom',
                                                        value: 'custom',
                                                    },
                                                    {
                                                        type: 'Winner Take All (1-4 players)',
                                                        value: '1-1',
                                                    },
                                                    {
                                                        type: 'Top 2 places (5-8 players)',
                                                        value: '1-2',
                                                    },
                                                    {
                                                        type: 'Top 3 places (9-16 players)',
                                                        value: '1-3',
                                                    },
                                                    {
                                                        type: 'Top 4 places (17-24 players)',
                                                        value: '1-4',
                                                    },
                                                    {
                                                        type: 'Top 6 places (25-31 players)',
                                                        value: '1-6',
                                                    },
                                                    {
                                                        type: 'Top 8 places (32-63 players)',
                                                        value: '1-8',
                                                    },
                                                    {
                                                        type: 'Top 12 places (64-80 players)',
                                                        value: '1-12',
                                                    },
                                                    {
                                                        type: 'Top 16 places (81-95 players)',
                                                        value: '1-16',
                                                    },
                                                    {
                                                        type: 'Top 24 places (96-128 players)',
                                                        value: '1-24',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                            {payouts && payouts.chosenPayouts && payouts.chosenPayouts.payouts && (
                                                <div style={{ marginTop: 10, padding: 10 }}>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Text style={{ fontSize: 15, fontWeight: 600 }}>Payout Preview</Text>
                                                    </div>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Text>
                                                            Total purse: <b>{numeral(payouts.totalPurse).format('$0,0.00')}</b> based on a{' '}
                                                            <b>${payouts.entryFee}</b> entry fee with <b>${payouts.addedMoney}</b> added money and{' '}
                                                            <b>{payouts.totalPlayers}</b> players.
                                                        </Text>
                                                    </div>
                                                    <table
                                                        width="100%"
                                                        className="info-table"
                                                        style={{
                                                            marginTop: 10,
                                                            marginBottom: 0,
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td>Place</td>
                                                                <td>Payout</td>
                                                                <td>Percent</td>
                                                            </tr>
                                                            {payouts.chosenPayouts.payouts.map((payouts, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{payouts.place}</td>
                                                                        <td>
                                                                            <Text>{numeral(payouts.payout).format('$0,0.[0]')}</Text>
                                                                        </td>
                                                                        <td>
                                                                            <Text>{payouts.percent}%</Text>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <Text>These payouts and percentages can be customized later.</Text>
                                                </div>
                                            )}
                                        </FormItem>

                                        <FormItem name="signup_cutoff_time" label="Sign Up Cutoff Time">
                                            <Select
                                                showSearch
                                                name="signup_cutoff_time"
                                                placeholder="Cutoff time before players are not allowed to sign up"
                                                size="large"
                                                optionFilterProp="children"
                                                defaultValue="start_time"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'At start time',
                                                        value: 'start_time',
                                                    },
                                                    {
                                                        type: '10 minutes before',
                                                        value: '10_min',
                                                    },
                                                    {
                                                        type: '15 minutes before',
                                                        value: '15_min',
                                                    },
                                                    {
                                                        type: '30 minutes before',
                                                        value: '30_min',
                                                    },
                                                    {
                                                        type: '1 hour before',
                                                        value: '1_hour',
                                                    },
                                                    {
                                                        type: '2 hours before',
                                                        value: '2_hours',
                                                    },
                                                    {
                                                        type: '6 hours before',
                                                        value: '6_hours',
                                                    },
                                                    {
                                                        type: '1 day before',
                                                        value: '1_day',
                                                    },
                                                    {
                                                        type: '2 days before',
                                                        value: '2_days',
                                                    },
                                                    {
                                                        type: 'None',
                                                        value: 'none',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                        <FormItem name="rsvp_allowed" label="Allow online registration">
                                            <Radio.Group name="rsvp_allowed">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem name="show_player_skill_levels" label="Show player skill levels in the bracket">
                                            <Radio.Group name="show_player_skill_levels">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem name="show_player_races" label="Show player races in the bracket">
                                            <Radio.Group name="show_player_races">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>

                                        <FormItem name="show_unconfirmed_players" label="Show unconfirmed players in public player list">
                                            <Radio.Group name="show_unconfirmed_players">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>

                                        {values && values.tournament_format === 'fargo_rated' && (
                                            <React.Fragment>
                                                <FormItem name="winners_race_to">
                                                    <Input name="winners_race_to" value={0} type="hidden" />
                                                </FormItem>
                                                <FormItem name="losers_race_to">
                                                    <Input name="losers_race_to" value={0} type="hidden" />
                                                </FormItem>
                                            </React.Fragment>
                                        )}

                                        {/* <FormItem
											wrapperCol={{
												xs: { span: 24, offset: 0 },
												sm: { span: 16, offset: 8 }
											}}
										> */}
                                    </Panel>
                                </Collapse>
                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    // loading={loading}
                                >
                                    Save and go to step 2
                                </SubmitButton>
                                <ResetButton size="large">Clear</ResetButton>
                                <div>
                                    <Text style={styles.error}>{errors.general}</Text>
                                </div>
                                <AddVenueModal
                                    {...props}
                                    modalVisible={venueModalVisible}
                                    onModalOk={handleVenueModalOk}
                                    onModalCancel={handleVenueModalCancel}
                                />
                                <AddEventModal
                                    {...props}
                                    modalVisible={eventModalVisible}
                                    onModalOk={handleEventModalOk}
                                    onModalCancel={handleEventModalCancel}
                                />
                                <ChipAmountModal
                                    {...props}
                                    tournament={tournament}
                                    tournamentID={tournament && tournament.id}
                                    modalVisible={chipModalVisible}
                                    onModalOk={handleChipModalOk}
                                    onModalCancel={handleChipModalCancel}
                                    onSave={handleSaveChips}
                                />
                                {/* <FormikDebug /> */}
                            </Form>
                        );
                    }}
                />
            </div>

            {/* <Mutation
                mutation={CREATE_TOURNAMENT_MUTATION}
                onCompleted={(data) => {
                    console.log(data);
                    const result = data.insert_tournaments.returning[0];

                    if (onCreated) {
                        onCreated(result);
                    } else {
                        props.history.push(`/tournament-builder/${result.slug}`);
                    }
                    // if (bracketProps.loading) return <p>Loading...</p>;
                    // if (bracketProps.error) return <p>Error :(</p>;
                }}
                awaitRefetchQueries
                notifyOnNetworkStatusChange
            >
                {(createTournament, { error }) => (
                    <Mutation
                        mutation={UPDATE_TOURNAMENT_MUTATION}
                        onCompleted={(data) => {
                            console.log(data);
                            message.success('Changes saved');
                            const result = data.update_tournaments.returning[0];

                            if (onCreated) {
                                onCreated(result);
                            } else {
                                props.history.push(`/tournament-builder/${result.slug}`);
                            }
                        }}
                        awaitRefetchQueries
                        notifyOnNetworkStatusChange
                    >
                        {(updateTournament, { loading, error }) => {
                            const formItemLayout = {
                                labelCol: {
                                    xs: { span: 24 },
                                    sm: { span: 8 },
                                },
                                wrapperCol: {
                                    xs: { span: 24 },
                                    sm: { span: 16 },
                                },
                            };

                            if (error) {
                                alert(error);
                            }
                            return <div>Test</div>;
                        }}
                    </Mutation>
                )}
            </Mutation> */}
        </div>
    );
}

export default withRouter(TBCreateTournamentForm);
