import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Divider, Icon, Typography, Avatar } from 'antd';
import { Form, FormItem, Input, InputNumber, Radio, Select, SubmitButton, ResetButton, AutoComplete, DatePicker } from 'formik-antd';
import * as Yup from 'yup';
import { isoCountries } from '../../utils/isoCountryCodes';
import { Formik, ErrorMessage } from 'formik';
import { CountryRegionData } from 'react-country-region-selector';
import CircleFlag from '../../components/CircleFlag';
import slugify from 'slugify';
import { Query, Mutation } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import { getGameType } from '../../utils';
import {
    GET_LEAGUE_QUERY,
    GET_LEAGUE_CHALLENGES_QUERY,
    GET_LEAGUE_CHALLENGE_QUERY,
    GET_GAME_TYPES_QUERY,
    GET_DIVISION_TYPES_QUERY,
    GET_LEAGUE_PLAYERS_QUERY,
    SEARCH_VENUES_QUERY,
    GET_LEAGUE_DIVISIONS_QUERY,
    GET_VENUE_TABLES_QUERY,
} from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddVenueModal from './AddVenueModal';
import { CREATE_LEAGUE_CHALLENGE, UPDATE_LEAGUE_CHALLENGE, DELETE_LEAGUE_CHALLENGE, UPDATE_MATCH_MUTATION } from './data/mutations';
import _ from 'lodash';
import moment from 'moment-timezone';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function AddChallengeModal(props) {
    const { authState, client, league, challenger, selectedChallengeId, userID, leagueID, divisionID, modalVisible, onModalOk, onModalCancel, theme } = props;
    const [loading, setLoading] = useState(false);
    const [players, setPlayers] = useState();
    const [tables, setTables] = useState();
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [venueDataSource, setVenueDataSource] = useState('');
    const [createLeagueChallenge] = useMutation(CREATE_LEAGUE_CHALLENGE);
    const [updateLeagueChallenge] = useMutation(UPDATE_LEAGUE_CHALLENGE);
    const [updateMatch] = useMutation(UPDATE_MATCH_MUTATION);
    // const [updateLeagueMatchPlayer] = useMutation(UPDATE_LEAGUE_CHALLENGE);
    // const [deleteLeagueMatchPlayer] = useMutation(DELETE_LEAGUE_CHALLENGE);
    const formRef = useRef();

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    const submitAdd = (data, actions) => {
        const {
            name,
            division_id,
            challenger_id,
            opponent_id,
            challenger_race_to,
            opponent_race_to,
            scoring_format,
            match_type,
            game_type,
            pool_table_id,
            venue_id,
            scheduled_time,
            notes,
        } = data;
        const table = tables.filter((table) => table.id === pool_table_id);
        const challenger = _.first(players.filter((player) => player.player_id === challenger_id));
        const opponent = _.first(players.filter((player) => player.player_id === opponent_id));
        const matchName = name ? name : challenger && opponent ? `${challenger.name} vs. ${opponent.name}` : '';
        const match_players = [challenger, opponent];

        let slug;
        if (matchName) {
            slug = slugify(`${matchName} ${moment(scheduled_time).format('MM-DD-YYYY')}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        setLoading(true);
        createLeagueChallenge({
            variables: {
                objects: [
                    {
                        name: matchName,
                        ...(slug && { slug: slug }),
                        ...(challenger_id && { challenger_id: challenger_id }),
                        ...(opponent_id && { opponent_id: opponent_id }),
                        league_id: leagueID,
                        division_id,
                        venue_id,
                        scheduled_time,
                        notes,
                        match: {
                            data: {
                                name: matchName,
                                ...(slug && { slug: slug }),
                                match_type,
                                game_type,
                                scoring_format,
                                venue_id,
                                league_id: leagueID,
                                division_id,
                                player_id: challenger.player_id,
                                player_name: challenger.name,
                                player_country: challenger.country,
                                player_fargo: challenger.fargo,
                                ...(challenger_race_to && { player_race_to: challenger_race_to }),
                                opponent_id: opponent.player_id,
                                opponent_name: opponent.name,
                                opponent_country: opponent.country,
                                opponent_fargo: opponent.fargo,
                                ...(opponent_race_to && { opponent_race_to: opponent_race_to }),
                                ...(pool_table_id && { pool_table_id: pool_table_id }),
                                ...(table && table.length && { table_name: table[0].label }),
                                ...(table && table.length && { table_size: table[0].size }),
                                scheduled_time,
                                match_players: {
                                    data: match_players.map((item) => {
                                        const player = players.filter((player) => player.player_id === item.player_id);
                                        return {
                                            league_id: leagueID,
                                            player_id: item.player_id,
                                            division_id: division_id,
                                            name: player && player[0].name,
                                            country: player && player[0].country,
                                            skill_level: player && player[0].skill_level && player[0].skill_level.toString(),
                                            race_to: player && player[0].race_to,
                                        };
                                    }),
                                },
                                managers: {
                                    data: [
                                        {
                                            user_id: authState && authState.user && authState.user.id,
                                            email: authState && authState.user && authState.user.email,
                                            match_slug: slug,
                                            invite_accepted: true,
                                            admin: true,
                                            creator: true,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_CHALLENGES_QUERY,
                    variables: { league_id: leagueID },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.success('Match successfully added');
                const added = res.data.insert_challenges.returning[0];
                onModalOk(added);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.info('There was an error', error);
                onModalOk();
            });
    };

    const submitUpdate = (data, match) => {
        console.log(data);
        const {
            name,
            division_id,
            challenger_id,
            opponent_id,
            challenger_race_to,
            opponent_race_to,
            scoring_format,
            match_type,
            game_type,
            pool_table_id,
            venue_id,
            scheduled_time,
            match_id,
            notes,
        } = data;
        const table = tables.filter((table) => table.id === pool_table_id);
        const challenger = _.first(players.filter((player) => player.player_id === challenger_id));
        const opponent = _.first(players.filter((player) => player.player_id === opponent_id));
        const matchName = name ? name : challenger && opponent ? `${challenger.name} vs. ${opponent.name}` : '';

        let slug;
        if (matchName) {
            slug = slugify(`${matchName} ${moment(scheduled_time).format('MM-DD-YYYY')}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        setLoading(true);
        updateLeagueChallenge({
            variables: {
                id: selectedChallengeId,
                changes: {
                    name: matchName,
                    ...(slug && { slug: slug }),
                    ...(challenger_id && { challenger_id: challenger_id }),
                    ...(opponent_id && { opponent_id: opponent_id }),
                    league_id: leagueID,
                    division_id,
                    venue_id,
                    scheduled_time,
                    notes,
                },
            },
        })
            .then((res) => {
                // setLoading(false);
                // message.success('Changes saved');
                // onModalOk();
                updateMatch({
                    variables: {
                        id: match_id,
                        changes: {
                            name: matchName,
                            ...(slug && { slug: slug }),
                            match_type,
                            game_type,
                            scoring_format,
                            venue_id,
                            league_id: leagueID,
                            division_id,
                            player_id: challenger.player_id,
                            player_name: challenger.name,
                            player_country: challenger.country,
                            player_fargo: challenger.fargo,
                            player_skill_level: challenger.skill_level || challenger.fargo,
                            ...(challenger_race_to && { player_race_to: challenger_race_to }),
                            opponent_id: opponent.player_id,
                            opponent_name: opponent.name,
                            opponent_country: opponent.country,
                            opponent_fargo: opponent.fargo,
                            opponent_skill_level: opponent.skill_level || opponent.fargo,
                            ...(opponent_race_to && { opponent_race_to: opponent_race_to }),
                            ...(pool_table_id && { pool_table_id: pool_table_id }),
                            ...(table && table.length && { table_name: table[0].label }),
                            ...(table && table.length && { table_size: table[0].size }),
                            scheduled_time,
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_LEAGUE_CHALLENGES_QUERY,
                            variables: { league_id: leagueID },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        setLoading(false);
                        message.success('Changes saved');
                        onModalOk();
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        message.info('There was an error', error);
                        onModalOk();
                    });
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        // division_id: Yup.number().required('Required'),
        match_type: Yup.string().required('Required'),
        game_type: Yup.string().required('Required'),
        // match_players: Yup.string().required('Required'),
        venue_id: Yup.number().required('Required'),
        scheduled_time: Yup.string().required('Required'),
    });

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function onSearchVenue(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelectVenue(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const renderForm = (challenge) => {
        const initialValues = selectedChallengeId
            ? {
                  ...challenge,
                  venue_name: challenge.venue && challenge.venue.name,
                  challenger_race_to: challenge.player_race_to,
                  opponent_race_to: challenge.opponent_race_to,
                  challenger_id: (challenger && challenger.player_id) || challenge.challenger_id,
              }
            : {
                  division_id: divisionID ? divisionID : undefined,
                  match_type: undefined,
                  game_type: undefined,
                  venue_id: undefined,
                  venue_name: undefined,
                  scoring_format: '10_POINT_SYSTEM',
                  challenger_id: challenger ? challenger.player_id : undefined,
                  challenger_race_to: '',
                  opponent_id: undefined,
                  opponent_race_to: '',
                  table_size: '8 Foot',
                  table_id: undefined,
                  scheduled_time: '',
                  notes: '',
              };

        return (
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    // console.log(data);
                    // const merged = {
                    // 	...data,
                    // 	...selected
                    // };
                    if (selectedChallengeId) {
                        submitUpdate(data, challenge);
                    } else {
                        submitAdd(data, actions);
                    }
                }}
                validationSchema={schema}
                enableReinitialize
                initialValues={initialValues}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Modal
                        title={selectedChallengeId ? 'Update Challenge' : 'Create Challenge'}
                        // getContainer={() => document.querySelector('.tables')}
                        visible={modalVisible}
                        onOk={onModalOk}
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        // transitionName="none"
                        maskTransitionName="none"
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                onClick={() => {
                                    formRef.current.handleSubmit();
                                }}
                            >
                                {selectedChallengeId ? (loading ? 'Updating...' : 'Update Challenge') : 'Create Challenge'}
                            </Button>,
                        ]}
                        bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                        destroyOnClose={true}
                    >
                        <Form
                            onSubmit={handleSubmit}
                            layout="vertical"
                            style={{ maxWidth: 600 }}
                            // onSubmit={(e) => {
                            // 	e.preventDefault();
                            // 	console.log(values);
                            // 	// handleSubmit(values);
                            // }}
                        >
                            <FormItem label="Name" name="name" autoFocus>
                                <Input
                                    name="name"
                                    placeholder="Name"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>
                            {league.has_division_support && (
                                <FormItem label="Division" name="division_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_LEAGUE_DIVISIONS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        variables={{
                                            leagueId: leagueID,
                                        }}
                                        notifyOnNetworkStatusChange={true}
                                    >
                                        {({ loading, error, data }) => {
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    name="division_id"
                                                    placeholder="Select division"
                                                    size="large"
                                                    loading={loading}
                                                    disabled={divisionID ? true : false}
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    optionFilterProp="children"
                                                    optionLabelProp="children"
                                                    filterOption={(inputValue, option) => {
                                                        return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data &&
                                                        data.league_divisions &&
                                                        data.league_divisions.map((item, index) => (
                                                            <Option key={index} name={item.name} value={item.id}>
                                                                <Avatar
                                                                    size={24}
                                                                    src={getGameType(item.game_types && item.game_types[0].game_type, theme)}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginRight: 8,
                                                                    }}
                                                                />
                                                                <Text>{item.name}</Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                            )}

                            <FormItem label="Match Type" name="match_type" required hasFeedback showValidateSuccess>
                                <Query query={GET_DIVISION_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                name="match_type"
                                                placeholder="Select a match type"
                                                size="large"
                                                loading={loading}
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.league_division_types &&
                                                    data.league_division_types.map((item, index) => (
                                                        <Option key={index} value={item.value}>
                                                            <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                        </Option>
                                                    ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Game Type" name="game_type" required hasFeedback showValidateSuccess>
                                <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                    {({ loading, error, data }) => {
                                        if (loading) return <div>Loading...</div>;
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                name="game_type"
                                                placeholder="Select game to be played"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.game_types &&
                                                    data.game_types
                                                        .sort((a, b) => a.value.localeCompare(b.value))
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                <Avatar
                                                                    size={24}
                                                                    src={getGameType(item.value, theme)}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginRight: 8,
                                                                    }}
                                                                />
                                                                <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                            </Option>
                                                        ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Scoring format" name="scoring_format" hasFeedback showValidateSuccess style={{ flex: 1 }}>
                                <Select name="scoring_format" defaultValue="10_POINT_SYSTEM" size="large">
                                    <Option value="10_POINT_SYSTEM">10 point system</Option>
                                    <Option value="RACE_TO">Race to number</Option>
                                    <Option value="BEST_OF">Best of number</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="Challenger" name="challenger_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_PLAYERS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange
                                    awaitRefetchQueries
                                    variables={{ league_id: leagueID }}
                                    onCompleted={(data) => {
                                        // console.log(data);
                                        setPlayers(data.league_players);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                // mode="multiple"
                                                name="challenger_id"
                                                loading={loading}
                                                placeholder="Select challenger"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {data &&
                                                    data.league_players &&
                                                    data.league_players
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((player, index) => {
                                                            return (
                                                                <Option key={index} name={player.name} value={player.player_id || player.id}>
                                                                    {player.avatarImg ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                size={24}
                                                                                src={player.avatarImg}
                                                                                style={{
                                                                                    display: 'inline-block',
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : player.country ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <CircleFlag country={player.country} left={0} />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    )}
                                                                </Option>
                                                            );
                                                        })}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Challenger race to" name="challenger_race_to" hasFeedback showValidateSuccess>
                                <InputNumber
                                    name="challenger_race_to"
                                    placeholder="0"
                                    min={0}
                                    // value={score}
                                    // onChange={onChange}
                                    // onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}
                                    size="large"
                                    // disabled
                                />
                            </FormItem>
                            <FormItem label="Opponent" name="opponent_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_PLAYERS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange
                                    awaitRefetchQueries
                                    variables={{ league_id: leagueID }}
                                    onCompleted={(data) => {
                                        // console.log(data);
                                        // setPlayers(data.league_players);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                // mode="multiple"
                                                name="opponent_id"
                                                loading={loading}
                                                placeholder="Select opponent"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {data &&
                                                    data.league_players &&
                                                    data.league_players
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((player, index) => {
                                                            return (
                                                                <Option key={index} name={player.name} value={player.player_id || player.id}>
                                                                    {player.avatarImg ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                size={24}
                                                                                src={player.avatarImg}
                                                                                style={{
                                                                                    display: 'inline-block',
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : player.country ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <CircleFlag country={player.country} left={0} />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    )}
                                                                </Option>
                                                            );
                                                        })}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Opponent race to" name="opponent_race_to" hasFeedback showValidateSuccess>
                                <InputNumber
                                    name="opponent_race_to"
                                    placeholder="0"
                                    min={0}
                                    // value={score}
                                    // onChange={onChange}
                                    // onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}
                                    size="large"
                                    // disabled
                                />
                            </FormItem>
                            <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AutoComplete
                                        name="venue_name"
                                        dataSource={venueDataSource}
                                        placeholder="Search for a venue"
                                        onSearch={onSearchVenue}
                                        onSelect={onSelectVenue}
                                        size="large"
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{
                                                        padding: '4px 8px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={handleAddVenue}
                                                >
                                                    <Icon type="plus" /> Add Venue
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {venueDataSource &&
                                            venueDataSource.map((item, index) => (
                                                <Option key={index} value={`${item.id}`}>
                                                    {item.name} - {item.city}, {item.region}
                                                </Option>
                                            ))}
                                    </AutoComplete>
                                    <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                        <Icon type="plus" /> Add Venue
                                    </Button>
                                </div>
                            </FormItem>
                            {values.venue_id && (
                                <FormItem label="Table" name="pool_table_id">
                                    <Query
                                        query={GET_VENUE_TABLES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            venueId: values.venue_id,
                                        }}
                                        onCompleted={(data) => {
                                            console.log(data);
                                            setTables(data.pool_tables);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="pool_table_id"
                                                    placeholder="Assign a table from the selected venue"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data.pool_tables
                                                        .sort((a, b) => {
                                                            return a.label.localeCompare(b.label, undefined, {
                                                                numeric: true,
                                                                sensitivity: 'base',
                                                            });
                                                        })
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.id}>
                                                                <Text>
                                                                    {item.label} - {item.size} {item.make} {item.model}
                                                                </Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                            )}
                            <FormItem label="Scheduled Date & Time" name="scheduled_time" required hasFeedback showValidateSuccess>
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    showNow
                                    showToday
                                    use12Hours
                                    name="scheduled_time"
                                    size="large"
                                    format="YYYY-MM-DD h:mm A z"
                                    // onChange={onDateChange}
                                    onOk={onOk}
                                />
                            </FormItem>
                            <FormItem label="Notes" name="notes" required hasFeedback showValidateSuccess>
                                <TextArea
                                    rows={4}
                                    name="notes"
                                    placeholder="Notes"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>
                            <AddVenueModal {...props} modalVisible={venueModalVisible} onModalOk={handleVenueModalOk} onModalCancel={handleVenueModalCancel} />
                        </Form>
                    </Modal>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            {selectedChallengeId ? (
                <Query
                    query={GET_LEAGUE_CHALLENGE_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedChallengeId, league_id: leagueID }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        const challenge = data && data.challenges && data.challenges.length > 0 ? data.challenges[0] : null;
                        const match = challenge && challenge.match;
                        const combined = {
                            ...challenge,
                            ...match,
                            match_id: match && match.id,
                        };

                        console.log('challenge', combined);

                        return <React.Fragment>{renderForm(combined)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddChallengeModal);
