import React, { useState, useEffect, useRef } from 'react';
import { message, Descriptions, Row, Tag, Badge, Tooltip, Modal, Avatar, Button, Empty, Icon, List, Skeleton, Col, Typography, Table } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import CircularLoader from '../../components/CircularLoader';
import Card, { Meta } from '../../components/Card';
import { getGameType, displayEnumType } from '../../utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query, Mutation, Subscription } from '@apollo/client/react/components';
import ZeroState from '../../components/ZeroState';
import TableScoreCard from '../digitalpool/tournaments/TableScoreCard';
import { GET_TOURNAMENT_TABLES_QUERY, GET_TABLE_BY_SLUG_QUERY } from './data/queries';
import { GET_TOURNAMENT_TABLE_SUBSCRIPTION } from './data/subscriptions';
import { DELETE_TABLE_MUTATION } from './data/mutations';
import AddTableModal from './AddTableModal';
import moment from 'moment';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

const { Title, Text } = Typography;
const { confirm } = Modal;

const styles = {};

function LiveTable(props) {
    const { tournament, matchData, table, subscribeToMore, subscribeToNewData } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <TableScoreCard
            title={`Match ${matchData && matchData.match_number}`}
            table={table}
            label={table && table.label}
            tournament={tournament}
            matchData={{
                ...matchData,
                race_to: matchData && matchData.race_to,
                start_time: matchData && matchData.start_time,
                end_time: matchData && matchData.start_time,
            }}
            status={matchData && matchData.status}
            gameType={tournament.game_type}
            hoverable={false}
            tableSize={breakpoint === 'mobile' ? 'small' : 'large'}
        />
    );
}

function TBTableDetail(props) {
    const { tournament, history, authState, client, theme } = props;
    const params = props.match.params;
    const tableSlug = params.table;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [deleteTable] = useMutation(DELETE_TABLE_MUTATION);
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [selectedTableId, setSelectedTableId] = useState();

    console.log(tournament);

    const managers = tournament && tournament.tournament_managers;
    var manager;
    var isManager = false;

    for (let i = 0; i < managers.length; i++) {
        manager = managers[i].manager;
        if (!manager) continue;
        if (manager.id === authState.user.id) {
            isManager = true;
            break;
        }
    }

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to delete ${record.name}?`,
            content: 'This will permanently remove the match and cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteTable({
                    variables: {
                        id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_TABLES_QUERY,
                            variables: {
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Table deleted');
                        props.history.push(`/tournament-builder/${tournament.slug}/tables`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    function handleTableModalOk(e) {
        console.log(e);
        setTableModalVisible(false);
    }

    function handleTableModalCancel(e) {
        setTableModalVisible(false);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={
                    <Text style={breakpoint === 'mobile' ? { fontSize: 18, color: '#fff', maxWidth: 300 } : { fontSize: 20, color: '#fff' }}>
                        {displayEnumType(tableSlug)}
                    </Text>
                }
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    letterSpacing: breakpoint === 'mobile' ? 0 : 6,
                }}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/tournament-builder/${tournament.slug}/tables`)}
                        type="default"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                style={styles.header}
                wrapButtons
            />

            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Query
                            query={GET_TABLE_BY_SLUG_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ tournament_slug: tournament.slug, table_slug: tableSlug }}
                            onCompleted={(data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data, subscribeToMore }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                if (data && !data.pool_tables) {
                                    return <div>Table not found.</div>;
                                }
                                if (data && data.pool_tables.length === 0) {
                                    return <div>Table not found.</div>;
                                }
                                const table = data.pool_tables && data.pool_tables[0];
                                const matchData = table.tournament_match_table && table.tournament_match_table[0];

                                console.log({
                                    table,
                                    matchData,
                                });

                                return (
                                    <Row gutter={24}>
                                        <Col xs={24} lg={8}>
                                            <Card
                                                title={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                            Table summary
                                                        </Title>

                                                        <div>
                                                            {((authState && authState.user && authState.user.role === 'admin') ||
                                                                (authState &&
                                                                    authState.user &&
                                                                    (authState.user.id === tournament.organizer_id ||
                                                                        authState.user.id === tournament.director_id))) && (
                                                                <Button
                                                                    type="ghost"
                                                                    onClick={() => {
                                                                        setSelectedTableId(table.id);
                                                                        setTableModalVisible(true);
                                                                    }}
                                                                >
                                                                    <Icon type="edit" />
                                                                </Button>
                                                            )}

                                                            {((authState && authState.user && authState.user.role === 'admin') ||
                                                                (authState &&
                                                                    authState.user &&
                                                                    (authState.user.id === tournament.organizer_id ||
                                                                        authState.user.id === tournament.director_id))) && (
                                                                <Button type="danger" ghost onClick={() => showConfirmDelete(table)}>
                                                                    <Icon type="delete" />
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                                // extra={
                                                //     <div>
                                                //         {matchData.status === 'IN_PROGRESS' && (
                                                //             <Tag color="red" size="large">
                                                //                 LIVE
                                                //             </Tag>
                                                //         )}
                                                //     </div>
                                                // }
                                                // cover={
                                                // 	<div
                                                // 		style={{
                                                // 			paddingTop: 16,
                                                // 			paddingBottom: 16,
                                                // 			paddingLeft: 24,
                                                // 			paddingRight: 10,
                                                // 			// background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                // 			borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                // 			display: 'flex',
                                                // 			alignItems: 'center',
                                                // 			justifyContent: 'space-between'
                                                // 		}}
                                                // 	>
                                                // 		<Title level={4} style={{ margin: 0, padding: 0 }}>
                                                // 			Match summary
                                                // 		</Title>
                                                // 		<div>
                                                // 			{matchData.status === 'IN_PROGRESS' ? (
                                                // 				<Tag color="red" size="large">
                                                // 					LIVE
                                                // 				</Tag>
                                                // 			) : (
                                                // 				<Tag size="large">{matchData.status}</Tag>
                                                // 			)}
                                                // 		</div>
                                                // 	</div>
                                                // }
                                                bodyStyle={{ padding: 0 }}
                                            >
                                                <div
                                                    style={{
                                                        padding: 20,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        backgroundColor: theme.name === 'dark' ? '#0b1a2c' : '#fafafa',
                                                    }}
                                                >
                                                    <Avatar
                                                        size={150}
                                                        src={getGameType(tournament.game_type, theme)}
                                                        style={{ height: '100%', width: '100%', maxWidth: 150 }}
                                                        // style={{ backgroundColor: '#1890ff' }}
                                                    />
                                                </div>
                                                <div style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                                                    <table width="100%" className="info-table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>
                                                                    <Text>{table.label || '-'}</Text>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Table ID</td>
                                                                <td>
                                                                    <Text>(ID: {table.id})</Text>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Live stream link</td>
                                                                <td>
                                                                    {table.livestream_url ? (
                                                                        <a
                                                                            href={table.livestream_url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                fontWeight: 700,
                                                                                color: '#f5212d',
                                                                                marginBottom: 5,
                                                                                fontSize: 10,
                                                                                textTransform: 'uppercase',
                                                                                letterSpacing: 2,
                                                                            }}
                                                                        >
                                                                            <Icon type="play-circle" theme="filled" style={{ marginRight: 2 }} /> Livestream
                                                                        </a>
                                                                    ) : (
                                                                        <Text>-</Text>
                                                                    )}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Type</td>
                                                                <td>
                                                                    <Text>{table.type || '-'}</Text>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Table Size</td>
                                                                <td>
                                                                    <Text>{table.size || '-'}</Text>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {((authState && authState.user && authState.user.role === 'admin') ||
                                                    (authState &&
                                                        authState.user &&
                                                        authState.user.id === tournament.organizer_id &&
                                                        authState.user.id === tournament.director_id) ||
                                                    isManager) && (
                                                    <div
                                                        style={{
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            paddingBottom: 20,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <Link
                                                                to={`/tournaments/${tournament.slug}/tables/${table.slug}/scoring-mobile`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Button
                                                                    type="ghost"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: 10,
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Mobile Scoring
                                                                </Button>
                                                            </Link>

                                                            <Link
                                                                to={`/tournaments/${tournament.slug}/tables/${table.slug}/scoring`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Button
                                                                    type="ghost"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: 10,
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Tablet Scoring
                                                                </Button>
                                                            </Link>

                                                            <Link
                                                                to={`/tournaments/${tournament.slug}/tables/${table.slug}/shotclock`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Button
                                                                    type="ghost"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: 10,
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Shot Clock
                                                                </Button>
                                                            </Link>

                                                            <Link
                                                                to={`/tournaments/${tournament.slug}/tables/${table.slug}/tvdisplay`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Button
                                                                    type="ghost"
                                                                    size="large"
                                                                    style={{
                                                                        marginTop: 10,
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    TV Display
                                                                </Button>
                                                            </Link>

                                                            {tournament &&
                                                            tournament.slug &&
                                                            (tournament.slug.includes('apf') || tournament.slug.includes('asian')) ? (
                                                                <Link
                                                                    to={`/tournaments/${tournament.slug}/overlay?table_id=${table.id}&template=apf`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <Button
                                                                        type="ghost"
                                                                        size="large"
                                                                        style={{
                                                                            marginTop: 10,
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        Live Scoring Overlay
                                                                    </Button>
                                                                </Link>
                                                            ) : (
                                                                <Link
                                                                    to={`/tournaments/${tournament.slug}/overlay?table_id=${table.id}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <Button
                                                                        type="ghost"
                                                                        size="large"
                                                                        style={{
                                                                            marginTop: 10,
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        Live Scoring Overlay
                                                                    </Button>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Card>
                                            {/* <br />

                                            

                                            {matchData.event && (
                                                <React.Fragment>
                                                    <Card title="Event" bodyStyle={{ padding: 0 }}>
                                                        <List
                                                            className="hover-list"
                                                            size="small"
                                                            loading={loading}
                                                            itemLayout="horizontal"
                                                            dataSource={[matchData.event]}
                                                            renderItem={(item) => (
                                                                <List.Item
                                                                    // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                    style={{ padding: '10px 16px' }}
                                                                    onClick={() => history.push(`/events/${item.slug}`)}
                                                                >
                                                                    <Skeleton avatar title={false} loading={loading} active>
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <div>
                                                                                    <Icon
                                                                                        type="calendar"
                                                                                        style={{
                                                                                            color: '#1890ff',
                                                                                            fontSize: 30,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            title={
                                                                                <Text
                                                                                    style={{
                                                                                        fontSize: 16,
                                                                                    }}
                                                                                >
                                                                                    {item.name}
                                                                                </Text>
                                                                            }
                                                                            description={<div>{item.description}</div>}
                                                                        />
                                                                    </Skeleton>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </Card>
                                                    <br />
                                                </React.Fragment>
                                            )} */}
                                        </Col>
                                        <Col xs={24} lg={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <Row justify="center">
                                                <Col xs={24}>
                                                    <Card title="Live Scores" bodyStyle={{ padding: 0 }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                padding: 30,
                                                            }}
                                                        >
                                                            {matchData ? (
                                                                <div
                                                                    style={{
                                                                        maxWidth: 600,
                                                                    }}
                                                                >
                                                                    <LiveTable
                                                                        table={table}
                                                                        tournament={tournament}
                                                                        matchData={matchData}
                                                                        subscribeToMore={subscribeToMore}
                                                                        subscribeToNewData={() => {
                                                                            subscribeToMore({
                                                                                document: GET_TOURNAMENT_TABLE_SUBSCRIPTION,
                                                                                variables: { tournament_slug: tournament.slug, table_slug: table.slug },
                                                                                updateQuery: (prev, { subscriptionData }) => {
                                                                                    if (!subscriptionData.data) return prev;
                                                                                    const newTable = subscriptionData.data.pool_tables;
                                                                                    let updated;
                                                                                    if (prev) {
                                                                                        updated = Object.assign({}, prev, {
                                                                                            pool_tables: [...newTable],
                                                                                        });
                                                                                    } else {
                                                                                        updated = Object.assign({}, prev, {
                                                                                            pool_tables: [...newTable],
                                                                                        });
                                                                                    }
                                                                                    return updated;
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <ZeroState
                                                                    message="No matches in progress"
                                                                    messageStyle={{ fontSize: 16 }}
                                                                    style={{ margin: 10 }}
                                                                    theme={theme}
                                                                />
                                                            )}
                                                        </div>
                                                    </Card>
                                                </Col>
                                                <Col xs={24}>
                                                    <Card title="Table matches" bodyStyle={{ padding: 0 }}>
                                                        {tournament && tournament.pool_tables && tournament.pool_tables.length > 0 ? (
                                                            <List
                                                                size="small"
                                                                loading={false}
                                                                itemLayout="horizontal"
                                                                dataSource={
                                                                    tournament &&
                                                                    tournament.pool_tables &&
                                                                    tournament.pool_tables
                                                                        .filter((item) => {
                                                                            return item.id === table.id;
                                                                        })
                                                                        .map((table, index) => {
                                                                            return {
                                                                                matches: table.tournament_match_table,
                                                                            };
                                                                        })
                                                                }
                                                                header={
                                                                    <div
                                                                        style={{
                                                                            padding: '0px 20px',
                                                                            margin: 0,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <Text style={{ fontWeight: 700, width: 50 }}>Match</Text>
                                                                            <Text style={{ fontWeight: 700, marginLeft: 10 }}>Players</Text>
                                                                        </div>
                                                                        <Text style={{ fontWeight: 700 }}>Score</Text>
                                                                    </div>
                                                                }
                                                                renderItem={(table) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            {table &&
                                                                                table.matches &&
                                                                                table.matches.map((match, index) => (
                                                                                    <List.Item
                                                                                        key={index}
                                                                                        style={{
                                                                                            padding: '10px 20px',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'space-between',
                                                                                        }}
                                                                                    >
                                                                                        <Text style={{ width: 60 }}>{match.match_number}</Text>
                                                                                        <div style={{ textAlign: 'left', width: '100%' }}>
                                                                                            <Text
                                                                                                style={{
                                                                                                    marginLeft: 0,
                                                                                                    width: '100%',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                }}
                                                                                            >
                                                                                                {match.challenger1_score > match.challenger2_score ? (
                                                                                                    <Badge
                                                                                                        count="W"
                                                                                                        style={{
                                                                                                            backgroundColor: '#52c41a',
                                                                                                            marginRight: 5,
                                                                                                        }}
                                                                                                    />
                                                                                                ) : match.challenger1_score < match.challenger2_score ? (
                                                                                                    <Badge count="L" style={{ marginRight: 5 }} />
                                                                                                ) : null}
                                                                                                {match.challenger1_name}{' '}
                                                                                                {match.challenger1_race_to
                                                                                                    ? `(${match.challenger1_race_to})`
                                                                                                    : null}
                                                                                            </Text>
                                                                                            {' vs. '}
                                                                                            <Text
                                                                                                style={{
                                                                                                    marginLeft: 0,
                                                                                                    width: '100%',
                                                                                                    whiteSpace: 'nowrap',
                                                                                                }}
                                                                                            >
                                                                                                {match.challenger2_score > match.challenger1_score ? (
                                                                                                    <Badge
                                                                                                        count="W"
                                                                                                        style={{
                                                                                                            backgroundColor: '#52c41a',
                                                                                                            marginRight: 5,
                                                                                                        }}
                                                                                                    />
                                                                                                ) : match.challenger2_score < match.challenger1_score ? (
                                                                                                    <Badge count="L" style={{ marginRight: 5 }} />
                                                                                                ) : null}
                                                                                                {match.challenger2_name}{' '}
                                                                                                {match.challenger2_race_to
                                                                                                    ? `(${match.challenger2_race_to})`
                                                                                                    : null}
                                                                                            </Text>
                                                                                        </div>

                                                                                        <Text style={{ whiteSpace: 'nowrap', width: 40 }}>
                                                                                            {match.challenger1_score}-{match.challenger2_score}
                                                                                        </Text>
                                                                                    </List.Item>
                                                                                ))}
                                                                        </React.Fragment>
                                                                    );
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    padding: 30,
                                                                }}
                                                            >
                                                                <ZeroState
                                                                    message="No matches have been played on this table yet"
                                                                    messageStyle={{ fontSize: 16 }}
                                                                    style={{ margin: 10, textAlign: 'center' }}
                                                                    theme={theme}
                                                                />
                                                            </div>
                                                        )}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                );
                            }}
                        </Query>
                        <AddTableModal
                            {...props}
                            tournament={tournament}
                            selectedTableId={selectedTableId}
                            tableModalVisible={tableModalVisible}
                            onTableModalOk={handleTableModalOk}
                            onTableModalCancel={handleTableModalCancel}
                        />
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(TBTableDetail);
