import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Button, Icon, Modal } from 'antd';
import { Query } from '@apollo/client/react/components';
// import { GET_TOURNAMENT_MANAGERS, CHECK_TOURNAMENT_MANAGERS_INVITE_USER } from './data/queries';
// import { DELETE_TOURNAMENT_MANAGER, DELETE_TOURNAMENT_MANAGER_INVITE_KEY } from './data/mutations';
import { reduce } from 'highcharts';
import CircularLoader from '../../../components/CircularLoader';
import { GET_MATCH_ADMINS, GET_ADMIN_INVITE_KEY } from './data/queries';
import { DELETE_MATCH_MANAGERS_INVITE_KEYS, DELETE_MATCH_MANAGERS, DELETE_MATCH_MANAGERS_BY_EMAIL } from './data/mutations';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const { confirm } = Modal;

function AdminMatchTable(props) {
    const { matchId, userId, adminId, client } = props;
    const [tableDataSource, setTableDataSource] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            key: 'name',
            width: 200,
        },
        {
            title: 'Accepted',
            dataIndex: 'accepted_invite',
            key: 'accepted_invite',
            render: (text, record) => (
                <React.Fragment>
                    <span style={record.accepted_invite === 'Yes' ? { color: 'green' } : record.accepted_invite === 'No' ? { color: 'red' } : {}}>
                        {text || '-'}
                    </span>
                </React.Fragment>
            ),
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            render: (text, record) => <React.Fragment>{text === true ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : ''}</React.Fragment>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return !record.creator ? (
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Delete">
                            <Button
                                type="ghost"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    confirm({
                                        title: `Are you sure you want to delete this admin?`,
                                        centered: true,
                                        transitionName: 'fade',
                                        maskTransitionName: 'none',
                                        async onOk() {
                                            const checkInviteKeyRes = await client.query({
                                                query: GET_ADMIN_INVITE_KEY,
                                                variables: {
                                                    email: record.email,
                                                    match_id: matchId,
                                                },
                                            });

                                            if (
                                                checkInviteKeyRes &&
                                                checkInviteKeyRes.data &&
                                                checkInviteKeyRes.data.match_managers_invite_keys &&
                                                checkInviteKeyRes.data.match_managers_invite_keys[0] &&
                                                checkInviteKeyRes.data.match_managers_invite_keys[0].id
                                            ) {
                                                await client.mutate({
                                                    mutation: DELETE_MATCH_MANAGERS_INVITE_KEYS,
                                                    variables: {
                                                        email: record.email,
                                                        match_id: matchId,
                                                    },
                                                });
                                            }

                                            await client.mutate({
                                                mutation: DELETE_MATCH_MANAGERS_BY_EMAIL,
                                                variables: {
                                                    email: record.email,
                                                    match_id: matchId,
                                                },
                                                notifyOnNetworkStatusChange: true,
                                                awaitRefetchQueries: true,
                                                refetchQueries: [
                                                    {
                                                        query: GET_MATCH_ADMINS,
                                                        variables: { match_id: matchId },
                                                    },
                                                ],
                                            });
                                        },
                                        onCancel() {},
                                        cancelText: 'No',
                                        okText: 'Yes',
                                    });
                                }}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip>
                    </span>
                ) : (
                    <div></div>
                );
            },
        },
    ];

    return (
        <Query
            query={GET_MATCH_ADMINS}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            // awaitRefetchQueries
            variables={{
                match_id: matchId,
            }}
            onCompleted={(data) => {
                if (data) {
                    let matchManagers = data && data.match_managers;
                    let matchManagersArr = [];
                    let manager;

                    for (let i = 0; i < matchManagers.length; i++) {
                        manager = matchManagers[i];

                        console.log('manager', manager);
                        const matchManagersObj = {
                            id: manager.id,
                            email: manager.email,
                            user_id: manager && manager.user !== null ? manager.user.id : '',
                            name: manager.user
                                ? manager.user.first_name
                                    ? `${manager.user.first_name} ${manager.user.last_name}`
                                    : manager.user.display_name
                                : manager.email,
                            accepted_invite: manager.invite_accepted ? 'Yes' : 'No',
                            creator: manager.creator ? true : false,
                            key: i,
                            creator_id: manager.match.creator_id,
                            admin: manager.admin,
                        };
                        matchManagersArr.push(matchManagersObj);
                    }

                    setTableDataSource(matchManagersArr);
                }
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) {
                    console.log('error', error);
                    return null;
                }

                return <Table columns={columns} dataSource={tableDataSource} pagination={false} tableLayout="auto" scroll={{ x: 400 }} size="default" />;
            }}
        </Query>
    );
}

export default AdminMatchTable;
