import { gql } from '@apollo/client';

export const LEAGUE_FIELDS = gql`
    fragment leagueFields2 on leagues {
        id
        name
        slug
        league_type
        short_description
        long_description
        avatar
        logo
        is_public
        has_team_support
        has_division_support
        has_challenge_support
        organization_id
        organization {
            id
            name
            slug
            description
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            fax_number
            email_address
            website
            owner_id
            created_at
            updated_at
        }
        owner_id
        owner {
            id
            first_name
            last_name
            slug
            email
        }
        operator_id
        operator {
            id
            first_name
            last_name
            slug
            email
        }
        address1
        address2
        city
        county
        country
        region
        postal_code
        phone_number
        fax_number
        email_address
        website
        social_facebook
        social_instagram
        social_twitter
        match_notifications_in_app
        match_notifications_sms
        league_divisions_aggregate {
            aggregate {
                count
            }
        }
        league_teams_aggregate {
            aggregate {
                count
            }
        }
        league_players_aggregate {
            aggregate {
                count
            }
        }
        matches_aggregate {
            aggregate {
                count
            }
        }
        league_schedules_aggregate {
            aggregate {
                count
            }
        }
        in_progress_matches: matches_aggregate(where: { status: { _eq: IN_PROGRESS } }) {
            aggregate {
                count
            }
        }
        completed_matches: matches_aggregate(where: { status: { _eq: COMPLETED } }) {
            aggregate {
                count
            }
        }
        not_started_matches: matches_aggregate(where: { status: { _eq: NOT_STARTED } }) {
            aggregate {
                count
            }
        }
        league_venues_aggregate {
            aggregate {
                count
            }
        }
        created_at
        updated_at
        deleted_at
    }
`;

export const LEAGUE_DIVISION_FIELDS = gql`
    fragment leagueDivisionFields on league_divisions {
        id
        name
        slug
        description
        division_type
        game_types
        best_of_num
        race_to_num
        scoring_format
        winner_determined_by
        # division_game_types {
        # 	game_type
        # }
        session_id
        num_weeks
        start_date
        end_date
        is_public
        is_active
        is_handicapped
        league_schedules_aggregate {
            aggregate {
                count
            }
        }
        league_player_divisions_aggregate {
            aggregate {
                count
            }
            nodes {
                id
            }
        }
        league_matches_aggregate {
            aggregate {
                count
            }
        }
        # league_player_divisions {
        #	 id
        # }
        league_team_divisions_aggregate {
            aggregate {
                count
            }
        }
        league_team_divisions {
            id
            league_team {
                id
                name
                slug
            }
        }
    }
`;

export const LEAGUE_MATCH_FIELDS = gql`
    fragment leagueMatchFields on league_matches {
        id
        name
        slug
        league_id
        match_id
        league_team_id
        match_type
        match_number
        scoring_format
        winner_determined_by
        best_of_num
        race_to_num
        is_vizion_member
        has_paid
        payment_method
        payment_amount
        home_team_id
        home_team_score
        home_team_points
        home_team_is_winner
        visitor_team_id
        visitor_team_score
        visitor_team_points
        visitor_team_is_winner
        challenger1_id
        challenger1_name
        challenger1_country
        challenger1_score
        challenger1_points
        challenger1_skill_level
        challenger1_race_to
        challenger1_is_winner
        challenger1_frames
        challenger2_id
        challenger2_name
        challenger2_country
        challenger2_score
        challenger2_points
        challenger2_skill_level
        challenger2_race_to
        challenger2_is_winner
        challenger2_frames
        ranking
        game_type
        table_id
        table_name
        table_number
        table_size
        status
        progress
        scheduled_time
        start_time
        end_time
        duration
        date_challenged
        date_accepted
        date_played
        days_left_to_acknowledge
        days_left_to_play_from_acknowledge
        days_left_to_play_from_callout
        created_at
        updated_at
        venue_id
        venue {
            id
            name
            slug
            address1
            address2
            city
            region
            country
            postal_code
        }
        division_id
        division {
            id
            name
            slug
        }
        league_match_teams {
            id
            league_team_id
            name
        }
        league_match_players {
            id
            name
            country
            league_player_id
            skill_level
            race_to
        }
        home_team {
            id
            name
            slug
        }
        visitor_team {
            id
            name
            slug
        }
        is_team_match
        is_singles_match
    }
`;
