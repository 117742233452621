import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Divider } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import { CountryRegionData } from 'react-country-region-selector';
import { CREATE_VENUE_MUTATION, UPDATE_VENUE_MUTATION } from '../data/mutations';
import { GET_VENUE_TYPES_QUERY } from '../data/queries';
import Flag from '../../../components/Flag';
import slugify from 'slugify';
import _ from 'lodash';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function LMCreateEditVenueForm(props) {
    const [loading, setLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    const schema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        description: Yup.string().required('Required'),
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        region: Yup.string().required('Region is required'),
        postal_code: Yup.string().required('Postal Code is required'),
        email_address: Yup.string().email('Email is invalid'),
        website: Yup.string().url('Website must be a valid url e.g. http://www.example.com)').nullable(),
        phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
        social_facebook: Yup.string().nullable(),
        social_instagram: Yup.string().nullable(),
        social_twitter: Yup.string().nullable(),
    });

    function handleSubmit(values, createVenue, updateVenue) {
        console.log(values);
        const {
            name,
            description,
            venue_type,
            address1,
            address2,
            city,
            country,
            region,
            postal_code,
            phone_number,
            email_address,
            website,
            social_facebook,
            social_twitter,
            social_instagram,
            operating_hours,
            is_public,
            is_claimed,
            claimed_by_id,
        } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (props.venue) {
            updateVenue({
                variables: {
                    id: props.venue.id,
                    changes: {
                        name,
                        description,
                        venue_type,
                        address1,
                        address2,
                        city,
                        country,
                        region,
                        postal_code,
                        phone_number,
                        email_address,
                        website,
                        social_facebook,
                        social_twitter,
                        social_instagram,
                        operating_hours,
                        owner_id: props.authState.user.id,
                        is_public: is_public === 'public' ? true : false,
                    },
                },
            });
        } else {
            createVenue({
                variables: {
                    objects: [
                        {
                            name,
                            description,
                            slug: slug,
                            venue_type,
                            address1,
                            address2,
                            city,
                            country,
                            region,
                            postal_code,
                            phone_number,
                            email_address,
                            website,
                            social_facebook,
                            social_twitter,
                            social_instagram,
                            operating_hours,
                            owner_id: props.authState.user.id,
                            is_public: is_public === 'public' ? true : false,
                            league_venues: {
                                data: {
                                    league_id: props.league.id,
                                },
                            },
                            // is_claimed: is_claimed,
                            // claimed_by_id: claimed_by_id
                        },
                    ],
                },
            });
        }
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    return (
        <Mutation
            mutation={CREATE_VENUE_MUTATION}
            onCompleted={(data) => {
                console.log(data);
                setLoading(false);
                message.success('Venue successfully created');
                const result = data.insert_venues.returning[0];
                props.history.push(`/league-manager/${props.league.slug}/venues/${result.slug}`);
            }}
            awaitRefetchQueries
        >
            {(createVenue, { error }) => (
                <Mutation
                    mutation={UPDATE_VENUE_MUTATION}
                    onCompleted={(data) => {
                        console.log(data);
                        setLoading(false);
                        message.success('Changes saved');
                        const result = data.update_venues.returning[0];
                        props.history.push(`/league-manager/${props.league.slug}/venues/${result.slug}`);
                    }}
                    // awaitRefetchQueries
                >
                    {(updateVenue, { loading, error }) => {
                        if (error) {
                            alert('Something went wrong');
                        }
                        return (
                            <Formik
                                initialValues={
                                    props.venue
                                        ? {
                                              ...props.venue,
                                              venue_types: props.venue.venue_types ? props.venue.venue_types.map((item) => item.venue_type) : [],
                                              is_public: props.venue.is_public === true ? 'public' : 'private',
                                          }
                                        : {
                                              name: '',
                                              description: '',
                                              venue_type: undefined,
                                              address1: '',
                                              address2: '',
                                              city: '',
                                              country: undefined,
                                              region: undefined,
                                              postal_code: '',
                                              phone_number: '',
                                              email_address: '',
                                              website: '',
                                              operating_hours: '',
                                              social_facebook: '',
                                              social_twitter: '',
                                              social_instagram: '',
                                              is_public: 'public',
                                              is_claimed: false,
                                              owner_id: '',
                                              claimed_by_id: '',
                                          }
                                }
                                validationSchema={schema}
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    actions.resetForm();
                                    handleSubmit(values, createVenue, updateVenue);
                                }}
                                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                    <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal" style={{ maxWidth: 600 }}>
                                        <FormItem
                                            name="name"
                                            label="Venue Name"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="name" placeholder="Name" size="large" />
                                            <ErrorMessage name="name" component="div" className="ant-form-explain" />
                                        </FormItem>

                                        <FormItem name="venue_type" label="Venue Type" required={true}>
                                            <Query query={GET_VENUE_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                                {({ loading, error, data }) => {
                                                    if (loading) return <div>Loading...</div>;
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    return (
                                                        <Select
                                                            showSearch
                                                            name="venue_type"
                                                            placeholder="Select a venue type"
                                                            size="large"
                                                            optionFilterProp="children"
                                                            // defaultValue="lucy"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {data.venue_types.map((item, index) => (
                                                                <Option key={index} value={item.value}>
                                                                    {_.startCase(_.toLower(item.value.replace('_', ' ')))}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    );
                                                }}
                                            </Query>
                                        </FormItem>

                                        <FormItem
                                            name="description"
                                            label="Description"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.description || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="description" placeholder="Description" size="large" />
                                            <ErrorMessage name="description" component="div" className="ant-form-explain" />
                                        </FormItem>

                                        <FormItem
                                            name="operating_hours"
                                            label="Operating Hours"
                                            className={'ant-form-item-control' + (errors.operating_hours || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="operating_hours" placeholder="Monday-Friday: 10am to 5pm" size="large" />
                                            <ErrorMessage name="operating_hours" component="div" className="ant-form-explain" />
                                        </FormItem>

                                        <FormItem
                                            name="address1"
                                            label="Address 1"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.address1 || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="address1" placeholder="Address 1" size="large" />
                                            <ErrorMessage name="address1" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="address2"
                                            label="Address 2"
                                            className={'ant-form-item-control' + (errors.address2 || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="address2" placeholder="Address 2" size="large" />
                                            <ErrorMessage name="address2" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="city"
                                            label="City"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.city || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="city" placeholder="City" size="large" />
                                            <ErrorMessage name="city" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        {/* <FormItem
									label="County"
									className={
										'ant-form-item-control' + (errors.county || errors.general ? ' has-error' : '')
									}
								>
									<Input name="county" placeholder="County" size="large" />
									<ErrorMessage name="county" component="div" className="ant-form-explain" />
								</FormItem> */}
                                        <FormItem
                                            name="country"
                                            label="Country"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.country || errors.general ? ' has-error' : '')}
                                        >
                                            {/* <Input name="country" placeholder="Country" size="large" /> */}
                                            <Select
                                                showSearch
                                                name="country"
                                                placeholder="Please select a country"
                                                optionFilterProp="children"
                                                size="large"
                                                filterOption={(input, option) => {
                                                    return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {CountryRegionData.map((option, index) => {
                                                    return (
                                                        <Option value={option[1]} key={index}>
                                                            <span role="img" aria-label={option[0]}>
                                                                <Flag
                                                                    name={option[1] || 'Unknown'}
                                                                    format="png"
                                                                    pngSize={24}
                                                                    shiny={false}
                                                                    alt={`${option[0]} Flag`}
                                                                    className="flag"
                                                                    basePath="/images/flags"
                                                                />{' '}
                                                            </span>

                                                            {option[0]}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            <ErrorMessage name="country" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="region"
                                            label="Region"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.region || errors.general ? ' has-error' : '')}
                                        >
                                            {/* <Input name="region" placeholder="Select State/Province/Region" size="large" /> */}
                                            <Select
                                                showSearch
                                                name="region"
                                                size="large"
                                                placeholder="Please select a state/province/region"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {getRegions(values.country).map((option, index) => (
                                                    <Option value={option} key={index}>
                                                        {option}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <ErrorMessage name="region" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="postal_code"
                                            label="Postal Code"
                                            required={true}
                                            className={'ant-form-item-control' + (errors.postal_code || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="postal_code" placeholder="Postal Code" size="large" />
                                            <ErrorMessage name="postal_code" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="phone_number"
                                            label="Phone Number"
                                            className={'ant-form-item-control' + (errors.phone_number || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="phone_number" placeholder="Phone Number" size="large" />
                                            <ErrorMessage name="phone_number" component="div" className="ant-form-explain" />
                                        </FormItem>

                                        <FormItem
                                            name="email_address"
                                            label="Email Address"
                                            className={'ant-form-item-control' + (errors.email_address || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="email_address" placeholder="Email Address" size="large" />
                                            <ErrorMessage name="email_address" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="website"
                                            label="Website"
                                            className={'ant-form-item-control' + (errors.website || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="website" placeholder="https://www.example.com" size="large" />
                                            <ErrorMessage name="website" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="social_facebook"
                                            label="Facebook Link"
                                            className={'ant-form-item-control' + (errors.social_facebook || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="social_facebook" placeholder="Facebook Link" size="large" />
                                            <ErrorMessage name="social_facebook" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="social_instagram"
                                            label="Instagram Link"
                                            className={'ant-form-item-control' + (errors.social_instagram || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="social_instagram" placeholder="Instagram Link" size="large" />
                                            <ErrorMessage name="social_instagram" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem
                                            name="social_twitter"
                                            label="Twitter Link"
                                            className={'ant-form-item-control' + (errors.social_twitter || errors.general ? ' has-error' : '')}
                                        >
                                            <Input name="social_twitter" placeholder="Twitter Link" size="large" />
                                            <ErrorMessage name="social_twitter" component="div" className="ant-form-explain" />
                                        </FormItem>
                                        <FormItem name="is_public" label="Access">
                                            <Radio.Group name="is_public">
                                                <Radio value="public">Public - Anyone can view this venue.</Radio>
                                                <br />
                                                <Radio value="private">Private - Only users who have been given access can view this venue.</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <SubmitButton
                                            size="large"
                                            disabled={Object.keys(errors).length > 0 ? true : false}
                                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                            loading={loading}
                                        >
                                            {props.venue ? (loading ? 'Updating...' : 'Update') : 'Create'} Venue
                                        </SubmitButton>
                                        <div>
                                            <Text style={styles.error}>{errors.general}</Text>
                                        </div>
                                    </Form>
                                )}
                            />
                        );
                    }}
                </Mutation>
            )}
        </Mutation>
    );
}

export default withRouter(LMCreateEditVenueForm);
