import React from 'react';
import { Button, Icon } from 'antd';

export default class ScrollingWrapper extends React.Component {
	state = { hasScrolled: false };

	componentDidMount() {
		this.scrollingWrapper.addEventListener('scroll', this.onScroll);
	}

	onScroll = () => {
		if (this.scrollingWrapper.scrollTop > 100 && !this.state.hasScrolled) {
			this.setState({ hasScrolled: true });
		} else if (this.scrollingWrapper.scrollTop < 100 && this.state.hasScrolled) {
			this.setState({ hasScrolled: false });
		}
	};

	scrollToTop = () => {
		this.scrollingWrapper.scrollTo({ top: 0, behavior: 'smooth' });
	};

	reference = (id) => (ref) => {
		this[id] = ref;
	};

	render() {
		return (
			<React.Fragment>
				{this.state.hasScrolled && (
					<div onClick={this.scrollToTop} className="scrollto-top-container">
						<Button>BACK TO TOP</Button>
					</div>
				)}
				<div ref={this.reference('scrollingWrapper')} className="scrolling-wrapper-container">
					{this.props.children}
				</div>
			</React.Fragment>
		);
	}
}
