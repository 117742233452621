import { gql } from '@apollo/client';
import { LEAGUE_FIELDS, LEAGUE_MATCH_FIELDS, LEAGUE_DIVISION_FIELDS } from './fragments';

export const GET_LEAGUE_BY_SLUG_QUERY = gql`
    ${LEAGUE_FIELDS}
    query leagues($slug: String) {
        leagues(where: { slug: { _eq: $slug } }) {
            ...leagueFields2
        }
    }
`;

export const GET_LEAGUE_QUERY = gql`
    ${LEAGUE_FIELDS}
    query leagues($id: Int) {
        leagues(where: { id: { _eq: $id } }) {
            ...leagueFields2
        }
    }
`;

export const GET_LEAGUE_PLAYERS_QUERY = gql`
    query league_players($league_id: Int) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            country_phone
            city
            state
            region
            gender
            email
            phone_number
            fargo_id
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            league_team_players {
                id
                league_team {
                    id
                    name
                }
            }
            player_id
            player {
                player_challenges_played: player_challenges_aggregate(where: { match: { status: { _eq: COMPLETED } } }) {
                    aggregate {
                        count
                    }
                }
                player_challenges_won: player_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, player_is_winner: { _eq: true } } }) {
                    aggregate {
                        count
                    }
                }
                player_challenges_lost: player_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, player_is_winner: { _eq: false } } }) {
                    aggregate {
                        count
                    }
                }
                opponent_challenges_played: opponent_challenges_aggregate(where: { match: { status: { _eq: COMPLETED } } }) {
                    aggregate {
                        count
                    }
                }
                opponent_challenges_won: opponent_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, opponent_is_winner: { _eq: true } } }) {
                    aggregate {
                        count
                    }
                }
                opponent_challenges_lost: opponent_challenges_aggregate(where: { match: { status: { _eq: COMPLETED }, opponent_is_winner: { _eq: false } } }) {
                    aggregate {
                        count
                    }
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                country
                email
                phone_number
                skill_level
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_PLAYERS_QUERY = gql`
    query league_player($league_id: Int, $division_id: Int) {
        league_players(where: { league_id: { _eq: $league_id }, league_player_divisions: { division_id: { _eq: $division_id } } }) {
            id
            name
            nickname
            country
            country_phone
            city
            state
            region
            gender
            email
            phone_number
            fargo_id
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            league_team_players {
                id
                team_id
                league_team {
                    id
                    name
                    slug
                }
            }
            player_id
            user_id
            user {
                id
                first_name
                last_name
                country
                email
                phone_number
                skill_level
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_BY_NAME_QUERY = gql`
    query league_players($name: String, $league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id }, name: { _eq: $name } }) {
            id
            name
            nickname
            country
            country_phone
            city
            state
            region
            gender
            email
            phone_number
            fargo_id
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                nickname
                country
                email
                phone_number
                skill_level
                status
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_QUERY = gql`
    query league_players($id: Int, $league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }) {
            id
            name
            nickname
            country
            country_phone
            city
            state
            region
            gender
            email
            phone_number
            fargo_id
            skill_level
            status
            points
            race_to
            special_group
            league_dues_paid
            last_payment_date
            league_player_divisions {
                id
                division_id
                division {
                    id
                    name
                }
            }
            league_team_players {
                id
                team_id
                league_team {
                    id
                    name
                }
            }
            player_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                country
                email
                phone_number
                skill_level
                status
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_TEAMS_QUERY = gql`
    query league_teams($league_id: Int!, $division_id: Int!) {
        league_teams(where: { league_id: { _eq: $league_id }, league_team_divisions: { division_id: { _eq: $division_id } } }, order_by: { name: asc }) {
            id
            name
            slug
            team_number
            avatar
            status
            division_id
            division {
                id
                name
                league_teams_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            captain_id
            team_captain {
                id
                name
            }
            co_captain_id
            team_co_captain {
                id
                name
            }
            venue_id
            venue {
                id
                name
            }
            league_dues_paid
            last_payment_date
            league_team_players {
                id
                team_id
                league_player {
                    id
                    name
                    nickname
                    country
                    email
                    phone_number
                    skill_level
                    user_id
                }
            }
            league_team_divisions {
                id
                division_id
                league_division {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_LEAGUE_TEAMS_QUERY = gql`
    query league_teams($league_id: Int) {
        league_teams(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            slug
            team_number
            avatar
            status
            division_id
            division {
                id
                name
            }
            captain_id
            team_captain {
                id
                name
            }
            co_captain_id
            team_co_captain {
                id
                name
            }
            venue_id
            venue {
                id
                name
            }
            league_dues_paid
            last_payment_date
            league_team_players {
                id
                league_player {
                    id
                    name
                    nickname
                    country
                    email
                    phone_number
                    skill_level
                    user_id
                }
            }
            league_team_divisions {
                id
                division_id
                league_division {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_LEAGUE_TEAM_BY_SLUG_QUERY = gql`
    query league_teams($slug: String, $league_id: Int!) {
        league_teams(where: { league_id: { _eq: $league_id }, slug: { _eq: $slug } }) {
            id
            name
            slug
            team_number
            avatar
            status
            division_id
            captain_id
            co_captain_id
            venue_id
            league_dues_paid
            last_payment_date
            league_team_players {
                id
                league_player {
                    id
                    name
                    nickname
                    country
                    email
                    phone_number
                    skill_level
                    user_id
                }
            }
            league_team_divisions {
                id
                division_id
                league_division {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_LEAGUE_TEAM_QUERY = gql`
    query league_teams($id: Int, $league_id: Int!) {
        league_teams(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }) {
            id
            name
            slug
            team_number
            avatar
            status
            division_id
            captain_id
            co_captain_id
            venue_id
            venue {
                id
                slug
                name
            }
            league_dues_paid
            last_payment_date
            league_team_players {
                id
                league_player {
                    id
                    name
                    nickname
                    country
                    email
                    phone_number
                    skill_level
                    user_id
                }
            }
            league_team_divisions {
                id
                division_id
                league_division {
                    id
                    name
                }
            }
        }
    }
`;

export const FILTER_VENUES = gql`
    query venues($name: String!) {
        venues(distinct_on: name, where: { name: { _ilike: $name } }) {
            id
            name
            slug
            description
            venue_type
            league_venues {
                league {
                    id
                    name
                    slug
                }
            }
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
        }
    }
`;

export const FILTER_LEAGUE_PLAYERS = gql`
    query league_players($name: String!, $nickname: String, $league_id: Int) {
        league_players(distinct_on: name, where: { league_id: { _eq: $league_id }, _or: [{ name: { _ilike: $name } }, { nickname: { _ilike: $nickname } }] }) {
            id
            user_id
            name
            email
            country
            nickname
            phone_number
            skill_level
            status
            league_dues_paid
            last_payment_date
        }
    }
`;

export const FILTER_USERS = gql`
    query users($first_name: String, $last_name: String, $nickname: String) {
        users(
            distinct_on: [first_name, last_name]
            where: {
                _or: [
                    { first_name: { _ilike: $first_name } }
                    { last_name: { _ilike: $last_name } }
                    { display_name: { _ilike: $first_name } }
                    { nickname: { _ilike: $nickname } }
                ]
            }
        ) {
            id
            first_name
            last_name
            email
            country
            display_name
            nickname
            skill_level
            phone_number
            status
        }
    }
`;

export const GET_PLAYER_STATUS = gql`
    query player_status {
        player_status(order_by: { value: asc }) {
            value
        }
    }
`;

export const GET_LEAGUE_PLAYER_STATUS = gql`
    query league_player_status {
        league_player_status(order_by: { value: asc }) {
            value
        }
    }
`;

export const GET_USER_LEAGUES_QUERY = gql`
    query leagues($owner_id: Int!, $limit: Int) {
        leagues(where: { owner_id: { _eq: $owner_id } }, order_by: { created_at: desc }, limit: $limit) {
            id
            name
            slug
            avatar
            deleted_at
        }
    }
`;

export const GET_USER = gql`
    query users($email: String) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            country
            email
            phone_number
            skill_level
        }
    }
`;

export const FIND_LEAGUE_PLAYER_BY_ID = gql`
    query league_players($user_id: Int, $league_id: Int) {
        league_players(where: { user_id: { _eq: $user_id }, _and: { league_id: { _eq: $league_id } } }) {
            id
            league_id
            user {
                id
                first_name
                last_name
                country
                email
                phone_number
                skill_level
                race_to
            }
        }
    }
`;

export const FIND_LEAGUE_PLAYER_BY_EMAIL = gql`
    query league_players($email: String!, $league_id: Int!) {
        league_players(where: { email: { _eq: $email }, _and: { league: { id: { _eq: $league_id } } } }) {
            id
            name
            country
            email
            phone_number
            skill_level
            status
            points
            race_to
        }
    }
`;

export const GET_DIVISIONS_COUNT_QUERY = gql`
    query divisions_count($leagueId: Int) {
        league_divisions_aggregate(where: { league_id: { _eq: $leagueId } }) {
            aggregate {
                count
                # sum {
                # 	rating
                # }
                # avg {
                # 	rating
                # }
                # max {
                # 	rating
                # }
            }
            # nodes {
            # 	id
            # 	name
            # 	slug
            # }
        }
    }
`;

export const GET_TEAMS_COUNT_QUERY = gql`
    query teams_count($leagueId: Int) {
        teams_aggregate(where: { league_id: { _eq: $leagueId } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_PLAYERS_COUNT_QUERY = gql`
    query players_count($leagueId: Int) {
        league_players_aggregate(where: { league_id: { _eq: $leagueId } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_VENUES_COUNT_QUERY = gql`
    query venues_count($leagueId: Int) {
        league_venues_aggregate(where: { league_id: { _eq: $leagueId } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_LEAGUE_SEASONS_QUERY = gql`
    query league_seasons($leagueId: Int) {
        league_seasons(where: { league_id: { _eq: $leagueId } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_DIVISION_SCHEDULED_MATCHES = gql`
    query league_schedules($leagueId: Int!, $divisionId: Int!, $slug: String) {
        league_scheduled_matches(where: { league_id: { _eq: $leagueId }, division_id: { _eq: $divisionId }, slug: { _eq: $slug } }) {
            id
            home_team {
                id
                name
                slug
            }
            home_team_id
            home_team_name
            league_id
            league_match_id
            schedule_id
            start_date
            start_time
            status
            updated_at
            venue_id
            venue_name
            visitor_team_id
            visitor_team_name
            visitor_team {
                id
                name
                slug
            }
            week
            created_at
            updated_at
        }
    }
`;

// export const GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG_QUERY = gql`
//     query league_schedules($leagueId: Int!, $divisionId: Int, $slug: String) {
//         league_schedules(where: { league_id: { _eq: $leagueId }, division_id: { _eq: $divisionId }, slug: { _eq: $slug } }) {
//             id
//             name
//             slug
//             description
//             league_id
//             division_id
//             season_id
//             start_date
//             start_time
//             duration_type
//             duration
//             reserved_weeks
//             weeks_off
//             is_active
//             created_at
//             updated_at
//         }
//     }
// `;

export const GET_LEAGUE_DIVISION_SCHEDULE = gql`
    query league_schedules($division_id: Int!) {
        league_schedules(where: { division_id: { _eq: $division_id } }) {
            id
            name
            slug
            description
            league_id
            division_id
            season_id
            start_date
            start_time
            duration_type
            duration
            reserved_weeks
            weeks_off
            total_weeks
            is_active
            created_at
            updated_at
            league_division {
                league_team_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
                league_player_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            league_matches {
                id
                name
                home_team {
                    id
                    name
                    slug
                }
                home_team_id
                league_id
                schedule_id
                scheduled_time
                status
                updated_at
                venue_id
                visitor_team_id
                visitor_team {
                    id
                    name
                    slug
                }
                created_at
                updated_at
            }
        }
    }
`;

// export const GET_LEAGUE_DIVISION_SCHEDULE = gql`
//     query league_schedules($id: Int!) {
//         league_schedules(where: { id: { _eq: $id } }) {
//             id
//             name
//             slug
//             description
//             league_id
//             division_id
//             season_id
//             start_date
//             start_time
//             duration_type
//             duration
//             reserved_weeks
//             weeks_off
//             total_weeks
//             is_active
//             created_at
//             updated_at
//             league_division {
//                 league_team_divisions_aggregate {
//                     aggregate {
//                         count
//                     }
//                 }
//                 league_player_divisions_aggregate {
//                     aggregate {
//                         count
//                     }
//                 }
//             }
//             league_scheduled_matches {
//                 id
//                 home_team {
//                     id
//                     name
//                     slug
//                 }
//                 home_team_id
//                 home_team_name
//                 league_id
//                 league_match_id
//                 schedule_id
//                 start_date
//                 start_time
//                 status
//                 updated_at
//                 venue_id
//                 venue_name
//                 visitor_team_id
//                 visitor_team_name
//                 visitor_team {
//                     id
//                     name
//                     slug
//                 }
//                 week
//                 created_at
//                 updated_at
//             }
//         }
//     }
// `;

export const GET_LEAGUE_DIVISION_SCHEDULES = gql`
    query league_schedules($leagueId: Int, $divisionId: Int) {
        league_schedules(where: { league_id: { _eq: $leagueId }, division_id: { _eq: $divisionId } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            description
            league_id
            division_id
            season_id
            start_date
            start_time
            duration_type
            duration
            reserved_weeks
            weeks_off
            total_weeks
            is_active
            created_at
            updated_at
            league_division {
                league_team_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
                league_player_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            league_scheduled_matches_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG_QUERY = gql`
    query league_schedules($slug: String) {
        league_schedules(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            league_id
            division_id
            season_id
            start_date
            start_time
            duration_type
            duration
            reserved_weeks
            weeks_off
            total_weeks
            is_active
            created_at
            updated_at
            league_division {
                league_team_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
                league_player_divisions_aggregate {
                    aggregate {
                        count
                    }
                }
            }
            league_scheduled_matches {
                id
                home_team {
                    id
                    name
                    slug
                }
                home_team_id
                home_team_name
                league_id
                league_match_id
                schedule_id
                start_date
                start_time
                status
                updated_at
                venue_id
                venue_name
                visitor_team_id
                visitor_team_name
                visitor_team {
                    id
                    name
                    slug
                }
                week
                created_at
                updated_at
            }
        }
    }
`;

// export const GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG_QUERY = gql`
//     query league_schedules($leagueId: Int!, $divisionId: Int, $slug: String) {
//         league_schedules(where: { league_id: { _eq: $leagueId }, division_id: { _eq: $divisionId }, slug: { _eq: $slug } }) {
//             id
//             name
//             slug
//             description
//             league_id
//             division_id
//             season_id
//             start_date
//             start_time
//             duration_type
//             duration
//             reserved_weeks
//             weeks_off
//             is_active
//             created_at
//             updated_at
//         }
//     }
// `;

export const GET_LEAGUE_DIVISION_SCHEDULE_QUERY = gql`
    query league_schedules($leagueId: Int!, $divisionId: Int, $scheduleId: Int) {
        league_schedules(where: { league_id: { _eq: $leagueId }, division_id: { _eq: $divisionId }, id: { _eq: $scheduleId } }) {
            id
            name
            slug
            description
            league_id
            division_id
            season_id
            start_date
            start_time
            duration_type
            duration
            reserved_weeks
            weeks_off
            is_active
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_DIVISIONS_SELECT_QUERY = gql`
    query league_divisions($leagueId: Int!) {
        league_divisions(where: { league_id: { _eq: $leagueId } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            description
            division_type
            game_types
            session_id
            num_weeks
            start_date
            end_date
            is_public
            is_active
            is_handicapped
        }
    }
`;

export const GET_LEAGUE_DIVISIONS_QUERY = gql`
    query league_divisions($leagueId: Int!) {
        league_divisions(where: { league_id: { _eq: $leagueId } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            description
            division_type
            game_types
            league_team_divisions_aggregate {
                aggregate {
                    count
                }
            }
            # division_game_types {
            # 	game_type
            # }
            session_id
            num_weeks
            start_date
            end_date
            is_public
            is_active
            is_handicapped
            league_player_divisions_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_BY_SLUG_QUERY = gql`
    ${LEAGUE_DIVISION_FIELDS}
    query league_divisions($leagueId: Int!, $divisionSlug: String) {
        league_divisions(where: { league_id: { _eq: $leagueId }, slug: { _eq: $divisionSlug } }) {
            ...leagueDivisionFields
        }
    }
`;

export const GET_LEAGUE_DIVISION_QUERY = gql`
    ${LEAGUE_DIVISION_FIELDS}
    query league_divisions($leagueId: Int!, $divisionId: Int) {
        league_divisions(where: { league_id: { _eq: $leagueId }, id: { _eq: $divisionId } }) {
            ...leagueDivisionFields
        }
    }
`;

export const GET_DIVISION_TYPES_QUERY = gql`
    query league_division_types {
        league_division_types {
            value
            comment
        }
    }
`;

export const GET_VENUE_TYPES_QUERY = gql`
    query venue_types {
        venue_types {
            value
            comment
        }
    }
`;

export const GET_GAME_TYPES_QUERY = gql`
    query game_types {
        game_types {
            value
            comment
        }
    }
`;

export const SEARCH_VENUES_QUERY = gql`
    query venues($name: String!) {
        venues(
            distinct_on: name
            where: {
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

export const SEARCH_LEAGUE_VENUES_QUERY = gql`
    query venues($name: String!) {
        venues(
            distinct_on: name
            where: {
                is_public: { _eq: true }
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
            league_venues {
                id
            }
        }
    }
`;

export const GET_LEAGUE_VENUES_QUERY = gql`
    query league_venues($leagueId: Int) {
        league_venues(where: { league_id: { _eq: $leagueId } }) {
            id
            venue {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_VENUES_QUERY = gql`
    query venues($league_id: Int) {
        venues(where: { league_venues: { league_id: { _eq: $league_id } } }, order_by: { name: asc }) {
            id
            name
            slug
            description
            venue_type
            league_venues {
                league {
                    id
                    name
                    slug
                }
            }
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
        }
    }
`;

export const GET_VENUE_QUERY = gql`
    query venues($slug: String) {
        venues(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
            pool_tables(order_by: { label: asc }) {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                status
                created_at
                updated_at
            }
        }
    }
`;

export const GET_LEAGUE_VENUE_QUERY = gql`
    query league_venues($slug: String) {
        league_venues(where: { venue: { slug: { _eq: $slug } } }) {
            id
            venue {
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
                pool_tables(order_by: { label: asc }) {
                    id
                    label
                    type
                    size
                    year_built
                    make
                    model
                    livestream_url
                    is_streaming_table
                    is_featured_table
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_LEAGUE_VENUE_BY_ID_QUERY = gql`
    query league_venues($id: Int!) {
        league_venues(where: { id: { _eq: $id } }) {
            id
            venue {
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
                pool_tables(order_by: { label: asc }) {
                    id
                    label
                    type
                    size
                    year_built
                    make
                    model
                    livestream_url
                    is_streaming_table
                    is_featured_table
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_VENUE_BY_ID_QUERY = gql`
    query venues($id: Int!) {
        venues(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
            pool_tables(order_by: { label: asc }) {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const GET_VENUE_TABLES_QUERY = gql`
    query pool_tables($venueId: Int) {
        pool_tables(where: { venue_id: { _eq: $venueId }, status: { _eq: OPEN } }, order_by: { label: asc }) {
            id
            label
            type
            size
            year_built
            make
            model
            livestream_url
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
        }
    }
`;

export const GET_LEAGUE_CHALLENGES_QUERY = gql`
    query challenges($league_id: Int!) {
        challenges(where: { league_id: { _eq: $league_id } }, order_by: { scheduled_time: desc }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
            }
            approved_by_id
            approver {
                id
                first_name
                last_name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                venue_id
                pool_table_id
                table_name
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_CHALLENGE_QUERY = gql`
    query challenges($id: Int!, $league_id: Int!) {
        challenges(where: { id: { _eq: $id }, league_id: { _eq: $league_id } }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
            }
            approved_by_id
            approver {
                id
                first_name
                last_name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                venue_id
                pool_table_id
                table_name
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_CHALLENGE_BY_SLUG_QUERY = gql`
    query challenges($slug: String!, $league_id: Int!) {
        challenges(where: { slug: { _eq: $slug }, league_id: { _eq: $league_id } }) {
            id
            name
            slug
            challenger_id
            challenger_verified
            opponent_id
            opponent_verified
            league_id
            league {
                id
                name
                slug
            }
            division_id
            division {
                id
                name
                slug
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            approved_by_id
            approver {
                id
                first_name
                last_name
                slug
            }
            match_id
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                scoring_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                event_id
                venue_id
                pool_table_id
                table_name
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            status
            notes
            scheduled_time
            start_time
            end_time
            is_accepted
            is_denied
            is_approved
            accepted_time
            approved_time
            denied_time
            created_at
            updated_at
        }
    }
`;

export const GET_MATCHES_QUERY = gql`
    query matches {
        matches {
            id
            name
            slug
            address1
            city
            region
            country
            venue_type
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            owner {
                id
            }
        }
    }
`;

export const GET_LEAGUE_MATCHES_QUERY = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int!) {
        league_matches(where: { league_id: { _eq: $league_id } }, order_by: { scheduled_time: asc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_DIVISION_MATCHES_QUERY = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int, $division_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id } }, order_by: { scheduled_time: desc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_UPCOMING_LEAGUE_MATCHES_QUERY = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int, $division_id: Int, $current_date: timestamptz) {
        league_matches(
            where: {
                league_id: { _eq: $league_id }
                division_id: { _eq: $division_id }
                schedule: { is_active: { _eq: true } }
                scheduled_time: { _gt: $current_date }
            }
            order_by: { scheduled_time: asc }
        ) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_MATCH_BY_SLUG_QUERY = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int, $slug: String) {
        league_matches(where: { league_id: { _eq: $league_id }, slug: { _eq: $slug } }, order_by: { scheduled_time: desc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_MATCH_QUERY = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int, $match_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, id: { _eq: $match_id } }, order_by: { scheduled_time: desc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_MATCHES_BY_STATUS = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int!, $status: league_match_status_enum) {
        league_matches(where: { league_id: { _eq: $league_id }, status: { _eq: $status } }, order_by: { scheduled_time: desc }) {
            ...leagueMatchFields
        }
    }
`;

export const GET_LEAGUE_DIVISION_MATCHES_BY_STATUS = gql`
    ${LEAGUE_MATCH_FIELDS}
    query league_matches($league_id: Int!, $division_id: Int, $status: league_league_match_status_enum) {
        league_matches(
            where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, status: { _eq: $status } }
            order_by: { scheduled_time: desc }
        ) {
            ...leagueMatchFields
        }
    }
`;

export const GET_VENUES_QUERY = gql`
    query venues {
        venues(order_by: { name: asc }) {
            id
            name
            slug
            address1
            city
            region
            country
            venue_type
            is_public
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            owner {
                id
            }
        }
    }
`;

export const GET_LEAGUE_PLACES = gql`
    query league_players($league_id: Int!, $division_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            country
            skill_level
            place
            points
            league_player_divisions(where: { division_id: { _eq: $division_id } }) {
                id
            }
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger1_matches_frames: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_frames: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger1_matches_points: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_points: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_TEAM_STATS = gql`
    query league_team_stats($league_team_id: Int!, $league_id: Int!) {
        matches_won: league_matches_aggregate(
            where: {
                league_id: { _eq: $league_id }
                league_match_teams: { league_team_id: { _eq: $league_team_id }, is_winner: { _eq: true } }
                status: { _eq: COMPLETED }
            }
            order_by: { match_number: asc }
        ) {
            aggregate {
                count
            }
            nodes {
                id
                name
                match_number
                league_match_teams {
                    id
                    name
                    country
                    combined_skill_level
                    race_to
                    score
                    is_winner
                }
            }
        }
        matches_lost: league_matches_aggregate(
            where: {
                league_id: { _eq: $league_id }
                league_match_teams: { league_team_id: { _eq: $league_team_id }, is_winner: { _eq: true } }
                status: { _eq: COMPLETED }
            }
            order_by: { match_number: asc }
        ) {
            aggregate {
                count
            }
            nodes {
                id
                name
                match_number
                league_match_teams {
                    id
                    name
                    country
                    combined_skill_level
                    race_to
                    score
                    is_winner
                }
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_STATS_BY_ID_QUERY = gql`
    query league_player_stats($player_id: Int!, $league_id: Int!) {
        matches_won: league_matches_aggregate(
            where: {
                league_id: { _eq: $league_id }
                league_match_players: { league_player_id: { _eq: $player_id }, is_winner: { _eq: true } }
                status: { _eq: COMPLETED }
            }
            order_by: { match_number: asc }
        ) {
            aggregate {
                count
            }
            nodes {
                id
                name
                match_number
                league_match_players {
                    id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
            }
        }
        matches_lost: league_matches_aggregate(
            where: {
                league_id: { _eq: $league_id }
                league_match_players: { league_player_id: { _eq: $player_id }, is_winner: { _eq: false } }
                status: { _eq: COMPLETED }
            }
            order_by: { match_number: asc }
        ) {
            aggregate {
                count
            }
            nodes {
                id
                name
                match_number
                league_match_players {
                    id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
            }
        }
    }
`;

export const GET_LEAGUE_TABLES_QUERY = gql`
    query pool_tables($league_id: Int) {
        pool_tables(where: { league_id: { _eq: $league_id } }, order_by: { label: asc }) {
            id
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
        }
    }
`;

export const GET_LEAGUE_TABLES_BY_STATUS_QUERY = gql`
    query pool_tables($league_id: Int, $status: pool_table_status_enum) {
        pool_tables(where: { league_id: { _eq: $league_id }, _and: { status: { _eq: $status } } }, order_by: { label: asc }) {
            id
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
        }
    }
`;

export const GET_TABLE_BY_ID_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            label
            type
            size
            year_built
            make
            model
            livestream_url
            manufacturer_id
            venue_id
            user_id
            match_id
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
        }
    }
`;

export const GET_ORGANIZATIONS_QUERY = gql`
    query organizations {
        organizations(order_by: { name: asc }) {
            id
            name
            slug
        }
    }
`;

export const GET_USER_ORGANIZATIONS_QUERY = gql`
    query organizations($ownerId: Int!, $limit: Int) {
        organizations(where: { owner_id: { _eq: $ownerId } }, order_by: { created_at: desc }, limit: $limit) {
            name
            slug
            description
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            fax_number
            email_address
            website
            owner_id
            created_at
            updated_at
        }
    }
`;

export const GET_ORGANIZATION_QUERY = gql`
    query organizations($slug: String) {
        organizations(where: { slug: { _eq: $slug } }) {
            name
            slug
            description
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            fax_number
            email_address
            website
            owner_id
            created_at
            updated_at
        }
    }
`;

export const SEARCH_PLAYERS = gql`
    query players($name: String!) {
        players(distinct_on: [name], where: { name: { _ilike: $name } }) {
            id
            name
            name_slug
            aliases
            country
            gender
            properties
            image_large
            image_small
            user_id
            user {
                id
                first_name
                last_name
                email
                country
                city
                region
                display_name
                nickname
                skill_level
                country_phone
                phone_number
                fargo_id
                fargo_rating
                status
            }
        }
    }
`;

export const SEARCH_ORGANIZATIONS_QUERY = gql`
    query organizations($name: String!) {
        organizations(
            distinct_on: name
            where: {
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            name
            slug
            description
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            fax_number
            email_address
            website
        }
    }
`;

export const GET_TABLE_MANUFACTURERS_QUERY = gql`
    query pool_table_manufacturers {
        pool_table_manufacturers {
            id
            name
        }
    }
`;

export const CHECK_USER_LEAGUE_INVITE = gql`
    query league_invite_keys($email: String!) {
        league_invite_keys(where: { email: { _eq: $email } }) {
            id
            email
            slug
            key
        }
    }
`;

export const GET_LEAGUE_PLAYER_STATS_QUERY = gql`
    query league_player_stats($league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            place
            country
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const SEARCH_POOL_VENUES = gql`
    query venues($name: String!) {
        venues(where: { name: { _ilike: $name } }) {
            id
            name
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POOL_TABLES = gql`
    query pool_tables($venue_id: Int!) {
        pool_tables(where: { venue_id: { _eq: $venue_id } }) {
            id
            slug
            is_featured_table
            is_streaming_table
            label
            league_id
            livestream_url
            make
            manufacturer_id
            match_id
            model
            size
            status
            tournament_id
            type
            updated_at
            user_id
            venue_id
            year_built
            created_at
        }
    }
`;

// League Manager Feed Queries

export const GET_LEAGUE_POSTS = gql`
    query posts($id: Int!) {
        league_posts(where: { league_id: { _eq: $id } }, order_by: { created_at: desc }) {
            id
            post {
                id
                message
                slug
                user_id
                image_url
                created_at
                updated_at
                user {
                    first_name
                    last_name
                    avatar
                }
                post_comments_aggregate {
                    aggregate {
                        count
                    }
                }
                post_likes_aggregate(where: { comment_id: { _is_null: true } }) {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`;

export const GET_POST_QUERY = gql`
    query posts($id: Int!) {
        posts(where: { id: { _eq: $id } }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            user {
                id
                first_name
                last_name
                slug
                email
                photo_url
                avatar
                display_name
                country
            }
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_COMMENTS = gql`
    query post_comments($post_id: Int!) {
        post_comments(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            id
            message
            user_id
            user {
                id
                first_name
                last_name
                slug
                avatar
            }
            post_id
            parent_id
            created_at
            likes_aggregate(where: { comment_id: { _is_null: false } }) {
                aggregate {
                    count
                }
            }
            children_aggregate {
                aggregate {
                    count
                }
            }
            children(order_by: { created_at: desc }) {
                id
                message
                user_id
                user {
                    id
                    first_name
                    last_name
                    slug
                    avatar
                }
                post_id
                parent_id
                created_at
                likes_aggregate(where: { comment_id: { _is_null: false } }) {
                    aggregate {
                        count
                    }
                }
                children_aggregate {
                    aggregate {
                        count
                    }
                }
                children(order_by: { created_at: desc }) {
                    id
                    message
                    user_id
                    user {
                        id
                        first_name
                        last_name
                        slug
                        avatar
                    }
                    post_id
                    parent_id
                    created_at
                    likes_aggregate(where: { comment_id: { _is_null: false } }) {
                        aggregate {
                            count
                        }
                    }
                    children_aggregate {
                        aggregate {
                            count
                        }
                    }
                    children(order_by: { created_at: desc }) {
                        id
                        message
                        user_id
                        user {
                            id
                            first_name
                            last_name
                            slug
                            avatar
                        }
                        post_id
                        parent_id
                        created_at
                        likes_aggregate(where: { comment_id: { _is_null: false } }) {
                            aggregate {
                                count
                            }
                        }
                        children_aggregate {
                            aggregate {
                                count
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_POST_LIKES = gql`
    query post_likes($post_id: Int!) {
        post_likes(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_POST_LIKE_COUNT = gql`
    query post_likes_aggregate($post_id: Int!) {
        post_likes_aggregate(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_POST_COMMENT_LIKE_COUNT = gql`
    query post_likes_aggregate($post_id: Int!, $comment_id: Int!) {
        post_likes_aggregate(where: { post_id: { _eq: $post_id }, comment_id: { _eq: $comment_id } }, order_by: { created_at: desc }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_USER_POST_LIKES = gql`
    query post_likes($post_id: Int!, $user_id: Int!) {
        post_likes(where: { user_id: { _eq: $user_id }, post_id: { _eq: $post_id }, comment_id: { _is_null: true } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_POST_COMMENT_LIKES = gql`
    query post_likes($post_id: Int!, $user_id: Int!, $comment_id: Int!) {
        post_likes(where: { user_id: { _eq: $user_id }, post_id: { _eq: $post_id }, comment_id: { _eq: $comment_id } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_CHALLENGE_COMMENTS = gql`
    query challenge_comments($challenge_id: Int!) {
        challenge_comments(where: { challenge_id: { _eq: $challenge_id } }, order_by: { created_at: desc }) {
            id
            comment
            user_id
            user {
                id
                first_name
                last_name
                country
                slug
                avatar
            }
            challenge_id
            parent_id
            created_at
            children_aggregate {
                aggregate {
                    count
                }
            }
            children(order_by: { created_at: desc }) {
                id
                comment
                user_id
                user {
                    id
                    first_name
                    last_name
                    country
                    slug
                    avatar
                }
                challenge_id
                parent_id
                created_at
                children_aggregate {
                    aggregate {
                        count
                    }
                }
                children(order_by: { created_at: desc }) {
                    id
                    comment
                    user_id
                    user {
                        id
                        first_name
                        last_name
                        country
                        slug
                        avatar
                    }
                    challenge_id
                    parent_id
                    created_at
                    children_aggregate {
                        aggregate {
                            count
                        }
                    }
                    children(order_by: { created_at: desc }) {
                        id
                        comment
                        user_id
                        user {
                            id
                            first_name
                            last_name
                            country
                            slug
                            avatar
                        }
                        challenge_id
                        parent_id
                        created_at
                        children_aggregate {
                            aggregate {
                                count
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_CHALLENGE_COMMENTS_QUERY = gql`
    query challenge_comments($id: Int!) {
        challenge_comments(where: { id: { _eq: $id } }) {
            id
            comment
            user_id
            user {
                id
                first_name
                last_name
                country
                slug
                avatar
            }
            challenge_id
            parent_id
            created_at
        }
    }
`;
