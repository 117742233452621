import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { message, Button, Modal, Typography, Avatar, Tabs, AutoComplete } from 'antd';
import { Form, FormItem, Input, Radio, InputNumber, Select, Checkbox, DatePicker, SubmitButton, FormikDebug } from 'formik-antd';
import { Query, Mutation } from '@apollo/client/react/components';
import { UPDATE_TABLE_MUTATION, CREATE_TABLE_MUTATION, DUPLICATE_POOL_TABLE } from './data/mutations';
import {
    GET_VENUE_QUERY,
    GET_ALL_TOURNAMENT_TABLES_QUERY,
    GET_TOURNAMENT_QUERY,
    GET_TABLE_MANUFACTURERS_QUERY,
    GET_TABLE_BY_ID_QUERY,
    SEARCH_VENUES_QUERY,
    GET_POOL_TABLES,
} from './data/queries';
import * as Yup from 'yup';
import _ from 'lodash';
import slugify from 'slugify';

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    tab: {
        padding: 20,
    },
    error: { color: '#f5222d', marginTop: 10 },
};

function AddTableModal(props) {
    const { authState, tableModalVisible, onTableModalOk, onTableModalCancel, venue, tournament, selectedTableId, redirectAfterSave, client, defaultTab } =
        props;

    const [loading, setLoading] = useState(false);
    const [manufacturers, setManufacturers] = useState();
    const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : venue ? 'duplicate' : 'single');
    const [venueTableDataSource, setVenueTableDataSource] = useState('');
    const formRef = useRef();
    const formRef2 = useRef();
    const formRef3 = useRef();

    const CreateTableSchema = Yup.object().shape({
        label: Yup.string().max(50, 'Too Long!').required('Required'),
    });

    async function queryPoolTableDB(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueTableDataSource(dataSource);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryPoolTableDB(name);
        }
    }

    function onSelect(value, option) {
        formRef3.current.setValues(value);
    }

    async function duplicateSubmit(venueId, actions) {
        setLoading(true);

        let bulkInsertData = [];

        let query = await client.query({
            query: GET_POOL_TABLES,
            variables: {
                venue_id: parseInt(venueId),
            },
        });

        let tableData = query.data.pool_tables;

        tableData.forEach(async (obj) => {
            const slug = slugify(obj.label, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });

            bulkInsertData.push({
                is_featured_table: obj.is_featured_table,
                is_streaming_table: obj.is_streaming_table,
                label: obj.label,
                slug,
                league_id: obj.league_id,
                livestream_url: obj.livestream_url,
                make: obj.make,
                manufacturer_id: obj.manufacturer_id,
                match_id: obj.match_id,
                model: obj.model,
                size: obj.size,
                status: 'OPEN',
                tournament_id: tournament.id,
                type: obj.type,
                updated_at: obj.updated_at,
                user_id: obj.user_id,
                year_built: obj.year_built,
                created_at: obj.created_at,
            });
        });

        console.log(bulkInsertData);

        await client
            .mutate({
                mutation: DUPLICATE_POOL_TABLE,
                variables: {
                    objects: bulkInsertData,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                        variables: {
                            tournament_id: tournament && tournament.id,
                        },
                    },
                ],
            })
            .then((res) => {
                // console.log(data);
                console.log('tables added', res);
                onTableModalOk();
                setLoading(false);
                message.success(`${(res && res.data && res.data.insert_pool_tables && res.data.insert_pool_tables.affected_rows) || 0} Tables added`);
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
                message.error('There was an error', error);
                setLoading(false);
            });
    }

    function range(start, end) {
        return [...Array(1 + end - start).keys()].map((v) => start + v);
    }

    function handleSubmitMultiple(values, createTable, updateTable) {
        const { start_number, end_number, size, manufacturer_id } = values;

        setLoading(true);

        const make =
            manufacturers &&
            manufacturers.filter((item) => {
                return item.id === manufacturer_id;
            })[0].name;

        const newTables = range(start_number, end_number).map((x, i) => {
            const label = `Table ${start_number + i}`;
            const slug = slugify(label, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });

            return {
                label: label,
                slug,
                size: size,
                make: make,
                manufacturer_id,
                status: 'OPEN',
                user_id: authState && authState.user && authState.user.id,
                tournament_id: tournament && tournament.id,
            };
        });

        // console.log(newTables);

        createTable({
            variables: {
                objects: newTables,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: venue
                ? [
                      {
                          query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                          variables: {
                              tournament_id: tournament && tournament.id,
                          },
                      },
                      {
                          query: GET_VENUE_QUERY,
                          variables: {
                              slug: venue.slug,
                          },
                      },
                  ]
                : [
                      {
                          query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                          variables: {
                              tournament_id: tournament && tournament.id,
                          },
                      },
                  ],
        })
            .then((res) => {
                // console.log(data);
                console.log('tables added', res);
                onTableModalOk();
                setLoading(false);
                message.success(`${(res && res.data && res.data.insert_pool_tables && res.data.insert_pool_tables.affected_rows) || 0} Tables added`);
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
                message.error('There was an error', error);
                setLoading(false);
            });
    }

    function handleSubmit(values, createTable, updateTable) {
        const { label, type, size, year_built, model, manufacturer_id, livestream_url, is_featured_table, is_streaming_table, status } = values;
        setLoading(true);

        let make;
        if (manufacturer_id) {
            make = manufacturers.filter((item) => {
                return item.id === manufacturer_id;
            })[0].name;
        }

        const slug = slugify(label, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (selectedTableId) {
            updateTable({
                variables: {
                    id: selectedTableId,
                    changes: {
                        label,
                        slug,
                        type,
                        size,
                        year_built,
                        make,
                        model,
                        livestream_url,
                        manufacturer_id,
                        is_streaming_table,
                        is_featured_table,
                        status,
                        // ...(venue && { venue_id: venue && venue.id }),
                        user_id: props.authState.user.id,
                        tournament_id: tournament && tournament.id,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: venue
                    ? [
                          {
                              query: GET_TABLE_BY_ID_QUERY,
                              variables: {
                                  id: selectedTableId,
                              },
                          },
                          {
                              query: GET_VENUE_QUERY,
                              variables: {
                                  slug: venue && venue.slug,
                              },
                          },
                          // {
                          // 	query: GET_TOURNAMENT_TABLES_QUERY,
                          // 	variables: {
                          // 		tournament_id: tournament.id,
                          // 		status: null
                          // 	}
                          // },
                      ]
                    : [
                          {
                              query: GET_TABLE_BY_ID_QUERY,
                              variables: {
                                  id: selectedTableId,
                              },
                          },
                      ],
            });
        } else {
            createTable({
                variables: {
                    objects: [
                        {
                            label,
                            slug,
                            type,
                            size,
                            year_built,
                            make,
                            model,
                            livestream_url,
                            manufacturer_id,
                            is_streaming_table,
                            is_featured_table,
                            status,
                            // ...(venue && venue.id && { venue_id: venue && venue.id }),
                            user_id: props.authState.user.id,
                            tournament_id: tournament && tournament.id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: venue
                    ? [
                          {
                              query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                              variables: {
                                  tournament_id: tournament && tournament.id,
                              },
                          },
                          {
                              query: GET_VENUE_QUERY,
                              variables: {
                                  slug: venue.slug,
                              },
                          },
                      ]
                    : [
                          {
                              query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                              variables: {
                                  tournament_id: tournament && tournament.id,
                              },
                          },
                      ],
                // refetchQueries: () => [ 'tournament_brackets' ],
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                // 		variables: { tournamentId: tournament.id }
                // 		// awaitRefetchQueries: true,
                // 		// onCompleted: (data) => {
                // 		// 	console.log(data);
                // 		// }
                // 		// notifyOnNetworkStatusChange: true
                // 		// fetchPolicy: 'cache-and-network'
                // 	}
                // ]
            });
        }
    }

    function callback(key) {
        console.log(key);
        setActiveTab(key);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const tableMutations = (data, loading, error) => {
        return (
            <Mutation
                mutation={CREATE_TABLE_MUTATION}
                notifyOnNetworkStatusChange
                awaitRefetchQueries
                // variables={venue ? { venue_id: venue.id } : null}
                onCompleted={(data) => {
                    setLoading(false);
                    message.success('Table(s) successfully created');
                    // const result = data.insert_pool_tables.returning[0];
                    if (redirectAfterSave === false) {
                        onTableModalOk();
                    } else {
                        if (tournament.slug && venue) {
                            props.history.push(`/tournament-builder/${tournament.slug}/venues/${venue.slug}`);
                        } else {
                            props.history.push(`/tournament-builder/${tournament.slug}/tables`);
                        }
                        onTableModalOk();
                    }
                }}
            >
                {(createTable, { error }) => (
                    <Mutation
                        mutation={UPDATE_TABLE_MUTATION}
                        // variables={venue ? { id: selectedTableId, venue_id: venue.id } : { id: selectedTableId }}
                        onCompleted={(data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Table changes saved');
                            // const result = data.update_pool_tables.returning[0];

                            if (redirectAfterSave === false) {
                                onTableModalOk();
                            } else {
                                if (tournament.slug && venue) {
                                    props.history.push(`/tournament-builder/${tournament.slug}/venues/${venue.slug}`);
                                } else {
                                    props.history.push(`/tournament-builder/${tournament.slug}/tables`);
                                }
                                onTableModalOk();
                            }
                        }}
                        // awaitRefetchQueries
                    >
                        {(updateTable, { error }) => {
                            if (error) {
                                alert('Something went wrong');
                            }
                            const pool_table = data && data.pool_tables && data.pool_tables.length > 0 && data.pool_tables[0];

                            const initialValues = pool_table
                                ? {
                                      ...pool_table,
                                      venue_id: tournament && tournament.venue_id,
                                  }
                                : {
                                      label: '',
                                      make: undefined,
                                      size: undefined,
                                      livestream_url: '',
                                      manufacturer_id: undefined,
                                      is_streaming_table: false,
                                      is_featured_table: false,
                                      status: 'OPEN',
                                      venue_id: undefined,
                                  };

                            return (
                                <React.Fragment>
                                    {!loading && !error && (
                                        <Modal
                                            title={<Text style={{ fontWeight: 700 }}>{selectedTableId ? 'Update Table' : 'Add Table(s)'}</Text>}
                                            // getContainer={() => document.querySelector('.tables')}
                                            visible={tableModalVisible}
                                            onOk={onTableModalOk}
                                            onCancel={onTableModalCancel}
                                            // enableReinitialize
                                            centered
                                            transitionName="fade"
                                            bodyStyle={{ padding: 0 }}
                                            // transitionName="none"
                                            maskTransitionName="none"
                                            destroyOnClose={true}
                                            footer={[
                                                <Button key="cancel" onClick={onTableModalCancel}>
                                                    Cancel
                                                </Button>,
                                                <Button
                                                    key="submit"
                                                    type="primary"
                                                    loading={loading}
                                                    onClick={() => {
                                                        if (activeTab === 'single') {
                                                            if (formRef.current) {
                                                                formRef.current.handleSubmit();
                                                            }
                                                        } else if (activeTab === 'multiple') {
                                                            if (formRef2.current) {
                                                                formRef2.current.handleSubmit();
                                                            }
                                                        } else {
                                                            if (formRef3.current) {
                                                                formRef3.current.handleSubmit();
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {selectedTableId
                                                        ? loading
                                                            ? 'Updating...'
                                                            : 'Update'
                                                        : activeTab === 'single'
                                                        ? 'Create'
                                                        : activeTab === 'multiple'
                                                        ? 'Create'
                                                        : 'Import'}{' '}
                                                    {activeTab === 'single' ? 'Table' : activeTab === 'duplicate' ? 'Venue Tables' : 'Tables'}
                                                </Button>,
                                            ]}
                                        >
                                            <Tabs
                                                animated={{ inkBar: true, tabPane: false }}
                                                onChange={callback}
                                                defaultActiveKey={activeTab}
                                                tabBarStyle={{
                                                    textTransform: 'uppercase',
                                                    margin: 0,
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                            >
                                                <TabPane tab="Single Table" key="single" style={styles.tab}>
                                                    <Formik
                                                        ref={formRef}
                                                        enableReinitialize
                                                        initialValues={initialValues}
                                                        validationSchema={CreateTableSchema}
                                                        onSubmit={(values, actions) => {
                                                            // actions.setSubmitting(false);
                                                            // actions.resetForm();
                                                            handleSubmit(values, createTable, updateTable);
                                                        }}
                                                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                                                <FormItem label="Label" name="label" autoFocus required hasFeedback showValidateSuccess>
                                                                    <Input
                                                                        name="label"
                                                                        placeholder="How to refer to this table (e.g. Table 1)"
                                                                        size="large"
                                                                        onChange={(event) => {
                                                                            handleFormFieldUpdates(event);
                                                                        }}
                                                                    />
                                                                </FormItem>

                                                                <FormItem name="manufacturer_id" label="Manufacturer" hasFeedback showValidateSuccess>
                                                                    <Query
                                                                        query={GET_TABLE_MANUFACTURERS_QUERY}
                                                                        fetchPolicy="cache-and-network"
                                                                        notifyOnNetworkStatusChange={true}
                                                                        onCompleted={(data) => {
                                                                            setManufacturers(data.pool_table_manufacturers);
                                                                        }}
                                                                    >
                                                                        {({ loading, error, data }) => {
                                                                            if (loading) return <div>Loading...</div>;
                                                                            if (error) return <div>Error: {error.message}</div>;

                                                                            return (
                                                                                <Select
                                                                                    showSearch
                                                                                    name="manufacturer_id"
                                                                                    placeholder="Select a manufacturer"
                                                                                    size="large"
                                                                                    optionFilterProp="children"
                                                                                    // defaultValue="lucy"
                                                                                    filterOption={(input, option) =>
                                                                                        option.props.children.props.children
                                                                                            .toLowerCase()
                                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                >
                                                                                    {data.pool_table_manufacturers.map((item, index) => (
                                                                                        <Option key={index} value={item.id}>
                                                                                            <Text>{item.name}</Text>
                                                                                        </Option>
                                                                                    ))}
                                                                                </Select>
                                                                            );
                                                                        }}
                                                                    </Query>
                                                                </FormItem>
                                                                <FormItem name="size" label="Size" hasFeedback showValidateSuccess>
                                                                    {/* <Select
																		showSearch
																		name="size"
																		placeholder="Select table size"
																		size="large"
																		optionFilterProp="children"
																		// defaultValue={[ 'jack', 'lucy' ]}
																		filterOption={(input, option) =>
																			option.props.children.props.children.indexOf(
																				input.toLowerCase()
																			) >= 0}
																	>
																		{[
																			'6.5 Foot',
																			'7 Foot',
																			'8 Foot',
																			'9 Foot',
																			'10 Foot'
																		].map((item, index) => (
																			<Option key={index} value={item}>
																				<Text>{item}</Text>
																			</Option>
																		))}
																	</Select> */}
                                                                    <Radio.Group name="size" defaultValue="8 Foot" size="medium">
                                                                        <Radio.Button value="6.5 Foot">6.5 Foot</Radio.Button>
                                                                        <Radio.Button value="7 Foot">7 Foot</Radio.Button>
                                                                        <Radio.Button value="8 Foot">8 Foot</Radio.Button>
                                                                        <Radio.Button value="9 Foot">9 Foot</Radio.Button>
                                                                        <Radio.Button value="10 Foot">10 Foot</Radio.Button>
                                                                        <Radio.Button value="12 Foot">12 Foot</Radio.Button>
                                                                    </Radio.Group>
                                                                </FormItem>
                                                                <FormItem name="is_streaming_table" label="Live Streaming table">
                                                                    <Radio.Group name="is_streaming_table">
                                                                        <Radio value={true}>Yes</Radio>
                                                                        <Radio value={false}>No</Radio>
                                                                    </Radio.Group>
                                                                </FormItem>
                                                                <FormItem name="livestream_url" label="Live Stream URL" hasFeedback showValidateSuccess>
                                                                    <Input
                                                                        name="livestream_url"
                                                                        placeholder="https://www.domain.com"
                                                                        size="large"
                                                                        onChange={(event) => {
                                                                            handleFormFieldUpdates(event);
                                                                        }}
                                                                    />
                                                                </FormItem>
                                                                {/* <FormItem
																	name="is_featured_table"
																	label="Featured table"
																>
																	<Radio.Group name="is_featured_table">
																		<Radio value={true}>Yes</Radio>
																		<Radio value={false}>No</Radio>
																	</Radio.Group>
																</FormItem> */}
                                                                <FormItem label="Status" name="status" hasFeedback showValidateSuccess>
                                                                    {/* <Select
																		showSearch
																		name="status"
																		placeholder="Select a status"
																		size="large"
																		optionFilterProp="children"
																		defaultValue="OPEN"
																		filterOption={(input, option) =>
																			option.props.children
																				.toLowerCase()
																				.indexOf(input.toLowerCase()) >= 0}
																	>
																		{[
																			{
																				type: 'OPEN',
																				value: 'OPEN'
																			},
																			{
																				type: 'CLOSED',
																				value: 'CLOSED'
																			},
																			{
																				type: 'IN_USE',
																				value: 'IN_USE'
																			}
																		].map((item, index) => (
																			<Option key={index} value={item.value}>
																				{item.type}
																			</Option>
																		))}
																	</Select> */}

                                                                    <Radio.Group name="status" defaultValue="OPEN" size="medium">
                                                                        <Radio.Button value="OPEN">OPEN</Radio.Button>
                                                                        <Radio.Button value="CLOSED">CLOSED</Radio.Button>
                                                                        <Radio.Button value="IN_USE">IN_USE</Radio.Button>
                                                                    </Radio.Group>
                                                                </FormItem>
                                                                <button type="submit" hidden />
                                                                {/* <FormikDebug /> */}
                                                            </Form>
                                                        )}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Multiple Tables" key="multiple" style={styles.tab} disabled={selectedTableId ? true : false}>
                                                    <Formik
                                                        ref={formRef2}
                                                        enableReinitialize
                                                        initialValues={{
                                                            start_number: undefined,
                                                            end_number: undefined,
                                                            size: undefined,
                                                            make: undefined,
                                                        }}
                                                        onSubmit={(values, actions) => {
                                                            // actions.setSubmitting(false);
                                                            // actions.resetForm();
                                                            handleSubmitMultiple(values, createTable, updateTable);
                                                        }}
                                                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                                                <FormItem name="start_number" label="Start Number" required hasFeedback showValidateSuccess>
                                                                    <InputNumber
                                                                        name="start_number"
                                                                        placeholder="Enter the first table number"
                                                                        size="large"
                                                                        min={0}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </FormItem>

                                                                <FormItem name="end_number" label="End Number" required hasFeedback showValidateSuccess>
                                                                    <InputNumber
                                                                        name="end_number"
                                                                        placeholder="Enter the last table number"
                                                                        size="large"
                                                                        min={1}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </FormItem>
                                                                <FormItem name="manufacturer_id" label="Manufacturer">
                                                                    <Query
                                                                        query={GET_TABLE_MANUFACTURERS_QUERY}
                                                                        fetchPolicy="cache-and-network"
                                                                        notifyOnNetworkStatusChange={true}
                                                                    >
                                                                        {({ loading, error, data }) => {
                                                                            if (loading) return <div>Loading...</div>;
                                                                            if (error) return <div>Error: {error.message}</div>;

                                                                            return (
                                                                                <Select
                                                                                    showSearch
                                                                                    name="manufacturer_id"
                                                                                    placeholder="Select a manufacturer"
                                                                                    size="large"
                                                                                    optionFilterProp="children"
                                                                                    // defaultValue="lucy"
                                                                                    filterOption={(input, option) =>
                                                                                        option.props &&
                                                                                        option.props.children &&
                                                                                        option.props.children.props &&
                                                                                        option.props.children.props.children
                                                                                            .toLowerCase()
                                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                >
                                                                                    {data.pool_table_manufacturers.map((item, index) => (
                                                                                        <Option key={index} value={item.id}>
                                                                                            <Text>{item.name}</Text>
                                                                                        </Option>
                                                                                    ))}
                                                                                </Select>
                                                                            );
                                                                        }}
                                                                    </Query>
                                                                </FormItem>
                                                                <FormItem name="size" label="Size">
                                                                    {/* <Select
																		showSearch
																		name="size"
																		placeholder="Select table size"
																		size="large"
																		optionFilterProp="children"
																		// defaultValue={[ 'jack', 'lucy' ]}
																		filterOption={(input, option) =>
																			option.props.children
																				.toLowerCase()
																				.indexOf(input.toLowerCase()) >= 0}
																	>
																		{[
																			'6.5 Foot',
																			'7 Foot',
																			'8 Foot',
																			'9 Foot',
																			'10 Foot'
																		].map((item, index) => (
																			<Option key={index} value={item}>
																				<Text>{item}</Text>
																			</Option>
																		))}
																	</Select> */}
                                                                    <Radio.Group name="size" defaultValue="8 Foot" size="medium">
                                                                        <Radio.Button value="6.5 Foot">6.5 Foot</Radio.Button>
                                                                        <Radio.Button value="7 Foot">7 Foot</Radio.Button>
                                                                        <Radio.Button value="8 Foot">8 Foot</Radio.Button>
                                                                        <Radio.Button value="9 Foot">9 Foot</Radio.Button>
                                                                        <Radio.Button value="10 Foot">10 Foot</Radio.Button>
                                                                        <Radio.Button value="12 Foot">12 Foot</Radio.Button>
                                                                    </Radio.Group>
                                                                </FormItem>

                                                                {/* <FormikDebug /> */}
                                                            </Form>
                                                        )}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Import Venue Tables" key="duplicate" style={styles.tab} disabled={selectedTableId ? true : false}>
                                                    <Formik
                                                        enableReinitialize
                                                        initialValues={
                                                            venue
                                                                ? {
                                                                      name: venue.name,
                                                                      venue_id: venue.id,
                                                                  }
                                                                : {
                                                                      name: undefined,
                                                                      venue_id: undefined,
                                                                  }
                                                        }
                                                        ref={formRef3}
                                                        placeholder="Search for a venue"
                                                        optionFilterProp="children"
                                                        optionLabelProp="value"
                                                        size="large"
                                                        name="name"
                                                        onSubmit={duplicateSubmit}
                                                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                                                <FormItem label="Find Venue" name="venue_id">
                                                                    <AutoComplete
                                                                        name="venue_id"
                                                                        dataSource={venueTableDataSource}
                                                                        placeholder="Search for a venue"
                                                                        onSearch={onSearch}
                                                                        onSelect={onSelect}
                                                                        size="large"
                                                                        filterOption={(inputValue, option) => {
                                                                            return (
                                                                                option.props &&
                                                                                option.props.children &&
                                                                                option.props.children
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                                    .indexOf(inputValue.toString().toLowerCase()) >= 0
                                                                            );
                                                                        }}
                                                                    >
                                                                        {venueTableDataSource &&
                                                                            venueTableDataSource.map((item, index) => (
                                                                                <Option key={index} value={`${item.id}`}>
                                                                                    {`${item.name} - ${item.city}, ${item.region} (${item.pool_tables_aggregate.aggregate.count} tables)`}
                                                                                </Option>
                                                                            ))}
                                                                    </AutoComplete>
                                                                </FormItem>
                                                            </Form>
                                                        )}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        </Modal>
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        );
    };

    return (
        <React.Fragment>
            {selectedTableId ? (
                <Query
                    query={GET_TABLE_BY_ID_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedTableId }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => tableMutations(data, loading, error)}
                </Query>
            ) : (
                tableMutations()
            )}
        </React.Fragment>
    );
}

export default withRouter(AddTableModal);
