import React, { useState } from 'react';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export default function MatchFargoColumnCharts(props) {
    const { tournament, selectedFargoRace, getFargoRaces, onSetSelectedFargoRace, onSetShowFargoColumnCharts } = props;
    const [raceChart, setRaceChart] = useState(tournament.handicap_format);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <div style={{ marginBottom: 15 }}>
                    <Title level={4} style={{ marginTop: 0, marginBottom: 10, color: '#fff' }}>
                        Select Fargo chart
                    </Title>
                    <div>
                        <Button
                            type={raceChart === 'fargo_hot_column' ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                setRaceChart('fargo_hot_column');
                            }}
                        >
                            Hot
                        </Button>
                        <Button
                            type={raceChart === 'fargo_medium_column' ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                setRaceChart('fargo_medium_column');
                            }}
                        >
                            Medium
                        </Button>
                        <Button
                            type={raceChart === 'fargo_mild_column' ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                setRaceChart('fargo_mild_column');
                            }}
                        >
                            Mild
                        </Button>
                    </div>
                </div>
                <div>
                    <Title level={4} style={{ marginTop: 0, marginBottom: 10, color: '#fff' }}>
                        Select Fargo{' '}
                        {raceChart === 'fargo_hot_column'
                            ? 'hot column'
                            : raceChart === 'fargo_medium_column'
                            ? 'medium column'
                            : raceChart === 'fargo_mild_column'
                            ? 'mild column'
                            : ''}{' '}
                        race
                    </Title>
                    <div>
                        <Button
                            type={selectedFargoRace === 2 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(2, raceChart);
                                onSetSelectedFargoRace(2);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R2
                        </Button>
                        <Button
                            type={selectedFargoRace === 3 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(3, raceChart);
                                onSetSelectedFargoRace(3);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R3
                        </Button>
                        <Button
                            type={selectedFargoRace === 4 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(4, raceChart);
                                onSetSelectedFargoRace(4);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R4
                        </Button>
                        <Button
                            type={selectedFargoRace === 5 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(5, raceChart);
                                onSetSelectedFargoRace(5);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R5
                        </Button>
                        <Button
                            type={selectedFargoRace === 6 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(6, raceChart);
                                onSetSelectedFargoRace(6);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R6
                        </Button>
                        <Button
                            type={selectedFargoRace === 7 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(7, raceChart);
                                onSetSelectedFargoRace(7);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R7
                        </Button>
                        <Button
                            type={selectedFargoRace === 9 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(9, raceChart);
                                onSetSelectedFargoRace(9);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R9
                        </Button>
                        <Button
                            type={selectedFargoRace === 11 ? 'primary' : `ghost`}
                            ghost
                            size="large"
                            onClick={() => {
                                getFargoRaces(11, raceChart);
                                onSetSelectedFargoRace(11);
                                onSetShowFargoColumnCharts(false);
                            }}
                        >
                            R11
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Button
                        type="secondary"
                        size="large"
                        onClick={() => {
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}
