import _ from 'lodash';
import Hashids from 'hashids';

function createBracketDBDataset(tournamentID, totalPlayers, maxPlayers, playersAdvancing, matchStartNumber) {
    const numPlayers = totalPlayers;
    const winnerRounds = Math.ceil(Math.log2(numPlayers));
    const consolidationThirdPlace = 0;
    const L2 = Math.log2(numPlayers);
    const loserRounds = Math.ceil(L2) + Math.ceil(Math.log2(L2));
    const totalRounds = Math.ceil(winnerRounds + loserRounds);
    const winnerSideArr = winnerRoundMatches(winnerRounds, 0);
    const hashids = new Hashids(`Tournament salt`);
    let winnerBracketMatchesArr = [];

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function winnerRoundMatches(start, end) {
        let extra = 0;
        if (consolidationThirdPlace === 1) {
            extra = extra + 1;
        }
        return Array(start - end + extra)
            .fill()
            .map((item, index) => {
                return Math.ceil(Math.pow(2, start - index) / 2);
            });
    }

    function changeIntoBye(seed, participantsCount) {
        //return seed <= participantsCount ?  seed : '{0} (= bye)'.format(seed);
        return seed <= participantsCount ? seed : null;
    }

    function generateSeeds(participants, rounds) {
        if (participants < 2) {
            return [];
        }

        let matches = [[1, 2]];
        let newRounds = [];

        for (let i = rounds; i >= 1; i--) {
            newRounds.push(i);
        }

        for (var round = 1; round < rounds; round++) {
            var roundMatches = [];
            var sum = Math.pow(2, round + 1) + 1;

            for (var i = 0; i < matches.length; i++) {
                var home = changeIntoBye(matches[i][0], participants);
                var away = changeIntoBye(sum - matches[i][0], participants);
                roundMatches.push([home, away]);
                home = changeIntoBye(sum - matches[i][1], participants);
                away = changeIntoBye(matches[i][1], participants);
                roundMatches.push([home, away]);
            }
            matches = roundMatches;
        }

        return matches;
    }

    function createMatch(data) {
        const match = {
            tournament_id: tournamentID || '',
            round: data.round || '',
            hash_id: data.hash_id,
            identifier: data.identifier || '',
            is_finals: data.is_finals || false,
            is_semi_finals: data.is_semi_finals || false,
            // is_consolidation_third_place: data.is_consolidation_third_place || false
            players_left: data.playersLeft,
            winner_to: data.winner_to,
            winner_from: data.winner_from,
            loser_to: data.loser_to,
            loser_from: data.loser_from,
            advance_to: data.advance_to,
        };

        // if (data.winner_to) {
        //     match.winner_to = data.winner_to || '';
        // }
        // if (data.winner_from) {
        //     match.winner_from = data.winner_from || '';
        // }
        // if (data.loser_to) {
        //     match.loser_to = data.loser_to || '';
        // }
        // if (data.loser_from) {
        //     match.loser_from = data.loser_from || '';
        // }
        return match;
    }

    let matchesToAdvance = 0;

    // step 2 for creating batch insert
    const winnerBracket = winnerSideArr.map((roundMatches, index) => {
        let matches = [];
        let counter = 1;
        let playersLeft;

        for (var i = 0; i < roundMatches; i++) {
            console.log('round matches', roundMatches);
            console.log('match', i);

            playersLeft = roundMatches * 2;
            console.log('players left', playersLeft);

            const loserToRound = index === 0 ? 1 : index * 2;
            const loserToMatch =
                index === 0
                    ? i + (1 % 2) === 0
                        ? counter + 1
                        : counter
                    : index % 2 === 0
                    ? roundMatches / 2 - i > 0
                        ? roundMatches / 2 - i
                        : roundMatches + roundMatches / 2 - i
                    : roundMatches / 2 - i > 0
                    ? Math.floor(roundMatches / 2 + i + 1)
                    : i + 1 - roundMatches + roundMatches / 2;

            const loserTo = `L${loserToRound}-${loserToMatch < 1 ? 1 : loserToMatch}`;
            const winnerToRound = index + 2;
            let winnerToMatch;
            if (i % 2 === 1) {
                counter++;
            }
            winnerToMatch = i === 0 ? 1 : i % 2 === 0 ? counter : counter - 1;
            // let winnerToMatchPosition = i === 0 ? 'A' : i % 2 === 0 ? 'A' : 'B';
            const identifier = `W${index + 1}-${i + 1}`;
            const winnerTo = `W${winnerToRound}-${winnerToMatch}`;
            const newRound = index + 1;
            const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
            const hashId = hashids.encode(randomNum, tournamentID, index + 1, i + 1);
            let match;

            console.log({
                identifier,
                hashId,
                winnerTo,
                loserTo,
                playersLeft,
                playersAdvancing,
            });

            let winnerToStage;
            console.log('winner side rounds');
            console.log('total players', totalPlayers);
            console.log('winner rounds', winnerRounds);
            console.log('round', newRound);
            const roundsToRemove = Math.ceil(Math.log2(playersAdvancing / 2));
            const advancingRound = roundsToRemove;
            console.log('Rounds to remove', roundsToRemove);
            console.log('Advancing round', advancingRound);

            if (newRound === winnerRounds - roundsToRemove + 1) {
                winnerToStage = `S2-W1-${matchesToAdvance + 1}`;
                matchesToAdvance++;
            } else {
                winnerToStage = null;
            }
            console.log('winner to stage', winnerToStage);

            if (index === 0) {
                console.log('add first match');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    // seed: seeds[i]
                    // status: 1
                });
            } else if (consolidationThirdPlace === 1 && index === winnerSideArr.length - 3) {
                console.log('add semi finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    is_semi_finals: true,
                    is_finals: false,
                    // is_consolidation_third_place: false
                });
            } else if (consolidationThirdPlace === 1 && index === winnerSideArr.length - 2) {
                console.log('add finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    is_semi_finals: false,
                    is_finals: true,
                    // is_consolidation_third_place: false
                });
            } else if (consolidationThirdPlace === 1 && index === winnerSideArr.length - 1) {
                console.log('add consolidation third place match');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    is_semi_finals: false,
                    is_finals: false,
                    // is_consolidation_third_place: true
                });
            } else if (consolidationThirdPlace !== 1 && index === winnerSideArr.length - 2) {
                console.log('add semi finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    is_semi_finals: true,
                    is_finals: false,
                    // is_consolidation_third_place: false
                });
            } else if (consolidationThirdPlace !== 1 && index === winnerSideArr.length - 1) {
                console.log('add finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    is_semi_finals: false,
                    is_finals: true,
                    // is_consolidation_third_place: false
                });
            } else {
                console.log('add other matches');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    // seed: seeds[i]
                });
            }
            // Insert players in the first round
            matches.push(match);
            winnerBracketMatchesArr.push(match);
        }
        return matches;
    });

    const brackets = winnerBracketMatchesArr;

    console.log('brackets', brackets);

    // generate the correct order in which the rounds should be played
    const roundOrder = setRoundOrdering(totalRounds, maxPlayers);
    console.log('round order:', roundOrder);
    // console.log('hard coded pattern:', [ 1, 2, -1, -2, 3, -3, -4, 4, -5, -6, 5, -7, -8, 6 ]);

    let rounds = roundOrder.map((round) => {
        const roundMatches = getMatchesByRound(round, brackets);
        return roundMatches;
    });

    console.log('rounds:', rounds);

    // update match numbers to reflect new round ordering
    const ordering = setMatchNumbers(rounds, matchStartNumber);

    console.log('ordering w/match numbers:', ordering);

    let orderingWinners = ordering.filter((round) => {
        return round[0] && round[0].round > 0;
    });
    let winnerBracketPlacement = orderingWinners.map((round, index) => {
        let roundPlayers = round.length;
        let initialPlayers;
        let playersLeft;
        let place;

        initialPlayers = roundPlayers + roundPlayers / 2;
        playersLeft = initialPlayers - roundPlayers / 2;

        // if (initialPlayers > 3) {
        // 	place = `${playersLeft + 1}`;
        // } else {
        // 	if (index === orderingWinners.length - 1) {
        // 		place = 1;
        // 	} else if (index === orderingWinners.length - 2) {
        // 		place = 3;
        // 	} else {
        // 		if (playersLeft === 2) {
        // 			place = 3; // tie for 3rd
        // 		} else {
        // 			place = initialPlayers;
        // 		}
        // 	}
        // }

        if (initialPlayers > 3) {
            place = `${playersLeft + 1}`;
            // place = `${playersLeft + 1}-${initialPlayers + roundPlayers / 2}`;
        } else {
            if (index === orderingWinners.length - 1) {
                place = 1;
            } else if (index === orderingWinners.length - 2) {
                place = 3;
            }
        }

        console.log({
            place: place && place.toString(),
            roundPlayers,
            length: round.length,
            round,
        });

        return place && place.toString();

        // return {
        // 	...round.map((match) => {
        // 		return {
        // 			...match,
        // 			place: place.toString()
        // 		};
        // 	})
        // };
    });

    const draw2 = ordering
        .filter((rounds) => {
            return rounds[0] && rounds[0].round === 1;
        })
        .map((rounds, index) => {
            return rounds.map((match) => {
                return {
                    ...match,
                    place: winnerBracketPlacement[index],
                };
            });
        });

    const winnerBracket2 = ordering
        .filter((rounds) => {
            return rounds[0] && rounds[0].round > 1;
        })
        .map((rounds, index) => {
            return rounds.map((match) => {
                return {
                    ...match,
                    place: winnerBracketPlacement[index + 1],
                };
            });
        });

    const bracketWithPlacements = [...draw2, ...winnerBracket2];

    let rounds2 = roundOrder.map((round) => {
        const roundMatches = getMatchesByRound(round, _.flatten(bracketWithPlacements));
        return roundMatches;
    });

    // update match numbers to reflect new round ordering
    const ordering2 = setMatchNumbers(rounds2, matchStartNumber);

    let batchInsertWithPlacement = _.flatten(ordering2);

    console.log('ordering1', _.flatten(ordering));
    console.log('ordering2', _.flatten(ordering2));

    // const loserBracket2 = ordering
    // 	.filter((rounds) => {
    // 		return rounds[0].round < 1;
    // 	})
    // 	.reverse();

    // const draw2 = ordering.filter((rounds) => {
    // 	return rounds[0].round === 1;
    // });

    // const winnerBracket2 = ordering.filter((rounds) => {
    // 	return rounds[0].round > 1;
    // });

    // let batchInsertArr = [ ..._.flatten(loserBracket2), ..._.flatten(draw2), ..._.flatten(winnerBracket2) ];
    return {
        winnerPlacement: winnerBracketPlacement,
        matches: batchInsertWithPlacement,
    };
}

function setRoundOrdering(totalRounds, maxPlayers) {
    let winnerRoundCounter = 0;
    let loserRoundCounter = 0;
    let roundOrder = [];

    Array.from({ length: totalRounds }, (v, index) => {
        if (index === 0) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }

        // if (index % 3 === 1 && index < totalRounds - 1) {
        if (index % 3 === 1) {
            loserRoundCounter--;
            roundOrder.push(loserRoundCounter);
            loserRoundCounter--;
            roundOrder.push(loserRoundCounter);
        } else if (index % 2 === 0) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }

        if (index === totalRounds - 1 && maxPlayers > 64) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }
    });
    console.log('total rounds:', totalRounds);
    console.log('round ordering:', roundOrder);
    return roundOrder;
}

function getMatchByIdentifier(matches, identifier) {
    var found = [];
    matches.forEach((match, index) => {
        if (match.identifier === identifier) {
            found.push(match);
        }
    });
    return found;
}

function setMatchNumbers(rounds, matchStartNumber) {
    let counter = matchStartNumber || 1;
    console.log('match start number', matchStartNumber);
    let ordering = rounds.map((round, index) => {
        return round.map((match, i) => {
            const newMatch = {
                ...match,
                match_number: counter,
            };
            counter++;
            return newMatch;
        });
    });

    const matchNumberMapping = ordering.map((round, index) => {
        return round.map((match, index2) => {
            const winnerNextMatch = getMatchByIdentifier(_.flatten(ordering), match.winner_to);
            const winnerPrevMatch = getMatchByIdentifier(_.flatten(ordering), match.winner_from);

            if (winnerNextMatch.length) {
                console.log('REGULAR ELIM');
                console.log(`index: ${index} rounds: ${rounds.length}`);
                if (index === rounds.length - 4) {
                    console.log('NEXT MATCH = FINALS', winnerNextMatch);
                    console.log(`${winnerNextMatch[0].identifier} set winner from top num:`, match.match_number);
                    winnerNextMatch[0].winner_from_bottom_num = match.match_number;
                } else if (index === rounds.length - 6) {
                    console.log('NEXT MATCH = SEMI FINALS', winnerNextMatch);
                    console.log(`${winnerNextMatch[0].identifier} set winner from bottom num:`, match.match_number);
                    winnerNextMatch[0].winner_from_top_num = match.match_number;
                } else {
                    if (match.match_number % 2 === 1) {
                        winnerNextMatch[0].winner_from_top_num = match.match_number;
                    } else if (!winnerNextMatch[0].winner_from_bottom_num) {
                        // console.log('set bottom num', match.match_number);
                        winnerNextMatch[0].winner_from_bottom_num = match.match_number;
                    }
                }
            }

            return {
                ...match,
                ...(match.winner_to && {
                    winner_to_num: winnerNextMatch.length ? winnerNextMatch[0].match_number : undefined,
                }),
                ...(match.winner_from && {
                    winner_from_num: winnerPrevMatch.length ? winnerPrevMatch[0].match_number : undefined,
                }),
            };
        });
    });
    console.log('MATCH NUMBERS', _.flatten(matchNumberMapping));
    return matchNumberMapping;
}

function getMatchesByRound(round, bracket) {
    const matches = [];
    bracket.forEach((item, index) => {
        if (item.round === round) {
            matches.push(item);
        }
    });
    return matches;
}

export default createBracketDBDataset;
