import React, { useState, useRef, useEffect } from 'react';
import { Icon, Row, Col, Typography, Progress, Modal, Layout, Button, Input, Tabs, Empty, Table } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import SidePanel from '../../screens/digitalpool/tournaments/bracket/SidePanel';
import CircleFlag from '../../components/CircleFlag';
import { isoCountries } from '../../utils/isoCountryCodes';
import { GET_TOURNAMENT_BRACKET_BY_STATUS_QUERY, GET_TOURNAMENT_BRACKET_QUERY } from './data/queries';
import {
    GET_TOURNAMENT_BRACKET_BY_STATUS_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_W_PLAYERS_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_IN_PROGRESS_MATCHES_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_UPCOMING_MATCHES_SUBSCRIPTION,
} from './data/subscriptions';
import Fade from 'react-reveal/Fade';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import tableBlue from '../../assets/img/table/table-blue-9-ball.png';
import tableGrey from '../../assets/img/table/table-grey-9-ball.png';
import MatchOverlay from '../digitalpool/tournaments/bracket/MatchOverlay3';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { Sider, Content } = Layout;

function TBScheduleMatches(props) {
    const { authState, tournament, width, theme } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [currentRound, setCurrentRound] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedStage, setSelectedStage] = useState();
    const [originalMatches, setOriginalMatches] = useState([]);
    const searchInput = useRef(null);
    const [tournamentComplete, setTournamentComplete] = useState(tournament.status === 'COMPLETED' ? true : false);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [sidePanelOpen, setSidePanelOpen] = useState(breakpoint === 'mobile' ? false : true);
    const defaultSize = 'small';
    const stageSlug = props.match.params.stage;
    console.log('stage slug', stageSlug);
    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
    const windowHeight = window.innerHeight;

    const styles = {
        row: {
            display: 'flex',
            flexGrow: 1,
        },
        tab: {
            paddingTop: 5,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
        },
    };

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'match_number',
            key: 'match',

            ...getColumnSearchProps('match_number'),
            sorter: (a, b) => a.match_number - b.match_number,
        },

        {
            title: 'Player 1',
            dataIndex: 'challenger1_name',
            key: 'player1',
            align: 'right',
            width: 220,
            sorter: (a, b) => {
                return a.challenger1_name.localeCompare(b.challenger1_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                const country = record.challenger1_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            {country && <CircleFlag country={country} left={0} />}
                            <div
                                style={{
                                    marginRight: 10,
                                    fontWeight:
                                        record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                            ? 'bold'
                                            : 'normal',
                                }}
                            >
                                {`${record.challenger1_name || '-'} ${
                                    record.challenger1_name !== 'BYE' && record.challenger1_race_to ? `(${record.challenger1_race_to})` : ''
                                }` || '-'}
                            </div>
                        </div>

                        <span
                            style={{
                                marginLeft: 10,
                                padding: '5px 10px',
                                backgroundColor: !record.challenger1_name
                                    ? 'rgba(0,0,0,0.4)'
                                    : record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                    ? '#1890fe'
                                    : theme && theme.name === 'dark'
                                    ? '#135da2'
                                    : '#61b3ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger1_is_forfeit === true ? 'FF' : record.challenger1_score || 0}
                        </span>
                    </div>
                );
            },
        },

        {
            title: 'Player 2',
            dataIndex: 'challenger2_name',
            key: 'player2',
            width: 220,
            sorter: (a, b) => {
                return a.challenger2_name.localeCompare(b.challenger2_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                const country = record.challenger2_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <span
                            style={{
                                marginRight: 20,
                                padding: '5px 10px',
                                backgroundColor: !record.challenger2_name
                                    ? 'rgba(0,0,0,0.4)'
                                    : record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                    ? '#1890fe'
                                    : theme && theme.name === 'dark'
                                    ? '#135da2'
                                    : '#61b3ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_score || 0}
                        </span>

                        {country && <CircleFlag country={country} left={0} />}

                        <span
                            style={{
                                marginLeft: 0,
                                fontWeight:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {`${record.challenger2_name || '-'} ${
                                record.challenger2_name !== 'BYE' && record.challenger2_race_to ? `(${record.challenger2_race_to})` : ''
                            }` || '-'}
                        </span>
                    </div>
                );
            },
        },

        {
            title: 'Table',
            dataIndex: 'table_name',
            align: 'left',
            key: 'table',
            width: 150,
            ...getColumnSearchProps('table_name'),
            sorter: (a, b) => {
                return (
                    a.table_name &&
                    b.table_name &&
                    a.table_name.localeCompare(b.table_name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <span>{text || '-'}</span>
                    </div>
                );
            },
        },

        {
            title: 'Time',
            dataIndex: 'start_time',
            key: 'start_time',
            width: 200,
            sorter: (a, b) => {
                return a.start_time.localeCompare(b.start_time, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                const matches = record.matches;
                let followsMatch;

                if (matches && matches.length > 0) {
                    const follows = matches.filter((match) => {
                        if (
                            match.priority &&
                            match.priority === record.priority - 1 &&
                            record.scheduled_table_id === match.scheduled_table_id &&
                            match.status !== 'COMPLETED'
                        ) {
                            console.log(match.status);
                            return match;
                        }
                    })[0];

                    if (follows) {
                        followsMatch = follows.match_number;
                    }
                }

                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {!record.start_time && !record.scheduled_time && followsMatch
                            ? `Follows Match ${followsMatch}`
                            : record.status === 'COMPLETED' && record.end_time
                            ? `Ended - ${moment.utc(record.end_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.scheduled_time
                            ? `Scheduled - ${moment.utc(record.scheduled_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_time
                            ? `Started - ${moment.utc(record.start_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_date_time
                            ? `Started - ${moment.utc(record.start_date_time).local().format('lll z')} ${timezone}`
                            : '-'}
                    </div>
                );
            },
        },

        {
            title: 'Order',
            dataIndex: 'priority',
            key: 'priority',
            width: 80,
            // defaultSortOrder: 'desc',
            sorter: (a, b) => {
                if (a.table_name && b.table_name) {
                    return (
                        a.table_name.localeCompare(b.table_name, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        }) || a.priority - b.priority
                    );
                }
            },
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },

        {
            title: 'Identifier',
            dataIndex: 'identifier',
            key: 'identifier',
            width: 100,
            sorter: (a, b) => {
                return a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },

        {
            title: 'Where to',
            dataIndex: 'identifier',
            key: 'where-to',
            width: 200,
            render: (text, record) => {
                return (
                    <div>
                        {/* {record.identifier}{' '} */}
                        {record.loser_to ? (
                            <React.Fragment>{record.loser_to_num ? `L to ${record.loser_to_num}` : ''}</React.Fragment>
                        ) : record.loser_from ? (
                            `L from ${record.loser_from_num} - W to ${record.winner_to_num}`
                        ) : record.winner_to ? (
                            `W to ${record.winner_to_num && record.winner_to_num}`
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Progress
                            percent={record.progress || 0}
                            status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                        {/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							width: '100%'
						}}
					>
						<span
							style={{
								width: '100%',
								textAlign: 'left',
								backgroundColor: '#ececec',
								height: 10,
								position: 'relative'
							}}
						>
							<span
								style={{
									display: 'inline-block',
									// backgroundColor: '#bac2cc',
									backgroundColor: '#1890fe',
									width: `${(record.progress || 0) / 100 * 100}%`,
									height: '100%',
									position: 'absolute',
									transition: 'background-color .3s ease',
									cursor: 'pointer'
								}}
							/>
						</span>
						<span
							style={{
								width: 80,
								marginLeft: 10
							}}
						>
							{record.progress || 0}%
						</span>
					</div> */}
                    </React.Fragment>
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                let duration;
                if (record.start_time && record.end_time) {
                    var start = moment(record.start_time);
                    var end = moment(record.end_time);
                    duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
                } else {
                    duration = moment.duration(moment.utc().diff(record.start_time, 'milliseconds')).format('h [hours] m [min]');
                }

                return duration !== '0 mins' ? (
                    <div>
                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> {duration}
                    </div>
                ) : (
                    <div>-</div>
                );
            },
            sorter: (a, b) => {
                return a.start_time.localeCompare(b.start_time, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return tournament.winners_race_to;
            } else {
                return tournament.losers_race_to;
            }
        }
    }

    // useEffect(
    // 	() => {
    // 		async function fetchData() {
    // 			const res = await props.client.query({
    // 				query: GET_TOURNAMENT_BRACKET_QUERY,
    // 				variables: {
    // 					tournamentId: tournament.id
    // 				}
    // 			});
    // 			const matches = res.data.tournament_brackets;
    // 			console.log(matches);
    // 			setBracket(matches);
    // 			return res;
    // 		}
    // 		fetchData();
    // 	},
    // 	[ props.client, tournament.id ]
    // );

    function handleModal() {
        setModalVisible(true);
    }

    function RenderRow(props) {
        return <div className="streaming">{props}</div>;
    }

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        const isWinnerSideMatch = record.identifier && record.identifier.includes('W') ? 'winners' : 'losers';
        return `${status} ${isStreamingTable} ${isFeaturedTable} ${isWinnerSideMatch}`;
    }

    function showCompleteDialog(winner) {
        Modal.success({
            content: `${winner.challenger2_name || winner.challenger1_name} won the tournament!`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        setTournamentComplete(true);
    }

    async function subscribeToNewMatches(subscribeToMore) {
        return subscribeToMore({
            document: GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION,
            variables: {
                tournamentId: tournament.id,
            },
            onSubscriptionData: (data) => {
                console.log('subscription data', data);
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                // console.log(prev);
                // console.log(subscriptionData);
                const match_updates = subscriptionData.data.tournament_brackets;
                if (!match_updates || match_updates.length === 0) return prev;
                // match_updates.forEach((match) => {
                // 	const exists = [ prev.tournament_brackets ].find(({ id }) => id === match.id);
                // 	if (exists) return prev;
                // });

                const matchIndex = _.findIndex(prev.tournament_brackets, {
                    id: match_updates[0].id,
                });

                var newMatches = [...prev.tournament_brackets.slice(0, matchIndex), match_updates[0], ...prev.tournament_brackets.slice(matchIndex + 1)];

                // console.log({
                // 	matchIndex,
                // 	newMatches
                // });

                const updates = Object.assign({}, prev, {
                    tournament_brackets: [...newMatches],
                });
                // console.log({
                // 	prev,
                // 	updates
                // });

                // setMatches(newMatches);

                // const brackets = buildBracket(newMatches);

                // setMatches(brackets.tournament_brackets);
                // setWinnerBracket(brackets.winnerBracket);
                // setLoserBracket(brackets.loserBracket);
                // setLoserBracketReversed(brackets.loserBracket.reverse());
                // setDrawRound(brackets.draw);

                return updates;
            },
        });
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalVisible(true);
    }

    return (
        <Query
            query={GET_TOURNAMENT_BRACKET_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ tournamentId: tournament.id }}
            onCompleted={(data, loading, error, subscribeToMore) => {
                if (!loading && !error) {
                    // const { tournament_brackets } = data;
                    // setMatches(tournament_brackets);
                    // const brackets = buildBracket(tournament_brackets);
                    // setMatches(brackets.tournament_brackets);
                    // setWinnerBracket(brackets.winnerBracket);
                    // setLoserBracket(brackets.loserBracket);
                    // setLoserBracketReversed(brackets.loserBracket.reverse());
                    // setDrawRound(brackets.draw);
                    if (data && data.tournament_brackets && data.tournament_brackets.length > 0) {
                        setOriginalMatches(data.tournament_brackets);
                    }

                    // console.log('data', data);

                    const stages = tournament.stages;
                    console.log('stages', stages);
                    if (stages && stages.length > 0) {
                        let defaultStage;
                        if (stageSlug) {
                            defaultStage = stages.filter((stage) => {
                                return stage.slug === stageSlug;
                            })[0];
                        } else {
                            defaultStage = stages[0];
                        }
                        console.log(defaultStage);
                        setSelectedStage(defaultStage);
                    }
                }
            }}
        >
            {({ loading, error, data, subscribeToMore, networkStatus }) => {
                if (networkStatus === 1 || loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                    if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                        return (
                            <Col xs={24}>
                                <Card>
                                    <Empty
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        imageStyle={{
                                            height: 100,
                                        }}
                                        description={<Text style={{ fontSize: 18 }}>No tournament bracket exists yet.</Text>}
                                        style={{ margin: 10 }}
                                    />
                                </Card>
                            </Col>
                        );
                    }
                }

                let multiStage = tournament && tournament.tournament_stage_format === 'multi';
                let tournamentBrackets;
                if (multiStage === true) {
                    const advanceRounds =
                        data.tournament_brackets &&
                        data.tournament_brackets
                            .filter((match) => {
                                const stageNum = 1;
                                return match.stage_number === stageNum && match.advance_to !== null;
                            })
                            .map((match) => {
                                return match.round;
                            });

                    tournamentBrackets =
                        data.tournament_brackets &&
                        data.tournament_brackets
                            .filter((match) => {
                                const stageNum = selectedStage ? selectedStage.number : 1;
                                return match.stage_number === stageNum;
                            })
                            .filter((match, index) => {
                                const stageNum = 1;
                                if (match.stage_number === stageNum) {
                                    return advanceRounds.some((value) => {
                                        if (match.identifier.includes('L')) {
                                            return value <= match.round;
                                        } else {
                                            return value >= match.round;
                                        }
                                    });
                                } else {
                                    return true;
                                }
                            });
                } else {
                    tournamentBrackets = data.tournament_brackets;
                }

                // subscribeToNewMatches(subscribeToMore);
                const matches = tournamentBrackets;
                const stages = tournament.stages;

                function getCurrentRound(match) {
                    return matches.filter((item) => {
                        return item.round === match.round;
                    });
                }

                return (
                    <Layout>
                        <Content
                            style={{
                                maxHeight: windowHeight - 250,
                                overflowY: 'auto',
                                padding: 20,
                                backgroundColor: theme && theme.name === 'dark' ? '#06101b' : 'transparent',
                            }}
                        >
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Subscription
                                        subscription={GET_TOURNAMENT_BRACKET_IN_PROGRESS_MATCHES_SUBSCRIPTION}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ tournament_id: tournament.id }}
                                        onSubscriptionData={(data) => {
                                            // console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <CircularLoader />;
                                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                            const inProgress =
                                                data.tournament_brackets &&
                                                data.tournament_brackets
                                                    .map((item, index) => {
                                                        return {
                                                            ...item,
                                                            table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                                            table_id: item.table_id || (item.pool_table && item.pool_table.id),
                                                            matches: data.tournament_brackets,
                                                            key: index,
                                                        };
                                                    })

                                                    .sort((a, b) => {
                                                        return (b.status === 'PENDING') - (a.status === 'PENDING');
                                                    });
                                            return (
                                                <React.Fragment>
                                                    <Card
                                                        title="In Progress"
                                                        bodyStyle={{ padding: 0 }}
                                                        // style={{
                                                        // 	borderRadius: 8,
                                                        // 	overflow: 'hidden',
                                                        // 	border: 'none',
                                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                                        // }}
                                                    >
                                                        <div className="table_nowrap">
                                                            <Table
                                                                dataSource={inProgress}
                                                                columns={columns}
                                                                className="match-table table-striped-rows"
                                                                tableLayout="auto"
                                                                scroll={{ x: 400 }}
                                                                size={defaultSize}
                                                                pagination={false}
                                                                // components={{
                                                                // 	body: {
                                                                // 		row: RenderRow
                                                                // 	}
                                                                // }}
                                                                onRow={(record, rowIndex) => {
                                                                    return {
                                                                        onClick: (event) => {
                                                                            // console.log("E", record);
                                                                            const currentRound = getCurrentRound(record);
                                                                            setCurrentRound(currentRound);
                                                                            setCurrentMatch(record);
                                                                            handleModal();
                                                                        }, // click row
                                                                        onDoubleClick: (event) => {}, // double click row
                                                                        onContextMenu: (event) => {}, // right button click row
                                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                                        className: getClassName(record),
                                                                    };
                                                                }}
                                                                onHeaderRow={(column) => {
                                                                    return {
                                                                        onClick: () => {}, // click header row
                                                                    };
                                                                }}
                                                            />
                                                        </div>
                                                    </Card>
                                                </React.Fragment>
                                            );
                                        }}
                                    </Subscription>
                                </Col>
                                <Col xs={24}>
                                    <Subscription
                                        subscription={GET_TOURNAMENT_BRACKET_UPCOMING_MATCHES_SUBSCRIPTION}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ tournament_id: tournament.id }}
                                        onSubscriptionData={(data) => {
                                            // console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <CircularLoader />;
                                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                            const notStarted =
                                                data.tournament_brackets &&
                                                data.tournament_brackets
                                                    .map((item, index) => {
                                                        return {
                                                            ...item,
                                                            table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                                            table_id: item.table_id || (item.pool_table && item.pool_table.id),
                                                            matches: data.tournament_brackets,
                                                            key: index,
                                                        };
                                                    })
                                                    .filter((match) => {
                                                        return match.advance_to === null;
                                                    });
                                            return (
                                                <React.Fragment>
                                                    <Card
                                                        title="Upcoming"
                                                        bodyStyle={{ padding: 0 }}
                                                        // style={{
                                                        // 	borderRadius: 8,
                                                        // 	overflow: 'hidden',
                                                        // 	border: 'none',
                                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                                        // }}
                                                    >
                                                        <div className="table_nowrap">
                                                            <Table
                                                                dataSource={notStarted}
                                                                columns={columns}
                                                                className="match-table table-striped-rows"
                                                                tableLayout="auto"
                                                                scroll={{ x: 400 }}
                                                                size={defaultSize}
                                                                pagination={false}
                                                                onRow={(record, rowIndex) => {
                                                                    return {
                                                                        onClick: (event) => {
                                                                            // console.log("E", record);
                                                                            setCurrentMatch(record);
                                                                            handleModal();
                                                                        }, // click row
                                                                        onDoubleClick: (event) => {}, // double click row
                                                                        onContextMenu: (event) => {}, // right button click row
                                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                                        className: getClassName(record),
                                                                    };
                                                                }}
                                                                onHeaderRow={(column) => {
                                                                    return {
                                                                        onClick: () => {}, // click header row
                                                                    };
                                                                }}
                                                            />
                                                        </div>
                                                    </Card>
                                                </React.Fragment>
                                            );
                                        }}
                                    </Subscription>
                                </Col>
                                <Col xs={24}>
                                    <Subscription
                                        subscription={GET_TOURNAMENT_BRACKET_BY_STATUS_SUBSCRIPTION}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ tournament_id: tournament.id, status: 'COMPLETED' }}
                                        onSubscriptionData={(data) => {
                                            // console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <CircularLoader />;
                                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                            const completed =
                                                data.tournament_brackets &&
                                                data.tournament_brackets.map((item, index) => {
                                                    return {
                                                        ...item,
                                                        table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                                        table_id: item.table_id || (item.pool_table && item.pool_table.id),
                                                        matches: data.tournament_brackets,
                                                        key: index,
                                                    };
                                                });
                                            return (
                                                <React.Fragment>
                                                    <Card
                                                        title="Completed"
                                                        bodyStyle={{ padding: 0 }}
                                                        // style={{
                                                        // 	borderRadius: 8,
                                                        // 	overflow: 'hidden',
                                                        // 	border: 'none',
                                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                                        // }}
                                                    >
                                                        <div className="table_nowrap">
                                                            <Table
                                                                dataSource={completed}
                                                                columns={columns}
                                                                className="match-table table-striped-rows"
                                                                tableLayout="auto"
                                                                scroll={{ x: 400 }}
                                                                size={defaultSize}
                                                                pagination={false}
                                                                onRow={(record, rowIndex) => {
                                                                    return {
                                                                        onClick: (event) => {
                                                                            // console.log("E", record);
                                                                            setCurrentMatch(record);
                                                                            handleModal();
                                                                        }, // click row
                                                                        onDoubleClick: (event) => {}, // double click row
                                                                        onContextMenu: (event) => {}, // right button click row
                                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                                        className: getClassName(record),
                                                                    };
                                                                }}
                                                                onHeaderRow={(column) => {
                                                                    return {
                                                                        onClick: () => {}, // click header row
                                                                    };
                                                                }}
                                                            />
                                                        </div>
                                                    </Card>
                                                </React.Fragment>
                                            );
                                        }}
                                    </Subscription>
                                </Col>
                            </Row>
                        </Content>

                        {sidePanelOpen === true && (
                            <Sider
                                width={300}
                                theme={theme.name}
                                // style={{
                                //     backgroundColor: '#fff',
                                //     boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                // }}
                            >
                                <SidePanel
                                    {...props}
                                    tournament={tournament}
                                    matches={matches}
                                    onSidePanelOpen={() => {
                                        setSidePanelOpen(false);
                                    }}
                                    onShowScoreModal={showScoreModal}
                                    scrollToTable={() => {
                                        // const nextHeights = elementsRef.current.map(
                                        // 	(ref) => ref.current.getBoundingClientRect().height
                                        // );
                                        // console.log(nextHeights);
                                        // scrollToComponent(this[`winnerRound1_ref`], {
                                        // 	offset: 1000,
                                        // 	align: 'top',
                                        // 	duration: 1500
                                        // });
                                    }}
                                    bracketHeight={windowHeight - 230}
                                />
                            </Sider>
                        )}

                        <MatchOverlay
                            {...props}
                            finals={matches && matches[matches.length - 1]}
                            matches={matches}
                            stage={selectedStage}
                            stages={stages}
                            originalMatches={originalMatches}
                            currentRound={currentRound}
                            currentMatch={currentMatch}
                            modalScoreVisible={modalVisible}
                            onModalScoreVisible={setModalVisible}
                            tournament={tournament}
                            onTournamentComplete={(winner) => {
                                completeTournament(winner);
                            }}
                        />
                    </Layout>
                );
            }}
        </Query>
    );
}

export default TBScheduleMatches;
