import React, { useState, useRef, useEffect } from 'react';
import { Table, Typography, Button, Avatar, Tag, Icon, Input, Tooltip, Modal } from 'antd';
import { displayEnumType, getGameType } from '../../utils';
import Flag from '../../components/Flag';
import CircleFlag from '../../components/CircleFlag';
import { getCountryShortCode } from '../../utils';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const { Text } = Typography;
const { confirm } = Modal;

export default function MatchesTable(props) {
    const { authState, league, division, matches, theme, onSelected, onEditSelectedTeam, onEditSelected, onDelete } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    console.log(matches);

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    function onSelectChange(selectedRowKeys, selectedRows) {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedKeys(selectedRowKeys);
        onSelected(selectedRowKeys, selectedRows);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    const rowSelection = {
        selectedKeys,
        onChange: onSelectChange,
    };

    function getChallengerRaceTo(currentMatch, challenger) {
        const challenger_race_to =
            challenger === 'player' ? currentMatch.challenger1_race_to : challenger === 'opponent' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            return null;
        }
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 60,

            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            // defaultSortOrder: 'ascend',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return (
                    a.name &&
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Scheduled date',
            dataIndex: 'scheduled_time',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.scheduled_time && a.scheduled_time.localeCompare(b.scheduled_time);
            },
            render: (text, record) => {
                return <Text>{moment(text).format('llll')}</Text>;
            },
        },

        {
            title: 'Home team/player',
            dataIndex: 'player1',
            key: 'player1',
            align: 'right',
            width: 300,
            sorter: (a, b) => {
                if (a.is_team_match === true) {
                    return a.home_team_name.localeCompare(b.home_team_name);
                } else {
                    return a.challenger1_name.localeCompare(b.challenger1_name);
                }
            },
            render: (text, record) => {
                if (record.is_team_match === true) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Text
                                style={{
                                    marginRight: 10,
                                    fontWeight: record.home_team_score > record.visitor_team_score ? 'bold' : 'normal',
                                    color: record.home_team_score > record.visitor_team_score && '#ffac00',
                                }}
                            >
                                {record.home_team.name}{' '}
                            </Text>

                            <span
                                style={{
                                    marginLeft: 10,
                                    padding: '5px 10px',
                                    backgroundColor: record.home_team_is_winner === true ? '#1890fe' : theme && theme.name === 'dark' ? '#135da2' : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.home_team_score || 0}
                            </span>
                        </div>
                    );
                } else {
                    const country = record.challenger1_country;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Text
                                style={{
                                    marginRight: 10,
                                    fontWeight: record.challenger1_score > record.challenger2_score ? 'bold' : 'normal',
                                    color: record.challenger1_score > record.challenger2_score && '#ffac00',
                                }}
                            >
                                {record.challenger1_name}{' '}
                                {record.challenger1_skill_level
                                    ? `(${record.challenger1_skill_level})`
                                    : record.challenger1_fargo
                                    ? `(${record.challenger1_fargo})`
                                    : ''}
                            </Text>
                            {country && <CircleFlag country={country} left={0} />}
                            <span
                                style={{
                                    marginLeft: 10,
                                    padding: '5px 10px',
                                    backgroundColor:
                                        record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'player')
                                            ? '#1890fe'
                                            : theme && theme.name === 'dark'
                                            ? '#135da2'
                                            : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.challenger1_score || 0}
                            </span>
                        </div>
                    );
                }
            },
        },
        {
            title: 'Visitor team/player',
            dataIndex: 'player2',
            key: 'player2',
            width: 300,
            sorter: (a, b) => {
                return a.challenger2_name.localeCompare(b.challenger2_name);
            },
            render: (text, record) => {
                if (record.is_team_match === true) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <span
                                style={{
                                    marginRight: 20,
                                    padding: '5px 10px',
                                    backgroundColor:
                                        record.visitor_team_is_winner === true ? '#1890fe' : theme && theme.name === 'dark' ? '#135da2' : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.visitor_team_score || 0}
                            </span>

                            <span
                                style={{
                                    fontWeight: record.visitor_team_score > record.home_team_score ? 'bold' : 'normal',
                                    color: record.visitor_team_score > record.home_team_score && '#ffac00',
                                }}
                            >
                                {record.visitor_team.name}{' '}
                            </span>
                        </div>
                    );
                } else {
                    const country = record.challenger2_country;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <span
                                style={{
                                    marginRight: 20,
                                    padding: '5px 10px',
                                    backgroundColor:
                                        record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'opponent')
                                            ? '#1890fe'
                                            : theme && theme.name === 'dark'
                                            ? '#135da2'
                                            : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.challenger2_score || 0}
                            </span>
                            {country && <CircleFlag country={country} left={0} />}
                            <span
                                style={{
                                    fontWeight: record.challenger2_score > record.challenger1_score ? 'bold' : 'normal',
                                    color: record.challenger2_score > record.challenger1_score && '#ffac00',
                                }}
                            >
                                {record.challenger2_name}{' '}
                                {record.challenger2_skill_level
                                    ? `(${record.challenger2_skill_level})`
                                    : record.challenger2_fargo
                                    ? `(${record.challenger2_fargo})`
                                    : ''}
                            </span>
                        </div>
                    );
                }
            },
        },
        {
            title: 'Table',
            dataIndex: 'table_name',
            sorter: (a, b) => {
                return a.table_name && b.table_name.localeCompare(b.table_name);
            },
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Match type',
            dataIndex: 'match_type',
            sorter: (a, b) => {
                return a.match_type && a.match_type.localeCompare(b.match_type);
            },
            render: (text, record) => {
                return <Text>{displayEnumType(text) || '-'}</Text>;
            },
        },
        // {
        // 	title: 'Frames',
        // 	dataIndex: 'challenger1_frames',
        // 	sorter: (a, b) => {
        // 		return a.challenger1_frames - b.challenger1_frames;
        // 	},
        // 	render: (text, record) => {
        // 		return (
        // 			<Text>
        // 				{record.challenger1_frames} - {record.challenger2_frames}
        // 			</Text>
        // 		);
        // 	}
        // },
        {
            title: 'Game type',
            dataIndex: 'game_type',
            sorter: (a, b) => {
                return a.game_type && a.game_type.localeCompare(b.game_type);
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record && record.game_type && (
                            <Avatar size={24} src={getGameType(record.game_type)} style={{ display: 'inline-block', marginRight: 8 }} />
                        )}

                        <Text>{displayEnumType(text) || '-'}</Text>
                    </div>
                );
            },
        },

        // {
        //     title: 'Division',
        //     dataIndex: 'division',
        //     sorter: (a, b) => {
        //         return a.division.localeCompare(b.division, undefined, {
        //             numeric: true,
        //             sensitivity: 'base',
        //         });
        //     },
        //     render: (text, record) => (
        //         <div className="ellipsis" style={{ maxWidth: 150 }}>
        //             <Text>{(record.division && record.division.name) || '-'}</Text>
        //         </div>
        //     ),
        // },
        {
            title: 'Venue',
            dataIndex: 'venue',

            sorter: (a, b) => {
                return a.venue.name && b.venue.name.localeCompare(b.venue.name);
            },
            render: (text, record) => {
                return (
                    <div className="ellipsis" style={{ width: 150 }}>
                        <Text>{(record.venue && record.venue.name) || '-'}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            sorter: (a, b) => {
                return a.status && a.status.localeCompare(b.status);
            },
            render: (text, record) => {
                return record.status || '-';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                console.log(record);
                                if (record.is_team_match === true) {
                                    if (onEditSelectedTeam) {
                                        onEditSelectedTeam(record.id);
                                    }
                                } else {
                                    onEditSelected(record.id);
                                }
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="danger"
                            ghost
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove match ${record.id}?`,
            content: 'Note: This action cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : record.status === 'SCHEDULED'
                ? 'scheduled'
                : '';

        return `${status}`;
    }

    return (
        <div className="table_nowrap">
            <Table
                dataSource={matches}
                columns={columns.filter((column) => {
                    if (league.has_division_support === false && column.dataIndex === 'division') {
                        return null;
                    } else {
                        return column;
                    }
                })}
                className="match-table table-striped-rows"
                tableLayout="auto"
                scroll={{ x: 400 }}
                size="small"
                onChange={onChange}
                pagination={false}
                // pagination={{ pageSize: 25 }}
                // bordered
                rowSelection={rowSelection}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log(record, rowIndex);
                            props.history.push({
                                pathname: `/league-manager/${league.slug}/divisions/${division.slug}/matches/${record.slug}`,
                                // search: '?query=abc',
                                state: { data: record },
                            });
                            // if (record.division && record.division.slug) {
                            //     props.history.push({
                            //         pathname: `/league-manager/${props.league.slug}/divisions/${record.division.slug}/matches/${record.slug}`,
                            //         // search: '?query=abc',
                            //         state: { data: record },
                            //     });
                            // } else {
                            //     props.history.push({
                            //         pathname: `/league-manager/${props.league.slug}/matches/${record.slug}`,
                            //         // search: '?query=abc',
                            //         state: { data: record },
                            //     });
                            // }
                        },
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                        className: getClassName(record),
                    };
                }}
            />
        </div>
    );
}
