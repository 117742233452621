import React from 'react';
import { Spin, Icon } from 'antd';

export default function CircularLoader() {
	const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
	return (
		<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<Spin indicator={antIcon} size="large" />
		</div>
	);
}
