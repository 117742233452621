import React, { useState } from 'react';
import { message, Button, Typography, Collapse, Avatar, Tooltip, Icon, Input as InputAntd, Divider } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Timer from 'react-compound-timer';
import CountdownTimerProgress from '../components/CountdownTimerProgress';
import CircularLoader from '../components/CircularLoader';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_MATCH_SHOT_CLOCK_QUERY, GET_TOURNAMENT_MATCH_SHOT_CLOCK_QUERY } from '../screens/digitalpool/data/queries';
import { UPDATE_MATCH_SHOT_CLOCK, UPDATE_TOURNAMENT_MATCH_SHOT_CLOCK } from '../screens/digitalpool/data/mutations';
import { Query, Subscription } from '@apollo/client/react/components';
import countdown from '../assets/sound/10sec-countdown.mp3';
import qs from 'query-string';

const { Text, Title } = Typography;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function ShotClock(props) {
    const {
        match,
        direction = 'forward',
        showDays = true,
        showHours = true,
        showMinutes = true,
        showSeconds = true,
        showMilliseconds = true,
        type = 'text',
        initialStartTime = 30000,
        matchData,
    } = props;

    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState();
    const [remainingTime, setRemainingTime] = useState();
    const [extensionCount, setExtensionCount] = useState(0);
    const [extensionsLeft, setExtensionsLeft] = useState(3);
    const [maxTime, setMaxTime] = useState(30000);
    const [key, setKey] = useState(0);
    const [updateMatchShotClock] = useMutation(UPDATE_MATCH_SHOT_CLOCK);
    const [updateTournamentMatchShotClock] = useMutation(UPDATE_TOURNAMENT_MATCH_SHOT_CLOCK);
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const textColor = params && params.textColor ? `#${params.textColor}` : '#000';
    const [audio, setAudio] = useState(new Audio(countdown));

    function stopAudio() {
        audio.currentTime = 0;
        audio.pause();
    }

    function startAudio() {
        audio.play();
    }

    function updateShotClock(command, seconds, initialTime) {
        updateTournamentMatchShotClock({
            variables: {
                match_id: matchData.id,
                objects: [
                    {
                        tournament_bracket_id: matchData.id,
                        duration: initialTime,
                        remaining_time: seconds,
                        direction: 'backward',
                        text_color: textColor,
                        command: command,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_MATCH_SHOT_CLOCK_QUERY,
                    variables: { match_id: matchData.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                // message.success('Changes saved');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function getRemainingTime(remainingTime) {
        const seconds = remainingTime.toFixed(0);
        const formatted = Math.round((parseInt(seconds) / 1000) * 1000);
        console.log(formatted);
        return formatted;
    }

    return (
        <Query
            query={GET_TOURNAMENT_MATCH_SHOT_CLOCK_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ match_id: matchData.id }}
            onCompleted={(data, error, loading) => {
                console.log(data);

                const res = data && data.tournament_match_shot_clock && data.tournament_match_shot_clock[0];
                const initialTime = (res && res.duration) || initialStartTime;
                const remainingTime = (res && res.remaining_time) || initialTime;

                setCurrentTime(initialTime);
                setRemainingTime(remainingTime);
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader /> <span style={{ marginLeft: 10 }}>Loading...</span>
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                const shotClockData = data && data.tournament_match_shot_clock && data.tournament_match_shot_clock[0];

                return (
                    <React.Fragment>
                        {currentTime && remainingTime && (
                            <Timer
                                initialTime={initialStartTime}
                                timeToUpdate={1000}
                                startImmediately={false}
                                direction={direction}
                                // formatValue={(value) => {
                                //     console.log('current time', value);
                                //     setCurrentTime(value);
                                // }}
                                onStart={() => {}}
                                onResume={() => {}}
                                onPause={() => {}}
                                onStop={() => {}}
                                onReset={() => {}}
                                checkpoints={[
                                    {
                                        time: 15000,
                                        callback: () => console.log('Checkpoint A at 15 secs'),
                                    },
                                    {
                                        time: 10000,
                                        callback: () => {
                                            // startAudio();
                                            console.log('Checkpoint B at 10 secs');
                                        },
                                    },
                                    {
                                        time: 5000,
                                        callback: () => console.log('Checkpoint C at 5 secs'),
                                    },
                                    {
                                        time: 0,
                                        callback: () => {
                                            setKey((prevKey) => prevKey + 1);
                                            setIsPlaying(true);
                                            setCurrentTime(initialStartTime);
                                            updateShotClock('RESTART', initialStartTime);
                                            console.log('onReset hook', initialStartTime);
                                            console.log('Checkpoint D at 0 secs');
                                        },
                                    },
                                ]}
                            >
                                {({ start, resume, pause, stop, reset, timerState, getTime }) => (
                                    <Text>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {type === 'circular' ? (
                                                <React.Fragment>
                                                    <CountdownTimerProgress
                                                        currentKey={key}
                                                        isPlaying={isPlaying}
                                                        duration={maxTime / 1000}
                                                        initialRemainingTime={remainingTime / 1000}
                                                        colorsTime={[60, 30, 15, 10, 0]}
                                                        colors={['#00aa00', '#00aa00', '#F7B801', '#A30000', '#A30000']}
                                                        textColor="#FFF"
                                                        size={300}
                                                        fontSize={100}
                                                        shouldRepeat={false}
                                                        // onUpdatedTime={(time) => {
                                                        //     console.log('time', time);
                                                        //     setCurrentTime(time);
                                                        // }}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {showDays === true && (
                                                        <React.Fragment>
                                                            <Timer.Days />d{' '}
                                                        </React.Fragment>
                                                    )}
                                                    {showHours === true && (
                                                        <React.Fragment>
                                                            <Timer.Hours />h{' '}
                                                        </React.Fragment>
                                                    )}
                                                    {showMinutes === true && (
                                                        <React.Fragment>
                                                            <Timer.Minutes />m{' '}
                                                        </React.Fragment>
                                                    )}
                                                    {showSeconds === true && (
                                                        <React.Fragment>
                                                            <Timer.Seconds />s{' '}
                                                        </React.Fragment>
                                                    )}
                                                    {showMilliseconds === true && (
                                                        <React.Fragment>
                                                            <Timer.Milliseconds />
                                                            ms
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15, marginBottom: 10 }}>
                                            {/* <Button
                                        ghost
                                        size="large"
                                        disabled={!shotClockData}
                                        onClick={() => {
                                            const time = getTime();
                                            const seconds = getRemainingTime(time);
                                            console.log('current time', currentTime);
                                            setIsPlaying(false);
                                            // setCurrentTime(seconds);
                                            updateShotClock('PAUSE', currentTime);
                                            console.log('onPause hook', currentTime);
                                            message.success('Shot clock paused');
                                            pause();
                                        }}
                                    >
                                        Pause
                                    </Button> */}
                                            {/* <Button
                            ghost
                            size="large"
                            onClick={() => {
                                const time = getTime();
                                const seconds = getRemainingTime(time);
                                console.log('current time', seconds);
                                setIsPlaying(true);
                                setCurrentTime(seconds);
                                updateShotClock('RESUME', seconds);
                                console.log('onResume hook', seconds);
                                resume();
                            }}
                        >
                            Resume
                        </Button> */}

                                            <Button
                                                ghost
                                                size="large"
                                                onClick={() => {
                                                    const time = getTime();
                                                    const seconds = getRemainingTime(time);

                                                    console.log('current time', currentTime);
                                                    // let calcSeconds = seconds + 30000;
                                                    let newTime;
                                                    if (currentTime < 90000) {
                                                        if (extensionCount === 0) {
                                                            newTime = seconds + 30000;
                                                        } else if (extensionCount === 1) {
                                                            newTime = seconds + 60000;
                                                        }

                                                        console.log(newTime);

                                                        setIsPlaying(true);
                                                        setCurrentTime(newTime);
                                                        setRemainingTime(newTime);
                                                        setMaxTime(newTime);
                                                        updateShotClock('EXTENSION', newTime, newTime);

                                                        setExtensionCount(extensionCount + 1);
                                                        console.log('onExtension hook', newTime);
                                                        message.success('+30 seconds added');

                                                        reset();
                                                        setKey((prevKey) => prevKey + 1);
                                                        // stopAudio();
                                                    }
                                                }}
                                                disabled={!shotClockData || currentTime >= 90000 ? true : false}
                                                style={{ fontSize: 25, height: 50, width: '100%' }}
                                            >
                                                Extension +30
                                            </Button>
                                            <Button
                                                ghost
                                                size="large"
                                                disabled={!shotClockData}
                                                onClick={() => {
                                                    setKey((prevKey) => prevKey + 1);
                                                    const time = getTime();
                                                    const seconds = getRemainingTime(time);
                                                    console.log('current time', currentTime);

                                                    setIsPlaying(true);
                                                    setCurrentTime(initialStartTime);
                                                    setRemainingTime(initialStartTime);
                                                    setMaxTime(initialStartTime);
                                                    updateShotClock('RESTART', initialStartTime, initialStartTime);
                                                    console.log('onReset hook', initialStartTime);
                                                    message.success('Shot clock restarted');
                                                    setExtensionCount(0);
                                                    reset();
                                                    // stopAudio();
                                                }}
                                                style={{ fontSize: 25, height: 50, width: '100%' }}
                                            >
                                                Restart
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                            <Button
                                                ghost
                                                size="large"
                                                onClick={() => {
                                                    setKey((prevKey) => prevKey + 1);
                                                    setIsPlaying(false);
                                                    setCurrentTime(0);
                                                    setRemainingTime(0);
                                                    setMaxTime(0);
                                                    updateShotClock('CLEAR', 0, 0);
                                                    console.log('onClear hook', 0);
                                                    message.success('Clear shot clock');
                                                    setExtensionCount(0);
                                                    reset();
                                                    // stopAudio();
                                                }}
                                                style={{ fontSize: 25, height: 50, width: '100%' }}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                ghost
                                                size="large"
                                                disabled={!shotClockData}
                                                onClick={() => {
                                                    setKey((prevKey) => prevKey + 1);
                                                    const time = getTime();
                                                    const seconds = getRemainingTime(time);
                                                    console.log('current time', currentTime);

                                                    // let calcSeconds = seconds + 60000;
                                                    let newTime = 60000;

                                                    console.log(newTime);

                                                    setIsPlaying(true);
                                                    setCurrentTime(newTime);
                                                    setRemainingTime(newTime);
                                                    setMaxTime(newTime);
                                                    updateShotClock('EXTENSION', newTime, newTime);
                                                    console.log('onExtension hook', newTime);
                                                    message.success('Shot clock set to 60 seconds');
                                                    setExtensionCount(0);
                                                    reset();

                                                    // stopAudio();
                                                }}
                                                style={{ fontSize: 25, height: 50, width: '100%' }}
                                            >
                                                Set to 60
                                            </Button>
                                        </div>
                                        <div>
                                            {isPlaying === true ? (
                                                <Button
                                                    type="danger"
                                                    size="large"
                                                    disabled={!shotClockData}
                                                    onClick={() => {
                                                        const time = getTime();
                                                        const seconds = getRemainingTime(time);
                                                        console.log('seconds', seconds);
                                                        console.log('current time', currentTime);
                                                        // setKey((prevKey) => prevKey + 1);
                                                        setIsPlaying(false);
                                                        setCurrentTime(seconds);
                                                        setRemainingTime(seconds);
                                                        updateShotClock('STOP', seconds, maxTime);
                                                        console.log('onStop hook', seconds);
                                                        message.success('Shot clock stopped');
                                                        setExtensionCount(0);
                                                        pause();
                                                        // stopAudio();
                                                    }}
                                                    style={{ width: '100%', fontSize: 25, height: 50 }}
                                                >
                                                    Stop
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={() => {
                                                        const time = getTime();
                                                        const seconds = getRemainingTime(time);
                                                        const roundedSeconds = Math.round(seconds / 1000) * 1000;
                                                        console.log('seconds', roundedSeconds);
                                                        console.log('current time', currentTime);
                                                        // setKey((prevKey) => prevKey + 1);
                                                        setIsPlaying(true);
                                                        setCurrentTime(roundedSeconds);
                                                        setRemainingTime(roundedSeconds);
                                                        updateShotClock('START', roundedSeconds, maxTime);
                                                        console.log('onStart hook', roundedSeconds);
                                                        message.success('Shot clock started');
                                                        setExtensionCount(0);
                                                        start();
                                                    }}
                                                    style={{ width: '100%', fontSize: 25, height: 50 }}
                                                >
                                                    Start
                                                </Button>
                                            )}
                                        </div>
                                    </Text>
                                )}
                            </Timer>
                        )}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(ShotClock);
