import React, { useState, useEffect } from 'react';
import { message, Alert, Icon, Row, Col, Modal, Typography, Button, Empty } from 'antd';
import Card from '../../components/Card';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import NotifyPlayersModal from './NotifyPlayersModal';
import NotificationsTable from './NotificationsTable';
import NotificationReceipt from './NotificationReceipt';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { GET_TOURNAMENT_NOTIFICATIONS_QUERY } from './data/queries';
import { GET_TOURNAMENT_NOTIFICATIONS_SUBSCRIPTION } from './data/subscriptions';
import { Query, Mutation, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DELETE_NOTIFICATION_MUTATION, LOG_PLAYER_SMS_NOTIFICATION } from './data/mutations';
import moment from 'moment';
import ZeroState from '../../components/ZeroState';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function TBNotifications(props) {
    const { tournament, authState, client } = props;
    const [loading, setLoading] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [playerNotifyModalVisible, setPlayerNotifyModalVisible] = useState(false);
    const [notificationModalVisible, setNotificationModalVisible] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(false);
    // const [ players, setPlayers ] = useState([]);
    const hasSelected = selectedKeys && selectedKeys.length > 0;
    const [deleteNotification] = useMutation(DELETE_NOTIFICATION_MUTATION);
    const [logSMSNotification] = useMutation(LOG_PLAYER_SMS_NOTIFICATION);

    function handlePlayerNotifyOk(e) {
        setPlayerNotifyModalVisible(false);
    }

    function handlePlayerNotifyModalCancel() {
        setPlayerNotifyModalVisible(false);
    }

    function handlePlayerNotify() {
        setPlayerNotifyModalVisible(true);
    }

    function handleNotificationReceiptOk(e) {
        setNotificationModalVisible(false);
    }

    function handleNotificationReceiptCancel() {
        setNotificationModalVisible(false);
    }

    function logSMSMessage(options) {
        const { match, player, tournament } = options;
        console.log('LOG SMS MESSAGE', options);

        return logSMSNotification({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        tournament_bracket_id: match.id,
                        type: 'SMS',
                        round_number: match.round,
                        match_number: match.match_number,
                        player_name: player.name,
                        player_id: player.id,
                        player_phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                        message: options.message.text,
                        message_id: options.message.id,
                        message_sent: options.message.sent,
                        message_delivered: options.message.delivered,
                        message_error: options.message.error,
                        message_undeliverable: options.message.undeliverable,
                        message_sent_at: options.message.sent_at,
                        message_delivered_at: options.message.delivered_at,
                    },
                ],
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_MATCH_STATS_QUERY,
            // 		variables: { match_id: matchId }
            // 	}
            // ]
        })
            .then((values) => {
                console.log(values);
                return values;
            })
            .catch((e) => {
                const err = (e && e.message) || JSON.stringify(e);
                message.error(err);
                console.log(err);
                return err;
            });
    }

    async function resendNotification(options) {
        console.log(options);

        const key = 'sending';
        message.loading({ content: 'Sending notification...', key, duration: 0 });

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }

        try {
            const sendSMS = await httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${options.message}\n${moment().format('llll')}`;
            const phoneNumber = `${options.country_phone || '+1'}${options.player_phone_number}`;
            const result = sendSMS({
                phone_number: phoneNumber,
                message: messageToSend,
                user_id: options.player_id,
                tournament_id: options.tournament_id,
                // token: ''
            })
                .then(async (values) => {
                    console.log(values);

                    await logSMSMessage({
                        tournament,
                        match: {
                            id: options.tournament_bracket_id,
                            round: options.round_number,
                            match_number: options.match_number,
                        },
                        player: {
                            id: options.player_id,
                            name: options.player_name,
                            phone_number: options.player_phone_number,
                        },
                        message: {
                            id: values && values.data && values.data['message-id'],
                            text: options.message,
                            sent: true,
                            error: null,
                            sent_at: moment.utc(),
                            delivered: null,
                            delivered_at: null,
                            undeliverable: null,
                        },
                    });

                    message.success({
                        content: `Notification sent to ${options.player_name}`,
                        key,
                        duration: 2,
                    });
                    return values;
                })
                .catch(async (e) => {
                    const err = (e && e.message) || JSON.stringify(e);
                    console.log(err);

                    await logSMSMessage({
                        tournament,
                        match: {
                            id: options.tournament_bracket_id,
                            round: options.round_number,
                            match_number: options.match_number,
                        },
                        player: {
                            id: options.player_id,
                            name: options.player_name,
                            phone_number: options.player_phone_number,
                        },
                        message: {
                            id: err && err.data && err.data['message-id'],
                            text: options.message,
                            sent: true,
                            error: err,
                            sent_at: moment.utc(),
                            delivered: false,
                            delivered_at: null,
                            undeliverable: true,
                        },
                    });

                    message.success({
                        content: `SMS Notification error: ${options.player_name}`,
                        key,
                        duration: 2,
                    });

                    return err;
                });
        } catch (e) {
            const err = (e && e.message) || JSON.stringify(e);
            console.log(e);
            await logSMSMessage({
                tournament,
                match: {
                    id: options.tournament_bracket_id,
                    round: options.round_number,
                    match_number: options.match_number,
                },
                player: {
                    id: options.player_id,
                    name: options.player_name,
                    phone_number: options.player_phone_number,
                },
                message: {
                    id: err && err.data && err.data['message-id'],
                    text: options.message,
                    sent: true,
                    error: err,
                    sent_at: moment.utc(),
                    delivered: false,
                    delivered_at: null,
                    undeliverable: true,
                },
            });
            message.error({ content: `SMS Notification error: ${err}`, key, duration: 1 });
            return err;
        }
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="players" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    {/* <Col xs={24}>
                        <Alert message="Understanding who was notified" description="Learn" type="info" closable showIcon />
                    </Col> */}
                    <Col xs={24}>
                        <Subscription
                            subscription={GET_TOURNAMENT_NOTIFICATIONS_SUBSCRIPTION}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange
                            awaitRefetchQueries
                            variables={{ tournament_id: tournament.id }}
                            onSubscriptionData={(data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                if ((data && !data.tournament_notifications) || (data && data.tournament_notifications.length === 0)) {
                                    return (
                                        <Card
                                            // title="Players"
                                            bodyStyle={{ padding: 0 }}
                                            // style={{
                                            // 	borderRadius: 8,
                                            // 	overflow: 'hidden',
                                            // 	border: 'none',
                                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                            // }}
                                            cover={
                                                <div
                                                    style={{
                                                        paddingTop: 16,
                                                        paddingBottom: 16,
                                                        paddingLeft: 24,
                                                        paddingRight: 24,
                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                        Notification history
                                                    </Title>
                                                </div>
                                            }
                                        >
                                            <ZeroState showImage message="No notifications have been sent yet." style={{ margin: 30 }} {...props} />
                                        </Card>
                                    );
                                }

                                const notifications = data.tournament_notifications.map((item, index) => {
                                    return {
                                        ...item,
                                        id: item.id,
                                        index: index + 1,
                                        key: index,
                                    };
                                });

                                return (
                                    <Card
                                        // title="Players"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                    Notification history
                                                </Title>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Button type="default" onClick={handlePlayerNotify}>
                                                        <Icon type="bell" /> Notify player
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <NotificationsTable
                                            {...props}
                                            tournament={tournament}
                                            selectedRowKeys={selectedKeys}
                                            handleOnClick={(record) => {
                                                setSelectedNotification(record);
                                                setNotificationModalVisible(true);
                                            }}
                                            onResend={resendNotification}
                                            onDelete={(record) => {
                                                deleteNotification({
                                                    variables: {
                                                        tournament_id: tournament.id,
                                                        id: record.id,
                                                    },
                                                    notifyOnNetworkStatusChange: true,
                                                    awaitRefetchQueries: true,
                                                    refetchQueries: [
                                                        {
                                                            query: GET_TOURNAMENT_NOTIFICATIONS_QUERY,
                                                            variables: { tournament_id: tournament.id },
                                                        },
                                                    ],
                                                })
                                                    .then((data) => {
                                                        console.log(data);
                                                        message.success('Notification deleted');
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        message.error('There was an error', error);
                                                    });
                                            }}
                                            notifications={notifications}
                                        />

                                        <NotifyPlayersModal
                                            tournamentID={tournament.id}
                                            onModalOk={handlePlayerNotifyOk}
                                            onModalCancel={handlePlayerNotifyModalCancel}
                                            modalVisible={playerNotifyModalVisible}
                                            {...props}
                                        />
                                        <NotificationReceipt
                                            notifications={notifications}
                                            selectedNotification={selectedNotification}
                                            onModalOk={handleNotificationReceiptOk}
                                            onModalCancel={handleNotificationReceiptCancel}
                                            modalVisible={notificationModalVisible}
                                            {...props}
                                        />
                                    </Card>
                                );
                            }}
                        </Subscription>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
