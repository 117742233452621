import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Divider, Icon, Typography, Avatar } from 'antd';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete, DatePicker } from 'formik-antd';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { CountryRegionData } from 'react-country-region-selector';
import { Query, Mutation } from '@apollo/client/react/components';
import { getGameType } from '../../utils';
import CircularLoader from '../../components/CircularLoader';
import {
    GET_LEAGUE_PLAYER_STATUS,
    GET_LEAGUE_DIVISION_TEAMS_QUERY,
    GET_LEAGUE_TEAMS_QUERY,
    GET_LEAGUE_TEAM_QUERY,
    GET_LEAGUE_PLAYERS_QUERY,
    GET_LEAGUE_QUERY,
    GET_VENUES_QUERY,
    SEARCH_VENUES_QUERY,
    GET_LEAGUE_DIVISIONS_SELECT_QUERY,
    GET_LEAGUE_DIVISION_QUERY,
    GET_LEAGUE_DIVISION_PLAYERS_QUERY,
} from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    CREATE_LEAGUE_TEAM,
    UPDATE_LEAGUE_TEAM,
    UPDATE_LEAGUE_TEAM_PLAYER,
    DELETE_LEAGUE_TEAM_PLAYER,
    UPDATE_LEAGUE_TEAM_DIVISION,
    DELETE_LEAGUE_TEAM_DIVISION,
    UPDATE_LEAGUE_PLAYER_DIVISION,
} from './data/mutations';
import _ from 'lodash';
import moment from 'moment-timezone';
import AddVenueModal from './AddVenueModal';
import slugify from 'slugify';

const { Title, Text } = Typography;
const { Option } = Select;

function AddTeamModal(props) {
    const { authState, client, league, selectedTeamId, userID, leagueID, divisionID, modalVisible, onModalOk, onModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState();
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState();

    const [createLeagueTeam, { createLoading, createError }] = useMutation(CREATE_LEAGUE_TEAM);
    const [updateLeagueTeam, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_TEAM);
    const [updateLeagueTeamPlayer] = useMutation(UPDATE_LEAGUE_TEAM_PLAYER);
    const [updateLeagueTeamDivision] = useMutation(UPDATE_LEAGUE_TEAM_DIVISION);
    const [deleteLeagueTeamPlayer] = useMutation(DELETE_LEAGUE_TEAM_PLAYER);
    const [deleteLeagueTeamDivision] = useMutation(DELETE_LEAGUE_TEAM_DIVISION);
    const [updateLeaguePlayerDivision] = useMutation(UPDATE_LEAGUE_PLAYER_DIVISION);
    const [venueDataSource, setVenueDataSource] = useState('');
    const formRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            const res = await client.query({
                query: GET_LEAGUE_TEAM_QUERY,
                variables: {
                    id: selectedTeamId,
                    league_id: leagueID,
                },
            });

            console.log(res);

            return res.data;
        };

        setSelectedDivision(undefined);
        if (selectedTeamId) {
            const data = fetchData();
            const team = data && data.league_teams && data.league_teams.length > 0 ? data.league_teams[0] : null;
            setSelectedDivision(team && team.league_team_players ? team.league_team_players.map((item) => item.league_player.id) : undefined);
        } else if (divisionID) {
            setSelectedDivision(divisionID);
        }
    }, [selectedTeamId, divisionID, leagueID, client]);

    console.log(divisionID);

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleDivisionChange(value) {
        setSelectedDivision(value);
    }

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelect(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    const submitAdd = (data, actions) => {
        const { name, venue_id, captain_id, co_captain_id } = data;
        setLoading(true);
        console.log(data);

        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@?]/g,
            lower: true,
        });

        createLeagueTeam({
            variables: {
                objects: [
                    {
                        league_id: props.leagueID,
                        name: data.name,
                        slug,
                        ...(data.status && { status: data.status }),
                        ...(data.league_dues_paid && { league_dues_paid: data.league_dues_paid }),
                        ...(data.last_payment_date && { last_payment_date: data.last_payment_date }),
                        venue_id,
                        captain_id,
                        co_captain_id,
                        ...(data.league_team_players && {
                            league_team_players: {
                                data: data.league_team_players.map((id) => {
                                    return {
                                        league_player_id: id,
                                        league_id: leagueID,
                                    };
                                }),
                            },
                        }),
                        ...(data.league_team_divisions && data.league_team_divisions.length > 0
                            ? {
                                  league_team_divisions: {
                                      data: data.league_team_divisions.map((division_id) => {
                                          return {
                                              league_id: leagueID,
                                              // team_id: selectedTeamId,
                                              division_id: division_id || props.divisionID,
                                          };
                                      }),
                                  },
                              }
                            : {
                                  league_team_divisions: {
                                      data: props.divisionID ? [props.divisionID] : [],
                                  },
                              }),
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: divisionID
                ? [
                      {
                          query: GET_LEAGUE_TEAMS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_QUERY,
                          variables: { id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                          variables: { league_id: leagueID, division_id: divisionID },
                      },
                      //   {
                      //       query: GET_LEAGUE_DIVISION_QUERY,
                      //       variables: { leagueId: leagueID },
                      //   },
                      {
                          query: GET_LEAGUE_DIVISION_TEAMS_QUERY,
                          variables: { league_id: leagueID, division_id: divisionID },
                      },
                  ]
                : [
                      {
                          query: GET_LEAGUE_TEAMS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_QUERY,
                          variables: { id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_PLAYERS_QUERY,
                          variables: { league_id: leagueID },
                      },
                  ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.success('Team successfully added');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.info('There was an error', error);
                onModalOk();
            });
    };

    const submitUpdate = (data, team) => {
        const { name } = data;
        console.log(data);
        setLoading(true);

        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@?]/g,
            lower: true,
        });

        updateLeagueTeam({
            variables: {
                id: selectedTeamId,
                changes: {
                    league_id: leagueID,
                    name: data.name,
                    slug,
                    status: data.status,
                    league_dues_paid: data.league_dues_paid,
                    last_payment_date: data.last_payment_date,
                    venue_id: data.venue_id,
                    captain_id: data.captain_id,
                    co_captain_id: data.co_captain_id,
                    // league_team_players: {
                    // 	data: data.league_team_players.map((id) => {
                    // 		return {
                    // 			league_player_id: id
                    // 		};
                    // 	})
                    // }
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: divisionID
                ? [
                      {
                          query: GET_LEAGUE_TEAMS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_QUERY,
                          variables: { id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_DIVISION_TEAMS_QUERY,
                          variables: { league_id: leagueID, division_id: divisionID },
                      },
                      {
                          query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                          variables: { league_id: leagueID, division_id: divisionID },
                      },
                  ]
                : [
                      {
                          query: GET_LEAGUE_TEAMS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_QUERY,
                          variables: { id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_PLAYERS_QUERY,
                          variables: { league_id: leagueID },
                      },
                  ],
        })
            .then((res) => {
                console.log('data', data);
                console.log('team', team);
                const original = team.league_team_players.map((item) => item.id);
                const toUpdate = _.uniq(data.league_team_players, original);
                const toDelete = _.difference(original, toUpdate);
                console.log('original', original);
                console.log('new', data.league_team_players);
                console.log('update', toUpdate);
                console.log('delete', toDelete);

                if (toDelete.length > 0) {
                    deleteLeagueTeamPlayer({
                        variables: {
                            team_id: selectedTeamId,
                        },
                    })
                        .then((data) => {
                            console.log(data);
                            updateTeamPlayers();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    updateTeamPlayers();
                }

                function updateTeamPlayers() {
                    updateLeagueTeamPlayer({
                        variables: {
                            team_id: selectedTeamId,
                            objects: toUpdate.map((playerId) => {
                                return {
                                    team_id: selectedTeamId,
                                    league_player_id: playerId,
                                    league_id: leagueID,
                                };
                            }),
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: divisionID
                            ? [
                                  {
                                      query: GET_LEAGUE_TEAMS_QUERY,
                                      variables: { league_id: leagueID },
                                  },
                                  {
                                      query: GET_LEAGUE_DIVISION_TEAMS_QUERY,
                                      variables: { league_id: leagueID, division_id: divisionID },
                                  },
                              ]
                            : [
                                  {
                                      query: GET_LEAGUE_TEAMS_QUERY,
                                      variables: { league_id: leagueID },
                                  },
                              ],
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

                if (league.has_division_support === true) {
                    const originalDivision = team.league_team_divisions.map((item) => item.division_id);
                    const toUpdateDivision = _.uniq(data.league_team_divisions, originalDivision);
                    const toDeleteDivision = _.difference(originalDivision, toUpdateDivision);

                    const allValues = originalDivision.concat(toUpdateDivision);
                    const uniqueValues = new Set(allValues.map((id) => id));

                    console.log(uniqueValues.size);
                    console.log(uniqueValues);
                    console.log(allValues.length);
                    console.log(allValues);

                    console.log('original division', originalDivision);
                    console.log('new division', data.league_team_divisions);
                    console.log('update division', toUpdateDivision);
                    console.log('delete division', toDeleteDivision);

                    if (toDeleteDivision.length > 0) {
                        deleteLeagueTeamDivision({
                            variables: {
                                team_id: selectedTeamId,
                            },
                        })
                            .then((data) => {
                                console.log(data);
                                updateTeamDivision();
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        if (uniqueValues.size >= allValues.length) {
                            updateTeamDivision();
                        } else {
                            setLoading(false);
                            message.success('Changes saved');
                            onModalOk();
                        }
                    }

                    function updateTeamDivision() {
                        updateLeagueTeamDivision({
                            variables: {
                                team_id: selectedTeamId,
                                objects: toUpdateDivision.map((divisionId) => {
                                    return {
                                        league_id: leagueID,
                                        team_id: selectedTeamId,
                                        division_id: divisionId || props.divisionID,
                                    };
                                }),
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: divisionID
                                ? [
                                      {
                                          query: GET_LEAGUE_TEAMS_QUERY,
                                          variables: { league_id: leagueID },
                                      },
                                      {
                                          query: GET_LEAGUE_DIVISION_TEAMS_QUERY,
                                          variables: { league_id: leagueID, division_id: divisionID },
                                      },
                                      // {
                                      // 	query: GET_LEAGUE_QUERY,
                                      // 	variables: { id: leagueID }
                                      // }
                                  ]
                                : [
                                      {
                                          query: GET_LEAGUE_TEAMS_QUERY,
                                          variables: { league_id: leagueID },
                                      },
                                  ],
                        })
                            .then((data) => {
                                console.log(data);
                                setLoading(false);
                                message.success('Changes saved');
                                onModalOk();
                            })
                            .catch((error) => {
                                console.log(error);
                                message.info('There was an error', error);
                                onModalOk();
                            });

                        // const originalPlayers = team.league_player_divisions.map((item) => item.division_id);
                        // const toUpdatePlayers = _.uniq(data.league_player_divisions, originalPlayers);
                        // const toDeletePlayers = _.difference(originalPlayers, toUpdatePlayers);
                        // console.log('original player divisions', originalPlayers);
                        // console.log('new player divisions', data.league_player_divisions);
                        // console.log('update player divisions', toUpdatePlayers);
                        // console.log('delete player divisions', toDeletePlayers);

                        // if (toDelete.length > 0) {
                        // 	deleteLeaguePlayerDivision({
                        // 		variables: {
                        // 			league_player_id: selectedPlayerId
                        // 		}
                        // 	})
                        // 		.then((data) => {
                        // 			console.log(data);
                        // 		})
                        // 		.catch((error) => {
                        // 			console.log(error);
                        // 		});
                        // }

                        toUpdate.forEach((playerId) => {
                            updateLeaguePlayerDivision({
                                variables: {
                                    league_player_id: playerId,
                                    objects: toUpdateDivision.map((divisionId) => {
                                        return {
                                            league_id: leagueID,
                                            league_player_id: playerId,
                                            division_id: divisionId,
                                        };
                                    }),
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: divisionID
                                    ? [
                                          {
                                              query: GET_LEAGUE_PLAYERS_QUERY,
                                              variables: { league_id: leagueID },
                                          },
                                          {
                                              query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                                              variables: { league_id: leagueID, division_id: divisionID },
                                          },
                                          {
                                              query: GET_LEAGUE_QUERY,
                                              variables: { id: leagueID },
                                          },
                                      ]
                                    : [
                                          {
                                              query: GET_LEAGUE_PLAYERS_QUERY,
                                              variables: { league_id: leagueID },
                                          },
                                          {
                                              query: GET_LEAGUE_QUERY,
                                              variables: { id: leagueID },
                                          },
                                      ],
                            })
                                .then((data) => {
                                    console.log(data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        name: Yup.string().required('Required'),
        // division_id: Yup.string().required('Required')
    });

    const renderForm = (team) => {
        const initialValues = selectedTeamId
            ? {
                  ...team,
                  league_team_divisions: team && team.league_team_divisions ? team.league_team_divisions.map((item) => item.league_division.id) : [],
                  league_team_players: team && team.league_team_players ? team.league_team_players.map((item) => item.league_player.id) : [],
                  venue_id: team && team.venue && team.venue.id,
                  venue_name: team && team.venue && team.venue.name,
              }
            : {
                  league_team_divisions: divisionID ? [divisionID] : [],
                  name: '',
                  league_dues_paid: true,
                  last_payment_date: '',
                  venue_id: undefined,
                  venue_name: undefined,
                  captain_id: undefined,
                  co_captain_id: undefined,
              };

        return (
            <React.Fragment>
                <Formik
                    ref={formRef}
                    onSubmit={(values, actions) => {
                        const merged = {
                            ...values,
                            ...selected,
                        };
                        if (selectedTeamId) {
                            submitUpdate(merged, team);
                        } else {
                            submitAdd(merged, actions);
                        }
                    }}
                    validationSchema={schema}
                    enableReinitialize
                    initialValues={initialValues}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Modal
                            title={selectedTeamId ? 'Update Team' : 'Add Team'}
                            // getContainer={() => document.querySelector('.tables')}
                            visible={modalVisible}
                            onOk={onModalOk}
                            onCancel={onModalCancel}
                            centered
                            transitionName="fade"
                            // transitionName="none"
                            maskTransitionName="none"
                            footer={[
                                <Button key="cancel" onClick={onModalCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    onClick={() => {
                                        formRef.current.handleSubmit();
                                    }}
                                >
                                    {selectedTeamId ? (loading ? 'Updating...' : 'Update Team') : 'Add Team'}
                                </Button>,
                            ]}
                            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                            destroyOnClose={true}
                        >
                            <Form
                                onSubmit={handleSubmit}
                                layout="vertical"
                                style={{ maxWidth: 600 }}
                                // onSubmit={(e) => {
                                // 	e.preventDefault();
                                // 	console.log(values);
                                // 	// handleSubmit(values);
                                // }}
                            >
                                {league.has_division_support === true && (
                                    <FormItem
                                        label={props.division ? 'Division' : 'Divisions'}
                                        name="league_team_divisions"
                                        required
                                        hasFeedback
                                        showValidateSuccess
                                    >
                                        <Query
                                            query={GET_LEAGUE_DIVISIONS_SELECT_QUERY}
                                            fetchPolicy="cache-and-network"
                                            variables={{
                                                leagueId: leagueID,
                                            }}
                                            notifyOnNetworkStatusChange
                                        >
                                            {({ loading, error, data }) => {
                                                if (loading) return <div>Loading...</div>;
                                                if (error) return <div>Error: {error.message}</div>;

                                                return (
                                                    <Select
                                                        showSearch
                                                        mode={props.division ? 'single' : 'multiple'}
                                                        name="league_team_divisions"
                                                        placeholder="Select division"
                                                        size="large"
                                                        loading={loading}
                                                        defaultValue={selectedDivision}
                                                        // disabled={divisionID ? true : false}
                                                        onChange={handleDivisionChange}
                                                        disabled={props.division ? true : false}
                                                        allowClear={props.division ? false : true}
                                                        optionFilterProp="children"
                                                        optionLabelProp="children"
                                                        filterOption={(inputValue, option) => {
                                                            return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                        }}
                                                    >
                                                        {data &&
                                                            data.league_divisions &&
                                                            data.league_divisions.map((item, index) => (
                                                                <Option
                                                                    key={index}
                                                                    name={item.name}
                                                                    value={item.id}
                                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                                >
                                                                    <Avatar
                                                                        size={24}
                                                                        src={getGameType(item.game_types && item.game_types[0].game_type)}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    />
                                                                    <Text>{item.name}</Text>
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                );
                                            }}
                                        </Query>
                                    </FormItem>
                                )}

                                <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                    <Input name="name" placeholder="Name" size="large" />
                                </FormItem>

                                <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AutoComplete
                                            name="venue_name"
                                            dataSource={venueDataSource}
                                            placeholder="Search for a venue"
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            size="large"
                                            filterOption={(inputValue, option) => {
                                                return (
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                );
                                            }}
                                            dropdownRender={(menu) => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div
                                                        style={{
                                                            padding: '4px 8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={handleAddVenue}
                                                    >
                                                        <Icon type="plus" /> Add Venue
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            {venueDataSource &&
                                                venueDataSource.map((item, index) => (
                                                    <Option key={index} value={`${item.id}`}>
                                                        {item.name} - {item.city}, {item.region}
                                                    </Option>
                                                ))}
                                        </AutoComplete>
                                        <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                            <Icon type="plus" /> Add Venue
                                        </Button>
                                    </div>
                                </FormItem>

                                {/* <FormItem label="Venue" name="venue_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_VENUES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        onCompleted={(data) => {
                                            // console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="venue_id"
                                                    placeholder="Select a venue for the match"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div
                                                                style={{
                                                                    padding: '4px 8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                onClick={handleAddVenue}
                                                            >
                                                                <Icon type="plus" /> Add Venue
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {data.venues.map((item, index) => (
                                                        <Option key={index} value={item.id}>
                                                            <Text>
                                                                {item.name} ({item.city}, {item.region})
                                                            </Text>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem> */}

                                <FormItem label="Players" name="league_team_players" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_LEAGUE_PLAYERS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        variables={{
                                            league_id: leagueID,
                                        }}
                                        notifyOnNetworkStatusChange
                                    >
                                        {({ loading, error, data }) => {
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    name="league_team_players"
                                                    placeholder="Select team players"
                                                    size="large"
                                                    loading={loading}
                                                    allowClear
                                                    optionFilterProp="children"
                                                    optionLabelProp="children"
                                                    filterOption={(inputValue, option) => {
                                                        return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data &&
                                                        data.league_players &&
                                                        data.league_players.map((item, index) => {
                                                            let name;

                                                            if (item.nickname) {
                                                                const nameSplit = item.name.split(' ');
                                                                const firstName = nameSplit[0];
                                                                const lastName = nameSplit[1];
                                                                name = `${firstName} "${item.nickname}" ${lastName}`;
                                                            } else {
                                                                name = item.name;
                                                            }
                                                            return (
                                                                <Option key={index} name={name} value={item.id}>
                                                                    {item.user_id && (
                                                                        <Avatar
                                                                            size={24}
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                marginRight: 8,
                                                                            }}
                                                                        >
                                                                            <Icon type="user" />
                                                                        </Avatar>
                                                                    )}
                                                                    <Text>{name}</Text>
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                <FormItem label="Captain" name="captain_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_LEAGUE_PLAYERS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        variables={{
                                            league_id: leagueID,
                                        }}
                                        notifyOnNetworkStatusChange={true}
                                    >
                                        {({ loading, error, data }) => {
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    name="captain_id"
                                                    placeholder="Select team captain"
                                                    size="large"
                                                    loading={loading}
                                                    allowClear
                                                    optionFilterProp="children"
                                                    optionLabelProp="children"
                                                    filterOption={(inputValue, option) => {
                                                        return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data &&
                                                        data.league_players &&
                                                        data.league_players.map((item, index) => (
                                                            <Option key={index} name={item.name} value={item.id}>
                                                                {item.user_id && (
                                                                    <Avatar
                                                                        size={24}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    >
                                                                        <Icon type="user" />
                                                                    </Avatar>
                                                                )}
                                                                <Text>{item.name}</Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                <FormItem label="Co-captain" name="co_captain_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_LEAGUE_PLAYERS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        variables={{
                                            league_id: leagueID,
                                        }}
                                        notifyOnNetworkStatusChange={true}
                                    >
                                        {({ loading, error, data }) => {
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    name="co_captain_id"
                                                    placeholder="Select team co-captain"
                                                    size="large"
                                                    loading={loading}
                                                    allowClear
                                                    optionFilterProp="children"
                                                    optionLabelProp="children"
                                                    filterOption={(inputValue, option) => {
                                                        return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data &&
                                                        data.league_players &&
                                                        data.league_players.map((item, index) => (
                                                            <Option key={index} name={item.name} value={item.id}>
                                                                {item.user_id && (
                                                                    <Avatar
                                                                        size={24}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    >
                                                                        <Icon type="user" />
                                                                    </Avatar>
                                                                )}
                                                                <Text>{item.name}</Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                <FormItem label="Status" name="status" hasFeedback showValidateSuccess>
                                    <Query query={GET_LEAGUE_PLAYER_STATUS} fetchPolicy="cache-and-network" notifyOnNetworkStatusChang>
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select showSearch name="status" placeholder="Select team status" size="large" optionFilterProp="children">
                                                    {data.league_player_status &&
                                                        data.league_player_status.map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                {item.value}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                <FormItem name="league_dues_paid" label="League dues paid">
                                    <Radio.Group name="league_dues_paid">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label="Last payment date" name="last_payment_date">
                                    <DatePicker
                                        showTime
                                        name="last_payment_date"
                                        size="large"
                                        format="YYYY-MM-DD hh:mm A z"
                                        // onChange={onDateChange}
                                        onOk={onOk}
                                    />
                                </FormItem>
                                <FormItem name="user_id">
                                    <Input name="user_id" type="hidden" />
                                </FormItem>
                                <AddVenueModal
                                    {...props}
                                    modalVisible={venueModalVisible}
                                    onModalOk={handleVenueModalOk}
                                    onModalCancel={handleVenueModalCancel}
                                />
                            </Form>
                        </Modal>
                    )}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {selectedTeamId ? (
                <Query
                    query={GET_LEAGUE_TEAM_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedTeamId, league_id: leagueID }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const team = data && data.league_teams && data.league_teams.length > 0 ? data.league_teams[0] : null;

                        return <React.Fragment>{renderForm(team)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddTeamModal);
