import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from 'antd';
import TBAvatarSettingsForm from './TBAvatarSettingsForm';
import TBLogoSettingsForm from './TBLogoSettingsForm';
import TBFlyerUploadForm from './TBFlyerUploadForm';
const { Title, Text } = Typography;

function TBLogoAvatarSettings(props) {
    return (
        <React.Fragment>
            <div style={{ marginBottom: 10 }}>
                <Title level={4}>Upload Flyer</Title>
                <Text>Flyers must in .jpg or .png format and 2MB or less.</Text>
            </div>
            <TBFlyerUploadForm {...props} />
            <div style={{ marginTop: 20, marginBottom: 10 }}>
                <Title level={4}>Upload Avatar</Title>
                <Text>Avatars must in .jpg or .png format and 2MB or less.</Text>
            </div>
            <TBAvatarSettingsForm {...props} />
            <div style={{ marginTop: 20, marginBottom: 10 }}>
                <Title level={4}>Upload Logo</Title>
                <Text>Logos must in .jpg or .png format and 2MB or less.</Text>
            </div>
            <TBLogoSettingsForm {...props} />
        </React.Fragment>
    );
}

export default withRouter(TBLogoAvatarSettings);
