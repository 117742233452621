import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Modal, Button, Divider } from 'antd';
import * as Yup from 'yup';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';
import { CREATE_VENUE_MUTATION, UPDATE_VENUE_MUTATION } from './data/mutations';
import { GET_NEW_VENUES_QUERY, GET_VENUE_TYPES_QUERY, GET_VENUE_BY_ID_QUERY } from './data/queries';
import apiEndPoint from '../../../utils/apiEndpoint';
import Flag from '../../../components/Flag';
import slugify from 'slugify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import axios from 'axios';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d' },
};

function AddVenueModal(props) {
    const { modalVisible, onModalOk, onModalCancel, authState, selectedVenueId } = props;
    const [createVenue] = useMutation(CREATE_VENUE_MUTATION);
    const [updateVenue] = useMutation(UPDATE_VENUE_MUTATION);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState();
    const formRef = useRef();

    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    const schema = Yup.object().shape({
        name: Yup.string().max(50, 'Too Long!').required('Name is required'),
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        region: Yup.string().required('Region is required'),
        postal_code: Yup.string().required('Postal Code is required'),
        email_address: Yup.string().email('Email is invalid'),
        website: Yup.string().url('Website must be a valid url e.g. http://www.example.com)').nullable(),
        phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
        social_facebook: Yup.string().nullable(),
        social_instagram: Yup.string().nullable(),
        social_twitter: Yup.string().nullable(),
    });

    function handleKeyPress(event) {
        console.log(event.key);
        if (event.key === 'Enter') {
            console.log('enter press here! ');
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    }

    function geocodeAddress(values) {
        return axios
            .post(`${apiEndPoint}mapquestGeocodeAddress`, {
                street: encodeURI(values.address1),
                city: encodeURI(values.city),
                state: encodeURI(values.region),
                postalCode: encodeURI(values.postal_code),
            })
            .then((res) => {
                const result = res && res.data && res.data.results && res.data.results[0] && res.data.results[0].locations && res.data.results[0].locations[0];
                const latLng = result.latLng;
                console.log(latLng);
                return latLng;
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });
    }

    async function handleSubmit(values, actions) {
        console.log(values);

        const {
            name,
            description,
            venue_type,
            address1,
            address2,
            city,
            country,
            region,
            postal_code,
            phone_number,
            email_address,
            website,
            social_facebook,
            social_twitter,
            social_instagram,
            operating_hours,
            is_public,
            is_claimed,
            claimed_by_id,
            owner_id,
        } = values;
        setLoading(true);
        const slug = slugify(`${name} ${city}`, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const location = await geocodeAddress(values);
        console.log('location', location);

        if (selectedVenueId) {
            updateVenue({
                variables: {
                    id: selectedVenueId,
                    changes: {
                        name,
                        slug: slug,
                        description,
                        venue_type,
                        address1,
                        address2,
                        city,
                        country,
                        region,
                        postal_code,
                        phone_number,
                        email_address,
                        website,
                        social_facebook,
                        social_twitter,
                        social_instagram,
                        operating_hours,
                        lat: location && location.lat && location.lat.toString(),
                        lng: location && location.lng && location.lng.toString(),
                        ...(location && {
                            location: {
                                type: 'Point',
                                coordinates: [location.lat, location.lng],
                            },
                        }),
                        owner_id,
                        is_public: is_public === 'public' ? true : false,
                        is_claimed,
                        claimed_by_id,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_VENUE_BY_ID_QUERY,
                        variables: { id: selectedVenueId },
                    },
                ],
            })
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    message.success('Changes saved');
                    onModalOk();
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    message.info('There was an error', error);
                    onModalOk();
                });
        } else {
            createVenue({
                variables: {
                    objects: [
                        {
                            name,
                            description,
                            slug: slug,
                            venue_type,
                            address1,
                            address2,
                            city,
                            country,
                            region,
                            postal_code,
                            phone_number,
                            email_address,
                            website,
                            social_facebook,
                            social_twitter,
                            social_instagram,
                            operating_hours,
                            lat: location && location.lat && location.lat.toString(),
                            lng: location && location.lng && location.lng.toString(),
                            ...(location && {
                                location: {
                                    type: 'Point',
                                    coordinates: [location.lat, location.lng],
                                },
                            }),
                            owner_id: authState && authState.user && authState.user.id,
                            is_public: is_public === 'public' ? true : false,
                            // league_venues: {
                            // 	data: {
                            // 		league_id: props.league.id
                            // 	}
                            // }
                            // is_claimed: is_claimed,
                            // claimed_by_id: claimed_by_id
                        },
                    ],
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_NEW_VENUES_QUERY,
                //     },
                // ],
            })
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    if (actions) {
                        actions.setSubmitting(false);
                        actions.resetForm();
                    }

                    message.success('Venue successfully added');
                    onModalOk();
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    if (e.message && e.message.includes('Uniqueness violation')) {
                        message.error('Error: There is already a venue in the same city with that name. Please choose a different name or city.');
                    } else {
                        message.error((e && e.message) || JSON.stringify(e));
                    }

                    onModalOk();
                });
        }
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    function selectCountry(val) {
        setCountry(val);
    }

    function getPriorityList() {
        const priorities = ['US', 'GB', 'CA'];
        let priorityList = [];
        CountryRegionData.forEach((option, index) => {
            if (_.includes(priorities, option[1])) {
                priorityList.push(option);
            }
        });
        return [...priorityList];
    }

    function getCountryData() {
        const priorities = ['US', 'GB', 'CA'];
        let priorityList = [];
        let newList = [];
        CountryRegionData.forEach((option, index) => {
            if (_.includes(priorities, option[1])) {
                priorityList.push(option);
            }
        });

        newList = [...priorityList, ...CountryRegionData];
        return newList;
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const venueMutations = (data) => {
        const venue = data && data.venues && data.venues.length > 0 && data.venues[0];
        const initialValues = venue
            ? {
                  ...venue,
                  name: venue.name,
                  is_public: venue.is_public === true ? 'public' : 'private',
              }
            : {
                  name: '',
                  description: '',
                  venue_type: undefined,
                  address1: '',
                  address2: '',
                  city: '',
                  country: undefined,
                  region: undefined,
                  postal_code: '',
                  phone_number: '',
                  email_address: '',
                  website: '',
                  operating_hours: '',
                  social_facebook: '',
                  social_twitter: '',
                  social_instagram: '',
                  is_public: 'public',
                  is_claimed: false,
                  owner_id: '',
                  claimed_by_id: '',
              };

        return (
            <React.Fragment>
                <Formik
                    ref={formRef}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={schema}
                    validate={(values) => {
                        // console.log(values);
                    }}
                    onSubmit={(values, actions) => {
                        // console.log(values, actions);
                        // actions.setSubmitting(false);
                        // actions.resetForm();
                        handleSubmit(values, actions);
                    }}
                    render={({ errors, touched, values }) => (
                        <Modal
                            title={selectedVenueId ? 'Update Venue' : 'Add Venue'}
                            // getContainer={() => document.querySelector('.tables')}
                            visible={modalVisible}
                            onOk={onModalOk}
                            onCancel={onModalCancel}
                            centered
                            transitionName="fade"
                            // transitionName="none"
                            maskTransitionName="none"
                            // footer={null}
                            footer={[
                                <Button key="cancel" onClick={onModalCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={loading}
                                    onClick={() => {
                                        if (formRef.current) {
                                            formRef.current.handleSubmit();
                                        }
                                    }}
                                >
                                    {selectedVenueId ? (loading ? 'Updating...' : 'Update Venue') : 'Create Venue'}
                                </Button>,
                            ]}
                            bodyStyle={{
                                maxHeight: 500,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                padding: '10px 20px',
                            }}
                            // style={{
                            // 	left: -120
                            // }}
                            destroyOnClose={true}
                        >
                            <Form
                                layout="vertical"
                                style={{ maxWidth: 600 }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(values, createVenue, updateVenue);
                                }}
                            >
                                <FormItem label="Venue Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                    <Input
                                        name="name"
                                        placeholder="Name"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Venue Type" name="venue_type" required hasFeedback showValidateSuccess>
                                    <Query query={GET_VENUE_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    name="venue_type"
                                                    placeholder="Select a venue type"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue="lucy"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {data.venue_types.map((item, index) => (
                                                        <Option key={index} value={item.value}>
                                                            {_.startCase(_.toLower(item.value.replace('_', ' ')))}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                <FormItem label="Description" name="description">
                                    <TextArea
                                        name="description"
                                        rows={4}
                                        placeholder="Description"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Operating Hours" name="operating_hours">
                                    <Input
                                        name="operating_hours"
                                        placeholder="Monday-Friday: 10am to 5pm"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Address 1" name="address1" required hasFeedback showValidateSuccess>
                                    <Input
                                        name="address1"
                                        placeholder="Address 1"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Address 2" name="address2">
                                    <Input
                                        name="address2"
                                        placeholder="Address 2"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="City" name="city" required hasFeedback showValidateSuccess>
                                    <Input
                                        name="city"
                                        placeholder="City"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                {/* <FormItem
																label="Country"
																name="country"
																required
																hasFeedback
																showValidateSuccess
															>
																<CountryDropdown
																	value={country}
																	onChange={(val) => selectCountry(val)}
																	style={{
																		backgroundColor: 'blue',
																		color: 'white',
																		fontSize: 20
																	}}
																	priorityOptions={[ 'US', 'GB', 'CA' ]}
																/>
															</FormItem> */}

                                <FormItem label="Country" name="country" required hasFeedback showValidateSuccess>
                                    <Select
                                        showSearch
                                        name="country"
                                        placeholder="Please select a country"
                                        optionFilterProp="children"
                                        size="large"
                                        filterOption={(input, option) => {
                                            return option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                        dropdownRender={(menu) => (
                                            <React.Fragment>
                                                <div>
                                                    {/* <div>
																					{getPriorityList().map(
																						(option, index) => {
																							return (
																								<Option
																									value={option[1]}
																									key={index}
																								>
																									<span
																										role="img"
																										aria-label={
																											option[0]
																										}
																									>
																										<Flag
																											name={
																												option[1] ||
																												'Unknown'
																											}
																											format="png"
																											pngSize={24}
																											shiny={
																												false
																											}
																											alt={`${option[0]} Flag`}
																											className="flag"
																											basePath="/images/flags"
																										/>{' '}
																									</span>

																									{option[0]}
																								</Option>
																							);
																						}
																					)}
																				</div>
																				<Divider style={{ margin: '4px 0' }} /> */}
                                                    {menu}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    >
                                        {CountryRegionData.map((option, index) => {
                                            return (
                                                <Option value={option[1]} key={index}>
                                                    <span role="img" aria-label={option[0]}>
                                                        <Flag
                                                            name={option[1] || 'Unknown'}
                                                            format="png"
                                                            pngSize={24}
                                                            shiny={false}
                                                            alt={`${option[0]} Flag`}
                                                            className="flag"
                                                            basePath="/images/flags"
                                                        />{' '}
                                                    </span>

                                                    {option[0]}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                                <FormItem label="Region" name="region" required hasFeedback showValidateSuccess>
                                    <Select
                                        showSearch
                                        name="region"
                                        size="large"
                                        placeholder="Please select a state/province/region"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {getRegions(values.country).map((option, index) => (
                                            <Option value={option} key={index}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                                <FormItem label="Postal Code" name="postal_code" required hasFeedback showValidateSuccess>
                                    <Input
                                        name="postal_code"
                                        placeholder="Postal Code"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Phone Number" name="phone_number">
                                    <Input
                                        name="phone_number"
                                        placeholder="Phone Number"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem
                                    label="Email Address"
                                    name="email_address"
                                    // required
                                    hasFeedback
                                    showValidateSuccess
                                >
                                    <Input
                                        name="email_address"
                                        placeholder="Email Address"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Website" name="website">
                                    <Input
                                        name="website"
                                        placeholder="https://www.example.com"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Facebook Link" name="social_facebook">
                                    <Input
                                        name="social_facebook"
                                        placeholder="Facebook Link"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Instagram Link" name="social_instagram">
                                    <Input
                                        name="social_instagram"
                                        placeholder="Instagram Link"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Twitter Link" name="social_twitter">
                                    <Input
                                        name="social_twitter"
                                        placeholder="Twitter Link"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem name="is_public" label="Access">
                                    <Radio.Group name="is_public">
                                        <Radio value="public">Public - Anyone can view this venue.</Radio>
                                        <br />
                                        <Radio value="private">Private - Only users who have been given access can view this venue.</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem name="owner_id">
                                    <Input name="owner_id" type="hidden" />
                                </FormItem>

                                <button type="submit" hidden />

                                {Object.keys(errors).length > 0 && (
                                    <div>
                                        <Text style={styles.error}>Validation errors: ({Object.keys(errors).length})</Text>
                                        <ul style={{ margin: 0 }}>
                                            {Object.entries(errors).map(([key, value]) => {
                                                return (
                                                    <li key={key} style={styles.error}>
                                                        <Text style={styles.error}>{value}</Text>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}

                                {/* <FormItem>
												<SubmitButton
													size="large"
													disabled={Object.keys(errors).length > 0 ? true : false}
													type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
													loading={loading}
												>
													{props.venue ? loading ? 'Updating...' : 'Update' : 'Create'} Venue
												</SubmitButton>
												<ResetButton size="large">Clear</ResetButton>
												<div>
													<Text style={{ color: '#f5222d', marginTop: 10 }}>
														{errors.general}
													</Text>
												</div>
											</FormItem> */}
                            </Form>
                        </Modal>
                    )}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {selectedVenueId ? (
                <Query
                    query={GET_VENUE_BY_ID_QUERY}
                    fetchPolicy="no-cache"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedVenueId }}
                    onCompleted={async (data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        return venueMutations(data);
                    }}
                </Query>
            ) : (
                venueMutations()
            )}
        </React.Fragment>
    );
}

export default withRouter(AddVenueModal);
