import React from 'react';
import { Card, Avatar, Typography, Tag } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { GET_PLAYER_LEAGUES_QUERY } from './data/queries';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { GlobalOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

const { Title, Text } = Typography;

function PlayerLeagues(props) {
    const { authState, player, theme } = props;

    return (
        <Query
            query={GET_PLAYER_LEAGUES_QUERY}
            fetchPolicy="cache-and-network"
            variables={{ player_id: player.id }}
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            onCompleted={(data, loading, error, subscribeToMore) => {}}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.league_players && !data.leagues) || (data && data.league_players.length === 0)) {
                    return (
                        <div style={styles.container}>
                            <ZeroState
                                message="No leagues created or played in yet."
                                messageStyle={{ fontSize: 16 }}
                                showImage={false}
                                style={{ textAlign: 'left' }}
                            />
                        </div>
                    );
                }

                const leagueMember =
                    data &&
                    data.league_players &&
                    data.league_players.map((player) => {
                        return {
                            ...player.league,
                            is_member: true,
                        };
                    });

                const leagues = leagueMember.sort((a, b) => {
                    const createdAtA = moment(a.created_at).valueOf();
                    const createdAtB = moment(b.created_at).valueOf();
                    return createdAtB - createdAtA;
                });

                return (
                    <React.Fragment>
                        {leagues.map((league, index) => {
                            return (
                                <Card
                                    // title={league.name}
                                    bordered={false}
                                    hoverable
                                    style={{ marginBottom: 10 }}
                                    // extra={<a href="#">More</a>}
                                    key={index}
                                >
                                    <Link to={league.is_owner === true ? `/league-manager/${league.slug}` : `/leagues/${league.slug}`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{
                                                        width: 50,
                                                        height: 50,
                                                    }}
                                                >
                                                    <Avatar
                                                        size={50}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        src={league.avatar}
                                                    >
                                                        <GlobalOutlined style={{ color: '#000', fontSize: 20 }} />
                                                    </Avatar>
                                                </div>
                                                <div style={{ marginLeft: 15 }}>
                                                    <Title level={4} style={{ margin: 0 }}>
                                                        {league.name}
                                                    </Title>
                                                    <Text>{league.short_description}</Text>
                                                </div>
                                            </div>
                                            <div>
                                                {league.is_owner === true ? (
                                                    <Tag size="large" color="#f50">
                                                        OWNER
                                                    </Tag>
                                                ) : (
                                                    <Tag color="#1890ff">MEMBER</Tag>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </Card>
                            );
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(PlayerLeagues);
