import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Button, Avatar } from 'antd';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import tournamentBuilderIcon from '../../assets/img/tournament-builder-icon-large.svg';
import Fade from 'react-reveal/Fade';
import * as routes from '../../config/routes';
import headerBg from '../../assets/img/home/tournament-builder-bg.jpg';
import screenshot from '../../assets/img/home/tournament-builder-screenshot.png';
import useBreakpoint from 'use-breakpoint';
import { Player, ControlBar, ReplayControl, ForwardControl, CurrentTimeDisplay, TimeDivider, PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#0D1E35',
        // background: 'linear-gradient(#20242d, #242733)',
        backgroundImage: `url(${headerBg})`,
        backgroundPosition: 'bottom left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundAttachment: 'fixed',
        borderRadius: 0,
        paddingTop: 50,
        paddingLeft: 10,
        paddingRight: 10,
    },
    avatar: {
        background: 'linear-gradient(180deg, #8649fe, #24befe)',
        // background: '#27282f',
    },
    col: {
        marginTop: 5,
        marginBottom: 5,
    },
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

function TBMarketing(props) {
    const { theme } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            <div
                style={{
                    // background: 'linear-gradient(180deg, #081629, #040e1b)',
                    backgroundColor: '#0D1E35',

                    // marginLeft: 60,
                    // position: 'relative',
                    // zIndex: 1,
                    // flexGrow: 1
                }}
            >
                <div style={styles.headerImg}>
                    <Fade>
                        <img src={tournamentBuilderIcon} width={150} alt="" style={{ marginBottom: 20 }} />
                    </Fade>
                    <Fade up distance="50px" delay={200}>
                        <Title level={2} align="center" style={{ color: '#fff', fontWeight: 500, paddingLeft: 20, paddingRight: 20 }}>
                            Become the ultimate tournament director
                        </Title>
                    </Fade>
                    <Fade up distance="50px" delay={100}>
                        <Text
                            align="center"
                            style={{
                                color: '#d6d6d6',
                                fontWeight: 400,
                                fontSize: 20,
                                maxWidth: 800,
                                display: 'inline-block',
                                marginBottom: 25,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            Minimize your workload by automating 90% of the tournament management process right from your mobile device or desktop.
                        </Text>
                    </Fade>
                    <Fade up distance="50px" delay={100}>
                        <Link to={routes.DIGITALPOOL.REGISTER}>
                            <Button
                                type="default"
                                size="large"
                                style={{
                                    textTransform: 'uppercase',
                                    width: breakpoint === 'mobile' ? '80%' : 'auto',
                                }}
                                ghost
                            >
                                Get started free
                            </Button>
                        </Link>
                    </Fade>
                    <div className="player-wrapper" style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: breakpoint === 'mobile' ? '100%' : '80%' }}>
                            <Player
                                playsInline
                                poster="/images/tournament-builder-screenshot.png"
                                src="https://d1k23ln7af6dzb.cloudfront.net/demo-videos/tournament-builder-demo1.mp4"
                                autoPlay={false}
                            />
                        </div>

                        {/* <Player poster="/assets/poster.png">
                            <source src="https://d1k23ln7af6dzb.cloudfront.net/demo-videos/tournament-builder-demo1.mp4" />

                            <ControlBar>
                                <ReplayControl seconds={10} order={1.1} />
                                <ForwardControl seconds={30} order={1.2} />
                                <CurrentTimeDisplay order={4.1} />
                                <TimeDivider order={4.2} />
                                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                <VolumeMenuButton disabled />
                            </ControlBar>
                        </Player> */}

                        {/* <img src={screenshot} alt="UI Screenshot" width={breakpoint === 'mobile' ? '100%' : '80%'} /> */}
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: 30,
                    paddingBottom: 20,
                    // background: 'linear-gradient(#fff, #eff3f5)',
                    // borderBottom: '1px solid rgb(221, 221, 221)',
                    background: 'linear-gradient(45deg, #24befe, #8649fe)',
                }}
            >
                <Title level={3} align="center" style={{ fontWeight: 500, color: '#fff' }}>
                    Everything you need to run world-class pool tournaments
                </Title>
            </div>
            <Row gutter={16} type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            marginTop: 60,
                            marginLeft: 24,
                            marginRight: 24,
                            marginBottom: 60,
                        }}
                    >
                        <Row gutter={100} type="flex" justify="center">
                            <Col xs={24} sm={8} style={styles.col}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-people" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Unique Tournament Formats
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Run Single or Double elimination tournaments as well as group stage, round robin, Fargo Hot Column, chip
                                            tournaments, and ghost events.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-play" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Live Scoring & Brackets
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Say goodbye to people hovering around the tournament board. Players and spectators have access to our live scoring
                                            and brackets through their mobile device.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-notifications" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            SMS Match Notifications
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Notify players via SMS when their match has been assigned to table with information including opponent, start time,
                                            race and table number.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-trophy" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Projected Match Times
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Our software predicts when the next matches will start for every round based on how the tournament is progressing.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-pin" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Tour & Series Support
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Manage multi stop tours with ease. Instantly access past results or qualifications. DigitalPool can easily be
                                            formatted to your specific needs.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-stats" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Real-time Statistics
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            All statistics are instantly updated. Analyze your current tournament stats or compare them to past performances.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-paper" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Register & Pay Online
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Enable players to register and pay for your tournament entry fee online. Waiting lists and RSVP are also supported.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-filing" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Quick Templates
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            Save time by storing complex tournament formats as a quick template or chose from basic tournament types.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ marginRight: 20 }}>
                                        <Avatar size={48} style={styles.avatar}>
                                            <i className="icon ion-md-podium" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <Title level={4} style={{ fontWeight: 600, color: theme.bodyTitle.color }}>
                                            Fargo Rate Integration
                                        </Title>
                                        <Text style={{ color: theme.bodyText.color }}>
                                            All tournaments are submitted to Fargo Rate every few days without any extra effort required.
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                            <Col xs={24} sm={12} align="center">
                                <Title level={3} style={{ marginTop: 20, fontWeight: 600, color: theme.bodyTitle.color }}>
                                    Digital Pool <span style={{ color: '#c9b7ff' }}>Network</span>
                                </Title>
                                <Text style={{ marginTop: 30, marginBottom: 40, color: theme.bodyText.color }}>
                                    Join our network and promote your tournament to a worldwide audience of players, operators, businesses, and venues. Benefit
                                    from new features and product improvements suggested by you, tournament directors and the players. Sign up for free today
                                    and start building your first tournament.
                                </Text>
                                <div style={{ marginTop: 20 }}>
                                    <Link to={routes.DIGITALPOOL.REGISTER}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            style={{
                                                width: breakpoint === 'mobile' ? '100%' : 'auto',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            Get Started Free
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default TBMarketing;
