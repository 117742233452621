import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import poweredByLogo from '../../../assets/img/powered-by-logo.png';
import CircleFlag from '../../../components/CircleFlag';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import RackTracker from './RackTracker';
import VideoJS from './VideoJS';
import RackTable from './RackTable';
import CircularLoader from '../../../components/CircularLoader';
import { GET_TOURNAMENT_MATCH_GAMES_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import _ from 'lodash';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
    inning: {
        fontSize: 20,
        padding: 10,
        textAlign: 'center',
        borderBottom: '1px solid rgba(255,255,255,0.3)',
        borderLeft: '1px solid rgba(255,255,255,0.3)',
    },
    hr: {
        fontSize: 25,
        padding: 10,
        fontWeight: 700,
        textAlign: 'center',
        borderLeft: '1px solid rgba(255,255,255,0.3)',
    },
};

function TVDisplayStraightPool(props) {
    const { tournamentData, matchData, tableData, subscribeToMore, subscribeToNewData } = props;
    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData) {
        if (matchData.identifier && matchData.identifier.includes('L')) {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        } else {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        }
    }

    const playerRef = React.useRef(null);

    const gameData = matchData && matchData.tournament_match_games;
    const currentGame = gameData && gameData[matchData.tournament_match_games.length - 1];

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const videoJsOptions = {
        // lookup the options in the docs for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
            {
                src: 'https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8',
                type: 'application/x-mpegURL',
            },
        ],
    };

    // <source src="https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8" type="application/x-mpegURL" />

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    return (
        <React.Fragment>
            <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%', position: 'relative' }}>
                <Col xs={12} sm={12} align="center">
                    <Title level={1} style={{ marginTop: 0, marginBottom: 0, color: '#fff' }}>
                        {tableData.label}
                    </Title>
                </Col>

                <Col xs={12} sm={12} align="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Avatar size={28} src={getGameType(tournamentData.game_type)} style={{ width: 30, marginRight: 10 }} />
                        <Title level={1} style={{ marginTop: 0, marginBottom: 0, color: '#fff' }}>
                            Race to{' '}
                            {matchData.challenger1_race_to && matchData.challenger2_race_to
                                ? `${challenger1RaceTo}/${challenger2RaceTo}`
                                : matchData.tournament && matchData.tournament.winners_race_to}
                        </Title>
                    </div>
                </Col>
            </Row>
            <Row gutter={24} type="flex" justify="space-between" style={{ width: '100%' }}>
                <Col xs={12} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            background:
                                matchData.challenger1_score > matchData.challenger2_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(-45deg, black, transparent)',
                            // background: 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)',
                            borderRadius: 30,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: 20,
                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                        }}
                    >
                        {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                            <div
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    position: 'absolute',
                                    bottom: 30,
                                    left: 30,
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 80, textTransform: 'uppercase' }}>
                                    {matchData.challenger1_points || 0}
                                </Title>
                                <Title level={1} style={{ margin: 0, color: '#fff', textTransform: 'uppercase' }}>
                                    {matchData.challenger1_points === 1 ? 'Ball' : 'Balls'}
                                </Title>
                            </div>
                        )}

                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                            {matchData.challenger1_country && (
                                <CircleFlag
                                    country={getCountryName(matchData.challenger1_country) || 'Unknown'}
                                    size="large"
                                    style={{ transform: 'scale(2.5)' }}
                                />
                            )}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 70, textAlign: 'center', textTransform: 'uppercase' }}>
                                {matchData.challenger1_name}{' '}
                                {matchData.challenger1_name &&
                                    matchData.challenger1_name !== 'BYE' &&
                                    matchData.challenger1_skill_level &&
                                    `(${matchData.challenger1_skill_level})`}
                            </Title>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ margin: 0, fontSize: 250, lineHeight: '250px', fontWeight: 700, color: '#fff' }}>
                                {matchData.challenger1_score || 0}
                            </Text>
                        </div>
                        {matchData.challenger1_is_playing === true && <div className="arrow-right"></div>}
                        {currentGame && currentGame.player_tpa && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255,255,255, 0.05)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRadius: 10,
                                    fontSize: 20,
                                }}
                            >
                                <Text style={{ fontSize: 30, color: '#fff' }}>
                                    TPA: <b>{currentGame.player_tpa}</b>
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            background:
                                matchData.challenger2_score > matchData.challenger1_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(45deg, black, transparent)',
                            // background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                            borderRadius: 30,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: 20,
                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                        }}
                    >
                        {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                            <div
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    position: 'absolute',
                                    bottom: 30,
                                    right: 30,
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 80, textTransform: 'uppercase' }}>
                                    {matchData.challenger2_points || 0}
                                </Title>
                                <Title level={1} style={{ margin: 0, color: '#fff', textTransform: 'uppercase' }}>
                                    {matchData.challenger2_points === 1 ? 'Ball' : 'Balls'}
                                </Title>
                            </div>
                        )}

                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                            {matchData.challenger2_country && (
                                <CircleFlag
                                    country={getCountryName(matchData.challenger2_country) || 'Unknown'}
                                    size="large"
                                    style={{ transform: 'scale(2.5)' }}
                                />
                            )}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 70, textAlign: 'center', textTransform: 'uppercase' }}>
                                {matchData.challenger2_name}{' '}
                                {matchData.challenger2_name &&
                                    matchData.challenger2_name !== 'BYE' &&
                                    matchData.challenger2_skill_level &&
                                    `(${matchData.challenger2_skill_level})`}
                            </Title>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ margin: 0, fontSize: 250, lineHeight: '250px', fontWeight: 700, color: '#fff' }}>
                                {matchData.challenger2_score || 0}
                            </Text>
                        </div>
                        {matchData.challenger2_is_playing === true && <div className="arrow-left"></div>}
                        {currentGame && currentGame.opponent_tpa && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255,255,255, 0.05)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRsxadius: 10,
                                    fontSize: 20,
                                }}
                            >
                                <Text style={{ fontSize: 30, color: '#fff' }}>
                                    TPA: <b>{currentGame.opponent_tpa}</b>
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>

            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 20, width: '100%' }}>
                <Col xs={24} align="center">
                    <Subscription
                        subscription={GET_TOURNAMENT_MATCH_GAMES_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournamentData.id, tournament_bracket_id: matchData.id }}
                        onSubscriptionData={(data, error, loading) => {
                            console.log(data);
                        }}
                    >
                        {({ loading, error, data, refetch }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div>Error: {error.message}</div>;
                            if (!matchData) {
                                return <div style={styles.container}>No games found.</div>;
                            }
                            const matchGames = data.tournament_match_games;
                            console.log('match games', matchGames);

                            const innings = _.groupBy(matchGames, 'inning');
                            console.log('innings', innings);

                            let playerHighestRun = _.maxBy(matchGames, 'player_high_run');
                            let opponentHighestRun = _.maxBy(matchGames, 'opponent_high_run');

                            let playerHighRuns = [];
                            let opponentHighRuns = [];

                            Object.values(innings).forEach((inning) => {
                                const playerHighRun = _.maxBy(inning, 'player_high_run');
                                if (playerHighRun && playerHighRun.player_high_run > 0) {
                                    playerHighRuns.push(playerHighRun);
                                }

                                const opponentHighRun = _.maxBy(inning, 'opponent_high_run');
                                if (opponentHighRun && opponentHighRun.opponent_high_run > 0) {
                                    opponentHighRuns.push(opponentHighRun);
                                }
                            });

                            console.log('player high runs', playerHighRuns);
                            console.log('opponent high runs', opponentHighRuns);

                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ width: '100%' }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ borderBottom: '1px solid rgba(255,255,255,0.3)', textTransform: 'uppercase' }}>
                                                        <b>Inning</b>
                                                    </th>
                                                    {playerHighRuns &&
                                                        playerHighRuns.map((row, index) => {
                                                            return (
                                                                <th style={styles.inning} key={index}>
                                                                    {row.inning}
                                                                </th>
                                                            );
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width={80} style={{ textTransform: 'uppercase' }}>
                                                        <b>High Run</b>
                                                    </td>
                                                    {playerHighRuns &&
                                                        playerHighRuns.map((row, index) => {
                                                            return (
                                                                <td style={styles.hr} key={index}>
                                                                    {row.player_high_run}
                                                                </td>
                                                            );
                                                        })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ width: 200 }} />
                                    <div style={{ width: '100%' }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ borderBottom: '1px solid rgba(255,255,255,0.3)', textTransform: 'uppercase' }}>
                                                        <b>Inning</b>
                                                    </th>
                                                    {opponentHighRuns &&
                                                        opponentHighRuns.map((row, index) => {
                                                            return (
                                                                <th style={styles.inning} key={index}>
                                                                    {row.inning}
                                                                </th>
                                                            );
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width={80} style={{ textTransform: 'uppercase' }}>
                                                        <b>High Run</b>
                                                    </td>
                                                    {opponentHighRuns &&
                                                        opponentHighRuns.map((row, index) => {
                                                            return (
                                                                <td style={styles.hr} key={index}>
                                                                    {row.opponent_high_run}
                                                                </td>
                                                            );
                                                        })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        }}
                    </Subscription>
                </Col>
            </Row>

            {/* <div style={{ position: 'absolute', width: 600, height: 400, right: 0, bottom: 0, zIndex: 100 }}>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            </div> */}

            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 20, width: '100%', paddingLeft: 20, paddingRight: 20 }}>
                <Col xs={24} align="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
                        <div>
                            <Title level={2} style={{ marginTop: 0, marginBottom: 0, color: '#fff', fontWeight: 400 }}>
                                {tournamentData.name}
                            </Title>
                        </div>

                        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                            <img src={poweredByLogo} alt="Powered By" width={300} />
                        </div>

                        <div>{tournamentData.logo && <img src={tournamentData.logo} alt="Logo" height={100} />}</div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default withRouter(TVDisplayStraightPool);
