import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { PageHeader, Icon, Row, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../components/Card';
import SectionContent from '../../components/SectionContent';
import SectionHeader from '../../components/SectionHeader';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_PLAYER_BY_NAME_QUERY, GET_LEAGUE_PLAYER_STATS_BY_ID_QUERY } from './data/queries';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMPlayerDetail(props) {
    const { league, history } = props;
    const [player, setPlayer] = useState();

    return (
        <React.Fragment>
            <SectionHeader
                title={player && player.name}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                backButton={
                    <Button
                        onClick={() => props.history.goBack()}
                        // onClick={() =>
                        // 	props.history.push(
                        // 		history && history.location && history.location.state && history.location.state.backPath
                        // 			? history.location.state.backPath
                        // 			: `/league-builder/${props.league.slug}/players`
                        // 	)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                subtitle="Create and manage your own leagues in a few simple steps."
                subtitleStyle={{ color: '#fff' }}
                theme="leagueManager"
            />
            {/* <PageHeader
				onBack={() => props.history.push(`/league-builder/${props.league.slug}/players`)}
				backIcon={<Icon type="arrow-left" style={{ color: props.theme === 'dark' ? '#fff' : '#000' }} />}
				title={
					<Text style={{ color: props.theme === 'dark' ? '#fff' : '#000' }}>
						{_.startCase(_.toLower(props.match.params.player.replace('-', ' '))).toUpperCase()}
					</Text>
				}
				style={{
					background:
						props.theme === 'dark'
							? 'linear-gradient(180deg, #07101f, #14243b)'
							: 'linear-gradient(#fff, #fafafa)',
					paddingTop: 24,
					paddingLeft: 40,
					paddingRight: 40,
					paddingBottom: 24,
					display: 'flex',
					alignItems: 'center'
				}}
			/> */}
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_LEAGUE_PLAYER_BY_NAME_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ league_id: league.id, name: props.match.params.player }}
                    onCompleted={(data) => {
                        console.log(data);
                        const player = data.league_players && data.league_players[0];
                        setPlayer(player);
                    }}
                >
                    {({ loading, error, data }) => {
                        const player = data && data.league_players && data.league_players[0];
                        let output;

                        if (player) {
                            output = {
                                id: player.id,
                                name: player.name,
                                country: player.country,
                                skill_level: player.skill_level,
                                status: player.status,
                                points: player.points,
                                seed: player.seed,
                                race_to: player.race_to,
                            };
                        }

                        return (
                            <React.Fragment>
                                {loading && (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                )}
                                {error && <div style={styles.container}>Error: {error.message}</div>}
                                {!loading && !player && (
                                    <Card>
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>player could not be found</Text>}
                                            style={{ margin: 10 }}
                                        >
                                            <Button>Create New player</Button>
                                        </Empty>
                                    </Card>
                                )}
                                {player && (
                                    <Row gutter={24}>
                                        <Col>
                                            <Card title="player Information">
                                                <pre>{JSON.stringify(output, null, 4)}</pre>
                                            </Card>
                                        </Col>
                                        <Query
                                            query={GET_LEAGUE_PLAYER_STATS_BY_ID_QUERY}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{
                                                league_id: league.id,
                                                player_id: player.id,
                                            }}
                                            onCompleted={(data) => {
                                                console.log(data);
                                            }}
                                        >
                                            {({ loading, error, data }) => {
                                                return (
                                                    <React.Fragment>
                                                        {loading && <div>Loading stats...</div>}
                                                        <Col>
                                                            <Card title="player Stats">
                                                                <pre>{JSON.stringify(data, null, 4)}</pre>
                                                            </Card>
                                                        </Col>
                                                    </React.Fragment>
                                                );
                                            }}
                                        </Query>
                                    </Row>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(LMPlayerDetail);
