import { gql } from '@apollo/client';

export const CREATE_TOURNAMENT_BRACKET_MUTATION = gql`
    mutation insert_tournament_brackets($objects: [tournament_brackets_insert_input!]!) {
        insert_tournament_brackets(
            objects: $objects
            on_conflict: { constraint: tournament_brackets_tournament_id_match_number_stage_number_key, update_columns: [] }
        ) {
            affected_rows
            returning {
                id
                table_id
                table_name
            }
        }
    }
`;

export const DELETE_TOURNAMENT_BRACKET_MUTATION = gql`
    mutation delete_tournament_brackets($tournament_id: Int) {
        delete_tournament_brackets(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_STAGE_BRACKET_MUTATION = gql`
    mutation delete_tournament_brackets($tournament_id: Int!, $stage_id: Int) {
        delete_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, stage_id: { _eq: $stage_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_TOURNAMENT_TEMPLATE = gql`
    mutation upsert_tournament_templates($objects: [tournament_templates_insert_input!]!) {
        insert_tournament_templates(objects: $objects, on_conflict: { constraint: tournament_templates_pkey, update_columns: [] }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_TEMPLATE = gql`
    mutation delete_tournament_templates($tournament_id: Int!, $id: Int!) {
        delete_tournament_templates(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_STAGE_MUTATION = gql`
    mutation delete_stages($tournament_id: Int!, $id: Int!) {
        delete_stages(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_STAGE_MUTATION = gql`
    mutation upsert_stages($objects: [stages_insert_input!]!) {
        insert_stages(
            objects: $objects
            on_conflict: {
                constraint: tournament_stages_pkey
                update_columns: [
                    name
                    slug
                    number
                    type
                    status
                    draw_type
                    tournament_type
                    tournament_format
                    usapl_race_format
                    handicap_format
                    autopilot_mode
                    bracket_template_id
                    consolidation_finals
                    max_players
                    winners_race_to
                    losers_race_to
                    split_bracket
                    participant_times_played
                    players_advance_per_group
                    players_per_group
                    players_per_table
                    players_ranked_by
                    default_chip_amount
                    enable_groups
                    finals_race_to
                ]
            }
        ) {
            affected_rows
            returning {
                id
                name
                slug
                number
                type
                status
                tournament_id
                draw_type
                tournament_type
                tournament_format
                usapl_race_format
                handicap_format
                autopilot_mode
                bracket_template_id
                consolidation_finals
                max_players
                winners_race_to
                losers_race_to
                split_bracket
                participant_times_played
                players_advance_per_group
                players_per_group
                players_per_table
                players_ranked_by
                default_chip_amount
                enable_groups
                finals_race_to
            }
        }
    }
`;

// export const UPDATE_STAGE_MUTATION = gql`
//     mutation update_stages($id: Int, $tournament_id: Int, $changes: stages_set_input) {
//         update_stages(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }, _set: $changes) {
//             affected_rows
//             returning {
//                 id
//                 name
//                 slug
//                 number
//                 type
//                 status
//                 tournament_id
//                 draw_type
//                 tournament_type
//                 tournament_format
//                 usapl_race_format
//                 handicap_format
//                 autopilot_mode
//                 bracket_template_id
//                 consolidation_finals
//                 max_players
//                 winners_race_to
//                 losers_race_to
//                 split_bracket
//                 participant_times_played
//                 players_advance_per_group
//                 players_per_group
//                 players_per_table
//                 players_ranked_by
//                 default_chip_amount
//                 enable_groups
//                 finals_race_to
//             }
//         }
//     }
// `;

export const CREATE_TOURNAMENT_MUTATION = gql`
    mutation insert_tournaments($objects: [tournaments_insert_input!]!) {
        insert_tournaments(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                max_players
                consolidation_finals
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_MUTATION = gql`
    mutation update_tournaments($id: Int, $changes: tournaments_set_input) {
        update_tournaments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                description
                avatar
                flyer
                logo
                tournament_type
                tournament_format
                bracket_template_id
                participant_times_played
                enable_groups
                enable_sets
                sets_per_match
                set_tiebreaker_format
                split_bracket
                players_per_group
                players_advance_per_group
                players_ranked_by
                handicap_format
                player_type
                game_type
                winners_race_to
                winners_max_race
                losers_race_to
                losers_max_race
                finals_race_to
                race_to
                consolidation_finals
                max_players
                max_tables
                rebuys_allowed
                max_rebuys
                rule_format
                break_format
                status
                progress
                start_date_time
                end_date_time
                entry_fee
                admin_fee
                added_money
                payout_type
                draw_type
                rating_system
                use_text_messaging
                use_in_app_messaging
                rsvp_allowed
                show_player_skill_levels
                show_player_races
                show_unconfirmed_players
                is_public
                is_featured
                is_fargo_reported
                tournament_stage_format
                signup_cutoff_time
                usapl_race_format
                filter_by_round
                players_per_table
                redraw_every_round
                default_chip_amount
                autopilot_mode
                event_id
                director_id
                director {
                    id
                    first_name
                    last_name
                    email
                }
                organizer_id
                organizer {
                    id
                    first_name
                    last_name
                    email
                }
                venue {
                    id
                    name
                    slug
                    description
                    venue_type
                    avatar
                    address1
                    address2
                    city
                    country
                    region
                    postal_code
                    phone_number
                    email_address
                    website
                    operating_hours
                    social_facebook
                    social_instagram
                    social_twitter
                }
                stages {
                    id
                    name
                    slug
                    number
                    type
                    status
                    tournament_id
                    draw_type
                    tournament_type
                    tournament_format
                    usapl_race_format
                    handicap_format
                    autopilot_mode
                    bracket_template_id
                    consolidation_finals
                    max_players
                    winners_race_to
                    losers_race_to
                    split_bracket
                    participant_times_played
                    players_advance_per_group
                    players_per_group
                    players_per_table
                    players_ranked_by
                    default_chip_amount
                    enable_groups
                    finals_race_to
                }
                tournament_templates {
                    id
                    name
                    tournament_id
                    user_id
                }
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_STATUS = gql`
    mutation update_tournaments($id: Int!, $changes: tournaments_set_input!) {
        update_tournaments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UNLOCK_TOURNAMENT_MUTATION = gql`
    mutation update_tournaments($id: Int!) {
        update_tournaments(where: { id: { _eq: $id } }, _set: { status: IN_PROGRESS, progress: "100" }) {
            affected_rows
        }
    }
`;

export const CREATE_TABLE_MUTATION = gql`
    mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
        insert_pool_tables(objects: $objects) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_POINTS = gql`
    mutation update_tournament_points($id: Int!, $place: String!, $points: Int) {
        __typename
        update_tournament_points(where: { id: { _eq: $id } }, _set: { place: $place, points: $points }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_POINTS_ENTRY = gql`
    mutation delete_tournament_points($id: Int!) {
        delete_tournament_points(where: { id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TABLE_MUTATION = gql`
    mutation update_pool_tables($id: Int!, $changes: pool_tables_set_input!) {
        update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const RESET_TABLE_STATUS_MUTATION = gql`
    mutation update_pool_tables($tournament_id: Int!) {
        update_pool_tables(where: { tournament_id: { _eq: $tournament_id } }, _set: { status: OPEN }) {
            affected_rows
        }
    }
`;

export const DELETE_TABLE_MUTATION = gql`
    mutation delete_pool_tables($id: Int) {
        delete_pool_tables(where: { id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_MUTATION = gql`
    mutation delete_tournaments($tournament_id: Int) {
        delete_tournaments(where: { id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_CHALLENGER1_MATCH_MUTATION = gql`
    mutation update_tournament_brackets($player_id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, challenger1_id: { _eq: $player_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_CHALLENGER2_MATCH_MUTATION = gql`
    mutation update_tournament_brackets($player_id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, challenger2_id: { _eq: $player_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_MATCH_MUTATION = gql`
    mutation update_tournament_brackets($id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION = gql`
    mutation update_tournament_brackets($player_id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, _and: { _or: [{ challenger1_id: { _eq: $player_id } }, { challenger2_id: { _eq: $player_id } }] } }
            _set: $changes
        ) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_GROUP_MUTATION = gql`
    mutation update_tournament_brackets($group: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, group: { _eq: $group } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TABLE_STATUS_MUTATION = gql`
    mutation update_pool_tables($id: Int!, $changes: pool_tables_set_input!) {
        update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_TOURNAMENT_PLAYER_MUTATION = gql`
    mutation delete_tournament_players($tournament_id: Int!, $id: Int!) {
        delete_tournament_players(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_VENUE_TABLE_MUTATION = gql`
    mutation insert_venue_pool_tables($objects: [venue_pool_tables_insert_input!]!) {
        insert_venue_pool_tables(objects: $objects) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_VENUE_TABLE_MUTATION = gql`
    mutation update_venue_pool_tables($id: Int, $changes: venue_pool_tables_set_input) {
        update_venue_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const CREATE_VENUE_MUTATION = gql`
    mutation insert_venues($objects: [venues_insert_input!]!) {
        insert_venues(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const UPDATE_VENUE_MUTATION = gql`
    mutation update_venues($id: Int, $changes: venues_set_input) {
        update_venues(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const DELETE_VENUE_MUTATION = gql`
    mutation delete_venues($venue_id: Int) {
        delete_venues(where: { id: { _eq: $venue_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_EVENT_MUTATION = gql`
    mutation insert_events($objects: [events_insert_input!]!) {
        insert_events(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
            }
        }
    }
`;

export const UPDATE_EVENT_MUTATION = gql`
    mutation update_events($id: Int, $changes: events_set_input) {
        update_events(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
            }
        }
    }
`;

export const DELETE_EVENT_MUTATION = gql`
    mutation delete_events($id: Int!, $league_id: Int) {
        delete_events(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_SIDEPOT_MUTATION = gql`
    mutation update_tournament_sidepots($tournament_id: Int!, $changes: tournament_sidepots_set_input) {
        update_tournament_sidepots(where: { tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                amount
            }
        }
    }
`;

export const CREATE_TOURNAMENT_SIDEPOT_MUTATION = gql`
    mutation insert_tournament_sidepots($objects: [tournament_sidepots_insert_input!]!) {
        insert_tournament_sidepots(objects: $objects) {
            affected_rows
            returning {
                id
                name
                amount
            }
        }
    }
`;

export const DELETE_TOURNAMENT_SIDEPOT_MUTATION = gql`
    mutation delete_tournament_sidepots($tournament_id: Int!) {
        delete_tournament_sidepots(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_PLAYER_MUTATION = gql`
    mutation upsert_players($objects: [players_insert_input!]!) {
        insert_players(objects: $objects, on_conflict: { constraint: players_name_slug_user_id_key, update_columns: [] }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_TOURNAMENT_PLAYER = gql`
    mutation upsert_tournament_players($objects: [tournament_players_insert_input!]!) {
        insert_tournament_players(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_PLAYER = gql`
    mutation update_tournament_players($id: Int, $changes: tournament_players_set_input) {
        update_tournament_players(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                nickname
                country
                email
                country_phone
                city
                region
                phone_number
                skill_level
                status
                points
                seed
                race_to
                special_group
                group
                fargo_id
                user_id
                user {
                    id
                    first_name
                    last_name
                    nickname
                    display_name
                    avatar
                    country
                    city
                    region
                    email
                    country_phone
                    phone_number
                    skill_level
                    fargo_id
                }
                tournament_matches_challenger1(limit: 1) {
                    id
                    hash_id
                    round
                    identifier
                    match_number
                    table_id
                }
                tournament_matches_challenger2(limit: 1) {
                    id
                    hash_id
                    round
                    identifier
                    match_number
                    table_id
                }
            }
        }
    }
`;

// export const CREATE_TOURNAMENT_PLAYER = gql`
// 	mutation upsert_tournament_players($objects: [tournament_players_insert_input!]!) {
// 		insert_tournament_players(objects: $objects) {
// 			affected_rows
// 			returning {
// 				id
// 			}
// 		}
// 	}
// `;

export const CREATE_TOURNAMENT_PAYOUTS_MUTATION = gql`
    mutation insert_tournament_payouts($objects: [tournament_payouts_insert_input!]!) {
        insert_tournament_payouts(objects: $objects) {
            affected_rows
            returning {
                id
                tournament_id
                tournament_player
                money
                place
            }
        }
    }
`;

export const DELETE_TOURNAMENT_PAYOUTS_MUTATION = gql`
    mutation delete_tournament_payouts($tournament_id: Int!) {
        delete_tournament_payouts(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_POINTS_MUTATION = gql`
    mutation delete_tournament_points($tournament_id: Int!) {
        delete_tournament_points(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_TOURNAMENT_POINTS_MUTATION = gql`
    mutation insert_tournament_points($objects: [tournament_points_insert_input!]!) {
        insert_tournament_points(objects: $objects) {
            affected_rows
            returning {
                id
                tournament_id
                place
                points
            }
        }
    }
`;

export const CREATE_TOURNAMENT_CHIP_AMOUNTS = gql`
    mutation insert_tournament_chips($objects: [tournament_chips_insert_input!]!) {
        insert_tournament_chips(objects: $objects) {
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_CHIP_AMOUNTS = gql`
    mutation delete_tournament_chips($tournament_id: Int!) {
        delete_tournament_chips(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_TOURNAMENT_TIERED_ENTRY_FEES = gql`
    mutation insert_tournament_tiered_entry_fees($objects: [tournament_tiered_entry_fees_insert_input!]!) {
        insert_tournament_tiered_entry_fees(objects: $objects) {
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_TIERED_ENTRY_FEES = gql`
    mutation delete_tournament_tiered_entry_fees($tournament_id: Int!) {
        delete_tournament_tiered_entry_fees(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_TOURNAMENT_PAYOUT = gql`
    mutation insert_tournament_payouts($tournament_id: Int!, $tournament_player: Int!, $place: String!, $money: Int!) {
        insert_tournament_payouts(objects: { money: $money, place: $place, tournament_id: $tournament_id, tournament_player: $tournament_player }) {
            returning {
                id
            }
        }
    }
`;

export const DELETE_PAYOUTS_ENTRY = gql`
    mutation delete_tournament_payouts($id: Int!) {
        delete_tournament_payouts(where: { id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const INSERT_TOURNAMENT_PAYOUTS = gql`
    mutation insert_tournament_payouts($objects: [tournament_payouts_insert_input!]!) {
        insert_tournament_payouts(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_PAYOUT = gql`
    mutation update_tournament_payouts($id: Int!, $changes: tournament_payouts_set_input) {
        update_tournament_payouts(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DUPLICATE_POOL_TABLE = gql`
    mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
        __typename
        insert_pool_tables(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_PLAYER_MUTATION = gql`
    mutation update_tournament_players($id: Int!, $tournament_id: Int!, $changes: tournament_players_set_input) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const UPDATE_TOURNAMENT_PLAYERS = gql`
    mutation update_tournament_players($tournament_id: Int!, $changes: tournament_players_set_input) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const RESET_TOURNAMENT_PLAYER_PLACEMENTS = gql`
    mutation reset_placements($tournament_id: Int!) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id } }, _set: { place: null }) {
            affected_rows
        }
    }
`;

export const RESET_TOURNAMENT_STAGES = gql`
    mutation reset_stages($tournament_id: Int!) {
        delete_stages(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_MANAGER = gql`
    mutation delete_tournament_managers($id: Int!) {
        delete_tournament_managers(where: { id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const INSERT_TOURNAMENT_MANAGERS = gql`
    mutation insert_tournament_managers($objects: [tournament_managers_insert_input!]!) {
        insert_tournament_managers(objects: $objects) {
            affected_rows
            returning {
                id
                manager {
                    email
                }
            }
        }
    }
`;

export const INSERT_TOURNAMENT_MANAGERS_INVITE_KEY = gql`
    mutation insert_tournament_managers_invite_keys($objects: [tournament_managers_invite_keys_insert_input!]!) {
        insert_tournament_managers_invite_keys(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_MANAGER_INVITE_KEY = gql`
    mutation delete_tournament_managers_invite_keys($tournament_id: Int!, $email: String!) {
        delete_tournament_managers_invite_keys(where: { tournament_id: { _eq: $tournament_id }, _and: { email: { _eq: $email } } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_MANAGER = gql`
    mutation update_tournament_managers($tournament_id: Int!, $email: String!, $changes: tournament_managers_set_input) {
        update_tournament_managers(where: { tournament_id: { _eq: $tournament_id }, email: { _eq: $email } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_NOTIFICATION_MUTATION = gql`
    mutation delete_tournament_notifications($tournament_id: Int, $id: Int) {
        delete_tournament_notifications(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const LOG_PLAYER_SMS_NOTIFICATION = gql`
    mutation upsert_tournament_notifications($objects: [tournament_notifications_insert_input!]!) {
        insert_tournament_notifications(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_TOURNAMENT_BRACKET_TEMPLATE = gql`
    mutation insert_tournament_bracket_templates($objects: [tournament_bracket_templates_insert_input!]!) {
        insert_tournament_bracket_templates(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                type
                creator_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_TOURNAMENT_BRACKET_TEMPLATE = gql`
    mutation delete_tournament_bracket_templates($id: Int!) {
        delete_tournament_bracket_templates(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_MATCHES = gql`
    mutation delete_tournament_brackets($tournament_id: Int!) {
        delete_tournament_brackets(where: { tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_TOURNAMENT_MATCH = gql`
    mutation delete_tournament_brackets($id: Int!, $tournament_id: Int!) {
        delete_tournament_brackets(where: { id: { _eq: $id }, tournament_id: { _eq: $tournament_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_TOURNAMENT_BRACKET_ROUND = gql`
    mutation insert_tournament_bracket_template_rounds($objects: [tournament_bracket_template_rounds_insert_input!]!) {
        insert_tournament_bracket_template_rounds(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_ROUND = gql`
    mutation update_tournament_bracket_template_rounds($id: Int, $changes: tournament_bracket_template_rounds_set_input) {
        update_tournament_bracket_template_rounds(where: { id: { _eq: $id } }, _set: $changes) {
            __typename
            returning {
                id
                tournament_bracket_template_id
                round
                identifier
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_TOURNAMENT_BRACKET_ROUND = gql`
    mutation delete_tournament_bracket_template_rounds($id: Int!, $tournament_bracket_template_id: Int!) {
        delete_tournament_bracket_template_rounds(where: { id: { _eq: $id }, tournament_bracket_template_id: { _eq: $tournament_bracket_template_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_TOURNAMENT_BRACKET_MATCH = gql`
    mutation insert_tournament_bracket_template_matches($objects: [tournament_bracket_template_matches_insert_input!]!) {
        insert_tournament_bracket_template_matches(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_BRACKET_MATCH = gql`
    mutation update_tournament_bracket_template_matches($id: Int, $changes: tournament_bracket_template_matches_set_input) {
        update_tournament_bracket_template_matches(where: { id: { _eq: $id } }, _set: $changes) {
            __typename
            returning {
                id
                tournament_bracket_template_id
                round
                identifier
                match_number
                place
                top_seed
                bottom_seed
                winner_to
                winner_to_num
                winner_from
                winner_from_num
                winner_from_top_num
                winner_from_bottom_num
                loser_to
                loser_to_num
                loser_from
                loser_from_num
                loser_from_top_num
                loser_from_bottom_num
                is_finals
                is_semi_finals
                is_consolidation_finals
            }
        }
    }
`;

export const DELETE_TOURNAMENT_BRACKET_MATCH = gql`
    mutation delete_tournament_bracket_template_matches($id: Int!, $tournament_bracket_template_id: Int!) {
        delete_tournament_bracket_template_matches(where: { id: { _eq: $id }, tournament_bracket_template_id: { _eq: $tournament_bracket_template_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_TOURNAMENT_BRACKET_SEGMENT = gql`
    mutation insert_tournament_bracket_template_segments($objects: [tournament_bracket_template_segments_insert_input!]!) {
        insert_tournament_bracket_template_segments(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_TOURNAMENT_BRACKET_SEGMENT = gql`
    mutation delete_tournament_bracket_template_segments($id: Int!, $tournament_bracket_template_id: Int!) {
        delete_tournament_bracket_template_segments(where: { id: { _eq: $id }, tournament_bracket_template_id: { _eq: $tournament_bracket_template_id } }) {
            affected_rows
        }
    }
`;

export const ADVANCE_TOURNAMENT_BRACKET_STAGE_MUTATION = gql`
    mutation update_tournament_brackets($match_id: Int!, $stage_id: Int!, $tournament_id: Int!, $changes: tournament_brackets_set_input!) {
        update_tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $match_id }, stage_id: { _eq: $stage_id } }, _set: $changes) {
            affected_rows
        }
    }
`;
