import React, { useState } from 'react';
import { Row, Col, Table, Typography, Statistic, Tooltip, Pagination, Button, Icon } from 'antd';
import Card from '../../../components/Card';
import { Query } from '@apollo/client/react/components';
import ZeroState from '../../../components/ZeroState';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import { getCountryName } from '../../../utils';
import { GET_ALL_PLAYERS_QUERY, GET_ALL_USERS_COUNT, GET_ALL_PLAYER_COUNT } from '../data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import AddPlayerModal from './AddPlayerModal';
import _ from 'lodash';
import numeral from 'numeral';

const { Text, Title } = Typography;

const styles = {
    container: {
        padding: 20,
    },
};

export default function AllUsers(props) {
    const { client, authState } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    // const userCount = useQuery(GET_ALL_USERS_COUNT);
    // const totalUsers = (userCount && userCount.data && userCount.data.users_aggregate && userCount.data.users_aggregate.aggregate.count) || 0;
    const playerCount = useQuery(GET_ALL_PLAYER_COUNT);
    const totalPlayers = (playerCount && playerCount.data && playerCount.data.players_aggregate && playerCount.data.players_aggregate.aggregate.count) || 0;
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    const [playerAddModalVisible, setPlayerAddModalVisible] = useState(false);

    const MyPagination = ({ total, onChange, current }) => {
        return <Pagination onChange={onChange} total={total} current={currentPage} pageSize={pageSize} />;
    };

    function handlePlayerAdd() {
        setPlayerAddModalVisible(true);
        setSelectedPlayerId(null);
    }

    function handlePlayerAddModalOk(data) {
        setPlayerAddModalVisible(false);
        setSelectedPlayerId(null);
    }

    function handlePlayerAddModalCancel(e) {
        setPlayerAddModalVisible(false);
        setSelectedPlayerId(null);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => {
                return a.id - b.id;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) =>
                b.name.localeCompare(a.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        // {
        //     title: 'Aliases',
        //     dataIndex: 'aliases',

        //     render: (text, record) => {
        //         return <Text>{JSON.stringify(text, null, 4) || '-'}</Text>;
        //     },
        // },

        {
            title: 'Country',
            dataIndex: 'country',

            sorter: (a, b) => {
                return a.country && a.country.localeCompare(b.country);
            },
            render: (text, record) => {
                const country = record.country ? getCountryName(record.country) : text;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {country ? _.startCase(country) : '-'}
                    </div>
                );
            },
        },

        {
            title: 'Phone',
            dataIndex: 'phone_number',
            sorter: (a, b) =>
                b.phone_number.localeCompare(a.phone_number, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'User ID',
            dataIndex: 'user_id',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Fargo ID',
            dataIndex: 'fargo_id',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Fargo Readable ID',
            dataIndex: 'fargo_readable_id',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Properties',
            dataIndex: 'properties',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return <Text>{JSON.stringify(text, null, 4) || '-'}</Text>;
            },
        },

        {
            title: 'Action',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => (
                <React.Fragment>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Edit">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedPlayerId(record.id);
                                    setPlayerAddModalVisible(true);
                                }}
                            >
                                <Icon type="edit" />
                            </Button>
                        </Tooltip>
                    </span>
                </React.Fragment>
            ),
        },
    ];

    return (
        <SectionContent padding="20px 40px">
            <Row gutter={[16, 16]} justify="start" type="flex">
                {/* <Col xs={12} sm={12}>
                    <Card>
                        <Statistic title="User accounts" value={totalUsers} />
                    </Card>
                </Col>
                <Col xs={12} sm={12}>
                    <Card>
                        <Statistic title="Player records" value={totalPlayers} />
                    </Card>
                </Col> */}
                <Col xs={24}>
                    <Card
                        title={`Players (${numeral(totalPlayers).format('0,0')})`}
                        extra={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button type="primary" onClick={handlePlayerAdd}>
                                    <Icon type="user-add" /> Add Player
                                </Button>
                            </div>
                        }
                        bodyStyle={{ padding: 0 }}
                    >
                        <Query
                            query={GET_ALL_PLAYERS_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange
                            awaitRefetchQueries
                            variables={{ limit: pageSize, offset: (currentPage - 1) * pageSize || 0 }}
                            onCompleted={(data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading)
                                    return (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                        </div>
                                    );
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                if ((data && !data.players) || (data && data.players.length === 0)) {
                                    return <ZeroState showImage message="No players found." style={{ margin: 30 }} {...props} />;
                                }

                                const players = data.players.map((player, index) => {
                                    return {
                                        ...player,
                                        key: index,
                                    };
                                });

                                return (
                                    <div className="table_nowrap">
                                        <Table
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        console.log(record, rowIndex);
                                                        props.history.push({
                                                            pathname: `/players/${record.name_slug}`,
                                                            // search: '?query=abc',
                                                            state: { data: record },
                                                        });
                                                    }, // click row
                                                    onDoubleClick: (event) => {}, // double click row
                                                    onContextMenu: (event) => {}, // right button click row
                                                    onMouseEnter: (event) => {}, // mouse enter row
                                                    onMouseLeave: (event) => {}, // mouse leave row
                                                };
                                            }}
                                            columns={columns}
                                            dataSource={players}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                        />
                                    </div>
                                );
                            }}
                        </Query>
                    </Card>
                </Col>
                <Col xs={24} align="center">
                    <MyPagination
                        total={totalPlayers}
                        current={currentPage}
                        onChange={(page) => {
                            client
                                .query({
                                    query: GET_ALL_PLAYERS_QUERY,
                                    variables: {
                                        limit: pageSize,
                                        offset: (currentPage - 1) * pageSize || 0,
                                    },
                                })
                                .then((res) => {
                                    setCurrentPage(page);
                                })
                                .catch((error) => {
                                    console.error(error);
                                    throw error;
                                });
                        }}
                    />
                </Col>
            </Row>
            <AddPlayerModal
                selectedPlayerId={selectedPlayerId}
                userID={authState.user.id}
                onModalOk={handlePlayerAddModalOk}
                onModalCancel={handlePlayerAddModalCancel}
                modalVisible={playerAddModalVisible}
                {...props}
            />
        </SectionContent>
    );
}
