import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Typography, Upload, Divider, Icon, Avatar } from 'antd';

const { Title, Text } = Typography;
const { confirm } = Modal;

export default function NotificationReceipt(props) {
    const { notifications, selectedNotification, modalVisible, onModalOk, onModalCancel, onSave } = props;
    const { delivery_receipt } = selectedNotification;

    const ERROR_CODES = {
        0: 'Success	- The message was successfully accepted for delivery.',
        1: 'Throttled - You are sending SMS faster than the account limit (see What is the Throughput Limit for Outbound SMS?).',
        2: 'Missing Parameters - Your request is missing one of the required parameters: from, to, api_key, api_secret or text.',
        3: 'Invalid Parameters - The value of one or more parameters is invalid.',
        4: 'Invalid Credentials - Your API key and/or secret are incorrect, invalid or disabled.',
        5: 'Internal Error - An error has occurred in the platform whilst processing this message.',
        6: 'Invalid Message - The platform was unable to process this message, for example, an unrecognized number prefix.',
        7: 'Number Barred - The number you are trying to send messages to is on the list of barred numbers.',
        8: 'Partner Account Barred - Your Vonage account has been suspended. Contact support@nexmo.com.',
        9: 'Partner Quota Violation - You do not have sufficient credit to send the message. Top-up and retry.',
        10: 'Too Many Existing Binds - The number of simultaneous connections to the platform exceeds your account allocation.',
        11: 'Account Not Enabled For HTTP - This account is not provisioned for the SMS API, you should use SMPP instead.',
        12: 'Message Too Long - The message length exceeds the maximum allowed.',
        14: 'Invalid Signature - The signature supplied could not be verified.',
        15: 'Invalid Sender Address - You are using a non-authorized sender ID in the from field. This is most commonly in North America, where a Vonage long virtual number or short code is required.',
        22: 'Invalid Network Code - The network code supplied was either not recognized, or does not match the country of the destination address.',
        23: 'Invalid Callback URL - The callback URL supplied was either too long or contained illegal characters.',
        29: 'Non-Whitelisted Destination - Your Vonage account is still in demo mode. While in demo mode you must add target numbers to your whitelisted destination list. Top-up your account to remove this limitation.',
        32: 'Signature And API Secret Disallowed - A signed request may not also present an api_secret.',
        33: 'Number De-activated - The number you are trying to send messages to is de-activated and may not receive them.',
    };

    return (
        <Modal
            title="Notification receipt"
            // getContainer={() => document.querySelector('.tables')}
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            centered
            transitionName="fade"
            // transitionName="none"
            maskTransitionName="none"
            footer={[
                <Button key="close" onClick={onModalCancel}>
                    Cancel
                </Button>,
            ]}
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
            destroyOnClose={true}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {/* {JSON.stringify(selectedNotification && selectedNotification.delivery_receipt, null, 4)} */}
                <table width="100%" className="info-table">
                    <tbody>
                        <tr>
                            <td>To</td>
                            <td>
                                <Text>{delivery_receipt && delivery_receipt.to}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td>
                                <Text>{delivery_receipt && delivery_receipt.price}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>
                                <Text>{delivery_receipt && delivery_receipt.status}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>Error code</td>
                            <td>
                                <Text>{ERROR_CODES[delivery_receipt && delivery_receipt['err-code']]}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>Network code</td>
                            <td>
                                <Text>{delivery_receipt && delivery_receipt['network-code']}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>Message ID</td>
                            <td>
                                <Text>{delivery_receipt && delivery_receipt.messageId}</Text>
                            </td>
                        </tr>
                        <tr>
                            <td>Message Timestamp</td>
                            <td>
                                <Text>{delivery_receipt && delivery_receipt['message-timestamp']}</Text>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Modal>
    );
}
