import React, { useState, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Row, Col, Table, Tooltip, Button, Radio, Modal, Icon, Typography, Statistic, Pagination } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import CircleFlag from '../../../components/CircleFlag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    GET_ALL_USERS_COUNT,
    GET_ALL_PLAYERS_COUNT,
    GET_ALL_TOURNAMENTS_COUNT,
    GET_ALL_PUBLIC_TOURNAMENTS_COUNT,
    GET_ALL_PRIVATE_TOURNAMENTS_COUNT,
    GET_ALL_IN_PROGRESS_TOURNAMENTS_COUNT,
    GET_ALL_COMPLETED_TOURNAMENTS_COUNT,
    GET_ALL_UPCOMING_TOURNAMENTS_COUNT,
    GET_TOURNAMENT_DIRECTORS_COUNT,
    GET_LEAGUE_OPERATORS_COUNT,
    GET_ALL_MATCHES_COUNT,
    GET_ALL_PUBLIC_MATCHES_COUNT,
    GET_ALL_PRIVATE_MATCHES_COUNT,
    GET_ALL_VENUES_COUNT,
    GET_ALL_VENUE_TABLES_COUNT,
    GET_ALL_VENUE_WITH_TABLES_COUNT,
    GET_ALL_TOURNAMENT_MATCHES_COUNT,
    GET_ALL_TOURNAMENT_FARGO_MATCHES_COUNT,
    GET_ALL_LEAGUES_COUNT,
    GET_ALL_LEAGUE_MATCHES_COUNT,
} from '../data/queries';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const styles = {
    container: {
        padding: 20,
    },
};

function Dashboard(props) {
    const { client, authState, theme } = props;
    const chartRef = useRef();
    const [selectedTime, setSelectedTime] = useState('all');
    const [startTime, setStartTime] = useState('2014-01-01');
    const [endTime, setEndTime] = useState(moment());
    const userCount = useQuery(GET_ALL_USERS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalUsers = (userCount && userCount.data && userCount.data.users_aggregate && userCount.data.users_aggregate.aggregate.count) || 0;
    const playerCount = useQuery(GET_ALL_PLAYERS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalPlayers = (playerCount && playerCount.data && playerCount.data.players_aggregate && playerCount.data.players_aggregate.aggregate.count) || 0;

    const leaguesCount = useQuery(GET_ALL_LEAGUES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalLeagues = (leaguesCount && leaguesCount.data && leaguesCount.data.leagues_aggregate && leaguesCount.data.leagues_aggregate.aggregate.count) || 0;
    // const leagueMatchesCount = useQuery(GET_ALL_LEAGUE_MATCHES_COUNT, {
    //     variables: {
    //         from: startTime,
    //         to: endTime,
    //     },
    // });
    // const totalLeagueMatches =
    //     (leagueMatchesCount &&
    //         leagueMatchesCount.data &&
    //         leagueMatchesCount.data.league_matches_aggregate &&
    //         leagueMatchesCount.data.league_matches_aggregate.aggregate.count) ||
    //     0;

    const tournamentCount = useQuery(GET_ALL_TOURNAMENTS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalTournaments =
        (tournamentCount && tournamentCount.data && tournamentCount.data.tournaments_aggregate && tournamentCount.data.tournaments_aggregate.aggregate.count) ||
        0;

    const publicTournamentCount = useQuery(GET_ALL_PUBLIC_TOURNAMENTS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalPublicTournaments =
        (publicTournamentCount &&
            publicTournamentCount.data &&
            publicTournamentCount.data.tournaments_aggregate &&
            publicTournamentCount.data.tournaments_aggregate.aggregate.count) ||
        0;
    const privateTournamentCount = useQuery(GET_ALL_PRIVATE_TOURNAMENTS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalPrivateTournaments =
        (privateTournamentCount &&
            privateTournamentCount.data &&
            privateTournamentCount.data.tournaments_aggregate &&
            privateTournamentCount.data.tournaments_aggregate.aggregate.count) ||
        0;

    const inProgressTournamentCount = useQuery(GET_ALL_IN_PROGRESS_TOURNAMENTS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalInProgressTournaments =
        (inProgressTournamentCount &&
            inProgressTournamentCount.data &&
            inProgressTournamentCount.data.tournaments_aggregate &&
            inProgressTournamentCount.data.tournaments_aggregate.aggregate.count) ||
        0;

    const upcomingTournamentCount = useQuery(GET_ALL_UPCOMING_TOURNAMENTS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalUpcomingTournaments =
        (upcomingTournamentCount &&
            upcomingTournamentCount.data &&
            upcomingTournamentCount.data.tournaments_aggregate &&
            upcomingTournamentCount.data.tournaments_aggregate.aggregate.count) ||
        0;

    const completedTournamentCount = useQuery(GET_ALL_COMPLETED_TOURNAMENTS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalCompletedTournaments =
        (completedTournamentCount &&
            completedTournamentCount.data &&
            completedTournamentCount.data.tournaments_aggregate &&
            completedTournamentCount.data.tournaments_aggregate.aggregate.count) ||
        0;

    const tournamentDirectorsCount = useQuery(GET_TOURNAMENT_DIRECTORS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalTournamentDirectors =
        (tournamentDirectorsCount &&
            tournamentDirectorsCount.data &&
            tournamentDirectorsCount.data.tournaments_aggregate &&
            tournamentDirectorsCount.data.tournaments_aggregate.aggregate.count) ||
        0;

    const tournamentMatchesCount = useQuery(GET_ALL_TOURNAMENT_MATCHES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalTournamentMatches =
        (tournamentMatchesCount &&
            tournamentMatchesCount.data &&
            tournamentMatchesCount.data.tournament_brackets_aggregate &&
            tournamentMatchesCount.data.tournament_brackets_aggregate.aggregate.count) ||
        0;

    const tournamentFargoMatchesCount = useQuery(GET_ALL_TOURNAMENT_FARGO_MATCHES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalTournamentFargoMatches =
        (tournamentFargoMatchesCount &&
            tournamentFargoMatchesCount.data &&
            tournamentFargoMatchesCount.data.tournament_brackets_aggregate &&
            tournamentFargoMatchesCount.data.tournament_brackets_aggregate.aggregate.count) ||
        0;

    const leagueOperatorsCount = useQuery(GET_LEAGUE_OPERATORS_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalLeagueOperators =
        (leagueOperatorsCount &&
            leagueOperatorsCount.data &&
            leagueOperatorsCount.data.leagues_aggregate &&
            leagueOperatorsCount.data.leagues_aggregate.aggregate.count) ||
        0;

    const matchesCount = useQuery(GET_ALL_MATCHES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalMatches = (matchesCount && matchesCount.data && matchesCount.data.matches_aggregate && matchesCount.data.matches_aggregate.aggregate.count) || 0;

    const publicMatchesCount = useQuery(GET_ALL_PUBLIC_MATCHES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalPublicMatches =
        (publicTournamentCount &&
            publicMatchesCount.data &&
            publicMatchesCount.data.matches_aggregate &&
            publicMatchesCount.data.matches_aggregate.aggregate.count) ||
        0;
    const privateMatchesCount = useQuery(GET_ALL_PRIVATE_MATCHES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalPrivateMatches =
        (privateMatchesCount &&
            privateMatchesCount.data &&
            privateMatchesCount.data.matches_aggregate &&
            privateMatchesCount.data.matches_aggregate.aggregate.count) ||
        0;

    const venuesCount = useQuery(GET_ALL_VENUES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalVenues = (venuesCount && venuesCount.data && venuesCount.data.venues_aggregate && venuesCount.data.venues_aggregate.aggregate.count) || 0;

    const venueTablesCount = useQuery(GET_ALL_VENUE_TABLES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalVenueTables =
        (venueTablesCount &&
            venueTablesCount.data &&
            venueTablesCount.data.pool_tables_aggregate &&
            venueTablesCount.data.pool_tables_aggregate.aggregate.count) ||
        0;

    const venuesWithTablesCount = useQuery(GET_ALL_VENUE_WITH_TABLES_COUNT, {
        variables: {
            from: startTime,
            to: endTime,
        },
    });
    const totalVenuesWithTables =
        (venuesWithTablesCount &&
            venuesWithTablesCount.data &&
            venuesWithTablesCount.data.venues_aggregate &&
            venuesWithTablesCount.data.venues_aggregate.aggregate.count) ||
        0;

    const tournamentStats = {
        colors: ['#2196f3', 'rgba(26, 135, 255, 0.4)', 'rgba(255, 204, 0, 1)', 'rgba(255, 204, 0, 0.4)', '#44475b'],
        title: {
            text: null,
        },
        chart: {
            height: 250,
            panning: false,
            zoomType: 'x',
            animation: false,
            backgroundColor: 'transparent',
        },
        legend: {
            // symbolWidth: 12,
            // symbolRadius: 6
            itemStyle: {
                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
            },
            itemHoverStyle: {
                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
            },
        },
        credits: { enabled: false },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            column: {
                // stacking: 'normal',
                pointPadding: 0.1,
                borderWidth: 0,
                animation: false,
                // dataLabels: {
                // 	enabled: true,
                // 	formatter: function() {
                // 		if (this.y > 0) {
                // 			return this.y;
                // 		}
                // 		return null;
                // 	}
                // }
                // borderRadiusTopLeft: 5,
                // borderRadiusTopRight: 5
                // dataLabels: {
                // 	enabled: true,
                // 	color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                // }
            },
        },
        yAxis: [
            {
                title: {
                    text: null,
                },
                lineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#C0D0E0',
                lineWidth: 0,
                gridLineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#D8D8D8',
                gridLineDashedStyle: 'solid',
                gridLineWidth: 1,
                labels: {
                    style: {
                        fontSize: 10,
                        color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                    },
                },
            },
        ],
        xAxis: {
            categories: ['Tournament'],
            crosshair: true,
            lineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#C0D0E0',
            lineWidth: 1,
            gridLineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#D8D8D8',
            gridLineDashedStyle: 'solid',
            gridLineWidth: 0,
            labels: {
                style: {
                    fontSize: 10,
                    color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                },
            },
        },
        series: [
            {
                name: 'All tournaments',
                type: 'column',
                data: [totalTournaments],
                stack: 'tournaments',
                borderRadiusTopLeft: 3,
                borderRadiusTopRight: 3,
            },
            {
                name: 'Public tournaments',
                type: 'column',
                data: [totalPublicTournaments],
                stack: 'tournaments',
                // borderRadiusTopLeft: 3,
                // borderRadiusTopRight: 3
            },
            {
                name: 'Private tournaments',
                type: 'column',
                data: [totalPrivateTournaments],
                stack: 'tournaments',
                // borderRadiusTopLeft: 3,
                // borderRadiusTopRight: 3
            },
        ],
    };

    function handleTimeChange(e) {
        const selected = e.target.value;
        let start;
        let end;
        setSelectedTime(selected);
        if (selected === 'all') {
            start = moment('2014-01-01').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '1d') {
            start = moment().subtract(1, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '7d') {
            start = moment().subtract(7, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '30d') {
            start = moment().subtract(30, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '60d') {
            start = moment().subtract(60, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '90d') {
            start = moment().subtract(90, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '6m') {
            start = moment().subtract(182, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        } else if (selected === '1y') {
            start = moment().subtract(365, 'days').format('YYYY-MM-DD');
            end = moment().format('YYYY-MM-DD');
        }

        setStartTime(start);
        setEndTime(end);
    }

    return (
        <SectionContent padding="40px 40px">
            <Row gutter={[16, 16]} justify="start" type="flex">
                <Col xs={24}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Title level={3} style={{ marginTop: 0 }}>
                            User stats
                        </Title>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 10 }}>
                                <Text>Timeframe:</Text>
                            </div>
                            <Radio.Group value={selectedTime} onChange={handleTimeChange}>
                                <Radio.Button value="1d">24H</Radio.Button>
                                <Radio.Button value="7d">1W</Radio.Button>
                                <Radio.Button value="30d">1M</Radio.Button>
                                <Radio.Button value="60d">2M</Radio.Button>
                                <Radio.Button value="90d">3M</Radio.Button>
                                <Radio.Button value="6m">6M</Radio.Button>
                                <Radio.Button value="1y">1Y</Radio.Button>
                                <Radio.Button value="all">ALL</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <Card>
                        <Statistic title="User accounts" value={totalUsers} />
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card>
                        <Statistic title="Player records" value={totalPlayers} />
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card>
                        <Statistic title="Tournament directors" value={totalTournamentDirectors} />
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card>
                        <Statistic title="League operators" value={totalLeagueOperators} />
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="start" type="flex">
                <Col xs={12}>
                    <Title level={3} style={{ marginTop: 15 }}>
                        Match stats
                    </Title>
                </Col>
                <Col xs={12}>
                    <Title level={3} style={{ marginTop: 15 }}>
                        Venue stats
                    </Title>
                </Col>

                <Col xs={12} sm={4}>
                    <Card>
                        <Statistic title="All matches" value={totalMatches} />
                    </Card>
                </Col>
                <Col xs={12} sm={4}>
                    <Card>
                        <Statistic title="Public matches" value={totalPublicMatches} />
                    </Card>
                </Col>
                <Col xs={12} sm={4}>
                    <Card>
                        <Statistic title="Private matches" value={totalPrivateMatches} />
                    </Card>
                </Col>
                <Col xs={12} sm={4}>
                    <Card>
                        <Statistic title="All venues" value={totalVenues} />
                    </Card>
                </Col>
                <Col xs={12} sm={4}>
                    <Card>
                        <Statistic title="Venues with tables" value={totalVenuesWithTables} />
                    </Card>
                </Col>
                <Col xs={12} sm={4}>
                    <Card>
                        <Statistic title="Venue tables" value={totalVenueTables} />
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="start" type="flex">
                <Col xs={24}>
                    <Title level={3} style={{ marginTop: 15 }}>
                        Tournament stats
                    </Title>
                </Col>
                <Col xs={24}>
                    <Card>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={tournamentStats}
                            containerProps={{ className: 'tournamentStatsContainer' }}
                            ref={chartRef}
                        />
                        <Row gutter={[16, 16]} justify="start" type="flex">
                            <Col xs={12} sm={6}>
                                <Statistic title="All tournaments" value={totalTournaments} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="Public tournaments" value={totalPublicTournaments} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="Private tournaments" value={totalPrivateTournaments} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="In progress tournaments" value={totalInProgressTournaments} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="Upcoming tournaments" value={totalUpcomingTournaments} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="Completed tournaments" value={totalCompletedTournaments} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="Tournament matches" value={totalTournamentMatches} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Statistic title="Matches submitted to Fargo" value={totalTournamentFargoMatches} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="start" type="flex">
                <Col xs={24}>
                    <Title level={3} style={{ marginTop: 15 }}>
                        League stats
                    </Title>
                </Col>
                <Col xs={24}>
                    <Card>
                        {/* <HighchartsReact
                            highcharts={Highcharts}
                            options={tournamentStats}
                            containerProps={{ className: 'tournamentStatsContainer' }}
                            ref={chartRef}
                        /> */}
                        <Row gutter={[16, 16]} justify="start" type="flex">
                            <Col xs={12} sm={6}>
                                <Statistic title="All leagues" value={totalLeagues} />
                            </Col>

                            {/* <Col xs={12} sm={6}>
                                <Statistic title="League matches" value={totalLeagueMatches} />
                            </Col> */}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </SectionContent>
    );
}

export default withRouter(Dashboard);
