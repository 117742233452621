import React, { useState, useEffect } from 'react';
import { GET_MATCH_LONGEST_WINNING_STREAK } from './data/subscriptions';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Subscription } from '@apollo/client/react/components';

const styles = {};

export default function StatLongestWinningStreak(props) {
    const { matchId } = props;

    return (
        <tr>
            <Subscription
                subscription={GET_MATCH_LONGEST_WINNING_STREAK}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ match_id: matchId }}
                onSubscriptionData={async (data) => {
                    // console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <React.Fragment></React.Fragment>;
                    if (error) return <React.Fragment></React.Fragment>;

                    const winningStreak = data && data.vw_match_longest_winning_streaks && data.vw_match_longest_winning_streaks[0];
                    const playerStreak = winningStreak && winningStreak.longest_player_winning_streak;
                    const opponentStreak = winningStreak && winningStreak.longest_opponent_winning_streak;
                    const totalValue = playerStreak && opponentStreak && playerStreak + opponentStreak;
                    const playerValuePct = playerStreak && ((playerStreak / totalValue) * 100).toFixed(0);
                    const opponentValuePct = opponentStreak && ((opponentStreak / totalValue) * 100).toFixed(0);

                    return (
                        <React.Fragment>
                            <td align="right" width="60px">
                                <div
                                    style={{
                                        fontSize: 16,
                                        textAlign: 'right',
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                    }}
                                >
                                    {/* <React.Fragment>{isNaN(playerValuePct) === false ? `${playerValuePct}%` : '-'}</React.Fragment> */}
                                </div>
                            </td>
                            <td align="right">
                                <div
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <b>{playerStreak || 0}</b>
                                </div>
                            </td>
                            <td align="center" width="350px">
                                Longest Game Winning Streak
                            </td>
                            <td align="left">
                                <div
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <b>{opponentStreak || 0}</b>
                                </div>
                            </td>
                            <td align="left" width="60px">
                                <div
                                    style={{
                                        fontSize: 16,
                                        textAlign: 'left',
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                    }}
                                >
                                    {/* <React.Fragment>{isNaN(opponentValuePct) === false ? `${opponentValuePct}%` : '-'}</React.Fragment> */}
                                </div>
                            </td>
                        </React.Fragment>
                    );
                }}
            </Subscription>
        </tr>
    );
}
