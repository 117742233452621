import React from 'react';
import { Card } from 'antd';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../utils/defaultBreakpoints';

const { Meta } = Card;
export { Meta };

function CardContainer(props) {
    const { theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const styles = {
        card: {
            maxWidth: 'auto',
            width: '100%',
            borderRadius: breakpoint === 'mobile' ? 6 : 8,
            // backgroundColor: theme && theme.name === 'dark' ? '#000' : '#fff',
            // boxShadow: '0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
            // boxShadow: theme && theme.name === 'dark' ? 'none' : '0 2px 2px 0 rgba(0,0,0,0.08), 0 8px 20px 0 rgba(218,224,235,.6)',
            // boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)',
            // boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
            height: '100%',
            overflow: 'hidden',
            // border: '1px solid #e0e0e0',
            border: theme && theme.name === 'dark' ? '1px solid rgba(255,255,255,0.2)' : '1px solid transparent',
            transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        cardHeader: {
            paddingLeft: 24,
            paddingRight: 24,
            borderRadius: '10px 10px 0px 0px',
            border: 'none',
            fontSize: 15,
            fontWeight: 600,
            // zIndex: 1,
            position: 'relative',
            textTransform: 'uppercase',
            letterSpacing: 2,
        },
    };

    return (
        <Card
            {...props}
            title={props.title}
            style={props.style || styles.card}
            bodyStyle={props.bodyStyle}
            headStyle={props.headStyle || styles.cardHeader}
            extra={props.extra}
        >
            {props.children}
        </Card>
    );
}
export default CardContainer;
