import React from 'react';
import lmbTourneyImg from '../assets/img/pool-hall14.jpg';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../utils/defaultBreakpoints';

function HeaderImage(props) {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const styles = {
        headerImg: {
            height: 200,
            overflow: 'hidden',
            borderRadius: 0,
            backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.2)), url(${lmbTourneyImg})`,
            backgroundAttachment: breakpoint === 'mobile' ? 'none' : 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: breakpoint === 'mobile' ? '0px 0px' : '0px -200px',
        },
    };

    return <div style={styles.headerImg} />;
}

export default HeaderImage;
