import React, { useState, useEffect } from 'react';
import { List, Tabs, Row, Col, Alert, Button, Icon, Table, Tooltip, Progress, Badge, Typography, Modal, Empty, Input } from 'antd';
import {
    GET_TOURNAMENT_PLAYER_QUERY,
    GET_TOURNAMENT_PLAYER_MATCHES_QUERY,
    GET_TOURNAMENT_PLAYER_STATS_QUERY,
    GET_TOURNAMENT_PLAYER_STATS_QUERY2,
    GET_TOURNAMENT_MATCH_PROJECTION,
} from '../data/queries';
import { Query, Mutation } from '@apollo/client/react/components';
import { withRouter, Link } from 'react-router-dom';
import CircleFlag from '../../../../components/CircleFlag';
import { getCountryName, formatPlayerName, getOrdinalSuffix } from '../../../../utils';
import CircularLoader from '../../../../components/CircularLoader';
import _ from 'lodash';
import numeral from 'numeral';
import verifiedIcon from '../../../../assets/img/verified-icon.svg';
import { TrophyFilled } from '@ant-design/icons';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { Text } = Typography;

function PlayerModal(props) {
    console.log(props);
    const { authState, tournament, tournamentID, player, playerId, modalVisible, onPlayerModalVisible, client } = props;
    const [currentPlayer, setCurrentPlayer] = useState();
    const [upcomingMatchProjection, setUpcomingMatchProjection] = useState();
    // const [ playerModalVisible, setPlayerModalVisible ] = useState(modalVisible || false);

    function handlePlayerModalOk(e) {
        console.log(e);
        onPlayerModalVisible(false);
        // setPlayerModalVisible(false);
    }

    function handlePlayerModalCancel(e) {
        onPlayerModalVisible(false);
        // setPlayerModalVisible(false);
    }

    function showPlayerModal(player) {
        setCurrentPlayer(player);
        onPlayerModalVisible(true);
        // setPlayerModalVisible(true);
    }

    function renderMatchHistory(matchesWithoutByes, player) {
        return (
            <Text>
                {matchesWithoutByes
                    .sort((a, b) => {
                        return a.match_number - b.match_number;
                    })
                    .filter((match) => {
                        return match.challenger1_is_winner === true || match.challenger2_is_winner === true;
                    })
                    .map((match, index) => {
                        const result =
                            match.challenger1_id === playerId && match.challenger1_is_winner === true
                                ? 'W'
                                : match.challenger2_id === playerId && match.challenger2_is_winner === true
                                ? 'W'
                                : match.challenger1_is_winner !== true && match.challenger2_is_winner !== true
                                ? '-'
                                : match.challenger1_is_forfeit === true || match.challenger2_is_forfeit === true
                                ? 'FF'
                                : 'L';

                        const opponentName =
                            match.challenger1_id === playerId ? match.challenger2_name : match.challenger2_id === playerId ? match.challenger1_name : null;
                        const playerScore =
                            match.challenger1_id === playerId ? match.challenger1_score : match.challenger2_id === playerId ? match.challenger2_score : 0;
                        const opponentScore =
                            match.challenger1_id !== playerId ? match.challenger1_score : match.challenger2_id !== playerId ? match.challenger2_score : 0;

                        return (
                            <React.Fragment key={index}>
                                <Tooltip placement="top" title={`${result} vs. ${opponentName} (${playerScore}-${opponentScore})`}>
                                    <span
                                        className={
                                            (match.challenger1_id === playerId && match.challenger1_is_winner === true) ||
                                            (match.challenger2_id === playerId && match.challenger2_is_winner === true)
                                                ? 'match-badge win'
                                                : 'match-badge loss'
                                        }
                                    >
                                        {result}
                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}
            </Text>
        );
    }

    async function getUpcomingMatchProjectedTime(id) {
        if (client) {
            const res = await client.query({
                query: GET_TOURNAMENT_MATCH_PROJECTION,
                variables: {
                    // "league_id": 5,
                    // "division_id": 18
                    tournament_id: tournamentID,
                    match_id: id,
                },
                fetchPolicy: 'no-cache',
            });
            const matchProjection = res && res.data && res.data.tournament_bracket_projections && res.data.tournament_bracket_projections[0];
            setUpcomingMatchProjection(matchProjection);
            return matchProjection;
        } else {
            return;
        }
    }

    return (
        <React.Fragment>
            {(player || playerId) && (
                <Query
                    query={GET_TOURNAMENT_PLAYER_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: player ? player.id : playerId, tournament_id: tournamentID }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const playerData = data && data.tournament_players && data.tournament_players.length > 0 ? data.tournament_players[0] : null;
                        const matches = [...playerData.tournament_matches_challenger1, ...playerData.tournament_matches_challenger2];
                        console.log(matches);

                        const matchesWithoutByes = matches.filter((match) => {
                            return match.is_bye !== true && match.challenger1_name && match.challenger2_name && match.status === 'COMPLETED';
                        });

                        const upcomingMatch = matches.filter((matchData) => {
                            return (
                                matchData.is_bye !== true &&
                                matchData.challenger1_name &&
                                matchData.challenger2_name &&
                                matchData.status === 'NOT_STARTED' &&
                                tournament.status !== 'COMPLETED'
                            );
                        })[0];

                        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
                        var zone_name = moment.tz.guess();
                        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

                        console.log(matches);
                        console.log(playerData);
                        console.log(upcomingMatch);
                        console.log(upcomingMatchProjection);

                        return (
                            <Modal
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {playerData.country && <CircleFlag country={playerData.country} left={0} />}

                                            <Text
                                                style={{
                                                    marginLeft: 0,
                                                    width: '100%',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {formatPlayerName(playerData)} {playerData.race_to ? `(${playerData.race_to})` : null}
                                            </Text>
                                        </div>
                                        {playerData.user_id && <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />}
                                    </div>
                                }
                                visible={modalVisible}
                                onOk={handlePlayerModalOk}
                                onCancel={handlePlayerModalCancel}
                                centered
                                transitionName="fade"
                                maskTransitionName="none"
                                bodyStyle={{ padding: 0 }}
                                footer={[
                                    // <Button key="cancel" onClick={handlePlayerModalCancel}>
                                    // 	Cancel
                                    // </Button>,
                                    <Button key="submit" type="primary" onClick={handlePlayerModalOk}>
                                        OK
                                    </Button>,
                                ]}
                            >
                                <Tabs
                                    animated={{ inkBar: true, tabPane: false }}
                                    defaultActiveKey="tables"
                                    // onChange={callback}
                                    // type="card"
                                    tabBarStyle={{ textTransform: 'uppercase', paddingLeft: 10 }}
                                >
                                    <TabPane tab="Player Info" key="player">
                                        <div style={{ padding: 20 }}>
                                            {/* {upcomingMatch && (
                                                    <div style={{ marginBottom: 15 }}>
                                                        <Alert
                                                            message={`Upcoming match #${upcomingMatch.match_number} (${upcomingMatch.identifier})`}
                                                            description={`${
                                                                upcomingMatch.challenger1_name === playerData.name
                                                                    ? upcomingMatch.challenger1_name
                                                                    : upcomingMatch.challenger2_name
                                                            }'s next match against ${
                                                                upcomingMatch.challenger1_name === playerData.name
                                                                    ? upcomingMatch.challenger2_name
                                                                    : upcomingMatch.challenger1_name
                                                            } is projected to start at ${
                                                                upcomingMatchProjection &&
                                                                moment.utc(upcomingMatchProjection.start_time).local().format('h:mm a z')
                                                            } ${timezone}.`}
                                                            type="info"
                                                            closable
                                                            showIcon
                                                        />
                                                    </div>
                                                )} */}

                                            <Text style={{ fontWeight: 700, marginBottom: 10 }}>Player info</Text>
                                            <table width="100%" className="info-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>
                                                            {/* {playerData.name} (Player ID: {playerData.id})
                                                             */}
                                                            {formatPlayerName(playerData)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Country</td>
                                                        <td className="display-linebreak">{playerData.country || '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Place</td>
                                                        <td className="display-linebreak">
                                                            {playerData.place === '1' ? (
                                                                <TrophyFilled
                                                                    style={{
                                                                        color: '#eab700',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            ) : playerData.place === '2' ? (
                                                                <TrophyFilled
                                                                    style={{
                                                                        color: '#9baab1',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            ) : (
                                                                playerData.place === '3' && (
                                                                    <TrophyFilled
                                                                        style={{
                                                                            color: '#f19921',
                                                                            marginRight: 5,
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                            {playerData.place ? `${getOrdinalSuffix(playerData.place)} place` : '-'}
                                                        </td>
                                                    </tr>
                                                    {tournament.show_player_skill_levels === true && (
                                                        <tr>
                                                            <td>Skill Level</td>
                                                            <td className="display-linebreak">{playerData.skill_level || '-'}</td>
                                                        </tr>
                                                    )}
                                                    {playerData.user_id && (
                                                        <tr>
                                                            <td>User Profile</td>
                                                            <td className="display-linebreak">
                                                                {/* {playerData.user_id || '-'}{' '} */}
                                                                {playerData.user && playerData.user.slug && (
                                                                    <Button ghost size="small">
                                                                        <Link to={`/users/${playerData.user.slug}`}>View User</Link>
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {playerData.player_id && (
                                                        <tr>
                                                            <td>Player Profile</td>
                                                            <td className="display-linebreak">
                                                                {/* {playerData.player_id || '-'}{' '} */}
                                                                {playerData.player && playerData.player.name_slug && (
                                                                    <Button ghost size="small">
                                                                        <Link to={`/players/${playerData.player.name_slug}`}>View Player</Link>
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {playerData.user && playerData.fargo_id && (
                                                        <tr>
                                                            <td>Fargo ID</td>
                                                            <td className="display-linebreak">{playerData.fargo_id || '-'}</td>
                                                        </tr>
                                                    )}

                                                    <tr>
                                                        <td>Matches played</td>
                                                        <td className="display-linebreak">{matchesWithoutByes.length}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Match history</td>
                                                        <td className="display-linebreak">{renderMatchHistory(matchesWithoutByes, player)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPane>
                                    <TabPane
                                        tab={`Match history (${matchesWithoutByes && matchesWithoutByes.length})`}
                                        key="matches"
                                        style={{ maxHeight: 500, overflowY: 'auto' }}
                                    >
                                        <Query
                                            query={GET_TOURNAMENT_PLAYER_MATCHES_QUERY}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{
                                                tournament_id: tournamentID,
                                                player_name: playerData.name,
                                            }}
                                            onCompleted={(data) => {
                                                console.log(data);
                                            }}
                                        >
                                            {({ loading, error, data }) => {
                                                if (loading) return <CircularLoader />;
                                                if (error) return <div>Error: {error.message}</div>;

                                                const matches = data && data.vw_player_tournament_matches;
                                                console.log(matches);

                                                return (
                                                    <React.Fragment>
                                                        <div
                                                            style={{
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                paddingBottom: 20,
                                                                paddingTop: 5,
                                                            }}
                                                        >
                                                            <table width="100%" className="info-table">
                                                                <tbody>
                                                                    {matches &&
                                                                        matches.map((match, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                paddingTop: 15,
                                                                                            }}
                                                                                        >
                                                                                            <Text
                                                                                                style={{
                                                                                                    fontWeight: 700,
                                                                                                    marginBottom: 10,
                                                                                                }}
                                                                                            >
                                                                                                Match: {match.match_number} ({match.identifier})
                                                                                            </Text>
                                                                                        </td>
                                                                                    </tr>

                                                                                    {/* <tr>
																						<td>Round</td>
																						<td>{match.identifier}</td>
																					</tr>
																					<tr>
																						<td>Match number</td>
																						<td className="display-linebreak">
																							{match.match_number}
																						</td>
																					</tr> */}
                                                                                    <tr>
                                                                                        <td>Players</td>
                                                                                        <td className="display-linebreak">
                                                                                            <div
                                                                                                style={{
                                                                                                    maxWidth: 250,
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    style={{
                                                                                                        marginLeft: 0,
                                                                                                        width: '100%',
                                                                                                    }}
                                                                                                >
                                                                                                    {match.player_won ? (
                                                                                                        <Badge
                                                                                                            count="W"
                                                                                                            style={{
                                                                                                                backgroundColor: '#87d068',
                                                                                                                marginRight: 5,
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : match.opponent_won ? (
                                                                                                        <Badge
                                                                                                            count={match.challenger2_is_forfeit ? 'FF' : 'L'}
                                                                                                            style={{
                                                                                                                backgroundColor: '#f5222d',
                                                                                                                marginRight: 5,
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : null}
                                                                                                    {match.player_name || 'N/A'}{' '}
                                                                                                    {match.player_skill_level &&
                                                                                                    tournament.show_player_skill_levels === true
                                                                                                        ? `(${match.player_skill_level})`
                                                                                                        : null}
                                                                                                </Text>
                                                                                                <Text
                                                                                                    style={{
                                                                                                        marginLeft: 5,
                                                                                                        marginRight: 5,
                                                                                                    }}
                                                                                                >
                                                                                                    vs.
                                                                                                </Text>
                                                                                                <Text
                                                                                                    style={{
                                                                                                        marginLeft: 0,
                                                                                                        width: '100%',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                    }}
                                                                                                >
                                                                                                    {match.opponent_won ? (
                                                                                                        <Badge
                                                                                                            count="W"
                                                                                                            style={{
                                                                                                                backgroundColor: '#87d068',
                                                                                                                marginRight: 5,
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : match.player_won ? (
                                                                                                        <Badge
                                                                                                            count={match.challenger1_is_forfeit ? 'FF' : 'L'}
                                                                                                            style={{
                                                                                                                backgroundColor: '#f5222d',
                                                                                                                marginRight: 5,
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : null}
                                                                                                    {match.opponent_name || 'N/A'}{' '}
                                                                                                    {match.opponent_skill_level &&
                                                                                                    tournament.show_player_skill_levels === true
                                                                                                        ? `(${match.opponent_skill_level})`
                                                                                                        : null}
                                                                                                </Text>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>Score</td>
                                                                                        <td className="display-linebreak">
                                                                                            <b>
                                                                                                {match.players_score || 0}-{match.opponents_score || 0}
                                                                                            </b>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {match.match_duration && (
                                                                                        <tr>
                                                                                            <td>Duration</td>
                                                                                            <td className="display-linebreak">
                                                                                                <Text type="secondary">
                                                                                                    <Icon type="clock-circle" />{' '}
                                                                                                    {match.match_duration.split(':')[0]}:
                                                                                                    {match.match_duration.split(':')[1]}:
                                                                                                    {Math.trunc(match.match_duration.split(':')[2])}
                                                                                                </Text>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}

                                                                                    {match.match_rating && tournament.show_player_skill_levels === true && (
                                                                                        <tr>
                                                                                            <td>Performance rating</td>
                                                                                            <td className="display-linebreak">
                                                                                                {match.match_rating === match.player_skill_level ? (
                                                                                                    <div>
                                                                                                        {parseFloat(
                                                                                                            match.match_rating - match.player_skill_level
                                                                                                        ).toFixed(2)}{' '}
                                                                                                        ({match.match_rating})
                                                                                                    </div>
                                                                                                ) : parseFloat(match.match_rating) >
                                                                                                  match.player_skill_level ? (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            color: 'green',
                                                                                                        }}
                                                                                                    >
                                                                                                        <ArrowUpOutlined style={{ color: 'green' }} />{' '}
                                                                                                        {parseFloat(
                                                                                                            match.match_rating - match.player_skill_level
                                                                                                        ).toFixed(2)}{' '}
                                                                                                        ({match.match_rating})
                                                                                                    </div>
                                                                                                ) : parseFloat(match.match_rating - match.player_skill_level) <
                                                                                                  match.player_skill_level ? (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            color: 'red',
                                                                                                        }}
                                                                                                    >
                                                                                                        <ArrowDownOutlined style={{ color: 'red' }} />{' '}
                                                                                                        {parseFloat(
                                                                                                            match.match_rating - match.player_skill_level
                                                                                                        ).toFixed(2)}{' '}
                                                                                                        ({match.match_rating})
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div>{match.match_rating - match.player_skill_level}</div>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        {/* <List
																size="small"
																loading={false}
																itemLayout="horizontal"
																dataSource={matches.sort((a, b) => {
																	return a.match_number - b.match_number;
																})}
																header={
																	<div
																		style={{
																			padding: '0px 20px',
																			margin: 0,
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'space-between'
																		}}
																	>
																		<div>
																			<Text
																				style={{ fontWeight: 700, width: 60 }}
																			>
																				Round
																			</Text>
																			<Text
																				style={{
																					fontWeight: 700,
																					width: 50,
																					marginLeft: 10
																				}}
																			>
																				Match
																			</Text>
																			<Text
																				style={{
																					fontWeight: 700,
																					marginLeft: 10
																				}}
																			>
																				Players
																			</Text>
																		</div>
																		<Text style={{ fontWeight: 700 }}>Score</Text>
																	</div>
																}
																renderItem={(match, index) => {
																	return (
																		<List.Item
																			key={index}
																			style={{
																				padding: '10px 20px',
																				display: 'flex',
																				justifyContent: 'space-between',
																				opacity:
																					match.is_bye === true ? 0.5 : 1.0
																			}}
																		>
																			<Text style={{ width: 60 }}>
																				{match.identifier}
																			</Text>
																			<Text style={{ width: 60 }}>
																				{match.match_number}
																			</Text>
																			<div
																				style={{
																					textAlign: 'left',
																					width: '100%'
																				}}
																			>
																				<div>
																					<Text
																						style={{
																							marginLeft: 0,
																							width: '100%',
																							whiteSpace: 'nowrap'
																						}}
																					>
																						{match.player_won ? (
																							<Badge
																								count="W"
																								style={{
																									backgroundColor:
																										'#87d068',
																									marginRight: 5
																								}}
																							/>
																						) : match.opponent_won ? (
																							<Badge
																								count="L"
																								style={{
																									backgroundColor:
																										'#f5222d',
																									marginRight: 5
																								}}
																							/>
																						) : null}
																						{match.player_name || 'N/A'}{' '}
																						{match.player_skill_level ? `(${match.player_skill_level})` : null}
																					</Text>
																					<Text
																						style={{
																							marginLeft: 5,
																							marginRight: 5
																						}}
																					>
																						vs.
																					</Text>
																					<Text
																						style={{
																							marginLeft: 0,
																							width: '100%',
																							whiteSpace: 'nowrap'
																						}}
																					>
																						{match.opponent_won ? (
																							<Badge
																								count="W"
																								style={{
																									backgroundColor:
																										'#87d068',
																									marginRight: 5
																								}}
																							/>
																						) : match.player_won ? (
																							<Badge
																								count="L"
																								style={{
																									backgroundColor:
																										'#f5222d',
																									marginRight: 5
																								}}
																							/>
																						) : null}
																						{match.opponent_name || 'N/A'}{' '}
																						{match.opponent_skill_level ? `(${match.opponent_skill_level})` : null}
																					</Text>
																				</div>
																				<div
																					style={{
																						display: 'flex',
																						alignItems: 'center',
																						justifyContent: 'flex-start',
																						marginTop: 5
																					}}
																				>
																					<Text>
																						Duration: <br />
																						<Icon type="clock-circle" />{' '}
																						{match.match_duration}
																					</Text>
																					<Text style={{ marginLeft: 10 }}>
																						Performance rating: <br />
																						{parseFloat(
																							match.match_rating
																						) > match.player_skill_level ? (
																							<div
																								style={{
																									color: 'green'
																								}}
																							>
																								<ArrowUpOutlined style={{ color: 'green' }} />{' '}
																								{match.match_rating}
																							</div>
																						) : parseFloat(
																							match.match_rating
																						) < match.player_skill_level ? (
																							<div
																								style={{ color: 'red' }}
																							>
																								<ArrowDownOutlined style={{ color: 'red' }} />{' '}
																								{match.match_rating}
																							</div>
																						) : (
																							<div>
																								{match.match_rating}
																							</div>
																						)}
																					</Text>
																				</div>
																			</div>

																			<Text
																				style={{
																					whiteSpace: 'nowrap',
																					width: 40
																				}}
																			>
																				{match.players_score || 0}-{match.opponents_score || 0}
																			</Text>
																		</List.Item>
																	);
																}}
															/> */}
                                                    </React.Fragment>
                                                );
                                            }}
                                        </Query>
                                    </TabPane>
                                    <TabPane tab="Stats" key="stats">
                                        <Query
                                            query={GET_TOURNAMENT_PLAYER_STATS_QUERY}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{
                                                tournament_id: tournamentID,
                                                player_id: player ? player.id : playerId,
                                            }}
                                            onCompleted={(data) => {
                                                console.log(data);
                                            }}
                                        >
                                            {({ loading, error, data }) => {
                                                if (loading) return <CircularLoader />;
                                                if (error) return <div>Error: {error.message}</div>;

                                                const players = data && data.tournament_players;
                                                const selectedPlayer = players && players.filter((p) => p.id === playerId);

                                                const matchesWon = selectedPlayer
                                                    .map((player) => {
                                                        const challenger1MatchesWon = player.challenger1_matches_won.aggregate.count;
                                                        const challenger2MatchesWon = player.challenger2_matches_won.aggregate.count;

                                                        return challenger1MatchesWon + challenger2MatchesWon;
                                                    })
                                                    .reduce((acc, total) => {
                                                        return acc + total;
                                                    }, 0);

                                                const matchesLost = selectedPlayer
                                                    .map((player) => {
                                                        const challenger1MatchesLost = player.challenger1_matches_lost.aggregate.count;
                                                        const challenger2MatchesLost = player.challenger2_matches_lost.aggregate.count;

                                                        return challenger1MatchesLost + challenger2MatchesLost;
                                                    })
                                                    .reduce((acc, total) => {
                                                        return acc + total;
                                                    }, 0);

                                                const totalMatches = matchesWon + matchesLost;
                                                const avgMatchesWon = parseFloat((matchesWon / totalMatches).toFixed(2) * 100);

                                                const gamesWon = selectedPlayer
                                                    .map((player) => {
                                                        const challenger1GamesWon1 = player.challenger1_matches_won.aggregate.sum.challenger1_score;
                                                        const challenger1GamesWon2 = player.challenger1_matches_lost.aggregate.sum.challenger1_score;
                                                        const challenger2GamesWon1 = player.challenger2_matches_won.aggregate.sum.challenger2_score;
                                                        const challenger2GamesWon2 = player.challenger2_matches_lost.aggregate.sum.challenger2_score;

                                                        return challenger1GamesWon1 + challenger1GamesWon2 + challenger2GamesWon1 + challenger2GamesWon2;
                                                    })
                                                    .reduce((acc, total) => {
                                                        return acc + total;
                                                    }, 0);

                                                const gamesLost = selectedPlayer
                                                    .map((player) => {
                                                        const challenger1GamesLost1 = player.challenger1_matches_won.aggregate.sum.challenger2_score;
                                                        const challenger1GamesLost2 = player.challenger1_matches_lost.aggregate.sum.challenger2_score;
                                                        const challenger2GamesLost1 = player.challenger2_matches_won.aggregate.sum.challenger1_score;
                                                        const challenger2GamesLost2 = player.challenger2_matches_lost.aggregate.sum.challenger1_score;

                                                        return challenger1GamesLost1 + challenger1GamesLost2 + challenger2GamesLost1 + challenger2GamesLost2;
                                                    })
                                                    .reduce((acc, total) => {
                                                        return acc + total;
                                                    }, 0);

                                                const totalGames = gamesWon + gamesLost;
                                                const avgGamesWon = parseFloat((gamesWon / totalGames).toFixed(2) * 100);

                                                const statsFormatted = {
                                                    id: selectedPlayer && selectedPlayer.id,
                                                    name: selectedPlayer && selectedPlayer.name,
                                                    place: selectedPlayer && selectedPlayer.place,
                                                    country: selectedPlayer && selectedPlayer.country,
                                                    points: selectedPlayer && selectedPlayer.points,
                                                    matches_won: `${matchesWon}-${matchesLost}`,
                                                    matches_won_pct: avgMatchesWon,
                                                    games_won: `${gamesWon}-${gamesLost}`,
                                                    games_won_pct: avgGamesWon,
                                                };

                                                return (
                                                    <div style={{ padding: 20 }}>
                                                        <Text style={{ fontWeight: 700, marginBottom: 10 }}>Player stats</Text>
                                                        {statsFormatted ? (
                                                            <table width="100%" className="info-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Match record</td>
                                                                        <td className="display-linebreak">
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'flex-start',
                                                                                }}
                                                                            >
                                                                                <Text
                                                                                    style={{
                                                                                        width: 80,
                                                                                        marginRight: 8,
                                                                                        whiteSpace: 'nowrap',
                                                                                    }}
                                                                                >
                                                                                    {statsFormatted.matches_won}
                                                                                </Text>
                                                                                <Progress
                                                                                    percent={parseInt(statsFormatted.matches_won_pct.toFixed(0)) || 0}
                                                                                    status="normal"
                                                                                    size="small"
                                                                                    showInfo={false}
                                                                                    strokeWidth={6}
                                                                                    strokeLinecap="square"
                                                                                    style={{ maxWidth: 100 }}
                                                                                />

                                                                                <div
                                                                                    style={{
                                                                                        width: 40,
                                                                                        marginLeft: 10,
                                                                                    }}
                                                                                >
                                                                                    <Text>{statsFormatted.matches_won_pct}%</Text>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Game record</td>
                                                                        <td className="display-linebreak">
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'flex-start',
                                                                                }}
                                                                            >
                                                                                <Text
                                                                                    style={{
                                                                                        width: 80,
                                                                                        marginRight: 8,
                                                                                        whiteSpace: 'nowrap',
                                                                                    }}
                                                                                >
                                                                                    {statsFormatted.games_won}
                                                                                </Text>
                                                                                <Progress
                                                                                    percent={parseInt(statsFormatted.games_won_pct.toFixed(0)) || 0}
                                                                                    status="normal"
                                                                                    size="small"
                                                                                    showInfo={false}
                                                                                    strokeWidth={6}
                                                                                    strokeLinecap="square"
                                                                                    style={{ maxWidth: 100 }}
                                                                                />

                                                                                <div
                                                                                    style={{
                                                                                        width: 40,
                                                                                        marginLeft: 10,
                                                                                    }}
                                                                                >
                                                                                    <Text>{statsFormatted.games_won_pct}%</Text>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Place</td>
                                                                        <td>{statsFormatted.place || '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Earnings</td>
                                                                        <td className="display-linebreak">
                                                                            <Text type="secondary">
                                                                                {(playerData.payouts && numeral(playerData.payouts.money).format('$0,00')) ||
                                                                                    '-'}
                                                                            </Text>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Auction</td>
                                                                        <td className="display-linebreak">
                                                                            <Text type="secondary">
                                                                                {(playerData.payouts && numeral(playerData.payouts.calcutta).format('$0,00')) ||
                                                                                    '-'}
                                                                            </Text>
                                                                        </td>
                                                                    </tr>
                                                                    {statsFormatted.points && (
                                                                        <tr>
                                                                            <td>Points</td>
                                                                            <td className="display-linebreak">
                                                                                <Text type="secondary">
                                                                                    {(statsFormatted.points && statsFormatted.points) || '-'}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        ) : (
                                                            <div>No stats found</div>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        </Query>
                                    </TabPane>
                                    {/* <TabPane tab="Stats" key="stats">
                                            <Query
                                                query={GET_TOURNAMENT_PLAYER_STATS_QUERY2}
                                                fetchPolicy="cache-and-network"
                                                notifyOnNetworkStatusChange={true}
                                                variables={{
                                                    tournament_id: tournamentID,
                                                    player_id: player ? player.id : playerId,
                                                }}
                                                onCompleted={(data) => {
                                                    console.log(data);
                                                }}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) return <CircularLoader />;
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    const stats = data && data.vw_tournament_statistics;
                                                    const statsFormatted = stats.map((player, index) => {
                                                        return {
                                                            id: player.id,
                                                            name: player.player_name,
                                                            place: player.place,
                                                            country: player.player_country,
                                                            points: player.points,
                                                            matches_won: player.match_record,
                                                            matches_won_pct: player.match_win_percentage,
                                                            games_won: player.game_record,
                                                            games_won_pct: player.game_win_percentage,
                                                            key: index,
                                                        };
                                                    })[0];

                                                    return (
                                                        <div style={{ padding: 20 }}>
                                                            <Text style={{ fontWeight: 700, marginBottom: 10 }}>Player stats</Text>
                                                            {statsFormatted ? (
                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        
                                                                        <tr>
                                                                            <td>Match record</td>
                                                                            <td className="display-linebreak">
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={{
                                                                                            width: 80,
                                                                                            marginRight: 8,
                                                                                            whiteSpace: 'nowrap',
                                                                                        }}
                                                                                    >
                                                                                        {statsFormatted.matches_won}
                                                                                    </Text>
                                                                                    <Progress
                                                                                        percent={parseInt(statsFormatted.matches_won_pct.toFixed(0)) || 0}
                                                                                        status="normal"
                                                                                        size="small"
                                                                                        showInfo={false}
                                                                                        strokeWidth={6}
                                                                                        strokeLinecap="square"
                                                                                        style={{ maxWidth: 100 }}
                                                                                    />

                                                                                    <div
                                                                                        style={{
                                                                                            width: 40,
                                                                                            marginLeft: 10,
                                                                                        }}
                                                                                    >
                                                                                        <Text>{statsFormatted.matches_won_pct}%</Text>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Game record</td>
                                                                            <td className="display-linebreak">
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'flex-start',
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={{
                                                                                            width: 80,
                                                                                            marginRight: 8,
                                                                                            whiteSpace: 'nowrap',
                                                                                        }}
                                                                                    >
                                                                                        {statsFormatted.games_won}
                                                                                    </Text>
                                                                                    <Progress
                                                                                        percent={parseInt(statsFormatted.games_won_pct.toFixed(0)) || 0}
                                                                                        status="normal"
                                                                                        size="small"
                                                                                        showInfo={false}
                                                                                        strokeWidth={6}
                                                                                        strokeLinecap="square"
                                                                                        style={{ maxWidth: 100 }}
                                                                                    />

                                                                                    <div
                                                                                        style={{
                                                                                            width: 40,
                                                                                            marginLeft: 10,
                                                                                        }}
                                                                                    >
                                                                                        <Text>{statsFormatted.games_won_pct}%</Text>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Place</td>
                                                                            <td>{statsFormatted.place || '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Earnings</td>
                                                                            <td className="display-linebreak">
                                                                                <Text type="secondary">
                                                                                    {(playerData.payouts &&
                                                                                        numeral(playerData.payouts.money).format('$0,00')) ||
                                                                                        '-'}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Auction</td>
                                                                            <td className="display-linebreak">
                                                                                <Text type="secondary">
                                                                                    {(playerData.payouts &&
                                                                                        numeral(playerData.payouts.calcutta).format('$0,00')) ||
                                                                                        '-'}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        {statsFormatted.points && (
                                                                            <tr>
                                                                                <td>Points</td>
                                                                                <td className="display-linebreak">
                                                                                    <Text type="secondary">
                                                                                        {(statsFormatted.points && statsFormatted.points) || '-'}
                                                                                    </Text>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <div>No stats found</div>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                            </Query>
                                        </TabPane> */}
                                </Tabs>
                            </Modal>
                        );
                    }}
                </Query>
            )}
        </React.Fragment>
    );
}

const MemoizedPlayerModal = React.memo(PlayerModal);
export default withRouter(MemoizedPlayerModal);
