import React, { useState, useEffect } from 'react';
import { Icon, Avatar, Row, Col, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import Masonry from '../../../components/Masonry';
import RSSParser from 'rss-parser';
import moment from 'moment';

const { Paragraph, Title, Text } = Typography;
// const { Meta } = Card;

const masonryBreakPoints = [ 600, 960, 1280, 1920 ];

function createMarkup(markup) {
	return { __html: markup };
}

export default function NewsFeed(props) {
	const [ articles, setArticles ] = useState();
	const [ error, setError ] = useState('');

	useEffect(() => {
		let isSubscribed = true;

		let parser = new RSSParser();
		const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';
		parser
			.parseURL(CORS_PROXY + 'http://www.azbilliards.com/rss/feed.xml')
			.then((response) => {
				if (isSubscribed) {
					setArticles(response.items);
				}
			})
			.catch((error) => (isSubscribed ? setError(error.toString()) : null));

		// Specify how to clean up after this effect
		return () => (isSubscribed = false);
	}, []);

	return (
		<Row gutter={16} style={{ marginTop: 10 }}>
			<Col xs={24}>
				{!error && !articles && <div className="loading">Loading...</div>}

				{!error &&
				articles && (
					<div className="masonry-container">
						<Masonry breakPoints={masonryBreakPoints}>
							{articles.map((article, index) => {
								return (
									<React.Fragment key={index}>
										<Card
											hoverable
											// cover={
											// 	<img
											// 		alt="example"
											// 		src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
											// 	/>
											// }
											// title={article.title}
											onClick={() => {
												window.open(article.link);
											}}
										>
											<Meta
												// avatar={
												// 	<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
												// }
												// title={article.title}
												description={
													<React.Fragment>
														<Title
															level={3}
															style={{
																borderLeft: '5px solid #1890ff',
																paddingLeft: 14
															}}
														>
															{article.title}
														</Title>
														<Text type="secondary">
															{moment(article.isoDate).format('MMMM Do YYYY')}
														</Text>
														<div
															style={{ marginTop: 10, color: 'rgba(0, 0, 0, 0.85)' }}
															dangerouslySetInnerHTML={createMarkup(
																article.contentSnippet
															)}
														/>
													</React.Fragment>
												}
											/>
										</Card>
									</React.Fragment>
								);
							})}
						</Masonry>
					</div>
				)}
				{error && <div className="error">{error}</div>}
			</Col>
		</Row>
	);
}
