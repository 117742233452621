import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Row, Input, Col, Typography, Table, Statistic, Tabs } from 'antd';
import Card, { Meta } from '../../components/Card';
import { Link, withRouter } from 'react-router-dom';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionTitle from '../../components/SectionTitle';
import CircularLoader from '../../components/CircularLoader';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import MatchTable from './matches/MatchTable';
import PublicMatchesTable from './matches/PublicMatchesTable';
import ChallengesTable from './matches/ChallengesTable';
import { TrophyFilled, TeamOutlined, CrownOutlined, EyeOutlined } from '@ant-design/icons';
import AdZoneTemplate from '../../components/advertising/AdZoneTemplate';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    tab: {
        paddingLeft: 4,
        paddingRight: 4,
    },
};

function Matches(props) {
    const { authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <SectionWrapper>
            <SectionTitle title="Matches" height={breakpoint === 'mobile' ? 80 : 100} />
            <SectionContent padding="20px 40px">
                <Row gutter={16}>
                    <Col xs={24}>
                        <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                    </Col>
                    <Col xs={24} xl={12}>
                        <Link to={authState && authState.user && authState.user.id ? '/matches/new' : '/login'}>
                            <Card
                                hoverable
                                style={{
                                    width: '100%',
                                    minWidth: 300,
                                    // maxWidth: 600,
                                    borderRadius: '8px',
                                    overflow: 'hidden',

                                    // boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                    border: 'none',
                                    // boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)',
                                    height: '100%',
                                    transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
                                    display: 'flex',
                                }}
                                bodyStyle={{
                                    padding: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        width: 100,
                                        padding: 10,
                                        background: 'linear-gradient(#ffbc00, #ff9900)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TeamOutlined style={{ fontSize: 40, color: '#fff' }} />
                                </div>
                                <Meta
                                    title={
                                        <Title level={3} style={{ margin: 0 }}>
                                            Create new match
                                        </Title>
                                    }
                                    description={
                                        <div
                                            style={{
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            }}
                                        >
                                            <Text>Create a new match between two players and keep track of live scoring and statistics.</Text>
                                        </div>
                                    }
                                    style={{
                                        flex: 1,
                                        padding: 30,
                                    }}
                                />
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Card
                            style={{
                                width: '100%',
                                minWidth: 300,
                                // maxWidth: 600,
                                borderRadius: '8px',
                                overflow: 'hidden',

                                // boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                border: 'none',
                                // boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)',
                                height: '100%',
                                transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
                                display: 'flex',
                            }}
                            bodyStyle={{
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    width: 100,
                                    padding: 10,
                                    background: 'linear-gradient(#0487ca, #0148b7)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CrownOutlined style={{ fontSize: 40, color: '#fff' }} />
                            </div>
                            <Meta
                                title={
                                    <Title level={3} style={{ margin: 0 }}>
                                        Create new challenge (coming soon)
                                    </Title>
                                }
                                description={
                                    <div
                                        style={{
                                            maxHeight: 300,
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <Text>Challenge other players to a 1v1 match in person or online and work your way up the player rankings.</Text>
                                    </div>
                                }
                                style={{
                                    flex: 1,
                                    padding: 30,
                                }}
                            />
                        </Card>
                    </Col>
                    {/* <Col xs={24} xl={8}>
						<Link to="/digital-scoreboard">
							<Card
								hoverable
								style={{
									width: '100%',
									minWidth: 300,
									// maxWidth: 600,
									borderRadius: '8px',
									overflow: 'hidden',

									// boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
									border: 'none',
									boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)',
									height: '100%',
									transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
									display: 'flex'
								}}
								bodyStyle={{
									padding: 0,
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<div
									style={{
										height: '100%',
										width: 100,
										padding: 10,
										background: 'linear-gradient(#24bfe2, #0091d6)',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}
								>
									<EyeOutlined style={{ fontSize: 40, color: '#fff' }} />
								</div>
								<Meta
									title={
										<Title level={3} style={{ margin: 0 }}>
											Try digital scoreboard
										</Title>
									}
									description={
										<div
											style={{
												maxHeight: 300,
												overflowY: 'auto'
											}}
										>
											<Text>
												Try out our live digital scoreboard to score a game or match without
												saving the results.
											</Text>
										</div>
									}
									style={{
										flex: 1,
										padding: 20
									}}
								/>
							</Card>
						</Link>
					</Col> */}
                    <Col xs={24}>
                        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Card title="All matches" bodyStyle={{ padding: 0 }}>
                                <Tabs
                                    defaultActiveKey="public"
                                    animated={{ inkBar: true, tabPane: false }}
                                    tabBarStyle={{
                                        textTransform: 'uppercase',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <TabPane tab="Public matches" key="public">
                                        <PublicMatchesTable {...props} />
                                    </TabPane>
                                    <TabPane tab="My matches" key="matches">
                                        <MatchTable {...props} />
                                    </TabPane>
                                    <TabPane tab="My challenges" key="challenges">
                                        <ChallengesTable {...props} />
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(Matches);
