import React from 'react';
import { Empty, Typography } from 'antd';
import dpIcon from '../assets/img/dp-icon.png';
import dpIconDark from '../assets/img/dp-icon-dark.png';

const { Text } = Typography;

export default function ZeroState(props) {
    const { style, showImage, message, messageStyle, theme } = props;

    return (
        <React.Fragment>
            {showImage !== false ? (
                <Empty
                    // image={poolTableImg}
                    image={theme && theme.name === 'dark' ? dpIcon : dpIconDark}
                    imageStyle={{
                        height: 100,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    description={<Text style={messageStyle ? messageStyle : { fontSize: 18 }}>{message}</Text>}
                    style={style ? style : { margin: 0 }}
                >
                    {props.children}
                </Empty>
            ) : (
                <Empty
                    image
                    imageStyle={{ display: 'none' }}
                    description={<Text style={messageStyle ? messageStyle : { fontSize: 18 }}>{message}</Text>}
                    style={style ? style : { margin: 0 }}
                >
                    {props.children}
                </Empty>
            )}
        </React.Fragment>
    );
}
