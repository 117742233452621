import React, { useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, Select, Input, Radio, SubmitButton, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { Popconfirm, Modal, message, Layout, Avatar, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_TOURNAMENT_BRACKET_TEMPLATE, CREATE_TOURNAMENT_BRACKET_SEGMENT } from './data/mutations';
import * as Yup from 'yup';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import slugify from 'slugify';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function TBBracketCreateTemplate(props) {
    const { authUser } = props;
    const [loading, setLoading] = useState(false);
    const [createBracketTemplate] = useMutation(CREATE_TOURNAMENT_BRACKET_TEMPLATE);
    const [createBracketSegment] = useMutation(CREATE_TOURNAMENT_BRACKET_SEGMENT);

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name required'),
    });

    function handleSubmit(values, actions) {
        const { name, type } = values;
        console.log({ values, actions });
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        createBracketTemplate({
            variables: {
                objects: [
                    {
                        name,
                        type,
                        slug,
                        creator_id: props.authState.user.id,
                    },
                ],
            },
        })
            .then(async (res) => {
                actions.setSubmitting(false);
                actions.resetForm();
                console.log(res);
                setLoading(false);
                const result = res.data.insert_tournament_bracket_templates.returning[0];
                if (type === 'DOUBLE_ELIMINATION') {
                    await createSegment('A side', result);
                    await createSegment('B side', result);
                } else if (type === 'SINGLE_ELIMINATION') {
                    await createSegment('A side', result);
                } else {
                    await createSegment('A side', result);
                }

                message.success('Bracket template created');
                props.history.push(`/tournament-builder/bracket-creator/${result.slug}`);
            })
            .catch((e) => {
                actions.setSubmitting(false);
                if (e.message && e.message.includes('Uniqueness violation')) {
                    message.error('Error: There is already a template with that name. Please choose a different name.');
                } else {
                    message.error((e && e.message) || JSON.stringify(e));
                }

                console.log(e);
            });
    }

    function createSegment(name, template) {
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });
        return createBracketSegment({
            variables: {
                objects: [
                    {
                        name,
                        slug,
                        tournament_bracket_template_id: template.id,
                    },
                ],
            },
        })
            .then((data) => {
                console.log(data);
                return data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Create Bracket Template"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={90}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/tournament-builder/bracket-creator`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                // subtitle="Select the type of tournament you want to run"
                // subtitleStyle={{ color: '#fff' }}
                theme="tournamentBuilder"
            />
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Formik
                            initialValues={{
                                name: '',
                                type: 'SINGLE_ELIMINATION',
                                creator_id: undefined,
                            }}
                            validationSchema={SignupSchema}
                            onSubmit={(values, actions) => {
                                console.log(values);
                                handleSubmit(values, actions);
                            }}
                            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 400 }}>
                                    <FormItem name="name" label="Template Name" required hasFeedback showValidateSuccess>
                                        <Input name="name" placeholder="Name" size="large" />
                                    </FormItem>

                                    <FormItem label="Type" name="type">
                                        <Select name="type" defaultValue="SINGLE_ELIMINATION" size="large">
                                            <Option value="SINGLE_ELIMINATION">Single Elimination</Option>
                                            <Option value="DOUBLE_ELIMINATION">Double Elimination</Option>
                                            <Option value="CUSTOM">Custom</Option>
                                        </Select>
                                    </FormItem>

                                    <SubmitButton
                                        size="large"
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    >
                                        Create Template
                                    </SubmitButton>

                                    <div>
                                        <Text style={styles.error}>{errors.general}</Text>
                                    </div>
                                    <div>
                                        <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                    </div>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBBracketCreateTemplate);
