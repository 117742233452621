import React from 'react';
import { GET_TOURNAMENT_PLAYER_PODIUM } from './data/queries';
import { GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION } from './data/subscriptions';
import { Query } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import PodiumPlayers from './PodiumPlayers';
import './podium.css';

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    avatar: {
        margin: 0,
    },
    bigAvatar: {
        width: 40,
        height: 40,
    },
};

function Podium(props) {
    const { tournamentID } = props;

    return (
        <div className="podium" style={props.style}>
            <Query
                query={GET_TOURNAMENT_PLAYER_PODIUM}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournamentID }}
                onCompleted={(data) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data, subscribeToMore }) => {
                    const players = data && data.tournament_players;
                    return (
                        <React.Fragment>
                            {players && players.length > 0 && (
                                <React.Fragment>
                                    <PodiumPlayers players={players} />
                                    {/* <PodiumPlayers
                                            players={players}
                                            subscribeToMore={subscribeToMore}
                                            subscribeToNewData={() => {
                                                subscribeToMore({
                                                    document: GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION,
                                                    variables: { tournament_id: tournamentID },
                                                    updateQuery: (prev, { subscriptionData }) => {
                                                        if (!subscriptionData.data) return prev;
                                                        const newPlayers = subscriptionData.data.tournament_players;
                                                        let updated;
                                                        if (prev) {
                                                            updated = Object.assign({}, prev, {
                                                                tournament_players: [...newPlayers],
                                                            });
                                                        } else {
                                                            updated = Object.assign({}, prev, {
                                                                tournament_players: [...newPlayers],
                                                            });
                                                        }
                                                        return updated;
                                                    },
                                                });
                                            }}
                                        /> */}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    );
                }}
            </Query>
        </div>
    );
}

export default Podium;
