import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { PageHeader, Tag, Row, Col, Card, Icon, Tabs, Button, Empty, Typography } from 'antd';
// import SectionHeader from '../../../components/SectionHeader';
// import SectionContent from '../../../components/SectionContent';
// import Card from '../../../components/Card';
// import usOpenHeader from '../../../assets/img/us-open-header.png';
import SectionHeader from '../../../components/SectionHeader';
import CircularLoader from '../../../components/CircularLoader';
import DPTournamentSchedule from './DPTournamentSchedule2';
import DPTournamentMatches from './DPTournamentMatches';
import DPTournamentScores from './DPTournamentScores2';
import DPTournamentPlayers from './DPTournamentPlayers';
import DPTournamentResults from './DPTournamentResults';
import DPTournamentBracket from './DPTournamentBracket';
import DPTournamentViewer from './DPTournamentViewer';
import DPTournamentStats from './DPTournamentStats2';
import DPTournamentPerformance from './DPTournamentPerformance';
import DPTournamentOverview from './DPTournamentOverview';
import DPRoundRobinViewer from './DPRoundRobinViewer';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Query } from '@apollo/client/react/components';
import qs from 'query-string';
import ZeroState from '../../../components/ZeroState';
import useBreakpoint from 'use-breakpoint';
import EmbedModal from './EmbedModal';
import ShareModal from './ShareModal';
import HelmetMetaData from '../../../components/HelmetMetaData';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
// import TabContent from 'rc-tabs/lib/TabContent';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import * as routes from '../../../config/routes';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function DPTournamentDetail(props) {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { client, authState, match } = props;
    const urlPath = window.location.pathname;
    const tournamentPath = props.match.params.slug;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['overview', 'schedule', 'bracket', 'matches', 'scores', 'results', 'stats', 'players', 'performance', 'viewer'];
    const [current, setCurrent] = useState();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [embedModalVisible, setEmbedModalVisible] = useState(false);
    const [shareModalVisible, setShareModalVisible] = useState(false);
    const [selectedStage, setSelectedStage] = useState();
    const [stages, setStages] = useState();
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const showSubNavigation = params && params.subnavigation === 'false' ? false : true;
    const showLiveScores = params && params.live_scores === 'true' ? true : false;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const isPrint = params && params.print === 'true' ? true : false;
    const stageSlug = props.match.params.stage;

    const styles = {
        headerImg: {
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
            // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            // backgroundPosition: '0px -400px'

            // backgroundPosition: '0px -40px'
        },
        header: {
            // background: 'linear-gradient(180deg, #07101f, #14243b)',
            // background: props.theme === 'dark' ? '#000' : 'linear-gradient(180deg, #fff, #f0f2f5)',
            // borderBottom: '1px solid #e0e3ea',
            paddingTop: breakpoint === 'mobile' ? 5 : 10,
            paddingLeft: breakpoint === 'mobile' ? 10 : 40,
            paddingRight: breakpoint === 'mobile' ? 0 : 0,
            paddingBottom: breakpoint === 'mobile' ? 0 : 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[3]) ? splitPath[3] : 'overview';

        setCurrent(pathMatch);
        // console.log(pathMatch);
    }, [urlPath]);

    const handleTabClick = (tab) => {
        setCurrent(tab);
        let url;
        if (params) {
            // console.log(params);
            const paramsFormatted = new URLSearchParams(params).toString();
            // console.log(paramsFormatted);
            url = `/tournaments/${tournamentPath}/${tab}?${paramsFormatted}`;
        } else {
            url = `/tournaments/${tournamentPath}/${tab}`;
        }
        props.history.push(url);
    };

    const showEmbedModal = () => {
        setEmbedModalVisible(true);
    };

    const handleEmbedModalOk = () => {
        setEmbedModalVisible(false);
    };

    const handleEmbedModalCancel = () => {
        setEmbedModalVisible(false);
    };

    const showShareModal = () => {
        setShareModalVisible(true);
    };

    const handleShareModalOk = () => {
        setShareModalVisible(false);
    };

    const handleShareModalCancel = () => {
        setShareModalVisible(false);
    };

    return (
        <React.Fragment>
            <Query
                query={GET_TOURNAMENT_BY_SLUG}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ slug: props.match.params.slug }}
                onCompleted={(data) => {
                    // var set = [];
                    // for (let i = 0; i < data.tournament_payouts.length; i++) {
                    // 	set.push(data.tournament_payouts[i]);
                    // }
                    // let formattedData = orderAndFormatData(set);
                    // setTableData(formattedData);

                    const tournament = data.tournaments && data.tournaments[0] && data.tournaments[0];
                    const stages = tournament && tournament.stages;
                    console.log('stages', stages);
                    if (stages && stages.length > 0) {
                        let defaultStage;
                        if (stageSlug) {
                            defaultStage = stages.filter((stage) => {
                                return stage.slug === stageSlug;
                            })[0];
                        } else {
                            defaultStage = stages[0];
                        }
                        console.log(defaultStage);
                        setStages(stages);
                        setSelectedStage(defaultStage);
                    }
                }}
            >
                {({ loading, error, data, refetch }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    const tournamentData = data.tournaments && data.tournaments[0];
                    const tournamentType = (selectedStage && selectedStage.tournament_type) || (tournamentData && tournamentData.tournament_type);
                    console.log('tournament data', tournamentData);
                    console.log('tournament type', tournamentType);

                    if (!tournamentData) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <ZeroState showImage message="Tournament not found" style={{ margin: 30 }} {...props} />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else if (tournamentData.is_public === false && showLiveScores === false && !authState.user) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <ZeroState showImage message="Tournament not found" style={{ margin: 30 }} {...props} />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else if (
                        tournamentData.is_public === false &&
                        authState &&
                        authState.user &&
                        (tournamentData.organizer_id !== authState.user.id || tournamentData.owner_id !== authState.user.id) &&
                        authState.user.role !== 'admin'
                    ) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <ZeroState showImage message="Tournament not found" style={{ margin: 30 }} {...props} />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else {
                        if (isPrint === true) {
                            return <DPTournamentBracket tournament={tournamentData} {...props} />;
                        } else {
                            return (
                                <React.Fragment>
                                    {/* <HelmetMetaData title={tournamentData.name} description={tournamentData.description} image={tournamentData.flyer} /> */}
                                    <Helmet>
                                        <title>{tournamentData.name}</title>
                                        <meta name="description" content={tournamentData.description} />
                                        <meta name="twitter:card" content="summary_large_image" />
                                        <meta
                                            name="twitter:title"
                                            content={tournamentData.name || 'DigitalPool - Bridging the gap between player and technology'}
                                        />
                                        <meta name="twitter:description" content={tournamentData.description} />
                                        <meta name="twitter:image" content={tournamentData.flyer || 'https://digitalpool.com/images/fb-image.png'} />
                                        <meta
                                            property="og:title"
                                            content={tournamentData.name || 'DigitalPool - Bridging the gap between player and technology'}
                                        />
                                        <meta property="og:description" content={tournamentData.description} />
                                        <meta property="og:image" content={tournamentData.flyer || 'https://digitalpool.com/images/fb-image.png'} />
                                        <meta property="og:url" content={`https://www.digitalpool.com/tournaments/${tournamentData.slug}`} />
                                        <meta property="og:site_name" content="digitalpool.com" />
                                        <meta property="og:locale" content="en_US" />
                                        <meta property="og:type" content="website" />
                                        <meta property="fb:app_id" content="1697957547011275" />
                                    </Helmet>

                                    <div style={styles.headerImg}>
                                        {/* {breakpoint === 'tablet' && isFullScreen !== true && showNavigation !== false && (
                                            <div style={{ padding: '20px 40px 0px 40px' }}>
                                                <BreadcrumbContainer theme="dark" />
                                            </div>
                                        )} */}
                                        {showNavigation !== false && (
                                            <React.Fragment>
                                                {authState &&
                                                authState.user &&
                                                (tournamentData.organizer_id === authState.user.id ||
                                                    tournamentData.owner_id === authState.user.id ||
                                                    authState.user.role === 'admin') ? (
                                                    <SectionHeader
                                                        title={
                                                            <Text
                                                                style={
                                                                    breakpoint === 'mobile'
                                                                        ? { fontSize: 16, color: '#fff', maxWidth: 300 }
                                                                        : { fontSize: 20, color: '#fff' }
                                                                }
                                                            >
                                                                {tournamentData && tournamentData.name.toUpperCase()}
                                                            </Text>
                                                        }
                                                        titleStyle={{
                                                            color: '#fff',
                                                            textTransform: 'uppercase',
                                                            margin: 0,
                                                            display: 'flex',
                                                            letterSpacing: breakpoint === 'mobile' ? 0 : 6,
                                                            paddingRight: 10,
                                                        }}
                                                        backButton={
                                                            <div
                                                                style={{
                                                                    paddingLeft: 0,
                                                                    paddingBottom: 10,
                                                                    paddingTop: 10,
                                                                    paddingRight: 0,
                                                                }}
                                                            >
                                                                <Icon
                                                                    type="arrow-left"
                                                                    onClick={() => props.history.push('/tournaments')}
                                                                    style={{ color: '#fff' }}
                                                                />
                                                            </div>
                                                        }
                                                        subTitle={
                                                            tournamentData && tournamentData.status === 'IN_PROGRESS' ? <Tag color="#f5222d">LIVE</Tag> : ''
                                                        }
                                                        subtitleStyle={{ color: '#fff' }}
                                                        style={styles.header}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <Button ghost onClick={() => props.history.push(`/tournament-builder/${tournamentData.slug}`)}>
                                                                {breakpoint === 'mobile' ? (
                                                                    <React.Fragment>
                                                                        <Icon type="setting" />
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <Icon type="setting" /> Manage
                                                                    </React.Fragment>
                                                                )}
                                                            </Button>
                                                            <Button
                                                                ghost
                                                                onClick={() => {
                                                                    showEmbedModal();
                                                                }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#fff">
                                                                    <path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z" />
                                                                </svg>
                                                            </Button>
                                                            <Button
                                                                ghost
                                                                onClick={() => {
                                                                    showShareModal();
                                                                }}
                                                            >
                                                                <Icon type="share-alt" />
                                                            </Button>
                                                        </div>
                                                    </SectionHeader>
                                                ) : (
                                                    <SectionHeader
                                                        title={
                                                            <Text
                                                                style={
                                                                    breakpoint === 'mobile'
                                                                        ? { fontSize: 16, color: '#fff', maxWidth: 300 }
                                                                        : { fontSize: 20, color: '#fff' }
                                                                }
                                                            >
                                                                {tournamentData && tournamentData.name.toUpperCase()}
                                                            </Text>
                                                        }
                                                        titleStyle={{
                                                            color: '#fff',
                                                            textTransform: 'uppercase',
                                                            margin: 0,
                                                            display: 'flex',
                                                        }}
                                                        backButton={
                                                            <div
                                                                style={{
                                                                    paddingLeft: 0,
                                                                    paddingBottom: 10,
                                                                    paddingTop: 10,
                                                                    paddingRight: 0,
                                                                }}
                                                            >
                                                                <Icon
                                                                    type="arrow-left"
                                                                    onClick={() => props.history.push('/tournaments')}
                                                                    style={{ color: '#fff' }}
                                                                />
                                                            </div>
                                                        }
                                                        subTitle={
                                                            tournamentData && tournamentData.status === 'IN_PROGRESS' ? <Tag color="#f5222d">LIVE</Tag> : ''
                                                        }
                                                        subtitleStyle={{ color: '#fff' }}
                                                        style={styles.header}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <Button
                                                                ghost
                                                                onClick={() => {
                                                                    showEmbedModal();
                                                                }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#fff">
                                                                    <path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z" />
                                                                </svg>
                                                            </Button>
                                                            <Button
                                                                ghost
                                                                onClick={() => {
                                                                    showShareModal();
                                                                }}
                                                            >
                                                                <Icon type="share-alt" />
                                                            </Button>
                                                        </div>
                                                    </SectionHeader>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {showSubNavigation !== false ? (
                                            <Tabs
                                                defaultActiveKey={current}
                                                activeKey={current}
                                                animated={{ inkBar: true, tabPane: false }}
                                                // renderTabBar={() => <ScrollableInkTabBar />}
                                                // renderTabContent={() => <TabContent />}
                                                onTabClick={handleTabClick}
                                                className="tabs-bg"
                                                tabPosition="top"
                                                tabBarStyle={{
                                                    textTransform: 'uppercase',
                                                    paddingLeft: breakpoint === 'mobile' ? 0 : 24,
                                                    paddingRight: breakpoint === 'mobile' ? 0 : 24,
                                                    paddingTop: 0,
                                                    // backgroundColor: '#132339',
                                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                                    color: '#fff',
                                                    zIndex: 1,
                                                    position: 'relative',
                                                    borderBottom: 'none',
                                                    borderTop: '1px solid rgba(255,255,255,0.1)',
                                                }}
                                            >
                                                <TabPane tab="Overview" key="overview">
                                                    <DPTournamentOverview tournament={tournamentData} {...props} />
                                                </TabPane>
                                                {tournamentType === 'chip' ? (
                                                    <TabPane tab="Viewer" key="viewer">
                                                        <DPTournamentViewer tournament={tournamentData} {...props} />
                                                    </TabPane>
                                                ) : tournamentType === 'round_robin' ? (
                                                    <TabPane tab="Viewer" key="viewer">
                                                        <DPRoundRobinViewer
                                                            tournament={tournamentData}
                                                            stages={stages}
                                                            selectedStage={selectedStage}
                                                            urlPath="/viewer"
                                                            onSelectedStage={(stage) => {
                                                                setSelectedStage(stage);
                                                            }}
                                                            compact={false}
                                                            {...props}
                                                        />
                                                    </TabPane>
                                                ) : stages && stages.length > 1 ? (
                                                    <TabPane tab="Viewer" key="viewer">
                                                        <DPTournamentBracket
                                                            tournament={tournamentData}
                                                            stages={stages}
                                                            selectedStage={selectedStage}
                                                            urlPath="/viewer"
                                                            onStageSelected={(stage) => {
                                                                setSelectedStage(stage);
                                                            }}
                                                            compact={false}
                                                            {...props}
                                                        />
                                                    </TabPane>
                                                ) : (
                                                    <TabPane tab="Bracket" key="bracket">
                                                        <DPTournamentBracket tournament={tournamentData} {...props} />
                                                    </TabPane>
                                                )}

                                                <TabPane tab="Live Scores" key="scores" id="scores">
                                                    <DPTournamentScores tournament={tournamentData} {...props} />
                                                </TabPane>

                                                <TabPane tab="Schedule" key="schedule">
                                                    <DPTournamentSchedule tournament={tournamentData} {...props} />
                                                </TabPane>

                                                <TabPane tab="Matches" key="matches">
                                                    <DPTournamentMatches tournament={tournamentData} {...props} />
                                                </TabPane>

                                                <TabPane tab="Players" key="players">
                                                    <DPTournamentPlayers tournament={tournamentData} {...props} />
                                                </TabPane>

                                                <TabPane tab="Stats" key="stats">
                                                    <DPTournamentStats tournament={tournamentData} {...props} />
                                                </TabPane>
                                                <TabPane tab="Performance" key="performance">
                                                    <DPTournamentPerformance tournament={tournamentData} {...props} />
                                                </TabPane>
                                            </Tabs>
                                        ) : (
                                            <React.Fragment>
                                                {current === 'overview' ? (
                                                    <DPTournamentOverview tournament={tournamentData} {...props} />
                                                ) : current === 'bracket' ? (
                                                    <DPTournamentBracket tournament={tournamentData} {...props} />
                                                ) : current === 'viewer' ? (
                                                    <DPTournamentViewer tournament={tournamentData} {...props} />
                                                ) : current === 'scores' ? (
                                                    <DPTournamentScores tournament={tournamentData} {...props} />
                                                ) : current === 'schedule' ? (
                                                    <DPTournamentSchedule tournament={tournamentData} {...props} />
                                                ) : current === 'matches' ? (
                                                    <DPTournamentMatches tournament={tournamentData} {...props} />
                                                ) : current === 'players' ? (
                                                    <DPTournamentPlayers tournament={tournamentData} {...props} />
                                                ) : current === 'stats' ? (
                                                    <DPTournamentStats tournament={tournamentData} {...props} />
                                                ) : current === 'performance' ? (
                                                    <DPTournamentPerformance tournament={tournamentData} {...props} />
                                                ) : (
                                                    <DPTournamentOverview tournament={tournamentData} {...props} />
                                                )}
                                            </React.Fragment>
                                        )}

                                        <EmbedModal
                                            tournament={tournamentData}
                                            modalVisible={embedModalVisible}
                                            onOk={handleEmbedModalOk}
                                            onCancel={handleEmbedModalCancel}
                                        />
                                        <ShareModal
                                            tournament={tournamentData}
                                            modalVisible={shareModalVisible}
                                            onOk={handleShareModalOk}
                                            onCancel={handleShareModalCancel}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        }
                    }
                }}
            </Query>
        </React.Fragment>
    );
}

export default withRouter(DPTournamentDetail);
