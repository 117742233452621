import React from 'react';
import { Typography, Row, Col, Table } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import HeaderImage from '../../../components/HeaderImage';
import Card, { Meta } from '../../../components/Card';
import blueBg from '../../../assets/img/blue-bg.png';

const { Title, Text } = Typography;

const styles = {
	headerImg: {
		height: 120,
		overflow: 'hidden',
		borderRadius: 0,
		background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
		// backgroundAttachment: 'fixed',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 40,
		paddingRight: 40
		// backgroundPosition: '0px -40px'
	}
};

const columns = [
	{
		title: 'Match',
		dataIndex: 'match',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.name.localeCompare(b.name);
		// }
	},
	{
		title: 'Time',
		dataIndex: 'time',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.name.localeCompare(b.name);
		// }
	},
	{
		title: 'Home Team',
		dataIndex: 'homeTeam',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.record.localeCompare(b.record);
		// }
	},
	{
		title: 'Results',
		dataIndex: 'results',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.record.localeCompare(b.record);
		// }
	},
	{
		title: 'Visiting Team',
		dataIndex: 'visitingTeam',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.record.localeCompare(b.record);
		// }
	},
	{
		title: 'Location',
		dataIndex: 'location',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.record.localeCompare(b.record);
		// }
	},
	{
		title: 'Table',
		dataIndex: 'table',
		defaultSortOrder: 'ascend'
		// sorter: (a, b) => {
		// 	return a.record.localeCompare(b.record);
		// }
	}
];
const data = [
	{
		key: '1',
		week: '1',
		match: '1',
		time: '7/31/2019 7:00 PM',
		homeTeam: 'English Majors',
		results: 'View',
		visitingTeam: 'Get & Wild Bunch',
		location: 'Snookered Billiards',
		table: '1 & 10'
	},
	{
		key: '2',
		week: '1',
		match: '2',
		time: '7/31/2019 7:00 PM',
		homeTeam: '4 Horsemen',
		results: 'View',
		visitingTeam: 'Poke & Hope',
		location: 'Snookered Billiards',
		table: '1 & 10'
	}
];

export default function DPDivisionSchedule(props) {
	const { league, division } = props;
	return (
		<SectionWrapper>
			{/* <SectionTitle
				title="Schedule"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
			{/* <HeaderImage /> */}
			<SectionContent padding="20px 40px">
				<Row gutter={24} justify="center" type="flex">
					<Col xs={24} sm={props.navType === 'desktop' ? 24 : 22} style={{ paddingTop: 0, paddingBottom: 0 }}>
						<Row gutter={24}>
							<Col xs={24}>
								<Card
									cover={
										<div
											style={{
												paddingTop: 16,
												paddingBottom: 16,
												paddingLeft: 24,
												paddingRight: 24,
												// background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
												borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
											}}
										>
											<Title level={4} style={{ margin: 0, padding: 0 }}>
												Week 1
											</Title>
										</div>
									}
									bodyStyle={{ padding: 0 }}
								>
									<Table columns={columns} dataSource={data} pagination={false} />
								</Card>
							</Col>
							<Col xs={24}>
								<Card
									cover={
										<div
											style={{
												paddingTop: 16,
												paddingBottom: 16,
												paddingLeft: 24,
												paddingRight: 24,
												// background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
												borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
											}}
										>
											<Title level={4} style={{ margin: 0, padding: 0 }}>
												Week 2
											</Title>
										</div>
									}
									bodyStyle={{ padding: 0 }}
								>
									<Table columns={columns} dataSource={data} pagination={false} />
								</Card>
							</Col>
							<Col xs={24}>
								<Card
									cover={
										<div
											style={{
												paddingTop: 16,
												paddingBottom: 16,
												paddingLeft: 24,
												paddingRight: 24,
												// background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
												borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
											}}
										>
											<Title level={4} style={{ margin: 0, padding: 0 }}>
												Week 3
											</Title>
										</div>
									}
									bodyStyle={{ padding: 0 }}
								>
									<Table columns={columns} dataSource={data} pagination={false} />
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</SectionContent>
		</SectionWrapper>
	);
}
