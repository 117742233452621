import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Typography, Upload, Divider, Icon, Avatar } from 'antd';
import { Form, Table, AddRowButton, RemoveRowButton, FormItem, Input, Select, InputNumber, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CircularLoader from '../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { UPDATE_TOURNAMENT_BRACKET_GROUP_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_BRACKET_QUERY } from './data/queries';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import _ from 'lodash';

const { Option } = Select;
const { Text, Title } = Typography;

function EditGroupModal(props) {
    const { client, tournament, group, modalVisible, onModalOk, onModalCancel, onSave } = props;
    const [loading, setLoading] = useState(false);
    const [updateBracketGroup] = useMutation(UPDATE_TOURNAMENT_BRACKET_GROUP_MUTATION);
    const formRef = useRef();

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function submitUpdate(data, actions) {
        const { group_name } = data;

        setLoading(true);
        return updateBracketGroup({
            variables: {
                group: group.group,
                tournament_id: tournament.id,
                changes: {
                    group_name: group_name,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_QUERY,
                    variables: { tournamentId: tournament.id },
                },
            ],
        })
            .then((data) => {
                actions.setSubmitting(false);
                setLoading(false);
                message.success('Changes saved');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    }

    const schema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    const initialValues = group
        ? {
              group_name: group.group_name,
          }
        : {
              group_name: 'Group name',
          };

    return (
        <Formik
            ref={formRef}
            onSubmit={(data, actions) => {
                submitUpdate(data, actions);
            }}
            enableReinitialize={false}
            initialValues={initialValues}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Modal
                    title="Edit Group Name"
                    // getContainer={() => document.querySelector('.tables')}
                    visible={modalVisible}
                    onOk={onModalOk}
                    onCancel={onModalCancel}
                    centered
                    transitionName="fade"
                    // transitionName="none"
                    maskTransitionName="none"
                    footer={[
                        <Button key="cancel" onClick={onModalCancel}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            disabled={Object.keys(errors).length > 0 ? true : false}
                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                            onClick={() => {
                                formRef.current.handleSubmit();
                            }}
                        >
                            {loading ? 'Saving...' : 'Submit'}
                        </Button>,
                    ]}
                    bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                    destroyOnClose={true}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Form onSubmit={handleSubmit}>
                            <FormItem name="group_name" hasFeedback showValidateSuccess required>
                                <Input
                                    name="group_name"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                    // autoFocus
                                    // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Group name"
                                />
                            </FormItem>
                        </Form>
                    </div>
                </Modal>
            )}
        />
    );
}

export default EditGroupModal;
