import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircleFlag from '../../../components/CircleFlag';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';

const { Text, Title } = Typography;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function StreamOverlayLarge(props) {
    const { tournamentData, matchData, tableData, subscribeToMore, subscribeToNewData } = props;
    const challenger1 = matchData && matchData.challenger1;
    const challenger2 = matchData && matchData.challenger2;
    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData && matchData.identifier.includes('L')) {
        challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.losers_race_to || 0;
        challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.losers_race_to || 0;
    } else {
        challenger1RaceTo = (matchData && matchData.challenger1_race_to) || (matchData && matchData.tournament.winners_race_to) || 0;
        challenger2RaceTo = (matchData && matchData.challenger2_race_to) || (matchData && matchData.tournament.winners_race_to) || 0;
    }
    const currentGame = matchData && matchData.tournament_match_games && matchData.tournament_match_games[matchData.tournament_match_games.length - 1];

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <React.Fragment>
            <div style={{ display: 'flex', height: '100%' }}>
                <div
                    style={{
                        paddingTop: 20,
                        paddingLeft: 20,
                        paddingRight: 10,
                        paddingBottom: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        height: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%' }}>
                        <Col xs={12} sm={12}>
                            {tournamentData && tournamentData.logo && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={tournamentData.logo} alt="Logo" height={120} />
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} align="right">
                            <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={80} />
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" justify="center" style={{ marginBottom: 100 }}>
                        <Col xs={22} md={20} lg={14} xl={12} xxl={10}>
                            <Row gutter={4} type="flex" justify="center">
                                <Col xs={24}>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            opacity: 0.9,
                                            background:
                                                matchData && matchData.challenger1_score > matchData && matchData.challenger2_score
                                                    ? 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)'
                                                    : 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',

                                            borderRadius: 10,
                                            paddingTop: 15,
                                            paddingBottom: 15,
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {matchData && matchData.challenger1_country && (
                                                <CircleFlag
                                                    country={getCountryName(matchData.challenger1_country) || 'Unknown'}
                                                    size="large"
                                                    style={{ transform: 'scale(1.1)' }}
                                                />
                                            )}
                                            <Title
                                                level={2}
                                                style={{
                                                    marginLeft: 10,
                                                    marginBottom: 0,
                                                    color: '#fff',
                                                    textTransform: 'uppercase',
                                                    // letterSpacing: 0,
                                                    // textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                }}
                                            >
                                                {((matchData && matchData.challenger1_name && matchData.challenger1_skill_level) ||
                                                    (challenger1 && challenger1.skill_level)) &&
                                                matchData.challenger1_race_to &&
                                                tournamentData.show_player_skill_levels === true &&
                                                tournamentData.show_player_races === true
                                                    ? matchData &&
                                                      `${matchData.challenger1_name} ${
                                                          matchData.challenger1_skill_level || (challenger1 && challenger1.skill_level)
                                                      } (${matchData.challenger1_race_to})`
                                                    : matchData &&
                                                      matchData.challenger1_name &&
                                                      matchData.challenger1_race_to &&
                                                      tournamentData.show_player_races === true
                                                    ? matchData && `${matchData.challenger1_name} (${matchData.challenger1_race_to})`
                                                    : matchData && `${matchData.challenger1_name}`}
                                            </Title>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {currentGame && currentGame.player_tpa && (
                                                <div
                                                    style={{
                                                        backgroundColor: 'rgba(255,255,255,0.05)',
                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        borderRadius: 10,
                                                        fontSize: 20,
                                                        marginRight: 20,
                                                    }}
                                                >
                                                    <Text style={{ color: '#fff', fontWeight: 500, fontSize: 25 }}>TPA: {currentGame.player_tpa}</Text>
                                                </div>
                                            )}

                                            {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                                                <Title
                                                    level={1}
                                                    style={{
                                                        margin: 0,
                                                        color: 'rgba(255,255,255,0.75)',
                                                        minWidth: 30,
                                                        fontSize: 40,
                                                        marginRight: 10,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {(matchData && matchData.challenger1_points) || 0} B
                                                </Title>
                                            )}

                                            <Title
                                                level={1}
                                                style={{
                                                    margin: 0,
                                                    color: '#fff',
                                                    minWidth: 30,
                                                    fontSize: 40,
                                                    textAlign: 'center',
                                                    paddingLeft: 15,
                                                    borderRadius: 10,
                                                }}
                                            >
                                                {(matchData && matchData.challenger1_score) || 0}
                                            </Title>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} style={{ padding: 0 }}>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            opacity: 0.8,
                                            background:
                                                matchData && matchData.challenger2_score > matchData && matchData.challenger1_score
                                                    ? 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)'
                                                    : 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',

                                            borderRadius: 10,
                                            paddingTop: 15,
                                            paddingBottom: 15,
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {matchData && matchData.challenger2_country && (
                                                <CircleFlag
                                                    country={getCountryName(matchData && matchData.challenger2_country) || 'Unknown'}
                                                    size="large"
                                                    style={{ transform: 'scale(1.1)' }}
                                                />
                                            )}
                                            <Title
                                                level={2}
                                                style={{
                                                    marginLeft: 10,
                                                    marginBottom: 0,
                                                    color: '#fff',
                                                    textTransform: 'uppercase',
                                                    // letterSpacing: 0,
                                                    // textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                }}
                                            >
                                                {((matchData && matchData.challenger2_name && matchData.challenger2_skill_level) ||
                                                    (challenger1 && challenger2.skill_level)) &&
                                                matchData.challenger2_race_to &&
                                                tournamentData.show_player_skill_levels === true &&
                                                tournamentData.show_player_races === true
                                                    ? matchData &&
                                                      `${matchData.challenger2_name} ${
                                                          matchData.challenger2_skill_level || (challenger1 && challenger2.skill_level)
                                                      } (${matchData.challenger2_race_to})`
                                                    : matchData &&
                                                      matchData.challenger2_name &&
                                                      matchData.challenger2_race_to &&
                                                      tournamentData.show_player_races === true
                                                    ? matchData && `${matchData.challenger2_name} (${matchData.challenger2_race_to})`
                                                    : matchData && `${matchData.challenger2_name}`}
                                            </Title>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {currentGame && currentGame.opponent_tpa && (
                                                <div
                                                    style={{
                                                        backgroundColor: 'rgba(255,255,255,0.05)',
                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        borderRadius: 10,
                                                        fontSize: 20,
                                                        marginRight: 20,
                                                    }}
                                                >
                                                    <Text style={{ color: '#fff', fontWeight: 500, fontSize: 25 }}>TPA: {currentGame.opponent_tpa}</Text>
                                                </div>
                                            )}

                                            {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                                                <Title
                                                    level={1}
                                                    style={{
                                                        margin: 0,
                                                        color: 'rgba(255,255,255,0.75)',
                                                        minWidth: 30,
                                                        fontSize: 40,
                                                        marginRight: 10,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {(matchData && matchData.challenger2_points) || 0} B
                                                </Title>
                                            )}

                                            <Title
                                                level={1}
                                                style={{
                                                    margin: 0,
                                                    color: '#fff',
                                                    minWidth: 30,
                                                    fontSize: 40,
                                                    textAlign: 'center',
                                                    paddingLeft: 15,
                                                    borderRadius: 10,
                                                }}
                                            >
                                                {(matchData && matchData.challenger2_score) || 0}
                                            </Title>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={4} type="flex" justify="center">
                                <Col xs={12} sm={12}>
                                    <Title
                                        level={3}
                                        style={{
                                            marginLeft: 20,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                        }}
                                    >
                                        {matchData && matchData.table_name}
                                    </Title>
                                </Col>
                                <div style={{ position: 'absolute' }}>
                                    <Title
                                        level={4}
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            opacity: 0.8,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                        }}
                                    >
                                        Match {matchData && matchData.match_number} ({matchData && matchData.identifier})
                                    </Title>
                                </div>
                                <Col xs={12} sm={12} align="right">
                                    <Title
                                        level={3}
                                        style={{
                                            marginRight: 20,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                        }}
                                    >
                                        Race to{' '}
                                        {matchData && matchData.challenger1_race_to && matchData.challenger2_race_to
                                            ? `${challenger1RaceTo}/${challenger2RaceTo}`
                                            : matchData && matchData.tournament && matchData.tournament.winners_race_to}
                                    </Title>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(StreamOverlayLarge);
