import React from 'react';
import { Table, Typography, Progress } from 'antd';
import Card from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import { getCountryName } from '../../../utils/getCountryName';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

export default function StatsTable(props) {
    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            defaultSortOrder: 'ascend',
            key: 'place',
            sorter: (a, b) => {
                return a.place - b.place;
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Match record',
            dataIndex: 'match_record',
            // defaultSortOrder: 'descend',
            key: 'matchRecord',
            sorter: (a, b) => {
                return a.matches.stats.matches_won - b.matches.stats.matches_won;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.matches.stats.matches_won}-{record.matches.stats.matches_lost}
                    </Text>
                );
            },
        },
        {
            title: 'Match win %',
            dataIndex: 'matches_won_pct',
            key: 'matchWinPercentage',
            sorter: (a, b) => {
                return a.matches.stats.matches_won_pct - b.matches.stats.matches_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.matches.stats.matches_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.matches.stats.matches_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Game record',
            dataIndex: 'game_record',
            key: 'gamesRecord',
            sorter: (a, b) => {
                return a.matches.stats.games_won - b.matches.stats.games_won;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.matches.stats.games_won}-{record.matches.stats.games_lost}
                    </Text>
                );
            },
        },
        {
            title: 'Game win %',
            dataIndex: 'games_won_pct',
            key: 'gameWinPercentage',
            sorter: (a, b) => {
                return a.matches.stats.games_won_pct - b.matches.stats.games_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.matches.stats.games_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.matches.stats.games_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },

        // {
        // 	title: 'Rounds',
        // 	dataIndex: 'rounds'
        // 	// sorter: (a, b) => {
        // 	// 	return a.rounds.localeCompare(b.rounds);
        // 	// }
        // },

        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',

            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => {
                return <Text>{(record.points && record.points) || '-'}</Text>;
            },
        },
        // {
        // 	title: 'Weeks',
        // 	dataIndex: 'weeks',
        // 	defaultSortOrder: 'descend'
        // 	// sorter: (a, b) => {
        // 	// 	return a.weeks.localeCompare(b.weeks);
        // 	// }
        // }
    ];

    return (
        <React.Fragment>
            <Card
                // title="Results"
                bodyStyle={{ padding: 0 }}
                // style={{
                // 	borderRadius: 8,
                // 	overflow: 'hidden',
                // 	border: 'none',
                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                // }}
            >
                <div className="table_nowrap">
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={props.formattedResults}
                        size="default"
                        tableLayout="auto"
                        scroll={{ x: 400 }}
                        // pagination={{ pageSize: 25 }}
                    />
                </div>
            </Card>
        </React.Fragment>
    );
}
