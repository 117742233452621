import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Table, Tabs, Row, Col, Typography } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import CircularLoader from '../../../components/CircularLoader';
import { GET_TOUR_STANDINGS_BY_SLUG } from './data/queries';
import { getCountryName } from '../../../utils/getCountryName';
import { Query } from '@apollo/client/react/components';
import qs from 'query-string';
import verifiedIcon from '../../../assets/img/verified-icon.svg';
import CircleFlag from '../../../components/CircleFlag';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import _ from 'lodash';
import moment from 'moment';
import slugify from 'slugify';
// import * as dfwTourStandings from './data/dfw-tour-2020-standings.json';
// import * as dfw2020Tournaments from './data/dfw-tour-tournaments-2020.json';
// import * as allEventMatches from './all_matches.json';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const styles = {};

function DPTourDetail(props) {
    const { client, authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    // function average(nums) {
    // 	return nums.reduce((a, b) => a + b) / nums.length;
    // }

    // const matches = MMmatches.default
    // 	.map((match) => {
    // 		var start = moment(match.start_time);
    // 		var end = moment(match.end_time);
    // 		const durationString = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
    // 		const duration = moment.duration(end.diff(start, 'minutes')).valueOf();

    // 		return {
    // 			...match,
    // 			start_time: match.start_time,
    // 			start_time_ms: moment(match.start_time).valueOf(),
    // 			end_time: match.end_time,
    // 			end_time_ms: moment(match.end_time).valueOf(),
    // 			duration: duration,
    // 			duration_string: durationString
    // 		};
    // 	})
    // 	.filter((match) => {
    // 		return match.is_forfeit !== true || match.is_bye !== true;
    // 	})
    // 	.map((match) => {
    // 		console.log(match);
    // 		return match.duration;
    // 	});

    // console.log('matches', matches);
    // const avg = average(matches);
    // console.log('avg match time', avg);

    // const tours = dfwTourStandings.default.data.tours;
    // const tournaments = tours && tours[0] && tours[0].tour_tournaments;
    // let allMatches = [];

    // allEventMatches.data.tournaments.map((tournament) => {
    // 	tournament.tournament_brackets.map((match) => {
    // 		allMatches.push({
    // 			...match,
    // 			tournament_name: tournament.name
    // 		});
    // 	});
    // });
    // console.log(allMatches);

    // const tournaments = dfw2020Tournaments.data.tournaments;
    // let allPlayers = [];

    // tournaments.map((tournament) => {
    //     tournament.tournament_players.map((player) => {
    //         allPlayers.push({
    //             ...player,
    //             tournament_name: tournament.name,
    //             date: moment(tournament.start_date_time).format('llll'),
    //         });
    //     });
    // });
    // console.log(allPlayers);

    function buildColumns(tournaments) {
        const columns = [
            {
                title: 'Rank',
                dataIndex: 'rank',
                key: 'rank',
                defaultSortOrder: 'ascend',
                width: 40,
                fixed: 'left',
                sorter: (a, b) => {
                    return a.rank - b.rank;
                },
            },
            {
                title: 'Player Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left',
                sorter: (a, b) => {
                    return a.name && a.name.localeCompare(b.name);
                },
                render: (text, record) => {
                    const countryName = record && record.country;

                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {countryName && <CircleFlag country={countryName} left={0} />}
                                <span style={{ marginLeft: countryName ? 10 : 0 }}>{text}</span>
                            </div>
                            {record.user_id && <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />}
                        </div>
                    );
                },
            },
            {
                title: 'Total points',
                dataIndex: 'points',
                key: 'points',
                sorter: (a, b) => {
                    return a.points - b.points;
                },
            },
            {
                title: 'Skill Level',
                dataIndex: 'skillLevel',
                key: 'skillLevel',
                sorter: (a, b) => {
                    return a.skillLevel - b.skillLevel;
                },
            },

            {
                title: 'Stops',
                dataIndex: 'stops',
                key: 'stops',
                sorter: (a, b) => {
                    if (a.stops && b.stops) {
                        return a.stops.length - b.stops.length;
                    }
                },
                render: (text, record) => {
                    return <Text>{record.stops.length}</Text>;
                },
            },
        ];

        tournaments.forEach((tournament, index) => {
            columns.push({
                title: (
                    <Link to={`/tournaments/${tournament.slug}`} target="blank">
                        {tournament.name}
                    </Link>
                ),
                dataIndex: `points${index + 1}`,
                key: `points${index + 1}`,
                sorter: (a, b) => {
                    if (a.points && b.points) {
                        return a.points - b.points;
                    }
                },
                render: (text, record) => {
                    const points =
                        record.stops &&
                        record.stops.filter((item) => {
                            return item.stop === index + 1;
                        });

                    return <Text>{(points && points[0] && points[0].points) || '-'}</Text>;
                },
            });
        });

        return columns;
    }

    return (
        <Query
            query={GET_TOUR_STANDINGS_BY_SLUG}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: props.match.params.slug }}
            onCompleted={(data) => {
                // var set = [];
                // for (let i = 0; i < data.tournament_payouts.length; i++) {
                // 	set.push(data.tournament_payouts[i]);
                // }
                // let formattedData = orderAndFormatData(set);
                // setTableData(formattedData);
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                const tourData = data.tours && data.tours[0];

                if (!tourData) {
                    return <div style={styles.container}>Tour not found.</div>;
                }

                let tournamentPlayers = [];
                let tournamentPoints = {};
                const tournamentPlayerPointsFlattened = [];

                const tournaments =
                    tourData &&
                    tourData.tour_tournaments &&
                    tourData.tour_tournaments.map((item, index) => {
                        return {
                            ...item.tournament,
                            num: index + 1,
                        };
                    });

                tournaments.forEach((tournament, index) => {
                    let tournamentPoints2 = {};
                    tournament.tournament_points &&
                        tournament.tournament_points.forEach((item) => {
                            if (item.points) {
                                tournamentPoints2 = { ...tournamentPoints2, [item.place]: item.points };
                            }
                        });
                    tournament.tournament_players &&
                        tournament.tournament_players.forEach((player) => {
                            const playerName = player.name.replace(/\s+/g, ' ').trim();
                            const slug = slugify(playerName, {
                                replacement: '-',
                                remove: /[*+~.,()'"#!:/@]/g,
                                lower: true,
                            });

                            tournamentPlayers.push({
                                ...player,
                                points: tournamentPoints2[player.place] || 0,
                            });
                            tournamentPlayerPointsFlattened.push({
                                stop: index + 1,
                                tournament_name: tournament.name,
                                id: player.id,
                                name: playerName,
                                slug: slug,
                                user_id: player.user_id,
                                skillLevel: player.skill_level,
                                // name: player.name.replace(/\s+/g, '').toLowerCase(),
                                country: player.country,
                                place: player.place,
                                points: tournamentPoints2[player.place] || 0,
                            });
                        });
                });

                //console.log('players', tournamentPlayers);
                //console.log('points', tournamentPoints);

                const players = _.uniqBy(tournamentPlayers, 'phone_number');
                const formattedPlayers = players
                    .map((player) => {
                        return {
                            name: player.name,
                            phone_number: player.phone_number,
                        };
                    })
                    .filter((player) => {
                        return player.phone_number !== null;
                    });

                console.log(formattedPlayers);
                const columns = buildColumns(tournaments);

                const result = _(tournamentPlayerPointsFlattened)
                    .groupBy('name')
                    .map((tournaments, id) => {
                        return {
                            stops: tournaments,
                            id: tournaments[0].id,
                            name: id,
                            slug: tournaments[0].slug,
                            user_id: tournaments[0].user_id,
                            skillLevel: tournaments[0].skillLevel,
                            country: tournaments[0].country,
                            phone_number: tournaments[0].phone_number,
                            points: _.sumBy(tournaments, 'points'),
                        };
                    })
                    .value();

                const sortedTotals = result
                    .sort((a, b) => {
                        return b.points - a.points;
                    })
                    .map((player, index) => {
                        return {
                            ...player,
                            rank: index + 1,
                        };
                    });

                console.log(sortedTotals);

                return (
                    <React.Fragment>
                        <SectionTitle
                            title={tourData.name && tourData.name.toUpperCase()}
                            // title="2020 DFW 9-Ball Tour Standings"
                            height={breakpoint === 'mobile' ? 80 : 80}
                            titleStyle={{ fontSize: 25, margin: 0 }}
                        />
                        <div>
                            <Table
                                rowKey="rank"
                                pagination={false}
                                className="match-table table-striped-rows"
                                columns={columns}
                                dataSource={sortedTotals}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: (event) => {
                                            console.log(record);
                                            if (record.slug) {
                                                props.history.push({
                                                    pathname: `/players/${record.slug}`,
                                                    // search: '?query=abc',
                                                    state: {
                                                        data: record,
                                                        // backPath: `/wpba-rankings/wpba-2023`,
                                                    },
                                                });
                                            }
                                        },
                                        onDoubleClick: (event) => {}, // double click row
                                        onContextMenu: (event) => {}, // right button click row
                                        onMouseEnter: (event) => {}, // mouse enter row
                                        onMouseLeave: (event) => {}, // mouse leave row
                                        // className: getClassName(record),
                                    };
                                }}
                                size="default"
                                tableLayout="auto"
                                scroll={{ x: 400 }}
                                // pagination={{ pageSize: 25 }}
                            />
                        </div>
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(DPTourDetail);
