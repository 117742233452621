import React, { useState, useEffect } from 'react';
import { Button, Typography } from 'antd';
import TBCreateReviewTournament from './TBCreateReviewTournament';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

const { Text, Title } = Typography;

export default function TBTournamentStep6Review(props) {
    const { authState, match, tournament } = props;
    let url;
    if (match.params.slug) {
        url = `/tournament-builder/${match.params.slug}/edit`;
    } else {
        url = `/tournament-builder/new/edit`;
    }

    return (
        <div
            style={{
                padding: 20,
            }}
        >
            <TBCreateReviewTournament tournament={tournament} {...props} />
            {(tournament && tournament.tournament_type === 'chip') || (tournament && tournament.tournament_type === 'round_robin') ? (
                <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                        props.history.push(`/tournament-builder/${tournament.slug}/viewer`);
                        const user = authState && authState.user && authState.user;
                        mixpanel.track('Tournament reviewed', {
                            ...user,
                            displayName: user.displayName,
                            tournamentId: tournament.id,
                            tournamentName: tournament.name,
                            source: 'web',
                        });
                    }}
                    style={{ marginTop: 20 }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ color: '#fff' }}>Go to Viewer</Text>
                        {/* <Icon type="arrow-right" style={{ marginLeft: 3 }} /> */}
                    </div>
                </Button>
            ) : (
                <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                        props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
                        const user = authState && authState.user && authState.user;
                        mixpanel.track('Tournament reviewed', {
                            ...user,
                            displayName: user.displayName,
                            tournamentId: tournament.id,
                            tournamentName: tournament.name,
                            source: 'web',
                        });
                    }}
                    style={{ marginTop: 20 }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ color: '#fff' }}>Go to bracket</Text>
                        {/* <Icon type="arrow-right" style={{ marginLeft: 3 }} /> */}
                    </div>
                </Button>
            )}

            <Button
                type="secondary"
                size="large"
                onClick={() => {
                    props.history.push(`${url}/flyer`);
                    // setCurrentStep(4);
                }}
                style={{ marginTop: 20 }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Text>Go back</Text>
                </div>
            </Button>
        </div>
    );
}
