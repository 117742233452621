import React from 'react';
import { Typography, Row, Col, Progress, Table } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import { getCountryName } from '../../../utils/getCountryName';
import CircleFlag from '../../../components/CircleFlag';
import { GET_LEAGUE_MATCHES_QUERY } from '../data/queries';
import blueBg from '../../../assets/img/blue-bg.png';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

const defaultSize = 'small';

export default function DPDivisionMatches(props) {
    const { league, division, theme } = props;

    const columns = [
        {
            title: 'Match',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },

        {
            title: 'When',
            dataIndex: 'scheduled_time',
            key: 'scheduled_time',
            width: 200,
            render: (text, record) => {
                return <Text style={{ whiteSpace: 'nowrap' }}>{text ? moment(text).format('lll') : moment(record.scheduled_time).format('ll')}</Text>;
            },
        },

        {
            title: 'Home team/player',
            dataIndex: 'player1',
            key: 'player1',
            align: 'right',
            width: 300,
            sorter: (a, b) => {
                if (a.is_team_match === true) {
                    return a.home_team_name.localeCompare(b.home_team_name);
                } else {
                    return a.challenger1_name.localeCompare(b.challenger1_name);
                }
            },
            render: (text, record) => {
                if (record.is_team_match === true) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Text
                                style={{
                                    marginRight: 10,
                                    fontWeight: record.home_team_score > record.visitor_team_score ? 'bold' : 'normal',
                                    color: record.home_team_score > record.visitor_team_score && '#ffac00',
                                }}
                            >
                                {record.home_team.name}{' '}
                            </Text>

                            <span
                                style={{
                                    marginLeft: 10,
                                    padding: '5px 10px',
                                    backgroundColor: record.home_team_is_winner === true ? '#1890fe' : theme && theme.name === 'dark' ? '#135da2' : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.home_team_score || 0}
                            </span>
                        </div>
                    );
                } else {
                    const country = record.challenger1_country;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Text
                                style={{
                                    marginRight: 10,
                                    fontWeight: record.challenger1_score > record.challenger2_score ? 'bold' : 'normal',
                                    color: record.challenger1_score > record.challenger2_score && '#ffac00',
                                }}
                            >
                                {record.challenger1_name}{' '}
                                {record.challenger1_skill_level
                                    ? `(${record.challenger1_skill_level})`
                                    : record.challenger1_fargo
                                    ? `(${record.challenger1_fargo})`
                                    : ''}
                            </Text>
                            {country && <CircleFlag country={country} left={0} />}
                            <span
                                style={{
                                    marginLeft: 10,
                                    padding: '5px 10px',
                                    backgroundColor:
                                        record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'player')
                                            ? '#1890fe'
                                            : theme && theme.name === 'dark'
                                            ? '#135da2'
                                            : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.challenger1_score || 0}
                            </span>
                        </div>
                    );
                }
            },
        },
        {
            title: 'Visitor team/player',
            dataIndex: 'player2',
            key: 'player2',
            width: 300,
            sorter: (a, b) => {
                return a.challenger2_name.localeCompare(b.challenger2_name);
            },
            render: (text, record) => {
                if (record.is_team_match === true) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <span
                                style={{
                                    marginRight: 20,
                                    padding: '5px 10px',
                                    backgroundColor:
                                        record.visitor_team_is_winner === true ? '#1890fe' : theme && theme.name === 'dark' ? '#135da2' : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.visitor_team_score || 0}
                            </span>

                            <span
                                style={{
                                    fontWeight: record.visitor_team_score > record.home_team_score ? 'bold' : 'normal',
                                    color: record.visitor_team_score > record.home_team_score && '#ffac00',
                                }}
                            >
                                {record.visitor_team.name}{' '}
                            </span>
                        </div>
                    );
                } else {
                    const country = record.challenger2_country;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <span
                                style={{
                                    marginRight: 20,
                                    padding: '5px 10px',
                                    backgroundColor:
                                        record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'opponent')
                                            ? '#1890fe'
                                            : theme && theme.name === 'dark'
                                            ? '#135da2'
                                            : '#8dc8ff',
                                    borderRadius: 5,
                                }}
                            >
                                {record.challenger2_score || 0}
                            </span>
                            {country && <CircleFlag country={country} left={0} />}
                            <span
                                style={{
                                    fontWeight: record.challenger2_score > record.challenger1_score ? 'bold' : 'normal',
                                    color: record.challenger2_score > record.challenger1_score && '#ffac00',
                                }}
                            >
                                {record.challenger2_name}{' '}
                                {record.challenger2_skill_level
                                    ? `(${record.challenger2_skill_level})`
                                    : record.challenger2_fargo
                                    ? `(${record.challenger2_fargo})`
                                    : ''}
                            </span>
                        </div>
                    );
                }
            },
        },
        {
            title: 'Venue',
            dataIndex: 'venue',
            key: 'venue',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Table',
            dataIndex: 'table_name',
            align: 'left',
            key: 'table',
            width: 150,
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {/* <img src={tableBlue} width={25} alt="Table 1" /> */}
                        {/* <span style={{ marginLeft: 10 }}>{text || '-'}</span> */}
                        <Text>{text || '-'}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Progress
                            percent={record.progress || 0}
                            status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                        {/* <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <span
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    backgroundColor: '#ececec',
                                    height: 10,
                                    position: 'relative'
                                }}
                            >
                                <span
                                    style={{
                                        display: 'inline-block',
                                        // backgroundColor: '#bac2cc',
                                        backgroundColor: '#1890fe',
                                        width: `${(record.progress || 0) / 100 * 100}%`,
                                        height: '100%',
                                        position: 'absolute',
                                        transition: 'background-color .3s ease',
                                        cursor: 'pointer'
                                    }}
                                />
                            </span>
                            <span
                                style={{
                                    width: 80,
                                    marginLeft: 10
                                }}
                            >
                                {record.progress || 0}%
                            </span>
                        </div> */}
                    </React.Fragment>
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                const duration = moment.duration(moment.utc().diff(record.start_time, 'milliseconds')).format('h [hrs], m [min]');
                return <Text>{duration}</Text>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    function getChallengerRaceTo(currentMatch, challenger) {
        const challenger_race_to =
            challenger === 'player' ? currentMatch.challenger1_race_to : challenger === 'opponent' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            return null;
        }
    }

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Matches"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row
                    gutter={24}
                    justify="center"
                    type="flex"
                    // style={{ padding: 24, position: 'relative', marginTop: -120 }}
                >
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22}>
                        <Query
                            query={GET_LEAGUE_MATCHES_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ league_id: league.id }}
                            onCompleted={(data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const matches =
                                    data.league_matches &&
                                    data.league_matches.map((item, index) => {
                                        return {
                                            ...item,
                                            key: index,
                                            venue: item.venue && item.venue.name,
                                        };
                                    });
                                return (
                                    <Card
                                        // title="In Progress"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                    Matches ({matches.length})
                                                </Title>
                                            </div>
                                        }
                                        style={styles.card}
                                    >
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                // components={{
                                                // 	body: {
                                                // 		row: RenderRow
                                                // 	}
                                                // }}

                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {
                                                            props.history.push(`/leagues/${league.slug}/divisions/${division.slug}/matches/${record.slug}`);
                                                        }, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
