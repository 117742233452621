import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Progress, Table, Button, Input, Tabs, Empty, Radio } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import CircleFlag from '../../../components/CircleFlag';
import { isoCountries } from '../../../utils/isoCountryCodes';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import { getCountryName } from '../../../utils';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYER_STATS_QUERY } from './data/queries';
import { GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2, GET_TOURNAMENT_STATS_SUBSCRIPTION2 } from './data/subscriptions';
import _ from 'lodash';
import PlayerModal from '../../digitalpool/tournaments/bracket/PlayerModal';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

export default function TBStats(props) {
    const { tournament } = props;
    const [rendered, setRendered] = useState();
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();

    const styles = {};
    function callback(key) {
        // console.log(key);
    }

    const tournament_statistics_columns = [
        {
            title: 'Skill level',
            dataIndex: 'skill_level_range',
            key: 'skill_level_range',
            sorter: (a, b) => {
                return a.skill_level_range - b.skill_level_range;
            },
        },
        {
            title: 'Num Players',
            dataIndex: 'number_of_players',
            key: 'number_of_players',
            sorter: (a, b) => {
                return a.number_of_players - b.number_of_players;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>
                                {record.number_of_players} ({record.percent_of_field}%)
                            </Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Match win %',
            dataIndex: 'win_percentage',
            key: 'win_percentage',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>{record.win_percentage}</Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Vs lower skill level',
            dataIndex: 'win_percentage_vs_lower_skill_level',
            key: 'win_percentage_vs_lower_skill_level',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>{record.win_percentage_vs_lower_skill_level}</Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Vs higher skill level',
            dataIndex: 'win_percent_vs_higher_skill_level',
            key: 'win_percent_vs_higher_skill_level',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>{record.win_percentage_vs_higher_skill_level}</Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Players in final 32',
            dataIndex: 'number_made_final_32',
            key: 'number_made_final_32',
            sorter: (a, b) => {
                return a.number_made_final_32 - b.number_made_final_32;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>
                                {record.number_made_final_32} ({record.percentage_of_final_32}%)
                            </Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Players in final 16',
            dataIndex: 'number_made_final_16',
            key: 'number_made_final_16',
            sorter: (a, b) => {
                return a.number_made_final_16 - b.number_made_final_16;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>
                                {record.number_made_final_16} ({record.percentage_of_final_16}%)
                            </Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Players in final 8',
            dataIndex: 'number_made_final_8',
            key: 'number_made_final_8',
            sorter: (a, b) => {
                return a.number_made_final_8 - b.number_made_final_8;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>
                                {record.number_made_final_8} ({record.percentage_of_final_8}%)
                            </Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Average match length',
            dataIndex: 'average_match_duration',
            key: 'average_match_duration',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 80 }}>
                            <Text>{record.average_match_duration} </Text>
                        </div>
                    </div>
                );
            },
        },
    ];

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
            sorter: (a, b) => {
                return (
                    a.place &&
                    a.place.localeCompare(b.place, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country && <CircleFlag country={record.country} left={0} />}

                        <Text
                            style={{
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {text ? text : '-'}
                        </Text>
                    </div>
                );
            },
        },
        {
            title: 'Match record',
            dataIndex: 'match_record',
            key: 'matchRecord',
            sorter: (a, b) => {
                const matchesWonA = a.matches_won.split('-')[0];
                const matchesWonB = b.matches_won.split('-')[0];
                return matchesWonA - matchesWonB;
            },
            render: (text, record) => {
                return <Text>{record.matches_won}</Text>;
            },
        },
        {
            title: 'Match win %',
            dataIndex: 'matches_won_pct',
            key: 'matchWinPercentage',
            sorter: (a, b) => {
                return a.matches_won_pct - b.matches_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.matches_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.matches_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Game record',
            dataIndex: 'game_record',
            key: 'gamesRecord',
            sorter: (a, b) => {
                const gamesWonA = a.games_won.split('-')[0];
                const gamesWonB = b.games_won.split('-')[0];
                return gamesWonA - gamesWonB;
            },
            render: (text, record) => {
                return <Text>{record.games_won}</Text>;
            },
        },
        {
            title: 'Game win %',
            dataIndex: 'games_won_pct',
            key: 'gameWinPercentage',
            sorter: (a, b) => {
                return a.games_won_pct - b.games_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.games_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.games_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => {
                return <Text>{(record.points && record.points) || '-'}</Text>;
            },
        },
    ];

    function handleShowPlayerModal(playerId) {
        setSelectedPlayerId(playerId);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row>
                    <Subscription
                        subscription={GET_TOURNAMENT_STATS_SUBSCRIPTION2}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data) => {
                            console.log(data);
                            if (rendered === true) {
                                message.info('Stats updated just now');
                            }
                            setRendered(true);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const tournament_stats = data && data.fn_get_tournament_statistics;

                            return (
                                <Col xs={24}>
                                    <Card
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                    Tournament stats
                                                </Title>
                                            </div>
                                        }
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Table
                                            rowKey="skill_level_range"
                                            columns={tournament_statistics_columns}
                                            dataSource={tournament_stats}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                        />
                                    </Card>
                                </Col>
                            );
                        }}
                    </Subscription>
                </Row>
                <Row gutter={24} justify="center" type="flex">
                    <Subscription
                        subscription={GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data) => {
                            if (rendered === true) {
                                message.info('Stats updated just now');
                            }
                            setRendered(true);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const stats = data && data.vw_tournament_statistics;

                            return (
                                <Col xs={24}>
                                    <Card
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                    Player stats
                                                </Title>
                                            </div>
                                        }
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Table
                                            columns={columns}
                                            dataSource={_.uniqBy(
                                                stats.map((player, index) => {
                                                    return {
                                                        id: player.id,
                                                        name: player.player_name,
                                                        player_id: player.player_id,
                                                        place: player.place,
                                                        country: player.player_country,
                                                        points: player.points,
                                                        matches_won: player.match_record,
                                                        matches_won_pct: player.match_win_percentage,
                                                        games_won: player.game_record,
                                                        games_won_pct: player.game_win_percentage,
                                                        key: index,
                                                    };
                                                }),
                                                'name'
                                            )}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        console.log(record);
                                                        handleShowPlayerModal(record.player_id);
                                                    },
                                                    onDoubleClick: (event) => {}, // double click row
                                                    onContextMenu: (event) => {}, // right button click row
                                                    onMouseEnter: (event) => {}, // mouse enter row
                                                    onMouseLeave: (event) => {}, // mouse leave row
                                                };
                                            }}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                        />
                                    </Card>
                                </Col>
                            );
                        }}
                    </Subscription>
                </Row>
                <PlayerModal
                    tournamentID={tournament.id}
                    playerId={selectedPlayerId}
                    modalVisible={playerModalVisible}
                    onPlayerModalVisible={handlePlayerModalVisible}
                    {...props}
                />
            </SectionContent>
        </SectionWrapper>
    );
}
