import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Table, Tabs, Row, Col, Typography, Tooltip } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import CircularLoader from '../../../components/CircularLoader';
import { GET_TOUR_STANDINGS_BY_SLUG, GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS } from './data/queries';
import { getCountryName } from '../../../utils/getCountryName';
import { Query } from '@apollo/client/react/components';
import qs from 'query-string';
import CircleFlag from '../../../components/CircleFlag';
import verifiedIcon from '../../../assets/img/verified-icon.svg';
import { QuestionCircleFilled } from '@ant-design/icons';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import slugify from 'slugify';
// import * as dfwTourStandings from './data/dfw-tour-2020-standings.json';
// import * as dfw2020Tournaments from './data/dfw-tour-tournaments-2020.json';
// import * as allEventMatches from './all_matches.json';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const styles = {
    container: {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // height: '100%'
    },
};

function WPBARankings(props) {
    const { client, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [totals, setTotals] = useState([]);
    const [loadingRatings, setLoadingRatings] = useState(false);
    const [tournamentsList, setTournamentsList] = useState([]);

    const totalPointsWeight = (params && params.total_points_weight) || 0.35;
    const totalPointsAvgWeight = (params && params.total_points_average_weight) || 0.35;
    // const currentYearPointsWeight = (params && params.current_points_weight) || 0.4;
    // const fargoRatingWeight = (params && params.fargo_rating_weight) || 0.2;
    const performanceRatingWeight = (params && params.performance_rating_weight) || 0.3;

    const rankingWeights = {
        totalPoints: parseFloat(totalPointsWeight),
        totalPointsAvg: parseFloat(totalPointsAvgWeight),
        performanceRating: parseFloat(performanceRatingWeight),
    };

    // function average(nums) {
    // 	return nums.reduce((a, b) => a + b) / nums.length;
    // }

    // const matches = MMmatches.default
    // 	.map((match) => {
    // 		var start = moment(match.start_time);
    // 		var end = moment(match.end_time);
    // 		const durationString = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
    // 		const duration = moment.duration(end.diff(start, 'minutes')).valueOf();

    // 		return {
    // 			...match,
    // 			start_time: match.start_time,
    // 			start_time_ms: moment(match.start_time).valueOf(),
    // 			end_time: match.end_time,
    // 			end_time_ms: moment(match.end_time).valueOf(),
    // 			duration: duration,
    // 			duration_string: durationString
    // 		};
    // 	})
    // 	.filter((match) => {
    // 		return match.is_forfeit !== true || match.is_bye !== true;
    // 	})
    // 	.map((match) => {
    // 		console.log(match);
    // 		return match.duration;
    // 	});

    // console.log('matches', matches);
    // const avg = average(matches);
    // console.log('avg match time', avg);

    // const tours = dfwTourStandings.default.data.tours;
    // const tournaments = tours && tours[0] && tours[0].tour_tournaments;
    // let allMatches = [];

    // allEventMatches.data.tournaments.map((tournament) => {
    // 	tournament.tournament_brackets.map((match) => {
    // 		allMatches.push({
    // 			...match,
    // 			tournament_name: tournament.name
    // 		});
    // 	});
    // });
    // console.log(allMatches);

    // const tournaments = dfw2020Tournaments.data.tournaments;
    // let allPlayers = [];

    // tournaments.map((tournament) => {
    //     tournament.tournament_players.map((player) => {
    //         allPlayers.push({
    //             ...player,
    //             tournament_name: tournament.name,
    //             date: moment(tournament.start_date_time).format('llll'),
    //         });
    //     });
    // });
    // console.log(allPlayers);

    function buildColumns(tournaments) {
        const columns = [
            {
                title: 'Rank',
                dataIndex: 'rank',
                key: 'rank',
                defaultSortOrder: 'ascend',
                width: 40,
                fixed: 'left',
                sorter: (a, b) => {
                    return a.rank - b.rank;
                },
            },
            {
                title: 'Player Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left',
                sorter: (a, b) => {
                    return a.name && a.name.localeCompare(b.name);
                },
                render: (text, record) => {
                    const countryName = record && record.country;

                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {countryName && <CircleFlag country={countryName} left={0} />}
                                <span style={{ marginLeft: countryName ? 10 : 0 }}>{text}</span>
                                {/* <span>{text}</span> */}
                            </div>
                            {record.user_id && <img src={verifiedIcon} height={20} alt="Verified User" style={{ marginLeft: 5 }} />}
                        </div>
                    );
                },
            },
            // {
            //     title: 'Country',
            //     dataIndex: 'country',
            //     key: 'country',

            //     sorter: (a, b) => {
            //         return a.country && a.country.localeCompare(b.country);
            //     },
            //     render: (text, record) => {
            //         return (
            //             <div
            //                 style={{
            //                     display: 'flex',
            //                     alignItems: 'center',
            //                 }}
            //             >
            //                 <span>{text}</span>
            //             </div>
            //         );
            //     },
            // },
            // {
            //     title: 'Total points',
            //     dataIndex: 'points',
            //     key: 'points',
            //     sorter: (a, b) => {
            //         return a.points - b.points;
            //     },
            // },

            {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>Total Points</Text>
                        <Tooltip placement="right" title="Total number of points won over the last 10 events">
                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'totalPoints',
                key: 'totalPoints',
                sorter: (a, b) => {
                    return a.totalPoints - b.totalPoints;
                },
                render: (text, record) => {
                    return (
                        <div>
                            <Text>{text ? numeral(text).format('0,0') : '-'}</Text>
                        </div>
                    );
                },
            },
            {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>Total Pts Avg</Text>
                        <Tooltip placement="right" title="Average number of points won over the last 10 events">
                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'totalPointsAvg',
                key: 'totalPointsAvg',
                sorter: (a, b) => {
                    return a.totalPointsAvg - b.totalPointsAvg;
                },
                render: (text, record) => {
                    return (
                        <div>
                            <Text>{text ? numeral(text).format('0,0') : '-'}</Text>
                        </div>
                    );
                },
            },
            {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>Performance Rating Avg</Text>
                        <Tooltip placement="right" title="Average performance rating over the last 10 events">
                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'performanceRatingAvg',
                key: 'performanceRatingAvg',
                sorter: (a, b) => {
                    return a.performanceRatingAvg - b.performanceRatingAvg;
                },
            },

            {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>Weighted Avg</Text>
                        <Tooltip
                            placement="right"
                            title="The rankings are determined by a weighted average of total points, total points average, and performance rating average using the weights 35%, 35%, and 30% respectively."
                        >
                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'weightedAvg',
                key: 'weightedAvg',
                sorter: (a, b) => {
                    return a.weightedAvg - b.weightedAvg;
                },
                render: (text, record) => {
                    return (
                        <div>
                            <Text>{text && text.toFixed(2)}</Text>
                        </div>
                    );
                },
            },
            {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>Skill Level</Text>
                        <Tooltip placement="right" title="Most recent Fargo Rating from the last event played">
                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'skillLevel',
                key: 'skillLevel',
                sorter: (a, b) => {
                    return a.skillLevel - b.skillLevel;
                },
                render: (text, record) => {
                    return <Text>{text || '-'}</Text>;
                },
            },

            {
                title: 'Stops Played',
                dataIndex: 'stops',
                key: 'stops',

                sorter: (a, b) => {
                    if (a.stops && b.stops) {
                        return a.stops - b.stops;
                    }
                },
                render: (text, record) => {
                    return <Text>{record.stops}</Text>;
                },
            },
        ];

        tournaments.forEach((tournament, index) => {
            columns.push({
                title: (
                    <Link to={`/tournaments/${tournament.slug}`} target="blank">
                        {tournament.name}
                    </Link>
                ),
                dataIndex: `points${index + 1}`,
                key: `points${index + 1}`,
                sorter: (a, b) => {
                    if (a.points && b.points) {
                        return a.points - b.points;
                    }
                },
                render: (text, record) => {
                    const points =
                        record.stopsPlayed &&
                        record.stopsPlayed.filter((item) => {
                            return item.stop === index + 1;
                        })[0];

                    return <Text>{(points && points.points) || '-'}</Text>;
                },
            });
        });

        return columns;
    }

    async function getTournamentEffectiveRating(tournament) {
        const ratings = await client.query({
            query: GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS,
            variables: {
                tournament_id: tournament.id,
            },
        });

        return {
            ...tournament,
            performance_ratings: ratings && ratings.data && ratings.data.vw_tournament_player_effective_ratings,
        };
    }

    const weightedAverage = (nums, weights) => {
        const [sum, weightSum] = weights.reduce(
            (acc, w, i) => {
                acc[0] = acc[0] + nums[i] * w;
                acc[1] = acc[1] + w;
                return acc;
            },
            [0, 0]
        );

        return sum / weightSum;
    };

    function getClassName(record) {
        const isActiveWPBAMember = record.is_active_wpba_member === false ? 'non_active_wpba_member' : '';
        return `${isActiveWPBAMember}`;
    }

    return (
        <Query
            query={GET_TOUR_STANDINGS_BY_SLUG}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: props.match.params.slug }}
            onCompleted={async (data) => {
                // var set = [];
                // for (let i = 0; i < data.tournament_payouts.length; i++) {
                // 	set.push(data.tournament_payouts[i]);
                // }
                // let formattedData = orderAndFormatData(set);
                // setTableData(formattedData);

                const tourData = data.tours && data.tours[0];

                const tournaments =
                    tourData &&
                    tourData.tour_tournaments &&
                    tourData.tour_tournaments
                        .map((item, index) => {
                            return {
                                ...item.tournament,
                                num: index + 1,
                            };
                        })
                        .slice(0, 16);

                let promises = [];
                let tournamentPlayerPointsFlattened = [];
                let tournamentPlayers = [];

                setLoadingRatings(true);

                tournaments.forEach((tournament, index) => {
                    promises.push(getTournamentEffectiveRating(tournament));
                });

                Promise.all(promises)
                    .then((values) => {
                        const tournamentsWithRatings = values;

                        tournaments.forEach((tournament, index) => {
                            // console.log(tournament);
                            let tournamentPoints = {};
                            tournament.tournament_points &&
                                tournament.tournament_points.forEach((item) => {
                                    if (item.points) {
                                        tournamentPoints = { ...tournamentPoints, [item.place]: item.points };
                                    }
                                });

                            const playerPerformanceRatings = tournamentsWithRatings[index] && tournamentsWithRatings[index].performance_ratings;

                            tournament.tournament_players &&
                                tournament.tournament_players.forEach(async (item) => {
                                    const playerName = item.name.replace(/\s+/g, ' ').trim();
                                    const performanceRating = playerPerformanceRatings.filter((item) => {
                                        return item.name === playerName;
                                    })[0];

                                    const slug = slugify(playerName, {
                                        replacement: '-',
                                        remove: /[*+~.,()'"#!:/@]/g,
                                        lower: true,
                                    });

                                    tournamentPlayers.push({
                                        ...item,
                                        name: playerName,
                                        slug: slug,
                                        name_formatted: playerName.toLowerCase(),
                                        is_active_wpba_member: item.player && item.player.is_active_wpba_member,
                                    });

                                    tournamentPlayerPointsFlattened.push({
                                        stop: index + 1,
                                        tournament_id: tournament.id,
                                        tournament_name: tournament.name,
                                        start_date_time: tournament.start_date_time,
                                        end_date_time: tournament.end_date_time,
                                        id: item.id,
                                        name: playerName,
                                        slug: slug,
                                        user_id: item.user_id,
                                        name_formatted: playerName.toLowerCase(),
                                        skill_level: item.skill_level || 0,
                                        performance_rating: performanceRating && performanceRating.effective_rating,
                                        to_par: performanceRating && performanceRating.to_par,
                                        // name: player.name.replace(/\s+/g, '').toLowerCase(),
                                        country: item.player && item.player.country,
                                        place: item.place,
                                        points: tournamentPoints[item.place] || 0,
                                        player: item,
                                    });
                                });
                        });

                        const players = _.uniq(tournamentPlayers);
                        const playerData = _(tournamentPlayerPointsFlattened)
                            .filter((player) => player.points > 0)
                            .groupBy('name_formatted')
                            .map((tournaments, id) => {
                                // console.log({
                                //     id,
                                //     tournaments,
                                // });
                                const prevYearEvents = tournaments.filter((item) => {
                                    return (
                                        (moment(item.start_date_time).year() <= moment().subtract('years', 1).year() &&
                                            (moment(item.start_date_time).month() > moment().subtract('years', 1).month() ||
                                                (moment(item.start_date_time).month() === moment().subtract('years', 1).month() &&
                                                    moment(item.start_date_time).days() >= moment().subtract('years', 1).days()))) ||
                                        (moment(item.start_date_time).year() <= moment().subtract('years', 1).year() &&
                                            (moment(item.start_date_time).month() < moment().month() ||
                                                (moment(item.start_date_time).month() === moment().month() &&
                                                    moment(item.start_date_time).days() <= moment().days())))
                                    );
                                });

                                const currentYearEvents = tournaments.filter((item) => {
                                    return moment(item.start_date_time).year() === moment().year();
                                });

                                // Previous years - 20%
                                // Looking back at 2022 - 10 events
                                // Average together the ones that you played (if there are 2 or more)
                                // Average with a 0 if less than 2
                                // That number would account for 20% of your ranking

                                // Current year - 40%
                                // Total points from this year
                                // - Rank everyone by total points
                                // - 10k added event may be less paints than a 40k added event

                                // Current system does a rolling 10 events regardless of when they were

                                // What happens with the first event of the year
                                // Atlantic City
                                // - Rankings come down to how we seed the event
                                // - Past years average
                                // - 60%

                                // Fargo ranking - 20%
                                // Average Performance rating - 20%

                                const stopsPlayed = tournaments.filter((tournament) => tournament.points > 0);
                                const currentPlayer = players.filter((player) => player.name_formatted === id)[0];

                                const minStops = 3;
                                let stopsMin = [];
                                if (stopsPlayed && stopsPlayed.length < 3) {
                                    for (let i = stopsPlayed.length; i < 3; i++) {
                                        stopsMin.push({
                                            points: 0,
                                        });
                                    }
                                }

                                // console.log(stopsMin);
                                const stopsCombined = [...stopsMin, ...stopsPlayed];

                                // console.log('stops combined', stopsCombined);
                                const performanceRatingAvg =
                                    _.meanBy(
                                        _.filter(stopsPlayed, (stop) => {
                                            return stop.performance_rating !== null;
                                        }),
                                        'performance_rating'
                                    ) || 0;
                                // const prevYearPointsAvg = prevYearEvents && prevYearEvents.length > 2 ? _.meanBy(prevYearEvents, 'points') : 0;
                                const totalPoints = _.sumBy(stopsCombined, 'points') || 0;
                                const totalPointsAvg = _.meanBy(stopsCombined, 'points') || 0;
                                // const currentYearPointsAvg = _.meanBy(currentYearEvents, 'points') || 0;
                                // const currentYearPoints = _.sumBy(currentYearEvents, 'points') || 0;
                                // const prevYearPerformanceRatingAvg =
                                //     prevYearEvents && prevYearEvents.length > 2 ? _.meanBy(prevYearEvents, 'performance_rating') : 0;
                                // const currentYearPerformanceRatingAvg = _.meanBy(currentYearEvents, 'performance_rating') || 0;
                                const skills = _.maxBy(tournaments, 'skill_level');
                                const skillLevel = skills.skill_level;
                                // console.log({
                                //     prevYearPointsAvg,
                                //     currentYearPointsAvg,
                                //     skillLevel,
                                //     performanceRatingAvg,
                                // });
                                // console.log(rankingWeights);
                                const weightedAvg = weightedAverage(
                                    [totalPoints, totalPointsAvg, performanceRatingAvg],
                                    [rankingWeights.totalPoints, rankingWeights.totalPointsAvg, rankingWeights.performanceRating]
                                );

                                return {
                                    stopsPlayed: stopsPlayed,
                                    stops: stopsPlayed.length,
                                    name: currentPlayer.name,
                                    isActive: currentPlayer.is_active_wpba_member,
                                    slug: currentPlayer.slug,
                                    user_id: currentPlayer.user_id,
                                    skillLevel: skillLevel,
                                    country: currentPlayer.country,
                                    phoneNumber: currentPlayer.phone_number,
                                    points: _.sumBy(stopsCombined, 'points'),
                                    pointsAvg: _.meanBy(stopsCombined, 'points'),
                                    performanceRatingAvg: performanceRatingAvg && parseInt(performanceRatingAvg.toFixed(0)),
                                    prevYears: prevYearEvents,
                                    // prevYearPoints: _.sumBy(prevYearEvents, 'points'),
                                    // prevYearPointsAvg: prevYearPointsAvg && parseInt(prevYearPointsAvg.toFixed(2)),
                                    // prevYearPerformanceRatingAvg: prevYearPerformanceRatingAvg && parseInt(prevYearPerformanceRatingAvg.toFixed(0)),
                                    // currentYear: currentYearEvents,
                                    // currentYearPoints: currentYearPoints,
                                    // currentYearPointsAvg: currentYearPointsAvg && parseInt(currentYearPointsAvg.toFixed(2)),
                                    // currentYearPerformanceRatingAvg: currentYearPerformanceRatingAvg && parseInt(currentYearPerformanceRatingAvg.toFixed(0)),
                                    totalPoints: totalPoints,
                                    totalPointsAvg: totalPointsAvg && parseInt(totalPointsAvg.toFixed(2)),
                                    weightedAvg: weightedAvg || 0,
                                };
                            })
                            .value();

                        // const prevYearEvents = tournamentPlayerPointsFlattened.filter((item) => {
                        //     return (
                        //         (moment(item.start_date_time).year() <= moment().subtract('years', 1).year() &&
                        //             (moment(item.start_date_time).month() > moment().subtract('years', 1).month() ||
                        //                 (moment(item.start_date_time).month() === moment().subtract('years', 1).month() &&
                        //                     moment(item.start_date_time).days() >= moment().subtract('years', 1).days()))) ||
                        //         (moment(item.start_date_time).year() <= moment().subtract('years', 1).year() &&
                        //             (moment(item.start_date_time).month() < moment().month() ||
                        //                 (moment(item.start_date_time).month() === moment().month() && moment(item.start_date_time).days() <= moment().days())))
                        //     );
                        // });

                        // const currentYearEvents = tournamentPlayerPointsFlattened.filter((item) => {
                        //     return moment(item.start_date_time).year() === moment().year();
                        // });

                        console.log('player data', playerData);
                        console.log('player points', tournamentPlayerPointsFlattened);
                        // console.log('prev year events', prevYearEvents);
                        // console.log('current year events', currentYearEvents);
                        // console.log('prev year point avg 2', _.meanBy(prevYearEvents, 'points'));
                        //console.log('points', tournamentPoints);

                        const sortedTotals = playerData
                            .sort((a, b) => {
                                return b.weightedAvg - a.weightedAvg;
                            })
                            .map((player, index) => {
                                return {
                                    ...player,
                                    rank: index + 1,
                                };
                            });

                        console.log(sortedTotals);

                        setTournamentsList(tournaments);
                        setTotals(sortedTotals);
                        setLoadingRatings(false);
                    })
                    .catch((e) => {
                        console.log(e);
                        // setDrawComplete(false);
                        setLoadingRatings(false);
                        // showConfirmDraw(drawRound, winnerBracket);
                    });
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                const tourData = data.tours && data.tours[0];

                if (!tourData) {
                    return <div style={styles.container}>Tour not found.</div>;
                }

                const columns = buildColumns(tournamentsList);

                if (totals && !loading && !loadingRatings) {
                    return (
                        <React.Fragment>
                            <SectionTitle
                                title={tourData.name}
                                // title="2020 DFW 9-Ball Tour Standings"
                                height={breakpoint === 'mobile' ? 80 : 80}
                            />
                            <div>
                                <Table
                                    rowKey="rank"
                                    className="match-table table-striped-rows"
                                    columns={columns}
                                    dataSource={totals}
                                    rowClassName={(record) => record.isActive === false && 'disabled-row'}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                console.log(record);
                                                if (record.slug) {
                                                    props.history.push({
                                                        pathname: `/players/${record.slug}`,
                                                        // search: '?query=abc',
                                                        state: {
                                                            data: record,
                                                            backPath: `/wpba-rankings/wpba-2023`,
                                                        },
                                                    });
                                                }
                                            },
                                            onDoubleClick: (event) => {}, // double click row
                                            onContextMenu: (event) => {}, // right button click row
                                            onMouseEnter: (event) => {}, // mouse enter row
                                            onMouseLeave: (event) => {}, // mouse leave row
                                            // className: getClassName(record),
                                        };
                                    }}
                                    pagination={false}
                                    size="small"
                                    tableLayout="auto"
                                    scroll={{ x: 400 }}
                                    // pagination={{ pageSize: 25 }}
                                />
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <SectionTitle
                                title={tourData.name}
                                // title="2020 DFW 9-Ball Tour Standings"
                                height={breakpoint === 'mobile' ? 80 : 80}
                            />
                            <div>
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            }}
        </Query>
    );
}

export default withRouter(WPBARankings);

// WPBA
// Last season 2021 they want the average points that you got for the season
// - With a minimum of 3
// - We can use the last so many tournaments
// - 2019 and 2021 there were 2 events

// Previous years - 20%
// Looking back at 2022 - 10 events
// Average together the ones that you played (if there are 2 or more)
// Average with a 0 if less than 2
// That number would account for 20% of your ranking

// Current year - 40%
// Total points from this year
// - Rank everyone by total points
// - 10k added event may be less paints than a 40k added event

// Current system does a rolling 10 events regardless of when they were

// What happens with the first event of the year
// Atlantic City
// - Rankings come down to how we seed the event
// - Past years average
// - 60%

// Fargo ranking - 20%
// Average Performance rating - 20%
