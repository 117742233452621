import React from 'react';
import { Row, Col, Button, Typography, Tag, Progress, Icon } from 'antd';
import PoolTable from '../../../components/PoolTable';
import Card, { Meta } from '../../../components/Card';
import { getGameType } from '../../../utils';
import Timer from 'react-compound-timer';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

export default function TableScoreCard(props) {
    const {
        authState,
        tournament,
        table,
        tableLayout,
        label,
        matchData,
        title,
        style,
        status,
        theme,
        tableSize,
        onClick,
        hoverable,
        hideStatus,
        hideTime,
        onEdit,
        onVideoClick,
    } = props;
    const isTableOccupied = status === 'live' || status === 'IN_USE' || status === 'IN_PROGRESS' || status === 'OPEN';

    let duration;
    let scheduledT;
    let startT;
    const defaultTimezone = authState && authState.user && authState.user.default_timezone;
    var zone_name = moment.tz.guess();
    var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
    let gameType;

    if (matchData) {
        gameType = (tournament && tournament.game_type) || (matchData && matchData.game_type);
        scheduledT = moment.utc(matchData.scheduled_time, 'HH:mm a');
        startT = moment.utc(matchData.start_time, 'HH:mm a');

        if (matchData.start_time && matchData.end_time) {
            var start = moment(matchData.start_time);
            var end = moment(matchData.end_time);
            duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
        } else {
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hours] m [minutes]');
            duration = matchData.start_time ? moment(matchData.start_time).fromNow() : '0 mins';
        }
    }

    return (
        <Card
            hoverable={hoverable !== undefined && hoverable !== null ? hoverable : true}
            onClick={onClick}
            bodyStyle={{
                paddingLeft: tableSize === 'small' ? 12 : 18,
                paddingRight: tableSize === 'small' ? 12 : 18,
                paddingTop: 10,
                paddingBottom: 10,
            }}
            style={style}
            // style={{
            // 	borderRadius: 8,
            // 	overflow: 'hidden',
            // 	border: 'none',
            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
            // }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                }}
            >
                <h3 style={{ margin: 0, fontWeight: 600, color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>{label}</h3>

                {hideStatus !== true && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {table && table.is_streaming_table === true && table.livestream_url && matchData.status !== 'COMPLETED' ? (
                            <Tag
                                style={{
                                    backgroundColor: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.tagBackgroundColor,
                                    border: 'none',
                                    color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.tagTextColor,
                                }}
                            >
                                <a
                                    href={table && table.livestream_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textTransform: 'uppercase',
                                        color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.tagTextColor,
                                    }}
                                    onClick={(e) => {
                                        if (onVideoClick) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onVideoClick();
                                        }
                                    }}
                                >
                                    <Icon type="play-circle" theme="filled" style={{ marginRight: 5, color: '#f5212d' }} /> Live stream
                                </a>
                            </Tag>
                        ) : matchData && matchData.status === 'COMPLETED' ? (
                            <React.Fragment>
                                {matchData && matchData.livestream_url ? (
                                    <Tag
                                        style={{
                                            backgroundColor: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.tagBackgroundColor,
                                            border: 'none',
                                            color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.tagTextColor,
                                        }}
                                    >
                                        <a
                                            href={table && table.livestream_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textTransform: 'uppercase',
                                                color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.tagTextColor,
                                            }}
                                            onClick={(e) => {
                                                if (onVideoClick) {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onVideoClick();
                                                }
                                            }}
                                        >
                                            <Icon type="play-circle" theme="filled" style={{ marginRight: 5, color: '#ff7529' }} /> View Recording
                                        </a>
                                    </Tag>
                                ) : (
                                    <Tag>COMPLETED</Tag>
                                )}
                            </React.Fragment>
                        ) : matchData && matchData.status !== 'IN_PROGRESS' && matchData.scheduled_time && moment(startT).isAfter(scheduledT) ? (
                            <Tag style={{ color: '#000', borderColor: '#61b3ff', backgroundColor: '#b2daff' }}>SCHEDULED</Tag>
                        ) : isTableOccupied === true && matchData && matchData.status !== 'COMPLETED' ? (
                            <Tag
                                color="blue"
                                style={{
                                    backgroundColor: '#b2daff',
                                    color: '#000',
                                }}
                            >
                                {status}
                            </Tag>
                        ) : table && table.status === 'ASSIGNED' ? (
                            <Tag color="orange">{status}</Tag>
                        ) : (
                            <Tag>{status}</Tag>
                        )}
                        {onEdit && (
                            <Button
                                size="small"
                                type="ghost"
                                ghost={theme && theme.name === 'dark' ? true : false}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onEdit();
                                }}
                            >
                                <Icon type="edit" />
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <PoolTable tournament={tournament} table={table} matchData={matchData} status={status} tableSize={tableSize} {...props} />
            <div>
                <Progress
                    percent={(matchData && matchData.progress) || 0}
                    status={matchData && matchData.progress < 100 ? 'active' : matchData && matchData.progress === 100 ? 'success' : 'normal'}
                    size="small"
                    // showInfo={false}
                    strokeWidth={5}
                    className={theme && theme.name}
                    // strokeLinecap="square"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {title && (
                    <Text style={{ marginLeft: 5, color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>{title}</Text>
                )}
                {/* <Text>Race to {tournament && tournament.winners_race_to}</Text> */}
                {hideTime !== true && (
                    <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                        {matchData && matchData.scheduled_time && matchData.start_time && moment(startT).isAfter(scheduledT) ? (
                            <React.Fragment>
                                <Icon type="clock-circle" style={{ marginRight: 5 }} />
                                <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                    {moment.utc(matchData.scheduled_time).local().calendar()}
                                </Text>
                            </React.Fragment>
                        ) : matchData && matchData.end_time ? (
                            <React.Fragment>
                                <Icon type="clock-circle" style={{ marginRight: 5 }} />
                                <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                    {matchData.end_time && `${moment.utc(matchData.end_time).local().format('h:mm A z')} ${timezone}`}{' '}
                                </Text>
                                <Text
                                    style={{
                                        color: matchData.end_time
                                            ? theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor
                                            : '#198fff',
                                    }}
                                >
                                    ({duration})
                                </Text>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {matchData && matchData.start_time && <Icon type="clock-circle" style={{ marginRight: 5 }} />}
                                <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                    {matchData && moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('h [hrs] m [min]')}
                                </Text>
                            </React.Fragment>
                        )}

                        {/* <Timer
						initialTime={
							matchData && matchData.start_time ? (
								moment.duration(moment.utc().diff(matchData && matchData.start_time)).asMilliseconds()
							) : null
						}
						formatValue={(value) => {
							return `${value < 10 ? `0${value}` : value}`;
						}}
					>
						<Timer.Hours />:
						<Timer.Minutes />
					</Timer> */}
                    </Text>
                )}
            </div>
        </Card>
    );
}
