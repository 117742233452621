import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Form,
    FormItem,
    Table,
    AddRowButton,
    RemoveRowButton,
    Input,
    Radio,
    Checkbox,
    DatePicker,
    Select,
    FormikDebug,
    SubmitButton,
    ResetButton,
    AutoComplete,
    InputNumber,
} from 'formik-antd';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import { message, Card, Collapse, Col, Row, Alert, Button, Tooltip, Typography, Avatar, Icon, Divider } from 'antd';
import * as Yup from 'yup';
import { Query } from '@apollo/client/react/components';
import slugify from 'slugify';
import _ from 'lodash';
import CircularLoader from '../../components/CircularLoader';
import { getGameType, getBracketSize, payoutStructures, calculateUSAPLRace, displayEnumType } from '../../utils';
import moment from 'moment-timezone';
import {
    GET_TOURNAMENT_BY_SLUG_QUERY,
    GET_GAME_TYPES_QUERY,
    GET_VENUES_QUERY,
    SEARCH_VENUES_QUERY,
    GET_USER_EVENTS_QUERY,
    SEARCH_USERS_BY_EMAIL,
    GET_TOURNAMENT_MANAGERS,
    SEARCH_USERS,
    GET_TOURNAMENT_BRACKET_TEMPLATES,
    GET_TOURNAMENT_TEMPLATES,
    GET_STAGE_QUERY,
    GET_TOURNAMENT_TIERED_ENTRY_FEES,
    GET_TOURNAMENT_SIDEPOTS,
} from './data/queries';
import {
    CREATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    DELETE_TOURNAMENT_MANAGER,
    INSERT_TOURNAMENT_MANAGERS,
    CREATE_TOURNAMENT_TEMPLATE,
    DELETE_TOURNAMENT_TEMPLATE,
    UPDATE_STAGE_MUTATION,
    DELETE_STAGE_MUTATION,
    DELETE_TOURNAMENT_SIDEPOT_MUTATION,
    UPDATE_TOURNAMENT_SIDEPOT_MUTATION,
    CREATE_TOURNAMENT_SIDEPOT_MUTATION,
} from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddVenueModal from './AddVenueModal';
import AddEventModal from './AddEventModal';
import TournamentStageForm from './TournamentStageForm';
import ChipAmountModal from './ChipAmountModal';
import TieredEntryFeeModal from './TieredEntryFeeModal';
import { QuestionCircleOutlined, QuestionCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import numeral from 'numeral';
import axios from 'axios';
import qs from 'query-string';
import { DollarCircleFilled, TrophyFilled } from '@ant-design/icons';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function TBCreateTournamentFormStages(props) {
    const { authState, onCreated, tournament, match, theme, client } = props;
    console.log(tournament);
    const [fargoLoading, setFargoLoading] = useState(false);
    const [chipAmounts, setChipAmounts] = useState();
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [chipModalVisible, setChipModalVisible] = useState(false);
    const [fargoCharts, setFargoCharts] = useState();
    const [fargoRaceSize, setFargoRaceSize] = useState(5);
    const [player1Fargo, setPlayer1Fargo] = useState(500);
    const [player1RaceTo, setPlayer1RaceTo] = useState(0);
    const [player2Fargo, setPlayer2Fargo] = useState(600);
    const [player2RaceTo, setPlayer2RaceTo] = useState(0);
    const [tieredEntryFeeModalVisible, setTieredEntryFeeModalVisible] = useState();
    const [venueDataSource, setVenueDataSource] = useState('');
    const [createTournamentTemplate] = useMutation(CREATE_TOURNAMENT_TEMPLATE);
    const [deleteTournamentTemplate] = useMutation(DELETE_TOURNAMENT_TEMPLATE);
    const [deleteStage] = useMutation(DELETE_STAGE_MUTATION);
    const [updateStage] = useMutation(UPDATE_STAGE_MUTATION);
    const [deleteTournamentSidepots] = useMutation(DELETE_TOURNAMENT_SIDEPOT_MUTATION);
    const [updateTournamentSidepots] = useMutation(UPDATE_TOURNAMENT_SIDEPOT_MUTATION);
    const [createTournamentSidepots] = useMutation(CREATE_TOURNAMENT_SIDEPOT_MUTATION);
    const [createTournament] = useMutation(CREATE_TOURNAMENT_MUTATION);
    const [updateTournament] = useMutation(UPDATE_TOURNAMENT_MUTATION);

    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const eventId = params.event_id;
    const formRef = useRef();

    // const [ publicPrivate, setPublicPrivate ] = useState('public');

    // const bracketDBDataset = createBracketDBDataset(1, 128);
    // console.log(bracketDBDataset);
    // console.table(bracketDBDataset.matches);

    // const winnerPlaces = bracketDBDataset.winnerPlacement;
    // const loserPlaces = bracketDBDataset.loserPlacement;
    // const matches = bracketDBDataset.matches;

    // console.log(matches);

    // const combined = _.uniq([ ...winnerPlaces.reverse(), ...loserPlaces.reverse(), '2' ])
    // 	.concat([ '3' ])
    // 	.sort((a, b) => {
    // 		return a.localeCompare(b, undefined, {
    // 			numeric: true,
    // 			sensitivity: 'base'
    // 		});
    // 	})
    // 	.map((item) => {
    // 		return {
    // 			place: item,
    // 			tournament_id: 1
    // 		};
    // 	});

    // console.log(combined);

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelect(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    const showLoading = () => {
        const hide = message.loading('Action in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleEventModalOk(e) {
        setEventModalVisible(false);
    }

    function handleEventModalCancel(e) {
        setEventModalVisible(false);
    }

    function handleAddEvent() {
        setEventModalVisible(true);
    }

    function handleChipModalOk(e) {
        setChipModalVisible(false);
    }

    function handleChipModalCancel(e) {
        setChipModalVisible(false);
    }

    function handleSetChipAmounts() {
        setChipModalVisible(true);
    }

    function handleSaveChips(data) {
        console.log(data);
        setChipAmounts(data);
    }

    function handleTieredEntryFees() {
        setTieredEntryFeeModalVisible(true);
    }

    function handleTieredEntryFeeModalOk(e) {
        setTieredEntryFeeModalVisible(false);
    }

    function handleTieredEntryFeeModalCancel(e) {
        setTieredEntryFeeModalVisible(false);
    }

    function handleSaveTieredEntries(data) {
        console.log(data);
    }

    // function onDateChange(value, dateString) {
    //     console.log('Selected Time: ', value);
    //     console.log('Formatted Selected Time: ', dateString);
    //     const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
    //     console.log('TZ Date', tzDate);
    //     return value;
    // }

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();
        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
        venue_id: Yup.string().required('Required'),
        winners_race_to: Yup.number().nullable().required('Required'),
        // losers_race_to: Yup.number().nullable().required('Required'),
        player_type: Yup.string().required('Required'),
        game_type: Yup.string().required('Required'),
        tournament_type: Yup.string().required('Required'),
        // tournament_format: Yup.string().required('Required'),
        start_date_time: Yup.date().nullable().required('Required'),
        end_date_time: Yup.date().nullable().required('Required'),
        // max_players: Yup.number().required('Required'),
    });

    function handleSubmit(values, actions) {
        console.log('values', values);

        if (values.tournament_stage_format === 'multi' && values.stages.length < 2) {
            message.error('A minimum of two stages are required for multi-stage tournaments');
            actions.setSubmitting(false);
            return;
        }

        const {
            name,
            venue_id,
            description,
            start_date_time,
            end_date_time,
            player_type,
            tournament_type,
            tournament_format,
            bracket_template_id,
            participant_times_played,
            enable_groups,
            enable_sets,
            sets_per_match,
            set_tiebreaker_format,
            split_bracket,
            players_per_group,
            players_advance_per_group,
            players_ranked_by,
            handicap_format,
            rule_format,
            break_format,
            game_type,
            max_players,
            max_tables,
            rebuys_allowed,
            max_rebuys,
            entry_fee,
            admin_fee,
            added_money,
            payout_type,
            draw_type,
            rating_system,
            use_text_messaging,
            is_public,
            is_featured,
            is_fargo_reported,
            tournament_stage_format,
            rsvp_allowed,
            show_player_skill_levels,
            show_player_races,
            show_unconfirmed_players,
            winners_race_to,
            winners_max_race,
            losers_race_to,
            losers_max_race,
            finals_race_to,
            signup_cutoff_time,
            consolidation_finals,
            usapl_race_format,
            event_id,
            save_as_template,
            template_name,
            players_per_table,
            redraw_every_round,
            default_chip_amount,
            autopilot_mode,
            tournament_sidepots,
            stages,
            livestream_split_url,
            enable_random_player_ordering,
        } = values;

        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (props.tournament) {
            updateTournament({
                variables: {
                    id: props.tournament.id,
                    changes: {
                        name,
                        slug,
                        venue_id,
                        description,
                        start_date_time,
                        end_date_time,
                        player_type,
                        tournament_type,
                        tournament_format,
                        bracket_template_id,
                        participant_times_played,
                        enable_groups,
                        enable_sets,
                        sets_per_match,
                        set_tiebreaker_format,
                        split_bracket,
                        players_per_group,
                        players_advance_per_group,
                        players_ranked_by,
                        handicap_format,
                        rule_format,
                        break_format,
                        game_type,
                        max_players: parseInt(max_players),
                        max_tables: parseInt(max_tables),
                        rebuys_allowed,
                        max_rebuys: parseInt(max_rebuys),
                        entry_fee,
                        admin_fee,
                        added_money,
                        payout_type,
                        draw_type,
                        rating_system,
                        use_text_messaging,
                        is_public,
                        is_featured,
                        is_fargo_reported,
                        tournament_stage_format,
                        rsvp_allowed,
                        show_player_skill_levels,
                        show_player_races,
                        show_unconfirmed_players,
                        winners_race_to: parseInt(winners_race_to),
                        winners_max_race: parseInt(winners_max_race),
                        losers_race_to: parseInt(losers_race_to),
                        losers_max_race: parseInt(losers_max_race),
                        finals_race_to: parseInt(finals_race_to),
                        usapl_race_format,
                        signup_cutoff_time,
                        consolidation_finals,
                        players_per_table: parseInt(players_per_table),
                        default_chip_amount: parseInt(default_chip_amount),
                        redraw_every_round,
                        autopilot_mode,
                        livestream_split_url,
                        enable_random_player_ordering,
                        // tournament_sidepots: tournament_sidepots.map((sidepot) => {
                        //     return {
                        //         name: sidepot.name,
                        //         amount: parseFloat(sidepot.amount),
                        //     };
                        // }),
                        event_id,
                        // is_public: is_public === 'public' ? true : false,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                        variables: {
                            slug: props.tournament.slug,
                        },
                    },
                ],
            })
                .then((res) => {
                    actions.setSubmitting(false);

                    // actions.resetForm();
                    if (save_as_template === true) {
                        if (tournament.tournament_templates && tournament.tournament_templates.length === 0) {
                            createTournamentTemplate({
                                variables: {
                                    objects: [
                                        {
                                            name: template_name,
                                            tournament_id: tournament.id,
                                            user_id: props.authState.user.id,
                                        },
                                    ],
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                        variables: {
                                            slug: tournament.slug,
                                        },
                                    },
                                ],
                            })
                                .then((data) => {
                                    actions.setSubmitting(false);
                                    console.log(data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    } else {
                        const original = tournament.tournament_templates.map((item) => item.id);
                        const toUpdate = _.uniq(save_as_template, original.length > 0);
                        const toDelete = _.difference(original, toUpdate);
                        console.log('original', original);
                        console.log('update', toUpdate);
                        console.log('delete', toDelete);

                        if (toDelete.length > 0) {
                            deleteTournamentTemplate({
                                variables: {
                                    id: toDelete[0],
                                    tournament_id: tournament.id,
                                },
                                notifyOnNetworkStatusChange: true,
                                awaitRefetchQueries: true,
                                refetchQueries: [
                                    {
                                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                        variables: {
                                            slug: tournament.slug,
                                        },
                                    },
                                ],
                            })
                                .then((data) => {
                                    console.log(data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }

                    const originalSidepots = tournament.tournament_sidepots && tournament.tournament_sidepots.map((item) => item.id);
                    const toUpdateSidepots = _.uniq(tournament_sidepots, originalSidepots);
                    const toDeleteSidepots = _.difference(
                        originalSidepots,
                        toUpdateSidepots.map((item) => item.id)
                    );

                    const allValues = originalSidepots && originalSidepots.concat(toUpdateSidepots);
                    const uniqueValues = allValues ? new Set(allValues.map((id) => id)) : [];

                    console.log('original sidepots', originalSidepots);
                    console.log('update sidepots', toUpdateSidepots);
                    console.log('delete sidepots', toDeleteSidepots);

                    if (toDeleteSidepots.length > 0) {
                        deleteTournamentSidepots({
                            variables: {
                                tournament_id: tournament.id,
                            },
                        })
                            .then((data) => {
                                console.log(data);
                                createSidepots();
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        if (allValues && uniqueValues.size >= allValues.length) {
                            createSidepots();
                        } else {
                            actions.setSubmitting(false);
                            message.success('Changes saved');
                        }
                    }

                    function createSidepots() {
                        createTournamentSidepots({
                            variables: {
                                objects:
                                    toUpdateSidepots &&
                                    toUpdateSidepots.map((sidepot) => {
                                        return {
                                            tournament_id: tournament.id,
                                            name: sidepot.name,
                                            amount: sidepot.amount ? parseFloat(sidepot.amount) : null,
                                        };
                                    }),
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_TOURNAMENT_SIDEPOTS,
                                    variables: { tournament_id: tournament.id },
                                },
                            ],
                        })
                            .then((data) => {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.log(error);
                                message.info('There was an error', error);
                            });
                    }

                    const originalStages = tournament.stages.map((item) => item.id);
                    const toUpdateStages = _.uniq(stages, originalStages);
                    const toDeleteStages = _.difference(
                        originalStages,
                        toUpdateStages.map((item) => item.id)
                    );
                    console.log('original stages', originalStages);
                    console.log('update stages', toUpdateStages);
                    console.log('delete stages', toDeleteStages);

                    if (toDeleteStages.length > 0) {
                        let promises = [];
                        toDeleteStages.forEach((id) => {
                            promises.push(
                                deleteStage({
                                    variables: {
                                        id: id,
                                        tournament_id: tournament.id,
                                    },
                                    notifyOnNetworkStatusChange: true,
                                    awaitRefetchQueries: true,
                                    refetchQueries: [
                                        {
                                            query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                            variables: {
                                                slug: tournament.slug,
                                            },
                                        },
                                    ],
                                })
                                    .then((data) => {
                                        console.log(data);
                                        return data;
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        return error;
                                    })
                            );
                        });

                        Promise.all(promises).then((values) => {
                            message.success(`Stages removed from tournament`);
                            if (toUpdateStages.length > 0) {
                                updateStages();
                            } else {
                                if (onCreated) {
                                    // const resUpdated = {
                                    //     ...result,
                                    //     stages: data && data.data && data.data.update_stages && data.data.update_stages.returning,
                                    // };
                                    // console.log(resUpdated);
                                    onCreated(tournament);
                                } else {
                                    props.history.push(`/tournament-builder/${tournament.slug}/edit/players`);
                                }
                            }
                        });
                    } else if (toUpdateStages.length > 0) {
                        updateStages();
                    } else {
                        const result =
                            res && res.data && res.data.update_tournaments && res.data.update_tournaments.returning && res.data.update_tournaments.returning[0];
                        console.log('res', result);
                        if (onCreated) {
                            console.log(result);
                            onCreated(result);
                        } else {
                            props.history.push(`/tournament-builder/${result.slug}/edit/players`);
                        }
                    }

                    function updateStages() {
                        updateStage({
                            variables: {
                                // id: stage.id,
                                // tournament_id: tournament.id,
                                objects: toUpdateStages.map((stage) => {
                                    console.log(stage);
                                    const stageSlug = slugify(stage.name, {
                                        replacement: '-',
                                        remove: /[*+~.,()'"#!:/@]/g,
                                        lower: true,
                                    });

                                    return {
                                        id: stage.id,
                                        name: stage.name,
                                        slug: stageSlug,
                                        number: stage.number,
                                        type: stage.tourament_type,
                                        status: stage.status,
                                        tournament_id: tournament.id,
                                        draw_type: stage.draw_type,
                                        tournament_type: stage.tournament_type,
                                        tournament_format: stage.tournament_format,
                                        usapl_race_format: stage.usapl_race_format,
                                        handicap_format: stage.handicap_format,
                                        autopilot_mode: stage.autopilot_mode,
                                        bracket_template_id: stage.bracket_template_id,
                                        consolidation_finals: stage.consolidation_finals,
                                        max_players: stage.max_players,
                                        winners_race_to: stage.winners_race_to,
                                        losers_race_to: stage.losers_race_to,
                                        split_bracket: stage.split_bracket,
                                        participant_times_played: stage.participant_times_played,
                                        players_advance_per_group: stage.players_advance_per_group,
                                        players_per_group: stage.players_per_group,
                                        players_per_table: stage.players_per_table,
                                        players_ranked_by: stage.players_ranked_by,
                                        default_chip_amount: stage.default_chip_amount,
                                        enable_groups: stage.enable_groups,
                                        finals_race_to: stage.finals_race_to,
                                    };
                                }),
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_TOURNAMENT_BY_SLUG_QUERY,
                                    variables: {
                                        slug: tournament.slug,
                                    },
                                },
                            ],
                        })
                            .then((data) => {
                                console.log('updated stages', data);
                                message.success('Changes saved');
                                const result =
                                    res &&
                                    res.data &&
                                    res.data.update_tournaments &&
                                    res.data.update_tournaments.returning &&
                                    res.data.update_tournaments.returning[0];
                                console.log('res', result);
                                if (onCreated) {
                                    const resUpdated = {
                                        ...result,
                                        stages: data && data.data && data.data.insert_stages && data.data.insert_stages.returning,
                                    };
                                    console.log(resUpdated);
                                    onCreated(resUpdated);
                                    // props.history.push(`/tournament-builder/${result.slug}/edit/?step=2`);
                                } else {
                                    props.history.push(`/tournament-builder/${result.slug}/edit/players`);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createTournament({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            organizer_id: props.authState.user.id,
                            director_id: props.authState.user.id,
                            venue_id,
                            start_date_time,
                            end_date_time,
                            tournament_type,
                            tournament_format,
                            bracket_template_id,
                            participant_times_played,
                            enable_groups,
                            enable_sets,
                            sets_per_match,
                            set_tiebreaker_format,
                            split_bracket,
                            players_per_group,
                            players_advance_per_group,
                            players_ranked_by,
                            handicap_format,
                            rule_format,
                            break_format,
                            player_type,
                            game_type,
                            max_players: parseInt(max_players),
                            max_tables: parseInt(max_tables),
                            rebuys_allowed,
                            max_rebuys: parseInt(max_rebuys),
                            entry_fee,
                            admin_fee,
                            added_money,
                            payout_type,
                            draw_type,
                            rating_system,
                            use_text_messaging,
                            is_public,
                            is_featured,
                            is_fargo_reported,
                            tournament_stage_format,
                            rsvp_allowed,
                            show_player_skill_levels,
                            show_player_races,
                            show_unconfirmed_players,
                            winners_race_to: parseInt(winners_race_to),
                            winners_max_race: parseInt(winners_max_race),
                            losers_race_to: parseInt(losers_race_to),
                            losers_max_race: parseInt(losers_max_race),
                            finals_race_to: parseInt(finals_race_to),
                            usapl_race_format,
                            signup_cutoff_time,
                            consolidation_finals,
                            players_per_table: parseInt(players_per_table),
                            default_chip_amount: parseInt(default_chip_amount),
                            redraw_every_round,
                            autopilot_mode,
                            livestream_split_url,
                            enable_random_player_ordering,
                            event_id,
                            ...(tournament_sidepots && {
                                tournament_sidepots: {
                                    data: tournament_sidepots.map((sidepot) => {
                                        return {
                                            name: sidepot.name,
                                            amount: sidepot.amount ? parseFloat(sidepot.amount) : null,
                                        };
                                    }),
                                },
                            }),
                            ...(stages && {
                                stages: {
                                    data: stages.map((stage) => {
                                        const stageSlug = slugify(stage.name, {
                                            replacement: '-',
                                            remove: /[*+~.,()'"#!:/@]/g,
                                            lower: true,
                                        });

                                        return {
                                            ...stage,
                                            slug: stageSlug,
                                            type: stage.tournament_type,
                                        };
                                    }),
                                },
                            }),
                            ...(chipAmounts && {
                                tournament_chips: {
                                    data: chipAmounts,
                                },
                            }),
                            ...(save_as_template === true && {
                                tournament_templates: {
                                    data: {
                                        name: template_name,
                                        user_id: props.authState.user.id,
                                    },
                                },
                            }),
                        },
                    ],
                },
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    const result =
                        res && res.data && res.data.insert_tournaments && res.data.insert_tournaments.returning && res.data.insert_tournaments.returning[0];
                    console.log(res);
                    console.log('res', result);
                    if (onCreated) {
                        props.history.push(`/tournament-builder/${result.slug}/edit/players`);
                        onCreated(result);
                    } else {
                        props.history.push(`/tournament-builder/${result.slug}/edit/players`);
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    if (e.message && e.message.includes('Uniqueness violation')) {
                        message.error('Error: There is already a tournament with that name. Please choose a different name.');
                    } else {
                        message.error((e && e.message) || JSON.stringify(e));
                    }

                    console.log(e);
                });
        }
    }

    const defaultStageValues = [
        {
            name: 'Stage 1',
            number: 1,
            type: 'double_elimination',
            tournament_type: 'double_elimination',
            tournament_format: 'standard',
            bracket_template_id: undefined,
            participant_times_played: 1,
            max_players: undefined,
            enable_groups: false,
            split_bracket: false,
            players_per_group: undefined,
            players_advance_per_group: undefined,
            players_ranked_by: 'match_wins',
            consolidation_finals: true,
            winners_race_to: 5,
            losers_race_to: 5,
            finals_race_to: undefined,
            usapl_race_format: 'longer_races',
            handicap_format: 'fargo_hot_column',
            players_per_table: 2,
            default_chip_amount: 3,
            autopilot_mode: false,
            draw_type: 'random',
        },
        {
            name: 'Stage 2',
            number: 2,
            type: 'single_elimination',
            tournament_type: 'single_elimination',
            tournament_format: 'standard',
            bracket_template_id: undefined,
            participant_times_played: 1,
            max_players: undefined,
            enable_groups: false,
            split_bracket: false,
            players_per_group: undefined,
            players_advance_per_group: undefined,
            players_ranked_by: 'match_wins',
            consolidation_finals: true,
            winners_race_to: 5,
            finals_race_to: 5,
            usapl_race_format: 'longer_races',
            handicap_format: 'fargo_hot_column',
            players_per_table: 2,
            default_chip_amount: 3,
            autopilot_mode: false,
            draw_type: 'random',
        },
    ];

    const initialValues = props.tournament
        ? {
              ...props.tournament,
              //   tournament_format: props.tournament_format || 'standard',
              venue_id: props.tournament.venue && props.tournament.venue.id,
              venue_name: props.tournament.venue && props.tournament.venue.name,
              event_id: props.tournament.event_id && props.tournament.event_id,
              save_as_template: props.tournament.tournament_templates && props.tournament.tournament_templates.length > 0 ? true : false,
              template_name: props.tournament.tournament_templates && props.tournament.tournament_templates[0] && props.tournament.tournament_templates[0].name,
              ...(props.tournament.tournament_stage_format === 'multi' && {
                  stages: props.tournament.stages && props.tournament.stages.length > 0 ? props.tournament.stages : defaultStageValues,
              }),
              ...(props.tournament.tournament_sidepots && {
                  tournament_sidepots: props.tournament.tournament_sidepots || [],
              }),
          }
        : {
              name: '',
              venue_id: undefined,
              venue_name: undefined,
              description: '',
              start_date_time: '',
              end_date_time: '',
              player_type: 'singles',
              tournament_type: 'double_elimination',
              tournament_format: 'standard',
              bracket_template_id: undefined,
              participant_times_played: 1,
              enable_groups: false,
              split_bracket: false,
              players_per_group: undefined,
              players_advance_per_group: undefined,
              players_ranked_by: 'match_wins',
              handicap_format: 'fargo_hot_column',
              rule_format: 'bca',
              break_format: 'winner_break',
              game_type: undefined,
              max_players: undefined,
              max_tables: undefined,
              rebuys_allowed: false,
              max_rebuys: undefined,
              entry_fee: '',
              admin_fee: '',
              added_money: '',
              payout_type: 'custom',
              draw_type: 'random',
              rating_system: undefined,
              use_text_messaging: false,
              is_public: false,
              is_featured: false,
              is_fargo_reported: true,
              tournament_stage_format: 'single',
              rsvp_allowed: false,
              show_player_skill_levels: true,
              show_player_races: true,
              show_unconfirmed_players: true,
              consolidation_finals: true,
              winners_race_to: 5,
              winners_max_race: undefined,
              losers_race_to: 5,
              losers_max_race: undefined,
              finals_race_to: undefined,
              save_as_template: false,
              redraw_every_round: false,
              players_per_table: 2,
              default_chip_amount: 3,
              autopilot_mode: props.tournament && props.tournament.tournament_type === 'chip' ? true : false,
              usapl_race_format: 'longer_races',
              signup_cutoff_time: 'start_time',
              enable_sets: false,
              sets_per_match: undefined,
              set_tiebreaker_format: undefined,
              livestream_split_url: '',
              event_id: eventId ? parseInt(eventId) : undefined,
              ...(props.tournament && props.tournament.tournament_stage_format === 'multi' && { stages: defaultStageValues }),
              enable_random_player_ordering: false,
          };

    function handleFocus(event) {
        event.target.select();
    }

    return (
        <div>
            <div>
                <Alert
                    message="Helpful tips for setting up your tournament"
                    description={`We recommend to start by making your tournament private if you are just testing out the software or don't want other people to be able to access it. When you are ready to make it public, simply change the access setting to "Public" and your tournament will appear on the main /tournaments page.`}
                    type="info"
                    closable
                    showIcon
                />
            </div>
            <Title level={4} style={{ marginTop: 20, marginBottom: 15 }}>
                Tournament Settings
            </Title>
            <div>
                <Formik
                    ref={formRef}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={SignupSchema}
                    onSubmit={(values, actions) => {
                        console.log(values);
                        handleSubmit(values, actions);
                    }}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                        const payouts = payoutStructures(values);
                        // console.log(payouts);
                        // console.log(fargoCharts);
                        // console.log('payouts', payouts.payoutOptions);
                        // console.log('recommended', payouts.recommendedPayouts);

                        return (
                            // <Form layout="horizontal" {...formItemLayout} style={{ maxWidth: 600 }}>
                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                <FormItem label="Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                    <Input
                                        name="name"
                                        placeholder="Name"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Saved Tournament Template" name="template_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_TOURNAMENT_TEMPLATES}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            user_id: props.authState.user.id,
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            const templates = data.tournament_templates;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="template_id"
                                                    placeholder="Select tournament template"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    onChange={(e) => {
                                                        const template = templates && templates.filter((item) => item.id === e)[0];
                                                        const templateTournament = template && template.tournament;

                                                        if (e) {
                                                            formRef.current.setValues({
                                                                ...templateTournament,
                                                                venue_id: templateTournament.venue && templateTournament.venue.id,
                                                                venue_name: templateTournament.venue && templateTournament.venue.name,
                                                                template_id: e,
                                                            });
                                                        } else {
                                                            formRef.current.setValues({
                                                                ...initialValues,
                                                            });
                                                        }
                                                    }}
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        // console.log(option);
                                                        return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    <Option value="">
                                                        <Text>None</Text>
                                                    </Option>
                                                    {data.tournament_templates &&
                                                        data.tournament_templates
                                                            .sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
                                                            .map((item, index) => (
                                                                <Option key={index} value={item.id}>
                                                                    <Text>
                                                                        {item.name} - {displayEnumType(item.tournament.game_type)}
                                                                        {item.tournament.max_players} Players {displayEnumType(item.tournament.tournament_type)}
                                                                    </Text>
                                                                </Option>
                                                            ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                                <FormItem name="description" label="Description">
                                    <TextArea
                                        rows={4}
                                        name="description"
                                        placeholder="Description"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Start Date & Time" name="start_date_time" required hasFeedback showValidateSuccess>
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        use12Hours
                                        name="start_date_time"
                                        size="large"
                                        format="YYYY-MM-DD h:mm A z"
                                        // onChange={onDateChange}
                                        onOk={onOk}
                                    />
                                </FormItem>
                                <FormItem
                                    label="End Date & Time"
                                    name="end_date_time"
                                    required
                                    hasFeedback
                                    showValidateSuccess
                                    // className={
                                    // 	'ant-form-item-control' +
                                    // 	(errors.end_date || errors.general ? ' has-error' : '')
                                    // }
                                >
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        use12Hours
                                        name="end_date_time"
                                        size="large"
                                        format="YYYY-MM-DD h:mm A z"
                                        // onChange={onDateChange}
                                        onOk={onOk}
                                    />
                                    {/* <ErrorMessage name="end_date" component="div" className="ant-form-explain" /> */}
                                </FormItem>
                                <FormItem
                                    label={
                                        <React.Fragment>
                                            Associate with an event{' '}
                                            <Tooltip
                                                placement="right"
                                                title={
                                                    <div>
                                                        Events can be used for marketing purposes and linking multiple tournaments together. In the near future,
                                                        players will also be able to pre-register for an event. Creating an event is not required.
                                                    </div>
                                                }
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                    name="event_id"
                                >
                                    <Query
                                        query={GET_USER_EVENTS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ user_id: props.authState.user.id }}
                                        onCompleted={async (data) => {
                                            console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            const events = data.events;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="event_id"
                                                    placeholder="Select an event"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={eventId}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div
                                                                style={{
                                                                    padding: '4px 8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                onClick={handleAddEvent}
                                                            >
                                                                <Icon type="plus" /> Add Event
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {events.map((item, index) => (
                                                        <Option key={index} value={item.id}>
                                                            <Text>{item.name}</Text>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                                <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AutoComplete
                                            name="venue_name"
                                            dataSource={venueDataSource}
                                            placeholder="Search for a venue"
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            size="large"
                                            filterOption={(inputValue, option) => {
                                                return (
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                );
                                            }}
                                            dropdownRender={(menu) => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div
                                                        style={{
                                                            padding: '4px 8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={handleAddVenue}
                                                    >
                                                        <Icon type="plus" /> Add Venue
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            {venueDataSource &&
                                                venueDataSource.map((item, index) => (
                                                    <Option key={index} value={`${item.id}`}>
                                                        {item.name} - {item.city}, {item.region}
                                                    </Option>
                                                ))}
                                        </AutoComplete>
                                        <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                            <Icon type="plus" /> Add Venue
                                        </Button>
                                    </div>
                                </FormItem>
                                {/* <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                                    <Query
                                                        query={GET_VENUES_QUERY}
                                                        fetchPolicy="cache-and-network"
                                                        notifyOnNetworkStatusChange={true}
                                                        onCompleted={async (data) => {
                                                            // console.log(data);
                                                        }}
                                                    >
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <div>Loading...</div>;
                                                            if (error) return <div>Error: {error.message}</div>;

                                                            return (
                                                                <Select
                                                                    showSearch
                                                                    // mode="multiple"
                                                                    name="venue_id"
                                                                    placeholder="Select a venue for the tournament"
                                                                    size="large"
                                                                    optionFilterProp="children"
                                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                                    filterOption={(input, option) => {
                                                                        return (
                                                                            option.props.children.props.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(input.toLowerCase()) >= 0
                                                                        );
                                                                    }}
                                                                    dropdownRender={(menu) => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            <div
                                                                                style={{
                                                                                    padding: '4px 8px',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onMouseDown={(e) => e.preventDefault()}
                                                                                onClick={handleAddVenue}
                                                                            >
                                                                                <Icon type="plus" /> Add Venue
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {data.venues
                                                                        .sort((a, b) => {
                                                                            return a.name.localeCompare(b.name, undefined, {
                                                                                numeric: true,
                                                                                sensitivity: 'base',
                                                                            });
                                                                        })
                                                                        .map((item, index) => (
                                                                            <Option key={index} value={item.id}>
                                                                                <Text>
                                                                                    {item.name} ({item.city}, {item.region})
                                                                                </Text>
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            );
                                                        }}
                                                    </Query>
                                                </FormItem> */}
                                <FormItem label="Player Type" name="player_type" required hasFeedback showValidateSuccess>
                                    {/* <Radio.Group
										name="player_type"
										defaultValue="singles"
										size="medium"
									>
										<Radio.Button value="singles">Singles (1v1)</Radio.Button>
										<Radio.Button value="doubles">Doubles (2v2)</Radio.Button>
										<Radio.Button value="scotch-doubles">
											Scotch Doubles (2v2 alternating shot)
										</Radio.Button>
										<Radio.Button value="team" disabled>
											Teams
										</Radio.Button>
									</Radio.Group> */}
                                    <Select
                                        showSearch
                                        name="player_type"
                                        placeholder="Select a player type"
                                        size="large"
                                        optionFilterProp="children"
                                        defaultValue="singles"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {[
                                            {
                                                type: 'Singles (1v1)',
                                                value: 'singles',
                                            },
                                            {
                                                type: 'Doubles (2v2)',
                                                value: 'doubles',
                                            },
                                            {
                                                type: 'Scotch Doubles (2v2 alternating shot)',
                                                value: 'scotch-doubles',
                                            },
                                            {
                                                type: 'Teams',
                                                value: 'teams',
                                            },
                                        ].map((item, index) => (
                                            <Option key={index} value={item.value}>
                                                {item.type}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                                <FormItem label="Game Type" name="game_type" required hasFeedback showValidateSuccess>
                                    <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="game_type"
                                                    placeholder="Select game being played in the tournament"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        // console.log(option);
                                                        return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data.game_types
                                                        // .sort((a, b) => a.value.localeCompare(b.value))
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                {getGameType(item.value) && (
                                                                    <Avatar
                                                                        size={24}
                                                                        src={getGameType(item.value, theme)}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    />
                                                                )}

                                                                <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))} </Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>

                                <FormItem
                                    label={
                                        <React.Fragment>
                                            Tournament Stage
                                            <Tooltip placement="right" title={<div>Run tournaments with multiple stages within a single tournament.</div>}>
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                    name="tournament_stage_format"
                                >
                                    <Radio.Group name="tournament_stage_format">
                                        <Radio value="single">Single stage</Radio>
                                        <Radio value="multi">Multi-stage</Radio>
                                    </Radio.Group>
                                </FormItem>

                                {values && values.tournament_stage_format === 'single' ? (
                                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                                        <TournamentStageForm
                                            formRef={formRef}
                                            stage={defaultStageValues}
                                            index={0}
                                            tournament={tournament}
                                            values={values}
                                            {...props}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                                        <FieldArray
                                            name="stages"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.stages &&
                                                        values.stages.length > 0 &&
                                                        values.stages.map((stage, index) => {
                                                            return (
                                                                <TournamentStageForm
                                                                    {...props}
                                                                    formRef={formRef}
                                                                    stage={stage}
                                                                    tournament={tournament}
                                                                    values={values}
                                                                    index={index}
                                                                    key={index}
                                                                    onRemoveStage={(stage) => {
                                                                        console.log(stage);
                                                                        formRef.current.setValues({
                                                                            ...formRef.current.state.values,
                                                                            stages: [
                                                                                ...values.stages.filter((item, index) => {
                                                                                    return item.number !== stage.number;
                                                                                }),
                                                                            ],
                                                                        });
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            )}
                                        />

                                        <Button
                                            type="ghost"
                                            style={{ width: '100%', flex: 1 }}
                                            size="large"
                                            disabled={values.stages && values.stages.length > 1}
                                            onClick={() => {
                                                const state = formRef.current.state.values;
                                                // const stageNum = state.stages.length + 1;
                                                formRef.current.setValues({
                                                    ...values,
                                                    stages:
                                                        values.stages && values.stages.length === 0
                                                            ? defaultStageValues.map((value, index) => {
                                                                  const stageNum = index + 1;
                                                                  return {
                                                                      ...value,
                                                                      name: `Stage ${stageNum}`,
                                                                      // tournament_type: 'single_elimination',
                                                                      number: stageNum,
                                                                  };
                                                              })
                                                            : [
                                                                  {
                                                                      ...defaultStageValues[0],
                                                                  },
                                                                  {
                                                                      ...defaultStageValues[1],
                                                                      name: `Stage 2`,
                                                                      number: 2,
                                                                  },
                                                              ],
                                                });
                                            }}
                                        >
                                            + Add Stage
                                        </Button>
                                    </div>
                                )}

                                <FormItem
                                    name="use_text_messaging"
                                    label={
                                        <React.Fragment>
                                            Use Text Messaging{' '}
                                            <Tooltip
                                                title={
                                                    <div>
                                                        The text messaging feature will automatically notify players when they are assigned to play a match. A
                                                        live scoring link is also sent with each text message so players can live score their own matches from
                                                        their mobile phone (no login required).
                                                    </div>
                                                }
                                                placement="right"
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                >
                                    <Radio.Group name="use_text_messaging">
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>
                                {/* <div style={{ marginTop: 5, marginBottom: 5, display: 'flex', alignItems: 'center' }}>
                                                    <Icon type="warning" style={{ color: 'orange' }} />
                                                    <Text type="secondary" style={{ marginLeft: 10 }}>
                                                        This feature is currently disabled until further notice. We are sorry for any inconvenience.
                                                    </Text>
                                                </div> */}

                                <FormItem
                                    name="is_fargo_reported"
                                    label={
                                        <React.Fragment>
                                            Report results to FargoRate{' '}
                                            <Tooltip
                                                title={
                                                    <div>
                                                        Automatically report your tournament results to FargoRate. FargoRate uses a simple mathematical approach
                                                        to accurately measure a pool player's ability relative to everyone else in the world.
                                                    </div>
                                                }
                                                placement="right"
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                >
                                    <Radio.Group name="is_fargo_reported">
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>

                                {authState && authState.user && authState.user.role === 'admin' && (
                                    <FormItem
                                        name="is_featured"
                                        label={
                                            <React.Fragment>
                                                Featured{' '}
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            Mark this tournament as featured and make it appear at the top of the list on the Tournaments page.
                                                        </div>
                                                    }
                                                    placement="right"
                                                >
                                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                </Tooltip>
                                            </React.Fragment>
                                        }
                                    >
                                        <Radio.Group name="is_featured">
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                )}

                                <FormItem name="is_public" label="Access">
                                    <Radio.Group name="is_public">
                                        <Radio value={true}>Public - Anyone can view this league and request to join.</Radio>
                                        <br />
                                        <Radio value={false}>Private - Only users who have been sent an invite can view or join this league.</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem
                                    name="save_as_template"
                                    label={
                                        <React.Fragment>
                                            Save Template{' '}
                                            <Tooltip
                                                title={<div>Save these tournament settings as a template which can be re-used for future tournaments.</div>}
                                                placement="right"
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                >
                                    <Checkbox name="save_as_template">Save tournament settings as template</Checkbox>
                                </FormItem>
                                {values && values.save_as_template === true && (
                                    <FormItem name="template_ame">
                                        <Input
                                            name="template_name"
                                            placeholder="Template name"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                )}

                                <Collapse style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Panel
                                        header="Extra Settings (optional)"
                                        key="1"
                                        extra={
                                            <Tooltip title="These settings are not required but can be useful.">
                                                <QuestionCircleFilled />
                                            </Tooltip>
                                        }
                                    >
                                        <FormItem name="rating_system" label="Rating System">
                                            <Select
                                                // showSearch
                                                name="rating_system"
                                                placeholder="Select a rating system"
                                                size="large"
                                                defaultValue="even"
                                            >
                                                {[
                                                    {
                                                        type: 'Fargo Rate',
                                                        value: 'fargo_rate',
                                                    },
                                                    {
                                                        type: 'APA',
                                                        value: 'apa',
                                                    },
                                                    {
                                                        type: 'ABCD',
                                                        value: 'abcd',
                                                    },
                                                    {
                                                        type: 'Local',
                                                        value: 'local',
                                                    },
                                                    {
                                                        type: 'In-House',
                                                        value: 'in_house',
                                                    },
                                                    {
                                                        type: 'No Handicap (Even race)',
                                                        value: 'even',
                                                    },
                                                    {
                                                        type: 'Florida Pool Tour',
                                                        value: 'florida_pool_tour',
                                                    },
                                                    {
                                                        type: `Danny K's`,
                                                        value: 'danny_ks',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                        <FormItem name="break_format" label="Break Format">
                                            <Radio.Group name="break_format" defaultValue="winner_break" size="medium">
                                                <Radio.Button value="winner_break">Winner Breaks</Radio.Button>
                                                <Radio.Button value="loser_break">Loser Breaks</Radio.Button>
                                                <Radio.Button value="alternate_break">Alternate Break</Radio.Button>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem
                                            name="enable_sets"
                                            label={
                                                <React.Fragment>
                                                    Enable multiple set scoring{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This option allows for multiple sets to be scored in a single match, similar to Tennis. If you
                                                                only plan to score one set in each match, you do not need to enable this option.
                                                            </div>
                                                        }
                                                        placement="right"
                                                    >
                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        >
                                            <Radio.Group name="enable_sets">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem
                                            name="enable_random_player_ordering"
                                            label={
                                                <React.Fragment>
                                                    Enable random player ordering each round{' '}
                                                    <Tooltip
                                                        title={<div>This option allows for the players in the bracket to be shuffled in each round.</div>}
                                                        placement="right"
                                                    >
                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        >
                                            <Radio.Group name="enable_random_player_ordering">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        {values && values.enable_sets === true && (
                                            <React.Fragment>
                                                <FormItem label="Default sets per match" name="sets_per_match" hasFeedback showValidateSuccess>
                                                    <Input
                                                        name="sets_per_match"
                                                        placeholder="Number of sets per match"
                                                        size="large"
                                                        suffix={
                                                            <Tooltip title="The number of default sets to use per match.">
                                                                <QuestionCircleFilled />
                                                            </Tooltip>
                                                        }
                                                    />
                                                </FormItem>
                                                <FormItem label="Set tiebreaker format" name="set_tiebreaker_format" hasFeedback showValidateSuccess>
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        name="set_tiebreaker_format"
                                                        placeholder="Select a tournament type"
                                                        size="large"
                                                        optionFilterProp="children"
                                                        defaultValue="most_sets_won"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        dropdownRender={(menu) => <div>{menu}</div>}
                                                    >
                                                        {[
                                                            {
                                                                type: 'Most sets won',
                                                                value: 'most_sets_won',
                                                            },
                                                            {
                                                                type: 'Most games won',
                                                                value: 'most_games_won',
                                                            },
                                                            {
                                                                type: 'Most points won',
                                                                value: 'most_points_won',
                                                            },
                                                        ].map((item, index) => (
                                                            <Option key={index} value={item.value} style={{ display: 'flex', alignItems: 'center' }}>
                                                                {item.type}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </FormItem>
                                            </React.Fragment>
                                        )}

                                        <FormItem name="rule_format" label="Rules">
                                            <Select
                                                // showSearch
                                                name="rule_format"
                                                placeholder="Select the rules to use"
                                                size="large"
                                                optionFilterProp="children"
                                                // defaultValue="lucy"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'WPA',
                                                        value: 'wpa',
                                                    },
                                                    {
                                                        type: 'BCA',
                                                        value: 'bca',
                                                    },
                                                    {
                                                        type: 'APA',
                                                        value: 'apa',
                                                    },
                                                    {
                                                        type: 'VNEA',
                                                        value: 'vnea',
                                                    },
                                                    {
                                                        type: 'Local',
                                                        value: 'local',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                        <div>
                                            <div>
                                                <Text>Tiered entry fees</Text>
                                            </div>
                                            <div>
                                                {tournament ? (
                                                    <Query
                                                        query={GET_TOURNAMENT_TIERED_ENTRY_FEES}
                                                        fetchPolicy="cache-and-network"
                                                        notifyOnNetworkStatusChange={true}
                                                        variables={{ tournament_id: tournament.id }}
                                                        onCompleted={async (data) => {
                                                            console.log(data);
                                                        }}
                                                    >
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <CircularLoader />;
                                                            if (error) return <div>Error: {error.message}</div>;

                                                            const entryFees =
                                                                data && data.tournament_tiered_entry_fees && data.tournament_tiered_entry_fees.length > 0
                                                                    ? data.tournament_tiered_entry_fees.map((item) => {
                                                                          return {
                                                                              skill_level: item.skill_level,
                                                                              amount: item.amount,
                                                                          };
                                                                      })
                                                                    : null;

                                                            return (
                                                                <React.Fragment>
                                                                    {entryFees ? (
                                                                        <React.Fragment>
                                                                            <table width="100%" style={{ marginTop: 10 }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Skill level</th>
                                                                                        <th>Entry fee</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {entryFees &&
                                                                                        entryFees.map((row, index) => {
                                                                                            return (
                                                                                                <tr
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        paddingTop: 2,
                                                                                                        paddingBottom: 2,
                                                                                                    }}
                                                                                                >
                                                                                                    <td style={styles.noWrap}>{row.skill_level}</td>
                                                                                                    <td style={styles.noWrap}>{row.amount}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        })}
                                                                                </tbody>
                                                                            </table>
                                                                            <Button
                                                                                type="primary"
                                                                                ghost
                                                                                size="large"
                                                                                onClick={() => {
                                                                                    handleTieredEntryFees();
                                                                                }}
                                                                                style={{ marginTop: 10 }}
                                                                            >
                                                                                <Icon type="edit" /> Edit Entry Fees
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <Button
                                                                            type="primary"
                                                                            ghost
                                                                            size="large"
                                                                            onClick={() => {
                                                                                handleTieredEntryFees();
                                                                            }}
                                                                            style={{ marginTop: 10 }}
                                                                        >
                                                                            <Icon type="plus" /> Tiered Entry Fees
                                                                        </Button>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        ghost
                                                        size="large"
                                                        onClick={() => {
                                                            handleTieredEntryFees();
                                                        }}
                                                        style={{ marginTop: 10 }}
                                                    >
                                                        <Icon type="plus" /> Tiered Entry Fees
                                                    </Button>
                                                )}
                                            </div>
                                        </div>

                                        <FormItem name="entry_fee" label="Entry Fee">
                                            <Input addonBefore={<DollarCircleFilled />} name="entry_fee" placeholder="Cost of entry" size="large" />
                                        </FormItem>

                                        <FormItem name="admin_fee" label="Admin Fee">
                                            <Input addonBefore={<DollarCircleFilled />} name="admin_fee" placeholder="Cost of admin fee" size="large" />
                                        </FormItem>
                                        <FormItem name="added_money" label="Added Money">
                                            <Input
                                                addonBefore={<DollarCircleFilled />}
                                                name="added_money"
                                                placeholder="Money added to the prize pool"
                                                size="large"
                                            />
                                        </FormItem>

                                        <FormItem label="Side pots" name="tournament_sidepots">
                                            <div>
                                                <Table
                                                    name="tournament_sidepots"
                                                    rowKey={(row, index) => index}
                                                    size="small"
                                                    pagination={false}
                                                    columns={[
                                                        {
                                                            title: 'Name',
                                                            key: 'name',
                                                            render: (text, record, i) => (
                                                                <Input placeholder="Name" style={{ border: 'none' }} name={`tournament_sidepots.${i}.name`} />
                                                            ),
                                                        },
                                                        {
                                                            title: 'Amount',
                                                            key: 'amount',
                                                            render: (text, record, i) => (
                                                                <Input
                                                                    addonBefore={<DollarCircleFilled />}
                                                                    style={{ border: 'none' }}
                                                                    name={`tournament_sidepots.${i}.amount`}
                                                                    placeholder="0.00"
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            width: 32,
                                                            key: 'actions',
                                                            align: 'right',
                                                            render: (text, record, index) => (
                                                                <RemoveRowButton
                                                                    style={{ border: 'none' }}
                                                                    icon="delete"
                                                                    name="tournament_sidepots"
                                                                    index={index}
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                />
                                                <AddRowButton
                                                    name="tournament_sidepots"
                                                    type="primary"
                                                    ghost
                                                    style={{ marginTop: 10 }}
                                                    createNewRow={() => ({
                                                        name: 'Side pot',
                                                        amount: '',
                                                    })}
                                                >
                                                    <Icon type="plus" /> Add
                                                </AddRowButton>
                                            </div>
                                        </FormItem>

                                        <FormItem name="payout_type" label="Payout Structure">
                                            <Select
                                                showSearch
                                                name="payout_type"
                                                placeholder="Select how winners will be paid out"
                                                size="large"
                                                optionFilterProp="children"
                                                defaultValue="custom"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'Custom',
                                                        value: 'custom',
                                                    },
                                                    {
                                                        type: 'Winner Take All (1-4 players)',
                                                        value: '1-1',
                                                    },
                                                    {
                                                        type: 'Top 2 places (5-8 players)',
                                                        value: '1-2',
                                                    },
                                                    {
                                                        type: 'Top 3 places (9-16 players)',
                                                        value: '1-3',
                                                    },
                                                    {
                                                        type: 'Top 4 places (17-24 players)',
                                                        value: '1-4',
                                                    },
                                                    {
                                                        type: 'Top 6 places (25-31 players)',
                                                        value: '1-6',
                                                    },
                                                    {
                                                        type: 'Top 8 places (32-63 players)',
                                                        value: '1-8',
                                                    },
                                                    {
                                                        type: 'Top 12 places (64-80 players)',
                                                        value: '1-12',
                                                    },
                                                    {
                                                        type: 'Top 16 places (81-95 players)',
                                                        value: '1-16',
                                                    },
                                                    {
                                                        type: 'Top 24 places (96-128 players)',
                                                        value: '1-24',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                            {payouts && payouts.chosenPayouts && payouts.chosenPayouts.payouts && (
                                                <div style={{ marginTop: 10, padding: 10 }}>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Text style={{ fontSize: 15, fontWeight: 600 }}>Payout Preview</Text>
                                                    </div>
                                                    <div style={{ marginBottom: 10 }}>
                                                        {/* <Text>
                                                    Total prize purse: <b>{numeral(payouts.totalPurse).format('$0,0.00')}</b> based on a{' '}
                                                    <b>${payouts.entryFee}</b> entry fee, <b>${payouts.adminFee}</b> admin fee, <b>${payouts.addedMoney}</b>{' '}
                                                    added money and <b>{payouts.totalPlayers}</b> players.
                                                </Text> */}
                                                        {/* <Text>
                                                    Total prize purse: <b>{numeral(payouts.totalPurse).format('$0,0.00')}</b>
                                                </Text>
                                                <br />
                                                <br /> */}
                                                        <Text>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td style={{ width: 50 }}>
                                                                            <b>${payouts.entryFee * payouts.totalPlayers}</b>
                                                                        </td>
                                                                        <td>
                                                                            <b>${payouts.entryFee}</b> entry fee x <b>{payouts.totalPlayers} players</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>+</td>
                                                                        <td>
                                                                            <b>${payouts.addedMoney}</b>
                                                                        </td>
                                                                        <td>added money</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>-</td>
                                                                        <td>
                                                                            <b>${payouts.adminFee * payouts.totalPlayers}</b>
                                                                        </td>
                                                                        <td>
                                                                            <b>${payouts.adminFee}</b> admin fee x <b>{payouts.totalPlayers} players</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        style={
                                                                            theme && theme.name === 'dark'
                                                                                ? { borderTop: '1px solid rgba(255,255,255,0.3)' }
                                                                                : { borderTop: '1px solid rgba(0,0,0,0.3)' }
                                                                        }
                                                                    >
                                                                        <td></td>
                                                                        <td>
                                                                            <b>{numeral(payouts.totalPurse).format('$0,0.00')}</b>
                                                                        </td>
                                                                        <td>Total payout</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Text>
                                                    </div>
                                                    <table
                                                        width="100%"
                                                        className="info-table"
                                                        style={{
                                                            marginTop: 10,
                                                            marginBottom: 0,
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td>Place</td>
                                                                <td>Payout</td>
                                                                <td>Percent</td>
                                                            </tr>
                                                            {payouts.chosenPayouts.payouts.map((payouts, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{payouts.place}</td>
                                                                        <td>
                                                                            <Text>{numeral(payouts.payout).format('$0,0')}</Text>
                                                                        </td>
                                                                        <td>
                                                                            <Text>{payouts.percent}%</Text>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            <tr>
                                                                <td>Total</td>
                                                                <td>
                                                                    <b>
                                                                        $
                                                                        {payouts.chosenPayouts.payouts
                                                                            .map((payouts, index) => payouts.payout)
                                                                            .reduce((acc, total) => {
                                                                                return acc + total;
                                                                            }, 0)
                                                                            .toFixed(0)}
                                                                    </b>
                                                                </td>
                                                                <td>100%</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <Text>These payouts and percentages can be customized later.</Text>
                                                </div>
                                            )}
                                        </FormItem>

                                        <FormItem name="signup_cutoff_time" label="Sign Up Cutoff Time">
                                            <Select
                                                showSearch
                                                name="signup_cutoff_time"
                                                placeholder="Cutoff time before players are not allowed to sign up"
                                                size="large"
                                                optionFilterProp="children"
                                                defaultValue="start_time"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'At start time',
                                                        value: 'start_time',
                                                    },
                                                    {
                                                        type: '10 minutes before',
                                                        value: '10_min',
                                                    },
                                                    {
                                                        type: '15 minutes before',
                                                        value: '15_min',
                                                    },
                                                    {
                                                        type: '30 minutes before',
                                                        value: '30_min',
                                                    },
                                                    {
                                                        type: '1 hour before',
                                                        value: '1_hour',
                                                    },
                                                    {
                                                        type: '2 hours before',
                                                        value: '2_hours',
                                                    },
                                                    {
                                                        type: '6 hours before',
                                                        value: '6_hours',
                                                    },
                                                    {
                                                        type: '1 day before',
                                                        value: '1_day',
                                                    },
                                                    {
                                                        type: '2 days before',
                                                        value: '2_days',
                                                    },
                                                    {
                                                        type: 'None',
                                                        value: 'none',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                        <FormItem name="rsvp_allowed" label="Allow online registration">
                                            <Radio.Group name="rsvp_allowed">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem name="show_player_skill_levels" label="Show player skill levels in the bracket">
                                            <Radio.Group name="show_player_skill_levels">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem name="show_player_races" label="Show player races in the bracket">
                                            <Radio.Group name="show_player_races">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>

                                        <FormItem name="show_unconfirmed_players" label="Show unconfirmed players in public player list">
                                            <Radio.Group name="show_unconfirmed_players">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </FormItem>

                                        {values && values.tournament_format === 'fargo_rated' && (
                                            <React.Fragment>
                                                <FormItem name="winners_race_to">
                                                    <Input name="winners_race_to" value={0} type="hidden" />
                                                </FormItem>
                                                <FormItem name="losers_race_to">
                                                    <Input name="losers_race_to" value={0} type="hidden" />
                                                </FormItem>
                                            </React.Fragment>
                                        )}

                                        {authState && authState.user && authState.user.role === 'admin' && (
                                            <FormItem name="livestream_split_url" label="Livestream Split URL" hasFeedback showValidateSuccess>
                                                <Input
                                                    name="livestream_split_url"
                                                    placeholder="https://www.domain.com"
                                                    size="large"
                                                    onChange={(event) => {
                                                        handleFormFieldUpdates(event);
                                                    }}
                                                />
                                            </FormItem>
                                        )}

                                        {/* <FormItem
											wrapperCol={{
												xs: { span: 24, offset: 0 },
												sm: { span: 16, offset: 8 }
											}}
										> */}
                                    </Panel>
                                </Collapse>
                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    // loading={loading}
                                >
                                    Save and go to step 2
                                </SubmitButton>
                                <ResetButton size="large">Clear</ResetButton>
                                <div>
                                    <Text style={styles.error}>{errors.general}</Text>
                                </div>
                                <AddVenueModal
                                    {...props}
                                    modalVisible={venueModalVisible}
                                    onModalOk={handleVenueModalOk}
                                    onModalCancel={handleVenueModalCancel}
                                />
                                <AddEventModal
                                    {...props}
                                    modalVisible={eventModalVisible}
                                    onModalOk={handleEventModalOk}
                                    onModalCancel={handleEventModalCancel}
                                />
                                {/* <ChipAmountModal
                                    {...props}
                                    tournament={tournament}
                                    tournamentID={tournament && tournament.id}
                                    modalVisible={chipModalVisible}
                                    onModalOk={handleChipModalOk}
                                    onModalCancel={handleChipModalCancel}
                                    onSave={handleSaveChips}
                                /> */}
                                {/* <FormikDebug /> */}
                            </Form>
                        );
                    }}
                />
            </div>

            <TieredEntryFeeModal
                {...props}
                tournament={tournament}
                tournamentID={tournament && tournament.id}
                modalVisible={tieredEntryFeeModalVisible}
                onModalOk={handleTieredEntryFeeModalOk}
                onModalCancel={handleTieredEntryFeeModalCancel}
                onSave={handleSaveTieredEntries}
            />
        </div>
    );
}

export default withRouter(TBCreateTournamentFormStages);
