import { message, Modal, Button, Upload, Icon, Typography } from 'antd';
import { Form, FormItem, Input, InputNumber, Radio, Select, SubmitButton, ResetButto, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage, validateYupSchema } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import CircularLoader from '../../components/CircularLoader';
import { GET_TOURNAMENT_QUERY, FILTER_TOURNAMENT_PLAYERS, GET_PAYOUTS_ENTRY, GET_PAYOUTS } from './data/queries';
import { INSERT_TOURNAMENT_PAYOUTS, UPDATE_TOURNAMENT_PAYOUT } from './data/mutations';
import * as Yup from 'yup';
import { useQuery } from '@apollo/react-hooks';
import { Query, Mutation } from '@apollo/client/react/components';
import { DollarCircleFilled, TrophyFilled } from '@ant-design/icons';

const styles = {};

function AddPlayerToPayoutModal(props) {
    const { modalVisible, payouts, onModalOk, refetch, onModalCancel, client, tournamentID, selectedPayoutsId, refetchQuery } = props;

    const formRef = useRef();
    const [acDataSource, setacDataSource] = useState();
    const { Option } = Select;

    console.log(payouts);

    async function queryDB(name) {
        var dataSet = [];
        const res = await client.query({
            query: FILTER_TOURNAMENT_PLAYERS,
            variables: {
                name: `%${name}%`,
                tournament_id: tournamentID,
            },
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            const { id, name } = player;

            dataSet.push({
                id,
                name,
            });
        }

        setacDataSource(dataSet);
    }

    function cancelSubmit(e) {
        onModalCancel();
    }

    function onSearch(name) {
        queryDB(name);
    }

    function editPayout(values) {
        console.log(values);
        onModalOk();
    }

    const schema = Yup.object().shape({
        place: Yup.string().matches(/([0-9+]|[0-9]+\-[0-9]+)/, 'Single number or number ranges are allowed: eg 1 or 5-10'),
    });

    // console.log("daaatta", data);

    // const initialValues = selectedPayoutsId
    // 	? {
    // 			place: payout && payout.place,
    // 			name: payout && payout.player[0] ? payout.player[0].name : '',
    // 			payout: payout && payout.money
    // 		}
    //     :
    //     {
    //         place: '',
    //         name: '',
    //         payout: ''
    //     }

    const initialValues = {};

    function editPayout(values) {
        console.log(values);
        onModalOk();
    }

    function handleSubmit(values, createPayout, updatePayout) {
        if (selectedPayoutsId) {
            updatePayout({
                variables: {
                    id: selectedPayoutsId,
                    changes: {
                        money: parseFloat(values.payout),
                        place: values.place,
                        calcutta: parseFloat(values.calcutta),
                        percent: parseFloat(values.percent),
                        tournament_player: Number.isInteger(parseInt(values.name)) ? parseInt(values.name) : parseInt(values.player_id),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: {
                            id: parseInt(tournamentID),
                        },
                    },
                    {
                        query: GET_PAYOUTS,
                        variables: {
                            tournament_id: tournamentID,
                        },
                    },
                    // {
                    // 	query: GET_PAYOUTS_ENTRY,
                    // 	variables: {
                    // 		id: selectedPayoutsId
                    // 	}
                    // }
                ],
            });
        } else {
            createPayout({
                variables: {
                    objects: [
                        {
                            tournament_id: parseInt(tournamentID),
                            money: parseFloat(values.payout),
                            place: values.place,
                            calcutta: parseFloat(values.calcutta),
                            percent: parseFloat(values.percent),
                            tournament_player: parseInt(values.name),
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: {
                            id: parseInt(tournamentID),
                        },
                    },
                    {
                        query: GET_PAYOUTS,
                        variables: {
                            tournament_id: tournamentID,
                        },
                    },
                    // {
                    // 	query: GET_PAYOUTS_ENTRY,
                    // 	variables: {
                    // 		id: selectedPayoutsId
                    // 	}
                    // }
                ],
            });
        }
    }

    function onChangePercent(event) {
        const percent = parseFloat(event.target.value);

        if (percent) {
            const newAmount = (percent / 100) * payouts;
            formRef.current.setValues({
                ...formRef.current.state.values,
                percent: percent,
                payout: newAmount.toFixed(2),
            });
        }
    }

    function onChangePayout(event) {
        const payout = parseFloat(event.target.value);

        if (payout) {
            const newAmount = (payout / payouts) * 100;
            formRef.current.setValues({
                ...formRef.current.state.values,
                percent: newAmount.toFixed(2),
                payout: payout,
            });
        }
    }

    const payoutMutations = (data, loading, error) => {
        if (loading) return <CircularLoader />;
        if (error) return <div style={styles.container}>Error: {error.message}</div>;

        return (
            <Mutation
                mutation={INSERT_TOURNAMENT_PAYOUTS}
                onCompleted={(data) => {
                    console.log(data);
                    // setLoading(false);
                    message.success('Payout Saved');
                    // const result = data.insert_venues.returning[0];
                    // props.history.push(`/tournament-builder/${props.tournament.slug}/venues/${result.slug}`);
                    onModalOk();
                }}
                awaitRefetchQueries
            >
                {(createPayout, { error }) => (
                    <Mutation
                        mutation={UPDATE_TOURNAMENT_PAYOUT}
                        onCompleted={(data) => {
                            console.log(data);
                            // setLoading(false);
                            message.success('Payout updated');
                            // const result = data.update_venues.returning[0];
                            // props.history.push(`/tournament-builder/${props.tournament.slug}/payouts`);
                            onModalOk();
                        }}
                        awaitRefetchQueries
                    >
                        {(updatePayout, { error }) => {
                            const payout = data && data.tournament_payouts && data.tournament_payouts.length > 0 ? data.tournament_payouts[0] : null;

                            const initialValues = selectedPayoutsId
                                ? {
                                      place: payout && payout.place,
                                      name: payout && payout.player[0] ? payout.player[0].name : '',
                                      payout: payout && payout.money,
                                      calcutta: payout && payout.calcutta,
                                      percent: payout && payout.percent,
                                      player_id: payout && payout.tournament_player,
                                  }
                                : {
                                      place: '',
                                      name: '',
                                      payout: '',
                                      calcutta: '',
                                      percent: '',
                                      player_id: '',
                                  };
                            return (
                                <React.Fragment>
                                    {!loading && !error && (
                                        <Formik
                                            ref={formRef}
                                            validationSchema={schema}
                                            onSubmit={(values, actions) => {
                                                handleSubmit(values, createPayout, updatePayout);
                                                actions.resetForm();
                                            }}
                                            initialValues={initialValues}
                                            enableReinitialize
                                            render={({ errors, touched, values }) => (
                                                <Modal
                                                    title="Add Player Payout"
                                                    visible={modalVisible}
                                                    onOk={onModalOk}
                                                    onCancel={onModalCancel}
                                                    transitionName="fade"
                                                    maskTransitionName="none"
                                                    validationSchema={schema}
                                                    footer={[
                                                        <Button key="cancel" onClick={cancelSubmit}>
                                                            Cancel
                                                        </Button>,
                                                        <Button
                                                            key="submit"
                                                            type="primary"
                                                            onClick={(e) => {
                                                                if (formRef.current) {
                                                                    formRef.current.handleSubmit();
                                                                }
                                                            }}
                                                        >
                                                            {selectedPayoutsId === 0 ? `Add` : `Save`} Payout
                                                        </Button>,
                                                    ]}
                                                    destroyOnClose={true}
                                                >
                                                    <Form
                                                        layout="vertical"
                                                        style={{ maxWidth: 600 }}
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            handleSubmit(values, createPayout, updatePayout);
                                                        }}
                                                    >
                                                        <FormItem label="Place" name="place" hasFeedback showValidateSuccess>
                                                            <Input addonBefore={<TrophyFilled />} name="place" placeholder="Place" size="large" autoFocus />
                                                            <FormItem label="Payout" name="payout" hasFeedback showValidateSuccess onChange={onChangePayout}>
                                                                <Input addonBefore={<DollarCircleFilled />} name="payout" placeholder="0.00" size="large" />
                                                            </FormItem>
                                                            <FormItem label="Auction" name="calcutta" hasFeedback showValidateSuccess>
                                                                <Input addonBefore={<DollarCircleFilled />} name="calcutta" placeholder="0.00" size="large" />
                                                            </FormItem>
                                                            <FormItem label="Percent" name="percent" hasFeedback showValidateSuccess onChange={onChangePercent}>
                                                                <InputNumber name="percent" placeholder="0.0" size="large" />
                                                            </FormItem>
                                                            <FormItem name="player_id" label="" style={{ display: 'none' }}>
                                                                <Input type="text" name="player_id" />
                                                            </FormItem>
                                                        </FormItem>
                                                    </Form>
                                                </Modal>
                                            )}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        );
    };

    return (
        <React.Fragment>
            {selectedPayoutsId ? (
                <Query
                    query={GET_PAYOUTS_ENTRY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{ id: selectedPayoutsId }}
                    onCompleted={async (data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => payoutMutations(data, loading, error)}
                </Query>
            ) : (
                payoutMutations()
            )}
        </React.Fragment>
    );
}

export default AddPlayerToPayoutModal;
