import React, { useState, useEffect } from 'react';
import { geolocated } from 'react-geolocated';
import Geocode from 'react-geocode';
import { Typography } from 'antd';

const { Text } = Typography;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey('AIzaSyAP2U62p52eLfY5Lji3MlX1CggQXHpcUXU');

// Enable or disable logs. Its optional.
// Geocode.enableDebug();

function GeoLocate(props) {
	const [ address, setAddress ] = useState(null);
	const [ location, setLocation ] = useState(null);
	const { coords } = props;

	useEffect(
		() => {
			const fetchGeodata = async () => {
				// Get address from latidude & longitude.
				const { latitude, longitude } = coords;
				const result = await Geocode.fromLatLng(latitude, longitude).then(
					(response) => {
						const address = response.results[0].formatted_address;
						const city = response.results[0].address_components[2].long_name;
						const state = response.results[0].address_components[4].short_name;

						const postalCode =
							response.results[0].address_components[response.results[0].address_components.length - 1]
								.long_name;

						return {
							address,
							postalCode,
							city,
							state
						};
					},
					(error) => {
						console.error(error);
						return error;
					}
				);
				setAddress(result);
				props.onFromLatLng(result);
			};
			if (coords) {
				fetchGeodata();
			}
		},
		[ coords ]
	);

	// useEffect(() => {
	// 	const fetchGeodata = async () => {
	// 		if (props.coords) {
	// 			// Get latidude & longitude from address.
	// 			const result = await Geocode.fromAddress('Eiffel Tower').then(
	// 				(response) => {
	// 					const { lat, lng } = response.results[0].geometry.location;
	// 					return {
	// 						lat,
	// 						lng
	// 					}
	// 				},
	// 				(error) => {
	// 					console.error(error);
	// 				}
	// 			);
	// 			const {lat, lng} = result;
	// 			setLocation({ lat, lng });
	// 				props.onFromAddress({
	// 					lat,
	// 					lng
	// 				});
	// 		}
	// 	}
	// 	fetchGeodata();
	// }, [address]);

	if (props.renderOutput === false) {
		return null;
	}

	return !props.isGeolocationAvailable ? (
		<div>Your browser does not support Geolocation</div>
	) : !props.isGeolocationEnabled ? (
		<div>Geolocation is not enabled</div>
	) : props.coords ? (
		<table>
			<tbody>
				<tr>
					<td>Latitude</td>
					<td>{props.coords.latitude}</td>
				</tr>
				<tr>
					<td>Longitude</td>
					<td>{props.coords.longitude}</td>
				</tr>
				<tr>
					<td>Altitude</td>
					<td>{props.coords.altitude}</td>
				</tr>
				<tr>
					<td>Heading</td>
					<td>{props.coords.heading}</td>
				</tr>
				<tr>
					<td>Speed</td>
					<td>{props.coords.speed}</td>
				</tr>
				<tr>
					<td>Accuracy</td>
					<td>{props.coords.accuracy}</td>
				</tr>
				<tr>
					<td>Address</td>
					<td>{JSON.stringify(address)}</td>
				</tr>
				<tr>
					<td>Location</td>
					<td>{location && `lat:${location.lat} lng:${location.lng}`}</td>
				</tr>
			</tbody>
		</table>
	) : (
		<div>Getting the location data&hellip; </div>
	);
}

const MainWithGeoloc = geolocated({
	positionOptions: {
		enableHighAccuracy: false,
		maximumAge: 0,
		timeout: Infinity
	},
	userDecisionTimeout: 5000,
	watchPosition: false,
	suppressLocationOnMount: false,
	geolocationProvider: navigator.geolocation,
	isOptimisticGeolocationEnabled: true
})(GeoLocate);

export default MainWithGeoloc;
