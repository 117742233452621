import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

import MatchScore from '../../../components/matches/MatchScore';
import TPA from '../../../components/matches/TPA';
import PlayerName from '../../../components/matches/PlayerName';
import PlayerPoints from '../../../components/matches/PlayerPoints';
import ScoringFooter from '../../../components/matches/ScoringHeader';
import BallsMade from '../../../components/matches/BallsMade';

const { Title, Text } = Typography;

export default function StreamOverlayLayout2(props) {
    const { ballsMade, matchData, params, stats } = props;
    const ballSize = params.ball_size || 28;

    function showBallCount() {
        return params.show_ball_count === 'true' || matchData.game_type === 'ONE_POCKET' || matchData.game_type === 'STRAIGHT_POOL';
    }

    function showBallTracker() {
        return params.show_balls !== 'false';
    }

    return (
        <div
            style={{
                minWidth: 300,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    color: '#fff',
                }}
            >
                <div
                    style={{
                        background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                        display: 'flex',
                        width: '100%',
                        borderBottom: '1px solid rgba(0,0,0,0.2)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            padding: 5,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <PlayerName player_name={matchData.player_name} country={matchData.player_country} player_fargo={matchData.player_fargo} />
                        <TPA player_stats={stats} player_tpa={stats.playerTPA} />
                    </div>
                    <PlayerPoints show_ball_count={showBallCount()} player_points={matchData.player_points} />

                    <MatchScore
                        player_score={matchData.player_score}
                        opponent_score={matchData.opponent_score}
                        race_to={matchData.race_to}
                        highlight_on_hill={true}
                    />
                </div>
                <div
                    style={{
                        background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                        display: 'flex',
                        width: '100%',
                        borderBottom: '1px solid rgba(0,0,0,0.4)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            padding: 5,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <PlayerName player_name={matchData.opponent_name} country={matchData.opponent_country} player_fargo={matchData.opponent_fargo} />
                        <TPA player_stats={stats} player_tpa={stats.opponentTPA} />
                    </div>
                    <PlayerPoints show_ball_count={showBallCount()} player_points={matchData.opponent_points} />

                    <MatchScore
                        player_score={matchData.opponent_score}
                        opponent_score={matchData.player_score}
                        race_to={matchData.race_to}
                        highlight_on_hill={true}
                    />
                </div>
                {showBallCount() && <ScoringFooter show_ball_count={params.show_ball_count} race_to={matchData.race_to}></ScoringFooter>}
            </div>
            {showBallTracker() && <BallsMade ball_size={ballSize} balls_made={ballsMade} game_type={matchData.game_type} />}
        </div>
    );
}
