import _ from 'lodash';

export const displayEnumType = (text) => {
    let combined = '';
    if (text && text.indexOf('_') !== -1) {
        const textSplitUnderscore = text.split('_');
        textSplitUnderscore.forEach((part) => {
            combined += `${_.capitalize(part)} `;
        });
    } else if (text && text.indexOf('-') !== -1) {
        const textSplitDashed = text.split('-');
        textSplitDashed.forEach((part) => {
            combined += `${_.capitalize(part)} `;
        });
    } else {
        combined = _.capitalize(text);
    }
    return combined;

    // const textSplit = text.split('_');
    // // const prefix = _.capitalize(textSplit[0]);
    // // const suffix = _.capitalize(textSplit[1]);
    // let combined = '';
    // textSplit.forEach((part) => {
    // 	combined += `${_.capitalize(part)} `;
    // });
    // return combined;
};

export function stringSorter(a, b) {
    return a && a.localeCompare(b);
}
