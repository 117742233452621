import React, { useState, useRef } from 'react';
import { Table, Avatar, Tooltip, Divider, Icon, Typography, Progress, Button, Modal, Input } from 'antd';
import moment from 'moment';
import { Line, Circle } from 'rc-progress';
import { getGameType } from '../../utils';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

const { Text } = Typography;
const { confirm } = Modal;
const defaultWidth = 8;

export default function TournamentTable(props) {
    const { tournament, authState, theme } = props;
    const { onSelected, onEditSelected, onDelete } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    function onSelectChange(selectedKeys) {
        // console.log('selectedRowKeys changed: ', selectedKeys);
        setSelectedKeys(selectedKeys);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const rowSelection = {
        selectedKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',

            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record.game_type && (
                            <Avatar size={24} src={getGameType(record.game_type, theme)} style={{ display: 'inline-block', marginRight: 8 }} />
                        )}
                        <Text>{text}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.created_at - b.created_at,
            render: (text, record) => <span>{moment(text).format('ll')}</span>,
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            sorter: (a, b) => a.progress - b.progress,
            render: (text, record) => {
                const progress = parseInt(text);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Progress
                            percent={progress || 0}
                            status={progress < 100 ? 'active' : progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status && a.status.localeCompare(b.status),
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Size',
            dataIndex: 'max_players',
            width: 80,
            sorter: (a, b) => a.max_players - b.max_players,
            render: (text, record) => {
                return <Text>{text} Players</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'tournament_type',
            sorter: (a, b) => a.tournament_type - b.tournament_type,
        },
        {
            title: 'Game Type',
            dataIndex: 'game_type',
            sorter: (a, b) => a.game_type - b.game_type,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            render: (text, record) => {
                return (
                    <div>
                        <Text>{record.venue && record.venue.name}</Text>
                        <br />
                        <Text>
                            {record.venue && record.venue.city}, {record.venue && record.venue.region}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Action',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => (
                <React.Fragment>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="View Bracket">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.history.push(`/tournament-builder/${record.slug}/bracket`);
                                }}
                            >
                                <Icon type="apartment" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.history.push(`/tournament-builder/${record.slug}/edit`);
                                }}
                            >
                                <Icon type="edit" />
                            </Button>
                        </Tooltip>
                        <Tooltip title={record.organizer_id !== authState.user.id ? 'No access to delete' : 'Delete'}>
                            <Button
                                type="danger"
                                ghost
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showConfirmDelete(record);
                                }}
                                disabled={record.organizer_id !== authState.user.id}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip>
                    </span>
                </React.Fragment>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to delete ${record.name}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            // rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        console.log(record, rowIndex);
                        props.history.push({
                            pathname: `/tournament-builder/${record.slug}`,
                            // search: '?query=abc',
                            state: { data: record },
                        });
                    }, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns}
            dataSource={props.tournaments}
            onChange={onChange}
            tableLayout="auto"
            scroll={{ x: 400 }}
            // size="middle"
            // bordered
        />
    );
}
