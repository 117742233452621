import React, { useState } from 'react';
import { message, Modal, Tooltip, Tag, Button, Icon } from 'antd';

export default function VideoOverlay(props) {
    const { match, video, modalVisible, onOk, onCancel, theme } = props;
    const windowHeight = window.innerHeight - 220;

    return (
        <React.Fragment>
            {video && (
                <Modal
                    title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                ${match && match.name} ${match && match.pool_table && match.pool_table.label ? `- ${match.pool_table.label}` : ''}
                            </div>

                            {match && match.status === 'COMPLETED' ? (
                                <div style={{ marginRight: 30 }}>
                                    <Icon type="play-circle" theme="filled" style={{ marginRight: 5, color: '#ff7529' }} /> Recording
                                </div>
                            ) : (
                                <div style={{ marginRight: 30 }}>
                                    <Icon type="play-circle" theme="filled" style={{ marginRight: 5, color: '#f5212d' }} /> Live stream
                                </div>
                            )}
                        </div>
                    }
                    visible={modalVisible}
                    onOk={onOk}
                    onCancel={onCancel}
                    centered
                    transitionName="fade"
                    okText="Copy"
                    // bodyStyle={{ padding: 0 }}
                    maskTransitionName="none"
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={onCancel}>
                            Close
                        </Button>,
                    ]}
                    width="100%"
                >
                    <div style={{ height: '100%' }}>
                        <iframe
                            src={video}
                            border="none"
                            name={match && match.name}
                            scrolling="yes"
                            frameBorder="0"
                            marginHeight="0px"
                            marginWidth="0px"
                            height={windowHeight + 'px'}
                            width="100%"
                            allowFullScreen
                            title={match && match.name}
                        />
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
}
