import React from 'react';
import { Avatar, Typography, Row, Col } from 'antd';
import dpIcon from '../assets/img/dp-icon.png';
import dplIcon from '../assets/img/dpl-icon.png';
import leagueManagerIcon from '../assets/img/league-manager-icon.png';
import tournamentBuilderIcon from '../assets/img/tournament-builder-icon.png';
import tourBuilderIcon from '../assets/img/tour-builder-icon.png';
import poolLiveIcon from '../assets/img/pool-live-icon.png';
import blueBg from '../assets/img/blue-bg.png';
import poolHall from '../assets/img/pool-hall9.jpg';
import poolHall2 from '../assets/img/pool-hall10.jpg';
import poolHall3 from '../assets/img/pool-hall15.jpg';
import _ from 'lodash';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../utils/defaultBreakpoints';

const { Text, Title } = Typography;

function SectionHeader(props) {
    const { width, breadCrumb, wrapButtons = false } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const isMobile = breakpoint === 'mobile';
    // const defaultHeight = isMobile ? 80 : 116;
    const defaultHeight = 80;

    const styles = {
        tab: {
            // backgroundColor: '#fff',
            padding: '10px 30px',
        },
        header: {
            height: props.height || defaultHeight,
            backgroundColor: '#fff',
            // overflow: 'hidden',
            borderRadius: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: isMobile ? 20 : 40,
            paddingRight: isMobile ? 20 : 40,
            position: 'relative',
            // backgroundPosition: '0px -40px'
        },
        headerImg: {
            height: props.height || defaultHeight,
            // overflow: 'hidden',
            borderRadius: 0,
            // background: `#0063c5`,
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
            // background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: isMobile ? 20 : 40,
            paddingRight: isMobile ? 20 : 40,
            position: 'relative',
            // backgroundPosition: '0px -40px'
        },
        headerImgLeagueManager: {
            height: props.height || defaultHeight,
            // overflow: 'hidden',
            borderRadius: 0,
            background: `linear-gradient(180deg, rgba(0,0,0, 0.4), rgba(0,0,0, 0.1)), url(${poolHall})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            // backgroundSize: '100% 100%',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: isMobile ? 20 : 40,
            paddingRight: isMobile ? 20 : 40,
            backgroundPosition: '0px 0px',
            position: 'relative',
        },
        headerImgTournamentBuilder: {
            height: props.height || defaultHeight,
            // overflow: 'hidden',
            borderRadius: 0,
            background: `linear-gradient(90deg, rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), url(${poolHall2})`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            // backgroundSize: '100% 100%',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: isMobile ? 20 : 40,
            paddingRight: isMobile ? 20 : 40,
            backgroundPosition: 'top center',
            position: 'relative',
        },
        headerTitleText: {
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.35,
            letterSpacing: 8,
        },
    };

    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');

    const isActivePath = (link) => {
        const urlPath = window.location.pathname;
        return urlPath === link;
    };

    return (
        <div
            className="section-header"
            style={
                props.style
                    ? props.style
                    : props.background !== false
                    ? props.theme === 'tournamentBuilder'
                        ? styles.headerImgTournamentBuilder
                        : props.theme === 'leagueManager'
                        ? styles.headerImgLeagueManager
                        : styles.headerImg
                    : styles.header
            }
        >
            <Row
                gutter={24}
                type="flex"
                justify="space-between"
                style={{ alignItems: 'center', width: '100%', height: isMobile ? '100%' : props.height || defaultHeight }}
            >
                <Col xs={wrapButtons !== false || !props.children ? 24 : 16} sm={16}>
                    {breadCrumb && <div>{breadCrumb}</div>}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {(isActivePath('/dpl') ||
                            isActivePath('/league-manager') ||
                            isActivePath('/tournament-builder') ||
                            isActivePath('/tour-builder') ||
                            isActivePath('/pool-live')) && (
                            <div style={{ marginRight: 16 }}>
                                <Avatar
                                    size={56}
                                    style={{ backgroundColor: 'transparent' }}
                                    src={
                                        isActivePath('/dpl')
                                            ? dplIcon
                                            : isActivePath('/league-manager')
                                            ? leagueManagerIcon
                                            : isActivePath('/tournament-builder')
                                            ? tournamentBuilderIcon
                                            : isActivePath('/tour-builder')
                                            ? tourBuilderIcon
                                            : isActivePath('/pool-live')
                                            ? poolLiveIcon
                                            : null
                                    }
                                />
                            </div>
                        )}

                        {props.backButton && <div style={{ marginRight: 14 }}>{props.backButton}</div>}

                        <div style={{ marginTop: 5, marginBottom: 5, position: 'relative', display: 'flex', alignItems: 'center' }}>
                            {props.avatar && (
                                <div style={{ marginRight: 10 }}>
                                    <Avatar src={props.avatar} size={64} />
                                </div>
                            )}
                            <div>
                                {props.subtitle && props.subtitlePrefix === true && (
                                    <div style={props.subtitleStyle ? props.subtitleStyle : { color: props.theme === 'dark' ? '#fff' : '#000' }}>
                                        {props.subtitle}
                                    </div>
                                )}

                                {props.compact ? (
                                    <React.Fragment>
                                        {props.titleExtra ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Title
                                                    style={
                                                        props.titleStyle
                                                            ? { ...styles.headerTitleText, ...props.titleStyle }
                                                            : {
                                                                  ...styles.headerTitleText,
                                                                  textTransform: 'uppercase',
                                                                  // fontWeight: 600,
                                                                  marginTop: 0,
                                                                  marginBottom: 0,
                                                                  marginLeft: 0,
                                                                  marginRight: 0,
                                                                  color: props.theme === 'dark' ? '#fff' : '#000',
                                                              }
                                                    }
                                                >
                                                    {props.title}
                                                </Title>
                                                <div style={{ marginLeft: 10 }}>{props.titleExtra}</div>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <Title
                                                    level={1}
                                                    style={
                                                        props.titleStyle
                                                            ? { ...styles.headerTitleText, ...props.titleStyle }
                                                            : {
                                                                  ...styles.headerTitleText,
                                                                  textTransform: 'uppercase',
                                                                  // fontWeight: 600,
                                                                  marginTop: 0,
                                                                  marginBottom: 0,
                                                                  marginLeft: 0,
                                                                  marginRight: 24,
                                                                  color: props.theme === 'dark' ? '#fff' : '#000',
                                                              }
                                                    }
                                                >
                                                    {props.title}
                                                </Title>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {props.titleExtra ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Title
                                                    level={1}
                                                    style={
                                                        props.titleStyle
                                                            ? { ...styles.headerTitleText, ...props.titleStyle }
                                                            : {
                                                                  ...styles.headerTitleText,
                                                                  textTransform: 'uppercase',
                                                                  marginTop: 0,
                                                                  marginBottom: 0,
                                                                  marginLeft: 0,
                                                                  marginRight: 0,
                                                              }
                                                    }
                                                >
                                                    {props.title}
                                                </Title>
                                                <div style={{ marginLeft: 10 }}>{props.titleExtra}</div>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <Title
                                                    level={1}
                                                    style={
                                                        props.titleStyle
                                                            ? { ...styles.headerTitleText, ...props.titleStyle }
                                                            : {
                                                                  ...styles.headerTitleText,
                                                                  textTransform: 'uppercase',
                                                                  marginTop: 0,
                                                                  marginBottom: 0,
                                                                  marginLeft: 0,
                                                                  marginRight: 24,
                                                              }
                                                    }
                                                >
                                                    {props.title}
                                                </Title>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}

                                {props.subtitle && props.subtitlePrefix !== true && (
                                    <div style={props.subtitleStyle ? props.subtitleStyle : { color: props.theme === 'dark' ? '#fff' : '#000' }}>
                                        {props.subtitle}
                                    </div>
                                )}
                            </div>

                            {/* <PageHeader
						backButton={false}
						title={props.title}
						subTitle={props.subtitle}
					
						style={{ background: 'transparent', textTransform: 'uppercase' }}
					/> */}
                        </div>
                    </div>
                </Col>
                {props.children && (
                    <Col xs={wrapButtons !== false ? 24 : 8} sm={8} align="right">
                        {props.children}
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default SectionHeader;
