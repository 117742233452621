import { gql } from '@apollo/client';

export const UPDATE_USER_PROFILE_MUTATION = gql`
    mutation update_users($id: Int, $changes: users_set_input) {
        update_users(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                email
                phone_number
                skill_level
                email_verified
                token
                photo_url
                nickname
                display_name
                status
                fargo_id
                fargo_robustness
                fargo_readable_id
                fargo_effective_rating
                fargo_rating
                fargo_uuid
                role
                avatar
                last_seen
                last_typed
                uid
                country
                region
                city
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation update_users($id: Int, $changes: users_set_input) {
        update_users(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                country
                region
                city
                country_phone
                fargo_id
                player_id
                email
                role
                uid
                last_seen
                token
                avatar
            }
        }
    }
`;

export const CREATE_PLAYER = gql`
    mutation upsert_players($objects: [players_insert_input!]!) {
        insert_players(objects: $objects, on_conflict: { constraint: ranked_player_pkey, update_columns: [] }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_PLAYER_MUTATION = gql`
    mutation update_players($id: Int, $changes: players_set_input) {
        update_players(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_USER_MUTATION = gql`
    mutation upsert_users($objects: [users_insert_input!]!) {
        insert_users(objects: $objects, on_conflict: { constraint: users_uid_key, update_columns: [] }) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                email
                email_verified
                role
                uid
                last_seen
                token
                photo_url
                fargo_id
                player_id
            }
        }
    }
`;

export const CREATE_PLAYER_MUTATION = gql`
    mutation upsert_players($objects: [players_insert_input!]!) {
        insert_players(objects: $objects, on_conflict: { constraint: ranked_player_pkey, update_columns: [] }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UNLINK_PLAYER_MUTATION = gql`
    mutation update_users($id: Int!, $changes: users_set_input) {
        update_users(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UNLINK_USER_MUTATION = gql`
    mutation update_players($id: Int!, $changes: players_set_input) {
        update_players(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

// mutation insertUserLocation(
//   $user_location_objects: [user_location_insert_input!]!
//   $user_location_conflict_rule: user_location_on_conflict
// ) {
//   insert_user_location(
//     objects: $user_location_objects
//     on_conflict: $user_location_conflict_rule
//   ) {
//     returning{
//       location
//       user_id
//     }
//   }
// }

// mutation upsert_users($objects: [users_insert_input!]!) {
//     insert_users(objects: $objects, on_conflict: { constraint: users_uid_key, update_columns: [] }) {
//         affected_rows
//         returning {
//             id
//             first_name
//             last_name
//             slug
//             email
//             email_verified
//             role
//             uid
//             last_seen
//             token
//             photo_url
//         }
//     }
// }

export const UPDATE_USER_LOCATION_MUTATION = gql`
    mutation insert_user_location($objects: [user_location_insert_input!]!) {
        insert_user_location(objects: $objects, on_conflict: { constraint: user_location_pkey, update_columns: [location] }) {
            affected_rows
            returning {
                location
                user_id
            }
        }
    }
`;

export const CREATE_POST_MUTATION = gql`
    mutation insert_posts($objects: [posts_insert_input!]!) {
        insert_posts(objects: $objects) {
            affected_rows
            returning {
                id
                message
                slug
                user_id
                image_url
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_MUTATION = gql`
    mutation update_posts($id: Int, $changes: posts_set_input) {
        update_posts(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                message
                slug
                user_id
                image_url
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_MUTATION = gql`
    mutation delete_posts($id: Int!) {
        delete_posts(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_POST_COMMENT_MUTATION = gql`
    mutation insert_post_comments($objects: [post_comments_insert_input!]!) {
        insert_post_comments(objects: $objects) {
            affected_rows
            returning {
                id
                message
                user_id
                post_id
                parent_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_COMMENT_MUTATION = gql`
    mutation update_post_comments($id: Int, $changes: post_comments_set_input) {
        update_post_comments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                message
                user_id
                post_id
                parent_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_COMMENT_MUTATION = gql`
    mutation delete_post_comments($id: Int!) {
        delete_post_comments(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_POST_LIKE_MUTATION = gql`
    mutation insert_post_likes($objects: [post_likes_insert_input!]!) {
        insert_post_likes(objects: $objects) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_LIKE_MUTATION = gql`
    mutation update_post_likes($id: Int, $changes: post_likes_set_input) {
        update_post_likes(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_LIKE_MUTATION = gql`
    mutation delete_post_likes($post_id: Int!, $user_id: Int!) {
        delete_post_likes(where: { post_id: { _eq: $post_id }, user_id: { _eq: $user_id }, comment_id: { _is_null: true } }) {
            affected_rows
        }
    }
`;

export const CREATE_POST_COMMENT_LIKE_MUTATION = gql`
    mutation insert_post_likes($objects: [post_likes_insert_input!]!) {
        insert_post_likes(objects: $objects) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_COMMENT_LIKE_MUTATION = gql`
    mutation update_post_likes($id: Int, $changes: post_likes_set_input) {
        update_post_likes(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_COMMENT_LIKE_MUTATION = gql`
    mutation delete_post_likes($post_id: Int!, $user_id: Int!, $comment_id: Int!) {
        delete_post_likes(where: { post_id: { _eq: $post_id }, user_id: { _eq: $user_id }, comment_id: { _eq: $comment_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_FOLLOWER_MUTATION = gql`
    mutation insert_followers($objects: [followers_insert_input!]!) {
        insert_followers(objects: $objects) {
            affected_rows
            returning {
                id
                follower_user_id
                followed_user_id
                followed_date
                unfollowed_date
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_FOLLOWER_MUTATION = gql`
    mutation update_followers($id: Int, $changes: followers_set_input) {
        update_followers(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                follower_user_id
                followed_user_id
                followed_date
                unfollowed_date
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_FOLLOWER_MUTATION = gql`
    mutation delete_followers($followed_user_id: Int!, $follower_user_id: Int!) {
        delete_followers(where: { followed_user_id: { _eq: $followed_user_id }, follower_user_id: { _eq: $follower_user_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_FRIEND_REQUEST_MUTATION = gql`
    mutation insert_friend_requests($objects: [friend_requests_insert_input!]!) {
        insert_friend_requests(objects: $objects) {
            affected_rows
            returning {
                id
                from_user_id
                to_user_id
                is_accepted
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_FRIEND_REQUEST_MUTATION = gql`
    mutation update_friend_requests($to_user_id: Int!, $from_user_id: Int!, $changes: friend_requests_set_input) {
        update_friend_requests(where: { to_user_id: { _eq: $to_user_id }, from_user_id: { _eq: $from_user_id } }, _set: $changes) {
            affected_rows
            returning {
                id
                from_user_id
                to_user_id
                is_accepted
                created_at
                updated_at
            }
        }
    }
`;

export const DENY_FRIEND_REQUEST_MUTATION = gql`
    mutation update_friend_requests($from_user_id: Int!, $changes: friend_requests_set_input) {
        update_friend_requests(where: { from_user_id: { _eq: $from_user_id } }, _set: $changes) {
            affected_rows
            returning {
                id
                from_user_id
                to_user_id
                is_accepted
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_FRIEND_REQUEST_MUTATION = gql`
    mutation delete_friend_requests($to_user_id: Int!) {
        delete_friend_requests(where: { to_user_id: { _eq: $to_user_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_FRIEND_MUTATION = gql`
    mutation insert_friends($objects: [friends_insert_input!]!) {
        insert_friends(objects: $objects) {
            affected_rows
            returning {
                id
                user1_id
                user2_id
                status
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_FRIEND_MUTATION = gql`
    mutation update_friends($id: Int, $changes: friends_set_input) {
        update_friends(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                user1_id
                user2_id
                status
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_FRIEND_MUTATION = gql`
    mutation delete_friends($user_id: Int!) {
        delete_friends(where: { _or: [{ user1_id: { _eq: $user_id } }, { user2_id: { _eq: $user_id } }] }) {
            affected_rows
        }
    }
`;

export const CREATE_FRIEND_REQUEST_NOTIFICATION = gql`
    mutation insert_notifications($objects: [notifications_insert_input!]!) {
        insert_notifications(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_FRIEND_ADDED_NOTIFICATION = gql`
    mutation insert_notifications($objects: [notifications_insert_input!]!) {
        insert_notifications(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation delete_users($id: Int!) {
        delete_users(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const DELETE_PLAYER = gql`
    mutation delete_players($user_id: Int!) {
        delete_players(where: { user_id: { _eq: $user_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_PLAYER = gql`
    mutation upsert_league_players($objects: [league_players_insert_input!]!) {
        insert_league_players(objects: $objects, on_conflict: { constraint: league_players_email_league_id_key, update_columns: [] }) {
            affected_rows
            returning {
                id
                user_id
                player_id
                name
                nickname
                country
                country_phone
                gender
                email
                phone_number
                fargo_id
                skill_level
                race_to
                special_group
                status
            }
        }
    }
`;

export const UPDATE_TOURNAMENT_PLAYER_MUTATION = gql`
    mutation update_tournament_players($id: Int!, $tournament_id: Int!, $changes: tournament_players_set_input) {
        update_tournament_players(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;
