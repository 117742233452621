import React, { useState, useRef } from 'react';
import { Modal, Button, Tabs, Avatar, Icon, message } from 'antd';
import { Formik } from 'formik';
import { Field, Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import {
    SEARCH_USERS,
    GET_MATCH_MANAGERS_BY_EMAIL,
    GET_MATCH_MANAGERS_BY_USER_ID,
    GET_USER_EMAIL_BY_ID,
    GET_MATCH_ADMINS,
    GET_USER_EMAIL_BY_EMAIL,
} from './data/queries';
import generateInviteKey from '../../../lib/generateInviteKey';
import { formatPlayerName } from '../../../utils';
import { v4 as uuidv4 } from 'uuid';
import { INSERT_MATCH_MANAGERS, INSERT_MATCH_MANAGERS_INVITE_KEYS } from './data/mutations';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

const { TabPane } = Tabs;
const { Option } = Select;

const styles = {
    tab: {
        padding: 20,
    },
};

export default function AddAdminModal(props) {
    const { client, matchSlug, matchId, userId, matchName, authState, visible, onOk, onCancel, userDisplayName } = props;

    const [modalAddAdminInviteLoading, setModalAddAdminInviteLoading] = useState(false);
    const [tabKey, setTabKey] = useState('add');
    const [modalOkText, setModalOkText] = useState('Add');
    const [autoCompleteDataSource, setAutoCompleteDataSource] = useState([]);
    const [autoCompleteSelectedItem, setAutoCompleteSelectedItem] = useState();

    const formRefAdd = useRef();
    const formRefInvite = useRef();

    async function searchUsers(name) {
        setAutoCompleteDataSource([]);

        var tournamentPlayers = [];
        var users = [];

        const res2 = await client.query({
            query: SEARCH_USERS,
            variables: {
                first_name: `%${name}%`,
                last_name: `%${name}%`,
                nickname: `%${name}%`,
            },
        });

        for (let i = 0; i < res2.data.users.length; i++) {
            let user = res2.data.users[i];

            users.push({
                user_id: user.id,
                city: user.city,
                region: user.region,
                name: user.display_name ? `${user.display_name}` : `${user.first_name} ${user.last_name}`,
                key: uuidv4(),
            });
        }

        const dataSet = users.sort((a, b) => a.name.localeCompare(b.name));

        setAutoCompleteDataSource(dataSet);
    }

    function addOnModalOk() {
        formRefAdd.current.handleSubmit();
    }

    function inviteOnModalOk() {
        formRefInvite.current.handleSubmit();
    }

    function onModalOk(evt) {
        switch (tabKey) {
            case 'add':
                addOnModalOk();
                break;

            case 'invite':
                inviteOnModalOk();
                break;
        }
        onOk();
        setModalOkText('Add');
        setTabKey('add');
    }

    function onModalCancel(evt) {
        onCancel();
        setModalOkText('Add');
        setTabKey('add');
    }

    function onTabChange(key) {
        switch (key) {
            case 'add':
                setModalOkText('Add');
                setTabKey('add');
                break;

            case 'invite':
                setModalOkText('Invite');
                setTabKey('invite');
                break;
        }
    }

    function onSearch(text) {
        searchUsers(text);
    }

    function onSelect(value, option) {
        const player = autoCompleteDataSource.filter((item) => item.key === value);
        console.log(player);
        if (player) {
            setAutoCompleteSelectedItem(player[0]);
            formRefAdd.current.setValues(player[0]);
        } else {
            formRefAdd.current.setValues([]);
        }
    }

    async function insertAddData(data) {
        const { user_id } = data;

        const matchIsUser = await client.query({
            query: GET_MATCH_MANAGERS_BY_USER_ID,
            variables: {
                match_id: matchId,
                user_id: userId,
            },
        });

        if (
            matchIsUser &&
            matchIsUser.data &&
            matchIsUser.data.match_managers &&
            matchIsUser.data.match_managers[0] &&
            matchIsUser.data.match_managers[0].length > 0
        ) {
            message.warning('This user has already been invited');
            return;
        }

        try {
            const resUserEmail = await client.query({
                query: GET_USER_EMAIL_BY_ID,
                variables: {
                    id: user_id,
                },
            });

            const emailAddress = resUserEmail && resUserEmail.data && resUserEmail.data.users && resUserEmail.data.users[0] && resUserEmail.data.users[0].email;

            const res = await client.mutate({
                mutation: INSERT_MATCH_MANAGERS,
                variables: {
                    objects: {
                        user_id: user_id,
                        invite_accepted: false,
                        match_id: matchId,
                        match_slug: matchSlug,
                        email: emailAddress,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_ADMINS,
                        variables: { match_id: matchId },
                    },
                ],
            });

            const inviteKey = generateInviteKey(50);

            await client.mutate({
                mutation: INSERT_MATCH_MANAGERS_INVITE_KEYS,
                variables: {
                    objects: {
                        user_id: user_id,
                        email: emailAddress,
                        match_id: matchId,
                        key: inviteKey,
                        slug: matchSlug,
                    },
                },
            });

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            const sendInvite = await httpsCallable(functions, 'matchAdminInvite');

            sendInvite({
                to_email: emailAddress,
                is_user: true,
                from_name: userDisplayName,
                from_match_name: matchName,
                from_match_slug: matchSlug,
                invite_key: inviteKey,
                redirect_url: `/matches/${matchSlug}`,
            });
        } catch (err) {
            console.log('add admin error', err);
        }
    }

    async function insertInviteData(data) {
        const { email } = data;

        if (!email) return;

        const matchIsUser = await client.query({
            query: GET_MATCH_MANAGERS_BY_EMAIL,
            variables: {
                match_id: matchId,
                email: email,
            },
        });

        if (
            matchIsUser &&
            matchIsUser.data &&
            matchIsUser.data.match_managers &&
            matchIsUser.data.match_managers[0] &&
            matchIsUser.data.match_managers[0].length > 0
        ) {
            message.warning('This user has already been invited');
            return;
        }

        try {
            const res = await client.mutate({
                mutation: INSERT_MATCH_MANAGERS,
                variables: {
                    objects: {
                        invite_accepted: false,
                        match_id: matchId,
                        match_slug: matchSlug,
                        email: email,
                        admin: true,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_ADMINS,
                        variables: { match_id: matchId },
                    },
                ],
            });

            const inviteKey = generateInviteKey(50);

            await client.mutate({
                mutation: INSERT_MATCH_MANAGERS_INVITE_KEYS,
                variables: {
                    objects: {
                        email: email,
                        match_id: matchId,
                        key: inviteKey,
                        slug: matchSlug,
                    },
                },
            });

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            const sendInvite = await httpsCallable(functions, 'matchAdminInvite');

            sendInvite({
                to_email: email,
                is_user: false,
                from_name: userDisplayName,
                from_match_name: matchName,
                from_match_slug: matchSlug,
                invite_key: inviteKey,
                redirect_url: `/matches/${matchSlug}`,
            });
        } catch (err) {
            console.log('invite admin error', err);
        }
    }

    return (
        <Modal
            title="Add Admin"
            transitionName="fade"
            onCancel={onModalCancel}
            destroyOnClose={true}
            visible={visible}
            centered
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: 0 }}
            footer={[
                <Button key="back" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={modalAddAdminInviteLoading} onClick={onModalOk}>
                    {modalOkText}
                </Button>,
            ]}
        >
            <Tabs
                animated={{ inkBar: true, tabPane: false }}
                defaultActiveKey="add"
                onChange={onTabChange}
                tabBarStyle={{
                    textTransform: 'uppercase',
                    margin: 0,
                    borderBottom: '1px solid #ccc',
                }}
            >
                <TabPane tab="Add Admin" key="add" style={styles.tab}>
                    <Formik
                        ref={formRefAdd}
                        onSubmit={(data) => {
                            insertAddData(data);
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                            return (
                                <Form onSubmit={handleSubmit} ayout="vertical">
                                    <FormItem label="Name" name="name">
                                        <AutoComplete
                                            dataSource={autoCompleteDataSource}
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            size="large"
                                            name="name"
                                            placeholder="Search for a player name"
                                            optionFilterProp="children"
                                            optionLabelProp="value"
                                            autoFocus
                                            allowClear
                                            filterOption={(inputValue, option) => {
                                                return (
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {autoCompleteDataSource &&
                                                autoCompleteDataSource.map((item, index) => (
                                                    <Option key={item.key} value={item.key}>
                                                        {item.manager_id && (
                                                            <Avatar
                                                                size={24}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: 8,
                                                                }}
                                                                src={item.avatar}
                                                            >
                                                                <Icon type="user" />
                                                            </Avatar>
                                                        )}
                                                        {formatPlayerName(item)} {item.city && item.region && ` - ${item.city}, ${item.region}`}{' '}
                                                    </Option>
                                                ))}
                                        </AutoComplete>
                                    </FormItem>
                                </Form>
                            );
                        }}
                    />
                </TabPane>
                <TabPane tab="Invite Admin" key="invite" style={styles.tab}>
                    <Formik
                        ref={formRefInvite}
                        onSubmit={(data) => {
                            insertInviteData(data);
                        }}
                        render={({ error, touched, values }) => {
                            return (
                                <Form layout="vertical">
                                    <FormItem label="Email" name="email">
                                        <Input name="email" placeholder="Email address" size="large" />
                                    </FormItem>
                                </Form>
                            );
                        }}
                    />
                </TabPane>
            </Tabs>
        </Modal>
    );
}
