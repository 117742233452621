export const coordinatesByIdentifier = function (identifier) {
    function getOffset(el) {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    const identifierElement = document.getElementById(identifier);
    if (identifierElement) {
        const scrollingElement = document.querySelector('.scroll-container');
        var viewportOffset = identifierElement.getBoundingClientRect();
        // let absoluteCoordinates = getOffset(identifierElement);
        const newY = viewportOffset.top - 300;
        const newX = viewportOffset.left - 300;

        console.log({ x: newX, y: newY });
        const newCoords = { x: newX, y: newY };

        if (scrollingElement) {
            // const identifierRect = identifierElement.getBoundingClientRect();
            scrollingElement.scroll({
                top: newY,
                left: newX,
                behavior: 'smooth',
            });
        }

        return newCoords;
    }
};
