export function getDKHandicapRace(player1, player2, currentMatch) {
    const p1SkillLevel = player1.skill_level;
    const p1SkillLevelStr = p1SkillLevel.toString();
    const p2SkillLevel = player2.skill_level;
    const p2SkillLevelStr = p2SkillLevel.toString();
    let p1DkRating;
    let p2DkRating;
    let p1Race;
    let p2Race;

    const dkConversionChart = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    const fargoChart = [
        [300, 320],
        [321, 340],
        [341, 360],
        [361, 380],
        [381, 400],
        [401, 420],
        [421, 440],
        [441, 460],
        [461, 490],
        [491, 520],
        [521, 550],
        [551, 580],
        [581, 620],
        [621, 650],
        [651, 680],
        [681, 710],
        [711, 750],
        [751, 790],
        [791, 999],
    ];

    if (p1SkillLevelStr && p1SkillLevelStr.length > 2) {
        console.log('p1 fargo rating');
        fargoChart.forEach((ratings, index) => {
            const lowRating = ratings[0];
            const highRating = ratings[1];
            if (p1SkillLevel >= lowRating && p1SkillLevel <= highRating) {
                p1DkRating = dkConversionChart[index];
            }
        });
    } else {
        p1DkRating = p1SkillLevel;
    }

    if (p2SkillLevelStr && p2SkillLevelStr.length > 2) {
        console.log('p2 fargo rating');
        fargoChart.forEach((ratings, index) => {
            const lowRating = ratings[0];
            const highRating = ratings[1];
            if (p2SkillLevel >= lowRating && p2SkillLevel <= highRating) {
                p2DkRating = dkConversionChart[index];
            }
        });
    } else {
        p2DkRating = p2SkillLevel;
    }

    console.log({
        p1SkillLevel,
        p2SkillLevel,
        p1DkRating,
        p2DkRating,
    });

    const raceMatrix = [
        ['4-4', '4-4'],
        ['4-5', '4-4'],
        ['4-6', '4-4'],
        ['4-7', '4-4'],
        ['4-8', '5-4'],
        ['4-9', '5-4'],
        ['4-10', '5-4'],
        ['4-11', '5-4'],
        ['4-12', '6-4'],
        ['4-13', '6-4'],
        ['4-14', '7-4'],
        ['4-15', '8-4'],
        ['4-16', '8-4'],
        ['4-17', '8-3'],
        ['4-18', '8-3'],
        ['4-19', '8-3'],
        ['4-20', '8-3'],
        ['4-21', '9-2'],
        ['4-22', '9-2'],
        ['5-4', '4-4'],
        ['5-5', '4-4'],
        ['5-6', '4-4'],
        ['5-7', '4-4'],
        ['5-8', '4-4'],
        ['5-9', '5-4'],
        ['5-10', '5-4'],
        ['5-11', '5-4'],
        ['5-12', '6-4'],
        ['5-13', '6-4'],
        ['5-14', '7-4'],
        ['5-15', '8-4'],
        ['5-16', '8-4'],
        ['5-17', '8-3'],
        ['5-18', '8-3'],
        ['5-19', '8-3'],
        ['5-20', '8-3'],
        ['5-21', '9-2'],
        ['5-22', '9-2'],
        ['6-4', '4-4'],
        ['6-5', '4-4'],
        ['6-6', '4-4'],
        ['6-7', '4-4'],
        ['6-8', '4-4'],
        ['6-9', '4-4'],
        ['6-10', '5-4'],
        ['6-11', '5-4'],
        ['6-12', '6-4'],
        ['6-13', '6-4'],
        ['6-14', '7-4'],
        ['6-15', '8-4'],
        ['6-16', '8-4'],
        ['6-17', '8-3'],
        ['6-18', '8-3'],
        ['6-19', '8-3'],
        ['6-20', '8-3'],
        ['6-21', '9-2'],
        ['6-22', '9-2'],
        ['7-4', '4-4'],
        ['7-5', '4-4'],
        ['7-6', '4-4'],
        ['7-7', '4-4'],
        ['7-8', '4-4'],
        ['7-9', '4-4'],
        ['7-10', '4-4'],
        ['7-11', '5-4'],
        ['7-12', '6-4'],
        ['7-13', '6-4'],
        ['7-14', '7-4'],
        ['7-15', '8-4'],
        ['7-16', '8-4'],
        ['7-17', '8-3'],
        ['7-18', '8-3'],
        ['7-19', '8-3'],
        ['7-20', '8-3'],
        ['7-21', '9-2'],
        ['7-22', '9-2'],
        ['8-4', '5-4'],
        ['8-5', '4-4'],
        ['8-6', '4-4'],
        ['8-7', '4-4'],
        ['8-8', '4-4'],
        ['8-9', '4-4'],
        ['8-10', '4-4'],
        ['8-11', '5-4'],
        ['8-12', '6-5'],
        ['8-13', '6-4'],
        ['8-14', '7-5'],
        ['8-15', '8-4'],
        ['8-16', '8-4'],
        ['8-17', '8-3'],
        ['8-18', '8-3'],
        ['8-19', '8-3'],
        ['8-20', '8-3'],
        ['8-21', '9-2'],
        ['8-22', '9-2'],
        ['9-4', '5-4'],
        ['9-5', '5-4'],
        ['9-6', '4-4'],
        ['9-7', '4-4'],
        ['9-8', '4-4'],
        ['9-9', '4-4'],
        ['9-10', '4-4'],
        ['9-11', '5-4'],
        ['9-12', '6-5'],
        ['9-13', '6-5'],
        ['9-14', '7-5'],
        ['9-15', '8-5'],
        ['9-16', '8-4'],
        ['9-17', '8-3'],
        ['9-18', '8-3'],
        ['9-19', '8-3'],
        ['9-20', '8-3'],
        ['9-21', '9-2'],
        ['9-22', '9-2'],
        ['10-4', '5-4'],
        ['10-5', '5-4'],
        ['10-6', '5-4'],
        ['10-7', '4-4'],
        ['10-8', '4-4'],
        ['10-9', '4-4'],
        ['10-10', '5-5'],
        ['10-11', '5-5'],
        ['10-12', '6-5'],
        ['10-13', '6-5'],
        ['10-14', '7-5'],
        ['10-15', '8-5'],
        ['10-16', '8-4'],
        ['10-17', '8-3'],
        ['10-18', '8-3'],
        ['10-19', '9-4'],
        ['10-20', '8-3'],
        ['10-21', '9-2'],
        ['10-22', '9-2'],
        ['11-4', '5-4'],
        ['11-5', '5-4'],
        ['11-6', '5-4'],
        ['11-7', '5-4'],
        ['11-8', '5-4'],
        ['11-9', '5-4'],
        ['11-10', '5-5'],
        ['11-11', '5-5'],
        ['11-12', '5-5'],
        ['11-13', '6-5'],
        ['11-14', '6-5'],
        ['11-15', '7-5'],
        ['11-16', '8-5'],
        ['11-17', '8-4'],
        ['11-18', '8-3'],
        ['11-19', '9-4'],
        ['11-20', '9-4'],
        ['11-21', '9-3'],
        ['11-22', '9-2'],
        ['12-4', '6-4'],
        ['12-5', '6-4'],
        ['12-6', '6-4'],
        ['12-7', '6-4'],
        ['12-8', '6-5'],
        ['12-9', '6-5'],
        ['12-10', '6-5'],
        ['12-11', '5-5'],
        ['12-12', '5-5'],
        ['12-13', '5-5'],
        ['12-14', '6-5'],
        ['12-15', '7-5'],
        ['12-16', '8-5'],
        ['12-17', '8-4'],
        ['12-18', '8-3'],
        ['12-19', '9-4'],
        ['12-20', '9-4'],
        ['12-21', '9-3'],
        ['12-22', '9-3'],
        ['13-4', '6-4'],
        ['13-5', '6-4'],
        ['13-6', '6-4'],
        ['13-7', '6-4'],
        ['13-8', '6-4'],
        ['13-9', '6-5'],
        ['13-10', '6-5'],
        ['13-11', '6-5'],
        ['13-12', '5-5'],
        ['13-13', '6-6'],
        ['13-14', '6-6'],
        ['13-15', '7-5'],
        ['13-16', '7-5'],
        ['13-17', '8-5'],
        ['13-18', '8-4'],
        ['13-19', '9-5'],
        ['13-20', '9-4'],
        ['13-21', '9-4'],
        ['13-22', '9-3'],
        ['14-4', '7-4'],
        ['14-5', '7-4'],
        ['14-6', '7-4'],
        ['14-7', '7-4'],
        ['14-8', '7-5'],
        ['14-9', '7-5'],
        ['14-10', '7-5'],
        ['14-11', '6-5'],
        ['14-12', '6-5'],
        ['14-13', '6-6'],
        ['14-14', '6-6'],
        ['14-15', '7-6'],
        ['14-16', '7-5'],
        ['14-17', '8-5'],
        ['14-18', '8-5'],
        ['14-19', '9-5'],
        ['14-20', '9-5'],
        ['14-21', '9-4'],
        ['14-22', '9-4'],
        ['15-4', '8-4'],
        ['15-5', '8-4'],
        ['15-6', '8-4'],
        ['15-7', '8-4'],
        ['15-8', '8-4'],
        ['15-9', '8-5'],
        ['15-10', '8-5'],
        ['15-11', '7-5'],
        ['15-12', '7-5'],
        ['15-13', '7-5'],
        ['15-14', '7-6'],
        ['15-15', '6-6'],
        ['15-16', '7-6'],
        ['15-17', '8-6'],
        ['15-18', '8-5'],
        ['15-19', '9-6'],
        ['15-20', '9-5'],
        ['15-21', '9-5'],
        ['15-22', '9-4'],
        ['16-4', '8-4'],
        ['16-5', '8-4'],
        ['16-6', '8-4'],
        ['16-7', '8-4'],
        ['16-8', '8-4'],
        ['16-9', '8-4'],
        ['16-10', '8-4'],
        ['16-11', '8-5'],
        ['16-12', '8-5'],
        ['16-13', '7-5'],
        ['16-14', '7-5'],
        ['16-15', '7-6'],
        ['16-16', '7-7'],
        ['16-17', '8-7'],
        ['16-18', '8-6'],
        ['16-19', '9-6'],
        ['16-20', '9-6'],
        ['16-21', '9-5'],
        ['16-22', '9-5'],
        ['17-4', '8-3'],
        ['17-5', '8-3'],
        ['17-6', '8-3'],
        ['17-7', '8-3'],
        ['17-8', '8-3'],
        ['17-9', '8-3'],
        ['17-10', '8-3'],
        ['17-11', '8-4'],
        ['17-12', '8-4'],
        ['17-13', '8-5'],
        ['17-14', '8-5'],
        ['17-15', '8-6'],
        ['17-16', '8-7'],
        ['17-17', '7-7'],
        ['17-18', '8-7'],
        ['17-19', '8-6'],
        ['17-20', '9-6'],
        ['17-21', '9-6'],
        ['17-22', '9-5'],
        ['18-4', '8-3'],
        ['18-5', '8-3'],
        ['18-6', '8-3'],
        ['18-7', '8-3'],
        ['18-8', '8-3'],
        ['18-9', '8-3'],
        ['18-10', '8-3'],
        ['18-11', '8-3'],
        ['18-12', '8-4'],
        ['18-13', '8-4'],
        ['18-14', '8-5'],
        ['18-15', '8-5'],
        ['18-16', '8-6'],
        ['18-17', '8-7'],
        ['18-18', '7-7'],
        ['18-19', '8-7'],
        ['18-20', '8-6'],
        ['18-21', '9-6'],
        ['18-22', '9-6'],
        ['19-4', '8-3'],
        ['19-5', '8-3'],
        ['19-6', '8-3'],
        ['19-7', '8-3'],
        ['19-8', '8-3'],
        ['19-9', '8-3'],
        ['19-10', '9-4'],
        ['19-11', '9-4'],
        ['19-12', '9-4'],
        ['19-13', '9-5'],
        ['19-14', '9-5'],
        ['19-15', '9-6'],
        ['19-16', '9-6'],
        ['19-17', '8-6'],
        ['19-18', '8-7'],
        ['19-19', '8-8'],
        ['19-20', '8-7'],
        ['19-21', '8-6'],
        ['19-22', '9-6'],
        ['20-4', '8-3'],
        ['20-5', '8-3'],
        ['20-6', '8-3'],
        ['20-7', '8-3'],
        ['20-8', '8-3'],
        ['20-9', '8-3'],
        ['20-10', '8-3'],
        ['20-11', '9-4'],
        ['20-12', '9-4'],
        ['20-13', '9-4'],
        ['20-14', '9-5'],
        ['20-15', '9-5'],
        ['20-16', '9-6'],
        ['20-17', '9-6'],
        ['20-18', '8-6'],
        ['20-19', '8-7'],
        ['20-20', '8-8'],
        ['20-21', '9-8'],
        ['20-22', '9-7'],
        ['21-4', '9-2'],
        ['21-5', '9-2'],
        ['21-6', '9-2'],
        ['21-7', '9-2'],
        ['21-8', '9-2'],
        ['21-9', '9-2'],
        ['21-10', '9-2'],
        ['21-11', '9-3'],
        ['21-12', '9-3'],
        ['21-13', '9-4'],
        ['21-14', '9-4'],
        ['21-15', '9-5'],
        ['21-16', '9-5'],
        ['21-17', '9-6'],
        ['21-18', '9-6'],
        ['21-19', '8-6'],
        ['21-20', '9-8'],
        ['21-21', '9-9'],
        ['21-22', '9-8'],
        ['22-4', '9-2'],
        ['22-5', '9-2'],
        ['22-6', '9-2'],
        ['22-7', '9-2'],
        ['22-8', '9-2'],
        ['22-9', '9-2'],
        ['22-10', '9-2'],
        ['22-11', '9-3'],
        ['22-12', '9-3'],
        ['22-13', '9-3'],
        ['22-14', '9-4'],
        ['22-15', '9-4'],
        ['22-16', '9-5'],
        ['22-17', '9-5'],
        ['22-18', '9-6'],
        ['22-19', '9-6'],
        ['22-20', '9-7'],
        ['22-21', '9-8'],
        ['22-22', '9-9'],
    ];

    raceMatrix.forEach((races, index) => {
        const dkPlayerRatings = races[0];
        const dkPlayerRaces = races[1];
        const dkPlayerRatingSplit = dkPlayerRatings.split('-');
        const dkRating1 = parseInt(dkPlayerRatingSplit[0]);
        const dkRating2 = parseInt(dkPlayerRatingSplit[1]);
        const dkRaceSplit = dkPlayerRaces.split('-');
        const dkHighRace = parseInt(dkRaceSplit[0]);
        const dkLowRace = parseInt(dkRaceSplit[1]);

        if (p1DkRating === dkRating1 && p2DkRating === dkRating2) {
            if (dkRating1 >= dkRating2) {
                p1Race = dkHighRace;
                p2Race = dkLowRace;
            } else {
                p1Race = dkLowRace;
                p2Race = dkHighRace;
            }
        } else if (p1DkRating === dkRating2 && p2DkRating === dkRating1) {
            if (dkRating2 >= dkRating1) {
                p1Race = dkHighRace;
                p2Race = dkLowRace;
            } else {
                p1Race = dkLowRace;
                p2Race = dkHighRace;
            }
        }
    });

    return {
        p1Race,
        p2Race,
    };
}
