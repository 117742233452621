import React from 'react';
import { Button, Icon, Row, Col, Alert, Typography } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import StatsTable from './components/GEStats/Table';
import { ghostEventId } from './data/atoms';

const { Title } = Typography;

export default function GEStats(props) {
    const { ghostEvent } = props;

    return (
            <React.Fragment>
                <SectionWrapper>
                    <SectionHeader
                        title={props.title}
                        titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                        subtitle="Ghost Game Name"
                        subtitlePrefix={true}
                        subtitleStyle={{
                            color: 'rgba(255,255,255,0.8)',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: 12,
                            letterSpacing: 3
                        }}
                        theme="tournamentBuilder"
                    >
                    </SectionHeader>
                    <SectionContent padding="20px 40px">
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                {/* <Alert
                                    message="Helpful tips for adding players"
                                    description="You can add players individually or many at once by using the Player Import feature. If you include phone numbers, you will be able to send SMS notifications to those players during the tournament. After adding your players, head over to the bracket page to start managing your tournament. If there are any duplicate phone numers, player import will not work."
                                    type="info"
                                    closable
                                    showIcon
                                /> */}

                                <Card
                                    // title="Players"
                                    bodyStyle={{ padding: 0 }}
                                    // style={{
                                    // 	borderRadius: 8,
                                    // 	overflow: 'hidden',
                                    // 	border: 'none',
                                    // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                    // }}
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                Statistics
                                            </Title>
                                        </div>
                                    }
                                >
                                <div>
                                    <StatsTable ghostEventId={ghostEvent.id} />
                                </div>
                                </Card>
                            </Col>
                        </Row>
                    </SectionContent>
                </SectionWrapper>
            </React.Fragment>
    );
}