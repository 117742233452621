import React from 'react';
import CircleFlag from '../../../components/CircleFlag';
import { message, Alert, Icon, Row, Col, Modal, Typography, Button, Empty } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
// import { isoCountries } from '../../../utils';
import Podium from './Podium';
import Card from '../../../components/Card';
import { isoCountries } from '../../../utils/isoCountryCodes';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import ZeroState from '../../../components/ZeroState';
import PlayerTable from './PlayerTable';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYER_LIST_QUERY, GET_TOURNAMENT_QUERY } from './data/queries';
import _ from 'lodash';

const { Text, Title } = Typography;

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    cell: {
        padding: '4px 30px 4px 20px',
        fontSize: 13,
    },
};

export default function DPTournamentPlayers(props) {
    const { tournament } = props;
    console.log(tournament);

    // const columns = [
    //     {
    //         title: 'Seed',
    //         dataIndex: 'seed',
    //         key: 'seed',
    //     },
    //     {
    //         title: 'Name',
    //         dataIndex: 'name',
    //         key: 'name',
    //         render: (text, record) => {
    //             return (
    //                 <div
    //                     style={{
    //                         display: 'flex',
    //                         alignItems: 'center',
    //                     }}
    //                 >
    //                     {record.country && <CircleFlag country={record.country} left={0} />}
    //                     <span style={{ marginLeft: 10 }}>{text}</span>
    //                 </div>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Country',
    //         dataIndex: 'country',
    //         key: 'country',
    //     },
    //     {
    //         title: 'Skill Level',
    //         dataIndex: 'skill_level',
    //         key: 'skill_level',
    //         render: (text, record) => {
    //             console.log(text);
    //             return <span>{text ? text : ''}</span>;
    //         },
    //     },
    //     {
    //         title: 'Points',
    //         dataIndex: 'points',
    //         key: 'points',
    //         render: (text, record) => <span>{text ? text : 0}</span>,
    //     },
    // ];

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        {tournament.show_unconfirmed_players === true ? (
                            <Query
                                query={GET_TOURNAMENT_PLAYER_LIST_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ tournament_id: tournament.id }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.tournament_players) || (data && data.tournament_players.length === 0)) {
                                        return <ZeroState showImage message="No Players have been added yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    const tournamentPlayers =
                                        data &&
                                        data.tournament_players &&
                                        data.tournament_players.map((player) => {
                                            return {
                                                ...player,
                                                matches: [...player.tournament_matches_challenger1, ...player.tournament_matches_challenger2],
                                            };
                                        });

                                    return (
                                        <Card
                                            // title="Players"
                                            bodyStyle={{ padding: 0 }}
                                            // style={{
                                            // 	borderRadius: 8,
                                            // 	overflow: 'hidden',
                                            // 	border: 'none',
                                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                            // }}
                                            cover={
                                                <div
                                                    style={{
                                                        paddingTop: 16,
                                                        paddingBottom: 16,
                                                        paddingLeft: 24,
                                                        paddingRight: 24,
                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                        Players ({tournament.tournament_players_aggregate.aggregate.count})
                                                    </Title>
                                                </div>
                                            }
                                        >
                                            <PlayerTable
                                                {...props}
                                                tournament={tournament}
                                                players={
                                                    tournamentPlayers &&
                                                    tournamentPlayers.map((item, index) => {
                                                        if (item.user) {
                                                            return {
                                                                ...item.user,
                                                                ...item,
                                                                id: item.id,
                                                                name: `${item.user.first_name} ${item.user.last_name}`,
                                                                index: index + 1,
                                                                key: index,
                                                            };
                                                        } else {
                                                            return {
                                                                ...item,
                                                                id: item.id,
                                                                index: index + 1,
                                                                key: index,
                                                            };
                                                        }
                                                    })
                                                }
                                            />
                                        </Card>
                                    );
                                }}
                            </Query>
                        ) : (
                            <Card bodyStyle={{ padding: 0 }}>
                                <ZeroState showImage message="No players have been confirmed yet." style={{ margin: 30 }} {...props} />
                            </Card>
                        )}
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
