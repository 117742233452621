import React, { useState, useEffect, useRef } from 'react';
import { message, Icon, Row, Col, Typography, Progress, Button, Radio, Input, Tabs, Empty, Table, Modal } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import Card from '../../../components/Card';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_SCHEDULED_TABLES_QUERY } from './data/queries';
import { GET_SCHEDULED_TABLES_SUBSCRIPTION } from './data/subscriptions';

import ScheduledTables from './ScheduledTables';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default function DPTournamentScheduleTables(props) {
    const { tournament, client, theme } = props;
    const [sortBy, setSortBy] = useState('table_time');

    return (
        <Query
            query={GET_SCHEDULED_TABLES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onCompleted={(data) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.pool_tables) {
                    return <div>Tables not found.</div>;
                }
                if (data && data.pool_tables.length === 0) {
                    return <div>Tables not found.</div>;
                }

                let scheduledMatches = [];

                data.pool_tables &&
                    data.pool_tables.forEach((table) => {
                        if (table.scheduled_matches && table.scheduled_matches.length > 0) {
                            table.scheduled_matches.forEach((match) => {
                                scheduledMatches.push({
                                    ...match,
                                    pool_table: table,
                                    table_id: match.table_id || table.id,
                                    table_name: match.table_name || table.label,
                                    names: `${match.challenger1_name} ${match.challenger2_name}`,
                                });
                            });
                        }
                    });

                return (
                    <Row gutter={24} justify="center" type="flex">
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                cover={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            paddingTop: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                            // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        }}
                                    >
                                        <Title
                                            level={4}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                fontSize: 18,
                                            }}
                                        >
                                            Scheduled matches by table
                                        </Title>
                                        <div>
                                            <Text>Sort by:</Text>
                                            <Radio.Group
                                                value={sortBy}
                                                type="ghost"
                                                onChange={(e) => {
                                                    const active = e.target.value;
                                                    setSortBy(active);
                                                }}
                                                style={{ marginLeft: 10 }}
                                            >
                                                <Radio.Button value="table_time" style={sortBy === 'table_time' ? styles.selectedToggle : styles.defaultToggle}>
                                                    Table + Time
                                                </Radio.Button>
                                                <Radio.Button
                                                    value="table_priority"
                                                    style={sortBy === 'table_priority' ? styles.selectedToggle : styles.defaultToggle}
                                                >
                                                    Table + Priority
                                                </Radio.Button>
                                                <Radio.Button value="table" style={sortBy === 'table' ? styles.selectedToggle : styles.defaultToggle}>
                                                    Table
                                                </Radio.Button>
                                                <Radio.Button value="time" style={sortBy === 'time' ? styles.selectedToggle : styles.defaultToggle}>
                                                    Time
                                                </Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                }
                            >
                                <ScheduledTables
                                    sortBy={sortBy}
                                    matches={scheduledMatches}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_SCHEDULED_TABLES_SUBSCRIPTION,
                                            variables: { tournament_id: tournament.id },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newTables = subscriptionData.data.pool_tables;
                                                const updated = Object.assign({}, prev, {
                                                    pool_tables: [...newTables],
                                                });
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            </Card>
                        </Col>
                    </Row>
                );
            }}
        </Query>
    );
}
