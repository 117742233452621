import React, { useState } from 'react';
import { message, Modal, Tooltip, Button, Input, Typography, Tabs } from 'antd';
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    TwitterShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    TwitterIcon,
} from 'react-share';
import html2canvas from 'html2canvas';
import { QRCode } from 'react-qrcode-logo';
import dpLogoBw from '../../../assets/img/dp-logo-text-centered-bg.png';
// import HelmetMetaData from '../../../components/HelmetMetaData';

const { Text, Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default function EmbedModal(props) {
    const { tournament, modalVisible, onOk, onCancel } = props;
    const [activeTab, setActiveTab] = useState('iframe');

    // console.log(window.location);

    const url = window.location.href;
    // const url = 'https://www.digitalpool.com';
    const title = `${tournament.name} Tournament`;
    const hashtag = '#digitalpool';

    const logoWidth = 50 * 2;
    const logoHeight = logoWidth;

    function downloadQRCodeImage() {
        // const input = document.querySelector('#bracket-container');
        const input = document.querySelector('.qr-code');

        const key = 'pdf';
        message.loading({ content: 'Generating QR code PNG. This could take a minute...', key, duration: 0 });

        html2canvas(input, {
            dpi: 300, // Set to 300 DPI
            // scale: 3, // Adjusts your resolution
        }).then((canvas) => {
            const filename = `${tournament.slug}-qr-code.png`;
            var link = document.createElement('a');

            if (typeof link.download === 'string') {
                // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                link.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                message.success({
                    content: `${filename} downloaded`,
                    key,
                    duration: 2,
                });
            } else {
                window.open(filename);
            }
        });
    }

    return (
        <React.Fragment>
            {/* <HelmetMetaData title={tournament.name} description={tournament.description} image={tournament.flyer} /> */}
            {/* <Helmet>
                <title>{title}</title>
                <meta name="description" content={tournament.description} />
                <meta property="og:image" content={tournament.flyer} />
            </Helmet> */}

            <Modal
                title="Share this page"
                visible={modalVisible}
                onOk={onOk}
                onCancel={onCancel}
                centered
                transitionName="fade"
                okText="Copy"
                // bodyStyle={{ padding: 0 }}
                maskTransitionName="none"
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Close
                    </Button>,
                ]}
            >
                <div>
                    <EmailShareButton url={url} quote={title} hashtag={hashtag} className="email-share-button">
                        <EmailIcon size={48} round />
                    </EmailShareButton>
                    <FacebookShareButton url={url} quote={title} hashtag={hashtag} className="facebook-share-button" style={{ marginLeft: 10 }}>
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                    {/* <FacebookMessengerShareButton
                    appId="1697957547011275"
                    url={url}
                    quote={title}
                    hashtag={hashtag}
                    className="facebook-share-button"
                    style={{ marginLeft: 10 }}
                >
                    <FacebookMessengerIcon size={48} round />
                </FacebookMessengerShareButton> */}
                    <TwitterShareButton url={url} quote={title} hashtag={hashtag} className="twitter-share-button" style={{ marginLeft: 10 }}>
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>

                    <Input
                        value={url}
                        size="large"
                        addonAfter={
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    navigator.clipboard.writeText(url);
                                    message.success(`URL copied to clipboard.`);
                                }}
                            >
                                COPY
                            </Button>
                        }
                        style={{ marginTop: 10 }}
                    />
                </div>
                <div>
                    <Title level={4} style={{ marginTop: 20 }}>
                        Scan QR Code
                    </Title>
                    <div className="qr-code" style={{ display: 'inline-block' }}>
                        <QRCode value={url} size={300} logoOpacity={1} ecLevel="H" logoImage={dpLogoBw} logoWidth={logoWidth} logoHeight={logoHeight} />
                    </div>
                    <br />

                    <Button
                        onClick={() => {
                            downloadQRCodeImage();
                        }}
                    >
                        Download Image
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );
}
