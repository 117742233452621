import React, { useState, useEffect } from 'react';
import { message, List, Tabs, Row, Col, Modal, Button, Icon, Tooltip, Typography, Empty, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { DigitalPoolAPI } from '../../lib/api';
import Fade from 'react-reveal/Fade';
import CircularLoader from '../../components/CircularLoader';
import ViewerMatches from './ViewerMatches';
import ViewerPlayers from './ViewerPlayers';
import useBreakpoint from 'use-breakpoint';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_SUBSCRIPTION, GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS } from './data/subscriptions';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import ZeroState from '../../components/ZeroState';
import moment from 'moment';

const { TabPane } = Tabs;
const { Search } = Input;
const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const tabHeight = 45;

function ViewerSidePanel(props) {
    const { tournament, tables, isPublic, theme, bracketHeight, sidePanelOpen, onSidePanelOpen, onShowScoreModal, onSetMatches, onSetPlayerWaiting } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [showStatusPanel, setShowStatusPanel] = useState(breakpoint === 'mobile' ? false : true);
    const [matches, setMatches] = useState([]);
    const [players, setPlayers] = useState([]);
    const [totalPlayers, setTotalPlayers] = useState();
    const [playerWaiting, setPlayerWaiting] = useState([]);
    const [playerQueue, setPlayerQueue] = useState([]);
    const [recommendedTables, setRecommendedTables] = useState();
    const API = DigitalPoolAPI(props);

    function showConfirmStart(drawRound, winnerBracket, matches) {
        confirm({
            title: 'Are you ready to start the tournament?',
            content: 'This action will start the tournament clock.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Tournament',
            cancelText: "I'll do this later",
            onOk() {
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
                // if (tables.length > 0 && drawRound[0] && drawRound[0][0] && !drawRound[0][0].challenger1_seed) {
                // 	showConfirmDraw(drawRound, winnerBracket);
                // } else if (tables.length > 0) {
                // 	startTournament(drawRound, winnerBracket);
                // } else {
                // 	message.error('You must add at least 1 table before you can start the tournament.', 10);
                // }
                if (tables.length > 0) {
                    startTournament();
                    // startTournament().then((data) => {
                    // 	generateDraw(drawRound, winnerBracket, matches, true);
                    // });

                    // showConfirmDraw(drawRound, winnerBracket);
                } else {
                    message.error('You must add at least 1 table before you can start the tournament.', 10);
                }
            },
            onCancel() {},
        });
    }

    function startTournament(callback) {
        API.updateTournament({
            start_date_time: moment.utc(),
            status: 'IN_PROGRESS',
        }).then((res) => {
            // await API.updateTournamentStatus('IN_PROGRESS');
            message.success({
                content: `Tournament clock started.`,
                duration: 2,
            });

            return res;
        });

        // const projectionConfig = {
        //     from_round: null,
        //     number_of_tables: tables.length || 1,
        //     use_tournament_actuals: false,
        //     winner_side_race_to: tournament.winner_side_race_to || 7,
        //     loser_side_race_to: tournament.loser_side_race_to || 7,
        //     average_time_per_game: 5,
        // };
        // generateMatchTimeProjections(projectionConfig);
    }

    function isPlayerInMatch(player) {
        const matchPlayer = matches
            .filter((match) => {
                return match.status === 'NOT_STARTED';
            })
            .filter((match) => {
                return match.challenger1_id === (player && player.id) || match.challenger2_id === (player && player.id);
            })[0];
        if (matchPlayer) {
            return true;
        } else {
            return false;
        }
    }

    function determineTablesNeeded(tables, players) {
        console.log('available tables', tables);

        const availableTables =
            tables &&
            tables
                .filter((table) => {
                    return table.status !== 'CLOSED';
                })
                .sort((a, b) => {
                    return a.label.localeCompare(b.label, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                });

        const playersLeft = players.filter((player) => {
            return player.is_eliminated === false;
        });

        // const playerQueue = players
        //     .filter((player) => {
        //         return player.is_eliminated === false && !player.table;
        //     })
        //     .sort((a, b) => {
        //         if (a.last_played_at && b.last_played_at) {
        //             const aTimeMs = moment(a.last_played_at).valueOf();
        //             const bTimeMs = moment(b.last_played_at).valueOf();

        //             console.log('a', aTimeMs);
        //             console.log('b', bTimeMs);
        //             return bTimeMs - aTimeMs;
        //         } else {
        //             return false;
        //         }

        //         // return a.last_played_at && a.last_played_at.localeCompare(b.last_played_at);
        //     });

        const playerTableRatio = playerQueue.length / availableTables.length;
        const recommendedTables = Math.floor(playersLeft.length / 3) || 1;
        console.log('player queue length', playerQueue.length);
        console.log('player table ratio', playerTableRatio);
        console.log('available tables', availableTables.length);
        console.log('players left', playersLeft.length);
        console.log('recommended # of tables', recommendedTables);
        const key = 'recommend';
        message.warning({ content: `We recommend using ${recommendedTables} table based on ${playersLeft.length} players left`, key, duration: 5 });

        if (playerTableRatio < 1) {
            // message.warning({ content: `We recommend using ${tableRecommendations} table based on ${playersLeft.length} players left`, key, duration: 5 });
            console.log('We recommend reducing the number of tables ');
        }
        setRecommendedTables(recommendedTables);
    }

    return (
        <React.Fragment>
            <Subscription
                subscription={GET_TOURNAMENT_BRACKET_SUBSCRIPTION}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournamentId: tournament.id }}
                onSubscriptionData={(data, loading, error) => {
                    if (!loading && !error) {
                        const { tournament_brackets } = data.subscriptionData.data;
                        setMatches(tournament_brackets);
                        onSetMatches(tournament_brackets);
                    }
                }}
            >
                {({ subscribeToMore, ...result }) => (
                    <Subscription
                        subscription={GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data, loading, error) => {
                            if (!loading && !error) {
                                const { tournament_players } = data.subscriptionData.data;
                                let players =
                                    tournament_players &&
                                    tournament_players
                                        // .filter((player) => {
                                        //     console.log(player);
                                        //     return player.is_eliminated === false;
                                        // })
                                        .map((player) => {
                                            const matchesChallenger1 = player.tournament_matches_challenger1.filter((match) => {
                                                return match.status !== 'COMPLETED';
                                            });

                                            const matchesChallenger2 = player.tournament_matches_challenger2.filter((match) => {
                                                return match.status !== 'COMPLETED';
                                            });

                                            const playerMatches = [...matchesChallenger1, ...matchesChallenger2];
                                            const player1MatchesWon =
                                                player.challenger1_matches_won &&
                                                player.challenger1_matches_won.aggregate &&
                                                player.challenger1_matches_won.aggregate.count;
                                            const player2MatchesWon =
                                                player.challenger2_matches_won &&
                                                player.challenger2_matches_won.aggregate &&
                                                player.challenger2_matches_won.aggregate.count;
                                            const matchesWon = player1MatchesWon + player2MatchesWon || 0;
                                            const player1MatchesLost =
                                                player.challenger1_matches_lost &&
                                                player.challenger1_matches_lost.aggregate &&
                                                player.challenger1_matches_lost.aggregate.count;
                                            const player2MatchesLost =
                                                player.challenger2_matches_lost &&
                                                player.challenger2_matches_lost.aggregate &&
                                                player.challenger2_matches_lost.aggregate.count;
                                            const matchesLost = player1MatchesLost + player2MatchesLost || 0;
                                            const playerMatchesSorted = playerMatches.sort((a, b) => {
                                                return b.match_number - a.match_number;
                                            });
                                            const playerTable =
                                                (playerMatches &&
                                                    playerMatchesSorted[0] &&
                                                    playerMatchesSorted[0].table_name &&
                                                    playerMatchesSorted[0].table_name) ||
                                                null;
                                            // console.log({
                                            //     player,
                                            //     playerMatchesSorted,
                                            //     playerTable,
                                            // });
                                            return {
                                                ...player,
                                                matches: playerMatchesSorted,
                                                matches_won: matchesWon,
                                                matches_lost: matchesLost,
                                                table: playerTable,
                                                is_waiting: playerTable ? false : true,
                                            };
                                        });

                                console.log(players);
                                setPlayers(players);
                                setTotalPlayers(players.length);
                                const playerQueue = players
                                    .filter((player) => {
                                        return player.is_eliminated === false && !player.table;
                                    })
                                    .sort((a, b) => {
                                        return new Date(b.last_played_at) < new Date(a.last_played_at) ? 1 : -1;
                                    });

                                let nextPlayerToAssign;
                                console.log('next player waiting', playerQueue);
                                if (playerQueue && isPlayerInMatch(playerQueue[0]) === true) {
                                    console.log(`${playerQueue[0].name} is already in match`);
                                    nextPlayerToAssign = playerQueue[1];
                                } else if (playerQueue && playerQueue[0]) {
                                    nextPlayerToAssign = playerQueue[0];
                                } else {
                                    nextPlayerToAssign = null;
                                }

                                if (nextPlayerToAssign) {
                                    console.log(`next player to assign ${nextPlayerToAssign.name}`);
                                }

                                console.log(playerQueue);

                                setPlayerWaiting(nextPlayerToAssign);
                                setPlayerQueue(playerQueue);
                                onSetPlayerWaiting(nextPlayerToAssign);
                                determineTablesNeeded(tables, players);
                            }
                        }}
                    >
                        {({ loading, error, data }) => {
                            return (
                                <React.Fragment>
                                    {(loading || result.loading) && (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                        </div>
                                    )}
                                    {!result.loading && !loading && !error && (
                                        <React.Fragment>
                                            {isPublic === true ? (
                                                <Fade up distance="40px" delay={300} duration={500}>
                                                    <div
                                                        style={{
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingTop: 7,
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                            }}
                                                        >
                                                            <Title level={4}>Players</Title>
                                                            <Button type="link" onClick={() => onSidePanelOpen(false)}>
                                                                <Icon type="close" style={{ color: theme.bracket.panels.iconColor }} />
                                                            </Button>
                                                        </div>
                                                        <ViewerPlayers
                                                            tournament={tournament}
                                                            players={players}
                                                            playerQueue={playerQueue}
                                                            matches={matches}
                                                            tables={tables}
                                                            playerWaiting={playerWaiting}
                                                            {...props}
                                                        />
                                                    </div>
                                                </Fade>
                                            ) : (
                                                <Tabs
                                                    animated={{ inkBar: true, tabPane: false }}
                                                    defaultActiveKey="matches"
                                                    // onChange={callback}
                                                    // type="card"
                                                    tabBarStyle={{
                                                        textTransform: 'uppercase',
                                                        paddingLeft: 10,
                                                        color: theme.bracket.panels.textColor,
                                                        borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                    }}
                                                    tabBarExtraContent={
                                                        <Button type="link" style={{ marginRight: 10 }} onClick={() => onSidePanelOpen(false)}>
                                                            <Icon type="close" style={{ color: theme.bracket.panels.iconColor }} />
                                                        </Button>
                                                    }
                                                >
                                                    <TabPane tab={`Matches (${matches && matches.length})`} key="matches">
                                                        <Fade up distance="40px" delay={300} duration={500}>
                                                            <div
                                                                style={{
                                                                    height: '100%',
                                                                }}
                                                            >
                                                                <ViewerMatches
                                                                    onStartTournament={() => {
                                                                        showConfirmStart();
                                                                    }}
                                                                    tournament={tournament}
                                                                    players={players}
                                                                    playerQueue={playerQueue}
                                                                    matches={matches}
                                                                    playerWaiting={playerWaiting}
                                                                    recommendedTables={recommendedTables}
                                                                    onShowScoreModal={onShowScoreModal}
                                                                    {...props}
                                                                />
                                                            </div>
                                                        </Fade>
                                                    </TabPane>
                                                    <TabPane
                                                        tab={`Players ${
                                                            tournament.tournament_players_aggregate
                                                                ? `(${tournament.tournament_players_aggregate.aggregate.count})`
                                                                : ''
                                                        }`}
                                                        key="players"
                                                    >
                                                        <Fade up distance="40px" delay={300} duration={500}>
                                                            <div
                                                                style={{
                                                                    height: '100%',
                                                                }}
                                                            >
                                                                <ViewerPlayers
                                                                    onStartTournament={() => {
                                                                        showConfirmStart();
                                                                    }}
                                                                    tournament={tournament}
                                                                    players={players}
                                                                    playerQueue={playerQueue}
                                                                    matches={matches}
                                                                    tables={tables}
                                                                    playerWaiting={playerWaiting}
                                                                    {...props}
                                                                />
                                                            </div>
                                                        </Fade>
                                                    </TabPane>
                                                </Tabs>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Subscription>
                )}
            </Subscription>
        </React.Fragment>
    );
}

export default withRouter(ViewerSidePanel);
