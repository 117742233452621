import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import RackTable from './RackTable';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';
import { GET_TOURNAMENT_MATCH_GAMES_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import _ from 'lodash';

const { Title, Text } = Typography;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
};

export default function RackTracker(props) {
    const { matchData, tournamentData, gameData } = props;

    console.log(gameData);

    if (!gameData) {
        return (
            <Subscription
                subscription={GET_TOURNAMENT_MATCH_GAMES_SUBSCRIPTION}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournamentData.id, tournament_bracket_id: matchData.id }}
                onSubscriptionData={(data, error, loading) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data, refetch }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div>Error: {error.message}</div>;
                    if (!matchData) {
                        return <div style={styles.container}>No games found.</div>;
                    }
                    const matchGames = data.tournament_match_games;
                    return <RackTable matchData={matchData} matchGames={matchGames} />;
                }}
            </Subscription>
        );
    } else {
        return <RackTable matchData={matchData} matchGames={gameData} />;
    }
}
