import React from 'react';
import { Layout } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import useTheme from './theme/useTheme';

function EmptyLayout(props, { authUser }) {
    const { component: Component, defaultTheme, ...rest } = props;
    const [theme, toggleTheme] = useTheme(defaultTheme);

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <Layout id="app-container" className={`mobile ${defaultTheme || (theme && theme.name)}`} style={{ height: '100%' }}>
                    <Component {...rest}>{props.children}</Component>
                </Layout>
            )}
        />
    );
}

export default EmptyLayout;
