import React, { useState, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Row, Col, Typography, Tag, Drawer, Empty, Modal, Button } from 'antd';
import Fullscreen from 'react-full-screen';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionContent from '../../../components/SectionContent';
import PlayerSearch from './bracket/PlayerSearch';
import useBreakpoint from 'use-breakpoint';
import qs from 'query-string';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import Bracket from './bracket/Bracket3';

const { Title, Text } = Typography;

function DPTournamentBracket(props) {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { client, tournament, authState, theme } = props;
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [playerSearchCoordinates, setPlayerSearchCoordinates] = useState({ x: 0, y: 0 });
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const isPrint = params && params.print === 'true' ? true : false;

    return (
        <React.Fragment>
            {isPrint === true ? (
                <div
                    id="bracket-container"
                    className="full-screenable-node"
                    style={{
                        position: 'relative',
                        // transform: 'scale(0.4)',
                        overflow: 'auto',
                        width: '100%',
                        height: '100%',
                        // background: 'linear-gradient(180deg, #fff, #f0f2f5)'
                        // backgroundColor: '#eaedef'
                    }}
                >
                    <Bracket
                        isPublic={true}
                        tournament={tournament}
                        isFullScreen={isFullScreen}
                        onFullScreen={(value) => setIsFullScreen(value)}
                        tablesPanel={false}
                        statusPanel={false}
                        setRoundTimes={false}
                        playerSearchCoordinates={playerSearchCoordinates}
                        onUpdateCoordinates={(coords) => {
                            setPlayerSearchCoordinates(coords);
                        }}
                        compact={false}
                        {...props}
                    />
                </div>
            ) : (
                <SectionWrapper>
                    <SectionContent padding="0px">
                        <Fullscreen enabled={isFullScreen} onChange={(isFull) => setIsFullScreen(isFull)}>
                            <div
                                id="bracket-container"
                                className="full-screenable-node"
                                style={{
                                    position: 'relative',
                                    overflow: 'auto',
                                    width: '100%',
                                    height: '100%',
                                    // background: 'linear-gradient(180deg, #fff, #f0f2f5)'
                                    // backgroundColor: '#eaedef'
                                }}
                            >
                                {breakpoint === 'mobile' && (
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: theme.bracket.panels.backgroundColor,
                                        }}
                                    >
                                        <PlayerSearch
                                            {...props}
                                            tournament={tournament}
                                            onPlayerSearch={(coords) => {
                                                setPlayerSearchCoordinates(coords);
                                            }}
                                            className={theme && theme.name === 'dark' ? 'player-search-clear' : 'player-search'}
                                            style={{ marginRight: 10, width: '100%' }}
                                        />
                                    </div>
                                )}

                                <Bracket
                                    isPublic={true}
                                    tournament={tournament}
                                    isFullScreen={isFullScreen}
                                    onFullScreen={(value) => setIsFullScreen(value)}
                                    tablesPanel={false}
                                    statusPanel={false}
                                    setRoundTimes={false}
                                    playerSearchCoordinates={playerSearchCoordinates}
                                    onUpdateCoordinates={(coords) => {
                                        setPlayerSearchCoordinates(coords);
                                    }}
                                    compact={false}
                                    {...props}
                                />
                                {/* <SizeMe monitorHeight>
													{({ size }) => {
														return (
															<Bracket
																isPublic={true}
																tournament={tournamentData}
																isFullScreen={isFullScreen}
																onFullScreen={(value) => setIsFullScreen(value)}
																tablesPanel={false}
																statusPanel={false}
																size={size}
																playerSearchCoordinates={playerSearchCoordinates}
																onUpdateCoordinates={() => {
																	console.log('test')
																}}
																{...props}
															/>
														);
													}}
												</SizeMe> */}
                            </div>
                        </Fullscreen>
                    </SectionContent>
                </SectionWrapper>
            )}
        </React.Fragment>
    );
}

export default withRouter(DPTournamentBracket);
