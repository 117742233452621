import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, DatePicker, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Modal, Button, Typography } from 'antd';
import * as Yup from 'yup';
import CircularLoader from '../../components/CircularLoader';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_TOURNAMENT_BRACKET_SEGMENT, DELETE_TOURNAMENT_BRACKET_SEGMENT } from './data/mutations';
import { GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG } from './data/queries';
import slugify from 'slugify';

const { Text } = Typography;

const styles = {
    error: { color: '#f5222d' },
};

function AddBracketSegmentModal(props) {
    const { modalVisible, onModalOk, onModalCancel, authState, template } = props;
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const slug = props.match.params.slug;

    const [createBracketSegment] = useMutation(CREATE_TOURNAMENT_BRACKET_SEGMENT);
    const [deleteBracketSegment] = useMutation(DELETE_TOURNAMENT_BRACKET_SEGMENT);

    const schema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    });

    const initialValues = {
        name: '',
    };

    function handleSubmit(values) {
        console.log(values);
        createSegment(values);
    }

    function createSegment(data) {
        const { name, identifier } = data;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });
        createBracketSegment({
            variables: {
                objects: [
                    {
                        name,
                        slug,
                        tournament_bracket_template_id: template.id,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG,
                    variables: { slug: slug },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                message.success('Segment successfully added');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    return (
        <Formik
            ref={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            validate={(values) => {
                // console.log(values);
            }}
            onSubmit={(values, actions) => {
                // console.log(values, actions);
                // actions.setSubmitting(false);
                // actions.resetForm();
                handleSubmit(values);
            }}
            render={({ errors, touched, values }) => (
                <Modal
                    title="Add Segment"
                    visible={modalVisible}
                    onOk={onModalOk}
                    onCancel={onModalCancel}
                    centered
                    transitionName="fade"
                    // transitionName="none"
                    maskTransitionName="none"
                    footer={[
                        <Button key="cancel" onClick={onModalCancel}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            disabled={Object.keys(errors).length > 0 ? true : false}
                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                            loading={loading}
                            onClick={() => {
                                if (formRef.current) {
                                    formRef.current.handleSubmit();
                                }
                            }}
                        >
                            Create Segment
                        </Button>,
                    ]}
                    bodyStyle={{
                        maxHeight: 500,
                        overflowY: 'auto',
                        padding: '10px 20px',
                    }}
                    // style={{
                    // 	left: -120
                    // }}
                    destroyOnClose={true}
                >
                    <Form
                        layout="vertical"
                        style={{ maxWidth: 600 }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(values);
                        }}
                    >
                        <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                            <Input
                                name="name"
                                placeholder="Name"
                                size="large"
                                onChange={(event) => {
                                    handleFormFieldUpdates(event);
                                }}
                            />
                        </FormItem>

                        {/* <FormItem label="Side" name="name" required hasFeedback showValidateSuccess>
                            <Input
                                name="name"
                                placeholder="Name"
                                size="large"
                                onChange={(event) => {
                                    handleFormFieldUpdates(event);
                                }}
                            />
                        </FormItem> */}

                        <div>
                            <Text style={styles.error}>{errors.general}</Text>
                        </div>
                        <div>
                            <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                        </div>
                    </Form>
                </Modal>
            )}
        />
    );
}

export default withRouter(AddBracketSegmentModal);
