import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import poweredByLogo from '../../../assets/img/powered-by-logo.png';
import CircleFlag from '../../../components/CircleFlag';
import CircleStateFlag from '../../../components/CircleStateFlag';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import RackTracker from './RackTracker';
import VideoJS from './VideoJS';

const { Text, Title } = Typography;
const { confirm } = Modal;

function MatchTVDisplay(props) {
    const { matchData, tableData, subscribeToMore, subscribeToNewData, theme } = props;
    let playerRaceTo;
    let opponentRaceTo;
    playerRaceTo = matchData.player_race_to || matchData.race_to || 0;
    opponentRaceTo = matchData.opponent_race_to || matchData.race_to || 0;

    const playerRef = React.useRef(null);

    const currentGame = matchData && matchData.tournament_match_games && matchData.tournament_match_games[matchData.tournament_match_games.length - 1];

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const videoJsOptions = {
        // lookup the options in the docs for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
            {
                src: 'https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8',
                type: 'application/x-mpegURL',
            },
        ],
    };

    // <source src="https://media.onsitepool.net:9443/nblusa/smil:feature_private.smil/playlist.m3u8" type="application/x-mpegURL" />

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    return (
        <React.Fragment>
            <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%', position: 'relative' }}>
                <Col xs={12} sm={12} align="center">
                    <Title level={1} style={{ marginTop: 0, marginBottom: 0, color: '#fff' }}>
                        {tableData.label}
                    </Title>
                </Col>

                <Col xs={12} sm={12} align="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Avatar size={28} src={getGameType(matchData.game_type, theme)} style={{ width: 30, marginRight: 10 }} />
                        <Title level={1} style={{ marginTop: 0, marginBottom: 0, color: '#fff' }}>
                            Race to{' '}
                            {matchData.player_race_to && matchData.opponent_race_to ? `${playerRaceTo}/${opponentRaceTo}` : matchData && matchData.race_to}
                        </Title>
                    </div>
                </Col>
            </Row>
            <Row gutter={24} type="flex" justify="space-between" style={{ width: '100%' }}>
                <Col xs={12} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            background:
                                matchData.player_score > matchData.opponent_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(-45deg, black, transparent)',
                            // background: 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)',
                            borderRadius: 30,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: 20,
                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                        }}
                    >
                        {matchData && matchData.game_type === 'ONE_POCKET' && (
                            <div
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    position: 'absolute',
                                    bottom: 30,
                                    left: 30,
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 100, textTransform: 'uppercase' }}>
                                    {matchData.player_points || 0}
                                </Title>
                                <Title level={1} style={{ margin: 0, color: '#fff', textTransform: 'uppercase' }}>
                                    {matchData.player_points === 1 ? 'Ball' : 'Balls'}
                                </Title>
                            </div>
                        )}

                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                            {matchData.player_country && (
                                <React.Fragment>
                                    {matchData.player_state ? (
                                        <CircleStateFlag state={matchData.player_state} size="large" style={{ transform: 'scale(2.5)' }} />
                                    ) : (
                                        <CircleFlag
                                            country={getCountryName(matchData.player_country) || 'Unknown'}
                                            size="large"
                                            style={{ transform: 'scale(2.5)' }}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 70, textAlign: 'center', textTransform: 'uppercase' }}>
                                {matchData.player_name}{' '}
                                {matchData.player_name &&
                                    matchData.player_name !== 'BYE' &&
                                    matchData.player_skill_level &&
                                    `(${matchData.player_skill_level})`}
                            </Title>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ margin: 0, fontSize: 300, lineHeight: '300px', fontWeight: 700, color: '#fff' }}>{matchData.player_score || 0}</Text>
                        </div>
                        {currentGame && currentGame.player_tpa && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255,255,255, 0.05)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRadius: 10,
                                    fontSize: 20,
                                }}
                            >
                                <Text style={{ fontSize: 30, color: '#fff' }}>
                                    TPA: <b>{currentGame.player_tpa}</b>
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            background:
                                matchData.opponent_score > matchData.player_score
                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                    : 'linear-gradient(45deg, black, transparent)',
                            // background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                            borderRadius: 30,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: 20,
                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                        }}
                    >
                        {matchData && matchData.game_type === 'ONE_POCKET' && (
                            <div
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    position: 'absolute',
                                    bottom: 30,
                                    right: 30,
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 100, textTransform: 'uppercase' }}>
                                    {matchData.opponent_points || 0}
                                </Title>
                                <Title level={1} style={{ margin: 0, color: '#fff', textTransform: 'uppercase' }}>
                                    {matchData.opponent_points === 1 ? 'Ball' : 'Balls'}
                                </Title>
                            </div>
                        )}

                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                            {matchData.opponent_country && (
                                <React.Fragment>
                                    {matchData.opponent_state ? (
                                        <CircleStateFlag state={matchData.opponent_state} size="large" style={{ transform: 'scale(2.5)' }} />
                                    ) : (
                                        <CircleFlag
                                            country={getCountryName(matchData.opponent_country) || 'Unknown'}
                                            size="large"
                                            style={{ transform: 'scale(2.5)' }}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Title level={1} style={{ margin: 0, color: '#fff', fontSize: 70, textAlign: 'center', textTransform: 'uppercase' }}>
                                {matchData.opponent_name}{' '}
                                {matchData.opponent_name &&
                                    matchData.opponent_name !== 'BYE' &&
                                    matchData.opponent_skill_level &&
                                    `(${matchData.opponent_skill_level})`}
                            </Title>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ margin: 0, fontSize: 300, lineHeight: '300px', fontWeight: 700, color: '#fff' }}>
                                {matchData.opponent_score || 0}
                            </Text>
                        </div>
                        {currentGame && currentGame.opponent_tpa && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255,255,255, 0.05)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRsxadius: 10,
                                    fontSize: 20,
                                }}
                            >
                                <Text style={{ fontSize: 30, color: '#fff' }}>
                                    TPA: <b>{currentGame.opponent_tpa}</b>
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>

            {/* <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 20 }}>
                <Col xs={24} align="center">
                    <div style={{ marginTop: 20, transform: 'scale(1.3)' }}>
                        <RackTracker matchData={matchData} />
                    </div>
                </Col>
            </Row> */}

            {/* <div style={{ position: 'absolute', width: 600, height: 400, right: 0, bottom: 0, zIndex: 100 }}>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            </div> */}

            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 20, width: '90%' }}>
                <Col xs={24} align="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
                        <div>
                            <Title level={2} style={{ marginTop: 0, marginBottom: 0, color: '#fff', fontWeight: 400 }}>
                                {matchData.name}
                            </Title>
                        </div>

                        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                            <img src={poweredByLogo} alt="Powered By" width={300} />
                        </div>

                        {matchData.logo && (
                            <div>
                                <img src={matchData.logo} alt="Logo" height={100} />
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default withRouter(MatchTVDisplay);
