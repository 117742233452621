import React, { useEffect } from 'react';
import CircleFlag from '../../../../components/CircleFlag';
import { Avatar, Typography } from 'antd';
import { Row, Col } from 'antd';
import { getGameType, getCountryShortCode } from '../../../../utils';

const { Text, Title } = Typography;

const styles = {
    smallAvatar: {
        width: 20,
        height: 20,
    },
    logo: { position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 10, width: 150 },
    overlay: {
        // borderRadius: '10px 10px 0px 0px',
        position: 'relative',
        top: 0,
        background: 'linear-gradient(180deg,#354358,#0d1e35)',
        width: '100%',
        color: '#fff',
        height: 50,
        display: 'flex',
        alignItems: 'center',
    },
};

export default function DefaultStreamOverlayTemplate(props) {
    const { authState, matchData, tournamentData, showScores, theme, subscribeToMore, subscribeToNewData } = props;

    const challenger1 = matchData && matchData.challenger1;
    const challenger2 = matchData && matchData.challenger2;
    const challenger1Country = getCountryShortCode(matchData && matchData.challenger1_country);
    const challenger2Country = getCountryShortCode(matchData && matchData.challenger2_country);

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <div style={styles.overlay}>
            <div style={{ position: 'fixed', left: '50%', transform: 'translateX(-50%)', display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div style={{ marginRight: 10, width: '100%' }}>
                            <Title level={4} style={{ margin: 0, color: '#fff', fontSize: 16, textAlign: 'right' }}>
                                {matchData &&
                                matchData.challenger1_name &&
                                (matchData.challenger1_skill_level || (challenger1 && challenger1.skill_level)) &&
                                matchData.challenger1_race_to &&
                                tournamentData.show_player_skill_levels === true &&
                                tournamentData.show_player_races === true
                                    ? matchData &&
                                      `${matchData.challenger1_name} ${matchData.challenger1_skill_level || (challenger1 && challenger1.skill_level)} (${
                                          matchData.challenger1_race_to
                                      })`
                                    : matchData && matchData.challenger1_name && matchData.challenger1_race_to && tournamentData.show_player_races === true
                                    ? matchData && `${matchData.challenger1_name} (${matchData.challenger1_race_to})`
                                    : matchData && `${matchData.challenger1_name}`}
                            </Title>
                        </div>

                        {matchData && matchData.challenger2_avatarImg ? (
                            <div>
                                <Avatar size="small" src={matchData.challenger2_avatarImg} style={styles.smallAvatar} />
                            </div>
                        ) : (
                            <React.Fragment>
                                {challenger1Country && (
                                    <div>
                                        <CircleFlag country={challenger1Country} size="small" left={0} />
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {tournamentData && tournamentData.game_type === 'ONE_POCKET' ? (
                        <React.Fragment>
                            <div
                                style={{
                                    marginRight: 5,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    paddingTop: 7,
                                    paddingBottom: 7,
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    borderRadius: 10,
                                    minWidth: 30,
                                    textAlign: 'center',
                                }}
                            >
                                {showScores !== false ? (
                                    <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 15 }}>
                                        {matchData && matchData.challenger1_points ? matchData.challenger1_points : 0}
                                    </Title>
                                ) : (
                                    <div style={{ height: 20 }} />
                                )}
                            </div>
                            <div
                                style={{
                                    padding: 5,
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    borderRadius: 10,
                                    minWidth: 40,
                                    textAlign: 'center',
                                }}
                            >
                                {showScores !== false ? (
                                    <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 20 }}>
                                        {matchData && matchData.challenger1_score ? matchData.challenger1_score : 0}
                                    </Title>
                                ) : (
                                    <div style={{ height: 20 }} />
                                )}
                            </div>
                        </React.Fragment>
                    ) : (
                        <div
                            style={{
                                padding: 5,
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                borderRadius: 10,
                                minWidth: 40,
                                textAlign: 'center',
                            }}
                        >
                            {showScores !== false ? (
                                <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 20 }}>
                                    {matchData && matchData.challenger1_score ? matchData.challenger1_score : 0}
                                </Title>
                            ) : (
                                <div style={{ height: 20 }} />
                            )}
                        </div>
                    )}
                </div>

                {tournamentData && tournamentData.game_type && (
                    <div align="center" style={{ paddingLeft: 5, paddingRight: 5, display: 'flex', alignItems: 'center' }}>
                        <Avatar size={28} src={getGameType(tournamentData.game_type, 'dark')} style={{ width: 28 }} />
                    </div>
                )}

                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    {tournamentData && tournamentData.game_type === 'ONE_POCKET' ? (
                        <React.Fragment>
                            <div
                                style={{
                                    marginRight: 5,
                                    padding: 5,
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    borderRadius: 10,
                                    minWidth: 40,
                                    textAlign: 'center',
                                }}
                            >
                                {showScores !== false ? (
                                    <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 20 }}>
                                        {matchData && matchData.challenger2_score ? matchData.challenger2_score : 0}
                                    </Title>
                                ) : (
                                    <div style={{ height: 20 }} />
                                )}
                            </div>
                            <div
                                style={{
                                    marginRight: 10,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    paddingTop: 7,
                                    paddingBottom: 7,
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    borderRadius: 10,
                                    minWidth: 30,
                                    textAlign: 'center',
                                }}
                            >
                                {showScores !== false ? (
                                    <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 15 }}>
                                        {matchData && matchData.challenger2_points ? matchData.challenger2_points : 0}
                                    </Title>
                                ) : (
                                    <div style={{ height: 20 }} />
                                )}
                            </div>
                        </React.Fragment>
                    ) : (
                        <div
                            style={{
                                marginRight: 10,
                                padding: 5,
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                borderRadius: 10,
                                minWidth: 40,
                                textAlign: 'center',
                            }}
                        >
                            {showScores !== false ? (
                                <Title level={3} style={{ margin: 0, color: '#fff', fontSize: 20 }}>
                                    {matchData && matchData.challenger2_score ? matchData.challenger2_score : 0}
                                </Title>
                            ) : (
                                <div style={{ height: 20 }} />
                            )}
                        </div>
                    )}

                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {matchData && matchData.challenger2_avatarImg ? (
                            <div>
                                <Avatar size="small" src={matchData.challenger2_avatarImg} style={styles.smallAvatar} />
                            </div>
                        ) : (
                            <React.Fragment>
                                {challenger2Country && (
                                    <div>
                                        <CircleFlag country={challenger2Country} size="small" left={0} />
                                    </div>
                                )}
                            </React.Fragment>
                        )}

                        <div style={{ width: '100%' }}>
                            <Title level={4} style={{ margin: 0, color: '#fff', fontSize: 16 }}>
                                {matchData &&
                                matchData.challenger2_name &&
                                (matchData.challenger2_skill_level || (challenger1 && challenger2.skill_level)) &&
                                matchData.challenger2_race_to &&
                                tournamentData.show_player_skill_levels === true &&
                                tournamentData.show_player_races === true
                                    ? matchData &&
                                      `${matchData.challenger2_name} ${matchData.challenger2_skill_level || (challenger1 && challenger2.skill_level)} (${
                                          matchData.challenger2_race_to
                                      })`
                                    : matchData && matchData.challenger2_name && matchData.challenger2_race_to && tournamentData.show_player_races === true
                                    ? matchData && `${matchData.challenger2_name} (${matchData.challenger2_race_to})`
                                    : matchData && `${matchData.challenger2_name}`}
                            </Title>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
