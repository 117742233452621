import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import Ripples from 'react-ripples';
import { getGameType } from '../../../utils';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

export default function DummyScoring(props) {
    const { event, table, eventSlug, tableSlug, onHandleScoringSetup, theme } = props;

    // useEffect(() => {
    //     let unsubscribe;
    //     if (subscribeToNewData) {
    //         unsubscribe = subscribeToNewData(subscribeToMore);
    //     }
    //     return () => {
    //         if (unsubscribe) {
    //             unsubscribe();
    //         }
    //     };
    // }, []);

    return (
        <div style={styles.container}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={250} style={{ marginBottom: 20 }} />
                <Title
                    level={1}
                    style={{
                        padding: 20,
                        margin: 0,
                        color: '#fff',
                        textTransform: 'uppercase',
                        letterSpacing: 2,
                        fontSize: 40,
                        marginBottom: 20,
                    }}
                >
                    Start Scoring on {tableSlug}
                </Title>

                <Row gutter={24} justify="center" type="flex">
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                size="large"
                                type="secondary"
                                ghost
                                onClick={() => {
                                    if (table) {
                                        onHandleScoringSetup(table, 'EIGHT_BALL', event);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    height: 80,
                                    fontSize: 40,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                8-Ball
                            </Button>
                        </Ripples>
                    </Col>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                size="large"
                                type="secondary"
                                ghost
                                onClick={() => {
                                    if (table) {
                                        onHandleScoringSetup(table, 'NINE_BALL', event);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    height: 80,
                                    fontSize: 40,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                9-Ball
                            </Button>
                        </Ripples>
                    </Col>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                size="large"
                                type="secondary"
                                ghost
                                onClick={() => {
                                    if (table) {
                                        onHandleScoringSetup(table, 'TEN_BALL', event);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    height: 80,
                                    fontSize: 40,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                10-Ball
                            </Button>
                        </Ripples>
                    </Col>
                </Row>
                <Row gutter={24} justify="center" type="flex" style={{ marginTop: 20 }}>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                size="large"
                                type="secondary"
                                ghost
                                onClick={() => {
                                    if (table) {
                                        onHandleScoringSetup(table, 'BANK_POOL', event);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    height: 80,
                                    fontSize: 40,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                {/* <Avatar
                                    size={24}
                                    src={getGameType('BANK_POOL', theme)}
                                    style={{
                                        display: 'inline-block',
                                        marginRight: 8,
                                    }}
                                /> */}
                                Banks
                            </Button>
                        </Ripples>
                    </Col>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                size="large"
                                type="secondary"
                                ghost
                                onClick={() => {
                                    if (table) {
                                        onHandleScoringSetup(table, 'ONE_POCKET', event);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    height: 80,
                                    fontSize: 40,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                One Pocket
                            </Button>
                        </Ripples>
                    </Col>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                size="large"
                                type="secondary"
                                ghost
                                onClick={() => {
                                    if (table) {
                                        onHandleScoringSetup(table, 'STRAIGHT_POOL', event);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    height: 80,
                                    fontSize: 40,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                Straight Pool
                            </Button>
                        </Ripples>
                    </Col>
                </Row>
                {/* <Row gutter={24} justify="center" type="flex" style={{ marginTop: 20 }}>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                type="danger"
                                ghost
                                style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    height: 80,
                                    fontSize: 30,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                Start Streaming
                            </Button>
                        </Ripples>
                    </Col>
                    <Col>
                        <Ripples color="#ccc">
                            <Button
                                type="danger"
                                ghost
                                style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    height: 80,
                                    fontSize: 30,
                                    fontWeight: 700,
                                    borderRadius: 20,
                                    borderWidth: 3,
                                }}
                            >
                                Stop Streaming
                            </Button>
                        </Ripples>
                    </Col>
                </Row> */}
            </div>
        </div>
    );
}
