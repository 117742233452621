import React, { useState, useEffect } from 'react';
import { message, Icon, Row, Col, Typography, Progress, Button, Input, Tabs, Empty, Table, Modal } from 'antd';
import Card from '../../components/Card';
import CircleFlag from '../../components/CircleFlag';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import _ from 'lodash';
import { Parser } from 'json2csv';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const defaultSize = 'small';

export default function StageMatches(props) {
    const { client, tournament, matches, stage, theme, subscribeToNewData, subscribeToMore } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tournamentComplete, setTournamentComplete] = useState(tournament.status === 'COMPLETED' ? true : false);
    const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);

    useEffect(() => {
        const unsubscribe = subscribeToNewData(subscribeToMore);

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'match_number',
            key: 'match',

            sorter: (a, b) => a.match_number - b.match_number,
        },
        {
            title: 'Player 1',
            dataIndex: 'challenger1_name',
            key: 'player1',
            align: 'right',
            width: 220,
            render: (text, record) => {
                const country = record.challenger1_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            {country && <CircleFlag country={country} left={0} />}
                            <div
                                style={{
                                    fontWeight:
                                        record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                            ? 'bold'
                                            : 'normal',
                                }}
                            >
                                {text || '-'}
                            </div>
                        </div>

                        <span
                            style={{
                                marginLeft: 20,
                                padding: '5px 10px',
                                backgroundColor:
                                    record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                        ? '#1890fe'
                                        : theme && theme.name === 'dark'
                                        ? '#135da2'
                                        : '#8dc8ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger1_is_forfeit === true ? 'FF' : record.challenger1_score || 0}
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'Player 2',
            dataIndex: 'challenger2_name',
            key: 'player2',
            width: 220,
            render: (text, record) => {
                const country = record.challenger2_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <span
                            style={{
                                marginRight: 20,
                                padding: '5px 10px',
                                backgroundColor:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? '#1890fe'
                                        : theme && theme.name === 'dark'
                                        ? '#135da2'
                                        : '#8dc8ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_score || 0}
                        </span>
                        {country && <CircleFlag country={country} left={0} />}
                        <span
                            style={{
                                marginLeft: 0,

                                fontWeight:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {text || '-'}
                        </span>
                    </div>
                );
            },
        },

        {
            title: 'Table',
            dataIndex: 'table_name',
            align: 'left',
            key: 'table',

            sorter: (a, b) => {
                return (
                    a.table_name &&
                    b.table_name &&
                    a.table_name.localeCompare(b.table_name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {/* <img src={tableBlue} width={25} alt="Table 1" /> */}
                        {/* <span style={{ marginLeft: 10 }}>{text || '-'}</span> */}
                        <span>{text || '-'}</span>
                    </div>
                );
            },
        },

        {
            title: 'Time',
            dataIndex: 'start_time',
            key: 'start_time',
            width: 200,
            sorter: (a, b) => {
                return a.start_time.localeCompare(b.start_time, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                const matches = record.matches;
                let followsMatch;

                if (matches && matches.length > 0) {
                    const follows = matches.filter((match) => {
                        if (match.priority && match.priority === record.priority - 1 && record.table_id === match.table_id) {
                            return match;
                        }
                    })[0];

                    if (follows) {
                        followsMatch = follows.match_number;
                    }
                }

                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {(!record.start_time || !record.scheduled_time) && followsMatch
                            ? `Follows Match ${followsMatch}`
                            : record.scheduled_time
                            ? moment(record.scheduled_time).format('lll')
                            : record.start_time
                            ? moment(record.start_time).format('lll')
                            : moment(record.tournament.start_date_time).format('ll')}
                    </div>
                );
            },
        },

        {
            title: 'Order',
            dataIndex: 'priority',
            key: 'priority',
            width: 80,
            // defaultSortOrder: 'desc',
            sorter: (a, b) => b.priority - a.priority,
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },

        {
            title: 'Identifier',
            dataIndex: 'identifier',
            key: (a) => {
                return `identifier-${a.identifier}`;
            },
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {record.identifier}{' '}
                        {/* {record.loser_to ? (
							`(Loser to ${record.loser_to})`
						) : record.loser_from ? (
							`(Loser from ${record.loser_from})`
						) : record.winner_to ? (
							`(Winner to ${record.winner_to})`
						) : (
							''
						)} */}
                    </div>
                );
            },
            sorter: (a, b) => {
                return a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },

        // {
        //     title: 'Where to',
        //     dataIndex: 'identifier',
        //     key: 'identifier',

        //     render: (text, record) => {
        //         return (
        //             <div>
        //                 {/* {record.identifier}{' '} */}
        //                 {record.loser_to ? (
        //                     <React.Fragment>{record.loser_to_num ? `L to ${record.loser_to_num}` : ''}</React.Fragment>
        //                 ) : record.loser_from ? (
        //                     `L from ${record.loser_from_num} - W to ${record.winner_to_num}`
        //                 ) : record.winner_to ? (
        //                     `W to ${record.winner_to_num && record.winner_to_num}`
        //                 ) : (
        //                     '-'
        //                 )}
        //             </div>
        //         );
        //     },
        // },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Progress
                            percent={record.progress || 0}
                            status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                        {/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							width: '100%'
						}}
					>
						<span
							style={{
								width: '100%',
								textAlign: 'left',
								backgroundColor: '#ececec',
								height: 10,
								position: 'relative'
							}}
						>
							<span
								style={{
									display: 'inline-block',
									// backgroundColor: '#bac2cc',
									backgroundColor: '#1890fe',
									width: `${(record.progress || 0) / 100 * 100}%`,
									height: '100%',
									position: 'absolute',
									transition: 'background-color .3s ease',
									cursor: 'pointer'
								}}
							/>
						</span>
						<span
							style={{
								width: 80,
								marginLeft: 10
							}}
						>
							{record.progress || 0}%
						</span>
					</div> */}
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                let duration;
                if (record.start_time && record.end_time) {
                    var start = moment(record.start_time);
                    var end = moment(record.end_time);
                    duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
                } else {
                    duration = moment.duration(moment.utc().diff(record.start_time, 'milliseconds')).format('h [hours] m [min]');
                }

                return duration !== '0 mins' ? (
                    <div>
                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> {duration}
                    </div>
                ) : (
                    <div>-</div>
                );
            },
            sorter: (a, b) => {
                return a.start_time.localeCompare(b.start_time, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => {
                return a.status.localeCompare(b.status, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },
    ];

    let bracket = [];
    let roundNum = 0;

    matches.forEach((round, i) => {
        if (i > 0 && round.round === matches[i - 1].round) {
            bracket[roundNum].push(round);
        } else if (i === 0) {
            bracket.push([round]);
            // if (!round.challenger1_seed) {
            // 	unseeded = true;
            // }
        } else if (i > 0 && round.round !== matches[i - 1].round) {
            bracket.push([round]);
            roundNum++;
        }
    });

    const loserBracket =
        bracket &&
        bracket.filter((rounds) => {
            return rounds[0].round < 1;
        });

    const draw =
        bracket &&
        bracket.filter((rounds) => {
            return rounds[0].round === 1;
        });

    const winnerBracket =
        bracket &&
        bracket.filter((rounds) => {
            return rounds[0].round > 1;
        });

    let brackets;
    let roundOrder = [];
    // console.log(brackets);
    const totalRounds = bracket.length;
    const maxPlayers = stage ? stage.max_players : tournament.max_players;
    const consolidationFinals = stage ? stage.consolidation_finals : tournament.consolidation_finals;

    if (tournament.tournament_stage_format === 'multi' && stage && stage.number > 1) {
        brackets = [..._.flatten(draw), ..._.flatten(winnerBracket)];
        roundOrder = Array.from({ length: totalRounds }, (v, index) => {
            return index + 1;
        });
    } else {
        // generate the correct order in which the rounds should be played
        // console.log('round order:', roundOrder);
        // console.log('hard coded pattern:', [ 1, 2, -1, -2, 3, -3, -4, 4, -5, -6, 5, -7, -8, 6 ]);
        brackets = [..._.flatten(loserBracket), ..._.flatten(draw), ..._.flatten(winnerBracket)];
        roundOrder = setRoundOrdering(totalRounds, maxPlayers, consolidationFinals);
    }

    let rounds = roundOrder.map((round) => {
        const roundMatches = getMatchesByRound(round, brackets);
        return roundMatches;
    });

    // update match numbers to reflect new round ordering
    const ordering = setMatchNumbers(rounds);
    console.log('ordering: ', ordering);

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return (stage && stage.winners_race_to) || tournament.winners_race_to;
            } else {
                return (stage && stage.losers_race_to) || tournament.losers_race_to;
            }
        }
    }

    function handleModal() {
        setModalVisible(true);
    }

    function RenderRow(props) {
        return <div className="streaming">{props}</div>;
    }

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    function getRoundTitle(rounds, index, bracket) {
        const round = rounds && rounds.length > 0 && parseInt(rounds[0].round);
        const consolidationFinals = stage ? stage.consolidation_finals : tournament.consolidation_finals;
        let title;

        if (tournament.tournament_stage_format === 'multi' && stage && stage.number === 1) {
            if (round > 1) {
                title = `Winner Round ${round}`;
            } else if (round === 1) {
                title = `Winner Round ${round}`;
            } else if (round < 0) {
                title = `Loser Round ${round.toString().substring(1)}`;
            } else {
                title = null;
            }
        } else {
            if (round > 1 && index === bracket.length - 1) {
                if (consolidationFinals) {
                    title = 'Consolidation Final';
                } else {
                    title = 'Final';
                }
            } else if (round > 1 && index === bracket.length - 2) {
                if (consolidationFinals) {
                    title = 'Final';
                } else {
                    title = 'Semi-Final';
                }
            } else if (round > 1 && index === bracket.length - 5) {
                if (consolidationFinals) {
                    title = 'Semi-Final';
                } else {
                    title = 'Quarter-Final';
                }
            } else if (round > 1) {
                title = `Winner Round ${round}`;
            } else if (round === 1) {
                title = `Winner Round ${round}`;
            } else if (round < 0) {
                title = `Loser Round ${round.toString().substring(1)}`;
            } else {
                title = null;
            }
        }

        return title;
    }

    function showCompleteDialog(winner) {
        Modal.success({
            content: `${winner.challenger2_name || winner.challenger1_name} won the tournament!`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        setTournamentComplete(true);
    }

    function getMatchesByRound(round, bracket) {
        const matches = [];
        bracket.forEach((item, index) => {
            if (item.round === round) {
                matches.push(item);
            }
        });
        return matches;
    }

    function setRoundOrdering(totalRounds, maxPlayers, consolidationFinals) {
        let winnerRoundCounter = 0;
        let loserRoundCounter = 0;
        let roundOrder = [];

        Array.from({ length: totalRounds }, (v, index) => {
            if (index === 0) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }
            if (index !== totalRounds - 2 && index % 3 === 1 && index !== totalRounds - 1) {
                loserRoundCounter--;
                roundOrder.push(loserRoundCounter);
                loserRoundCounter--;
                roundOrder.push(loserRoundCounter);
            } else if (index % 2 === 0) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }
            // if (index === 0) {
            // 	winnerRoundCounter++;
            // 	roundOrder.push(winnerRoundCounter);
            // }

            // if (index % 3 === 1) {
            // 	loserRoundCounter--;
            // 	roundOrder.push(loserRoundCounter);
            // 	loserRoundCounter--;
            // 	roundOrder.push(loserRoundCounter);
            // } else if (index % 2 === 0) {
            // 	winnerRoundCounter++;
            // 	roundOrder.push(winnerRoundCounter);
            // }

            // if (index === totalRounds - 1 && maxPlayers > 64) {
            // 	winnerRoundCounter++;
            // 	roundOrder.push(winnerRoundCounter);
            // }
            // if (index === totalRounds - 1 && consolidationFinals === true) {
            // 	winnerRoundCounter++;
            // 	roundOrder.push(winnerRoundCounter);
            // }
        });
        return roundOrder;
    }

    function setMatchNumbers(rounds) {
        let counter = 1;
        let ordering = rounds.map((round, index) => {
            return round.map((match, i) => {
                const newMatch = {
                    ...match,
                    match_number: counter,
                };
                counter++;
                return newMatch;
            });
        });

        return ordering;
    }

    function calcDuration(challenger1_times, challenger2_times) {
        const challenger1Duration = _.compact(
            challenger1_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        const challenger2Duration = _.compact(
            challenger2_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        return challenger1Duration + challenger2Duration;
    }

    function orderAndFormatData(arr, csv = false) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formattedResults = [];

        for (let i = 0; i < count; i++) {
            split = false;

            let match = arr[i];

            let duration;
            if (match.start_time && match.end_time) {
                var start = moment(match.start_time);
                var end = moment(match.end_time);
                duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
            }

            if (csv) {
                formattedResults.push({
                    id: match.id,
                    ...(stage && { stage: stage.number }),
                    round: match.round,
                    match_id: match.match_id,
                    match_number: match.match_number,
                    identifier: match.identifier,
                    table_id: match.table_id,
                    challenger1_name: match.challenger1_name,
                    challenger1_country: match.challenger1_country,
                    challenger1_score: match.challenger1_score,
                    challenger2_name: match.challenger2_name,
                    challenger2_country: match.challenger2_country,
                    challenger2_score: match.challenger2_score,
                    start_time: match.start_time,
                    end_time: match.end_time,
                    duration: duration,
                });
            } else {
                formattedResults.push({
                    id: match.id,
                    ...(stage && { stage: stage.number }),
                    round: match.round,
                    match_id: match.match_id,
                    match_number: match.match_number,
                    identifier: match.identifier,
                    table_id: match.table_id,
                    challenger1_name: match.challenger1_name,
                    challenger1_country: match.challenger1_country,
                    challenger1_score: match.challenger1_score,
                    challenger2_name: match.challenger2_name,
                    challenger2_country: match.challenger2_country,
                    challenger2_score: match.challenger2_score,
                    start_time: match.start_time,
                    end_time: match.end_time,
                    duration: duration,
                });
            }
        }

        console.log(formattedResults);

        return formattedResults;
    }

    function downloadCSV() {
        setCSVDownloadLoading(true);
        if (!matches.length) {
            message.success('There was an error');
            setCSVDownloadLoading(false);
        }
        let filename = tournament.slug + '.csv';
        let data = orderAndFormatData(matches, true);
        let fields;
        if (stage) {
            fields = [
                'id',
                'stage',
                'round',
                'match_id',
                'match_number',
                'identifier',
                'table_id',
                'challenger1_name',
                'challenger1_country',
                'challenger1_score',
                'challenger2_name',
                'challenger2_country',
                'challenger2_score',
                'start_time',
                'end_time',
                'duration',
            ];
        } else {
            fields = [
                'id',
                'round',
                'match_id',
                'match_number',
                'identifier',
                'table_id',
                'challenger1_name',
                'challenger1_country',
                'challenger1_score',
                'challenger2_name',
                'challenger2_country',
                'challenger2_score',
                'start_time',
                'end_time',
                'duration',
            ];
        }

        // const csv = await new objtocsv(data).toString();
        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(data);

        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }

        message.success('Exporting completed');
        setCSVDownloadLoading(false);
    }

    async function formatResultsTableData(data) {
        if (data) {
            // console.log(data);
            let orderedData = orderAndFormatData(data);
            setTableData(orderedData);
        }
    }

    return (
        <React.Fragment>
            <Col xs={24}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button type="primary" onClick={downloadCSV}>
                        <Icon type={csvDownloadLoading ? 'loading' : 'download'} /> {stage ? `Export Stage ${stage.number} CSV` : 'Export CSV'}
                    </Button>
                </div>
            </Col>
            {ordering.map((round, index) => {
                // let progress = Math.floor(Math.random() * 100) + 1;

                if (round && round.length > 0) {
                    return (
                        <Col xs={24} key={index}>
                            <Card
                                title={getRoundTitle(round, index, bracket)}
                                bodyStyle={{ padding: 0 }}
                                // style={{
                                // 	borderRadius: 8,
                                // 	overflow: 'hidden',
                                // 	border: 'none',
                                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                // }}
                            >
                                <div className="table_nowrap">
                                    <Table
                                        dataSource={round.map((item, index) => {
                                            return {
                                                ...item,
                                                table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                                matches: matches,
                                                key: index,
                                            };
                                        })}
                                        columns={columns}
                                        className="match-table table-striped-rows noselect"
                                        tableLayout="auto"
                                        scroll={{ x: 400 }}
                                        size={defaultSize}
                                        pagination={false}
                                        // components={{
                                        // 	body: {
                                        // 		row: RenderRow
                                        // 	}
                                        // }}
                                        onRow={(record, rowIndex) => {
                                            let classNameRow;
                                            if (record.challenger1_name === 'BYE' || record.challenger2_name === 'BYE') {
                                                classNameRow = `${getClassName(record)} bye-round`;
                                            } else {
                                                classNameRow = getClassName(record);
                                            }
                                            return {
                                                onClick: (event) => {
                                                    // console.log("E", record);
                                                    // setCurrentMatch(record);
                                                    // handleModal();
                                                }, // click row
                                                onDoubleClick: (event) => {}, // double click row
                                                onContextMenu: (event) => {}, // right button click row
                                                onMouseEnter: (event) => {}, // mouse enter row
                                                onMouseLeave: (event) => {}, // mouse leave row
                                                className: classNameRow,
                                            };
                                        }}
                                        onHeaderRow={(column) => {
                                            return {
                                                onClick: () => {}, // click header row
                                            };
                                        }}
                                    />
                                </div>
                            </Card>
                        </Col>
                    );
                } else {
                    return null;
                }
            })}
            {/* <MatchOverlay
										{...props}
										modalScoreVisible={modalVisible}
										finals={matches && matches[matches.length - 1]}
										matches={matches}
										onModalScoreVisible={setModalVisible}
										currentMatch={currentMatch}
										tournament={tournament}
										onTournamentComplete={(winner) => {
											completeTournament(winner);
										}}
									/> */}
        </React.Fragment>
    );
}
