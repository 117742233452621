export function getBracketSize(totalPlayers) {
    const bracketSizes = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];
    let recommendedSize = 4;

    function between(x, min, max) {
        return x >= min && x <= max;
    }

    if (bracketSizes.includes(totalPlayers) === true) {
        recommendedSize = totalPlayers;
    } else {
        bracketSizes.forEach((size, index) => {
            // console.log({
            // 	totalPlayers,
            // 	size,
            // 	nextSize: bracketSizes[index + 1],
            // 	result: between(totalPlayers, size, bracketSizes[index + 1])
            // });

            if (between(totalPlayers, size, bracketSizes[index + 1])) {
                recommendedSize = bracketSizes[index + 1];
            }
        });
    }

    console.log('BRACKET SIZE', recommendedSize);
    return recommendedSize;
}
