import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, DatePicker, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Modal, Button, Divider, Icon } from 'antd';
import * as Yup from 'yup';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_EVENT_MUTATION, UPDATE_EVENT_MUTATION } from './data/mutations';
import { SEARCH_VENUES_QUERY, GET_USER_EVENTS_QUERY, GET_EVENT_QUERY } from './data/queries';
import EventUploadFlyer from '../events/EventUploadFlyer';
import slugify from 'slugify';
import _ from 'lodash';
import AddVenueModal from './AddVenueModal';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d' },
};

function AddEventModal(props) {
    const { modalVisible, onModalOk, onModalCancel, authState, selectedEventId, client } = props;
    const [loading, setLoading] = useState(false);
    const [venueDataSource, setVenueDataSource] = useState('');
    const formRef = useRef();
    const [venueModalVisible, setVenueModalVisible] = useState(false);

    const schema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
        event_type: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        // venue_id: Yup.string().required('Required'),
        start_date_time: Yup.string().required('Required'),
    });

    function handleKeyPress(event) {
        console.log(event.key);
        if (event.key === 'Enter') {
            console.log('enter press here! ');
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function onOk(value) {
        const defaultTimezone = authState && authState.user && authState.user.default_timezone;
        var zone_name = moment.tz.guess();
        var timezone = defaultTimezone ? moment.tz(defaultTimezone).zoneAbbr() : moment.tz(zone_name).zoneAbbr();

        const tzDate = moment.tz(value, timezone).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelect(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function handleSubmit(values, createEvent, updateEvent) {
        console.log(values);

        const { name, description, start_date_time, end_date_time, owner_id, venue_id, event_type, is_public } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (selectedEventId) {
            updateEvent({
                variables: {
                    id: selectedEventId,
                    changes: {
                        name,
                        slug,
                        description,
                        start_date_time,
                        end_date_time,
                        venue_id,
                        event_type,
                        owner_id: authState.user.id,
                        is_public: is_public === 'public' ? true : false,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_EVENT_QUERY,
                        variables: { id: selectedEventId },
                    },
                ],
            });
        } else {
            createEvent({
                variables: {
                    objects: [
                        {
                            name,
                            description,
                            start_date_time,
                            end_date_time,
                            venue_id,
                            event_type,
                            owner_id: authState.user.id,
                            is_public: is_public === 'public' ? true : false,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_USER_EVENTS_QUERY,
                        variables: { user_id: authState.user.id },
                    },
                ],
            });
        }
    }

    const eventMutations = (data, loading, error) => {
        return (
            <Mutation
                mutation={CREATE_EVENT_MUTATION}
                onCompleted={(data) => {
                    console.log(data);
                    setLoading(false);
                    message.success('Event successfully created');
                    // const result = data.insert_venues.returning[0];
                    onModalOk();
                    // props.history.push(`/tournament-builder/${props.tournament.slug}/venues/${result.slug}`);
                }}
                awaitRefetchQueries
            >
                {(createEvent, { error }) => (
                    <Mutation
                        mutation={UPDATE_EVENT_MUTATION}
                        onCompleted={(data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            // const result = data.update_events.returning[0];
                            // props.history.push(`/events/${result.slug}`);
                            onModalOk();
                        }}
                        awaitRefetchQueries
                    >
                        {(updateEvent, { error }) => {
                            if (error) {
                                alert('Something went wrong');
                            }
                            const event = data && data.events && data.events.length > 0 && data.events[0];
                            const initialValues = event
                                ? {
                                      ...event,
                                      name: event.name,
                                      venue_id: event.venue && event.venue.id,
                                      venue_name: event.venue && event.venue.name,
                                      is_public: event.is_public === true ? 'public' : 'private',
                                  }
                                : {
                                      name: '',
                                      venue_id: undefined,
                                      venue_name: undefined,
                                      event_type: 'tournament',
                                      start_date_time: undefined,
                                      end_date_time: undefined,
                                      is_public: true,
                                      owner_id: '',
                                  };

                            return (
                                <React.Fragment>
                                    {/* {loading && (
											<div style={styles.container}>
												<CircularLoader />
											</div>
										)} */}
                                    {/* {error && <div style={styles.container}>Error: {error.message}</div>} */}

                                    {!loading && !error && (
                                        <Formik
                                            ref={formRef}
                                            enableReinitialize
                                            initialValues={initialValues}
                                            validationSchema={schema}
                                            validate={(values) => {
                                                // console.log(values);
                                            }}
                                            onSubmit={(values, actions) => {
                                                // console.log(values, actions);
                                                // actions.setSubmitting(false);
                                                // actions.resetForm();
                                                handleSubmit(values, createEvent, updateEvent);
                                            }}
                                            render={({ errors, touched, values }) => (
                                                <Modal
                                                    title={selectedEventId ? 'Update Event' : 'Add Event'}
                                                    // getContainer={() => document.querySelector('.tables')}
                                                    visible={modalVisible}
                                                    onOk={onModalOk}
                                                    onCancel={onModalCancel}
                                                    centered
                                                    transitionName="fade"
                                                    // transitionName="none"
                                                    maskTransitionName="none"
                                                    // footer={null}
                                                    footer={[
                                                        <Button key="cancel" onClick={onModalCancel}>
                                                            Cancel
                                                        </Button>,
                                                        <Button
                                                            key="submit"
                                                            disabled={Object.keys(errors).length > 0 ? true : false}
                                                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                                            loading={loading}
                                                            onClick={() => {
                                                                if (formRef.current) {
                                                                    formRef.current.handleSubmit();
                                                                }
                                                            }}
                                                        >
                                                            {selectedEventId ? (loading ? 'Updating...' : 'Update') : 'Create'} Event
                                                        </Button>,
                                                    ]}
                                                    bodyStyle={{
                                                        maxHeight: 500,
                                                        overflowY: 'auto',
                                                        padding: '10px 20px',
                                                    }}
                                                    // style={{
                                                    // 	left: -120
                                                    // }}
                                                    destroyOnClose={true}
                                                >
                                                    <Form
                                                        layout="vertical"
                                                        style={{ maxWidth: 600 }}
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            handleSubmit(values, createEvent, updateEvent);
                                                        }}
                                                    >
                                                        <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                                            <Input
                                                                name="name"
                                                                placeholder="Name"
                                                                size="large"
                                                                onChange={(event) => {
                                                                    handleFormFieldUpdates(event);
                                                                }}
                                                            />
                                                        </FormItem>
                                                        <FormItem label="Event Type" name="event_type" required hasFeedback showValidateSuccess>
                                                            <Select
                                                                showSearch
                                                                name="event_type"
                                                                placeholder="Select an event type"
                                                                size="large"
                                                                optionFilterProp="children"
                                                                defaultValue="tournament"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {[
                                                                    {
                                                                        type: 'Tournament',
                                                                        value: 'tournament',
                                                                    },
                                                                    {
                                                                        type: 'Live stream',
                                                                        value: 'live_stream',
                                                                    },

                                                                    {
                                                                        type: 'Other',
                                                                        value: 'other',
                                                                    },
                                                                ].map((item, index) => (
                                                                    <Option key={index} value={item.value}>
                                                                        {item.type}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </FormItem>
                                                        <FormItem label="Description" name="description" required hasFeedback showValidateSuccess>
                                                            <TextArea
                                                                rows={4}
                                                                name="description"
                                                                placeholder="Description"
                                                                size="large"
                                                                onChange={(event) => {
                                                                    handleFormFieldUpdates(event);
                                                                }}
                                                            />
                                                        </FormItem>
                                                        <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <AutoComplete
                                                                    name="venue_name"
                                                                    dataSource={venueDataSource}
                                                                    placeholder="Search for a venue"
                                                                    onSearch={onSearch}
                                                                    onSelect={onSelect}
                                                                    size="large"
                                                                    filterOption={(inputValue, option) => {
                                                                        return (
                                                                            option.props &&
                                                                            option.props.children &&
                                                                            option.props.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(inputValue.toString().toLowerCase()) >= 0
                                                                        );
                                                                    }}
                                                                    // dropdownRender={(menu) => (
                                                                    //     <div>
                                                                    //         {menu}
                                                                    //         <Divider style={{ margin: '4px 0' }} />
                                                                    //         <div
                                                                    //             style={{
                                                                    //                 padding: '4px 8px',
                                                                    //                 cursor: 'pointer',
                                                                    //             }}
                                                                    //             onMouseDown={(e) => e.preventDefault()}
                                                                    //             onClick={handleAddVenue}
                                                                    //         >
                                                                    //             <Icon type="plus" /> Add Venue
                                                                    //         </div>
                                                                    //     </div>
                                                                    // )}
                                                                >
                                                                    {venueDataSource &&
                                                                        venueDataSource.map((item, index) => (
                                                                            <Option key={index} value={`${item.id}`}>
                                                                                {item.name} - {item.city}, {item.region}
                                                                            </Option>
                                                                        ))}
                                                                </AutoComplete>
                                                                {/* <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                                                    <Icon type="plus" /> Add Venue
                                                                </Button> */}
                                                            </div>
                                                        </FormItem>

                                                        <FormItem label="Start Date & Time" name="start_date_time" required hasFeedback showValidateSuccess>
                                                            <DatePicker
                                                                showTime
                                                                name="start_date_time"
                                                                size="large"
                                                                format="YYYY-MM-DD hh:mm A z"
                                                                // onChange={onDateChange}
                                                                onOk={onOk}
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            label="End Date & Time"
                                                            name="end_date_time"
                                                            hasFeedback
                                                            showValidateSuccess
                                                            // className={
                                                            // 	'ant-form-item-control' +
                                                            // 	(errors.end_date || errors.general ? ' has-error' : '')
                                                            // }
                                                        >
                                                            <DatePicker
                                                                showTime
                                                                name="end_date_time"
                                                                size="large"
                                                                format="YYYY-MM-DD hh:mm A z"
                                                                // onChange={onDateChange}
                                                                onOk={onOk}
                                                            />
                                                            {/* <ErrorMessage name="end_date" component="div" className="ant-form-explain" /> */}
                                                        </FormItem>

                                                        <FormItem name="is_public" label="Access">
                                                            <Radio.Group name="is_public">
                                                                <Radio value={true}>Public - Anyone can view and RSVP for this event.</Radio>
                                                                <br />
                                                                <Radio value={false}>Private - Only users with the link can see this event.</Radio>
                                                            </Radio.Group>
                                                        </FormItem>

                                                        {/* <div style={{ marginLeft: 200 }}>
                                                            <EventUploadFlyer {...props} event={event} />
                                                        </div> */}

                                                        <div>
                                                            <Text style={styles.error}>{errors.general}</Text>
                                                        </div>
                                                        <div>
                                                            <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                                        </div>
                                                        <AddVenueModal
                                                            {...props}
                                                            modalVisible={venueModalVisible}
                                                            onModalOk={handleVenueModalOk}
                                                            onModalCancel={handleVenueModalCancel}
                                                        />
                                                    </Form>
                                                </Modal>
                                            )}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        );
    };

    return (
        <React.Fragment>
            {selectedEventId ? (
                <Query
                    query={GET_EVENT_QUERY}
                    fetchPolicy="no-cache"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedEventId }}
                    onCompleted={async (data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => eventMutations(data, loading, error)}
                </Query>
            ) : (
                eventMutations()
            )}
        </React.Fragment>
    );
}

export default withRouter(AddEventModal);
