import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import HelmetMetaData from './components/HelmetMetaData';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
// import App from './App';
import Auth from './Auth';
import ErrorBoundary from './components/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import ThemeSelector from './theme/ThemeSelector';
import './index.css';
import smoothscroll from 'smoothscroll-polyfill';

import './theme/light-theme.scss';
import './theme/dark-theme.scss';
// kick off the polyfill!
smoothscroll.polyfill();

function noop() {}

if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
}

function AppContainer(props, context) {
    return (
        <HelmetProvider>
            <HelmetMetaData />
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Digital Pool</title>
                <link rel="canonical" href="https://www.digitalpool.com/" />
                <meta
                    name="description"
                    content="Create and manage your own leagues and tournaments while you stay up to date with the latest scores, news, and social activity from around the pool world."
                />
                <meta property="og:title" content="DigitalPool - Bridging the gap between player and technology" />
                <meta property="og:site_name" content="digitalpool.com" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:description"
                    content="Create and manage your own leagues and tournaments while you stay up to date with the latest scores, news, and social activity from around the pool world."
                />
                <meta property="og:url" content="https://www.digitalpool.com" />
                <meta property="og:image" content="https://digitalpool.com/images/fb-image.png" />
                <meta property="fb:app_id" content="1697957547011275" />
                <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
            </Helmet> */}

            <RecoilRoot>
                <Auth {...props} {...context} />
            </RecoilRoot>
        </HelmetProvider>
    );
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
